import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import trackTraceEmailTemplate from '../../../../server/functions/mail/templates/trackTraceEmailTemplate.js'
import cancelEmailTemplate from '../../../../server/functions/mail/templates/cancelEmailTemplate.js'
import {
    Colors,
    Editor,
    EmailPreview,
    H3,
    Panel,
    Input,
    Toggle,
    Tab,
    Tabs
} from '../../../components/UI/index.js'
import retourConfirmationTemplate from '../../../../server/functions/mail/templates/retourConfirmationTemplate.js'


class CommunicationSettings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'confirmed'
        }
    }

    componentDidMount() {
        if (this.props?.location?.state?.view) {
            this.setState({view: this.props.location.state.view})
        }
    }

    onChangeValue(path, event) {
        const user = {...this.props.user}

        _.set(user.settings, path, event.target.value)

        this.props.onChange(user)
    }

    onChangeChecked(path, event) {
        const user = {...this.props.user}

        _.set(user.settings, path, event.target.checked)

        this.props.onChange(user, path)
    }

    onChangeFileUpload(path, fileName, value) {
        const user = {...this.props.user}

        _.set(user.settings, path, value)

        this.props.onChange(user, path)
    }

    render() {
        const {reseller, user} = this.props
        const {view} = this.state

        const returnsEnabled = _.some(_.keys(reseller.settings.parcels.products?.Retour), (carrier) => {
            return reseller.settings.parcels.products.Retour[carrier]
        })

        return (
            <div style={{display: 'flex'}}>
                <Panel style={{width: 600, padding: 20}}>
                    <H3>Email notificaties</H3>

                    <Tabs value={view} onChange={(event) => this.setState({view: event.target.value})}>
                        <Tab value='confirmed'>Aangemeld</Tab>
                        <Tab value='shipped'>Verzonden</Tab>
                        <Tab value='cancelEmail'>Geannuleerd</Tab>
                        {returnsEnabled && <Tab value='retourConfirmation'>Retourbevestiging</Tab>}
                    </Tabs>

                    {view === 'confirmed' &&
                        <>
                            <Toggle
                                label='Verstuur "Aangemeld" email'
                                checked={user.settings.parcels.sendConfirmedEmail}
                                onChange={this.onChangeChecked.bind(this, 'parcels.sendConfirmedEmail')}
                            />

                            <Input
                                label='Afzender naam'
                                placeholder={user.name}
                                value={user.settings.parcels.confirmedEmailSenderName}
                                onChange={this.onChangeValue.bind(this, 'parcels.confirmedEmailSenderName')}
                            />

                            <Input
                                label='Afzender emailadres'
                                placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                                value={user.settings.parcels.confirmedEmailReplyTo}
                                onChange={this.onChangeValue.bind(this, 'parcels.confirmedEmailReplyTo')}
                            />


                            <br/>
                            <Editor
                                label='Onderwerp'
                                as='input'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'trackTraceCode']}
                                value={user.settings.parcels.confirmedEmailSubject}
                                onChange={this.onChangeValue.bind(this, 'parcels.confirmedEmailSubject')}
                            />

                            <Editor
                                label='Bericht'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'trackTraceCode', 'trackTraceLink', user.settings.parcels.returnPortalActive && 'retourlink']}
                                value={user.settings.parcels.confirmedEmailBody}
                                onChange={this.onChangeValue.bind(this, 'parcels.confirmedEmailBody')}
                            />
                        </>
                    }

                    {view === 'shipped' &&
                    <>
                        <Toggle
                            label='Verstuur "Verzonden" email'
                            checked={user.settings.parcels.sendShippedEmail}
                            onChange={this.onChangeChecked.bind(this, 'parcels.sendShippedEmail')}
                        />

                        <Input
                            label='Afzender naam'
                            placeholder={user.name}
                            value={user.settings.parcels.shippedEmailSenderName}
                            onChange={this.onChangeValue.bind(this, 'parcels.shippedEmailSenderName')}
                        />

                        <Input
                            label='Afzender emailadres'
                            placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                            value={user.settings.parcels.shippedEmailReplyTo}
                            onChange={this.onChangeValue.bind(this, 'parcels.shippedEmailReplyTo')}
                        />


                        <br/>
                        <Editor
                            label='Onderwerp'
                            as='input'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'trackTraceCode']}
                            value={user.settings.parcels.shippedEmailSubject}
                            onChange={this.onChangeValue.bind(this, 'parcels.shippedEmailSubject')}
                        />

                        <Editor
                            label='Bericht'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'trackTraceCode', 'trackTraceLink', user.settings.parcels.returnPortalActive && 'retourlink']}
                            value={user.settings.parcels.shippedEmailBody}
                            onChange={this.onChangeValue.bind(this, 'parcels.shippedEmailBody')}
                        />
                    </>
                    }

                    {view === 'cancelEmail' &&
                        <>
                            <Toggle
                                label='Verstuur "Geannuleerd" email'
                                checked={user.settings.parcels.sendCancelEmail}
                                onChange={this.onChangeChecked.bind(this, 'parcels.sendCancelEmail')}
                            />

                            <Input
                                label='Afzender naam'
                                placeholder={user.name}
                                value={user.settings.parcels.cancelEmailSenderName}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailSenderName')}
                            />

                            <Input
                                label='Afzender emailadres'
                                placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                                value={user.settings.parcels.cancelEmailReplyTo}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailReplyTo')}
                            />

                            <br/>
                            <Editor
                                label='Onderwerp'
                                as='input'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                                value={user.settings.parcels.cancelEmailSubject}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailSubject')}
                            />

                            <Editor
                                label='Bericht'
                                tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                                value={user.settings.parcels.cancelEmailBody}
                                onChange={this.onChangeValue.bind(this, 'parcels.cancelEmailBody')}
                            />
                        </>
                    }

                    {view === 'retourConfirmation' &&
                    <>
                        <Toggle
                            label='Verstuur "Retourbevestiging" email'
                            checked={user.settings.parcels.sendRetourConfirmationEmail}
                            onChange={this.onChangeChecked.bind(this, 'parcels.sendRetourConfirmationEmail')}
                        />

                        <Input
                            label='Afzender naam'
                            placeholder={user.name}
                            value={user.settings.parcels.retourConfirmationEmailSenderName}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailSenderName')}
                        />

                        <Input
                            label='Afzender emailadres'
                            placeholder={(user.settings.useOwnSMTP && user.settings.smtpUser) || user.email || user.loginEmail}
                            value={user.settings.parcels.retourConfirmationEmailReply}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailReply')}
                        />

                        <br/>
                        <Editor
                            label='Onderwerp'
                            as='input'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                            value={user.settings.parcels.retourConfirmationEmailSubject}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailSubject')}
                        />

                        <Editor
                            label='Bericht'
                            tags={['bedrijfsnaam', 'naam', 'referentie', 'vervoerder', 'barcode']}
                            value={user.settings.parcels.retourConfirmationEmailBody}
                            onChange={this.onChangeValue.bind(this, 'parcels.retourConfirmationEmailBody')}
                        />
                    </>
                    }
                </Panel>

                {(view === 'confirmed' || view === 'shipped') &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='trackTrace'
                            html={trackTraceEmailTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: (view === 'confirmed' ? user.settings.parcels.confirmedEmailBody : view === 'shipped' ? user.settings.parcels.shippedEmailBody : '')?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerAddress: {
                                        name: user.name
                                    },
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                },
                                addressHeader: 'Bezorgadres',
                                address: {
                                    name: 'John Doe',
                                    attention: '',
                                    street: 'Kastanjelaan',
                                    nr: '400',
                                    addition: '',
                                    street2: '',
                                    postalCode: '5616 LZ',
                                    city: 'Eindhoven',
                                    country: 'NL'
                                }
                            })}
                            action={view}
                        />
                    </div>
                }

                {view === 'cancelEmail' &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='cancelEmail'
                            html={cancelEmailTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: user.settings.parcels.cancelEmailBody?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerAddress: {
                                        name: user.name
                                    },
                                    address: {
                                        name: 'John Doe',
                                        attention: '',
                                        street: 'Kastanjelaan',
                                        nr: '400',
                                        addition: '',
                                        street2: '',
                                        postalCode: '5616 LZ',
                                        city: 'Eindhoven',
                                        country: 'NL'
                                    },
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                }

                            })}
                        />
                    </div>
                }

                {view === 'retourConfirmation' &&
                    <div style={{width: 650, marginLeft: 60}}>
                        <EmailPreview
                            type='retourConfirmation'
                            html={retourConfirmationTemplate({
                                logo: user.settings.logo || '/images/logoPlaceholder.png',
                                message: user.settings.parcels.retourConfirmationEmailBody?.replace(/\n/g, '<br/>') || '',
                                color: user.settings.color || Colors.brandColor,
                                parcel: {
                                    customerAddress: user.settings.parcels.customReturnAddress ?
                                        user.settings.parcels.returnAddress :
                                        user.address,
                                    trackTrace: 'VEL73BF282DF2',
                                    reference: '831012'
                                }
                            })}
                        />
                    </div>
                }

            </div>
        )
    }
}

export default (CommunicationSettings)

import React from 'react'

import {Colors} from '../UI/index.js'


export default class Tab extends React.Component {
    constructor(props) {
        super(props)
        this.state = {focused: false}
        this.onPressEnter = this.onPressEnter.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onPressEnter)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressEnter)
    }

    onPressEnter(event) {
        const {focused} = this.state

        if (focused && event.keyCode === 13) {
            this.onClick(event)
        }
    }

    onClick(event) {
        const {value, onClick} = this.props
        event.target.value = value
        typeof onClick === 'function' && onClick(event)
    }

    render() {
        const {style = {}, size, children, active} = this.props

        const defaultStyle = {
            width: '100%',
            height: '100%',
            fontSize: typeof children === 'string' ? (size === 'sm' ? 12 : 14) : 20,
            fontWeight: 500,
            letterSpacing: '.05rem',
            background: this.state.hover || this.state.focused ? Colors.grey20 : 'transparent',
            color: active ? Colors.buttonSolid : Colors.textDark,
            cursor: 'pointer',
            outline: 'none',
            ...style
        }

        const borderStyle = {
            width: '100%',
            height: 2,
            background: active ? Colors.buttonSolid : Colors.grey20,
            transition: 'all ease 0.2s'
        }

        return (
            <div
                tabIndex={0}
                style={defaultStyle}
                onFocus={() => this.setState({focused: true})}
                onBlur={() => this.setState({focused: false})}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
                onClick={this.onClick.bind(this)}
            >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', paddingLeft: size === 'sm' ? 10 : 25, paddingRight: size === 'sm' ? 10 : 25}} >
                    {children}
                </div>

                <div style={borderStyle}/>
            </div>
        )
    }
}

import React from 'react'
import S1 from './fonts/S1.js'
import IconButton from './IconButton.js'


export default class DividerHeader extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            show: !this.props.defaultHidden,
            hover: false
        }
    }

    render() {
        const {show, hover} = this.state
        const {title, style={}, children, edit, onClickEdit, onClickSave} = this.props

        const defaultStyle = {
            width: '100%',
            height: 'fit-content',
            marginBottom: 18,
            ...style
        }

        return (
            <div
                style={defaultStyle}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}
            >
                <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: 6, height: 36}}>

                    <S1 onClick={() => this.setState({show: !show})}>{title}</S1>

                    <i onClick={() => this.setState({show: !show})} style={{marginLeft: 6}} className={show ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>

                    <div style={{flex: 1}}/>

                    {hover && show && !edit && onClickEdit &&
                        <IconButton onClick={onClickEdit}>
                            <i className='mdi mdi-pencil'/>
                        </IconButton>
                    }

                    {show && edit && onClickSave &&
                        <IconButton onClick={onClickSave}>
                            <i className='mdi mdi-check'/>
                        </IconButton>
                    }
                </div>

                <div style={{
                    width: '100%',
                    height: show ? 'fit-content' : 0,
                    overflow: show ? 'visible' : 'hidden'
                }}
                >
                    {children}
                </div>
            </div>
        )
    }
}

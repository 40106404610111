import React from 'react'

import {Divider, H3, H6, Toggle, Input, Select, Button, Alert} from '../../../../components/UI/index.js'
import LoginActions from '../../../../actions/LoginActions.js'

class ShopwareSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {error: '', verified: false, loading: false}
    }

    onChangeChecked(key, event) {
        const {user, onChange} = this.props

        user.settings.webshops.shopware[key] = event.target.checked

        onChange(user)
    }

    onChangeValue(key, event) {
        const {user, onChange} = this.props

        user.settings.webshops.shopware[key] = event.target.value

        onChange(user)
    }

    onVerifyConnection() {
        const {user, onChange} = this.props

        this.setState({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('shopware', (err) => {
                if (err) {
                    this.setState({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    this.setState({loading: false, error: '', verified: true})
                }
            })
        }, 1000)
    }

    render() {
        const {user} = this.props
        const {error, verified, loading} = this.state

        return (
            <div>
                <H3>Shopware</H3>

                <H6>Instellingen</H6>
                <Divider/>

                <Toggle
                    label='Actief'
                    checked={user.settings.webshops.shopware.active}
                    onChange={this.onChangeChecked.bind(this, 'active')}
                />

                {user.settings.webshops.shopware.active &&
                <>
                    <Input
                        label='Webshop URL'
                        value={user.settings.webshops.shopware.url}
                        onChange={this.onChangeValue.bind(this, 'url')}
                    />

                    <Input
                        label='Access Key ID'
                        value={user.settings.webshops.shopware.clientId}
                        onChange={this.onChangeValue.bind(this, 'clientId')}
                    />

                    <Input
                        label='Secret Access Key'
                        value={user.settings.webshops.shopware.clientSecret}
                        onChange={this.onChangeValue.bind(this, 'clientSecret')}
                    />

                    <Select
                        label='Additional address field 1'
                        info='Additional address field 1 in Shopware wordt eventueel gebruikt voor Huisnummer, Toevoeging, Huisnummer + toevoeging of 2e adres regel'
                        value={user.settings.webshops.shopware.additionalAddressField1}
                        onChange={this.onChangeValue.bind(this, 'additionalAddressField1')}
                    >
                        <option value=''>N.v.t.</option>
                        <option value='nr'>Huisnummer</option>
                        <option value='addition'>Toevoeging</option>
                        <option value='nrAndAddition'>Huisnummer + Toevoeging</option>
                        <option value='street2'>2e adres regel</option>
                    </Select>

                    <Select
                        label='Additional address field 2'
                        info='Additional address field 2 in Shopware wordt eventueel gebruikt voor Huisnummer, Toevoeging, Huisnummer + toevoeging of 2e adres regel'
                        value={user.settings.webshops.shopware.additionalAddressField2}
                        onChange={this.onChangeValue.bind(this, 'additionalAddressField2')}
                    >
                        <option value=''>N.v.t.</option>
                        <option value='nr'>Huisnummer</option>
                        <option value='addition'>Toevoeging</option>
                        <option value='nrAndAddition'>Huisnummer + Toevoeging</option>
                        <option value='street2'>2e adres regel</option>
                    </Select>

                    <Input
                        type='number'
                        min={0}
                        max={180}
                        label='Aantal dagen terugkijken voor ophalen bestellingen'
                        placeholder={7}
                        value={user.settings.webshops.shopware.nrOfDays}
                        onChange={this.onChangeValue.bind(this, 'nrOfDays')}
                    />

                    <Select
                        label='Update order status in Shopware'
                        info=''
                        value={user.settings.webshops.shopware.updateStatus}
                        onChange={this.onChangeValue.bind(this, 'updateStatus')}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Toggle
                        label='Haal alleen betaalde zendingen op'
                        checked={user.settings.webshops.shopware.ignoreNotPaidOrders}
                        onChange={this.onChangeChecked.bind(this, 'ignoreNotPaidOrders')}
                    />

                    <Button
                        variant='text'
                        style={{marginBottom: 15, marginLeft: 0}}
                        loading={loading}
                        onClick={this.onVerifyConnection.bind(this)}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => this.setState({error: ''})} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => this.setState({verified: false})}>Verificatie succesvol.</Alert>
                    )}

                </>
                }
            </div>
        )
    }
}

export default ShopwareSettings

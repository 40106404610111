import React from 'react'

import Reflux from 'reflux-react-16'

import {Button, IconButton, Divider, Popup, H3, H6, P} from '../../../../components/UI/index.js'
import {Input, Panel} from '../../../../components/UI/index.js'


class APISettings extends Reflux.Component {
    onClickGenerateApiKey() {
        const {user, onChange} = this.props

        if (!user.settings.apiKey) {
            user.settings.apiKey = generateUUID()
            onChange(user)
        } else {
            this.popup.open('Genereer nieuwe API key', 'De oude API key is hierna niet meer geldig, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                user.settings.apiKey = generateUUID()
                onChange(user)
            })
        }
    }

    onClickRemoveApiKey() {
        const {user, onChange} = this.props

        if (user.settings.apiKey) {
            this.popup.open('Verwijder API key', 'De API key wordt verwijderd, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                user.settings.apiKey = ''
                user.settings.webhookURL = ''
                user.settings.parcelWebhookURL = ''
                onChange(user)
            })
        }
    }

    onClickGenerateHmacSecret() {
        const {user, onChange} = this.props

        if (!user.settings.hmacSecret) {
            user.settings.hmacSecret = generateUUID()
            onChange(user)
        } else {
            this.popup.open('Genereer nieuwe HMAC key', 'De oude HMAC key is hierna niet meer geldig, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                user.settings.hmacSecret = generateUUID()
                onChange(user)
            })
        }
    }

    onClickRemoveHmacSecret() {
        const {user, onChange} = this.props

        if (user.settings.hmacSecret) {
            this.popup.open('Verwijder HMAC key', 'De HMAC key wordt verwijderd, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                user.settings.hmacSecret = ''
                onChange(user)
            })
        }
    }

    onChangeSettingsValue(key, event) {
        const {user, onChange} = this.props

        if (key === 'webhookURL') {
            event.target.value = event.target.value.toLowerCase()
        }

        user.settings[key] = event.target.value
        onChange(user)
    }

    render() {
        const {user} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>

                <H3>API</H3>

                <H6>API Key</H6>
                <Divider/>
                <P>Om gebruik te maken van de API of een van onze webshopkoppelingen is een API key vereist, deze kun je hieronder genereren.</P>
                <br/>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Input
                        style={{flex: 1, marginRight: 15}}
                        label='API Key'
                        readOnly
                        value={user.settings.apiKey || ''}
                    />

                    {!user.settings.apiKey &&
                        <Button
                            variant='outline'
                            icon='mdi mdi-plus'
                            label='Nieuwe API key'
                            onClick={this.onClickGenerateApiKey.bind(this)}
                        />
                    }

                    {user.settings.apiKey &&
                        <IconButton onClick={this.onClickGenerateApiKey.bind(this)}>
                            <i className='mdi mdi-refresh'/>
                        </IconButton>
                    }

                    {user.settings.apiKey &&
                        <IconButton onClick={this.onClickRemoveApiKey.bind(this)}>
                            <i className='mdi mdi-delete'/>
                        </IconButton>
                    }
                </div>

                {user.settings.apiKey &&
                <>
                    <Input
                        style={{flex: 1}}
                        label='Webhook URL ritten'
                        value={user.settings.webhookURL || ''}
                        onChange={this.onChangeSettingsValue.bind(this, 'webhookURL')}
                    />

                    <Input
                        style={{flex: 1}}
                        label='Webhook URL zendingen'
                        value={user.settings.parcelWebhookURL || ''}
                        onChange={this.onChangeSettingsValue.bind(this, 'parcelWebhookURL')}
                    />

                    {(user.settings.webhookURL || user.settings.parcelWebhookURL) &&
                    <>
                        <Input
                            style={{flex: 1}}
                            label='Webhook Authorization header'
                            value={user.settings.webhookAuthHeader || ''}
                            onChange={this.onChangeSettingsValue.bind(this, 'webhookAuthHeader')}
                        />

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Input
                                style={{flex: 1, marginRight: 15}}
                                label='HMAC key'
                                readOnly
                                value={user.settings.hmacSecret || ''}
                            />

                            {!user.settings.hmacSecret &&
                                <Button
                                    variant='outline'
                                    icon='mdi mdi-plus'
                                    label='Nieuwe HMAC key'
                                    onClick={this.onClickGenerateHmacSecret.bind(this)}
                                />
                            }

                            {user.settings.hmacSecret &&
                                <IconButton onClick={this.onClickGenerateHmacSecret.bind(this)}>
                                    <i className='mdi mdi-refresh'/>
                                </IconButton>
                            }

                            {user.settings.hmacSecret &&
                                <IconButton onClick={this.onClickRemoveHmacSecret.bind(this)}>
                                    <i className='mdi mdi-delete'/>
                                </IconButton>
                            }
                        </div>
                    </>
                    }
                </>
                }

                <br/>
                <br/>
                <H6>API documentatie</H6>
                <Divider/>
                <P>Klik <a href='https://app.veloyd.nl/apidoc' target="_blank" rel='noopener noreferrer'>hier</a> voor de API documentatie.</P>

                <Popup ref={(ref) => this.popup = ref}/>
            </Panel>
        )
    }
}

function generateUUID() {
    let d = new Date().getTime()

    if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now()
    }

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random()*16)%16 | 0
        d = Math.floor(d/16)
        return (c=='x' ? r : (r&0x3|0x8)).toString(16)
    })

    return uuid
}

export default (APISettings)

import React from 'react'

import {Alert, Button, Modal, P, VerificationCodeInput} from './index.js'

class MfaPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            show: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(onLogin, mfaMethod) {
        this.setState({show: true, onLogin, mfaMethod})
    }

    close() {
        this.setState(this.initialState)
    }

    setError(error) {
        this.setState({error})
    }

    render() {
        const {show, onLogin, mfaMethod, code, error} = this.state
        const {confirmButtonText} = this.props

        return (
            <Modal
                title='Tweestapsverificatie'
                show={show}
                onClose={this.close.bind(this)}
                style={{width: 400}}
                closeButton
            >
                <form onSubmit={(event) => {
                    event.preventDefault()
                    this.setState({error: ''})
                    typeof onLogin === 'function' && onLogin(code)
                }}
                >
                    <P style={{marginBottom: 24}}>
                        Vul de tweestapsverificatiecode in verkregen via {mfaMethod === 'app' && 'je authenticator app'}{mfaMethod === 'email' && 'via e-mail'}.
                    </P>

                    <VerificationCodeInput
                        onChange={(codeArray) => {
                            this.setState({code: codeArray.join('')})
                        }}
                    />

                    {error && <Alert variant='error'>{error}</Alert>}

                    <Button
                        type='submit'
                        style={{marginLeft: 'auto', marginTop: 24}}
                    >
                        {confirmButtonText || 'Log in'}
                    </Button>
                </form>
            </Modal>
        )
    }
}

export default MfaPopup

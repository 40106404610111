import React from 'react'

import {S2, Colors, P} from './index.js'
import ErrorHandler from './ErrorHandler.js'

class Alert extends React.Component {
    render() {
        const {variant, children, onClose, err, customMessage, style={}} = this.props

        let icon = 'mdi mdi-check-circle-outline'
        let background = Colors.successPale

        if (variant === 'transparent') {
            background = 'transparent'
            icon = 'mdi mdi-information-outline'
        }

        if (variant === 'info') {
            background = Colors.infoPale
            icon = 'mdi mdi-information-outline'
        }

        if (variant === 'warning') {
            background = Colors.warningPale
            icon = 'mdi mdi-alert'
        }

        if (variant === 'danger' || variant === 'error' || variant === 'errorHandler') {
            background = Colors.errorPale
            icon = 'mdi mdi-alert'
        }

        if (this.props.icon) {
            icon = this.props.icon
        }

        const defaultStyle = {
            position: 'relative',
            display: 'flex',
            flexShrink: 0,
            width: '100%',
            minHeight: 36,
            marginBottom: 6,
            padding: '6px 10px 6px 10px',
            background,
            borderRadius: 4,
            color: Colors.textDark,
            wordBreak: 'break-word',
            ...style
        }

        if (variant === 'errorHandler') {
            return (
                <P style={defaultStyle}>
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <i style={{marginRight: 12, fontSize: 18}} className={icon}/>

                            <div style={{flex: 1}}>
                                <P >Onderstaande fout is opgetreden:</P>
                                <P>{err?.message}</P>
                            </div>
                        </div>

                        <div style={{display: 'flex'}}>
                            <S2 onClick={() => this.errorHandler.open(err, customMessage)} style={{fontWeight: 'bold', marginLeft: 30, marginTop: 15, cursor: 'pointer'}}>Meld deze fout</S2>
                            <ErrorHandler ref={(ref) => this.errorHandler = ref} open={true}/>
                        </div>
                    </div>
                </P>
            )
        } else {
            return (
                <div style={defaultStyle}>
                    <i style={{marginRight: 12, fontSize: 18}} className={icon}/>

                    <div style={{flex: 1, display: style?.display}}>
                        {typeof children === 'string' ? children.split(/\n/).map((child, index) => {
                            return <P key={index}>{child}</P>
                        }) :
                            children
                        }
                    </div>

                    {onClose &&
                        <i style={{cursor: 'pointer', marginLeft: 12, fontSize: 18}} className='mdi mdi-close' onClick={onClose}/>
                    }
                </div>
            )
        }
    }
}

export default Alert

import React from 'react'

import _ from 'underscore'

import {H4, IconButton, Modal, Row, CarrierLogo, S1, S2, Input, Button, DraggableList, SaveBeforeExitPopup, P, Colors} from '../../UI/index.js'

import PreferenceModal from '../../modals/PreferenceModal.js'

export default class ParcelReferenceModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            show: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(preferences) {
        this.setState({show: true, preferences})
    }

    close(showWarning) {
        if (showWarning) {
            this.saveBeforeExitPopup.open(this.onSubmit.bind(this), () => {
                this.setState(this.initialState)
            })
        } else {
            this.setState(this.initialState)
        }
    }

    onClickAddPreference(productName) {
        const {preferences} = this.state

        const preference = {name: '', countries: []}
        preferences[productName] = preferences[productName] || []
        preferences[productName].push(preference)

        this.setState({preferences})

        this.onClickOpenPreferenceModal(productName, preferences[productName].length - 1, preference)
    }

    onClickOpenPreferenceModal(productName, index, preference) {
        const {preferences} = this.state
        const {products} = this.props

        let countries = products[productName].countries

        if (countries.length > 1) {
            countries.push('Overige')
        }

        const usedCountries = []
        preferences[productName].map((preference) => {
            usedCountries.push(...preference.countries)
        })

        countries = _.uniq(_.difference(countries, usedCountries))
        countries.push(...preference.countries)

        this.PreferenceModal.open(productName, index, preference, countries)
    }

    onEditPreference(productName, index, preference) {
        const {preferences} = this.state

        preferences[productName][index] = preference

        this.setState({preferences})
    }

    onRemovePreference(productName, index) {
        const {preferences} = this.state

        preferences[productName].splice(index, 1)

        this.setState({preferences})
    }

    onChangePreference(productName, index, carrierName, event) {
        const {preferences} = this.state
        const preference = event.target.value.toUpperCase()

        if (!preference || /^[0-9 X]$/.test(preference)) {
            preferences[productName][index][carrierName] = preference

            this.setState({preferences})
        }
    }

    onSortPreferences(productName, productPreferences) {
        const {preferences} = this.state

        preferences[productName] = productPreferences

        this.setState({preferences})
    }

    onSubmit() {
        const {preferences} = this.state
        const {onChange, reseller} = this.props

        reseller.settings.parcels.preferences = preferences

        onChange(reseller, 'parcels.preferences')
        this.close()
    }

    render() {
        const {show} = this.state
        const {enabledCarriers, carriers, products, reseller} = this.props

        return (
            <Modal style={{width: 'fit-content', maxWidth: '95%', height: 'fit-content', maxHeight: '95%', overflowY: 'auto'}} show={show} onClose={this.close.bind(this, true)}>
                <Row style={{justifyContent: 'space-between'}}>
                    <H4>Wijzig voorkeuren</H4>

                    <IconButton
                        icon='mdi mdi-close'
                        onClick={this.close.bind(this, true)}
                    />
                </Row>


                <div style={{maxHeight: '80%', overflowY: 'auto', marginBottom: 24}}>
                    <div style={{display: 'flex', marginLeft: 300, position: 'sticky', top: 0, zIndex: 10, backgroundColor: Colors.backgroundNeutral, width: 'fit-content'}}>
                        {enabledCarriers.map((carrier) => {
                            return (
                                <div
                                    key={`header${carrier.name}`} style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginRight: 12,
                                        minWidth: 90,
                                        width: 90
                                    }}
                                >
                                    <CarrierLogo
                                        size={45}
                                        carriers={carriers}
                                        value={carrier.name}
                                    />
                                    <P>{carrier.displayName || carrier.name}</P>
                                </div>
                            )
                        })}
                    </div>

                    {_.keys(products).map((productName) => {
                        if (products[productName].carriers.length > 0) {
                            return (
                                <div key={productName} style={{marginBottom: 12}}>
                                    <S1 style={{marginBottom: 12}}>{productName}</S1>

                                    {reseller.settings.parcels.preferences[productName] &&
                                    <DraggableList
                                        items={reseller.settings.parcels.preferences[productName]}
                                        renderItem={(preference, DragHandle, index) => {
                                            return (
                                                <div key={`${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: 285,
                                                        minWidth: 285,
                                                        height: 30
                                                    }}
                                                    >
                                                        <DragHandle/>
                                                        <IconButton onClick={this.onClickOpenPreferenceModal.bind(this, productName, index, preference)}>
                                                            <i className='mdi mdi-pencil'/>
                                                        </IconButton>
                                                        <S2>{preference.name}</S2>
                                                    </div>

                                                    {enabledCarriers.map((carrier) => {
                                                        const carrierHasProduct = products[productName].carriers.indexOf(carrier.name) > -1
                                                        const carrierHasCountry = carrierHasProduct && (preference.countries.indexOf('Overige') > -1 || _.intersection(carrier.combinedProducts[productName], preference.countries).length > 0)

                                                        return (
                                                            <Input
                                                                key={`carriers${carrier.name}`} style={{
                                                                    marginLeft: 0,
                                                                    marginRight: 12,
                                                                    width: 90
                                                                }}
                                                                disabled={!carrierHasCountry}
                                                                value={preference[carrier.name] || ''}
                                                                onChange={this.onChangePreference.bind(this, productName, index, carrier.name)}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        }}
                                        onChange={this.onSortPreferences.bind(this, productName)}
                                    />
                                    }

                                    <Button
                                        variant='outline-white'
                                        style={{marginBottom: 24, width: 'fit-content'}}
                                        icon='mdi mdi-plus'
                                        label='Nieuwe landengroep'
                                        onClick={this.onClickAddPreference.bind(this, productName)}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='text' onClick={this.close.bind(this, true)}>Annuleer</Button>
                    <Button variant='text' onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>

                <PreferenceModal
                    onChange={this.onEditPreference.bind(this)}
                    remove={this.onRemovePreference.bind(this)}
                    ref={(ref) => this.PreferenceModal = ref}
                />

                <SaveBeforeExitPopup
                    ref={(ref) => this.saveBeforeExitPopup = ref}
                />
            </Modal>
        )
    }
}

import React from 'react'

import {Colors, IconButton, Popover, ListItem, S1, Info} from '../UI/index.js'
import {Check, Toggle, Select} from '../UI/index.js'

export default class Header extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    renderHeader() {
        const {columns, setSort, sortColumnIndex, sortDecending, tableName, onOptionChange, selectedRows} = this.props

        return columns.map((column, index) => {
            if (column.visible && !column.filterOnly) {
                const style = {cursor: 'pointer', color: Colors.textDark}

                let parsedTableObj

                if (column.dropdown && localStorage[`_${tableName}`]) {
                    parsedTableObj = JSON.parse(localStorage[`_${tableName}`])
                }

                style.overflow = 'hidden',
                style.whiteSpace = 'nowrap',
                style.textOverflow = 'ellipsis'

                const wrapperStyle = {display: 'flex', alignItems: 'center', marginLeft: 12, marginRight: 12, minWidth: 25}

                if (column.width) {
                    wrapperStyle.width = column.width
                }

                if (column.flex) {
                    wrapperStyle.flex = column.flex
                }

                return (
                    <div key={`header${index}`} style={wrapperStyle}>
                        <S1 style={style} onClick={() => column.title ? setSort(index) : null}>

                            {column.title || ''}

                            {sortColumnIndex === index &&
                                (sortDecending ?
                                    <i style={{marginLeft: 3}} className='mdi mdi-sort-descending'/> :
                                    <i style={{marginLeft: 3}} className='mdi mdi-sort-ascending'/>
                                )
                            }
                        </S1>

                        {column.info && <Info placement={index > (columns.length - 4) ? 'left' : undefined} iconColor={Colors.textDark} text={column.info}/>}

                        {column.dropdown && sortColumnIndex === index &&
                            <Popover
                                ref={(ref) => this.popover = ref}
                                content={
                                    <div style={{padding: 20, width: 300, maxHeight: 700, overflowX: 'auto'}}>
                                        {column.dropdown.options.map((option, optionIndex) => (
                                            <ListItem
                                                key={optionIndex} onClick={() => {
                                                    this.popover.close()
                                                    this.setState({
                                                        [column.dropdown.localStorageKey]: option.value
                                                    })

                                                    if (JSON.parse(localStorage[`_${tableName}`])[column.dropdown.localStorageKey] !== option.value) {
                                                        onOptionChange(selectedRows) // Trigger re-render
                                                    }

                                                    column.dropdown.onClick(option.value)
                                                }}
                                                active={this.state[column.dropdown.localStorageKey] === option.value || parsedTableObj?.[column.dropdown.localStorageKey] === option.value}
                                            >
                                                {option.label}
                                            </ListItem>

                                        ))}
                                    </div>
                                }
                            >
                                <IconButton style={{position: 'absolute', top: -18, right: -34}}>
                                    <i className='mdi mdi-chevron-down'/>
                                </IconButton>
                            </Popover>
                        }
                    </div>
                )
            }
        })
    }

    renderSettings() {
        const {pageSize, setPageSize, columns, headerToggle} = this.props

        return (
            <Popover
                noClose={true}
                placement='left'
                content={
                    <div style={{padding: 20, width: 300, maxHeight: 700, overflowX: 'auto'}}>
                        {!this.props.noPagination &&
                            <Select label='Aantal per pagina' value={pageSize} onChange={setPageSize}>
                                <option value={10}>10</option>
                                <option value={11}>11</option>
                                <option value={12}>12</option>
                                <option value={13}>13</option>
                                <option value={14}>14</option>
                                <option value={15}>15</option>
                                <option value={16}>16</option>
                                <option value={17}>17</option>
                                <option value={18}>18</option>
                                <option value={20}>20</option>
                                <option value={19}>19</option>
                                <option value={25}>25</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </Select>
                        }

                        {columns.map((column, index) => {
                            if (column.title && !column.filterOnly) {
                                return (
                                    <Toggle
                                        style={{marginBottom: 0}}
                                        key={index}
                                        label={column.title}
                                        checked={column.visible}
                                        onChange={headerToggle.bind(this, column.title)}
                                    />
                                )
                            }
                        })}
                    </div>
                }
            >
                <IconButton style={{position: 'absolute', top: -18, right: 20}}>
                    <i className='mdi mdi-dots-vertical'/>
                </IconButton>
            </Popover>
        )
    }

    render() {
        const {selectAllRows, allRowsSelected, selectedRows, addPageToSelection, onOptionChange} = this.props

        const isIndeterminate = selectedRows.length > 0

        const style = {
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
            height: 36,
            background: Colors.backgroundWhite,
            borderRadius: '4px 4px 0px 0px',
            orderBottom: 'solid 1px',
            borderColor: Colors.grey40,
            userSelect: 'none'
        }

        return (
            <div style={style}>
                {!!onOptionChange &&
                    <>
                        <Check
                            checked={allRowsSelected}
                            indeterminate={isIndeterminate}
                            onChange={selectAllRows}
                        />

                        <div style={{width: 20}}/>

                        <Popover
                            placement='right'
                            ref={(ref) => this.popover = ref}
                            content={
                                <div style={{width: 240, maxHeight: 700, overflowX: 'auto'}}>
                                    <ListItem onClick={() => {
                                        this.popover.close()
                                        addPageToSelection()
                                    }}
                                    >
                                        Voeg pagina toe aan selectie
                                    </ListItem>
                                </div>
                            }
                        >
                            <IconButton style={{position: 'absolute', top: -18, left: -28}}>
                                <i className='mdi mdi-chevron-down'/>
                            </IconButton>
                        </Popover>
                    </>
                }

                {this.renderHeader()}

                {this.renderSettings()}
            </div>
        )
    }
}


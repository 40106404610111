export default () => {
    return JSON.parse(JSON.stringify({
        name: '',
        firstName: '',
        lastName: '',
        address: {
            name: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL'
        },
        email: '',
        phone: '',
        settings: {
        },
        permissions: {
            orders: false,
            parcels: false,
            shifts: false,
            subscriptions: false,
            customers: false,
            invoices: false,
            users: false,
            paychecks: false,
            settings: false,
            vehicles: false
        },
        readNotifications: []
    }))
}

import io from 'socket.io-client'
import Colors from '../components/UI/Colors.js'
import guid from '../../utils/guid.js'

const options = {
    transports: ['websocket']
}

const socket = io(options)

connect()

socket.on('joined', () => {
    console.log('connected')
})

socket.on('disconnect', () => {
    console.log('disconnected')
    connect()
})

socket.on('orderNotification', showNotification)

let notificationCount = 0

const favIcon = document.querySelector('link[rel="icon"]')
const favIconSrc = favIcon ? favIcon.href : null

const updateFavicon = () => {
    if (!favIconSrc) return

    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
    img.src = favIconSrc

    img.onload = () => {
        const size = 32
        canvas.width = size
        canvas.height = size
        const ctx = canvas.getContext('2d')

        ctx.drawImage(img, 0, 0, size, size)

        if (notificationCount > 0) {
            const badgeSize = size / 2.5
            ctx.fillStyle = '#FF0000'
            ctx.beginPath()
            ctx.arc(size - badgeSize, badgeSize, badgeSize, 0, Math.PI * 2)
            ctx.fill()

            ctx.fillStyle = Colors.white
            ctx.font = `bold ${18}px Arial`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillText(notificationCount.toString(), size - badgeSize, badgeSize)
        }

        favIcon.href = canvas.toDataURL('image/png')
    }
}

window.addEventListener('focus', () => {
    notificationCount = 0
    updateFavicon()
})

export async function showNotification({orderId, customerName}) {
    if (!('Notification' in window)) {
        return
    }

    try {
        const permission = await Notification.requestPermission()
        if (permission === 'granted') {
            const options = {
                icon: '/favicon-192x192.png',
                requireInteraction: true
            }

            if (orderId) {
                options.tag = orderId
                options.renotify = true
            }

            const notification = new Notification(`Nieuwe rit: ${customerName}`, options)

            notificationCount++
            updateFavicon()

            window.id = guid.generate()

            const windowId = window.id

            notification.addEventListener('click', () => {
                notification.close()
                notificationCount--
                updateFavicon()
                if (window.id === windowId) {
                    window.focus()
                    if (orderId) {
                        window.location.replace(`/planning/${orderId}`)
                    }
                }
            })
        }
    } catch (error) {
        console.error('Notification error:', error)
    }
}

function connect() {
    if (sessionStorage.token) {
        console.log('connecting...')
        socket.emit('join', sessionStorage.token, (err) => {
            if (err && err.name === 'TokenExpiredError') {
                sessionStorage.removeItem('token')
                window.location = '/login'
            }
        })
    }
}

export default socket

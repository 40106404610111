import React from 'react'


import ShiftActions from '../../actions/ShiftActions.js'

import {Input, Select, TextArea, CurrencyInput, FloatInput, DatePicker, TimePicker, Recurrence, Toggle, Check, Row, Column} from '../UI/index.js'
import {Alert, Button, Colors, H4, S2, P, IconButton, Modal, Popup, Prompt, ScrollView} from '../UI/index.js'
import NewShift from './NewShift.js'

class ShiftModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            shift: NewShift(),
            orders: [],
            showOrders: false,
            loading: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(shift) {
        shift = shift ? structuredClone(shift) : NewShift()

        this.setState({modalIsOpen: true, shift})

        console.log(shift)

        ShiftActions.getShiftOrders(shift, (err, orders) => {
            if (!err) {
                this.setState({orders})
            }
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onSubmit(event) {
        event.preventDefault()
        const shift = this.state.shift

        if (shift._id) {
            if (shift.rrule) {
                this.prompt.open('Bewerk herhaalde dienst', '', [
                    {title: 'Alleen deze dienst', value: 'this', default: true},
                    {title: 'Deze en toekomstige diensten', value: 'editAll'}
                ], (choice) => {
                    this.setState({loading: true, error: ''})
                    ShiftActions.edit(shift, choice === 'editAll', this.response.bind(this))
                })
            } else {
                this.setState({loading: true, error: ''})
                ShiftActions.edit(shift, false, this.response.bind(this))
            }
        } else {
            this.setState({loading: true, error: ''})
            ShiftActions.create(shift, this.response.bind(this))
        }
    }

    onClickRemove(event) {
        const {shift} = this.state

        if (shift.rrule) {
            this.prompt.open('Verwijder herhaalde dienst', '', [
                {title: 'Alleen deze dienst', value: 'remove', default: true},
                {title: 'Deze en toekomstige diensten', value: 'removeAll'}
            ], (choice) => {
                this.setState({loading: true, error: ''})

                if (choice === 'removeAll') {
                    ShiftActions.removeTemplate(shift, this.response.bind(this))
                } else {
                    ShiftActions.remove([shift._id], this.response.bind(this))
                }
            })
        } else {
            this.popup.open('Verwijder dienst', 'Weet je zeker dat je deze dienst wilt verwijderen?', () => {
                this.setState({loading: true, error: ''})
                this.popup.close()
                ShiftActions.remove([shift._id], this.response.bind(this))
            })
        }
    }

    response(err) {
        if (err) {
            this.setState({loading: false, error: err})
        } else {
            this.close()
        }
    }

    onChangeDate(event) {
        const {shift} = this.state

        shift.date = event.target.value

        this.setState({shift, orders: []}, () => {
            const {shift} = this.state

            ShiftActions.getShiftOrders(shift, (err, orders) => {
                if (!err) {
                    this.setState({orders})
                }
            })
        })
    }

    onChangeValue(key, event) {
        const {shift} = this.state

        if (key.split('.').length === 2) {
            shift[key.split('.')[0]][key.split('.')[1]] = event.target.value
        } else if (key === 'rrule') {
            if (!event.target.value) {
                delete shift.rrule
            } else {
                shift.rrule = event.target.value
            }
        } else {
            shift[key] = event.target.value
        }

        this.setState({shift})
    }

    onChangeChecked(key, event) {
        const {shift} = this.state

        if (key.split('.').length === 2) {
            shift[key.split('.')[0]][key.split('.')[1]] = event.target.checked
        } else {
            shift[key] = event.target.checked
        }

        this.setState({shift})
    }

    render() {
        const {modalIsOpen, shift, orders, showOrders, error, loading} = this.state
        const {users} = this.props

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
                    <H4>{shift._id ? 'Bewerk dienst' : 'Nieuwe dienst' }</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <Row>
                        <Column>
                            <Select
                                style={{marginLeft: 12}}
                                label="Koerier"
                                allowEmptyValue
                                options={users.map((user) => {
                                    return {value: user._id, title: user.name}
                                })}
                                value={shift.messenger}
                                onChange={this.onChangeValue.bind(this, 'messenger')}
                            />
                        </Column>

                        <Column>
                            <DatePicker
                                label='Datum'
                                value={shift.date}
                                onChange={this.onChangeDate.bind(this)}
                            />

                            {!shift._id ?
                                <Check
                                    label='Herhalen'
                                    checked={!!shift.rrule}
                                    onChange={() => {
                                        const shift = {...this.state.shift}

                                        if (!shift.rrule) {
                                            shift.rrule = this.recurrence.init()
                                        } else {
                                            shift.rrule = ''
                                        }

                                        this.setState({shift})
                                    }}
                                /> :
                                <Recurrence
                                    disabled={true}
                                    date={shift.date}
                                    rrule={shift.rrule}
                                />
                            }
                        </Column>
                    </Row>

                    {!shift._id &&
                        <Row >
                            <Column/>
                            <Column style={{height: shift.rrule ? 100 : 0}}>
                                <Recurrence
                                    date={shift.date}
                                    rrule={shift.rrule}
                                    onChange={this.onChangeValue.bind(this, 'rrule')}
                                    ref={(ref) => this.recurrence = ref}
                                />
                            </Column>
                        </Row>
                    }


                    <Row style={{marginTop: 12}}>
                        <Column>
                            <Input
                                style={{marginRight: 6}}
                                label='Naam'
                                value={shift.name}
                                onChange={this.onChangeValue.bind(this, 'name')}
                            />
                        </Column>

                        <Column>
                            <TimePicker
                                style={{marginRight: 6}}
                                label='Aanvangstijd'
                                time={shift.startTime}
                                onChange={this.onChangeValue.bind(this, 'startTime')}
                            />

                            <TimePicker
                                style={{marginLeft: 6}}
                                label='Eindtijd'
                                time={shift.endTime}
                                onChange={this.onChangeValue.bind(this, 'endTime')}
                            />
                        </Column>
                    </Row>


                    <Row style={{marginTop: 12}}>
                        <Column>
                            <Select
                                style={{marginRight: 6}}
                                noPlaceholder
                                label='Salaris'
                                value={shift.salaryMethod}
                                onChange={this.onChangeValue.bind(this, 'salaryMethod')}
                            >
                                <option value={0}>Geen</option>
                                <option value={1}>Vast</option>
                                <option value={2}>Uurloon</option>
                                <option value={3}>Percentage</option>
                                <option value={4}>Percentage met minimum vast</option>
                                <option value={5}>Percentage met minimum uurloon</option>
                            </Select>
                        </Column>

                        <Column>
                            {(shift.salaryMethod == 1 || shift.salaryMethod == 4) &&
                                <CurrencyInput
                                    style={{marginRight: 12}}
                                    label='Vast loon'
                                    value={shift.salaryOptions.fixedWage}
                                    onChange={this.onChangeValue.bind(this, 'salaryOptions.fixedWage')}
                                />
                            }

                            {(shift.salaryMethod == 2 || shift.salaryMethod == 5) &&
                                <CurrencyInput
                                    style={{marginRight: 12}}
                                    label='Uurloon'
                                    value={shift.salaryOptions.hourlyWage}
                                    onChange={this.onChangeValue.bind(this, 'salaryOptions.hourlyWage')}
                                />
                            }

                            {(shift.salaryMethod == 3 || shift.salaryMethod == 4 || shift.salaryMethod == 5) &&
                                <FloatInput
                                    label='Percentage'
                                    append='%'
                                    value={shift.salaryOptions.percentage}
                                    onChange={this.onChangeValue.bind(this, 'salaryOptions.percentage')}
                                />
                            }
                        </Column>
                    </Row>

                    {shift.salaryMethod != 0 &&
                        <Row>
                            <Column>
                                <Toggle
                                    label='Onbelast'
                                    checked={shift.salaryOptions.taxFree}
                                    onChange={this.onChangeChecked.bind(this, 'salaryOptions.taxFree')}
                                />
                            </Column>
                            <Column/>
                        </Row>
                    }

                    <Row style={{marginTop: 12, marginBottom: 12}}>
                        <Column>
                            <TextArea
                                rows={3}
                                label='Opmerkingen'
                                value={shift.comment}
                                onChange={this.onChangeValue.bind(this, 'comment')}
                            />
                        </Column>
                    </Row>

                    {orders.length > 0 &&
                        <S2
                            style={{
                                width: 200,
                                color: Colors.buttonSolid,
                                cursor: 'pointer'
                            }}
                            onClick={() => this.setState({showOrders: !showOrders})}
                        >
                            {showOrders ? '- Verberg gekoppelde ritten' : '+ Toon gekoppelde ritten'}
                        </S2>
                    }

                    {showOrders &&
                        <div style={{maxHeight: 300, overflowY: 'auto'}}>
                            <table style={{width: '100%', textAlign: 'left'}}>
                                <thead>
                                    <tr>
                                        <th>Klant</th>
                                        <th>Ophaaladres</th>
                                        <th>Bezorgadres</th>
                                        <th>Koerier</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order) => {
                                        return (
                                            <tr key={order._id}>
                                                <td>{order.customerAddress?.name}</td>
                                                <td>{order.addresses[0].name}</td>
                                                <td>{order.addresses[order.addresses.length-1].name}</td>
                                                <td>{order.messenger}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }

                    {shift._id &&
                        <ScrollView style={{marginTop: 30, maxHeight: 250}}>
                            {[...shift.history].reverse().map((entry, index) => {
                                return (
                                    <div key={`history${index}`} style={{display: 'flex', alignItems: 'center', padding: 5}}>
                                        <div style={{height: 15, width: 15, borderRadius: '50%', marginRight: 15, background: Colors.grey20}}/>
                                        <div style={{flex: 1}}>
                                            <P>{entry.description || ''}</P>
                                        </div>

                                        <S2 style={{marginRight: 15}}>{`${entry.who} | ${entry.time}`}</S2>
                                    </div>
                                )
                            })}
                        </ScrollView>
                    }

                    <br/>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <br/>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {shift._id &&
                            <Button variant='error' loading={loading} onClick={this.onClickRemove.bind(this)}>
                                <i className='mdi mdi-delete'/>
                            </Button>
                        }

                        <Button loading={loading} type="submit">Opslaan</Button>
                    </div>
                </form>

                <Prompt ref={(ref) => this.prompt = ref}/>
                <Popup ref={(ref) => this.popup = ref}/>
            </Modal>
        )
    }
}

export default (ShiftModal)

import React from 'react'

import moment from 'moment'

import {Card, H6, S2, P} from '../UI/index.js'

class PaycheckFull extends React.Component {
    render() {
        const {paycheck} = this.props
        return (
            <Card>
                <H6>{paycheck.messenger}</H6>
                <br/>
                <br/>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <S2 style={{width: 120}}>Datum</S2>
                    <S2 style={{flex: 1}}>Dienst</S2>
                    <S2 style={{width: 120}}>Uren</S2>
                    <S2 style={{width: 120}}>Salaris</S2>
                    <S2 style={{width: 120}}>Onbelast</S2>
                </div>

                {paycheck.shifts.map((shift, index) => {
                    return (
                        <div key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 16, minWidth: 0}}>
                            <P style={{width: 120}}>{moment(shift.date).format('DD-MM-YYYY')}</P>
                            <P ellipsis style={{flex: 1}}>{shift.name}</P>
                            <P style={{width: 120}}>{shift.hours}</P>
                            <P style={{width: 120}}>{`€ ${shift.salary}`}</P>
                            <P style={{width: 120}}>{`€ ${shift.taxFree}`}</P>
                        </div>
                    )
                })}
            </Card>
        )
    }
}

export default (PaycheckFull)

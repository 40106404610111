import React from 'react'
import {Colors} from './index.js'

class RadioGroup extends React.Component {
    constructor(props) {
        super(props)
    }

    onClickOption(value, event) {
        event.preventDefault()
        const {onChange} = this.props
        onChange({target: {value}})
    }

    render() {
        const {options, value, style, disabled} = this.props

        const containerStyle = {
            display: 'flex',
            flex: 1,
            borderRadius: 4,
            background: Colors.grey30,
            padding: 3,
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 12,
            ...style
        }

        const defaultOptionStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            background: 'transparent',
            cursor: disabled ? 'default' : 'pointer',
            height: 36,
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: 14,
            border: 'none',
            borderRadius: 4,
            color: Colors.textMedium
        }

        const activeOptionStyle = {
            ...defaultOptionStyle,
            background: Colors.backgroundWhite,
            color: Colors.textDark
        }

        const disabledActiveOptionStyle = {
            ...defaultOptionStyle,
            background: Colors.grey40,
            color: Colors.textLight
        }

        return (
            <div style={containerStyle}>
                {options.map((option, index) => {
                    const style = {
                        ...defaultOptionStyle,
                        ...(option.value === value && !disabled) && activeOptionStyle,
                        ...(option.value === value && disabled) && disabledActiveOptionStyle
                    }

                    return (
                        <div
                            style={style}
                            key={index}
                            onClick={(event) => !disabled && this.onClickOption(option.value, event)}
                        >
                            {option.icon &&
                                <i className={option.icon} style={{fontSize: 20, marginRight: option.label ? 10 : 0}}/>
                            }

                            {option.label}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default RadioGroup

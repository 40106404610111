export default (address1, address2) => {
    return address1 && address2 &&
    address1.position?.lat === address2.position?.lat &&
            address1.position?.lng === address2.position?.lng &&
            address1.street === address2.street &&
            address1.nr === address2.nr &&
            address1.addition === address2.addition &&
            address1.postalCode === address2.postalCode &&
            address1.city === address2.city
}

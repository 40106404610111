import React from 'react'

import {Alert, IconButton, P, Select} from '../UI/index.js'
import _ from 'lodash'


export default class Info extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            customerId: '',
            contact: '',
            email: '',
            hover: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    componentDidMount() {
        this.setState({
            customerId: this.props.parcel.customer,
            contact: this.props.parcel.contact,
            email: this.props.parcel.email
        })
    }

    render() {
        const {customerId, hover, editCustomer, error} = this.state
        const {customers, parcel, onChangeCustomer, onSaveCustomer} = this.props


        const customerName = customers?.[parcel.customer] ? customers[parcel.customer].name : ''

        return (
            <div style={{width: '100%', padding: 0}}>
                <div
                    style={{display: 'flex'}}
                >
                    <div
                        style={{flex: 1, display: 'flex'}}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex'}}>
                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-store'/>
                                {editCustomer ?
                                    <Select
                                        openAfter={1}
                                        label='Klant'
                                        options={_.values(customers).map((customer) => ({value: customer._id, title: customer.name}))}
                                        value={customerId}
                                        onChange={(event) => {
                                            const customerId = event.target.value
                                            const customer = customers[customerId]
                                            typeof onChangeCustomer === 'function' && onChangeCustomer({customer})
                                            this.setState({customerId})
                                        }}
                                    /> :
                                    <P>{customerName}</P>}
                            </div>

                            {parcel.contact &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-account'/>
                                    <P>{parcel.contact}</P>
                                </div>
                            }

                            {parcel.email &&
                                <div style={{display: 'flex'}}>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-at'/>
                                    <P>{parcel.email}</P>
                                </div>
                            }

                        </div>

                        <div style={{width: 42, height: 36, marginTop: -6}}>
                            {editCustomer ?
                                <IconButton
                                    icon='mdi mdi-check'
                                    onClick={(event) => {
                                        typeof onSaveCustomer === 'function' && onSaveCustomer(event)
                                        this.setState({editCustomer: !editCustomer})
                                    }
                                    }
                                /> :
                                hover &&
                                <IconButton
                                    icon='mdi mdi-pencil'
                                    onClick={() => this.setState({editCustomer: !editCustomer})}
                                />
                            }
                        </div>
                    </div>
                </div>

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }
            </div>


        )
    }
}


import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'

import AdminStore from '../../stores/AdminStore.js'
import AdminActions from '../../actions/AdminActions.js'
import LoginActions from '../../actions/LoginActions.js'

import {Button, Colors, IconButton, Input, Panel, Popup, Row} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'

class Customers extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [AdminStore]
    }


    onClickLogin(customer, event) {
        event.stopPropagation()

        LoginActions.loginAsCustomer(customer, (err) => {
            if (err) {
                this.popup.open('Foutmelding', '', () => {})
                this.popup.setError(err)
            }
        })
    }

    columns() {
        const {resellers} = this.state
        const {history} = this.props

        return [
            {title: 'Klantnaam', visible: true, flex: 1, key: 'name'},
            {title: 'Reseller', visible: true, flex: 1, key: 'reseller', render: (customer) =>{
                const reseller = resellers.find((reseller) => reseller.name === customer.reseller)
                return reseller ? (reseller.settings?.accountName || reseller.name) : customer.reseller
            }},
            {title: 'Plaats', visible: true, flex: 1, key: 'address.city'},
            {
                title: 'Aangemaakt', visible: true, flex: 1, key: '_id', sort: (customer) => {
                    const dateFromObjectId = new Date(parseInt(customer._id.substring(0, 8), 16) * 1000)
                    return moment(dateFromObjectId).valueOf()
                }, render: (customer) => {
                    const dateFromObjectId = new Date(parseInt(customer._id.substring(0, 8), 16) * 1000)
                    return moment(dateFromObjectId).format('DD-MM-YYYY')
                }
            },
            {title: 'Bekijk logs', visible: true, key: '', width: 120, render: (customer) => {
                return (
                    <IconButton
                        onClick={() => {
                            history?.push(`/admin/logs?customerId=${customer._id}`)
                        }}
                    >
                        <i className="mdi mdi-text-box-search-outline"/>
                    </IconButton>
                )
            }},
            {title: 'Login', visible: true, key: '', width: 120, onClick: () => {}, render: (customer) => {
                return (
                    <IconButton onClick={this.onClickLogin.bind(this, customer)} >
                        <i className="mdi mdi-login"/>
                    </IconButton>
                )
            }}
        ]
    }

    async onPagination(pageSize, page) {
        const {customerName, resellerName} = this.state

        const promise = new Promise((resolve, reject) => {
            AdminActions.getCustomers(pageSize, page, {customerName, resellerName}, (err) => {
                if (!err) {
                    resolve()
                } else {
                    reject(err)
                }
            })
        })

        return promise
    }

    onChangeValue(key, event) {
        this.setState({[key]: event.target.value})
    }

    async onKeyDown(event) {
        if (event.key === 'Enter') {
            const pageSize = localStorage.getItem('_pageSize') || 40

            await this.onPagination(+pageSize, 1)
            this.table.selectPage(1)
        }
    }

    render() {
        const {customersLoading, customers, totalCustomers, customerName, resellerName} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>
                    <Row style={{alignItems: 'baseline'}}>
                        <Input
                            value={customerName}
                            onChange={this.onChangeValue.bind(this, 'customerName')}
                            style={{width: 250}}
                            label='Klantnaam'
                            onKeyDown={this.onKeyDown.bind(this)}
                        />
                        <Input
                            value={resellerName}
                            onChange={this.onChangeValue.bind(this, 'resellerName')}
                            style={{width: 250}}
                            label='Reseller'
                            onKeyDown={this.onKeyDown.bind(this)}
                        />
                        <Button
                            icon='mdi mdi-magnify'
                            label='Zoeken'
                            onClick={() => {
                                const pageSize = localStorage.getItem('_pageSize') || 40

                                this.onPagination(+pageSize, 1)
                            }}
                        />
                    </Row>
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginLeft: 24, marginRight: 24}}>
                    <Table
                        tableName='Customers'
                        columns={this.columns()}
                        rows={customers || []}
                        selectedRows={[]}
                        sortKey='name'
                        onPagination={this.onPagination.bind(this)}
                        totalRowsCount={totalCustomers}
                        serverSidePagination
                        loading={customersLoading}
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}


export default Customers

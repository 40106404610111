import React from 'react'
import Reflux from 'reflux-react-16'

import {Alert, Button, IconButton, Modal, H4, S2, P, Input, TextArea, Colors} from '../UI/index.js'

import UtilStore from '../../stores/UtilStore.js'
import UtilActions from '../../actions/UtilActions.js'

class ErrorHandler extends Reflux.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            view: 'error',
            isOpen: false,
            err: null,
            loading: false,
            message: '',
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))

        this.store = UtilStore
    }

    componentDidCatch(err, info) {
        this.setState({isOpen: true, err})
    }

    open(err, customMessage) {
        this.setState({isOpen: true, customMessage, err, view: 'send'})
    }

    close() {
        this.setState({isOpen: false}, () => {
            typeof this.state.onClose === 'function' && this.state.onClose()
            this.setState(this.initialState)
        })
    }

    onClickConfirm() {
        const {email, comment, err} = this.state
        this.setState({loading: true, message: '', error: ''})

        const error = {
            customMessage: this.state.customMessage,
            message: err.message,
            stack: err.stack,
            url: window.location.href
        }

        UtilActions.sendErrorEmail(email, comment, error, (err) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, message: 'We hebben je bericht ontvangen, we nemen zo snel mogelijk contact op.'})
            }
        })
    }

    render() {
        if (!this.state.err && !this.props.open) {
            return this.props.children
        }

        const {isOpen, view, err, loading, message, error} = this.state

        return (
            <Modal show={isOpen} style={{width: 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>

                    <H4>Foutmelding</H4>

                    <div style={{flex: 1}}/>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>

                </div>

                {view === 'error' &&
                    <>
                        <P style={{marginBottom: 30}}>
                            Onderstaande fout is opgetreden:
                        </P>


                        <div style={{width: '100%', maxHeight: 200, overflowY: 'auto', marginBottom: 12}}>
                            {err &&
                            <Alert variant='danger'>
                                <P style={{marginBottom: 12}}>{err.message}</P>
                                <S2>{err.stack}</S2>
                            </Alert>
                            }
                        </div>
                    </>
                }

                {view === 'send' &&
                    <>
                        <P style={{marginBottom: 30}}>
                            Vul je emailadres in en geef een korte toelichting.
                        </P>

                        <Input
                            label='Emailadres'
                            value={this.state.email}
                            onChange={(event) => this.setState({email: event.target.value})}
                        />

                        <TextArea
                            rows={4}
                            label='Toelichting'
                            value={this.state.comment}
                            onChange={(event) => this.setState({comment: event.target.value})}
                        />
                    </>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }


                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>


                    {view === 'error' && !message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={() => this.setState({view: 'send'})}
                        >
                            Meld deze fout
                        </Button>
                    }

                    {view === 'send' && !message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            disabled={!this.state.email || !this.state.comment}
                            onClick={this.onClickConfirm.bind(this)}
                        >
                            Verstuur
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default ErrorHandler

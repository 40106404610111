import React from 'react'

import moment from 'moment'
import _ from 'underscore'

import dbCodes from '../../../server/dbCodes.js'
import ParcelActions from '../../actions/ParcelActions.js'
import NewParcel from './NewParcel.js'
import countries from '../../utils/Countries.js'

import {
    Alert,
    Button,
    CarrierLogo,
    Colors,
    CurrencyInput,
    IconButton,
    Input,
    Link,
    MetricInput,
    Modal,
    P,
    ScrollView,
    Select,
    S2,
    H4,
    TextArea,
    Card,
    Row,
    Column,
    Spinner
} from '../UI/index.js'
import Info from './Info.js'
import SendTrackTracePopup from './SendTrackTracePopup.js'
import DividerHeader from '../UI/DividerHeader.js'

export default class ViewParcelModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            parcel: NewParcel(),
            customer: '',
            price: '',
            fees: [],
            priceLocked: false,
            emailTT: '',
            weight: 0,
            length: 0,
            width: 0,
            height: 0,
            editCustomer: false,
            editDimensionsAndWeight: false,
            editAdministration: false,
            notes: '',
            loading: false,
            message: '',
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(id) {
        this.setState({modalIsOpen: true, parcelLoading: true})

        ParcelActions.getOne(id, (err, parcel) => {
            if (err) {
                this.setState({parcelLoading: false, parcelError: err})
            } else {
                console.log(parcel)

                const {reseller, user} = this.props
                parcel = parcel ? JSON.parse(JSON.stringify(parcel)) : NewParcel()

                if (!parcel._id && user.isCustomer) {
                    parcel.customer = user._id
                    parcel.customerAddress = user.address
                }

                this.setState({
                    parcelLoading: false,
                    parcelError: null,
                    parcel,
                    customer: parcel.customer || '',
                    price: parcel.price,
                    fees: JSON.parse(JSON.stringify(parcel.fees || [])),
                    priceLocked: !!parcel.priceLocked,
                    emailTT: (user.isCustomer || reseller.isShop)? parcel.address.email : parcel.emailTT || '',
                    weight: parcel.weight || 0,
                    length: parcel.length || 0,
                    width: parcel.width || 0,
                    height: parcel.height || 0,
                    editCustomer: false,
                    editDimensionsAndWeight: false,
                    editAdministration: false,
                    notes: parcel.notes
                })
            }
        })
    }

    close() {
        const {user, history} = this.props
        const {parcel, notes} = this.state

        if (notes !== parcel.notes) {
            ParcelActions.editNotes(parcel._id, notes)
        }

        this.setState(this.initialState)

        if (this.props.match?.params?.parcelId) {
            if (user.isCustomer) {
                history.push('/klant/zendingen')
            } else {
                history.push('/zendingen')
            }
        }
    }

    onClickGotoTrackTrace(event) {
        event.preventDefault()
        const parcel = {...this.state.parcel}

        if (parcel.trackTraceLink.indexOf('veloyd') > -1) {
            window.open(`/track&trace/${parcel.trackTrace}`)
        } else {
            window.open(parcel.trackTraceLink)
        }
    }

    onSendTrackTraceEmail() {
        const parcel = {...this.state.parcel}
        const {emailTT} = this.state

        this.setState({message: '', error: ''})


        this.sendTrackTracePopup?.open(parcel, emailTT, this.response.bind(this))
    }

    onChangeEditCustomer(event) {
        event.preventDefault()
        const {editCustomer} = this.state
        this.setState({editCustomer: !editCustomer})
    }

    onChangeCustomer({customer}) {
        if (customer) {
            this.setState({customer: customer._id})
        }
    }

    onSaveCustomer(event) {
        event.preventDefault()
        const {customer} = this.state
        const parcel = {...this.state.parcel}
        const customerValid = !this.parcelCustomer || this.parcelCustomer.validate()

        if (customerValid) {
            if (parcel.customer !== customer) {
                parcel.customer = customer
                parcel.contact = ''
                ParcelActions.editCustomer(parcel._id, parcel.customer, parcel.contact, (err, updatedParcel) => {
                    if (err) {
                        this.setState({error: err})
                    } else {
                        this.setState({parcel: updatedParcel})
                    }
                })
            }

            this.setState({editCustomer: false})
        }
    }


    onChangeWeight(event) {
        if (/^[0-9]{0,5}$/.test(event.target.value)) {
            const weight = parseInt(event.target.value) || 0
            this.setState({weight})
        }
    }

    onChangeDimension(key, event) {
        this.setState({
            [key]: +event.target.value
        })
    }

    onSaveDimensionsAndWeight(event) {
        event.preventDefault()
        const {weight, length, height, width} = this.state
        const parcel = {...this.state.parcel}

        if (parcel.weight !== weight || parcel.length !== length || parcel.width !== width || parcel.height !== height) {
            parcel.weight = weight
            parcel.length = length
            parcel.width = width
            parcel.height = height
            const dimensions = {
                length,
                width,
                height
            }
            ParcelActions.editDimensionsAndWeight(parcel._id, weight, dimensions, (err, updatedParcel) => {
                this.setState({
                    parcel: updatedParcel,
                    fees: JSON.parse(JSON.stringify(updatedParcel.fees || []))

                })
            })
        }

        this.setState({editDimensionsAndWeight: false})
    }


    onChangePrice(event) {
        if (/^$|^[-]{0,1}[0-9]{0,3}$|^[-]{0,1}[0-9]{0,3}[,][0-9]{0,2}$/.test(event.target.value)) {
            this.setState({price: event.target.value})
        }
    }

    onChangeFee(key, index, event) {
        event.preventDefault()
        const {fees} = this.state
        const {reseller} = this.props

        if (key === 'quantity') {
            fees[index].quantity = parseInt(event.target.value) || 0
        } else {
            const fee = _.findWhere(reseller.settings.parcels.fees, {description: event.target.value})

            if (fee && fee.description) {
                fee.quantity = 1
                fees[index] = fee
            }
        }

        this.setState({fees})
    }

    onClickAddFee(event) {
        event.preventDefault()
        const {reseller} = this.props
        const {fees} = this.state

        if (reseller.settings.parcels.fees) {
            const fee = JSON.parse(JSON.stringify(reseller.settings.parcels.fees[0]))

            if (fee && fee.description) {
                fees.push({
                    description: fee.description,
                    price: fee.price,
                    productCode: fee.productCode,
                    quantity: 1
                })

                this.setState({fees})
            }
        }
    }

    onClickRemoveFee(index, event) {
        event.preventDefault()
        const {fees} = this.state
        fees.splice(index, 1)
        this.setState({fees})
    }


    onSaveAdministration() {
        const {price, fees, priceLocked, notes} = this.state
        const parcel = {...this.state.parcel}

        // price = parseFloat(price.replace(',', '.')).toFixed(2).replace('.', ',')
        // // parcel.price = price
        // // parcel.fees = fees

        ParcelActions.editAdministration(parcel._id, price, fees, priceLocked, notes, (err, updatedParcel) => {
            this.setState({
                parcel: updatedParcel,
                price: updatedParcel.price,
                priceLocked: updatedParcel.priceLocked,
                fees: JSON.parse(JSON.stringify(updatedParcel.fees || [])),
                notes: updatedParcel.notes
            })
        })

        this.setState({editAdministration: false, price})
    }


    onGetStatus() {
        const parcel = {...this.state.parcel}

        this.setState({loadingStatus: true})

        ParcelActions.getStatus(parcel._id, (err, updatedParcel) => {
            this.setState({loadingStatus: false, error: err})
            if (!err && updatedParcel) {
                this.setState({
                    parcel: updatedParcel,
                    fees: JSON.parse(JSON.stringify(updatedParcel.fees || []))

                })
            }
        })
    }

    response(err, response) {
        if (err) {
            this.setState({loading: false, message: '', error: err})
        } else {
            this.setState({loading: false, message: response, error: ''})
        }
    }

    render() {
        const {modalIsOpen, parcelLoading, parcelError, parcel, emailTT, notes, loadingStatus, message, error, editDimensionsAndWeight, editAdministration} = this.state
        const {reseller, history, user, carriers, customers, onClickCopy, onClickPrintLabels} = this.props

        const customer = customers?.[parcel.customer] || user
        const sizes = customer.settings?.parcels?.sizes || []
        const sizeIndex = sizes.findIndex((size) => size.width === parcel.width && size.length === parcel.length && size.height === parcel.height)

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <Row style={{alignItems: 'center', marginBottom: 12, position: 'relative'}}>
                    <Column>
                        <H4>Zending info</H4>

                        <div style={{flex: 1}}/>
                    </Column>

                    {!parcelLoading &&
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <IconButton
                                tooltip='Kopieer zending'
                                onClick={() => {
                                    this.close()
                                    onClickCopy(parcel._id)
                                }}
                                icon='mdi mdi-content-copy'
                            />

                            <IconButton
                                tooltip='Print labels'
                                onClick={() => onClickPrintLabels(parcel._id)}
                                icon='mdi mdi-printer'
                            />

                            <IconButton
                                tooltip={emailTT ? `Verstuur Track & Trace email naar: ${emailTT}` : 'Verstuur Track & Trace email'}
                                onClick={this.onSendTrackTraceEmail.bind(this)}
                                icon='mdi mdi-at'
                            />
                        </div>
                    }

                    <Column>
                        <div style={{flex: 1}}/>

                        <IconButton onClick={this.close.bind(this)}>
                            <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                        </IconButton>
                    </Column>
                </Row>

                {parcelLoading ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                        <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark}/>
                    </div> :
                    <>
                        {!user.isCustomer &&
                            <Card style={{marginBottom: 24}}>
                                <Info
                                    customers={customers}
                                    parcel={parcel}
                                    onChangeCustomer={this.onChangeCustomer.bind(this)}
                                    onSaveCustomer={this.onSaveCustomer.bind(this)}
                                    onUpdateParcel={(parcel) => this.setState({parcel})}
                                    user={user}
                                />
                            </Card>
                        }

                        <DividerHeader title={parcel.options.includes('Retour') ? 'Afzender' : 'Bezorgadres'}>

                            <Card style={{padding: 0}}>
                                <Row style={{height: '100%'}}>
                                    <Column style={{flexDirection: 'column', borderRight: `1px dashed ${Colors.grey40}`, padding: 6}}>
                                        <Row>
                                            <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-account-outline'/>
                                            <P>{parcel.address.name}</P>
                                        </Row>

                                        <div style={{marginLeft: 24}}>
                                            <P>{parcel.address.attention}</P>
                                            <P>{`${parcel.address.street} ${parcel.address.nr}${parcel.address.addition}`}</P>
                                            <P>{parcel.address.street2}</P>
                                            <P>{`${parcel.address.postalCode} ${parcel.address.city}`}</P>
                                            <P>{_.findWhere(countries, {code2: parcel.address.country}).name}</P>
                                        </div>


                                        {parcel.address.email &&
                                            <Row style={{marginTop: 12}}>
                                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-at'/>
                                                <P>{parcel.address.email}</P>
                                            </Row>
                                        }

                                        {parcel.address.phone &&
                                            <Row>
                                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-phone'/>
                                                <P>{parcel.address.phone}</P>
                                            </Row>
                                        }

                                        {parcel.comment &&
                                            <Row style={{marginTop: 12}}>
                                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-message-outline'/>
                                                <P>{parcel.comment}</P>
                                            </Row>
                                        }
                                    </Column>

                                    <Column style={{flexDirection: 'column', alignItems: 'center', padding: 6}}>
                                        <CarrierLogo
                                            size={100}
                                            carriers={carriers}
                                            value={parcel.carrier}
                                        />

                                        <P
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            {carriers.find((carrier) => carrier.name === parcel.carrier)?.displayName || parcel.carrier}
                                        </P>

                                        <div style={{width: 'fit-content', marginTop: 24}}>
                                            <Row>
                                                <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-web'/>
                                                <Link onClick={this.onClickGotoTrackTrace.bind(this)}>{parcel.trackTrace}</Link>
                                            </Row>

                                            {['GLS', 'Skynet'].includes(parcel.carrier)&&
                                                <Row>
                                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-identifier'/>
                                                    <P>{parcel.externId}</P>
                                                </Row>
                                            }
                                        </div>

                                    </Column>
                                </Row>
                            </Card>
                        </DividerHeader>

                        {parcel.pickupAddress &&
                            <DividerHeader title='Ophaaladres'>

                                <Row>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-account-outline'/>
                                    <P>{parcel.pickupAddress.name}</P>
                                </Row>

                                <div style={{marginLeft: 24}}>
                                    <P>{parcel.pickupAddress.attention}</P>
                                    <P>{`${parcel.pickupAddress.street} ${parcel.pickupAddress.nr}${parcel.pickupAddress.addition}`}</P>
                                    <P>{parcel.pickupAddress.street2}</P>
                                    <P>{`${parcel.pickupAddress.postalCode} ${parcel.pickupAddress.city}`}</P>
                                    <P>{_.findWhere(countries, {code2: parcel.pickupAddress.country}).name}</P>
                                </div>
                            </DividerHeader>
                        }

                        {parcel.options.includes('Afhaalpunt') &&
                            <DividerHeader title='Afhaalpunt'>
                                <Row>
                                    <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-store-outline'/>
                                    <P>{parcel.pickupPoint?.address?.name}</P>
                                </Row>

                                <div style={{marginLeft: 24}}>
                                    <P>{`${parcel.pickupPoint?.address.street} ${parcel.pickupPoint?.address.nr}${parcel.pickupPoint?.address.addition}`}</P>
                                    <P>{`${parcel.pickupPoint?.address.postalCode} ${parcel.pickupPoint?.address.city}`}</P>
                                    <P>{_.findWhere(countries, {code2: parcel.pickupPoint?.address.country}).name}</P>
                                </div>
                            </DividerHeader>
                        }

                        <DividerHeader
                            title='Zending details'
                            edit={editDimensionsAndWeight}
                            onClickEdit={!user.isCustomer && (() => this.setState({editDimensionsAndWeight: !editDimensionsAndWeight}))}
                            onClickSave={this.onSaveDimensionsAndWeight.bind(this)}
                        >
                            <Row>

                                <Column style={{flexDirection: 'column'}}>
                                    <Row>
                                        <MetricInput
                                            label='Gewicht'
                                            value={this.state.weight}
                                            onChange={this.onChangeWeight.bind(this)}
                                            readOnly={!editDimensionsAndWeight}
                                            append='g'
                                        />

                                        <MetricInput
                                            label='Lengte'
                                            value={this.state.length}
                                            onChange={this.onChangeDimension.bind(this, 'length')}
                                            readOnly={!editDimensionsAndWeight}
                                            divisionFactor={10}
                                            append='cm'
                                        />
                                    </Row>

                                    <Row>
                                        <MetricInput
                                            label='Breedte'
                                            value={this.state.width}
                                            onChange={this.onChangeDimension.bind(this, 'width')}
                                            readOnly={!editDimensionsAndWeight}
                                            divisionFactor={10}
                                            append='cm'
                                        />

                                        <MetricInput
                                            label='Hoogte'
                                            value={this.state.height}
                                            onChange={this.onChangeDimension.bind(this, 'height')}
                                            readOnly={!editDimensionsAndWeight}
                                            divisionFactor={10}
                                            append='cm'
                                        />
                                    </Row>
                                </Column>

                                <Column style={{flexDirection: 'column'}}>
                                    <Row>
                                        {user.isCustomer && sizeIndex > -1 && (
                                            <Select
                                                label='Standaardformaat'
                                                value={sizeIndex}
                                                options={(() => {
                                                    const customer = customers?.[parcel.customer] || user
                                                    const sizes = customer.settings.parcels?.sizes || []

                                                    return sizes.map((size, index) => ({
                                                        title: size.name,
                                                        value: index
                                                    }))
                                                })()}
                                                readOnly
                                            />
                                        )}
                                    </Row>

                                    <Row>
                                        <TextArea
                                            label='Verzendopties'
                                            value={parcel.options.map((option) => {
                                                if (option === 'Verzekerd') {
                                                    option = `Verzekerd: € ${parcel.cover}`
                                                }

                                                if (option === 'Spoed levering') {
                                                    option = `Spoed levering voor ${parcel.deliveryTime} uur`
                                                }

                                                if (option === 'Pick & Ship' || option === 'Pick & Return') {
                                                    option = `${option}: ${moment(parcel.pickupDate).format('DD-MM-YYYY')}`
                                                }

                                                return option
                                            }).join(', ')}
                                            readOnly
                                        />
                                    </Row>
                                </Column>
                            </Row>
                        </DividerHeader>

                        <DividerHeader
                            title='Administratie'
                            defaultHidden={true}
                            edit={editAdministration}
                            onClickEdit={!user.isCustomer && (() => this.setState({editAdministration: !editAdministration}))}
                            onClickSave={() => this.onSaveAdministration()}
                        >
                            <Row>
                                <Column>
                                    <CurrencyInput
                                        label='Prijs'
                                        info='De prijs wordt automatisch herberekend op basis van het gewicht en de afmetingen van de zending. Als je dit niet wilt, kan je de prijs vastzetten door op het slotje te klikken.'
                                        value={this.state.price}
                                        readOnly={!editAdministration}
                                        onChange={this.onChangePrice.bind(this)}
                                        append={
                                            <IconButton
                                                icon={this.state.priceLocked ? 'mdi mdi-lock' : 'mdi mdi-lock-open-outline'}
                                                disabled={!editAdministration}
                                                onClick={() => this.setState({priceLocked: !this.state.priceLocked})}
                                            />
                                        }
                                    />

                                    <Input
                                        label='Referentie'
                                        value={parcel.reference}
                                        readOnly
                                    />
                                </Column>

                                <Column>
                                    {!user.isCustomer &&
                                    <TextArea
                                        label='Notities'
                                        value={notes}
                                        onChange={(event) => this.setState({notes: event.target.value})}
                                        readOnly={!editAdministration}
                                    />
                                    }
                                </Column>
                            </Row>

                            {(!user.isCustomer || this.state.fees.length > 0) &&
                            <Row>
                                <Column style={{flexDirection: 'column'}}>
                                    {this.state.fees.map((fee, index) => {
                                        return (
                                            <Row key={`fee${index}`} style={{alignItems: 'center'}}>
                                                <Select
                                                    label='Toeslag'
                                                    options={(reseller.settings.parcels.fees || []).map((fee) => {
                                                        if (fee.description) {
                                                            return {title: fee.description, value: fee.description}
                                                        }
                                                    })}
                                                    value={fee.description}
                                                    readOnly={!editAdministration}
                                                    onChange={this.onChangeFee.bind(this, 'description', index)}
                                                />

                                                <Input
                                                    noPlaceholder
                                                    style={{maxWidth: 80}}
                                                    label='Aantal'
                                                    type='number'
                                                    value={fee.quantity}
                                                    readOnly={!editAdministration}
                                                    onChange={this.onChangeFee.bind(this, 'quantity', index)}
                                                />

                                                {editAdministration &&
                                                    <IconButton onClick={this.onClickRemoveFee.bind(this, index)}>
                                                        <i className='mdi mdi-delete'/>
                                                    </IconButton>
                                                }
                                            </Row>
                                        )
                                    })}
                                </Column>
                                <Column/>
                            </Row>
                            }

                            {editAdministration &&
                                <Button
                                    label='Nieuwe toeslag'
                                    variant='outline-white'
                                    icon='mdi mdi-plus'
                                    onClick={this.onClickAddFee.bind(this)}
                                />
                            }


                        </DividerHeader>


                        {(!!parcel.customs?.content?.length || parcel.refundMethod) &&
                            <DividerHeader
                                title='Artikelen'
                                defaultHidden={true}
                            >

                                {parcel.refundMethod &&
                                    <Row>
                                        <Column>
                                            <Input
                                                label='Terugbetaling'
                                                value={parcel.refundMethod === 'refund' ?
                                                    'Geld terug' :
                                                    parcel.refundMethod === 'exchange' ?
                                                        'Artikelen ruilen' :
                                                        'Winkel tegoed'
                                                }
                                                readOnly
                                            />
                                        </Column>

                                        {parcel.refundMethod === 'exchange' &&
                                            <Column>
                                                <Input
                                                    label='Terugbetaling opmerking'
                                                    value={parcel.refundMethodComment}
                                                    readOnly
                                                />
                                            </Column>
                                        }
                                    </Row>
                                }

                                {parcel.customs?.typeItem &&
                                    <Row style={{marginBottom: 6}}>
                                        <Column>
                                            <Select
                                                label='Type zending'
                                                value={parcel.customs.typeItem}
                                                readOnly
                                            >
                                                <option value='Commercial Goods'>Verkoop goederen</option>
                                                <option value='Returned Goods'>Retour goederen</option>
                                                <option value='Commercial Sample'>Handelsmonster</option>
                                                <option value='Documents'>Documenten</option>
                                                <option value='Gift'>Geschenk</option>
                                            </Select>
                                        </Column>

                                        <Column>
                                            <Select
                                                label='Type nummer'
                                                value={parcel.customs.typeNumber}
                                                readOnly
                                                allowEmptyValue
                                            >
                                                <option value='invoice'>Factuur</option>
                                                <option value='certificate'>Certificaat</option>
                                                <option value='license'>Licentie</option>
                                            </Select>

                                            <Input
                                                label='Nummer'
                                                value={parcel.customs.number}
                                                readOnly
                                            />
                                        </Column>
                                    </Row>
                                }

                                {parcel.customs?.content?.map((content, index) => {
                                    return (
                                        <Row key={`content${index}`}>
                                            <Column>
                                                <Input
                                                    style={{maxWidth: 60}}
                                                    label='Aantal'
                                                    value={content.quantity}
                                                    readOnly
                                                />

                                                <Input
                                                    label='Omschrijving'
                                                    value={content.description}
                                                    readOnly
                                                />
                                            </Column>

                                            <Column>
                                                <MetricInput
                                                    label='Gewicht'
                                                    value={content.weight}
                                                    append='g'
                                                    readOnly
                                                />

                                                <CurrencyInput
                                                    label={parcel.customs?.typeItem ? 'Waarde' : 'Prijs'}
                                                    value={content.value}
                                                    readOnly
                                                />

                                                {parcel.customs?.typeItem &&
                                                <>
                                                    <Input
                                                        label='HS-code'
                                                        value={content.hsCode}
                                                        readOnly
                                                    />


                                                    <Input
                                                        label='Landcode'
                                                        value={content.countryOrigin}
                                                        readOnly
                                                    />
                                                </>
                                                }
                                            </Column>
                                        </Row>
                                    )
                                })}
                            </DividerHeader>
                        }


                        {message && <Alert variant="success">{message}</Alert>}
                        {error && <Alert variant="danger">{error}</Alert>}

                        <DividerHeader title='Historie'>
                            <ScrollView style={{maxHeight: 250}}>
                                {[...parcel.history].reverse().map((entry, index) => {
                                    if (entry.type === 'resellerOnly' && user.isCustomer) {
                                        return
                                    }

                                    if (entry.type === 'invoice' && (user.isCustomer || !user.permissions?.invoices)) {
                                        return
                                    }

                                    return (
                                        <div key={`history${index}`} style={{display: 'flex', alignItems: 'center', padding: 5}}>
                                            <div style={{height: 15, width: 15, borderRadius: '50%', marginRight: 15, background: !entry.status || entry.status === 1 ? Colors.grey20 : Colors.parcelStatus[entry.status]}}/>

                                            <div style={{flex: 1}}>
                                                {entry.type === 'invoice' ?
                                                    <Link onClick={() => history.push('/facturatie', {invoiceId: entry.invoiceId})}>{entry.description}</Link> :
                                                    <P>{entry.description || (entry.status ? dbCodes.parcelStatus[entry.status]: '')}</P>
                                                }
                                            </div>

                                            {!user.isCustomer ?
                                                <S2 style={{marginRight: 15}}>{`${entry.who} | ${entry.eventTime || entry.timestamp ? moment(entry.eventTime || entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2> :
                                                <S2 style={{marginRight: 15}}>{`${entry.eventTime || entry.timestamp ? moment(entry.eventTime || entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2>
                                            }

                                        </div>
                                    )
                                })}
                            </ScrollView>

                            {['Cycloon', 'DHL', 'PostNL', 'Packs'].indexOf(parcel.carrier) > -1 &&
                                <IconButton loading={loadingStatus} onClick={this.onGetStatus.bind(this)}>
                                    <i className='mdi mdi-refresh'/>
                                </IconButton>
                            }
                        </DividerHeader>
                    </>
                }

                {parcelError &&
                    <Alert variant="danger">{parcelError}</Alert>
                }

                <SendTrackTracePopup ref={(ref) => this.sendTrackTracePopup = ref}/>
            </Modal>
        )
    }
}

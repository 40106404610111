import React from 'react'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables)
import annotationPlugin from 'chartjs-plugin-annotation'
Chart.register(annotationPlugin)
import _ from 'lodash'

import {Card, Colors, P, S1} from '../UI/index.js'


class Temperature extends React.PureComponent {
    componentDidMount() {
        const {reseller, order} = this.props

        if (order.monitorTemperature && order.temperatureData && order.temperatureData.length) {
            const sensor = _.find(reseller.settings.orders.sensors, {sensorId: order.sensorId})

            const ctx = this.chart.getContext('2d')

            new Chart(ctx, {
                type: 'line',
                data: {
                    labels: order.temperatureData.map((entry)=> entry.timestamp),
                    datasets: [
                        {
                            label: '',
                            data: order.temperatureData.map((entry) => entry.temperature),
                            backgroundColor: order.lowestTemperature < order.minTemperature || order.highestTemperature > order.maxTemperature ? Colors.errorBright : Colors.successBright,
                            borderColor: order.lowestTemperature < order.minTemperature || order.highestTemperature > order.maxTemperature ? Colors.errorBright : Colors.successBright,
                            borderWidth: 1
                        }
                    ]
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: sensor?.name || ''
                        },
                        legend: {
                            display: false
                        },
                        annotation: {
                            annotations: {
                                line1: {
                                    type: 'line',
                                    yMin: order.minTemperature,
                                    yMax: order.minTemperature,
                                    borderColor: 'black',
                                    borderWidth: 1
                                },
                                line2: {
                                    type: 'line',
                                    yMin: order.maxTemperature,
                                    yMax: order.maxTemperature,
                                    borderColor: 'black',
                                    borderWidth: 1
                                }
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    scales: {

                        y: {
                            title: {
                                display: true,
                                text: 'Temperatuur °C'
                            },
                            suggestedMin: order.minTemperature - 5,
                            suggestedMax: order.maxTemperature + 5
                        }
                        /*
                        xAxis: {
                            adapters: {
                                date: {
                                    locale: nl
                                }
                            },
                            distribution: 'linear',
                            type: 'time',
                            time: {
                                min: _.pluck(order.temperatureData, 'timestamp')[0],
                                max: _.pluck(order.temperatureData, 'timestamp')[_.pluck(order.temperatureData, 'timestamp').length - 1],
                                unit: 'minute',
                                stepSize: '1'
                            }
                        }
                        */
                    }
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.order !== this.props.order) {
            // TODO update graph
        }
    }


    render() {
        const {order} = this.props

        return (
            <Card style={{padding: 6, marginBottom: 24}}>
                <div style={{height: 200, marginTop: 12, marginBottom: 12}}>
                    <canvas ref={(ref) => this.chart = ref}/>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <S1>Laagste temperatuur</S1>
                        <P>{`${order.lowestTemperature.toFixed(2).replace('.', ',')} °C`}</P>
                    </div>

                    <div style={{flex: 1}}>
                        <S1>Hoogste temperatuur</S1>
                        <P>{`${order.highestTemperature.toFixed(2).replace('.', ',')} °C`}</P>
                    </div>

                    <div style={{flex: 1}}>
                        <S1>Min/Max overschreden</S1>
                        <P style={{color: order.lowestTemperature < order.minTemperature || order.highestTemperature > order.maxTemperature ? Colors.errorBright : Colors.successBright}}>{order.lowestTemperature < order.minTemperature || order.highestTemperature > order.maxTemperature ? 'Ja' : 'Nee'}</P>
                    </div>
                </div>
            </Card>
        )
    }
}

export default Temperature


import React from 'react'


import {Input} from '../UI/index.js'

export default class CurrencyInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {value: typeof props.value === 'number' ? props.value.toFixed(2).replace('.', ',') : props.value}
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props

        if (prevProps.value !== value) {
            this.setState({value: typeof value === 'number' ? value.toString().replace('.', ',') : value})
        }
    }

    focus() {
        this.formControl.focus()
    }

    onBlur() {
        const {decimals=2, onBlur, onChange} = this.props
        const {value} = this.state


        const event = {target: {value: ''}}

        if (typeof value === 'number') {
            event.target.value = value.toFixed(decimals).replace('.', ',')
        } else {
            event.target.value = (parseFloat((value || '').replace(',', '.')) || 0).toFixed(decimals).replace('.', ',')
        }

        this.setState({value: event.target.value}, () => {
            onChange(event)
        })


        typeof onBlur === 'function' && onBlur()
    }

    onChange(event) {
        const {decimals = 2} = this.props
        event.target.value = event.target.value.replace('.', ',')

        if (new RegExp(`^$|^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,${decimals}}$`).test(event.target.value)) {
            this.setState({value: event.target.value})
        }
    }

    render() {
        const {value} = this.state
        const {style, inputStyle, id, label, info, append, isInvalid, onFocus, disabled, readOnly} = this.props

        return (
            <Input
                id={id}
                style={style}
                inputStyle={inputStyle}
                label={label}
                info={info}
                value={value}
                isInvalid={isInvalid}
                onFocus={onFocus}
                onBlur={this.onBlur.bind(this)}
                onChange={this.onChange.bind(this)}
                disabled={disabled}
                readOnly={readOnly}
                prepend='€'
                append={append}
                ref={(ref) => this.formControl = ref}
            />
        )
    }
}

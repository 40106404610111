import React from 'react'


import IconButton from './IconButton.js'
import Colors from './Colors.js'
import H4 from './fonts/H4.js'

class Modal extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', this.onPressEsc.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressEsc.bind(this))
    }

    onPressEsc(event) {
        const {show, onClose, noEsc} = this.props

        if (show && !noEsc && event.keyCode === 27) {
            typeof onClose === 'function' && onClose()
        }
    }

    onClose() {
        const {show, onClose} = this.props
        typeof onClose === 'function' && show && onClose()
    }

    render() {
        const {style, children, show, title, closeButton, clickClose} = this.props

        const backgroundStyle = {
            position: 'fixed',
            display: show ? 'flex' : 'none',
            flexDirection: 'column',
            alignItems: 'center',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: style?.zIndex || 1030,
            overflow: 'auto',
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
        const defaultStyle = {
            borderRadius: 4,
            background: Colors.backgroundNeutral,
            marginTop: 20,
            marginBottom: 20,
            padding: 12,
            minWidth: 0,
            width: '80%',
            maxWidth: 800
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div className='veloyd_modal' data-is-open={show} style={backgroundStyle} onClick={clickClose ? this.onClose.bind(this) : null} ref={(ref) => this.innerRef = ref}>
                <div style={defaultStyle} onClick={(event) => event.stopPropagation()}>
                    {(title || closeButton) &&
                        <>
                            <div style={{display: 'flex', marginBottom: 24}}>
                                {title &&
                                    <H4>{title}</H4>
                                }

                                <div style={{flex: 1}}/>

                                {closeButton &&
                                    <IconButton onClick={this.onClose.bind(this)}>
                                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                                    </IconButton>
                                }
                            </div>
                        </>
                    }

                    {show && children}
                </div>
            </div>

        )
    }
}

export default Modal

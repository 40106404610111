import React from 'react'


class Dialog extends React.Component {
    render() {
        const {style, children} = this.props

        const defaultStyle = {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 400,
            borderRadius: 4,
            backgroundColor: '#555',
            color: 'white',
            padding: 10
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                {children}
            </div>

        )
    }
}

export default (Dialog)

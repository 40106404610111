import React from 'react'

import moment from 'moment'
import _ from 'underscore'
import {RRule} from 'rrule'

import languages from '../../../server/languages.js'

import P from './fonts/P.js'
import Option from './Option.js'
import Select from './Select.js'


const rruleDays = {
    0: RRule.MO,
    1: RRule.TU,
    2: RRule.WE,
    3: RRule.TH,
    4: RRule.FR,
    5: RRule.SA,
    6: RRule.SU
}

const days = {
    0: 'M',
    1: 'D',
    2: 'W',
    3: 'D',
    4: 'V',
    5: 'Z',
    6: 'Z'
}

export default class Recurrence extends React.Component {
    constructor(props) {
        super(props)

        const {type} = this.props

        let rrule = {freq: RRule.WEEKLY, interval: 1, byweekday: [0, 1, 2, 3, 4]}

        if (type === 'subscription') {
            rrule = {freq: RRule.MONTHLY, interval: 1, dtstart: moment(moment().format('YYYY-MM-DD')).hour(12).toDate()}
        }

        this.state = {frequency: type === 'subscription' ? 3 : 1,	rrule}
    }


    componentDidUpdate(props) {
        if (this.props.rrule && this.props.date && props.date !== this.props.date) {
            this.onChange()
        }
    }

    init() {
        const {date} = this.props
        let {rrule} = this.state

        rrule.dtstart = date ? moment(date).hour(12).toDate() : rrule.dtstart
        rrule = _.clone(rrule)
        rrule = new RRule(rrule).toString()

        return rrule
    }

    selectRruleFreq(event) {
        const {rrule} = this.state
        const {type} = this.props
        const frequency = parseInt(event.target.value)

        if (frequency === 1 || frequency === 2 || frequency === 6) {
            rrule.freq = 2
            rrule.interval = frequency === 6 ? 4 : frequency

            delete rrule.bymonthday

            if (type !== 'subscription') {
                rrule.byweekday = [0, 1, 2, 3, 4]
            }
        } else if (frequency === 3 || frequency === 4 || frequency === 5) {
            rrule.freq = 1
            rrule.interval = frequency === 5 ? 12 : (frequency === 4 ? 3 : 1)

            if (type === 'subscription') {
                delete rrule.byweekday
            } else {
                rrule.byweekday = rruleDays[0].nth(1)
            }
        }

        this.setState({rrule, frequency}, () => {
            this.onChange()
        })
    }

    changeRruleDays(day, event) {
        const {rrule} = this.state

        if (event.target.checked) {
            rrule.byweekday.push(day)
        } else {
            rrule.byweekday.splice(rrule.byweekday.indexOf(day), 1)
        }

        this.setState({rrule}, () => {
            this.onChange()
        })
    }

    changeRruleByWeekDay(event) {
        const {rrule} = this.state

        rrule.byweekday = rruleDays[parseInt(event.target.value)].nth(rrule.byweekday.n)

        this.setState({rrule}, () => {
            this.onChange()
        })
    }

    changeRruleByWeekDayN(event) {
        const {rrule} = this.state

        rrule.byweekday = rruleDays[rrule.byweekday.weekday].nth(parseInt(event.target.value))

        this.setState({rrule}, () => {
            this.onChange()
        })
    }

    changeRruleByMonthDay(event) {
        const {rrule} = this.state

        rrule.bymonthday = parseInt(event.target.value)

        this.setState({rrule}, () => {
            this.onChange()
        })
    }

    onChange() {
        const {date, onChange} = this.props
        let {rrule, frequency} = this.state

        if (frequency) {
            rrule.dtstart = date ? moment(date).hour(12).toDate() : rrule.dtstart
            rrule = _.clone(rrule)
            rrule = new RRule(rrule).toString()

            onChange({target: {value: rrule}})
        } else {
            onChange({target: {value: ''}})
        }
    }

    rruleToText() {
        const {rrule} = this.props

        if (rrule) {
            return `${RRule.fromString(this.props.rrule).toText((t) => {
                return languages.DUTCH.rrule[t] || t
            }, languages.DUTCH)}`
        }
    }

    render() {
        const {frequency, rrule} = this.state
        const {type, disabled} = this.props
        const monthDays = []

        for (let i = 1; i <= 28; i++) {
            monthDays.push(<option key={i} value={i}>{i + 'e'}</option>)
        }

        if (disabled) {
            return (
                <>
                    {this.props.rrule &&
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', marginBottom: 12}}>
                            <i style={{marginRight: 6, fontSize: 18}} className='mdi mdi-refresh'/>
                            <P>{this.rruleToText()} </P>
                        </div>
                    }
                </>
            )
        }

        return (
            <>
                {this.props.rrule &&
                    <div style={{position: 'relative', flex: 1}}>
                        <Select
                            label={frequency > 3 && frequency !== 6 ? 'Herhaald elk' : 'Herhaald elke'}
                            value={frequency}
                            onChange={this.selectRruleFreq.bind(this)}
                        >
                            <option value={1}>Week</option>
                            <option value={2}>2 weken</option>
                            <option value={6}>4 weken</option>
                            <option value={3}>Maand</option>
                            <option value={4}>Kwartaal</option>
                            <option value={5}>Jaar</option>
                        </Select>

                        {!!frequency && rrule.freq === RRule.WEEKLY && type !== 'subscription' &&
                            <div style={{position: 'absolute', top: 54, left: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start'}}>
                                {_.keys(days).map((key) => {
                                    return (
                                        <Option
                                            key={key}
                                            label={days[key]}
                                            checked={_.includes(rrule.byweekday, parseInt(key))}
                                            onChange={this.changeRruleDays.bind(this, parseInt(key))}
                                        />
                                    )
                                })}
                            </div>
                        }

                        {!!frequency && rrule.freq === RRule.MONTHLY && type !== 'subscription' &&
                            <div style={{display: 'flex', position: 'absolute', top: 54, left: 0}}>
                                <Select
                                    value={rrule.byweekday.n}
                                    onChange={this.changeRruleByWeekDayN.bind(this)}
                                >
                                    <option value={1}>Eerste</option>
                                    <option value={2}>Tweede</option>
                                    <option value={3}>Derde</option>
                                    <option value={4}>Vierde</option>
                                    <option value={-1}>Laatste</option>
                                </Select>

                                <Select
                                    value={this.state.rrule.byweekday.weekday}
                                    onChange={this.changeRruleByWeekDay.bind(this)}
                                >
                                    <option value={0}>Maandag</option>
                                    <option value={1}>Dinsdag</option>
                                    <option value={2}>Woensdag</option>
                                    <option value={3}>Donderdag</option>
                                    <option value={4}>Vrijdag</option>
                                    <option value={5}>Zaterdag</option>
                                    <option value={6}>Zondag</option>
                                </Select>
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setView',
    'setDates',
    'setTimes',
    'setQueries',
    'setSelected',

    'get',
    'getOne',
    'getOrderShifts',
    'getRecentCustomerOrders',
    'create',
    'edit',
    'getTemplate',
    'editTemplate',

    'editInfo',
    'editDate',
    'editAddress',
    'editCollo',
    'editAdministration',
    'editMoreOptions',

    'remove',
    'removeTemplate',
    'printLabels',
    'calculatePrice',
    'getPrice',
    'addHistoryComment',
    'editColloStatus',
    'complete',
    'atHub',
    'reschedule',
    'onHold',
    'cancel',
    'notSupplied',
    'manco',
    'pickup',
    'deliver',
    'getNotPossibleReasons',
    'sendRetour',
    'cancelRetour',
    'newRoute',
    'updateRoute',
    'rescheduleRoute',
    'removeRoute',
    'addToRoute',
    'planRoute',
    'setRouteReady',
    'setRouteLocked',
    'setAddressLocked',
    'autoSetMessengers',

    'changeTimeslot',
    'check',
    'sendDeliveryMail',

    'findByBarcode',
    'scan',

    'resetRoutesHidden',
    'setRouteHidden',
    'setRouteFocus',
    'setActiveRouteTab',
    'setSelectedRoute',
    'setMapOptions',
    'setShowRoutePanel',
    'setShowOrderPanel',
    'setShowOrdersOnMap',
    'setShowCompletedAddresses',
    'setShowRoutesOnMap',
    'setShowStopNumbersOnMap',

    'clearError',
    'getFile'

])

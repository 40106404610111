import React from 'react'
import _ from 'lodash'

import {
    Alert,
    AlertPopup,
    Caption,
    Colors,
    Divider,
    Input,
    MfaPopup,
    Panel,
    Popup,
    S2,
    Toggle,
    UploadImage
} from '../../../../components/UI/index.js'

import {H3, H6, P, ListItem, Button} from '../../../../components/UI/index.js'
import AddressModal from '../../../../components/addresses/AddressModal.js'
import LoginActions from '../../../../actions/LoginActions.js'

class AddressSettings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            addressModalIsSublocation: false
        }
    }

    onChangeLogo(logo) {
        const {reseller, onChange} = this.props
        reseller.settings.logo = logo
        onChange(reseller, 'logo')
    }

    onChangeLogoBlack(logo) {
        const {reseller, onChange} = this.props
        reseller.settings.logoBlack = logo
        onChange(reseller, 'logoBlack')
    }


    onChangeMainLogo(logo) {
        const {reseller, onChange} = this.props
        reseller.settings.mainLogo = logo
        onChange(reseller, 'mainLogo')
    }

    onChangeSquareLogo(logo) {
        const {reseller, onChange} = this.props
        reseller.settings.squareLogo = logo
        onChange(reseller, 'squareLogo')
    }

    onChangeSizedLogo(path, logo) {
        const {reseller, onChange} = this.props
        _.set(reseller.settings, path, logo)
        onChange(reseller, path)
    }

    onClickEditCompanyAddress(address) {
        this.setState({addressModalIsSublocation: false}, () => {
            this.addressModal.open(address, (address) => {
                const {reseller, onChange} = this.props
                reseller.settings.companyAddress = address
                onChange(reseller, 'companyAddress')
                this.addressModal.close()
            })
        })
    }

    onClickNewSubLocation() {
        this.setState({addressModalIsSublocation: true})
        this.addressModal.open(undefined, this.onSaveSubLocation.bind(this))
    }

    onClickEditSubLocation(address, index) {
        this.setState({addressModalIsSublocation: true})
        this.addressModal.open(address, this.onSaveSubLocation.bind(this, index), this.onRemoveSubLocation.bind(this, index))
    }

    onRemoveSubLocation(index) {
        const {reseller, onChange} = this.props

        if (index !== undefined) {
            reseller.settings.sublocations.splice(index, 1)
            onChange(reseller, 'sublocations')
        }

        this.addressModal.close()
    }

    onSaveSubLocation(address, index) {
        const {reseller, onChange} = this.props

        if (index !== undefined) {
            reseller.settings.sublocations[index] = address
        } else {
            reseller.settings.sublocations.push(address)
        }

        onChange(reseller, 'sublocations')
        this.addressModal.close()
    }

    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}
        const {user} = this.props

        if ((path === 'mfaWebEnabled' && !event.target.checked) || (path === 'mfaAppEnabled' && !event.target.checked && reseller.settings.mfaWebEnabled)) {
            if (user.mfaEnabled) {
                if (user.mfaMethod === 'email') {
                    LoginActions.mailMFACode(null, user.index, null, (err) => {
                        if (err) {
                            this.mfaPopup.setError(err)
                        }
                    })
                }

                this.mfaPopup.open((code) => {
                    LoginActions.verify2FACode(code, (err) => {
                        if (err) {
                            this.mfaPopup.setError(err)
                        } else {
                            this.mfaPopup.close()

                            _.set(reseller.settings, path, event.target.checked)

                            this.props.onChange(reseller, path)
                        }
                    })
                }, user.mfaMethod)
            } else {
                this.alertPopup.open('Tweestapsverificatie nog niet ingesteld.', 'Er is nog geen twee-stapsverificatie ingesteld op dit account, maar het is wel verplicht. Log uit en vervolgens weer in op het betreffende account om het proces voor het instellen van tweestapsverificatie te starten.')
                this.alertPopup.setAction('Uitloggen', () => {
                    LoginActions.logout()
                })
            }
        } else if (path === 'brandingEnabled') {
            if (event.target.checked) {
                this.popup.open('Eigen branding activeren', 'Na activeren kunnen gebruikers en klanten alleen nog inloggen via de eigen URL.\n\nDe eigen branding module wordt geactiveerd voor gebruikers.', () => {
                    _.set(reseller.settings, path, event.target.checked)

                    this.props.onChange(reseller, path)
                    this.popup.close()
                })
            }
        } else {
            _.set(reseller.settings, path, event.target.checked)

            this.props.onChange(reseller, path)
        }
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {addressModalIsSublocation} = this.state
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Bedrijfsgegevens</H3>


                <Input
                    label='Accountnaam'
                    info=''
                    value={reseller.settings.accountName}
                    onChange={this.onChangeValue.bind(this, 'accountName')}
                    onBlur={this.onSaveValue.bind(this, 'accountName')}
                />


                <H6 style={{marginTop: 48}}>Inlogbeveiliging</H6>
                <Divider/>


                <Toggle
                    label='Tweefactorauthenticatie voor web omgeving'
                    checked={reseller.settings.mfaWebEnabled}
                    onChange={this.onChangeChecked.bind(this, 'mfaWebEnabled')}
                />

                <Toggle
                    label='Tweefactorauthenticatie voor koerier app'
                    checked={reseller.settings.mfaAppEnabled}
                    onChange={this.onChangeChecked.bind(this, 'mfaAppEnabled')}
                />

                {(reseller.settings.mfaWebEnabled || reseller.settings.mfaAppEnabled) &&
                    <Caption>Het eigenaar-account van {reseller.settings.accountName || reseller.name} kan per gebruiker de 2FA methode resetten.
                        {reseller.ownerEmail &&
                            <>Het eigenaar-account is: {reseller.ownerEmail}</>
                        }
                    </Caption>
                }

                <H6 style={{marginTop: 48}}>Logo en kleur</H6>
                <Divider/>


                <UploadImage
                    label='Scherm logo'
                    info='Dit logo komt op verschillende plekken terug. Bijvoorbeeld bij aangemaakte zendingen en op de Track & Trace pagina.'
                    image={reseller.settings.logo}
                    onChange={this.onChangeLogo.bind(this)}
                />

                <UploadImage
                    label='Print logo'
                    info='Dit logo is optioneel en wordt gebruikt op labels in plaats van het standaard logo. Een zwart-wit logo met maximaal contrast heeft de voorkeur in verband met de eigenschappen van labelprinters. Om het logo zo groot mogelijk op het label te krijgen kan een beeldverhouding van 2:1 gebruikt worden.'
                    image={reseller.settings.logoBlack}
                    onChange={this.onChangeLogoBlack.bind(this)}
                />


                {!reseller.settings.logo &&
                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30, maxWidth: 658}}>Je hebt nog geen scherm logo geupload, dit logo wordt op verschillende plekken gebruikt.</Alert>
                }

                {reseller.settings.logo && !reseller.settings.logoBlack &&
                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30, maxWidth: 658}}>Je hebt nog geen print logo geupload, dit logo wordt gebruikt op de verzendlabels.</Alert>
                }


                <Input
                    type='color'
                    label='Bedrijfskleur'
                    info='Kies hier de kleur van jouw bedrijf, deze kleur wordt op verschillende plekken gebruikt. Bijv. op de Track & Trace pagina'
                    value={reseller.settings.color || Colors.brandColor}
                    onChange={this.onChangeValue.bind(this, 'color')}
                    onBlur={this.onSaveValue.bind(this, 'color')}
                />

                {reseller.customBrandingEnabled ?
                    <div style={{position: 'relative'}}>
                        <H6 style={{marginTop: 48}}>Eigen branding</H6>
                        <Divider/>

                        <div style={{marginBottom: 24}}>
                            {!reseller.settings.brandingEnabled &&
                                <Button
                                    onClick={this.onChangeChecked.bind(this, 'brandingEnabled', {target: {checked: true}})}
                                >
                                    Activeer de eigen branding module voor gebruikers
                                </Button>
                            }
                        </div>

                        <Alert variant="info" style={{marginTop: -10, marginBottom: 30, maxWidth: 658}}>
                            {'Op verschillende plekken in het systeem worden logo\'s gebruikt, deze kunnen hieronder worden geupload. Vanwege eisen van de verschillende platformen waarop Veloyd gebruikt wordt, zijn er verschillende afmetingen nodig. Voor de vierkante logo\'s is het aan te raden om een zo groot mogelijk logo te gebruiken, deze worden met name gebruikt als favicon en app-iconen op mobiele apparaten'}
                        </Alert>

                        <UploadImage
                            label={
                                <>
                                    Logo login pagina
                                    <S2 style={{whiteSpace: 'initial'}}>(Hoogte: 128px. Minimale breedte: 128px, maxmimale breedte: 256px.)</S2>
                                </>
                            }
                            image={reseller.settings.mainLogo}
                            onChange={this.onChangeMainLogo.bind(this)}
                            minHeight={128}
                            maxHeight={128}
                            minWidth={128}
                            maxWidth={256}
                            showDimensionsError
                        />

                        <UploadImage
                            label={
                                <>
                                    Logo navigatie balk
                                    <S2 style={{whiteSpace: 'initial'}}>(Hoogte: 32px. Minimale breedte: 32px, maxmimale breedte: 64px.)</S2>
                                </>
                            }
                            image={reseller.settings.squareLogo}
                            onChange={this.onChangeSquareLogo.bind(this)}
                            minHeight={32}
                            maxHeight={32}
                            minWidth={32}
                            maxWidth={64}
                            showDimensionsError
                        />

                        <UploadImage
                            label='Logo 32x32'
                            image={reseller.settings.logos?.size_32}
                            onChange={this.onChangeSizedLogo.bind(this, 'logos.size_32')}
                            minHeight={32}
                            maxHeight={32}
                            minWidth={32}
                            maxWidth={32}
                            showDimensionsError
                        />

                        <UploadImage
                            label='Logo 128x128'
                            image={reseller.settings.logos?.size_128}
                            onChange={this.onChangeSizedLogo.bind(this, 'logos.size_128')}
                            minHeight={128}
                            maxHeight={128}
                            minWidth={128}
                            maxWidth={128}
                            showDimensionsError
                        />

                        <UploadImage
                            label='Logo 180x180'
                            image={reseller.settings.logos?.size_180}
                            onChange={this.onChangeSizedLogo.bind(this, 'logos.size_180')}
                            minHeight={180}
                            maxHeight={180}
                            minWidth={180}
                            maxWidth={180}
                            showDimensionsError
                        />


                        <UploadImage
                            label='Logo 192x192'
                            image={reseller.settings.logos?.size_192}
                            onChange={this.onChangeSizedLogo.bind(this, 'logos.size_192')}
                            minHeight={192}
                            maxHeight={192}
                            minWidth={192}
                            maxWidth={192}
                            showDimensionsError
                            style={{marginBottom: 0}}
                        />


                    </div> :
                    !reseller.invoiceResellerId &&
                    <>
                        <H6 style={{marginTop: 48}}>Eigen branding</H6>
                        <Divider/>

                        <P>
                            Met de branding module worden de kleur en het logo van Veloyd vervangen door de bedrijfskleur en een eigen gekozen logo, en krijg je een eigen URL om in te loggen. Dit wordt toegepast in de vervoerder omgeving, de klant omgeving en de koerier omgeving. Zo is jouw bedrijf herkenbaarder voor klanten, koeriers en planners.
                            <br/>
                            <br/>
                            Aan het gebruik van de branding module zitten maandelijkse kosten verbonden. Stuur een mail naar info@veloyd.nl voor het aanvragen van de branding module.
                        </P>

                        <Button
                            style={{marginTop: 12, marginLeft: 0}}
                            onClick={() => window.open(`mailto:info@veloyd.nl?subject=Aanvragen van Eigen branding voor ${reseller.settings.accountName || reseller.name}`)}
                            label='Branding module aanvragen'
                        />
                    </>
                }

                <H6 style={{marginTop: 48}}>Adres</H6>
                <Divider/>

                <ListItem
                    style={{height: 'fit-content'}}
                    onClick={this.onClickEditCompanyAddress.bind(this, reseller.settings.companyAddress)}
                >
                    {reseller.settings.companyAddress &&
                        (
                            <div>
                                <p>
                                    {reseller.settings.companyAddress.name}
                                    <br/>
                                    {`${reseller.settings.companyAddress.street} ${reseller.settings.companyAddress.nr} ${reseller.settings.companyAddress.addition}`}
                                    <br/>
                                    {`${reseller.settings.companyAddress.postalCode} ${reseller.settings.companyAddress.city}`}
                                    <br/>
                                    {`${reseller.settings.companyAddress.email || ''} ${reseller.settings.companyAddress.phone ? ` | ${reseller.settings.companyAddress.phone}` : ''}`}
                                </p>
                            </div>
                        )
                    }
                </ListItem>


                <H6 style={{marginTop: 48}}>Andere locaties</H6>
                <Divider/>

                {reseller.settings.sublocations && reseller.settings.sublocations.length > 0 &&
                (
                    <Panel style={{marginBottom: 12}}>
                        {reseller.settings.sublocations.map((address, index) => (
                            <ListItem onClick={this.onClickEditSubLocation.bind(this, address, index)} style={{height: 'fit-content'}} key={index}>
                                <div>
                                    <p>
                                        {address.name}
                                        <br/>
                                        {`${address.street} ${address.nr} ${address.addition}`}
                                        <br/>
                                        {`${address.postalCode} ${address.city}`}
                                    </p>
                                </div>
                            </ListItem>
                        ))}
                    </Panel>
                )
                }
                <Button
                    variant='outline'
                    icon='mdi mdi-plus'
                    label='Nieuwe locatie'
                    onClick={this.onClickNewSubLocation.bind(this)}
                />

                <AddressModal
                    showEmail={!addressModalIsSublocation}
                    showPhone={!addressModalIsSublocation}
                    showBusinessCheckbox={false}
                    noTAV
                    ref={(ref) => this.addressModal = ref}
                />

                <MfaPopup confirmButtonText='Bevestig' ref={(ref) => this.mfaPopup = ref}/>
                <AlertPopup ref={(ref) => this.alertPopup = ref}/>
                <Popup ref={(ref) => this.popup = ref}/>
            </Panel>
        )
    }
}

export default AddressSettings

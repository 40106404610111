import React from 'react'


import {Tabs, Tab, ToolTip} from '../UI/index.js'


class CarrierSelector extends React.Component {
    render() {
        const {size=45, carriers, displayCarriers, value, values, onChange} = this.props

        return (
            <Tabs style={{height: size, marginBottom: 2, width: 'fit-content', paddingBottom: 32, boxSizing: 'content-box'}} value={value} values={values} onChange={onChange}>
                {carriers.map((carrier) => {
                    if (!displayCarriers || displayCarriers.indexOf(carrier.name) > -1) {
                        return (
                            <ToolTip key={carrier.name} text={carrier.displayName || carrier.name}>
                                <Tab
                                    active={carrier.name === value || values && values.indexOf(carrier.name) > -1}
                                    onClick={onChange}
                                    key={carrier.name}
                                    value={carrier.name}
                                >
                                    {value === carrier.name || values && values.indexOf(carrier.name) > -1 ?
                                        <img style={{objectFit: 'contain', height: '100%', maxWidth: 2*size}} src={carrier.logo}/>:
                                        <img style={{objectFit: 'contain', height: '100%', maxWidth: 2*size, filter: 'grayscale(100%)'}} src={carrier.logo}/>
                                    }
                                </Tab>
                            </ToolTip>
                        )
                    }
                })}
            </Tabs>
        )
    }
}

export default CarrierSelector

import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import {Button, IconButton, Divider, Popup, P, H3, H6, S2, Panel, ListItem} from '../../../../components/UI/index.js'
import {Input, Toggle} from '../../../../components/UI/index.js'
import SensorModal from '../../../../components/resellers/SensorModal.js'


class Connections extends Reflux.Component {
    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        reseller.settings.orders = reseller.settings.orders || {}
        reseller.settings.orders.fks = reseller.settings.orders.fks || {}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }


    onClickGenerateApiKey() {
        const {reseller, onChange} = this.props

        if (!reseller.settings.apiKey) {
            reseller.settings.apiKey = generateUUID()
            onChange(reseller, 'apiKey')
        } else {
            this.popup.open('Genereer nieuwe API key', 'De oude API key is hierna niet meer geldig, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                reseller.settings.apiKey = generateUUID()
                onChange(reseller, 'apiKey')
            })
        }
    }

    onClickRemoveApiKey() {
        const {reseller, onChange} = this.props

        if (reseller.settings.apiKey) {
            this.popup.open('Verwijder API key', 'De API key wordt verwijderd, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                reseller.settings.apiKey = ''
                onChange(reseller, 'apiKey')
            })
        }
    }


    onUpdateSensor(sensor, index) {
        const reseller = {...this.props.reseller}

        if (typeof index === 'number') {
            reseller.settings.orders.sensors[index] = sensor
        } else {
            reseller.settings.orders.sensors = reseller.settings.orders.sensors || []
            reseller.settings.orders.sensors.push(sensor)
        }

        this.props.onChange(reseller, 'orders.sensors')
    }

    onRemoveSensor(index) {
        const reseller = {...this.props.reseller}

        reseller.settings.orders.sensors.splice(index, 1)

        this.props.onChange(reseller, 'orders.sensors')
    }


    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Koppelingen</H3>

                <H6>API Key</H6>
                <Divider/>
                <S2 style={{marginBottom: 12}}>Om gebruik te maken van koppelingen is een API key vereist, deze kun je hieronder genereren. Deze API key is niet voor klanten en webshopkoppelingen. Hiervoor dient een API key in het klantaccount gegenereerd te worden.</S2>


                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                    <Input
                        style={{marginBottom: 0}}
                        label='API Key'
                        readOnly
                        value={reseller.settings.apiKey || ''}
                    />
                    {!reseller.settings.apiKey &&
                    <Button onClick={this.onClickGenerateApiKey.bind(this)}>Genereer API key</Button>
                    }
                    {reseller.settings.apiKey &&
                    <IconButton onClick={this.onClickGenerateApiKey.bind(this)}><i className='mdi mdi-refresh'/></IconButton>
                    }
                    {reseller.settings.apiKey &&
                    <IconButton onClick={this.onClickRemoveApiKey.bind(this)}><i className='mdi mdi-delete'/></IconButton>
                    }
                </div>

                <br/>
                <br/>
                <H6>Cycloon</H6>
                <Divider/>

                <Input
                    label='API key'
                    value={reseller.settings.orders ? reseller.settings.orders.fks.apiKey : ''}
                    onChange={this.onChangeValue.bind(this, 'orders.fks.apiKey')}
                    onBlur={this.onSaveValue.bind(this, 'orders.fks.apiKey')}
                />


                <br/>
                <br/>
                <H6>Sense Anywhere</H6>
                <Divider/>
                <S2 style={{marginBottom: 12}}>Koppeling voor het monitoren van temperatuur tijdens het transport.</S2>

                <Toggle
                    style={{marginBottom: 30}}
                    label='Actief'
                    checked={reseller.settings.orders.monitorTemperature}
                    onChange={this.onChangeChecked.bind(this, 'orders.monitorTemperature')}
                />

                {reseller.settings.orders.monitorTemperature &&
                <>
                    {reseller.settings.orders?.sensors?.length > 0 &&
                        <Panel style={{maxHeight: 300, overflowY: 'auto', marginBottom: 12}}>
                            {(reseller.settings.orders.sensors || []).map((sensor, index) => {
                                return (
                                    <ListItem key={`sensor${index}`} onClick={() => this.sensorModal.open(sensor, index)}>
                                        <div>
                                            <P>{sensor.name}</P>
                                            <S2>{sensor.sensorId}</S2>
                                        </div>
                                    </ListItem>
                                )
                            })}
                        </Panel>
                    }

                    <Button
                        variant='outline'
                        icon='mdi mdi-plus'
                        label='Nieuwe sensor'
                        onClick={() => this.sensorModal.open()}
                    />
                </>
                }

                <SensorModal
                    onSubmit={this.onUpdateSensor.bind(this)}
                    onRemove={this.onRemoveSensor.bind(this)}
                    ref={(ref) => this.sensorModal = ref}
                />


                <Popup ref={(ref) => this.popup = ref}/>
            </Panel>
        )
    }
}


function generateUUID() {
    let d = new Date().getTime()

    if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now()
    }

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random()*16)%16 | 0
        d = Math.floor(d/16)
        return (c=='x' ? r : (r&0x3|0x8)).toString(16)
    })

    return uuid
}

export default (Connections)

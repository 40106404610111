import Leaflet from 'leaflet'

Leaflet.Canvas.prototype._updateText = function(ctx, layer) {
    if (!this._drawing || layer._empty()) {
        return
    }

    const p = layer._point

    ctx.font = `bold ${layer.options.text.length <= 2 ? '10px': layer.options.text.length === 3 ? '11px': '9px'} arial`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = layer.options.fontColor || 'black'
    ctx.fillText(layer.options.text, p.x, p.y+1)
}

Leaflet.Canvas.prototype._updateCircle = function(layer) {
    if (!this._drawing || layer._empty()) {
        return
    }

    const p = layer._point
    const ctx = this._ctx
    const r = Math.max(Math.round(layer._radius), 1)
    const s = (Math.max(Math.round(layer._radiusY), 1) || r) / r

    if (s !== 1) {
        ctx.save()
        ctx.scale(1, s)
    }

    ctx.beginPath()
    ctx.arc(p.x, p.y / s, r, 0, Math.PI * 2, false)

    if (s !== 1) {
        ctx.restore()
    }

    this._fillStroke(ctx, layer)

    if (layer.options.text) {
        this._updateText(ctx, layer)
    }
}

export default Leaflet

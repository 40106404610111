import Reflux from 'reflux-react-16'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import SubscriptionActions from '../actions/SubscriptionActions.js'

export default class SubscriptionStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            subscriptionQueries: [],
            subscriptions: [],
            selectedSubscriptions: [],
            subscriptionsLoading: false
        }

        this.listenables = SubscriptionActions

        SubscriptionActions.get(true)

        socket.on('updateSubscriptions', () => {
            SubscriptionActions.get()
        })
    }

    onSetQueries(subscriptionQueries) {
        this.setState({subscriptionQueries, selectedSubscriptions: []})
    }

    onSetSelected(selectedSubscriptions) {
        this.setState({selectedSubscriptions})
    }


    onGet(loading) {
        const {selectedSubscriptions} = this.state
        this.setState({subscriptionsLoading: !!loading})

        socket.emit('subscriptions.get', sessionStorage.token, (err, subscriptions) => {
            if (!err) {
                const ids = _.pluck(subscriptions, '_id')

                this.setState({
                    subscriptions,
                    selectedSubscriptions: _.filter(selectedSubscriptions, (id) => ids.indexOf(id) > -1),
                    subscriptionsLoading: false
                })
            }
        })
    }

    onGetOne(id, callback) {
        socket.emit('subscriptions.getOne', sessionStorage.token, id, callback)
    }

    onCreate(subscription, callback) {
        socket.emit('subscriptions.create', sessionStorage.token, subscription, callback)
    }

    onEdit(edit, callback) {
        socket.emit('subscriptions.edit', sessionStorage.token, edit, callback)
    }

    onRemove(ids, callback) {
        socket.emit('subscriptions.remove', sessionStorage.token, ids, callback)
    }
}

import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'underscore'

import PaycheckStore from '../../stores/PaycheckStore.js'
import PaycheckActions from '../../actions/PaycheckActions.js'

import {Button, Colors, Panel, Popup} from '../../components/UI/index.js'
import Paycheck from '../../components/Paychecks/Paycheck.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import csvExport from '../../utils/csvExport.js'
import filter from '../../utils/filter.js'
import printPDF from '../../utils/printPDF.js'

const columns = [
    {title: 'Naam', visible: true, flex: 1, key: 'messenger'},
    {title: 'Dagen', visible: true, flex: 1, key: 'days'},
    {title: 'Uren', visible: true, flex: 1, key: 'hours'},
    {title: 'Salaris', visible: true, flex: 1, key: 'salary', render: (paycheck) => {
        return `€ ${paycheck.salary}`
    }},
    {title: 'Onbelast', visible: true, flex: 1, key: 'taxFree', render: (paycheck) => {
        return `€ ${paycheck.taxFree}`
    }},
    {title: '', visible: true, key: '', width: 60}
]

class Paychecks extends Reflux.Component {
    constructor(props) {
        super(props)
        this.store = PaycheckStore
    }

    componentDidMount() {
        const {reseller} = this.props
        document.title = `Urenregistratie • ${reseller.settings.accountName || reseller.name}`
    }

    onChangeDates(startDate, endDate) {
        PaycheckActions.setDates(startDate, endDate)
    }

    onChangeQueries(paycheckQueries) {
        this.table.selectPage(1)
        PaycheckActions.setQueries(paycheckQueries)
    }

    onChangeTable(selectedPaychecks) {
        PaycheckActions.setSelected(selectedPaychecks)
    }

    onClickDownload() {
        const {startDate, endDate, paychecks, selectedPaychecks} = this.state

        const exportPaychecks = _.filter(paychecks, (paycheck) => {
            return selectedPaychecks.indexOf(paycheck._id) > -1
        })

        csvExport.paychecks(exportPaychecks, startDate, endDate)
    }

    onClickPrint() {
        const {paychecks, selectedPaychecks} = this.state

        const exportPaychecks = _.filter(paychecks, (paycheck) => {
            return selectedPaychecks.indexOf(paycheck._id) > -1
        })

        PaycheckActions.toPDF(exportPaychecks, (err, pdf) => {
            if (err) {
                this.popup.open('Foutmelding', '', () => {})
                this.popup.setError(err)
            } else {
                printPDF(pdf)
            }
        })
    }

    onClickSend() {
        const {paychecks, selectedPaychecks} = this.state

        const exportPaychecks = _.filter(paychecks, (paycheck) => {
            return selectedPaychecks.indexOf(paycheck._id) > -1
        })

        this.popup.open('Versturen', 'De dienstenoverzichten worden per email verstuurd.', () => {
            PaycheckActions.sendByEmail(exportPaychecks, (errors) => {
                this.popup.setErrors(errors)
                const success = exportPaychecks.length - errors.length
                this.popup.setMessage(`${success} ${success === 1 ? 'email' : 'emails'} verstuurd.`)
            })
        })
    }

    render() {
        const {selectedPaychecks, paychecksLoading, paycheckQueries, startDate, endDate} = this.state
        const paychecks = filter.paychecks(this.state.paychecks, paycheckQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={columns}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        onDateChange={this.onChangeDates.bind(this)}
                        startDate={startDate}
                        endDate={endDate}
                        queries={paycheckQueries}
                    />

                    {selectedPaychecks.length > 0 &&
                        <>
                            <Button
                                variant='outline-white'
                                onClick={this.onClickDownload.bind(this)}
                            >
                                <i className="mdi mdi-download"/>
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickPrint.bind(this)}
                            >
                                <i className="mdi mdi-printer"/>
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickSend.bind(this)}
                            >
                                <i className="mdi mdi-email-send"/>
                            </Button>
                        </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <div style={{display: 'flex', height: '100%'}}>
                        <div style={{width: '50%', paddingRight: 5}}>
                            <Table
                                tableName='paychecks'
                                columns={columns}
                                rows={paychecks}
                                selectedRows={selectedPaychecks}
                                loading={paychecksLoading}
                                onChange={this.onChangeTable.bind(this)}
                                ref={(ref) => this.table = ref}
                            />
                        </div>

                        {selectedPaychecks.length === 1 &&
                            <div style={{width: '50%', paddingLeft: 5}}>
                                <Paycheck paycheck={_.findWhere(paychecks, {_id: selectedPaychecks[0]})}/>
                            </div>
                        }
                    </div>
                </div>

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default (Paychecks)

import moment from 'moment'

export default () => {
    return JSON.parse(JSON.stringify({
        customer: '',
        contact: '',
        description: '',
        price: '0,00',
        reference: '',
        rrule: '',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: '2999-12-31',
        notes: ''
    }))
}

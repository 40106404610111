
export default (date) => {
    return JSON.parse(JSON.stringify({
        name: '',
        attention: '',
        street: '',
        street2: '',
        postalCode: '',
        nr: '',
        addition: '',
        city: '',
        country: 'NL',
        isBusiness: false,
        email: '',
        phone: ''
    }))
}

import React from 'react'

import {parse} from 'csv-parse/browser/esm'

import {Colors, Input} from '../../../../components/UI/index.js'
import {Button, IconButton, Modal, H4} from '../../../../components/UI/index.js'

class OrderPriceTableModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            name: ''
        }
        this.state = this.initialState
    }

    open() {
        this.setState({open: true})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeName(event) {
        this.setState({name: event.target.value})
    }

    onClickDownloadExample() {
        const path = '/downloads/Voorbeeld Tarieftabel.csv'
        const name = 'Voorbeeld Tarieftabel.csv'
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = path
        a.download = name
        a.click()
        document.body.removeChild(a)
    }

    onClickOpenFile(event) {
        const {name} = this.state
        if (name) {
            document.getElementById('csv').value = null
            document.getElementById('csv').click()
        }
    }

    onLoadCSV() {
        const {name} = this.state
        const {onChange} = this.props
        const file = document.getElementById('csv').files[0]
        const reader = new FileReader()

        reader.readAsText(file)

        reader.onerror = (event) => {
            if (event.target.error.name == 'NotReadableError') {
                alert('Can\'t read file !')
            }
        }

        reader.onload = (event) => {
            const table = {table: {}, fees: {}}
            const keyIndex = {}

            let delimiter = ';'
            let commaCount = 0
            let semicolonCount = 0

            event.target.result.split('').slice(0, 500).map((char) => {
                commaCount += char === ',' ? 1: 0
                semicolonCount += char === ';' ? 1: 0
            })

            if (commaCount > semicolonCount) {
                delimiter =','
            }

            parse(event.target.result, {delimiter, relax_column_count: true, skip_empty_lines: true, trim: true}, (err, data) => {
                if (err) {
                    alert(err)
                } else {
                    data[0].map(((key, i) => {
                        if (key) {
                            table.table[key] = {}
                            keyIndex[i] = key
                        }
                    }))

                    delete data[0]

                    data.map((row) => {
                        if (row[0] && row[1]) {
                            row.map((value, i) => {
                                if (i > 0 && value) {
                                    table.table[keyIndex[i]][row[0]] = parseFloat(value.replace(',', '.'))
                                }
                            })
                        } else if (row[2] && row[3]) {
                            table.fees[row[2]] = parseFloat(row[3].replace(',', '.'))
                        }
                    })

                    onChange(name, table)
                    this.close()
                }
            })
        }
    }

    render() {
        const {open, name} = this.state

        return (
            <Modal style={{width: 400}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Nieuwe tarieftabel</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <Input
                    label='Naam'
                    value={name}
                    onChange={this.onChangeName.bind(this)}
                />
                <input style={{display: 'none', height: 0}} type='file' id='csv' accept='.csv' onChange={this.onLoadCSV.bind(this)}/>

                <br/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='text' onClick={this.onClickDownloadExample.bind(this)}>Voorbeeld</Button>
                    <Button variant='text' disabled={!name} onClick={this.onClickOpenFile.bind(this)}>Selecteer CSV</Button>
                </div>
            </Modal>
        )
    }
}

export default (OrderPriceTableModal)

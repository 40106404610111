import React from 'react'

import aSync from 'async'
import _ from 'underscore'
import {parse} from 'csv-parse/browser/esm'

import UserActions from '../../actions/UserActions.js'

import {Alert, Button, IconButton, Colors, Modal, H4, S2, P, Row, Column} from '../UI/index.js'
import {Select, Toggle} from '../UI/index.js'
import splitAddress from '../../utils/splitAddress.js'
import NewUser from './NewUser.js'

export default class ImportUserModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: false,
            ignoreFirstLine: false,
            users: [],
            lines: [],
            loading: false,
            error: '',
            columns: []
        }
    }

    open() {
        this.setState({modalIsOpen: true})
    }

    close() {
        this.setState({
            modalIsOpen: false,
            users: [],
            lines: [],
            loading: false,
            error: '',
            columns: []
        })
    }

    onClickOpenFile(event) {
        event.preventDefault()
        document.getElementById('csv').value = null
        document.getElementById('csv').click()
    }

    onLoadFile() {
        const file = document.getElementById('csv').files[0]

        if (file) {
            const reader = new FileReader()
            reader.readAsText(file)
            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    alert('Can\'t read file !')
                }
            }
            reader.onload = (event) => {
                let delimiter = ';'
                let commaCount = 0
                let semicolonCount = 0

                event.target.result.split('').slice(0, 500).map((char) => {
                    commaCount += char === ',' ? 1: 0
                    semicolonCount += char === ';' ? 1: 0
                })

                if (commaCount > semicolonCount) {
                    delimiter =','
                }

                parse(event.target.result, {delimiter, relax_column_count: true, skip_empty_lines: true, trim: true}, (err, lines) => {
                    if (err) {
                        alert('Geen geldig .csv bestand')
                    } else {
                        const {columns} = this.state

                        let nrOfColumns = 0

                        const nonEmptyLines = []

                        lines.map((line) => {
                            if (line.length > nrOfColumns) {
                                nrOfColumns = line.length
                            }

                            if (line.join('') !== '') {
                                nonEmptyLines.push(line)
                            }
                        })

                        const columnDiff = nrOfColumns - columns.length
                        if (columnDiff > 0) {
                            for (let i = 0; i < columnDiff; i++) {
                                columns.push('')
                            }
                        } else {
                            columns.splice(nrOfColumns)
                        }

                        this.setState({lines: nonEmptyLines, columns, error: ''})
                    }
                })
            }
        }
    }

    onChangeColumn(index, event) {
        const {columns} = this.state
        columns[index] = event.target.value
        this.setState({columns})
    }

    onSubmit(event) {
        event.preventDefault()
        const {lines, columns, ignoreFirstLine} = this.state
        const failedUsers = []

        this.setState({loading: true, error: ''})

        aSync.eachOfSeries(lines, (line, index, next) => {
            if (index > 0 || !ignoreFirstLine) {
                const user = NewUser()

                line.map((value, index) => {
                    if (columns[index]) {
                        const key = columns[index].split('.')

                        value = value.replace(/"/g, '')

                        if (key[0] === 'streetNr') {
                            const address = splitAddress(value)
                            user.address.street = address.street
                            user.address.nr = address.nr
                            user.address.addition = address.addition
                        } else if (key[0] === 'nrAddition') {
                            const address = splitAddress(`straat ${value}`)
                            user.address.nr = address.nr
                            user.address.addition = address.addition
                        } else if (key[0] === 'address.postalCode') {
                            user.address.postalCode = value.toUpperCase().replace(/  +/g, ' ').trim()

                            if (/^[0-9]{4}[A-Z]{2}$/.test(user.address.postalCode)) {
                                user.address.postalCode = `${user.address.postalCode.substr(0, 4)} ${user.address.postalCode.substr(4, 2)}`
                            }
                        } if (key.length === 1) {
                            user[key[0]] = value.trim()
                        } else {
                            user[key[0]][key[1]] = value.trim() || user[key[0]][key[1]] || ''
                        }
                    }
                })

                user.address.country = user.address.country.toUpperCase()

                if (user.address.country === 'NLD') {
                    user.address.country = 'NL'
                }

                user.name = user.address.name

                UserActions.create(user, (err) => {
                    if (err) {
                        failedUsers.push(line)
                    }
                    next()
                })
            } else {
                next()
            }
        }, () => {
            if (!failedUsers.length) {
                this.close()
            }
            const error ='Bovenstaande gebruikers zijn niet aangemaakt, controleer het csv bestand en probeer het opnieuw.'
            this.setState({lines: failedUsers, error, loading: false})
        })
    }

    render() {
        const {modalIsOpen, columns, lines, error, loading, ignoreFirstLine} = this.state

        return (
            <Modal style={{width: 'auto', minWidth: 600, maxWidth: '90vw'}} show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
                    <H4>Gebruikers importeren</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                {!lines.length &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 300}}>
                        <P>Selecteer csv bestand</P>

                        <IconButton onClick={this.onClickOpenFile.bind(this)}>
                            <i className='mdi mdi-folder-open'/>
                        </IconButton>
                    </div>
                }

                {!!lines.length &&
                    <>
                        <Toggle
                            style={{width: 300}}
                            label='Negeer 1e regel'
                            checked={ignoreFirstLine}
                            onChange={(event) => this.setState({ignoreFirstLine: event.target.checked})}
                        />

                        <div style={{height: 500, overflow: 'auto', marginBottom: 24, marginTop: 24}}>
                            <Row style={{flexShrink: 0}}>
                                {columns.map((value, index) => {
                                    return (
                                        <Select
                                            key={index}
                                            style={{width: 200}}
                                            placeholder='Negeer kolom'
                                            options={_.keys(possibleColumns).map((key) => {
                                                return {value: key, title: possibleColumns[key]}
                                            })}
                                            value={value}
                                            onChange={this.onChangeColumn.bind(this, index)}
                                        />
                                    )
                                })}
                            </Row>

                            {lines.map((line, index) => {
                                if ((index > 0 || !ignoreFirstLine) && index < 20) {
                                    return (
                                        <Row key={index} style={{flexShrink: 0, minHeight: 32, marginBottom: 10}}>
                                            {line.map((value, index) => {
                                                return (
                                                    <Column
                                                        key={index}
                                                        style={{
                                                            width: 212,
                                                            flexShrink: 0,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            borderBottom: '1px solid', borderColor: Colors.grey40
                                                        }}
                                                    >
                                                        {value}
                                                    </Column>
                                                )
                                            })}
                                        </Row>
                                    )
                                }
                            })}
                        </div>
                    </>
                }

                {!!lines.length &&
                    <S2>{`${ignoreFirstLine ? lines.length - 1 : lines.length} regels`}</S2>
                }

                <br/>
                <br/>
                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        loading={loading}
                        disabled={!lines.length}
                        onClick={this.onSubmit.bind(this)}
                    >
                        Importeer
                    </Button>
                </div>

                <input
                    type='file' id='csv' accept='.csv'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Modal>
        )
    }
}

const possibleColumns = {
    'address.name': 'Naam',
    'address.street': 'Straat',
    'address.nr': 'Nr',
    'address.addition': 'Toevoeging',
    streetNr: 'Straat + nr + toevoeging',
    nrAddition: 'Nr + toevoeging',
    'address.street2': '2e adres regel',
    'address.postalCode': 'Postcode',
    'address.city': 'Plaats',
    'address.country': 'Land',
    phone: 'Telefoon',
    email: 'Emailadres'
}


import React from 'react'

import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/nl' // Sets locale globally?
moment.locale('en')

import {
    Colors,
    Editor,
    EmailPreview,
    H3,
    Input,
    Panel,
    ListItem,
    Tab,
    Tabs,
    Select
} from '../../../../components/UI/index.js'

import emailNotificationTemplate from '../../../../../server/functions/mail/templates/emailNotificationTemplate.js'
import defaultEmailNotifications from '../../../admin/components/resellers/defaultEmailNotifications.js'
const emailNotifications = defaultEmailNotifications()

class EmailNotifications extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            view: 'receiver',
            notification: 'pickup_eta',
            action: 'default',
            emailPreviewScreen: 'desktop'
        }
    }

    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    onSaveValues(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.values)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {reseller} = this.props
        const {view, notification, action} = this.state

        let dateHeader
        let displayDate
        let displayTime
        let infoColor
        let info
        let addressHeader

        if (notification === 'pickup_registered') {
            dateHeader ='Verwachte ophaaldatum'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = ''
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'pickup_eta') {
            dateHeader ='Verwacht ophaalmoment'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'tussen 09.15 en 10.15 uur'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'pickup_ontheway') {
            dateHeader ='Verwacht ophaalmoment'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'tussen 09.15 en 10.15 uur'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'pickup_delayed') {
            dateHeader ='Ophalen niet gelukt op'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = ''
            infoColor = Colors.errorBright
            info = 'Neem contact op met {klant} voor het maken van een nieuwe afspraak.'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'pickup_nothome') {
            dateHeader ='Je was niet thuis op'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'om 19.17 uur'
            infoColor = Colors.errorBright
            info = 'Neem contact op met {klant} voor het maken van een nieuwe afspraak.'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'pickup_completed') {
            dateHeader ='Je zending is opgehaald'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'om 19.17 uur'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'delivery_registered') {
            dateHeader ='Verwachte bezorgdatum'
            displayDate = 'Nog niet bekend'
            displayTime = ''
            addressHeader = 'Bezorgadres'
        }

        if (notification === 'delivery_collected') {
            dateHeader ='Verwachte bezorgdatum'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = ''
            addressHeader = 'Bezorgadres'
        }

        if (notification === 'delivery_eta') {
            dateHeader ='Verwacht bezorgmoment'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'tussen 18.15 en 19.15 uur'
            addressHeader = 'Bezorgadres'
        }

        if (notification === 'delivery_ontheway') {
            dateHeader ='Verwacht bezorgmoment'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'tussen 18.15 en 19.15 uur'
            addressHeader = 'Bezorgadres'
        }

        if (notification === 'delivery_delayed') {
            if (action === 'reschedule') {
                dateHeader ='Een nieuwe bezorging staat gepland op'
                displayDate = moment().locale('NL').format('dddd DD MMMM')
                displayTime = ''
                addressHeader = 'Bezorgadres'
            }

            if (action === 'retour') {
                dateHeader ='Je zending is niet bezorgd vanwege'
                displayDate = 'Foutief adres'
                displayTime = ''
                infoColor = Colors.errorBright,
                info = 'Je zending gaat retour naar de afzender.'
                addressHeader = 'Bezorgadres'
            }
        }

        if (notification === 'delivery_nothome') {
            if (action === 'reschedule') {
                dateHeader ='Een nieuwe bezorging staat gepland op'
                displayDate = moment().locale('NL').format('dddd DD MMMM')
                displayTime = ''
                addressHeader = 'Bezorgadres'
            }

            if (action === 'onhold') {
                dateHeader ='We houden je zending in bewaring tot'
                displayDate = moment().locale('NL').format('dddd DD MMMM')
                displayTime = ''
                addressHeader = 'Bezorgadres'
            }

            if (action === 'retour') {
                dateHeader ='Bezorging niet gelukt'
                displayDate = 'Retour afzender'
                displayTime = ''
                addressHeader = 'Bezorgadres'
            }
        }

        if (notification === 'delivery_completed') {
            dateHeader ='Je zending is bezorgd op'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'om 19.17 uur'
            addressHeader = 'Bezorgadres'
        }

        if (notification === 'customer_pickup_completed') {
            dateHeader ='De zending is opgehaald op'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'om 19.17 uur'
            addressHeader = 'Ophaaladres'
        }

        if (notification === 'customer_delivery_completed') {
            dateHeader ='De zending is bezorgd op'
            displayDate = moment().locale('NL').format('dddd DD MMMM')
            displayTime = 'om 19.17 uur'
            addressHeader = 'Bezorgadres'
        }

        const notificationKeysReceiver = Object.keys(emailNotifications).filter((key) => !key.includes('customer') && emailNotifications[key].constructor === Object)
        const notificationKeysCustomer = Object.keys(emailNotifications).filter((key) => key.includes('customer') && emailNotifications[key].constructor === Object)

        const notificationsReceiver = notificationKeysReceiver.map((notificationKey) => ({
            value: notificationKey,
            title: emailNotifications[notificationKey].label
        }))

        const notificationsCustomer = notificationKeysCustomer.map((notificationKey) => ({
            value: notificationKey,
            title: emailNotifications[notificationKey].label
        }))

        const editorTags = ['bedrijfsnaam', 'klant', 'naam', 'referentie']

        if (action === 'deliveredAtNeighbors') {
            editorTags.push('huisnummer_buren')
        }


        return (
            <div style={{display: 'flex'}}>
                <Panel style={{width: 600, padding: 20}}>
                    <H3>Email Notificaties</H3>

                    <Input
                        label='Afzender naam'
                        placeholder={reseller.settings?.accountName || reseller.name}
                        value={reseller.settings.orders.emailNotifications.senderName}
                        onChange={this.onChangeValue.bind(this, 'orders.emailNotifications.senderName')}
                        onBlur={this.onSaveValue.bind(this, 'orders.emailNotifications.senderName')}
                    />

                    <Input
                        label='Afzender emailadres'
                        placeholder={(reseller.settings.useOwnSMTP && reseller.settings.smtpUser) || reseller.settings.companyAddress.email || 'noreply@veloyd.nl'}
                        value={reseller.settings.orders.emailNotifications.replyToEmail}
                        onChange={this.onChangeValue.bind(this, 'orders.emailNotifications.replyToEmail')}
                        onBlur={this.onSaveValue.bind(this, 'orders.emailNotifications.replyToEmail')}
                    />

                    <Select
                        label='Standaard notificaties ontvanger'
                        values={reseller.settings.orders.emailNotifications.defaultNotificationsReceiver}
                        options={notificationsReceiver || []}
                        onChange={this.onSaveValues.bind(this, 'orders.emailNotifications.defaultNotificationsReceiver')}
                    />

                    <Select
                        label='Standaard notificaties klant'
                        values={reseller.settings.orders.emailNotifications.defaultNotificationsCustomer}
                        options={notificationsCustomer || []}
                        onChange={this.onSaveValues.bind(this, 'orders.emailNotifications.defaultNotificationsCustomer')}
                    />

                    <div style={{height: 30}}/>

                    {!reseller.isShop &&
                        <Tabs value={view} onChange={(event) => this.setState({view: event.target.value, notification: view === 'receiver' ? 'customer_pickup_completed' : 'pickup_eta', action: 'default'})}>
                            <Tab value='receiver'>Ontvanger</Tab>
                            <Tab value='customer'>Klant</Tab>
                        </Tabs>
                    }

                    <div style={{display: 'flex'}}>
                        {view === 'receiver' &&
                            <Panel style={{width: 200}}>
                                {notificationKeysReceiver.map((notificationKey) => (
                                    <ListItem
                                        key={notificationKey}
                                        active={notification === notificationKey}
                                        onClick={() => this.setState({notification: notificationKey, action: emailNotifications[notificationKey].reschedule ? 'reschedule' : emailNotifications[notificationKey].delivered ? 'delivered' : 'default'})}
                                    >
                                        {emailNotifications[notificationKey].label}
                                    </ListItem>
                                ))}
                            </Panel>
                        }

                        {view === 'customer' &&
                            <Panel style={{width: 200}}>
                                {notificationKeysCustomer.map((notificationKey) => (
                                    <ListItem
                                        key={notificationKey}
                                        active={notification === notificationKey}
                                        onClick={() => this.setState({notification: notificationKey, action: 'default'})}
                                    >
                                        {emailNotifications[notificationKey].label}
                                    </ListItem>
                                ))}
                            </Panel>
                        }


                        <div style={{flex: 1, marginLeft: 30, height: '100%'}}>
                            {notification === 'delivery_delayed' &&
                                <Tabs value={action} onChange={(event) => this.setState({action: event.target.value})}>
                                    <Tab size='sm' value='reschedule'>Nieuwe bezorging</Tab>
                                    <Tab size='sm' value='retour'>Retour</Tab>
                                </Tabs>
                            }

                            {notification === 'delivery_nothome' &&
                                <Tabs value={action} onChange={(event) => this.setState({action: event.target.value})}>
                                    <Tab size='sm' value='reschedule'>Nieuwe bezorging</Tab>
                                    <Tab size='sm' value='onhold'>In bewaring</Tab>
                                    <Tab size='sm' value='retour'>Retour</Tab>
                                </Tabs>
                            }

                            {notification === 'delivery_completed' &&
                                <Tabs value={action} onChange={(event) => this.setState({action: event.target.value})}>
                                    <Tab size='sm' value='delivered'>Bezorgd</Tab>
                                    <Tab size='sm' value='deliveredAtNeighbors'>Bezorgd bij buren</Tab>
                                    <Tab size='sm' value='deliveredInMailbox'>Bezorgd in brievenbus</Tab>
                                </Tabs>
                            }

                            <Editor
                                label='Onderwerp'
                                as='input'
                                tags={editorTags}
                                value={reseller.settings.orders.emailNotifications[notification]?.[action]?.subject}
                                onChange={this.onSaveValue.bind(this, `orders.emailNotifications.${notification}.${action}.subject`)}
                            />

                            <Editor
                                label='Bericht'
                                tags={editorTags}
                                value={reseller.settings.orders.emailNotifications[notification]?.[action]?.body}
                                onChange={this.onSaveValue.bind(this, `orders.emailNotifications.${notification}.${action}.body`)}
                                comment={reseller.settings.orders.emailNotifications?.[notification]?.comment || ''}
                            />

                        </div>
                    </div>
                </Panel>

                <div style={{width: 650, marginLeft: 60}}>
                    <EmailPreview
                        type='emailNotification'
                        notification={notification}
                        action={action}
                        html={emailNotificationTemplate({
                            logo: (reseller.settings.logo && `logo/reseller/${reseller._id}`) || '/images/logoPlaceholder.png',
                            message: reseller.settings.orders.emailNotifications[notification]?.[action]?.body.replace(/\n/g, '<br/>') || '',
                            order: {
                                customerAddress: {
                                    name: 'Klant'
                                },
                                trackTrace: 'VEL73BF282DF2',
                                reference: '831012'
                            },
                            address: {
                                name: 'John Doe',
                                attention: '',
                                street: 'Kastanjelaan',
                                nr: '400',
                                addition: '',
                                street2: '',
                                postalCode: '5616 LZ',
                                city: 'Eindhoven',
                                country: 'NL'
                            },
                            color: reseller.settings.color,
                            dateHeader,
                            displayDate,
                            displayTime,
                            infoColor,
                            info,
                            addressHeader
                        })}
                    />
                </div>
            </div>
        )
    }
}

export default (EmailNotifications)

import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'get',
    'update',
    'edit',
    'remove',
    'autocomplete',
    'autocompleteSearch',
    'getDistance',
    'downloadAttachment'
])

import React, {useReducer} from 'react'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

import {Alert, Divider, H3, H6, S2, P, MetricInput, Column, Row} from '../../../../components/UI/index.js'
import {Toggle, Input, Select, Button} from '../../../../components/UI/index.js'

const initialState = {
    loading: false,
    verified: false,
    error: ''
}

export default function ShopifySettings({user, onChange, location}) {
    const [{loading, verified, error}, dispatch] = useReducer(reducer, initialState)


    function onChangeChecked(key, event) {
        user.settings.webshops.shopify[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.shopify[key] = event.target.value

        onChange(user)
    }


    function onChangeValueParcel(key, event) {
        user.settings.parcels[key] = event.target.value

        onChange(user)
    }

    function onChangeCheckedParcel(key, event) {
        user.settings.parcels[key] = event.target.checked

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('shopify', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true})
                }
            })
        }, 1000)
    }

    return (
        <div>
            {location.state && location.state.message &&
                <Alert variant='success'>{location.state.message}</Alert>
            }
            {location.state && location.state.err &&
                <Alert variant='error'>{`Shopify foutmelding: ${location.state.err}`}</Alert>
            }

            <H3>Shopify</H3>

            <H6>Installatie instructies</H6>
            <Divider/>

            <P>Stap 1</P>
            <S2 style={{width: 700}}>
                {'Ga naar de Shopify App Store en zoek op "Veloyd". Klik op "Add app" en vervolgens op "Installeer App"'}
            </S2>

            <br/>

            <P>Stap 2</P>
            <S2 style={{width: 700}}>
                {'Je wordt nu doorverwezen naar de inlog pagina van Veloyd. Log in met je emailadres en wachtwoord.'}
            </S2>

            <br/>

            <P>Stap 3</P>
            <S2 style={{width: 700}}>
                {'De koppeling is nu actief. Je wordt automatisch doorgestuurd naar de instellingen pagina, hier kun je nog enkele opties aanpassen.'}
            </S2>

            <br/>
            <br/>
            <H6>Instellingen</H6>
            <Divider/>

            <Toggle
                label='Actief'
                checked={user.settings.webshops.shopify.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.shopify.active &&
            <>
                <Input
                    label='Webshop URL'
                    disabled
                    value={user.settings.webshops.shopify.url}
                    onChange={(event) => onChangeValue('url', event)}
                />

                <Input
                    label='API key'
                    disabled
                    value={user.settings.webshops.shopify.apiKey}
                    onChange={(event) => onChangeValue('apiKey', event)}
                />

                <Input
                    type='number'
                    min={0}
                    max={90}
                    label='Aantal dagen terugkijken voor ophalen bestellingen'
                    placeholder={7}
                    value={user.settings.webshops.shopify.nrOfDays}
                    onChange={(event) => onChangeValue('nrOfDays', event)}
                />

                <Select
                    label='Update order status in Shopify'
                    info=''
                    value={user.settings.webshops.shopify.updateStatus}
                    onChange={(event) => onChangeValue('updateStatus', event)}
                >
                    <option value=''>Nooit</option>
                    <option value='confirm'>Na bevestigen</option>
                    <option value='afterSent'>Na verzenden</option>
                </Select>

                <Toggle
                    label='Haal alleen betaalde zendingen op'
                    checked={user.settings.webshops.shopify.ignoreNotPayedOrders}
                    onChange={(event) => onChangeChecked('ignoreNotPayedOrders', event)}
                />

                <Toggle
                    label='Automatisch brievenbuspakket'
                    info='Zendingen die aan de ingestelde voorwaarden voldoen worden automatisch aangemeld als brievenbuspakket.'
                    checked={!!user.settings.parcels.autoMailboxParcel}
                    onChange={(event) => onChangeCheckedParcel('autoMailboxParcel', event)}
                />

                {user.settings.parcels.autoMailboxParcel &&
                    <>
                        <Row>
                            <Column>
                                <Toggle
                                    label='Maximaal gewicht'
                                    checked={!!user.settings.parcels.useMailboxParcelWeight}
                                    onChange={(event) => onChangeCheckedParcel('useMailboxParcelWeight', event)}
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Gewicht'
                                    value={user.settings.parcels.mailboxParcelWeight}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelWeight', event)}
                                    placeholder={2000}
                                    max={2000}
                                    append='g'
                                    disabled={!user.settings.parcels.useMailboxParcelWeight}
                                />
                            </Column>
                        </Row>

                        {/* <Row>
                            <Column>
                                <Toggle
                                    label='Maximale afmetingen'
                                    checked={!!user.settings.parcels.useMailboxParcelDimensions}
                                    onChange={(event) => onChangeCheckedParcel('useMailboxParcelDimensions', event)}
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Lengte'
                                    value={user.settings.parcels.mailboxParcelLength}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelLength', event)}
                                    placeholder={380}
                                    max={380}
                                    divisionFactor={10}
                                    append='cm'
                                    disabled={!user.settings.parcels.useMailboxParcelDimensions}
                                />
                                <MetricInput
                                    label='Breedte'
                                    value={user.settings.parcels.mailboxParcelWidth}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelWidth', event)}
                                    placeholder={265}
                                    max={265}
                                    divisionFactor={10}
                                    append='cm'
                                    disabled={!user.settings.parcels.useMailboxParcelDimensions}
                                />
                                <MetricInput
                                    label='Hoogte'
                                    value={user.settings.parcels.mailboxParcelHeight}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelHeight', event)}
                                    placeholder={32}
                                    max={32}
                                    divisionFactor={10}
                                    append='cm'
                                    disabled={!user.settings.parcels.useMailboxParcelDimensions}
                                />
                            </Column>
                        </Row> */}

                        <Row>
                            <Column>
                                <Toggle
                                    label='Maximale waarde'
                                    checked={!!user.settings.parcels.useMailboxParcelValue}
                                    onChange={(event) => onChangeCheckedParcel('useMailboxParcelValue', event)}
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Waarde'
                                    value={user.settings.parcels.mailboxParcelValue}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelValue', event)}
                                    divisionFactor={100}
                                    prepend='€'
                                    disabled={!user.settings.parcels.useMailboxParcelValue}
                                />
                            </Column>
                        </Row>
                    </>
                }


                <Button
                    variant='text'
                    style={{marginBottom: 12, marginLeft: 0}}
                    loading={loading}
                    onClick={onVerifyConnection}
                >
                    Verifieer
                </Button>

                {error && (
                    <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                )}

                {!error && verified && (
                    <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                )}
            </>
            }
        </div>
    )
}

import React from 'react'


import {Colors, Input} from '../UI/index.js'
import {Button, IconButton, Modal, H4} from '../UI/index.js'

class CategoryModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            category: {
                minWeight: '0',
                maxWeight: '0'
            }
        }
        this.state = this.initialState
    }

    open(carrier, product, i, j, category) {
        this.setState({open: true, carrier, product, i, j, category})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeMinWeight(event) {
        if (/^$|^[-]{0,1}[0-9]{0,3}$|^[-]{0,1}[0-9]{0,3}[,][0-9]{0,2}$/.test(event.target.value)) {
            const {category} = this.state
            category.minWeight = event.target.value
            this.setState({category})
        }
    }

    onChangeMaxWeight(event) {
        if (/^$|^[-]{0,1}[0-9]{0,3}$|^[-]{0,1}[0-9]{0,3}[,][0-9]{0,2}$/.test(event.target.value)) {
            const {category} = this.state
            category.maxWeight = event.target.value
            this.setState({category})
        }
    }

    onRemove() {
        const {carrier, product, i, j} = this.state
        const {remove} = this.props
        remove(carrier, product, i, j)
        this.close()
    }

    onSubmit() {
        const {carrier, product, i, j, category} = this.state
        const {onChange} = this.props
        onChange(carrier, product, i, j, category)
        this.close()
    }

    render() {
        const {open, category} = this.state

        return (
            <Modal style={{width: 400}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Wijzig categorie</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <div style={{display: 'flex'}}>
                    <Input
                        style={{marginRight: 6}}
                        label='Minimaal gewicht'
                        value={category.minWeight}
                        onChange={this.onChangeMinWeight.bind(this)}
                        append='kg'
                    />

                    <Input
                        style={{marginLeft: 6}}
                        label='Maximaal gewicht'
                        value={category.maxWeight}
                        onChange={this.onChangeMaxWeight.bind(this)}
                        append='kg'
                    />
                </div>

                <br/>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='text' onClick={this.onRemove.bind(this)}>Verwijder</Button>
                    <Button variant='text' onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>
            </Modal>
        )
    }
}

export default (CategoryModal)

import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'getStatistics',

    'getResellers',
    'selectReseller',
    'updateReseller',
    'removeReseller',
    'toggleArchiveReseller',

    'getCustomers',

    'searchOrderAndParcel',

    'setMonth',
    'getInvoices',
    'selectInvoice',
    'updateInvoice',
    'sendInvoice',
    'removeInvoice',

    'getNotifications',
    'createNotification',
    'updateNotification',
    'removeNotification'
])

import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setDates',
    'setQueries',
    'setSelected',

    'get',
    'getOne',
    'create',
    'edit',
    'remove',
    'cancel',
    'editCustomer',
    'editNotes',
    'editAdministration',
    'editFees',
    'editDimensionsAndWeight',
    'printLabels',
    'getStatus',
    'calculatePrice',
    'sendTrackTraceEmail',
    'getOptions',
    'getCarriers',
    'getOrdersFromWebshop',
    'scan',
    'changeStatus'
])

import React from 'react'


import {Check} from '../UI/index.js'

class ParcelOptions extends React.Component {
    onChangeOptions(option, event) {
        const {selected, onChange} = this.props
        const index = selected.indexOf(option)

        if (index > -1) {
            selected.splice(index, 1)
        } else {
            selected.push(option)
        }
        onChange(selected)
    }

    render() {
        const {options, selected, disabledOptions} = this.props

        return (
            <>
                {!!options.length &&
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', marginBottom: 12}} >
                        {options.sort().map((option, index) => {
                            if (option !== 'Antwoordnummer' && option !== 'Postbus') {
                                return (
                                    <Check
                                        key={index}
                                        style={{width: 250}}
                                        checked={selected.indexOf(option) > -1}
                                        label={option === 'Handtekening voor ontvangst' ? 'Handtekening' : option}
                                        onChange={this.onChangeOptions.bind(this, option)}
                                        disabled={disabledOptions.indexOf(option) > -1}
                                    />
                                )
                            }
                        })}
                    </div>
                }
            </>
        )
    }
}

export default (ParcelOptions)

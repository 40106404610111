import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import ShiftActions from '../actions/ShiftActions.js'

export default class ShiftStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            shiftQueries: [],
            shifts: [],
            selectedShifts: [],
            shiftsLoading: false,
            shiftLimitWarning: false
        }

        this.listenables = ShiftActions

        ShiftActions.get(true, true)

        socket.on('updateShifts', (updateTemplates) => {
            ShiftActions.get(false, updateTemplates)
        })
    }

    onSetDates(startDate, endDate) {
        this.setState({startDate, endDate, selectedShifts: []})
        ShiftActions.get(true, true)
    }

    onSetQueries(shiftQueries) {
        this.setState({shiftQueries, selectedShifts: []})
    }

    onSetSelected(selectedShifts) {
        this.setState({selectedShifts})
    }


    onGet(loading, updateTemplates) {
        const {startDate, endDate, selectedShifts} = this.state

        this.setState({shiftsLoading: !!loading})

        socket.emit('shifts.get', sessionStorage.token, startDate, endDate, updateTemplates, (err, shifts) => {
            if (!err) {
                const ids = _.pluck(shifts, '_id')

                this.setState({
                    shifts,
                    selectedShifts: _.filter(selectedShifts, (id) => ids.indexOf(id) > -1),
                    shiftsLoading: false,
                    shiftLimitWarning: shifts.length >= 10000
                })
            }
        })
    }

    onGetShiftOrders(shift, callback) {
        if (shift._id && shift.name) {
            socket.emit('orders.getShiftOrders', sessionStorage.token, shift.date, shift.name, callback)
        }
    }

    onCreate(shift, callback) {
        socket.emit('shifts.create', sessionStorage.token, shift, callback)
    }

    onEdit(shift, editAll, callback) {
        socket.emit('shifts.edit', sessionStorage.token, shift, editAll, callback)
    }

    onRemove(ids, callback) {
        socket.emit('shifts.remove', sessionStorage.token, ids, callback)
    }

    onRemoveTemplate(shift, callback) {
        socket.emit('shifts.removeTemplate', sessionStorage.token, shift, callback)
    }

    onSetMessenger(ids, messenger, callback) {
        socket.emit('shifts.setMessenger', sessionStorage.token, ids, messenger, callback)
    }
}

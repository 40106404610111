import React from 'react'

import Reflux from 'reflux-react-16'

import LoginStore from '../../stores/LoginStore.js'
import LoginActions from '../../actions/LoginActions.js'

import {Alert, Button, Card, Colors, H3, P} from '../../components/UI/index.js'
import {Input} from '../../components/UI/index.js'
import moment from 'moment'
import LoadingPage from '../../components/UI/LoadingPage.js'

export default class ResetPassword extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {password: '', password2: '', message: '', error: '', loading: false}
        this.store = LoginStore
    }

    componentDidMount() {
        document.title = 'Wachtwoord wijzigen'
    }

    onSubmit(event) {
        event.preventDefault()
        const {password, password2} = this.state
        const {token} = this.props.match.params
        this.setState({message: '', error: '', loading: true})

        if (password.length < 10) {
            this.setState({error: 'Het wachtwoord moet minstens 10 tekens bevatten.', loading: false})
        } else if (password !== password2) {
            this.setState({error: 'De ingevulde wachtwoorden komen niet overeen.', loading: false})
        } else {
            LoginActions.resetPassword(token, password, (err) => {
                if (err) {
                    this.setState({message: '', error: err, loading: false})
                } else {
                    this.setState({message: 'Je wachtwoord is gewijzigd. Je kunt nu inloggen met je nieuwe wachtwoord.', error: '', loading: false})
                }
            })
        }
    }

    onClickLogin() {
        window.location = '/login'
    }

    render() {
        const {password, password2, message, error, loading, brandingLoading, branding} = this.state

        if (brandingLoading) {
            return <LoadingPage/>
        }


        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', overflowY: 'auto', background: Colors.backgroundNeutral}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <Card shadow='true' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 450, padding: 24, marginTop: 120, marginLeft: 'auto', marginRight: 'auto'}}>
                            <img style={{maxHeight: 128, maxWidth: 256, marginBottom: 50, objectFit: 'contain'}} src={branding?.mainLogo || '/images/VELOYDLOGO.png'}/>


                            <H3>Wachtwoord wijzigen</H3>

                            {!message &&
                            <>
                                <P style={{maxWidth: 400, marginBottom: 24}} >Vul hieronder je nieuwe wachtwoord in.</P>

                                <Input
                                    style={{width: '100%'}}
                                    label='Wachtwoord (minstens 10 tekens)'
                                    type="password"
                                    autocomplete="new-password"
                                    value={password}
                                    onChange={(event) => this.setState({password: event.target.value})}
                                />

                                <Input
                                    style={{width: '100%'}}
                                    label='Herhaal wachtwoord'
                                    type="password"
                                    autocomplete="new-password"
                                    value={password2}
                                    onChange={(event) => this.setState({password2: event.target.value})}
                                />
                            </>
                            }

                            {message && <Alert variant='success'>{message}</Alert>}
                            {error && <Alert variant='danger'>{error}</Alert>}

                            {!message &&
                                <Button
                                    type="submit"
                                    style={{width: '100%', marginTop: 12}}
                                    color={branding?.color || Colors.brandColor}
                                    loading={loading}
                                >
                                    Bevestigen
                                </Button>
                            }

                            <Button
                                style={{margin: 'auto', marginTop: 72}}
                                variant='text'
                                onClick={this.onClickLogin.bind(this)}
                            >
                                Inloggen
                            </Button>
                        </Card>
                    </form>
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: 24}}>
                    <img style={{objectFit: 'contain', width: 100}} src="/images/VELOYDLOGO.png" alt="Logo"/>

                    <div style={{display: 'flex', marginTop: 12}}>
                        <P>&copy; {moment().year()} Veloyd</P>
                        <P style={{marginLeft: 6, marginRight: 6}}>|</P>
                        <P><a style={{color: Colors.textDark}} target='_blank' href='https://veloyd.nl/privacy-policy/' rel="noreferrer">Privacy policy</a></P>
                    </div>
                </div>
            </div>
        )
    }
}


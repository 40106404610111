import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setDates',
    'setQueries',
    'setSelected',

    'get',
    'toPDF',
    'sendByEmail'
])

import moment from 'moment'

export default () => {
    return JSON.parse(JSON.stringify({
        date: moment().format('YYYY-MM-DD'),
        name: '',
        messenger: '',
        comment: '',
        startTime: moment().format('HH:mm'),
        endTime: moment().format('HH:mm'),
        salaryMethod: 0,
        salaryOptions: {
            fixedWage: '0,00',
            hourlyWage: '0,00',
            percentage: '0',
            taxFree: false
        }
    }))
}

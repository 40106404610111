import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, DatePicker, Button, H4, Input, Modal, P, S2} from '../UI/index.js'
import time from '../../../server/utils/time.js'

export default class OnHoldOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            ids: [],
            date: time.tomorrow(),
            comment: '',
            callback: null,
            loading: false,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    open(ids, callback) {
        this.setState({isOpen: true, ids: [...ids], callback})
    }

    close() {
        this.setState(this.initialState)
    }

    confirm() {
        const {ids, date, comment, callback} = this.state


        this.setState({loading: true, errors: []})

        OrderActions.onHold(ids, date, comment, (err, errors, order) => {
            if (err) {
                this.setState({errors: [err]})
            } else if (errors.length) {
                this.setState({errors})
            } else {
                OrderActions.setSelected([])
                typeof callback === 'function' && callback(order)
                this.close()
            }
        })
    }

    render() {
        const {isOpen, ids, date, comment, loading, errors} = this.state

        return (
            <Modal show={isOpen} style={{width: 600, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Rit in bewaring</H4>

                    <div style={{flex: 1}}/>
                </div>

                <P style={{marginBottom: 24}}>Selecteer hieronder de datum tot wanneer de rit in bewaring wordt gezet, hierna wordt er automatisch een retour rit aangemaakt.</P>

                <DatePicker
                    label='In bewaring tot'
                    value={date}
                    minDate={time.tomorrow()}
                    onChange={(event) => this.setState({date: event.target.value})}
                />

                <Input
                    label='Opmerking'
                    value={comment}
                    onChange={(event) => this.setState({comment: event.target.value})}
                />

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {ids.length > 1 &&
                        <>
                            <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check'/>
                            <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                        </>
                    }

                    {!errors.length && !loading &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }


                    {!!errors.length &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}


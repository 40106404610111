import React from 'react'
import _ from 'lodash'

import {
    Panel,
    Toggle,
    TimePicker,
    H3, H6, Divider
} from '../../../../components/UI/index.js'


class OpeningHours extends React.Component {
    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Openingstijden</H3>


                <H6>Open</H6>
                <Divider/>

                {_.keys(days).map((day) => {
                    return (
                        <div key={day} style={{display: 'flex', alignItems: 'center'}}>

                            <Toggle
                                style={{flex: 1, marginRight: 15}}
                                label={days[day]}
                                checked={reseller.settings.account && reseller.settings.account.openingHours[day].open}
                                onChange={this.onChangeChecked.bind(this, `account.openingHours.${day}.open`)}
                            />

                            <div key={day} style={{display: 'flex'}}>
                                <TimePicker
                                    label='Van'
                                    disabled={!(reseller.settings.account && reseller.settings.account.openingHours[day].open)}
                                    style={{width: 130}}
                                    time={reseller.settings.account && reseller.settings.account.openingHours[day] ? reseller.settings.account.openingHours[day].from : '00:00'}
                                    onChange={this.onSaveValue.bind(this, `account.openingHours.${day}.from`)}
                                />
                                <div style={{width: 15}}/>
                                <TimePicker
                                    label='Tot'
                                    disabled={!(reseller.settings.account && reseller.settings.account.openingHours[day].open)}
                                    style={{width: 130}}
                                    time={reseller.settings.account && reseller.settings.account.openingHours[day] ? reseller.settings.account.openingHours[day].till : '00:00'}
                                    onChange={this.onSaveValue.bind(this, `account.openingHours.${day}.till`)}
                                />
                            </div>
                        </div>
                    )
                })}

                <br/>
                <br/>
                <H6>Gesloten</H6>
                <Divider/>

                {_.keys(holidays).map((day) => {
                    return (
                        <Toggle
                            key={day}
                            label={holidays[day]}
                            checked={reseller.settings.account && reseller.settings.account.openingHours.closed[day]}
                            onChange={this.onChangeChecked.bind(this, `account.openingHours.closed.${day}`)}
                        />
                    )
                })}
            </Panel>
        )
    }
}

const days = {
    monday: 'Maandag',
    tuesday: 'Dinsdag',
    wednesday: 'Woensdag',
    thursday: 'Donderdag',
    friday: 'Vrijdag',
    saturday: 'Zaterdag',
    sunday: 'Zondag'
}

const holidays ={
    nieuwjaarsdag: 'Nieuwjaarsdag',
    koningsdag: 'Koningsdag',
    goedeVrijdag: 'Goede vrijdag',
    eerstePaasdag: 'Eerste Paasdag',
    tweedePaasdag: 'Tweede Paasdag',
    dagvdarbeid: 'Dag van de arbeid (1 mei)',
    bevrijdingsdag: 'Bevrijdingsdag',
    hemelvaartsdag: 'Hemelvaarstdag',
    pinksteren: 'Pinksteren',
    pinkstermaandag: 'Pinkstermaandag',
    eersteKerstdag: 'Eerste Kerstdag',
    tweedeKerstdag: 'Tweede Kerstdag'
}

export default OpeningHours

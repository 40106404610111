import React from 'react'


import {Colors} from '../index.js'

class H1 extends React.PureComponent {
    render() {
        const {id, children, style, ellipsis} = this.props

        const defaultStyle = {
            fontSize: 96,
            fontWeight: 300, // light
            letterSpacing: -1.5,
            color: Colors.textDark,
            marginBottom: 30
        }

        if (ellipsis) {
            defaultStyle.overflow = 'hidden',
            defaultStyle.whiteSpace = 'nowrap',
            defaultStyle.textOverflow = 'ellipsis'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div
                id={id}
                style={defaultStyle}
                onClick={this.props.onClick || null}
                onMouseDown={this.props.onMouseDown || null}
                onMouseUp={this.props.onMouseUp || null}
                onMouseEnter={this.props.onMouseEnter || null}
                onMouseLeave={this.props.onMouseLeave || null}
            >
                {children}
            </div>
        )
    }
}

export default (H1)

import React from 'react'
import Reflux from 'reflux-react-16'
import _ from 'lodash'

import {returnRefundMethods, returnReasons} from '../../../../utils/variables.js'

import LoginActions from '../../../actions/LoginActions.js'
import LoginStore from '../../../stores/LoginStore.js'

import {
    Address,
    Alert,
    Button,
    Check,
    CurrencyInput,
    Divider,
    H3,
    H6,
    S2,
    P,
    Panel,
    Input,
    Toast,
    Toggle,
    Select,
    IconButton,
    Row,
    Link
} from '../../../components/UI/index.js'

export default class ReturnSettings extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            newReturnReason: '',
            error: ''
        }

        this.store = LoginStore
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(location.search)
        const code = searchParams.get('code')

        if (code) {
            LoginActions.installMollie(code, (err) => {
                if (err) {
                    console.log(err)
                }

                history.pushState('', '', '/klant/instellingen/retouren')
            })
        }
    }

    onChangeValue(path, event) {
        const user = {...this.props.user}

        if (path === 'settings.parcels.returnPortalURL') {
            _.set(user, path, event.target.value.toLowerCase())
        } else {
            _.set(user, path, event.target.value)
        }

        this.props.onChange(user)
    }

    onChangeChecked(path, event) {
        const user = {...this.props.user}

        _.set(user, path, event.target.checked)

        this.props.onChange(user)
    }

    onInstallMollie() {
        const clientId = 'app_YRTnv9PY29eKzLcvBBgrwy6y'
        const mollieAuthUrl = new URL('https://www.mollie.com/oauth2/authorize')

        const randomStr = Math.random().toString(36).substr(2, 5)

        mollieAuthUrl.searchParams.append('client_id', clientId)
        mollieAuthUrl.searchParams.append('state', randomStr)
        mollieAuthUrl.searchParams.append('scope', 'payments.read payments.write profiles.read')
        mollieAuthUrl.searchParams.append('response_type', 'code')
        mollieAuthUrl.searchParams.append('approval_prompt', 'force')

        location = mollieAuthUrl.href.replace(/\+/g, ' ')
    }

    onUninstallMollie() {
        const user = {...this.props.user}

        user.settings.parcels.mollie.accessToken = ''
        user.settings.parcels.mollie.websiteProfiles = []
        user.settings.parcels.mollie.active = false

        this.props.onChange(user, true)
    }

    onGetMollieProfiles() {
        this.setState({error: '', websiteProfilesLoading: true})

        LoginActions.getMollieProfiles((err) => {
            this.setState({error: err?.message, websiteProfilesLoading: false})
        })
    }

    onChangeWebsiteProfile(e) {
        const user = {...this.props.user}

        user.settings.parcels.mollie.websiteProfiles.map((profile) => profile.active = false)
        const activeIndex = user.settings.parcels.mollie.websiteProfiles.findIndex((profile) => profile.id === e.target.value)
        user.settings.parcels.mollie.websiteProfiles[activeIndex].active = true

        this.props.onChange(user)
    }

    onChangeReturnAddress(address) {
        const user = {...this.props.user}

        user.settings.parcels.returnAddress = address

        this.props.onChange(user)
    }

    onChangeReturnReasons(returnReason) {
        const user = {...this.props.user}

        const index = user.settings.parcels.returnReasons.findIndex((el) => el === returnReason)

        if (index === -1) {
            user.settings.parcels.returnReasons.push(returnReason)
        } else {
            user.settings.parcels.returnReasons.splice(index, 1)
        }

        this.props.onChange(user)
    }

    onClickAddReturnReason() {
        const user = {...this.props.user}

        user.settings.parcels.returnReasons.push(this.state.newReturnReason)

        this.setState({newReturnReason: ''})
        this.props.onChange(user)
    }

    onChangeRefundMethod(refundMethod) {
        const user = {...this.props.user}

        const index = user.settings.parcels.returnRefundMethods.findIndex((el) => el === refundMethod)

        if (index === -1) {
            user.settings.parcels.returnRefundMethods.push(refundMethod)
        } else {
            user.settings.parcels.returnRefundMethods.splice(index, 1)
        }

        this.props.onChange(user)
    }

    onNavigateToEmailNotifications() {
        const {history} = this.props

        history.push('/klant/instellingen/emailnotificaties', {view: 'retourConfirmation'})
    }


    render() {
        const {user, reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Retour instellingen</H3>
                <Toggle
                    label='Retourportaal actief'
                    checked={user.settings.parcels.returnPortalActive}
                    onChange={this.onChangeChecked.bind(this, 'settings.parcels.returnPortalActive')}
                />

                {!user.settings.parcels.returnPortalActive &&
                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30}}>Je retourportaal is nog niet actief.</Alert>
                }

                <br/>
                <div style={{display: 'flex'}}>
                    <P style={{marginTop: 18, marginRight: 12}}>{'app.veloyd.nl/retourportaal/'}</P>
                    <Input
                        label='Retourportaal URL'
                        info=''
                        value={user.settings.parcels.returnPortalURL}
                        onChange={this.onChangeValue.bind(this, 'settings.parcels.returnPortalURL')}
                    />
                    <IconButton
                        onClick={() => {
                            navigator.clipboard.writeText(`https://app.veloyd.nl/retourportaal/${user.settings.parcels.returnPortalURL}`).then(() => {
                                this.toast.showToast('succes', 'Link gekopieerd', 2500)
                            })
                        }}
                    >
                        <i className='mdi mdi-content-copy'/>
                    </IconButton>
                </div>

                {user.settings.parcels.returnPortalURL &&
                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30}}>Let op! Als je de URL van het retourportaal wijzigd werken links naar het oude portaal niet meer.</Alert>
                }

                <Input
                    type='number'
                    label='Maximale retourperiode'
                    info='Hoeveel dagen na ontvangst kan de klant een bestelling retour sturen.'
                    min={14}
                    max={999}
                    value={user.settings.parcels.returnPeriod}
                    onChange={this.onChangeValue.bind(this, 'settings.parcels.returnPeriod')}
                />

                <Input
                    label='Retourbeleid URL'
                    info='Vul hier de URL in die verwijst naar de pagina op jullie website met informatie over retouren.'
                    value={user.settings.parcels.returnPolicyURL}
                    onChange={this.onChangeValue.bind(this, 'settings.parcels.returnPolicyURL')}
                />

                <H6 style={{marginTop: 50}}>Verzendkosten</H6>
                <Divider/>

                {Object.keys(reseller.settings.parcels.products.Retour).map((carrier, index) => {
                    if (reseller.settings.parcels.products.Retour[carrier]) {
                        // force carrier on disabled if reseller doesn't allow Retour option
                        Object.keys(user.settings.parcels.returnShippingCosts).map((carrier) => {
                            if (user.settings.parcels.returnShippingCosts?.[carrier] && reseller.settings.parcels.products?.Retour?.[carrier] === false) {
                                user.settings.parcels.returnShippingCosts[carrier].enabled = reseller.settings.parcels.products?.Retour?.[carrier] && reseller.settings.parcels.carriers[carrier].enabled
                            }
                        })
                        const onlyOneActive = Object.keys(user.settings.parcels.returnShippingCosts).filter((carrier) => user.settings.parcels.returnShippingCosts?.[carrier]?.enabled).length < 2 && user.settings.parcels.returnShippingCosts?.[carrier]?.enabled
                        return (
                            <Row key={`returnCarrierCosts${index}`} style={{marginBottom: 24}}>
                                <Check
                                    label={carrier}
                                    checked={user.settings.parcels.returnShippingCosts?.[carrier]?.enabled}
                                    disabled={onlyOneActive}
                                    onChange={this.onChangeChecked.bind(this, `settings.parcels.returnShippingCosts.${carrier}.enabled`)}
                                />

                                <CurrencyInput
                                    label='Verzendkosten'
                                    value={user.settings.parcels.returnShippingCosts?.[carrier]?.value}
                                    disabled={!user.settings.parcels.returnShippingCosts?.[carrier]?.enabled}
                                    onChange={this.onChangeValue.bind(this, `settings.parcels.returnShippingCosts.${carrier}.value`)}
                                />
                            </Row>
                        )
                    }
                })}


                {!user.settings.parcels.mollie.accessToken &&
                    <Button
                        style={{marginTop: 6}}
                        variant='outline'
                        icon='mdi mdi-link-variant'
                        label='Koppel Mollie'
                        onClick={this.onInstallMollie.bind(this)}
                    />
                }

                {user.settings.parcels.mollie.accessToken &&
                    <Button
                        style={{marginTop: 6}}
                        variant='outline'
                        icon='mdi mdi-link-variant-off'
                        label='Verbreek verbinding met Mollie'
                        onClick={this.onUninstallMollie.bind(this)}
                    />
                }

                {user.settings.parcels.mollie.accessToken &&
                    <Toggle
                        label='Mollie actief'
                        checked={user.settings.parcels.mollie.active}
                        onChange={this.onChangeChecked.bind(this, 'settings.parcels.mollie.active')}
                    />
                }

                {user.settings.parcels.mollie.active && user.settings.parcels.mollie.accessToken &&
                    <div style={{display: 'flex'}}>
                        <Select
                            label='Mollie website profielen'
                            value={user.settings.parcels.mollie.websiteProfiles.find((profile) => profile.active)?.id}
                            onChange={this.onChangeWebsiteProfile.bind(this)}
                        >
                            {user.settings.parcels.mollie.websiteProfiles.map((profile, index) => <option key={index} value={profile.id}>{profile.name}</option>)}
                        </Select>

                        <IconButton
                            loading={this.state.websiteProfilesLoading}
                            onClick={this.onGetMollieProfiles.bind(this)}
                        >
                            <i className='mdi mdi-refresh'/>
                        </IconButton>
                    </div>
                }

                <H6 style={{marginTop: 50}}>Retour redenen</H6>
                <Divider/>
                <S2 style={{marginBottom: 12}}>Kies hieronder de redenen die getoond worden aan je klanten tijdens het aanmelden van een retour zending.</S2>

                <div style={{overflowY: 'auto', maxHeight: 200}}>
                    {_.uniq([...returnReasons, ...user.settings.parcels.returnReasons]).map((returnReason, index) => {
                        return (
                            <Check
                                key={`returnReason${index}`}
                                checked={user.settings.parcels.returnReasons?.includes(returnReason)}
                                label={returnReason}
                                onChange={this.onChangeReturnReasons.bind(this, returnReason)}
                            />
                        )
                    })}
                </div>

                <Input
                    style={{marginTop: 15}}
                    label='Retour reden'
                    value={this.state.newReturnReason}
                    onChange={(event) => this.setState({newReturnReason: event.target.value})}
                />


                <Button
                    variant='outline'
                    icon='mdi mdi-plus'
                    label='Nieuwe retour reden'
                    disabled={!this.state.newReturnReason}
                    onClick={this.onClickAddReturnReason.bind(this)}
                />


                <H6 style={{marginTop: 50}}>Retouradres</H6>
                <Divider/>

                <Toggle
                    label='Gebruik afwijkend retouradres'
                    checked={user.settings.parcels.customReturnAddress}
                    onChange={this.onChangeChecked.bind(this, 'settings.parcels.customReturnAddress')}
                />

                {user.settings.parcels.customReturnAddress &&
                    <Address
                        noCountry
                        address={user.settings.parcels.returnAddress || {}}
                        onChange={this.onChangeReturnAddress.bind(this)}
                    />
                }

                <H6 style={{marginTop: 50}}>Terugbetaling</H6>
                <Divider/>

                {Object.keys(returnRefundMethods).map((key) => {
                    const refundMethod = returnRefundMethods[key]

                    return (
                        <Check
                            key={key}
                            label={refundMethod.title}
                            checked={user.settings.parcels.returnRefundMethods?.includes(key)}
                            onChange={this.onChangeRefundMethod.bind(this, key)}
                        />
                    )
                })}

                <H6 style={{marginTop: 50}}>Retourbevestiging Email</H6>
                <Divider/>

                <Link onClick={this.onNavigateToEmailNotifications.bind(this)}>Retourbevestiging email instellingen</Link>

                <Toast ref={(ref) => this.toast = ref}></Toast>
            </Panel>
        )
    }
}


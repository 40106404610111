// @ts-check

export default () => {
    /** @type {Parcel} */
    const parcel = {
        customer: '',
        contact: '',
        customerAddress: {
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: true
        },
        address: {
            name: '',
            attention: '',
            street: '',
            nr: '',
            addition: '',
            street2: '',
            postalCode: '',
            city: '',
            country: 'NL',
            isBusiness: false,
            email: '',
            phone: ''
        },
        emailTT: '',
        reference: '',
        comment: '',
        carrier: '',
        options: [],
        weight: 0,
        length: 0,
        width: 0,
        height: 0,
        price: '0,00',
        notes: '',
        productCodes: [],
        fees: [],
        history: [],
        content: []
    }

    return JSON.parse(JSON.stringify(parcel))
}

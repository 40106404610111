import React from 'react'

import {Modal, H5, P, Row, Button, IconButton} from './index.js'

export default class SaveBeforeExitPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            show: false
        }

        this.state = structuredClone(this.initialState)
    }


    open(onSave, onNotSave, onClose) {
        this.setState({show: true, onSave, onNotSave, onClose})
    }

    close() {
        this.setState(this.initialState)
    }

    render() {
        const {show, onSave, onNotSave, onClose} = this.state

        return (
            <Modal style={{width: 400}} show={show}>
                <Row style={{alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: 24}}>
                    <H5>Wijzigingen opslaan</H5>

                    <IconButton
                        icon='mdi mdi-close'
                        onClick={() => {
                            typeof onClose === 'function' && onClose()
                            this.close()
                        }}
                    />
                </Row>

                <P style={{marginBottom: 24}}>Wil je de wijzigingen opslaan voor het sluiten? </P>

                <Row style={{justifyContent: 'flex-end'}}>
                    <Button
                        variant='text'
                        onClick={() => {
                            typeof onNotSave === 'function' && onNotSave()
                            this.close()
                        }}
                    >
                        Niet opslaan
                    </Button>

                    <Button
                        onClick={() => {
                            typeof onSave === 'function' && onSave()
                            this.close()
                        }}
                    >
                        Opslaan
                    </Button>
                </Row>
            </Modal>
        )
    }
}

import React, {useState, useEffect} from 'react'
import {Card, Colors, Select, P, Button, Panel, ListItem, Row, Info} from '../UI/index.js'

import dbCodes from '../../../server/dbCodes.js'
import ReasonsSettingsModal from './ReasonsSettingsModal.js'

export default function ReasonsSettings({reseller, customer, onChange, type}) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [activeModalSpecificationIndex, setActiveModalSpecificationIndex] = useState(null)

    useEffect(() => {
        if (customer) {
            if (!customer.settings.orders[`${type}NotPossibleReasons`] || customer.settings.orders[`${type}NotPossibleReasons`].length === 0) {
                customer.settings.orders[`${type}NotPossibleReasons`] = reseller.settings[`${type}NotPossibleReasons`]
                customer.settings.orders[`${type}NotPossibleReasonsSpecifications`] = reseller.settings[`${type}NotPossibleReasonsSpecifications`]
                onChange(customer)
            }
        }
    }, [])

    const onChangeReason = (event) => {
        const settings = customer ? customer.settings.orders : reseller.settings
        settings[`${type}NotPossibleReasons`] = event.target.values
        onChange(customer || reseller, `${type}NotPossibleReasons`)
    }

    const onChangeSpecification = (index, specification, save) => {
        const settings = customer ? customer.settings.orders : reseller.settings

        if (settings[`${type}NotPossibleReasonsSpecifications`]?.[index]) {
            settings[`${type}NotPossibleReasonsSpecifications`][index] = specification
        } else {
            settings[`${type}NotPossibleReasonsSpecifications`] = settings[`${type}NotPossibleReasonsSpecifications`] || []
            settings[`${type}NotPossibleReasonsSpecifications`].push(specification)
        }

        save ? onChange(customer || reseller, `${type}NotPossibleReasonsSpecifications`) : onChange(customer || reseller)
    }

    const onRemoveSpecification = (index) => {
        const settings = customer ? customer.settings.orders : reseller.settings

        if (settings[`${type}NotPossibleReasonsSpecifications`]?.[index]) {
            settings[`${type}NotPossibleReasonsSpecifications`].splice(index, 1)
            onChange(customer || reseller, `${type}NotPossibleReasonsSpecifications`)
        }
    }

    const settings = customer ? customer.settings.orders : reseller.settings

    const notPossibleReasonsSpecifications = settings?.[`${type}NotPossibleReasons`].includes('default') ? reseller.settings?.[`${type}NotPossibleReasonsSpecifications`] : settings?.[`${type}NotPossibleReasonsSpecifications`]

    const pickupOptions = [
        {value: 'nothome', title: dbCodes.colloReasons['nothome']},
        {value: 'notready', title: dbCodes.colloReasons['notready']},
        {value: 'invalid', title: dbCodes.colloReasons['invalid']},
        {value: 'addressnotfound', title: dbCodes.colloReasons['addressnotfound']},
        {value: 'wrongaddress', title: dbCodes.colloReasons['wrongaddress']},
        {value: 'companyaddressclosed', title: dbCodes.colloReasons['companyaddressclosed']},
        {value: 'delayed', title: dbCodes.colloReasons['delayed']},
        {value: 'overmacht', title: dbCodes.colloReasons['overmacht']},
        {value: 'other', title: dbCodes.colloReasons['other']}
    ]

    const deliveryOptions = [
        {value: 'notdelivered;nothome', title: dbCodes.colloReasons['nothome']},
        {value: 'notdelivered;addressnotfound', title: dbCodes.colloReasons['addressnotfound']},
        {value: 'notdelivered;wrongaddress', title: dbCodes.colloReasons['wrongaddress']},
        {value: 'notdelivered;companyaddressclosed', title: dbCodes.colloReasons['companyaddressclosed']},
        {value: 'notdelivered;verificationfailed', title: dbCodes.colloReasons['verificationfailed']},
        {value: 'missing', title: dbCodes.colloStatus['missing']},
        {value: 'refused', title: dbCodes.colloStatus['refused']},
        {value: 'notdelivered;delayed', title: dbCodes.colloReasons['delayed']},
        {value: 'notdelivered;overmacht', title: dbCodes.colloReasons['overmacht']},
        {value: 'notdelivered;other', title: dbCodes.colloReasons['other']}
    ]

    return (
        <Card style={{backgroundColor: customer ? Colors.backgroundNeutral2 : '', paddingTop: 12, border: 'none', ...(customer && {paddingLeft: 0, paddingRight: 0, paddingTop: 0})}}>
            <Select
                label={`Redenen voor '${type === 'delivery' ? 'Bezorgen' : 'Ophalen'} niet mogelijk'`}
                options={type === 'delivery' ? deliveryOptions : pickupOptions}
                values={customer?.settings.orders[`${type}NotPossibleReasons`].includes('default') ? ['default', ...reseller.settings[`${type}NotPossibleReasons`]] : settings[`${type}NotPossibleReasons`]}
                onChange={(event) => onChangeReason(event)}
            />


            {notPossibleReasonsSpecifications?.length > 0 &&
            <Panel style={{maxHeight: 300, overflowY: 'auto', marginBottom: 12, ...(customer && {marginLeft: 6, width: '50%'})}}>
                {notPossibleReasonsSpecifications.map((specification, index) => (
                    <ListItem
                        style={{gap: 8}}
                        key={`specification${index}`}
                        onClick={() => {
                            setActiveModalSpecificationIndex(index)
                            setModalIsOpen(true)
                        }}
                    >
                        <P>{dbCodes.colloReasons[specification.reason.split(';')[1] || specification.reason] || dbCodes.colloStatus[specification.reason]} - {specification.specification}</P>
                    </ListItem>
                ))}
            </Panel>
            }

            <Row style={{alignItems: 'baseline'}}>
                <Button
                    onClick={() => {
                        setActiveModalSpecificationIndex(notPossibleReasonsSpecifications.length)
                        setModalIsOpen(true)
                    }}
                    variant='outline'
                    icon='mdi mdi-plus'
                    label='Specificatie toevoegen'
                    disabled={settings[`${type}NotPossibleReasons`].includes('default')}
                />
                {customer &&
                    <P>
                        <Info
                            text='Een specificatie is een extra uitleg bij de reden. Deze moet door de koerier worden gekozen als ze de reden selecteren.'
                        />
                    </P>
                }
            </Row>

            <ReasonsSettingsModal
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                activeSpecification={notPossibleReasonsSpecifications[activeModalSpecificationIndex] || {reason: '', specification: ''}}
                settings={settings}
                reseller={reseller}
                type={type}
                onChange={(specification) => {
                    onChangeSpecification(activeModalSpecificationIndex, specification, true)
                    setModalIsOpen(false)
                    setActiveModalSpecificationIndex(null)
                }}
                onRemove={() => {
                    onRemoveSpecification(activeModalSpecificationIndex)
                    setModalIsOpen(false)
                    setActiveModalSpecificationIndex(null)
                }}
            />
        </Card>
    )
}

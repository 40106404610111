import React from 'react'

import {Slider} from '../UI/index.js'


class TimeSlider extends React.Component {
    onChange(value1, value2) {
        const {onTimeChange, startInclude, endInclude} = this.props
        const {label1, label2} = this.valuesToLabel(value1, value2)

        onTimeChange(label1, label2, startInclude, endInclude)
    }

    onClickStartInclude() {
        const {onTimeChange, startTime, endTime, startInclude, endInclude} = this.props

        onTimeChange(startTime, endTime, !startInclude, endInclude)
    }

    onClickEndInclude() {
        const {onTimeChange, startTime, endTime, startInclude, endInclude} = this.props

        onTimeChange(startTime, endTime, startInclude, !endInclude)
    }

    valuesToLabel(value1, value2) {
        let startHour = parseInt(value1 / 4).toString()
        let startMinute = ((value1 - (startHour * 4)) * 15).toString()

        if (startHour.length === 1) {
            startHour = `0${startHour}`
        }
        if (startMinute.length === 1) {
            startMinute = `0${startMinute}`
        }

        const startTime = `${startHour}:${startMinute}`

        let endHour = parseInt(value2 / 4).toString()
        let endMinute = ((value2 - (endHour * 4)) * 15).toString()

        if (endHour.length === 1) {
            endHour = `0${endHour}`
        }
        if (endMinute.length === 1) {
            endMinute = `0${endMinute}`
        }

        const endTime = `${endHour}:${endMinute}`

        return {label1: startTime, label2: endTime}
    }

    timesToValue(startTime, endTime) {
        const startValue = (parseInt(startTime.split(':')[0]) * 4) + (parseInt(startTime.split(':')[1]) / 15)
        const endValue = (parseInt(endTime.split(':')[0]) * 4) + (parseInt(endTime.split(':')[1]) / 15)

        return {startValue, endValue}
    }

    render() {
        const {startTime, endTime, startInclude, endInclude} = this.props
        const {startValue, endValue} = this.timesToValue(startTime, endTime)

        return (
            <div style={{marginTop: -10}}>
                <Slider
                    min={0} max={96} step={1} diff={3}
                    value1={startValue}
                    value2={endValue}
                    value1Icon={startInclude ? 'mdi-arrow-expand-left' : 'mdi-arrow-collapse-left'}
                    value2Icon={endInclude ? 'mdi-arrow-expand-right' : 'mdi-arrow-collapse-right'}
                    startInclude={startInclude}
                    endInclude={endInclude}
                    onClickValue1={this.onClickStartInclude.bind(this)}
                    onClickValue2={this.onClickEndInclude.bind(this)}
                    valuesToLabel={this.valuesToLabel.bind(this)}
                    onChange={this.onChange.bind(this)}
                />
            </div>
        )
    }
}

export default (TimeSlider)

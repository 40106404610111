import Reflux from 'reflux-react-16'
import socket from '../socketio/socketio.js'
import UtilActions from '../actions/UtilActions.js'

export default class UtilStore extends Reflux.Store {
    constructor() {
        super()

        this.listenables = UtilActions
    }

    onVerifySMTP(settings, callback) {
        socket.emit('verifySMTP', settings, callback)
    }

    onSendEmailPreview(email, type, notification, action, callback) {
        socket.emit('sendEmailPreview', sessionStorage.token, email, type, notification, action, callback)
    }

    onTrackTrace(trackTrace, addressIndex, callback) {
        socket.emit('orders.trackTrace', trackTrace, addressIndex, callback)
    }

    onGetReturnCustomer(customerURL, callback) {
        socket.emit('parcels.getReturnCustomer', customerURL, callback)
    }

    onGetParcelForReturn(customerURL, orderNumberOrTrackTrace, postalCode, callback) {
        socket.emit('parcels.getParcelForReturn', customerURL, orderNumberOrTrackTrace, postalCode, callback)
    }

    onCreateReturnParcel(returnParcel, callback) {
        socket.emit('parcels.createReturnParcel', returnParcel, callback)
    }

    onGetOptionsReturnPortal(customerURL, parcel, callback) {
        socket.emit('parcels.getOptionsReturnPortal', customerURL, parcel, callback)
    }

    onSendErrorEmail(email, message, error, callback) {
        socket.emit('mail.sendErrorEmail', sessionStorage.token || localStorage.mtoken, email, message, error, callback)
    }

    onCreatePayment(returnParcelRequest, returnParcel, customerURL, callback) {
        socket.emit('parcels.createPaymentForReturn', returnParcelRequest, returnParcel, customerURL, callback)
    }
}

import React from 'react'


import {Colors, IconButton, Modal} from './index.js'

class PrintModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {showModal: false, blobUrl: null}
    }

    open(pdf) {
        const raw = window.atob(pdf)
        const rawLength = raw.length
        const array = new Uint8Array(new ArrayBuffer(rawLength))

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i)
        }

        const blob = new Blob([array], {type: 'application/pdf'})

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'labels.pdf')
        } else {
            const blobUrl = URL.createObjectURL(blob)

            this.setState({showModal: true, blobUrl})

            // window.open(blobUrl)
        }
    }

    close() {
        this.setState({showModal: false, blobUrl: null})
    }

    onClickDownload() {
        const {blobUrl} = this.state

        const link = document.createElement('a')
        link.href = blobUrl
        link.download='labels.pdf'
        link.click()

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobUrl)
            this.close()
        }, 100)
    }

    render() {
        const {showModal, blobUrl} = this.state

        return (
            <Modal
                style={{padding: 0, height: '90%', width: '80%', maxWidth: '100%'}}
                show={showModal}
                clickClose={true}
                onClose={this.close.bind(this)}
            >
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton onClick={this.onClickDownload.bind(this)}>
                        <i className='mdi mdi-download'/>
                    </IconButton>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <embed
                    type='application/pdf'
                    style={{height: 'calc(100% - 35px)', width: '100%', border: 'none'}}
                    src={blobUrl}
                    ref={(ref) => this.embed = ref}
                />
            </Modal>
        )
    }
}

export default (PrintModal)

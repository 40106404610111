import React from 'react'

import _ from 'underscore'

import {Alert, Button, IconButton, Modal, H4, ProgressBar, CurrencyInput, RadioButton} from '../UI/index.js'
import {Check, Input, Select} from '../UI/index.js'

class EditPricePopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            fees: [],
            removeOldFees: false,
            newPrice: '',
            pricingOption: '',
            loading: false,
            progressNow: 0,
            progressMax: null,
            onConfirm: null,
            onClose: null,
            message: '',
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(onConfirm) {
        this.setState({isOpen: true, onConfirm})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeFee(key, index, event) {
        const fees = [...this.state.fees]
        const {reseller} = this.props

        if (key === 'quantity') {
            fees[index].quantity = parseInt(event.target.value) || 0
        } else {
            const fee = _.findWhere(reseller.settings.parcels.fees, {description: event.target.value})

            if (fee && fee.description) {
                fee.quantity = 1
                fees[index] = fee
            }
        }

        this.setState({fees})
    }

    onClickAddFee(event) {
        const fees = [...this.state.fees]
        const {reseller} = this.props

        if (reseller.settings.parcels.fees) {
            const fee = JSON.parse(JSON.stringify(reseller.settings.parcels.fees[0]))

            if (fee && fee.description) {
                fees.push({
                    description: fee.description,
                    price: fee.price,
                    productCode: fee.productCode,
                    quantity: 1
                })

                this.setState({fees})
            }
        }
    }

    onClickRemoveFee(index, event) {
        const fees = [...this.state.fees]
        fees.splice(index, 1)
        this.setState({fees})
    }

    onClickConfirm() {
        this.setState({loading: true, message: '', error: ''})
        const {fees, removeOldFees, pricingOption, newPrice} = this.state

        typeof this.state.onConfirm === 'function' && this.state.onConfirm(fees, removeOldFees, pricingOption, newPrice)
    }

    setMessage(message) {
        this.setState({message, loading: false})
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    setErrors(errors) {
        this.setState({errors, loading: false})
    }

    setProgress(progressNow, progressMax) {
        this.setState({progressNow, progressMax})
    }

    render() {
        const {isOpen, fees, removeOldFees, newPrice, pricingOption, progressNow, progressMax, onConfirm, loading, message, error, errors} = this.state
        const {reseller} = this.props

        return (
            <Modal show={isOpen} style={{width: errors.length > 0 ? 800 : 600, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>

                    <H4>Wijzig zending prijs</H4>

                    <div style={{flex: 1}}/>
                </div>

                <br/>
                <Check
                    label='Verwijderen oude toeslagen'
                    checked={removeOldFees}
                    onChange={(event) => this.setState({removeOldFees: event.target.checked})}
                    disabled={error || message}
                />

                <RadioButton
                    label='Herbereken prijs volgens tarieftabel'
                    checked={pricingOption === 'recalculatePrice'}
                    value='recalculatePrice'
                    onChange={(event) => this.setState({pricingOption: event.target.value})}
                    disabled={error || message}
                />

                <div style={{display: 'flex'}}>
                    <RadioButton
                        style={{width: '100%'}}
                        label='Wijzig prijs'
                        checked={pricingOption === 'newPrice'}
                        value='newPrice'
                        onChange={(event) => this.setState({pricingOption: event.target.value})}
                        disabled={error || message}
                    />
                    <CurrencyInput
                        style={{width: '45%'}}
                        label='prijs'
                        value={newPrice}
                        onChange={(event) => this.setState({newPrice: event.target.value})}
                        disabled={(pricingOption !== 'newPrice') || (error || message)}
                    />
                </div>

                <br/>

                {fees.map((fee, index) => {
                    return (
                        <div key={`fee${index}`} style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                            <Select
                                style={{marginRight: 6, marginBottom: 0}}
                                label='Toeslag'
                                options={(reseller.settings.parcels.fees || []).map((fee, index) => {
                                    if (fee.description) {
                                        return {title: fee.description, value: fee.description}
                                    }
                                })}
                                value={fee.description}
                                onChange={this.onChangeFee.bind(this, 'description', index)}
                                disabled={error || message}
                            />

                            <Input
                                type='number'
                                noPlaceholder
                                style={{maxWidth: 80, marginLeft: 6, marginBottom: 0}}
                                label='Aantal'
                                value={fee.quantity}
                                onChange={this.onChangeFee.bind(this, 'quantity', index)}
                                disabled={error || message}
                            />

                            <IconButton
                                onClick={this.onClickRemoveFee.bind(this, index)}
                                disabled={error || message}
                            >
                                <i className='mdi mdi-delete'/>
                            </IconButton>
                        </div>
                    )
                })}

                {reseller.settings.parcels.fees?.length > 0 && (
                    <Button
                        style={{marginBottom: 12}}
                        variant='outline-white'
                        icon='mdi mdi-plus'
                        label='Nieuwe toeslag'
                        onClick={this.onClickAddFee.bind(this)}
                        disabled={error || message}
                    />
                )}

                {progressMax && !message && !error &&
                    <ProgressBar now={progressNow} max={progressMax}/>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {loading && progressNow !== progressMax &&
                        <Button
                            variant='outline-white'
                            onClick={() => this.setState({stopOperation: true, progressNow: progressMax, loading: false}, this.close)}
                        >
                            Afbreken
                        </Button>
                    }

                    {!message && !loading && !error &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {onConfirm && !message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={() => this.setState({stopOperation: false}, this.onClickConfirm)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default (EditPricePopup)

import React from 'react'
import aSync from 'async'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, DatePicker, Button, H4, Input, Modal, ProgressBar, P, S2} from '../UI/index.js'
import time from '../../../server/utils/time.js'

class ReturnOrderPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            ids: [],
            date: time.nextWeekDay(props?.reseller),
            comment: '',
            callback: null,
            loading: false,
            progressNow: 0,
            progressMax: 0,
            errors: []
        }

        this.state = structuredClone(this.initialState)
    }

    open(ids, callback) {
        this.setState({isOpen: true, ids: [...ids], callback})
    }

    close() {
        this.setState(this.initialState)
    }

    confirm() {
        const {ids, date, comment, callback} = this.state

        const errors = []

        this.setState({loading: true, errors, progressNow: 0, progressMax: ids?.length || 1})

        aSync.eachSeries(ids, (id, next) => {
            OrderActions.sendRetour(id, date, comment, ids.length === 1, (err, order) => {
                if (err) {
                    errors.push(err)
                }

                this.setState({progressNow: this.state.progressNow + 1})

                if (ids.length === 1) {
                    if (errors.length) {
                        this.setState({errors})
                    } else {
                        typeof callback === 'function' && callback(order)
                        this.close()
                    }
                } else {
                    next()
                }
            })
        }).then(() => {
            if (errors.length) {
                this.setState({errors})
            } else {
                typeof callback === 'function' && callback()
                this.close()
            }
        })
    }

    render() {
        const {isOpen, ids, date, comment, progressNow, loading, errors} = this.state

        return (
            <Modal show={isOpen} style={{width: 600, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Retour afzender</H4>

                    <div style={{flex: 1}}/>
                </div>

                <P style={{marginBottom: 24}}>Selecteer hieronder de datum waarop de retour rit wordt ingepland.</P>

                <DatePicker
                    label='Retour op'
                    value={date}
                    onChange={(event) => this.setState({date: event.target.value})}
                />

                <Input
                    label='Opmerking'
                    value={comment}
                    onChange={(event) => this.setState({comment: event.target.value})}
                />


                {loading && ids.length > 1 &&
                    <ProgressBar now={progressNow} max={ids.length}/>
                }


                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {ids.length > 1 &&
                        <>
                            <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check'/>
                            <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                        </>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!errors.length &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.confirm.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }


                    {!!errors.length &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default ReturnOrderPopup

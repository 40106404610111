import Reflux from 'reflux-react-16'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import CustomerActions from '../actions/CustomerActions.js'

export default class CustomerStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            customerQueries: [],
            customers: {},
            selectedCustomers: [],
            customer: {address: {}, settings: {orders: {}, parcels: {}}},
            customersLoading: false
        }

        this.listenables = CustomerActions

        CustomerActions.get(true)

        socket.on('updateCustomers', () => {
            CustomerActions.get()
        })
    }

    onSetQueries(customerQueries) {
        this.setState({customerQueries, selectedCustomers: []})
    }

    onSetSelected(selectedCustomers) {
        this.setState({selectedCustomers})
    }

    onGet(loading) {
        const {selectedCustomers} = this.state
        this.setState({customersLoading: !!loading})

        socket.emit('customers.get', sessionStorage.token, (err, customers) => {
            const ids = _.keys(customers)

            this.setState({
                customers,
                selectedCustomers: _.filter(selectedCustomers, (id) => ids.indexOf(id) > -1),
                customersLoading: false
            })
        })
    }

    onCreate(customer, callback) {
        socket.emit('customers.create', sessionStorage.token, customer, callback)
    }

    onEdit(customer, callback) {
        socket.emit('customers.edit', sessionStorage.token, customer, callback)
    }

    onToggleArchive(ids, callback) {
        socket.emit('customers.toggleArchive', sessionStorage.token, ids, callback)
    }

    onSync(callback) {
        socket.emit('customers.sync', sessionStorage.token, callback)
    }

    updatePriceTable(name, oldName, callback) {
        socket.emit('customers.updatePriceTable', sessionStorage.token, name, oldName, callback)
    }
}

import React from 'react'
import {Route} from 'react-router-dom'


import OrderOptions from './OrderOptions.js'
import VehicleTypes from './VehicleTypes.js'
import OrderPrices from './OrderPrices.js'
import EmailNotifications from './EmailNotifications.js'
import Connections from './Connections.js'
import TrackTrace from './TrackTrace.js'

class OrderSettings extends React.Component {
    componentDidMount() {
        const {history} = this.props

        if (history.location.pathname === '/instellingen/planning' || history.location.pathname === '/instellingen/planning/') {
            history.push('/instellingen/planning/opties')
        }
    }

    render() {
        const {reseller, history, onChange} = this.props

        return (
            <>
                <Route path="/instellingen/planning/opties" render={(props) => <OrderOptions reseller={reseller} history={history} onChange={onChange}/>}/>
                {reseller.permissions.vehicles && <Route path="/instellingen/planning/voertuig-typen" render={(props) => <VehicleTypes reseller={reseller} history={history} onChange={onChange}/>}/>}
                <Route path="/instellingen/planning/tarieven" render={(props) => <OrderPrices reseller={reseller} history={history} onChange={onChange}/>}/>
                <Route path="/instellingen/planning/emailnotificaties" render={(props) => <EmailNotifications reseller={reseller} history={history} onChange={onChange}/>}/>
                <Route path="/instellingen/planning/koppelingen" render={(props) => <Connections {...props} reseller={reseller} onChange={onChange}/>}/>
                <Route path="/instellingen/planning/tracktrace" render={(props) => <TrackTrace {...props} reseller={reseller} onChange={onChange}/>}/>
            </>
        )
    }
}

export default (OrderSettings)

import React from 'react'

import {Colors, Info, P} from '../UI/index.js'

class RadioButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {focused: false}
        this.onPressSpace = this.onPressSpace.bind(this)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onPressSpace)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onPressSpace)
    }

    onPressSpace(event) {
        const {focused} = this.state
        const {disabled, checked, onChange} = this.props


        if (focused && event.keyCode === 32) {
            if (!disabled && typeof onChange === 'function') {
                onChange({target: {checked: !checked}})
            }
        }
    }

    onClick() {
        const {disabled, value, onChange} = this.props

        if (!disabled && typeof onChange === 'function') {
            onChange({target: {value: value}})
        }
    }

    render() {
        const {disabled, checked, label, info, infoIcon, style={}, labelStyle={}, clickableLabel, hideDot, unsetWhiteSpace} = this.props

        const overLayStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: !disabled && (this.state.hover || this.state.focused) ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
            width: 36,
            height: 36,
            borderRadius: '50%',
            cursor: disabled ? 'default' : 'pointer'
        }

        const checkboxStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: disabled ? Colors.grey20 : Colors.backgroundWhite,
            width: 18,
            height: 18,
            borderRadius: '50%',
            border: `1px solid ${checked && !disabled ? Colors.buttonSolid : Colors.grey40}`,
            outline: 'none'
        }

        const dotStyle = {
            background: checked ? (disabled ? Colors.grey60 : Colors.buttonSolid) : 'transparent',
            width: checked ? 10 : 0,
            height: checked ? 10 : 0,
            borderRadius: '50%',
            transition: 'all .1s ease'
        }

        const defaultLabelStyle= {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 6,
            marginRight: 20,
            cursor: disabled ? 'default' : clickableLabel ? 'pointer' : 'auto',
            ...labelStyle
        }

        const defaultStyle = {display: 'flex', alignItems: 'center', height: 36, marginBottom: 12, ...style}

        return (
            <div style={defaultStyle} >
                {!hideDot &&
                    <div
                        style={overLayStyle}
                        onClick={this.onClick.bind(this)}
                        onMouseEnter={() => this.setState({hover: true})}
                        onMouseLeave={() => this.setState({hover: false})}
                    >
                        <div
                            tabIndex={disabled ? -1 : 0} style={checkboxStyle}
                            onFocus={() => this.setState({focused: true})}
                            onBlur={() => this.setState({focused: false})}
                        >
                            <div style={dotStyle}/>
                        </div>
                    </div>
                }

                {label &&
                    <P style={defaultLabelStyle} onClick={clickableLabel && this.onClick.bind(this)}>
                        <div style={{whiteSpace: unsetWhiteSpace ? 'unset' : 'nowrap'}}>{label}</div>
                        {info && <Info icon={infoIcon} text={info}/>}
                    </P>
                }
            </div>
        )
    }
}

export default RadioButton

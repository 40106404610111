export default [
    {name: 'Afghanistan', code2: 'AF', code3: 'AFG', category: 'Rest van wereld'},
    {name: 'Åland', code2: 'AX', code3: 'ALA', category: 'EU1'},
    {name: 'Albanië', code2: 'AL', code3: 'ALB', category: 'EU1'},
    {name: 'Algerije', code2: 'DZ', code3: 'DZA', category: 'Afrika'},
    {name: 'Amerikaanse Maagdeneilanden', code2: 'VI', code3: 'VIR', category: 'Noord Amerika'},
    {name: 'Amerikaans-Samoa', code2: 'AS', code3: 'ASM', category: 'Oceanië'},
    {name: 'Andorra', code2: 'AD', code3: 'AND', category: 'EU1'},
    {name: 'Angola', code2: 'AO', code3: 'AGO', category: 'Afrika'},
    {name: 'Anguilla', code2: 'AI', code3: 'AIA', category: 'Noord Amerika'},
    {name: 'Antarctica', code2: 'AQ', code3: 'ATA', category: 'Antarctica'},
    {name: 'Antigua en Barbuda', code2: 'AG', code3: 'ATG', category: 'Noord Amerika'},
    {name: 'Argentinië', code2: 'AR', code3: 'ARG', category: 'Zuid Amerika'},
    {name: 'Armenië', code2: 'AM', code3: 'ARM', category: 'Rest van wereld'},
    {name: 'Aruba', code2: 'AW', code3: 'ABW', category: 'Noord Amerika'},
    {name: 'Australië', code2: 'AU', code3: 'AUS', category: 'Oceanië'},
    {name: 'Azerbeidzjan', code2: 'AZ', code3: 'AZE', category: 'Rest van wereld'},
    {name: 'Bahama\'s', code2: 'BS', code3: 'BHS', category: 'Noord Amerika'},
    {name: 'Bahrein', code2: 'BH', code3: 'BHR', category: 'Rest van wereld'},
    {name: 'Bangladesh', code2: 'BD', code3: 'BGD', category: 'Rest van wereld'},
    {name: 'Barbados', code2: 'BB', code3: 'BRB', category: 'Noord Amerika'},
    {name: 'België', code2: 'BE', code3: 'BEL', category: 'EU1'},
    {name: 'Belize', code2: 'BZ', code3: 'BLZ', category: 'Noord Amerika'},
    {name: 'Benin', code2: 'BJ', code3: 'BEN', category: 'Afrika'},
    {name: 'Bermuda', code2: 'BM', code3: 'BMU', category: 'Noord Amerika'},
    {name: 'Bhutan', code2: 'BT', code3: 'BTN', category: 'Rest van wereld'},
    {name: 'Bolivia', code2: 'BO', code3: 'BOL', category: 'Zuid Amerika'},
    {name: 'Caribisch Nederland', code2: 'BQ', code3: 'BES', category: 'Noord Amerika'},
    {name: 'Bosnië en Herzegovina', code2: 'BA', code3: 'BIH', category: 'EU1'},
    {name: 'Botswana', code2: 'BW', code3: 'BWA', category: 'Afrika'},
    {name: 'Bouveteiland', code2: 'BV', code3: 'BVT', category: 'Antarctica'},
    {name: 'Brazilië', code2: 'BR', code3: 'BRA', category: 'Zuid Amerika'},
    {name: 'Britse Maagdeneilanden', code2: 'VG', code3: 'VGB', category: 'Noord Amerika'},
    {name: 'Brits Indische Oceaanterritorium', code2: 'IO', code3: 'IOT', category: 'Rest van wereld'},
    {name: 'Brunei', code2: 'BN', code3: 'BRN', category: 'Rest van wereld'},
    {name: 'Bulgarije', code2: 'BG', code3: 'BGR', category: 'EU1'},
    {name: 'Burkina Faso', code2: 'BF', code3: 'BFA', category: 'Afrika'},
    {name: 'Burundi', code2: 'BI', code3: 'BDI', category: 'Afrika'},
    {name: 'Cambodja', code2: 'KH', code3: 'KHM', category: 'Rest van wereld'},
    {name: 'Canada', code2: 'CA', code3: 'CAN', category: 'Noord Amerika'},
    {name: 'Canarische Eilanden', code2: 'IC', code3: 'ICA', category: 'EU2'},
    {name: 'Centraal-Afrikaanse Republiek', code2: 'CF', code3: 'CAF', category: 'Afrika'},
    {name: 'Chili', code2: 'CL', code3: 'CHL', category: 'Zuid Amerika'},
    {name: 'China', code2: 'CN', code3: 'CHN', category: 'Rest van wereld'},
    {name: 'Christmaseiland', code2: 'CX', code3: 'CXR', category: 'Rest van wereld'},
    {name: 'Cocoseilanden', code2: 'CC', code3: 'CCK', category: 'Rest van wereld'},
    {name: 'Colombia', code2: 'CO', code3: 'COL', category: 'Zuid Amerika'},
    {name: 'Comoren', code2: 'KM', code3: 'COM', category: 'Afrika'},
    {name: 'Congo-Brazzaville', code2: 'CG', code3: 'COG', category: 'Afrika'},
    {name: 'Congo-Kinshasa', code2: 'CD', code3: 'COD', category: 'Afrika'},
    {name: 'Cookeilanden', code2: 'CK', code3: 'COK', category: 'Oceanië'},
    {name: 'Costa Rica', code2: 'CR', code3: 'CRI', category: 'Noord Amerika'},
    {name: 'Cuba', code2: 'CU', code3: 'CUB', category: 'Noord Amerika'},
    {name: 'Curaçao', code2: 'CW', code3: 'CUW', category: 'Noord Amerika'},
    {name: 'Cyprus', code2: 'CY', code3: 'CYP', category: 'Rest van wereld'},
    {name: 'Denemarken', code2: 'DK', code3: 'DNK', category: 'EU1'},
    {name: 'Djibouti', code2: 'DJ', code3: 'DJI', category: 'Afrika'},
    {name: 'Dominica', code2: 'DM', code3: 'DMA', category: 'Noord Amerika'},
    {name: 'Dominicaanse Republiek', code2: 'DO', code3: 'DOM', category: 'Noord Amerika'},
    {name: 'Duitsland', code2: 'DE', code3: 'DEU', category: 'EU1'},
    {name: 'Ecuador', code2: 'EC', code3: 'ECU', category: 'Zuid Amerika'},
    {name: 'Egypte', code2: 'EG', code3: 'EGY', category: 'Afrika'},
    {name: 'El Salvador', code2: 'SV', code3: 'SLV', category: 'Noord Amerika'},
    {name: 'Equatoriaal-Guinea', code2: 'GQ', code3: 'GNQ', category: 'Afrika'},
    {name: 'Eritrea', code2: 'ER', code3: 'ERI', category: 'Afrika'},
    {name: 'Estland', code2: 'EE', code3: 'EST', category: 'EU1'},
    {name: 'Ethiopië', code2: 'ET', code3: 'ETH', category: 'Afrika'},
    {name: 'Faeröer', code2: 'FO', code3: 'FRO', category: 'EU1'},
    {name: 'Falklandeilanden', code2: 'FK', code3: 'FLK', category: 'Zuid Amerika'},
    {name: 'Fiji', code2: 'FJ', code3: 'FJI', category: 'Oceanië'},
    {name: 'Filipijnen', code2: 'PH', code3: 'PHL', category: 'Rest van wereld'},
    {name: 'Finland', code2: 'FI', code3: 'FIN', category: 'EU1'},
    {name: 'Frankrijk', code2: 'FR', code3: 'FRA', category: 'EU1'},
    {name: 'Franse Zuidelijke en Antarctische Gebieden', code2: 'TF', code3: 'ATF', category: 'Antarctica'},
    {name: 'Frans-Guyana', code2: 'GF', code3: 'GUF', category: 'Zuid Amerika'},
    {name: 'Frans-Polynesië', code2: 'PF', code3: 'PYF', category: 'Oceanië'},
    {name: 'Gabon', code2: 'GA', code3: 'GAB', category: 'Afrika'},
    {name: 'Gambia', code2: 'GM', code3: 'GMB', category: 'Afrika'},
    {name: 'Georgië', code2: 'GE', code3: 'GEO', category: 'Rest van wereld'},
    {name: 'Ghana', code2: 'GH', code3: 'GHA', category: 'Afrika'},
    {name: 'Gibraltar', code2: 'GI', code3: 'GIB', category: 'EU1'},
    {name: 'Grenada', code2: 'GD', code3: 'GRD', category: 'Noord Amerika'},
    {name: 'Griekenland', code2: 'GR', code3: 'GRC', category: 'EU1'},
    {name: 'Groenland', code2: 'GL', code3: 'GRL', category: 'Noord Amerika'},
    {name: 'Guadeloupe', code2: 'GP', code3: 'GLP', category: 'Noord Amerika'},
    {name: 'Guam', code2: 'GU', code3: 'GUM', category: 'Oceanië'},
    {name: 'Guatemala', code2: 'GT', code3: 'GTM', category: 'Noord Amerika'},
    {name: 'Guernsey', code2: 'GG', code3: 'GGY', category: 'EU1'},
    {name: 'Guinee', code2: 'GN', code3: 'GIN', category: 'Afrika'},
    {name: 'Guinee-Bissau', code2: 'GW', code3: 'GNB', category: 'Afrika'},
    {name: 'Guyana', code2: 'GY', code3: 'GUY', category: 'Zuid Amerika'},
    {name: 'Haïti', code2: 'HT', code3: 'HTI', category: 'Noord Amerika'},
    {name: 'Heard en McDonaldeilanden', code2: 'HM', code3: 'HMD', category: 'Antarctica'},
    {name: 'Honduras', code2: 'HN', code3: 'HND', category: 'Noord Amerika'},
    {name: 'Hongarije', code2: 'HU', code3: 'HUN', category: 'EU1'},
    {name: 'Hongkong', code2: 'HK', code3: 'HKG', category: 'Rest van wereld'},
    {name: 'Ierland', code2: 'IE', code3: 'IRL', category: 'EU1'},
    {name: 'IJsland', code2: 'IS', code3: 'ISL', category: 'EU1'},
    {name: 'India', code2: 'IN', code3: 'IND', category: 'Rest van wereld'},
    {name: 'Indonesië', code2: 'ID', code3: 'IDN', category: 'Rest van wereld'},
    {name: 'Irak', code2: 'IQ', code3: 'IRQ', category: 'Rest van wereld'},
    {name: 'Iran', code2: 'IR', code3: 'IRN', category: 'Rest van wereld'},
    {name: 'Israël', code2: 'IL', code3: 'ISR', category: 'Rest van wereld'},
    {name: 'Italië', code2: 'IT', code3: 'ITA', category: 'EU1'},
    {name: 'Ivoorkust', code2: 'CI', code3: 'CIV', category: 'Afrika'},
    {name: 'Jamaica', code2: 'JM', code3: 'JAM', category: 'Noord Amerika'},
    {name: 'Japan', code2: 'JP', code3: 'JPN', category: 'Rest van wereld'},
    {name: 'Jemen', code2: 'YE', code3: 'YEM', category: 'Rest van wereld'},
    {name: 'Jersey', code2: 'JE', code3: 'JEY', category: 'EU1'},
    {name: 'Jordanië', code2: 'JO', code3: 'JOR', category: 'Rest van wereld'},
    {name: 'Kaaimaneilanden', code2: 'KY', code3: 'CYM', category: 'Noord Amerika'},
    {name: 'Kaapverdië', code2: 'CV', code3: 'CPV', category: 'Afrika'},
    {name: 'Kameroen', code2: 'CM', code3: 'CMR', category: 'Afrika'},
    {name: 'Kazachstan', code2: 'KZ', code3: 'KAZ', category: 'Rest van wereld'},
    {name: 'Kenia', code2: 'KE', code3: 'KEN', category: 'Afrika'},
    {name: 'Kirgizië', code2: 'KG', code3: 'KGZ', category: 'Rest van wereld'},
    {name: 'Kiribati', code2: 'KI', code3: 'KIR', category: 'Oceanië'},
    {name: 'Kleine afgelegen eilanden van de Verenigde Staten', code2: 'UM', code3: 'UMI', category: 'Oceanië'},
    {name: 'Koeweit', code2: 'KW', code3: 'KWT', category: 'Rest van wereld'},
    {name: 'Kroatië', code2: 'HR', code3: 'HRV', category: 'EU1'},
    {name: 'Laos', code2: 'LA', code3: 'LAO', category: 'Rest van wereld'},
    {name: 'Lesotho', code2: 'LS', code3: 'LSO', category: 'Afrika'},
    {name: 'Letland', code2: 'LV', code3: 'LVA', category: 'EU1'},
    {name: 'Libanon', code2: 'LB', code3: 'LBN', category: 'Rest van wereld'},
    {name: 'Liberia', code2: 'LR', code3: 'LBR', category: 'Afrika'},
    {name: 'Libië', code2: 'LY', code3: 'LBY', category: 'Afrika'},
    {name: 'Liechtenstein', code2: 'LI', code3: 'LIE', category: 'EU1'},
    {name: 'Litouwen', code2: 'LT', code3: 'LTU', category: 'EU1'},
    {name: 'Luxemburg', code2: 'LU', code3: 'LUX', category: 'EU1'},
    {name: 'Macau', code2: 'MO', code3: 'MAC', category: 'Rest van wereld'},
    {name: 'Macedonië', code2: 'MK', code3: 'MKD', category: 'EU1'},
    {name: 'Madagaskar', code2: 'MG', code3: 'MDG', category: 'Afrika'},
    {name: 'Malawi', code2: 'MW', code3: 'MWI', category: 'Afrika'},
    {name: 'Maldiven', code2: 'MV', code3: 'MDV', category: 'Rest van wereld'},
    {name: 'Maleisië', code2: 'MY', code3: 'MYS', category: 'Rest van wereld'},
    {name: 'Mali', code2: 'ML', code3: 'MLI', category: 'Afrika'},
    {name: 'Malta', code2: 'MT', code3: 'MLT', category: 'EU1'},
    {name: 'Man', code2: 'IM', code3: 'IMN', category: 'EU1'},
    {name: 'Marokko', code2: 'MA', code3: 'MAR', category: 'Afrika'},
    {name: 'Marshalleilanden', code2: 'MH', code3: 'MHL', category: 'Oceanië'},
    {name: 'Martinique', code2: 'MQ', code3: 'MTQ', category: 'Noord Amerika'},
    {name: 'Mauritanië', code2: 'MR', code3: 'MRT', category: 'Afrika'},
    {name: 'Mauritius', code2: 'MU', code3: 'MUS', category: 'Afrika'},
    {name: 'Mayotte', code2: 'YT', code3: 'MYT', category: 'Afrika'},
    {name: 'Mexico', code2: 'MX', code3: 'MEX', category: 'Noord Amerika'},
    {name: 'Micronesia', code2: 'FM', code3: 'FSM', category: 'Oceanië'},
    {name: 'Moldavië', code2: 'MD', code3: 'MDA', category: 'EU1'},
    {name: 'Monaco', code2: 'MC', code3: 'MCO', category: 'EU1'},
    {name: 'Mongolië', code2: 'MN', code3: 'MNG', category: 'Rest van wereld'},
    {name: 'Montenegro', code2: 'ME', code3: 'MNE', category: 'EU1'},
    {name: 'Montserrat', code2: 'MS', code3: 'MSR', category: 'Noord Amerika'},
    {name: 'Mozambique', code2: 'MZ', code3: 'MOZ', category: 'Afrika'},
    {name: 'Myanmar', code2: 'MM', code3: 'MMR', category: 'Rest van wereld'},
    {name: 'Namibië', code2: 'NA', code3: 'NAM', category: 'Afrika'},
    {name: 'Nauru', code2: 'NR', code3: 'NRU', category: 'Oceanië'},
    {name: 'Nederland', code2: 'NL', code3: 'NLD', category: 'Nederland'},
    {name: 'Nepal', code2: 'NP', code3: 'NPL', category: 'Rest van wereld'},
    {name: 'Nicaragua', code2: 'NI', code3: 'NIC', category: 'Noord Amerika'},
    {name: 'Nieuw-Caledonië', code2: 'NC', code3: 'NCL', category: 'Oceanië'},
    {name: 'Nieuw-Zeeland', code2: 'NZ', code3: 'NZL', category: 'Oceanië'},
    {name: 'Niger', code2: 'NE', code3: 'NER', category: 'Afrika'},
    {name: 'Nigeria', code2: 'NG', code3: 'NGA', category: 'Afrika'},
    {name: 'Niue', code2: 'NU', code3: 'NIU', category: 'Oceanië'},
    {name: 'Noordelijke Marianen', code2: 'MP', code3: 'MNP', category: 'Oceanië'},
    {name: 'Noord-Korea', code2: 'KP', code3: 'PRK', category: 'Rest van wereld'},
    {name: 'Noorwegen', code2: 'NO', code3: 'NOR', category: 'EU1'},
    {name: 'Norfolk', code2: 'NF', code3: 'NFK', category: 'Oceanië'},
    {name: 'Oeganda', code2: 'UG', code3: 'UGA', category: 'Afrika'},
    {name: 'Oekraïne', code2: 'UA', code3: 'UKR', category: 'EU1'},
    {name: 'Oezbekistan', code2: 'UZ', code3: 'UZB', category: 'Rest van wereld'},
    {name: 'Oman', code2: 'OM', code3: 'OMN', category: 'Rest van wereld'},
    {name: 'Oostenrijk', code2: 'AT', code3: 'AUT', category: 'EU1'},
    {name: 'Oost-Timor', code2: 'TL', code3: 'TLS', category: 'Rest van wereld'},
    {name: 'Pakistan', code2: 'PK', code3: 'PAK', category: 'Rest van wereld'},
    {name: 'Palau', code2: 'PW', code3: 'PLW', category: 'Oceanië'},
    {name: 'Palestina', code2: 'PS', code3: 'PSE', category: 'Rest van wereld'},
    {name: 'Panama', code2: 'PA', code3: 'PAN', category: 'Noord Amerika'},
    {name: 'Papoea-Nieuw-Guinea', code2: 'PG', code3: 'PNG', category: 'Oceanië'},
    {name: 'Paraguay', code2: 'PY', code3: 'PRY', category: 'Zuid Amerika'},
    {name: 'Peru', code2: 'PE', code3: 'PER', category: 'Zuid Amerika'},
    {name: 'Pitcairneilanden', code2: 'PN', code3: 'PCN', category: 'Oceanië'},
    {name: 'Polen', code2: 'PL', code3: 'POL', category: 'EU1'},
    {name: 'Portugal', code2: 'PT', code3: 'PRT', category: 'EU1'},
    {name: 'Puerto Rico', code2: 'PR', code3: 'PRI', category: 'Noord Amerika'},
    {name: 'Qatar', code2: 'QA', code3: 'QAT', category: 'Rest van wereld'},
    {name: 'Réunion', code2: 'RE', code3: 'REU', category: 'Afrika'},
    {name: 'Roemenië', code2: 'RO', code3: 'ROU', category: 'EU1'},
    {name: 'Rusland', code2: 'RU', code3: 'RUS', category: 'EU1'},
    {name: 'Rwanda', code2: 'RW', code3: 'RWA', category: 'Afrika'},
    {name: 'Saint-Barthélemy', code2: 'BL', code3: 'BLM', category: 'Noord Amerika'},
    {name: 'Saint Kitts en Nevis', code2: 'KN', code3: 'KNA', category: 'Noord Amerika'},
    {name: 'Saint Lucia', code2: 'LC', code3: 'LCA', category: 'Noord Amerika'},
    {name: 'Saint-Pierre en Miquelon', code2: 'PM', code3: 'SPM', category: 'Noord Amerika'},
    {name: 'Saint Vincent en de Grenadines', code2: 'VC', code3: 'VCT', category: 'Noord Amerika'},
    {name: 'Salomonseilanden', code2: 'SB', code3: 'SLB', category: 'Oceanië'},
    {name: 'Samoa', code2: 'WS', code3: 'WSM', category: 'Oceanië'},
    {name: 'San Marino', code2: 'SM', code3: 'SMR', category: 'EU1'},
    {name: 'Saoedi-Arabië', code2: 'SA', code3: 'SAU', category: 'Rest van wereld'},
    {name: 'Sao Tomé en Principe', code2: 'ST', code3: 'STP', category: 'Afrika'},
    {name: 'Senegal', code2: 'SN', code3: 'SEN', category: 'Afrika'},
    {name: 'Servië', code2: 'RS', code3: 'SRB', category: 'EU1'},
    {name: 'Seychellen', code2: 'SC', code3: 'SYC', category: 'Afrika'},
    {name: 'Sierra Leone', code2: 'SL', code3: 'SLE', category: 'Afrika'},
    {name: 'Singapore', code2: 'SG', code3: 'SGP', category: 'Rest van wereld'},
    {name: 'Sint-Helena Ascension en Tristan da Cunha', code2: 'SH', code3: 'SHN', category: 'Afrika'},
    {name: 'Sint-Maarten', code2: 'MF', code3: 'MAF', category: 'Noord Amerika'},
    {name: 'Sint Maarten', code2: 'SX', code3: 'SXM', category: 'Noord Amerika'},
    {name: 'Slovenië', code2: 'SI', code3: 'SVN', category: 'EU1'},
    {name: 'Slowakije', code2: 'SK', code3: 'SVK', category: 'EU1'},
    {name: 'Soedan', code2: 'SD', code3: 'SDN', category: 'Afrika'},
    {name: 'Somalië', code2: 'SO', code3: 'SOM', category: 'Afrika'},
    {name: 'Spanje', code2: 'ES', code3: 'ESP', category: 'EU1'},
    {name: 'Spitsbergen en Jan Mayen', code2: 'SJ', code3: 'SJM', category: 'EU1'},
    {name: 'Sri Lanka', code2: 'LK', code3: 'LKA', category: 'Rest van wereld'},
    {name: 'Suriname', code2: 'SR', code3: 'SUR', category: 'Zuid Amerika'},
    {name: 'Swaziland', code2: 'SZ', code3: 'SWZ', category: 'Afrika'},
    {name: 'Syrië', code2: 'SY', code3: 'SYR', category: 'Rest van wereld'},
    {name: 'Tadzjikistan', code2: 'TJ', code3: 'TJK', category: 'Rest van wereld'},
    {name: 'Taiwan', code2: 'TW', code3: 'TWN', category: 'Rest van wereld'},
    {name: 'Tanzania', code2: 'TZ', code3: 'TZA', category: 'Afrika'},
    {name: 'Thailand', code2: 'TH', code3: 'THA', category: 'Rest van wereld'},
    {name: 'Togo', code2: 'TG', code3: 'TGO', category: 'Afrika'},
    {name: 'Tokelau', code2: 'TK', code3: 'TKL', category: 'Oceanië'},
    {name: 'Tonga', code2: 'TO', code3: 'TON', category: 'Oceanië'},
    {name: 'Trinidad en Tobago', code2: 'TT', code3: 'TTO', category: 'Noord Amerika'},
    {name: 'Tsjaad', code2: 'TD', code3: 'TCD', category: 'Afrika'},
    {name: 'Tsjechië', code2: 'CZ', code3: 'CZE', category: 'EU1'},
    {name: 'Tunesië', code2: 'TN', code3: 'TUN', category: 'Afrika'},
    {name: 'Turkije', code2: 'TR', code3: 'TUR', category: 'EU1'},
    {name: 'Turkmenistan', code2: 'TM', code3: 'TKM', category: 'Rest van wereld'},
    {name: 'Turks- en Caicoseilanden', code2: 'TC', code3: 'TCA', category: 'Noord Amerika'},
    {name: 'Tuvalu', code2: 'TV', code3: 'TUV', category: 'Oceanië'},
    {name: 'Uruguay', code2: 'UY', code3: 'URY', category: 'Zuid Amerika'},
    {name: 'Vanuatu', code2: 'VU', code3: 'VUT', category: 'Oceanië'},
    {name: 'Vaticaanstad', code2: 'VA', code3: 'VAT', category: 'EU1'},
    {name: 'Venezuela', code2: 'VE', code3: 'VEN', category: 'Zuid Amerika'},
    {name: 'Verenigde Arabische Emiraten', code2: 'AE', code3: 'ARE', category: 'Rest van wereld'},
    {name: 'Verenigde Staten', code2: 'US', code3: 'USA', category: 'Noord Amerika'},
    {name: 'Verenigd Koninkrijk', code2: 'GB', code3: 'GBR', category: 'EU1'},
    {name: 'Vietnam', code2: 'VN', code3: 'VNM', category: 'Rest van wereld'},
    {name: 'Wallis en Futuna', code2: 'WF', code3: 'WLF', category: 'Oceanië'},
    {name: 'Westelijke Sahara', code2: 'EH', code3: 'ESH', category: 'Afrika'},
    {name: 'Wit-Rusland', code2: 'BY', code3: 'BLR', category: 'EU1'},
    {name: 'Zambia', code2: 'ZM', code3: 'ZMB', category: 'Afrika'},
    {name: 'Zimbabwe', code2: 'ZW', code3: 'ZWE', category: 'Afrika'},
    {name: 'Zuid-Afrika', code2: 'ZA', code3: 'ZAF', category: 'Afrika'},
    {name: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden', code2: 'GS', code3: 'SGS', category: 'Antarctica'},
    {name: 'Zuid-Korea', code2: 'KR', code3: 'KOR', category: 'Rest van wereld'},
    {name: 'Zuid-Soedan', code2: 'SS', code3: 'SSD', category: 'Afrika'},
    {name: 'Zweden', code2: 'SE', code3: 'SWE', category: 'EU1'},
    {name: 'Zwitserland', code2: 'CH', code3: 'CHE', category: 'EU1'}
]

function reducer(state, action) {
    const newState = {
        ...state
    }

    Object.keys(action).forEach((key) => {
        newState[key] = action[key]
    })

    return newState
}

export default reducer

import React, {useState, useEffect, useRef} from 'react'
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from '@dnd-kit/sortable'
import {
    restrictToVerticalAxis,
    restrictToParentElement
} from '@dnd-kit/modifiers'
import SortableItem from './SortableItem.js'


export default function SortableList(props) {
    const [height, setHeight] = useState(100)

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // Require pointer to move by 5 pixels before activating draggable
                // Allows nested onClicks/buttons/interactions to be accessed
                distance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        }),
        useSensor(TouchSensor)
    )

    function handleDragEnd(event) {
        const {active, over} = event

        if (active.id !== over.id) {
            const oldIndex = props.items.findIndex((item) => item.id === active.id)
            const newIndex = props.items.findIndex((item) => item.id === over.id)

            props.onChange(arrayMove(props.items, oldIndex, newIndex))
        }
    }

    const containerRef = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setHeight(event[0].contentBoxSize[0].blockSize - 20) // -20 to prevent overflow
        })

        if (containerRef) {
            resizeObserver.observe(containerRef.current)
        }

        return () => {
            resizeObserver.unobserve(containerRef.current)
        }
    })


    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={handleDragEnd}
        >
            <div style={{height: '100%', width: '100%'}} ref={containerRef}>
                <div style={{height, width: '100%', overflowY: 'scroll'}}>
                    <SortableContext
                        items={props.items || []}
                        strategy={verticalListSortingStrategy}
                    >
                        {(props.items || []).map((item) => {
                            return (
                                <SortableItem key={item.id} id={item.id} disabled={props.isDisabled(item)}>
                                    {props.renderItem(item)}
                                </SortableItem>
                            )
                        })}
                    </SortableContext>
                </div>
            </div>
        </DndContext>

    )
}


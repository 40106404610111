import React from 'react'

import Guid from '../../../utils/guid.js'

import {Colors, Input} from '../UI/index.js'
import {Button, IconButton, Modal, H4} from '../UI/index.js'

class SensorModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            newSensor: true,
            sensor: {id: '', name: '', sensorId: '', minTemperature: 0, maxTemperature: 0},
            validation: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(sensor, index) {
        sensor = sensor ? JSON.parse(JSON.stringify(sensor)) : {id: Guid.generate(), name: '', sensorId: '', minTemperature: 0, maxTemperature: 0}

        this.setState({
            modalIsOpen: true,
            newSensor: typeof index === 'number',
            index,
            sensor
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const sensor = {...this.state.sensor}
        sensor[key] = event.target.value
        this.setState({sensor})
    }

    onChangeChecked(key, event) {
        const sensor = {...this.state.sensor}
        sensor[key] = event.target.checked
        this.setState({sensor})
    }

    onValidate() {
        const sensor = {...this.state.sensor}
        const validation = {}
        let id = ''
        let valid = true


        if (!sensor.sensorId) {
            id = 'sensorId'
            validation.sensorId = true
            valid = false
        }

        if (!sensor.name) {
            id = 'name'
            validation.name = true
            valid = false
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    onRemove(event) {
        event.preventDefault()

        this.props.onRemove(this.state.index)
        this.close()
    }

    onSubmit(event) {
        event.preventDefault()

        if (this.onValidate()) {
            this.props.onSubmit(this.state.sensor, this.state.index)
            this.close()
        }
    }

    render() {
        const {modalIsOpen, sensor, validation} = this.state

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{sensor.id ? 'Bewerk sensor' : 'Nieuwe sensor'}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <br/>
                <form onSubmit={this.onSubmit.bind(this)}>

                    <Input
                        id='name'
                        label='Naam'
                        value={sensor.name}
                        isInvalid={validation.name}
                        onChange={this.onChangeValue.bind(this, 'name')}
                    />

                    <Input
                        id='sensorId'
                        label='Sensor ID'
                        value={sensor.sensorId}
                        isInvalid={validation.sensorId}
                        onChange={this.onChangeValue.bind(this, 'sensorId')}
                    />

                    <div style={{display: 'flex'}}>
                        <Input
                            id='minTemperature'
                            type='number'
                            style={{marginRight: 6}}
                            label='Minimum temperatuur'
                            value={sensor.minTemperature}
                            isInvalid={validation.minTemperature}
                            onChange={this.onChangeValue.bind(this, 'minTemperature')}
                            append='°C'
                        />

                        <Input
                            id='maxTemperature'
                            type='number'
                            style={{marginLeft: 6}}
                            label='Maximum temperatuur'
                            value={sensor.maxTemperature}
                            isInvalid={validation.maxTemperature}
                            onChange={this.onChangeValue.bind(this, 'maxTemperature')}
                            append='°C'
                        />
                    </div>


                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant='error' onClick={this.onRemove.bind(this)}>
                            Verwijder
                        </Button>

                        <Button type="submit">
                            Opslaan
                        </Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default (SensorModal)

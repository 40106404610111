import React from 'react'
import {Route} from 'react-router-dom'


import APISettings from './APISettings.js'
import WebshopSettings from './WebshopSettings.js'

class Layout extends React.Component {
    componentDidMount() {
        const {history} = this.props

        if (history.location.pathname === '/klant/instellingen/koppelingen' || history.location.pathname === '/klant/instellingen/koppelingen/') {
            history.push('/klant/instellingen/koppelingen/api')
        }
    }

    render() {
        const {reseller, user, onChange} = this.props

        return (
            <>
                <Route path="/klant/instellingen/koppelingen/api" render={(props) => <APISettings {...props} user={user} onChange={onChange}/>}/>
                <Route path="/klant/instellingen/koppelingen/webshops" render={(props) => <WebshopSettings {...props} reseller={reseller} user={user} onChange={onChange}/>}/>
            </>
        )
    }
}

export default (Layout)

import React from 'react'


import {Button, Modal, H3, H5, S2} from '../../../src/components/UI/index.js'
import {Check} from '../../../src/components/UI/index.js'


class NewVersionPopup extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            isOpen: false,
            language: 'NL',
            dontShowAgain: false
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(onClose) {
        this.setState({isOpen: true, onClose})
    }

    close() {
        const {onClose, dontShowAgain} = this.state

        this.setState({isOpen: false}, () => {
            this.setState(this.initialState)
            typeof onClose === 'function' && onClose(dontShowAgain)
        })
    }

    render() {
        const {isOpen, language, dontShowAgain} = this.state

        return (
            <Modal show={isOpen} style={{width: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <H3>Veloyd update</H3>
                </div>
                <br/>

                <img style={{width: '100%', objectFit: 'contain'}} src='/images/screenschots/address.png'/>

                <br/>
                <br/>
                <H5>
                    {language === 'NL' ?
                        'Nieuwe adres invoer bij ritten':
                        ''
                    }
                </H5>
                <S2 style={{marginBottom: 30}}>
                    {language === 'NL' ?
                        `De invoer van adressen bij ritten is veranderd. Er is nu één invoerveld voor het adres, hiermee kan een adres gezocht worden op postcode of straat en huisnummer.
                        Op basis van de input worden adressen gezocht die als suggestie worden getoond. Let op dat een suggestie geselecteerd moet worden om een geldig adres
                        in te vullen, dit kan door op enter te drukken (met de pijltjes toetsen kan een andere suggestie geselecteerd worden) of op een suggestie te klikken.
                        Als het adres wordt uitklapt kunnen alle velden van het adres worden aangepast.`:
                        ''
                    }
                </S2>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Check
                        label={language === 'NL' ? 'Niet meer tonen' : 'Don\'t show again'}
                        checked={dontShowAgain}
                        onChange={() => this.setState({dontShowAgain: !dontShowAgain})}
                    />

                    <Button
                        variant='text'
                        onClick={this.close.bind(this)}
                    >
                        {language === 'NL' ?
                            'Sluiten' :
                            'Close'
                        }
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default (NewVersionPopup)

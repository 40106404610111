import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'verifySMTP',
    'sendEmailPreview',
    'trackTrace',
    'getReturnCustomer',
    'getParcelForReturn',
    'createReturnParcel',
    'getOptionsReturnPortal',
    'createPayment',
    'sendErrorEmail'
])

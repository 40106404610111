import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'underscore'
import moment from 'moment'

import UserStore from '../../stores/UserStore.js'
import UserActions from '../../actions/UserActions.js'

import UserModal from '../../components/users/UserModal.js'
import {Button, Colors, IconButton, Panel, Popup} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import csvExport from '../../utils/csvExport.js'
import filter from '../../utils/filter.js'
import ImportUserModal from '../../components/users/ImportUserModal.js'

class Users extends Reflux.Component {
    constructor(props) {
        super(props)
        this.store = UserStore
    }

    componentDidMount() {
        const {reseller} = this.props
        document.title = `Gebruikers • ${reseller.settings.accountName || reseller.name}`
    }

    columns() {
        return [
            {title: 'Naam', visible: true, key: 'name', flex: 1},
            {title: 'Telefoon', visible: true, key: 'phone', flex: 1},
            {title: 'Emailadres', visible: true, key: 'email', flex: 1},
            {title: 'Laatst actief', visible: true, key: 'lastActive', flex: 1, render: (user) => {
                return user.lastActive ? moment(user.lastActive).format('DD-MM-YYYY HH:mm') : ''
            }},
            {title: '', visible: true, key: '', width: 60, onClick: () => {}, render: (user) => {
                return (
                    <IconButton onClick={this.onClickEdit.bind(this, user)} >
                        <i className="mdi mdi-pencil"/>
                    </IconButton>
                )
            }}
        ]
    }

    onChangeQueries(userQueries) {
        this.table.selectPage(1)
        UserActions.setQueries(userQueries)
    }

    onChangeTable(selectedUsers) {
        UserActions.setSelected(selectedUsers)
    }

    onClickNew() {
        this.modal.open()
        UserActions.setSelected([])
    }

    onClickImport() {
        this.importModal.open()
        UserActions.setSelected([])
    }

    onClickEdit(user) {
        this.modal.open(user)
    }

    onClickRemove() {
        const {selectedUsers} = this.state

        this.popup.open('Gebruikers verwijderen', 'Gebruikers worden definitief verwijderd.', () => {
            UserActions.setSelected([])

            UserActions.remove(selectedUsers, (err) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    onClickExport() {
        const {users, selectedUsers} = this.state
        const exportUsers = []

        selectedUsers.map((id) => {
            const user = _.findWhere(users, {_id: id})
            if (user) {
                exportUsers.push(user)
            }
        })

        csvExport.users(exportUsers)
        UserActions.setSelected([])
    }

    render() {
        const {selectedUsers, usersLoading, userQueries} = this.state
        const {reseller, user} = this.props

        const users = filter.users(this.state.users, userQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        queries={userQueries}
                    />

                    <Button
                        variant='outline-white'
                        onClick={this.onClickNew.bind(this)}
                    >
                        Nieuwe Gebruiker
                    </Button>

                    <Button
                        variant='outline-white'
                        onClick={this.onClickImport.bind(this)}
                    >
                        <i className="mdi mdi-file-import"/>
                    </Button>

                    {selectedUsers.length > 0 &&
                    <>
                        <Button
                            variant='outline-white'
                            onClick={this.onClickExport.bind(this)}
                        >
                            <i className="mdi mdi-file-export"/>
                        </Button>

                        <Button
                            variant='outline-white'
                            onClick={this.onClickRemove.bind(this)}
                        >
                            <i className="mdi mdi-delete"/>
                        </Button>
                    </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='users'
                        columns={this.columns()}
                        rows={users}
                        selectedRows={selectedUsers}
                        loading={usersLoading}
                        onChange={this.onChangeTable.bind(this)}
                        sortKey='name'
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <UserModal reseller={reseller} activeUser={user} ref={(ref) => this.modal = ref}/>

                <ImportUserModal
                    ref={(modal) => this.importModal = modal}
                />
                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default (Users)

import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter, Route, Switch} from 'react-router-dom'


import Login from './views/utils/Login.js'

import PlannerLayout from './views/planner/Layout.js'
import CustomerLayout from './views/customer/Layout.js'
import AdminLayout from './views/admin/Layout.js'

import ForgotPassword from './views/utils/ForgotPassword.js'
import ResetPassword from './views/utils/ResetPassword.js'

import TrackTraceSearch from './views/utils/TrackTraceSearch.js'
import TrackTrace from './views/utils/TrackTrace.js'

import ReturnPortal from './views/utils/ReturnPortal.js'
import Components from './views/utils/Components.js'

import ErrorHandler from './components/UI/ErrorHandler.js'

const router = (
    <ErrorHandler>
        <BrowserRouter>
            <Switch>
                <Route exact path="/componenten" component={Components}/>
                <Route exact path="/retourportaal/:customerURL?/:orderNumberOrTrackTrace?/:postalCode?" component={ReturnPortal}/>

                <Route exact path="/track&trace" component={TrackTraceSearch}/>
                <Route exact path="/track&trace/:trackTrace/:addressIndex?" component={TrackTrace}/>

                <Route path="/wachtwoordvergeten" component={ForgotPassword}/>
                <Route path="/wachtwoordwijzigen/:token" component={ResetPassword}/>

                <Route path="/admin" component={AdminLayout}/>
                <Route path="/klant" component={CustomerLayout}/>

                <Route exact path="/login" component={Login}/>
                <Route path="/" component={PlannerLayout}/>
            </Switch>

        </BrowserRouter>
    </ErrorHandler>
)

render(router, document.getElementById('react-container'))

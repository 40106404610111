import React, {useReducer, forwardRef, useImperativeHandle} from 'react'
import aSync from 'async'

import {Modal, H4, S2, Button, P, Check, TimePicker, Alert} from '../UI/index.js'
import reducer from '../../helpers/reducer.js'

import OrderActions from '../../actions/OrderActions.js'

const initialState = {
    isOpen: false,
    ids: [],
    startTimeEnabled: false,
    endTimeEnabled: false,
    startTime: '',
    endTime: '',
    loading: false,
    errors: []
}

const ChangeTimeslotPopup = forwardRef((props, ref) => {
    const [{isOpen, ids, startTimeEnabled, endTimeEnabled, startTime, endTime, errors, loading}, dispatch] = useReducer(reducer, initialState)

    const open = (ids) => {
        dispatch({isOpen: true, ids})
    }

    const close = () => {
        dispatch(initialState)
    }

    const onConfirm = async () => {
        OrderActions.setSelected([])

        dispatch({loading: true})

        const errors = []

        await aSync.eachSeries(ids, (id, next) => {
            OrderActions.changeTimeslot(id, startTimeEnabled && startTime, endTimeEnabled && endTime, (err) => {
                if (err) {
                    errors.push(err)
                }

                next()
            })
        })

        if (errors.length) {
            dispatch({errors, loading: false})
        } else {
            close()
        }
    }

    // To make passed functions accessible to parents
    useImperativeHandle(ref, () => ({open, close}))


    return (
        <Modal style={{maxWidth: 400}} show={isOpen}>
            <div style={{display: 'flex', marginBottom: 24}}>
                <H4>Tijdvak wijziging</H4>

                <div style={{flex: 1}}/>
            </div>

            <P style={{marginBottom: 10}}>Wijzig de starttijd en/of de eindtijd van alle adressen in de geselecteerde ritten.</P>

            <div>
                <div style={{display: 'flex'}}>
                    <Check onChange={(event) => dispatch({startTimeEnabled: event.target.checked})} checked={startTimeEnabled}/>
                    <TimePicker
                        label="Starttijd"
                        time={startTime}
                        onChange={(event) => dispatch({startTime: event.target.value})}
                        disabled={!startTimeEnabled}
                    />
                </div>
                <div style={{display: 'flex'}}>
                    <Check onChange={(event) => dispatch({endTimeEnabled: event.target.checked})} checked={endTimeEnabled}/>
                    <TimePicker
                        label="Eindtijd"
                        time={endTime}
                        onChange={(event) => dispatch({endTime: event.target.value})}
                        disabled={!endTimeEnabled}
                    />
                </div>
            </div>


            {errors.length > 0 && errors.map((error, index) => {
                return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
            })}

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                {ids.length > 1 &&
                <>
                    <i style={{fontSize: 12, marginLeft: 6, marginRight: 3}} className='mdi mdi-check'/>
                    <S2 style={{flex: 1}}>{`${ids.length} ritten geselecteerd`}</S2>

                </>
                }

                {!errors.length &&
                <Button
                    variant='text'
                    onClick={close}
                >
                    Annuleren
                </Button>
                }

                {!errors.length &&
                <Button
                    variant='text'
                    loading={loading}
                    onClick={onConfirm}
                >
                    Bevestigen
                </Button>
                }


                {!!errors.length &&
                <Button
                    style={{marginBottom: 0}}
                    variant='text'
                    onClick={close}
                >
                    Sluiten
                </Button>
                }
            </div>
        </Modal>
    )
})

export default ChangeTimeslotPopup

import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, Card, Colors, IconButton, H6, S1, P, Icon, AddressAttachment} from '../UI/index.js'
import EditAddress from './EditAddress.js'


class Address extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            hover: false,
            loading: true,
            error: '',
            message: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }


    saveAddress(address) {
        const {order, addressIndex} = this.props

        this.setState({hover: false, edit: false, error: ''})

        OrderActions.editAddress(order._id, addressIndex, address, (err, order) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {hover, edit, error} = this.state
        const {order, addressIndex, addressBook} = this.props

        const address = order.addresses[addressIndex]

        return (
            <div style={{width: '100%', marginBottom: 12}}>

                <Card
                    style={{position: 'relative', display: 'flex', padding: 0, borderRadius: '0px 4px 4px 4px', background: edit ? Colors.backgroundNeutral2 : Colors.backgroundWhite}}
                >

                    <div style={{position: 'absolute', left: -6, top: 0, height: 36, width: 6, borderRadius: '4px 0px 0px 4px', background: Colors[address.type]}}/>

                    {!edit &&
                        <div style={{display: 'flex', width: '100%'}} >
                            <div
                                style={{display: 'flex', flex: 1, borderRight: `1px dotted ${Colors.border}`}}
                                onMouseEnter={() => this.setState({hover: true})}
                                onMouseLeave={() => this.setState({hover: false})}
                            >
                                <div style={{flex: 1, padding: 6}} >

                                    <S1 style={{display: 'flex', alignItems: 'center'}}>
                                        <i style={{marginRight: 6, fontSize: 16}} className='mdi mdi-map-marker'/>
                                        {`${address.street} ${address.nr}${address.addition}`}
                                    </S1>
                                    <P style={{marginLeft: 25}}>{address.street2}</P>
                                    <P style={{marginLeft: 25, marginBottom: 12}}>{`${address.postalCode} ${address.city} (${address.country})`}</P>

                                    {address.name &&
                                        <P style={{display: 'flex', alignItems: 'center'}}>
                                            <i style={{marginRight: 6, fontSize: 16}} className={address.isBusiness ? 'mdi mdi-store' : address.attention ? 'mdi mdi-home' : 'mdi mdi-account'}/>
                                            {address.name}
                                        </P>
                                    }

                                    {address.attention &&
                                        <P style={{display: 'flex', alignItems: 'center'}}>
                                            <i style={{marginRight: 6, fontSize: 16}} className={'mdi mdi-account'}/>
                                            {address.attention}
                                        </P>
                                    }

                                    {address.phone &&
                                        <P style={{display: 'flex', alignItems: 'center'}}>
                                            <i style={{marginRight: 6, fontSize: 16}} className='mdi mdi-phone'/>
                                            {address.phone}
                                        </P>
                                    }

                                    {address.email &&
                                        <P style={{display: 'flex', alignItems: 'center'}}>
                                            <i style={{marginRight: 6, fontSize: 16}} className='mdi mdi-at'/>
                                            {address.email}
                                        </P>
                                    }

                                    {address.attachments && address.attachments.length > 0 &&
                                        <P style={{display: 'flex', alignItems: 'center'}}>
                                            <AddressAttachment address={address}/>
                                        </P>
                                    }
                                </div>

                                <div style={{height: 42, width: 42}}>
                                    {address.type === 'delivery' && hover && !edit &&
                                        <IconButton
                                            onClick={() => this.props.onClickPrintLabels(order._id, address.index)}
                                            icon='mdi mdi-printer'
                                        />
                                    }
                                </div>

                                <div style={{height: 42, width: 42}}>
                                    {hover && !edit &&
                                        <IconButton
                                            icon='mdi mdi-pencil'
                                            onClick={() => this.setState({edit: !edit})}
                                        />
                                    }
                                </div>
                            </div>


                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <div style={{flex: 1, padding: 6}}>
                                    <S1>{address.type === 'pickup' ? 'Ophalen' : 'Bezorgen'}</S1>

                                    <P style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                                        <i style={{marginRight: 6, fontSize: 16}} className='mdi mdi-clock-outline'/>
                                        {`${address.startTime} - ${address.endTime}`}
                                    </P>

                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {address.gpsRequired &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-map-marker"/>
                                        }
                                        {address.signatureRequired &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-fountain-pen-tip"/>
                                        }
                                        {address.nameRequired && !address.signatureRequired &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-signature-text"/>
                                        }
                                        {address.signatureNeighbors &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-home-edit-outline"/>
                                        }
                                        {address.statedAddressOnly &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-home"/>
                                        }
                                        {address.mailboxDelivery &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-mailbox-open-up"/>
                                        }
                                        {address.scanRequired &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-barcode-scan"/>
                                        }
                                        {address.imageRequired &&
                                            <Icon style={{fontSize: 20}} icon="mdi mdi-camera"/>
                                        }
                                        {address.minimumAge &&
                                            <div style={{fontSize: 14, fontWeight: 500, marginRight: 6}}>{`${address.minimumAge}+`}</div>
                                        }
                                        {address.idCheck &&
                                            <Icon style={{fontSize: 20, marginRight: 3}} icon="mdi mdi-card-account-details-outline"/>
                                        }
                                        {address.verificationCode &&
                                            <Icon style={{fontSize: 20}} icon="mdi mdi-shield-key-outline"/>
                                        }
                                    </div>

                                    {address.instructions &&
                                        <P style={{display: 'flex'}}>
                                            <Icon style={{marginRight: 6, fontSize: 16}} icon='mdi mdi-message-outline'/>
                                            {address.instructions}
                                        </P>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {edit &&
                        <div style={{width: '100%', padding: 6}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <H6>{(address.isBusiness && address.name) ? address.name : `${address.street} ${address.nr}${address.addition}`}</H6>

                                <div style={{flex: 1}}/>

                                <IconButton
                                    icon='mdi mdi-check'
                                    onClick={() => this.editAddress.save()}
                                />
                            </div>

                            <EditAddress
                                address={address}
                                addressBook={addressBook}
                                onSave={this.saveAddress.bind(this)}
                                allowAttachments={true}
                                ref={(ref) => this.editAddress = ref}
                            />
                        </div>
                    }
                </Card>

                {error &&
                    <Alert variant='error'>{error}</Alert>
                }
            </div>


        )
    }
}

export default Address


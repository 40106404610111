import React from 'react'

import ParcelActions from '../../actions/ParcelActions.js'

import {Alert, Button, H4, Modal, Input} from '../UI/index.js'

class SendTrackTracePopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            parcel: null,
            callback: null,
            email: '',
            loading: false,
            message: '',
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(parcel, email, callback) {
        this.setState({isOpen: true, parcel, email, callback})
    }

    close() {
        this.setState(this.initialState)
    }


    send() {
        const {parcel, email, callback} = this.state

        if (email) {
            this.setState({loading: true, message: '', error: ''})

            ParcelActions.sendTrackTraceEmail(parcel, email, (err, response) => {
                callback(err, response)
                this.close()
            })
        }
    }


    render() {
        const {isOpen, email, loading, message, error, errors} = this.state

        return (
            <Modal show={isOpen} style={{width: errors.length > 0 ? 800 : 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', marginBottom: 24}}>
                    <H4>Verstuur Track & Trace</H4>

                    <div style={{flex: 1}}/>
                </div>

                <Input
                    label='Emailadres'
                    value={email}
                    onChange={(event) => this.setState({email: event.target.value})}
                />

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    {!message && !error &&
                        <Button
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Annuleren
                        </Button>
                    }

                    {!message && !error &&
                        <Button
                            variant='text'
                            loading={loading}
                            onClick={this.send.bind(this)}
                        >
                            Bevestigen
                        </Button>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </div>
            </Modal>
        )
    }
}

export default SendTrackTracePopup

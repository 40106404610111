import {create} from 'zustand'
import socket from '../socketio/socketio.js'

const useAdminStore = create((set) => ({
    logs: [],
    getLogs: (pageSize, page, search) => {
        socket.emit('admin.getLogs', sessionStorage.token, pageSize, page, search, (err, logs, totalLogs) => {
            set({logs, totalLogs})
        })
    },
    resellers: [],
    /*
    Very basic, does not have all functionality as the original yet
    TODO: Add functionality from the original
    */
    getResellers: (includeArchived = false) => {
        socket.emit('admin.getResellers', sessionStorage.token, includeArchived, (err, resellers) => {
            set({resellers})
        })
    },
    searchOrderAndParcel: (trackTraceOrId, callback) => {
        socket.emit('admin.searchOrderAndParcel', sessionStorage.token, trackTraceOrId, callback)
    }
}))


export default useAdminStore

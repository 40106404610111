import React, {Component} from 'react'


import {Colors} from '../UI/index.js'
class SearchItem extends Component {
    render() {
        const {onRemove, query} = this.props
        const operators = {
            '=': {
                label: 'Bevat',
                icon: 'mdi-equal'
            },
            '==': {
                label: 'Is gelijk aan'
            },
            '!=': {
                label: 'Niet gelijk aan',
                icon: 'mdi-not-equal-variant'
            },
            '>': {
                label: 'Groter dan',
                icon: 'mdi-greater-than'
            },
            '<': {
                label: 'Kleiner dan',
                icon: 'mdi-less-than'
            }
        }

        const operatorStyle = {
            marginLeft: query.title === '' ? 0 : 5,
            marginRight: 6,
            fontSize: 15,
            color: Colors.textDark,
            transform: 'translateY(1px)'
        }

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginBottom: 0,
                    marginTop: 12,
                    marginLeft: 12,
                    background: Colors.grey20,
                    paddingLeft: 15,
                    paddingRight: 15,
                    height: 40,
                    borderRadius: 20,
                    color: Colors.textDark,
                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0,0,0,.12)'

                }}
                onClick={onRemove}
            >
                {query.title}
                {operators[query.operator].icon ? <i style={operatorStyle} className={`mdi ${operators[query.operator].icon}`}/> : <span style={operatorStyle}>{query.operator}</span>}
                {query.text}
                <i style={{marginLeft: 6, fontSize: 20, color: Colors.textDark, transform: 'translateX(3px)'}} className='mdi mdi-close-circle'/>
            </div>
        )
    }
}

export default (SearchItem)

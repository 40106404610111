import React from 'react'
import {Route} from 'react-router-dom'

import ParcelCarriers from './ParcelCarriers.js'
import ParcelProducts from './ParcelProducts.js'
import ParcelPreferences from './ParcelPreferences.js'
import ParcelPrices from './ParcelPrices.js'
import ParcelProductCodes from './ParcelProductCodes.js'
import TrackTraceEmail from './TrackTraceEmail.js'

class ParcelSettings extends React.Component {
    componentDidMount() {
        const {history} = this.props

        if (history.location.pathname === '/instellingen/zendingen' || history.location.pathname === '/instellingen/zendingen/') {
            history.push('/instellingen/zendingen/vervoerders')
        }
    }

    render() {
        const {reseller, carriers, onChange} = this.props

        return (
            <>
                <Route path="/instellingen/zendingen/vervoerders" render={(props) => <ParcelCarriers reseller={reseller} carriers={carriers} onChange={onChange}/>}/>
                <Route path="/instellingen/zendingen/verzendopties" render={(props) => <ParcelProducts reseller={reseller} carriers={carriers} onChange={onChange}/>}/>
                <Route path="/instellingen/zendingen/voorkeuren" render={(props) => <ParcelPreferences reseller={reseller} carriers={carriers} onChange={onChange}/>}/>
                <Route path="/instellingen/zendingen/tarieven" render={(props) => <ParcelPrices reseller={reseller} carriers={carriers} onChange={onChange}/>}/>
                <Route path="/instellingen/zendingen/productcodes" render={(props) => <ParcelProductCodes reseller={reseller} carriers={carriers} onChange={onChange}/>}/>
                <Route path="/instellingen/zendingen/tracktraceemail" render={(props) => <TrackTraceEmail reseller={reseller} onChange={onChange}/>}/>
            </>
        )
    }
}

export default ParcelSettings

import React from 'react'
import NotificationsPopup from './NotificationsPopup.js'
import Card from '../UI/Card.js'

class NotificationsPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            checkedNotifications: false
        }
    }

    componentDidMount() {
        const {notifications} = this.props
        if (notifications) {
            this.notificationsPopup.checkIfNewNotification(true)
            this.setState({checkedNotifications: true})
        }
    }

    componentDidUpdate(prevProps) {
        const {checkedNotifications} = this.state
        const {notifications} = this.props

        if (notifications?.toString !== prevProps.notifications?.toString() && !checkedNotifications) {
            this.notificationsPopup.checkIfNewNotification(true)
        }
    }

    render() {
        const {user, notifications} = this.props

        return (
            <div style={{marginTop: 100}}>
                <Card style={{maxWidth: 600, margin: 'auto', padding: 24}}>
                    <NotificationsPopup
                        showAll
                        user={user}
                        notifications={notifications}
                        ref={(ref) => this.notificationsPopup = ref}
                    />
                </Card>
            </div>
        )
    }
}

export default NotificationsPage

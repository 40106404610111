import React from 'react'

import {Colors} from './index.js'


export default class ProgressBar extends React.Component {
    render() {
        const {style={}, now, max} = this.props

        const percentage = Math.min(100, ((now || 0) / (max || 100)) * 100)

        const defaultStyle = {
            position: 'relative',
            width: '100%',
            background: Colors.grey20,
            borderRadius: 4,
            height: 20,
            marginBottom: 16,
            ...style
        }

        return (
            <div style={defaultStyle}>
                <div style={{position: 'absolute', color: Colors.textDark, width: `${percentage}%`, background: Colors.successBright, borderRadius: 4, height: 20}}/>
                <div style={{position: 'absolute', zIndex: 10, fontSize: 12, left: '50%', top: 3}}>{`${parseInt(percentage)} %`}</div>
            </div>
        )
    }
}


// @ts-check
import React, {forwardRef} from 'react'
import {create} from 'zustand'
import socket from '../socketio/socketio.js'

/**
 * @typedef {Object} VehicleState
 * @property {Vehicle[]} vehicles
 * @property {(options?: {ids: string[], types: string[]}) => void} getVehicles
 * @property {(vehicle: Vehicle, callback?: (err: string) => void) => void} createVehicle
 * @property {(vehicle: Vehicle[], callback?: (err: string[]) => void) => void} updateVehicles
*/

/**
 * @type {import('zustand').UseBoundStore<import('zustand').StoreApi<VehicleState>>}
*/
const useVehicleStore = create((set) => ({
    vehicles: [],
    getVehicles: (options) => {
        socket.emit('vehicles.get', sessionStorage.token || localStorage.mtoken, options,
        /**
         * @param {string} err
         * @param {Vehicle[]} vehicles
         */
            (err, vehicles) => {
                set({vehicles})
            })
    },
    createVehicle: (vehicle, callback) => {
        socket.emit('vehicles.create', sessionStorage.token, vehicle,
        /**
         * @param {string} err
         * @param {Vehicle} vehicle
         */
            (err, vehicle) => {
                callback && callback(err)

                if (!err) {
                    set((state) => ({vehicles: [...state.vehicles, vehicle]}))
                }
            })
    },
    updateVehicles: (vehicles, callback) => {
        const errors = []
        vehicles.forEach((vehicle) => {
            socket.emit('vehicles.update', sessionStorage.token, vehicle,
            /**
             *
             * @param {string} err
             * @param {Vehicle} vehicle
             */
                (err, vehicle) => {
                    if (err) {
                        errors.push(err)
                    }

                    if (!err) {
                        set((state) => {
                            const clonedVehicles = structuredClone(state.vehicles)
                            const index = clonedVehicles.findIndex((v) => v._id === vehicle._id)
                            clonedVehicles[index] = vehicle
                            return {vehicles: clonedVehicles}
                        })
                    }
                })
        })
        callback && callback(errors)
    }
}))

export function withVehiclesHook(Component) {
    return forwardRef((props, ref) => {
        const vehicles = useVehicleStore((state) => state.vehicles)
        return <Component {...props} ref={ref} vehicles={vehicles}/>
    })
}

export default useVehicleStore

import React from 'react'
import Reflux from 'reflux-react-16'

import {Button, Colors, Input, P, Row, IconButton, Alert, H5} from '../../components/UI/index.js'

import AdminActions from '../../actions/AdminActions.js'
import AdminStore from '../../stores/AdminStore.js'
import LoginActions from '../../actions/LoginActions.js'

class SearchOrdersAndParcels extends Reflux.Component {
    constructor(props) {
        super(props)

        this.state = {
            trackTraceOrId: '',
            order: null,
            error: ''
        }

        this.stores = [AdminStore]
    }

    onClickSearch() {
        const {trackTraceOrId} = this.state

        this.setState({error: '', order: null, parcel: null})

        AdminActions.searchOrderAndParcel(trackTraceOrId, (err, result) => {
            if (err) {
                this.setState({error: err})
            } else if (result) {
                const {order, parcel} = result
                this.setState({order, parcel})
            }
        })
    }

    onChangeValue(key, event) {
        this.setState({[key]: event.target.value})
    }

    onClickLoginReseller(event) {
        event.stopPropagation()
        const {order} = this.state

        LoginActions.loginAsReseller(order.resellerId, undefined, (err) => {
            if (err) {
                this.setState({error: err})
            }
        })
    }

    onClickLoginCustomer(event) {
        event.stopPropagation()
        const {order} = this.state

        LoginActions.loginAsCustomer({_id: order.customer}, (err) => {
            if (err) {
                this.setState({error: err})
            }
        })
    }

    onClickViewOrderInfo(event) {
        const {order} = this.state

        LoginActions.loginAsReseller(order.resellerId, `/planning/${order._id}`, (err) => {
            if (err) {
                this.setState({error: err})
            }
        })
    }

    onClickViewParcelInfo() {
        const {parcel} = this.state

        LoginActions.loginAsReseller(parcel.resellerId, `/zendingen/${parcel._id}`, (err) => {
            if (err) {
                this.setState({error: err})
            }
        })
    }

    onKeyDown(event) {
        if (event.key === 'Enter') {
            this.onClickSearch()
        }
    }


    render() {
        const {trackTraceOrId, order, parcel, error} = this.state

        const PStyle = {
            margin: 3,
            fontSize: 18
        }

        const firstPickupAddress = order?.addresses.find((address) => address.type === 'pickup')

        const firstDeliveryAddress = order?.addresses.find((address) => address.type === 'delivery')

        return (
            <div style={{padding: 20, width: '100%'}}>
                {error && <Alert variant='error'>{error}</Alert>}
                <Row style={{width: '100%', gap: 140}}>
                    <div>
                        <Input
                            label='Track & trace code of ID'
                            value={trackTraceOrId}
                            onChange={this.onChangeValue.bind(this, 'trackTraceOrId')}
                            style={{width: '100%'}}
                            onKeyDown={this.onKeyDown.bind(this)}
                        />

                        <Button style={{width: '100%'}} icon='mdi mdi-magnify' label='Zoeken' onClick={this.onClickSearch.bind(this)}/>
                    </div>

                    <div>
                        <H5 style={{marginBottom: 24}}>Rit info</H5>
                        {order && (
                            <>
                                <Row>
                                    <P style={PStyle}>Reseller: {order.reseller}</P>
                                    <IconButton onClick={this.onClickLoginReseller.bind(this)} icon='mdi mdi-login'/>
                                </Row>

                                <Row>
                                    <P style={PStyle}>Klant: {order.customerName}</P>
                                    <IconButton onClick={this.onClickLoginCustomer.bind(this)} icon='mdi mdi-login'/>
                                </Row>


                                <div style={{height: 18}}/>

                                <P style={PStyle}>ID: {order._id}</P>
                                <P style={PStyle}>Track & trace: <a style={{color: Colors.buttonSolid, textDecoration: 'none'}} target='_blank' href={`/track&trace/${order.trackTrace}`} rel="noreferrer">{order.trackTrace}</a></P>
                                {order.colli.map((collo, index) => (
                                    <P style={PStyle} key={collo.barcode}>Barcode {index+1}: {collo.barcode || 'Geen barcode'}</P>
                                ))}

                                <div style={{height: 18}}/>

                                {firstPickupAddress &&
                                    <div style={{marginBottom: 24}}>
                                        <P style={PStyle}>Ophaaladres</P>
                                        <P style={PStyle}>{firstPickupAddress.name}</P>
                                        <P style={PStyle}>{firstPickupAddress.street} {firstPickupAddress.nr}{firstPickupAddress.addition}</P>
                                        <P style={PStyle}>{firstPickupAddress.postalCode}, {firstPickupAddress.city}</P>
                                    </div>
                                }

                                {firstDeliveryAddress &&
                                    <div style={{marginBottom: 24}}>
                                        <P style={PStyle}>Bezorgadres</P>
                                        <P style={PStyle}>{firstDeliveryAddress.name}</P>
                                        <P style={PStyle}>{firstDeliveryAddress.street} {firstDeliveryAddress.nr}{firstDeliveryAddress.addition}</P>
                                        <P style={PStyle}>{firstDeliveryAddress.postalCode}, {firstDeliveryAddress.city}</P>
                                    </div>
                                }

                                {(order.addresses.length - 2) > 0 && <P style={PStyle}>+ {order.addresses.length - 2} adressen</P>}

                                <br/>
                                <Button
                                    style={{width: '100%'}}
                                    icon='mdi mdi-eye'
                                    label='Open rit info'
                                    onClick={this.onClickViewOrderInfo.bind(this)}
                                />
                            </>
                        )}
                    </div>
                    <div>
                        <H5 style={{marginBottom: 24}}>Zending info</H5>
                        {parcel && (
                            <>
                                <Row>
                                    <P style={PStyle}>Reseller: {parcel.reseller}</P>
                                    <IconButton onClick={this.onClickLoginReseller.bind(this)} icon='mdi mdi-login'/>
                                </Row>

                                <Row>
                                    <P style={PStyle}>Klant: {parcel.customerName}</P>
                                    <IconButton onClick={this.onClickLoginCustomer.bind(this)} icon='mdi mdi-login'/>
                                </Row>


                                <div style={{height: 18}}/>

                                <P style={PStyle}>ID: {parcel._id}</P>
                                <P style={PStyle}>Track & trace: <a style={{color: Colors.buttonSolid, textDecoration: 'none'}} target='_blank' href={`/track&trace/${parcel.trackTrace}`} rel="noreferrer">{parcel.trackTrace}</a></P>

                                <div style={{height: 18}}/>


                                <div style={{margin: 3}}>
                                    <P style={PStyle}>{parcel.address.name}</P>
                                    <P style={PStyle}>{parcel.address.street} {parcel.address.nr}{parcel.address.addition}</P>
                                    <P style={PStyle}>{parcel.address.postalCode}, {parcel.address.city}</P>
                                </div>


                                <br/>
                                <Button
                                    style={{width: '100%'}}
                                    icon='mdi mdi-eye'
                                    label='Open zending info'
                                    onClick={this.onClickViewParcelInfo.bind(this)}
                                />
                            </>
                        )}
                    </div>
                </Row>
            </div>
        )
    }
}

export default SearchOrdersAndParcels

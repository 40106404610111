import React from 'react'
import Colors from './Colors.js'


export default class Panel extends React.Component {
    render() {
        const {style={}, children} = this.props

        const defaultStyle = {
            background: 'white',
            width: '100%',
            border: `1px solid ${Colors.border}`,
            flexShrink: 0,
            ...style
        }

        return (
            <div
                {...this.props} style={defaultStyle} ref={(ref) => this.parent = ref}
            >
                {children}
            </div>

        )
    }
}

import React from 'react'
import _ from 'lodash'

import dbCodes from '../../../server/dbCodes.js'
import {Colors, Column, IconButton, Input, MetricInput, P, Row, S1, Select} from '../UI/index.js'


class EditCollo extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            collo: null
        }
    }

    componentDidMount() {
        if (this.props.onSave) {
            this.setState({collo: structuredClone(this.props.collo)})
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.collo !== this.props.collo) {
            if (this.props.onSave) {
                this.setState({collo: structuredClone(this.props.collo)})
            }
        }
    }

    onChangeValue(path, parser, event) {
        if (!event) {
            event = parser
            parser = undefined
        }

        let collo = {...this.props.collo}

        if (this.props.onSave) {
            collo = {...this.state.collo}
        }

        if (typeof parser === 'function') {
            _.set(collo, path, parser(event.target.value))
        } else {
            _.set(collo, path, event.target.value)
        }

        if (this.props.onSave) {
            this.setState({collo})
        } else {
            this.props.onChange(collo)
        }
    }


    render() {
        let {order, collo, onRemove, onSave} = this.props

        if (this.props.onSave) {
            collo = {...this.state.collo}
        }


        const pickupAddresses = _.filter(order.addresses, (address, index) => {
            address.index = index
            return address.type === 'pickup'
        })

        const deliveryAddresses = _.filter(order.addresses, (address) => address.type === 'delivery')

        return (
            <div style={{width: '100%', background: Colors.grey20, borderRadius: 4, padding: 6}}>
                <Row style={{alignItems: 'center', marginBottom: 12}}>
                    <S1 style={{width: 20, marginRight: 12}}>{(collo.index || 0) + 1}</S1>

                    <P >{collo.barcode || ''}</P>

                    <div style={{flex: 1}}/>

                    {!!onRemove &&
                        <IconButton onClick={onRemove} >
                            <i className='mdi mdi-delete'/>
                        </IconButton>
                    }

                    {!!onSave &&
                        <IconButton onClick={() => onSave(this.state.collo)} >
                            <i className='mdi mdi-check'/>
                        </IconButton>
                    }
                </Row>

                <Row>
                    <Column>
                        <Select
                            label='Type'
                            allowEmptyValue
                            value={collo.type}
                            options={_.keys(dbCodes.colloTypes).map((key) => {
                                return {value: key, title: dbCodes.colloTypes[key]}
                            })}
                            onChange={this.onChangeValue.bind(this, 'type')}
                        />
                    </Column>

                    <Column>
                        <Input
                            label='Omschrijving'
                            value={collo.description}
                            onChange={this.onChangeValue.bind(this, 'description')}
                        />
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <MetricInput
                            label='Gewicht'
                            value={collo.weight}
                            onChange={this.onChangeValue.bind(this, 'weight')}
                            append='g'
                        />

                        <MetricInput
                            label='Lengte'
                            max={9999}
                            divisionFactor={10}
                            value={collo.length}
                            onChange={this.onChangeValue.bind(this, 'length')}
                            append='cm'
                        />
                    </Column>

                    <Column>
                        <MetricInput
                            label='Breedte'
                            max={9999}
                            divisionFactor={10}
                            value={collo.width}
                            onChange={this.onChangeValue.bind(this, 'width')}
                            append='cm'
                        />

                        <MetricInput
                            label='Hoogte'
                            max={9999}
                            divisionFactor={10}
                            value={collo.height}
                            onChange={this.onChangeValue.bind(this, 'height')}
                            append='cm'
                        />
                    </Column>
                </Row>

                {(order.addresses.length > 2 || (order.addresses.length === 2 && order.addresses[0].type === order.addresses[1].type))&&
                    <Row>
                        <Column>
                            {pickupAddresses.length > 0 &&
                                <Select
                                    label='Ophaaladres'
                                    value={pickupAddresses.length === 1 ? pickupAddresses[0].index : collo.pickupAddressIndex}
                                    readOnly={pickupAddresses.length === 1}
                                    options={pickupAddresses.map((address) => {
                                        return {value: address.index, title: `${(address.isBusiness && address.name) ? address.name : `${address.street} ${address.nr}${address.addition}`}`}
                                    })}
                                    onChange={this.onChangeValue.bind(this, 'pickupAddressIndex', parseInt)}
                                />
                            }
                        </Column>

                        <Column>
                            {deliveryAddresses.length > 0 &&
                                <Select
                                    label='Bezorgadres'
                                    value={deliveryAddresses.length === 1 ? deliveryAddresses[0].index : collo.deliveryAddressIndex}
                                    readOnly={deliveryAddresses.length === 1}
                                    options={deliveryAddresses.map((address) => {
                                        return {value: address.index, title: `${(address.isBusiness && address.name) ? address.name : `${address.street} ${address.nr}${address.addition}`}`}
                                    })}
                                    onChange={this.onChangeValue.bind(this, 'deliveryAddressIndex', parseInt)}
                                />
                            }
                        </Column>
                    </Row>
                }
            </div>
        )
    }
}

export default EditCollo


import React from 'react'

import {Modal, H4, IconButton, Colors, Row, P, RadioButton, Button, Alert, ProgressBar} from './index.js'
import dbCodes from '../../../server/dbCodes.js'

export default class ChangeParcelStatusPopup extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            isOpen: false,
            newStatus: undefined,
            progressNow: 0,
            progressMax: null,
            message: '',
            error: '',
            errors: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(onChange) {
        this.setState({isOpen: true, onChange})
    }

    close() {
        this.setState(this.initialState)
    }

    onStatusChange(event) {
        this.setState({newStatus: event.target.value})
    }

    onSave() {
        const {newStatus, onChange} = this.state

        onChange(newStatus)
    }

    setMessage(message) {
        this.setState({message, loading: false})
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    setErrors(errors) {
        this.setState({errors, loading: false})
    }

    setProgress(progressNow, progressMax) {
        this.setState({progressNow, progressMax})
    }

    render() {
        const {isOpen, newStatus, progressMax, progressNow, message, error, errors} = this.state

        return (
            <Modal show={isOpen} style={{width: 400, maxWidth: '90%'}} onClose={this.close.bind(this)}>
                <Row style={{marginBottom: 24, alignItems: 'center'}}>
                    <H4>Status wijzigen</H4>

                    <div style={{flex: 1}}/>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </Row>

                <P style={{marginBottom: 10}}>Wijzig hieronder de status van zendingen via externe vervoerders. Automatische statusupdates zullen deze status overschrijven.</P>

                <RadioButton
                    label='Manco'
                    checked={newStatus === dbCodes.parcelStatus.manco()}
                    value={dbCodes.parcelStatus.manco()}
                    onChange={this.onStatusChange.bind(this)}
                />
                <RadioButton
                    label='Bezorgd'
                    checked={newStatus === dbCodes.parcelStatus.bezorgd()}
                    value={dbCodes.parcelStatus.bezorgd()}
                    onChange={this.onStatusChange.bind(this)}
                />
                <RadioButton
                    label='Retour'
                    checked={newStatus === dbCodes.parcelStatus.retour()}
                    value={dbCodes.parcelStatus.retour()}
                    onChange={this.onStatusChange.bind(this)}
                />

                {progressMax && !message && !error &&
                    <ProgressBar now={progressNow} max={progressMax}/>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {error &&
                    <Alert variant='danger'>{error}</Alert>
                }

                {errors.length > 0 && errors.map((error, index) => {
                    return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                })}

                <Row style={{justifyContent: 'flex-end', marginTop: 24}}>
                    {!message && !error &&
                        <>
                            <Button
                                onClick={this.close.bind(this)}
                                variant='text'
                            >
                                Annuleren
                            </Button>
                            <Button
                                onClick={this.onSave.bind(this)}
                            >
                                Opslaan
                            </Button>
                        </>
                    }

                    {(message || error) &&
                        <Button
                            style={{marginBottom: 0}}
                            variant='text'
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button>
                    }
                </Row>
            </Modal>
        )
    }
}

import React, {useState} from 'react'

import {H3, H6, Toggle, Input, Divider, Select, Button, Alert} from '../../../../components/UI/index.js'

import LoginActions from '../../../../actions/LoginActions.js'

export default function PrestaShopSettings({user, onChange}) {
    const [loading, setLoading] = useState(false)
    const [verified, setVerified] = useState(false)
    const [error, setError] = useState('')

    const onChangeChecked = (key, event) => {
        user.settings.webshops.prestashop[key] = event.target.checked

        onChange(user)
    }

    const onChangeValue = (key, event) => {
        user.settings.webshops.prestashop[key] = event.target.value

        onChange(user)
    }

    const onVerifyConnection = () => {
        setLoading(true)
        setVerified(false)

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('prestashop', (err) => {
                setLoading(false)
                if (err) {
                    setError(`Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`)
                } else {
                    setError('')
                    setVerified(true)
                }
            })
        }, 1000)
    }

    return (
        <div>
            <H3>PrestaShop</H3>

            <H6>Instellingen</H6>
            <Divider/>

            <Toggle
                label='Actief'
                checked={user.settings.webshops.prestashop.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.prestashop.active &&
                <>
                    <Input
                        label='Webshop URL'
                        value={user.settings.webshops.prestashop.url}
                        onChange={(event) => onChangeValue('url', event)}
                    />

                    <Input
                        label='API key'
                        value={user.settings.webshops.prestashop.apiKey}
                        onChange={(event) => onChangeValue('apiKey', event)}
                    />

                    <Input
                        type='number'
                        min={0}
                        max={180}
                        label='Aantal dagen terugkijken voor ophalen bestellingen'
                        placeholder={7}
                        value={user.settings.webshops.prestashop.nrOfDays}
                        onChange={(event) => onChangeValue('nrOfDays', event)}
                    />

                    <Select
                        label='Update order status in PrestaShop'
                        info={'Update order status \'Shipped\' in PrestaShop'}
                        value={user.settings.webshops.prestashop.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieër
                    </Button>

                    {error && (
                        <Alert onClose={() => setError('')} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => setVerified(false)}>Verificatie succesvol.</Alert>
                    )}
                </>
            }
        </div>
    )
}

import React from 'react'


import {Colors} from '../UI/index.js'

let pos1 = 0
let pos2 = 0

class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            left1: 0,
            left2: 0,
            value1: 0,
            value2: 0,
            cursor: 'grab',
            isMoving: false
        }
    }

    componentDidMount() {
        const {value1, value2, min, max} = this.props

        const left1 = (value1 * (this.slideBar.clientWidth - 20)) / (max - min)
        const left2 = (value2 * (this.slideBar.clientWidth - 20)) / (max - min)

        this.setState({left1, left2, value1, value2})
    }

    componentDidUpdate(prevProps) {
        const {value1, value2, min, max} = this.props

        if (prevProps.value1 !== value1 || prevProps.value2 !== value2) {
            const left1 = (value1 * (this.slideBar.clientWidth - 20)) / (max - min)
            const left2 = (value2 * (this.slideBar.clientWidth - 20)) / (max - min)

            this.setState({left1, left2, value1, value2})
        }
    }

    dragMouseDown(dragHandle, event) {
        // get the mouse cursor position at startup:
        pos2 = event.clientX
        document.onmouseup = this.closeDragElement.bind(this)
        // call a function whenever the cursor moves:
        document.onmousemove = this.elementDrag.bind(this, dragHandle)
        this.setState({cursor: 'grabbing'})
    }

    elementDrag(dragHandle, event) {
        const {isMoving} = this.state
        if (!isMoving) {
            this.setState({isMoving: true})
        }
        const {min, max, step, diff} = this.props
        // calculate the new cursor position:
        pos1 = pos2 - event.clientX
        pos2 = event.clientX

        const left = Math.min(this.slideBar.clientWidth - 20, Math.max(0, (this[dragHandle].offsetLeft - pos1)))

        let value = (left * (max - min)) / (this.slideBar.clientWidth - 20)

        value = Math.round(value / step) * step

        if (dragHandle === 'dragHandle') {
            const {value2} = this.state
            if (value < (value2 - diff)) {
                this.setState({left1: left, value1: value})
            }
        } else {
            const {value1} = this.state
            if (value > (value1 + diff)) {
                this.setState({left2: left, value2: value})
            }
        }
    }

    closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null

        const {value1, value2} = this.state
        const {onChange} = this.props
        onChange(value1, value2)
        this.setState({cursor: 'grab', isMoving: false})
    }

    render() {
        const {left1, left2, value1, value2, cursor, isMoving} = this.state
        const {valuesToLabel, value1Icon, value2Icon, onClickValue1, onClickValue2, startInclude, endInclude} = this.props

        const {label1, label2} = valuesToLabel(value1, value2)

        return (
            <div style={{width: '100%', height: 30, marginLeft: 6, marginRight: 6}}>
                <div style={{height: 2, width: '100%', background: Colors.grey30, position: 'relative', marginTop: 13, userSelect: 'none'}} ref={(ref) => this.slideBar = ref}>
                    <div style={{height: 2, width: left2 - left1, background: Colors.buttonSolid, position: 'absolute', left: left1}}>
                    </div>
                    {startInclude &&
                        <div style={{height: 2, width: left1, background: Colors.grey50, position: 'absolute', left: 0}}></div>
                    }

                    {endInclude &&
                        <div style={{height: 2, width: `calc(100% - ${left2}px)`, background: Colors.grey40, position: 'absolute', right: 0}}></div>
                    }

                    <div
                        style={{height: 20, width: 20,
                            background: 'white', left: left1, top: -9, position: 'absolute', borderRadius: '50%', cursor,
                            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0,0,0,.12)'
                        }}
                        ref={(ref) => this.dragHandle = ref}
                        onMouseUp={() => {
                            if (!isMoving) {
                                onClickValue1()
                            }
                        }}
                        onMouseDown={this.dragMouseDown.bind(this, 'dragHandle')}
                    >
                        {value1Icon &&
                            <i style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 12}} className={`mdi ${value1Icon}`}/>
                        }
                        <div style={{marginTop: 20, userSelect: 'none', marginLeft: -9, color: Colors.textMedium}}>{label1}</div>
                    </div>
                    <div
                        style={{height: 20, width: 20,
                            background: 'white', left: left2, top: -9, position: 'absolute', borderRadius: '50%', cursor,
                            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0,0,0,.12)'
                        }}
                        ref={(ref) => this.dragHandle2 = ref}
                        onMouseUp={() => {
                            if (!isMoving) {
                                onClickValue2()
                            }
                        }}
                        onMouseDown={this.dragMouseDown.bind(this, 'dragHandle2')}
                    >
                        {value2Icon &&
                            <i style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 12}} className={`mdi ${value2Icon}`}/>
                        }
                        <div style={{marginTop: 20, userSelect: 'none', marginLeft: -9, color: Colors.textMedium}}>{label2}</div>
                    </div>
                </div>
            </div>
        )
    }
}


export default (Slider)

import React from 'react'
import Leaflet from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import togeojson from 'togeojson'
import fileLayer from 'leaflet-filelayer'
import tokml from 'tokml'
import _ from 'lodash'

import {
    H3,
    S2,
    Colors,
    IconButton,
    Toast,
    ColorPicker,
    Input,
    Panel
} from '../../../../components/UI/index.js'

fileLayer(null, Leaflet, togeojson)

class DeliveryZones extends React.Component {
    componentDidMount() {
        if (this.props.reseller.settings?.companyAddress) {
            this.initMap()
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.map && this.props.reseller.settings?.companyAddress) {
            this.initMap()
        } else if (prevProps.reseller.settings.deliveryZones !== this.props.reseller.settings.deliveryZones) {
            this.updateMap()
        }
    }

    initMap() {
        const {reseller} = this.props

        this.map = Leaflet.map('map', {
            preferCanvas: true,
            boxZoom: false
        }).setView(reseller.settings.companyAddress.position || {lat: 52.2076823, lng: 5.1584931}, 13)

        Leaflet.tileLayer(`${window.location.origin}/api/mapbox/styles/v1/{account}/{id}/tiles/{z}/{x}/{y}`, {
            maxZoom: 18,
            account: 'veloyd',
            id: 'cka2lgtlz0huj1iln27ujuqim',
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(this.map)

        this.map.pm.addControls({
            position: 'topleft',
            drawMarker: false,
            drawPolyline: false,
            drawCircleMarker: false,
            drawRectangle: false,
            drawCircle: false,
            drawText: false,
            dragMode: false,
            rotateMode: false,
            removalMode: false
        })

        this.map.pm.setLang('nl')

        this.map.pm.setGlobalOptions({
            hintlineStyle: {color: '#242424', weight: 1, dashArray: [5, 5]},
            templineStyle: {color: '#242424', weight: 1},
            allowSelfIntersection: false,
            requireSnapToFinish: true
        })

        this.map.pm.Toolbar.changeActionsOfControl('Polygon', ['cancel', 'removeLastVertex'])
        this.map.pm.Toolbar.changeActionsOfControl('Edit', [])
        this.map.pm.Toolbar.changeActionsOfControl('Cut', ['cancel', 'removeLastVertex'])

        this.drawnItems = new Leaflet.FeatureGroup()
        this.map.addLayer(this.drawnItems)

        this.map.on('pm:create', ({shape, layer}) => {
            const id = '_' + Math.random().toString(36).substr(2, 9)
            const color = Colors.routeColors[_.random(0, Colors.routeColors.length - 1)]

            layer.setStyle({
                color: color,
                weight: 1,
                fillOpacity: .2,
                opacity: 1
            })

            reseller.settings.deliveryZones.push({
                id,
                name: `Bezorggebied ${reseller.settings.deliveryZones.length + 1}`,
                color: color,
                geoJSON: layer.toGeoJSON(),
                visible: true
            })

            layer.options.id = id

            this.drawnItems.addLayer(layer)
            this.pmEvents(layer)
            this.props.onChange(reseller, 'deliveryZones')
        })

        const fileUpload = Leaflet.Control.fileLayerLoad({
            fitBounds: true,
            addToMap: false,
            fileSizeLimit: 500000
        })

        fileUpload.addTo(this.map)

        const uploadBtn = document.getElementsByClassName('leaflet-control-filelayer leaflet-control-zoom-in leaflet-bar-part')
        uploadBtn[0].innerHTML = '<i class="mdi mdi-file-import" style="color: #5A5B5B"></i>'
        uploadBtn[0].title = 'Importeer bestand (GPX, KML, GeoJSON)'

        fileUpload.loader.on('data:loaded', (event) => {
            const layers = event.layer._layers


            Object.values(layers).map((layer) => {
                if (layer?.feature?.properties && typeof layer?.toGeoJSON === 'function') {
                    const id = '_' + Math.random().toString(36).substr(2, 9)
                    const color = Colors.routeColors[_.random(0, Colors.routeColors.length - 1)]

                    layer.setStyle({
                        color: color,
                        weight: 1,
                        fillOpacity: .2,
                        opacity: 1
                    })

                    reseller.settings.deliveryZones.push({
                        id,
                        name: layer.feature.properties.name ? layer.feature.properties.name : `Zone ${reseller.settings.deliveryZones.length + 1}`,
                        color: color,
                        geoJSON: layer.toGeoJSON(),
                        visible: true
                    })

                    layer.options.id = id

                    this.drawnItems.addLayer(layer)
                    this.pmEvents(layer)
                }
            })
            this.props.onChange(reseller, 'deliveryZones')
        })

        fileUpload.loader.on('data:error', (error) => {
            this.toast.showToast('error', 'Importeren mislukt. Geen geldige data', 2500)
        })

        this.updateMap(true)
    }

    updateMap(init) {
        const {reseller} = this.props

        this.drawnItems.clearLayers()
        let visibleZones = 0

        reseller.settings.deliveryZones.map((deliveryZone) => {
            if (deliveryZone.visible) {
                visibleZones += 1
                Leaflet.geoJson(deliveryZone.geoJSON, {
                    style: (feature) => {
                        return {
                            color: deliveryZone.color,
                            weight: 1,
                            fillOpacity: .2,
                            opacity: 1
                        }
                    },
                    onEachFeature: (feature, layer) => {
                        layer.options.id = deliveryZone.id
                        this.drawnItems.addLayer(layer)
                        // deliveryZone.id = layer._leaflet_id
                        // deliveryZone.geoJSON = layer.toGeoJSON()
                        // deliveryZone.visible = true

                        this.pmEvents(layer)
                    }
                })
            }
        })

        if (init && visibleZones > 0) {
            const bounds = this.drawnItems.getBounds()
            this.map.fitBounds(bounds)
        }
    }


    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}
        reseller.settings = {...reseller.settings}
        reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}
        reseller.settings = {...reseller.settings}
        reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, 'deliveryZones')
    }

    onClickRemoveZone(index, event) {
        const reseller = {...this.props.reseller}
        reseller.settings = {...reseller.settings}
        reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

        reseller.settings.deliveryZones.splice(index, 1)

        this.props.onChange(reseller, 'deliveryZones')
    }

    onClickShowHide(index, event) {
        const reseller = {...this.props.reseller}
        reseller.settings = {...reseller.settings}
        reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

        const zone = reseller.settings.deliveryZones[index]
        zone.visible = !zone.visible

        this.props.onChange(reseller)
    }

    onClickExportLayer(index) {
        const {reseller} = this.props
        const zone = reseller.settings.deliveryZones[index]

        zone.geoJSON.properties = zone.geoJSON.properties || {}

        const kml = tokml(zone.geoJSON, {
            documentName: zone.name
        })

        const element = document.createElement('a')
        const file = new Blob([kml], {
            type: 'application/inkml+xml'
        })
        element.href = URL.createObjectURL(file)
        element.download = `${zone.name}.kml`
        document.body.appendChild(element)
        element.click()
    }

    pmEvents(layer) {
        layer.on('pm:edit', (e) => {
            if (!e.layer.cutted) {
                const reseller = {...this.props.reseller}
                reseller.settings = {...reseller.settings}
                reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

                const zone = _.find(reseller.settings.deliveryZones, {id: e.layer.options.id})

                if (zone) {
                    zone.geoJSON = e.layer.toGeoJSON()
                }

                this.props.onChange(reseller, 'deliveryZones')
            }
        })

        layer.on('pm:cut', (e) => {
            const reseller = {...this.props.reseller}
            reseller.settings = {...reseller.settings}
            reseller.settings.deliveryZones = [...reseller.settings.deliveryZones]

            const zone = _.find(reseller.settings.deliveryZones, {id: e.layer.options.id})

            e.originalLayer.cutted = true // Important; prevents pm:edit event

            if (zone) {
                zone.geoJSON = e.layer.toGeoJSON()
            }

            this.props.onChange(reseller, 'deliveryZones')
        })
    }


    render() {
        const {reseller} = this.props

        return (
            <div style={{display: 'flex'}}>
                <Panel style={{width: 600, padding: 20, height: 'calc(100vh - 120px)', overflowY: 'auto'}}>

                    <H3>Bezorggebieden</H3>

                    <S2 style={{marginBottom: 12}}>
                        Klik op het <img style={{height: 20, marginBottom: -5, marginRight: 3}} src='/images/icons/polygon.svg'/> icoon op de kaart om een nieuw bezorggebied te tekenen.
                    </S2>

                    {(reseller.settings.deliveryZones || []).map((zone, index) => {
                        return (
                            <div
                                key={zone.id}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderBottom: 'solid 1px',
                                    borderColor: Colors.grey40
                                }}
                            >
                                <ColorPicker
                                    value={zone.color}
                                    colors={Colors.routeColors}
                                    onChange={this.onSaveValue.bind(this, `deliveryZones.${index}.color`)}
                                />

                                <Input
                                    label='Naam'
                                    style={{
                                        marginTop: 15,
                                        marginRight: 15,
                                        marginBottom: 12,
                                        marginLeft: 15
                                    }}
                                    value={zone.name}
                                    onChange={this.onChangeValue.bind(this, `deliveryZones.${index}.name`)}
                                    onBlur={this.onSaveValue.bind(this, `deliveryZones.${index}.name`)}
                                />

                                <IconButton onClick={this.onClickShowHide.bind(this, index)}><i className={zone.visible ? 'mdi mdi-eye-off' : 'mdi mdi-eye'}/></IconButton>

                                <IconButton onClick={this.onClickExportLayer.bind(this, index)}><i className='mdi mdi-file-export'/></IconButton>

                                <IconButton onClick={this.onClickRemoveZone.bind(this, index)}><i className='mdi mdi-delete'/></IconButton>

                            </div>
                        )
                    })}

                    <Toast ref={(ref) => this.toast = ref}></Toast>
                </Panel>
                <div id="map" style={{width: 'calc(100vw - 500px)', height: 'calc(100vh - 120px)'}}></div>

            </div>
        )
    }
}

export default DeliveryZones

export default {
    '01012100': 'fokpaarden van zuiver ras',
    '01012910': 'slachtpaarden',
    '01012990': 'paarden, levend (m.u.v. fokpaarden van zuiver ras en slachtpaarden)',
    '01013000': 'ezels, levend',
    '01019000': 'muildieren en muilezels, levend',
    '01022110': 'fokvaarzen "vrouwelijke fokrunderen die nog niet gekalfd hebben" van zuiver ras, levend',
    '01022130': 'fokkoeien van zuiver ras (m.u.v. fokvaarzen)',
    '01022190': 'Rundvee, fokdieren van zuiver ras (m.u.v. koeien en vaarzen)',
    '01022905': 'Levend rundvee van het ondergeslacht Bibos of van het ondergeslacht Poephagus (m.u.v. fokdieren van zuiver ras)',
    '01022910': 'Rundvee, levend, met een gewicht van <= 80 kg (m.u.v. fokrunderen van zuiver ras)',
    '01022921': 'Rundvee, slachtdieren met een gewicht van > 80 doch <= 160 kg',
    '01022929': 'Rundvee, levend, met een gewicht van > 80 doch <= 160 kg (m.u.v. fokdieren van zuiver ras en slachtdieren)',
    '01022941': 'Rundvee, slachtdieren met een gewicht van > 160 kg doch <= 300 kg',
    '01022949': 'rundvee, levend, met een gewicht van > 160 kg doch <= 300 kg (m.u.v. fokdieren van zuiver ras en slachtdieren)',
    '01022951': 'slachtvaarzen "vrouwelijke slachtrunderen die nog niet gekalfd hebben" met een gewicht van > 300 kg',
    '01022959': 'vaarzen "vrouwelijke runderen die nog niet gekalfd hebben", levend, met een gewicht van > 300 kg (m.u.v. slachtdieren en fokdieren van zuiver ras)',
    '01022961': 'slachtkoeien met een gewicht van > 300 kg (m.u.v. vaarzen)',
    '01022969': 'koeien, levend, met een gewicht van > 300 kg (m.u.v. slachtkoeien en fokdieren van zuiver ras en vaarzen)',
    '01022991': 'Rundvee, slachtdieren met een gewicht van > 300 kg (m.u.v. koeien en vaarzen)',
    '01022999': 'rundvee, levend, met een gewicht van > 300 kg (m.u.v. slachtdieren, koeien, vaarzen en fokdieren van zuiver ras)',
    '01023100': 'buffels, fokdieren van zuiver ras',
    '01023910': 'Buffels "huisdieren", levend (m.u.v. fokdieren van zuiver ras)',
    '01023990': 'buffels, levend (m.u.v. fokdieren van zuiver ras en huisdieren)',
    '01029020': 'fokrunderen van zuiver ras,levend,  (m.u.v. rundvee en buffels)',
    '01029091': 'runderen "huisdieren", levend (m.u.v. fokdieren van zuiver ras, rundvee en buffels)',
    '01029099': 'runderen, levend (m.u.v. fokdieren van zuiver ras, huisdieren, rundvee en buffels)',
    '01031000': 'fokvarkens van zuiver ras',
    '01039110': 'varkens "huisdieren", levend, met een gewicht van < 50 kg (m.u.v. fokdieren van zuiver ras)',
    '01039190': 'varkens "in het wild levend", levend, met een gewicht van < 50 kg',
    '01039211': 'zeugen "huisdieren", levend, die ten minste eenmaal gebigd hebben, met een gewicht van >= 160 kg (m.u.v. fokdieren van zuiver ras)',
    '01039219': 'varkens "huisdieren", levend, met een gewicht van >= 50 kg (m.u.v. fokdieren van zuiver ras en m.u.v. zeugen die ten minste eenmaal gebigd hebben, met een gewicht van >= 160 kg)',
    '01039290': 'varkens "in het wild levend", levend, met een gewicht van >= 50 kg',
    '01041010': 'fokschapen van zuiver ras',
    '01041030': 'lammeren "schapen < 1 jaar", levend (m.u.v. fokdieren van zuiver ras)',
    '01041080': 'schapen, levend (m.u.v. fokdieren van zuiver ras en lammeren)',
    '01042010': 'fokgeiten van zuiver ras',
    '01042090': 'geiten, levend (m.u.v. fokdieren van zuiver ras)',
    '01051111': 'vrouwelijke selectie- en vermeerderingskuikens van legkippenrassen "pluimvee", met een gewicht van <= 185 g',
    '01051119': 'vrouwelijke selectie- en vermeerderingskuikens van kippen "pluimvee", met een gewicht van <= 185 g (m.u.v. die van legrassen)',
    '01051191': 'hanen en kippen "pluimvee" van legrassen, met een gewicht van <= 185 g (m.u.v. vrouwelijke selectie- en vermeerderingskuikens)',
    '01051199': 'hanen en kippen "pluimvee", levend, met een gewicht van <= 185 g (m.u.v. die van legrassen en m.u.v. vrouwelijke selectie- en vermeerderingskuikens)',
    '01051200': 'kalkoenen "pluimvee", levend, met een gewicht van <= 185 g',
    '01051300': 'eenden "pluimvee", levend, met een gewicht van <= 185 g',
    '01051400': 'ganzen "pluimvee", levend, met een gewicht van <= 185 g',
    '01051500': 'parelhoenders "pluimvee", levend, met een gewicht van <= 185 g',
    '01059400': 'hanen en kippen "pluimvee", levend, met een gewicht van > 185 gr',
    '01059910': 'eenden "pluimvee", levend, met een gewicht van > 185 g',
    '01059920': 'ganzen "pluimvee", levend, met een gewicht van > 185 g',
    '01059930': 'kalkoenen "pluimvee", levend, met een gewicht van > 185 g',
    '01059950': 'parelhoenders "pluimvee", levend, met een gewicht van > 185 g',
    '01061100': 'levende primaten',
    '01061200': 'Levende walvissen, dolfijnen en bruinvissen (zoogdieren van de orde Cetacea); lamantijnen en doejongs (zoogdieren van de orde Sirenia): zeehonden, zeeleeuwen en walrussen (zoogdieren van de suborde Pinnipedia)".',
    '01061300': 'Levende kamelen en andere kameelachtigen (Camelidae)',
    '01061410': 'tamme konijnen, levend',
    '01061490': 'konijnen en hazen, levend (m.u.v. tamme konijnen)',
    '01061900': 'levende zoogdieren (m.u.v. primaten, walvissen, dolfijnen en bruinvissen, lamantijnen en doejongs, zeehonden, zeeleeuwen en walrussen en m.u.v. kamelen en kameelachtigen, konijnen en hazen, paarden, ezels, muildieren, muilezels, runderen, varkens, schapen en geiten)',
    '01062000': 'levende reptielen "slangen en zeeschildpadden daaronder begrepen"',
    '01063100': 'levende roofvogels',
    '01063200': 'levende psittaciformes "papegaaiachtigen" "papegaaien, parkieten, ara\'s en kaketoes daaronder begrepen"',
    '01063300': 'Struisvogels en emoes (Dromaius novaehollandiae), levend',
    '01063910': 'levende duiven',
    '01063980': 'levende vogels (m.u.v. roofvogels, psittaciformes "papegaaiachtigen" "papegaaien, parkieten, ara\'s en kaketoes daaronder begrepen", struisvogels, emoes (Dromaius novaehollandiae) en duiven)',
    '01064100': 'Levende bijen',
    '01064900': 'levende insecten (m.u.v. bijen)',
    '01069000': 'levende dieren (m.u.v. zoogdieren, reptielen, vogels, insecten, vissen,  schaaldieren, weekdieren, andere ongewervelde waterdieren en culturen van micro-organismen e.d.)',
    '02011000': 'runderen, hele en halve dieren, vers of gekoeld',
    '02012020': 'compensated quarters van runderen, met been, vers of gekoeld',
    '02012030': 'voorvoeten en voorspannen van runderen, met been, vers of gekoeld',
    '02012050': 'achtervoeten en achterspannen van runderen, met been, vers of gekoeld',
    '02012090': 'vlees van runderen, met been, vers of gekoeld (m.u.v. hele en halve dieren, "compensated quarters", voorvoeten en voorspannen, achtervoeten en achterspannen)',
    '02013000': 'vlees van runderen, uitgebeend, vers of gekoeld',
    '02021000': 'runderen, hele en halve dieren, bevroren',
    '02022010': 'compensated quarters van runderen, met been, bevroren',
    '02022030': 'voorvoeten en voorspannen van runderen, met been, bevroren',
    '02022050': 'achtervoeten en achterspannen van runderen, met been, bevroren',
    '02022090': 'vlees van runderen, met been, bevroren (m.u.v. hele en halve dieren, "compensated quarters", voorvoeten en voorspannen, achtervoeten en achterspannen)',
    '02023010': 'voorvoeten van runderen, uitgebeend, bevroren, geheel of verdeeld in ten hoogste vijf delen, waarbij iedere voorvoet in één enkel vriesblok wordt aangeboden, alsmede zgn. "compensated quarters" aangeboden in twee vriesblokken, waarvan het ene blok de voorvoet in zijn geheel of verdeeld in ten hoogste vijf delen omvat, en het andere de achtervoet, zonder de filet, in één enkel deel',
    '02023050': 'crops, "chucks and blades" en "briskets" van runderen, uitgebeend, bevroren',
    '02023090': 'vlees van runderen, uitgebeend, bevroren (m.u.v. voorvoeten, geheel of verdeeld in <= 5 delen, waarbij iedere voorvoet in één enkel vriesblok wordt aangeboden, alsmede zgn. "compensated quarters" aangeboden in twee vriesblokken, waarvan het ene blok de voorvoet in zijn geheel of verdeeld in <= 5 delen omvat, en het andere de achtervoet, zonder de filet, in één enkel deel en m.u.v. "crops", "chucks and blades" en "briskets")',
    '02031110': 'varkens "huisdieren", hele en halve dieren, vers of gekoeld',
    '02031190': 'varkens "in het wild levend", hele en halve dieren, vers of gekoeld',
    '02031211': 'hammen en delen daarvan, met been, van varkens "huisdieren", vers of gekoeld',
    '02031219': 'schouders en delen daarvan, met been, van varkens "huisdieren", vers of gekoeld',
    '02031290': 'hammen en schouders, alsmede delen daarvan, met been, van varkens "in het wild levend", vers of gekoeld',
    '02031911': 'voorstukken en delen daarvan, van varkens "huisdieren", vers of gekoeld',
    '02031913': 'karbonadestrengen en delen daarvan, van varkens "huisdieren", vers of gekoeld',
    '02031915': 'buiken "buikspek" en delen daarvan, van varkens "huisdieren", vers of gekoeld',
    '02031955': 'vlees van varkens "huisdieren", uitgebeend, vers of gekoeld (m.u.v. buiken "buikspek" en delen daarvan)',
    '02031959': 'Vlees van varkens "huisdieren", met been, vers of gekoeld (m.u.v. hele en halve dieren en m.u.v. hammen, schouders, voorstukken, karbonadestrengen en buiken "buikspek" en delen daarvan)',
    '02031990': 'vlees van varkens "in het wild levend", vers of gekoeld (m.u.v. hele en halve dieren en m.u.v. hammen en schouders, alsmede delen daarvan, met been)',
    '02032110': 'varkens "huisdieren", hele en halve dieren, bevroren',
    '02032190': 'varkens "in het wild levend", hele en halve dieren, bevroren',
    '02032211': 'hammen en delen daarvan, met been, van varkens "huisdieren", bevroren',
    '02032219': 'schouders en delen daarvan, met been, van varkens "huisdieren", bevroren',
    '02032290': 'hammen en schouders, alsmede delen daarvan, met been, van varkens "in het wild levend", bevroren',
    '02032911': 'voorstukken en delen daarvan, van varkens "huisdieren", bevroren',
    '02032913': 'karbonadestrengen en delen daarvan, van varkens "huisdieren", bevroren',
    '02032915': 'buiken "buikspek" en delen daarvan, van varkens "huisdieren", bevroren',
    '02032955': 'vlees van varkens "huisdieren", uitgebeend, bevroren (m.u.v. buiken "buikspek" en delen daarvan)',
    '02032959': 'vlees van varkens "huisdieren", met been, bevroren (m.u.v. hele en halve dieren en m.u.v. hammen, schouders, voorstukken, karbonadestrengen en buiken "buikspek" en delen daarvan)',
    '02032990': 'vlees van varkens "in het wild levend", bevroren (m.u.v. hele en halve dieren en m.u.v. hammen en schouders, alsmede delen daarvan, met been)',
    '02041000': 'lammeren, hele en halve dieren, vers of gekoeld',
    '02042100': 'schapen, hele en halve dieren, vers of gekoeld (m.u.v. lammeren)',
    '02042210': 'voorstukken en halve voorstukken van schapen, vers of gekoeld',
    '02042230': 'nierstukken en/of zadels en halve nierstukken en/of zadels, van schapen, vers of gekoeld',
    '02042250': 'achterstellen en halve achterstellen van schapen, vers of gekoeld',
    '02042290': 'vlees van schapen, met been, vers of gekoeld (m.u.v. hele en halve dieren, voorstukken en halve voorstukken, nierstukken en/of zadels en halve nierstukken en/of zadels en achterstellen en halve achterstellen)',
    '02042300': 'vlees van schapen, uitgebeend, vers of gekoeld',
    '02043000': 'lammeren, hele en halve dieren, bevroren',
    '02044100': 'schapen, hele en halve dieren, bevroren (m.u.v. lammeren)',
    '02044210': 'voorstukken en halve voorstukken van schapen, bevroren',
    '02044230': 'nierstukken en/of zadels en halve nierstukken en/of zadels, van schapen, bevroren',
    '02044250': 'achterstellen en halve achterstellen van schapen, bevroren',
    '02044290': 'vlees van schapen, met been, bevroren (m.u.v. hele en halve dieren, voorstukken en halve voorstukken, nierstukken en/of zadels en halve nierstukken en/of zadels en achterstellen en halve achterstellen)',
    '02044310': 'vlees van lammeren, uitgebeend, bevroren',
    '02044390': 'vlees van schapen, uitgebeend, bevroren (m.u.v. dat van lammeren)',
    '02045011': 'geiten, hele en halve dieren, vers of gekoeld',
    '02045013': 'voorstukken en halve voorstukken van geiten, vers of gekoeld',
    '02045015': 'nierstukken en/of zadels en halve nierstukken en/of zadels, van geiten, vers of gekoeld',
    '02045019': 'achterstellen en halve achterstellen van geiten, vers of gekoeld',
    '02045031': 'vlees van geiten, met been, vers of gekoeld (m.u.v. hele en halve dieren, voorstukken en halve voorstukken, nierstukken en/of zadels en halve nierstukken en/of zadels en achterstellen en halve achterstellen)',
    '02045039': 'vlees van geiten, uitgebeend, vers of gekoeld',
    '02045051': 'geiten, hele en halve dieren, bevroren',
    '02045053': 'voorstukken en halve voorstukken van geiten, bevroren',
    '02045055': 'nierstukken en/of zadels en halve nierstukken en/of zadels, van geiten, bevroren',
    '02045059': 'achterstellen en halve achterstellen van geiten, bevroren',
    '02045071': 'vlees van geiten, met been, bevroren (m.u.v. hele en halve dieren, voorstukken en halve voorstukken, nierstukken en/of zadels en halve nierstukken en/of zadels en achterstellen en halve achterstellen)',
    '02045079': 'vlees van geiten, uitgebeend, bevroren',
    '02050020': 'vlees van paarden, van ezels, van muildieren of van muilezels, vers of gekoeld',
    '02050080': 'vlees van paarden, van ezels, van muildieren of van muilezels, bevroren',
    '02061010': 'slachtafvallen van runderen, eetbaar, vers of gekoeld, bestemd voor de vervaardiging van farmaceutische producten',
    '02061095': 'longhaasjes en omlopen van runderen, eetbaar, vers of gekoeld (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02061098': 'Slachtafvallen van runderen, eetbaar, vers of gekoeld (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten en m.u.v. longhaasjes en omlopen)',
    '02062100': 'tongen van runderen, eetbaar, bevroren',
    '02062200': 'levers van runderen, eetbaar, bevroren',
    '02062910': 'slachtafvallen van runderen, eetbaar, bevroren, bestemd voor de vervaardiging van farmaceutische producten (m.u.v. tongen en levers)',
    '02062991': 'longhaasjes en omlopen van runderen, eetbaar, bevroren (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02062999': 'slachtafvallen van runderen, eetbaar, bevroren (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten en m.u.v. tongen, levers, longhaasjes en omlopen)',
    '02063000': 'slachtafvallen van varkens, eetbaar, vers of gekoeld',
    '02064100': 'levers van varkens, eetbaar, bevroren',
    '02064900': 'Eetbare slachtafvallen van varkens, bevroren (m.u.v. levers)',
    '02068010': 'slachtafvallen van schapen, van geiten, van paarden, van ezels, van muildieren en van muilezels, eetbaar, vers of gekoeld, bestemd voor de vervaardiging van farmaceutische producten',
    '02068091': 'slachtafvallen van paarden, van ezels, van muildieren en van muilezels, eetbaar, vers of gekoeld (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02068099': 'slachtafvallen van schapen en van geiten, eetbaar, vers of gekoeld (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02069010': 'slachtafvallen van schapen, van geiten, van paarden, van ezels, van muildieren en van muilezels, eetbaar, bevroren, bestemd voor de vervaardiging van farmaceutische producten',
    '02069091': 'slachtafvallen van paarden, van ezels, van muildieren en van muilezels, eetbaar, bevroren (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02069099': 'slachtafvallen van schapen en van geiten, eetbaar, bevroren (m.u.v. die bestemd voor de vervaardiging van farmaceutische producten)',
    '02071110': 'hanen en kippen "pluimvee", geplukt, ontdarmd, met kop en met poten "zgn. kippen 83%", vers of gekoeld',
    '02071130': 'hanen en kippen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. kippen 70%", vers of gekoeld',
    '02071190': 'hanen en kippen "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. kippen 65%", of in andere staat aangeboden, vers of gekoeld (niet in stukken gesneden en m.u.v. zgn. kippen 83% en zgn. kippen 70%)',
    '02071210': 'hanen en kippen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. kippen 70%", bevroren',
    '02071290': 'hanen en kippen "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. kippen 65%", of in andere staat aangeboden, bevroren (niet in stukken gesneden en m.u.v. zgn. kippen 70%)',
    '02071310': 'delen van hanen of van kippen "pluimvee", uitgebeend, vers of gekoeld',
    '02071320': 'helften en kwarten van hanen of van kippen "pluimvee", vers of gekoeld',
    '02071330': 'hele vleugels, ook indien ontdaan van de spits, van hanen of van kippen "pluimvee", vers of gekoeld',
    '02071340': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van hanen of van kippen "pluimvee", vers of gekoeld',
    '02071350': 'borsten en delen daarvan, met been, van hanen of van kippen "pluimvee", vers of gekoeld',
    '02071360': 'dijen en delen daarvan, met been, van hanen of van kippen "pluimvee", vers of gekoeld',
    '02071370': 'delen van hanen of van kippen "pluimvee", met been, vers of gekoeld (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan)',
    '02071391': 'levers van hanen of van kippen "pluimvee", eetbaar, vers of gekoeld',
    '02071399': 'eetbare slachtafvallen van hanen of van kippen "pluimvee", vers of gekoeld (m.u.v. levers)',
    '02071410': 'delen van hanen of van kippen "pluimvee", uitgebeend, bevroren',
    '02071420': 'helften en kwarten van hanen of van kippen "pluimvee", bevroren',
    '02071430': 'hele vleugels, ook indien ontdaan van de spits, van hanen of van kippen "pluimvee", bevroren',
    '02071440': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van hanen of van kippen "pluimvee", bevroren',
    '02071450': 'borsten en delen daarvan, met been, van hanen of van kippen "pluimvee", bevroren',
    '02071460': 'dijen en delen daarvan, met been, van hanen of van kippen "pluimvee", bevroren',
    '02071470': 'delen van hanen of van kippen "pluimvee", met been, bevroren (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan)',
    '02071491': 'levers van hanen of van kippen "pluimvee", eetbaar, bevroren',
    '02071499': 'eetbare slachtafvallen van hanen of van kippen "pluimvee", bevroren (m.u.v. levers)',
    '02072410': 'kalkoenen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. kalkoenen 80%", vers of gekoeld',
    '02072490': 'kalkoenen "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. kalkoenen 73%", of in andere staat aangeboden, vers of gekoeld (niet in stukken gesneden en m.u.v. zgn. kalkoenen 80%)',
    '02072510': 'kalkoenen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. kalkoenen 80%", bevroren',
    '02072590': 'kalkoenen "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. kalkoenen 73%", of in andere staat aangeboden, bevroren (niet in stukken gesneden en m.u.v. zgn. kalkoenen 80%)',
    '02072610': 'delen van kalkoenen "pluimvee", uitgebeend, vers of gekoeld',
    '02072620': 'helften en kwarten van kalkoenen "pluimvee", vers of gekoeld',
    '02072630': 'vleugels, ook indien ontdaan van de spits, van kalkoenen "pluimvee", vers of gekoeld',
    '02072640': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van kalkoenen "pluimvee", vers of gekoeld',
    '02072650': 'borsten en delen daarvan, met been, van kalkoenen "pluimvee", vers of gekoeld',
    '02072660': 'onderdijen en delen daarvan, met been, van kalkoenen "pluimvee", vers of gekoeld',
    '02072670': 'dijen en delen daarvan, met been, van kalkoenen "pluimvee", vers of gekoeld (m.u.v. onderdijen en delen daarvan)',
    '02072680': 'delen van kalkoenen "pluimvee", met been, vers of gekoeld (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan)',
    '02072691': 'levers van kalkoenen "pluimvee", eetbaar, vers of gekoeld',
    '02072699': 'eetbare slachtafvallen van kalkoenen "pluimvee", vers of gekoeld (m.u.v. levers)',
    '02072710': 'delen van kalkoenen "pluimvee", uitgebeend, bevroren',
    '02072720': 'helften en kwarten van kalkoenen "pluimvee", bevroren',
    '02072730': 'vleugels, ook indien ontdaan van de spits, van kalkoenen "pluimvee", bevroren',
    '02072740': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van kalkoenen "pluimvee", bevroren',
    '02072750': 'borsten en delen daarvan, met been, van kalkoenen "pluimvee", bevroren',
    '02072760': 'onderdijen en delen daarvan, met been, van kalkoenen "pluimvee", bevroren',
    '02072770': 'dijen en delen daarvan, met been, van kalkoenen "pluimvee", bevroren (m.u.v. onderdijen en delen daarvan)',
    '02072780': 'delen van kalkoenen "pluimvee", met been, bevroren (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan)',
    '02072791': 'levers van kalkoenen "pluimvee", eetbaar, bevroren',
    '02072799': 'slachtafvallen van kalkoenen "pluimvee", eetbaar, bevroren (m.u.v. levers)',
    '02074120': 'eenden "pluimvee", geplukt, uitgebloed, al dan niet ontdarmd, met kop en met poten "zgn. eenden 85%", niet in stukken gesneden, vers of gekoeld',
    '02074130': 'eenden "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. eenden 70%", niet in stukken gesneden, vers of gekoeld',
    '02074180': 'eenden "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. eenden 63%" of in andere staat aangeboden, niet in stukken gesneden, vers of gekoeld',
    '02074230': 'eenden "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, doch met hals, met hart, met lever en met spiermaag "zgn. eenden 70%", niet in stukken gesneden, bevroren',
    '02074280': 'eenden "pluimvee", geplukt, schoongemaakt, ontdaan van kop, hals, poten, hart, lever en spiermaag "zgn. eenden 63%", of in andere staat aangeboden, niet in stukken gesneden,  bevroren',
    '02074300': 'vette levers "foies gras" van eenden "pluimvee", vers of gekoeld',
    '02074410': 'delen van eenden "pluimvee", uitgebeend, vers of gekoeld',
    '02074421': 'helften en kwarten van eenden "pluimvee", vers of gekoeld',
    '02074431': 'Hele vleugels, ook indien ontdaan van de spits, van eenden "pluimvee", vers of gekoeld',
    '02074441': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van eenden "pluimvee", vers of gekoeld',
    '02074451': 'borsten en delen daarvan, met been, van eenden "pluimvee", vers of gekoeld',
    '02074461': 'dijen en delen daarvan, met been, van eenden "pluimvee", vers of gekoeld',
    '02074471': 'eendenpaletots "pluimvee", met been, vers of gekoeld',
    '02074481': 'delen van eenden "pluimvee", met been, vers of gekoeld (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan, en m.u.v. eendenpaletots)',
    '02074491': 'levers van eenden "pluimvee", eetbaar, vers of gekoeld (m.u.v. vette levers "foies gras")',
    '02074499': 'slachtafvallen van eenden "pluimvee", eetbaar, vers of gekoeld (m.u.v. levers)',
    '02074510': 'delen van eenden  "pluimvee", uitgebeend, bevroren',
    '02074521': 'helften en kwarten van eenden "pluimvee", bevroren',
    '02074531': 'Hele vleugels, ook indien ontdaan van de spits, van eenden "pluimvee", bevroren',
    '02074541': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen, van eenden "pluimvee", bevroren',
    '02074551': 'borsten en delen daarvan, met been, van eenden "pluimvee", bevroren',
    '02074561': 'dijen en delen daarvan, met been, van eenden "pluimvee", bevroren',
    '02074571': 'eendenpaletots "pluimvee", met been, bevroren',
    '02074581': 'delen van eenden "pluimvee", met been, bevroren (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan, en m.u.v. zgn. eendenpaletots)',
    '02074593': 'vette levers "foies gras" van eenden "pluimvee", eetbaar, bevroren',
    '02074595': 'levers van eenden "pluimvee", eetbaar, bevroren (m.u.v. vette levers "foies gras" van eenden)',
    '02074599': 'slachtafvallen van eenden "pluimvee", eetbaar, bevroren (m.u.v. levers)',
    '02075110': 'ganzen "pluimvee", geplukt, uitgebloed, met darmen, met kop en met poten "zgn. ganzen 82%", niet in stukken gesneden, vers of gekoeld',
    '02075190': 'ganzen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, ook indien met hart en met spiermaag "zgn. ganzen 75%" of in andere staat aangeboden, niet in stukken gesneden, vers of gekoeld',
    '02075210': 'ganzen "pluimvee", geplukt, uitgebloed, met darmen, met kop en met poten "zgn. ganzen 82%", niet in stukken gesneden, bevroren',
    '02075290': 'ganzen "pluimvee", geplukt, schoongemaakt, ontdaan van kop en poten, ook indien met hart en met spiermaag "zgn. ganzen 75%", of in andere staat aangeboden, niet in stukken gesneden, bevroren',
    '02075300': 'vette levers "foies gras" van ganzen "pluimvee", eetbaar, vers of gekoeld',
    '02075410': 'delen van ganzen "pluimvee", uitgebeend, vers of gekoeld',
    '02075421': 'helften en kwarten van ganzen "pluimvee", vers of gekoeld',
    '02075431': 'Hele vleugels, ook indien ontdaan van de spits van ganzen "pluimvee", vers of gekoeld',
    '02075441': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen van ganzen "pluimvee", vers of gekoeld',
    '02075451': 'borsten en delen daarvan, met been, van ganzen "pluimvee", vers of gekoeld',
    '02075461': 'dijen en delen daarvan, met been, van ganzen "pluimvee", vers of gekoeld',
    '02075471': 'ganzenpaletots "pluimvee", met been,  vers of gekoeld',
    '02075481': 'delen van ganzen "pluimvee", met been, vers of gekoeld (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan, en m.u.v. zgn. ganzenpaletots)',
    '02075491': 'levers van ganzen "pluimvee", eetbaar, vers of gekoeld (m.u.v. vette levers "foies gras")',
    '02075499': 'slachtafvallen van ganzen "pluimvee", eetbaar, vers of gekoeld (m.u.v. levers)',
    '02075510': 'delen van ganzen "pluimvee", uitgebeend, bevroren',
    '02075521': 'helften en kwarten van ganzen "pluimvee", bevroren',
    '02075531': 'Hele vleugels, ook indien ontdaan van de spits van ganzen"pluimvee", bevroren',
    '02075541': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen van ganzen "pluimvee", bevroren',
    '02075551': 'borsten en delen daarvan, met been, van ganzen "pluimvee", bevroren',
    '02075561': 'dijen en delen daarvan, met been, van ganzen "pluimvee", bevroren',
    '02075571': 'ganzenpaletots "pluimvee", met been,  bevroren',
    '02075581': 'delen van ganzen "pluimvee", met been, bevroren (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan, en m.u.v. zgn. ganzenpaletots)',
    '02075593': 'vette levers "foies gras" van ganzen "pluimvee", eetbaar, bevroren',
    '02075595': 'levers van ganzen "pluimvee", eetbaar, bevroren (m.u.v. vette levers "foies gras")',
    '02075599': 'slachtafvallen van ganzen "pluimvee", eetbaar, bevroren (m.u.v. levers)',
    '02076005': 'parelhoenders "pluimvee", vers, gekoeld of bevroren (niet in stukken gesneden)',
    '02076010': 'delen van parelhoenders "pluimvee", uitgebeend, vers, gekoeld of bevroren',
    '02076021': 'helften en kwarten van parelhoenders "pluimvee" vers, gekoeld of bevroren',
    '02076031': 'Hele vleugels, ook indien ontdaan van de spits van parelhoenders "pluimvee", vers, gekoeld of bevroren',
    '02076041': 'ruggen, halzen, ruggen met halzen, staarten en vleugelspitsen van parelhoenders "pluimvee", vers, gekoeld of bevroren',
    '02076051': 'borsten en delen daarvan, met been van parelhoenders "pluimvee", vers,  gekoeld of bevroren',
    '02076061': 'dijen en delen daarvan, met been, van parelhoenders "pluimvee", vers, gekoeld of bevroren',
    '02076081': 'delen van parelhoenders "pluimvee", met been, vers, gekoeld of bevroren (m.u.v. helften en kwarten, hele vleugels, ook indien ontdaan van de spits, ruggen, halzen, ruggen met halzen, staarten, vleugelspitsen, borsten en dijen en delen daarvan)',
    '02076091': 'levers van parelhoenders "pluimvee", eetbaar, vers, gekoeld of bevroren',
    '02076099': 'slachtafvallen van parelhoenders "pluimvee", eetbaar, vers, gekoeld of bevroren (m.u.v. levers)',
    '02081010': 'Vlees en eetbare slachtafvallen van tamme konijnen, vers, gekoeld of bevroren',
    '02081090': 'vlees en eetbare slachtafvallen van wilde konijnen of van hazen, vers, gekoeld of bevroren',
    '02083000': 'vlees en eetbare slachtafvallen van primaten, vers, gekoeld of bevroren',
    '02084010': 'vlees van walvissen, vers, gekoeld of bevroren',
    '02084020': 'vlees van zeehonden vers, gekoeld of bevroren',
    '02084080': 'Vlees en eetbare slachtafvallen van dolfijnen of van bruinvissen "zoogdieren van de orde Cetacea", van lamantijnen of van doejongs "zoogdieren van de orde Sirenia", van zeeleeuwen en walrussen (zoogdieren van de suborde Pinnipedia), vers, gekoeld of bevroren (m.u.v. vlees van walvissen en van zeehonden)',
    '02085000': 'vlees en eetbare slachtafvallen van reptielen "slangen en zeeschildpadden daaronder begrepen", vers, gekoeld of bevroren',
    '02086000': 'Vlees en eetbare slachtafvallen van kamelen en andere kameelachtigen (Camelidae), vers, gekoeld of bevroren',
    '02089010': 'vlees en eetbare slachtafvallen van tamme duiven, vers, gekoeld of bevroren',
    '02089030': 'Vlees en eetbare slachtafvallen van wild, vers, gekoeld of bevroren (m.u.v. die van konijnen, hazen en varkens)',
    '02089060': 'vlees en eetbare slachtafvallen van rendieren, vers, gekoeld of bevroren',
    '02089070': 'kikkerbilletjes, vers, gekoeld of bevroren',
    '02089098': 'vlees en eetbare slachtafvallen, vers, gekoeld of bevroren (m.u.v. vlees en afvallen van runderen, varkens, schapen, geiten, paarden, ezels, muildieren, muilezels, pluimvee "hanen, kippen, eenden, ganzen, kalkoenen, parelhoenders", konijnen, hazen, primaten, walvissen, dolfijnen en bruinvissen "zoogdieren van de orde Cetacea", lamantijnen en doejongs "zoogdieren van de orde Sirenia", zeehonden, zeeleeuwen en walrussen (zoogdieren van de suborde Pinnipedia), reptielen, tamme duiven, wild, kamelen en andere kameelachtigen (Camelidae), rendieren en m.u.v. kikkerbilletjes)',
    '02091011': 'spek van varkens, niet gesmolten noch anderszins geëxtraheerd, vers, gekoeld, bevroren, gezouten of gepekeld (m.u.v. doorregen spek)',
    '02091019': 'spek van varkens, niet gesmolten noch anderszins geëxtraheerd, gedroogd of gerookt (m.u.v. doorregen spek)',
    '02091090': 'varkensvet, ongesmolten noch anderszins geëxtraheerd, vers, gekoeld, bevroren, gezouten, gepekeld, gedroogd of gerookt',
    '02099000': 'vet van gevogelte, ongesmolten noch anderszins geëxtraheerd, vers, gekoeld, bevroren, gezouten, gepekeld, gedroogd of gerookt',
    '02101111': 'hammen en delen daarvan, met been, van varkens "huisdieren", gezouten of gepekeld',
    '02101119': 'schouders en delen daarvan, met been, van varkens "huisdieren", gezouten of gepekeld',
    '02101131': 'hammen en delen daarvan, met been, van varkens "huisdieren", gedroogd of gerookt',
    '02101139': 'schouders en delen daarvan, met been, van varkens "huisdieren", gedroogd of gerookt',
    '02101190': 'hammen en schouders, alsmede delen daarvan, met been, van varkens "in het wild levend", gezouten, gepekeld, gedroogd of gerookt',
    '02101211': 'buiken "buikspek" en delen daarvan, van varkens "huisdieren", gezouten of gepekeld',
    '02101219': 'buiken "buikspek" en delen daarvan, van varkens "huisdieren", gedroogd of gerookt',
    '02101290': 'buiken "buikspek" en delen daarvan, van varkens "in het wild levend", gezouten, gepekeld, gedroogd of gerookt',
    '02101910': 'halve baconvarkens en "spencers", van varkens "huisdieren", gezouten of gepekeld',
    '02101920': '3/4-sides en "middles", van varkens "huisdieren", gezouten of gepekeld',
    '02101930': 'voorstukken en delen daarvan, van varkens "huisdieren", gezouten of gepekeld',
    '02101940': 'karbonadestrengen en delen daarvan, van varkens "huisdieren", gezouten of gepekeld',
    '02101950': 'Vlees van varkens [huisdieren], gezouten of gepekeld (m.u.v. hammen en schouders, alsmede delen daarvan, buiken [buikspek] en delen daarvan, halve baconvarkens en "spencers", "3/4-sides" en "middles", en m.u.v. voorstukken en karbonadestrengen en delen daarvan)',
    '02101960': 'voorstukken en delen daarvan, van varkens "huisdieren", gedroogd of gerookt',
    '02101970': 'karbonadestrengen en delen daarvan, van varkens "huisdieren", gedroogd of gerookt',
    '02101981': 'vlees van varkens "huisdieren", uitgebeend, gedroogd of gerookt (m.u.v. buiken "buikspek" en delen daarvan)',
    '02101989': 'vlees van varkens "huisdieren", met been, gedroogd of gerookt (m.u.v. hammen en schouders, alsmede delen daarvan, buiken "buikspek" en delen daarvan, en m.u.v. voorstukken en karbonadestrengen en delen daarvan)',
    '02101990': 'vlees van varkens "in het wild levend", gezouten, gepekeld, gedroogd of gerookt (m.u.v. hammen en schouders, alsmede delen daarvan, met been, en buiken "buikspek" en delen daarvan)',
    '02102010': 'vlees van runderen, met been, gezouten, gepekeld, gedroogd of gerookt',
    '02102090': 'vlees van runderen, uitgebeend, gezouten, gepekeld, gedroogd of gerookt',
    '02109100': 'vlees en eetbare slachtafvallen, gezouten, gepekeld, gedroogd of gerookt, alsmede meel en poeder van vlees of van slachtafvallen, geschikt voor menselijke consumptie, van primaten',
    '02109210': 'vlees en eetbare slachtafvallen, gezouten, gepekeld, gedroogd of gerookt, alsmede meel en poeder van vlees of van slachtafvallen, geschikt voor menselijke consumptie, van walvissen, van dolfijnen of van bruinvissen "zoogdieren van de orde Cetacea", van lamantijnen of van doejongs "zoogdieren van de orde Sirenia"',
    '02109291': 'Vlees, gezouten, gepekeld, gedroogd of gerookt, van zeehonden, van zeeleeuwen of van walrussen "zoogdieren van de suborde Pinnipedia".',
    '02109292': 'eetbare slachtafvallen, gezouten, gepekeld, gedroogd of gerookt, van zeehonden, van zeeleeuwen of van walrussen "zoogdieren van de suborde Pinnipedia".',
    '02109299': 'Meel en poeder, van vlees of van slachtafvallen, geschikt voor menselijke consumptie, van zeehonden, van zeeleeuwen of van walrussen "zoogdieren van de suborde Pinnipedia".',
    '02109300': 'vlees en eetbare slachtafvallen, gezouten, gepekeld, gedroogd of gerookt, alsmede meel en poeder van vlees of van slachtafvallen, geschikt voor menselijke consumptie, van reptielen "slangen en zeeschildpadden daaronder begrepen"',
    '02109910': 'vlees van paarden, gezouten, gepekeld of gedroogd',
    '02109921': 'vlees van schapen en van geiten, met been, gezouten, gepekeld, gedroogd of gerookt',
    '02109929': 'vlees van schapen en van geiten, uitgebeend, gezouten, gepekeld, gedroogd of gerookt',
    '02109931': 'vlees van rendieren, gezouten, gepekeld, gedroogd of gerookt',
    '02109939': 'Vlees, gezouten, gepekeld, gedroogd of gerookt (m.u.v. vlees van varkens, vlees van runderen, van rendieren, van schapen en van geiten, van primaten, van walvissen, van dolfijnen of van bruinvissen (zoogdieren van de orde Cetacea), van lamantijnen of van doejongs (zoogdieren van de orde Sirenia), van zeehonden, van zeeleeuwen of van walrussen (zoogdieren van de suborde Pinnipedia), van reptielen (slangen en zeeschildpadden daaronder begrepen) of vlees van paarden, gezouten, gepekeld of gedroogd)',
    '02109941': 'levers van varkens "huisdieren", eetbaar, gezouten, gepekeld, gedroogd of gerookt',
    '02109949': 'slachtafvallen van varkens "huisdieren", eetbaar, gezouten, gepekeld, gedroogd of gerookt (m.u.v. levers)',
    '02109951': 'longhaasjes en omlopen van runderen, eetbaar, gezouten, gepekeld, gedroogd of gerookt',
    '02109959': 'slachtafvallen van runderen, eetbaar, gezouten, gepekeld, gedroogd of gerookt (m.u.v. longhaasjes en omlopen)',
    '02109971': 'vette levers "foies gras" van ganzen en van eenden, eetbaar, gezouten of gepekeld',
    '02109979': 'levers van pluimvee, eetbaar, gezouten, gepekeld, gedroogd of gerookt (m.u.v. vette levers "foies gras" van ganzen en van eenden)',
    '02109985': 'eetbare slachtafvallen, gezouten, gepekeld, gedroogd of gerookt (m.u.v. afvallen van varkens "huisdieren", runderen, primaten, walvissen, dolfijnen en bruinvissen "zoogdieren van de orde Cetacea", lamantijnen en doejongs "zoogdieren van de orde Sirenia", van zeehonden, zeeleeuwen of van walrussen "zoogdieren van de suborde Pinnipedia", reptielen en m.u.v. levers van pluimvee)',
    '02109990': 'Meel en poeder van vlees of van slachtafvallen, geschikt voor menselijke consumptie (m.u.v. die van primaten, van walvissen, van dolfijnen of van bruinvissen (zoogdieren van de orde Cetacea); van lamantijnen of van doejongs (zoogdieren van de orde Sirenia); van zeehonden, van zeeleeuwen of van walrussen (zoogdieren van de suborde Pinnipedia en reptielen)',
    '03011100': 'zoetwatersiervis, levend',
    '03011900': 'siervis, levend (m.u.v. zoetwatersiervis)',
    '03019110': 'forel van de soorten "Oncorhynchus apache" en "Oncorhynchus chrysogaster", levend',
    '03019190': 'forel van de soorten "Salmo trutta", "Oncorhynchus mykiss", "Oncorhynchus clarki", "Oncorhynchus aguabonita" en "Oncorhynchus gilae", levend',
    '03019210': 'paling of aal "Anguilla spp", levend, met een lengte van < 12 cm',
    '03019230': 'paling of aal "Anguilla spp", levend, met een lengte van >=12 cm, doch < 20 cm',
    '03019290': 'paling of aal "Anguilla spp", levend, met een lengte van >= 20 cm',
    '03019300': 'Karper (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophtalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), levend',
    '03019410': 'Atlantische blauwvintonijn "Thunnus thynnus", levend',
    '03019490': 'Pacifische blauwvintonijn "Thunnus orientalis", levend',
    '03019500': 'zuidelijke blauwvintonijn "Thunnus maccoyii", levend',
    '03019911': 'Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", Atlantische zalm "Salmo salar" en Donauzalm "Hucho hucho", levend',
    '03019917': 'zoetwatervis, levend (m.u.v. siervis, forel, paling of aal, karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", Atlantische zalm "Salmo salar" en Donauzalm "Hucho hucho")',
    '03019985': 'zeevis, levend (m.u.v. siervis, forel [Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache, Oncorhynchus chrysogaster]; paling of aal [Anguilla spp.]; Atlantische blauwvintonijn [Thunnus thynnus], Pacifische blauwvintonijn [Thunnus orientalis] en m.u.v. zuidelijke blauwvintonijn [Thunnus maccoyii])',
    '03021110': 'forel van de soorten "Oncorhynchus apache" en "Oncorhynchus chrysogaster", vers of gekoeld',
    '03021120': 'forel van de soort "Oncorhynchus mykiss", met kop en kieuwen, doch ontdaan van ingewanden "gutted", wegende > 1,2 kg per stuk, of ontdaan van de kop "heads off" en van ingewanden en kieuwen "gilled and gutted", wegende > 1 kg per stuk, vers of gekoeld',
    '03021180': 'forel van de soorten "Salmo trutta", "Oncorhynchus mykiss", "Oncorhynchus clarki", "Oncorhynchus aguabonita" en "Oncorhynchus gilae", vers of gekoeld (m.u.v. de soort "Oncorhynchus mykiss", met kop en kieuwen, doch ontdaan van ingewanden "gutted", wegende > 1,2 kg per stuk, of ontdaan van de kop "heads off" en van ingewanden en kieuwen "gilled and gutted", wegende > 1 kg per stuk)',
    '03021300': 'Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", vers of gekoeld',
    '03021400': 'Atlantische zalm "Salmo salar" en Donauzalm "Hucho hucho", vers of gekoeld',
    '03021900': 'zalmachtigen "Salmonidae", vers of gekoeld (m.u.v. forel, Pacifische zalm, Atlantische zalm en Donauzalm)',
    '03022110': 'zwarte heilbot "Reinhardtius hippoglossoides", vers of gekoeld',
    '03022130': 'Atlantische heilbot "Hippoglossus hippoglossus", vers of gekoeld',
    '03022190': 'Pacifische heilbot "Hippoglossus stenolepis", vers of gekoeld',
    '03022200': 'schol "Pleuronectes platessa", vers of gekoeld',
    '03022300': 'tong "Solea spp.", vers of gekoeld',
    '03022400': 'Tarbot (Psetta maxima), vers of gekoeld',
    '03022910': 'schartong "Lepidorhombus spp.", vers of gekoeld',
    '03022980': 'platvis "Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae en Citharidae", vers of gekoeld (m.u.v. heilbot, schol, tong, tarbot en schartong)',
    '03023110': 'witte tonijn "Thunnus alalunga", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023190': 'witte tonijn "Thunnus alalunga", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023210': 'geelvintonijn "Thunnus albacares", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023290': 'geelvintonijn "Thunnus albacares", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023310': 'boniet "Euthynnus -Katsuwonus- pelamis", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023390': 'boniet "Euthynnus -Katsuwonus- pelamis", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023410': 'grootoogtonijn "Thunnus obesus", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023490': 'grootoogtonijn "Thunnus obesus", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023511': 'Atlantische blauwvin tonijn "Thunnus thynnus", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023519': 'Atlantische blauwvin tonijn "Thunnus thynnus", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023591': 'Pacifische blauwvintonijn van het geslacht "Thunnus orientalis", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023599': 'Pacifische blauwvintonijn van het geslacht "Thunnus orientalis", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023610': 'zuidelijke blauwvintonijn "Thunnus maccoyii", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03023690': 'zuidelijke blauwvintonijn "Thunnus maccoyii", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03023920': 'tonijn van het geslacht "Thunnus", vers of gekoeld, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis (m.u.v. witte tonijn "Thunnus alalunga", geelvintonijn "Thunnus albacares", grootoogtonijn "Thunnus obesus", Atlantische en Pacifische blauwvintonijn "Thunnus thynnus, Thunnus orientalis" en zuidelijke blauwvintonijn "Thunnus maccoyii")',
    '03023980': 'tonijn van het geslacht "Thunnus", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis en m.u.v. witte tonijn "Thunnus alalunga", geelvintonijn "Thunnus albacares", grootoogtonijn "Thunnus obesus", Atlantische en Pacifische blauwvintonijn "Thunnus thynnus, Thunnus orientalis" en zuidelijke blauwvintonijn "Thunnus maccoyii")',
    '03024100': 'haring "Clupea harengus, Clupea pallasii", vers of gekoeld',
    '03024200': 'ansjovis "Engraulis spp.", vers of gekoeld',
    '03024310': 'sardines van de soort "Sardina pilchardus", vers of gekoeld',
    '03024330': 'sardines van het geslacht "Sardinops" en sardinella\'s "Sardinella spp.", vers of gekoeld',
    '03024390': 'sprot "Sprattus sprattus", vers of gekoeld',
    '03024400': 'makreel "Scomber scombrus, Scomber australasicus, Scomber japonicus", vers of gekoeld',
    '03024510': 'atlantische horsmakreel (Trachurus trachurus), vers of gekoeld',
    '03024530': 'Chileense horsmakreel (Trachurus murphyi), vers of gekoeld',
    '03024590': 'horsmakreel (m.u.v. atlantische horsmakreel "Trachurus trachurus", en Chileense horsmakreel "Trachurus murphyi), vers of gekoeld',
    '03024600': 'Cobia "Rachycentron canadum", vers of gekoeld',
    '03024700': 'zwaardvis "Xiphias gladius", vers of gekoeld',
    '03024911': 'Oostdwergtonijn (Euthynnus affinis), bestemd voor de industriële vervaardiging van producten bedoeld bij post 1604, vers of gekoeld',
    '03024919': 'Oostdwergtonijn "Euthynnus affinis", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van producten bedoeld bij post 1604)',
    '03024990': 'Dwergmakreel "Rastrelliger spp.", koningsmakreel "Scomberomorus spp.", paardenhorsmakreel "Caranx spp.", zilverpomfrets "Pampus spp.", Japanse makreelgeep "Cololabis saira", stekelmakreel "Decapterus spp.", lodde "Mallotus villosus", bonieten "Sarda spp.", zeilvissen "Istiophoridae", vers of gekoeld',
    '03025110': 'kabeljauw van de soort "Gadus morhua", vers of gekoeld',
    '03025190': 'kabeljauw van de soorten "Gadus ogac" en "Gadus macrocephalus", vers of gekoeld',
    '03025200': 'schelvis "Melanogrammus aeglefinus", vers of gekoeld',
    '03025300': 'koolvis "Pollachius virens", vers of gekoeld',
    '03025411': 'Kaapse heek "Merluccius capensis of Merluccius paradoxus", vers of gekoeld',
    '03025415': 'Australische heek "Merluccius australis", vers of gekoeld',
    '03025419': 'heek van het geslacht "Merluccius", vers of gekoeld (m.u.v. Kaapse heek; Australische heek)',
    '03025490': 'heek van het geslacht "Urophycis", vers of gekoeld',
    '03025500': 'Alaska koolvis "Theragra chalcogramma", vers of gekoeld',
    '03025600': 'blauwe wijting "Micromesistius poutassou", "Micromesistius australis", vers of gekoeld',
    '03025910': 'vis van de soort "Boreogadus saida", vers of gekoeld',
    '03025920': 'wijting "Merlangius merlangus", vers of gekoeld',
    '03025930': 'Witte koolvis, pollak of vlaswijting "Pollachius pollachius", vers of gekoeld',
    '03025940': 'leng "Molva spp.", vers of gekoeld',
    '03025990': 'Vis die behoort tot een der families "Bregmacerotidae", "Euclichthyidae", "Gadidae", "Macrouridae","Melanonidae","Merlucciidae", "Moridae" and "Muraenolepididae" , vers of gekoeld (m.u.v.  kabeljauw, schelvis, koolvis, heek, Alaska koolvis, Blauwe wijting, vis van de soort "Boreogadus saida", wijting, pollak  en leng).',
    '03027100': 'Tilapia "Oreochromis spp.", vers of gekoeld',
    '03027200': 'Katvis (Pangasius spp, Silurus spp., Clarias spp., Ictalurus spp.), vers of gekoeld',
    '03027300': 'Karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", vers of gekoeld',
    '03027400': 'paling of aal "Anguilla spp.", vers of gekoeld',
    '03027900': 'Nijlbaars "Lates niloticus" en slangenkopvis "Channa spp", vers  of gekoeld',
    '03028115': 'doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", vers of gekoeld',
    '03028130': 'Neushaai  "Lamna nasus", vers of gekoeld',
    '03028140': 'blauwe haai "Prionace glauca", vers of gekoeld',
    '03028180': 'Haai, vers of gekoeld (m.u.v. doornhaai "Squalus acanthias" en hondshaai " en Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca")',
    '03028200': 'Rog "Rajidae", vers of gekoeld',
    '03028300': 'Antarctische diepzeeheek "Dissostichus spp.", vers of gekoeld',
    '03028410': 'Europese zeebaars "Dicentrarchus labrax", vers of gekoeld',
    '03028490': 'Zeebaars "Dicentrarchus spp." (m.u.v. Europese zeebaars "Dicentrarchus labrax"), vers of gekoeld',
    '03028510': 'zeebrasem "Dentex dentex, Pagellus spp.", vers of gekoeld',
    '03028530': 'goudbrasem "Sparus aurata", vers of gekoeld',
    '03028590': 'Zeebrasem "Sparidae" (m.u.v. zeebrasem "Dentex dentex, Pagellus spp." en goudbrasem "Sparus aurata"), vers of gekoeld',
    '03028910': 'Zoetwatervis n.e.g., vers of gekoeld',
    '03028921': 'vis van het geslacht "Euthynnus", vers of gekoeld, bestemd voor de industriële vervaardiging van producten bedoeld bij post 1604 (m.u.v. boniet en Oostdwergtonijn)',
    '03028929': 'vis van het geslacht "Euthynnus", vers of gekoeld (m.u.v. die voor de industriële vervaardiging van producten van post 1604 en m.u.v. boniet en oostdwergtonijn).',
    '03028931': 'Noorse schelvis of roodbaars van de soort "Sebastes marinus", vers of gekoeld',
    '03028939': 'Noorse schelvis of roodbaars "Sebastes spp.", vers of gekoeld (m.u.v. de soort "Sebastes marinus")',
    '03028940': 'braam "Brama spp.", vers of gekoeld',
    '03028950': 'zeeduivel "Lophius spp.", vers of gekoeld',
    '03028960': 'roze koningklip "Genypterus blacodes", vers of gekoeld',
    '03028990': 'Vis n.e.g., vers of gekoeld',
    '03029100': 'Levers, hom en kuit, vers of gekoeld',
    '03029200': 'haaienvinnen, vers of gekoeld',
    '03029900': 'Vinnen, koppen, staarten, zwemblazen en ander eetbaar slachtafval van vis (m.u.v. levers, kuit, hom en haaienvinnen)',
    '03031100': 'rode zalm "Oncorhynchus nerka", bevroren',
    '03031200': 'Pacifische zalm "Onorchynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytsa, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", bevroren (m.u.v. rode zalm "Oncorhynchus nerka")',
    '03031300': 'Atlantische zalm "Salmo salar" en Donauzalm "Hucho hucho", bevroren',
    '03031410': 'forel van de soorten "Oncorhynchus apache" en "Oncorhynchus chrysogaster", bevroren',
    '03031420': 'forel van de soort "Oncorhynchus mykiss", met kop en kieuwen, doch ontdaan van ingewanden "gutted", wegende > 1,2 kg per stuk, of ontdaan van de kop "heads off" en van ingewanden en kieuwen "gilled and gutted", wegende > 1 kg per stuk, bevroren',
    '03031490': 'forel van de soorten "Salmo trutta", "Oncorhynchus mykiss", "Oncorhynchus clarki", "Oncorhynchus aguabonita" en "Oncorhynchus gilae", bevroren (m.u.v. de soort "Oncorhynchus mykiss", met kop en kieuwen, doch ontdaan van ingewanden "gutted", wegende > 1,2 kg per stuk, of ontdaan van de kop "heads off" en van ingewanden en kieuwen "gilled and gutted", wegende > 1 kg per stuk)',
    '03031900': 'zalmachtigen "Salmonidae", bevroren (m.u.v. Pacifische zalm, Atlantische zalm, Donauzalm en forel)',
    '03032300': 'Tilapia "Oreochromis spp", bevroren',
    '03032400': 'Katvis (Pangasius spp, Silurus spp., Clarias spp., Ictalurus spp.), bevroren',
    '03032500': 'Karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", bevroren',
    '03032600': 'paling of aal "Anguilla spp.", bevroren',
    '03032900': 'Nijlbaars "Lates niloticus" en slangenkopvis "Channa spp.", bevroren',
    '03033110': 'zwarte heilbot "Reinhardtius hippoglossoides", bevroren',
    '03033130': 'Atlantische heilbot "Hippoglossus hippoglossus", bevroren',
    '03033190': 'Pacifische heilbot "Hippoglossus stenolepis", bevroren',
    '03033200': 'schol "Pleuronectes platessa", bevroren',
    '03033300': 'tong "Solea spp.", bevroren',
    '03033400': 'Tarbot (Psetta maxima), bevroren',
    '03033910': 'bot "Platichthys flesus", bevroren',
    '03033930': 'vis van het geslacht "Rhombosolea", bevroren',
    '03033950': 'vis van de soort "Pelotreis flavilatus" en "Peltorhampus novaezealandiae", bevroren',
    '03033985': 'platvis "Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae en Citharidae", bevroren (m.u.v. heilbot, schol, zeetong, tarbot, bot, vis van het geslacht "Rhombosolea" en vis van de soort "Pelotreis flavilatus" of "Peltorhampusnovaezelandiae")',
    '03034110': 'Witte tonijn "Thunnus alalunga", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034190': 'witte tonijn "Thunnus alalunga", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034220': 'geelvintonijn "Thunnus albacares", bevroren, bestemd voor de industriële vervaardiging van producten bedoeld bij post 1604',
    '03034290': 'Geelvintonijn "Thunnus albacares", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034310': 'Boniet "Euthynnus -Katsuwonus- pelamis", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034390': 'boniet "Euthynnus -Katsuwonus- pelamis", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034410': 'Grootoogtonijn "Thunnus obesus", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034490': 'grootoogtonijn "Thunnus obesus", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034512': 'Atlantische blauwvin tonijn "Thunnus thynnus", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034518': 'Atlantische blauwvintonijn "Thunnus thynnus", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034591': 'Pacifische blauwvintonijn "Thunnus orientalis" bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034599': 'Pacifische blauwvintonijn "Thunnus orientalis", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034610': 'Zuidelijke blauwvintonijn "Thunnus maccoyii", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis',
    '03034690': 'zuidelijke blauwvintonijn "Thunnus maccoyii", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03034920': 'Tonijn van het geslacht "Thunnus", bevroren, bestemd voor de industriële vervaardiging van bereidingen en conserven van vis (m.u.v. witte tonijn "Thunnus alalunga", geelvintonijn "Thunnus albacares", grootoogtonijn "Thunnus obesus", Atlantische blauwvintonijn "Thunnus thynnus", Pacifische blauwvintonijn "Thunnus orientalis" en zuidelijke blauwvintonijn "Thunnus maccoyii")',
    '03034985': 'tonijn van het geslacht "Thunnus", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis en m.u.v. witte tonijn "Thunnus alalunga", geelvintonijn "Thunnus albacares", grootoogtonijn "Thunnus obesus", Atlantische blauwvintonijn "Thunnus thynnus", Pacifische blauwvintonijn "Thunnus orientalis" en zuidelijke blauwvintonijn "Thunnus maccoyii")',
    '03035100': 'haring "Clupea harengus, Clupea pallasii", bevroren',
    '03035310': 'sardines van de soort "Sardina pilchardus", bevroren',
    '03035330': 'Sardines van het geslacht "Sardinops spp." en sardinella\'s "Sardinella spp.", bevroren',
    '03035390': 'Sprot "Sprattus sprattus", bevroren',
    '03035410': 'makreel van de soorten "Scomber scombrus" en "Scomber japonicus", bevroren',
    '03035490': 'makreel van de soort "Scomber australasicus", bevroren',
    '03035510': 'Atlantische horsmakreel (Trachurus trachurus), bevroren',
    '03035530': 'Chileens horsmakreel (Trachurus murphyi), bevroren',
    '03035590': 'horsmakreel (m.u.v. atlantische horsmakreel "Trachurus trachurus", en Chileense horsmakreel "Trachurus murphyi), bevroren',
    '03035600': 'Cobia "Rachycentron canadum", bevroren',
    '03035700': 'zwaardvis "Xiphias gladius", bevroren',
    '03035910': 'ansjovis "Engraulis spp.", bevroren',
    '03035921': 'Oostdwergtonijn "Euthynnus affinis",  bestemd voor de industriële vervaardiging van producten bedoeld bij post 1604, bevroren',
    '03035929': 'Oostdwergtonijn "Euthynnus affinis", bevroren (m.u.v. die voor de industriële vervaardiging van bereidingen en conserven van vis)',
    '03035990': 'Dwergmakreel "Rastrelliger spp.", koningsmakreel "Scomberomorus spp.", paardenhorsmakreel "Caranx spp.", zilverpomfrets "Pampus spp.", Japanse makreelgeep "Cololabis saira", stekelmakreel "Decapterus spp.", lodde "Mallotus villosus", bonieten "Sarda spp.", zeilvissen "Istiophoridae", bevroren',
    '03036310': 'kabeljauw van de soort "Gadus morhua" , bevroren',
    '03036330': 'kabeljauw van de soort "Gadus ogac", bevroren',
    '03036390': 'kabeljauw van de soort "Gadus macrocephalus", bevroren',
    '03036400': 'schelvis "Melanogrammus aeglefinus", bevroren',
    '03036500': 'koolvis "Pollachius virens", bevroren',
    '03036611': 'Kaapse heek "Merluccius capensis of Merluccius paradoxus", bevroren',
    '03036612': 'Argentijnse heek of Zuid-Amerikaanse heek "Merluccius hubbsi", bevroren',
    '03036613': 'Australische heek "Merluccius australis", bevroren',
    '03036619': 'heek van het geslacht "Merluccius", bevroren (m.u.v. Kaapse heek; Argentijnse of Zuid-Amerikaanse heek; Australische heek)',
    '03036690': 'heek van het geslacht "Urophycis", bevroren',
    '03036700': 'Alaska koolvis "Theragra chalcogramma", bevroren',
    '03036810': 'blauwe wijting "Micromesistius poutassou", bevroren',
    '03036890': 'zuidelijke blauwe wijting "Micromesistius australis", bevroren',
    '03036910': 'vis van de soort "Boreogadus saida", bevroren',
    '03036930': 'wijting "Merlangius merlangus", bevroren',
    '03036950': 'Witte koolvis, pollak of vlaswijting "Pollachius pollachius", bevroren',
    '03036970': 'blauwe grenadier "Macruronus novaezealandiae", bevroren',
    '03036980': 'leng "Molva spp.", bevroren',
    '03036990': 'Bevroren vis die behoort tot een der families „Bregmacerotidae”, „Euclichthyidae”, „Gadidae”, „Macrouridae”, „Melanonidae”, „Merlucciidae”, „Moridae” en „Muraenolepididae”, (m.u.v kabeljauw (Gadus morhua, Gadus ogac, Gadus macrocephalus) of schelvis (Melanogrammus aeglefinus) of koolvis (Pollachius virens) of heek (Merluccius spp., Urophycis spp.) of Alaska koolvis (Theragra chalcogramma) of blauwe wijting (Micromesistius poutassou, Micromesistius australis) of vis van de soort „Boreogadus saida” of wijting (Merlangius merlangus) of pollak (Pollachius pollachius) of blauwe grenadier (Macruronus novaezelandiae) en leng (Molva spp.))',
    '03038115': 'doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", bevroren',
    '03038130': 'Neushaai, bevroren "Lamna nasus"',
    '03038140': 'Blauwe haai "Prionace glauca", bevroren',
    '03038190': 'Haai, bevroren (m.u.v. doornhaai  "Squalus acanthias", hondshaai "Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca")',
    '03038200': 'Rog "Rajidae", bevroren',
    '03038300': 'Antarctische diepzeeheek "Dissostichus spp.", bevroren',
    '03038410': 'Europese zeebaars "Dicentrarchus labrax", bevroren',
    '03038490': 'Zeebaars "Dicentrarchus ", bevroren (m.u.v. Europese zeebaars "Dicentrarchus labrax")',
    '03038910': 'zoetwatervis , geschikt voor menselijke consumptie, n.e.g., bevroren',
    '03038921': 'Vis van het geslacht "Euthynnus", bevroren, bestemd voor de industriële vervaardiging van producten bedoeld bij post 1604 (m.u.v. boniet en oostdwergtonijn)',
    '03038929': 'vis van het geslacht "Euthynnus", bevroren (m.u.v. die voor de industriële vervaardiging van producten bedoeld bij post 1604 en m.u.v. boniet en oostdwergtonijn)',
    '03038931': 'Noorse schelvis of roodbaars van de soort "Sebastes marinus", bevroren',
    '03038939': 'Noorse schelvis of roodbaars "Sebastes spp.", bevroren (m.u.v. die van de soort "Sebastes marinus")',
    '03038940': 'vis van de soort "Orcynopsis unicolor", bevroren',
    '03038950': 'zeebrasem "Dentex dentex, Pagellus spp.", bevroren',
    '03038955': 'goudbrasem "Sparus aurata", bevroren',
    '03038960': 'braam "Brama spp.", bevroren',
    '03038965': 'zeeduivel "Lophius spp.", bevroren',
    '03038970': 'roze koningklip "Genypterus blacodes", bevroren',
    '03038990': 'Zeevis, geschikt voor menselijke consumptie, en n.e.g., bevroren',
    '03039110': 'kuit en hom, bestemd voor de vervaardiging van desoxyribonucleïnezuur of protaminesulfaat, bevroren',
    '03039190': 'vislevers, hom en kuit, geschikt voor menselijke consumptie, bevroren (m.u.v. kuit en hom, bestemd voor de vervaardiging van desoxyribonucleïnezuur of protaminesulfaat)',
    '03039200': 'Haaienvinnen, bevroren',
    '03039900': 'Vinnen, koppen, staarten, zwemblazen en ander eetbaar slachtafval van vis ( m.u.v. levers, hom, kuit en haaienvinnen)',
    '03043100': 'Filets van Tilapia "Oreochromis spp.", vers of gekoeld',
    '03043200': 'Filets van Katvis "Pangasius "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.", vers of gekoeld',
    '03043300': 'Filets van Nijlbaars "Lates niloticus", vers of gekoeld',
    '03043900': 'Filets van karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.",  paling of aal  "Anguilla spp." en  slangenkopvis "Channa spp.", vers of gekoeld',
    '03044100': 'filets van Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", van Atlantische zalm "Salmo salar" en van Donauzalm "Hucho hucho", vers of gekoeld',
    '03044210': 'filets van forel van de soort "Oncorhynchus mykiss", wegende > 400 g per stuk, vers of gekoeld',
    '03044250': 'filets van forel van de soorten "Oncorhynchus apache" en "Oncorhynchus chrysogaster", vers of gekoeld',
    '03044290': 'filets van forel van de soorten "Salmo trutta", "Oncorhynchus mykiss" wegende <= 400 g per stuk, "Oncorhynchus clarki", "Oncorhynchus aguabonita" en "Oncorhynchus gilae", vers of gekoeld',
    '03044300': 'filets van platvis ("Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophtalmidae en Citharidae"), vers of gekoeld',
    '03044410': 'filets van kabeljauw "Gadus morhua, Gadus ogac, Gadus macrocephalus" en van vis van de soort Boreogadus saida", vers of gekoeld',
    '03044430': 'Filets van koolvis "Pollachius virens", vers of gekoeld',
    '03044490': 'filets van vis van de families "Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae", (m.u.v  die van kabeljauw van de soorten "Gadus morhua, Gadus ogac, Gadus macrocephalus" en van vis van de soort "Boreogadus saida" en van koolvis "Pollachius virens"), vers of gekoeld',
    '03044500': 'filets van zwaardvis "Xiphias gladius", vers of gekoeld',
    '03044600': 'filets van Antarctische diepzeeheek "Dissostichus spp.", vers of gekoeld',
    '03044710': 'Filets van doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", vers of gekoeld',
    '03044720': 'Filets van neushaai "Lamna nasus", vers of gekoeld',
    '03044730': 'Filets van blauwe haai "Prionace glauca", vers of gekoeld',
    '03044790': 'Filets van haai, vers of gekoeld (m.u.v. doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca")',
    '03044800': 'Filets van rog "Rajidae", vers of gekoeld',
    '03044910': 'Filets van zoetwatervis n.e.g., vers of gekoeld',
    '03044950': 'Filets van Noorse schelvis of roodbaars "Sebastes spp.", vers of gekoeld',
    '03044990': 'Filets van vis n.e.g., vers of gekoeld',
    '03045100': 'Visvlees, ook indien fijngemaakt, van Tilapias "Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.,", van karper "Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus, Hypophtalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus", Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp., van palingen of aal "Anguilla spp.", van Nijlbaars "Lates niloticus" en van slangenkopvis "Channa spp.", vers of gekoeld (m.u.v. visfilets)',
    '03045200': 'Visvlees, ook indien fijngemaakt van zalmachtigen [Salmonidae], vers of gekoeld (m.u.v. visfilets)',
    '03045300': 'Visvlees, ook indien fijngemaakt, van vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, vers of gekoeld (m.u.v. visfilets)',
    '03045400': 'visvlees, ook indien fijngemaakt, van zwaardvis "Xiphias gladius" (m.u.v. visfilets)',
    '03045500': 'visvlees, ook indien fijngemaakt, van Antarctische diepzeeheek "Dissostichus spp." vers of gekoeld (m.u.v. visfilets)',
    '03045610': 'Visvlees, ook indien fijngemaakt, van doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", vers of gekoeld (m.u.v. visfilets)',
    '03045620': 'Visvlees, ook indien fijngemaakt, van neushaai "Lamna nasus", vers of gekoeld (m.u.v. visfilets)',
    '03045630': 'Visvlees, ook indien fijngemaakt, van blauwe haai "Prionace glauca", vers of gekoeld (m.u.v. visfilets)',
    '03045690': 'Visvlees, ook indien fijngemaakt, van haai, vers of gekoeld (m.u.v. visfilets en m.u.v. doornhaai "Squalus acanthias", hondshaai "Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca")',
    '03045700': 'Visvlees, ook indien fijngemaakt, van rog "Rajidae", vers of gekoeld (m.u.v. visfilets)',
    '03045910': 'visvlees, ook indien fijngemaakt, van zoetwatervis, vers of gekoeld (m.u.v.Tilapias, Katvis, Karper, Paling of aal, Nijlbaars, van Slangenkopvis en van zalmachtigen [Salmonidae], zwaardvis, Antarctische diepzeeheek en vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae  en m.u.v. visfilets)',
    '03045950': 'Haringlappen, vers of gekoeld',
    '03045990': 'Visvlees, ook indien fijngemaakt, vers of gekoeld (m.u.v. visfilets, zoetwatervis, haringlappen,Tilapias, Katvis, Karper, Paling of aal, Nijlbaars, van Slangenkopvis en van zalmachtigen [Salmonidae], zwaardvis, Antarctische diepzeeheek, haaiensoorten, rog, vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae)',
    '03046100': 'Filets van Tilapia "Oreochromis spp.", bevroren',
    '03046200': 'Filets van Katvis, "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), bevroren',
    '03046300': 'Filets van Nijlbaars "Lates niloticus", bevroren',
    '03046900': 'filets van karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", paling of aal "Anguilla spp." en slangenkopvis "Channa spp.", bevroren',
    '03047110': 'filets van kabeljauw van de soort "Gadus macrocephalus", bevroren',
    '03047190': 'filets van kabeljauw "Gadus morhua, Gadus ogac", bevroren',
    '03047200': 'Filets van schelvis "Melanogrammus aeglefinus", bevroren',
    '03047300': 'filets van koolvis "Pollachius virens", bevroren',
    '03047411': 'filets van Kaapse heek "Merluccius capensis of Merluccius paradoxus", bevroren',
    '03047415': 'filets van Argentijnse heek of Zuid-Amerikaanse heek "Merluccius hubbsi", bevroren',
    '03047419': 'filets van heek van het geslacht "Merluccius spp.", bevroren (m.u.v. die van Kaapse heek; die van Argentijnse of Zuid-Amerikaanse heek)',
    '03047490': 'filets van heek van het geslacht "Urophycis spp.", bevroren',
    '03047500': 'filets van Alaskakoolvis "Theragra chalcogramma", bevroren',
    '03047910': 'filets van kabeljauw van de soort "Boreogadus saida", bevroren',
    '03047930': 'filets van wijting "Merlangius merlangus", bevroren',
    '03047950': 'Filets van blauwe grenadier "Macruronus novaezealandiae", bevroren',
    '03047980': 'filets van leng "Molva spp.", bevroren',
    '03047990': 'filets van vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae, bevroren (m.u.v. kabeljauw, vis van de soort "Boreogadus saida"; koolvis; schelvis; wijting; leng; heek; Alaskakoolvis; blauwe grenadier)',
    '03048100': 'filets van Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", van Atlantische zalm "Salmo salar" en van Donauzalm "Hucho hucho", bevroren',
    '03048210': 'filets van forel van de soort "Oncorhynchus mykiss", wegende > 400 g per stuk, bevroren',
    '03048250': 'filets van forel van de soort "Oncorhynchus apache" en "Oncorhynchus chrysogaster", bevroren',
    '03048290': 'filets van forel van de soorten "Salmo trutta", "Oncorhynchus mykiss" wegende <= 400 g per stuk, "Oncorhynchus clarki", "Oncorhynchus aguabonita" en "Oncorhynchus gilae", bevroren',
    '03048310': 'filets van schol "Pleuronectes platessa", bevroren',
    '03048330': 'filets van bot "Platichthys flesus", bevroren',
    '03048350': 'filets van schartong "Lepidorhombus spp.", bevroren',
    '03048390': 'filets van platvis "Pleuronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae and Citharidae" , bevroren (m.u.v. filets van schol "Pleuronectes platessa", bot "Platichthys flesus" en schartong "Lepidorhombus spp.")',
    '03048400': 'filets van zwaardvis "Xiphias gladius", bevroren',
    '03048500': 'filets van Antarctische diepzeeheek "Dissostichus spp.", bevroren',
    '03048600': 'filets van haring "Clupea harengus, Clupea pallasii", bevroren',
    '03048700': 'Filets van tonijn van het geslacht "Thunnus" en van boniet "Euthynnus (Katsuwonus) pelamis", bevroren',
    '03048811': 'filets van doornhaai "Squalus acanthias" en van hondshaai "Scyliorhinus spp.", bevroren',
    '03048815': 'Filets van neushaai "Lamna nasus", bevroren',
    '03048818': 'Filets van blauwe haai "Prionace glauca", bevroren',
    '03048819': 'Filets van haaiensoorten, bevroren (m.u.v. filets van doornhaai  "Squalus acanthias", van hondshaai "Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca")',
    '03048890': 'Filets van rog "Rajidae", bevroren',
    '03048910': 'Filets van zoetwatervis n.e.g. , bevroren',
    '03048921': 'filets van Noorse schelvis of roodbaars van de soort "Sebastes marinus", bevroren',
    '03048929': 'filets van Noorse schelvis of roodbaars "Sebastes spp.", bevroren (m.u.v. die van de soort "Sebastes marinus")',
    '03048930': 'Filets van vis van het geslacht "Euthynnus", andere dan die soorten vermeld in onderverdeling 0304 87, bevroren',
    '03048941': 'filets van makreel van de soort "Scomber australasicus", bevroren',
    '03048949': 'filets van makreel van de soort "Scomber scombrus" en "Scomber japonicus" en van vis van de soort "Orcynopsis unicolor", bevroren',
    '03048960': 'filets van zeeduivel "Lophius spp.", bevroren',
    '03048990': 'filets van vis n.e.g., bevroren',
    '03049100': 'visvlees van zwaardvis "Xiphias gladius", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049200': 'visvlees van Antarctische diepzeeheek "Dissostichus spp." ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049310': 'surimi van Tilapias "Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.,", van karper "Cyprinus carpio, Carassius spp., Ctenopharyngodon idellus, Hypophtalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus", Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.,  van palingen of aal "Anguilla spp.", van Nijlbaars "Lates niloticus" en van slangenkopvis "Channa spp.", bevroren',
    '03049390': 'Visvlees, ook indien fijngemaakt, van Tilapias "Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.,", van karper "Cyprinus carpio, Carassius spp., Ctenopharyngodon idellus, Hypophtalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus", Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.,  van palingen of aal "Anguilla spp.", van Nijlbaars "Lates niloticus" en van slangenkopvis "Channa spp.", bevroren (m.u.v. surimi en visfilets)',
    '03049410': 'Surimi van Alaska koolvis (Theragra chalcogramma), bevroren',
    '03049490': 'Visvlees van Alaskakoolvis "Theragra chalcogramma", ook indien fijngemaakt, bevroren (m.u.v. surimi en filets)',
    '03049510': 'Surimi van vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, (m.u.v. Alaskakoolvis "Theragra chalcogramma") , bevroren',
    '03049521': 'visvlees van kabeljauw van de soort "Gadus macrocephalus", ook indien fijngemaakt, bevroren (m.u.v.surimi en filets)',
    '03049525': 'visvlees van kabeljauw van de soort "Gadus morhua", ook indien fijngemaakt, bevroren (m.u.v. filets en surimi)',
    '03049529': 'Visvlees van kabeljauw van de soort "Gadus ogac" en van vis van de soort "Boreogadus saida", ook indien fijngemaakt, bevroren (m.u.v. filets en surimi)',
    '03049530': 'visvlees van schelvis "Melanogrammus aeglefinus", ook indien fijngemaakt, bevroren (m.u.v. surimi en filets)',
    '03049540': 'visvlees van koolvis "Pollachius virens", ook indien fijngemaakt, bevroren (m.u.v.surimi en filets)',
    '03049550': 'visvlees van heek "Merluccius spp.", ook indien fijngemaakt, bevroren (m.u.v. surimi en filets)',
    '03049560': 'visvlees van blauwe wijting "Micromesistius poutassou", ook indien fijngemaakt, bevroren (m.u.v.surimi en filets)',
    '03049590': 'Visvlees van vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, ook indien fijngemaakt, bevroren (m.u.v. Alaskakoolvis (Theragra chalcogramma)) kabeljauw, vis van de soort "Boreogadus saida"; koolvis; schelvis; heek "Merluccius spp."; blauwe wijting en m.u.v.surimi en visfilets)',
    '03049610': 'Visvlees, ook indien fijngemaakt, van doornhaai "Squalus acanthias" en hondshaai "Scyliorhinus spp.", bevroren',
    '03049620': 'Visvlees, ook indien fijngemaakt, van neushaai "Lamna nasus", bevroren',
    '03049630': 'Visvlees, ook indien fijngemaakt, van blauwe haai "Prionace glauca", bevroren',
    '03049690': 'Visvlees, ook indien fijngemaakt, van haai (m.u.v. doornhaai "Squalus acanthias", hondshaai "Scyliorhinus spp.", neushaai "Lamna nasus" en blauwe haai "Prionace glauca"), bevroren',
    '03049700': 'Visvlees, ook indien fijngemaakt, van rog "Rajidae", bevroren',
    '03049910': 'Surimi van vis n.e.g., bevroren',
    '03049921': 'Visvlees van zoetwatervis n.e.g., bevroren (m.u.v. filets en surimi)',
    '03049923': 'visvlees van haring "Clupea harengus, Clupea pallasii", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049929': 'visvlees van Noorse schelvis of roodbaars "Sebastes spp.", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049955': 'visvlees van schartong "Lepidorhombus spp.", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049961': 'visvlees van braam "Brama spp.", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049965': 'visvlees van zeeduivel "Lophius spp.", ook indien fijngemaakt, bevroren (m.u.v. filets)',
    '03049999': 'Visvlees, van zeevis n.e.g.,  bevroren (m.u.v. filets en surimi)',
    '03051000': 'vismeel, geschikt voor menselijke consumptie',
    '03052000': 'vislevers, hom en kuit, gedroogd, gerookt, gezouten of gepekeld',
    '03053100': 'Visfilets van  tilapia "Oreochromis spp.", katvis"Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.", karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", paling of aal"Anguilla spp.", nijlbaars "Lates niloticus" en slangenkopvis"Channa spp.",  gedroogd, gezouten of gepekeld, doch niet gerookt',
    '03053211': 'visfilets van kabeljauw van de soort "Gadus macrocephalus", gedroogd, gezouten of gepekeld, doch ongerookt',
    '03053219': 'visfilets van kabeljauw van de soorten "Gadus morhua" en "Gadus ogac" en van vis van de soort "Boreogadus saida", gedroogd, gezouten of gepekeld, doch ongerookt',
    '03053290': 'visfilets van vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, gedroogd, gezouten of gepekeld, doch ongerookt (m.u.v. die van kabeljauw van de soorten "Gadus macrocephalus", "Gadus morhua" en "Gadus ogac" en van vis van de soort "Boreogadus saida").',
    '03053910': 'visfilets van Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", van Atlantische zalm "Salmo salar" en van Donauzalm "Hucho hucho", gezouten of gepekeld, doch ongerookt',
    '03053950': 'filets van zwarte heilbot "Reinhardtius hippoglossoides", gezouten of gepekeld, doch ongerookt',
    '03053990': 'visfilets, gedroogd, gezouten of gepekeld, doch ongerookt (m.u.v. tilapia, katvis, karper, paling of aal, Nijlbaars, slangenkopvis, vis van de families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, en visfilets, gezouten of gepekeld van Pacifische zalm, Atlantische zalm, Donauzalm en zwarte heilbot)',
    '03054100': 'Gerookte Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou and Oncorhynchus rhodurus", Atlantische zalmn "Salmo salar" en Donauzalm "Hucho hucho", incl. filets (m.u.v. eetbare slachtafvallen)',
    '03054200': 'Gerookte haring (Clupea harengus, Clupea pallasii), incl. haringfilets (m.u.v. eetbare slachtafvallen)',
    '03054300': 'forel "Salmo trutta, Oncorhynchus mykiss, Oncorhynchus clarki, Oncorhynchus aguabonita, Oncorhynchus gilae, Oncorhynchus apache en Oncorhynchus chrysogaster", gerookt, incl. gerookte forelfilets (m.u.v. eetbare visafvallen)',
    '03054410': 'paling of aal "Anguilla spp.", gerookt, incl. gerookte palingfilets (m.u.v. eetbare visafvallen)',
    '03054490': 'Gerookte tilapia "Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.", karper "Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus", Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", Nijlbaars "Lates niloticus" en slangenkopvis "Channa spp.", incl. filets(m.u.v. eetbare slachtafvallen)',
    '03054910': 'Gerookte zwarte heilbot (Reinhardtius hippoglossoides), incl. filets (m.u.v. eetbare slachtafvallen)',
    '03054920': 'Gerookte Atlantische heilbot "Hippoglossus hippoglossus", incl. filets (m.u.v. eetbare slachtafvallen)',
    '03054930': 'Gerookte makreel "Scomber scombrus, Scomber australicus, Scomber japonicus", incl. filets (m.u.v. eetbare slachtafvallen)',
    '03054980': 'Gerookte vis, incl. filets (m.u.v. Pacifische zalm , Atlantische zalm, Donauzalm, haring, forel, tilapia, katvis, karper, paling of aal, nijlbaars en slangenkopvis,  zwarte heilbot,  Atlantische heilbot, makreel en m.u.v. eetbare slachtafvallen)',
    '03055110': 'kabeljauw "Gadus morhua, Gadus ogac, Gadus macrocephalus", gedroogd, doch ongezouten en ongerookt (m.u.v. kabeljauwfilets en m.u.v. eetbare visafvallen)',
    '03055190': 'kabeljauw "Gadus morhua, Gadus ogac, Gadus macrocephalus", gedroogd en gezouten, doch ongerookt (m.u.v. kabeljauwfilets en m.u.v. eetbare visafvallen)',
    '03055200': 'Tilapia"Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.", karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", paling of aal "Anguilla spp.", Nijlbaars "Lates niloticus" en slangenkopvis "Channa spp.", gedroogd, ook indien gezouten, doch niet gerookt (m.u.v. visfilets en visafvallen)',
    '03055310': 'Poolkabeljauw "Boreogadus saida",gedroogd, ook indien gezouten, doch niet gerookt (m.u.v. filets daarvan en eetbare visafvallen)',
    '03055390': 'Vis van de soort Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae en Muraenolepididae, gedroogd, ook indien gezouten, doch ongerookt (m.u.v. visfilets, eetbare visafvallen, kabeljauw "Gadus morhua, Gadus ogac, Gadus macrocephalus" en poolkabeljauw "Boreogadus saida")',
    '03055430': 'haring "Clupea harengus, Clupea pallasii", gedroogd, ook indien gezouten, doch ongerookt (m.u.v. haringfilets en m.u.v. eetbare visafvallen)',
    '03055450': 'ansjovis "Engraulis spp.", gedroogd, ook indien gezouten, doch ongerookt (m.u.v. ansjovisfilets en m.u.v. eetbare visafvallen)',
    '03055490': 'Sardines "Sardina pilchardus, Sardinops spp.", sardinella "Sardinella spp.", sprot "Sprattus sprattus", makreel "Scomber scombrus, Scomber australasicus, Scomber japonicus", dwergmakreel "Rastrelliger spp.", koningsmakreel "Scomberomorus spp.", horsmakreel "Trachurus spp.",paardenhorsmakreel "Caranx spp.", cobia "Rachycentron canadum", zilverpomfrets "Pampus spp.", Japanse makreelgeep "Cololabis saira", stekelmakreel "Decapterus spp.", lodde "Mallotus villosus", zwaardvis "Xiphias gladius", Oostdwergtonijn "Euthynnus affinis", bonieten "Sarda spp.", zeilvissen "Istiophoridae", gedroogd, ook indien gezouten, doch niet gerookt (m.u.v. visfilets en visafvallen)',
    '03055970': 'Atlantische heilbot "Hippoglossus hippoglossus", gedroogd, ook indien gezouten, doch ongerookt (m.u.v. heilbotfilets en m.u.v. eetbare visafvallen)',
    '03055985': 'gedroogde vis, andere dan eetbare visafvallen, ook indien gezouten, doch ongerookt, n.e.g.  (m.u.v. visfilets en eetbare visafvallen)',
    '03056100': 'haring "Clupea harengus, Clupea pallasii", alleen gezouten of gepekeld (m.u.v. filets en m.u.v. eetbare visafvallen)',
    '03056200': 'kabeljauw "Gadus morhua, Gadus ogac en Gadus macrocephalus", alleen gezouten of gepekeld (m.u.v. kabeljauwfilets en m.u.v. eetbare visafvallen)',
    '03056300': 'ansjovis "Engraulis spp.", alleen gezouten of gepekeld (m.u.v. ansjovisfilets en m.u.v. eetbare visafvallen)',
    '03056400': 'Tilapia"Oreochromis spp.", katvis "Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.", karper "Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.", paling of aal "Anguilla spp.", Nijlbaars "Lates niloticus" en slangenkopvis "Channa spp.", alleen gezouten of gepekeld (m.u.v. visfilets en visafvallen)',
    '03056910': 'vis van de soort "Boreogadus saida", alleen gezouten of gepekeld (m.u.v. filets daarvan en m.u.v. eetbare visafvallen)',
    '03056930': 'Atlantische heilbot "Hippoglossus hippoglossus", alleen gezouten of gepekeld (m.u.v. heilbotfilets en m.u.v. eetbare visafvallen)',
    '03056950': 'Pacifische zalm "Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha, Oncorhynchus kisutch, Oncorhynchus masou en Oncorhynchus rhodurus", Atlantische zalm "Salmo salar" en Donauzalm "Hucho hucho", alleen gezouten of gepekeld (m.u.v. zalmfilets en m.u.v. eetbare visafvallen)',
    '03056980': 'vis andere dan eetbare visafvallen, alleen gezouten of gepekeld (m.u.v. haring, kabeljauw, ansjovis, tilapia, katvis, karper, paling of aal, nijlbaars en slangenkopvis, vis van de soort "Boreogadus saida", Atlantische heilbot, Pacifische zalm, Atlantische zalm en Donauzalm en m.u.v. visfilets)',
    '03057100': 'Haaienvinnen, gerookt, gedroogd, gezouten of gepekeld',
    '03057200': 'Vissenkoppen, -staarten en zwemblazen, gerookt, gedroogd, gezouten of gepekeld',
    '03057900': 'Visvinnen en andere eetbare visafvallen, gerookt, gedroogd, gezouten of gepekeld (m.u.v. vissenkoppen en -staarten, zwemblazen en haaienvinnen)',
    '03061110': 'Staarten van langoesten "Palinurus spp., Panulirus spp., Jasus spp." ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt daaronder begrepen, bevroren',
    '03061190': 'Langoesten "Palinurus spp., Panulirus spp., Jasus spp." ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren (m.u.v. staarten van langoesten)',
    '03061210': 'zeekreeften "Homarus spp.", in gehele staat, bevroren, incl. zeekreeften die eerst zijn gestoomd of in water gekookt',
    '03061290': 'Zeekreeften "Homarus spp.", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren (m.u.v. gehele)',
    '03061410': 'Krabben "Paralithodes camchaticus", "Callinectes sapidus" en "Chionecetes spp.", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren',
    '03061430': 'Noordzeekrabben "Cancer pagurus", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren',
    '03061490': 'Krabben, ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren (m.u.v. "Paralithodes camchaticus", "Chionoecetes spp.", "Callinectes sapidus" en "Cancer pagurus")',
    '03061500': 'Langoestines "Nephrops norvegicus", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren',
    '03061691': 'Koudwatergarnalen "Crangon crangon", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren',
    '03061699': 'Koudwatergarnalen "Pandalus spp.", ook gerookt, ook indien ontdaan van de schaal, incl. die in de schaal, gestoomd of in water gekookt, bevroren',
    '03061791': 'Roze diepzeegarnaal "Parapenaeus longirostris", ook gerookt, ook indien ontdaan van de schaal, incl. garnalen in de schaal, gestoomd of in water gekookt, bevroren',
    '03061792': 'Garnalen van het geslacht Penaeus, ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen, bevroren',
    '03061793': 'Garnalen van de familie "Pandalidae", ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen, bevroren (m.u.v. die van het geslacht Pandalus)',
    '03061794': 'Garnalen van het geslacht Crangon, ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen, bevroren (m.u.v. de soort Crangon crangon")',
    '03061799': 'Garnalen,  ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen, bevroren (m.u.v. "Pandalidae", "Crangon", roze diepzeegarnaal "Parapenaeus longirostris" en "Penaeus")',
    '03061910': 'rivierkreeften, ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen, bevroren',
    '03061990': 'Schaaldieren, daaronder begrepen meel, poeder en pellets van schaaldieren, geschikt voor menselijke consumptie, ook gerookt, ook indien ontdaan van de schaal, die in de schaal gestoomd of in water gekookt daaronder begrepen (m.u.v. langoesten, zeekreeften, krabben, langoustines "Nephrops norvegicus" en koudwatergarnalen), bevroren',
    '03063100': 'langoesten "Palinurus spp., Panulirus spp., Jasus spp.", ook indien ontdaan van de schaal, levend, vers ofgekoeld',
    '03063210': 'zeekreeften "Homarus spp.", levend',
    '03063291': 'zeekreeften "Homarus spp.", in gehele staat, vers of gekoeld,',
    '03063299': 'delen van zeekreeften "Homarus spp.", ook indien ontdaan van de schaal, vers of gekoeld',
    '03063310': 'Noordzeekrabben "Cancer pagurus", ook indien ontdaan van de schaal, levend, vers of gekoeld',
    '03063390': 'Krabben, ook indien ontdaan van de schaal, levend, vers of gekoeld (m.u.v. Noordzeekrabben "Cancer pagurus")',
    '03063400': 'langoestines "Nephrops norvegicus", ook indien ontdaan van de schaal, levend, vers of gekoeld',
    '03063510': 'garnalen van de soort "Crangon crangon", ook indien ontdaan van de schaal, vers of gekoeld',
    '03063550': 'garnalen van de soort "Crangon crangon", levend',
    '03063590': 'koudwatergarnalen van het geslacht "Pandalus spp.", ook indien ontdaan van de schaal, levend, vers of gekoeld',
    '03063610': 'garnalen van de familie "Pandalidae", ook indien ontdaan van de schaal, levend, vers of gekoeld (m.u.v. die van het geslacht "Pandalus")',
    '03063650': 'garnalen van het geslacht "Crangon", ook indien ontdaan van de schaal, levend, vers of gekoeld (m.u.v. die van de soort Crangon crangon)',
    '03063690': 'Garnalen, ook indien ontdaan van de schaal, levend, vers of gekoeld (m.u.v. garnalen van de familie "Pandalidae" en van het geslacht "Crangon")',
    '03063910': 'Rivierkreeften, ook indien ontdaan van de schaal, levend, vers of gekoeld',
    '03063990': 'Schaaldieren, daaronder begrepen meel, poeder en pellets van schaaldieren, geschikt voor menselijke consumptie, ook indien ontdaan van de schaal (m.u.v. langoesten, zeekreeften, krabben, langoustines "Nephrops norvegicus", koudwatergarnalen en rivierkreeften), levend, vers of gekoeld',
    '03069100': 'Langoesten "Palinurus spp., Panulirus spp., Jasus spp.", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. die in de schaal gestoomd of in water gekookt',
    '03069210': 'zeekreeften "Homarus spp.", in gehele staat, gerookt, gedroogd, gezouten of gepekeld, incl. zeekreeften die eerst zijn gestoomd of in water gekookt',
    '03069290': 'delen van zeekreeften "Homarus spp.", gedroogd, gerookt, gezouten of gepekeld incl. delen van zeekreeften in de schaal, gestoomd of in water gekookt',
    '03069310': 'Noordzeekrabben "Cancer pagurus", ook indien ontdaan van de schaal, gerookt,  gedroogd, gezouten of gepekeld, incl. Noordzeekrabben in de schaal gestoomd of in water gekookt',
    '03069390': 'krabben, ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. krabben in de schaal gestoomd of in water gekookt (m.u.v. Noordzeekrabben "Cancer pagurus)',
    '03069400': 'langoestines "Nephrops norvegicus", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. langoestines in de schaal  gestoomd of in water gekookt',
    '03069511': 'garnalen van het geslacht "Crangon crangon", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld,  gestoomd of in water gekookt',
    '03069519': 'garnalen van het geslacht "Crangon crangon", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld (m.u.v. gestoomd of in water gekookt)',
    '03069520': 'koudwatergarnalen van het geslacht "Pandalus spp.", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. garnalen in de schaal die eerst zijn gestoomd of in water gekookt',
    '03069530': 'garnalen van de familie "Pandalidae", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. garnalen in de schaal die eerst zijn gestoomd of in water gekookt (m.u.v. die van het geslacht "Pandalus spp.")',
    '03069540': 'garnalen van het geslacht "Crangon", uitgezonderd die van de soort "Crangon crangon", ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. garnalen in de schaal die eerst zijn gestoomd of in water gekookt,',
    '03069590': 'garnalen, ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. garnalen in de schaal die eerst zijn gestoomd of in water gekookt (m.u.v. garnalen van de familie "Pandalidae" of het geslacht "Crangon")',
    '03069910': 'rivierkreeften, ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. rivierkreeften in de schaal, gestoomd of in water gekookt',
    '03069990': 'schaaldieren, geschikt voor menselijke consumptie, ook indien ontdaan van de schaal, gerookt, gedroogd, gezouten of gepekeld, incl. schaaldieren in de schaal, gestoomd of in water gekookt (m.u.v. langoesten, zeekreeften, garnalen, krabben, rivierkreeften en langoestines); meel, poeder en pellets, van schaaldieren, geschikt voor menselijke consumptie, gerookt, gedroogd, gezouten of gepekeld',
    '03071110': 'levende platte oesters "Ostrea spp.", wegende, in de schelp, <= 40 g per stuk',
    '03071190': 'oesters, ook indien ontdaan van de schelp, levend, vers of gekoeld, (m.u.v. levende platte oesters "Ostrea spp.", wegende, in de schelp, <= 40 g per stuk)',
    '03071200': 'Oesters, ook indien ontdaan van de schelp, bevroren',
    '03071900': 'oesters, ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03072100': 'jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03072210': 'jakobsschelpen "Pecten maximus", ook indien ontdaan van de schelp, bevroren',
    '03072290': 'jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten", ook indien ontdaan van de schelp, bevroren (m.u.v.  jakobsschelpen "Pecten maximus")',
    '03072900': 'jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03073110': 'mosselen van het geslacht "Mytilus", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03073190': 'mosselen van het geslacht "Perna", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03073210': 'mosselen van het geslacht "Mytilus", ook indien ontdaan van de schelp, bevroren,',
    '03073290': 'mosselen van het geslacht "Perna", ook indien ontdaan van de schelp, bevroren,',
    '03073920': 'mosselen van het geslacht "Mytilus", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03073980': 'mosselen van het geslacht "Perna", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03074210': 'inktvissen "Sepia officinalis, Rossia macrosoma, Sepiola spp.", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03074220': 'pijlinktvissen van het geslacht "Loligo",  levend, vers of gekoeld',
    '03074230': 'pijlinktvissen "Ommastrephes spp., Nototodarus spp., Sepioteuthis spp.", levend, vers of gekoeld',
    '03074240': 'grote pijlinktvis "Todarodes sagittatus", levend, vers of gekoeld',
    '03074290': 'weekdieren,ook indien ontdaan van de schelp, levend, vers of gekoeld, (m.u.v.  inktvissen "Sepia officinalis, Rossia macrosoma, Sepiola spp.", pijlinktvissen "Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp."; "Todarodes sagittatus")',
    '03074321': 'kleine zeekat "Sepiola rondeleti", ook indien ontdaan van de schelp,  bevroren',
    '03074325': 'inktvissen van het geslacht "Sepiola", ook indien ontdaan van de schelp, bevroren (m.u.v. die van de soort "Sepiola rondeleti")',
    '03074329': 'inktvissen "Sepia officinalis, Rossia macrosoma", ook indien ontdaan van de schelp, bevroren',
    '03074331': 'pijlinktvissen van de soort "Loligo vulgaris", bevroren',
    '03074333': 'pijlinktvissen van de soort "Loligo pealei", bevroren',
    '03074335': 'pijlinktvissen van de soort "Loligo gahi",bevroren',
    '03074338': 'pijlinktvissen van het geslacht "Loligo", bevroren (m.u.v. die van de soorten "Loligo vulgaris, Loligo pealei en Loligo gahi")',
    '03074391': 'pijlinktvissen "Ommastrephes spp., Nototodarus spp. en Sepioteuthis spp.", bevroren (m.u.v. Ommastrephes sagittatus")',
    '03074392': 'weekdieren van het geslacht "Illex", bevroren',
    '03074395': 'Grote pijlinktvis van het geslacht "Todarodes sagittatus, Ommastrephes sagittatus", bevroren',
    '03074399': 'inktvissen en pijlinktvissen, ook indien ontdaan van de schelp, bevroren, (m.u.v.  inktvissen "Sepia officinalis, Rossia macrosoma, Sepiola spp.", pijlinktvissen "Ommastrephes spp., Loligo spp., Nototodarus spp., Sepioteuthis spp.", Illex spp., "Todarodes sagittatus")',
    '03074920': 'inktvissen "Sepia officinalis, Rossia macrosoma, Sepiola spp.", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03074940': 'pijlinktvissen van het geslacht "Loligo", gerookt,  gedroogd, gezouten of gepekeld',
    '03074950': 'pijlinktvissen "Ommastrephes spp., Nototodarus spp. en Sepioteuthis spp.", gerookt, gedroogd, gezouten of gepekeld (m.u.v. "Ommastrephes sagittatus")',
    '03074960': 'grote pijlinktvis van het geslacht "Todarodes sagittatus", gerookt, gedroogd, gezouten of gepekeld',
    '03074980': 'Inktvissen en pijlinktvissen, ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld (m.u.v. "Sepia officinialis, Rossia macrosoma, Sepiola spp., Loligo spp., Ommastrephes spp., Nototodarus spp., Sepioteuthis spp., Todarodes sagittatus")',
    '03075100': 'achtarmige inktvissen "Octopus spp.",  levend, vers of gekoeld',
    '03075200': 'achtarmige inktvissen "Octopus spp.", bevroren',
    '03075900': 'achtarmige inktvissen "Octopus spp.", gerookt,  gedroogd, gezouten of gepekeld',
    '03076000': 'eetbare slakken, ook indien ontdaan van de schelp, levend, vers, gekoeld, bevroren, gerookt, gedroogd, gezouten of gepekeld, (m.u.v. zeeslakken)',
    '03077100': 'Tweekleppigen, kokkels en arkschelpen "Arcidae, Arcticidae, Cardiidae, Donacidae, Hiatellidae, Mactridae, Mesodesmatidae, Myidae, Semelidae, Solecurtidae, Solenidae, Tridacnidae,Veneridae", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03077210': 'Tapijtschelp of andere soorten van de familie "Veneridae", ook indien ontdaan van de schelp, bevroren',
    '03077290': 'Tweekleppigen, kokkels en arkschelpen "Arcidae, Arcticidae, Cardiidae, Donacidae, Hiatellidae, Mactridae, Mesodesmatidae, Myidae, Semelidae, Solecurtidae, Solenidae, Tridacnidae, ook indien ontdaan van de schelp, bevroren',
    '03077900': 'Tweekleppigen, kokkels en arkschelpen "Arcidae, Arcticidae, Cardiidae, Donacidae, Hiatellidae, Mactridae, Mesodesmatidae, Myidae, Semelidae, Solecurtidae, Solenidae, Tridacnidae en Veneridae" ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03078100': 'Zeeoor "Haliotis spp.", levend, vers of gekoeld',
    '03078200': 'Strombushoorns "Strombus spp.", ook indien ontdaan van de schelp, levend, vers of gekoeld',
    '03078300': 'Zeeoren "Haliotis spp.", ook indien ontdaan van de schelp, bevroren',
    '03078400': 'Strombushoorns "Strombus spp.", ook indien ontdaan van de schelp, bevroren',
    '03078700': 'Zeeoren "Haliotis spp.", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03078800': 'Strombushoorns "Strombus spp.", ook indien ontdaan van de schelp, gerookt, gedroogd, gezouten of gepekeld',
    '03079100': 'weekdieren,ook indien ontdaan van de schelp,  meel, poeder en pellets daaronder begrepen, geschikt voor menselijke consumptie,  levend, vers of gekoeld, (m.u.v. oesters; jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten"; mosselen "Mytilus spp., Perna spp."; inktvissen en pijlinktvissen; achtarmige inktvissen "Octopus spp."; eetbare slakken, andere dan zeeslakken; Tweekleppigen, kokkels en arkschelpen,  Zeeoor  en Strombushoorns)',
    '03079200': 'weekdieren,ook indien ontdaan van de schelp,  meel, poeder en pellets daaronder begrepen, geschikt voor menselijke consumptie,  bevroren, (m.u.v. oesters; jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten"; mosselen "Mytilus spp., Perna spp."; inktvissen en pijlinktvissen; achtarmige inktvissen "Octopus spp."; eetbare slakken, andere dan zeeslakken; Tweekleppigen, kokkels en arkschelpen,  Zeeoor  en Strombushoorns)',
    '03079900': 'weekdieren,ook indien ontdaan van de schelp,  meel, poeder en pellets daaronder begrepen, geschikt voor menselijke consumptie,  gerookt, gedroogd, gezouten of gepekeld, (m.u.v. oesters; jakobsschelpen en andere schelpdieren van de geslachten "Pecten", "Chlamys" of "Placopecten"; mosselen "Mytilus spp., Perna spp."; inktvissen en pijlinktvissen; achtarmige inktvissen "Octopus spp."; eetbare slakken, andere dan zeeslakken; Tweekleppigen, kokkels en arkschelpen,  Zeeoor  en Strombushoorns)',
    '03081100': 'Zeekomkommer "Stichopus japonicus, Holothurioidea", levend, vers of gekoeld',
    '03081200': 'Zeekomkommer "Stichopus japonicus, Holothurioidea", bevroren',
    '03081900': 'Zeekomkommer "Stichopus japonicus, Holothurioidea", gerookt, gedroogd, gezouten of gepekeld',
    '03082100': 'Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus", levend, vers of gekoeld',
    '03082200': 'Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus", bevroren',
    '03082900': 'Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus", gerookt, gedroogd, gezouten of gepekeld',
    '03083050': 'kwallen "Rhopilema spp.", bevroren (m.u.v. gerookt)',
    '03083080': 'Kwallen "Rhopilema spp.", levend, vers, gekoeld, gerookt, gedroogd, gezouten, gepekeld',
    '03089010': 'Ongewervelde waterdieren, andere dan schaal- en weekdieren, levend, vers of gekoeld, daaronder begrepen meel, poeder en pellets,van ongewervelde waterdieren, andere dan schaal- en weekdieren, geschikt voor menselijke consumptie (m.u.v. Zeekomkommer "Stichopus japonicus, Holothurioidea", Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus" en kwallen \'Rhopilema spp.")',
    '03089050': 'Ongewervelde waterdieren, andere dan schaal- en weekdieren, bevroren, daaronder begrepen meel, poeder en pellets, van ongewervelde waterdieren (andere dan van schaal- en weekdieren), geschikt voor menselijke consumptie (m.u.v. Zeekomkommer "Stichopus japonicus, Holothurioidea", Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus" en kwallen \'Rhopilema spp.")',
    '03089090': 'Ongewervelde waterdieren, andere dan schaal- en weekdieren, gerookt, gedroogd, gezouten of gepekeld, daaronder begrepen meel, poeder en pellets, van ongewervelde waterdieren (andere dan van schaal- en weekdieren), geschikt voor menselijke consumptie (m.u.v. Zeekomkommer "Stichopus japonicus, Holothurioidea", Zee-egel "Strongylocentrotus spp., Paracentrotus lividus, Loxechinus ablus, Echichinus esculentus" en kwallen \'Rhopilema spp.")',
    '04011010': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 1 gewichtspercent, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04011090': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 1 gewichtspercent (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04012011': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 3 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04012019': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 3 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04012091': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 3 <= 6 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04012099': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 3 <= 6 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2 l',
    '04014010': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 10, doch > 6 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04014090': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 10, doch > 6 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04015011': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 21, doch > 10 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04015019': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 21, doch > 10 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04015031': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 21 doch <= 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04015039': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 21 doch <= 45 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04015091': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l',
    '04015099': 'melk en room (niet ingedikt, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 45 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 l)',
    '04021011': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 1,5 gewichtspercent (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg',
    '04021019': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 1,5 gewichtspercent (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04021091': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 1,5 gewichtspercent, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg',
    '04021099': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 1,5 gewichtspercent, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04022111': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 1,5 gewichtspercent doch <= 27 gewichtspercenten (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg',
    '04022118': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 1,5  doch <= 27 gewichtspercenten (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04022191': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 27 gewichtspercenten (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg',
    '04022199': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 27 gewichtspercenten (zonder toegevoegde suiker of andere zoetstoffen), in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04022911': 'melk in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, voor zuigelingen, luchtdicht verpakt in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 500 g, met een vetgehalte van > 10 <= 27 gewichtspercenten',
    '04022915': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 27 gewichtspercenten, doch > 1,5 gewichtspercent, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg (m.u.v. melk voor zuigelingen, luchtdicht verpakt in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 500 g)',
    '04022919': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 27 gewichtspercenten, doch > 1,5 gewichtspercent, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04022991': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 27 gewichtspercenten, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg',
    '04022999': 'melk en room, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 27 gewichtspercenten, met toegevoegde suiker of andere zoetstoffen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg',
    '04029110': 'Melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van  <= 8 gewichtspercenten (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029130': 'Melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 8 doch <= 10 gewichtspercenten (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029151': 'melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 10 <= 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029159': 'melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 10 <= 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029191': 'melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029199': 'melk en room, ingedikt (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029910': 'Melk en room, ingedikt, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 9,5 gewichtspercenten (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029931': 'melk en room, ingedikt, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 9,5 <= 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029939': 'melk en room, ingedikt, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 9,5 <= 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029991': 'melk en room, ingedikt, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04029999': 'melk en room, ingedikt, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 45 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2,5 kg (m.u.v. melk en room in poeder, in korrels of in andere vaste vorm)',
    '04031011': 'yoghurt, ongearomatiseerd, zonder toegevoegde vruchten of cacao en zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 3 gewichtspercenten',
    '04031013': 'yoghurt, ongearomatiseerd, zonder toegevoegde vruchten of cacao en zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 3 doch <= 6 gewichtspercenten',
    '04031019': 'yoghurt, ongearomatiseerd, zonder toegevoegde vruchten of cacao en zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 6 gewichtspercenten',
    '04031031': 'yoghurt, ongearomatiseerd en zonder toegevoegde vruchten of cacao, maar met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 3 gewichtspercenten',
    '04031033': 'yoghurt, ongearomatiseerd en zonder toegevoegde vruchten of cacao, maar met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 3 doch <= 6 gewichtspercenten',
    '04031039': 'yoghurt, ongearomatiseerd en zonder toegevoegde vruchten of cacao, maar met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 6 gewichtspercenten',
    '04031051': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een gehalte aan van melk afkomstige vetstoffen van >= 1,5 gewichtspercent',
    '04031053': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een gehalte aan van melk afkomstige vetstoffen van > 1,5 <= 27 gewichtspercenten',
    '04031059': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een gehalte aan van melk afkomstige vetstoffen van > 27 gewichtspercenten',
    '04031091': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een gehalte aan van melk afkomstige vetstoffen van <= 3 gewichtspercenten (m.u.v. yoghurt in poeder, in korrels of in andere vaste vorm)',
    '04031093': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een gehalte aan van melk afkomstige vetstoffen van > 3 <= 6 gewichtspercenten (m.u.v. yoghurt in poeder, in korrels of in andere vaste vorm)',
    '04031099': 'yoghurt, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een gehalte aan van melk afkomstige vetstoffen van > 6 gewichtspercenten (m.u.v. yoghurt in poeder, in korrels of in andere vaste vorm)',
    '04039011': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. yoghurt)',
    '04039013': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 1,5 <= 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039019': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm (zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039031': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. yoghurt)',
    '04039033': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 1,5 <= 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039039': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room (ongearomatiseerd, zonder toegevoegde vruchten of cacao), in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039051': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van <= 3 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039053': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 3 <= 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039059': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao, zonder toegevoegde suiker of andere zoetstoffen), met een vetgehalte van > 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039061': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao), met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 3 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039063': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao), met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 3 >= 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039069': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt (ongearomatiseerd, zonder toegevoegde vruchten of cacao), met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039071': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. yoghurt)',
    '04039073': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 1,5 <= 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039079': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, in poeder, in korrels of in andere vaste vorm, met een vetgehalte van > 27 gewichtspercenten (m.u.v. yoghurt)',
    '04039091': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 3 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039093': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 3 <= 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04039099': 'karnemelk, gestremde melk en room, kefir en andere gegiste of aangezuurde melk en room, ook indien ingedikt, gearomatiseerd of met toegevoegde vruchten of cacao, ook indien met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 6 gewichtspercenten (m.u.v. in poeder, in korrels of in andere vaste vorm, m.u.v. yoghurt)',
    '04041002': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent',
    '04041004': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten',
    '04041006': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten',
    '04041012': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent',
    '04041014': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten',
    '04041016': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten',
    '04041026': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent',
    '04041028': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten',
    '04041032': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten',
    '04041034': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent',
    '04041036': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten',
    '04041038': 'wei en gewijzigde wei, in poeder, in korrels of in andere vaste vorm, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten',
    '04041048': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041052': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041054': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041056': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041058': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041062': 'wei en gewijzigde wei, ook indien ingedikt, zonder toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041072': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041074': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041076': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van <= 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041078': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van <= 1,5 gewichtspercent (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041082': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04041084': 'wei en gewijzigde wei, ook indien ingedikt, met toegevoegde suiker of andere zoetstoffen, met een gehalte aan proteïne "stikstofgehalte x 6,38" van > 15 gewichtspercenten en met een vetgehalte van > 27 gewichtspercenten (m.u.v. die in poeder, in korrels of in andere vaste vorm)',
    '04049021': 'producten bestaande uit natuurlijke bestanddelen van melk, zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 1,5 gewichtspercenten, n.e.g.',
    '04049023': 'producten bestaande uit natuurlijke bestanddelen van melk, zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten, n.e.g.',
    '04049029': 'producten bestaande uit natuurlijke bestanddelen van melk, zonder toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 27 gewichtspercenten, n.e.g.',
    '04049081': 'producten bestaande uit natuurlijke bestanddelen van melk, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van <= 1,5 gewichtspercent, n.e.g.',
    '04049083': 'producten bestaande uit natuurlijke bestanddelen van melk, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 1,5 doch <= 27 gewichtspercenten, n.e.g.',
    '04049089': 'producten bestaande uit natuurlijke bestanddelen van melk, met toegevoegde suiker of andere zoetstoffen, met een vetgehalte van > 27 gewichtspercenten, n.e.g.',
    '04051011': 'natuurlijke boter met een vetgehalte van >= 80 doch <= 85 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. gedehydrateerde boter en ghee)',
    '04051019': 'natuurlijke boter met een vetgehalte van >= 80 doch <= 85 gewichtspercenten (m.u.v. die in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg; gedehydrateerde boter en ghee)',
    '04051030': 'gerecombineerde boter met een vetgehalte van >= 80 doch <= 85 gewichtspercenten (m.u.v. gedehydrateerde boter en ghee)',
    '04051050': 'weiboter met een vetgehalte van >= 80 doch <= 85 gewichtspercenten (m.u.v. gedehydrateerde boter en ghee)',
    '04051090': 'boter met een vetgehalte van > 85 doch <= 95 gewichtspercenten (m.u.v. gedehydrateerde boter en ghee)',
    '04052010': 'zuivelpasta\'s met een vetgehalte van >= 39 doch < 60 gewichtspercenten',
    '04052030': 'zuivelpasta\'s met een vetgehalte van >= 60 doch <= 75 gewichtspercenten',
    '04052090': 'zuivelpasta\'s met een vetgehalte van > 75 doch < 80 gewichtspercenten',
    '04059010': 'van melk afkomstige vetstoffen met een vetgehalte van >= 99,3 gewichtspercenten en een vochtgehalte van <= 0,5 gewichtspercent',
    '04059090': 'van melk afkomstige vetstoffen, alsmede gedehydrateerde boter en ghee (m.u.v. die met een vetgehalte van >= 99,3 gewichtspercenten en een vochtgehalte van <= 0,5 gewichtspercent en m.u.v. natuurlijke en gerecombineerde boter en weiboter)',
    '04061030': 'verse Mozzarella, ook indien in een vloeistof, met een vetgehalte van <= 40 gewichtspercenten',
    '04061050': 'verse kaas, ongerijpt, incl. weikaas, en wrongel, met een vetgehalte van <= 40 gewichtspercenten (m.u.v. Mozzarella)',
    '04061080': 'verse kaas, ongerijpt, incl. weikaas, en wrongel, met een vetgehalte van > 40 gewichtspercenten',
    '04062000': 'kaas van alle soorten, geraspt of in poeder',
    '04063010': 'smeltkaas, ongeraspt noch in poeder, waarin geen andere kaassoorten zijn verwerkt dan Emmentaler, Gruyère en Appenzell, evtl. met toevoeging van Glaris kruidkaas "zgn. Schabziger", opgemaakt voor de verkoop in het klein, met een vetgehalte, berekend op de droge stof, van <= 56 gewichtspercenten',
    '04063031': 'smeltkaas, ongeraspt noch in poeder, met een vetgehalte van <= 36 gewichtspercenten en met een vetgehalte, berekend op de droge stof, van <= 48 gewichtspercenten (m.u.v. smeltkaas van uitsluitend Emmentaler, Gruyère en Appenzell, ook met toevoeging van Glaris kruidkaas "zgn. Schabziger", opgemaakt voor de verkoop in het klein)',
    '04063039': 'smeltkaas, ongeraspt noch in poeder, met een vetgehalte van <= 36 gewichtspercenten en met een vetgehalte, berekend op de droge stof, van > 48 gewichtspercenten (m.u.v. smeltkaas van uitsluitend Emmentaler, Gruyère en Appenzell, ook met toevoeging van Glaris kruidkaas "zgn. Schabziger", opgemaakt voor de verkoop in het klein, met een vetgehalte, berekend op de droge stof, van <= 56 gewichtspercenten)',
    '04063090': 'smeltkaas, ongeraspt noch in poeder, met een vetgehalte van > 36 gewichtspercenten (m.u.v. smeltkaas van uitsluitend Emmentaler, Gruyère en Appenzell, ook met toevoeging van Glaris kruidkaas "zgn. Schabziger", opgemaakt voor de verkoop in het klein, met een vetgehalte, berekend op de droge stof, van <= 56 gewichtspercenten)',
    '04064010': 'Roquefort',
    '04064050': 'Gorgonzola',
    '04064090': 'Blauw-groen geaderde kaas en andere kaas die aders bevat die zijn verkregen door gebruik te maken van "Penicillium roqueforti" (m.u.v. Roquefort en Gorgonzola)',
    '04069001': 'Kaas bestemd voor verwerking (m.u.v. verse kaas, ongerijpt, weikaas daaronder begrepen, wrongel, smeltkaas, blauw-groen geaderde kaas en andere kaas die aders bevat die zijn verkregen door gebruik te maken van "Penicillium roqueforti", alsmede kaas, geraspt of in poeder)',
    '04069013': 'Emmentaler (m.u.v. geraspte of in poeder; en deze bestemd voor verwerking)',
    '04069015': 'Gruyère, Sbrinz (m.u.v. geraspte of in poeder en deze bestemd voor verwerking)',
    '04069017': 'Bergkäse, Appenzell (m.u.v. geraspte of in poeder en deze bestemd voor verwerking)',
    '04069018': 'Fromage Fribourgeois, Vacherin Mont d\'Or en Tête de Moine (m.u.v. kaas, geraspt of in poeder en kaas bestemd voor verwerking)',
    '04069021': 'Cheddar (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069023': 'Edam (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069025': 'Tilsit (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069029': 'Kashkaval (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069032': 'Feta (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069035': 'Kefalotyri (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069037': 'Finlandia (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069039': 'Jarlsberg (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069050': 'schapenkaas en kaas, bereid uit buffelmelk, in bergingsmiddelen die pekel bevatten, of in zakken van schapen- of geitenvellen (m.u.v. Feta)',
    '04069061': 'Grana Padano, Parmigiano Reggiano, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van <= 47 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069063': 'Fiore Sardo en Pecorino, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van <= 47 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069069': 'kaas met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van <= 47 gewichtspercenten, n.e.g.',
    '04069073': 'Provolone, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 <= 72 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069074': 'Maasdam met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa van > 47 doch <= 72 gewichtspercenten (m.u.v. geraspt of in poeder, en kaas bestemd voor verwerking)',
    '04069075': 'Asiago, Caciocavallo, Montasio en Ragusano, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 <= 72 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069076': 'Danbo, Fontal, Fontina, Fynbo, Havarti, Maribo en Samsø, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 doch <= 72 gewichtspercenten (m.u.v. kaas, geraspt of in poeder, en kaas bestemd voor verwerking)',
    '04069078': 'Gouda, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 doch <= 72 gewichtspercenten (m.u.v. kaas, geraspt of in poeder, en kaas bestemd voor verwerking)',
    '04069079': 'Esrom, Italico, Kernhem, Saint-Nectaire, Saint-Paulin en Taleggio, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 <= 72 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069081': 'Cantal, Cheshire, Wensleydale, Lancashire, Double Gloucester, Blarney, Colby en Monterey, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 <= 72 gewichtspercenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069082': 'Camembert, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 doch <= 72 gewichtspercenten (m.u.v. kaas, geraspt of in poeder, en kaas bestemd voor verwerking)',
    '04069084': 'Brie, met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 47 doch <= 72 gewichtspercenten (m.u.v. kaas, geraspt of in poeder, en kaas bestemd voor verwerking)',
    '04069085': 'Kefalograviera en Kasseri  met een vetgehalte van niet meer dan 40 gewichtspercenten en een vochtgehalte berekend op de vetvrije kaasmassa van meer dan 47 gewichtsprocenten maar niet meer dan 72 gewichtsprocenten (m.u.v. geraspt of in poeder, kaas bestemd voor verwerking)',
    '04069086': 'kaas met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa van > 47 doch <= 52 gewichtspercenten, n.e.g.',
    '04069089': 'kaas met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa van > 52 doch <= 62 gewichtspercenten, n.e.g.',
    '04069092': 'kaas met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 62 doch <= 72 gewichtspercenten, n.e.g.',
    '04069093': 'kaas met een vetgehalte van <= 40 gewichtspercenten en een vochtgehalte, berekend op de vetvrije kaasmassa, van > 72 gewichtspercenten, n.e.g.',
    '04069099': 'kaas met een vetgehalte van > 40 gewichtspercenten, n.e.g.',
    '04071100': 'Broedeieren van kippen',
    '04071911': 'Broedeieren van kalkoenen of van ganzen',
    '04071919': 'Broedeieren van pluimvee (m.u.v. van kalkoenen, van ganzen en van kippen)',
    '04071990': 'Broedeieren  (m.u.v. die van pluimvee)',
    '04072100': 'eieren van kippen van de soort "Gallus domesticus" in de schaal, vers,  (m.u.v. broedeieren)',
    '04072910': 'eieren van pluimvee, andere dan van kippen van de soort "Gallus domesticus" in de schaal, vers, (m.u.v. broedeieren)',
    '04072990': 'vogeleieren in de schaal, vers (m.u.v. die van pluimvee en broedeieren)',
    '04079010': 'eieren van pluimvee, in de schaal, verduurzaamd of gekookt',
    '04079090': 'vogeleieren in de schaal, verduurzaamd of gekookt (m.u.v. die van pluimvee)',
    '04081120': 'eigeel, gedroogd, ook indien met toegevoegde suiker of andere zoetstoffen, ongeschikt voor menselijke consumptie',
    '04081180': 'eigeel, gedroogd, ook indien met toegevoegde suiker of andere zoetstoffen, geschikt voor menselijke consumptie',
    '04081920': 'eigeel, vers, gestoomd of in water gekookt, in een bepaalde vorm gebracht, bevroren of op andere wijze verduurzaamd, ook indien met toegevoegde suiker of andere zoetstoffen, ongeschikt voor menselijke consumptie (m.u.v. gedroogd eigeel)',
    '04081981': 'eigeel, vloeibaar, ook indien met toegevoegde suiker of andere zoetstoffen, geschikt voor menselijke consumptie',
    '04081989': 'eigeel, niet-vloeibaar, incl. bevroren eiwit, ook indien met toegevoegde suiker of andere zoetstoffen, geschikt voor menselijke consumptie (m.u.v. gedroogd eigeel)',
    '04089120': 'vogeleieren uit de schaal, gedroogd, ook indien met toegevoegde suiker of andere zoetstoffen, ongeschikt voor menselijke consumptie (m.u.v. eigeel)',
    '04089180': 'vogeleieren uit de schaal, gedroogd, ook indien met toegevoegde suiker of andere zoetstoffen, geschikt voor menselijke consumptie (m.u.v. eigeel)',
    '04089920': 'vogeleieren uit de schaal, vers, gestoomd of in water gekookt, in een bepaalde vorm gebracht, bevroren of op andere wijze verduurzaamd, ook indien met toegevoegde suiker of andere zoetstoffen, ongeschikt voor menselijke consumptie (m.u.v. eigeel en gedroogde vogeleieren uit de schaal)',
    '04089980': 'vogeleieren uit de schaal, vers, gestoomd of in water gekookt, in een bepaalde vorm gebracht, bevroren of op andere wijze verduurzaamd, ook indien met toegevoegde suiker of andere zoetstoffen, geschikt voor menselijke consumptie (m.u.v. eigeel en gedroogd e vogeleieren uit de schaal)',
    '04090000': 'natuurhonig',
    '04100000': 'poteieren, nesten van salanganen en andere eetbare producten van dierlijke oorsprong, n.e.g.',
    '05010000': 'mensenhaar, onbewerkt, ook indien gewassen of ontvet, incl. afval van mensenhaar',
    '05021000': 'haar van varkens of van wilde zwijnen en afval van dit haar',
    '05029000': 'dassenhaar en ander dierlijk haar, voor borstelwerk, incl. afval van dit haar',
    '05040000': 'darmen, blazen en magen van dieren, in hun geheel of in stukken, vers, gekoeld, bevroren, gezouten, gepekeld, gedroogd of gerookt (m.u.v. die van vissen)',
    '05051010': 'veren van de soorten die als opvulmateriaal worden gebruikt en dons, ruw, ook indien stofvrij gemaakt, gedesinfecteerd of eenvoudig gereinigd',
    '05051090': 'veren van de soorten die als opvulmateriaal worden gebruikt en dons, grondig gereinigd en behandeld ter voorkoming van bederf',
    '05059000': 'vogelhuiden en andere delen van vogels, met veren of dons bezet, alsmede veren en delen van veren, ook indien bijgesneden, ruw, gereinigd, ontsmet of op andere wijze behandeld ter voorkoming van bederf, doch verder onbewerkt, alsmede poeder en afval, van veren of van delen van veren (m.u.v. veren van de soorten die als opvulmateriaal worden gebruikt en m.u.v. dons)',
    '05061000': 'osseïne en met zuur behandelde beenderen',
    '05069000': 'beenderen en hoornpitten, ruw, ontvet, ontdaan van gelatine of eenvoudig voorbehandeld, alsmede poeder en afval van deze stoffen (m.u.v. osseïne en met zuur behandelde of in vorm gesneden beenderen)',
    '05071000': 'ivoor, ruw of eenvoudig voorbehandeld, alsmede poeder en afval van ivoor (m.u.v. in vorm gesneden ivoor)',
    '05079000': 'schildpad, walvisbaarden, walvisbaardhaar, horens, geweien, hoeven, nagels, klauwen en snavels, ruw of eenvoudig voorbehandeld, alsmede poeder en afval van deze stoffen (m.u.v. dergelijke waren, in vorm gesneden, en m.u.v. ivoor)',
    '05080000': 'koraal e.d. stoffen, schelpen en schalen, van schaaldieren, van weekdieren of van stekelhuidigen, en rugplaten van inktvissen, ruw of eenvoudig voorbehandeld, alsmede poeder en afval van deze stoffen (m.u.v. dergelijke goederen die verder zijn bewerkt of in vorm gesneden)',
    '05100000': 'grijze amber, bevergeil, civet en muskus; Spaanse vlieg; gal, ook indien gedroogd; klieren en andere stoffen van dierlijke oorsprong, die worden gebruikt voor het bereiden van farmaceutische producten, vers, gekoeld, bevroren of anderszins voorlopig geconserveerd',
    '05111000': 'rundersperma',
    '05119110': 'visafvallen',
    '05119190': 'producten van vis, van schaaldieren, van weekdieren of van andere ongewervelde waterdieren, n.e.g.; dode vis, dode schaaldieren, dode weekdieren en andere dode ongewervelde waterdieren, niet geschikt voor menselijke consumptie (m.u.v. visafvallen)',
    '05119910': 'pezen en zenen, alsmede snippers e.d. afval van ongelooide huiden of vellen',
    '05119931': 'Echte sponsen van dierlijke oorsprong, ruw',
    '05119939': 'echte sponsen, bewerkt (m.u.v. ruwe)',
    '05119985': 'producten van dierlijke oorsprong, n.e.g.; dode dieren, niet geschikt voor menselijke consumptie (m.u.v. vis, schaaldieren, weekdieren en andere ongewervelde waterdieren)',
    '06011010': 'hyacintebollen, in rusttoestand',
    '06011020': 'narcissenbollen, in rusttoestand',
    '06011030': 'tulpenbollen, in rusttoestand',
    '06011040': 'gladiolebollen, in rusttoestand',
    '06011090': 'bollen, knollen en wortelstokken, in rusttoestand (m.u.v. die welke gebruikt worden voor menselijke consumptie en m.u.v. hyacinte-, narcisse-, tulpe- en gladiolebollen en cichoreiplanten en -wortels)',
    '06012010': 'cichoreiplanten en -wortels (m.u.v. cichoreiwortels van de variëteit "Cichorium intybus sativum")',
    '06012030': 'orchideeën, hyacinten, narcissen en tulpen, in blad of in bloei',
    '06012090': 'bollen, knollen en wortelstokken, in blad of in bloei (m.u.v. die welke gebruikt worden voor menselijke consumptie en m.u.v. orchideeën, hyacinten, narcissen, tulpen en cichoreiplanten en -wortels)',
    '06021010': 'stekken zonder wortels en enten, van de wijnstok',
    '06021090': 'stekken zonder wortels en enten (m.u.v. die van de wijnstok)',
    '06022010': 'plantgoed van wijnstokken, geënt of met wortels',
    '06022020': 'bomen en heesters, met blote wortel, voor de teelt van eetbare vruchten, ook indien geënt (m.u.v. plantgoed van wijnstokken)',
    '06022030': 'citrusplanten, ook indien geënt (m.u.v. die met blote wortel)',
    '06022080': 'bomen en heesters, voor de teelt van eetbare vruchten, ook indien geënt (m.u.v. plantgoed van wijnstokken, citrusplanten en die met blote wortel)',
    '06023000': 'rododendrons en azalea\'s, ook indien geënt',
    '06024000': 'Rozen, ook indien veredeld',
    '06029010': 'champignonbroed',
    '06029020': 'ananasplantjes',
    '06029030': 'groenteplanten en aardbeiplanten',
    '06029041': 'woudbomen en woudheesters "zgn. bosplantsoen"',
    '06029045': 'bewortelde stekken en jonge planten, van bomen en heesters (m.u.v. die van bomen en heesters voor de teelt van eetbare vruchten of van woudbomen en woudheesters)',
    '06029046': 'bomen en heesters, incl. levende wortels daarvan, met blote wortel (m.u.v. stekken, enten en jonge planten en m.u.v. bomen en heesters voor de teelt van eetbare vruchten en woudbomen en woudheesters)',
    '06029047': 'coniferen en groenblijvende bomen en heesters, incl. levende wortels daarvan (m.u.v. stekken, enten en jonge planten en m.u.v. bomen en heesters voor de teelt van eetbare vruchten en woudbomen en woudheesters en m.u.v. die met blote wortel)',
    '06029048': 'bomen en heesters, incl. levende wortels daarvan (m.u.v. stekken, enten en jonge planten en m.u.v. bomen en heesters voor de teelt van eetbare vruchten en woudbomen en woudheesters en m.u.v. die met blote wortel)',
    '06029050': 'Levende planten voor de open grond, incl. levende wortels daarvan (m.u.v. bollen, knollen, wortelstokken, incl. cichoreiplanten en -wortels; stekken zonder wortels en enten; rododendrons, azalea\'s; rozen; champignonbroed; ananasplantjes; groente- en aardbeiplanten; bomen, heesters)',
    '06029070': 'bewortelde stekken, zaailingen en plantgoed, van kamerplanten (m.u.v. die van cactussen)',
    '06029091': 'bloeiende kamerplanten, in knop of bloem (m.u.v. cactussen)',
    '06029099': 'levende kamerplanten (m.u.v. stekken, zaailingen en plantgoed en m.u.v. bloeiende kamerplanten, in knop of bloem)',
    '06031100': 'rozen "afgesneden bloemen, bloesems en bloemknoppen", voor bloemstukken of voor versiering, vers',
    '06031200': 'anjers "afgesneden bloemen, bloesems en bloemknoppen", voor bloemstukken of voor versiering, vers',
    '06031300': 'orchideeën "afgesneden bloemen, bloesems en bloemknoppen", voor bloemstukken of voor versiering, vers',
    '06031400': 'chrysanten "afgesneden bloemen, bloesems en bloemknoppen", voor bloemstukken of voor versiering, vers',
    '06031500': 'Lelies "Lilium spp." afgesneden bloemen, bloesems en bloemknoppen, voor bloemstukken of voor versiering, vers',
    '06031910': 'gladiolen "afgesneden bloemen, bloesems en bloemknoppen", voor bloemstukken of voor versiering, vers',
    '06031920': 'afgesneden ranonkels en ranonkelknoppen, voor bloemstukken of voor versiering, vers (m.u.v. rozen, anjers, orchideeën, gladiolen, ranonkels, chrysanten en lelies)',
    '06031970': 'afgesneden bloemen, bloesems en bloemknoppen, voor bloemstukken of voor versiering, vers (m.u.v. rozen, anjers, orchideeën, gladiolen, ranonkels, chrysanten en lelies)',
    '06039000': 'afgesneden bloemen, bloesems en bloemknoppen, voor bloemstukken of voor versiering, gedroogd, gebleekt, geverfd, geïmpregneerd of op andere wijze geprepareerd',
    '06042011': 'rendiermos, voor bloemstukken of voor versiering, vers',
    '06042019': 'mossen en korstmossen, voor bloemstukken of voor versiering, vers (m.u.v. rendiermos)',
    '06042020': 'Kerstbomen, vers',
    '06042040': 'Takken en twijgen van naaldbomen, voor bloemstukken of voor versiering, vers',
    '06042090': 'loof, bladeren, twijgen, takken en andere delen van planten, zonder bloemen, bloesems of bloemknoppen, alsmede grassen, voor bloemstukken of voor versiering, vers (m.u.v. kerstbomen en takken en twijgen van naaldbomen)',
    '06049011': 'rendiermos, voor bloemstukken of voor versiering, gedroogd, gebleekt, geverfd, geïmpregneerd of op andere wijze geprepareerd',
    '06049019': 'mossen en korstmossen, voor bloemstukken of voor versiering, gedroogd, gebleekt, geverfd, geïmpregneerd of op andere wijze geprepareerd (m.u.v. rendiermos)',
    '06049091': 'loof, bladeren, twijgen, takken en andere delen van planten, zonder bloemen, bloesems of bloemknoppen, alsmede grassen, voor bloemstukken of voor versiering, enkel gedroogd',
    '06049099': 'loof, bladeren, twijgen, takken en andere delen van planten, zonder bloemen, bloesems of bloemknoppen, alsmede grassen, voor bloemstukken of voor versiering, gebleekt, geverfd, geïmpregneerd of op andere wijze geprepareerd (m.u.v. enkel gedroogd)',
    '07011000': 'pootaardappelen',
    '07019010': 'aardappelen, vers of gekoeld, bestemd voor de vervaardiging van zetmeel',
    '07019050': 'nieuwe aardappelen "primeurs", vers of gekoeld, van 1 januari tot en met 30 juni',
    '07019090': 'aardappelen, vers of gekoeld (m.u.v. nieuwe aardappelen "primeurs" van 1 januari tot en met 30 juni, pootaardappelen en aardappelen bestemd voor de vervaardiging van zetmeel)',
    '07020000': 'tomaten, vers of gekoeld',
    '07031011': 'plantuitjes, vers of gekoeld',
    '07031019': 'uien, vers of gekoeld (m.u.v. plantuitjes)',
    '07031090': 'sjalotten, vers of gekoeld',
    '07032000': 'knoflook, vers of gekoeld',
    '07039000': 'prei en andere eetbare looksoorten, vers of gekoeld (m.u.v. uien, sjalotten en knoflook)',
    '07041000': 'bloemkool, vers of gekoeld',
    '07042000': 'spruitjes, vers of gekoeld',
    '07049010': 'witte kool en rode kool, vers of gekoeld',
    '07049090': 'koolrabi, boerenkool e.d. eetbare kool van het geslacht "Brassica", vers of gekoeld (m.u.v. bloemkool, spruitjes, witte kool en rode kool)',
    '07051100': 'kropsla, vers of gekoeld',
    '07051900': 'sla "Lactuca sativa", vers of gekoeld (m.u.v. kropsla)',
    '07052100': 'witloof "Cichorium intybus var. foliosum", vers of gekoeld',
    '07052900': 'andijvie en andere cichoreigroenten, vers of gekoeld (m.u.v. witloof "Cichorium intybus var. foliosum")',
    '07061000': 'wortelen en rapen, vers of gekoeld',
    '07069010': 'knolselderij, vers of gekoeld',
    '07069030': 'mierikswortel of peperwortel "Cochlearia armoracia", vers of gekoeld',
    '07069090': 'kroten, schorseneren, radijs e.d. eetbare wortelen en knollen, vers of gekoeld (m.u.v. wortelen, rapen, knolselderij en mierikswortel of peperwortel "Cochlearia armoracia")',
    '07070005': 'komkommers, vers of gekoeld',
    '07070090': 'augurken, vers of gekoeld',
    '07081000': 'erwten "Pisum sativum", peultjes daaronder begrepen, ook indien gedopt, vers of gekoeld',
    '07082000': 'bonen "Vigna spp., Phaseolus spp.", ook indien gedopt, vers of gekoeld',
    '07089000': 'peulgroenten, ook indien gedopt, vers of gekoeld (m.u.v. erwten "Pisum sativum", peultjes en bonen "Vigna spp., Phaseolus spp.")',
    '07092000': 'asperges, vers of gekoeld',
    '07093000': 'aubergines, vers of gekoeld',
    '07094000': 'selderij, vers of gekoeld (m.u.v. knolselderij)',
    '07095100': 'paddestoelen van het geslacht "Agaricus", vers of gekoeld',
    '07095910': 'cantharellen, vers of gekoeld',
    '07095930': 'eekhoorntjesbrood, vers of gekoeld',
    '07095950': 'truffels, vers of gekoeld',
    '07095990': 'eetbare paddestoelen, vers of gekoeld (m.u.v. truffels cantharellen, eekhoorntjesbrood en paddestoelen van het geslacht "Agaricus")',
    '07096010': 'pepers zonder scherpe smaak, vers of gekoeld',
    '07096091': 'vruchten van het geslacht "Capsicum", vers of gekoeld, bestemd voor de vervaardiging van capsaïcine of van tincturen',
    '07096095': 'vruchten van de geslachten "Capsicum" en "Pimenta", vers of gekoeld, bestemd voor de industriële vervaardiging van etherische oliën of van harsaroma\'s',
    '07096099': 'vruchten van de geslachten "Capsicum" en "Pimenta", vers of gekoeld (m.u.v. die bestemd voor de vervaardiging van capsaïcine, van tincturen, van etherische oliën of van harsaroma\'s, en m.u.v. niet-scherpsmakende pepers)',
    '07097000': 'spinazie, Nieuw-Zeelandse spinazie en tuinmelde, vers of gekoeld',
    '07099100': 'artisjokken, vers of gekoeld',
    '07099210': 'olijven, vers of gekoeld (m.u.v. die bestemd voor het vervaardigen van olie)',
    '07099290': 'olijven, vers of gekoeld, bestemd voor het vervaardigen van olie',
    '07099310': 'kleine pompoenen "zgn. courgettes", vers of gekoeld',
    '07099390': 'Pompoenen en kalebassen "Cucurbita spp.", vers of gekoeld (m.u.v. courgettes)',
    '07099910': 'sla, vers of gekoeld (m.u.v. "Lactuca sativa" en cichoreigroenten "Cichorium spp.")',
    '07099920': 'snijbiet en kardoen, vers of gekoeld',
    '07099940': 'kappers, vers of gekoeld',
    '07099950': 'venkel, vers of gekoeld',
    '07099960': 'suikermaïs, vers of gekoeld',
    '07099990': 'Groenten n.e.g., vers of gekoeld',
    '07101000': 'aardappelen, ook indien gestoomd of in water gekookt, bevroren',
    '07102100': 'erwten "Pisum sativum", peultjes daaronder begrepen, ook indien gedopt en ook indien gestoomd of in water gekookt, bevroren',
    '07102200': 'bonen "Vigna spp., Phaseolus spp.", ook indien gedopt en ook indien gestoomd of in water gekookt, bevroren',
    '07102900': 'peulgroenten, ook indien gedopt en ook indien gestoomd of in water gekookt, bevroren (m.u.v. erwten, "Pisum sativum", peultjes en bonen "Vigna spp., Phaseolus spp.")',
    '07103000': 'spinazie, Nieuw-Zeelandse spinazie en tuinmelde, ook indien gestoomd of in water gekookt, bevroren',
    '07104000': 'suikermaïs, ook indien gestoomd of in water gekookt, bevroren',
    '07108010': 'olijven, ook indien gestoomd of in water gekookt, bevroren',
    '07108051': 'pepers zonder scherpe smaak, ook indien gestoomd of in water gekookt, bevroren',
    '07108059': 'vruchten van de geslachten "Capsicum" en "Pimenta", ook indien gestoomd of in water gekookt, bevroren (m.u.v. niet-scherpsmakende pepers)',
    '07108061': 'paddestoelen van het geslacht "Agaricus", ook indien gestoomd of in water gekookt, bevroren',
    '07108069': 'paddestoelen, ook indien gestoomd of in water gekookt, bevroren (m.u.v. die van het geslacht "Agaricus")',
    '07108070': 'tomaten, ook indien gestoomd of in water gekookt, bevroren',
    '07108080': 'artisjokken, ook indien gestoomd of in water gekookt, bevroren',
    '07108085': 'asperges, ook indien gestoomd of in water gekookt, bevroren',
    '07108095': 'groenten, ook indien gestoomd of in water gekookt, bevroren (m.u.v. aardappelen, peulgroenten, spinazie, Nieuw-Zeelandse spinazie, tuinmelde, suikermaïs, olijven, vruchten van de geslachten "Capsicum" en "Pimenta", paddestoelen, tomaten, artisjokken en asperges)',
    '07109000': 'mengsels van groenten, ook indien gestoomd of in water gekookt, bevroren',
    '07112010': 'olijven, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie (m.u.v. olijven bestemd voor het vervaardigen van olie)',
    '07112090': 'olijven, bestemd voor het vervaardigen van olie, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07114000': 'komkommers en augurken, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07115100': 'paddestoelen van het geslacht "Agaricus", voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07115900': 'paddestoelen en truffels, voorlopig verduurzaamd, bv. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie (m.u.v. paddestoelen van het geslacht "Agaricus")',
    '07119010': 'vruchten van de geslachten "Capsicum" en "Pimenta", voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie (m.u.v. niet-scherpsmakende pepers)',
    '07119030': 'suikermaïs, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07119050': 'uien, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07119070': 'kappers, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07119080': 'groenten, voorlopig verduurzaamd, bv. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie (m.u.v. olijven, kappers, komkommers, augurken, paddestoelen, truffels, vruchten van de geslachten "Capsicum" en "Pimenta" andere dan niet-scherpsmakende pepers, suikermaïs, uien en mengsels van groenten)',
    '07119090': 'mengsels van groenten, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '07122000': 'gedroogde uien, ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07123100': 'gedroogde paddestoelen van het geslacht "Agaricus", ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07123200': 'gedroogde judasoren "Auricularia spp.", ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07123300': 'gedroogde trilzwammen "Tremella spp.", ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07123900': 'gedroogde paddestoelen en truffels, ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid (m.u.v. paddestoelen van het geslacht "Agaricus", judasoren "Auricularia spp." en trilzwammen "Tremella spp.")',
    '07129005': 'gedroogde aardappelen, ook indien in stukken of in schijven gesneden, doch zonder op andere wijze te zijn bereid',
    '07129011': 'gedroogde hybriden van suikermaïs "Zea mays var. saccharata", bestemd voor zaaidoeleinden',
    '07129019': 'gedroogde suikermaïs "Zea mays var. saccharata", ook indien in stukken of in schijven gesneden, doch zonder op andere wijze te zijn bereid (m.u.v. hybriden, bestemd voor zaaidoeleinden)',
    '07129030': 'gedroogde tomaten, ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07129050': 'gedroogde wortelen, ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid',
    '07129090': 'gedroogde groenten en mengsels van groenten, ook indien in stukken of in schijven gesneden, dan wel fijngemaakt of in poedervorm, doch zonder op andere wijze te zijn bereid (m.u.v. aardappelen, uien, paddestoelen, truffels, suikermaïs, tomaten en wortelen)',
    '07131010': 'gedroogde erwten "Pisum sativum", gedopt, voor zaaidoeleinden',
    '07131090': 'gedroogde erwten "Pisum sativum", gedopt, ook indien gepeld, b.v. spliterwten (m.u.v. die voor zaaidoeleinden)',
    '07132000': 'gedroogde kekers, gedopt, ook indien gepeld',
    '07133100': 'gedroogde bonen van de soort "Vigna mungo L. Hepper" of "Vigna radiata L. Wilczek", gedopt, ook indien gepeld',
    '07133200': 'gedroogde bonen van de soort "Phaseolus angularis" of "Vigna angularis" "adzuki-bonen", gedopt, ook indien gepeld',
    '07133310': 'gedroogde bonen van de soort "Phaseolus vulgaris", gedopt, voor zaaidoeleinden',
    '07133390': 'gedroogde bonen van de soort "Phaseolus vulgaris", gedopt, ook indien gepeld (m.u.v. bonen voor zaaidoeleinden)',
    '07133400': 'gedroogde Bambarabonen "Vigna subterranea of Voandzeia subterranea", gedopt, ook indien gepeld',
    '07133500': 'gedroogde koeienerwten "Vigna unguiculata", gedopt, ook indien gepeld',
    '07133900': 'Gedroogde bonen "Vigna spp., Phaseolus spp.", gedopt, ook indien gepeld, (m.u.v. bonen van de soort „Vigna mungo (L.) Hepper” of „Vigna radiata (L.) Wilczek”, Adzukibonen, bonen van de soort „Phaseolus vulgaris”, Bambarabonen en koeienerwten)',
    '07134000': 'gedroogde linzen, gedopt, ook indien gepeld',
    '07135000': 'gedroogde tuinbonen "Vicia faba var. major", paardebonen "Vicia faba var. equina" en duivebonen "Vicia faba var. minor", gedopt, ook indien gepeld',
    '07136000': 'gedroogde struikerwten "Cajanus cajan", gedopt, ook indien gepeld',
    '07139000': 'gedroogde zaden van peulgroenten, gedopt, ook indien gepeld (m.u.v. erwten "Pisum sativum", kekers, bonen "Vigna spp., Phaseolus spp.",  linzen, tuinbonen, paardenbonen, duivenbonen en struikerwten "Cajanus cajan")',
    '07141000': 'Maniokwortel, vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets',
    '07142010': 'bataten "zoete aardappelen", vers, geheel, bestemd voor menselijke consumptie',
    '07142090': 'bataten "zoete aardappelen", vers, gekoeld, bevroren of gedroogd, ook in stukken of in pellets (m.u.v. gehele, verse bataten, bestemd voor menselijke consumptie)',
    '07143000': 'Yams "Dioscorea spp.", vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets',
    '07144000': 'Taro "Colocasia spp." vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets',
    '07145000': 'Yautia "Xanthosoma spp." vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets',
    '07149020': 'Arrowroot "pijlwortel", salepwortel e.d. wortels en knollen met een hoog gehalte aan zetmeel, vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets (m.u.v. maniokwortel, zoete aardappelen, yams, taro en yautia)',
    '07149090': 'aardperen e.d. wortels en knollen met een hoog gehalte aan inuline, vers, gekoeld, bevroren of gedroogd, ook indien in stukken of in pellets, alsmede merg van de sagopalm (m.u.v. maniokwortel, arrowroot "pijlwortel", salepwortel, bataten "zoete aardappelen", yams, taro en yautia)',
    '08011100': 'gedroogde kokosnoten',
    '08011200': 'verse kokosnoten in de binnenste schaal (endocarpium)',
    '08011900': 'verse kokosnoten, ook zonder dop of schaal (m.u.v. die in de binnenste schaal (endocarpium))',
    '08012100': 'paranoten, vers of gedroogd, in de dop',
    '08012200': 'paranoten, vers of gedroogd, zonder dop',
    '08013100': 'cashewnoten, vers of gedroogd, in de dop',
    '08013200': 'cashewnoten, vers of gedroogd, zonder dop',
    '08021110': 'amandelen, bitter, vers of gedroogd, in de dop',
    '08021190': 'amandelen, zoet, vers of gedroogd, in de dop',
    '08021210': 'amandelen, bitter, vers of gedroogd, zonder dop, ook indien gepeld',
    '08021290': 'amandelen, zoet, vers of gedroogd, zonder dop, ook indien gepeld',
    '08022100': 'hazelnoten "Corylus spp.", vers of gedroogd, in de dop',
    '08022200': 'hazelnoten "Corylus spp.", vers of gedroogd, zonder dop, ook indien gepeld',
    '08023100': 'walnoten "okkernoten", vers of gedroogd, in de dop',
    '08023200': 'walnoten "okkernoten", vers of gedroogd, zonder dop, ook indien gepeld',
    '08024100': 'kastanjes "Castanea spp.", vers of gedroogd, in de dop',
    '08024200': 'kastanjes "Castanea spp.", vers of gedroogd, zonder dop, ook indien gepeld',
    '08025100': 'pimpernoten "pistaches", vers of gedroogd,in de dop',
    '08025200': 'pimpernoten "pistaches", vers of gedroogd,zonder dop, ook indien gepeld',
    '08026100': 'macadamianoten, vers of gedroogd, in de dop',
    '08026200': 'macadamianoten, vers of gedroogd, zonder dop, ook indien gepeld',
    '08027000': 'Colanoten "Cola spp.", vers of gedroogd, ook zonder dop of schaal, ook indien gepeld',
    '08028000': 'Arecanoten "betelnoten", vers of gedroogd, ook zonder dop of schaal, ook indien gepeld',
    '08029010': 'Pecannoten, vers of gedroogd, ook zonder dop of schaal, ook indien gepeld',
    '08029050': 'pingels of pignolen"Pinus spp.", vers of gedroogd, ook zonder dop of schaal, ook indien gepeld',
    '08029085': 'noten, vers of gedroogd, ook zonder dop of schaal, ook indien gepeld (m.u.v. kokosnoten, paranoten, cashewnoten, amandelen, hazelnoten, walnoten "okkernoten", kastanjes, pimpernoten "pistaches", pecannoten, arecanoten "betelnoten", colanoten, pingels "pignolen", Australische noten)',
    '08031010': 'plantains, vers',
    '08031090': 'plantains, gedroogd',
    '08039010': 'bananen, vers (m.u.v. plantains)',
    '08039090': 'bananen, gedroogd (m.u.v. plantains)',
    '08041000': 'dadels, vers of gedroogd',
    '08042010': 'vijgen, vers',
    '08042090': 'vijgen, gedroogd',
    '08043000': 'ananassen, vers of gedroogd',
    '08044000': 'advocaten "avocado\'s", vers of gedroogd',
    '08045000': 'guaves, manga\'s en manggistans, vers of gedroogd',
    '08051022': 'Navelsinaasappelen, vers',
    '08051024': 'Blonde sinaasappels, vers',
    '08051028': 'Sinaasappels, zoet en vers (m.u.v. navel- en blonde sinaasappels)',
    '08051080': 'sinaasappelen, gedroogd, alsmede pomeransen "bittere oranjeappelen", vers of gedroogd',
    '08052110': 'Satsuma\'s, vers of gedroogd',
    '08052190': 'Mandarijnen, waaronder begrepen tangerines, vers of gedroogd (m.u.v. clementines en satsuma\'s)',
    '08052200': 'Clementines, waaronder begrepen monreales, vers of gedroogd',
    '08052900': 'Wilkings en dergelijke kruisingen van citrusvruchten, vers of gedroogd',
    '08054000': 'pompelmoezen en pomelo\'s, vers of gedroogd',
    '08055010': 'citroenen "Citrus limon, Citrus limonum", vers of gedroogd',
    '08055090': 'lemmetjes "Citrus aurantifolia, Citrus latifolia", vers of gedroogd',
    '08059000': 'citrusvruchten, vers of gedroogd (m.u.v. sinaasappelen, citroenen "Citrus limon, Citrus limonum", lemmetjes "Citrus aurantifolia, Citrus latifolia", pompelmoezen, pomelo\'s, mandarijnen, incl. tangerines en satsuma\'s, clementines, wilkings e.d. kruisingen van citrusvruchten)',
    '08061010': 'druiven voor tafelgebruik, vers',
    '08061090': 'druiven (m.u.v. druiven voor tafelgebruik, rozijnen en krenten)',
    '08062010': 'Krenten',
    '08062030': 'Sultana\'s',
    '08062090': 'Rozijnen (m.u.v. sultana\'s)',
    '08071100': 'verse watermeloenen',
    '08071900': 'verse meloenen (m.u.v. watermeloenen)',
    '08072000': 'papaja\'s, vers',
    '08081010': 'persappelen, vers, los verladen, van 16 september tot en met 15 december',
    '08081080': 'Appelen, vers (m.u.v. persappelen, los verladen, van 16 september tot en met 15 december)',
    '08083010': 'persperen,  los verladen, van 1 augustus tot en met 31 december, vers',
    '08083090': 'peren, vers (m.u.v. persperen, los verladen, van 1 augustus tot en met 31 december)',
    '08084000': 'kweeperen, vers',
    '08091000': 'abrikozen, vers',
    '08092100': 'zure kersen "Prunus cerasus", vers',
    '08092900': 'kersen, vers (m.u.v. zure kersen "Prunus cerasus")',
    '08093010': 'nectarines, vers',
    '08093090': 'perziken, vers (m.u.v. nectarines)',
    '08094005': 'pruimen, vers',
    '08094090': 'sleepruimen, vers',
    '08101000': 'aardbeien, vers',
    '08102010': 'frambozen, vers',
    '08102090': 'bramen, moerbeien en loganbessen, vers',
    '08103010': 'aalbessen, zwart, vers',
    '08103030': 'aalbessen, rood, vers',
    '08103090': 'kruisbessen en witte aalbessen, vers',
    '08104010': 'rode bosbessen "vruchten van de Vaccinium vitis-idaea", vers',
    '08104030': 'blauwe bosbessen "vruchten van de Vaccinium myrtillus", vers',
    '08104050': 'vruchten van de "Vaccinium macrocarpon" en van de "Vaccinium corymbosum", vers',
    '08104090': 'vruchten van het geslacht "Vaccinium", vers (m.u.v. vruchten van de "Vaccinium vitis-idaea", "Vaccinium myrtillus", "Vacinium macrocarpon" en "Vaccinium corymbosum")',
    '08105000': 'verse kiwi\'s',
    '08106000': 'verse doerians',
    '08107000': 'Dadelpruimen, vers',
    '08109020': 'Tamarindevruchten, cashewappelen, nangka\'s "jackfruit", lychees en sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, vers',
    '08109075': 'Fruit, geschikt voor menselijke consumptie, vers (m.u.v. noten, bananen, dadels, vijgen, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, nangka\'s "jackfruit", litchis, sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, citrusvruchten, druiven, rozijnen, krenten, meloenen, appelen, peren, kweeperen, abrikozen, kersen, perziken, pruimen, sleepruimen, aardbeien, frambozen, bramen, moerbeien, loganbessen, zwarte, witte of rode aalbessen, kruisbessen, veenbessen, vruchten van het geslacht "Vaccinium", kiwi\'s,doerians en dadelpruimen)',
    '08111011': 'aardbeien, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van > 13 gewichtspercenten',
    '08111019': 'aardbeien, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van <= 13 gewichtspercenten',
    '08111090': 'aardbeien, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08112011': 'frambozen, bramen, moerbeien, loganbessen, aalbessen en kruisbessen, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van > 13 gewichtspercenten',
    '08112019': 'frambozen, bramen, moerbeien, loganbessen, aalbessen en kruisbessen, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van <= 13 gewichtspercenten',
    '08112031': 'frambozen, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08112039': 'aalbessen, zwart, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08112051': 'aalbessen, rood, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08112059': 'bramen en moerbeien, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08112090': 'loganbessen, kruisbessen en witte aalbessen, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08119011': 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van > 13 gewichtspercenten',
    '08119019': 'vruchten, geschikt voor menselijke consumptie, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van > 13 gewichtspercenten (m.u.v. aardbeien, frambozen, bramen, moerbeien, loganbessen; aalbessen, kruisbessen; guaves, manga\'s, manggistans; papaja\'s; tamarindevruchten, cashewappelen, nangka\'s "jackfruit", litchis, sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s; kokosnoten, paranoten, cashewnoten; arecanoten [betelnoten], colanoten; Australische noten)',
    '08119031': 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van <= 13 gewichtspercenten',
    '08119039': 'vruchten, geschikt voor menselijke consumptie, ook indien gestoomd of in water gekookt, bevroren, met toegevoegde suiker of andere zoetstoffen, met een suikergehalte van <= 13 gewichtspercenten (m.u.v. aardbeien, frambozen, bramen, moerbeien, loganbessen; aalbessen, kruisbessen; guaves, manga\'s, manggistans; papaja\'s; tamarindevruchten, cashewappelen, nangka\'s "jackfruit", litchis, sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s; kokosnoten, paranoten, cashewnoten; arecanoten [betelnoten], colanoten; Australische noten)',
    '08119050': 'blauwe bosbessen "vruchten van de Vaccinium myrtillus", ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08119070': 'blauwe bosbessen "vruchten van de Vaccinium myrtilloides en de Vaccinium angustifolium", ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08119075': 'zure kersen "Prunus cerasus", ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet',
    '08119080': 'kersen, ook indien gestoomd of in water gekookt, bevroren, ongesuikerd, ongezoet (m.u.v. zure kersen "Prunus cerasus")',
    '08119085': 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten, ook indien gestoomd of in water gekookt, bevroren, zonder toegevoegde suiker of andere zoetstoffen',
    '08119095': 'vruchten, geschikt voor menselijke consumptie, ook indien gestoomd of in water gekookt, bevroren, zonder toegevoegde suiker of andere zoetstoffen (m.u.v. aardbeien, frambozen, bramen, moerbeien, loganbessen, aalbessen, kruisbessen, bosbessen van de "Vaccinium myrtillus, Vaccinium myrtilloides, Vaccinium angustifolium", kersen, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, nangka\'s "jackfruit", litchis, sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, paranoten, cashewnoten, arecanoten [betelnoten], colanoten en Australische noten)',
    '08121000': 'kersen, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '08129025': 'abrikozen en sinaasappelen, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '08129030': 'papaja\'s, voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '08129040': 'blauwe bosbessen "vruchten van de Vaccinium myrtillus", voorlopig verduurzaamd, b.v. door middel van zwaveldioxide of in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie',
    '08129070': 'guaves, manga\'s, manggistans, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten, als zodanig ongeschikt voor dadelijke consumptie',
    '08129098': 'Vruchten, voorlopig verduurzaamd, bv. door middel van zwaveldioxide of in water waaraan zout, zwavel of andere stoffen zijn toegevoegd, doch als zodanig ongeschikt voor dadelijke consumptie (m.u.v. kersen, abrikozen, sinaasappelen, papaja\'s, blauwe bosbessen "vruchten van de Vaccinium myrtillus", guaves, manga\'s, manggistans, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, paranoten, cashewnoten, arecanoten "betelnoten", colanoten en Australische noten)',
    '08131000': 'abrikozen, gedroogd',
    '08132000': 'pruimen, gedroogd',
    '08133000': 'appelen, gedroogd',
    '08134010': 'perziken, incl. nectarines, gedroogd',
    '08134030': 'peren, gedroogd',
    '08134050': 'papaja\'s, gedroogd',
    '08134065': 'Tamarindevruchten, cashewappelen, lychees, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, gedroogd',
    '08134095': 'vruchten, geschikt voor menselijke consumptie, gedroogd (m.u.v. noten, bananen, dadels, vijgen, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, nangka\'s "jackfruit", litchis, sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, citrusvruchten, rozijnen, krenten, abrikozen, pruimen, appelen, peren, perziken, incl. nectarines, niet gemengd)',
    '08135012': 'mengsels van gedroogde papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, zonder pruimen',
    '08135015': 'mengsels van gedroogde vruchten, geschikt voor menselijke consumptie, zonder pruimen (m.u.v. mengsels van noten, bananen, dadels, vijgen, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, papaja\'s, citrusvruchten, rozijnen, krenten, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s)',
    '08135019': 'mengsels van gedroogde abrikozen, appelen, perziken, incl. nectarines, peren, papaja\'s of andere gedroogde vruchten, geschikt voor menselijke consumptie, met pruimen (m.u.v. mengsels, bevattende noten, bananen, dadels, vijgen, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, citrusvruchten, rozijnen en krenten)',
    '08135031': 'mengsels uitsluitend bestaande uit gedroogde kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten',
    '08135039': 'mengsels uitsluitend bestaande uit gedroogde noten, geschikt voor menselijke consumptie, bedoeld bij post 0802 (m.u.v. die van kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten)',
    '08135091': 'mengsels van gedroogde noten, bananen, dadels, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, citrusvruchten, rozijnen en krenten, geschikt voor menselijke consumptie, zonder pruimen en zonder vijgen (m.u.v. mengsels van noten bedoeld bij de posten 0801 en 0802)',
    '08135099': 'mengsels van gedroogde noten, bananen, dadels, vijgen, ananassen, advocaten "avocado\'s", guaves, manga\'s, manggistans, citrusvruchten, rozijnen en krenten, geschikt voor menselijke consumptie, met pruimen of vijgen',
    '08140000': 'schillen van citrusvruchten en van meloenen, incl. watermeloenen, vers, bevroren, gedroogd, dan wel in water waaraan, voor het voorlopig verduurzamen, zout, zwavel of andere stoffen zijn toegevoegd',
    '09011100': 'ongebrande koffie waaruit geen cafeïne is verwijderd',
    '09011200': 'ongebrande koffie waaruit cafeïne is verwijderd',
    '09012100': 'gebrande koffie waaruit geen cafeïne is verwijderd',
    '09012200': 'gebrande koffie waaruit cafeïne is verwijderd',
    '09019010': 'bolsters en schillen, van koffie',
    '09019090': 'koffiesurrogaten die koffie bevatten, ongeacht de mengverhouding',
    '09021000': 'groene "ongefermenteerde" thee, ook indien gearomatiseerd, in onmiddellijke verpakkingen met een inhoud van <= 3 kg',
    '09022000': 'groene "ongefermenteerde" thee in onmiddellijke verpakkingen met een inhoud van > 3 kg',
    '09023000': 'zwarte "gefermenteerde" thee en gedeeltelijk gefermenteerde thee, ook indien gearomatiseerd, in onmiddellijke verpakkingen met een inhoud van <= 3 kg',
    '09024000': 'zwarte "gefermenteerde" thee en gedeeltelijk gefermenteerde thee, ook indien gearomatiseerd, in onmiddellijke verpakkingen met een inhoud van > 3 kg',
    '09030000': 'maté',
    '09041100': 'peper van het geslacht "Piper" (niet fijngemaakt en niet gemalen)',
    '09041200': 'peper van het geslacht "Piper", fijngemaakt of gemalen',
    '09042110': 'pepers zonder scherpe smaak, gedroogd, niet fijngemaakt en niet gemalen',
    '09042190': 'vruchten van de geslachten "Capsicum" en "Pimenta", gedroogd, niet fijngemaakt en niet gemalen (m.u.v. niet-scherpsmakende pepers)',
    '09042200': 'vruchten van de geslachten "Capsicum" en "Pimenta", fijngemaakt of gemalen',
    '09051000': 'vanille, niet fijngemaakt en niet gemalen',
    '09052000': 'vanille, fijngemaakt of gemalen',
    '09061100': 'Kaneel "Cinnamomum zeylanicum Blume" (m.u.v. fijngemaakt en gemalen)',
    '09061900': 'Kaneel en kaneelknoppen (m.u.v. kaneel "Cinnamomum zeylanicum Blume" en fijngemaakte en gemalen kaneel)',
    '09062000': 'kaneel en kaneelknoppen, fijngemaakt of gemalen',
    '09071000': 'kruidnagels, moernagels en kruidnagelstelen, niet fijngemaakt en niet gemalen',
    '09072000': 'kruidnagels, moernagels en kruidnagelstelen, fijngemaakt of gemalen',
    '09081100': 'muskaatnoten, niet fijngemaakt en niet gemalen',
    '09081200': 'muskaatnoten, fijngemaakt of gemalen',
    '09082100': 'foelie, niet fijngemaakt en niet gemalen',
    '09082200': 'foelie, fijngemaakt of gemalen',
    '09083100': 'amomen en kardemom,niet fijngemaakt en niet gemalen',
    '09083200': 'amomen en kardemom, fijngemaakt of gemalen',
    '09092100': 'korianderzaad, niet fijngemaakt en niet gemalen',
    '09092200': 'korianderzaad, fijngemaakt of gemalen',
    '09093100': 'komijnzaad, niet fijngemaakt en niet gemalen',
    '09093200': 'komijnzaad, fijngemaakt of gemalen',
    '09096100': 'Jeneverbessen, anijszaad en steranijszaad, karwijzaad, venkelzaad,  niet fijngemaakt en niet gemalen',
    '09096200': 'Jeneverbessen, anijszaad en steranijszaad, karwijzaad, venkelzaad, fijngemaakt of gemalen',
    '09101100': 'gember, niet fijngemaakt en niet gemalen',
    '09101200': 'gember, fijngemaakt of gemalen',
    '09102010': 'saffraan (niet fijngemaakt en niet gemalen)',
    '09102090': 'saffraan, fijngemaakt of gemalen',
    '09103000': 'kurkuma',
    '09109105': 'Kerrie',
    '09109110': 'mengsels van diverse specerijen (niet fijngemaakt en niet gemalen)',
    '09109190': 'mengsels van diverse specerijen, fijngemaakt of gemalen',
    '09109910': 'fenegriekzaad',
    '09109931': 'wilde tijm "Thymus serpyllum" (niet fijngemaakt en niet gemalen)',
    '09109933': 'tijm (niet fijngemaakt en niet gemalen en m.u.v. wilde tijm "Thymus serpyllum")',
    '09109939': 'tijm, fijngemaakt of gemalen',
    '09109950': 'laurierbladeren',
    '09109991': 'specerijen (m.u.v. fijngemaakte en gemalen specerijen; peper van het geslacht "Piper"; vruchten van de geslachten "Capsicum" en "Pimenta"; vanille; kaneel, kaneelknoppen; kruidnagels, moernagels, kruidnagelstelen; muskaatnoten, foelie, amomen, kardemom; anijszaad, steranijszaad, venkelzaad, korianderzaad, komijnzaad, karwijzaad; jeneverbessen; gember, saffraan, kurkuma, tijm, laurierbladeren, kerrie; fenegriekzaad; mengsels van diverse specerijen)',
    '09109999': 'specerijen, fijngemaakt of gemalen (m.u.v. peper van het geslacht "Piper"; vruchten van de geslachten "Capsicum" en "Pimenta"; vanille; kaneel, kaneelknoppen; kruidnagels, moernagels, kruidnagelstelen; muskaatnoten, foelie, amomen, kardemom; anijszaad, steranijszaad, venkelzaad, korianderzaad, komijnzaad, karwijzaad; jeneverbessen; gember, saffraan, kurkuma, tijm, laurierbladeren, kerrie; fenegriekzaad; mengsels van diverse specerijen)',
    10011100: 'Harde tarwe, zaaigoed',
    10011900: 'harde tarwe, (m.u.v. zaaigoed)',
    10019110: 'spelt, zaaigoed',
    10019120: 'zachte tarwe en mengkoren, zaaigoed',
    10019190: 'tarwe en mengkoren, zaaigoed (m.u.v. harde tarwe, spelt, zachte tarwe en mengkoren)',
    10019900: 'Tarwe en mengkoren (m.u.v. harde tarwe en zaaigoed)',
    10021000: 'rogge, zaaigoed',
    10029000: 'rogge, (m.u.v. zaaigoed)',
    10031000: 'gerst, zaaigoed',
    10039000: 'gerst (m.u.v. zaaigoed)',
    10041000: 'haver, zaaigoed',
    10049000: 'haver (m.u.v. zaaigoed)',
    10051013: 'drieweg-hybriden van maïs, zaaigoed',
    10051015: 'enkele hybriden van maïs, zaaigoed',
    10051018: 'Hybriden van maïs, zaaigoed (m.u.v. drieweg-hybriden en enkele hybriden)',
    10051090: 'maïs, zaaigoed (m.u.v. hybriden van maïs)',
    10059000: 'maïs (m.u.v. zaaigoed)',
    10061010: 'padie, bestemd voor zaaidoeleinden',
    10061030: 'padie, rondkorrelig',
    10061050: 'padie, halflangkorrelig',
    10061071: 'padie, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is',
    10061079: 'padie, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is',
    10062011: 'gedopte rijst, rondkorrelig, voorgekookt "parboiled"',
    10062013: 'gedopte rijst, halflangkorrelig, voorgekookt "parboiled"',
    10062015: 'gedopte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is, voorgekookt "parboiled"',
    10062017: 'gedopte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is, voorgekookt "parboiled"',
    10062092: 'gedopte rijst, rondkorrelig (m.u.v. voorgekookte "parboiled" rijst)',
    10062094: 'gedopte rijst, halflangkorrelig (m.u.v. voorgekookte "parboiled" rijst)',
    10062096: 'gedopte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is (m.u.v. voorgekookte "parboiled" rijst)',
    10062098: 'gedopte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is (m.u.v. voorgekookte "parboiled" rijst)',
    10063021: 'halfwitte rijst, rondkorrelig, voorgekookt "parboiled"',
    10063023: 'halfwitte rijst, halflangkorrelig, voorgekookt "parboiled"',
    10063025: 'halfwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is, voorgekookt "parboiled"',
    10063027: 'halfwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is, voorgekookt "parboiled"',
    10063042: 'halfwitte rijst, rondkorrelig (m.u.v. voorgekookte "parboiled" rijst)',
    10063044: 'halfwitte rijst, halflangkorrelig (m.u.v. voorgekookte "parboiled" rijst)',
    10063046: 'halfwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is (m.u.v. voorgekookte "parboiled" rijst)',
    10063048: 'halfwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is (m.u.v. voorgekookte "parboiled" rijst)',
    10063061: 'volwitte rijst, rondkorrelig, ook indien gepolijst of geglansd, voorgekookt "parboiled"',
    10063063: 'volwitte rijst, halflangkorrelig, ook indien gepolijst of geglansd, voorgekookt "parboiled"',
    10063065: 'volwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is, ook indien gepolijst of geglansd, voorgekookt "parboiled"',
    10063067: 'volwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is, ook indien gepolijst of geglansd, voorgekookt "parboiled"',
    10063092: 'volwitte rijst, rondkorrelig, ook indien gepolijst of geglansd (m.u.v. voorgekookte "parboiled" rijst)',
    10063094: 'volwitte rijst, halflangkorrelig, ook indien gepolijst of geglansd (m.u.v. voorgekookte "parboiled" rijst)',
    10063096: 'volwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte > 2 doch < 3 is, ook indien gepolijst of geglansd (m.u.v. voorgekookte "parboiled" rijst)',
    10063098: 'volwitte rijst, langkorrelig, waarvan de verhouding lengte/breedte >= 3 is, ook indien gepolijst of geglansd (m.u.v. voorgekookte "parboiled" rijst)',
    10064000: 'breukrijst',
    10071010: 'hybriden van graansorgho, zaaigoed',
    10071090: 'graansorgho, zaaigoed (m.u.v. hybriden)',
    10079000: 'graansorgho (m.u.v. zaaigoed)',
    10081000: 'boekweit',
    10082100: 'gierst, zaaigoed (m.u.v. graansorgho)',
    10082900: 'gierst (m.u.v. zaaigoed en graansorgho)',
    10083000: 'kanariezaad',
    10084000: 'Fonio "Digitaria spp."',
    10085000: 'Quinoa "Chenopodium quinoa"',
    10086000: 'triticale',
    10089000: 'graan (m.u.v. tarwe en mengkoren, rogge, gerst, haver, maïs, rijst, boekweit, gierst, kanariezaad, fonio, quinoa, triticale en graansorgho)',
    11010011: 'Meel van harde tarwe "durum"',
    11010015: 'Meel van zachte tarwe en spelt',
    11010090: 'Meel van mengkoren',
    11022010: 'maïsmeel, met een gehalte aan vetstoffen van <= 1,5 gewichtspercent',
    11022090: 'maïsmeel, met een gehalte aan vetstoffen van > 1,5 gewichtspercent',
    11029010: 'meel van gerst',
    11029030: 'meel van haver',
    11029050: 'rijstmeel',
    11029070: 'Roggemeel',
    11029090: 'meel van granen (m.u.v. meel van tarwe of van mengkoren, roggemeel, maïsmeel, rijstmeel, meel van gerst en meel van haver)',
    11031110: 'gries en griesmeel, van harde tarwe "durum"',
    11031190: 'gries en griesmeel, van zachte tarwe en spelt',
    11031310: 'gries en griesmeel, van maïs, met een gehalte aan vetstoffen van <= 1,5 gewichtspercent',
    11031390: 'gries en griesmeel, van maïs, met een gehalte aan vetstoffen van > 1,5 gewichtspercent',
    11031920: 'Gries en griesmeel, van rogge of van gerst',
    11031940: 'gries en griesmeel, van haver',
    11031950: 'gries en griesmeel, van rijst',
    11031990: 'gries en griesmeel, van granen (m.u.v. gries en griesmeel van tarwe, van haver, van maïs, van rijst, van rogge en van gerst)',
    11032025: 'Pellets, van rogge of van gerst',
    11032030: 'pellets van haver',
    11032040: 'pellets van maïs',
    11032050: 'pellets van rijst',
    11032060: 'pellets van tarwe',
    11032090: 'pellets van granen (m.u.v. die van rogge, gerst, haver, maïs, rijst of tarwe)',
    11041210: 'granen van haver, geplet',
    11041290: 'granen van haver, in vlokken',
    11041910: 'granen van tarwe, geplet of in vlokken',
    11041930: 'granen van rogge, geplet of in vlokken',
    11041950: 'granen van maïs, geplet of in vlokken',
    11041961: 'granen van gerst, geplet',
    11041969: 'granen van gerst, in vlokken',
    11041991: 'granen van rijst, in vlokken',
    11041999: 'granen, geplet of in vlokken (m.u.v. die van haver, tarwe, rogge of maïs en m.u.v. vlokken van rijst)',
    11042240: 'granen van haver, gepeld, al dan niet gesneden of gebroken',
    11042250: 'granen van haver, gepareld',
    11042295: 'Granen van haver, gesneden, gebroken of op andere wijze bewerkt (m.u.v. geplet, in vlokken, gepeld, gepareld en m.u.v. meel en pellets)',
    11042340: 'granen van maïs, gepeld, al dan niet gesneden of gebroken, en gepareld',
    11042398: 'Granen van maïs,gesneden, gebroken of op andere wijze bewerkt (m.u.v. geplet, in vlokken, gepeld, gepareld en m.u.v. meel en pellets)',
    11042904: 'Granen van gerst, gepeld, al dan niet gesneden of gebroken',
    11042905: 'granen van gerst, gepareld',
    11042908: 'Granen van gerst, gesneden, gebroken of op andere wijze bewerkt  (m.u.v. geplet, in vlokken, gepeld, gepareld en m.u.v. meel en pellets)',
    11042917: 'Granen, gepeld, al dan niet gesneden of gebroken (m.u.v. granen van gerst, van haver, van maïs en van rijst)',
    11042930: 'Granen, gepareld (m.u.v. granen van gerst, van haver, van maïs en van rijst)',
    11042951: 'Granen van tarwe, enkel gebroken',
    11042955: 'Granen van rogge, enkel gebroken',
    11042959: 'Granen, enkel gebroken (m.u.v. granen van gerst, van haver, van maïs, van tarwe en van rogge)',
    11042981: 'Granen van tarwe, gesneden of gebroken of anderzijds bewerkt (m.u.v. geplet of in vlokken, meel, pellets, gepareld en die enkel gebroken)',
    11042985: 'Granen van rogge, gesneden of gebroken of anderzijds bewerkt (m.u.v. geplet of in vlokken, meel, pellets, gepareld en die enkel gebroken)',
    11042989: 'Granen, gesneden of gebroken of anderzijds bewerkt (m.u.v. die van gerst, haver, mais, tarwe en rogge, geplet of in vlokken, meel, pellets, gepareld en die enkel gebroken en m.u.v. halfwitte of volwitte rijst en  breukrijst)',
    11043010: 'graankiemen van tarwe, ook indien geplet, in vlokken of gemalen',
    11043090: 'graankiemen, ook indien geplet, in vlokken of gemalen (m.u.v. graankiemen van tarwe)',
    11051000: 'meel, gries en poeder, van aardappelen',
    11052000: 'vlokken, korrels en pellets, van aardappelen',
    11061000: 'meel, gries en poeder van erwten, kekers, bonen, linzen en andere gedroogde zaden van peulgroenten bedoeld bij post 0713',
    11062010: 'meel, gries en poeder, van merg van de sagopalm, van maniokwortel, pijlwortel "arrowroot", salepwortel, aardperen, zoete aardappelen "bataten" e.d. wortels en knollen met een hoog gehalte aan zetmeel of aan inuline, bedoeld bij post 0714, gedenatureerd',
    11062090: 'meel, gries en poeder, van merg van de sagopalm, van maniokwortel, pijlwortel "arrowroot", salepwortel, aardperen, zoete aardappelen "bataten" e.d. wortels en knollen met een hoog gehalte aan zetmeel of aan inuline, bedoeld bij post 0714 (niet gedenatureerd)',
    11063010: 'meel, gries en poeder van bananen',
    11063090: 'meel, gries en poeder van producten bedoeld bij hoofdstuk 8 "Fruit; schillen van citrusvruchten en van meloenen" (m.u.v. bananen)',
    11071011: 'mout van tarwe, ongebrand, in de vorm van meel',
    11071019: 'mout van tarwe, ongebrand (m.u.v. in de vorm van meel)',
    11071091: 'mout, ongebrand, in de vorm van meel (m.u.v. mout van tarwe)',
    11071099: 'mout, ongebrand (m.u.v. mout van tarwe en mout in de vorm van meel)',
    11072000: 'mout, gebrand',
    11081100: 'tarwezetmeel',
    11081200: 'maïszetmeel',
    11081300: 'aardappelzetmeel',
    11081400: 'maniokzetmeel "cassave"',
    11081910: 'rijstzetmeel',
    11081990: 'zetmeel (m.u.v. tarwezetmeel, maïszetmeel, aardappelzetmeel, maniokzetmeel "cassave" en rijstzetmeel)',
    11082000: 'inuline',
    11090000: 'tarwegluten, ook indien gedroogd',
    12011000: 'sojabonen, zaaigoed',
    12019000: 'sojabonen, ook indien gebroken (m.u.v. zaaigoed)',
    12023000: 'grondnoten, zaaigoed',
    12024100: 'grondnoten, niet gebrand of op andere wijze door verhitting bereid, in de dop (m.u.v. zaaigoed)',
    12024200: 'grondnoten, niet gebrand of op andere wijze door verhitting bereid, gedopt, ook indien gebroken (m.u.v. zaaigoed)',
    12030000: 'kopra',
    12040010: 'lijnzaad, bestemd voor zaaidoeleinden',
    12040090: 'lijnzaad, ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12051010: 'kool- en raapzaad met een laag gehalte aan erucazuur "dat een vaste olie oplevert met een gehalte aan erucazuur van < 2 gewichtspercenten en een vast bestanddeel met een gehalte aan glucosinolaten van < 30 micromol per gram", bestemd voor zaaidoeleinden',
    12051090: 'kool- en raapzaad met een laag gehalte aan erucazuur "dat een vaste olie oplevert met een gehalte aan erucazuur van < 2 gewichtspercenten en een vast bestanddeel met een gehalte aan glucosinolaten van < 30 micromol per gram", ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12059000: 'kool- en raapzaad met een hoog gehalte aan erucazuur "dat een vaste olie oplevert met een gehalte aan erucazuur van >= 2 gewichtspercenten en een vast bestanddeel met een gehalte aan glucosinolaten van >= 30 micromol per gram", ook indien gebroken',
    12060010: 'zonnebloempitten, bestemd voor zaaidoeleinden',
    12060091: 'zonnebloempitten, ook indien gebroken,  gedopt of ongedopt en grijs-wit gestreept (m.u.v. die bestemd voor zaaidoeleinden)',
    12060099: 'zonnebloempitten, ook indien gebroken (m.u.v. die bestemd voor zaaidoeleinden, die welke gedopt zijn en die welke ongedopt maar grijs-wit gestreept zijn)',
    12071000: 'palmnoten en palmpitten',
    12072100: 'katoenzaad, zaaigoed',
    12072900: 'katoenzaad, ook indien gebroken (m.u.v. zaaigoed)',
    12073000: 'Ricinuszaad, ook indien gebroken',
    12074010: 'sesamzaad, bestemd voor zaaidoeleinden',
    12074090: 'sesamzaad, ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12075010: 'mosterdzaad, bestemd voor zaaidoeleinden',
    12075090: 'mosterdzaad, ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12076000: 'saffloerzaad "Carthamus tinctorius", ook indien gebroken',
    12077000: 'meloenzaad, ook indien gebroken',
    12079110: 'papaverzaad, bestemd voor zaaidoeleinden',
    12079190: 'papaverzaad, ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12079920: 'oliehoudende zaden en vruchten, zaaigoed (m.u.v. eetbare noten, olijven, sojabonen, grondnoten, kopra, lijnzaad, kool- en raapzaad, zonnebloempitten, palmnoten en palmpitten, katoen-, ricinus-,  sesam-, mosterd-, saffloer-, meloen-  en papaverzaad)',
    12079991: 'hennepzaad, ook indien gebroken (m.u.v. dat voor zaaidoeleinden)',
    12079996: 'oliehoudende zaden en vruchten, ook indien gebroken (m.u.v. zaaigoed en m.u.v. eetbare noten, olijven, sojabonen, grondnoten, kopra, lijnzaad, kool- en raapzaad, zonnebloempitten, palmnoten en palmpitten, katoen-, ricinus-, sesam-, mosterd-, saffloer-, meloen-, papaver- en hennepzaad)',
    12081000: 'meel van sojabonen',
    12089000: 'meel van oliehoudende zaden en vruchten (m.u.v. mosterdmeel en meel van sojabonen)',
    12091000: 'zaaigoed van suikerbieten',
    12092100: 'zaaigoed van luzerne',
    12092210: 'zaaigoed van rode klaver "Trifolium pratense L."',
    12092280: 'zaaigoed van klaver "Trifolium spp." (m.u.v. dat van rode klaver "Trifolium pratense L.")',
    12092311: 'zaaigoed van beemdlangbloem "Festuca pratensis Huds."',
    12092315: 'zaaigoed van rood zwenkgras "Festuca rubra L."',
    12092380: 'zaaigoed van zwenkgras (m.u.v. dat van beemdlangbloem "Festuca pratensis Huds." en van rood zwenkgras "Festuca rubra L.")',
    12092400: 'zaaigoed van veldbeemdgras "Poa pratensis L."',
    12092510: 'zaaigoed van Westerwolds en Italiaans raaigras "Lolium multiflorum Lam."',
    12092590: 'zaaigoed van Engels raaigras "Lolium perenne L."',
    12092945: 'Timotheegraszaad; zaad van wikken, van ruw beemdgras "Poa palustris L., Poa trivalis L", van kropaar "Dactylis glomerata L." en van struisgras"Agrostides"',
    12092950: 'zaaigoed van lupinen',
    12092960: 'voederbietenzaad "Beta vulgaris var. alba", bestemd voor zaaidoeleinden',
    12092980: 'zaaigoed van voedergewassen (m.u.v. zaaigoed van granen, voederbieten "Beta vulgaris var. alba", suikerbieten, luzerne, klaver "Trifolium spp.", zwenkgras, veldbeemdgras "Poa pratensis L.", raaigras "Lolium multiflorum Lam., Lolium perenne L.", timotheegras, wikken, ruw beemdgras "Poa palustris L., Poa trivialis L.", kropaar "Dactylis glomerata L.", struisgras "Agrostides" en lupinen)',
    12093000: 'zaaigoed van kruidachtige planten, hoofdzakelijk gekweekt voor de bloemen',
    12099130: 'rodebietenzaad "Beta vulgaris var. conditiva", bestemd voor zaaidoeleinden',
    12099180: 'Groentezaad, zaaigoed (m.u.v. rodebietenzaad "Beta vulgaris var.conditiva")',
    12099910: 'zaaigoed van woudbomen en van woudheesters',
    12099991: 'zaaigoed van planten, hoofdzakelijk gekweekt voor de bloemen (m.u.v. kruidachtige planten)',
    12099999: 'zaaigoed, sporen daaronder begrepen (m.u.v. peulgroenten, suikermaïs, koffie, thee, maté, specerijen, granen, oliehoudende zaden en vruchten, bieten, voedergewassen, groentezaden, zaden van woudbomen en van woudheesters, zaden van planten, hoofdzakelijk gekweekt voor de bloemen of van de soort, hoofdzakelijk gebruikt in de reukwerkindustrie, in de geneeskunde of voor insecten- of parasietenbestrijding of voor dergelijke doeleinden)',
    12101000: 'hopbellen, vers of gedroogd (m.u.v. fijngemaakte en gemalen hopbellen; hopbellen in pellets)',
    12102010: 'hopbellen, vers of gedroogd, fijngemaakt, gemalen of in pellets, met lupuline verrijkt, alsmede lupuline',
    12102090: 'hopbellen, vers of gedroogd, fijngemaakt, gemalen of in pellets (m.u.v. die verrijkt met lupuline)',
    12112000: 'ginsengwortel, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm',
    12113000: 'cocabladeren, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm',
    12114000: 'papaverbolkaf, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm',
    12115000: 'Planten, plantendelen, zaden en vruchten van de soort Ephedra, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm.',
    12119030: 'Tonkabonen, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm',
    12119086: 'Planten, plantendelen, zaden en vruchten van de soort hoofdzakelijk gebruikt in de reukwerkindustrie, in de geneeskunde of voor insecten- of parasietenbestrijding of voor dergelijke doeleinden, vers, gekoeld, bevroren of gedroogd, ook indien gesneden, gebroken of in poedervorm (m.u.v. ginsengwortel, cocabladeren, papaverbolkaf, de soort Ephedra en tonkabonen).',
    12122100: 'zeewier en andere algen, vers, gekoeld, bevroren of gedroogd, ook indien in poedervorm, geschikt voor menselijke consumptie',
    12122900: 'zeewier en andere algen, vers, gekoeld, bevroren of gedroogd, ook indien in poedervorm (m.u.v. die geschikt voor menselijke consumptie)',
    12129120: 'suikerbieten, gedroogd, ook indien in poedervorm',
    12129180: 'suikerbieten, vers, gekoeld of bevroren',
    12129200: 'sint-jansbrood, vers, gekoeld, bevroren of gedroogd, ook indien in poedervorm',
    12129300: 'suikerriet, vers, gekoeld, bevroren of gedroogd, ook indien in poedervorm',
    12129400: 'chicoreiwortels, vers, gekoeld, bevroren of gedroogd, ook indien in poedervorm',
    12129941: 'sint-jansbroodpitten, vers of gedroogd, ongepeld, ongebroken en ongemalen',
    12129949: 'sint-jansbroodpitten, vers of gedroogd, gepeld, gebroken of gemalen',
    12129995: 'vruchtenpitten, ook indien in de steen en andere plantaardige producten, hoofdzakelijk gebruikt voor menselijke consumptie, n.e.g.',
    12130000: 'stro en kaf van graangewassen, onbewerkt, ook indien gehakt, gemalen, geperst of in "pellets"',
    12141000: 'luzernemeel en luzerne in "pellets"',
    12149010: 'mangelwortels "voederbieten", voederrapen en andere voederwortels',
    12149090: 'hooi, luzerne, klaver, hanenkammetjes "esparcette", mergkool, lupine, wikke e.d. voedergewassen (m.u.v. koolrapen, voederbieten, voederwortels en luzernemeel)',
    13012000: 'Arabische gom',
    13019000: 'gomlak "schellak", gommen, harsen, gomharsen en oleoharsen "b.v. balsems", van natuurlijke oorsprong (m.u.v. Arabische gom)',
    13021100: 'opium',
    13021200: 'extract van zoethout (m.u.v. dat met een sacharosegehalte van > 10 gewichtspercenten of toebereid als suikergoed)',
    13021300: 'extract van hop',
    13021400: 'Plantensappen en plantenextracten van planten van het geslacht Ephedra',
    13021905: 'vanille-oleohars',
    13021970: 'plantensappen en plantenextracten (m.u.v. die van zoethout, hop, vanille-oleohars, opium en van het geslacht Ephedra)',
    13022010: 'pectinestoffen, pectinaten en pectaten, in droge toestand "in poeder"',
    13022090: 'pectinestoffen, pectinaten en pectaten, vloeibaar',
    13023100: 'agar-agar, ook indien gewijzigd',
    13023210: 'plantenslijmen en bindmiddelen, ook indien gewijzigd, uit sint-jansbrood of uit sint-jansbroodpitten',
    13023290: 'plantenslijmen en bindmiddelen, ook indien gewijzigd, uit guarzaden',
    13023900: 'plantenslijmen en bindmiddelen, ook indien gewijzigd (m.u.v. die uit sint-jansbrood, uit sint-jansbroodpitten of uit guarzaden, m.u.v. agar-agar)',
    14011000: 'bamboe',
    14012000: 'rotting',
    14019000: 'riet, bies, teen, raffia, lindebast, gezuiverd, gebleekt of geverfd stro van graangewassen, alsmede andere plantaardige stoffen van de soort hoofdzakelijk gebruikt in de mandenmakerij of voor vlechtwerk (m.u.v. bamboe en rotting)',
    14042000: 'katoenlinters',
    14049000: 'plantaardige producten, n.e.g.',
    15011010: 'Reuzel, gesmolten of anderszins geëxtraheerd, bestemd voor industrieel gebruik (m.u.v. dat voor de vervaardiging van producten voor menselijke consumptie, varkensstearine en spekolie)',
    15011090: 'Reuzel, gesmolten of anderszins geëxtraheerd (m.u.v. dat bestemd voor industrieel, technisch gebruik, varkensstearine en spekolie)',
    15012010: 'Varkensvet, gesmolten of anderszins geëxtraheerd, bestemd voor industrieel gebruik (m.u.v. dat voor de vervaardiging van producten voor menselijke consumptie en reuzel)',
    15012090: 'Varkensvet, gesmolten of anderszins geëxtraheerd (dat bestemd voor industrieel, technisch gebruik en reuzel)',
    15019000: 'vet van gevogelte, gesmolten of anderszins geëxtraheerd',
    15021010: 'Talk van rund-, schapen- of geitenvet bestemd voor industrieel gebruik (m.u.v. dat voor de vervaardiging van producten voor menselijke consumptie en m.u.v. olie en oleostearine)',
    15021090: 'Talg van rund-, schapen- of geitenvet (m.u.v. dat bestemd voor industrieel, technisch gebruik en m.u.v. olie en oleostearine)',
    15029010: 'Rund-, schapen- of geitenvet,  bestemd voor industrieel gebruik (m.u.v. dat voor de vervaardiging van producten voor menselijke consumptie en m.u.v. talk, oleostearine en oleo-olie)',
    15029090: 'Rund-, schapen- of geitenvet (m.u.v. dat bestemd voor industrieel, technisch gebruik en m.u.v. talk, oleostearine en oleo-olie)',
    15030011: 'varkensstearine en oleostearine (niet geëmulgeerd, niet vermengd, noch op andere wijze bereid), bestemd voor industrieel gebruik',
    15030019: 'varkensstearine en oleostearine, niet geëmulgeerd, niet vermengd, noch op andere wijze bereid (m.u.v. bestemd voor industrieel gebruik)',
    15030030: 'talkolie, niet geëmulgeerd, niet vermengd, noch op andere wijze bereid, bestemd voor industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15030090: 'spekolie, oleomargarine en talkolie, niet geëmulgeerd, niet vermengd, noch op andere wijze bereid (m.u.v. talkolie voor technisch gebruik in de industrie)',
    15041010: 'oliën uit vislevers en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, met een gehalte aan vitamine A van <= 2.500 internationale eenheden per gram',
    15041091: 'oliën uit de levers van heilbot en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. olie uit vislevers met een gehalte aan vitamine A van <= 2500 internationale eenheden per gram)',
    15041099: 'oliën uit vislevers en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. die met een gehalte aan vitamine A van <= 2500 internationale eenheden per gram; oliën uit de levers van heilbot)',
    15042010: 'vaste fracties van vetten en oliën van vis, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. fracties van oliën uit vislevers)',
    15042090: 'vetten en oliën van vis, alsmede vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. oliën uit vislevers)',
    15043010: 'fracties van vetten en oliën van zeezoogdieren, vast, ook indien geraffineerd, doch chemisch ongewijzigd',
    15043090: 'vetten en oliën van zeezoogdieren, alsmede vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd',
    15050010: 'wolvet, ruw',
    15050090: 'wolvet en daaruit verkregen vetstoffen, lanoline daaronder begrepen (m.u.v. ruw wolvet)',
    15060000: 'dierlijke vetten en oliën, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. vetten en oliën van varkens, gevogelte, runderen, schapen, geiten, vis en zeezoogdieren, alsmede varkensstearine, spekolie, oleostearine, oleomargarine, talkolie, wolvet en daaruit verkregen vetstoffen)',
    15071010: 'sojaolie, ruw, ook indien ontgomd, voor technisch of industrieel gebruik (m.u.v. gebruik voor de vervaardiging van producten voor menselijke consumptie)',
    15071090: 'sojaolie, ruw, ook indien ontgomd (m.u.v. olie voor technisch of industrieel gebruik)',
    15079010: 'sojaolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. gebruik voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe sojaolie)',
    15079090: 'sojaolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. olie voor technisch of industrieel gebruik en m.u.v. ruwe sojaolie)',
    15081010: 'grondnotenolie, ruw, voor technisch of industrieel gebruik (m.u.v. gebruik voor de vervaardiging van producten voor menselijke consumptie)',
    15081090: 'grondnotenolie, ruw (m.u.v. olie voor technisch of industrieel gebruik)',
    15089010: 'grondnotenolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. gebruik voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe grondnotenolie)',
    15089090: 'grondnotenolie en fracties daarvan, geraffineerd, doch chemisch ongewijzigd (m.u.v. olie voor technisch of industrieel gebruik)',
    15091010: 'lampolie',
    15091020: 'olijfolie, van extra vierge kwaliteit, uitsluitend door middel van mechanische of fysische procédés en zonder aantasting van de kwaliteit van de olie uit de vruchten van de olijfboom verkregen, ruw (m.u.v. lampolie)',
    15091080: 'olijfolie, van vierge kwaliteit, uitsluitend door middel van mechanische of fysische procédés en zonder aantasting van de kwaliteit van de olie uit de vruchten van de olijfboom verkregen, ruw (m.u.v. lampolie en olijfolie van extra vierge kwaliteit)',
    15099000: 'olijfolie en fracties daarvan, uitsluitend door middel van mechanische of fysische procédés en zonder aantasting van de kwaliteit van de olie uit de vruchten van de olijfboom verkregen, behandeld, doch chemisch ongewijzigd',
    15100010: 'olie, uitsluitend verkregen uit olijven, ruw, incl. mengsels daarvan met olijfolie of fracties daarvan, zoals bedoeld bij post 1509 (m.u.v. olie verkregen door mechanische of fysische procédés)',
    15100090: 'olie en fracties daarvan, uitsluitend verkregen uit olijven, ook indien geraffineerd, doch chemisch ongewijzigd, incl. mengsels daarvan met olijfolie of fracties daarvan zoals bedoeld bij post 1509 (m.u.v. olijfolie verkregen door mechanische of fysische procédés, ruwe olie)',
    15111010: 'palmolie, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15111090: 'palmolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15119011: 'fracties van palmolie, vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    15119019: 'fracties van palmolie, vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    15119091: 'palmolie en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe palmolie)',
    15119099: 'palmolie en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch en industrieel technisch gebruik en m.u.v. ruwe palmolie)',
    15121110: 'zonnebloemzaad- en saffloerolie, ruw, voor technisch of industrieel technisch gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15121191: 'zonnebloemzaadolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15121199: 'saffloerolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15121910: 'zonnebloemzaad- en saffloerolie, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15121990: 'zonnebloemzaadolie en saffloerolie, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. die voor technisch of industrieel gebruik en m.u.v. ruwe olie)',
    15122110: 'katoenzaadolie, ruw, ook indien ontdaan van gossypol, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15122190: 'katoenzaadolie, ruw, ook indien ontdaan van gossypol (m.u.v. voor technisch of industrieel technisch gebruik)',
    15122910: 'katoenzaadolie en fracties daarvan, ook indien ontdaan van gossypol, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe katoenzaadolie)',
    15122990: 'katoenzaadolie en fracties daarvan, ook indien ontdaan van gossypol, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe katoenzaadolie)',
    15131110: 'kokosolie "kopraolie", ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15131191: 'kokosolie "kopraolie", ruw, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. voor technisch of industrieel technisch gebruik)',
    15131199: 'kokosolie "kopraolie", ruw, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. voor technisch of industrieel technisch gebruik)',
    15131911: 'fracties van kokosolie "kopraolie", vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    15131919: 'fracties van kokosolie "kopraolie", vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    15131930: 'kokosolie "kopraolie" en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe kokosolie)',
    15131991: 'kokosolie "kopraolie" en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe kokosolie)',
    15131999: 'kokosolie "kopraolie" en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe kokosolie)',
    15132110: 'palmpittenolie en babassunotenolie, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15132130: 'palmpittenolie en babassunotenolie, ruw, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. voor technisch of industrieel technisch gebruik)',
    15132190: 'palmpitten- en babassunotenolie, ruw, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die bestemd voor technisch of industrieel-technisch gebruik)',
    15132911: 'fracties van palmpitten- en babassunotenolie, vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    15132919: 'fracties van palmpitten- en babassunotenolie, vast, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    15132930: 'palmpitten- en babassunotenolie en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15132950: 'palmpitten- en babassunotenolie en vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15132990: 'palmpittenolie en babassunotenolie, alsmede vloeibare fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15141110: 'koolzaad- en raapzaadolie, met een laag gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van < 2 gewichtspercenten", voor technisch of industrieel gebruik (m.u.v. die voor de vervaardiging van producten voor menselijke consumptie)',
    15141190: 'koolzaad- en raapzaadolie, met een laag gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van < 2 gewichtspercenten", ruw (m.u.v. die voor technisch of industrieel gebruik)',
    15141910: 'koolzaad- en raapzaadolie, met een laag gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van < 2 gewichtspercenten", alsmede fracties daarvan, ook indien geraffineerd, doch niet chemisch gewijzigd, voor technisch of industrieel gebruik (m.u.v. die voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15141990: 'koolzaad- en raapzaadolie, met een laag gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van < 2 gewichtspercenten", alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. die voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15149110: 'koolzaad- en raapzaadolie, met een hoog gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van >= 2 gewichtspercenten" en mosterdzaadolie, ruw, voor technisch of industrieel gebruik (m.u.v. die voor de vervaardiging van producten voor menselijke consumptie)',
    15149190: 'koolzaad- en raapzaadolie, met een hoog gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van >= 2 gewichtspercenten" en mosterdzaadolie, ruw (m.u.v. die voor technisch of industrieel gebruik)',
    15149910: 'koolzaad- en raapzaadolie, met een hoog gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van >= 2 gewichtspercenten" en mosterdzaadolie, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. die voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15149990: 'koolzaad- en raapzaadolie, met een hoog gehalte aan erucazuur "vaste olie met een gehalte aan erucazuur van >= 2 gewichtspercenten" en mosterdzaadolie, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. die voor technisch of industrieel gebruik en m.u.v. ruwe olie)',
    15151100: 'lijnolie, ruw',
    15151910: 'lijnolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15151990: 'lijnolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15152110: 'maïsolie, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15152190: 'maïsolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15152910: 'maïsolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15152990: 'maïsolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15153010: 'ricinusolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor de vervaardiging van amino-undekaanzuur hetwelk is bestemd voor de vervaardiging van synthetische textielvezels en van kunststof',
    15153090: 'ricinusolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. olie voor de vervaardiging van amino-undekaanzuur hetwelk is bestemd voor de vervaardiging van synthetische textielvezels en van kunststof)',
    15155011: 'sesamolie, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15155019: 'sesamolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15155091: 'sesamolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. ruwe olie)',
    15155099: 'sesamolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15159011: 'tungolie, jojobaolie, oiticicaolie, myricawas en japanwas, alsmede fracties van deze producten, ook indien geraffineerd, doch chemisch ongewijzigd',
    15159021: 'tabakszaadolie, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15159029: 'tabakszaadolie, ruw (m.u.v. voor technisch of industrieel technisch gebruik)',
    15159031: 'tabakszaadolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. ruwe olie)',
    15159039: 'tabakszaadolie en fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe olie)',
    15159040: 'plantaardige vetten en vette oliën, ruw, voor technisch of industrieel gebruik (m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. sojaolie, grondnotenolie, olijfolie, palmolie, babassunotenolie, raapzaad-, koolzaad-, mosterdzaadolie, lijnolie, maïsolie, ricinusolie, tungolie, sesamolie, jojobaolie, oiticicaolie, myricawas, japanwas en tabakszaadolie)',
    15159051: 'plantaardige vetten en vette oliën, ruw, vast, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. sojaolie, grondnotenolie, olijfolie, palmolie, zonnebloempittenolie, saffloerolie, katoenzaadolie, kokosolie "kopraolie", palmpittenolie, babassunotenolie, raapzaad-, koolzaad- en mosterdzaadolie, lijnolie, maïsolie, ricinusolie, tungolie, sesamolie, jojobaolie, oiticicaolie, myricawas, japanwas en tabakszaadolie)',
    15159059: 'plantaardige vetten en vette oliën, ruw, vast of vloeibaar (m.u.v. plantaardige vetten en vette oliën, vast, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg, m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. sojaolie, grondnotenolie, olijfolie, palmolie, zonnebloempittenolie, saffloerolie, katoenzaadolie, kokosolie "kopraolie", palmpittenolie, babassunotenolie, raapzaad-, koolzaad- en mosterdzaadolie, lijnolie, maïsolie, ricinusolie, tungolie, sesamolie, jojobaolie, oiticicaolie, myricawas, japanwas en tabakszaadolie)',
    15159060: 'plantaardige vetten en vette oliën, voor technisch of industrieel gebruik (m.u.v. ruwe oliën, m.u.v. voor de vervaardiging van producten voor menselijke consumptie en m.u.v. sojaolie, grondnotenolie, olijfolie, palmolie, babassunotenolie, raapzaad-, koolzaad-, mosterdzaadolie, lijnolie, maïsolie, ricinusolie, tungolie, sesamolie, jojobaolie, oiticicaolie, myricawas, japanwas en tabakszaadolie)',
    15159091: 'plantaardige vetten en vette oliën, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, vast, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg, n.e.g. (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe vetten en oliën)',
    15159099: 'plantaardige vetten en vette oliën, alsmede fracties daarvan, ook indien geraffineerd, doch chemisch ongewijzigd, vast, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg, of vloeibaar, n.e.g. (m.u.v. voor technisch of industrieel technisch gebruik en m.u.v. ruwe vetten en oliën)',
    15161010: 'dierlijke vetten en oliën, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, ook indien geraffineerd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. vetten en oliën, alsmede fracties daarvan, verder bereid)',
    15161090: 'dierlijke vetten en oliën, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, ook indien geraffineerd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. vetten en oliën, alsmede fracties daarvan, verder bereid)',
    15162010: 'ricinusolie, gehydrogeneerd, zgn. "opal wax"',
    15162091: 'plantaardige vetten en oliën, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, ook indien geraffineerd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. vetten en oliën, alsmede fracties daarvan, verder bereid en m.u.v. ricinusolie, gehydrogeneerd)',
    15162095: 'koolzaad- en raapzaadolie, lijnolie, zonnebloemzaadolie, illipenotenolie, kariténotenolie, makoreolie, touloucounazadenolie en babassunotenolie, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, ook indien geraffineerd, voor technisch of industrieel gebruik, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg of op andere wijze gepresenteerd (m.u.v. die voor de levensmiddelenfabricage)',
    15162096: 'grondnotenolie, katoenzaadolie, sojaolie en zonnebloemzaadolie, alsmede fracties daarvan (m.u.v. die bedoeld bij 1516.20.95); andere oliën alsmede fracties daarvan, met een gehalte aan vrije vetzuren van < 50 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg of op andere wijze gepresenteerd (m.u.v. palmpittenolie, illipenotenolie, kokosolie, koolzaad- en raapzaadolie, kopaivaolie; die bedoeld bij 1516.20.95)',
    15162098: 'plantaardige vetten en oliën, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, ook indien geraffineerd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg of op andere wijze gepresenteerd (m.u.v. vetten en oliën, fracties daarvan, die verder zijn bereid; ricinusolie, gehydrogeneerd; die bedoeld bij 1516.20.95 en 1516.20.96)',
    15171010: 'margarine met een gehalte aan van melk afkomstige vetstoffen van > 10 doch niet meer dan 15 gewichtspercenten (m.u.v. vloeibare margarine)',
    15171090: 'margarine met een gehalte aan van melk afkomstige vetstoffen van <= 10 gewichtspercenten (m.u.v. vloeibare margarine)',
    15179010: 'mengsels en bereidingen, voor menselijke consumptie, van dierlijke of plantaardige vetten of oliën of van fracties van verschillende vetten en oliën, voor menselijke consumptie, met een gehalte aan van melk afkomstige vetstoffen van > 10 doch niet meer dan 15 gewichtspercenten (m.u.v. vetten en oliën, alsmede fracties daarvan, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, "ook indien geraffineerd, doch niet verder bereid", mengsels van olijfolie en fracties daarvan en m.u.v. vaste margarine)',
    15179091: 'mengsels van plantaardige oliën, vloeibaar, voor menselijke consumptie, met een gehalte aan van melk afkomstige vetstoffen van <= 10 gewichtspercenten (m.u.v. oliën, geheel of gedeeltelijk gehydrogeneerd, veresterd, opnieuw veresterd of geëlaïdiniseerd, "ook indien geraffineerd, doch niet verder bereid" en mengsels van olijfolie)',
    15179093: 'mengsels en bereidingen van de soorten gebruikt als preparaten voor het insmeren van bakvormen, met een gehalte aan van melk afkomstige vetstoffen van <= 10 gewichtspercenten',
    15179099: 'mengsels en bereidingen van dierlijke of plantaardige vetten of oliën of van fracties van verschillende vetten en oliën, voor menselijke consumptie, met een gehalte aan van melk afkomstige vetstoffen van <= 10 gewichtspercenten (m.u.v. mengsels van plantaardige oliën, vloeibaar, mengsels en bereidingen van de soorten gebruikt als preparaten voor het insmeren van bakvormen en m.u.v. vaste margarine)',
    15180010: 'linoxyne "geoxideerde lijnolie"',
    15180031: 'mengsels van plantaardige oliën, vloeibaar, niet geschikt voor menselijke consumptie, n.e.g., voor technisch of industrieel gebruik, ruw (m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15180039: 'mengsels van plantaardige oliën, vloeibaar, niet geschikt voor menselijke consumptie, n.e.g., voor technisch of industrieel gebruik (m.u.v. ruwe oliën en m.u.v. voor de vervaardiging van producten voor menselijke consumptie)',
    15180091: 'standolie en andere dierlijke of plantaardige oliën, alsmede fracties daarvan, gekookt, geoxideerd, gedehydreerd, gezwaveld, geblazen of op andere wijze chemisch gewijzigd (m.u.v. die bedoeld bij post 1516; linoxyne "geoxideerde lijnolie")',
    15180095: 'mengsels en bereidingen van dierlijke vetten en oliën of van dierlijke en plantaardige vetten en oliën, alsmede fracties daarvan, ongeschikt voor menselijke consumptie "yellow grease"',
    15180099: 'mengsels en bereidingen van dierlijke of plantaardige vetten of oliën of van fracties van verschillende vetten en oliën bedoeld bij hoofdstuk 15, ongeschikt voor menselijke consumptie, n.e.g.',
    15200000: 'ruwe glycerol, alsmede glycerolwater en glycerollogen',
    15211000: 'plantaardige was, ook indien geraffineerd of gekleurd (m.u.v. triglyceriden)',
    15219010: 'walschot "spermaceti", ook indien geraffineerd of gekleurd',
    15219091: 'bijenwas en was van andere insecten, ruw',
    15219099: 'bijenwas en was van andere insecten, ook indien geraffineerd of gekleurd (m.u.v. was van insecten, ruw)',
    15220010: 'degras "vetten voor de leerlooierij"',
    15220031: 'soapstocks, bevattende olie die de kenmerken van olijfolie heeft',
    15220039: 'afvallen, afkomstig van de behandeling van vetstoffen, bevattende olie die de kenmerken van olijfolie heeft (m.u.v. soapstocks)',
    15220091: 'droesem of bezinksel van olie; soapstocks (m.u.v. droesem en soapstocks die olie bevatten die de kenmerken van olijfolie heeft)',
    15220099: 'afvallen, afkomstig van de behandeling van vetstoffen of van dierlijke of plantaardige was (m.u.v. afvallen die olie bevatten die de kenmerken van olijfolie heeft en m.u.v. droesem of bezinksel van olie en soapstocks)',
    16010010: 'leverworst van alle soorten, incl. bereidingen van deze producten, voor menselijke consumptie',
    16010091: 'gedroogde worst en smeerworst, niet gekookt en niet gebakken, van vlees, van slachtafvallen of van bloed (m.u.v. leverworst)',
    16010099: 'worst van alle soorten, van vlees, van slachtafvallen of van bloed, incl. bereidingen van deze producten, voor menselijke consumptie (m.u.v. leverworst en m.u.v. gedroogde worst en smeerworst, niet gekookt en niet gebakken)',
    16021000: 'fijn gehomogeniseerde bereidingen van vlees, van slachtafvallen of van bloed, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g',
    16022010: 'Bereidingen en conserven van levers van ganzen of van eenden (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g)',
    16022090: 'bereidingen van levers (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers van ganzen of van eenden)',
    16023111: 'bereidingen en conserven van vlees van kalkoenen "pluimvee", bevattende uitsluitend ongekookt en ongebakken vlees van kalkoenen (m.u.v. worst van alle soorten)',
    16023119: 'Bereidingen en conserven van vlees of van slachtafvallen van kalkoenen "pluimvee", bevattende >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee (m.u.v. die welke uitsluitend ongekookt en ongebakken vlees van kalkoenen bevatten; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16023180: 'Bereidingen en conserven van vlees of van slachtafvallen van kalkoenen "pluimvee" (m.u.v. die met >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16023211: 'bereidingen en conserven van vlees of van slachtafvallen, van hanen en van kippen "pluimvee", bevattende >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee, ongekookt en ongebakken (m.u.v. worst van alle soorten en bereidingen van levers)',
    16023219: 'bereidingen en conserven van vlees of van slachtafvallen, van hanen en van kippen "pluimvee", bevattende >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee, gekookt of gebakken (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16023230: 'bereidingen en conserven van vlees of van slachtafvallen, van hanen en van kippen "pluimvee", bevattende >= 25 doch < 57 gewichtspercenten vlees of slachtafvallen van pluimvee (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16023290: 'bereidingen en conserven van vlees of van slachtafvallen, van hanen en van kippen "pluimvee" (m.u.v. die met >= 25 gewichtspercenten vlees of slachtafvallen van pluimvee; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16023921: 'bereidingen en conserven van vlees of van slachtafvallen, van eenden, van ganzen of van parelhoenders "pluimvee", bevattende >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee, ongekookt en ongebakken (m.u.v. worst van alle soorten en bereidingen van levers)',
    16023929: 'bereidingen en conserven van vlees of van slachtafvallen, van eenden, van ganzen of van parelhoenders "pluimvee", bevattende >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee, gekookt of gebakken (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16023985: 'bereidingen en conserven van vlees of van slachtafvallen, van eenden, van ganzen of van parelhoenders "pluimvee" (m.u.v. die met >= 57 gewichtspercenten vlees of slachtafvallen van pluimvee; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16024110: 'bereidingen en conserven van hammen en delen daarvan, van varkens "huisdieren"',
    16024190: 'bereidingen en conserven van hammen en delen daarvan, van varkens (m.u.v. die van varkens "huisdieren")',
    16024210: 'bereidingen en conserven van schouders en delen daarvan, van varkens "huisdieren"',
    16024290: 'bereidingen en conserven van schouders en delen daarvan, van varkens (m.u.v. die van varkens "huisdieren")',
    16024911: 'bereidingen en conserven van karbonadestrengen en delen daarvan, incl. mengsels van karbonadestreng en ham, van varkens "huisdieren" (m.u.v. halskarbonades)',
    16024913: 'bereidingen en conserven van halskarbonades en delen daarvan, incl. mengsels van halskarbonade en schouder, van varkens "huisdieren"',
    16024915: 'bereidingen en conserven van mengsels die ham, schouder, karbonadestreng of halskarbonade alsmede delen daarvan bevatten, van varkens "huisdieren" (m.u.v. mengsels die uitsluitend karbonadestreng en ham of halskarbonade en schouder bevatten)',
    16024919: 'bereidingen en conserven van vlees of van slachtafvallen, van varkens "huisdieren", >= 80 gewichtspercenten vlees of slachtafvallen, ongeacht van welke soort, incl. spek en vet, ongeacht van welke aard of herkomst, bevattend (m.u.v. karbonadestrengen en delen daarvan, halskarbonades en delen daarvan alsmede mengsels van karbonadestreng en ham of van halskarbonade en schouder en mengsels die ham, karbonadestreng of halskarbonade, alsmede delen daarvan bevatten; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16024930: 'bereidingen en conserven van vlees of van slachtafvallen, incl. mengsels, van varkens "huisdieren", >= 40 doch < 80 gewichtspercenten vlees of slachtafvallen, ongeacht van welke soort, incl. spek en vet ongeacht van welke aard of herkomst, bevattend (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16024950: 'bereidingen en conserven van vlees of van slachtafvallen, incl. mengsels, van varkens "huisdieren", < 40 gewichtspercenten vlees of slachtafvallen, ongeacht van welke soort, incl. spek en vet ongeacht van welke aard of herkomst, bevattend (m.u.v. worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16024990: 'bereidingen en conserven van vlees of van slachtafvallen, incl. mengsels, van varkens (m.u.v. die van varkens "huisdieren"; hammen, schouders en delen daarvan; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16025010: 'bereidingen en conserven van vlees of van slachtafvallen, van runderen, ongekookt en ongebakken, incl. mengsels van gekookt of gebakken en ongekookt of ongebakken vlees of slachtafvallen (m.u.v. worst van alle soorten en bereidingen van levers)',
    16025031: 'corned beef in luchtdichte verpakkingen',
    16025095: 'Bereidingen en conserven van vlees of van slachtafvallen, van runderen, gekookt of gebakken (m.u.v. corned beef in luchtdichte verpakkingen; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16029010: 'bereidingen van bloed van dieren van alle soorten (m.u.v. worst van alle soorten)',
    16029031: 'bereidingen en conserven van vlees of van slachtafvallen, van wild of van konijn (m.u.v. die van in het wild levende varkens; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16029051: 'bereidingen en conserven van vlees of van slachtafvallen, die vlees of slachtafvallen van varkens "huisdieren" bevatten (m.u.v. die van pluimvee, van runderen, van rendieren, van wild of van konijn; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten van vlees)',
    16029061: 'bereidingen en conserven van vlees of van slachtafvallen, ongekookt en ongebakken, die vlees of slachtafvallen van runderen bevatten, incl. mengsels van gekookt of gebakken vlees of gekookte of gebakken slachtafvallen en ongekookt en ongebakken vlees of ongekookte en ongebakken slachtafvallen (m.u.v. die van pluimvee, van varkens "huisdieren", van rendieren, van wild of van konijn; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers)',
    16029069: 'bereidingen en conserven van vlees of van slachtafvallen, gekookt of gebakken, die vlees of slachtafvallen van runderen bevatten (m.u.v. die van pluimvee, van varkens "huisdieren", van rendieren, van wild of van konijn; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16029091: 'bereidingen en conserven van vlees of van slachtafvallen, van schapen,  (m.u.v. worst van alle soorten en bereidingen en conserven van levers; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees en vlees of slachtafvallen van runderen of varkens"huisdieren" bevattend)',
    16029095: 'bereidingen en conserven van vlees of van slachtafvallen, van geiten,  (m.u.v. worst van alle soorten en bereidingen en conserven van levers; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; en vlees of slachtafvallen van runderen of varkens "huisdieren" bevattend)',
    16029099: 'Bereidingen en conserven van vlees of van slachtafvallen (m.u.v. die van pluimvee, van varkens, van runderen, van rendieren, van wild, van konijn, van schapen, van geiten; worst van alle soorten; fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g; bereidingen van levers; extracten en sappen van vlees)',
    16030010: 'extracten en sappen van vlees, van vis, van schaaldieren, van weekdieren of van andere ongewervelde waterdieren, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    16030080: 'extracten en sappen van vlees, van vis, van schaaldieren, van weekdieren of van andere ongewervelde waterdieren, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    16041100: 'bereidingen en conserven van zalm, geheel of in stukken (m.u.v. fijngemaakte zalm)',
    16041210: 'haringfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren',
    16041291: 'bereidingen en conserven van haring, geheel of in stukken, in luchtdichte verpakkingen (m.u.v. fijngemaakte vis en filets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren)',
    16041299: 'bereidingen en conserven van haring, geheel of in stukken (m.u.v. fijngemaakte vis, filets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren en vis in luchtdichte verpakkingen)',
    16041311: 'bereidingen en conserven van sardines, geheel of in stukken, in olijfolie (m.u.v. fijngemaakte vis)',
    16041319: 'bereidingen en conserven van sardines, geheel of in stukken (m.u.v. die in olijfolie en m.u.v. fijngemaakte vis)',
    16041390: 'bereidingen en conserven van sardinella\'s en sprot, geheel of in stukken (m.u.v. fijngemaakte vis)',
    16041421: 'bereidingen en conserven van boniet, geheel of in stukken, in plantaardige olie (m.u.v. fijngemaakte bereidingen)',
    16041426: 'bereidingen en conserven van filets "zgn. loins" van boniet, geheel of in stukken (m.u.v. die in plantaardige olie of fijngemaakte vis)',
    16041428: 'bereidingen en conserven van boniet, geheel of in stukken (m.u.v. fijngemaakte bereidingen en conserven; die van filets [zgn. loins]; die in plantaardige olie)',
    16041431: 'bereidingen en conserven van geelvintonijn "Thunnus albacares", geheel of in stukken, in plantaardige olie (m.u.v. fijngemaakte bereidingen)',
    16041436: 'bereidingen en conserven van filets "zgn. loins" van Geelvintonijn "Thunnus albacares" (m.u.v. die in plantaardige olie)',
    16041438: 'bereidingen en conserven van Geelvintonijn "Thunnus albacares", geheel of in stukken (m.u.v. fijngemaakte bereidingen en conserven; die van filets [zgn. loins]; die in plantaardige olie)',
    16041441: 'bereidingen en conserven van tonijn, geheel of in stukken, in plantaardige olie (m.u.v. fijngemaakte bereidingen, boniet en Geelvintonijn "Thunnus albacares")',
    16041446: 'bereidingen en conserven van filets "zgn. loins" van tonijn, geheel of in stukken (m.u.v. die in plantaardige olie,  fijngemaakte bereidingen, boniet en Geelvintonijn "Thunnus albacares")',
    16041448: 'bereidingen en conserven van tonijn, geheel of in stukken (m.u.v. fijngemaakte bereidingen; die van filets [zgn. loins]; die in plantaardige olie, boniet en Geelvintonijn "Thunnus albacares")',
    16041490: 'bereidingen en conserven van bonito "Sarda spp.", geheel of in stukken (m.u.v. fijngemaakte vis)',
    16041511: 'bereidingen en conserven van makreel van de soorten "Scomber scombrus" of "Scomber japonicus", in filets',
    16041519: 'bereidingen en conserven van makreel van de soorten "Scomber scombrus" of "Scomber japonicus", geheel of in stukken (m.u.v. filets en fijngemaakte vis)',
    16041590: 'bereidingen en conserven van makreel van de soort "Scomber australasicus", geheel of in stukken (m.u.v. fijngemaakte vis)',
    16041600: 'bereidingen en conserven van ansjovis, geheel of in stukken (m.u.v. fijngemaakte vis)',
    16041700: 'Bereidingen en conserven van paling of aal, geheel of in stukken doch niet fijngemaakt.',
    16041800: 'Bereidingen en conserven van haaienvinnen, geheel of in stukken, doch niet fijngemaakt',
    16041910: 'bereidingen en conserven van zalmvissen, geheel of in stukken (m.u.v. fijngemaakte vis en m.u.v. zalm)',
    16041931: 'bereidingen en conserven van filets "zgn. loins" van vis van het geslacht "Euthynnus" (m.u.v. die van boniet [Euthynnus Katsuwonus pelamis])',
    16041939: 'bereidingen en conserven van vis van het geslacht "Euthynnus", geheel of in stukken (m.u.v. fijngemaakte bereidingen en conserven; die van filets [zgn. loins]; die van boniet [Euthynnus Katsuwonus pelamis])',
    16041950: 'bereidingen en conserven van vis van de soort "Orcynopsis unicolor", geheel of in stukken (m.u.v. fijngemaakte vis)',
    16041991: 'visfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren (m.u.v. die van zalmvissen, haring, sardines, sardinella\'s, sprot, tonijn, bonito "Sarda spp.", makreel, ansjovis, vis van het geslacht "Euthynnus" en van de soort "Orcynopsis unicolor")',
    16041992: 'bereidingen en conserven van kabeljauw "Gadus morhua, Gadus ogac, Gadus macrocephalus", geheel of in stukken (m.u.v. fijngemaakte vis; kabeljauwfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren)',
    16041993: 'bereidingen en conserven van koolvis "Pollachius virens", geheel of in stukken (m.u.v. fijngemaakte vis; koolvisfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren)',
    16041994: 'bereidingen en conserven van heek "Merluccius spp., Urophycis spp.", geheel of in stukken (m.u.v. fijngemaakte vis; heekfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren)',
    16041995: 'bereidingen en conserven van Alaska koolvis "Theragra chalcogramma" en witte koolvis, pollak of vlaswijting "Pollachius pollachius", geheel of in stukken (m.u.v. fijngemaakte vis; visfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren)',
    16041997: 'Bereidingen en conserven van vis, geheel of in stukken, doch niet fijngemaakt (m.u.v. enkel gerookt, en visfilets, rauw, enkel omgeven door beslag of door paneermeel "gepaneerd", ook indien in olie voorgebakken, bevroren en m.u.v.zalm en zalmvissen, haring, sardines, sadinella\'s, sprot, tonijn, boniet, bonita (Sarda spp.), makreel, ansjovis, paling of aal, haaievinnen, vis van het geslacht "Euthynnus", vis van de soort "Orcynopsis unicolor", kabeljauw, koolvis, heek, Alaskakoolvis, witte koolvis, pollak en  vlaswijting)',
    16042005: 'bereidingen van surimi',
    16042010: 'bereidingen en conserven van zalm (m.u.v. vis, geheel of in stukken)',
    16042030: 'bereidingen en conserven van zalmvissen (m.u.v. vis, geheel of in stukken en m.u.v. zalm)',
    16042040: 'bereidingen en conserven van ansjovis (m.u.v. vis, geheel of in stukken)',
    16042050: 'bereidingen en conserven van sardines, van bonito "Sarda spp.", van makreel van de soorten "Scomber scombrus" en "Scomber japonicus" en van vis van de soort "Orcynopsis unicolor" (m.u.v. vis, geheel of in stukken)',
    16042070: 'bereidingen en conserven van tonijn, van boniet en van andere vis van het geslacht "Euthynnus" (m.u.v. vis, geheel of in stukken)',
    16042090: 'bereidingen en conserven van vis (m.u.v. die van zalmvissen, ansjovis, sardines, bonito "Sarda spp.", makreel van de soorten "Scomber scombrus" en "Scomber japonicus", vis van de soort "Orcynopsis unicolor", tonijn, boniet en andere vis van het geslacht "Euthynnus" en m.u.v. bereidingen van surimi en vis, geheel of in stukken)',
    16043100: 'Kaviaar',
    16043200: 'kaviaarsurrogaten bereid uit kuit',
    16051000: 'Bereidingen en conserven van krab (m.u.v. gerookte)',
    16052110: 'bereidingen en conserven van garnalen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 2 kg (m.u.v. die in luchtdichte verpakkingen en m.u.v. enkel gerookte)',
    16052190: 'bereidingen en conserven van garnalen, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 2 kg (m.u.v. die in luchtdichte verpakkingen en enkel gerookte)',
    16052900: 'Bereidingen en conserven van garnaal, in luchtdichte verpakkingen (m.u.v. gerookte)',
    16053010: 'vlees van zeekreeften, gekookt, bestemd voor de verwerkende industrie voor de vervaardiging van kreeftenboter, kreeftenpasteien, kreeftensoep of kreeftensaus',
    16053090: 'Bereidingen en conserven van zeekreeft (m.u.v enkel gerookte; vlees van zeekreeften, gekookt, bestemd voor de verwerkende industrie voor de vervaardiging van kreeftenboter, kreeftenpasteien, kreeftensoep of kreeftensaus)',
    16054000: 'Bereidingen en conserven van schaaldieren (m.u.v. gerookte en  m.u.v. krab, garnaal en zeekreeft)',
    16055100: 'Bereidingen en conserven van oesters (m.u.v. gerookte)',
    16055200: 'Bereidingen en conserven van jakobsschelpen, wijde mantel daaronder begrepen (m.u.v. gerookte)',
    16055310: 'Bereidingen en conserven van mosselen, in luchtdichte verpakkingen (m.u.v. enkel gerookte)',
    16055390: 'bereidingen en conserven van mosselen (m.u.v. die in luchtdichte verpakkingen en enkel gerookte)',
    16055400: 'Bereidingen en conserven van Inktvissen en pijlinktvissen (m.u.v. gerookte)',
    16055500: 'Bereidingen en conserven van achtarmige inktvissen (m.u.v. gerookte)',
    16055600: 'Bereidingen en conserven van tweekleppigen, kokkels en arkschelpen (m.u.v. gerookte)',
    16055700: 'Bereidingen en conserven van zeeoren (m.u.v. gerookte)',
    16055800: 'Bereidingen en conserven van eetbare slakken, andere dan zeeslakken (m.u.v. gerookte)',
    16055900: 'Bereidingen en conserven van weekdieren (m.u.v. gerookte en m.u.v. oesters, jakobsschelpen, mosselen, inktvissen, pijlinktvissen, achtarmige inktvissen,  tweekleppigen, kokkels, arkschelpen, zeeoren, eetbare slakken, andere dan zeeslakken)',
    16056100: 'Bereidingen en conserven van zeekomkommers (m.u.v. gerookte)',
    16056200: 'Bereidingen en conserven van zee-egels (m.u.v. gerookte)',
    16056300: 'Bereidingen en conserven van kwallen (m.u.v. gerookte)',
    16056900: 'Bereidingen en conserven van ongewervelde waterdieren (m.u.v. gerookte, zeekomkommers, zee-egels en kwallen en m.u.v. schaal- en weekdieren)',
    17011210: 'ruwe beetwortelsuiker, bestemd om te worden geraffineerd (m.u.v. gearomatiseerde suiker en suiker met toegevoegde kleurstoffen)',
    17011290: 'ruwe beetwortelsuiker (m.u.v. gearomatiseerde suiker en suiker met toegevoegde kleurstoffen en m.u.v. suiker, bestemd om te worden geraffineerd)',
    17011310: 'Ruwe rietsuiker, bedoeld bij aanvullende aantekening 2, in vaste vorm, niet gearomatiseerd en zonder toegevoegde kleurstoffen, bestemd om te worden geraffineerd',
    17011390: 'Ruwe rietsuiker, bedoeld bij aanvullende aantekening 2, in vaste vorm, niet gearomatiseerd en zonder toegevoegde kleurstoffen (m.u.v. die bestemd om te worden geraffineerd)',
    17011410: 'Ruwe rietsuiker in vaste vorm,  ander dan bij 1701 13, bestemd om te worden geraffineerd, niet gearomatiseerd en zonder toegevoegde kleurstoffen',
    17011490: 'Ruwe rietsuiker in vaste vorm, ander dan bij 1701 13, niet gearomatiseerd en zonder toegevoegde kleurstoffen (m.u.v. die bestemd om te worden geraffineerd',
    17019100: 'rietsuiker en beetwortelsuiker, in vaste vorm, gearomatiseerd of met toegevoegde kleurstoffen',
    17019910: 'witte suiker, waarvan het aantal gewichtspercenten sacharose, in droge toestand, overeenkomt met een polarisatiegraad van >= 99,5 (m.u.v. gearomatiseerde suiker en suiker met toegevoegde kleurstoffen)',
    17019990: 'rietsuiker en beetwortelsuiker, alsmede chemisch zuivere sacharose, in vaste vorm (m.u.v. rietsuiker en beetwortelsuiker, gearomatiseerd of met toegevoegde kleurstoffen en m.u.v. ruwe suiker en witte suiker)',
    17021100: 'lactose "melksuiker" in vaste vorm en melksuikerstroop zonder toegevoegde kleur- en smaakstoffen, bevattende >= 99 gewichtspercenten lactose, uitgedrukt in kristalwatervrije lactose, berekend op de droge stof',
    17021900: 'lactose "melksuiker" in vaste vorm en melksuikerstroop zonder toegevoegde kleur- en smaakstoffen, bevattende < 99 gewichtspercenten lactose, uitgedrukt in kristalwatervrije lactose, berekend op de droge stof',
    17022010: 'ahornsuiker, in vaste vorm, gearomatiseerd of met toegevoegde kleurstoffen',
    17022090: 'ahornsuiker, in vaste vorm, en ahornsuikerstroop (niet gearomatiseerd en zonder toegevoegde kleurstoffen)',
    17023010: 'isoglucose in vaste vorm, in droge toestand geen of < 20 gewichtspercenten fructose "levulose" bevattend',
    17023050: 'Glucose in wit kristallijn poeder, ook indien geagglomereerd, in droge toestand geen of < 20 gewichtspercenten fructose "levulose" bevattend (m.u.v. isoglucose)',
    17023090: 'Glucose in vaste vorm en glucosestroop zonder toegevoegde kleur- en smaakstoffen, in droge toestand geen of < 20 gewichtspercenten fructose "levulose" bevattend (m.u.v. isoglucose en glucose in wit kristallijn poeder, ook indien geagglomereerd)',
    17024010: 'isoglucose in vaste vorm, in droge toestand >= 20 doch < 50 gewichtspercenten fructose "levulose" bevattend (m.u.v. invertsuiker)',
    17024090: 'glucose in vaste vorm en glucosestroop zonder toegevoegde kleur- of smaakstoffen, in droge toestand >= 20 doch < 50 gewichtspercenten fructose "levulose" bevattend (m.u.v. isoglucose en invertsuiker)',
    17025000: 'chemisch zuivere fructose, in vaste vorm',
    17026010: 'isoglucose in vaste vorm, in droge toestand > 50 gewichtspercenten fructose "levulose" bevattend (m.u.v. chemisch zuivere fructose en invertsuiker)',
    17026080: 'inulinestroop, onmiddellijk na hydrolyse van inuline of oligofructose verkregen, bevattende, in droge toestand, > 50 gewichtspercenten vrije fructose of fructose in de vorm van sacharose',
    17026095: 'fructose "levulose" in vaste vorm en fructosestroop zonder toegevoegde kleur- of smaakstoffen, in droge toestand > 50 gewichtspercenten fructose bevattend (m.u.v. isoglucose, inulinestroop, chemisch zuivere fructose en invertsuiker)',
    17029010: 'chemisch zuivere maltose, in vaste vorm',
    17029030: 'isoglucose in vaste vorm, in droge toestand 50 gewichtspercenten fructose bevattend, verkregen uit glucosepolymeren',
    17029050: 'maltodextrine, in vaste vorm, en maltodextrinestroop (niet gearomatiseerd en zonder toegevoegde kleurstoffen)',
    17029071: 'karamel bevattende, in droge toestand, >= 50 gewichtspercenten sacharose',
    17029075: 'karamel bevattende, in droge toestand, < 50 gewichtspercenten sacharose, in poeder, ook indien geagglomereerd',
    17029079: 'karamel bevattende, in droge toestand, < 50 gewichtspercenten sacharose (m.u.v. karamel in poeder, ook indien geagglomereerd)',
    17029080: 'inulinestroop, onmiddellijk na hydrolyse van inuline of oligofructose verkregen, bevattende, in droge toestand, >= 10 doch <= 50 gewichtspercenten vrije fructose of fructose in de vorm van sacharose',
    17029095: 'Suiker, daaronder begrepen invertsuiker, in vaste vorm en suiker en suikerstroop, in droge toestand 50 gewichtspercenten fructose bevattend, zonder toegevoegde kleur- en smaakstoffen (m.u.v. rietsuiker, beetwortelsuiker, sacharose, maltose, chemisch zuiver, lactose \'melksuiker\', ahornsuiker, glucose, fructose "levulose" en maltodextrine, alsmede stroop daarvan, isoglucose, inulinestroop en karamel)',
    17031000: 'melasse van rietsuiker verkregen bij de extractie of de raffinage van suiker',
    17039000: 'melasse van beetwortelsuiker verkregen bij de extractie of de raffinage van suiker',
    17041010: 'Kauwgom, ook indien bedekt met een laagje suiker, met een sacharosegehalte van < 60 gewichtsprocenten, incl. het gehalte aan invertsuiker, berekend als sacharose',
    17041090: 'Kauwgom, ook indien bedekt met een laagje suiker, met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, van >= 60 gewichtspercenten',
    17049010: 'zoethoutextract "drop", bevattende > 10 gewichtspercenten sacharose (m.u.v. die met andere toegevoegde stoffen)',
    17049030: 'witte chocolade',
    17049051: 'pasta\'s en spijs, incl. marsepein, in onmiddellijke verpakking met een netto-inhoud van >= 1 kg',
    17049055: 'keelpastilles en hoestbonbons',
    17049061: 'dragees e.d. met een suikerlaag omhulde artikelen, cacaoloos',
    17049065: 'gom- en geleiproducten, incl. vruchtenpasta\'s toebereid als suikergoed',
    17049071: 'zuurtjes e.d. hardgekookt suikerwerk, ook indien gevuld',
    17049075: 'karamels, toffees e.d.',
    17049081: 'cacaoloos suikerwerk, verkregen door samenpersing, ook indien vervaardigd met bindmiddelen (m.u.v. kauwgom; witte chocolade; keelpastilles, hoestbonbons; gom- en geleiproducten, incl. vruchtenpasta\'s toebereid als suikergoed; zuurtjes e.d. hardgekookt suikerwerk, ook indien gevuld; karamels, toffees e.d.; marsepein in onmiddellijke verpakking van >= 1 kg)',
    17049099: 'fondants, marsepein, noga en ander cacaoloos suikerwerk (m.u.v. kauwgom, witte chocolade, keelpastilles en hoestbonbons, gom- en geleiproducten, incl. vruchtenpasta\'s toebereid als suikergoed, zuurtjes e.d. hardgekookt suikerwerk, ook indien gevuld, karamels, toffees e.d. en suikerwerk verkregen door samenpersing en m.u.v. marsepein in onmiddellijke verpakking met een netto-inhoud van >= 1 kg)',
    18010000: 'cacaobonen, ook indien gebroken of gebrand',
    18020000: 'cacaodoppen, cacaoschillen, cacaovliezen en andere afvallen van cacao',
    18031000: 'cacaopasta (m.u.v. ontvette cacaopasta)',
    18032000: 'cacaopasta, geheel of gedeeltelijk ontvet',
    18040000: 'cacaoboter, cacaovet en cacao-olie',
    18050000: 'cacaopoeder (m.u.v. die met toegevoegde suiker of met andere zoetstoffen)',
    18061015: 'cacaopoeder waaraan suiker of andere zoetstoffen zijn toegevoegd, geen sacharose bevattend of met een sacharosegehalte, incl. het als sacharose berekende gehalte aan invertsuiker of een als sacharose berekend isoglucosegehalte van < 5 gewichtspercenten',
    18061020: 'cacaopoeder waaraan suiker of andere zoetstoffen zijn toegevoegd, met een sacharosegehalte, incl. het als sacharose berekende gehalte aan invertsuiker of een als sacharose berekend isoglucosegehalte van >= 5 gewichtspercenten, doch < 65 gewichtspercenten',
    18061030: 'cacaopoeder waaraan suiker of andere zoetstoffen zijn toegevoegd, geen sacharose bevattend of met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, of met een isoglucosegehalte, berekend als sacharose, van >= 65 gewichtspercenten, doch < 80 gewichtspercenten',
    18061090: 'cacaopoeder waaraan suiker of andere zoetstoffen zijn toegevoegd, geen sacharose bevattend of met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, of met een isoglucosegehalte, berekend als sacharose, van >= 80 gewichtspercenten',
    18062010: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, hetzij in blokken of in staven, met een gewicht van > 2 kg, hetzij vloeibaar of als pasta, poeder, korrels o.d., in verpakkingen met een inhoud per onmiddellijke verpakking van > 2 kg, met een gehalte aan cacaoboter van >= 31 gewichtspercenten of met een totaalgehalte aan cacaoboter en van melk afkomstige vetstoffen van >= 31 gewichtspercenten (m.u.v. cacaopoeder)',
    18062030: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, hetzij in blokken of in staven, met een gewicht van > 2 kg, hetzij in vloeibare toestand of in de vorm van pasta, poeder, korrels of dergelijke, in verpakkingen met een inhoud per onmiddellijke verpakking van > 2 kg, met een totaalgehalte aan cacaoboter en van melk afkomstige vetstoffen van >= 25, doch < 31 gewichtspercenten (m.u.v. cacaopoeder)',
    18062050: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, hetzij in blokken of in staven, met een gewicht van > 2 kg, hetzij in vloeibare toestand of in de vorm van pasta, poeder, korrels of dergelijke, in recipiënten of in andere verpakkingen met een inhoud per onmiddellijke verpakking van > 2 kg, met een gehalte aan cacaoboter van >= 18, doch < 31 gewichtspercenten (m.u.v. cacaopoeder)',
    18062070: 'zgn. "chocolate milk crumb", in recipiënten of in andere verpakkingen, met een inhoud per onmiddellijke verpakking van > 2 kg',
    18062080: 'cacaofantasie, in recipiënten of in andere verpakkingen met een inhoud per onmiddellijke verpakking van > 2 kg',
    18062095: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, hetzij in blokken of in staven, met een gewicht van > 2 kg, hetzij in vloeibare toestand of in de vorm van pasta, poeder, korrels o.d., in recipiënten of in andere verpakkingen met een inhoud per onmiddellijke verpakking van > 2 kg, met een gehalte aan cacaoboter van < 18 gewichtspercenten (m.u.v. zgn. "chocolate milk crumb", cacaofantasie en cacaopoeder)',
    18063100: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, in de vorm van tabletten, staven of repen, met een gewicht van <= 2 kg, gevuld',
    18063210: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, in de vorm van tabletten, staven of repen, met een gewicht van <= 2 kg, met toegevoegde granen, noten of andere vruchten, ongevuld',
    18063290: 'chocolade en andere bereidingen voor menselijke consumptie die cacao bevatten, in de vorm van tabletten, staven of repen, met een gewicht van <= 2 kg, ongevuld (m.u.v. die met toegevoegde granen, noten of andere vruchten)',
    18069011: 'Chocolade en chocoladewerken in de vorm van bonbons of pralines,ook indien gevuld, alcohol bevattend',
    18069019: 'Chocolade en chocoladewerken in de vorm van bonbons of pralines, ook indien gevuld (m.u.v. die welke alcohol bevatten)',
    18069031: 'chocolade en chocoladewerken, gevuld (m.u.v. die in de vorm van tabletten, staven of repen en m.u.v. bonbons of pralines)',
    18069039: 'Chocolade en chocoladewerken, ongevuld (m.u.v. die in de vorm van tabletten, staven of repen en m.u.v. bonbons of pralines)',
    18069050: 'suikerwerken en overeenkomstige bereidingen op basis van suiker vervangende stoffen, die cacao bevatten',
    18069060: 'boterhampasta die cacao bevat',
    18069070: 'bereidingen voor dranken, die cacao bevatten',
    18069090: 'bereidingen voor menselijke consumptie, die cacao bevatten, in recipiënten of in andere verpakkingen, met een inhoud per onmiddellijke verpakking van <= 2 kg (m.u.v. chocolade, bonbons of pralines en andere chocoladewerken, suikerwerk dat cacao bevat, boterhampasta, bereidingen voor dranken en cacaopoeder)',
    19011000: 'bereidingen van meel, gort, gries, griesmeel, zetmeel of moutextract, geen of < 40 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, n.e.g.; bereidingen van melk, room, karnemelk, gestremde melk en room, wei, yoghurt, kefir en dergelijke producten bedoeld bij de posten 0401 tot en met 0404, geen of < 5 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, n.e.g., voor de voeding van kinderen, opgemaakt voor de verkoop in het klein',
    19012000: 'mengsels en deeg van meel, gort, gries, griesmeel, zetmeel of moutextract, geen of < 40 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, n.e.g.; mengsels en deeg van melk, room, karnemelk, gestremde melk en room, wei, yoghurt, kefir en dergelijke producten bedoeld bij de posten 0401 tot en met 0404, geen of < 5 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, n.e.g., voor de bereiding van bakkerswaren bedoeld bij post 1905',
    19019011: 'moutextract met een gehalte aan droge stof van >= 90 gewichtspercenten',
    19019019: 'moutextract met een gehalte aan droge stof van < 90 gewichtspercenten',
    19019091: 'bereidingen voor menselijke consumptie van meel, gort, gries, griesmeel, zetmeel of moutextract, geen of < 1,5 gewichtspercenten van melk afkomstige vetstoffen bevattend, geen of < 5 gewichtspercenten sacharose "incl. het gehalte aan invertsuiker", isoglucose, glucose of zetmeel bevattend en geen of < 40 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis (m.u.v. die voor de voeding van kinderen, opgemaakt voor de verkoop in het klein, en die in de vorm van poeder van melk, room, karnemelk, gestremde melk en room, wei, yoghurt, kefir en dergelijke producten bedoeld bij de posten 0401 t/m 0404 en m.u.v. mengsels en deeg, voor de bereiding van bakkerswaren, en moutextract)',
    19019095: 'bereidingen voor menselijke consumptie in poeder, bestaande uit een mengsel van magere melk en/of wei en plantaardige vetten/oliën dat niet meer dan 30 gewichtspercenten vetten/oliën bevat',
    19019099: 'bereidingen voor menselijke consumptie van meel, gort, gries, griesmeel, zetmeel of moutextract, geen of < 40 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, en bereidingen voor menselijke consumptie van melk, room, karnemelk, gestremde melk en room, wei, yoghurt, kefir en dergelijke producten bedoeld bij 0401 t/m 0404, geen of < 5 gewichtspercenten cacao bevattend, berekend op een geheel ontvette basis, n.e.g. (m.u.v. die voor de voeding van kinderen, opgemaakt voor de verkoop in het klein en m.u.v. moutextract, mengsels en deeg, voor de bereiding van bakkerswaren, en goederen bedoeld bij onderverdeling 1901.90.91)',
    19021100: 'deegwaren waarin ei is verwerkt (m.u.v. gekookte, gevulde of op andere wijze bereide deegwaren)',
    19021910: 'deegwaren zonder ei, zonder gries, griesmeel of meel van zachte tarwe (m.u.v. gekookte, gevulde of op andere wijze bereide deegwaren)',
    19021990: 'deegwaren zonder ei, doch meel, gries of griesmeel van zachte tarwe bevattend (m.u.v. gekookte, gevulde of op andere wijze bereide deegwaren)',
    19022010: 'met vlees of andere zelfstandigheden gevulde deegwaren, ook indien gekookt of op andere wijze bereid, bevattende > 20 gewichtspercenten vis, schaal- of weekdieren of andere ongewervelde waterdieren',
    19022030: 'met vlees of andere zelfstandigheden gevulde deegwaren, ook indien gekookt of op andere wijze bereid, bevattende > 20 gewichtspercenten worst, vlees of slachtafvallen van alle soorten, incl. vet van alle soorten of oorsprong',
    19022091: 'met vlees of andere zelfstandigheden gevulde deegwaren, gekookt (m.u.v. deegwaren bevattende > 20 gewichtspercenten vis, schaal- of weekdieren of andere ongewervelde waterdieren of > 20 gewichtspercenten worst, vlees of slachtafvallen van alle soorten, incl. vet van alle soorten of oorsprong)',
    19022099: 'met vlees of andere zelfstandigheden gevulde deegwaren, ook op andere wijze bereid (m.u.v. gekookte of gebakken deegwaren en m.u.v. deegwaren bevattende > 20 gewichtspercenten vis, schaal- of weekdieren of andere ongewervelde waterdieren of > 20 gewichtspercenten worst, vlees of slachtafvallen van alle soorten, incl. vet van alle soorten of oorsprong)',
    19023010: 'gedroogde deegwaren (m.u.v. gevulde deegwaren)',
    19023090: 'deegwaren, gekookt of andere wijze bereid (m.u.v. gevulde of gedroogde deegwaren)',
    19024010: 'koeskoes (m.u.v. bereide koeskoes)',
    19024090: 'koeskoes, gekookt of op andere wijze bereid',
    19030000: 'vlokken, korrels, parels e.d.',
    19041010: 'maïspreparaten verkregen door poffen of door roosteren',
    19041030: 'rijstpreparaten verkregen door poffen of door roosteren',
    19041090: 'graanpreparaten verkregen door poffen of door roosteren (m.u.v. die op basis van maïs of rijst)',
    19042010: 'bereidingen van de soort "Müsli", op basis van niet-geroosterde graanvlokken',
    19042091: 'bereidingen voor menselijke consumptie verkregen uit ongeroosterde graanvlokken of uit mengsels van ongeroosterde graanvlokken en geroosterde graanvlokken of gepofte granen, op basis van maïs (m.u.v. die van de soort "Müsli", op basis van niet-geroosterde graanvlokken)',
    19042095: 'bereidingen voor menselijke consumptie verkregen uit ongeroosterde graanvlokken of uit mengsels van ongeroosterde graanvlokken en geroosterde graanvlokken of gepofte granen, op basis van rijst (m.u.v. die van de soort "Müsli", op basis van niet-geroosterde graanvlokken)',
    19042099: 'bereidingen voor menselijke consumptie verkregen uit ongeroosterde graanvlokken of uit mengsels van ongeroosterde graanvlokken en geroosterde graanvlokken of gepofte granen (m.u.v. die op basis van maïs of rijst en die van de soort "Müsli", op basis van niet-geroosterde graanvlokken)',
    19043000: 'bulgur tarwe, in de vorm van bewerkte korrels, verkregen door koken van harde-tarwekorrels',
    19049010: 'rijst, voorgekookt of op andere wijze bereid, n.e.g. (m.u.v. meel, gort, gries en griesmeel, preparaten verkregen door poffen of roosteren, bereidingen voor menselijke consumptie verkregen uit ongeroosterde graanvlokken en bereidingen voor menselijke consumptie verkregen uit mengsels van ongeroosterde graanvlokken en geroosterde graanvlokken of gepofte granen)',
    19049080: 'granen, in de vorm van korrels of in de vorm van vlokken of van andere bewerkte korrels, voorgekookt of op andere wijze bereid, n.e.g. (m.u.v. rijst en maïs, meel, gort, gries en griesmeel, preparaten verkregen door poffen of roosteren, bereidingen voor menselijke consumptie verkregen uit ongeroosterde graanvlokken en bereidingen voor menselijke consumptie verkregen uit mengsels van ongeroosterde graanvlokken en geroosterde graanvlokken of gepofte granen, en bulgur tarwe)',
    19051000: 'bros gebakken brood, zgn. "knäckebröd"',
    19052010: 'ontbijtkoek, ook indien deze cacao bevat, met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, van < 30 gewichtspercenten',
    19052030: 'ontbijtkoek, ook indien deze cacao bevat, met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, van >= 30, doch < 50 gewichtspercenten',
    19052090: 'ontbijtkoek, ook indien deze cacao bevat, met een sacharosegehalte, incl. het gehalte aan invertsuiker, berekend als sacharose, van >= 50 gewichtspercenten',
    19053111: 'koekjes en biscuits, gezoet, ook indien cacao bevattend, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten, in een onmiddellijke verpakking met een netto-inhoud van <= 85 g',
    19053119: 'koekjes en biscuits, gezoet, ook indien cacao bevattend, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten, in een onmiddellijke verpakking met een netto-inhoud van > 85 g',
    19053130: 'koekjes en biscuits, gezoet, ook indien cacao bevattend, met een gehalte aan van melk afkomstige vetstoffen van >= 8 gewichtspercenten (m.u.v. koekjes en biscuits, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten)',
    19053191: 'dubbele koekjes of biscuits, gezoet, met tussenlaag, ook indien cacao bevattend, met een gehalte aan van melk afkomstige vetstoffen van < 8 gewichtspercenten (m.u.v. koekjes en biscuits, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten)',
    19053199: 'koekjes en biscuits, gezoet, ook indien cacao bevattend, met een gehalte aan van melk afkomstige vetstoffen van < 8 gewichtspercenten (m.u.v. koekjes en biscuits, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten, en dubbele koekjes of biscuits met tussenlaag)',
    19053205: 'wafels en wafeltjes met een watergehalte van > 10 gewichtspercenten',
    19053211: 'wafels en wafeltjes, ook indien cacao bevattend, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten, in een onmiddellijke verpakking met een netto-inhoud van <= 85 g (m.u.v. die met een watergehalte van > 10 gewichtspercenten)',
    19053219: 'wafels en wafeltjes, ook indien cacao bevattend, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten (m.u.v. die in een onmiddellijke verpakking met een netto-inhoud van <= 85 g en m.u.v. van wafels en wafeltjes met een watergehalte van > 10 gewichtspercenten)',
    19053291: 'wafels en wafeltjes, gezouten, ook indien gevuld (m.u.v. wafels en wafeltjes met een watergehalte van > 10 gewichtspercenten)',
    19053299: 'wafels en wafeltjes, ook indien deze producten cacao bevatten, ook indien gevuld (m.u.v. wafels en wafeltjes, geheel of gedeeltelijk bedekt met chocolade of met andere bereidingen die cacao bevatten, en die met een watergehalte van > 10 gewichtspercenten)',
    19054010: 'beschuit',
    19054090: 'geroosterd brood e.d. geroosterde producten (m.u.v. beschuit)',
    19059010: 'matzes',
    19059020: 'ouwel in bladen, hosties, ouwels voor geneesmiddelen, plakouwels e.d. producten, van meel of van zetmeel',
    19059030: 'brood zonder toegevoegde honig, eieren, kaas of vruchten, met een gehalte aan suikers en aan vetstoffen van elk <= 5 gewichtspercenten, berekend op de droge stof',
    19059045: 'koekjes en biscuits, ongezoet',
    19059055: 'geëxtrudeerde en geëxpandeerde producten, gezouten of gearomatiseerd (m.u.v. "knäckebröd", beschuit, geroosterd brood e.d. geroosterde producten)',
    19059070: 'Fruittaart, krenten- en rozijnenbrood, panettone, meringues, kerststollen, croissants en andere bakkerswaren, met een gehalte van 5 of meer gewichtspercenten aan sacharose, invertsuiker of isoglucose (m.u.v. bros gebakken brood, zgn. "knäckebröd", ontbijtkoek, koekjes en biscuits, wafels en wafeltjes en beschuit)',
    19059080: 'Pizza\'s, quiches en andere bakkerswaren, met een gehalte van 5 of minder gewichtspercenten aan sacharose, invertsuiker of isoglucose (m.u.v. bros gebakken brood, zgn. "knäckebröd", koekjes en biscuits, wafels en wafeltjes, beschuit en vergelijkbare geroosterde producten, brood, ouwel in bladen, hosties, ouwels voor geneesmiddelen, plakouwels e.d. producten, van meel of van zetmeel)',
    20011000: 'komkommers en augurken, bereid of verduurzaamd in azijn of in azijnzuur',
    20019010: 'mangochutney, bereid of verduurzaamd in azijn of in azijnzuur',
    20019020: 'scherpsmakende vruchten van het geslacht "Capsicum", bereid of verduurzaamd in azijn of in azijnzuur',
    20019030: 'suikermaïs "Zea mays var. saccharata", bereid of verduurzaamd in azijn of in azijnzuur',
    20019040: 'broodwortelen, zoete aardappelen "bataten" e.d. eetbare plantendelen met een zetmeelgehalte van >= 5 gewichtspercenten, bereid of verduurzaamd in azijn of in azijnzuur',
    20019050: 'paddestoelen, bereid of verduurzaamd in azijn of in azijnzuur',
    20019065: 'olijven, bereid of verduurzaamd in azijn of in azijnzuur',
    20019070: 'niet-scherpsmakende pepers, bereid of verduurzaamd in azijn of in azijnzuur',
    20019092: 'Guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten en palmharten, bereid of verduurzaamd in azijn of in azijnzuur',
    20019097: 'Groenten, vruchten en andere eetbare plantendelen, bereid of verduurzaamd in azijn of in azijnzuur (m.u.v. komkommers, augurken; mangochutney; scherpsmakende vruchten "Capsicum" andere dan niet-scherpsmakende pepers; suikermaïs; broodwortelen, bataten e.d. eetbare plantendelen met een zetmeelgehalte van >= 5 gewichtspercenten; paddestoelen; palmharten; olijven; niet-scherpsmakende pepers; guaves, manga\'s, manggistans; papaja\'s; tamarindevruchten, cashewappelen, lychees; nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten)',
    20021010: 'gepelde tomaten (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), geheel of in stukken',
    20021090: 'ongepelde tomaten (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), geheel of in stukken',
    20029011: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van < 12 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20029019: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van < 12 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20029031: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van >= 12 doch <= 30 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20029039: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van >= 12 doch <= 30 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20029091: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van > 30 gewichtspercenten, in verpakkingen met een netto inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20029099: 'tomaten, bereid of verduurzaamd zonder azijn of azijnzuur, met een gehalte aan droge stof van > 30 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. tomaten, geheel of in stukken)',
    20031020: 'paddestoelen van het geslacht "Agaricus", zonder azijn of azijnzuur voorlopig verduurzaamd, volledig gekookt',
    20031030: 'paddestoelen van het geslacht "Agaricus", bereid of verduurzaamd zonder azijn of azijnzuur (m.u.v. die welke volledig gekookt, doch slechts voorlopig verduurzaamd zijn)',
    20039010: 'Truffels op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur',
    20039090: 'Paddenstoelen (m.u.v. die van het geslacht "Agaricus") op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur',
    20041010: 'aardappelen, enkel gekookt of gebakken, bevroren',
    20041091: 'aardappelen, bereid of verduurzaamd in de vorm van meel, gries, griesmeel of vlokken, bevroren',
    20041099: 'aardappelen (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), bevroren (m.u.v. enkel gekookt of gebakken aardappelen en aardappelen in de vorm van meel, gries, griesmeel of vlokken)',
    20049010: 'suikermaïs "Zea mays var. saccharata" (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), bevroren',
    20049030: 'zuurkool, kappers en olijven (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), bevroren',
    20049050: 'erwten "Pisum sativum" en bonen in de dop "Phaseolus spp." (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur), bevroren',
    20049091: 'uien, enkel gekookt of gebakken, bevroren',
    20049098: 'groenten en mengsels van groenten, bereid of verduurzaamd zonder azijn of azijnzuur, bevroren (niet gekonfijt met suiker en m.u.v. tomaten, paddestoelen, truffels, aardappelen, suikermaïs "Zea mays var. saccharata", zuurkool, kappers, olijven, erwten "Pisum sativum", bonen in de dop "Phaseolus spp." en enkel gekookt of gebakken uien, niet gemengd)',
    20051000: 'groenten in de vorm van fijn gehomogeniseerde bereidingen, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g (m.u.v. tomaten, paddestoelen en truffels)',
    20052010: 'aardappelen in de vorm van meel, gries, griesmeel of vlokken (niet bevroren)',
    20052020: 'aardappelen in schijfjes, gebakken, ook indien gezouten of gearomatiseerd, luchtdicht verpakt, geschikt voor onmiddellijk verbruik (niet bevroren)',
    20052080: 'aardappelen, bereid of verduurzaamd zonder azijn of azijnzuur (niet bevroren en m.u.v. aardappelen in de vorm van meel, gries, griesmeel of vlokken of die in schijfjes, gebakken, ook indien gezouten of gearomatiseerd, luchtdicht verpakt, geschikt voor onmiddellijk verbruik)',
    20054000: 'erwten "Pisum sativum" (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20055100: 'bonen "Vigna spp., Phaseolus spp.", gedopt (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20055900: 'bonen "Vigna spp., Phaseolus spp.", ongedopt (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20056000: 'asperges (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20057000: 'Olijven, bereid of verduurzaamd zonder azijn of azijnzuur, niet bevroren',
    20058000: 'suikermaïs "Zea mays var. saccharata" (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20059100: 'bamboescheuten (op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren)',
    20059910: 'scherpsmakende vruchten van het geslacht "Capsicum", op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur, niet bevroren',
    20059920: 'kappers, op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren',
    20059930: 'artisjokken, op andere wijze bereid of verduurzaamd dan in azijn of azijnzuur en niet bevroren',
    20059950: 'mengsels van groenten, bereid of verduurzaamd zonder azijn of azijnzuur, niet bevroren',
    20059960: 'zuurkool (m.u.v. bevroren zuurkool)',
    20059980: 'groenten, bereid of verduurzaamd zonder azijn of azijnzuur, niet bevroren en niet gekonfijt met suiker (m.u.v. gehomogeniseerde groenten als bedoeld bij onderverdeling 2005.10.00, tomaten, paddestoelen, truffels, aardappelen, zuurkool, erwten "Pisum sativum", bonen "Vigna spp., Phaseolus spp.", asperges, olijven, suikermaïs "Zea mays var. saccharata", bamboescheuten, scherpsmakende vruchten van het geslacht "Capsicum", kappers, artisjokken en mengsels van groenten)',
    20060010: 'gember, gekonfijt met suiker, uitgedropen, geglaceerd of uitgekristalliseerd',
    20060031: 'kersen, gekonfijt met suiker, uitgedropen, geglaceerd of uitgekristalliseerd, met een suikergehalte van > 13 gewichtspercenten',
    20060035: 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, geconfijt met suiker, uitgedropen, geglaceerd of uitgekristalliseerd, met een suikergehalte van > 13 gewichtspercenten',
    20060038: 'groenten, vruchten, vruchtenschillen en andere eetbare plantendelen, gekonfijt met suiker "uitgedropen, geglaceerd of uitgekristalliseerd", met een suikergehalte van > 13 gewichtspercenten (m.u.v. kersen; gember; guaves, manga\'s, manggistans; papaja\'s; tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten)',
    20060091: 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, geconfijt met suiker, uitgedropen, geglaceerd of uitgekristalliseerd, met een suikergehalte van <= 13 gewichtspercenten',
    20060099: 'groenten, vruchten, vruchtenschillen en andere eetbare plantendelen, gekonfijt met suiker "uitgedropen, geglaceerd of uitgekristalliseerd", met een suikergehalte van <= 13 gewichtspercenten (m.u.v. gember; guaves, manga\'s, manggistans; papaja\'s; tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten)',
    20071010: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen, in de vorm van fijn gehomogeniseerde vruchten, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g, met een suikergehalte van > 13 gewichtspercenten',
    20071091: 'jam, gelei, marmelade, moes en pasta, van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, door koken of stoven verkregen, ook indien met toegevoegde suiker of andere zoetstoffen, in de vorm van fijn gehomogeniseerde vruchten, opgemaakt voor de verkoop in het klein als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20071099: 'jam, vruchtengelei, marmelade, vruchtenmoes en -pasta, door koken of stoven verkregen, ook indien met toegevoegde suiker of andere zoetstoffen, in de vorm van fijn gehomogeniseerde vruchten, opgemaakt voor de verkoop in het klein als kinder- of dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g (m.u.v. die met een suikergehalte van > 13 gewichtspercenten en die van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s)',
    20079110: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van citrusvruchten, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079130: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van citrusvruchten, door koken of stoven verkregen, met een suikergehalte van > 13 doch <= 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079190: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van citrusvruchten, door koken of stoven verkregen, ook indien met toegevoegde suiker of andere zoetstoffen (m.u.v. die met een suikergehalte van > 13 gewichtspercenten en m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079910: 'pruimenmoes en pruimenpasta, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 100 kg en bestemd voor industriële verwerking',
    20079920: 'kastanjepasta "crème de marrons", door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079931: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van kersen, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079933: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van aardbeien, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079935: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, van frambozen, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079939: 'jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen, met een suikergehalte van > 30 gewichtspercenten (m.u.v. die van frambozen, aardbeien, kersen en citrusvruchten en m.u.v. kastanjepasta "crème de marrons", gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10, en pruimenmoes en pruimenpasta, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 100 kg en bestemd voor industriële verwerking)',
    20079950: 'Jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen, met een suikergehalte van > 13 doch <= 30 gewichtspercenten (m.u.v. die van citrusvruchten en gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10)',
    20079993: 'jam, vruchtengelei, marmelade, vruchtenmoes en -pasta, van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, door koken of stoven verkregen, ook indien met toegevoegde suiker of andere zoetstoffen (m.u.v. die met > 13 gewichtspercenten suiker en m.u.v. gehomogeniseerde bereidingen bedoeld bij 2007.10)',
    20079997: 'Jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen, ook indien met toegevoegde suiker of andere zoetstoffen (m.u.v. die met een suikergehalte van > 13 gewichtspercenten, gehomogeniseerde bereidingen als bedoeld bij onderverdeling 2007.10 en die van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, lychees, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s, pitahaya\'s, kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten en citrusvruchten)',
    20081110: 'pindakaas',
    20081191: 'Grondnoten, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. gebrande grondnoten; die gekonfijt met suiker; pindakaas)',
    20081196: 'grondnoten, gebrand, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20081198: 'grondnoten, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. gebrande grondnoten; die gekonfijt met suiker; pindakaas)',
    20081912: 'kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, incl. mengsels hiervan met een gehalte aan noten van >= 50 gewichtspercenten, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die gekonfijt met suiker)',
    20081913: 'amandelen en pimpernoten "pistaches", gebrand, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20081919: 'noten en andere zaden, incl. mengsels, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die bereid of verduurzaamd in azijn of azijnzuur; die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; grondnoten; gebrande amandelen en pimpernoten "pistaches"; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, incl. mengsels daarvan met een gehalte aan tropische noten en tropische vruchten van >= 50 gewichtspercenten)',
    20081992: 'kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, incl. mengsels hiervan met een gehalte aan noten van >= 50 gewichtspercenten, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20081993: 'amandelen en pimpernoten "pistaches", gebrand, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20081995: 'noten, gebrand, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. grondnoten; amandelen en pimpernoten "pistaches"; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten, Australische noten)',
    20081999: 'noten en andere zaden, incl. mengsels, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die bereid of verduurzaamd in azijn of azijnzuur; die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; grondnoten; gebrande noten; kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten, incl. mengsels met een gehalte aan deze tropische noten en tropische vruchten van >= 50 gewichtspercenten)',
    20082011: 'ananas, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 17 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20082019: 'ananas, bereid of verduurzaamd, met toegevoegde alcohol, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 17 gewichtspercenten)',
    20082031: 'ananas, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 19 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20082039: 'ananas, bereid of verduurzaamd, met toegevoegde alcohol, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 19 gewichtspercenten)',
    20082051: 'ananas, bereid of verduurzaamd, alcoholvrij, maar met toegevoegde suiker, met een suikergehalte van > 17 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20082059: 'ananas, bereid of verduurzaamd, alcoholvrij, maar met toegevoegde suiker, met een suikergehalte van > 13, doch <= 17 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20082071: 'ananas, bereid of verduurzaamd, alcoholvrij, maar met toegevoegde suiker, met een suikergehalte van > 19 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20082079: 'ananas, bereid of verduurzaamd, alcoholvrij, maar met toegevoegde suiker, met een suikergehalte van > 13, doch <= 19 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20082090: 'ananas, bereid of verduurzaamd (m.u.v. ananas met toegevoegde alcohol of toegevoegde suiker)',
    20083011: 'citrusvruchten, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas',
    20083019: 'citrusvruchten, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas',
    20083031: 'citrusvruchten, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20083039: 'citrusvruchten, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20083051: 'partjes van pompelmoezen en van pomelo\'s, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20083055: 'mandarijnen, incl. tangerines en satsuma\'s, clementines, wilkings e.d. kruisingen van citrusvruchten, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20083059: 'citrusvruchten, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. mandarijnen, tangerines, satsuma\'s, clementines, wilkings e.d. kruisingen van citrusvruchten en m.u.v. partjes van pompelmoezen en van pomelo\'s)',
    20083071: 'partjes van pompelmoezen en van pomelo\'s, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20083075: 'mandarijnen, incl. tangerines en satsuma\'s, clementines, wilkings e.d. kruisingen van citrusvruchten, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20083079: 'citrusvruchten, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. mandarijnen, tangerines, satsuma\'s, clementines, wilkings e.d. kruisingen van citrusvruchten en m.u.v. partjes van pompelmoezen en van pomelo\'s)',
    20083090: 'citrusvruchten, bereid of verduurzaamd (m.u.v. citrusvruchten met toegevoegde alcohol of met toegevoegde suiker)',
    20084011: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20084019: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20084021: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20084029: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20084031: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20084039: 'peren, bereid of verduurzaamd, met toegevoegde alcohol, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die met een suikergehalte van > 15 gewichtspercenten)',
    20084051: 'peren, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20084059: 'peren, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van <= 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20084071: 'peren, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20084079: 'peren, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van <= 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20084090: 'peren, bereid of verduurzaamd (m.u.v. peren met toegevoegde alcohol of met toegevoegde suiker)',
    20085011: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20085019: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20085031: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20085039: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20085051: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20085059: 'abrikozen, bereid of verduurzaamd, met toegevoegde alcohol, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die met een suikergehalte van > 15 gewichtspercenten)',
    20085061: 'abrikozen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20085069: 'abrikozen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 9, doch <= 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20085071: 'abrikozen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20085079: 'abrikozen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, met een suikergehalte van > 9, doch <= 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20085092: 'abrikozen, bereid of verduurzaamd, zonder toegevoegde alcohol of toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 5 kg',
    20085098: 'abrikozen, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van < 5 kg (m.u.v. abrikozen met toegevoegde alcohol of met toegevoegde suiker)',
    20086011: 'kersen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas',
    20086019: 'kersen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas',
    20086031: 'kersen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20086039: 'kersen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20086050: 'Kersen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20086060: 'Kersen, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20086070: 'kersen, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 4,5 kg',
    20086090: 'kersen, bereid of verduurzaamd, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van < 4,5 kg',
    20087011: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20087019: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20087031: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20087039: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20087051: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20087059: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, met toegevoegde alcohol, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die met een suikergehalte van > 15 gewichtspercenten)',
    20087061: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, met een suikergehalte van > 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20087069: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, met een suikergehalte van > 9 doch <= 13 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20087071: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, met een suikergehalte van > 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20087079: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, met een suikergehalte van > 9 doch <= 15 gewichtspercenten, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20087092: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol of toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 5 kg',
    20087098: 'perziken "nectarines daaronder begrepen", bereid of verduurzaamd, zonder toegevoegde alcohol of toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van < 5 kg',
    20088011: 'aardbeien, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas',
    20088019: 'aardbeien, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas',
    20088031: 'aardbeien, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20088039: 'aardbeien, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van <= 9%, met een effectief alcohol-massagehalte van > 11,85% mas',
    20088050: 'aardbeien, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20088070: 'aardbeien, bereid of verduurzaamd, alcoholvrij, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20088090: 'aardbeien, bereid of verduurzaamd (m.u.v. aardbeien met toegevoegde alcohol of met toegevoegde suiker)',
    20089100: 'palmharten, bereid of verduurzaamd, ook indien met toegevoegde suiker, andere zoetstoffen of alcohol (m.u.v. die bereid of verduurzaamd in azijn of azijnzuur)',
    20089311: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten, met een effectief alcohol-massagehalte van <= 11,85 % mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089319: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten, met een effectief alcohol-massagehalte van > 11,85 % mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089321: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van <=9 gewichtspercenten, met een effectief alcohol-massagehalte van <= 11,85 % mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089329: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van <= 9 gewichtspercenten, met een effectief alcohol-massagehalte van > 11,85 % mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089391: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, zonder toegevoegde alcohol, met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089393: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, zonder toegevoegde alcohol, met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089399: 'veenbessen "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen)',
    20089703: 'Mengsels van tropische noten en tropisch fruit, incl. mengsels met een gehalte aan deze tropische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, ook indien met toegevoegde suiker, andere zoetstoffen of alcohol,  in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089705: 'Mengsels van tropische noten en tropisch fruit, incl. mengsels met een gehalte aan deze tropische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, ook indien met toegevoegde suiker, andere zoetstoffen of alcohol,  in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20089712: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas',
    20089714: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hfdst 20 met een gehalte van >= 50 gewichtspercenten; mengsels van grondnoten en andere zaden)',
    20089716: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas',
    20089718: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. mengsels van noten, tropische vruchten en met een gehalte aan tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hfdst 20 van >= 50 gewichtspercenten; mengsels van grondnoten en andere zaden)',
    20089732: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20089734: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten; mengsels van noten, tropische vruchten en met een gehalte aan tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hfdst 20 van >= 50 gewichtspercenten; mengsels van grondnoten en andere zaden)',
    20089736: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20089738: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten; mengsels van noten, tropische vruchten en met een gehalte aan tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hfdst 20 van >= 50 gewichtspercenten; mengsels van grondnoten en andere zaden)',
    20089751: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089759: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hoofdstuk 20, met een gehalte van >= 50 gewichtspercenten; grondnoten en andere zaden; bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089772: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, waarin het gewicht van geen van de daarin aanwezige vruchtensoorten > 50 gewichtspercenten van het totaal gewicht van de vruchten is, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20089774: 'mengsels van vruchten waarin het gewicht van geen van de daarin aanwezige vruchtensoorten > 50 gewichtspercenten van het totale gewicht van de vruchten is, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hoofdstuk 20, met een gehalte van >= 50 gewichtspercenten; grondnoten en andere zaden; bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089776: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. mengsels waarin het gewicht van geen van de daarin aanwezige vruchtensoorten > 50 gewichtspercenten van het totaal gewicht van de vruchten is)',
    20089778: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hoofdstuk 20, met een gehalte van >= 50 gewichtspercenten; grondnoten en andere zaden; mengsels waarin het gewicht van geen van de daarin aanwezige vruchtensoorten > 50 gewichtspercenten van het totaal gewicht van de vruchten is; bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089792: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 5 kg',
    20089793: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 5 kg, n.e.g. (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hoofdstuk 20, met een gehalte van >= 50 gewichtspercenten; grondnoten en andere zaden; bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089794: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 4,5 doch < 5 kg',
    20089796: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 4,5 doch < 5 kg, n.e.g. (m.u.v. mengsels van noten, tropische vruchten en tropische vruchten/noten overeenkomstig aanvullende aantekeningen [GN] 7 en 8 bij hoofdstuk 20, met een gehalte van >= 50 gewichtspercenten; grondnoten en andere zaden; bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089797: 'mengsels van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, incl. mengsels met een gehalte aan deze vruchten en kokosnoten, cashewnoten, paranoten, arecanoten "betelnoten", colanoten en Australische noten van >= 50 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van < 4,5 kg',
    20089798: 'mengsels van vruchten of van andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van < 4,5 kg, n.e.g. (m.u.v. mengsels van noten, tropische vruchten overeenkomstig aanvullende aantekening [GN] 7 bij hoofdstuk 20, grondnoten en andere zaden, en bereidingen van de soort "Müsli" op basis van niet-geroosterde graanvlokken bedoeld bij onderverdeling 1904.20.10)',
    20089911: 'gember, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas',
    20089919: 'gember, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas',
    20089921: 'druiven, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 13 gewichtspercenten',
    20089923: 'druiven, bereid of verduurzaamd, met toegevoegde alcohol (m.u.v. die met een suikergehalte van > 13 gewichtspercenten)',
    20089924: 'Tropische vruchten (guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s (jackfruit), sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s), bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas',
    20089928: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; gember; druiven; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s)',
    20089931: 'Tropische vruchten (guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s), bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van >11,85% mas',
    20089934: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een suikergehalte van > 9 gewichtspercenten en met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; gember; druiven; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s)',
    20089936: 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20089937: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van <= 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; gember; veenbessen; druiven; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s, pitahaya\'s)',
    20089938: 'guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten)',
    20089940: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, met toegevoegde alcohol, met een effectief alcohol-massagehalte van > 11,85% mas (m.u.v. die met een suikergehalte van > 9 gewichtspercenten; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; gember; druiven; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s, pitahaya\'s)',
    20089941: 'gember, bereid of verduurzaamd, alcoholvrij, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089943: 'druiven, bereid of verduurzaamd, alcoholvrij, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089945: 'pruimen, bereid of verduurzaamd, alcoholvrij, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089948: 'Passievruchten, guaves en tamarindevruchten, manga\'s, manggistans, papaja\'s, cashewappelen, lychees, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg',
    20089949: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 1 kg (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; gember; druiven; pruimen; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s)',
    20089951: 'gember, bereid of verduurzaamd, alcoholvrij, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    20089963: 'Passievruchten en guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, lychees, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. mengsels)',
    20089967: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol, doch met toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg (m.u.v. die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; gember; passievruchten, guaves; manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, carambola\'s en pitahaya\'s)',
    20089972: 'pruimen, bereid of verduurzaamd, zonder toegevoegde alcohol, zonder toegevoegde suiker, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van >= 5 kg',
    20089978: 'pruimen, bereid of verduurzaamd, in onmiddellijke verpakkingen met een netto-inhoud van < 5 kg (m.u.v. pruimen met toegevoegde alcohol of met toegevoegde suiker)',
    20089985: 'maïs, bereid of verduurzaamd (m.u.v. suikermaïs "Zea mays var. saccharata" en m.u.v. maïs met toegevoegde alcohol of met toegevoegde suiker)',
    20089991: 'broodwortelen, zoete aardappelen "bataten" e.d. eetbare plantendelen met een zetmeelgehalte van >= 5 gewichtspercenten, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker (m.u.v. bevroren of gedroogd)',
    20089999: 'vruchten en andere eetbare plantendelen, bereid of verduurzaamd, zonder toegevoegde alcohol en zonder toegevoegde suiker (m.u.v. die bereid of verduurzaamd in azijn of azijnzuur; die gekonfijt met suiker, maar niet in stroop ingemaakt; jam, vruchtengelei, marmelade, vruchtenmoes en vruchtenpasta, door koken of stoven verkregen; noten, grondnoten en andere zaden; ananas; citrusvruchten, peren; abrikozen; kersen; perziken; aardbeien; veenbessen; pruimen; maïs; broodwortelen, zoete aardappelen "bataten" e.d. eetbare plantendelen)',
    20091111: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, bevroren, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht',
    20091119: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, bevroren, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht',
    20091191: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, bevroren, met een Brix-waarde van < 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20091199: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, bevroren, met een Brix-waarde van <= 67 bij 20°C (m.u.v. die met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten)',
    20091200: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van <= 20 bij 20°C (m.u.v. bevroren sap)',
    20091911: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. bevroren sap)',
    20091919: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. bevroren sap)',
    20091991: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (m.u.v. bevroren sap)',
    20091998: 'sinaasappelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 20 doch <= 67 bij 20°C (m.u.v. bevroren sap en m.u.v. dat met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten)',
    20092100: 'sap van pompelmoezen of van pomelo\'s, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van <= 20 bij 20°C',
    20092911: 'sap van pompelmoezen of van pomelo\'s, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht',
    20092919: 'sap van pompelmoezen of van pomelo\'s, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht',
    20092991: 'sap van pompelmoezen of van pomelo\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20092999: 'sap van pompelmoezen of van pomelo\'s, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 20 doch <= 67 bij 20°C (m.u.v. dat met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten)',
    20093111: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend (m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093119: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat en m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093151: 'citroensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend',
    20093159: 'citroensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat)',
    20093191: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend (m.u.v. mengsels, citroensap, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093199: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat en m.u.v. mengsels, citroensap, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093911: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093919: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093931: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend (m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093939: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat en m.u.v. mengsels, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093951: 'citroensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20093955: 'citroensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20093959: 'citroensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat)',
    20093991: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (m.u.v. mengsels, citroensap, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093995: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten (m.u.v. mengsels, citroensap, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20093999: 'sap van citrusvruchten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. sap dat toegevoegde suiker bevat en m.u.v. mengsels, citroensap, sinaasappelsap en sap van pompelmoezen of van pomelo\'s)',
    20094192: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C, toegevoegde suiker bevattend',
    20094199: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C (m.u.v. sap dat toegevoegde suiker bevat)',
    20094911: 'ananassap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht',
    20094919: 'ananassap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht',
    20094930: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend',
    20094991: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20094993: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20094999: 'ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C (m.u.v. sap dat toegevoegde suiker bevat)',
    20095010: 'tomatensap met een gehalte aan droge stof van < 7 gewichtspercenten, ongegist, alcoholvrij, toegevoegde suiker bevattend',
    20095090: 'tomatensap, ongegist, alcoholvrij (m.u.v. sap dat toegevoegde suiker bevat)',
    20096110: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van <= 30 bij 20°C en met een waarde van > 18 € per 100 kg nettogewicht',
    20096190: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van <= 30 bij 20°C en met een waarde van <= 18 € per 100 kg nettogewicht',
    20096911: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 22 € per 100 kg nettogewicht',
    20096919: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 22 € per 100 kg nettogewicht',
    20096951: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 30 doch <= 67 bij 20°C en met een waarde van > 18 € per 100 kg nettogewicht, geconcentreerd',
    20096959: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 30 doch <= 67 bij 20°C en met een waarde van > 18 € per 100 kg nettogewicht (niet geconcentreerd)',
    20096971: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 30 doch <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten, geconcentreerd',
    20096979: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 30 doch <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (niet geconcentreerd)',
    20096990: 'druivensap "druivenmost daaronder begrepen", ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 30 doch <= 67 bij 20°C en met een waarde van <= 18 € per 100 kg nettogewicht (m.u.v. die met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten)',
    20097120: 'Appelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C, met toegevoegde suiker',
    20097199: 'appelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 20 bij 20°C (m.u.v. sap met toegevoegde suiker)',
    20097911: 'appelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 22 € per 100 kg nettogewicht',
    20097919: 'appelsap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 22 € per 100 kg nettogewicht',
    20097930: 'appelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C en met een waarde van > 18 € per 100 kg nettogewicht, met toegevoegde suiker',
    20097991: 'appelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20097998: 'appelsap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van > 20 doch <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten, of geen toegevoegde suiker bevattend',
    20098111: 'veenbessensap "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, ook indien met toegevoegde suiker of andere zoetstoffen, met een brixwaarde van meer dan 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht',
    20098119: 'veenbessensap "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, ook indien met toegevoegde suiker of andere zoetstoffen, met een brixwaarde van meer dan 67 bij 20°C, met een waarde van > 30 € per 100 kg nettogewicht',
    20098131: 'veenbessensap "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, met een waarde van > 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend',
    20098151: 'veenbessensap "Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht, met een gehalte aan toegevoegde suiker van meer dan 30 gewichtspercenten',
    20098159: 'veenbessensap"Vaccinium macrocarpon, Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht, met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20098195: 'veenbessensap "Vaccinium macrocarpon", ongegist, alcoholvrij, met een brixwaarde van <= dan 67 bij 20°C, geen toegevoegde suiker bevattend',
    20098199: 'veenbessensap "Vaccinium oxycoccos,Vaccinium vitis-idaea", ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, geen toegevoegde suiker bevattend',
    20098911: 'perensap, ongegist, alcoholvrij, ook indien met toegevoegde suiker of andere zoetstoffen, met een brixwaarde van > 67 bij 20°C, met een waarde van <= 22 € per 100 kg nettogewicht',
    20098919: 'perensap, ongegist, alcoholvrij, ook indien met toegevoegde suiker of andere zoetstoffen, met een brixwaarde van > 67 bij 20°C, met een waarde van > 22 € per 100 kg nettogewicht',
    20098934: 'Sap van tropische vruchten (guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s (jackfruit), sapodilla\'s, passievruchten, carambola\'s en pitahayas), ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. mengsels)',
    20098935: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met waarde <= 30 € per 100 kg nettogewicht (m.u.v. mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap en veenbessensap)',
    20098936: 'sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. mengsels)',
    20098938: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap en veenbessensap)',
    20098950: 'perensap, ongegist, alcoholvrij, met een Brixwaarde van <= 67 bij 20°C, met een waarde van > 18 € per 100 kg nettogewicht,toegevoegde suiker bevattend',
    20098961: 'perensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20098963: 'perensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20098969: 'perensap, ongegist, alcoholvrij, met een Brixwaarde van <= 67 bij 20°C, geen toegevoegde suiker bevattend',
    20098971: 'kersensap, ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, met een waarde van > 30 € per 100 kg nettogewicht, toegevoegde suiker bevattend',
    20098973: 'sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, met toegevoegde suiker (m.u.v. mengsels)',
    20098979: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, met toegevoegde suiker (m.u.v. mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap, veenbessensap en kersensap)',
    20098985: 'Sap van tropische vruchten (guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s en pitahaya\'s), ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (m.u.v. mengsels)',
    20098986: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (m.u.v. mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap en veenbessensap)',
    20098988: 'sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten (m.u.v. mengsels)',
    20098989: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten (m.u.v. mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap en veenbessensap)',
    20098996: 'kersensap, ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, geen toegevoegde suiker bevattend',
    20098997: 'sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, alcoholvrij, met een brixwaarde van <= 67 bij 20°C, geen toegevoegde suiker bevattend (m.u.v. mengsels)',
    20098999: 'sap van vruchten of van groenten, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C,  (m.u.v. sap met toegevoegde suiker , mengsels, sap van citrusvruchten, guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ananassap, tomatensap, druivensap "druivenmost daaronder begrepen", appelsap, perensap, kersensap en veenbessensap).',
    20099011: 'mengsels van appel- en perensap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 22 € per 100 kg nettogewicht',
    20099019: 'mengsels van appel- en perensap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 22 € per 100 kg nettogewicht',
    20099021: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. mengsels van appel- en perensap)',
    20099029: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van > 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. mengsels van appel- en perensap)',
    20099031: 'mengsels van appel- en perensap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20099039: 'mengsels van appel- en perensap, ongegist, zonder toegevoegde alcohol, ook indien met toegevoegde suiker of andere zoetstoffen, met een Brix-waarde van <= 67 bij 20°C (m.u.v. die met een waarde van <= 18 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten)',
    20099041: 'mengsels van sap van citrusvruchten en ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht, met toegevoegde suiker',
    20099049: 'mengsels van sap van citrusvruchten en ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. die met toegevoegde suiker)',
    20099051: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 5 per 100 kg nettogewicht, met toegevoegde suiker (m.u.v. die van appel- en perensap of van sap van citrusvruchten en ananassap)',
    20099059: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van > 30 € per 100 kg nettogewicht (m.u.v. die met toegevoegde suiker en m.u.v. mengsels van appel- en perensap of van sap van citrusvruchten en ananassap)',
    20099071: 'mengsels van sap van citrusvruchten en ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20099073: 'mengsels van sap van citrusvruchten en ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20099079: 'mengsels van sap van citrusvruchten en ananassap, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. mengsels die toegevoegde suiker bevatten)',
    20099092: 'mengsels van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten',
    20099094: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van > 30 gewichtspercenten (m.u.v. mengsels van appel- en perensap, van citrusvruchten- en ananassap of van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s)',
    20099095: 'mengsels van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten',
    20099096: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht en met een gehalte aan toegevoegde suiker van <= 30 gewichtspercenten (m.u.v. mengsels van appel- en perensap, van citrusvruchten- en ananassap of van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s)',
    20099097: 'mengsels van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C, met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. die met toegevoegde suiker)',
    20099098: 'mengsels van vruchtensappen, druivenmost daaronder begrepen, of van groentesappen, ongegist, zonder toegevoegde alcohol, met een Brix-waarde van <= 67 bij 20°C en met een waarde van <= 30 € per 100 kg nettogewicht (m.u.v. die met toegevoegde suiker en m.u.v. mengsels van appel- en perensap, van citrusvruchten- en ananassap of van sap van guaves, manga\'s, manggistans, papaja\'s, tamarindevruchten, cashewappelen, litchis, nangka\'s "jackfruit", sapodilla\'s, passievruchten, carambola\'s of pitahaya\'s)',
    21011100: 'Extracten, essences en concentraten, van koffie',
    21011292: 'preparaten op basis van extracten, essences of concentraten, van koffie',
    21011298: 'preparaten op basis van koffie',
    21012020: 'extracten, essences en concentraten, van thee of van maté',
    21012092: 'preparaten op basis van extracten, essences en concentraten, van thee of van maté',
    21012098: 'preparaten op basis van thee of van maté',
    21013011: 'cichorei, gebrand',
    21013019: 'koffiesurrogaten, gebrand (m.u.v. cichorei)',
    21013091: 'extracten, essences en concentraten, van gebrande cichorei',
    21013099: 'extracten, essences en concentraten, van gebrande koffiesurrogaten (m.u.v. cichorei)',
    21021010: 'reinculturen van gist',
    21021031: 'bakkersgist, gedroogd',
    21021039: 'bakkersgist (m.u.v. gedroogd)',
    21021090: 'gist, levend (m.u.v. reinculturen van gist, bakkersgist)',
    21022011: 'gist, inactief, in tabletten, in blokken of in dergelijke vormen, dan wel in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg',
    21022019: 'gist, inactief (m.u.v. gist in tabletten, in blokken of in dergelijke vormen, dan wel in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 1 kg)',
    21022090: 'eencellige micro-organismen, dood (m.u.v. opgemaakt als geneesmiddel)',
    21023000: 'bakpoeder, samengesteld',
    21031000: 'sojasaus',
    21032000: 'tomatenketchup en andere tomatensausen',
    21033010: 'mosterdmeel (m.u.v. bereide mosterd)',
    21033090: 'bereide mosterd',
    21039010: 'mangochutney, vloeibaar',
    21039030: 'aromatische bitters met een alcohol-volumegehalte van >= 44,2 doch <= 49,2% vol, bevattende >= 1,5 doch <= 6 gewichtspercenten gentianide, kruiden en diverse ingrediënten en met een suikergehalte van >= 4 doch <= 10 gewichtspercenten, in verpakkingen met een inhoudsruimte van <= 0,5 l',
    21039090: 'sausen en preparaten voor sausen; samengestelde kruiderijen e.d. producten (m.u.v. sojasaus; tomatenketchup en andere tomatensausen; vloeibare mangochutney; aromatische bitters bedoeld bij onderverdeling 2103.90-30)',
    21041000: 'Preparaten voor soep of voor bouillon, bereide soep en bouillon',
    21042000: 'producten voor menselijke consumptie in de vorm van bereidingen van een fijngehomogeniseerd mengsel van twee of meer basisbestanddelen zoals vlees, vis, groenten en vruchten, opgemaakt voor de verkoop in het klein, als kindervoeding of als dieetvoeding, in verpakkingen met een nettogewicht van <= 250 g',
    21050010: 'consumptie-ijs, ook indien cacao bevattend, geen of minder dan 3 gewichtspercenten van melk afkomstige vetstoffen bevattend',
    21050091: 'consumptie-ijs, ook indien cacao bevattend, met een gehalte aan van melk afkomstige vetstoffen van >= 3 gewichtspercenten, doch < 7 gewichtspercenten',
    21050099: 'consumptie-ijs, ook indien cacao bevattend, met een gehalte aan van melk afkomstige vetstoffen van >= 7 gewichtspercenten',
    21061020: 'proteïneconcentraten en getextureerde proteïnestoffen, bevattende geen van melk afkomstige vetstoffen, sacharose, isoglucose, glucose of zetmeel, of bevattende < 1,5 gewichtspercenten van melk afkomstige vetstoffen, < 5 gewichtspercenten sacharose of isoglucose, < 5 gewichtspercenten glucose of < 5 gewichtspercenten zetmeel',
    21061080: 'proteïneconcentraten en getextureerde proteïnestoffen, bevattende >= 1,5 gewichtspercenten van melk afkomstige vetstoffen, >= 5 gewichtspercenten sacharose of isoglucose, >= 5 gewichtspercenten glucose of >= 5 gewichtspercenten zetmeel',
    21069020: 'samengestelde alcoholhoudende preparaten van de soort gebruikt voor de vervaardiging van dranken, met een effectief alcohol-volumegehalte van > 0,5% vol (m.u.v. die op basis van reukstoffen)',
    21069030: 'stroop van isoglucose, gearomatiseerd of met toegevoegde kleurstoffen',
    21069051: 'stroop van lactose, gearomatiseerd of met toegevoegde kleurstoffen',
    21069055: 'stroop van glucose en van maltodextrine, gearomatiseerd of met toegevoegde kleurstoffen',
    21069059: 'suikerstroop, gearomatiseerd of met toegevoegde kleurstoffen (m.u.v. stroop van isoglucose, van lactose, van glucose en van maltodextrine)',
    21069092: 'producten voor menselijke consumptie, n.e.g., bevattende geen van melk afkomstige vetstoffen, sacharose, isoglucose, glucose of zetmeel, of bevattende < 1,5 gewichtspercenten van melk afkomstige vetstoffen, < 5 gewichtspercenten sacharose of isoglucose, < 5 gewichtspercenten glucose of < 5 gewichtspercenten zetmeel',
    21069098: 'producten voor menselijke consumptie, n.e.g., bevattende >= 1,5 gewichtspercenten van melk afkomstige vetstoffen, >= 5 gewichtspercenten sacharose of isoglucose, >= 5 gewichtspercenten glucose of >= 5 gewichtspercenten zetmeel',
    22011011: 'natuurlijk mineraalwater, niet-koolzuurgashoudend, zonder toegevoegde suiker of andere zoetstoffen, ongearomatiseerd',
    22011019: 'natuurlijk mineraalwater, koolzuurgashoudend, zonder toegevoegde suiker of andere zoetstoffen, ongearomatiseerd',
    22011090: 'kunstmatig mineraalwater, zonder toegevoegde suiker of andere zoetstoffen, ongearomatiseerd, incl. spuitwater',
    22019000: 'water, zonder toegevoegde suiker of andere zoetstoffen, ongearomatiseerd; ijs en sneeuw (m.u.v. mineraalwater en spuitwater en m.u.v. zeewater, gedistilleerd water, conductometrisch zuiver water e.d. zuiver water)',
    22021000: 'water, incl. mineraalwater en spuitwater, met toegevoegde suiker of andere zoetstoffen, dan wel gearomatiseerd, zodanig gebruikt als drank',
    22029100: 'Alcoholvrij bier met een alcohol volumegehalte <= 0,5 %',
    22029911: 'dranken, alcoholvrij, op basis van soja, met een proteïnegehalte van 2,8 of meer gewichtspercenten, bevattende geen melk of melkproducten en geen hiervan afkomstige vetstoffen',
    22029915: 'dranken, alcoholvrij, op basis van soja, met een proteïnegehalte van minder dan 2,8 gewichtspercenten en dranken op basis van noten bedoeld bij hoofdstuk 8, van granen bedoeld bij hoofdstuk 10 of van zaden bedoeld bij hoofdstuk 12, bevattende geen melk of melkproducten en geen hiervan afkomstige vetstoffen',
    22029919: 'Niet-alcoholische dranken, niet bevattende melk, melkproducten of daarvan afgeleide vetten (m.u.v. water, fruit- of groentensappen, bier en dranken op basis van soja, noten uit Hoofdstuk 8, granen uit Hoofdstuk 10 of zaden uit Hoofdstuk 12).',
    22029991: 'dranken, alcoholvrij, met een gehalte aan van melk of van melkproducten afkomstige vetstoffen van < 0,2 gewichtspercent',
    22029995: 'dranken, alcoholvrij, met een gehalte aan van melk of van melkproducten afkomstige vetstoffen van >= 0,2 gewichtspercent, doch < 2 gewichtspercenten',
    22029999: 'dranken, alcoholvrij, met een gehalte aan van melk of van melkproducten afkomstige vetstoffen van >= 2 gewichtspercenten',
    22030001: 'bier van mout, verpakt in flessen met een inhoud van <= 10 l',
    22030009: 'bier van mout, in verpakkingen met een inhoud van <= 10 l (m.u.v. bier in flessen)',
    22030010: 'bier van mout, in verpakkingen met een inhoud van > 10 l',
    22041011: 'Champagne met beschermde oorsprongsbenaming (BOB)',
    22041013: 'Cava met beschermde oorsprongsbenaming (BOB)',
    22041015: 'Prosecco met beschermde oorsprongsbenaming (BOB)',
    22041091: 'Asti spumante met beschermde oorsprongsbenaming (BOB)',
    22041093: 'Mousserende wijn van verse druiven met beschermde oorsprongsbenaming (BOB) (m.u.v. Asti spumante, Champagne, Cava en Prosecco)',
    22041094: 'Mousserende wijn van verse druiven met beschermde geografische aanduiding (BGA)',
    22041096: 'Mousserende cépagewijnen van verse druiven (m.u.v. die met beschermde oorsprongsbenaming (BOB) en die met beschermde geografische aanduiding (BGA))',
    22041098: 'Mousserende wijn van verse druiven (m.u.v. cépagewijnen en wijnen met beschermde oorsprongsbenaming (BOB) en beschermde geografische aanduiding (BGA))',
    22042106: 'Wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende <= 2 l; wijn in andere verpakkingen inhoudende <= 2 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar  met beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijnen)',
    22042107: 'Wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende <= 2 l; wijn in andere verpakkingen inhoudende <= 2 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar,  met beschermde geografische aanduiding (BGA), (m.u.v. mousserende wijnen)',
    22042108: 'Wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende <= 2 l; wijn in andere verpakkingen inhoudende <= 2 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar, cépagewijnen zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), (m.u.v. mousserende wijnen)',
    22042109: 'Andere wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende <= 2 l; wijn in andere verpakkingen inhoudende <= 2 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar (m.u.v. mousserende wijn, cépagewijnen, wijnen met beschermde oorsprongsbenaming (BOB) en met beschermde geografische aanduiding (BGA))',
    22042111: 'Witte wijn, geproduceerd in Alsace (Elzas) in verpakkingen inhoudende <=2 l, met een effetief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042112: 'Witte wijn, geproduceerd in Bordeaux, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042113: 'Witte wijn, geproduceerd in Bourgogne, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042117: 'Witte wijn, geproduceerd in Val de Loire, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042118: 'Witte wijn, geproduceerd in Mosel, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042119: 'Witte wijn, geproduceerd in Pfalz, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042122: 'Witte wijn, geproduceerd in Rheinhessen, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042123: 'Witte wijn, geproduceerd in Tokaj "b.v. Aszu, Szamorodni, Máslás, Fordítás" , in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042124: 'Witte wijn, geproduceerd in Lazio (Latium), in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042126: 'Witte wijn, geproduceerd in Toscana (Toscane), in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042127: 'Witte wijn, geproduceerd in Trentino, Alto Adige (Zuid-Tirol) en Friuli , in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042128: 'Witte wijn, geproduceerd in Veneto, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042131: 'Witte wijnen, geproduceerd in Sicilië, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042132: 'Witte wijn "Vinho Verde", geproduceerd in de Europese Unie, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042134: 'Witte wijn, geproduceerd in Penedès, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042136: 'Witte wijn, geproduceerd in Rioja, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042137: 'Witte wijn, geproduceerd in Valencia, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042138: 'Witte wijnen, geproduceerd in de EU, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. Alsace (Elzas), Bordeaux, Bourgogne, Val de Loire, Mosel, Pfalz, Rheinhessen, Tokaj, Lazio, Toscana, Trentino, Alto Adige, Friuli, Veneto, Sicilië, vinho verde, Penedés, Rioja, Valencia; mousserende wijn en parelwijn.',
    22042142: 'Wijnen, geproduceerd in Bordeaux, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB)(m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042143: 'Wijnen, geproduceerd in Bourgogne, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042144: 'Wijnen, geproduceerd in Beaujolois, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042146: 'Wijnen, geproduceerd in Vallée-du-Rhône, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042147: 'Wijnen, geproduceerd in Languedoc-Roussillon, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042148: 'Wijnen, geproduceerd in Val de Loire, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042161: 'Wijnen, geproduceerd in Sicilië, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB),( m.u.v. mousserende wijn en parelwijn en witte wijn).',
    22042162: 'Wijnen, geproduceerd in Piemonte, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042166: 'Wijnen, geproduceerd in Toscana (Toscane), in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042167: 'Wijnen, geproduceerd in Trentino en Alto Adige (Zuid-Tirol), in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042168: 'Wijnen, geproduceerd in Veneto, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042169: 'Wijnen, geproduceerd in Dão, Bairrada and Douro, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042171: 'Wijnen, geproduceerd in Navarra, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042174: 'Wijnen, geproduceerd in Penedès, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042176: 'Wijnen, geproduceerd in Rioja, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042177: 'Wijnen, geproduceerd in Valdepenãs, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol met een beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn; parelwijn en witte wijn)',
    22042178: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), (m.u.v. Bordeaux, Bourgogne, Beaujolais, Vallée du Rhône, Languedoc-Roussillon, Val de Loire, Sicilië, Piemonte, Toscana, Trentino, Alto Adige, Veneto, Dâo, Bairrada, Douro, Navarra, Penedés, Rioja, Valdepenâs; mousserende wijn, parelwijn en witte wijn.)',
    22042179: 'Witte wijn, in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcoholvolumegehalte van <= 15% vol, met beschermde geografische aanduiding (BGA), (m.u.v. mousserende wijn en parelwijn)',
    22042180: 'Wijn, in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcoholvolumegehalte van <= 15% vol, met beschermde geografische aanduiding (BGA), (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042181: 'Witte cépagewijnen zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol (m.u.v. mousserende wijn en parelwijn)',
    22042182: 'Cépagewijnen zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042183: 'Witte wijn, in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042184: 'Wijn, in de EU geproduceerd, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van <= 15% vol (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22042185: 'Madeirawijn en Moscatel de Setubal, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van > 15% doch <= 22% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042186: 'Sherrywijn \'Xereswijn\', in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van > 15% doch <= 22% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042187: 'Marsalawijn, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van > 15% doch <= 22% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042188: 'Samoswijn en muskaatwijn van Limnos, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van > 15% doch <= 22% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042189: 'Portwijn, in verpakkingen inhoudende <= 2 l, met een effectief alcohol-volumegehalte van > 15% doch <= 22% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042190: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van meer dan 15% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA), m.u.v. Port, Madeira, Sherry, Marsala, muskaatwijn uit Limnos en moscatel de Setubal.',
    22042191: 'Wijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende niet meer dan 2 liter, met een effectief alcoholvolumegehalte van meer dan 15% vol.',
    22042193: 'Witte wijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l, met beschermde oorsprongsbenaming (BOB) of met beschemde geografische aanduiding (m.u.v. mousserende wijn en parelwijn)',
    22042194: 'Wijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l, met beschermde oorsprongsbenaming (BOB) of met beschemde geografische aanduiding (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042195: 'Witte cépagewijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l, zonder beschermde oorsprongsbenaming (BOB) of zonder beschemde geografische aanduiding (BGA),  (m.u.v. mousserende wijn en parelwijn)',
    22042196: 'Cépagewijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l, zonder beschermde oorsprongsbenaming (BOB) en zonder beschemde geografische aanduiding (BGA),  (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042197: 'Witte wijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042198: 'Wijnen, niet in de EU geproduceerd, in verpakkingen inhoudende < = 2 l (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22042210: 'Wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende > 2 l; wijn in andere verpakkingen inhoudende >  2l, maar <= 10 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar (m.u.v. mousserende wijn)',
    22042222: 'Wijn, geproduceerd in Bordeaux, in verpakkingen inhoudende > 2 l, maar <=10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042223: 'Wijn, geproduceerd in Bourgogne,  in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042224: 'Wijnen, geproduceerd in Beaujolais, in verpakkingen inhoudende  > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042226: 'Wijnen, geproduceerd in de Rhône-vallei (Vallée du Rhône), in verpakkingen inhoudende  > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042227: 'Wijnen, geproduceerd in Languedoc-Roussillon, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042228: 'Wijn, geproduceerd in Val de Loire, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042232: 'Wijnen, geproduceerd in Piemonte, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042233: 'Wijn, geproduceerd in Tokaj "b.v.  Aszu, Szamorodni, Máslás, Fordítás", in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042238: 'Witte wijnen, geproduceerd in de EU, in verpakkingen inhoudende >  2l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB),( m.u.v. witte wijnen uit de Bordeaux, Bourgogne, Beaujolais, Vallée du Rhône, Languedoc-Roussillon, Val de Loire, Piemonte, Tokaj, mousserende wijn en parelwijn).',
    22042278: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. Bordeaux, Bourgogne, Beaujolais, Vallée du Rhône, Languedoc-Rousillon, Val de Loire, Piemonte,Tokaj, mousserende wijn, parelwijn en witte wijn)',
    22042279: 'Witte wijn van verse druiven, geproduceerd in de EU, in verpakkingen inhoudende > 2l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn en parelwijn)',
    22042280: 'Wijn van verse druiven, geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042281: 'Witte cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol ( m.u.v. mousserende wijn en parelwijn)',
    22042282: 'Cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol ( m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042283: 'Witte wijnen, geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042284: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende  > 2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22042285: 'Madeirawijn en Moscatel de Setubal, in verpakkingen met een inhoud van >  2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van > 15% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042286: 'Sherrywijn \'Xereswijn\', in verpakkingen met een inhoud van  >  2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van > 15%  vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042288: 'Samoswijn en Muskaatwijn van Limnos, in verpakkingen met een inhoud van  >  2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van > 15% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042290: 'Wijnen, geproduceerd in de EU, in verpakkingen met een inhoud van >  2 l, maar <= 10 l, met een effectief alcohol-volumegehalte van > 15%  vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. Madeirawijn, Sherrywijn, Samoswijn, Muskaatwijn van Limnos en Setubal muscatel)',
    22042291: 'Wijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende >  2 l, maar <= 10 l, met een effectief alcoholvolumegehalte van meer dan 15% vol.',
    22042293: 'Witte wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn en parelwijn)',
    22042294: 'Wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende  > 2 l, maar <= 10 l, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042295: 'Witte cépagewijnen, zonder beschermde oosprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), niet in de EU geproduceerd, in verpakkingen inhoudende > 2 l, maar <= 10 l (m.u.v. mousserende wijn en parelwijn)',
    22042296: 'Cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), niet in de EU geproduceerd, in verpakking inhoudende > 2 l, maar <= 10 l  (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042297: 'Witte wijn, niet geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l  (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042298: 'Wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende > 2 l, maar <= 10 l  (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22042910: 'Wijn van verse druiven, incl. wijn waaraan alcohol is toegevoegd, verpakt in flessen, gesloten door middel van een champignonvormige stop waarvan de afsluiting door draden, banden of anderszins is geborgd, inhoudende > 2 l; wijn in andere verpakkingen inhoudende >  2l, maar <= 10 l die bij 20°C een overdruk heeft die is teweeggebracht door koolzuurgas in oplossing van >= 1, doch < 3 bar (m.u.v. mousserende wijn)',
    22042922: 'Wijn, geproduceerd in Bordeaux, in verpakkingen inhoudende > 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042923: 'Wijn, geproduceerd in Bourgogne,  in verpakkingen inhoudende > 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042924: 'Wijnen, geproduceerd in Beaujolais, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042926: 'Wijnen, geproduceerd in de Rhône-vallei (Vallée du Rhône), in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042927: 'Wijnen, geproduceerd in Languedoc-Roussillon, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), m.u.v. mousserende wijn en parelwijn).',
    22042928: 'Wijn, geproduceerd in Val de Loire, in verpakkingen inhoudende > 10 l, met een effectief alcohol-volumegehalte van <= 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. mousserende wijn en parelwijn)',
    22042932: 'Wijnen, geproduceerd in Piemonte, in verpakkingen inhoudende > 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), (m.u.v. mousserende wijn en parelwijn).',
    22042938: 'Witte wijnen, geproduceerd in de EU, in verpakkingen inhoudende > 10 l, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB), ( m.u.v. witte wijnen uit de Bordeaux, Bourgogne, Beaujolais, Vallée du Rhône, Languedoc-Roussillon, Val de Loire, Piemonte, mousserende wijn en parelwijn).',
    22042978: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde oorsprongsbenaming (BOB) (m.u.v. Bordeaux, Bourgogne, Beaujolais, Vallée du Rhône, Languedoc-Rousillon, Val de Loire, Piemonte, mousserende wijn, parelwijn en witte wijn)',
    22042979: 'Witte wijn van verse druiven, geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol, met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn en parelwijn)',
    22042980: 'Wijn van verse druiven, geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042981: 'Witte cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol ( m.u.v. mousserende wijn en parelwijn)',
    22042982: 'Cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol ( m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042983: 'Witte wijnen, geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042984: 'Wijnen, geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van niet meer dan 15% vol (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22042985: 'Madeirawijn en Moscatel de Setubal, in verpakkingen met een inhoud van > 10 l, met een effectief alcohol-volumegehalte van > 15% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042986: 'Sherrywijn \'Xereswijn\', in verpakkingen met een inhoud van > 10 l, met een effectief alcohol-volumegehalte van > 15%  vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042988: 'Samoswijn en Muskaatwijn van Limnos, in verpakkingen met een inhoud van > 10 l, met een effectief alcohol-volumegehalte van > 15% vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA)',
    22042990: 'Wijnen, geproduceerd in de EU, in verpakkingen met een inhoud van > 10 l, met een effectief alcohol-volumegehalte van > 15%  vol, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. Madeirawijn, Sherrywijn, Samoswijn, Muskaatwijn van Limnos en Setubal muscatel)',
    22042991: 'Wijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), in de EU geproduceerd, in verpakkingen inhoudende meer dan 10 liter, met een effectief alcoholvolumegehalte van meer dan 15% vol.',
    22042993: 'Witte wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn en parelwijn)',
    22042994: 'Wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter, met beschermde oorsprongsbenaming (BOB) of met beschermde geografische aanduiding (BGA) (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042995: 'Witte cépagewijnen, zonder beschermde oosprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), niet in de EU geproduceerd, in verpakkingen inhoudende meer dan 10 liter (m.u.v. mousserende wijn en parelwijn)',
    22042996: 'Cépagewijnen, zonder beschermde oorsprongsbenaming (BOB) en zonder beschermde geografische aanduiding (BGA), niet in de EU geproduceerd, in verpakking inhoudende meer dan 10 liter (m.u.v. mousserende wijn, parelwijn en witte wijn)',
    22042997: 'Witte wijn, niet geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter (m.u.v. mousserende wijn, parelwijn en cépagewijnen)',
    22042998: 'Wijnen, niet geproduceerd in de EU, in verpakkingen inhoudende meer dan 10 liter (m.u.v. mousserende wijn, parelwijn, witte wijn en cépagewijnen)',
    22043010: 'druivenmost, gedeeltelijk gegist, ook indien de gisting op andere wijze dan door toevoegen van alcohol is gestuit, met een effectief alcohol-volumegehalte van > 1% vol (m.u.v. druivenmost waarvan de gisting door toevoegen van alcohol is gestuit)',
    22043092: 'druivenmost, ongegist, geconcentreerd overeenkomstig aanvullende aantekening [GN] 7 bij hoofdstuk 22, met een dichtheid van <= 1,33 g/cm³ bij 20°C en een effectief alcohol-volumegehalte van <= 1% vol, doch > 0,5% vol (m.u.v. druivenmost waarvan de gisting door toevoegen van alcohol is verhinderd of gestuit)',
    22043094: 'druivenmost, ongegist, ongeconcentreerd, met een dichtheid van <= 1,33 g/cm³ bij 20°C en een effectief alcohol-volumegehalte van <= 1% vol, doch > 0,5% vol (m.u.v. druivenmost waarvan de gisting door toevoegen van alcohol is verhinderd of gestuit)',
    22043096: 'druivenmost, ongegist, geconcentreerd overeenkomstig aanvullende aantekening [GN] 7 bij hoofdstuk 22, met een dichtheid van > 1,33 g/cm³ bij 20°C en een effectief alcohol-volumegehalte van <= 1% vol, doch > 0,5% vol (m.u.v. druivenmost waarvan de gisting door toevoegen van alcohol is verhinderd of gestuit)',
    22043098: 'druivenmost, ongegist, ongeconcentreerd, met een dichtheid van > 1,33 g/cm³ bij 20°C en een effectief alcohol-volumegehalte van <= 1% vol, doch > 0,5% vol (m.u.v. druivenmost waarvan de gisting door toevoegen van alcohol is verhinderd of gestuit)',
    22051010: 'vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen, in verpakkingen inhoudende <= 2 l en met een effectief alcohol-volumegehalte van <= 18% vol',
    22051090: 'vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen, in verpakkingen inhoudende <= 2 l en met een effectief alcohol-volumegehalte van > 18% vol',
    22059010: 'vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen, in verpakkingen inhoudende > 2 l en met een effectief alcohol-volumegehalte van <= 18% vol',
    22059090: 'vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen, in verpakkingen inhoudende > 2 l en met een effectief alcohol-volumegehalte van > 18% vol',
    22060010: 'piquette, verkregen uit draf van druiven',
    22060031: 'appelwijn en perenwijn, mousserend',
    22060039: 'honigdrank en andere gegiste dranken alsmede mengsels van gegiste dranken en mengsels van gegiste dranken met alcoholvrije dranken, mousserend, n.e.g. (m.u.v. bier; wijn van verse druiven; druivenmost; piquette; appelwijn, perenwijn)',
    22060051: 'appelwijn en perenwijn, niet-mousserend, in verpakkingen inhoudende <= 2 l',
    22060059: 'honigdrank en andere gegiste dranken, alsmede mengsels van gegiste dranken en mengsels van gegiste dranken met alcoholvrije dranken, niet-mousserend, in verpakkingen met een inhoud van <= 2 l, n.e.g. (m.u.v. wijn van verse druiven; druivenmost; vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen; piquette; appelwijn, perenwijn)',
    22060081: 'appelwijn en perenwijn, niet-mousserend, in verpakkingen inhoudende > 2 l',
    22060089: 'honigdrank en andere gegiste dranken, alsmede mengsels van gegiste dranken en mengsels van gegiste dranken met alcoholvrije dranken, niet-mousserend, in verpakkingen met een inhoud van > 2 l, n.e.g. (m.u.v. wijn van verse druiven; druivenmost; vermout en andere wijn van verse druiven, bereid met aromatische planten of met aromatische stoffen; piquette; appelwijn, perenwijn)',
    22071000: 'ethylalcohol met een alcohol-volumegehalte van >= 80%, niet gedenatureerd',
    22072000: 'ethylalcohol en gedistilleerde dranken, gedenatureerd, ongeacht het gehalte',
    22082012: 'cognac, in verpakkingen inhoudende <= 2 l',
    22082014: 'armagnac, in verpakkingen inhoudende <= 2 l',
    22082016: 'Brandy de Jerez, in verpakkingen inhoudende <= 2 l',
    22082018: 'Brandy of Weinbrand, in verpakkingen inhoudende <= 2 l (m.u.v. Brandy de Jerez en Armagnac)',
    22082019: 'dranken, gedistilleerd uit wijn, in verpakkingen inhoudende <= 2 l (m.u.v. Cognac, Armagnac, Brandy of Weinbrand)',
    22082026: 'grappa, in verpakkingen inhoudende <= 2 l',
    22082028: 'dranken, gedistilleerd uit druivenmoer, in verpakkingen inhoudende <= 2 l (m.u.v.  Grappa)',
    22082062: 'cognac, in verpakkingen inhoudende > 2 l',
    22082066: 'Brandy of Weinbrand in verpakkingen > 2 l',
    22082069: 'dranken, gedistilleerd uit wijn, in verpakkingen inhoudende > 2 l (incl. Armagnac, m.u.v. Cognac en Brandy of Weinbrand)',
    22082086: 'grappa, in verpakkingen inhoudende > 2 l',
    22082088: 'dranken, gedistilleerd uit druivenmoer, in verpakkingen inhoudende > 2 l (m.u.v. Grappa)',
    22083011: 'Bourbonwhisky, in verpakkingen inhoudende <= 2 l',
    22083019: 'Bourbonwhisky, in verpakkingen inhoudende > 2 l',
    22083030: 'Single malt Scotch whisky',
    22083041: 'Scotchwhisky, zgn. \'blended whisky\', in verpakkingen inhoudende <= 2 l',
    22083049: 'Scotchwhisky, zgn. \'blended whisky\', in verpakkingen inhoudende > 2 l',
    22083061: 'Scotchwhisky, zgn "single grain whisky" en zgn "blended grain whisky", in verpakkingen inhoudende < = 2 l',
    22083069: 'Scotchwhisky, zgn. "single grain whisky" en zgn. "blended grain whisky", in verpakkingen inhoudende > 2 l',
    22083071: 'Blended Scotch whisky, in verpakkingen inhoudende <= 2 l (m.u.v. zgn. single malt, blended malt, single grain en blended grain whisky)',
    22083079: 'Blended Scotch whisky, in verpakkingen inhoudende > 2 l (m.u.v. single malt, blended malt, single grain en blended grain whisky)',
    22083082: 'Whisky, in verpakkingen inhoudende <= 2 l (m.u.v. Bourbonwhisky en "Scotchwhisky")',
    22083088: 'Whisky, in verpakkingen inhoudende > 2 l (m.u.v. Bourbonwhisky en "Scotchwhisky")',
    22084011: 'rum met een gehalte aan vluchtige stoffen (andere dan ethylalcohol en methylalcohol) van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%, in verpakkingen inhoudende <= 2 l',
    22084031: 'rum en tafia, met een waarde van > 7,9 €/l zuivere alcohol, in verpakkingen inhoudende <= 2 l (m.u.v. rum met een gehalte aan vluchtige stoffen, andere dan ethylalcohol en methylalcohol, van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%)',
    22084039: 'rum en tafia, met een waarde van <= 7,9 €/l zuivere alcohol, in verpakkingen inhoudende <= 2 l (m.u.v. rum met een gehalte aan vluchtige stoffen, andere dan ethylalcohol en methylalcohol, van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%)',
    22084051: 'rum met een gehalte aan vluchtige stoffen (andere dan ethylalcohol en methylalcohol) van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%, in verpakkingen inhoudende > 2 l',
    22084091: 'rum en tafia, met een waarde van > 2 €/l zuivere alcohol, in verpakkingen inhoudende > 2 l (m.u.v. rum met een gehalte aan vluchtige stoffen, andere dan ethylalcohol en methylalcohol, van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%)',
    22084099: 'rum en tafia, met een waarde van <= 2 €/l zuivere alcohol, in verpakkingen inhoudende > 2 l (m.u.v. rum met een gehalte aan vluchtige stoffen, andere dan ethylalcohol en methylalcohol, van >= 225 g/hl zuivere alcohol, met een tolerantie van 10%)',
    22085011: 'gin, in verpakkingen inhoudende <= 2 l',
    22085019: 'gin, in verpakkingen inhoudende > 2 l',
    22085091: 'jenever, in verpakkingen inhoudende <= 2 l',
    22085099: 'jenever, in verpakkingen inhoudende > 2 l',
    22086011: 'wodka met een alcohol-volumegehalte van <= 45,4% vol, in verpakkingen inhoudende <= 2 l',
    22086019: 'wodka met een alcohol-volumegehalte van <= 45,4% vol, in verpakkingen inhoudende > 2 l',
    22086091: 'wodka met een alcohol-volumegehalte van > 45,4% vol, in verpakkingen inhoudende <= 2 l',
    22086099: 'wodka met een alcohol-volumegehalte van > 45,4% vol, in verpakkingen inhoudende > 2 l',
    22087010: 'likeuren in verpakkingen inhoudende <= 2 l',
    22087090: 'likeuren in verpakkingen inhoudende > 2 l',
    22089011: 'arak, in verpakkingen inhoudende <= 2 l',
    22089019: 'arak, in verpakkingen inhoudende > 2 l',
    22089033: 'pruimenbrandewijn, perenbrandewijn en kersenbrandewijn, in verpakkingen inhoudende <= 2 l',
    22089038: 'pruimenbrandewijn, perenbrandewijn en kersenbrandewijn, in verpakkingen inhoudende > 2 l',
    22089041: 'ouzo, in verpakkingen inhoudende <= 2 l',
    22089045: 'calvados, in verpakkingen inhoudende <= 2 l',
    22089048: 'gedistilleerde dranken uit fruit, in verpakkingen inhoudende <= 2 l (m.u.v. pruimenbrandewijn, perenbrandewijn, kersenbrandewijn en calvados)',
    22089054: 'tequila in verpakkingen inhoudende <= 2 l',
    22089056: 'gedistilleerde dranken in verpakkingen inhoudende <= 2 l (m.u.v. dranken gedistilleerd uit wijn of druivenmoer, whisky, rum en andere gedistilleerde dranken verkregen door het distilleren van gegiste suikerrietproducten, gin, jenever, arak, wodka, likeuren, ouzo, gedistilleerde dranken uit fruit en tequila)',
    22089069: 'dranken die gedistilleerde alcohol bevatten, in verpakkingen inhoudende <= 2 l (m.u.v. ouzo, gedistilleerde dranken en likeuren)',
    22089071: 'gedistilleerde dranken uit fruit, in verpakkingen inhoudende > 2 l (m.u.v. pruimenbrandewijn, perenbrandewijn en kersenbrandewijn)',
    22089075: 'tequila, in verpakkingen inhoudende > 2 l',
    22089077: 'gedistilleerde dranken, in verpakkingen inhoudende > 2 l (m.u.v. dranken gedistilleerd uit wijn of druivenmoer, whisky, rum, tafia, gin, jenever, arak, wodka, likeuren, ouzo, gedistilleerde dranken uit fruit en tequila)',
    22089078: 'dranken die gedistilleerde alcohol bevatten, in verpakkingen inhoudende > 2 l (m.u.v. gedistilleerde dranken, likeuren en ouzo)',
    22089091: 'ethylalcohol, niet gedenatureerd, met een alcohol-volumegehalte van < 80% vol, in verpakkingen inhoudende <= 2 l',
    22089099: 'ethylalcohol, niet gedenatureerd, met een alcohol-volumegehalte van < 80% vol, in verpakkingen inhoudende > 2 l',
    22090011: 'wijnazijn, in verpakkingen inhoudende <= 2 l',
    22090019: 'wijnazijn, in verpakkingen inhoudende > 2 l',
    22090091: 'tafelazijn, in verpakkingen inhoudende <= 2 l (m.u.v. wijnazijn)',
    22090099: 'tafelazijn, in verpakkingen inhoudende > 2 l (m.u.v. wijnazijn)',
    23011000: 'meel, poeder en pellets, van vlees of van slachtafvallen, ongeschikt voor menselijke consumptie; kanen',
    23012000: 'meel, poeder en pellets, van vis, van schaaldieren, van weekdieren of van andere ongewervelde waterdieren, ongeschikt voor menselijke consumptie',
    23021010: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van maïs, ook indien in pellets, met een zetmeelgehalte van <= 35 gewichtspercenten',
    23021090: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van maïs, ook indien in pellets, met een zetmeelgehalte van > 35 gewichtspercenten',
    23023010: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van tarwe, ook indien in pellets, met een zetmeelgehalte van <= 28 gewichtspercenten en waarvan <= 10 gewichtspercenten door een zeef met mazen van 0,2 mm valt of, indien > 10 gewichtspercenten van het product door de zeef valt, het asgehalte van het product dat door de zeef gevallen is, berekend op basis van de droge stof, >= 1,5 gewichtspercenten bedraagt',
    23023090: 'zemelen, slijpsel en ander resten van het zeven, van het malen of van andere bewerkingen van tarwe, ook indien in pellets (m.u.v. die met een zetmeelgehalte van <= 28 gewichtspercenten en waarvan <= 10 gewichtspercenten door een zeef met mazen van 0,2 mm valt of, indien > 10 gewichtspercenten van het product door de zeef valt, het asgehalte van het product dat door de zeef gevallen is, berekend op basis van de droge stof, >= 1,5 gewichtspercenten bedraagt)',
    23024002: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van rijst, ook indien in pellets, met een zetmeelgehalte van <= 35 gewichtspercenten',
    23024008: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van rijst, ook indien in pellets, met een zetmeelgehalte van > 35 gewichtspercenten',
    23024010: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van granen, ook indien in pellets, met een zetmeelgehalte van <= 28 gewichtspercenten, op voorwaarde dat <= 10 gewichtspercenten van het product door een zeef met mazen van 0,2 mm valt of, indien > 10 gewichtspercenten van het product door de zeef valt, het asgehalte van het door de zeef gevallen product, berekend op basis van de droge stof, >= 1,5 gewichtspercenten bedraagt (m.u.v. die van maïs, van rijst of van tarwe)',
    23024090: 'zemelen, slijpsel en andere resten van het zeven, van het malen of van andere bewerkingen van granen, ook indien in pellets (m.u.v. van maïs, van rijst of van tarwe; producten met een zetmeelgehalte van <= 28 gewichtspercenten, op voorwaarde dat <= 10 gewichtspercenten van het product door een zeef met mazen van 0,2 mm valt of, indien > 10 gewichtspercenten van het product door de zeef valt, het asgehalte van het door de zeef gevallen product, berekend op basis van de droge stof, >= 1,5 gewichtspercenten bedraagt)',
    23025000: 'resten van het zeven, van het malen of van andere bewerkingen van peulvruchten, ook indien in pellets',
    23031011: 'afvallen van maïszetmeelfabrieken, met een gehalte aan proteïnen, berekend op de droge stof, van > 40 gewichtspercenten (m.u.v. ingedikt zwelwater)',
    23031019: 'afvallen van maïszetmeelfabrieken, met een gehalte aan proteïnen, berekend op de droge stof, van <= 40 gewichtspercenten (m.u.v. ingedikt zwelwater)',
    23031090: 'afvallen van zetmeelfabrieken, incl. ingedikt zwelwater, e.d. afvallen (m.u.v. afvallen van maïszetmeelfabrieken)',
    23032010: 'Bietenpulp',
    23032090: 'uitgeperst suikerriet "ampas" en andere afvallen van de suikerindustrie (m.u.v. bietenpulp)',
    23033000: 'bostel "brouwerijafval" en afvallen van branderijen',
    23040000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van sojaolie, ook indien fijngemaakt of in pellets',
    23050000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van grondnotenolie, ook indien fijngemaakt of in pellets',
    23061000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van katoenzaad, ook indien fijngemaakt of in pellets',
    23062000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van lijnzaad, ook indien fijngemaakt of in pellets',
    23063000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van zonnebloempitten, ook indien fijngemaakt of in pellets',
    23064100: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van kool- of van raapzaad met een laag gehalte aan erucazuur "dat een vaste olie oplevert met een gehalte aan erucazuur van < 2 gewichtspercenten en een vast bestanddeel met een gehalte aan glucosinolaten van < 30 micromol per gram", ook indien fijngemaakt of in pellets',
    23064900: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van kool- of van raapzaad met een hoog gehalte aan erucazuur "dat een vaste olie oplevert met een gehalte aan erucazuur van >= 2 gewichtspercenten en een vast bestanddeel met een gehalte aan glucosinolaten van >= 30 micromol per gram", ook indien fijngemaakt of in pellets',
    23065000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van kokosnoten of van kopra, ook indien fijngemaakt of in pellets',
    23066000: 'perskoeken en andere vaste afvallen, verkregen bij de winning van vetten of oliën van palmnoten of van palmpitten, ook indien fijngemaakt of in pellets',
    23069005: 'perskoeken en andere vaste afvallen, verkregen bij de winning van plantaardige vetten of oliën van maïskiemen, ook indien fijngemaakt of in pellets',
    23069011: 'perskoeken van olijven en andere bij de winning van olijfolie verkregen afvallen, ook indien fijngemaakt of in pellets, met een gehalte aan olijfolie van <= 3 gewichtspercenten',
    23069019: 'perskoeken van olijven en andere bij de winning van olijfolie verkregen afvallen, ook indien fijngemaakt of in pellets, met een gehalte aan olijfolie van > 3 gewichtspercenten',
    23069090: 'perskoeken en andere vaste afvallen, verkregen bij de winning van plantaardige vetten of oliën, ook indien fijngemaakt of in pellets (m.u.v. van katoenzaad, van lijnzaad, van zonnebloempitten, van raap- of van koolzaad, van kokosnoten of van kopra, van palmnoten of van palmpitten, van maïskiemen; die verkregen bij de winning van olijfolie, sojaolie en grondnotenolie)',
    23070011: 'wijnmoer met een totaal alcohol-massagehalte van <= 7,9% mas en met een gehalte aan droge stof van >= 25 gewichtspercenten',
    23070019: 'wijnmoer (m.u.v. die met een totaal alcohol-massagehalte van <= 7,9% mas en met een gehalte aan droge stof van >= 25 gewichtspercenten)',
    23070090: 'ruwe wijnsteen',
    23080011: 'draf "droesem" van druiven, ook indien in pellets, van de soort gebruikt voor het voederen van dieren, met een totaal alcohol-massagehalte van <= 4,3% mas en met een gehalte aan droge stof van >= 40 gewichtspercenten',
    23080019: 'draf "droesem" van druiven, ook indien in pellets, van de soort gebruikt voor het voederen van dieren (m.u.v. dat met een totaal alcohol-massagehalte van <= 4,3% mas en met een gehalte aan droge stof van >= 40 gewichtspercenten)',
    23080040: 'eikels en wilde kastanjes, alsmede draf "droesem" van vruchten, van de soort gebruikt voor het voederen van dieren, ook indien in pellets (m.u.v. draf "droesem" van druiven)',
    23080090: 'stelen en bladeren van de maïsplant, afvallen van fruit en andere plantaardige zelfstandigheden, plantaardig afval, plantaardige residuen en bijproducten, ook indien in pellets, van de soort gebruikt voor het voederen van dieren, n.e.g. (m.u.v. eikels, wilde kastanjes en draf van vruchten)',
    23091011: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel of zuivelproducten of met een zetmeelgehalte van <= 10 gewichtspercenten en een gehalte aan zuivelproducten van < 10 gewichtspercenten',
    23091013: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 10 gewichtspercenten, doch < 50 gewichtspercenten',
    23091015: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 50 gewichtspercenten, doch < 75 gewichtspercenten',
    23091019: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 75 gewichtspercenten',
    23091031: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van > 10 doch <= 30 gewichtspercenten, geen zuivelproducten bevattend of met een gehalte aan zuivelproducten van < 10 gewichtspercenten',
    23091033: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van > 10 doch <= 30 gewichtspercenten en met een gehalte aan zuivelproducten van >= 10 doch minder dan < 50 gewichtspercenten',
    23091039: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een gehalte aan zuivelproducten van >= 50 gewichtspercenten',
    23091051: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van > 30 gewichtspercenten, geen zuivelproducten bevattende of met een gehalte aan zuivelproducten van < 10 gewichtspercenten',
    23091053: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van > 30 gewichtspercenten, en met een gehalte aan zuivelproducten van >= 10 gewichtspercenten doch < 50 gewichtspercenten',
    23091059: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van > 30 gewichtspercenten, en met een gehalte aan zuivelproducten van >= 50 gewichtspercenten',
    23091070: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, geen zetmeelglucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop doch wel zuivelproducten bevattend',
    23091090: 'honden- en kattenvoer, opgemaakt voor de verkoop in het klein, geen zetmeelglucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop en geen zuivelproducten bevattend',
    23099010: 'visperswater en perswater van zeezoogdieren "solubles", voor het aanvullen van het op de boerderij gewonnen voedsel',
    23099020: 'afvallen verkregen bij de vervaardiging van maïszetmeel overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 23, van de soort gebruikt voor het voederen van dieren (m.u.v. honden- en kattenvoer opgemaakt voor de verkoop in het klein)',
    23099031: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel bevattende of met een zetmeelgehalte van <= 10 gewichtspercenten, geen zuivelproducten bevattend of met een gehalte aan zuivelproducten van < 10 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099033: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel bevattende of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 10 doch minder dan < 50 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099035: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel bevattende of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 50 doch minder dan < 75 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099039: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch geen zetmeel bevattende of met een zetmeelgehalte van <= 10 gewichtspercenten en met een gehalte aan zuivelproducten van >= 75 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099041: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 10 doch <= 30 gewichtspercenten, geen zuivelproducten bevattend of met een gehalte aan zuivelproducten van <= 10 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099043: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 10 doch <= 30 gewichtspercenten, en met een gehalte aan zuivelproducten van > 10 doch <= 50 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099049: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 10 doch <= 30 gewichtspercenten, en met een gehalte aan zuivelproducten van >= 50 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099051: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 30 gewichtspercenten, geen zuivelproducten bevattend of met een gehalte aan zuivelproducten van <= 10 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099053: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 30 gewichtspercenten, met een gehalte aan zuivelproducten van >= 10 doch <= 50 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099059: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, bevattende glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, met een zetmeelgehalte van >= 30 gewichtspercenten en met een gehalte aan zuivelproducten van >= 50 gewichtspercenten (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099070: 'bereidingen, zog. "premelanges" daaronder begrepen, van de soort gebruikt voor het voederen van dieren, geen zetmeel, glucose "druivensuiker", glucosestroop, maltodextrine of maltodextrinestroop, doch wel zuivelproducten bevattend (m.u.v. honden- en kattenvoer, opgemaakt voor de verkoop in het klein)',
    23099091: 'bietenpulp waaraan melasse is toegevoegd',
    23099096: 'bereidingen van de soort gebruikt voor het voederen van dieren, geen zetmeel, glucose "druivensuiker", glucosestroop, maltodextrine, maltodextrinestroop en geen zuivelproducten bevattend (m.u.v. honden- en kattenvoer opgemaakt voor de verkoop in het klein; "solubles" van vissen en zeezoogdieren; afvallen verkregen bij de vervaardiging van maïszetmeel overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 23; bietenpulp met melasse; zog. "premelanges")',
    24011035: 'Tabak, "light-air-cured", ongestript',
    24011060: 'tabak van de soort Oriënt, "sun-cured", ongestript',
    24011070: 'tabak, "dark-air-cured", ongestript',
    24011085: 'Tabak, "flue-cured", ongestript',
    24011095: 'Tabak, ongestript (m.u.v. tabak, "flue-cured", "light-air-cured", "dark-air-cured" alsmede tabak van de soort Oriënt, "sun-cured")',
    24012035: 'Tabak, "light-air-cured", geheel of gedeeltelijk gestript, zonder verdere bereiding',
    24012060: 'tabak van de soort Oriënt, "sun-cured", geheel of gedeeltelijk gestript, zonder verdere bereiding',
    24012070: 'tabak, "dark-air-cured", geheel of gedeeltelijk gestript, zonder verdere bereiding',
    24012085: 'Tabak, "flue-cured", geheel of gedeeltelijk gestript, zonder verdere bereiding',
    24012095: 'Tabak, geheel of gedeeltelijk gestript, zonder verdere bereiding (m.u.v. tabak, "flue-cured", "light-air-cured", "dark-air-cured" alsmede tabak van de soort Oriënt, "sun-cured")',
    24013000: 'afvallen van tabak',
    24021000: 'sigaren en cigarillo\'s, tabak bevattend',
    24022010: 'sigaretten, tabak en kruidnagels bevattend',
    24022090: 'sigaretten, tabak bevattend (m.u.v. sigaretten, kruidnagels bevattend)',
    24029000: 'sigaren, cigarillo\'s en sigaretten, tabakssurrogaten bevattend',
    24031100: 'waterpijptabak zoals bedoeld in aanvullende aantekening 1 bij dit hoofdstuk (m.u.v. tabaksvrije producten)',
    24031910: 'rooktabak, ook indien tabakssurrogaten bevattend, ongeacht in welke verhouding, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van <= 500 g (m.u.v. waterpijptabak bevattende tabak)',
    24031990: 'rooktabak, ook indien tabakssurrogaten bevattend, ongeacht in welke verhouding, in verpakkingen met een netto-inhoud per onmiddellijke verpakking van > 500 g (m.u.v. waterpijptabak bevattende tabak)',
    24039100: 'tabak van gehomogeniseerde of gereconstitueerde fijngemaakte tabaksbladeren, afvallen van tabak of tabaksstof',
    24039910: 'pruimtabak en snuif',
    24039990: 'tabak en tabakssurrogaten, tot verbruik bereid, tabakspoeder, tabaksextracten en tabakssausen (m.u.v. pruimtabak, snuif, sigaren, sigaretten, cigarillo\'s en rooktabak, ook indien tabakssurrogaten bevattend, ongeacht in welke verhouding, gehomogeniseerde of gereconstitueerde tabak, alsmede nicotine welke uit de tabaksplant wordt geëxtraheerd en insectendodende middelen, vervaardigd van tabaksextracten of tabakssausen)',
    25010010: 'zeewater en moederloog',
    25010031: 'zout bestemd om chemisch te worden verwerkt, namelijk het scheiden van natrium en chloor, voor de vervaardiging van andere producten',
    25010051: 'zout, gedenatureerd, dan wel bestemd voor ander industrieel gebruik, incl. raffineren (m.u.v. zout bestemd om chemisch te worden verwerkt of bestemd voor het conserveren of het bereiden van producten voor menselijke of dierlijke consumptie)',
    25010091: 'zout geschikt voor menselijke consumptie',
    25010099: 'zout en zuiver natriumchloride, ook indien in waterige oplossing of met toegevoegde zelfstandigheden om het klonteren tegen te gaan of om de strooibaarheid te bevorderen (m.u.v. keuken- en tafelzout, zout bestemd om chemisch te worden verwerkt "scheiden van natrium en chloor", gedenatureerd zout en zout bestemd voor industrieel-technisch gebruik)',
    25020000: 'ijzerkies, ongeroost',
    25030010: 'ruwe zwavel en niet-geraffineerde zwavel (m.u.v. gesublimeerde, geprecipiteerde en colloïdale zwavel)',
    25030090: 'zwavel van alle soorten (m.u.v. ruwe zwavel en niet-geraffineerde zwavel en m.u.v. gesublimeerde, geprecipiteerde en colloïdale zwavel)',
    25041000: 'natuurlijk grafiet, in poeder of in schilfers',
    25049000: 'natuurlijk grafiet (m.u.v. grafiet in poeder of in schilfers)',
    25051000: 'kiezelzand en kwartszand, ook indien gekleurd',
    25059000: 'natuurlijk zand van alle soorten, ook indien gekleurd (m.u.v. goudzand en platinahoudend zand, zirkoonzand, rutielzand en ilmenietzand, monazietzand, teer- of asfaltzand, kiezelzand en kwartszand)',
    25061000: 'kwarts (m.u.v. kwartszand)',
    25062000: 'kwartsiet, ook indien enkel kantrecht behouwen, in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze',
    25070020: 'kaolien',
    25070080: 'kaolienhoudende klei (m.u.v. kaolien)',
    25081000: 'bentoniet',
    25083000: 'vuurvaste klei (m.u.v. kaolien en andere kaolienhoudende klei en m.u.v. geëxpandeerde klei)',
    25084000: 'klei (m.u.v. vuurvaste klei, betoniet, kaolien en andere kaolienhoudende klei en m.u.v. geëxpandeerde klei)',
    25085000: 'andalusiet, kyaniet en sillimaniet',
    25086000: 'mulliet',
    25087000: 'chamotte- en dinasaarde',
    25090000: 'krijt',
    25101000: 'natuurlijk calciumfosfaat, natuurlijk aluminiumcalciumfosfaat en gefosfateerd krijt, ongemalen',
    25102000: 'natuurlijk calciumfosfaat, natuurlijk aluminiumcalciumfosfaat en gefosfateerd krijt, gemalen',
    25111000: 'natuurlijk bariumsulfaat "zwaarspaat, bariet"',
    25112000: 'natuurlijk bariumcarbonaat "witheriet", ook indien gebrand',
    25120000: 'diatomeeënaarde "b.v. kiezelgoer, bergmeel, diatomiet" en andere dergelijke kiezelaarden met een schijnbare dichtheid van <= 1',
    25131000: 'puimsteen',
    25132000: 'amaril, natuurlijk korund, natuurlijk granaat en andere natuurlijke schuur-, slijp- en polijstmiddelen, ook indien zij een warmtebehandeling hebben ondergaan',
    25140000: 'leisteen, ook indien enkel kantrecht behouwen, dan wel in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze; leisteenpoeder en afval van leisteen',
    25151100: 'marmer en travertijn, onbewerkt of enkel kantrecht behouwen',
    25151200: 'Marmer en travertijn, in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze',
    25152000: 'ecaussine en andere kalksteen voor de steenhouwerij of voor het bouwbedrijf, met een schijnbare dichtheid van >= 2,5, en albast, ook indien enkel kantrecht behouwen, dan wel in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze (m.u.v. korrels, scherven, splinters en poeder van deze steensoorten en m.u.v. marmer en travertijn)',
    25161100: 'graniet, onbewerkt of enkel kantrecht behouwen (m.u.v. graniet dat reeds de typische kenmerken van stenen voor bestrating, plaveien en trottoirbanden heeft)',
    25161200: 'Graniet, in blokken of platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze (m.u.v. graniet dat reeds de typische kenmerken van stenen voor bestrating, plaveien en trottoirbanden heeft)',
    25162000: 'zandsteen, onbewerkt of enkel kantrecht behouwen, in blokken of platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze (m.u.v. zandsteen dat reeds de typische kenmerken van stenen voor bestrating, plaveien en trottoirbanden heeft)',
    25169000: 'porfier, basalt en andere natuursteen voor de steenhouwerij of voor het bouwbedrijf, ook indien enkel kantrecht behouwen, dan wel in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze (m.u.v. korrels, scherven, splinters en poeder van deze steensoorten, m.u.v. stenen die reeds de typische kenmerken van stenen voor bestrating, plaveien en trottoirbanden hebben en m.u.v. natuursteen voor de steenhouwerij of voor het bouwbedrijf met een schijnbare dichtheid van >= 2,5, graniet en zandsteen)',
    25171010: 'keistenen en grind, van de soort gewoonlijk gebruikt voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast, rolstenen en vuurstenen, ook indien zij een warmtebehandeling hebben ondergaan',
    25171020: 'Steenslag van dolomiet en van kalksteen, van de soort gewoonlijk gebruikt voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast',
    25171080: 'steenslag van de soort gewoonlijk gebruikt voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast, ook indien het een warmtebehandeling heeft ondergaan (m.u.v. keistenen, grind, vuurstenen, rolstenen; steenslag van dolomiet en van kalksteen)',
    25172000: 'macadam van hoogovenslakken, van metaalslakken of van dergelijke industriële afvallen, ook indien dit keistenen, grint en steenslag, van de soort gewoonlijk gebruikt voor voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast, of rolstenen en vuurstenen bevat',
    25173000: 'teermacadam',
    25174100: 'korrels, scherven, splinters en poeder van marmer, ook indien zij een warmtebehandeling hebben ondergaan',
    25174900: 'korrels, scherven, splinters en poeder van travertijn, ecaussine, albast, graniet, porfier, cyeniet, lava, basalt, gneis, trachiet of van andere steensoorten bedoeld bij de posten 2515 en 2516, ook indien zij een warmtebehandeling hebben ondergaan (m.u.v. die van marmer)',
    25181000: 'dolomiet, onbewerkt, ongebrand en ongesinterd, daaronder begrepen dolomiet enkel kantrecht behouwen, dan wel in blokken of in platen van vierkante of rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze (m.u.v. steenslag van dolomiet voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast)',
    25182000: 'dolomiet, gebrand of gesinterd (m.u.v. steenslag van dolomiet voor de vervaardiging van beton, voor het verharden van wegen, als ballastbed voor spoorwegen of als andere ballast)',
    25183000: 'stamp- en strijkmassa van dolomiet',
    25191000: 'natuurlijk magnesiumcarbonaat "magnesiet"',
    25199010: 'magnesiumoxide, ook indien zuiver (m.u.v. gebrand natuurlijk magnesiumcarbonaat)',
    25199030: 'doodgebrande magnesia "gesinterd", ook indien geringe hoeveelheden andere oxiden bevattend die vóór het sinteren zijn toegevoegd',
    25199090: 'gesmolten magnesia',
    25201000: 'gips; anhydriet',
    25202000: 'Gebrand gips, ook indien gekleurd of met toevoeging van kleine hoeveelheden bindingsversnellers of -vertragers',
    25210000: 'kalksteen voor hoogoventoeslag; kalksteen voor de vervaardiging van kalk of van cement',
    25221000: 'ongebluste kalk',
    25222000: 'gebluste kalk',
    25223000: 'hydraulische kalk (m.u.v. calciumoxide en calciumhydroxide)',
    25231000: 'cementklinker',
    25232100: 'portlandcement, wit, ook indien kunstmatig gekleurd',
    25232900: 'portlandcement, normaal of gemodereerd (m.u.v. wit portlandcement, ook indien kunstmatig gekleurd)',
    25233000: 'aluminiumcement',
    25239000: 'hydraulisch cement, ook indien gekleurd (m.u.v. portlandcement en aluminiumcement)',
    25241000: 'crocidoliet (m.u.v.werken daarvan)',
    25249000: 'asbest (m.u.v. crocidoliet en werken van asbest)',
    25251000: 'ruw mica, mica in bladen en micasplittings',
    25252000: 'micapoeder',
    25253000: 'afval van mica',
    25261000: 'natuurlijk speksteen, ook indien enkel kantrecht behouwen, dan wel in blokken of in platen van vierkante of van rechthoekige vorm, verkregen door zagen, door splijten of op dergelijke wijze, en talk, niet fijngemaakt, noch gemalen',
    25262000: 'natuurlijk speksteen, fijngemaakt of gemalen "talk"',
    25280000: 'Natuurlijke boraten en concentraten daarvan, ook indien gebrand, en natuurlijk boorzuur met een gehalte aan H3BO3 van <= 85 gewichtspercenten, berekend op de droge stof (m.u.v. boraten verkregen uit natuurlijke pekel "brijn")',
    25291000: 'veldspaat',
    25292100: 'vloeispaat, bevattende <= 97 gewichtspercenten calciumfluoride',
    25292200: 'vloeispaat, bevattende > 97 gewichtspercenten calciumfluoride',
    25293000: 'leuciet; nefelien en nefelien-syeniet',
    25301000: 'Niet-geëxpandeerd vermiculiet, perliet en chloriet',
    25302000: 'kieseriet, epsomiet "natuurlijk magnesiumsulfaat"',
    25309000: 'arseensulfide, aluniet "aluinsteen", puzzolaanaarde, verfaarden en minerale stoffen, n.e.g.',
    26011100: 'ijzererts en concentraten daarvan, ongeagglomereerd (m.u.v. geroost ijzerkies "pyrietas")',
    26011200: 'ijzererts en concentraten daarvan, geagglomereerd (m.u.v. geroost ijzerkies "pyrietas")',
    26012000: 'geroost ijzerkies "pyrietas"',
    26020000: 'Mangaanerts en concentraten daarvan, incl. ijzerhoudend mangaanerts en concentraten daarvan, met een mangaangehalte van >= 20 gewichtspercenten, berekend op de droge stof',
    26030000: 'kopererts en concentraten daarvan',
    26040000: 'nikkelerts en concentraten daarvan',
    26050000: 'kobalterts en concentraten daarvan',
    26060000: 'aluminiumerts en concentraten daarvan',
    26070000: 'looderts en concentraten daarvan',
    26080000: 'zinkerts en concentraten daarvan',
    26090000: 'tinerts en concentraten daarvan',
    26100000: 'chroomerts en concentraten daarvan',
    26110000: 'wolfraamerts en concentraten daarvan',
    26121010: 'uraniumerts en pekblende en concentraten daarvan, met een uraniumgehalte van > 5 gewichtspercenten "Euratom"',
    26121090: 'uraniumerts en concentraten daarvan (m.u.v. uraniumerts en pekblende en concentraten daarvan, met een uraniumgehalte van > 5 gewichtspercenten)',
    26122010: 'monaziet; uranium-thorianiet en andere thoriumertsen en concentraten daarvan, met een thoriumgehalte van > 20 gewichtspercenten "Euratom"',
    26122090: 'thoriumerts en concentraten daarvan (m.u.v. monaziet en m.u.v. uranium-thorianiet en andere thoriumertsen en concentraten daarvan, met een thoriumgehalte van > 20 gewichtspercenten)',
    26131000: 'molybdeenerts en concentraten daarvan, geroost',
    26139000: 'molybdeenerts en concentraten daarvan (m.u.v. geroost erts en gerooste concentraten)',
    26140000: 'Titaanerts en concentraten daarvan',
    26151000: 'zirkoniumerts en concentraten daarvan',
    26159000: 'Niobium-, tantaal- of vanadiumerts en concentraten daarvan',
    26161000: 'zilvererts en concentraten daarvan',
    26169000: 'ertsen van edele metalen en concentraten daarvan (m.u.v. zilvererts en concentraten daarvan)',
    26171000: 'antimoonerts en concentraten daarvan',
    26179000: 'ertsen en concentraten daarvan (m.u.v. ertsen van edele metalen, ijzer-, mangaan-, koper-, nikkel-, kobalt-, aluminium-, lood-, zink-, tin-, chroom-, wolfraam, uranium-, thorium-, molybdeen-, titaan, niobium-, tantaal-, vanadium-, zirkonium- en antimoonerts en concentraten daarvan)',
    26180000: 'Gegranuleerde hoogovenslakken "slakkenzand" verkregen bij de vervaardiging van ijzer en staal',
    26190020: 'bij de vervaardiging van ijzer en staal verkregen afval, geschikt voor het recupereren van ijzer of van mangaan',
    26190090: 'slakken, walsschilfers en ander bij de vervaardiging van ijzer en staal verkregen afval (m.u.v. gegranuleerde hoogovenslakken, afval geschikt voor het recupereren van ijzer of van mangaan)',
    26201100: 'hardzink',
    26201900: 'assen en residuen, hoofdzakelijk zink bevattend (m.u.v. hardzink)',
    26202100: 'slib van loodhoudende benzine en slib van loodhoudende antiklopmiddelen, afkomstig uit opslagtanks van loodhoudende benzine en loodhoudende antiklopmiddelen, hoofdzakelijk bestaande uit lood, loodverbindingen en ijzeroxide',
    26202900: 'assen en residuen, hoofdzakelijk lood bevattend (m.u.v. slib van loodhoudende benzine en slib van loodhoudende antiklopmiddelen)',
    26203000: 'assen en residuen, hoofdzakelijk koper bevattend',
    26204000: 'assen en residuen, hoofdzakelijk aluminium bevattend',
    26206000: 'assen en residuen, arseen, kwik, tallium of mengsels daarvan bevattend, van de soort gebruikt voor het winnen van arseen of van deze metalen of voor het vervaardigen van chemische verbindingen daarvan (m.u.v. die verkregen bij de vervaardiging van ijzer en staal)',
    26209100: 'assen en residuen, antimoon, beryllium, cadmium, chroom of mengsels daarvan bevattend (m.u.v. die verkregen bij de vervaardiging van ijzer en staal)',
    26209910: 'assen en residuen, hoofdzakelijk nikkel bevattend',
    26209920: 'assen en residuen, hoofdzakelijk niobium of tantaal bevattend',
    26209940: 'assen en residuen, hoofdzakelijk tin bevattend',
    26209960: 'assen en residuen, hoofdzakelijk titaan bevattend',
    26209995: 'assen en residuen die metaal of metaalverbindingen bevatten (m.u.v. die verkregen bij de vervaardiging van ijzer en staal, die welke hoofdzakelijk zink, lood, koper, aluminium, nikkel, niobium, tantaal, tin of titaan bevatten, die welke arseen, kwik, tallium of mengsels daarvan bevatten, van de soort gebruikt voor het winnen van arseen of van deze metalen of voor het vervaardigen van chemische verbindingen daarvan en die welke antimoon, beryllium, cadmium, chroom of mengsels daarvan bevatten)',
    26211000: 'assen en residuen afkomstig van de verbranding van stedelijk afval',
    26219000: 'slakken en assen, as van zeewier daaronder begrepen (m.u.v. bij de vervaardiging van ijzer en staal verkregen slakken, gegranuleerde hoogovenslakken daaronder begrepen, assen en residuen die arseen, metaal of metaalverbindingen bevatten en assen en residuen afkomstig van de verbranding van stedelijk afval)',
    27011100: 'antraciet, ook indien in poedervorm (m.u.v. geperste steenkool)',
    27011210: 'bitumineuze cokeskolen, ook indien in poedervorm (m.u.v. van geperste steenkool)',
    27011290: 'bitumineuze steenkool, ook indien in poedervorm (m.u.v. van cokeskolen en met uitzondering van geperste steenkool)',
    27011900: 'steenkool, ook indien in poedervorm (m.u.v. antraciet en bitumineuze steenkool en m.u.v. geperste steenkool)',
    27012000: 'briketten, eierkolen e.d. van steenkool vervaardigde brandstoffen',
    27021000: 'bruinkool, ook indien in poedervorm (m.u.v. geperste bruinkool)',
    27022000: 'geperste bruinkool',
    27030000: 'turf, incl. turfstrooisel, ook indien geperst',
    27040010: 'cokes en halfcokes, van steenkool, ook indien geperst',
    27040030: 'cokes en halfcokes, van bruinkool, ook indien geperst',
    27040090: 'cokes en halfcokes, van turf, ook indien geagglomereerd en geperst, alsmede retortenkool',
    27050000: 'steenkoolgas, watergas, generatorgas e.d. gassen (m.u.v. van aardgas en andere gasvormige koolwaterstoffen)',
    27060000: 'teer uit steenkool, uit bruinkool of uit turf en andere minerale teersoorten, ook indien gedehydreerd of gedeeltelijk gedistilleerd "topped", incl. zgn. mengteer',
    27071000: 'Benzol "benzeen", > 50 gewichtspercenten benzeen bevattend (m.u.v. chemisch welbepaalde verbindingen)',
    27072000: 'Toluol "tolueen", > 50 gewichtspercenten tolueen bevattend (m.u.v. chemisch welbepaalde verbindingen)',
    27073000: 'Xylol "xylenen", > 50 gewichtspercenten xyleen bevattend (m.u.v. chemisch welbepaald verbindingen)',
    27074000: 'naftaleen, > 50 gewichtspercenten naftaleen bevattend (m.u.v. chemisch welbepaalde verbindingen)',
    27075000: 'Mengsels van aromatische koolwaterstoffen die, distillatieverliezen inbegrepen, voor >= 65% van hun volume overdistilleren bij <= 250 °C, bepaald volgens de methode ASTM D 86 (m.u.v. chemisch welbepaalde verbindingen)',
    27079100: 'creosootolie (m.u.v. chemisch welbepaalde verbindingen)',
    27079911: 'ruwe lichte oliën, verkregen bij het distilleren van hoge-temperatuur-steenkoolteer, die voor >= 90% van hun volume overdistilleren bij <= 200 °C (m.u.v. chemisch welbepaalde verbindingen)',
    27079919: 'ruwe oliën, verkregen bij het distilleren van hoge-temperatuur-steenkoolteer (m.u.v. van ruwe lichte oliën die voor >= 90% van hun volume overdistilleren bij <= 200 °C en m.u.v. chemisch welbepaalde verbindingen)',
    27079920: 'zwavelhoudende "tops", verkregen bij de eerste distillatie van hoge-temperatuur-steenkoolteer en antraceen (m.u.v. chemisch welbepaalde verbindingen)',
    27079950: 'pyridine-, chinoline-, acridine- en anilinebasen en andere teeroliebasen, verkregen bij de distillatie van hoge-temperatuur-steenkoolteer, n.e.g.',
    27079980: 'fenolen, > 50 gewichtspercenten fenolen bevattend (m.u.v. chemisch welbepaalde verbindingen)',
    27079991: 'olie en andere producten, verkregen bij het distilleren van hoge-temperatuur-steenkoolteer, alsmede soortgelijke producten waarin het gewicht van de aromatische bestanddelen dat van de niet-aromatische overtreft, bestemd voor de vervaardiging van koolstof "carbonblack" als bedoeld in post 2803',
    27079999: 'olie en andere producten, verkregen bij het distilleren van hoge-temperatuur-steenkoolteer, alsmede soortgelijke producten waarin het gewicht van de aromatische bestanddelen dat van de niet-aromatische overtreft, n.e.g.',
    27081000: 'pek van steenkoolteer of van andere minerale teer',
    27082000: 'pecokes van steenkoolteer of van andere minerale teer',
    27090010: 'aardgascondensaten',
    27090090: 'ruwe aardolie en ruwe olie uit bitumineuze mineralen (m.u.v. aardgascondensaten)',
    27101211: 'lichte oliën uit aardolie of uit bitumineuze mineralen bestemd om een aangewezen behandeling als bedoeld in aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan (m.u.v biodiesel bevattend)',
    27101215: 'lichte oliën uit aardolie of uit bitumineuze mineralen, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27, en biodiesel bevattend)',
    27101221: 'white spirit',
    27101225: 'speciale lichte oliën (m.u.v. "white spirit") uit aardolie of uit bitumineuze mineralen',
    27101231: 'vliegtuigbenzine',
    27101241: 'motorbenzine ,geen biodiesel bevattend, met een loodgehalte van <= 0,013 g/l en een research-octaangetal "RON" van < 95',
    27101245: 'motorbenzine, geen biodiesel bevattend,  met een loodgehalte van <= 0,013 g/l en een research-octaangetal "RON" van        >= 95 doch < 98',
    27101249: 'motorbenzine, geen biodiesel bevattend,  met een loodgehalte van <= 0,013 g/l en een research-octaangetal "RON" van >= 98',
    27101250: 'motorbenzine met een loodgehalte van > 0,013 g/l  (m.u.v. biodiesel en vliegtuigbenzine)',
    27101270: 'lichte reactiemotorbrandstof (m.u.v. vliegtuigbenzine)',
    27101290: 'lichte oliën en preparaten uit aardolie of uit bitumineuze mineralen, geen biodiesel bevattend,  n.e.g. (m.u.v. die bestemd om chemisch te worden verwerkt en m.u.v. speciale lichte oliën, motorbenzine en lichte reactiemotorbrandstof)',
    27101911: 'halfzware oliën uit aardolie of uit bitumineuze mineralen, bestemd om een aangewezen behandeling overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan',
    27101915: 'halfzware oliën uit aardolie of bitumineuze mineralen, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27)',
    27101921: 'halfzware reactiemotorbrandstof',
    27101925: 'kerosine "lamppetroleum of lampolie" (m.u.v. reactiemotorbrandstof)',
    27101929: 'halfzware oliën uit aardolie of uit bitumineuze mineralen, n.e.g. (m.u.v. die bestemd om chemisch te worden verwerkt of om een aangewezen behandeling overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan en m.u.v. kerosine "lamppetroleum of lampolie)',
    27101931: 'gasolie uit aardolie of uit bitumineuze mineralen, bestemd om een aangewezen behandeling overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan',
    27101935: 'gasolie uit aardolie of bitumineuze mineralen, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27)',
    27101943: 'gasolie uit aardolie of bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van <= 0,001 gewichtspercent (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101946: 'gasolie uit aardolie of bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van > 0,001 doch <= 0,002 gewichtspercenten (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101947: 'gasolie uit aardolie of bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van > 0,002 doch <= 0,1 gewichtspercenten (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101948: 'gasolie uit aardolie of uit bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van > 0,1 gewichtspercent (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101951: 'stookolie uit aardolie of uit bitumineuze mineralen, bestemd om een aangewezen behandeling overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan (m.u.v. biodiesel bevattend)',
    27101955: 'stookolie uit aardolie of uit bitumineuze mineralen, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27 en biodiesel bevattend)',
    27101962: 'stookolie uit aardolie of uit bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van <= 0,1 gewichtspercent (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101966: 'stookolie uit aardolie of uit bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van > 0,1 doch <= 0,5 gewichtspercenten (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101967: 'stookolie uit aardolie of uit bitumineuze mineralen, geen biodiesel bevattend, met een zwavelgehalte van > 0,5 gewichtspercenten (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101971: 'smeerolie en andere oliën, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald, bestemd om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan',
    27101975: 'smeerolie en andere oliën, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27)',
    27101981: 'motorolie, compressorolie, turbineolie, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101983: 'Oliën voor hydraulische krachtoverbrenging, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101985: 'white oils, paraffinum liquidum, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101987: 'versnellingsbakolie, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101991: 'olie voor de metaalbewerking, olie voor het insmeren van vormen, corrosiewerende olie, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101993: 'isolatieolie, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat en waarvan het karakter door deze olie wordt bepaald (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27101999: 'smeerolie en andere oliën, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten en waarvan het karakter door deze olie wordt bepaald, n.e.g. (m.u.v. die bestemd om chemisch te worden verwerkt)',
    27102011: 'gasolie die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat, biodiesel bevattend, met een zwavelgehalte van <= 0,001 gewichtspercent',
    27102016: 'gasolie die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat, biodiesel bevattend, met een zwavelgehalte van > 0,001 doch <= 0,1 gewichtspercent',
    27102019: 'gasolie die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat, biodiesel bevattend, met een zwavelgehalte van > 0,1 gewichtspercent',
    27102032: 'stookolie die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat, biodiesel bevattend, met een zwavelgehalte van <= 0,5 gewichtspercent',
    27102038: 'stookolie die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevat, biodiesel bevattend, met een zwavelgehalte van > 0,5 gewichtspercent',
    27102090: 'Oliën, die >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten, biodiesel bevattend (m.u.v. gasolie en stookolie)',
    27109100: 'afvalolie, bevattende polychloorbifenylen "PCB\'s", polychloorterfenylen "PCT\'s" of polybroombifenylen "PBB\'s"',
    27109900: 'afvalolie die hoofdzakelijk aardolie en olie uit bitumineuze mineralen bevat (m.u.v. die welke polychloorbifenylen "PCB\'s", polychloorterfenylen "PCT\'s" of polybroombifenylen "PBB\'s" bevatten)',
    27111100: 'vloeibaar gemaakt aardgas',
    27111211: 'vloeibaar gemaakt propaan, met een zuiverheidsgraad van >= 99%, bestemd om te worden gebezigd als motorbrandstof of als andere brandstof',
    27111219: 'vloeibaar gemaakt propaan, met een zuiverheidsgraad van >= 99% (m.u.v. die bestemd om te worden gebezigd als motorbrandstof of als andere brandstof)',
    27111291: 'vloeibaar gemaakt propaan, met een zuiverheidsgraad van < 99%, bestemd om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan',
    27111293: 'vloeibaar gemaakt propaan, met een zuiverheidsgraad van < 99%, bestemd om chemisch te worden verwerkt (m.u.v. de werkwijzen als bedoeld bij onderverdeling 2711.12.91)',
    27111294: 'vloeibaar gemaakt propaan met een zuiverheidsgraad van > 90 doch < 99% (m.u.v. dat bestemd om chemisch te worden verwerkt of om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan)',
    27111297: 'vloeibaar gemaakt propaan met een zuiverheidsgraad van <= 90% (m.u.v. dat bestemd om chemisch te worden verwerkt of om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan)',
    27111310: 'vloeibaar gemaakte butanen, bestemd om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan (m.u.v. die met een zuiverheidsgraad van >= 95% aan N-butaan of isobutaan)',
    27111330: 'vloeibaar gemaakte butanen, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27; butanen met een zuiverheidsgraad van >= 95% aan N-butaan of isobutaan)',
    27111391: 'vloeibaar gemaakte butanen met een zuiverheidsgraad van > 90 doch < 95% (m.u.v. die bestemd om chemisch te worden verwerkt of om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan)',
    27111397: 'vloeibaar gemaakte butanen met een zuiverheidsgraad van <= 90% (m.u.v. die bestemd om chemisch te worden verwerkt of om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan)',
    27111400: 'ethyleen, propyleen, butyleen en butadieen, vloeibaar gemaakt (m.u.v. ethyleen met een zuiverheidsgraad van >= 95% en propyleen, butyleen en butadieen met een zuiverheid van >= 90%)',
    27111900: 'gasvormige koolwaterstoffen, vloeibaar gemaakt, n.e.g. (m.u.v. aardgas, propaan, butanen, ethyleen, propyleen, butyleen en butadieen)',
    27112100: 'aardgas in gasvormige toestand',
    27112900: 'koolwaterstoffen in gasvormige toestand, n.e.g. (m.u.v. aardgas)',
    27121010: 'ruwe vaseline',
    27121090: 'gezuiverde vaseline',
    27122010: 'paraffine, synthetisch, bevattende < 0,75 gewichtspercent olie, met een molecuulgewicht van >= 460 doch <= 1.560',
    27122090: 'paraffine bevattende < 0,75 gewichtspercent olie (m.u.v. synthetische paraffine met een molecuulgewicht van >= 460 doch <= 1.560)',
    27129011: 'ozokeriet, montaanwas en turfwas "natuurlijke producten", ruw',
    27129019: 'ozokeriet, montaanwas en turfwas "natuurlijke producten", gezuiverd, ook indien gekleurd',
    27129031: 'paraffine, microkristallijne was uit aardolie, "slack wax", andere minerale was e.d. door synthetische of op andere wijze verkregen producten, ruw, bestemd om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan (m.u.v. vaseline, paraffine bevattende < 0,75 gewichtspercent olie, ozokeriet, montaanwas en turfwas)',
    27129033: 'paraffine, microkristallijne was uit aardolie, "slack wax", andere minerale was e.d. door synthese of op andere wijze verkregen producten, ruw, bestemd om chemisch te worden verwerkt (m.u.v. die bestemd om een aangewezen behandeling te ondergaan overeenkomstig aanvullende aantekening [GN] 5 op hoofdstuk 27; vaseline; paraffine bevattende < 0,75 gewichtspercent olie; ozokeriet, montaanwas en turfwas)',
    27129039: 'paraffine, microkristallijne was uit aardolie, "slack wax", andere minerale was e.d. door synthetische of op andere wijze verkregen producten, ruw (m.u.v. die bestemd om chemisch te worden verwerkt of om een aangewezen behandeling in de zin van aanvullende aantekening [GN] 5 op hoofdstuk 27 te ondergaan en m.u.v. vaseline, paraffine bevattende < 0,75 gewichtspercent olie, ozokeriet, montaanwas en turfwas)',
    27129091: 'mengsels van 1-alkenen bevattende >= 80 gewichtspercenten 1-alkenen met een ketenlengte van >= 24 doch <= 28 koolstofatomen',
    27129099: 'paraffine, microkristallijne was uit aardolie, "slack wax", ozokeriet, montaanwas, turfwas, andere minerale was e.d. door synthese of op andere wijze verkregen producten, ook indien gekleurd (m.u.v. vaseline; paraffine bevattende < 0,75 gewichtspercent olie; mengsels van 1-alkenen bevattende >= 80 gewichtspercenten 1-alkenen met een ketenlengte van >= 24 doch <= 28 koolstofatomen)',
    27131100: 'niet-gecalcineerde petroleumcokes',
    27131200: 'gecalcineerde petroleumcokes',
    27132000: 'petroleumbitumen',
    27139010: 'residuen van aardolie of van olie uit bitumineuze mineralen, bestemd voor de vervaardiging van koolstof als bedoeld bij post 2803',
    27139090: 'residuen van aardolie of van olie uit bitumineuze mineralen (m.u.v. die bestemd voor de vervaardiging van koolstof als bedoeld bij post 2803 en m.u.v. petroleumcokes en petroleumbitumen)',
    27141000: 'bitumineuze leisteen en bitumineus zand',
    27149000: 'natuurlijk bitumen en natuurlijk asfalt; asfaltiet en asfaltsteen',
    27150000: 'bitumineuze mastiek, vloeibitumen of koudasfalt "cutback" en andere bitumineuze mengsels van natuurlijk asfalt, van natuurlijk bitumen, van petroleumbitumen, van minerale teer of van minerale teerpek',
    27160000: 'elektrische energie',
    28011000: 'chloor',
    28012000: 'jood "jodium"',
    28013010: 'fluor',
    28013090: 'broom',
    28020000: 'gesublimeerde of geprecipiteerde zwavel; colloïdale zwavel',
    28030000: 'Koolstof "carbonblack en andere vormen van koolstof", n.e.g.',
    28041000: 'waterstof',
    28042100: 'argon',
    28042910: 'helium',
    28042990: 'neon, krypton en xenon',
    28043000: 'stikstof',
    28044000: 'zuurstof',
    28045010: 'boor "borium"',
    28045090: 'telluur "tellurium"',
    28046100: 'silicium, bevattende >= 99,99 gewichtspercenten silicium',
    28046900: 'silicium, bevattende < 99,99 gewichtspercenten silicium',
    28047000: 'fosfor',
    28048000: 'arseen "arsenicum"',
    28049000: 'seleen "selenium"',
    28051100: 'natrium',
    28051200: 'calcium',
    28051910: 'strontium en barium',
    28051990: 'alkalimetalen (m.u.v. natrium)',
    28053010: 'zeldzame aardmetalen, scandium en yttrium, onderling vermengd of onderling gelegeerd',
    28053020: 'cerium, lanthaan, praseodymium, neodymium en samarium, met een zuiverheid van 95 of meer gewichtspercenten (m.u.v. onderling vermengd of onderling gelegeerd)',
    28053030: 'europium, gadolinium, terbium, dysprosium, holmium, erbium, thulium, ytterbium, lutetium en yttrium, met een zuiverheid van 95 of meer gewichtspercenten (m.u.v. onderling vermengd of onderling gelegeerd)',
    28053040: 'scandium met een zuiverheid van 95 of meer gewichtspercenten (m.u.v. onderling vermengd of onderling gelegeerd)',
    28053080: 'zeldzame aardmetalen, scandium en yttrium, met een zuiverheid van minder dan 95 gewichtspercenten (m.u.v. onderling vermengd of onderling gelegeerd)',
    28054010: 'kwik "kwikzilver" in ijzeren of stalen flessen met een netto-inhoud van 34,5 kg "standaardgewicht" en waarvan de waarde fob, per fles, <= 224 € bedraagt',
    28054090: 'kwik "kwikzilver" (m.u.v. die in ijzeren of stalen flessen met een netto-inhoud van 34,5 kg "standaardgewicht" en waarvan de waarde fob, per fles, <= 224 € bedraagt)',
    28061000: 'waterstofchloride "zoutzuur"',
    28062000: 'chlorozwavelzuur',
    28070000: 'zwavelzuur en oleum "rokend zwavelzuur"',
    28080000: 'salpeterzuur; nitreerzuren',
    28091000: 'difosforpentaoxide "fosforzuuranhydride"',
    28092000: 'fosforzuur en polyfosforzuren, ook indien chemisch welbepaald',
    28100010: 'diboortrioxide',
    28100090: 'booroxiden; boorzuren (m.u.v. diboortrioxide)',
    28111100: 'waterstoffluoride "fluorwaterstof"',
    28111200: 'hydrogeencyanide "blauwzuur"',
    28111910: 'hydrogeenbromide "broomwaterstof"',
    28111980: 'anorganische zuren (m.u.v. waterstofchloride "zoutzuur", chlorozwavelzuur, zwavelzuur, oleum "rokend zwavelzuur", salpeterzuur, nitreerzuren, fosforzuur en polyfosforzuren, boorzuren, waterstoffluoride "fluorwaterstof", hydrogeenbromide "broomwaterstof" en hydrogeencyanide "blauwzuur")',
    28112100: 'koolstofdioxide "kooldioxide"',
    28112200: 'siliciumdioxide',
    28112905: 'zwaveldioxide',
    28112910: 'zwaveltrioxide; diarseentrioxide',
    28112930: 'stikstofoxiden',
    28112990: 'anorganische zuurstofverbindingen van niet-metalen (m.u.v. difosforpentaoxide, booroxiden, koolstofdioxide "kooldioxide", siliciumdioxide, zwaveldioxide, zwaveltrioxide, diarseentrioxide en stikstofoxiden)',
    28121100: 'Carbonylchloride (fosgeen)',
    28121200: 'Fosforoxychloride',
    28121300: 'Fosfortrichloride',
    28121400: 'Fosforpentachloride',
    28121500: 'Zwavelmonochloride',
    28121600: 'Zwaveldichloride',
    28121700: 'Thionylchloride',
    28121910: 'Fosforchloriden en fosforchlorideoxiden (m.u.v. fosfor oxy-, tri- en pentachloriden)',
    28121990: 'Chloriden en chlorideoxiden (m.u.v. fosforchloriden en fosforchlorideoxiden, carbonylchloride (fosgeen), zwavelmonochloride, zwaveldichloride en thionylchloride)',
    28129000: 'Halogeniden en halogenideoxiden van niet-metalen (m.u.v. chloriden en chlorideoxiden)',
    28131000: 'koolstofdisulfide "zwavelkoolstof"',
    28139010: 'fosforsulfiden, incl. fosfortrisulfide in handelskwaliteit',
    28139090: 'zwavelverbindingen van niet-metalen (m.u.v. fosforsulfiden, incl. fosfortrisulfide in handelskwaliteit, en koolstofdisulfide "zwavelkoolstof")',
    28141000: 'watervrije ammoniak',
    28142000: 'ammoniak in waterige oplossing "ammonia"',
    28151100: 'natriumhydroxide "bijtende soda", vast',
    28151200: 'natriumhydroxide "bijtende soda", in waterige oplossing "natronloog"',
    28152000: 'Kaliumhydroxide "bijtende potas"',
    28153000: 'natriumperoxide en kaliumperoxide',
    28161000: 'magnesiumhydroxide en magnesiumperoxide',
    28164000: 'strontiumoxide, strontiumhydroxide en strontiumperoxide; bariumoxide, bariumhydroxide en bariumperoxide',
    28170000: 'zinkoxide; zinkperoxide',
    28181011: 'Kunstmatig korund, al dan niet chemisch welbepaald, voor < 50 gewichtspercenten bestaande uit deeltjes > 10 mm en met een gehalte aan aluminiumoxide van >= 98,5 gewichtspercenten',
    28181019: 'Kunstmatig korund, al dan niet chemisch welbepaald, voor >= 50 gewichtspercenten bestaande uit deeltjes met een afmeting > 10 mm  en met een gehalte aan aluminiumoxide van >= 98,5 gewichtspercenten',
    28181091: 'Kunstmatig korund, al dan niet chemisch welbepaald, voor < 50  gewichtspercenten bestaande uit deeltjes met een afmeting van > 10 mm en met een gehalte aan aluminiumoxide van < 98,5 gewichtspercenten "hoge zuiverheidsgraad"',
    28181099: 'Kunstmatig korund, al dan niet chemisch welbepaald, voor >= 50 gewichtspercenten bestaande uit deeltjes met een afmeting van > 10 mm en met een gehalte aan aluminiumoxide van < 98,5 gewichtspercenten "hoge zuiverheidsgraad"',
    28182000: 'aluminiumoxide (m.u.v. kunstmatig korund)',
    28183000: 'aluminiumhydroxide',
    28191000: 'chroomtrioxide',
    28199010: 'chroomdioxide',
    28199090: 'chroomoxiden en chroomhydroxiden (m.u.v. chroomtrioxide; chroomdioxide)',
    28201000: 'mangaandioxide',
    28209010: 'mangaanoxide met een gehalte aan mangaan van >= 77 gewichtspercenten',
    28209090: 'mangaanoxiden (m.u.v. mangaandioxide; mangaanoxide met een gehalte aan mangaan van >= 77 gewichtspercenten)',
    28211000: 'ijzeroxiden en ijzerhydroxiden',
    28212000: 'verfaarden die >= 70 gewichtspercenten ijzerverbindingen, berekend als Fe2O3, bevatten',
    28220000: 'kobaltoxiden en kobalthydroxiden; kobaltoxiden in handelskwaliteit',
    28230000: 'titaanoxiden',
    28241000: 'loodmonoxide "loodglit, massicot"',
    28249000: 'loodoxiden; rode menie, kristalmenie en oranje menie  (m.u.v. loodmonoxide "loodglit, massicot")',
    28251000: 'hydrazine en hydroxylamine, alsmede anorganische zouten daarvan',
    28252000: 'lithiumoxide en lithiumhydroxide',
    28253000: 'vanadiumoxiden en vanadiumhydroxiden',
    28254000: 'nikkeloxiden en nikkelhydroxiden',
    28255000: 'koperoxiden en koperhydroxiden',
    28256000: 'germaniumoxiden en zirkoniumdioxide',
    28257000: 'molybdeenoxiden en molybdeenhydroxiden',
    28258000: 'antimoonoxiden',
    28259011: 'calciumhydroxide, met een zuiverheid van >= 98 gewichtspercenten, berekend op de droge stof, in de vorm van deeltjes, waarvan de afmetingen voor niet meer dan 1 gewichtspercent > 75 micrometer bedragen en de afmetingen voor niet meer dan 4 gewichtspercenten < 1,3 micrometer bedragen',
    28259019: 'calciumoxide, calciumhydroxide en calciumperoxide (m.u.v. calciumhydroxide, met een zuiverheid van >= 98 gewichtspercenten, berekend op de droge stof, in de vorm van deeltjes, waarvan de afmetingen voor niet meer dan 1 gewichtspercent > 75 micrometer bedragen en de afmetingen voor niet meer dan 4 gewichtspercenten < 1,3 micrometer bedragen)',
    28259020: 'berylliumoxide en berylliumhydroxide',
    28259040: 'wolfraamoxiden en wolfraamhydroxiden',
    28259060: 'cadmiumoxide',
    28259085: 'Anorganische basen en oxiden, hydroxiden en peroxiden van metalen, n.e.g.',
    28261200: 'aluminiumfluoride',
    28261910: 'ammoniumfluoriden en natriumfluoriden',
    28261990: 'fluoriden (m.u.v. ammoniumfluoriden, natriumfluoriden, aluminiumfluoriden en kwikfluoriden)',
    28263000: 'natriumhexafluoroaluminaat "synthetisch kryoliet"',
    28269010: 'dikaliumhexafluorozirkonaat "kaliumfluorzirkonaat"',
    28269080: 'fluorosilicaten, fluoroaluminaten en andere complexe fluorzouten (m.u.v. natriumhexafluoroaluminaat "synthetisch kryoliet" en dikaliumhexafluorozirkonaat "kaliumfluorzirkonaat" en anorganische en organische kwikverbindingen)',
    28271000: 'ammoniumchloride',
    28272000: 'calciumchloride',
    28273100: 'magnesiumchloride',
    28273200: 'aluminiumchloride',
    28273500: 'nikkelchloride',
    28273910: 'tinchloriden',
    28273920: 'ijzerchloriden',
    28273930: 'kobaltchloriden',
    28273985: 'chloriden (m.u.v. ammonium-, calcium-, magnesium-, aluminium-, ijzer-, kobalt-, nikkel- tin- en kwikchloriden)',
    28274100: 'koperchlorideoxiden en koperchloridehydroxiden',
    28274910: 'loodchlorideoxiden en loodchloridehydroxiden',
    28274990: 'chlorideoxiden en chloridehydroxiden (m.u.v. koperchlorideoxiden, koperchloridehydroxiden, loodchlorideoxiden, loodchloridehydroxiden, kwikchlorideoxiden en kwikchloridehydroxiden)',
    28275100: 'natriumbromide en kaliumbromide',
    28275900: 'bromiden en bromideoxiden (m.u.v. natriumbromide, kaliumbromide en kwikbromide)',
    28276000: 'jodiden en jodideoxiden (m.u.v. anorganische en organische kwikverbindingen)',
    28281000: 'calciumhypochloriet in handelskwaliteit en andere calciumhypochlorieten',
    28289000: 'hypochlorieten, chlorieten en hypobromieten (m.u.v. calciumhypochlorieten)',
    28291100: 'natriumchloraat',
    28291900: 'chloraten (m.u.v. natriumchloraat)',
    28299010: 'perchloraten (m.u.v. anorganische en organische kwikverbindingen)',
    28299040: 'kaliumbromaat en natriumbromaat',
    28299080: 'Bromaten en perbromaten (m.u.v. kalium- en natriumbromaat); jodaten en perjodaten',
    28301000: 'natriumsulfiden',
    28309011: 'calciumsulfide, antimoonsulfiden en ijzersulfiden',
    28309085: 'Sulfiden; polysulfiden, al dan niet chemisch welbepaald maar niet zijnde natriumsulfiden of calciumsulfide, antimoonsulfiden en ijzersulfiden',
    28311000: 'natriumdithioniet en natriumsulfoxylaat',
    28319000: 'dithionieten en sulfoxylaten (m.u.v. natriumdithioniet en natriumsulfoxylaat)',
    28321000: 'natriumsulfieten',
    28322000: 'sulfieten (m.u.v. natriumsulfieten)',
    28323000: 'thiosulfaten',
    28331100: 'dinatriumsulfaat',
    28331900: 'natriumsulfaten (m.u.v. dinatriumsulfaat)',
    28332100: 'magnesiumsulfaten',
    28332200: 'aluminiumsulfaat',
    28332400: 'nikkelsulfaten',
    28332500: 'kopersulfaten',
    28332700: 'bariumsulfaat',
    28332920: 'cadmiumsulfaat, chroomsulfaten en zinksulfaat',
    28332930: 'kobaltsulfaten en titaansulfaten',
    28332960: 'loodsulfaten',
    28332980: 'Sulfaten (m.u.v. natrium-, magnesium-, chroom-, nikkel-, koper-, kobalt-, titaan-, kwik- en loodsulfaten en aluminium-, zink-, barium- en cadmiumsulfaat)',
    28333000: 'aluinen',
    28334000: 'peroxosulfaten "persulfaten"',
    28341000: 'nitrieten',
    28342100: 'kaliumnitraat',
    28342920: 'bariumnitraat, berylliumnitraat, cadmiumnitraat, kobaltnitraat, nikkelnitraat en loodnitraat',
    28342940: 'kopernitraten',
    28342980: 'nitraten (m.u.v. kalium-, barium-, beryllium-, cadmium-, kobalt-, nikkel- en loodnitraat en koper- en kwiknitraten)',
    28351000: 'fosfinaten "hypofosfieten" en fosfonaten "fosfieten"',
    28352200: 'natriumdiwaterstoforthofosfaat en dinatriumwaterstoforthofosfaat',
    28352400: 'kaliumfosfaten',
    28352500: 'Calciumwaterstoforthofosfaat "dicalciumfosfaat"',
    28352600: 'Calciumfosfaten (m.u.v. calciumwaterstoforthofosfaat "dicalciumfosfaat")',
    28352910: 'triammoniumorthofosfaat',
    28352930: 'trinatriumorthofosfaat',
    28352990: 'fosfaten (m.u.v. triammoniumorthofosfaat, natriumdiwaterstoforthofosfaat en dinatriumwaterstoforthofosfaat, trinatriumorthofosfaat, kaliumfosfaten, calciumfosfaten en kwikfosfaten)',
    28353100: 'natriumtrifosfaat "natriumtripolyfosfaat", ook indien chemisch welbepaald',
    28353900: 'Polyfosfaten, al dan niet chemisch welbepaald (m.u.v. natriumtrifosfaat "natriumtripolyfosfaat; organische en anorganische kwikverbindingen, ook indien chemisch welbepaald)',
    28362000: 'dinatriumcarbonaat',
    28363000: 'natriumwaterstofcarbonaat "natriumbicarbonaat"',
    28364000: 'kaliumcarbonaten',
    28365000: 'calciumcarbonaat',
    28366000: 'bariumcarbonaat',
    28369100: 'lithiumcarbonaten',
    28369200: 'strontiumcarbonaat',
    28369911: 'magnesiumcarbonaten en kopercarbonaten',
    28369917: 'Carbonaten; ammoniumcarbonaat in handelskwaliteit en andere ammoniumcarbonaten (m.u.v. dinatriumcarbonaat, natriumwaterstofcarbonaat "natriumbicarbonaat", kalium-, lithium-, magnesium- en kopercarbonaten en calcium-, barium-, strontiumcarbonaat en anorganische en organische kwikverbindingen)',
    28369990: 'peroxocarbonaten "percarbonaten"',
    28371100: 'natriumcyanide',
    28371900: 'Cyaniden en cyanideoxiden (m.u.v. natriumcyanide en kwikcyanide)',
    28372000: 'complexe cyaniden (m.u.v. anorganische en organische kwikverbindingen)',
    28391100: 'natriummetasilicaten, ook indien in handelskwaliteit',
    28391900: 'natriumsilicaten, ook indien in handelskwaliteit (m.u.v. natriummetasilicaten)',
    28399000: 'silicaten; alkalimetaalsilicaten in handelskwaliteit (m.u.v. natriumsilicaten)',
    28401100: 'dinatriumtetraboraat "geraffineerde borax", watervrij',
    28401910: 'dinatriumtetraboraatpentahydraat',
    28401990: 'dinatriumtetraboraat "geraffineerde borax" (m.u.v. watervrij dinatriumtetraboraat; dinatriumtetraboraatpentahydraat)',
    28402010: 'natriumboraten, watervrij (m.u.v. dinatriumtetraboraat "geraffineerde borax")',
    28402090: 'boraten (m.u.v. natriumboraten, watervrij en m.u.v. dinatriumtetraboraat "geraffineerde borax")',
    28403000: 'peroxoboraten "perboraten"',
    28413000: 'natriumdichromaat',
    28415000: 'chromaten en dichromaten, alsmede peroxochromaten (m.u.v.  natriumdichromaat en anorganische en organische kwikverbindingen)',
    28416100: 'kaliumpermanganaat',
    28416900: 'manganieten, manganaten en permanganaten (m.u.v. kaliumpermanganaat)',
    28417000: 'molybdaten',
    28418000: 'wolframaten',
    28419030: 'zinkaten en vanadaten',
    28419085: 'zouten van oxometaalzuren of van peroxometaalzuren (m.u.v. chromaten, dichromaten, peroxochromaten, manganieten, manganaten, permanganaten, molybdaten, wolframaten, zinkaten en vanadaten)',
    28421000: 'dubbelsilicaten en complexe silicaten van anorganische zouten en peroxozouten "aluminosilicaten, ook indien chemisch welbepaald, daaronder begrepen" (m.u.v. anorganische en organische kwikverbindingen, ook indien chemisch welbepaald)',
    28429010: 'Zouten, incl. dubbelzouten en complexe zouten, van seleenzuren "seleniumzuren" of van telluurzuren "telluriumzuren"',
    28429080: 'anorganische zouten en peroxozouten (m.u.v. aziden, zouten van oxometaalzuren of van peroxometaalzuren, dubbelsilicaten, complexe silicaten "aluminosilicaten, ook indien chemisch welbepaald, daaronder begrepen", en zouten "dubbelzouten en complexe zouten daaronder begrepen" van seleenzuren "seleniumzuren" of van telluurzuren "telluriumzuren" en anorganische en organische kwikverbindingen)',
    28431010: 'zilver in colloïdale toestand',
    28431090: 'edele metalen in colloïdale toestand (m.u.v. zilver)',
    28432100: 'Zilvernitraat',
    28432900: 'anorganische of organische zilververbindingen, ook indien chemisch welbepaald (m.u.v. zilvernitraat en van anorganische en organische kwikverbindingen)',
    28433000: 'anorganische of organische goudverbindingen, ook indien chemisch welbepaald',
    28439010: 'amalgamen van edele metalen',
    28439090: 'anorganische of organische verbindingen van edele metalen, al dan niet chemisch welbepaald (m.u.v. zilver- en goudverbindingen)',
    28441010: 'natuurlijk uranium, ruw; resten en afvallen, van natuurlijk uranium "Euratom"',
    28441030: 'natuurlijk uranium, bewerkt "Euratom"',
    28441050: 'legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende natuurlijk uranium met ijzer of verbindingen van natuurlijk uranium met ijzer "ferro-uranium"',
    28441090: 'verbindingen van natuurlijk uranium; legeringen en dispersies, incl. cermets, keramische producten en mengsels, bevattende natuurlijk uranium of verbindingen van natuurlijk uranium "Euratom" (m.u.v. ferro-uranium)',
    28442025: 'legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende met U 235 verrijkt uranium met ijzer "ferro-uranium"',
    28442035: 'uranium, verrijkt met U 235 en verbindingen daarvan; legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende uranium verrijkt met U 235 "Euratom" (m.u.v. ferro-uranium)',
    28442051: 'mengsels van uranium en plutonium met ijzer "ferro-uranium"',
    28442059: 'mengsels van uranium en plutonium "Euratom" (m.u.v. ferro-uranium)',
    28442099: 'plutonium en verbindingen daarvan; legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende plutonium of verbindingen van dit product (m.u.v. mengsels van uranium en plutonium)',
    28443011: 'cermets bevattende uranium waaruit U 235 is afgescheiden of verbindingen van dit product',
    28443019: 'uranium waaruit U 235 is afgescheiden; legeringen, dispersies, keramische producten en mengsels, bevattende uranium waaruit U 235 is afgescheiden, of verbindingen van dit product (m.u.v. cermets)',
    28443051: 'cermets bevattende thorium of verbindingen van dit product',
    28443055: 'thorium, ruw; resten en afvallen, van thorium "Euratom"',
    28443061: 'staven, profielen, draad, platen, bladen en strippen, van thorium "Euratom"',
    28443069: 'thorium, bewerkt; legeringen, dispersies, keramische producten en mengsels, bevattende thorium of verbindingen van dit product "Euratom" (m.u.v. cermets; staven, profielen, draad, platen, bladen en strippen)',
    28443091: 'verbindingen van thorium of van uranium waaruit U 235 is afgescheiden, ook indien onderling vermengd "Euratom" (m.u.v. zouten van thorium)',
    28443099: 'zouten van thorium',
    28444010: 'uranium bevattende U 233 alsmede verbindingen daarvan; legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende U 233 of verbindingen van dit product',
    28444020: 'isotopen, kunstmatig radioactief "Euratom"',
    28444030: 'verbindingen van kunstmatige radioactieve isotopen "Euratom"',
    28444080: 'radioactieve elementen, isotopen en verbindingen (m.u.v. die bedoeld bij 2844.10, 2844.20, 2844.30 en 2844.40.10 t/m 2844.40.30); legeringen, dispersies, incl. cermets, keramische producten en mengsels, bevattende deze elementen, isotopen of verbindingen; radioactief afval (m.u.v. dat van uranium met U 233)',
    28445000: 'gebruikte "bestraalde" splijtstofelementen van kernreactoren "Euratom"',
    28451000: 'zwaar water "deuteriumoxide" "Euratom"',
    28459010: 'deuterium en verbindingen daarvan; waterstof en verbindingen daarvan verrijkt met deuterium; mengsels en oplossingen die deze producten bevatten "Euratom" (m.u.v. zwaar water "deuteriumoxide")',
    28459090: 'isotopen, niet-radioactief; anorganische en organische verbindingen daarvan, ook indien chemisch niet-welbepaald (m.u.v. deuterium, zwaar water "deuteriumoxide" en andere deuteriumverbindingen, waterstof en verbindingen daarvan, verrijkt met deuterium; mengsels en oplossingen die deze producten bevatten)',
    28461000: 'ceriumverbindingen',
    28469010: 'anorganische en organische verbindingen van lanthaan, van praseodymium, van neodymium of van samarium',
    28469020: 'anorganische en organische verbindingen van europium, gadolinium, terbium, dysprosium, holmium, erbium, thulium, ytterbium, lutetium of yttrium',
    28469030: 'anorganische en organische verbindingen van scandium',
    28469090: 'anorganische en organische verbindingen van metaalmengsels van zeldzame aardmetalen, van yttrium of van scandium',
    28470000: 'waterstofperoxide, ook indien in vaste toestand gebracht met ureum',
    28491000: 'calciumcarbide, ook indien chemisch welbepaald',
    28492000: 'siliciumcarbide, ook indien chemisch welbepaald',
    28499010: 'boorcarbide "boriumcarbide", ook indien chemisch welbepaald',
    28499030: 'wolfraamcarbiden, ook indien chemisch welbepaald',
    28499050: 'aluminiumcarbide, chroomcarbiden, molybdeencarbiden, vanadiumcarbide, tantaalcarbiden "tantaliumcarbiden" en titaancarbide "titaniumcarbide", ook indien chemisch welbepaald',
    28499090: 'Carbiden, al dan niet chemisch welbepaald (m.u.v. calciumcarbide, siliciumcarbide, boorcarbide (boriumcarbide), wolfraamcarbiden, aluminiumcarbide, chroomcarbiden, molybdeencarbiden, vanadiumcarbide, tantaalcarbiden (tantaliumcarbiden) en titaancarbide (titaniumcarbide), en anorganische en organische kwikverbindingen, ook indien chemisch welbepaald)',
    28500020: 'Hydriden en nitriden, al dan niet chemisch welbepaald, andere dan verbindingen die tevens carbiden bedoeld bij post 2849 zijn (m.u.v. anorganische en organische kwikverbindingen, al dan niet chemisch welbepaald)',
    28500060: 'Aziden en siliciden, al dan niet chemisch welbepaald, andere dan verbindingen die tevens carbiden bedoeld bij post 2849 zijn (m.u.v. anorganische en organische kwikverbindingen, al dan niet chemisch welbepaald)',
    28500090: 'Boriden, al dan niet chemisch welbepaald, andere dan verbindingen die tevens carbiden bedoeld bij post 2849 zijn (m.u.v. anorganische en organische kwikverbindingen, al dan niet chemisch welbepaald)',
    28521000: 'anorganische of organische kwikverbindingen, chemisch welbepaald (m.u.v. amalgamen)',
    28529000: 'anorganische of organische kwikverbindingen, niet chemisch welbepaald (m.u.v. amalgamen)',
    28531000: 'Cyanogeenchloride (chloorcyaan)',
    28539010: 'Gedistilleerd water, conductometrisch zuiver water e.d. zuiver water',
    28539030: 'Vloeibare lucht, ook indien daaraan edelgassen zijn onttrokken; samengeperste lucht',
    28539090: 'Anorganische verbindingen, n.e.g., alsmede amalgamen (m.u.v. amalgamen van edele metalen)',
    29011000: 'koolwaterstoffen, acyclisch, verzadigd',
    29012100: 'ethyleen',
    29012200: 'propeen "propyleen"',
    29012300: 'Buteen "butyleen" en isomeren daarvan',
    29012400: 'Buta-1,3-dieen en isopreen',
    29012900: 'koolwaterstoffen, acyclisch, onverzadigd (m.u.v. ethyleen; propeen "propyleen"; buteen "butyleen" en isomeren daarvan; buta-1,3-dieen en isopreen)',
    29021100: 'cyclohexaan',
    29021900: 'Cycloalkanen, cycloalkenen en cycloterpenen (m.u.v. cyclohexaan)',
    29022000: 'benzeen',
    29023000: 'tolueen',
    29024100: 'o-xyleen',
    29024200: 'm-xyleen',
    29024300: 'p-xyleen',
    29024400: 'mengsels van xyleenisomeren',
    29025000: 'styreen',
    29026000: 'ethylbenzeen',
    29027000: 'cumeen',
    29029000: 'Cyclische koolwaterstoffen (m.u.v. cycloalkanen, cycloalkenen en cycloterpenen; benzeen, tolueen, xylenen, styreen, ethylbenzeen, cumeen)',
    29031100: 'chloormethaan "methylchloride" en chloorethaan "ethylchloride"',
    29031200: 'dichloorethaan "methyleenchloride"',
    29031300: 'chloroform "trichloormethaan"',
    29031400: 'koolstoftetrachloride',
    29031500: '1,2-dichloorethaan "ethyleendichloride"',
    29031900: 'verzadigde chloorderivaten van acyclische koolwaterstoffen (m.u.v. chloormethaan "methylchloride", chloorethaan "ethylchloride", dichloormethaan "methyleenchloride", chloroform "trichloormethaan", koolstoftetrachloride en 1,2-dichloorethaan "ethyleendichloride" )',
    29032100: 'vinylchloride "chloorethyleen"',
    29032200: 'trichloorethyleen',
    29032300: 'tetrachloorethyleen "perchloorethyleen"',
    29032900: 'chloorderivaten van acyclische koolwaterstoffen, onverzadigd (m.u.v. vinylchloride "chloorethyleen", trichloorethyleen en tetrachloorethyleen "perchloorethyleen")',
    29033100: 'ethyleendibromide (ISO) (1,2-dibroomethaan)',
    29033911: 'broommethaan "methylbromide"',
    29033915: 'dibroommethaan',
    29033919: 'bromiden "broomderivaten" van acyclische koolwaterstoffen (m.u.v. ethyleendibromide [ISO] [1,2-dibroomethaan]; broommethaan [methylbromide]; dibroommethaan)',
    29033921: 'difluormethaan',
    29033923: 'trifluormethaan',
    29033924: 'pentafluorethaan en 1,1,1-trifluorethaan',
    29033925: '1,1-difluorethaan',
    29033926: '1,1,1,2-tetrafluorethaan',
    29033927: 'pentafluorpropanen, hexafluorpropanen en heptafluorpropanen',
    29033928: 'geperfluoreerde verzadigde fluoriden',
    29033929: 'verzadigde fluoriden "fluorderivaten"  van acyclische koolwaterstoffen n.e.g.',
    29033931: '2,3,3,3-tetrafluorpropeen',
    29033935: '1,3,3,3-tetrafluorpropeen',
    29033939: 'vonerzadigde fluoriden "fluorderivaten"  van acyclische koolwaterstoffen (m.u.v. 2,3,3,3-tetrafluorpropeen en 1,3,3,3-tetrafluorpropeen',
    29033980: 'jodiden "joodderivaten" van acyclische koolwaterstoffen',
    29037100: 'Chloordifluormethaan',
    29037200: 'Dichloortrifluorethanen',
    29037300: 'Dichloorfluorethanen',
    29037400: 'chloordifluorethanen',
    29037500: 'Dichloorpentafluorpropanen',
    29037610: 'broomchloordifluormethaan',
    29037620: 'broomtrifluormethaan',
    29037690: 'Dibroomtetrafluorethanen',
    29037760: 'trichloorfluormethaan, dichloordifluormethaan, trichloortrifluorethanen, dichloortetrafluorethanen en chloorpentafluorethaan',
    29037790: 'Halogeenderivaten van acyclische koolwaterstoffen bevattende twee of meer verschillende halogenen, enkel geperhalogeneerd met fluor en chloor, n.e.g.',
    29037800: 'perhalogeenderivaten van acyclische koolwaterstoffen bevattende twee of meer verschillende halogenen, n.e.g.',
    29037930: 'halogeenderivaten van acyclische koolwaterstoffen, enkel gehalogeneerd met broom en chloor, met fluor en chloor of met fluor en broom ( m.u.v. geperhalogeneerd, chloordifluormethaan, dichloortrifluorethanen, dichloortrifluorethanen, dichloorfluorethanen, chloordifluorethanen, dichloorpentafluorpropanen, broomtrifluormethaan en dibroomtetrafluorethanen)',
    29037980: 'halogeenderivaten van acyclische koolwaterstoffen bevattende twee of meer verschillende halogenen, n.e.g.',
    29038100: '1,2,3,4,5,6-hexachloorcyclohexaan (HCH(ISO)), lindaan (ISO, INN) daaronder begrepen',
    29038200: 'aldrine (ISO), chloordaan (ISO) en heptachloor (ISO)',
    29038300: 'Mirex (ISO)',
    29038910: '1,2-dibroom-4-(1,2-dibroomethyl)cyclohexaan; tetrabroomcyclooctanen',
    29038980: 'Halogeenderivaten van cycloalkanen, van cycloalkenen of van cycloterpenen (m.u.v. aldrine [ISO], chloordaan [ISO] en heptachloor [ISO]; 1,2,3,4,5,6-hexachloorcyclohexaan (HCH(ISO)), lindaan (ISO, INN), mirex (ISO), 1,2-dibroom-4-(1,2-dibroomethyl)cyclohexaan en tetrabroomcyclooctanen)',
    29039100: 'chloorbenzeen, o-dichloorbenzeen en p-dichloorbenzeen',
    29039200: 'hexachloorbenzeen (ISO) en DDT (ISO) (clofenotaan (INN), "1,1,1-trichloor-2,2-bis[p-chloorfenyl]ethaan"',
    29039300: 'Pentachloorbenzeen (ISO)',
    29039400: 'Hexabroombifenylen',
    29039910: '2,3,4,5,6-pentabroomethylbenzeen',
    29039980: 'Halogeenderivaten van aromatische koolwaterstoffen (m.u.v. chloorbenzeen, o-dichloorbenzeen, p-dichloorbenzeen, hexachloorbenzeen (ISO), DDT (ISO) "clofenotaan (INN), "1,1,1-trichloor-2,2-bis[p-chloorfenyl]ethaan" , pentachloorbenzeen (ISO), hexabroombifenyl en 2,3,4,5,6-pentabroomethylbenzeen)',
    29041000: 'derivaten van koolwaterstoffen die enkel sulfogroepen bevatten, alsmede zouten en ethylesters daarvan',
    29042000: 'derivaten van koolwaterstoffen, die enkel nitro- of enkel nitrosogroepen bevatten',
    29043100: 'Perfluoroctaansulfonzuur',
    29043200: 'Ammoniumperfluoroctaansulfonaat',
    29043300: 'Lithiumperfluoroctaansulfonaat',
    29043400: 'Kaliumperfluoroctaansulfonaat',
    29043500: 'Zouten van perfluoroctaansulfonzuur (m.u.v. ammonium-, lithium- en kalium-perfluoroctaansulfonaten)',
    29043600: 'Perfluoroctaansulfonylfluoride',
    29049100: 'Trichloornitromethaan "chloorpikrine"',
    29049900: 'Sulfo-, nitro- en nitrosoderivaten van koolwaterstoffen, ook indien gehalogeneerd (m.u.v. derivaten die enkel sulfo-, enkel nitro- of enkel nitrosogroepen bevatten, trichloornitromethaan "chloorpikrine", perfluoroctaansulfonzuur en zouten daarvan, perfluoroctaansulfonylfluoride  en glycerolesters verkregen met behulp van organische verbindingen met een zuurfunctie)',
    29051100: 'methanol "methylalcohol"',
    29051200: 'propaan-1-ol "propylalcohol" en propaan-2-ol "isopropylalcohol"',
    29051300: 'butaan-1-ol "n-butylalcohol"',
    29051410: '2-methylpropaan-2-ol "tert-butylalcohol"',
    29051490: 'butanolen (m.u.v. butaan-1-ol "n-butylalcohol" en 2-methylpropaan-2-ol "tert-butylalcohol")',
    29051620: 'octaan-2-ol',
    29051685: 'Octanol "octylalcohol" en isomeren daarvan (m.u.v. octaan-2-ol)',
    29051700: 'dodecaan-1-ol "laurylalcohol", hexadecaan-1-ol "cetylalcohol" en octadecaan-1-ol "stearylalcohol"',
    29051900: 'alcoholen, acyclisch, eenwaardig, verzadigd (m.u.v. methanol "methylalcohol", propaan-1-ol "propylalcohol", propaan-2-ol "isopropylalcohol", butanolen, octanol "octylalcohol" en isomeren daarvan, dodecaan-1-ol "laurylalcohol", hexadecaan-1-ol "cetylalcohol" en octadecaan-1-ol "stearylalcohol")',
    29052200: 'Acyclische terpeenalcoholen',
    29052910: 'allylalcohol',
    29052990: 'alcoholen, acyclisch, eenwaardig, onverzadigd (m.u.v. allylalcohol en acayclische terpeenalcoholen)',
    29053100: 'ethyleenglycol "ethaandiol"',
    29053200: 'propyleenglycol "propaan-1,2-diol"',
    29053920: 'butaan-1,3-diol',
    29053926: 'butaan-1,4-diol of tetramethyleenglycol (1,4-butaandiol) met een biogebaseerd koolstofgehalte van 100 massapercenten',
    29053928: 'butaan-1,4-diol  (m.u.v. die met een biogebaseerd koolstofgehalte van 100 massapercenten)',
    29053930: '2,4,7,9-tetramethyldec-5-yn-4,7-diol',
    29053995: 'Alcoholen, acyclisch, tweewaardig (m.u.v. ethyleenglycol [ethaandiol]; propyleenglycol [propaan-1,2-diol];  butaan-1,3-diol, butaan-1,4-diol en 2,4,7,9-tetramethyldec-5-yn-4,7-diol)',
    29054100: '2-ethyl-2-"hydroxymethyl"propaan-1,3-diol "trimethylolpropaan"',
    29054200: 'pentaerytritol "pentaerytriet"',
    29054300: 'mannitol',
    29054411: 'D-glucitol "sorbitol", in waterige oplossing, met een gehalte aan mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte',
    29054419: 'D-glucitol "sorbitol", in waterige oplossing (m.u.v. D-glucitol met een gehalte aan mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte)',
    29054491: 'D-glucitol "sorbitol" met een gehalte aan mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte (m.u.v. D-glucitol in waterige oplossing)',
    29054499: 'D-glucitol "sorbitol" (m.u.v. D-glucitol in waterige oplossing en m.u.v. D-glucitol met een gehalte aan mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte)',
    29054500: 'glycerol',
    29054900: 'Drie- en andere meerwaardige alcoholen, acyclisch (m.u.v. 2-ethyl-2-"hydroxymethyl"propaan-1,3-diol "trimethylolpropaan", pentaerytritol "pentaerytriet", mannitol, D-glucitol "sorbitol" en glycerol)',
    29055100: 'ethchloorvynol "INN"',
    29055991: '2,2-bis"broommethyl"propaandiol',
    29055998: 'Halogeen-, sulfo-, nitro- en nitrosoderivaten van acyclische alcoholen (m.u.v. 2,2-bis"broommethyl"propaandiol en ethchloorvynol "INN")',
    29061100: 'menthol',
    29061200: 'cyclohexanol, methylcyclohexanolen en dimethylcyclohexanolen',
    29061310: 'sterolen',
    29061390: 'inositolen',
    29061900: 'alcoholen van cycloalkanen, van cycloalkenen of van cycloterpenen, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. menthol, cyclohexanol, methylcyclohexanolen, dimethylcyclohexanolen, sterolen en inositolen)',
    29062100: 'benzylalcohol',
    29062900: 'alcoholen, cyclisch, aromatisch, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. benzylalcohol)',
    29071100: 'fenol "hydroxybenzeen" en zouten daarvan',
    29071200: 'kresolen en zouten daarvan',
    29071300: 'octylfenol, nonylfenol, alsmede isomeren daarvan; zouten van deze producten',
    29071510: '1-naftol',
    29071590: 'naftolen en zouten daarvan (m.u.v. 1-naftol)',
    29071910: 'xylenolen en zouten daarvan',
    29071990: 'fenolen, eenwaardig (m.u.v. fenol "hydroxybenzeen" en zouten daarvan, kresolen en zouten daarvan, octylfenol, nonylfenol, alsmede isomeren daarvan en zouten van deze producten, xylenolen en zouten daarvan en naftolen en zouten daarvan)',
    29072100: 'resorcinol en zouten daarvan',
    29072200: 'hydrochinon en zouten daarvan',
    29072300: '4,4\'-isopropylideendifenol "bisfenol A, difenylolpropaan" en zouten daarvan',
    29072900: 'meerwaardige fenolen; fenolalcoholen (m.u.v. resorcinol, hydrochinon en 4,4\'-isopropylideendifenol "bisfenol A, difenylolpropaan", alsmede zouten daarvan)',
    29081100: 'pentachloorophenol (ISO)',
    29081900: 'derivaten van fenolen of van fenolalcoholen die enkel halogeengroepen bevatten, alsmede zouten daarvan (m.u.v. pentachloorfenol [ISO])',
    29089100: 'dinoseb (ISO) en zouten daarvan',
    29089200: '4,6-dinitro-0-kresol (DNOC(ISO)) en zouten daarvan',
    29089900: 'halogeen-, sulfo-, nitro- en nitrosoderivaten van fenolen of van fenolalcoholen (m.u.v. derivaten die enkel halogeengroepen bevatten, alsmede zouten daarvan, dinoseb [ISO] en zouten daarvan en 4,6-Dinitro-0-cresol (DNOC(ISO)) en zouten daarvan)',
    29091100: 'diëthylether',
    29091910: 'Tert-butylethylether (ethyl-tertiair-butylether, ETBE)',
    29091990: 'Ethers, acyclisch en halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. diëthylether en tert-butylethylether (ethyl-tertiair-butylether, ETBE))',
    29092000: 'ethers van cycloalkanen, van cycloalkenen of van cycloterpenen, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan',
    29093010: 'difenylether',
    29093031: 'pentabroomdifenylether; 1,2,4,5-tetrabroom-3,6-bis"pentabroomfenoxy"benzeen',
    29093035: '1,2-bis"2,4,6-tribroomfenoxy"ethaan, bestemd voor de vervaardiging van acrylonitril-butadieen-styreen "ABS"',
    29093038: 'broomderivaten van aromatische ethers (m.u.v. pentabroomdifenylether; 1,2,4,5-tetrabroom-3,6-bis[pentabroomfenoxy]benzeen; 1,2-bis[2,4,6-tribroomfenoxy]ethaan, bestemd voor de vervaardiging van acrylonitril-butadieen-styreen [ABS])',
    29093090: 'ethers, aromatisch, en halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. difenylether en broomderivaten)',
    29094100: '2,2\'-oxidiëthanol "diëthyleenglycol"',
    29094300: 'monobutylethers van ethyleenglycol of van diëthyleenglycol',
    29094400: 'monoalkylethers van ethyleenglycol of van diëthyleenglycol (m.u.v. monobutylethers)',
    29094911: '2-"2-chloorethoxy"ethanol',
    29094980: 'Etheralcoholen, cyclisch, en halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. 2-[2-chloorethoxy]ethanol)',
    29095000: 'Etherfenolen, etherfenolalcoholen, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan',
    29096000: 'alcoholperoxiden, etherperoxiden, ketonperoxiden, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan',
    29101000: 'oxiraan "ethyleenoxide"',
    29102000: 'methyloxiraan "propyleenoxide"',
    29103000: '1-chloor-2,3-epoxypropaan "epichloorhydrine"',
    29104000: 'dieldrine (ISO) (INN)',
    29105000: 'Endrin (ISO)',
    29109000: 'Epoxiden, epoxyalcoholen, epoxyfenolen en epoxyethers, met een drieringsysteem, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. oxiraan "ethyleenoxide", methyloxiraan "propyleenoxide" , 1-chloor-2,3-epoxypropaan "epichloorhydrine", dieldrine [ISO] [INN] en endrin (ISO))',
    29110000: 'acetalen en hemiacetalen, ook indien met andere zuurstofhoudende groepen, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan',
    29121100: 'methanal "formaldehyd"',
    29121200: 'ethanal "aceetaldehyd"',
    29121900: 'aldehyden, acyclisch, zonder andere zuurstofhoudende groepen (m.u.v. methanal "formaldehyde" en ethanal "aceetaldehyde")',
    29122100: 'benzaldehyd',
    29122900: 'aldehyden, cyclisch, zonder andere zuurstofhoudende groepen (m.u.v. benzaldehyd)',
    29124100: 'vanilline "4-hydroxy-3-methoxybenzaldehyd"',
    29124200: 'ethylvanilline "3-ethoxy-4-hydroxybenzaldehyd"',
    29124900: 'Aldehyde-alcoholen, aldehyde-ethers, aldehydefenolen en aldehyden met andere zuurstofhoudende groepen (m.u.v. ethylvanilline "3-ethoxy-4-hydroxybenzaldehyde" en vanilline "4-hydroxy-3-methoxybenzaldehyde")',
    29125000: 'polymeren, cyclisch, van aldehyden',
    29126000: 'paraformaldehyd',
    29130000: 'halogeen-, sulfo-, nitro- en nitrosoderivaten van aldehyden, cyclische polymeren van aldehyden of formaldehyd',
    29141100: 'aceton',
    29141200: 'butanon "methylethylketon"',
    29141300: '4-methylpentaan-2-on "methylisobutylketon"',
    29141910: '5-methylhexaan-2-on',
    29141990: 'ketonen, acyclisch, zonder andere zuurstofhoudende groepen (m.u.v. aceton; butanon [methylethylketon]; 4-methylpentaan-2-on [methylisobutylketon]; 5-methylhexaan-2-on)',
    29142200: 'cyclohexanon en methylcyclohexanonen',
    29142300: 'iononen en methyliononen',
    29142900: 'ketonen van cycloalkanen, van cycloalkenen of van cycloterpenen, zonder andere zuurstofhoudende groepen (m.u.v. cyclohexanon en methylcyclohexanonen, iononen en methyliononen)',
    29143100: 'fenylaceton "fenylpropaan-2-on"',
    29143900: 'aromatische ketonen zonder andere zuurstofhoudende groepen (m.u.v. fenylaceton "fenylpropaan-2-on")',
    29144010: '4-hydroxy-4-methylpentaan-2-on "diacetonalcohol"',
    29144090: 'ketonalcoholen en ketonaldehyden (m.u.v. 4-hydroxy-4-methylpentaan-2-on "diacetonalcohol")',
    29145000: 'ketonfenolen en ketonen met andere zuurstofhoudende groepen',
    29146100: 'Antrachinon',
    29146200: 'Co-enzym Q10 (ubidecarenon (INN))',
    29146910: '1,4-naftochinon',
    29146980: 'Chinonen (m.u.v. antrachinon,co-enzym Q10 (ubidecarenon (INN)) en 1,4-naftochinon)',
    29147100: 'Chloordecon (ISO)',
    29147900: 'Halogeen-, sulfo-, nitro- en nitrosoderivaten van ketonen of chinonen (m.u.v. chloordecon (ISO) en anorganische en organische kwikverbindingen)',
    29151100: 'mierenzuur',
    29151200: 'zouten van mierenzuur',
    29151300: 'esters van mierenzuur',
    29152100: 'azijnzuur',
    29152400: 'azijnzuuranhydride',
    29152900: 'zouten van azijnzuur (m.u.v. anorganische en organische kwikverbindingen)',
    29153100: 'ethylacetaat',
    29153200: 'vinylacetaat',
    29153300: 'n-butylacetaat',
    29153600: 'dinosebacetaat (ISO)',
    29153900: 'Esters van azijnzuur (m.u.v. ethyl-, vinyl-, n-butyl-, dinoseb [ISO] acetaten)',
    29154000: 'mono-, di- en trichloorazijnzuur, alsmede zouten en esters daarvan',
    29155000: 'propionzuur en zouten en esters daarvan',
    29156011: '1-isopropyl-2,2-dimethyltrimethyleendiisobutyraat',
    29156019: 'butaanzuur en isobutaanzuur, alsmede zouten en esters daarvan (m.u.v. 1-isopropyl-2,2-dimethyltrimethyleendiisobutyraat)',
    29156090: 'pentaanzuur en isopentaanzuur, alsmede zouten en esters daarvan',
    29157040: 'palmitinezuur, alsmede zouten en esters daarvan',
    29157050: 'stearinezuur, alsmede zouten en esters daarvan',
    29159030: 'laurinezuur, alsmede zouten en esters daarvan',
    29159070: 'Verzadigde eenwaardige acyclische carbonzuren, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. mieren-, azijn-, mono-, di- en trichloorazijn-, propion-, palmitine- en stearinezuur, laurinezuur en butaan- en pentaanzuren, zouten en esters van deze producten, azijnzuuranhydride)',
    29161100: 'acrylzuur en zouten daarvan',
    29161200: 'Esters van acrylzuur',
    29161300: 'methacrylzuur en zouten daarvan',
    29161400: 'Esters van methacrylzuur',
    29161500: 'oliezuur, linolzuur en linoleenzuur, alsmede zouten en esters daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29161600: 'Binapacryl (ISO)',
    29161910: 'undeceenzuren, alsmede zouten en esters daarvan',
    29161940: 'crotonzuur',
    29161995: 'Carbonzuren, onverzadigd, acyclisch, eenwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan  (m.u.v. acrylzuur, zouten en esters daarvan; methacrylzuur, zouten en esters daarvan; oliezuur, linolzuur en linoleenzuur, zouten en esters daarvan; undeceenzuren, zouten en esters daarvan; crotonzuur en binapacryl [ISO])',
    29162000: 'carbonzuren van cycloalkanen, van cycloalkenen of van cycloterpenen, eenwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29163100: 'benzoëzuur en zouten en esters daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29163200: 'Benzoylperoxide en  benzoylchloride',
    29163400: 'fenylazijnzuur en zouten daarvan',
    29163910: 'esters van fenylazijnzuur',
    29163990: 'carbonzuren, aromatisch, eenwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren en halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. benzoëzuur en zouten en esters daarvan, benzoylperoxide, benzoylchloride, fenylazijnzuur en zouten en esters daarvan, binapacryl (ISO) en anorganische en organische kwikverbindingen, al dan niet chemisch welbepaald)',
    29171100: 'oxaalzuur en zouten en esters daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29171200: 'Adipinezuur, zouten en esters daarvan',
    29171310: 'sebacinezuur',
    29171390: 'azelaïnezuur, alsmede zouten en esters daarvan; zouten en esters van sebacinezuur',
    29171400: 'maleïnezuuranhydride',
    29171910: 'malonzuur, alsmede zouten en esters daarvan',
    29171920: 'ethaan-1,2-dicarbonzuur of butaandizuur (barnsteenzuur) met een biogebaseerd koolstofgehalte van 100 massapercenten',
    29171980: 'carbonzuren, acyclisch, meerwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. oxaalzuur en zouten en esters daarvan, adipinezuur en zouten en esters daarvan, azelaïnezuur en sebacinezuur, alsmede zouten en esters daarvan, malonzuur, alsmede zouten en esters daarvan, maleïnezuuranhydride en anorganische en organische kwikverbindingen en ethaan-1,2-dicarbonzuur of butaandizuur (barnsteenzuur) met een biogebaseerd koolstofgehalte van 100 massapercenten)',
    29172000: 'carbonzuren van cycloalkanen, van cycloalkenen of van cycloterpenen, meerwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede derivaten daarvan',
    29173200: 'dioctylorthoftalaten',
    29173300: 'dinonylorthoftalaten en didecylorthoftalaten',
    29173400: 'Esters van orthoftaalzuur (m.u.v. dioctylorthoftalaten, dinonylorthoftalaten en didecylorthoftalaten)',
    29173500: 'ftaalzuuranhydride',
    29173600: 'tereftaalzuur en zouten daarvan',
    29173700: 'dimethyltereftalaat',
    29173920: 'Esters of anhydride van tetrabroomftaalzuur; benzeen-1,2,4-tricarbonzuur; isoftaloyldichloride, bevattende <= 0,8 gewichtspercent tereftaloyldichloride;  naftaleen-1,4,5,8-tetracarbonzuur; tetrachloorftaalzuuranhydride;  natrium-3,5-bis"methoxycarbonyl"benzeensulfonaat',
    29173995: 'Aromatische carbonzuren, meerwaardig, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. esters van orthoftaalzuur; ftaalzuuranhydride; tereftaalzuur en zouten daarvan; dimethyltereftalaat; esters of anhydride van tetrabroomftaalzuur; benzeen-1,2,4-tricarbonzuur; isoftaloyldichloride, bevattende <= 0,8 gewichtspercent tereftaloyldichloride; naftaleen-1,4,5,8-tetracarbonzuur; tetrachloorftaalzuuranhydride; natrium 3,5-bis[methoxycarbonyl]benzeensulfonaat)',
    29181100: 'melkzuur en zouten en esters daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29181200: 'wijnsteenzuur',
    29181300: 'zouten en esters van wijnsteenzuur',
    29181400: 'citroenzuur',
    29181500: 'zouten en esters van citroenzuur (m.u.v. anorganische en organische kwikverbindingen)',
    29181600: 'gluconzuur en zouten en esters daarvan',
    29181700: '2,2-Difenyl-2-hydroxyazijnzuur (benzilzuur)',
    29181800: 'chloorbenzilaat (ISO)',
    29181930: 'cholzuur en 3alfa, 12alfa-dihydroxy-5beta-cholaan-24-zuur "desoxycholzuur", alsmede zouten en esters daarvan',
    29181940: '2,2-bis"hydroxymethyl"propionzuur',
    29181998: 'Carbonzuren met alcoholische hydroxylgroepen, doch zonder andere zuurstofhoudende groepen, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. melkzuur, wijnsteenzuur, citroenzuur, gluconzuur, cholzuur en 3-alfa,12-alfa-dihydroxy-5beta-cholaan-24-zuur "desoxycholzuur", alsmede zouten en esters daarvan, en 2,2-bis"hydroxymethyl"propionzuur en chloorbenzilaat [ISO] en 2,2-difenyl-2-hydroxyazijnzuur(benzilzuur))',
    29182100: 'salicylzuur en zouten daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29182200: 'o-acetylsalicylzuur en zouten en esters daarvan',
    29182300: 'Esters van salicylzuur en zouten daarvan (m.u.v. o-acetylsalicylzuur en zouten en esters daarvan)',
    29182900: 'Carbonzuren met fenolische hydroxylgroepen, doch zonder andere zuurstofhoudende groepen, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. salicylzuur, o-acetylsalicylzuur, zouten en esters daarvan)',
    29183000: 'carbonzuren met aldehyd- of ketongroepen, doch zonder andere zuurstofhoudende groepen, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan',
    29189100: '2,4,5-T (ISO) (2,4,5-trichloorfenoxyazijnzuur), alsmede zouten en esters daarvan',
    29189940: '2,6-Dimethoxybenzoëzuur; dicamba "ISO"; natriumfenoxyacetaat',
    29189990: 'carbonzuren met andere zuurstofhoudende groepen, daarvan afgeleide anhydriden, halogeniden, peroxiden en peroxyzuren, alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. carbonzuren met enkel alcoholische of fenolische hydroxylgroepen; carbonzuren met enkel aldehyde- of ketongroepen; 2,6-dimethoxybenzoëzuur; dicamba [ISO]; natriumfenoxyacetaat) en 2,4,5-T [ISO] [2,4,5-trichloorfenoxyazijnzuur], alsmede zouten en esters daarvan)',
    29191000: 'Tris(2,3-dibroompropyl)fosfaat',
    29199000: 'Fosforzure esters en zouten daarvan, incl. lactofosfaten; alsmede halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan (m.u.v. Tris"2,3-dibroompropyl"fosfaat)',
    29201100: 'Parathion (ISO) en parathionmethyl (ISO) (methylparathion)',
    29201900: 'thiofosforzure esters "fosforthioaten" en zouten daarvan; halogeen-, sulfo, nitro- en nitrosoderivaten van deze producten (m.u.v. Parathion [ISO] en parathionmethyl [ISO] [methylparathion])',
    29202100: 'Dimethylfosfiet',
    29202200: 'Diëthylfosfiet',
    29202300: 'Trimethylfosfiet',
    29202400: 'Triëthylfosfiet',
    29202900: 'Fosfietesters en zouten daarvan, halogeen-, sulfo-, nitro- en nitrosoderivaten van deze producten (m.u.v. dimethyl-, diethyl-, trimethyl- en triëthylfosfieten)',
    29203000: 'Endosulfan (ISO)',
    29209010: 'Zwavelzure en koolzure esters, alsmede zouten, halogeen-, sulfo, nitro- en nitrosoderivaten daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29209070: 'Esters van anorganische zuren van niet metalen, alsmede zouten daarvan; halogeen-, sulfo-, nitro- en nitrosoderivaten van deze producten (m.u.v. esters van waterstofhalogeniden en m.u.v. fosforzure esters, fosfietesters, thiofosforzure esters "fosforthioaten" en zwavelzure en koolzure esters, alsmede zouten, halogeen-, sulfo-, nitro- en nitrosoderivaten daarvan en  m.u.v. endosulfan (ISO))',
    29211100: 'Methylamine, dimethylamine en trimethylamine alsmede zouten daarvan',
    29211200: '2-(N,N-dimethylamino)ethylchloride-hydrochloride',
    29211300: '2-(N,N-diethylamino)ethylchloride-hydrochloride',
    29211400: '2-(N,N-diisopropylamino)ethylchloride-hydrochloride',
    29211940: '1,1,3,3-tetramethylbutylamine',
    29211950: 'diëthylamine en zouten daarvan',
    29211999: 'Acyclische eenwaardige aminoverbindingen,  en derivaten daarvan; zouten van deze producten (m.u.v. methylamine, dimethylamine en trimethylamine, zouten daarvan; diëthylamine en zouten daarvan;  1,1,3,3-tetramethylbutylamine en 2-(N,N-Diethylamino)ethyl chloride hydrochloride, 2-(N,N-diisopropylamino)ethyl chloride hydrochloride and 2-(N,N-dimethylamino)ethyl chloride hydrochloride)',
    29212100: 'ethyleendiamine en zouten daarvan',
    29212200: 'hexamethyleendiamine en zouten daarvan',
    29212900: 'meerwaardige aminoverbindingen, acyclisch, en derivaten daarvan; zouten van deze producten (m.u.v. ethyleendiamine, hexamethyleendiamine, alsmede zouten daarvan)',
    29213010: 'cyclohexylamine en cyclohexyldimethylamine, alsmede zouten daarvan',
    29213091: 'cyclohex-1,3-yleendiamine "1,3-diaminocyclohexaan"',
    29213099: 'aminoverbindingen van cycloalkanen, van cycloalkenen of van cycloterpenen, alsmede derivaten daarvan; zouten van deze producten (m.u.v. cyclohexylamine en cyclohexyldimethylamine, zouten daarvan; cyclohex-1,3-yleendiamine [1,3-diaminocyclohexaan])',
    29214100: 'aniline en zouten daarvan (m.u.v. anorganische en organische kwikverbindingen)',
    29214200: 'Aniline derivaten, alsmede zouten daarvan',
    29214300: 'toluïdinen en derivaten daarvan; zouten van deze producten',
    29214400: 'difenylamine en derivaten daarvan; zouten van deze producten',
    29214500: '1-naftylamine "alfa-naftylamine" en 2-naftylamine "beta-naftylamine", alsmede derivaten daarvan; zouten van deze producten',
    29214600: 'amfetamine "INN", benzfetamine "INN", dexamfetamine "INN", etilamfetamine "INN", feentermine "INN", fencamfamine "INN", lefetamine "INN", levamfetamine "INN" en mefenorex "INN"; zouten van deze producten',
    29214900: 'Eenwaardige aromatische aminoverbindingen en derivaten daarvan; zouten van deze producten (m.u.v. aniline, toluïdinen, difenylamine, 1- en 2-naftylamine "alfa- en beta-naftylamine" en derivaten daarvan, alsmede zouten van deze producten, en m.u.v. amfetamine "INN", benzfetamine "INN", dexamfetamine "INN", etilamfetamine "INN", feentermine "INN", fencamfamine "INN", lefetamine "INN", levamfetamine "INN", mefenorex "INN", alsmede zouten van deze producten)',
    29215111: 'm-fenyleendiamine, met een zuiverheid van >= 99 gewichtspercenten, bevattende <= 1 gewichtspercent water, <= 200 mg/kg o-fenyleendiamine en <= 450 mg/kg p-fenyleendiamine',
    29215119: 'o-, m- en p-fenyleendiamine en diaminotoluenen, alsmede halogeen-, sulfo-, nitro en nitrosoderivaten daarvan; zouten van deze producten (m.u.v. m-fenyleendiamine, met een zuiverheid van >= 99 gewichtspercenten, bevattende <= 1 gewichtspercent water, <= 200 mg/kg o-fenyleendiamine en <= 450 mg/kg p-fenyleendiamine)',
    29215190: 'derivaten van o-, m- en p-fenyleendiamine of diaminotoluenen; zouten van deze producten (m.u.v. halogeen-, sulfo-, nitro- en nitrosoderivaten, alsmede zouten daarvan)',
    29215950: 'm-Fenyleenbis"methylamine"; 2,2\'-dichloor-4,4\'-methyleendianiline; 4,4\'-bi-o-toluïdine; 1,8-naftyleendiamine',
    29215990: 'meerwaardige aromatische aminoverbindingen en derivaten daarvan; zouten van deze producten (m.u.v. o-, m- en p-fenyleendiamine en diaminotoluenen, alsmede derivaten en zouten daarvan; m-fenyleenbis[methylamine]; 4,4\'-bi-o-toluïdine; 1,8-naftyleendiamine)',
    29221100: 'monoëthanolamine en zouten daarvan',
    29221200: 'diëthanolamine en zouten daarvan',
    29221400: 'dextropropoxyfeen "INN" en zouten daarvan',
    29221500: 'Triëthanolamine',
    29221600: 'Diëthanolammoniumperfluoroctaansulfonaat',
    29221700: 'Methyldiëthanolamine en ethyldiëthanolamine',
    29221800: '2-(N,N-diisopropylamino)ethanol',
    29221900: 'Aminoalcoholen, andere dan die met zuurstofhoudende groepen van meer dan een soort, alsmede ethers en esters daarvan; zouten van deze producten (m.u.v.  monoëthanolamine , diëthanolamine , dextropropoxyfeen "INN" , en zouten daarvan, triëthanolamine, diëthanolammoniumperfluoroctaansulfonaat , methyldiëthanolamine en ethyldiëthanolamine en  2-(N,N-diisopropylamino)ethanol )',
    29222100: 'aminonaftolsulfonzuren en zouten daarvan',
    29222900: 'Aminonaftolen en andere aminofenolen alsmede ethers en esters daarvan; zouten van deze producten (m.u.v. die met zuurstofhoudende groepen van meer dan een soort, alsmede ethers en esters daarvan en m.u.v. aminonaftolsulfonzuren, alsmede zouten daarvan)',
    29223100: 'amfepramon "INN", methadon "INN" en normethadon "INN"; zouten van deze producten',
    29223900: 'aminoaldehyden, aminoketonen en aminochinonen (m.u.v. die met zuurstofhoudende groepen van meer dan een soort en m.u.v. amfepramon "INN", methadon "INN" en normethadon "INN", alsmede zouten daarvan); zouten van deze producten',
    29224100: 'lysine en esters daarvan; zouten van deze producten',
    29224200: 'glutaminezuur en zouten daarvan',
    29224300: 'antranilzuur en zouten daarvan',
    29224400: 'tilidine "INN" en zouten daarvan',
    29224920: 'beta-alanine',
    29224985: 'Aminozuren en esters daarvan; zouten van deze producten (m.u.v. die met zuurstofhoudende groepen van meer dan een soort en m.u.v. lysine en esters daarvan, glutaminezuur, antranilzuur, en tilidine "INN", alsmede zouten daarvan, en beta-alanine)',
    29225000: 'aminofenolalcoholen, aminofenolzuren en andere aminoverbindingen met zuurstofhoudende groepen (m.u.v. aminoalcoholen, aminonaftolen en andere aminofenolen, alsmede ethers en esters daarvan; zouten van deze producten; aminoaldehyden, aminoketonen en aminochinonen, alsmede zouten daarvan; aminozuren en esters daarvan alsmede zouten van deze producten)',
    29231000: 'choline en zouten daarvan',
    29232000: 'Lecithinen en andere fosfoaminolipiden, ook indien chemisch welbepaald',
    29233000: 'Tetraethylammoniumperfluoroctaansulfonaat',
    29234000: 'Didecyldimethylammoniumperfluoroctaansulfonaat',
    29239000: 'Quaternaire ammoniumzouten en -hydroxiden (m.u.v. choline en zouten daarvan, tetraethylammoniumperfluoroctaansulfonaat en didecyldimethylammoniumperfluoroctaansulfonaat)',
    29241100: 'meprobamaat "INN"',
    29241200: 'fluoroaceetamide (ISO), monocrotofos (ISO) en fosfamidon (ISO)',
    29241900: 'Acyclische amidoverbindingen, acyclische carbamaten daaronder begrepen, alsmede derivaten daarvan; zouten van deze producten (m.u.v. meprobamaat "INN", fluoroaceetamide [ISO], monocrotofos [ISO] en fosfamidon [ISO])',
    29242100: 'Ureïnen en derivaten daarvan; zouten van deze producten',
    29242300: '2-aceetamidobenzoëzuur "N-acetylantranilzuur" en zouten daarvan',
    29242400: 'ethinamaat "INN"',
    29242500: 'Alachloor (ISO)',
    29242910: 'lidocaïne "INN"',
    29242970: 'Cyclische amidoverbindingen, cyclische carbamaten daaronder begrepen, alsmede derivaten daarvan; zouten van deze producten (m.u.v. ureïnen en derivaten daarvan en 2-acealetamidobenzoëzuur "N-acetylantranilzuur", alsmede zouten daarvan, ethinamaat "INN", alachloor (ISO) en lidocaïne "INN" )',
    29251100: 'sacharine en zouten daarvan',
    29251200: 'glutethimide "INN"',
    29251920: '3,3\',4,4\',5,5\',6,6\'-octabroom-N,N\'-ethyleendiftaalimide;  N,N\'-ethyleenbis"4,5-dibroomhexahydro-3,6-methanoftaalimide"',
    29251995: 'imidoverbindingen en derivaten daarvan; zouten van deze producten (m.u.v. sacharine en zouten daarvan, glutethimide "INN", 3,3\',4,4\',5,5\',6,6\'-octabroom N,N\'-ethyleendiftaalimide en N,N\'-ethyleenbis"4,5-dibroomhexahydro-3,6-methanoftaalimide" en anorganische en organische kwikverbindingen)',
    29252100: 'chloordimeform (ISO)',
    29252900: 'iminoverbindingen en derivaten daarvan; zouten van deze producten (m.u.v. chloordimeform [ISO])',
    29261000: 'acrylonitril',
    29262000: '1-cyaanguanidine "dicyaandiamide"',
    29263000: 'fenproporex "INN" en zouten daarvan; methadon "INN"-tussenproduct "4-cyano-2-dimethylamino-4,4-difenylbutaan"',
    29264000: 'Alfa-fenylacetoacetonitril',
    29269020: 'isoftalonitril',
    29269070: 'Nitrillen "cyaanverbindingen" (m.u.v. acrylonitril, 1-cyaanguanidine "dicyaandiamide", fenproporex "INN" en zouten daarvan, methadon "INN"-tussenproduct "4-cyano-2-dimethylamino-4,4-difenylbutaan" , alfa-fenylacetoacetonitril en isoftalonitril)',
    29270000: 'diazo-, azo- en azoxyverbindingen',
    29280010: 'N,N-bis"2-methoxyethyl"hydroxylamine',
    29280090: 'derivaten, organisch, van hydrazine of van hydroxylamine (m.u.v. N,N-bis[2-methoxyethyl]hydroxylamine)',
    29291000: 'Isocyanaten',
    29299000: 'verbindingen met stikstofhoudende groepen (m.u.v. aminoverbindingen, aminoverbindingen met zuurstofhoudende groepen, quaternaire ammoniumzouten en -hydroxiden, lecithinen en andere fosfoaminolipiden, amidoverbindingen van carbonzuren of van koolzuur, imidoverbindingen van carbonzuren, iminoverbindingen en nitrillen "cyaanverbindingen", diazo-, azo- en azoxyverbindingen, organische derivaten van hydrazine of van hydroxylamine en isocyanaten)',
    29302000: 'thiocarbamaten en dithiocarbamaten (m.u.v. anorganische en organische kwikverbindingen)',
    29303000: 'thiurammono-, thiuramdi- en thiuramtetrasulfiden',
    29304010: 'methionine "INN"',
    29304090: 'methionine (m.u.v. methionine "INN")',
    29306000: '2-(N,N-Diethylamino)ethaanthiol',
    29307000: 'bis(2-hydroxyethyl)sulfide (thiodiglycol (INN))',
    29308000: 'aldicarb (ISO), captafol (ISO) en methamidofos (ISO)',
    29309013: 'cysteïne en cystine',
    29309016: 'derivaten van cysteïne of cystine',
    29309030: 'DL-2-hydroxy-4-"methylthio"boterzuur',
    29309040: '2,2\'-thiodiëthylbis[3-"3,5-di-tert-butyl-4-hydroxyfenyl"propionaat]',
    29309050: 'mengsels van isomeren bestaande uit 4-methyl-2,6-bis"methylthio"-m-fenyleendiamine en 2-methyl-4,6-bis"methylthio"-m-fenyleendiamine',
    29309098: 'Organische zwavelverbindingen (m.u.v. thio- en dithiocarbamaten; thiurammono-, thiuramdi- en thiuramtetrasulfiden; methionine; aldicarb (ISO), captafol (ISO) en methamidofos (ISO); cysteïne, derivaten daarvan; cystine, derivaten daarvan; bis(2-hydroxyethyl)sulfide (thiodiglycol (INN)) ; DL-2-hydroxy-4-[methylthio]boterzuur; 2,2\'-thiodiëthylbis[3-"3,5-di-tert-butyl-4-hydroxyfenyl"propionaat]; mengsels van isomeren bestaande uit 4-methyl-2,6-bis[methylthio]-m-fenyleendiamine en 2-methyl-4,6-bis[methylthio]-m-fenyleendiamine en 2-(N,N-Diethylamino)ethaanthiol)',
    29311000: 'Tetramethyllood en tetraethyllood',
    29312000: 'Tributyltinverbindingen',
    29313100: 'Dimethylmethylfosfonaat',
    29313200: 'Dimethylpropylfosfonaat',
    29313300: 'Diëthylethylfosfonaat',
    29313400: 'Natrium-3-(trihydroxysilyl)propylmethylfosfonaat',
    29313500: '2,4,6-tripropyl-1,3,5,2,4,6-trioxatrifosfinaan-2,4,6-trioxide',
    29313600: '(5-ethyl-2-methyl-2-oxido-1,3,2-dioxafosfinaan-5-yl)methyl-methyl-methylfosfonaat',
    29313700: 'bis[(5-ethyl-2-methyl-2-oxido-1,3,2-dioxafosfinaan-5-yl)methyl]methylfosfonaat',
    29313800: 'Zout van methylfosfonzuur en (aminoiminomethyl)ureum (1 : 1)',
    29313920: 'Methylfosfonoyldifluoride (methylfosfonzuurdifluoride)',
    29313930: 'Methylfosfonoyldichloride (methylfosfonzuurdichloride)',
    29313950: 'Etidroninezuur (INN) (1-hydroxyethaan-1,1-difosfonzuur) en zouten daarvan',
    29313960: '(Nitrilotrimethaandiyl)tris(fosfonzuur), {ethaan-1,2-diylbis[nitrilobis(methyleen)]}tetrakis(fosfonzuur), [(bis{2-[bis(fosfonomethyl)amino]ethyl}amino)methyl]fosfonzuur, {hexaan-1,6-diylbis[nitrilobis(methyleen)]}tetrakis(fosfonzuur), {[(2-hydroxyethyl)imino]bis(methyleen)}bis(fosfonzuur), en [(bis{6-[bis(fosfonomethyl)amino]hexyl}amino)methyl]fosfonzuur; zouten van deze producten',
    29313990: 'Andere organofosforderivaten n.e.g.',
    29319000: 'Organische en anorganische verbindingen van welbepaalde chemische samenstelling n.e.g. (m.u.v. organozwavel, kwik, tetramethyllood, tetraethyllood, tributyltin-verbindingen en organofosforderivaten)',
    29321100: 'tetrahydrofuraan',
    29321200: '2-furaldehyd "furfuraldehyd"',
    29321300: 'furfurylalcohol en tetrahydrofurfurylalcohol',
    29321400: 'Sucralose',
    29321900: 'Verbindingen, heterocyclisch, met uitsluitend één of meer zuurstofatomen als hetero-atoom met een al dan niet-gehydrogeneerde, niet-geanelleerde "niet-gecondenseerde" furaanring (m.u.v. tetrahydrofuraan, 2-furaldehyd "furfuraldehyd", furfurylalcohol,  tetrahydrofurfurylalcohol en sucralose)',
    29322010: 'fenolftaleïne; 1-hydroxy-4-[1-"4-hydroxy-3-methoxycarbonyl-1-naftyl"-3-oxo-1H, 3H-benzo[de]isochromen-1-yl]-6-octadecyloxy-2-naftoëzuur; 3\'-chloor-6\'-cyclohexylaminospiro[isobenzofuraan-1"3H", 9\'-xantheen]-3-on; 6\'-"N-ethyl-p-toluïdino"-2\'-methylspiro[isobenzofuraan-1"3H", 9\'-xantheen]-3-on; methyl-6-docosyloxy-1-hydroxy-4-[1-"4-hydroxy-3-methyl-1-fenantryl"-3-oxo-1H, 3H-nafto[1,8-cd]pyran-1-yl]naftaleen-2-carboxylaat)',
    29322020: 'gamma-butyrolacton',
    29322090: 'Lactonen (m.u.v. fenolftaleïne; 1-hydroxy-4-[1-"4-hydroxy-3-methoxycarbonyl-1-naftyl"-3-oxo-1H, 3H-benzo[de]isochromen-1-yl]-6-octadecyloxy-2-naftoëzuur; 3\'-chloor-6\'-cyclohexylaminospiro[isobenzofuraan-1"3H", 9\'-xantheen]-3-on; 6\'-[N-ethyl-p-toluïdino]-2\'-methylspiro[isobenzofuraan-1"3H", 9\'-xantheen]-3-on; methyl-6-docosyloxy-1-hydroxy-4-[1-"4-hydroxy-3-methyl-1-fenantryl"-3-oxo-1H, 3H-nafto[1,8-cd]pyran-1-yl]naftaleen-2-carboxylaat en gamma-butyrolacton)',
    29329100: 'isosafrool',
    29329200: '1-"1,3-benzodioxol-5-yl"propaan-2-on',
    29329300: 'piperonal',
    29329400: 'safrool',
    29329500: 'tetrahydrocannabinolen "alle isomeren"',
    29329900: 'Heterocyclische verbindingen met uitsluitend één of meer zuurstofatomen als hetero-atoom (m.u.v. die met een niet-geanelleerde "niet gecondenseerde" furaanring, ook indien gehydrogeneerd, en m.u.v. lactonen, isosafrool, 1-"1,3-benzodioxol-5-yl"propaan-2-on, piperonal, safrool, tetrahydrocannabinolen "alle isomeren",  en anorganische en organische kwikverbindingen)',
    29331110: 'propyfenazon "INN"',
    29331190: 'fenazon "antipyrine" en derivaten daarvan (m.u.v. propyfenazon "INN")',
    29331910: 'fenylbutazon "INN"',
    29331990: 'verbindingen, heterocyclisch, met uitsluitend één of meer stikstofatomen als hetero-atoom, met een al dan niet gehydrogeneerde, niet-geanelleerde "niet-gecondenseerde" pyrazoolring (m.u.v. fenazon "antipyrine" en derivaten daarvan en fenylbutazon "INN")',
    29332100: 'hydantoïne en derivaten daarvan',
    29332910: 'nafazolinehydrochloride "INNM" en nafazolinenitraat "INNM"; fentolamine "INN"; tolazolinehydrochloride "INNM"',
    29332990: 'Heterocyclische verbindingen met uitsluitend een of meer stikstofatomen als heteroatoom zijnde verbindingen met een al dan niet gehydrogeneerde, niet-geanelleerde (niet-gecondenseerde) imidazoolring (m.u.v. hydantoïne en derivaten daarvan, nafazolinehydrochloride (INNM) en nafazolinenitraat (INNM); fentolamine (INN); tolazolinehydrochloride (INNM))',
    29333100: 'pyridine en zouten daarvan',
    29333200: 'piperidine en zouten daarvan',
    29333300: 'alfentanil "INN", anileridine "INN", bezitramide "INN", bromazepam "INN", difenoxin "INN", difeenoxylaat "INN", dipipanon "INN", fencyclidine "INN" "PCP", fenoperidine "INN", fentanyl "INN", ketobemidon "INN", methylfenidaat "INN", pentazocine "INN", pethidine "INN", pethidine "INN" tussenproduct A, pipradrol "INN", piritramide "INN", propiram "INN" en trimeperidine "INN"; zouten van deze producten',
    29333910: 'iproniazide "INN"; ketobemidonhydrochloride "INNM"; pyridostigminebromide "INN"',
    29333920: '2,3,5,6-tetrachloorpyridine',
    29333925: '3,6-dichloorpyridine-2-carbonzuur',
    29333935: '2-hydroxyethylammonium-3,6-dichloorpyridine-2-carboxylaat',
    29333940: '2-butoxyethyl-"3,5,6-trichloor-2-pyridyloxy"acetaat',
    29333945: '3,5-dichloor-2,4,6-trifluorpyridine',
    29333950: 'methylester van fluroxypyr "ISO"',
    29333955: '4-methylpyridine',
    29333999: 'Heterocyclische verbindingen met uitsluitend één of meer stikstofatomen als hetero-atoom, met een niet geanelleerde "niet gecondenseerde" pyridinering, ook indien gehydrogeneerd (m.u.v. pyridine, piperidine, alfentanil "INN", anileridine "INN", bezitramide "INN", bromazepam "INN", difenoxin "INN", difeenoxylaat "INN", dipipanon "INN", fencyclidine "INN" "PCP", fenoperidine "INN", fentanyl "INN", ketobemidon "INN", methylfenidaat "INN", pentazocine "INN", pethidine "INN", pethidine "INN" tussenproduct A, pipradrol "INN", piritramide "INN", propiram "INN" en trimeperidine "INN", alsmede zouten van deze producten, en m.u.v. iproniazide "INN", ketobemidonhydrochloride "INNM", pyridostigminebromide "INN", 2,3,5,6-tetrachloorpyridine, 3,6-dichloorpyridine-2-carbonzuur, 2-hydroxyethylammonium-3,6-dichloorpyridine-2-carboxylaat, 2-butoxyethyl-"3,5,6-trichloor-2-pyridyloxy"acetaat, 3,5-dichloor-2,4,6-trifluorpyridine, methylester van fluroxypyr "ISO" en 4-methylpyridine en anorganische en organische kwikverbindingen)',
    29334100: 'levorfanol "INN" en zouten daarvan',
    29334910: 'Halogeenderivaten van chinoline; derivaten van chinolinecarbonzuren',
    29334930: 'Dextromethorfan "INN" en zouten daarvan',
    29334990: 'heterocyclische verbindingen met een chinoline- of isochinolinering, ook indien gehydrogeneerd, verder niet geanelleerd "gecondenseerd" (m.u.v. levorfanol "INN" en dextromethorfan "INN", alsmede zouten daarvan, en halogeenderivaten van chinoline en derivaten van chinolinecarbonzuren en anorganische of organische kwikverbindingen)',
    29335200: 'malonylureum "barbituurzuur" en zouten daarvan',
    29335310: 'barbital "INN", fenobarbital "INN", alsmede zouten daarvan',
    29335390: 'allobarbital "INN", amobarbital "INN", butalbital "INN", butobarbital, cyclobarbital "INN", methylfenobarbital "INN", pentobarbital "INN", secbutabarbital "INN", secobarbital "INN" en vinylbital "INN"; zouten van deze producten',
    29335400: 'derivaten van malonylureum "barbituurzuur"; zouten van deze producten (m.u.v. zouten van malonylureum)',
    29335500: 'loprazolam "INN", mecloqualon "INN", methaqualon "INN" en zipeprol "INN"; zouten van deze producten',
    29335910: 'diazinon "ISO"',
    29335920: '1,4-diazabicyclo[2.2.2]octaan "triëthyleendiamine"',
    29335995: 'Heterocyclische verbindingen met uitsluitend één of meer stikstofatomen als hetero-atoom, met een pyrimidinering, ook indien gehydrogeneerd, of met een piperazinering (m.u.v. malonylureum "barbituurzuur" en derivaten daarvan, allobarbital "INN", amobarbital "INN", barbital "INN", butalbital "INN", butobarbital, cyclobarbital "INN", fenobarbital "INN", loprazolam "INN", mecloqualon "INN", methaqualon "INN", methylfenobarbital "INN", pentobarbital "INN", secbutabarbital "INN", secobarbital "INN", vinylbital "INN" en zipeprol "INN", alsmede zouten van deze producten, diazinon "ISO" en 1,4-diazabicyclo[2.2.2]octaan "triëthyleendiamine")',
    29336100: 'melamine',
    29336910: 'atrazine "ISO"; propazine "ISO"; simazine "ISO"; hexahydro-1,3,5-trinitro-1,3,5-triazine "hexogeen, trimethyleentrinitramine"',
    29336940: 'Methenamine "INN" "hexamethyleentetramine";  2,6-di-tert-butyl-4-[4,6-bis"octylthio"-1,3,5-triazine-2-ylamino]-fenol',
    29336980: 'heterocyclische verbindingen met uitsluitend één of meer stikstofatomen als hetero-atoom, met een al dan niet gehydrogeneerde, niet-geanelleerde "niet-gecondenseerde" triazinering (m.u.v. melamine; atrazine [ISO]; propazine [ISO]; simazine [ISO]; hexahydro-1,3,5-trinitro-1,3,5-triazine [hexogeen, trimethyleentrinitramine]; methenamine "INN" [hexamethyleentetramine]; 2,6-di-tert-butyl-4-[4,6-bis"octylthio"-1,3,5-triazine-2-ylamino]-fenol)',
    29337100: '6-hexaanlactam "epsilon-caprolactam"',
    29337200: 'clobazam "INN" en methyprylon "INN"',
    29337900: 'Lactamen (m.u.v. 6-hexaanlactam "epsilon-caprolactam", clobazam "INN" en methyprylon "INN" en anorganische en organische kwikverbindingen)',
    29339110: 'chloordiazepoxide "INN"',
    29339190: 'alprazolam "INN", camazepam "INN", clonazepam "INN", clorazepaat, delorazepam "INN", diazepam "INN", estazolam "INN", ethylloflazepaat "INN", fludiazepam "INN", flunitrazepam "INN", flurazepam "INN", halazepam "INN", lorazepam "INN", lormetazepam "INN", mazindol "INN", medazepam "INN", midazolam "INN", nimetazepam "INN", nitrazepam "INN", nordazepam "INN", oxazepam "INN", pinazepam "INN", prazepam "INN", pyrovaleron "INN", temazepam "INN", tetrazepam "INN" en triazolam "INN"; zouten van deze producten en van chloordiazepoxide "INN"',
    29339200: 'Azinfos-methyl (ISO)',
    29339920: 'indool, 3-methylindool "skatol", 6-allyl-6,7-dihydro-5H-dibenzol"c,e"azepine "azapetine", fenindamine "INN" en zouten daarvan; imipraminehydrochloride "INNM"',
    29339950: '2,4-di-tert-butyl-6-"5-chloorbenzotriazool-2-yl"fenol',
    29339980: 'Heterocyclische verbindingen met uitsluitend één of meer stikstofatomen als hetero-atoom (m.u.v. die met een niet geanelleerde "niet gecondenseerde" pyrazool-, imidazool-, pyridine- of triazinering, ook indien gehydrogeneerd, die met een chinoline- of isochinolinering, ook indien gehydrogeneerd, verder niet geanelleerd "niet gecondenseerd", die met een pyrimidinering, ook indien gehydrogeneerd, of een piperazinering, en m.u.v. lactamen, alprazolam "INN", camazepam "INN", chloordiazepoxide "INN", clonazepam "INN", clorazepaat, delorazepam "INN", diazepam "INN", estazolam "INN", ethylloflazepaat "INN", fludiazepam "INN", flunitrazepam "INN", flurazepam "INN", halazepam "INN", lorazepam "INN", lormetazepam "INN", mazindol "INN", medazepam "INN", midazolam "INN", nimetazepam "INN", nitrazepam "INN", nordazepam "INN", oxazepam "INN", pinazepam "INN", prazepam "INN", pyrovaleron "INN", temazepam "INN", tetrazepam "INN", triazolam "INN", , indool, 3-methylindool "skatol", 6-allyl-6,7-dihydro-5H-dibenzol"c,e"azepine "azapetine", fenindamine "INN", alsmede zouten van deze producten, imipraminehydrochloride "INNM"; 2,4-di-tert-butyl-6-"5-chloorbenzotriazool-2-yl"fenol en azinfos-methyl (ISO) )',
    29341000: 'verbindingen, heterocyclisch, met een al dan niet gehydrogeneerde, niet-geanelleerde "niet-gecondenseerde" thiazoolring',
    29342020: 'di"benzothiazool-2-yl"disulfide; benzothiazool-2-thiol "mercaptobenzothiazool" en zouten daarvan',
    29342080: 'heterocyclische verbindingen met een benzothiazoolring, ook indien gehydrogeneerd, verder niet geanelleerd "gecondenseerd" (m.u.v. di"benzothiazool-2-yl"disulfide, benzothiazool-2-thiol "mercaptobenzothiazool" en zouten daarvan en anorganische en organische kwikverbindingen)',
    29343010: 'thiëthylperazine "INN"; thioridazine "INN" en zouten daarvan',
    29343090: 'heterocyclische verbindingen met een fenothiazinering, ook indien gehydrogeneerd, verder niet geanelleerd "niet gecondenseerd" (m.u.v. thiëthylperazine "INN", thioridazine "INN" en zouten daarvan)',
    29349100: 'aminorex "INN", brotizolam "INN", clotiazepam "INN", cloxazolam "INN", dextromoramide "INN", feendimetrazine "INN", feenmetrazine "INN", haloxazolam "INN", ketazolam "INN", mesocarb "INN", oxazolam "INN", pemoline "INN" en sufentanil "INN"; zouten van deze producten',
    29349960: 'Chloorprothixeen "INN"; thenalidine "INN" en tartraten en maleïnaten daarvan; furazolidoon "INN"; 7-aminocefalosporaanzuur; zouten en esters van "6R, 7R"-3-acetoxymethyl-7-["R"-2-formyl-oxy-2-fenylacetamido]-8-oxo-5-thia-1-azabicyclo[4.2.0]oct-2-een-2-carbonzuur; 1-[2-"1,3-dioxaan-2-yl"ethyl]-2-methylpyridiniumbromide',
    29349990: 'Nucleïnezuren en zouten daarvan, al dan niet chemisch welbepaald; andere heterocyclische verbindingen (m.u.v. verbindingen met een al dan niet gehydrogeneerde, niet-geanelleerde (niet-gecondenseerde) thiazoolring of verbindingen met een al dan niet gehydrogeneerde benzothiazoolring, niet verder geanelleerd (gecondenseerd), verbindingen met een al dan niet gehydrogeneerde fenothiazinering, niet verder geanelleerd (gecondenseerd); aminorex (INN), brotizolam (INN), clotiazepam (INN), cloxazolam (INN), dextromoramide (INN), feendimetrazine (INN), feenmetrazine (INN), haloxazolam (INN), ketazolam (INN), mesocarb (INN), oxazolam (INN), pemoline (INN) en sufentanil (INN); zouten van deze producten of chloorprothixeen (INN); thenalidine (INN) en tartraten en maleïnaten daarvan; furazolidoon (INN); 7-aminocefalosporaanzuur; zouten en esters van (6R,7R)-3-acetoxymethyl-7-[(R)-2-formyloxy-2-fenylaceetamido]-8-oxo-5-thia-1-azabicyclo[4.2.0]oct-2-een-2-carbonzuur; 1-[2-(1,3-dioxaan-2-yl)ethyl]-2-methylpyridiniumbromide)',
    29351000: 'N-methylperfluoroctaansulfonamide',
    29352000: 'N-ethylperfluoroctaansulfonamide',
    29353000: 'N-ethyl-N-(2-hydroxyethyl)perfluoroctaansulfonamide',
    29354000: 'N-(2-hydroxyethyl)-N-methylperfluoroctaansulfonamide',
    29355000: 'Perfluoroctaansulfonamidoverbindingen (m.u.v. N-methylperfluoroctaansulfonamide, N-ethylperfluoroctaansulfonamide, N-ethyl-N-(2-hydroxyethyl)perfluoroctaansulfonamide en N-(2-hydroxyethyl)-N-methylperfluoroctaansulfonamide   )',
    29359030: '3-{1-[7-(hexadecylsulfonylamino)-1H-indool-3-yl]-3-oxo-1H,3H-nafto[1,8-cd]pyran-1-yl}-N,N-dimethyl-1H-indool-7-sulfonamide; metosulam (ISO)',
    29359090: 'Sulfonamidoverbindingen (m.u.v. perfluoroctaansulfonamidooverbindingen,3-{1-[7-(hexadecylsulfonylamino)-1H-indool-3-yl]-3-oxo-1H,3H-nafto[1,8-cd]pyran-1-yl}-N,N-dimethyl-1H-indool-7-sulfonamide en metosulam (ISO) )',
    29362100: 'vitaminen A en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362200: 'vitamine B1 en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362300: 'vitamine B2 en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362400: 'D- en DL-pantotheenzuur "vitamine B3 of vitamine B5" en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362500: 'vitamine B6 en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362600: 'vitamine B12 en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362700: 'vitamine C en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362800: 'vitamine E en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt',
    29362900: 'Vitaminen en derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt, onvermengd (m.u.v. vitaminen A, B1, B2, B3, B5, B6, B12, C, E en derivaten daarvan)',
    29369000: 'Provitaminen en mengsels van vitaminen, van provitaminen of van concentraten, ook indien in oplossingen en natuurlijke concentraten van vitaminen',
    29371100: 'somatotropine "groeihormoon", alsmede derivaten en structuuranalogons daarvan die hoofdzakelijk als hormonen worden gebruikt',
    29371200: 'insuline en zouten daarvan die hoofdzakelijk als hormonen worden gebruikt',
    29371900: 'polypeptidehormonen, proteïnehormonen en glycoproteïnehormonen, alsmede derivaten en structuuranalogons daarvan die hoofdzakelijk als hormonen worden gebruikt (m.u.v. somatotropine "groeihormoon", alsmede derivaten en structuuranalogons daarvan, en insuline en zouten daarvan)',
    29372100: 'cortison, hydrocortison, prednison "dehydrocortison" en prednisolon "dehydrohydrocortison"',
    29372200: 'halogeenderivaten en gehalogeneerde derivaten van corticosteroïde hormonen "hormonen uit de bijnierschors"',
    29372300: 'oestrogenen en progestogenen',
    29372900: 'steroïdale hormonen, alsmede derivaten en structuuranalogons daarvan, die hoofdzakelijk als hormonen worden gebruikt (m.u.v. cortison, hydrocortison, prednison "dehydrocortison", prednisolon "dehydrohydrocortison", halogeenderivaten en gehalogeneerde derivaten van corticosteroïde hormonen "hormonen uit de bijnierschors", oestrogenen en progestogenen)',
    29375000: 'prostaglandinen, thromboxanen en leukotriënen, alsmede derivaten en structuuranalogons daarvan die hoofdzakelijk als hormonen worden gebruikt',
    29379000: 'hormonen, natuurlijke of door synthese gereproduceerd; derivaten en structuuranalogons daarvan die hoofdzakelijk als hormonen worden gebruikt (m.u.v. polypeptidehormonen, proteïnehormonen, glycoproteïnehormonen, steroïdale hormonen, prostaglandinen, thromboxanen en leukotriënen, alsmede derivaten en structuuranalogons daarvan)',
    29381000: 'rutoside "rutine" en derivaten daarvan',
    29389010: 'digitalisglucosiden',
    29389030: 'glycyrrizine en glycyrrizinaten',
    29389090: 'glucosiden "heterosiden", natuurlijk of door synthese gereproduceerd, alsmede zouten, ethers, esters en andere derivaten daarvan (m.u.v. rutoside "rutine" en derivaten daarvan, digitalisglucosiden, glycyrrizine en glycyrrizinaten)',
    29391100: 'concentraten van papaverbolkaf; buprenorfine "INN", codeïne, dihydrocodeïne "INN", ethylmorfine, etorfine "INN", folcodine "INN", heroïne, hydrocodon "INN", hydromorfon "INN", morfine, nicomorfine "INN", oxycodon "INN", oxymorfon "INN", thebacon "INN" en thebaïne; zouten van deze producten',
    29391900: 'opiumalkaloïden en derivaten daarvan; zouten van deze producten (m.u.v. concentraten van papaverbolkaf; buprenorfine "INN", codeïne, dihydrocodeïne "INN", ethylmorfine, etorfine "INN", folcodine "INN", heroïne, hydrocodon "INN", hydromorfon "INN", morfine, nicomorfine "INN", oxycodon "INN", oxymorfon "INN", thebacon "INN" en thebaïne, alsmede zouten van deze producten)',
    29392000: 'Kina-alkaloïden en derivaten daarvan; zouten van deze producten',
    29393000: 'cafeïne en zouten daarvan',
    29394100: 'efedrine en zouten daarvan',
    29394200: 'pseudo-efedrine "INN" en zouten daarvan',
    29394300: 'cathine "INN" en zouten daarvan',
    29394400: 'norefedrine en zouten daarvan',
    29394900: 'efedrinen en zouten daarvan (m.u.v. efedrine, pseudo-efedrine "INN" en cathine "INN", alsmede zouten daarvan, norefedrine en zouten daarvan)',
    29395100: 'fenetylline "INN" en zouten daarvan',
    29395900: 'theofylline en aminofylline "theofylline-ethyleendiamine", alsmede derivaten daarvan; zouten van deze producten (m.u.v. fenetylline "INN" en zouten daarvan)',
    29396100: 'ergometrine "INN" en zouten daarvan',
    29396200: 'ergotamine "INN" en zouten daarvan',
    29396300: 'lyserginezuur en zouten daarvan',
    29396900: 'alkaloïden van moederkoren en derivaten daarvan; zouten van deze producten (m.u.v. lyserginezuur, ergotamine en ergometrine, alsmede zouten van deze producten)',
    29397100: 'Cocaïne, ecgonine, levometamfetamine, metamfetamine "INN", metamfetamineracemaat; zouten, esters en andere derivaten van deze producten',
    29397910: 'Nicotine, alsmede zouten, ethers, esters en andere derivaten daarvan',
    29397990: 'Plantaardige alkaloïden, natuurlijke of door synthese gereproduceerd, alsmede zouten, ethers, esters en andere derivaten daarvan (m.u.v. opiumalkaloïden, kina-alkaloïden, theofylline en aminofylline "theofylline-ethyleendiamine" en alkaloïden van moederkoren, alsmede zouten en derivaten van deze producten, m.u.v. cocaïne, ecgonine, levometamfetamine, metamfetamine "INN" en metamfetamineracemaat, alsmede zouten, esters en andere derivaten van deze producten, m.u.v. cafeïne en efedrinen, alsmede zouten van deze producten, en m.u.v. nicotine, alsmede zouten, ethers, esters en andere derivaten daarvan)',
    29398000: 'Alkaloïden van niet-plantaardige oorsprong, natuurlijke of door synthese gereproduceerd, alsmede zouten, ethers, esters en andere derivaten daarvan',
    29400000: 'Suikers, chemisch zuiver (m.u.v. sacharose, lactose, maltose, glucose en fructose "levulose"); ethers, acetalen en esters van suikers, alsmede zouten daarvan (m.u.v. natuurlijke of door synthese gereproduceerde provitaminen, vitaminen, hormonen, glucosiden, plantaardige alkaloïden, alsmede zouten, ethers, esters en andere derivaten daarvan)',
    29411000: 'Penicillinen en derivaten daarvan met een structuur van penicillaanzuur; zouten van deze producten',
    29412030: 'dihydrostreptomycine, alsmede zouten, esters en hydraten daarvan',
    29412080: 'streptomycinen en derivaten daarvan; zouten van deze producten (m.u.v. dihydrostreptomycine en zouten, esters en hydraten daarvan)',
    29413000: 'tetracyclinen en derivaten daarvan; zouten van deze producten',
    29414000: 'chlooramfenicol en derivaten daarvan; zouten van deze producten',
    29415000: 'erytromycine en derivaten daarvan; zouten van deze producten',
    29419000: 'antibiotica (m.u.v. penicillinen en derivaten daarvan met een structuur van penicillaanzuur; zouten van deze producten, streptomycinen, tetracyclinen, chlooramfenicol en erytromycine, alsmede derivaten daarvan; zouten van deze producten)',
    29420000: 'verbindingen, geïsoleerd, chemisch welbepaald, n.e.g.',
    30012010: 'extracten van klieren of van ander organen, of van afscheidingsproducten daarvan, van menselijke oorsprong, voor opotherapeutisch gebruik',
    30012090: 'extracten van klieren of van ander organen, of van afscheidingsproducten daarvan, van dieren, voor opotherapeutisch gebruik',
    30019020: 'klieren en andere organen, voor opotherapeutisch gebruik, gedroogd, al dan niet in poedervorm, andere stoffen van menselijke oorsprong, bereid voor therapeutisch of profylactisch gebruik, n.e.g.',
    30019091: 'heparine en zouten daarvan',
    30019098: 'Klieren en andere organen van dierlijke oorsprong, voor opotherapeutisch gebruik, gedroogd, ook indien in poedervorm, en andere stoffen van dierlijke oorsprong, bereid voor therapeutisch of profylactisch gebruik, n.e.g. (m.u.v. heparine en zouten daarvan)',
    30021100: 'Diagnostische testpakketten voor malaria',
    30021200: 'Sera van geïmmuniseerde dieren of personen, alsmede andere bloedfracties',
    30021300: 'Immunologische producten, onvermengd, noch in afgemeten hoeveelheden noch opgemaakt voor de verkoop in het klein',
    30021400: 'Immunologische producten, vermengd, noch in afgemeten hoeveelheden noch opgemaakt voor de verkoop in het klein',
    30021500: 'Immunologische producten,  in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein',
    30021900: 'Immunologische producten, n.e.g.',
    30022000: 'Vaccins voor mensen',
    30023000: 'Vaccins voor dieren',
    30029010: 'Menselijk bloed',
    30029030: 'Dierlijk bloed bereid voor therapeutisch of profylactisch gebruik of voor het stellen van diagnosen',
    30029050: 'Culturen van micro-organismen (m.u.v. gist)',
    30029090: 'Toxinen e.d. producten "b.v. malariaparasieten" (m.u.v. vaccins en culturen van micro-organismen)',
    30031000: 'Geneesmiddelen bevattende penicillinen of derivaten daarvan met een structuur van penicillaanzuur, dan wel streptomycinen of derivaten daarvan (m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30032000: 'Geneesmiddelen bevattende antibiotica (m.u.v. geneesmiddelen bevattende penicillinen of derivaten daarvan met een structuur van penicillaanzuur, dan wel streptomycinen of derivaten daarvan en m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30033100: 'Geneesmiddelen bevattende insuline (m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30033900: 'Geneesmiddelen bevattende hormonen of derivaten daarvan en andere steroïden, die hoofdzakelijk als hormonen worden gebruikt (m.u.v. geneesmiddelen bevattende insuline of antibiotica en m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30034100: 'Geneesmiddelen bevattende efedrine of zouten daarvan (m.u.v. geneesmiddelen bevattende antibiotica of hormonen en derivaten daarvan en andere steroïden, die hoofdzakelijk als hormonen worden gebruikt, en m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30034200: 'Geneesmiddelen bevattende pseudo-efedrine (INN) of zouten daarvan (m.u.v. geneesmiddelen bevattende antibiotica of hormonen en derivaten daarvan en andere steroïden, die hoofdzakelijk als hormonen worden gebruikt, en m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30034300: 'Geneesmiddelen bevattende norefedrine of zouten daarvan (m.u.v. geneesmiddelen bevattende antibiotica of hormonen en derivaten daarvan en andere steroïden, die hoofdzakelijk als hormonen worden gebruikt, en m.u.v. geneesmiddelen in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein)',
    30034900: 'Geneesmiddelen bevattende alkaloïden of derivaten daarvan , niet in afgemeten hoeveelheden en niet opgemaakt voor de verkoop in het klein (m.u.v. geneesmiddelen bevattende antibiotica of hormonen en derivaten daarvan en andere steroïden, die hoofdzakelijk als hormonen worden gebruikt, en m.u.v. geneesmiddelen bevattende efedrine, pseudo-efedrine (INN), norefedrine of zouten daarvan)',
    30036000: 'Geneesmiddelen, bevattende één van volgende werkzame bestanddelen tegen malaria: artemisinine "INN" voor orale inname gecombineerd met andere werkzame farmaceutische bestanddelen, of  amodiaquine "INN"; artelinic zuur of zouten daarvan; artenimol "INN"; artemotil "INN"; artemether "INN"; artesunaat "INN; chloroquine "INN"; dihydroartemisinine "INN";  lumefantrine "INN"; mefloquine "INN"; piperaquine "INN"; pyrimethamine "INN"; of  sulfadoxine "INN", doch geen hormonen, steroïden gebruikt als hormonen of antibiotica bevattend, niet in afgemeten hoeveelheden of opgemaakt voor de verkoop in het klein',
    30039000: 'Geneesmiddelen bestaande uit voor therapeutisch of profylactisch gebruik vermengde zelfstandigheden niet in afgemeten hoeveelheden en niet opgemaakt voor de verkoop in het klein (m.u.v. penicillinen, antibiotica, hormonen en andere steroïden die hoofdzakelijk als hormonen worden gebruikt, alkaloïden of derivaten daarvan bedoeld bij de posten 3002, 3005 of 3006)',
    30041000: 'Geneesmiddelen bevattende penicillinen of derivaten daarvan met een structuur van penicillaanzuur, dan wel streptomycinen of derivaten daarvan, in afgemeten hoeveelheden " ook die in de vorm van systemen voor gereguleerde toediening door de huid", dan wel opgemaakt voor de verkoop in het klein',
    30042000: 'Geneesmiddelen bevattende antibiotica, in afgemeten hoeveelheden " ook die in de vorm van systemen voor gereguleerde toediening door de huid", dan wel opgemaakt voor de verkoop in het klein (m.u.v.  geneesmiddelen bevattende penicillinen of derivaten daarvan met een structuur van penicillaanzuur, dan wel streptomycinen of derivaten daarvan)',
    30043100: 'Geneesmiddelen bevattende insuline, in afgemeten hoeveelheden " ook die in de vorm van systemen voor gereguleerde toediening door de huid", dan wel opgemaakt voor de verkoop in het klein (m.u.v.  geneesmiddelen die antibiotica bevatten)',
    30043200: 'Geneesmiddelen bevattende corticosteroïde hormonen, alsmede derivaten en structuuranalogons daarvan, doch geen antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid", opgemaakt voor de verkoop in het klein',
    30043900: 'Geneesmiddelen bevattende hormonen of als hormonen gebruikte steroïden, doch geen antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid" en opgemaakt voor de verkoop in het klein (m.u.v. geneesmiddelen die insuline of corticosteroïde hormonen, alsmede derivaten en structuuranalogons daarvan bevatten)',
    30044100: 'Geneesmiddelen bevattende efedrine of zouten daarvan, doch geen hormonen of als hormonen gebruikte steroïden noch antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid", opgemaakt voor de verkoop in het klein',
    30044200: 'Geneesmiddelen bevattende pseudo-efedrine (INN) of zouten daarvan, doch geen hormonen of als hormonen gebruikte steroïden noch antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid", opgemaakt voor de verkoop in het klein',
    30044300: 'Geneesmiddelen bevattende norefedrine of zouten daarvan, doch geen hormonen of als hormonen gebruikte steroïden noch antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid", opgemaakt voor de verkoop in het klein',
    30044900: 'Geneesmiddelen bevattende alkaloïden of derivaten daarvan, doch geen hormonen of als hormonen gebruikte steroïden noch antibiotica, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid", opgemaakt voor de verkoop in het klein (m.u.v. die bevattende efedrine, pseudo-efedrine (INN), norefedrine of zouten daarvan)',
    30045000: 'Geneesmiddelen, bevattende provitaminen en vitaminen, natuurlijke concentraten daaronder begrepen, of derivaten daarvan die hoofdzakelijk als vitaminen worden gebruikt, in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid",  opgemaakt voor de verkoop in het klein (m.u.v.  geneesmiddelen die antibiotica, hormonen, alkaloïden, of derivaten daarvan bevatten)',
    30046000: 'Geneesmiddelen, bevattende één van volgende werkzame bestanddelen tegen malaria: artemisinine "INN" voor orale inname gecombineerd met andere werkzame farmaceutische bestanddelen, of  amodiaquine "INN"; artelinic zuur of zouten daarvan; artenimol "INN"; artemotil "INN"; artemether "INN"; artesunaat "INN; chloroquine "INN"; dihydroartemisinine "INN";  lumefantrine "INN"; mefloquine "INN"; piperaquine "INN"; pyrimethamine "INN"; of  sulfadoxine "INN" in afgemeten hoeveelheden "ook die in de vorm van systemen voor gereguleerde toediening door de huid",  opgemaakt voor de verkoop in het klein (m.u.v. geneesmiddelen die antibiotica, hormonen, alkaloïden, provitaminen, vitaminen of derivaten daarvan bevatten)',
    30049000: 'Geneesmiddelen bestaande uit al dan niet vermengde producten voor therapeutisch of profylactisch gebruik, in afgemeten hoeveelheden (ook die in de vorm van systemen voor gereguleerde toediening door de huid), dan wel opgemaakt voor de verkoop in het klein (m.u.v. geneesmiddelen bevattende antibiotica, bevattende hormonen of steroiden gebruikt als hormonen, bevattende alkaloïden of derivaten daarvan,  geneesmiddelen die provitaminen, vitaminen of als vitaminen gebruikte derivaten daarvan bevatten of geneesmiddelen met werkzame bestanddelen tegen malaria)',
    30051000: 'hechtpleisters en andere artikelen met een klevende laag, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden',
    30059010: 'watten en artikelen van watten, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden',
    30059031: 'gaas en artikelen van gaas, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden',
    30059050: 'Verband, zwachtels e.d. artikelen, van textielstoffen, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden (m.u.v. watten, gaas, artikelen van watten of van gaas, hechtpleisters en andere artikelen met een klevende laag)',
    30059099: 'verband, zwachtels e.d. artikelen, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden (m.u.v. die van textielstoffen en m.u.v. hechtpleisters en andere artikelen met een klevende laag)',
    30061010: 'steriel catgut',
    30061030: 'steriele barrièremiddelen tegen het verkleven, voor de chirurgie en voor de tandheelkunde, ook indien zij door het lichaam kunnen worden geabsorbeerd',
    30061090: 'steriel hechtdraad en hechtgaren en steriele kleefstoffen, voor het dichten van wonden in de chirurgie; steriele laminaria; steriele bloedstelpende artikelen, die door het lichaam worden geabsorbeerd, voor de chirurgie en voor de tandheelkunde (m.u.v. catgut)',
    30062000: 'reagentia voor het bepalen van bloedgroepen of van bloedfactoren',
    30063000: 'röntgencontrastmiddelen, alsmede reageermiddelen voor het stellen van een diagnose, bestemd om aan de patiënt zelf te worden toegediend',
    30064000: 'tandcement en andere producten voor tandvulling; beendercement',
    30065000: 'tassen, dozen, trommels e.d., gevuld met artikelen voor eerste hulp bij ongelukken',
    30066000: 'chemische anticonceptionele preparaten van hormonen, van prostaglandinen, van thromboxanen, van leukotriënen, van derivaten en structuuranalogons daarvan, of van spermiciden',
    30067000: 'bereidingen in de vorm van een gel die worden toegepast bij de mens- of diergeneeskunde op bepaalde delen van het lichaam als smeermiddel bij chirurgische handelingen of medisch onderzoek of als koppelmiddel tussen het lichaam en de medische instrumenten',
    30069100: 'hulpmiddelen die herkenbaar zijn voor gebruik in de stomazorg',
    30069200: 'farmaceutische afvallen',
    31010000: 'meststoffen van dierlijke of van plantaardige oorsprong, ook indien onderling vermengd of chemisch behandeld; meststoffen verkregen door vermenging of chemische behandeling van dierlijke of plantaardige producten (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31021010: 'ureum, ook indien in waterige oplossing, met een gehalte aan stikstof van > 45 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31021090: 'ureum, ook indien in waterige oplossing, met een stikstofgehalte van <= 45 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. ureum in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31022100: 'ammoniumsulfaat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31022900: 'dubbelzouten en mengsels van ammoniumsulfaat en ammoniumnitraat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31023010: 'ammoniumnitraat in waterige oplossing (m.u.v. die in verpakkingen met een brutogewicht van <= 10 kg)',
    31023090: 'ammoniumnitraat (m.u.v. die in waterige oplossing en die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31024010: 'mengsels van ammoniumnitraat en calciumcarbonaat of andere niet- vruchtbaarmakende anorganische stoffen, voor gebruik als meststof, met een stikstofgehalte van <= 28 gewichtspercenten (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31024090: 'mengsels van ammoniumnitraat en calciumcarbonaat of andere niet- vruchtbaarmakende anorganische stoffen, voor gebruik als meststof, met een stikstofgehalte van >= 28 gewichtspercenten (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31025000: 'natriumnitraat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31026000: 'dubbelzouten en mengsels van calciumnitraat en amoniumnitraat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31028000: 'mengsels van ureum en ammoniumnitraat, opgelost in water of in ammoniakwater (m.u.v. die in verpakkingen met een brutogewicht van niet meer dan 10 kg)',
    31029000: 'minerale of chemische stikstofhoudende meststoffen (m.u.v. ureum; ammoniumsulfaat; ammoniumnitraat; natriumnitraat; dubbelzouten en mengsels van ammoniumnitraat met ammoniumsulfaat of calciumnitraat; mengsels van ureum en ammoniumnitraat, opgelost in water of in ammoniakwater; mengsels van ammoniumnitraat  en calciumcarbonaat of andere niet-vruchtbaarmakende anorganische stoffen en m.u.v. meststoffen in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31031100: 'Superfosfaat met een gehalte aan difosforpentaoxide "P2O5" van => 35 gewichtspercenten (m.u.v. dat in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31031900: 'Superfosfaat (m.u.v. die met een gehalte aan difosforpentaoxide van => 35 gewichtspercenten en m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31039000: 'minerale of chemische fosfaatmeststoffen (m.u.v. superfosfaat en m.u.v. van minerale of chemische fosfaatmeststoffen in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31042010: 'Kaliumchloride met een gehalte aan kalium berekend als kaliummonoxide van <= 40 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31042050: 'Kaliumchloride met een gehalte aan kalium berekend als kaliummonoxide van > 40 doch <= 62 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31042090: 'Kaliumchloride met een gehalte aan kalium berekend als kaliummonoxide van > 62 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31043000: 'Kaliumsulfaat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31049000: 'Carnalliet, sylviniet en andere ruwe natuurlijke kalizouten, kaliummagnesiumsulfaat alsmede mengsels van kalimeststoffen [b.v. mengsels van kaliumchloride en kaliumsulfaat] (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31051000: 'Meststoffen van dierlijke of van plantaardige oorsprong en minerale of chemische meststof in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg',
    31052010: 'Minerale of chemische meststoffen die de drie vruchtbaarmakende elementen stikstof, fosfor en kalium bevatten, met een gehalte aan stikstof > 10 gewichtspercenten, berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31052090: 'Minerale of chemische meststoffen die de drie vruchtbaarmakende elementen stikstof, fosfor en kalium bevatten, met een gehalte aan stikstof <= 10 gewichtspercenten berekend op het droge kristalwatervrije product (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31053000: 'Diammoniumwaterstoforthofosfaat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31054000: 'Ammoniumdiwaterstoforthofosfaat en mengsels daarvan met diammoniumwaterstoforthofosfaat (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31055100: 'Minerale of chemische meststoffen die nitraten en fosfaten bevatten (m.u.v. ammoniumdiwaterstoforthofosfaat, diammoniumwaterstoforthofosfaat en die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31055900: 'Minerale of chemische meststoffen die de twee vruchtbaarmakende elementen stikstof en fosfor bevatten (m.u.v. die welke nitraten bevatten, m.u.v. ammoniumdiwaterstoforthofosfaat en diammoniumwaterstoforthofosfaat en m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31056000: 'Minerale of chemische meststoffen die de twee vruchtbaarmakende elementen fosfor en kalium bevatten  (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31059020: 'Minerale of chemische meststoffen die de twee vruchtbaarmakende elementen stikstof en kalium of slechts een hoofdzakelijk vruchtbaarmakend element bevatten, incl. mengsels van dierlijke of plantaardige meststoffen met chemische of minerale meststoffen, met een gehalte aan stikstof van > 10 gewichtspercenten (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    31059080: 'minerale of chemische meststoffen die de twee vruchtbaarmakende elementen stikstof en kalium of slechts een hoofdzakelijk vruchtbaarmakend element bevatten, incl. mengsels van dierlijke of plantaardige meststoffen met chemische of minerale meststoffen, geen stikstof bevattend of met een gehalte aan stikstof van <= 10 gewichtspercenten (m.u.v. die in tabletten of in dergelijke vormen, dan wel in verpakkingen met een brutogewicht van <= 10 kg)',
    32011000: 'looiextract van quebracho',
    32012000: 'looiextract van mimosabast',
    32019020: 'looiextracten van sumak, van valonea\'s, van eik of van kastanje',
    32019090: 'Looiextracten van plantaardige oorsprong; tannine (looizuur), alsmede zouten, ethers, esters en andere derivaten daarvan (m.u.v. looiextract van quebracho, looiextract van mimosabast, looiextract van sumak, van valonea\'s, van eik of van kastanje)',
    32021000: 'synthetische organische looistoffen',
    32029000: 'anorganische looistoffen; preparaten voor het looien, ook indien zij natuurlijke looistoffen bevatten; enzympreparaten voor het voorlooien',
    32030010: 'kleurstoffen van plantaardige oorsprong, incl. verfstofextracten, ook indien chemisch welbepaald, preparaten op basis van kleurstoffen van plantaardige oorsprong van de soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32030090: 'kleurstoffen van dierlijke oorsprong, incl. verfstofextracten (m.u.v. dierlijk zwart), ook indien chemisch welbepaald, preparaten op basis van kleurstoffen van dierlijke oorsprong van de soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041100: 'synthetische, organische, gedispergeerde kleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen en als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische gedispergeerde kleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041200: 'synthetische organische zure kleurstoffen, ook indien gemetalliseerd en synthetische organische bijtende kleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische zure kleurstoffen en bijtende kleurstofzuren (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041300: 'synthetische organische basische kleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische basische kleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041400: 'synthetische organische directe kleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische directe kleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041500: 'synthetische organische kuipkleurstoffen, incl. die als zodanig geschikt zijn als pigment; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische kuipkleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041600: 'synthetische organische reactieve kleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische reactieve kleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041700: 'synthetische organische pigmentkleurstoffen; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van synthetische organische pigmentkleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32041900: 'synthetische organische kleurstoffen (m.u.v. gedispergeerde zure bijtende basische directe reactieve kuip- en pigmentkleurstoffen); preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van deze kleurstoffen (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215); mengsels van kleurstoffen bedoeld bij twee of meer van de onderverdelingen 3204.11 tot en met 3204.19',
    32042000: 'synthetische organische producten van het soort gebruikt als fluorescerende heldermakende stoffen, ook indien chemisch welbepaald',
    32049000: 'synthetische organische producten van de soort gebruikt als luminoforen "lichtgevende stoffen", ook indien chemisch welbepaald',
    32050000: 'Verflakken (m.u.v. Japanse of Chinese lak en lakverven); preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van verflakken (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215);',
    32061100: 'Pigmenten en preparaten op basis van titaandioxide, van de soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten, bevattende >= 80 gewichtspercenten titaandioxide berekend op de droge stof (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32061900: 'Pigmenten en preparaten op basis van titaandioxide, van de soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten, bevattende < 80 gewichtspercenten titaandioxide berekend op de  droge stof (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32062000: 'pigmenten en preparaten op basis van chroomverbindingen, van de soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3212, 3213 en 3215)',
    32064100: 'Ultramarijn en preparaten op basis van ultramarijn van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3213 en 3215);',
    32064200: 'Lithopoon en andere pigmenten en preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van zinksulfide (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3213 en 3215);',
    32064910: 'fijngemalen magnetiet',
    32064970: 'Anorganische verfstoffen en verfstoffen van minerale oorsprong, n.e.g.; preparaten van het soort gebruikt voor het kleuren van stoffen of als bestanddeel bij de vervaardiging van kleurpreparaten op basis van anorganische verfstoffen en verfstoffen van minerale oorsprong n.e.g. (m.u.v. preparaten bedoeld bij de posten 3207, 3208, 3209, 3210, 3213 en 3215 en m.u.v. anorganische producten van de soort gebruikt als luminoforen "lichtgevende stoffen" en magnetiet)',
    32065000: 'anorganische producten van het soort gebruikt als luminoforen "lichtgevende stoffen", ook indien chemisch welbepaald',
    32071000: 'bereide pigmenten, bereide opacifieermiddelen, bereide verfstoffen e.d. preparaten, van de soort gebruikt voor keramiek, voor het emailleren of voor glaswerk',
    32072010: 'engobes "slips"',
    32072090: 'verglaasbare samenstellingen e.d. preparaten van de soort gebruikt voor keramiek, voor het emailleren of voor glaswerk (m.u.v. engobes "slips")',
    32073000: 'vloeibare glansmiddelen e.d. preparaten van de soort gebruikt voor keramiek, voor het emailleren of voor glaswerk',
    32074040: 'Glas in de vorm van vlokken met een lengte van >= 0,1 doch <= 3,5 mm en een dikte van >= 2 doch <= 5 micrometer en glas in de vorm van poeder of van korreltjes, met een gehalte aan siliciumdioxide van >= 99 gewichtspercenten (m.u.v. glazuurglas [email]',
    32074085: 'glasfritten en ander glas, in de vorm van poeder, van korreltjes, van schilfers of van vlokken (m.u.v. glas in de vorm van vlokken met een lengte van >= 0,1, doch <= 3,5 mm en een dikte van >= 2, doch <= 5 micrometer; glas in de vorm van poeder of van korreltjes met een gehalte aan siliciumdioxide van >= 99 gewichtspercenten)',
    32081010: 'oplossingen van polyesters in vluchtige organische oplosmiddelen die > 50 gewichtspercenten oplosmiddelen bevatten',
    32081090: 'verf en vernis op basis van polyesters, gedispergeerd of opgelost in een niet-waterig medium',
    32082010: 'oplossingen van acryl- of vinylpolymeren in vluchtige organische oplosmiddelen die > 50 gewichtspercenten oplosmiddelen bevatten',
    32082090: 'verf en vernis op basis van acryl- of vinylpolymeren, gedispergeerd of opgelost in een niet-waterig medium',
    32089011: 'polyurethaan vervaardigd van 2,2\'-"tert-butylimino"diëthanol en 4,4\'-methyleendicyclohexyldiisocyanaat, opgelost in N,N-dimethylaceetamide, met een gehalte aan polymeer van >= 48 doch < 50 gewichtspercenten',
    32089013: 'copolymeer van p-kresol en divinylbenzeen, opgelost in N,N-dimethylaceetamide, met een gehalte aan polymeer van >= 48 doch < 50 gewichtspercenten',
    32089019: 'oplossingen van producten bedoeld bij de posten 3901 tot en met 3913 in vluchtige organische oplosmiddelen die > 50 gewichtspercenten oplosmiddelen bevatten (m.u.v. die van polyesters, die van acryl- of vinylpolymeren; collodion; polyurethaan vervaardigd van 2,2\'-[tert-butylimino]diëthanol en 4,4\'-methyleendicyclohexyldiisocyanaat en copolymeer van p-kresol en divinylbenzeen ieder opgelost in N,N-dimethylaceetamide, met een gehalte aan polymeer van >= 48 gewichtspercenten)',
    32089091: 'verf en vernis op basis van synthetische polymeren, gedispergeerd of opgelost in een niet-waterig medium (m.u.v. die op basis van polyesters of van acryl- of vinylpolymeren)',
    32089099: 'verf en vernis op basis van gewijzigde natuurlijke polymeren, gedispergeerd of opgelost in een niet-waterig medium',
    32091000: 'verf en vernis op basis van acryl- of vinylpolymeren, gedispergeerd of opgelost in een waterig medium',
    32099000: 'verf en vernis op basis van synthetische polymeren of gewijzigde natuurlijke polymeren, gedispergeerd of opgelost in een waterig medium (m.u.v. die op basis van acryl- of vinylpolymeren)',
    32100010: 'olieverf en -vernis',
    32100090: 'verf en vernis (m.u.v. die op basis van synthetische polymeren of gewijzigde natuurlijke polymeren en m.u.v. olieverf en -vernis); bereide waterverfpigmenten van de soort gebruikt voor het afwerken van leer "lederverven"',
    32110000: 'bereide siccatieven',
    32121000: 'stempelfoliën van een soort gebruikt voor het bedrukken van boekbanden en van hoedbanden',
    32129000: 'pigmenten, incl. metaalpoeder en metaalvlokken, gedispergeerd in een niet- waterig medium, als vloeistof of als pasta, van het soort gebruikt voor de vervaardiging van verf;  kleur- en verfstoffen n.e.g., opgemaakt voor de verkoop in het klein',
    32131000: 'verf in assortimenten voor kunstschilders, voor onderwijsdoeleinden of voor vermaak, plakkaatverf en kleurpasta\'s, in tabletten, in tubes, in flesjes, in bakjes of in dergelijke verpakkingsmiddelen',
    32139000: 'verf voor kunstschilders, voor onderwijsdoeleinden of voor vermaak, plakkaatverf en kleurpasta\'s, in tabletten, in tubes, in flesjes, in bakjes of in dergelijke verpakkingsmiddelen (m.u.v. verf in assortimenten)',
    32141010: 'stopverf, harscement en ander mastiek "kit"',
    32141090: 'plamuur',
    32149000: 'niet-vuurvaste preparaten van de soort gebruikt voor het bestrijken of bepleisteren van metselwerk',
    32151100: 'Zwarte drukinkt, ook indien geconcentreerd of in vaste vorm',
    32151900: 'Drukinkt, ook indien geconcentreerd of in vaste vorm (m.u.v. zwarte drukinkt)',
    32159020: 'Inktpatronen (zonder geïntegreerde printkop) voor gebruik in toestellen bedoeld bij de onderverdelingen 8443 31, 8443 32 of 8443 39, met mechanische of elektrische onderdelen, en vaste inkt in technische vervaardigde vormen voor gebruik in toestellen bedoeld bij de onderverdelingen 8443 31, 8443 32 of 8443 39 (m.u.v. inkt niet beschouwd als "drukinkt"  meestal als pasta)',
    32159070: 'Inkt, ook indien geconcentreerd of in vaste vorm (m.u.v. drukinkt, inktpatronen voor gebruik in toestellen bedoeld bij de onderverdelingen 8443 31, 8443 32 of 8443 39, met mechanische of elektrische onderdelen, en vaste inkt in technische vervaardigde vormen voor gebruik in toestellen bedoeld bij de onderverdelingen 8443 31, 8443 32 of 8443 39)',
    33011210: 'sinaasappelolie, met de terpenen, vast of vloeibaar, m.u.v. oranjebloesemolie "neroli-olie"',
    33011290: 'sinaasappelolie waaruit de terpenen zijn afgesplitst, vast of vloeibaar, m.u.v. oranjebloesemolie "neroli-olie"',
    33011310: 'citroenolie, met de terpenen, vast of vloeibaar',
    33011390: 'citroenolie waaruit de terpenen zijn afgesplitst, vast of vloeibaar',
    33011920: 'oliën, etherisch, van citrusvruchten, met de terpenen, vast of vloeibaar (m.u.v.  sinaasappelolie en citroenolie)',
    33011980: 'oliën, etherisch, van citrusvruchten, waaruit de terpenen zijn afgesplitst, vast of vloeibaar (m.u.v. sinaasappelolie en citroenolie)',
    33012410: 'pepermuntolie "Mentha piperita", met de terpenen, vast of vloeibaar',
    33012490: 'pepermuntolie "Mentha piperita", waaruit de terpenen zijn afgesplitst, vast of vloeibaar',
    33012510: 'muntolie, met de terpenen, vast of vloeibaar, m.u.v. pepermuntolie "Mentha piperita"',
    33012590: 'muntolie, waaruit de terpenen zijn afgesplitst, vast of vloeibaar, m.u.v. pepermuntolie "Mentha piperita"',
    33012911: 'kruidnagelolie, niaouliolie en ylang-ylangolie, met de terpenen, vast of vloeibaar',
    33012931: 'kruidnagelolie, niaouliolie en ylang-ylangolie, waaruit de terpenen zijn afgesplitst, vast of vloeibaar',
    33012941: 'etherische oliën, terpenen bevattend, vast of vloeibaar (m.u.v. oliën van citrusvruchten, muntolie, kruidnagelolie, niaouliolie en ylang-ylangolie)',
    33012971: 'geraniumolie, jasmijnolie en vetiverolie waaruit de terpenen zijn afgesplitst, vast of vloeibaar',
    33012979: 'lavendelolie en lavandinolie, waaruit de terpenen zijn afgesplitst, vast of vloeibaar',
    33012991: 'oliën, etherisch, waaruit de terpenen zijn afgesplitst, vast of vloeibaar (m.u.v. oliën van citrusvruchten, geraniumolie, jasmijnolie, lavendelolie, lavendinolie, muntolie, vetiverolie, kruidnagelolie, niaouliolie, ylang- ylangolie)',
    33013000: 'harsaroma\'s',
    33019010: 'terpeenhoudende bijproducten, afgesplitst uit etherische oliën',
    33019021: 'door extractie verkregen oleoharsen, van zoethout of van hop',
    33019030: 'door extractie verkregen oleoharsen, van kwassiehout "bitterhout", van aloë, van manna en van andere planten (m.u.v. die van vanille, van zoethout en van hop)',
    33019090: 'geconcentreerde oplossingen van etherische oliën in vet, in vette oliën, in was of in dergelijke stoffen, verkregen door enfleurage of door maceratie; gedistilleerd aromatisch water en waterige oplossingen van etherische oliën',
    33021010: 'bereidingen op basis van reukstoffen, die alle essentiële aromatische stoffen van een bepaalde drank bevatten, met een effectief alcohol-volumegehalte van > 0,5% vol, van de soort gebruikt in de drankenindustrie',
    33021021: 'bereidingen op basis van reukstoffen, die alle essentiële aromatische stoffen van een bepaalde drank bevatten, bevattende geen van melk afkomstige vetstoffen, sacharose, isoglucose, glucose of zetmeel, of bevattende < 1,5 gewichtspercent van melk afkomstige vetstoffen, < 5 gewichtspercenten sacharose of isoglucose of < 5 gewichtspercenten glucose of zetmeel, van de soort gebruikt in de drankenindustrie (m.u.v. die met een effectief alcohol-volumegehalte van > 0,5% vol)',
    33021029: 'bereidingen op basis van reukstoffen, die alle essentiële aromatische stoffen van een bepaalde drank bevatten, bevattende >= 1,5 gewichtspercent van melk afkomstige vetstoffen, >= 5 gewichtspercenten sacharose of isoglucose of >= 5 gewichtspercenten glucose of zetmeel, van de soort gebruikt in de drankenindustrie (m.u.v. die met een effectief alcohol-volumegehalte van > 0,5% vol)',
    33021040: 'mengsels van reukstoffen en mengsels, incl. oplossingen in alcohol, op basis van een of meer van deze zelfstandigheden met andere stoffen, van de soort gebruikt als grondstof voor de drankenindustrie, alsmede andere bereidingen op basis van reukstoffen, van de soort gebruikt in de drankenindustrie (m.u.v. bereidingen die alle essentiële aromatische stoffen van een bepaalde drank bevatten)',
    33021090: 'mengsels van reukstoffen en mengsels, incl. oplossingen in alcohol, op basis van een of meer van deze zelfstandigheden met andere stoffen, van de soort gebruikt als grondstof voor de voedingsmiddelenindustrie',
    33029010: 'mengsels van reukstoffen en mengsels op basis van een of meer van deze zelfstandigheden met andere stoffen, van de soort gebruikt als grondstof voor de industrie, als oplossingen in alcohol (m.u.v. die van de soort gebruikt in de voedingsmiddelen- en drankenindustrie)',
    33029090: 'mengsels van reukstoffen en mengsels op basis van een of meer van deze zelfstandigheden met andere stoffen, van de soort gebruikt als grondstof voor de industrie (m.u.v. die van de soort gebruikt in de voedingsmiddelen- en drankenindustrie en m.u.v. van opl ossingen in alcohol)',
    33030010: 'parfums (m.u.v. scheerlotions "after shave lotions", deodorantia voor lichaamsverzorging en haarlotions)',
    33030090: 'reuk- en toiletwaters (m.u.v. scheerlotions "after shave lotions", deodorantia voor lichaamsverzorging en haarlotions)',
    33041000: 'producten voor het opmaken van de lippen',
    33042000: 'producten voor het opmaken van de ogen',
    33043000: 'producten voor manicure of voor pedicure',
    33049100: 'schoonheidsmiddelen en producten voor de huidverzorging in poedervorm, incl. babypoeder, ook indien geperst (m.u.v. geneesmiddelen)',
    33049900: 'schoonheidsmiddelen en producten voor de huidverzorging, incl. preparaten tegen zonnebrand en preparaten voor het verkrijgen van een bruine huidskleur (m.u.v. geneesmiddelen en m.u.v. producten voor het opmaken van de lippen, producten voor het opmaken van de ogen, producten voor manicure of voor pedicure en m.u.v. poeder, ook indien geperst)',
    33051000: 'shampoo',
    33052000: 'permanent-haargolfpreparaten en preparaten voor het ontkrullen van het haar',
    33053000: 'haarlak',
    33059000: 'Haarverzorgingsmiddelen (m.u.v. shampoo, permanent-haargolfpreparaten, preparaten voor het ontkrullen van het haar en haarlak)',
    33061000: 'tandreinigingsmiddelen, ook die welke door tandartsen worden gebruikt',
    33062000: 'garens gebruikt voor het schoonmaken tussen de tanden "floszijde", opgemaakt voor de verkoop in het klein',
    33069000: 'producten voor mondhygiëne en voor tandverzorging, incl. kleefpoeders en -pasta\'s voor kunstgebitten (m.u.v. tandreinigingsmiddelen en garens gebruikt voor het schoonmaken tussen de tanden "floszijde")',
    33071000: 'scheermiddelen en middelen die voor of na het scheren worden gebruikt',
    33072000: 'deodorantia voor lichaamsverzorging en anti-transpiratiemiddelen',
    33073000: 'badzouten, geparfumeerd en andere badpreparaten',
    33074100: 'agarbatti en andere preparaten die bij verbranding een welriekende geur verspreiden',
    33074900: 'preparaten voor het parfumeren van vertrekken of voor het neutraliseren van geuren in vertrekken, incl. preparaten die bij godsdienstige plechtigheden worden gebruikt (m.u.v. "agarbatti" en andere preparaten die bij verbranding een welriekende geur verspreiden)',
    33079000: 'ontharingsmiddelen en andere parfumerieën, toiletartikelen en andere cosmetische producten, n.e.g.',
    34011100: 'zeep en als zeep te gebruiken organische tensioactieve producten en organische tensioactieve bereidingen, in de vorm van staven, broden, gestempelde stukken of gestempelde fantasievormen, alsmede papier, watten, vilt en gebonden textielvlies, geïmpregneerd of bedekt met zeep of met detergentia, voor toiletdoeleinden, incl. die voor medicinale doeleinden',
    34011900: 'zeep en als zeep te gebruiken organische tensioactieve producten en organische tensioactieve bereidingen, in de vorm van staven, broden, gestempelde stukken of gestempelde fantasievormen, alsmede papier, watten, vilt en gebonden textielvlies, geïmpregneerd of bedekt met zeep of met detergentia (m.u.v. die voor toiletdoeleinden of voor medicinale doeleinden)',
    34012010: 'zeep in de vorm van vlokken, schilfers, korrels of poeder',
    34012090: 'zeep in de vorm van pasta\'s "zachte zeep" of in waterige oplossing "vloeibare zeep"',
    34013000: 'voor het wassen van de huid te gebruiken organische tensioactieve producten en organische tensioactieve bereidingen, in de vorm van een vloeistof of een crème, ook indien zeep bevattend, opgemaakt voor de verkoop in het klein',
    34021110: 'waterige oplossingen bevattende >= 30 doch <= 50 gewichtspercenten dinatriumalkyl[oxidi"benzeensulfonaat"], tensioactief, organisch, ook indien opgemaakt voor de verkoop in het klein, anionisch (m.u.v. zeep)',
    34021190: 'tensioactieve producten, organisch, ook indien opgemaakt voor de verkoop in het klein, anionisch (m.u.v. zeep; waterige oplossingen bevattende >= 30 doch <= 50 gewichtspercenten dinatriumalkyl[oxidi"benzeensulfonaat"])',
    34021200: 'tensioactieve producten, organisch, ook indien opgemaakt voor de verkoop in het klein, cationisch (m.u.v. zeep)',
    34021300: 'tensioactieve producten, organisch, ook indien opgemaakt voor de verkoop in het klein, niet-ionisch (m.u.v. zeep)',
    34021900: 'tensioactieve producten, organisch, ook indien opgemaakt voor de verkoop in het klein (m.u.v. anionische, cationische en niet-ionische producten en m.u.v. zeep)',
    34022020: 'tensioactieve bereidingen opgemaakt voor de verkoop in het klein (m.u.v. organische tensioactieve bereidingen in de vorm van staven, broden, gestempelde stukken of gestempelde fantasievormen, alsmede voor het wassen van de huid te gebruiken organische tensioactieve producten en organische tensioactieve bereidingen, in de vorm van een vloeistof of een crème)',
    34022090: 'wasmiddelen, hulppreparaten voor het wassen en reinigingsmiddelen, ook indien zeep bevattend, opgemaakt voor de verkoop in het klein (m.u.v. organische tensioactieve stoffen, zeep en tensioactieve bereidingen, alsmede voor het wassen van de huid te gebruiken producten en bereidingen in de vorm van een vloeistof of een crème)',
    34029010: 'tensioactieve bereidingen (m.u.v. die opgemaakt voor de verkoop in het klein en m.u.v. organische tensioactieve bereidingen in de vorm van staven, broden, gestempelde stukken of gestempelde fantasievormen, alsmede voor het wassen van de huid te gebruiken producten en bereidingen in de vorm van een vloeistof of een crème)',
    34029090: 'wasmiddelen, hulppreparaten voor het wassen en reinigingsmiddelen, ook indien zeep bevattend (m.u.v. die opgemaakt voor de verkoop in het klein en m.u.v. organische tensioactieve stoffen, zeep en tensioactieve bereidingen in de vorm van staven, broden, gestempelde stukken of gestempelde fantasievormen, alsmede voor het wassen van de huid te gebruiken producten en bereidingen in de vorm van een vloeistof of een crème)',
    34031100: 'preparaten van het soort gebruikt voor het smouten, vetten of oliën van textiel, van leder, van pelterijen of van andere stoffen die aardolie of olie uit bitumineuze mineralen bevatten (m.u.v. preparaten die >= 70 gewichtspercenten van deze olie bevatten en waarvan het karakter door deze olie wordt bepaald)',
    34031910: 'smeermiddelen, incl. boor-, snij- en draaiolie "koelolie", preparaten voor het losmaken van bouten en moeren, en corrosiewerende preparaten en preparaten voor het insmeren van vormen, op basis van smeermiddelen, bevattende >= 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen waarvan het karakter niet door deze olie wordt bepaald (m.u.v. preparaten van het soort gebruikt voor het smouten, vetten of oliën van textiel, van leder, van pelterijen of van andere stoffen)',
    34031920: 'smeermiddelen met een biogebaseerd koolstofgehalte van ten minste 25 massapercenten die biologisch afbreekbaar zijn op een niveau van ten minste 60 gewichtspercenten',
    34031980: 'Smeermiddelen, incl. boor-, snij- en draaiolie "koelolie", preparaten voor het losmaken van bouten en moeren, roest- en corrosiewerende preparaten en preparaten voor het insmeren van vormen, op basis van smeermiddelen, die < 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten (m.u.v. preparaten voor het behandelen van textiel, van leder, van pelterijen of van andere stoffen en smeermiddelen met een biogebaseerd koolstofgehalte van ten minste 25 massapercenten die biologisch afbreekbaar zijn op een niveau van ten minste 60 gewichtspercenten)',
    34039100: 'preparaten van het soort gebruikt voor het smouten, vetten of oliën van textiel, van leder, van pelterijen of van andere stoffen die geen aardolie of olie uit bitumineuze mineralen bevatten',
    34039900: 'Smeermiddelen, incl. boor-, snij- en draaiolie "koelolie", preparaten voor het losmaken van bouten en moeren, roest- en corrosiewerende preparaten en preparaten voor het insmeren van vormen, op basis van smeermiddelen, die geen aardolie of olie uit bitumineuze mineralen bevatten (m.u.v. preparaten voor het behandelen van textiel, van leder, van pelterijen of van andere stoffen)',
    34042000: 'was van poly"oxyethyleen" "polythyleenglycol"',
    34049000: 'Kunstwas en bereide was (m.u.v. was van poly"oxyethyleen" "polythyleenglycol")',
    34051000: 'schoensmeer, pasta\'s e.d. preparaten voor schoeisel of voor leder, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten (m.u.v. kunstwas en bereide was bedoeld bij post 3404)',
    34052000: 'boenwas e.d. preparaten voor het onderhoud van houten meubelen, houten vloeren en ander houtwerk, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten (m.u.v. kunstwas en bereide was bedoeld bij post 3404)',
    34053000: 'poetsmiddelen e.d. preparaten voor carrosserieën, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten (m.u.v. kunstwas en bereide was bedoeld bij post 3404; poetsmiddelen voor metalen)',
    34054000: 'schuurpasta\'s, schuurpoeders en andere schuurmiddelen, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten',
    34059010: 'poetsmiddelen voor metalen, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten',
    34059090: 'poetsmiddelen voor glas, ook indien in de vorm van papier, van watten, van vilt, van gebonden textielvlies, van kunststof of rubber met celstructuur, geïmpregneerd of bedekt met deze preparaten',
    34060000: 'Kaarsen, waspitten e.d. artikelen',
    34070000: 'modelleerpasta\'s, ook indien opgemaakt als kinderspeelgoed; tandtechnische waspreparaten e.d. preparaten, in assortimenten, opgemaakt voor de verkoop in het klein of in plaats-, staaf- of hoefijzervorm of in dergelijke vormen "b.v. bijtplaatjes"; andere preparaten voor tandtechnisch gebruik, op basis van gebrande gips',
    35011010: 'caseïne bestemd voor het vervaardigen van kunstmatige textielvezels',
    35011050: 'caseïne bestemd voor industriële doeleinden (m.u.v. die voor de vervaardiging van producten voor menselijke consumptie of van veevoeder en m.u.v. caseïne bestemd voor het vervaardigen van kunstmatige textielvezels)',
    35011090: 'caseïne bestemd voor de vervaardiging van producten voor menselijke consumptie of van veevoeder en andere caseïne (m.u.v. caseïne bestemd voor het vervaardigen van kunstmatige textielvezels of voor andere industriële doeleinden)',
    35019010: 'lijm van caseïne (m.u.v. deze bestemd voor het verkoop in het klein als lijm met een gewicht van < 1kg)',
    35019090: 'caseïnaten en andere derivaten van caseïne (m.u.v. lijm van caseïne)',
    35021110: 'gedroogd ovoalbumine "in de vorm van bladen, schilfers, kristallen, poeder enz.", ongeschikt of ongeschikt gemaakt voor menselijke consumptie',
    35021190: 'gedroogd ovoalbumine "in de vorm van bladen, schilfers, kristallen, poeder enz.", geschikt voor menselijke consumptie',
    35021910: 'ovoalbumine, ongeschikt of ongeschikt gemaakt voor menselijke consumptie (niet gedroogd "in de vorm van bladen, schilfers, kristallen, poeder enz.")',
    35021990: 'ovoalbumine, geschikt voor menselijke consumptie (niet gedroogd "in de vorm van bladen, schilfers, kristallen, poeder enz.")',
    35022010: 'lactoalbumine, incl. concentraten van twee of meer weiproteïnen, bevattende > 80 gewichtspercenten weiproteïnen, berekend op de droge stof, ongeschikt of ongeschikt gemaakt voor menselijke consumptie',
    35022091: 'lactoalbumine, incl. concentraten van twee of meer weiproteïnen, bevattende > 80 gewichtspercenten weiproteïnen, berekend op de droge stof, geschikt voor menselijke consumptie, gedroogd "in de vorm van bladen, schilfers, kristallen, poeder enz."',
    35022099: 'lactoalbumine, incl. concentraten van twee of meer weiproteïnen, bevattende > 80 gewichtspercenten weiproteïnen, berekend op de droge stof, geschikt voor menselijke consumptie (niet gedroogd "in de vorm van bladen, schilfers, kristallen, poeder enz.")',
    35029020: 'albuminen, ongeschikt of ongeschikt gemaakt voor menselijke consumptie (m.u.v. ovoalbumine, lactoalbumine en concentraten van twee of meer weiproteïnen, bevattende > 80 gewichtspercenten weiproteïnen, berekend op de droge stof)',
    35029070: 'albuminen, geschikt voor menselijke consumptie (m.u.v. ovoalbumine, lactoalbumine en concentraten van twee of meer weiproteïnen, bevattende > 80 gewichtspercenten weiproteïnen, berekend op de droge stof)',
    35029090: 'albuminaten en andere derivaten van albuminen',
    35030010: 'gelatine, incl. gelatine in vierkantige of rechthoekige bladen of vellen, ook indien gekleurd of aan het oppervlak bewerkt, en derivaten daarvan (m.u.v. onzuivere gelatine)',
    35030080: 'isinglass en andere lijm van dierlijke oorsprong (m.u.v. lijm van caseïne bedoeld bij post 3501)',
    35040010: 'Melkproteïneconcentraten met een proteïnegehalte van meer dan 85 gewichtspercenten, berekend op de droge stof',
    35040090: 'Peptonen en derivaten daarvan; andere proteïnestoffen en derivaten daarvan, elders genoemd noch elders onder begrepen; poeder van huiden, ook indien behandeld met chroom (m.u.v. melkproteïneconcentraten met een proteïnegehalte van meer dan 85 gewichtspercenten, berekend op de droge stof)',
    35051010: 'dextrine',
    35051050: 'zetmeel, door ethervorming of door verestering gewijzigd',
    35051090: 'zetmeel, gewijzigd (m.u.v. zetmeel door ethervorming of door verestering gewijzigd)',
    35052010: 'lijm met een gehalte aan zetmeel, aan dextrine of aan ander gewijzigd zetmeel van < 25 gewichtspercenten (m.u.v. die opgemaakt voor de verkoop in het klein met een nettogewicht van <= 1 kg)',
    35052030: 'lijm met een gehalte aan zetmeel, aan dextrine of aan ander gewijzigd zetmeel van >= 25, doch < 55 gewichtspercenten (m.u.v. die opgemaakt voor de verkoop in het klein met een nettogewicht van <= 1 kg)',
    35052050: 'lijm met een gehalte aan zetmeel, aan dextrine of aan ander gewijzigd zetmeel van >= 55, doch < 80 gewichtspercenten (m.u.v. die opgemaakt voor de verkoop in het klein met een nettogewicht van <= 1 kg)',
    35052090: 'lijm met een gehalte aan zetmeel, aan dextrine of aan ander gewijzigd zetmeel van >= 80 gewichtspercenten (m.u.v. die opgemaakt voor de verkoop in het klein met een nettogewicht van <= 1 kg)',
    35061000: 'als lijm of als kleefmiddel te gebruiken producten, opgemaakt voor de verkoop in het klein als lijm of als kleefmiddel, in een opmaak met een nettogewicht van <= 1 kg',
    35069110: 'Transparante kleeffolies en vloeibare transparante uithardende kleefmiddelen van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van platte beeldschermen of aanraakschermen, op basis van polymeren van de posten 3901 tot en met 3913 en rubber',
    35069190: 'kleefmiddel op basis van polymeren van de posten 3901 tot en met 3913 of van rubber (m.u.v. kleefmiddel opgemaakt voor de verkoop in het klein, in een opmaak met een nettogewicht van <= 1 kg, en van kleefmiddel van de soort  uitsluitend of hoofdzakelijk gebruikt voor de vervaardiging van platte beeldschermen of aanraakschermen)',
    35069900: 'lijm en andere bereide kleefmiddelen, n.e.g.',
    35071000: 'leb en concentraten daarvan',
    35079030: 'Lipoproteïnelipase en aspergillus-alkaline protease',
    35079090: 'enzymen en bereidingen van enzymen, n.e.g. (m.u.v. leb en concentraten daarvan; lipoproteïnelipase; aspergillus-alkaline protease)',
    36010000: 'buskruit',
    36020000: 'springstoffen, bereid (m.u.v. buskruit)',
    36030020: 'Lonten',
    36030030: 'Slagkoorden',
    36030040: 'Percussiedopjes (m.u.v. buizen voor patroonhulzen met slaghoedjes)',
    36030050: 'Slaghoedjes',
    36030060: 'Ontstekers',
    36030080: 'Elektrische ontstekingspatronen (m.u.v. ontstekers voor granaten)',
    36041000: 'vuurwerk',
    36049000: 'lichtkogels en vuurpijlen, anti-hagelraketten e.d., voetzoekers, knalsignalen en andere pyrotechnische artikelen (m.u.v. vuurwerk en losse patronen)',
    36050000: 'lucifers (m.u.v. pyrotechnische artikelen bedoeld bij post 3604)',
    36061000: 'brandstof, vloeibaar en vloeibaar gemaakt brandbaar gas, in recipiënten van het soort gemaakt voor het vullen van aanstekers en met een inhoudsruimte van <= 300 cm 3',
    36069010: 'ferrocerium en andere vonkende legeringen, ongeacht de vorm;',
    36069090: 'metaldehyd, hexamethyleentetramine e.d. producten, in tabletten, in staafjes of in dergelijke vormen, die wijzen op het gebruik ervan als brandstof; alcoholhoudende brandstoffen e.d. bereide brandstoffen, in vaste vorm of in pasta; harstoortsen en harsfakkels, vuurmakers e.d.',
    37011000: 'Platen en vlakfilm, lichtgevoelig, onbelicht, voor röntgenopnamen (m.u.v. die van papier, van karton of van textiel)',
    37012000: 'vlakfilm voor "direct-klaar"-fotografie, lichtgevoelig, onbelicht, ook indien in cassette',
    37013000: 'fotografische platen en vlakfilm, lichtgevoelig, onbelicht, waarvan de afmeting van een der zijden > 250 mm is',
    37019100: 'fotografische platen en vlakfilm, lichtgevoelig, onbelicht, voor kleurenfotografie "polychroom", van alle stoffen (m.u.v. die van papier, karton of textiel en m.u.v. platen en vlakfilm waarvan de afmeting van een der zijden > 250 mm is en m.u.v. vlakfilm voor "direct-klaar"-fotografie)',
    37019900: 'fotografische platen en vlakfilm, lichtgevoelig, onbelicht, voor monochrome opnamen van alle stoffen (m.u.v. die van papier, karton of textiel, m.u.v. die voor röntgenopnamen en m.u.v. platen en vlakfilm waarvan de afmeting van een der zijden > 255 mm is en m.u.v. vlakfilm voor "direct-klaar"-fotografie)',
    37021000: 'film, lichtgevoelig, onbelicht, op rollen, voor röntgenopnamen (m.u.v. die van papier, van karton of van textiel)',
    37023191: 'negatieve kleurenfilms met een breedte van >=75 doch <= 105 mm en een lengte van >= 100 m, bestemd om te worden gebruikt bij de vervaardiging van filmcassettes voor "direct-klaar"- fotografie, lichtgevoelig, onbelicht, ongeperforeerd, op rollen (m.u.v. die van papier, karton of textiel)',
    37023197: 'fotografische film incl. film voor "direct-klaar"-fotografie, op rollen, lichtgevoelig, onbelicht, ongeperforeerd, op rollen, met een breedte van <= 105 mm, voor kleurenfotografie "polychroom", (m.u.v. die van papier, karton of textiel; negatieve kleurenfilms met een breedte van >=75 doch <= 105 mm en een lengte van >= 100 m, bestemd om te worden gebruikt bij de vervaardiging van filmcassettes voor "direct-klaar"- fotografie)',
    37023210: 'microfilm en film voor grafische doeleinden, incl. film voor "direct-klaar"-fotografie, lichtgevoelig, onbelicht, op rollen, ongeperforeerd met een breedte van <= 35 mm en een zilverhalogenide emulsie voor monochrome opnamen (m.u.v. die van papier, karton of textiel)',
    37023220: 'fotografische film, incl. film voor "direct-klaar"-fotografie, op rollen, lichtgevoelig, onbelicht, ongeperforeerd, met een breedte van <= 35 mm en een zilverhalogenide emulsie voor monochrome opnamen (m.u.v. die van papier, karton of textiel en m.u.v. film voor röntgenopnamen, microfilm en film voor grafische doeleinden)',
    37023285: 'Fotografische film, incl. film voor "direct-klaar"-fotografie, op rollen, lichtgevoelig, onbelicht,  met een breedte van > 35 doch <= 105 mm, ongeperforeerd, met zilverhalogenide-emulsie, voor monochrome opnamen (m.u.v. die van papier, karton of textiel en m.u.v. film voor röntgenopnamen)',
    37023900: 'fotografische film, incl. film voor "direct-klaar"-fotografie, op rollen, lichtgevoelig, onbelicht, ongeperforeerd, met een breedte van <= 105 mm voor monochrome opnamen (m.u.v. die met een zilverhalogenide emulsie en m.u.v. die van papier, karton of textiel en film voor röntgenopnamen)',
    37024100: 'fotografische film, incl. film voor "direct-klaar"-fotografie,  op rollen, lichtgevoelig, onbelicht, ongeperforeerd, met een breedte van > 610 mm en een lengte van > 200 m, voor kleurenfotografie "polychroom" (m.u.v. die van papier, karton of textiel)',
    37024200: 'fotografische film, incl. film voor "direct-klaar"-fotografie,  op rollen, lichtgevoelig, onbelicht, ongeperforeerd, met een breedte van > 610 mm en een lengte van > 200 m, voor monochrome opname (m.u.v. die van papier, karton of textiel)',
    37024300: 'fotografische film, incl. film voor "direct-klaar"-fotografie,  op rollen, lichtgevoelig, onbelicht,  ongeperforeerd, met een breedte van > 610 mm en een lengte van <= 200 m (m.u.v. die van papier, karton of textiel)',
    37024400: 'fotografische film, incl. film voor "direct-klaar"-fotografie,  op rollen, lichtgevoelig, onbelicht,  ongeperforeerd, met een breedte van > 105 doch <= 610 mm (m.u.v. die van papier, karton of textiel)',
    37025200: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor kleurenfotografie "polychroom", met een breedte van <= 16 mm (m.u.v. die van papier, van karton of van textiel)',
    37025300: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor kleurenfotografie "polychroom", met een breedte van > 16, doch <= 35 mm en een lengte van <= 30 m, voor diapositieven',
    37025400: 'Fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor kleurenfotografie "polychroom", met een breedte van > 16, doch <= 35 mm en een lengte van <= 30 m (m.u.v. die van papier, van karton of van textiel,  en die voor diapositieven)',
    37025500: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor kleurenfotografie "polychroom", met een breedte van > 16, doch <= 35 mm en een lengte van > 30 m (m.u.v. die van papier, van karton of van textiel; die voor diapositieven)',
    37025600: 'fotografische film, lichtgevoelig, onbelicht, op rollen, ongeperforeerd, voor kleurenfotografie "polychroom", met een breedte van > 35 mm (m.u.v. die van papier, karton of textiel)',
    37029610: 'microfilm en film voor grafische doeleinden, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor monochrome opname, met een breedte van <= 35 mm en een lengte van <= 30 m',
    37029690: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor monochrome opname, met een breedte van <= 35 mm en een lengte van <= 30 m (m.u.v. die van papier, van karton of van textiel; film voor röntgenopnamen, film voor "direct-klaar"-fotografie en film voor grafische doeleinden)',
    37029710: 'microfilm en film voor grafische doeleinden, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor monochrome opname, met een breedte van <= 35 mm en een lengte van > 30 m (m.u.v. die van papier, van karton of van textiel)',
    37029790: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor monochrome opname, met een breedte van <= 35 mm en een lengte van > 30 m (m.u.v. die van papier, van karton of van textiel; microfilm; film voor grafische doeleinden; film voor "direct-klaar"-fotografie en film voor röntgenopnamen)',
    37029800: 'fotografische film, lichtgevoelig, onbelicht, op rollen, geperforeerd, voor monochrome opname, met een breedte van > 35 mm (m.u.v. die van papier, karton of textiel en m.u.v. film voor röntgenopnamen)',
    37031000: 'papier, karton en textiel, fotografisch, lichtgevoelig, onbelicht, op rollen, met een breedte van > 610 mm',
    37032000: 'Fotografisch papier, karton en textiel, lichtgevoelig, onbelicht, voor kleurenfotografie "polychroom" (m.u.v. die op rollen, met een breedte van > 610 mm)',
    37039000: 'Fotografisch papier, karton en textiel, lichtgevoelig, onbelicht, voor monochrome opname (m.u.v. die op rollen, met een breedte van > 610 mm)',
    37040010: 'Platen en film, belicht doch onontwikkeld (m.u.v. die van papier, karton of textiel)',
    37040090: 'papier, karton en textiel, fotografisch belicht doch onontwikkeld',
    37050010: 'Fotografische platen en film, belicht en ontwikkeld, voor offsetreproductie',
    37050090: 'Fotografische platen en film, belicht en ontwikkeld (m.u.v. die van papier, karton en textiel, en m.u.v. die voor offsetreproductie en cinematografische film en direct-klaar drukplaten)',
    37061020: 'cinematografische film, belicht en ontwikkeld, waarop uitsluitend geluid is vastgelegd; negatieve; positieve werkkopiën voor laboratoria (duplicaatpositieven of masterprints), met een breedte van >= 35 mm',
    37061099: 'positieve cinematografische films, belicht en ontwikkeld, ook indien daarop geluid is vastgelegd, met een breedte van >= 35 mm (m.u.v. positieve werkkopieën)',
    37069052: 'cinematografische film, belicht en ontwikkeld, waarop uitsluitend geluid is vastgelegd; negatieve; positieve werkkopieën voor filmlaboratoria (duplicaatpositieven of masterprints); filmjournaals, met een breedte van < 35 mm',
    37069091: 'positieven van cinematografische films, belicht en ontwikkeld, ook indien daarop geluid is vastgelegd, met een breedte van < 10 mm (m.u.v. positieve werkkopieën en filmjournaals)',
    37069099: 'positieven van cinematografische films, belicht en ontwikkeld, ook indien daarop geluid is vastgelegd, met een breedte van >= 10, doch < 35 mm (m.u.v. positieve werkkopieën en filmjournaals)',
    37071000: 'emulsies, voor fotografisch gebruik, voor het gevoelig maken van oppervlakken',
    37079020: 'Ontwikkel- en fixeermiddelen in de vorm van chemische preparaten voor fotografisch gebruik, incl. ongemengde producten die hetzij voorkomen in afgemeten hoeveelheden, hetzij gebruiksklaar zijn opgemaakt voor de verkoop in het klein (m.u.v. zouten en verbindingen bedoeld bij de posten 2843 tot en met 2846)',
    37079090: 'chemische preparaten voor fotografisch gebruik, incl. ongemengde producten die met het oog op fotografisch gebruik hetzij voorkomen in afgemeten hoeveelheden, hetzij gebruiksklaar zijn opgemaakt voor de verkoop in het klein (m.u.v. vernis, lijm e.d. preparaten, emulsies voor het gevoelig maken van oppervlakken, ontwikkel- en fixeermiddelen en zouten en verbindingen van edele metalen, enz., bedoeld bij de posten 2843 t/m 2846)',
    38011000: 'grafiet, kunstmatig (m.u.v. retortengrafiet of retortenkool en producten van kunstmatig grafiet, incl. vuurvaste producten op basis van kunstmatig grafiet)',
    38012010: 'grafiet, colloïdaal, gedispergeerd in olie; semi-colloïdaal grafiet',
    38012090: 'grafiet, colloïdaal (m.u.v. grafiet gedispergeerd in olie en semi-colloïdaal grafiet)',
    38013000: 'koolstofpasta\'s voor elektroden e.d. pasta\'s voor de inwendige bekleding van ovens',
    38019000: 'preparaten op basis van grafiet of van andere koolstof, in de vorm van pasta\'s, van blokken, van platen of van andere halffabrikaten (m.u.v. koolstofpasta\'s voor elektroden e.d. pasta\'s voor de inwendige bekleding van ovens)',
    38021000: 'actieve kool (m.u.v. geneesmiddelen en actieve kool opgemaakt voor de verkoop in het klein voor gebruik als deodoriseringsmiddel voor koelkasten, voor voertuigen, enz.)',
    38029000: 'kiezelgoer, geactiveerd, en andere geactiveerde minerale producten, alsmede dierlijk zwartsel, incl. afgewerkt dierlijk zwartsel (m.u.v. actieve kool, gecalcineerde diatomeeënaarde zonder toevoeging van sinteringsmiddelen en m.u.v. geactiveerde chemische producten)',
    38030010: 'tallolie, ruw',
    38030090: 'Tallolie, ook indien geraffineerd (m.u.v. ruwe tallolie)',
    38040000: 'Residulogen, ontstaan bij de vervaardiging van houtcellulose, ook indien geconcentreerd, ontsuikerd of chemisch behandeld, ligninesulfonaten daaronder begrepen (m.u.v. tallolie, bijtende soda en talloliepek)',
    38051010: 'terpentijnolie',
    38051030: 'houtterpentijnolie',
    38051090: 'sulfaatterpentijnolie',
    38059010: 'voornamelijk uit alfa-terpineol bestaande pijnolie',
    38059090: 'Dipenteen, ruw; sulfietterpentijnolie en ander ruw paracymeen; terpeenhoudende olie, verkregen door distillatie of door andere behandelingen van naaldhout (m.u.v. terpentijnolie, houtterpentijnolie en sulfaatterpentijnolie en m.u.v. voornamelijk uit alfa-terpineol bestaande pijnolie)',
    38061000: 'Colofonium en harszuren',
    38062000: 'zouten van colofonium, van harszuren of van derivaten van colofonium of van harszuren (m.u.v. zouten van colofoniumadditieproducten)',
    38063000: 'gomesters',
    38069000: 'derivaten van colofonium, incl. zouten van colofoniumadditieproducten, en derivaten van harszuren; essence en olie van colofonium; gesmolten harsen (m.u.v. gomesters en zouten van colofonium of van harszuren of van derivaten daarvan)',
    38070010: 'houtteer',
    38070090: 'brouwerspek e.d. preparaten op basis van colofonium, van harszuren of van plantaardig pek; houtteerolie, creosootolie van hout; ruwe houtgeest; plantaardig pek (m.u.v. houtteer "bois de bourgogne", "bois des vosges", "bois jaune", stearinepek, vetpek, vetteer en glycerinepek)',
    38085200: 'DDT "ISO" clofenotaan "INN", in verpakkingen inhoudende een netto-gewicht van <= 300 g',
    38085900: 'Goederen bedoeld bij aanvullende aantekening 1 op dit hoofdstuk bevattende een of meer van de volgende stoffen: alachloor(ISO); aldicarb (ISO); aldrin (ISO); azinfos-methyl (ISO); binapacryl (ISO); camfechloor (ISO) (toxafeen); captafol (ISO); chloordaan (ISO); chloordimeform (ISO); chloorbenzilaat (ISO); dieldrine (ISO, INN); 4,6-dinitro-o-kresol (DNOC (ISO)) or zouten daarvan; dinoseb (ISO), alsmede zouten en esters daarvan; endosulfan (ISO); ethyleendibromide (ISO) (1,2-dibromoethaan); ethyleendichloride (ISO) (1,2-dichloorethaan); fluoracetamide (ISO); heptachloor (ISO); hexachloorbenzeen (ISO); 1,2,3,4,5,6-hexachloorcyclohexaan (HCH (ISO)), lindaan daaronder begrepen (ISO, INN); kwikverbindingen; methamidofos (ISO); monocrotofos (ISO); oxiraan (ethyleenoxide); parathion (ISO); parathion-methyl (ISO) (methyl-parathion); penta-and-octabroomdifenyl ethers; pentachloorfenol (ISO), alsmede zouten en esters daarvan; perfluoroctaan sulfonzuur en zouten daarvan; perfluoroctaan sulfonamides; perfluoroctaan sulfonyl fluoride; fosfamidon (ISO); 2,4,5-T (ISO) (2,4,5-trichloorfenoxyazijnzuur), alsmede zouten en esters daarvan; tributyltinverbindingen. Alsook stuifpoederbereidingen bevattende een mengsel van benomyl (ISO), carbofuran (ISO) and thiram (ISO).',
    38086100: 'Goederen bedoeld bij aanvullende aantekening 1 op dit hoofdstuk bevattende  alfa-cypermethrin (ISO), bendiocarb (ISO), bifenthrin (ISO), chloorfenapyr (ISO), cyfluthrin (ISO), deltamethrin (INN, ISO), etofenprox (INN), fenitrothion (ISO), lambda-cyhalothrin (ISO), malathion (ISO), pirimifos-methyl (ISO) of propoxur (ISO), in verpakkingen met een nettogewicht van <= dan 300 g',
    38086200: 'Goederen bedoeld bij aanvullende aantekening 1 op dit hoofdstuk bevattende alfa-cypermethrin (ISO), bendiocarb (ISO), bifenthrin (ISO), chloorfenapyr (ISO), cyfluthrin (ISO), deltamethrin (INN, ISO), etofenprox (INN), fenitrothion (ISO), lambda-cyhalothrin (ISO), malathion (ISO), pirimifos-methyl (ISO) of propoxur (ISO), in verpakkingen met een nettogewicht van meer dan 300 g, maar niet meer dan 7,5 kg',
    38086900: 'Goederen bedoeld bij aanvullende aantekening 1 op dit hoofdstuk bevattende alfa-cypermethrin (ISO), bendiocarb (ISO), bifenthrin (ISO), chloorfenapyr (ISO), cyfluthrin (ISO), deltamethrin (INN, ISO), etofenprox (INN), fenitrothion (ISO), lambda-cyhalothrin (ISO), malathion (ISO), pirimifos-methyl (ISO) of propoxur (ISO), (m.u.v. die in verpakkingen van niet meer dan 7,5 kg)',
    38089110: 'Insectendodende middelen op basis van pyretroïden, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdelingen 3808.52 t.e.m. 3808.69)',
    38089120: 'Insectendodende middelen op basis van gechloreerde koolwaterstoffen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.52 t.e.m. 3808.69)',
    38089130: 'Insectendodende middelen op basis van carbamaten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.52 t.e.m. 3808.69)',
    38089140: 'Insectendodende middelen op basis van organische fosforverbindingen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.52 t.e.m. 3808.69)',
    38089190: 'Insectendodende middelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. van die op basis van pyretroïden, gechloreerde koolwaterstoffen, carbamaten of organische fosforverbindingen en m.u.v. goederen van onderverdeling 3808.52 t.e.m. 3808.69)',
    38089210: 'Schimmelwerende middelen in de vorm van preparaten op basis van koperverbindingen, anorganisch (m.u.v. goederen van onderverdeling 3808.59)',
    38089220: 'Schimmelwerende middelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen, anorganisch (m.u.v. preparaten op basis van koperverbindingen en m.u.v. goederen van onderverdeling 3808.59)',
    38089230: 'Schimmelwerende middelen op basis van dithiocarbamaten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. anorganische middelen en m.u.v. goederen van onderverdeling 3808.59)',
    38089240: 'Schimmelwerende middelen op basis van benzimidazolen opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. anorganische middelen en m.u.v. goederen van onderverdeling 3808.59)',
    38089250: 'Schimmelwerende middelen op basis van diazolen of triazolen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. anorganische middelen en m.u.v. goederen van onderverdeling 3808.59)',
    38089260: 'Schimmelwerende middelen op basis van diazinen of morfolinen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. anorganische middelen en goederen van onderverdeling 3808.59)',
    38089290: 'Schimmelwerende middelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. die op basis van dithiocarbamaten, benzimidazolen, diazolen, triazolen, diazinen of morfolinen en m.u.v. anorganische middelen en goederen van onderverdeling 3808.59)',
    38089311: 'Onkruidbestrijdingsmiddelen op basis van fenoxyfytohormonen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089313: 'Onkruidbestrijdingsmiddelen op basis van triazinen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089315: 'Onkruidbestrijdingsmiddelen op basis van amiden, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089317: 'Onkruidbestrijdingsmiddelen op basis van carbamaten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089321: 'Onkruidbestrijdingsmiddelen op basis van dinitroanilinederivaten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089323: 'Onkruidbestrijdingsmiddelen op basis van ureum-, uracil- of sulfonylureumderivaten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen  (m.u.v. goederen van onderverdeling 3808.59)',
    38089327: 'Onkruidbestrijdingsmiddelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. die op basis van fenoxyfytohormonen, triazinen, amiden, carbamaten, dinitroanilinederivaten of ureum-, uracil- of sulfonylureumderivaten en m.u.v. goederen van onderverdeling 3808.59)',
    38089330: 'Middelen om het kiemen tegen te gaan, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089390: 'Middelen om de plantengroei te regelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089410: 'Desinfecteermiddelen op basis van quaternaire ammoniumzouten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089420: 'Desinfecteermiddelen op basis van halogeenverbindingen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089490: 'Desinfecteermiddelen, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. die op basis van quaternaire ammoniumzouten of halogeenverbindingen en m.u.v. goederen van onderverdeling 3808.59)',
    38089910: 'Knaagdierenbestrijdingsmiddelen opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. goederen van onderverdeling 3808.59)',
    38089990: 'Andere middelen ter bescherming tegen plantenziekten, opgemaakt in vormen of verpakkingen voor de verkoop in het klein, dan wel voorkomend als bereidingen of in de vorm van artikelen (m.u.v. insectendodende, schimmelwerende, onkruidbestrijdings-, desinfecteer- en knaagdierenbestrijdingsmiddelen en m.u.v. goederen van onderverdeling 3808.59)',
    38091010: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de textielindustrie, de papierindustrie, de lederindustrie of dergelijke industrieën, n.e.g. op basis van zetmeel of van zetmeelhouden stoffen, met een gehalte aan deze stoffen van < 55 gewichtspercenten',
    38091030: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de textielindustrie, de papierindustrie, de lederindustrie of dergelijke industrieën, n.e.g. op basis van zetmeel of van zetmeelhouden stoffen, met een gehalte aan deze stoffen van >= 55, doch < 70 gewichtspercenten',
    38091050: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de textielindustrie, de papierindustrie, de lederindustrie of dergelijke industrieën, n.e.g. op basis van zetmeel of van zetmeelhouden stoffen, met een gehalte aan deze stoffen van >= 70, doch < 83 gewichtspercenten',
    38091090: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de textielindustrie, de papierindustrie, de lederindustrie of dergelijke industrieën, n.e.g. op basis van zetmeel of van zetmeelhouden stoffen, met een gehalte aan deze stoffen van >= 83 gewichtspercenten',
    38099100: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de textielindustrie of in dergelijke industrieën, n.e.g. (m.u.v. die op basis van zetmeel of van zetmeelhoudende stoffen)',
    38099200: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de papierindustrie of in dergelijke industrieën, n.e.g. (m.u.v. die op basis van zetmeel of van zetmeelhoudende stoffen)',
    38099300: 'appreteermiddelen, middelen voor het versnellen van het verfproces of van het fixeren van kleurstoffen, alsmede andere producten en preparaten "b.v. preparaten voor het beitsen", van de soort gebruikt in de lederindustrie of in dergelijke industrieën, n.e.g. (m.u.v. die op basis van zetmeel of van zetmeelhoudende stoffen)',
    38101000: 'preparaten voor het beitsen van metalen; soldeer- en laspoeder en soldeer- en laspasta\'s, samengesteld uit metaal en andere stoffen',
    38109010: 'preparaten voor het bekleden of het vullen van elektroden en van soldeer- en lasstaafjes',
    38109090: 'vloeimiddelen en andere hulpmiddelen voor het solderen en het lassen van metalen (m.u.v. preparaten van de soort gebruikt voor het bekleden of het vullen van elektroden en van soldeer- en lasstaafjes, soldeer- en laspoeder en -pasta\'s, samengesteld uit metaal en andere stoffen, en met vloeimiddelen beklede elektroden en soldeer- en lasstaafjes van onedele metalen of van metaalcarbiden)',
    38111110: 'antiklopmiddelen voor benzine op basis van tetraëthyllood',
    38111190: 'antiklopmiddelen voor benzine op basis van loodverbindingen (m.u.v. tetraëthyllood)',
    38111900: 'antiklopmiddelen voor benzine (m.u.v. die op basis van loodverbindingen)',
    38112100: 'additieven voor smeerolie, bevattende aardolie of olie uit bitumineuze mineralen',
    38112900: 'additieven voor smeerolie, zonder aardolie of olie uit bitumineuze mineralen',
    38119000: 'dopes "antiklopmiddelen, oxidatievertragers, peptisatiemiddelen, middelen ter verbetering van de viscositeit, corrosievertragers e.d. preparaten", voor minerale olie, "incl. benzine", of voor andere vloeistoffen die voor dezelfde doeleinden worden gebruikt als minerale olie (m.u.v. antiklopmiddelen en additieven voor smeerolie)',
    38121000: 'rubbervulkanisatieversnellers, bereid',
    38122010: 'weekmakers van gemengde samenstelling voor rubber of voor kunststof in de vorm van reactiemengsels, bevattende benzyl-3-isobutyryloxy-1-isopropyl-2,2-dimethylpropylftalaat en benzyl-3-isobutyryloxy-2,2,4-tri-methylpentylftalaat',
    38122090: 'weekmakers van gemengde samenstelling voor rubber of voor kunststof, n.e.g. (m.u.v. reactiemengsels, bevattende benzyl-3-isobutyryloxy-1-isopropyl-2,2-dimethylpropylftalaat en benzyl-3-isobutyryloxy-2,2,4-tri-methylpentylftalaat)',
    38123100: 'Mengsels van oligomeren van 2,2,4-trimethyl-1,2-dihydroquinoline (TMQ)',
    38123910: 'Bereide antioxidanten voor rubber of voor kunststof ((m.u.v. mengsels van oligomeren van 2,2,4-trimethyl-1,2-dihydroquinoline (TMQ))',
    38123990: 'Stabilisatiemiddelen van gemengde samenstelling, voor rubber of voor kunststof (m.u.v. antioxidanten)',
    38130000: 'preparaten en ladingen, voor brandblusapparaten, brandblusbommen (m.u.v. al dan niet gevulde brandblusapparaten, ook draagbare, en m.u.v. onvermengde chemisch welbepaalde producten met brandblussende eigenschappen, in een andere opmaak)',
    38140010: 'oplosmiddelen en verdunners, organisch, van gemengde samenstelling en preparaten voor het verwijderen van verf en vernis, op basis van butylaccetaat (m.u.v. oplosmiddelen om nagellak te verwijderen)',
    38140090: 'oplosmiddelen en verdunners, organisch, van gemengde samenstelling en preparaten voor het verwijderen van verf en vernis (m.u.v. die op basis van butylaccetaat en m.u.v. oplosmiddelen om nagellak te verwijderen)',
    38151100: 'katalysatoren op een drager, met nikkel of nikkelverbindingen als actieve stof, n.e.g.',
    38151200: 'katalysatoren op een drager, met een edel metaal of met een verbinding van een edel metaal als actieve stof, n.e.g.',
    38151910: 'katalysatoren, met een schijnbare dichtheid van >= 0,2 doch <= 1,0, in de vorm van korrels, waarvan de afmetingen voor >= 90 gewichtspercenten niet meer bedragen dan 10 micrometer, bestaande uit een mengsel van oxiden gefixeerd op een drager van magnesiumsilicaat, met een gehalte aan koper van >= 20 doch <= 35 gewichtspercenten en aan bismut van >= 2 doch <= 3 gewichtspercenten',
    38151990: 'katalysatoren op een drager, n.e.g. (m.u.v. die met een edel metaal of met een verbinding van een edel metaal of met nikkel of nikkelverbindingen als actieve stof; katalysatoren, met een schijnbare dichtheid van >= 0,2 doch <= 1,0, in de vorm van korrels, waarvan de afmetingen voor >= 90 gewichtspercenten niet meer bedragen dan 10 micrometer, bestaande uit een mengsel van oxiden gefixeerd op een drager van magnesiumsilicaat, met een gehalte aan koper van >= 20 doch <= 35 gewichtspercenten en aan bismut van >= 2 doch <= 3 gewichtspercenten)',
    38159010: 'katalysatoren, bestaande uit ethyltrifenylfosfoniumacetaat opgelost in methanol (m.u.v. katalysatoren op een drager)',
    38159090: 'reactie-initiatoren, reactieversnellers en katalytische preparaten, n.e.g. (m.u.v. rubbervulkanisatieversnellers; katalysatoren op een drager; katalysatoren, bestaande uit ethyltrifenylfosfoniumacetaat opgelost in methanol)',
    38160000: 'cement, vuurvaste, vuurvaste mortel, vuurvast beton e.d. vuurvaste preparaten (m.u.v. preparaten op basis van grafiet of van andere koolstof)',
    38170050: 'lineair alkylbenzeen',
    38170080: 'alkylbenzenen en alkylnaftalenen, van gemengde samenstelling, vervaardigd door het alkyleren van benzeen en naftaleen (m.u.v. lineair alkylbenzeen en mengsels van isomeren van cyclische koolwaterstoffen)',
    38180010: 'silicium, gedoopt met het oog op hun gebruik voor elektronische doeleinden, in de vorm van schijven, plaatjes of dergelijke vormen, ook indien gepolijst en ook indien bedekt met een gelijkvormige epitaxiale laag (m.u.v. verder verwerkt silicium, b.v. door selectieve diffusie)',
    38180090: 'elementen, chemisch, en chemische verbindingen, gedoopt met het oog op hun gebruik voor elektronische doeleinden, in de vorm van schijven, plaatjes of dergelijke vormen, resp. in de vorm van cilinders, staven, enz. of in schijven, plaatjes of dergelijke vormen gesneden, ook indien gepolijst en ook indien bedekt met een gelijkvormige epitaxiale laag (m.u.v. verder verwerkte producten, b.v. door selectieve diffusie en m.u.v. gedoopt silicium)',
    38190000: 'remvloeistoffen en andere vloeibare preparaten voor hydraulische krachtoverbrenging, die geen of < 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevatten',
    38200000: 'antivriespreparaten en vloeibare ontdooiingspreparaten (m.u.v. additieven voor minerale olie of voor andere vloeistoffen die voor dezelfde doeleinden worden gebruikt als minerale olie)',
    38210000: 'voedingsbodems, bereid voor het cultiveren on in stand houden van micro-organismen (incl. virussen en dergelijke organismen) of van planten, menselijke of dierlijke cellen',
    38220000: 'Reageermiddelen voor diagnose of voor laboratoriumgebruik, op een drager, alsmede bereide reageermiddelen voor diagnose of voor laboratoriumgebruik, ook indien op een drager; gecertificeerde referentiematerialen (m.u.v. reageermiddelen voor diagnose van gemengde samenstelling die bestemd zijn om aan patiënten te worden toegediend, reagentia voor het bepalen van bloedgroepen of van bloedfactoren en dierlijk bloed voor diagnostische doeleinden en m.u.v. vaccins, toxinen, culturen van micro-organismen en dergelijke producten)',
    38231100: 'industrieel stearinezuur',
    38231200: 'industrieel oliezuur',
    38231300: 'industriële tallvetzuren',
    38231910: 'gedistilleerde vetzuren, industrieel, eenwaardig',
    38231930: 'vetzuurdistillaat, industrieel, eenwaardig',
    38231990: 'vetzuren, industrieel, eenwaardig; bij raffinage verkregen acid-oils (m.u.v. stearinezuur, oliezuur, tallvetzuren, gedistilleerde vetzuren, vetzuurdistillaat)',
    38237000: 'industriële vetalcoholen',
    38241000: 'bereide bindmiddelen voor gietvormen of voor gietkernen',
    38243000: 'niet-gesinterde metaalcarbiden, onderling vermengd of vermengd met bindmiddelen van metaal',
    38244000: 'bereide toevoegingsmiddelen voor cement, voor mortel of voor beton',
    38245010: 'stortklare beton',
    38245090: 'mortel en beton (m.u.v. vuurvast beton en stortklaar beton)',
    38246011: 'sorbitol, met een gehalte aan D-mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte, in waterige oplossing (m.u.v. D-glucitol "sorbitol")',
    38246019: 'sorbitol, met een gehalte aan D-mannitol van > 2 gewichtspercenten, berekend op het D-glucitolgehalte, in waterige oplossing (m.u.v. D-glucitol "sorbitol")',
    38246091: 'sorbitol, met een gehalte aan D-mannitol van <= 2 gewichtspercenten, berekend op het D-glucitolgehalte (m.u.v. die in waterige oplossing; D-glucitol "sorbitol")',
    38246099: 'sorbitol, met een gehalte aan D-mannitol van > 2 gewichtspercenten, berekend op het D-glucitolgehalte (m.u.v. die in waterige oplossing; D-glucitol "sorbitol")',
    38247100: 'mengsels bevattende chloorfluorkoolstoffen (CFK\'s), al dan niet bevattende chloorfluorkoolwaterstoffen (HCFK\'s), perfluorkoolstoffen (PFK\'s) of fluorkoolwaterstoffen (HFK\'s)',
    38247200: 'mengsels bevattende broomchloordifluoromethaan, broomtrifluormethaan of dibroomtetrafluorethanen',
    38247300: 'mengsels bevattende broomfluorkoolwaterstoffen (HBFKs)',
    38247400: 'mengsels bevattende chloorfluorkoolwaterstoffen (HCFK\'s), al dan niet bevattende perfluorkoolstoffen (PFK\'s) of fluorkoolwaterstoffen (HFK\'s) doch geen chloorfluorkoolstoffen (CFK\'s) bevattend',
    38247500: 'mengsels bevattende tetrachloorkoolstof',
    38247600: 'mengsels bevattende 1,1,1-trichloorethaan (methylchloroform)',
    38247700: 'mengsels bevattende broommethaan (methylbromide) of broomchloormethaan',
    38247810: 'mengels bevattende alleen 1,1,1-trifluorethaan en pentafluorethaan',
    38247820: 'mengels bevattende alleen 1,1,1-trifluorethaan, pentafluorethaan en 1,1,1,2-tetrafluorethaan',
    38247830: 'mengels bevattende alleen difluormethaan en pentafluorethaan',
    38247840: 'mengels bevattende alleen difluormethaan, pentafluorethaan en 1,1,1,2-tetrafluorethaan',
    38247880: 'mengels bevattende onverzadigde fluorkoolwaterstoffen, doch geen chloorfluorkoolstoffen (CFK\'s) of chloorfluorkoolwaterstoffen (HCFK\'s) bevattend',
    38247890: 'mengsels bevattende perfluorkoolstoffen (PFK\'s) of fluorkoolwaterstoffen (HFK\'s), n.e.g.',
    38247900: 'mengsels bevattende halogeenderivaten van methaan, van ethaan of van propaan (m.u.v. de producten genoemd in de onderverdelingen 3824 71 00 t/m 3824 78 00)',
    38248100: 'mengsels en bereidingen bevattende oxiraan (ethyleenoxide)',
    38248200: 'mengsels en bereidingen bevattende polybroombifenylen (PBB\'s), polychloorbifenylen (PCB\'s) of polychloortrifenylen (PCT\'s)',
    38248300: 'mengsels en bereidingen bevattende tris(2,3-dibroompropyl)fosfaat',
    38248400: 'Mengsels en preparaten bevattende aldrine (ISO), camfechloor (ISO) (toxafeen), chlordaan (ISO), chloordecon (ISO), DDT (ISO) (clofenotaan (INN), 1,1,1-trichloor-2,2-bis(p-chloorfenyl)ethaan), dieldrine (ISO, INN), endosulfan (ISO), endrin (ISO), heptachloor (ISO) of mirex (ISO)',
    38248500: 'Mengsels en preparaten bevattende 1,2,3,4,5,6-hexachloorcyclohexaan (HCH (ISO)), lindaan (ISO, INN) daaronder begrepen',
    38248600: 'Mengsels en preparaten bevattende pentachloorbenzeen (ISO) of hexachloorbenzeen (ISO)',
    38248700: 'Mengsels en preparaten bevattende perfluoroctaansulfonzuur, zouten daarvan, perfluoroctaansulfonamiden of  perfluoroctaansulfonylfluoride',
    38248800: 'Mengsels en preparaten bevattende tetra-, penta-, hexa- hepta- of octabroomdifenylethers',
    38249100: 'Mengsels en preparaten die hoofdzakelijk bestaan uit (5-ethyl-2-methyl-2-oxido-1,3,2-dioxafosfinaan-5-yl)methyl-methyl-methylfosfonaat en bis[(5-ethyl-2-methyl-2-oxido-1,3,2-dioxafosfinaan-5-yl)methyl] methylfosfonaat',
    38249910: 'Sulfonzuren, thiofeenhoudend, van oliën uit bitumineuze mineralen, alsmede zouten daarvan, petroleumsulfonaten (m.u.v. petroleumsulfonaten van alkalimetalen, ammonium of ethanolaminen)',
    38249915: 'ionenwisselaars (m.u.v. polymeren bedoeld bij hoofdstuk 39)',
    38249920: 'gasbinders "getters" voor elektrische lampen en buizen',
    38249925: 'Pyrolignieten "calciumpyroligniet enz.", ruw calciumtartraat, ruw calciumcitraat',
    38249930: 'nafteenzuren, niet in water onoplosbare zouten van nafteenzuren en esters van nafteenzuren',
    38249945: 'preparaten voor ketelsteenbestrijding e.d.',
    38249950: 'preparaten van de chemische industrie of van aanverwante industrieën voor de galvanotechniek',
    38249955: 'mengsels van mono-, di- en trivetzure esters van glycerol "emulgeermiddelen voor vetstoffen"',
    38249956: 'Cartridges en navullingen, gevuld, voor elektronische sigaretten; preparaten voor gebruik in cartridges en navullingen voor elektronische sigaretten, bevattende nicotine alsmede  zouten, ethers, esters en andere derivaten daarvan',
    38249957: 'Cartridges en navullingen, gevuld, voor elektronische sigaretten; preparaten voor gebruik in cartridges en navullingen voor elektronische sigaretten, niet bevattende nicotine alsmede  zouten, ethers, esters en andere derivaten daarvan',
    38249958: 'Nicotinepatches (transdermale systemen), bedoeld als hulpmiddel voor rokers om te stoppen met roken',
    38249961: 'tussenproducten van de vervaardiging van antibiotica, verkregen door de fermentatie van Streptomyces tenebrarius, ook indien gedroogd, bestemd voor de vervaardiging van geneesmiddelen voor menselijk gebruik bedoeld bij post 3004',
    38249962: 'tussenproducten verkregen bij de vervaardiging van zouten van monensine, gebruikt voor farmaceutische of chirurgische doeleinden',
    38249964: 'Producten en preparaten van de chemische of aanverwante industrieën, gebruikt voor farmaceutische of chirurgische doeleinden, n.e.g.',
    38249965: 'hulpmiddelen in de vorm van chemische preparaten van de soorten die worden gebruikt in de gieterij (m.u.v. bereide bindmiddelen voor gietmiddelen of voor gietkernen)',
    38249970: 'Preparaten, chemisch, vlamwerend, waterwerend e.d., gebruikt voor de bescherming van bouwwerken',
    38249975: 'niet-gedoopte lithiumniobaatschijven',
    38249980: 'mengsels van aminen verkregen uit gedimeriseerde vetzuren, met een gemiddeld molecuulgewicht van >= 520 doch <= 550',
    38249985: '3-"1-ethyl-1-methylpropyl"isoxazool-5-ylamine, opgelost in tolueen',
    38249986: 'Mengsel hoofdzakelijk bestaande uit dimethyl methylfosfonaat, oxiraan and difosforpentaoxide',
    38249992: 'Chemische producten of preparaten, voornamelijk samengesteld uit organische verbindingen, in vloeibare vorm bij 20°C, n.e.g.',
    38249993: 'Chemische producten of preparaten, voornamelijk samengesteld uit organische verbindingen  n.e.g. (m.u.v. die in vloeibare vorm bij 20°C)',
    38249996: 'Chemische producten of preparaten van de chemische of van aanverwante industrieën, incl. mengsels van natuurlijke producten, niet voornamelijk samengesteld uit organische verbindingen, n.e.g.',
    38251000: 'stedelijk afval',
    38252000: 'slib van afvalwater',
    38253000: 'klinisch afval',
    38254100: 'afvallen van organische oplosmiddelen, gehalogeneerd',
    38254900: 'afvallen van organische oplosmiddelen, tenzij gehalogeneerd',
    38255000: 'afvallen van beitsvloeimiddelen voor metalen, van hydraulische vloeistoffen, van remvloeistoffen en van antivriesvloeistoffen',
    38256100: 'afvallen van de chemische of van aanverwante industrieën, hoofdzakelijk organische oplosmiddelen bevattend (m.u.v. antivriesvloeistoffen)',
    38256900: 'afvallen van de chemische of van aanverwante industrieën (m.u.v. afvallen van beitsvloeimiddelen voor metalen, van hydraulische vloeistoffen, van remvloeistoffen en van antivriesvloeistoffen en die welke hoofdzakelijk organische oplosmiddelen bevatten)',
    38259010: 'ijzeroxiden, gealkaliseerd, voor zuiveren van gas',
    38259090: 'residuen van de chemische of van aanverwante industrieën, elders genoemd noch elders onder begrepen (m.u.v. afval)',
    38260010: 'Monoalkylesters van vetzuren, met een estergehalte van 96,5 gewichtspercenten of meer (FAMAE)',
    38260090: 'Biodiesel en mengsels daarvan, geen of minder dan 70 gewichtspercenten aardolie of olie uit bitumineuze mineralen bevattend (m.u.v. Monoalkylesters van vetzuren, met een estergehalte van 96,5 gewichtspercenten of meer (FAMAE))',
    39011010: 'polyethyleen, lineair, met een relatieve dichtheid van < 0,94, in primaire vormen',
    39011090: 'polyethyleen met een relatieve dichtheid van < 0,94, in primaire vormen (m.u.v. lineair polyethyleen)',
    39012010: 'polyethyleen in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d., met een relatieve dichtheid van >= 0,958 bij 23°C, bevattende <= 50 mg/kg aluminium, <= 2 mg/kg kalium, <= 2 mg/kg chroom, <= 2 mg/kg ijzer, <= 2 mg/kg nikkel, <= 2 mg/kg titaan en <= 8 mg/kg vanadium, bestemd voor de vervaardiging van gechloorsulfoneerd polyethyleen',
    39012090: 'polyethyleen met een relatieve dichtheid van >= 0,94, in primaire vormen (m.u.v. polyethyleen in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d., met een relatieve dichtheid van >= 0,958 bij 23°C, bevattende <= 50 mg/kg aluminium, <= 2 mg/kg kalium, <= 2 mg/kg chroom, <= 2 mg/kg ijzer, <= 2 mg/kg nikkel, <= 2 mg/kg titaan en <= 8 mg/kg vanadium, bestemd voor de vervaardiging van gechloorsulfoneerd polyethyleen)',
    39013000: 'copolymeren van ethyleen en vinylacetaat in primaire vormen',
    39014000: 'copolymeren van ethyleen en alfa-olefine, met een relatieve dichtheid van minder dan 0,94, in primaire vormen',
    39019030: 'Ionomeerharsen bestaande uit een zout van een terpolymeer van ethyleen, isobutylacrylaat en methacrylzuur, in primaire vormen en A-B-A-blokcopolymeren van ethyleen, bestaande uit polystyreen, ethyleen-butyleen-copolymeer en polystyreen, bevattende <= 35 gewichtspercenten styreen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.',
    39019080: 'polymeren van ethyleen, in primaire vormen (m.u.v. polyethyleen; copolymeren van ethyleen en vinylacetaat; copolymeren van ethyleen en alfa-olefine; ionomeerharsen bestaande uit een zout van een terpolymeer van ethyleen, isobutylacrylaat en methacrylzuur; A-B-A-blokcopolymeren, bestaande uit polystyreen, ethyleen-butyleen-copolymeer en polystyreen, bevattende <= 35 gewichtspercenten styreen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.)',
    39021000: 'polypropyleen in primaire vormen',
    39022000: 'polyisobutyleen in primaire vormen',
    39023000: 'copolymeren van propyleen in primaire vormen',
    39029010: 'A-B-A-blokcopolymeren van propyleen of van andere olefinen, bestaande uit polystyreen, een ethyleen-butyleen-copolymeer en polystyreen, bevattende <= 35 gewichtspercenten styreen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.',
    39029020: 'poly"but-1-een", copolymeren van but-1-een en ethyleen bevattende <= 10 gewichtspercenten ethyleen, en mengsels van poly"but-1-een" met polyethyleen en/of polypropyleen bevattende <= 10 gewichtspercenten polyethyleen en/of <= 25 gewichtspercenten polypropyleen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.',
    39029090: 'polymeren van propyleen of van andere olefinen, in primaire vormen (m.u.v. polypropyleen; polyisobutyleen; copolymeren van propyleen; A-B-A-blokcopolymeren, bestaande uit polystyreen, een ethyleen-butyleen-copolymeer en polystyreen, bevattende <= 35 gewichtspercenten styreen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.; poly"but-1-een", copolymeren van but-1-een en ethyleen bevattende <= 10 gewichtspercenten ethyleen, en mengsels van poly"but-1-een" met polyethyleen en/of polypropyleen bevattende <= 10 gewichtspercenten polyethyleen en/of <= 25 gewichtspercenten polypropyleen, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.)',
    39031100: 'polystyreen in primaire vormen, expandeerbaar',
    39031900: 'polystyreen in primaire vormen (m.u.v. expandeerbaar polystyreen)',
    39032000: 'copolymeren van styreen en acrylonitrol "SAN", in primaire vormen',
    39033000: 'copolymeren van acrylonitrol, butadieen en styreen "ABS", in primaire vormen',
    39039010: 'copolymeren enkel van allylalcohol met styreen, met een acetylgetal van >= 175, in primaire vormen',
    39039020: 'gebromeerd polystyreen, bevattende >= 58 doch <= 71 gewichtspercenten broom, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.',
    39039090: 'polymeren van styreen, in primaire vormen (m.u.v. polystyreen; copolymeren van styreen en acrylonitrol [SAN]; copolymeren van acrylonitril, butadieen en styreen [ABS]; copolymeren enkel van allylalcohol met styreen, met een acetylgetal van >= 175; gebromeerd polystyreen, bevattende >= 58, doch <= 71 gewichtspercenten broom, in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.)',
    39041000: 'poly"vinylchloride", tenzij gemengd met andere zelfstandigheden, in primaire vormen',
    39042100: 'poly"vinylchloride", gemengd met andere zelfstandigheden, in primaire vormen, geen weekmakers bevattend',
    39042200: 'poly"vinylchloride", gemengd met andere zelfstandigheden, in primaire vormen, weekmakers bevattend',
    39043000: 'copolymeren van vinylchloride en vinylacetaat, in primaire vormen',
    39044000: 'copolymeren van vinylchloride in primaire vormen (m.u.v. copolymeren van vinylchloriden en vinylacetaat)',
    39045010: 'copolymeren van vinylideenchloride en acrylonitril, in de vorm van expandeerbare bolletjes met een diameter van >= 4 doch <= 20 micrometer',
    39045090: 'polymeren van vinylideenchloride, in primaire vormen (m.u.v. copolymeren van vinylideenchloride en acrylonitril, in de vorm van expandeerbare bolletjes met een diameter van >= 4 doch <= 20 micrometer)',
    39046100: 'polytetrafluorethyleen, in primaire vormen',
    39046910: 'poly"vinylfluoride", in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.',
    39046920: 'Fluorelastomeer FKM, in primaire vormen',
    39046980: 'Polymeren van vinylchloride of van andere halogeenolefinen, in primaire vormen, fluorhoudend (m.u.v. fluorelastomeer FKM, polytetrafluorethyleen; poly"vinylfluoride", in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.)',
    39049000: 'polymeren van vinylchloride of van andere halogeenolefinen, in primaire vormen (m.u.v. poly"vinylchloride", copolymeren van vinylchloride, polymeren van vinylideenchloride en fluorhoudende polymeren)',
    39051200: 'poly"vinylacetaat" in waterige dispersie',
    39051900: 'poly"vinylacetaat" in primaire vormen (m.u.v. dat in waterige dispersie)',
    39052100: 'copolymeren van vinylacetaat, in waterige dispersie',
    39052900: 'copolymeren van vinylacetaat, in primaire vormen (m.u.v. die in waterige dispersie)',
    39053000: 'poly"vinylalcohol", ook indien niet-gehydrolyseerde acetaatgroepen bevattend, in primaire vormen',
    39059100: 'copolymeren van vinyl, in primaire vormen (m.u.v. die van vinylchloride en vinylacetaat, alsmede andere van vinylchloride of van vinylacetaat)',
    39059910: 'poly"vinylformal" in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d., met een molecuulgewicht van >= 10.000 doch <= 40.000 en met een gehalte aan acetylgroepen, berekend als vinylacetaat, van >= 9,5 doch <= 13 gewichtspercenten en aan hydroxylgroepen, berekend als vinylalcohol, van >= 5 doch <= 6,5 gewichtspercenten',
    39059990: 'polymeren van vinylesters en andere vinylpolymeren, in primaire vormen (m.u.v. die van vinylchloride of van andere halogeenolefinen; poly"vinylacetaat"; copolymeren; poly"vinylalcohol", ook indien niet-gehydrolyseerde acetaatgroepen bevattend; poly"vinylformal" in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d., met een molecuulgewicht van >= 10.000 doch <= 40.000 en met een gehalte aan acetylgroepen, berekend als vinylacetaat, van >= 9,5 doch <= 13 gewichtspercenten en aan hydroxylgroepen, berekend als vinylalcohol, van >= 5 doch <= 6,5 gewichtspercenten)',
    39061000: 'poly"methylmethacrylaat", in primaire vormen',
    39069010: 'poly[N-"3-hydroxyimino-1,1-dimethylbutyl"acrylamide], in primaire vormen',
    39069020: 'copolymeren van 2-diisopropylaminoëthylmethacrylaat en decylmethacrylaat, opgelost in N,N-dimethylaceetamide, met een gehalte aan copolymeer van >=55 gewichtspercenten',
    39069030: 'copolymeren van acrylzuur en 2-ethylhexylacrylaat, bevattende >= 10 doch <= 11 gewichtspercenten 2-ethyl-hexylacrylaat, in primaire vormen',
    39069040: 'copolymeren van acrylonitril en methylacrylaat, gewijzigd met polybutadieenacrylonitril "NBR", in primaire vormen',
    39069050: 'polymerisatieproducten van acrylzuur, alkylmethacrylaat en kleine hoeveelheden andere monomeren, bestemd om te worden gebruikt als verdikkingsmiddel bij de vervaardiging van pasta\'s voor de textieldruk',
    39069060: 'copolymeren van methylacrylaat, ethyleen en een monomeer met een niet-eindstandige carboxylgroep als substituent, bevattende >=50 gewichtspercenten methylacrylaat, ook indien vermengd met siliciumdioxide, in primaire vormen',
    39069090: 'acrylpolymeren in primaire vormen (m.u.v. poly"methylmethacrylaat"; poly[N-"3-hydroxyimino-1,1-dimethylbutyl"acrylamide]; copolymeren van 2-diisopropylaminoëthylmethacrylaat en decylmethacrylaat opgelost in N,N-dimethylaceetamide, met een gehalte aan copolymeer van >=55 gewichtspercenten; copolymeren van acrylzuur en 2-ethylhexylacrylaat bevattende >= 10, doch <= 11 gewichtspercenten 2-ethyl-hexylacrylaat; copolymeren van acrylonitril en methylacrylaat gewijzigd met polybutadieenacrylonitril [NBR]; polymerisatieproducten van acrylzuur, alkylmethacrylaat en kleine hoeveelheden andere monomeren bestemd om te worden gebruikt als verdikkingsmiddel bij de vervaardiging van pasta\'s voor de textieldruk; copolymeren van methylacrylaat, ethyleen en een monomeer met een niet-eindstandige carboxylgroep als substituent, bevattende >= 50 gewichtspercenten methylacrylaat, ook indien vermengd met siliciumdioxide)',
    39071000: 'polyacetalen in primaire vormen',
    39072011: 'polyethyleenglycolen in primaire vormen',
    39072020: 'Polyetheralcoholen, in primaire vormen (m.u.v. polyethyleenglycolen)',
    39072091: 'copolymeren van 1-chloor-2,3-epoxypropaan en ethyleenoxide, in primaire vormen',
    39072099: 'polyethers in primaire vormen (m.u.v. polyetheralcoholen; polyacetalen; copolymeren van 1-chloor-2,3-epoxypropaan en ethyleenoxide)',
    39073000: 'epoxyharsen in primaire vormen',
    39074000: 'polycarbonaten in primaire vormen',
    39075000: 'alkydharsen in primaire vormen',
    39076100: 'poly"ethyleentereftalaat" in primaire vormen, met een viscositeitsgetal van >= 78 ml/g',
    39076900: 'poly"ethyleentereftalaat" in primaire vormen, met een viscositeitsgetal van < 78 ml/g',
    39077000: 'poly(melkzuur) in primaire vormen',
    39079110: 'polyallylesters en andere polyesters, onverzadigd, vloeibaar, in primaire vormen (m.u.v. polycarbonaten, alkydharsen en poly"ethyleentereftalaat")',
    39079190: 'polyallylesters en andere polyesters, onverzadigd, in primaire vormen (m.u.v. vloeibare polyesters en polycarbonaten, alkydharsen en poly"ethyleentereftalaat")',
    39079905: 'Thermoplastische aromatische vloeibare-kristal-polyester-copolymeren, gesatureerd, in primaire vormen',
    39079910: 'Poly"ethyleennaftaleen-2,6-dicarboxylaat", verzadigd, in primaire vormen',
    39079980: 'Polyesters, verzadigd, in primaire vormen (m.u.v. polycarbonaten; alkydharsen; poly"ethyleentereftalaat"; poly[melkzuur]; poly[ethyleennaftaleen-2,6-dicarboxylaat] en thermoplastische aromatische vloeibare-kristal-polyester-copolymeren)',
    39081000: 'polyamide-6, -11, -12, -6,6, -6,9, -6,10 of -6,12 in primaire vormen',
    39089000: 'polyamide in primaire vormen (m.u.v. polyamide-6, -11, -12, -6,6, -6,9, -6,10 of -6,12)',
    39091000: 'ureumharsen, incl. thio-ureumharsen, in primaire vormen',
    39092000: 'melamineharsen in primaire vormen',
    39093100: 'Poly(methyleenfenylisocyanaat) (ruw MDI, polymerisch MDI), in primaire vormen',
    39093900: 'Aminoharsen, in primaire vormen (m.u.v.ureumharsen, incl. thio-ureumharsen, en melamineharsen en MDI)',
    39094000: 'fenolharsen in primaire vormen',
    39095010: 'polyurethaan vervaardigd van 2,2\'-"tert-butylimino"diëthanol en 4,4\'-methyleendicyclohexyldiisocyanaat, opgelost in N,N-dimethylaceetamide, met een gehalte aan polymeer van >= 50 gewichtspercenten',
    39095090: 'polyurethanen in primaire vormen (m.u.v. polyurethaan vervaardigd van 2,2\'-"tert-butylimino"diëthanol en 4,4\'-methyleendicyclohexyldiisocyanaat, opgelost in N,N-dimethylaceetamide)',
    39100000: 'siliconen in primaire vormen',
    39111000: 'petroleumharsen, cumaronharsen, indeenharsen of cumaronindeenharsen en polyterpenen, in primaire vormen',
    39119011: 'poly"oxy-1,4-fenyleensulfonyl-1,4-fenyleenoxy-1,4-fenyleenisopropylideen-1,4-fenyleen", in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d., ook indien chemisch gewijzigd',
    39119013: 'poly"thio-1,4-fenyleen", ook indien chemisch gewijzigd, in primaire vormen',
    39119019: 'polymerisatieproducten verkregen door herschikking of condensatie, ook indien chemisch gewijzigd, n.e.g., in primaire vormen (m.u.v. poly[oxy-1,4-fenyleensulfonyl-1,4-fenyleenoxy-1,4-fenyleenisopropylideen-1,4-fenyleen], in blokken in onregelmatige vorm, stukken, klonters, poeder, korrels, vlokken e.d.; poly[thio-1,4-fenyleen])',
    39119092: 'Gehydrogeneerde copolymeren van vinyltolueen en alfa-methylstyreen, vervaardigd door chemische synthese, en copolymeer van p-kresol en divinylbenzeen, opgelost in N,N-dimethylaceetamide, met een gehalte aan polymeer van >=50 gewichtspercenten, vervaardigd door chemische synthese, in primaire vormen',
    39119099: 'kunststoffen "polymeren en prepolymeren", vervaardigd door chemische synthese, n.e.g., in primaire vormen (m.u.v. copolymeer van p-kresol en divinylbenzeen, opgelost in N,N-dimethylaceetamide; gehydrogeneerde copolymeren van vinyltolueen en alfa-methylstyreen)',
    39121100: 'celluloseacetaten, zonder weekmakers, in primaire vormen',
    39121200: 'celluloseacetaten, weekmakers bevattend, in primaire vormen',
    39122011: 'collodion en celloïdine, zonder weekmakers, in primaire vormen',
    39122019: 'cellulosenitraten, zonder weekmakers, in primaire vormen (m.u.v. collodion en celloïdine)',
    39122090: 'cellulosenitraten, incl. collodion, weekmakers bevattend, in primaire vormen',
    39123100: 'carboxymethylcellulose en zouten daarvan, in primaire vormen',
    39123920: 'hydroxypropylcellulose, in primaire vormen',
    39123985: 'Cellulose-ethers in primaire vormen (m.u.v. hydroxypropylcellulose en carboxymethylcellulose en zouten daarvan)',
    39129010: 'cellulose-esters in primaire vormen',
    39129090: 'cellulose en chemische derivaten daarvan, n.e.g., in primaire vormen (m.u.v. celluloseacetaten, cellulosenitraten, cellulose-ethers en cellulose-esters)',
    39131000: 'alginezuur, alsmede zouten en esters daarvan, in primaire vormen',
    39139000: 'polymeren, natuurlijk en gewijzigde natuurlijke polymeren, b.v. geharde proteïnen, chemische derivaten van natuurlijke rubber, n.e.g., in primaire vormen (m.u.v. alginezuur, alsmede zouten en esters daarvan)',
    39140000: 'Ionenwisselaars van polymeren bedoeld bij de posten 3901 tot en met 3913 in primaire vormen',
    39151000: 'resten en afval van polymeren van ethyleen',
    39152000: 'resten en afval van polymeren van styreen',
    39153000: 'resten en afval van polymeren van vinylchloride',
    39159011: 'resten en afval, van polymeren van propyleen',
    39159080: 'Resten en afval van kunststof (m.u.v. die van polymeren van ethyleen, van styreen, van vinylchloride en van propyleen)',
    39161000: 'monofilament waarvan de grootste afmeting van de dwarsdoorsnede 2 keer groter dan 1 mm is, alsmede staven en profielen, van polymeren van ethyleen, ook indien aan het oppervlak bewerkt, doch die geen andere bewerking hebben ondergaan',
    39162000: 'Monofilament met een grootste afmeting van de dwarsdoorsnede van > 1 mm, alsmede staven en profielen, van polymeren van vinylchloride, ook indien aan het oppervlak bewerkt, doch die geen andere bewerking hebben ondergaan',
    39169010: 'Monofilament waarvan de grootste afmeting van de dwarsdoorsnede groter dan 1 mm is, alsmede staven en profielen, van polymerisatieproducten verkregen door herschikking of condensatie, ook indien chemisch gewijzigd, ook indien aan het oppervlak bewerkt, doch die geen andere bewerking hebben ondergaan (m.u.v. die van polymeren van ethyleen en van vinylchloride)',
    39169050: 'Monofilament waarvan de grootste afmeting van de dwarsdoorsnede groter dan 1 mm is, alsmede staven en profielen, van polymerisatieproducten verkregen door additie, ook indien aan het oppervlak bewerkt, doch die geen andere bewerking hebben ondergaan (m.u.v. die van polymeren van ethyleen en van vinylchloride)',
    39169090: 'monofilament waarvan de grootste afmeting van de dwarsdoorsnede 2 keer groter dan 1 mm is, alsmede staven en profielen, van kunststof, ook indien aan het oppervlak bewerkt, doch die geen andere bewerking hebben ondergaan (m.u.v. die van polymerisatieproducten verkregen door additie of door herschikking of condensatie, ook indien chemisch gewijzigd)',
    39171010: 'kunstdarmen "worstvellen" van geharde proteïne',
    39171090: 'kunstdarmen "worstvellen" van cellulosekunststof',
    39172110: 'buizen en slangen, stijf, van polymeren van ethyleen, naadloos en met een lengte die de grootste afmeting van de dwarsdoorsnede overtreft, ook indien aan het oppervlak bewerkt, doch verder onbewerkt',
    39172190: 'Buizen en slangen, stijf, van polymeren van ethyleen, (m.u.v. naadloze buizen en slangen die enkel op lengte zijn gesneden)',
    39172210: 'buizen en slangen, stijf, van polymeren van propyleen, naadloos en met een lengte die de grootste afmeting van de dwarsdoorsnede overtreft, ook indien aan het oppervlak bewerkt, doch verder onbewerkt',
    39172290: 'Buizen en slangen, stijf, van polymeren van propyleen, (m.u.v. naadloze buizen en slangen die enkel op lengte zijn gesneden en met een lengte die de grootste afmeting van de dwarsdoorsnede overtreft)',
    39172310: 'buizen en slangen, stijf, van polymeren van vinylchloride, naadloos en met een lengte die de grootste afmeting van de dwarsdoorsnede overtreft, ook indien aan het oppervlak bewerkt, doch verder onbewerkt',
    39172390: 'Buizen en slangen, stijf, van polymeren van vinylchloride (m.u.v. naadloze buizen en slangen die enkel op lengte zijn gesneden en met een lengte die de grootste afmeting van de dwarsdoorsnede overtreft)',
    39172900: 'Buizen en slangen,  stijf, van kunststof (m.u.v. die van polymeren van ethyleen, van propyleen en van vinylchloride)',
    39173100: 'Buizen en slangen, flexibel, van kunststof, met een drukweerstand van >= 27,6 MPa',
    39173200: 'Buizen en slangen, flexibel, van kunststof, niet versterkt of op andere wijze gecombineerd met andere stoffen, zonder hulpstukken',
    39173300: 'Buizen en slangen, flexibel, van kunststof, met hulpstukken (niet versterkt of op andere wijze gecombineerd met andere stoffen)',
    39173900: 'Buizen en slangen,  flexibel, van kunststof, versterkt of op andere wijze gecombineerd met andere stoffen (m.u.v. buizen en slangen met een drukweerstand van >= 27,6 MPa)',
    39174000: 'Hulpstukken "verbindingsstukken, moffen, ellebogen, flenzen", van kunststof, voor buizen en slangen',
    39181010: 'vloerbedekking, ook indien zelfklevend, op rollen of in tegels en wand- of plafondbekleding op rollen met een breedte van 45 cm of meer, bestaande uit op een drager van ongeacht welke stof (m.u.v. papier), blijvende bevestigde laag kunststof die is gegreineerd, gegaufreerd, gekleurd, met motieven bedrukt of op andere wijze aan de voorzijde versierd, op een drager die is geïmpregneerd met, voorzien van een deklaag van of bekleed met poly"vinylchloride"',
    39181090: 'vloerbedekking, ook indien zelfklevend, op rollen of in tegels, van polymeren van vinylchloride (m.u.v. die op een drager, die is geïmpregneerd met, voorzien van een deklaag van of bekleed met poly"vinylchloride")',
    39189000: 'vloerbedekking, ook indien zelfklevend, op rollen of in tegels en wand- of plafondbekleding op rollen met een breedte van 45 cm of meer, bestaande uit op een drager van ongeacht welke stof (m.u.v. papier), blijvende bevestigde laag kunststof die is gegreineerd, gegaufreerd, gekleurd, met motieven bedrukt of op andere wijze aan de voorzijde versierd, van kunststof (m.u.v. die van polymeren van vinylchloride)',
    39191012: 'Zelfklevend band van poly"vinylchloride" of van polyethyleen, waarvan het kleefmiddel bestaat uit niet-gevulkaniseerde natuurlijke of synthetische rubber, op rollen met een breedte van <= 20 cm',
    39191015: 'zelfklevend band van polypropyleen, waarvan het kleefmiddel bestaat uit niet-gevulkaniseerde natuurlijke of synthetische rubber, op rollen met een breedte van <= 20 cm',
    39191019: 'zelfklevend band van kunststof, waarvan het kleefmiddel bestaat uit niet-gevulkaniseerde natuurlijke of synthetische rubber, op rollen met een breedte van <= 20 cm (m.u.v. die van poly"vinylchloride", polyethyleen of polypropyleen)',
    39191080: 'Foliën, stroken, strippen en andere platte producten, van kunststof, zelfklevend, op rollen met een breedte van <= 20 cm (m.u.v. die waarvan het kleefmiddel bestaat uit niet-gevulkaniseerde natuurlijke of synthetische rubber)',
    39199020: 'Zelfklevende ronde schuurlappen van de soort gebruikt voor de vervaardiging van schijven (wafers) van halfgeleidermateriaal, van kunststof',
    39199080: 'Platen, vellen, foliën, stroken, strippen en andere platte producten, van kunststof, zelfklevend, ook indien op rollen met een breedte van > 20 cm, (m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. cirkelvormige polijstsponzen gebruikt bij de productie van halfgeleider wafers)',
    39201023: 'foliën van polyethyleen zonder celstructuur, met een dikte van >= 20 doch <= 40 micrometer en met een relatieve dichtheid van < 0,94, bestemd voor de vervaardiging van fotoresistfilm gebruikt bij de productie van halfgeleiders of van gedrukte schakelingen',
    39201024: 'stretchfoliën van polyethyleen zonder celstructuur, onbedrukt, met een dikte van <= 0,125 mm en met een relatieve dichtheid van < 0,94',
    39201025: 'Platen, vellen, foliën, stroken en strippen, van polyethyleen zonder celstructuur, bedrukt, zonder drager, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,125 mm en met een relatieve dichtheid van < 0,94, n.e.g. (m.u.v. niet bedrukte stretchfoliën en m.u.v. foliën van polyethyleen met een dikte van >= 20, doch <= 40 micrometer, bestemd voor de vervaardiging van fotoresistfilm gebruikt bij de productie van halfgeleiders of van gedrukte schakelingen)',
    39201028: 'Platen, vellen, foliën, stroken, strippen, van polyethyleen, zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,125 mm en met een relatieve dichtheid van >= 0,94, n.e.g. (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39201040: 'Platen, vellen, foliën, stroken, strippen, van polymeren van ethyleen, zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,125 mm (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; wand- en plafondbekleding bedoeld bij post 3918)',
    39201081: 'synthetische papierpulp in de vorm van vochtige vellen, bestaande uit niet samenhangende fijnvertakte fibrillen van polyethyleen zonder celstructuur, ook indien gemengd met <= 15 gewichtspercenten vezels van cellulose, bevattende als bevochtigingsmiddel poly"vinylalcohol" opgelost in water, met een dikte van > 0,125 mm',
    39201089: 'platen, vellen, foliën, stroken, strippen, van polymeren van ethyleen zonder celstructuur, zonder drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van > 0,125 mm (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding bedoeld bij post 3918; synthetische papierpulp in de vorm van vochtige vellen, bestaande uit niet samenhangende fijnvertakte fibrillen van polyethyleen, ook indien gemengd met <= 15 gewichtspercenten vezels van cellulose, bevattende als bevochtigingsmiddel poly"vinylalcohol" opgelost in water)',
    39202021: 'platen, vellen, foliën, stroken en strippen, van polymeren van propyleen zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,10 mm, biaxiaal georiënteerd (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten en vloerbedekking en wand- en plafondbekleding)',
    39202029: 'platen, vellen, foliën, stroken en strippen, van polymeren van propyleen zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,10 mm (niet biaxiaal georiënteerd, m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten en vloerbedekking en wand- en plafondbekleding)',
    39202080: 'Platen, vellen, foliën, stroken en strippen, van polymeren van propyleen zonder celstructuur, zonder drager, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van > 0,10 mm, n.e.g.',
    39203000: 'platen, vellen, foliën, stroken en strippen, van polymeren van styreen zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39204310: 'platen, vellen, foliën, stroken en strippen, van polymeren van vinylchloride zonder celstructuur, bevattende >= 6 gewichtspercenten weekmakers, met een dikte van <= 1 mm, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten, alsmede vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39204390: 'platen, vellen, foliën, stroken en strippen, van polymeren van vinylchloride zonder celstructuur, bevattende >= 6 gewichtspercenten weekmakers, met een dikte van > 1 mm, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten, alsmede vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39204910: 'platen, vellen, foliën, stroken en strippen, van polymeren van vinylchloride zonder celstructuur, bevattende < 6 gewichtspercenten weekmakers, met een dikte van <= 1 mm, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten, alsmede vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39204990: 'platen, vellen, foliën, stroken en strippen, van polymeren van vinylchloride zonder celstructuur, bevattende < 6 gewichtspercenten weekmakers, met een dikte van > 1 mm, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten, alsmede vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39205100: 'platen, vellen, foliën, stroken en strippen, van poly"methylmethacrylaat" zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39205910: 'copolymeren van acryl- en methacrylesters zonder celstructuur, in de vorm van foliën met een dikte van <= 150 micrometer',
    39205990: 'platen, vellen, foliën, stroken en strippen, van acrylpolymeren zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die van poly"methylmethacrylaat"; zelfklevende producten; vloerbedekking en wand- en plafondbekleding zoals bedoeld bij post 3918; copolymeren van acryl- en methacrylesters in de vorm van foliën met een dikte van <= 150 micrometer)',
    39206100: 'platen, vellen, foliën, stroken en strippen, van polycarbonaten zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39206212: 'Foliën van poly"ethyleentereftalaat" zonder celstructuur met een dikte van >= 72 doch <= 79 micrometer, bestemd voor de vervaardiging van flexibele magneetschijven, en foliën van poly"ethyleentereftalaat", niet versterkt, met een dikte van >= 100 doch <= 150 micrometer, bestemd voor de vervaardiging van fotopolymere hoogdrukplaten',
    39206219: 'platen, vellen, foliën, stroken en strippen, van poly"ethyleentereftalaat" zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van <= 0,35 mm (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding zoals bedoeld bij post 3918; foliën van poly"ethyleentereftalaat" met een dikte van >= 72 doch <= 79 micrometer bestemd voor de vervaardiging van flexibele magneetschijven; foliën van poly"ethyleentereftalaat" met een dikte van >= 100 doch <= 150 micrometer, bestemd voor de vervaardiging van fotopolymere hoogdrukplaten)',
    39206290: 'platen, vellen, foliën, stroken en strippen van poly"ethyleentereftalaat" zonder celstructuur, niet op een drager, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden, met een dikte van > 0,35 mm (m.u.v. die welke zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen en m.u.v. zelfklevende producten en vloerbedekking en wand- en plafondbekleding van post 3918)',
    39206300: 'platen, vellen, foliën, stroken en strippen, van onverzadigde polyesters zonder celstructuur, maar zonder drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39206900: 'platen, vellen, foliën, stroken en strippen, van alkydharsen, polyallylesters en andere polyesters zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die van poly"ethyleentereftalaat" en van onverzadigde polyesters en m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39207100: 'Platen, vellen, foliën, stroken en strippen, van geregenereerde cellulose zonder celstructuur, niet op een drager, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v.  zelfklevende producten en m.u.v. vloerbedekkingen en wand- en plafondbekleding als bedoeld bij post 3918)',
    39207310: 'films op rollen of in stroken, voor de cinematografie of voor de fotografie, van celluloseacetaat zonder celstructuur',
    39207380: 'Platen, vellen, foliën, stroken en strippen, van celluloseacetaat zonder celstructuur, zonder drager, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en  m.u.v. films op rollen of in stroken)',
    39207910: 'platen, vellen, foliën, stroken en strippen, van vulkanfiber, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden',
    39207990: 'platen, vellen, foliën, stroken en strippen, van cellulosederivaten zonder celstructuur, niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen,  niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die van celluloseacetaten en m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39209100: 'platen, vellen, foliën, stroken en strippen, van poly"vinylbutyral" zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39209200: 'platen, vellen, foliën, stroken en strippen, van polyamiden zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39209300: 'platen, vellen, foliën, stroken en strippen, van aminoharsen zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39209400: 'platen, vellen, foliën, stroken en strippen, van fenolharsen zonder celstructuur, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen)',
    39209921: 'foliën en strippen van polyimide zonder celstructuur, zonder deklaag of enkel voorzien van een deklaag van kunststof, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39209928: 'platen, vellen, foliën, stroken en strippen, van polymerisatieproducten zonder celstructuur verkregen door herschikking of condensatie, n.e.g., niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918; foliën en strippen van polyimide, zonder deklaag of enkel voorzien van een deklaag van kunststof)',
    39209952: 'Foliën van poly"vinylfluoride"  en biaxiaal georiënteerde foliën van poly"vinylalcohol" zonder celstructuur, bevattende >= 97 gewichtspercenten poly"vinylalcohol", zonder deklaag, met een dikte van <= 1 mm, niet op een drager, niet versterkt, gelaagd of op andere wijze gecombineerd met andere stoffen, alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39209953: 'ionenwisselaarmembranen van gefluoreerde kunststof zonder celstructuur, bestemd voor gebruik in elektrolytische cellen voor de elektrolyse van chlooralkalische oplossingen',
    39209959: 'platen, vellen, foliën, stroken en strippen, van polymerisatieproducten zonder celstructuur verkregen door additie n.e.g., niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. producten die zijn versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918; foliën van poly"vinylfluoride"; ionenwisselaarmembranen van gefluoreerde kunststof bestemd voor gebruik in elektrolytische cellen voor de elektrolyse van chlooralkalische oplossingen; biaxiaal georiënteerde foliën van poly"vinylalcohol", bevattende >= 97 gewichtspercenten poly"vinylalcohol", zonder deklaag, met een dikte van <= 1 mm)',
    39209990: 'Platen, vellen, foliën, stroken en strippen, van kunststof zonder celstructuur n.e.g.,niet versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, niet op een drager, onbewerkt, of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211100: 'platen, vellen, foliën, stroken en strippen, van polymeren van styreen met celstructuur, onbewerkt, of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211200: 'platen, vellen, foliën, stroken en strippen, van polymeren van vinylchloride met celstructuur, onbewerkt, of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211310: 'flexibele platen, vellen, foliën, stroken en strippen, van polymeren van urethanen met celstructuur, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en vloerbedekking en wand- en plafondbekleding bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211390: 'platen, vellen, foliën, stroken en strippen, van polymeren van urethaan met celstructuur, onbewerkt, alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. flexibele en zelfklevende producten en vloerbedekking en wand- en plafondbekleding bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211400: 'platen, vellen, foliën, stroken en strippen, van polymeren van geregenereerde cellulose met celstructuur, onbewerkt, of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39211900: 'platen, vellen, foliën, stroken en strippen, van polymeren van andere kunststof met celstructuur, onbewerkt, of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. die van polymeren van styreen, vinylchloride, of urethanen of van geregenereerde cellulose, en m.u.v. zelfklevende producten, vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918 en m.u.v. steriele chirugische of tandheelkundige barrièremiddelen van onderverdeling 3006 10 30)',
    39219010: 'Platen, vellen, foliën, stroken en strippen, van polyesters, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. die van kunststof met celstructuur;  zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39219030: 'platen, vellen, foliën, stroken en strippen, van fenolharsen, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39219041: 'platen, vellen, foliën, stroken en strippen, onder hoge druk gelaagd, van aminoharsen, met een decoratieve laag op een of op beide zijden',
    39219043: 'platen, vellen, foliën, stroken en strippen, van gelaagde aminoharsen, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. die welke onder hoge druk gelaagd, met een decoratieve laag op een of op beide zijden, en m.u.v. zelfklevende producten en vloerbedekking)',
    39219049: 'platen, vellen, foliën, stroken en strippen, van ongelaagde aminoharsen, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en m.u.v. vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39219055: 'platen, vellen, foliën, stroken en strippen, van polymerisatieproducten zonder celstructuur, verkregen door herschikking of condensatie, ook indien chemisch gewijzigd, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. die van polyesters, fenolharsen of aminoharsen; zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39219060: 'platen, vellen, foliën, stroken en strippen, van polymerisatieproducten zonder celstructuur, verkregen door additie, ook indien chemisch gewijzigd, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt, alleen bewerkt aan het oppervlak of alleen vierkant of rechthoekig versneden (m.u.v. zelfklevende producten en vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39219090: 'platen, vellen, foliën, stroken en strippen, van kunststof zonder celstructuur, versterkt, gelaagd of op dergelijke wijze gecombineerd met andere stoffen, onbewerkt of alleen aan het oppervlak bewerkt of alleen vierkant of rechthoekig versneden (m.u.v. polymerisatieproducten verkregen door additie of door herschikking of condensatie, ook indien chemisch gewijzigd; zelfklevende producten; vloerbedekking en wand- en plafondbekleding als bedoeld bij post 3918)',
    39221000: 'badkuipen, douchebakken, gootstenen en wasbakken, van kunststof',
    39222000: 'closetbrillen en -deksels van kunststof',
    39229000: 'bidets, closetpotten, stortbakken en dergelijke sanitaire artikelen, van kunststof (m.u.v. badkuipen, douchebakken, gootstenen, wasbakken en closetbrillen en -deksels)',
    39231010: 'Dozen, bakken, kisten en dergelijke artikelen, van kunststof, speciaal gevormd of ingericht voor het vervoer of verpakken van schijven (wafers) van halfgeleidermateriaal, patroonmaskers of systeemrasters ("reticles")',
    39231090: 'dozen, bakken, kisten e.d. artikelen voor vervoer en voor verpakking, van kunststof (m.u.v. die speciaal gevormd of ingericht voor het vervoer of verpakken van schijven (wafers) van halfgeleidermateriaal, patroonmaskers of systeemrasters ("reticles"))',
    39232100: 'zakken van polymeren van ethyleen',
    39232910: 'zakken van poly"vinylchloride"',
    39232990: 'zakken van kunststof (m.u.v. die van poly"vinylchloride" of van polymeren van ethyleen)',
    39233010: 'flessen, flacons e.d. artikelen voor vervoer en voor verpakking, van kunststof, van <= 2 l',
    39233090: 'flessen, flacons e.d. artikelen voor vervoer en voor verpakking, van kunststof, van > 2 l',
    39234010: 'spoelen e.d. dragers voor fotografische of cinematografische films, dan wel voor dragers voor het opnemen van beelden, geluid, signalen, gegevens, programma\'s of voor dergelijke doeleinden',
    39234090: 'spoelen, cops, klossen e.d. opwindmiddelen, van kunststof (m.u.v. die voor fotografische of cinematografische films, dan wel voor dragers voor het opnemen van beelden, geluid, signalen, gegevens, programma\'s of voor dergelijke doeleinden)',
    39235010: 'capsules, van kunststof',
    39235090: 'stoppen, deksels en andere sluitingen, van kunststof',
    39239000: 'Artikelen voor vervoer of voor verpakking, van kunststof (m.u.v. dozen, bakken, kisten e.d. artikelen, zakken, flessen, flacons e.d. artikelen, spoelen, cops, klossen e.d. opwindmiddelen en stoppen, deksels, capsules en andere sluitingen)',
    39241000: 'keuken- en tafelgerei, van kunststof',
    39249000: 'huishoudelijke artikelen en toiletartikelen, van kunststof (m.u.v. keuken- en tafelgerei en sanitaire artikelen)',
    39251000: 'reservoirs, tanks, vaten e.d. bergingsmiddelen, met een inhoudsruimte van > 300 l',
    39252000: 'deuren en ramen, alsmede kozijnen daarvoor en drempels voor deuren, van kunststof',
    39253000: 'blinden, jaloezieën, rolgordijnen e.d. artikelen, alsmede delen daarvan, van kunststof (m.u.v. garnituren en beslag)',
    39259010: 'garnituren en beslag, om blijvend te worden bevestigd in of op deuren, vensters, trappen, muren of andere delen van gebouwen, van kunststof',
    39259020: 'kabelgoten voor elektrische leidingen',
    39259080: 'vloer-, wand-, tussenschot-, plafond- of dakelementen, dakgoten en hulpstukken daarvoor, balkons, balustrades, omheiningen, hekken e.d. afsluitingen, grote rekken, bestemd om te worden gemonteerd en blijvend te worden bevestigd in winkels, werkplaatsen, pakhuizen enz., bouwkundige versieringsmotieven "b.v. profiellijsten, koepels, architraven" en andere artikelen voor de bouw, van kunststof, n.e.g.',
    39261000: 'kantoor- en schoolbenodigdheden, van kunststof, n.e.g.',
    39262000: 'kleding en kledingtoebehoren, door naaien of lijmen vervaardigd uit kunststoffoliën "handschoenen [met of zonder vingers] en wanten daaronder begrepen" (m.u.v. goederen van post 9619)',
    39263000: 'beslag voor meubelen, carrosserieën e.d., van kunststof (m.u.v. uitrustingsstukken om blijvend te worden bevestigd in of op delen van gebouwen)',
    39264000: 'deeltjes en andere versieringsvoorwerpen, van kunststof',
    39269050: 'slibemmers e.d. artikelen, van kunststof, voor het filtreren van water bij de ingang van rioleringen',
    39269097: 'Artikelen van kunststof of van andere stoffen bedoeld bij de posten 3901 tot en met 3914, n.e.g.',
    40011000: 'natuurlijke rubberlatex, ook indien voorgevulkaniseerd',
    40012100: 'natuurlijke rubber in de vorm van gerookte vellen "smoked sheets"',
    40012200: 'natuurlijke rubber, technisch gespecificeerd "TSNR"',
    40012900: 'natuurlijke rubber, in primaire vormen of in platen, vellen of strippen (m.u.v. rubber in de vorm van gerookte vellen "smoked sheets", technisch gespecificeerde en natuurlijke rubber "TSNR" en m.u.v. natuurlijke rubberlatex, ook indien voorgevulkaniseerd)',
    40013000: 'balata, gutta-percha, guayule, chicle e.d. natuurlijke gommen, in primaire vormen of in platen, vellen of strippen (m.u.v. natuurlijke rubber, ook indien voorgevulkaniseerd)',
    40021100: 'latex van styreenbutadieenrubber "SBR" of van styreenbutadieenrubber gewijzigd door carboxylgroepen "XSBR"',
    40021910: 'Styreenbutadieenrubber verkregen door emulsiepolymerisatie [E-SBR], in balen',
    40021920: 'Styreenbutadieenstyreen-blokcopolymeren verkregen door oplossingspolymerisatie [SBS, thermoplastische elastomeren], in korrels, stukjes of poedervorm',
    40021930: 'Styreenbutdieenrubber verkregen door oplossingspolymerisatie [S-SBR], in balen',
    40021990: 'Styreenbutadieenrubber [SBR] en styreenbutadieenrubber gewijzigd door carboxylgroepen [XSBR], in primaire vormen of in platen, vellen of strippen (m.u.v. E-SBR en S-SBR in balen,  SBS, thermoplastische elastomeren in korrels, stukjes of poedervorm en m.u.v. latex)',
    40022000: 'butadieenrubber "BR" in primaire vormen of in platen, vellen of strippen',
    40023100: 'isobuteen-isopreen "butyl" rubber "IIR", in primaire vormen of in platen, vellen of strippen',
    40023900: 'gehalogeneerde isobuteen-isopreen-rubber "CIIR of BIIR", in primaire vormen of in platen, vellen of strippen',
    40024100: 'latex van chloropreen "chlorobutadieen" rubber "CR"',
    40024900: 'chloropreen "chlorobutadieen" rubber "CR", in primaire vormen of in platen, vellen of strippen (m.u.v. latex)',
    40025100: 'latex van acrylonitriel-butadieenrubber "NBR", in primaire vormen of in platen, vellen of strippen',
    40025900: 'acrylonitriel-butadieenrubber "NBR", in primaire vormen of in platen, vellen of strippen (m.u.v. latex)',
    40026000: 'isopreenrubber "IR", in primaire vormen of in platen, vellen of strippen',
    40027000: 'ethyleen-propyleen-dieen, ongeconjugeerd, "EPDM", in primaire vormen of in platen, vellen of strippen',
    40028000: 'mengsels van natuurlijke rubber, balata, gutta-percha, guayule, chicle e.d. natuurlijke gommen, met synthetische rubber of uit olie vervaardigde factis, in primaire vormen of in platen, vellen of strippen',
    40029100: 'latex van synthetische rubber (m.u.v. latex van styreenbutadieenrubber "SBR", van styreenbutadieenrubber gewijzigd door carboxylgroepen "XSBR", van butadieenrubber "BR", van isobuteen-isopreen "butyl" rubber "IIR", van gehalogeneerde isobuteen-isopreen-rubber "CIIR of BIIR", van chloropreen "chlorobutadieen" rubber "CR", acrylonitriel-butadieenrubber "NBR", van isopreen-rubber "IR" en van ethyleen-propyleen-dieenrubber, ongeconjugeerd, "EPDM")',
    40029910: 'natuurlijke rubber, gewijzigd door enting of vermenging met kunststof (m.u.v. gedepolymeriseerde natuurlijke rubber)',
    40029990: 'natuurlijke rubber, in primaire vormen of in platen, vellen of strippen (m.u.v. rubber in de vorm van gerookte vellen "smoked sheets", technisch gespecificeerde en natuurlijke rubber "TSNR", crêperubber en m.u.v. natuurlijke rubberlatex, ook indien voorgevulkaniseerd en m.u.v. natuurlijke rubber, gewijzigd door enting of vermenging met kunststof)',
    40030000: 'rubber, geregenereerd, in primaire vormen of in platen, vellen of strippen',
    40040000: 'Resten en afval, van ongeharde rubber, alsmede poeder en korrels verkregen uit resten en afval van ongeharde rubber',
    40051000: 'bereide rubber, niet-gevulkaniseerd, met toevoeging van carbonblack e.d. zwartsel of siliciumdioxide, in primaire vormen of in platen, vellen of strippen',
    40052000: 'bereide rubber, niet-gevulkaniseerd, in de vorm van oplossingen of dispersies (m.u.v. die met toevoeging van carbonblack e.d. zwartsel of siliciumdioxide; mengsels van natuurlijke rubber, balata, gutta-percha, guayule, chicle e.d. natuurlijke gommen met synthetische rubber of uit olie vervaardigde factis)',
    40059100: 'bereide rubber, niet-gevulkaniseerd, in de vorm van platen, vellen of strippen (m.u.v. die met toevoeging van carbonblack e.d. zwartsel of siliciumdioxide; mengsels van natuurlijke rubber, balata, gutta-percha, guayule, chicle e.d. natuurlijke gommen met synthetische rubber of uit olie vervaardigde factis)',
    40059900: 'bereide rubber, niet-gevulkaniseerd, in primaire vormen (m.u.v. oplossingen en dispersies; rubber met toevoeging van carbonblack e.d. zwartsel of siliciumdioxide; mengsels van natuurlijke rubber, balata, gutta-percha, guayule, chicle e.d. natuurlijke gommen met synthetische rubber of uit olie vervaardigde factis; rubber in de vorm van platen, vellen of strippen)',
    40061000: 'loopvlakrubber, ongevulkaniseerd, voor banden',
    40069000: 'staven, buizen, profielen en andere vormen uit ongevulkaniseerde rubber, ook indien vermengd (m.u.v. platen, vellen en strippen, ongesneden of enkel vierkant of rechthoekig gesneden, ook indien zij een eenvoudige oppervlaktebehandeling hebben ondergaan en m.u.v. loopvlakrubber)',
    40070000: 'draad en koord, van gevulkaniseerd rubber (m.u.v. niet-overtrokken enkelvoudige rubberdraad met een dwarsdoorsnede van > 5 mm en textielstof in verbinding met rubberdraad "b.v. met textielstof overtrokken draad en koord")',
    40081100: 'platen, vellen en strippen, van niet-geharde gevulkaniseerde rubber met celstructuur',
    40081900: 'staven en profielen, van niet-geharde gevulkaniseerde rubber met celstructuur',
    40082110: 'vloerbedekking en matten, aan het stuk, in banen of in de vorm van vierkante of rechthoekige tegels, van niet-geharde gevulkaniseerde rubber zonder celstructuur',
    40082190: 'platen, vellen en strippen, van niet-geharde gevulkaniseerde rubber zonder celstructuur (m.u.v. vloerbedekking en matten)',
    40082900: 'Staven en profielen, van niet-geharde gevulkaniseerde rubber zonder celstructuur',
    40091100: 'buizen en slangen, van niet geharde gevulkaniseerde rubber, zonder hulpstukken (m.u.v. die welke zijn versterkt of op andere wijze zijn gecombineerd met andere stoffen)',
    40091200: 'Buizen en slangen, van niet-geharde gevulkaniseerde rubber, met hulpstukken (b.v. verbindingsstukken, moffen, ellebogen, flenzen), niet versterkt of op andere wijze zijn gecombineerd met andere stoffen',
    40092100: 'buizen en slangen, van niet geharde gevulkaniseerde rubber, uitsluitend met metaal versterkt of gecombineerd, zonder hulpstukken',
    40092200: 'Buizen en slangen, van niet-geharde gevulkaniseerde rubber, uitsluitend met metaal versterkt of gecombineerd, met hulpstukken (b.v. verbindingsstukken, moffen, ellebogen, flenzen)',
    40093100: 'buizen en slangen, van niet geharde gevulkaniseerde rubber, uitsluitend met textielstoffen versterkt of gecombineerd, zonder hulpstukken',
    40093200: 'Buizen en slangen, van niet-geharde gevulkaniseerde rubber, uitsluitend met textielstoffen versterkt of gecombineerd, met hulpstukken (b.v. verbindingsstukken, moffen, ellebogen, flenzen)',
    40094100: 'buizen en slangen, van niet geharde gevulkaniseerde rubber, met andere stoffen dan metaal of textielstoffen versterkt of gecombineerd, zonder hulpstukken',
    40094200: 'Buizen en slangen, van niet-geharde gevulkaniseerde rubber, met andere stoffen dan metaal of textielstoffen versterkt of gecombineerd, met hulpstukken (b.v. verbindingsstukken, moffen, ellebogen, flenzen)',
    40101100: 'transportbanden van gevulkaniseerde rubber, uitsluitend met metaal versterkt',
    40101200: 'transportbanden van gevulkaniseerde rubber, uitsluitend met textielstof versterkt',
    40101900: 'transportbanden van gevulkaniseerde rubber (m.u.v. die welke uitsluitend met metaal of uitsluitend met textielstof zijn versterkt)',
    40103100: 'eindeloze drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met trapeziumvormige dwarsdoorsnede "V-snaren", gegroefd, met een buitenomtrek van > 60 doch <= 180 cm',
    40103200: 'eindeloze drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met trapeziumvormige dwarsdoorsnede "V-snaren", met een buitenomtrek van > 60 doch <= 180 cm (niet gegroefd)',
    40103300: 'eindeloze drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met trapeziumvormige dwarsdoorsnede "V-snaren", gegroefd, met een buitenomtrek van > 180 doch <= 240 cm',
    40103400: 'eindeloze drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met trapeziumvormige dwarsdoorsnede "V-snaren", met een buitenomtrek van > 180 doch <= 240 cm (niet gegroefd)',
    40103500: 'eindeloze synchroon drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met een buitenomtrek van > 60 doch <= 150 cm',
    40103600: 'eindeloze synchroon drijfriemen en drijfsnaren, van gevulkaniseerde rubber, met een buitenomtrek van > 150 doch <= 198 cm',
    40103900: 'drijfriemen en drijfsnaren, van gevulkaniseerde rubber (m.u.v. eindeloze drijfriemen en drijfsnaren, met trapeziumvormige dwarsdoorsnede "V-snaren", met een buitenomtrek van > 60 doch <= 240 cm en eindeloze synchroon drijfriemen en drijfsnaren, met een buitenomtrek van > 60 doch <= 198 cm)',
    40111000: 'luchtbanden van rubber, nieuw, van de soort gebruikt voor personenauto\'s, incl. die van het type "station-wagon" of "break" en racewagens',
    40112010: 'luchtbanden van rubber, nieuw, van de soort gebruikt voor autobussen of voor vrachtwagens, met een belastingsindex van <= 121',
    40112090: 'luchtbanden van rubber, nieuw, van de soort gebruikt voor autobussen of voor vrachtwagens, met een belastingsindex van > 121',
    40113000: 'Luchtbanden van rubber, nieuw, van de soort gebruikt voor luchtvaartuigen',
    40114000: 'Luchtbanden van rubber, nieuw, van de soort gebruikt voor motorrijwielen',
    40115000: 'luchtbanden van rubber, nieuw, van de soort gebruikt voor rijwielen',
    40117000: 'Nieuwe luchtbanden van rubber,  van de soort gebruikt voor voertuigen of machines voor land- en bosbouw',
    40118000: 'Nieuwe luchtbanden van rubber,  van de soort gebruikt voor voertuigen of machines voor mijn-, weg- en waterbouw of voor industriële goederenbehandeling',
    40119000: 'Nieuwe luchtbanden van rubber (m.u.v. die van de soort gebruikt voor voertuigen of machines voor land- en bosbouw, voor mijn-, weg- en waterbouw of voor industriële goederenbehandeling, m.u.v. die voor personenwagens, station-wagens, racewagens, autobussen, vrachtwagens, en m.u.v. die voor luchtvaartuigen, motorrijwielen en rijwielen)',
    40121100: 'luchtbanden van rubber, van een nieuw loopvlak voorzien, van de soort gebruikt voor personenauto\'s, incl. die van het type "station-wagon" of "break" en racewagens',
    40121200: 'luchtbanden van rubber, van een nieuw loopvlak voorzien, van de soort gebruikt voor autobussen of voor vrachtwagens',
    40121300: 'Luchtbanden van rubber, van een nieuw loopvlak voorzien, van de soort gebruikt voor luchtvaartuigen',
    40121900: 'van een nieuw loopvlak voorziene luchtbanden van rubber (m.u.v. die van de soort gebruikt voor personenauto\'s, die van het type "station-wagon" of "break" en racewagens daaronder begrepen, autobussen, vrachtwagens en luchtvaartuigen)',
    40122000: 'Luchtbanden van rubber, gebruikt',
    40129020: 'massieve of halfmassieve banden, van rubber',
    40129030: 'loopvlakken voor banden, van rubber',
    40129090: 'velglinten, van rubber',
    40131000: 'Binnenbanden, van rubber, van de soort gebruikt voor personenauto\'s, incl. die van het type "station-wagon" of "break" en racewagens, voor autobussen of voor vrachtwagens',
    40132000: 'binnenbanden, van rubber, van de soort gebruikt voor rijwielen',
    40139000: 'binnenbanden, van rubber (m.u.v. die van de soort gebruikt voor personenauto\'s, incl. die van het type "station-wagon" of "break" en racewagens, voor autobussen, voor vrachtwagens en voor rijwielen)',
    40141000: 'preservatieven, van niet-geharde gevulkaniseerde rubber',
    40149000: 'Hygiënische en farmaceutische artikelen, incl. spenen,  van niet-geharde gevulkaniseerde rubber, ook indien met delen van geharde rubber, n.e.g. (m.u.v. preservatieven en kleding, handschoenen en kledingtoebehoren, voor alle doeleinden)',
    40151100: 'handschoenen van niet-geharde gevulkaniseerde rubber, voor chirurgisch gebruik (m.u.v. vingerlingen)',
    40151900: 'Handschoenen [met of zonder vingers] en wanten, van niet-geharde gevulkaniseerde rubber (m.u.v. handschoenen voor chirurgisch gebruik)',
    40159000: 'kleding en kledingtoebehoren, van niet-geharde gevulkaniseerde rubber, ongeacht het gebruik daarvan (m.u.v. schoeisel en hoofddeksels alsmede delen daarvan, en handschoenen [met of zonder vingers] en wanten)',
    40161000: 'Artikelen van niet-geharde gevulkaniseerde rubber met celstructuur, n.e.g',
    40169100: 'vloerbedekking en matten, van niet-geharde gevulkaniseerde rubber, voorzien van afgeschuinde of opgewerkte randen, van afgeronde hoeken, van opengewerkte boorden of anders bewerkt (m.u.v. die enkel vierkant of rechthoekig gesneden en die van rubber met celstructuur)',
    40169200: 'vlakgom van niet-geharde gevulkaniseerde rubber, gebruiksklaar (m.u.v. die welke enkel vierkant of rechthoekig gesneden is)',
    40169300: 'Pakking- en sluitringen, van niet-geharde gevulkaniseerde rubber, (m.u.v. die van rubber met celstructuur)',
    40169400: 'stootkussens voor schepen, ook indien opblaasbaar, van niet-geharde gevulkaniseerde rubber (m.u.v. die van rubber met celstructuur)',
    40169500: 'luchtmatrassen, luchtkussens en andere opblaasbare artikelen, van niet-geharde gevulkaniseerde rubber (m.u.v. stootkussens, boten, vlotten en andere drijvende inrichtingen en hygiënische en farmaceutische artikelen)',
    40169952: 'Artikelen van niet-geharde gevulkaniseerde rubber met metaal, van de soort gebruikt voor motorvoertuigen bedoeld bij de posten 8701 tot en met 8705 (m.u.v. artikelen van rubber met celstructuur)',
    40169957: 'Artikelen van niet-geharde gevulkaniseerde rubber, van de soort gebruikt voor motorvoertuigen bedoeld bij de posten 8701 tot en met 8705, n.e.g. (m.u.v. artikelen van rubber met celstructuur en artikelen van rubber met metaal)',
    40169991: 'Artikelen van niet-geharde gevulkaniseerde rubber met metaal (m.u.v. artikelen van rubber met celstructuur en die van de soort gebruikt voor motorvoertuigen bedoeld bij de posten 8701 tot en met 8705)',
    40169997: 'Artikelen van niet-geharde gevulkaniseerde rubber, n.e.g. (m.u.v. artikelen van rubber met celstructuur)',
    40170000: 'Geharde rubber, b.v. eboniet, in ongeacht welke vorm, incl. resten en afval; artikelen van geharde rubber, n.e.g.',
    41012010: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard, niet gesplit, wegende per stuk <= 16 kg, vers',
    41012030: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard, niet gesplit, wegende per stuk <= 16 kg, nat gezouten',
    41012050: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard, niet gesplit, wegende per stuk, indien enkel gedroogd <= 8 kg of indien droog gezouten <= 10 kg',
    41012080: 'Gehele huiden en vellen van runderen "buffels daaronder begrepen", ook indien onthaard, ongesplit, wegende per stuk <= 16 kg, gekalkt, gepekeld "pickled" of anderszins geconserveerd (m.u.v. die welke vers, nat gezouten, enkel gedroogd of droog gezouten zijn en m.u.v. gelooide, tot perkament verwerkte huiden en vellen)',
    41015010: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard of gesplit, wegende per stuk > 16 kg, vers',
    41015030: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard of gesplit, wegende per stuk > 16 kg, nat gezouten',
    41015050: 'gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard of gesplit, wegende per stuk > 16 kg, gedroogd of droog gezouten',
    41015090: 'Gehele huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard of gesplit, wegende per stuk > 16 kg, gekalkt, gepekeld "pickled" of anderszins geconserveerd (m.u.v. die welke vers, nat gezouten, enkel gedroogd of droog gezouten zijn en m.u.v. gelooide, tot perkament verwerkte huiden en vellen)',
    41019000: 'croupons, halve croupons en flanken, alsmede andere huiden en vellen, van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, ongelooid, ook indien onthaard of gesplit, vers, gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd; gehele huiden en vellen, ongelooid, wegende per stuk, indien enkel gedroogd > 8 kg doch < 16 kg of indien droog gezouten > 10 kg doch < 16 kg (m.u.v. gelooide, tot perkament verwerkte of verder bewerkte huiden en vellen)',
    41021010: 'Huiden en vellen van lammeren, ongelooid, met het haar, vers of gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd (m.u.v. die van van Astrachan-, Breitschwanz-, Karakul-, Persianer e.d. lammeren of van Indische, Chinese, Mongoolse en Tibetaanse lammeren en m.u.v. gelooide, tot perkament verwerkte huiden en vellen)',
    41021090: 'Huiden en vellen van schapen, ongelooid, met het haar, vers of gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd (m.u.v. die van lammeren en m.u.v. gelooide, tot perkament verwerkte huiden en vellen)',
    41022100: 'huiden en vellen van schapen, ongelooid, onthaard, gepekeld "pickled", ook indien gesplit',
    41022900: 'Huiden en vellen van schapen, ongelooid, onthaard, vers of gezouten, gedroogd, gekalkt of anderszins geconserveerd, ook indien gesplit (m.u.v. gepekelde of tot perkament verwerkte of verder bewerkte huiden en vellen)',
    41032000: 'Huiden en vellen van reptielen, ongelooid, vers of gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd, ook indien gesplit (m.u.v. tot perkament verwerkte of verder bewerkte huiden en vellen)',
    41033000: 'Huiden en vellen, ongelooid, van varkens, vers of gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd, ook indien onthaard of gesplit (m.u.v. tot perkament verwerkte huiden en vellen)',
    41039000: 'Huiden en vellen, ongelooid, vers of gezouten, gedroogd, gekalkt, gepekeld "pickled" of anderszins geconserveerd, ook indien onthaard of gesplit, andere dan die bij aantekening 1(b) of 1(c) op dit hoofdstuk  (m.u.v. tot perkament verwerkte huiden en vellen, die van runderen "buffels daaronder begrepen", van paarden en van paardachtigen, van schapen, van reptielen en van varkens)',
    41041110: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in vochtige staat, "wet blue daaronder begrepen", van huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m², gelooid en onthaard (m.u.v. verder bewerkt leder)',
    41041151: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in vochtige staat, "wet blue daaronder begrepen", van huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m², gelooid en onthaard (m.u.v. verder bewerkt leder)',
    41041159: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in vochtige staat, "wet blue daaronder begrepen", van huiden en vellen van runderen "buffels daaronder begrepen", gelooid en onthaard (m.u.v. verder bewerkt leder en gehele huiden en vellen)',
    41041190: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in vochtige staat, "wet blue daaronder begrepen", van huiden en vellen van paarden of van paardachtigen, gelooid en onthaard (m.u.v. verder bewerkt leder)',
    41041910: 'gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m², in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41041951: 'gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m², in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41041959: 'huiden en vellen van runderen "buffels daaronder begrepen", in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, gehele huiden en vellen, en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41041990: 'huiden en vellen van paarden of van paardachtigen, in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, en met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41044111: 'kips van Indische runderen, met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, onthaard, in gehele staat, ook indien zonder kop en zonder poten, in droge staat "crust", met een oppervlakte van <= 2,6 m² "28 vierkante voed", wegende per stuk <= 4,5 kg, enkel met plantaardige stoffen voorgelooid of gelooid, ook indien verder bewerkt, maar als zodanig onbruikbaar voor het vervaardigen van werken van leder',
    41044119: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in droge staat "crust", van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed", onthaard (m.u.v. verder bewerkt leder en kips van Indische runderen, bedoeld bij onderverdeling 4104.41.11)',
    41044151: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in droge staat "crust", van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m² "28 vierkante voed", onthaard (m.u.v. verder bewerkt leder en kips van Indische runderen, bedoeld bij onderverdeling 4104.41.11)',
    41044159: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in droge staat "crust", van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m² "28 vierkante voed", onthaard (m.u.v. verder bewerkt leder en kips van Indische runderen, bedoeld bij onderverdeling 4104.41.11)',
    41044190: 'leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, in droge staat "crust", van huiden en vellen van paarden of van paardachtigen, onthaard (m.u.v. verder bewerkt leder)',
    41044911: 'kips van Indische runderen, onthaard, in gehele staat, ook indien zonder kop en zonder poten, in droge staat "crust", met een oppervlakte van <= 2,6 m² "28 vierkante voed", wegende per stuk <= 4,5 kg, enkel met plantaardige stoffen voorgelooid of gelooid, ook indien verder bewerkt, maar als zodanig onbruikbaar voor het vervaardigen van werken van leder (m.u.v. leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41044919: 'gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed", in droge staat "crust", onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant, en kips van Indische runderen, bedoeld bij onderverdeling 4104.49.11)',
    41044951: 'gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m² "28 vierkante voed", in droge staat "crust", onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41044959: 'huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van > 2,6 m² "28 vierkante voed", in droge staat "crust", onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, gehele huiden en vellen, en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41044990: 'huiden en vellen van paarden of van paardachtigen, in droge staat "crust", onthaard, ook indien gesplit (m.u.v. niet verder bewerkte huiden en vellen, en leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant)',
    41051000: 'schapenleder, in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. verder bewerkte en enkel voorgelooide huiden en vellen)',
    41053010: 'huiden en vellen van Indische bastaards, in droge staat "crust", onthaard, met plantaardige stoffen voorgelooid, ook indien verder bewerkt, maar als zodanig onbruikbaar voor het vervaardigen van werken van leder',
    41053090: 'schapenleder, in droge staat "crust", onthaard, ook indien gesplit (m.u.v. verder bewerkte en enkel voorgelooide huiden en vellen en m.u.v. huiden en vellen van Indische bastaards, met plantaardige stoffen voorgelooid, ook indien verder bewerkt, maar als zodanig onbruikbaar voor het vervaardigen van werken van leder)',
    41062100: 'leder van geiten, in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard, ook indien gesplit (m.u.v. verder bewerkte en enkel voorgelooide vellen)',
    41062210: 'huiden en vellen van Indische geiten, in droge staat ["crust"], onthaard, met plantaardige stoffen voorgelooid, ook indien verder bewerkt, maar als zodanig onbruikbaar voor het vervaardigen voor werken van leder',
    41062290: 'huiden en vellen van geiten, in droge staat ["crust"], onthaard, ook indien gesplit (m.u.v. verder bewerkte en enkel voorgelooide vellen en met plantaardige stoffen voorgelooide huiden en vellen van Indische geiten bedoeld bij onderverdeling 4106.22.10)',
    41063100: 'Leder van varkens, ook indien gesplit,  in vochtige staat "wet blue daaronder begrepen", gelooid, onthaard (m.u.v. verder bewerkte en enkel voorgelooide huiden en vellen)',
    41063200: 'Leder van varkens, ook indien gesplit, in droge staat ["crust"], onthaard (m.u.v. verder bewerkte en enkel voorgelooide huiden en vellen)',
    41064010: 'huiden en vellen van reptielen, enkel met plantaardige stoffen voorgelooid',
    41064090: 'gelooide huiden en vellen van reptielen, ook indien gedroogd en ook indien gesplit (m.u.v. verder bewerkte en enkel met plantaardige stoffen voorgelooide huiden en vellen)',
    41069100: 'huiden en vellen van antilopen, van reeën, van elanden, van olifanten en van andere dieren, zeedieren daaronder begrepen, onthaard en leder van onbehaarde dieren, in vochtige staat "wet blue daaronder begrepen", gelooid, ook indien gesplit (m.u.v. die van runderen, van paarden, van paardachtigen, van schapen, van geiten, van varkens en van reptielen, en m.u.v. enkel voorgelooide huiden, vellen en leder)',
    41069200: 'huiden en vellen van antilopen, van reeën, van elanden, van olifanten en van andere dieren, zeedieren daaronder begrepen, onthaard en leder van onbehaarde dieren, in droge staat "crust", ook indien gesplit (m.u.v. die van runderen, van paarden, van paardachtigen, van schapen, van geiten, van varkens en van reptielen, en m.u.v. enkel voorgelooide huiden, vellen en leder)',
    41071111: 'boxcalf van leder, met natuurlijke nerf, ongesplit, van gehele huiden en vellen van kalveren, met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk',
    41071119: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk, met natuurlijke nerf, ongesplit, onthaard (m.u.v. boxcalf, zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071190: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, met natuurlijke nerf, ongesplit, onthaard (m.u.v. die met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk en m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071211: 'boxcalf van leder, gesplit, met de nerfkant, van gehele huiden en vellen van kalveren, met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk',
    41071219: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk, gesplit, met de nerfkant, onthaard (m.u.v. boxcalf, zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071291: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", gesplit, met de nerfkant, onthaard (m.u.v. die van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk en m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071299: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van paarden of van paardachtigen, gesplit, met de nerfkant, onthaard (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071910: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk, onthaard (m.u.v. leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant en m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41071990: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van gehele huiden en vellen van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, onthaard (m.u.v. die van runderen "buffels daaronder begrepen", met een oppervlakte van <= 2,6 m² "28 vierkante voed" per stuk, m.u.v. leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant en m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079110: 'zoolleder dat na het looien of het drogen verder is bewerkt, alsmede voor zoolleder bestemde tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, met natuurlijke nerf, ongesplit, onthaard (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079190: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van runderen "buffels daaronder begrepen", van paarden of van paardachtigen, met natuurlijke nerf, ongesplit, onthaard (m.u.v. zoolleder, zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079210: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van runderen "buffels daaronder begrepen", gesplit, met de nerfkant,  onthaard (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079290: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van paarden of van paardachtigen, gesplit, met de nerfkant, onthaard (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079910: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van runderen "buffels daaronder begrepen", onthaard (m.u.v. leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant en m.u.v. zoolleder, zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41079990: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van halve vellen of van andere delen van huiden en vellen van paarden of van paardachtigen, onthaard (m.u.v. leder met natuurlijke nerf, ongesplit, dan wel gesplit, met de nerfkant en m.u.v. zoolleder, zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41120000: 'Leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van schapen, onthaard, ook indien gesplit (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41131000: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van geiten, onthaard, ook indien gesplit (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41132000: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van varkens, onthaard, ook indien gesplit (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41133000: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van reptielen, ook indien gesplit (m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41139000: 'leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van antilopen, van reeën, van elanden, van olifanten en van andere dieren, zeedieren daaronder begrepen, onthaard, en leder dat na het looien of het drogen verder is bewerkt, alsmede tot perkament verwerkte huiden en vellen, van niet-behaarde dieren, ook indien gesplit (m.u.v. dat van runderen, paarden of paardachtigen, schapen, geiten, varkens en reptielen, en m.u.v. zeemleder, lakleder, gelamineerd lakleder en gemetalliseerd leder)',
    41141010: 'zeemleder, incl. gecombineerd gelooid zeemleder, van schapen (m.u.v. glacé gelooid leder, dat achteraf met formaldehyde is behandeld en leder dat na het looien enkel is gevet)',
    41141090: 'zeemleder, incl. gecombineerd gelooid zeemleder (m.u.v. dat van schapen en m.u.v. glacé gelooid leder, dat achteraf met formaldehyde is behandeld en leder dat na het looien enkel is gevet)',
    41142000: 'lakleder, gelamineerd lakleder en gemetalliseerd leder (m.u.v. gelakt of gemetalliseerd kunstleder)',
    41151000: 'kunstleder op basis van leder of van ledervezels, in platen, bladen, vellen of strippen, ook indien op rollen',
    41152000: 'Snippers en ander afval van leder of van kunstleder, onbruikbaar voor de vervaardiging van lederwaren; stof en poeder van leder, ledermeel',
    42010000: 'zadel- en tuigmakerswerk voor dieren "strengen, leibanden, kniestukken, muilbanden, zadeldekken, zadeltassen, hondendekken e.d. artikelen daaronder begrepen", ongeacht de stof waarvan het vervaardigd is (m.u.v. leibanden voor kinderen, alsmede rijzwepen en andere artikelen bedoeld bij post 6602)',
    42021110: 'documentenkoffertjes, aktetassen, school- en boekentassen, alsmede dergelijke bergingsmiddelen met een buitenkant van leder, van kunstleder of van lakleder',
    42021190: 'reiskoffers en valiezen, koffers voor toiletbenodigdheden, alsmede dergelijke koffers met een buitenkant van leder, van kunstleder of van lakleder (m.u.v. documentenkoffertjes)',
    42021211: 'documentenkoffertjes, aktetassen, school- en boekentassen, alsmede dergelijke bergingsmiddelen, met een buitenkant van kunststof in vellen',
    42021219: 'Reiskoffers en valiezen, koffers voor toiletbenodigdheden koffers met een buitenkant van kunststof in vellen (m.u.v. documentenkoffertjes)',
    42021250: 'reiskoffers en valiezen, koffers voor toiletbenodigdheden, documentenkoffertjes, aktetassen, school- en boekentassen, alsmede dergelijke bergingsmiddelen met een buitenkant van gevormde kunststof',
    42021291: 'documentenkoffertjes, aktetassen, school- en boekentassen, alsmede dergelijke bergingsmiddelen, met een buitenkant van kunststof, incl. vulkanfiber of van textiel (m.u.v. die van kunststof in vellen of van gevormde kunststof)',
    42021299: 'reiskoffers en valiezen, koffers voor toiletbenodigdheden, e.d. koffers met een buitenkant van kunststof of van textiel (m.u.v. die van kunststof in vellen of van gevormde kunststof en m.u.v. documentenkoffertjes)',
    42021910: 'reiskoffers en valiezen, koffers voor toiletbenodigdheden, documentenkoffertjes, aktetassen, school- en boekentassen, alsmede dergelijke bergingsmiddelen met een buitenkant van aluminium',
    42021990: 'reiskoffers en valiezen, koffers voor toiletbenodigdheden, documentenkoffertjes, aktetassen, school- en boekentassen e.d. bergingsmiddelen (m.u.v. die met een buitenkant van leder, van kunstleder, van lakleder, van kunststof, van textiel of van aluminium)',
    42022100: 'handtassen, ook indien met schouderband, incl. die zonder handvatten, met een buitenkant van leder, van kunstleder of van lakleder',
    42022210: 'handtassen, ook indien met schouderband, incl. die zonder handvatten, met een buitenkant van kunststof in vellen',
    42022290: 'handtassen, ook indien met schouderband, incl. die zonder handvatten, met een buitenkant van textiel',
    42022900: 'handtassen, ook indien met schouderband, incl. die zonder handvatten, met een buitenkant van vulkanfiber of van karton, dan wel geheel of voor het grootste deel met deze stoffen of met papier bekleed',
    42023100: 'portefeuilles, portemonnaies, sleutelzakjes, sigarettenkokers, tabakszakken e.d. in de zak of in een handtas meegedragen artikelen, met een buitenkant van leder, van kunstleder of van lakleder',
    42023210: 'portefeuilles, portemonnaies, sleutelzakjes, sigarettenkokers, tabakszakken e.d. in de zak of in een handtas meegedragen artikelen, met een buitenkant van kunststof in vellen',
    42023290: 'portefeuilles, portemonnaies, sleutelzakjes, sigarettenkokers, tabakszakken e.d. in de zak of in een handtas meegedragen artikelen, met een buitenkant van textiel',
    42023900: 'portefeuilles, portemonnaies, sleutelzakjes, sigarettenkokers, tabakszakken e.d. in de zak of in de handtas meegedragen artikelen, met een buitenkant van vulkanfiber of van karton, dan wel geheel of voor het grootste deel met deze stoffen of met papier bekleed, incl. brillenkokers van gevormde kunststof',
    42029110: 'reiszakken, toiletzakken, rugzakken en sporttassen, met een buitenkant van leder, van kunstleder of van lakleder',
    42029180: 'isothermische zakken voor voedsel of voor dranken, boodschappen-, kaarten- en gereedschapstassen, juwelendoosjes, etuis en dergelijke voor messenmakerswerk, kijkers, camera\'s, wapens of muziekinstrumenten, alsmede dergelijke bergingsmiddelen, met een buitenkant van leder, van kunstleder of van lakleder (m.u.v. koffers, aktetassen, schooltassen en dergelijke bergingsmiddelen, handtassen en in de zak of in de handtas meegedragen artikelen, reis-, toilet- en rugzakken en sporttassen)',
    42029211: 'reiszakken, toiletzakken, rugzakken en sporttassen, met een buitenkant van kunststof in vellen',
    42029215: 'bergingsmiddelen voor muziekinstrumenten, met een buitenkant van kunststof in vellen',
    42029219: 'isothermische zakken voor voedsel of voor dranken, boodschappen-, kaarten- en gereedschapstassen, juwelendoosjes, etuis e.d. voor messenmakerswerk, kijkers, camera\'s of wapens, alsmede dergelijke bergingsmiddelen, met een buitenkant van kunststof in vellen (m.u.v. koffers, aktetassen, schooltassen en dergelijke bergingsmiddelen, handtassen en in de zak of in de handtas meegedragen artikelen, reis-, toilet- en rugzakken, sporttassen en bergingsmiddelen voor muziekinstrumenten)',
    42029291: 'reiszakken, toiletzakken, rugzakken en sporttassen, met een buitenkant van textiel',
    42029298: 'isothermische zakken voor voedsel of voor dranken, boodschappen-, kaarten- en gereedschapstassen, juwelendoosjes, etuis e.d., voor messenmakerswerk, kijkers, camera\'s, wapens of muziekinstrumenten, alsmede dergelijke bergingsmiddelen, met een buitenkant van textiel (m.u.v. koffers, aktetassen, schooltassen en dergelijke bergingsmiddelen, handtassen en in de zak of in de handtas meegedragen artikelen, reis , toilet- en rugzakken en sporttassen)',
    42029900: 'reiszakken, boodschappen- en gereedschapstassen, juwelendoosjes, etuis e.d. voor messenmakerswerk, met een buitenkant van vulkanfiber of van karton en etuis voor kijkers, camera\'s, wapens of muziekinstrumenten, e.d. (m.u.v. die met een buitenkant van leder, kunstleder, lakleder, textiel of kunststof in vellen en m.u.v. koffers, aktetassen, schooltassen e.d., handtassen en in de zak of handtas meegedragen artikelen)',
    42031000: 'kleding van leder of van kunstleder (m.u.v. kledingtoebehoren, schoeisel en hoofddeksels en delen daarvan, alsmede scheenbeschermers, schermmaskers e.d. sportuitrustingen als bedoeld in hoofdtuk 95)',
    42032100: 'handschoenen [met of zonder vingers] en wanten, speciaal ontworpen voor sportbeoefening, van leder of van kunstleder',
    42032910: 'werkhandschoenen en -wanten, van leder of van kunstleder',
    42032990: 'Handschoenen [met of zonder vingers] en wanten, van leder of van kunstleder (m.u.v. die welke speciaal zijn ontworpen voor sportbeoefening en m.u.v. werkhandschoenen en -wanten)',
    42033000: 'gordels, koppelriemen en draagriemen, van leder of van kunstleder',
    42034000: 'kledingtoebehoren van leder of van kunstleder (m.u.v. handschoenen [met of zonder vingers] en wanten, gordels, koppelriemen en draagriemen, schoeisel en hoofddeksels en delen daarvan en m.u.v. scheenbeschermers, schermmaskers e.d. sportuitrusting als bedoeld in hoofdstuk 95)',
    42050011: 'drijfriemen, drijfsnaren en transportbanden, van leder of van kunstleder',
    42050019: 'artikelen voor technisch gebruik, van leder of van kunstleder (m.u.v. drijfriemen, drijfsnaren en transportbanden)',
    42050090: 'werken van leder of van kunstleder, n.e.g. (m.u.v. zadel- en tuigmakerswerk; reiskoffers en dergelijke bergingsmiddelen; kleding en kledingtoebehoren; zwepen, rijzwepen en andere artikelen bedoeld bij post 6602; meubelen; verlichtingsartikelen; speelgoed, spellen en sportartikelen; knopen en delen daarvan; machetknopen, armbanden en andere fancybijouterieën; geconfectioneerde artikelen bedoeld bij post 5608; en artikelen van vlechtstoffen)',
    42060000: 'werken van darmen, van goudvlies "goudslagershuidjes", van blazen of van pezen (m.u.v. steriel catgut e.d. steriele hechtmiddelen voor chirugisch gebruik en m.u.v. snaren voor muziekinstrumenten)',
    43011000: 'ongelooide pelterijen van nertsen, in gehele vellen, ook indien ontdaan van kop, staart of poten (m.u.v. bereide pelterijen)',
    43013000: 'ongelooide pelterijen van lammeren van de soorten Astrakan, Breitschwanz, Karakoel, Persianer e.d., alsmede van Indische, Chinese, Mongoolse of Tibetaanse lammeren, in gehele vellen, ook indien ontdaan van kop, staart of poten (m.u.v. bereide pelterijen)',
    43016000: 'ongelooide pelterijen van vossen, in gehele vellen, ook indien ontdaan van kop, staart of poten (m.u.v. bereide pelterijen)',
    43018000: 'ongelooide pelterijen, in gehele vellen, ook indien ontdaan van kop, staart of poten (m.u.v. die van nertsen, van lammeren van de soorten Astrakan, Breitschwanz, Karakoel, Persianer e.d., van Indische, Chinese, Mongoolse of Tibetaanse lammeren of van vossen)',
    43019000: 'koppen, staarten, poten en ander voor bontwerk geschikte delen van pelterijen, ongelooid (m.u.v. bereide pelterijen)',
    43021100: 'pelterijen van nertsen, gelooid of anderszins bereid, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021915: 'pelterijen van bevers, van muskusratten of van vossen in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021935: 'pelterijen van konijnen of van hazen, gelooid of anderszins bereid, in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021941: 'pelterijen van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks", in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021949: 'pelterijen van zeehonden of van oorrobben, in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. die van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks" en m.u.v. samengevoegde pelterijen)',
    43021975: 'pelterijen van lammeren van de soorten Astrakan, Breitschwanz, Karakoel, Persianer e.d., alsmede van Indische, Chinese, Mongoolse of Tibetaanse lammeren, gelooid of anderszins bereid, in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021980: 'pelterijen van schapen, gelooid of anderszins bereid, in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. samengevoegde pelterijen)',
    43021999: 'pelterijen, gelooid of anderszins bereid, in gehele vellen, ook indien zonder kop, staart of poten (m.u.v. die van nertsen, konijnen, hazen, lammeren van de soorten Astrakan, Breitschwanz, Karakoel, Persianer e.d., Indische, Chinese, Mongoolse en Tibetaanse lammeren, bevers, muskusratten, vossen, zeehonden, oorrobben of schapen en m.u.v. samengevoegde pelterijen)',
    43022000: 'koppen, staarten, poten en andere delen, alsmede afvallen, van gelooide of anderszins bereide pelterijen (m.u.v. samengevoegde pelterijen)',
    43023010: 'uitgelaten vellen, gelooid of anderszins bereid',
    43023025: 'pelterijen van konijnen of van hazen, gelooid of anderszins bereid, samengevoegd zonder toevoeging van andere materialen (m.u.v. uitgelaten vellen en m.u.v. kleding, kledingtoebehoren en andere artikelen van bont)',
    43023051: 'pelterijen van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks", gelooid of anderszins bereid, samengevoegd zonder toevoeging van andere materialen (m.u.v. uitgelaten vellen en m.u.v. kleding, kledingtoebehoren en andere artikelen van bont)',
    43023055: 'pelterijen van zeehonden of van oorrobben, gelooid of anderszins bereid, samengevoegd zonder toevoeging van andere materialen (m.u.v. die van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks", m.u.v. uitgelaten vellen en m.u.v. kleding, kledingtoebehoren en andere artikelen van bont)',
    43023099: 'pelterijen, gelooid of anderszins bereid, samengevoegd zonder toevoeging van andere materialen (m.u.v. die van konijnen, hazen, zeehonden, oorrobben, m.u.v. uitgelaten vellen en m.u.v. kleding, kledingtoebehoren en andere artikelen van bont)',
    43031010: 'kleding en kledingtoebehoren, van pelterijen van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks" (m.u.v. handschoenen van leder en bont, en m.u.v. schoeisel en hoofddeksels en delen daarvan)',
    43031090: 'kleding en kledingtoebehoren, van bont (m.u.v. die van pelterijen van zadelrobjongen "whitecoats" of van klapmutsjongen "bluebacks" en m.u.v. handschoenen van leder en bont, schoeisel en hoofddeksels en delen daarvan)',
    43039000: 'artikelen van bont (m.u.v. kleding en kledingtoebehoren en m.u.v. speelgoed en sportartikelen als bedoeld in hoofdstuk 95)',
    43040000: 'namaakbont en artikelen van namaakbont (m.u.v. handschoenen van leder en namaakbont, schoeisel en hoofddeksels en delen daarvan en m.u.v. speelgoed en sportartikelen als bedoeld in hoofdstuk 95)',
    44011100: 'brandhout, in de vorm van ronde en andere blokken, rijshout, takkenbossen e.d., naaldhout',
    44011200: 'brandhout, in de vorm van ronde en andere blokken, rijshout, takkenbossen e.d., niet-naaldhout',
    44012100: 'naaldhout in plakjes, spanen of kleine stukjes (m.u.v. die van de soort die hoofdzakelijk wordt gebruikt voor het verven of voor het looien)',
    44012200: 'hout in plakjes, spanen of kleine stukjes (m.u.v. die van de soort die hoofdzakelijk gebruikt wordt voor het verven of voor het looien en m.u.v. naaldhout)',
    44013100: 'Houtpellets',
    44013900: 'Zaagsel, resten en afval, van hout, geperst tot blokken, briketten of dergelijke vormen (m.u.v. pellets)',
    44014010: 'Zaagsel, van hout, niet geperst',
    44014090: 'Resten en afval, van hout, niet geperst (m.u.v. zaagsel)',
    44021000: 'houtskool van bamboe, incl. houtskool uit schalen van vruchten of van noten, ook indien samengeperst (m.u.v. houtskool als geneesmiddel, met wierook vermengde houtskool, actieve houtskool en tekenkool)',
    44029000: 'houtskool, incl. houtskool uit schalen van vruchten of van noten, ook indien samengeperst (m.u.v. houtskool van bamboe, houtskool als geneesmiddel, met wierook vermengde houtskool, actieve houtskool en tekenkool)',
    44031100: 'Onbewerkt hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen, naaldhout (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.)',
    44031200: 'onbewerkt hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen, niet-naaldhout (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.)',
    44032110: 'zaaghout en hout bestemd om te worden gesneden of geschild, van dennenhout van de soort "Pinus spp.", met een dwarsdoorsnede van =>15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd',
    44032190: 'Onbewerkt hout, van dennenhout van de soort "Pinus spp.", met een dwarsdoorsnede van =>15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. zaaghout en hout bestemd om te worden gesneden of geschild;  hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44032200: 'Onbewerkt dennenhout van de soort"Pinnus spp.",met een dwarsdoorsnede van < 15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v.ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44032310: 'zaaghout en hout bestemd om te worden gesneden of geschild, van de zilverspar "Abies spp." en sparhout "Picea spp.", met een dwarsdoorsnede van => 15 cm, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd',
    44032390: 'Onbewerkt hout van de zilverspar "Abies spp." en sparhout "Picea spp.", met een dwarsdoorsnede van => 15 cm, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. zaaghout en hout bestemd om te worden gesneden en geschild, hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44032400: 'Zilverspar "Abies spp." en sparhout "Picea spp." onbewerkt, met een dwarsdoorsnede van < 15 cm, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44032510: 'zaaghout en hout bestemd om te worden gesneden of geschild, van naaldhout , met een dwarsdoorsnede van =>15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. dennenhout, zilverspar en sparhout; m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44032590: 'Onbewerkt naaldhout , met een dwarsdoorsnede van =>15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. dennenhout, zilverspar en sparhout; zaaghout en hout bestemd om te worden gesneden of geschild; m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44032600: 'Onbewerkt naaldhout,met een dwarsdoorsnede van < 15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. dennenhout, zilverspar en sparhout; m.u.v.ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d.; m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz.;m.u.v. hout, behandeld met verf, met creosoot of met andere conserveringsmiddelen)',
    44034100: 'dark red meranti, light red meranti en meranti bakau, onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, voor paraplu\'s, voor gereedschapsstelen e.d., hout gezaagd in de vorm van planken of balken enz. en met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44034910: 'sapelli, acajou d\'Afrique en iroko, onbewerkt ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, parapluies, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz. en m.u.v. met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44034935: 'Okoumé en sipo, onbewerkt ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz. en m.u.v. met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44034985: 'Tropisch hout, onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v.  acajou d\'Afrique, iroko, sapelli, okoume en sipo;ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d.; hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039100: 'Eik "Quercus spp.", onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d.; hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039300: 'Beukenhout  "Fagus spp.", onbewerkt,met een dwarsdoorsnede van =>15 cm, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v.  hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039400: 'Beukenhout  "Fagus spp.", onbewerkt,met een dwarsdoorsnede van < 15 cm, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d.; m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039510: 'zaaghout en hout bestemd om te worden gesneden of geschild, van berkenhout (Betula spp.), met een dwarsdoorsnede van => 15 cm, onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd',
    44039590: 'Berkenhout "Betula spp.", onbewerkt, met een dwarsdoorsnede van => 15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. zaaghout en hout bestemd om te worden gesneden of geschild, hout gezaagd in de vorm van dwarsliggers en wisselhouten of van  planken of balken enz. en met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039600: 'Berkenhout (Betula spp.), onbewerkt,met een dwarsdoorsnede van < 15 cm,  ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, voor paraplu\'s, voor gereedschapsstelen e.d., hout gezaagd in de vorm van planken of balken enz. en met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039700: 'Populieren- en espenhout "Populus spp.", onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d. en m.u.v. hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken, enz. en m.u.v. met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039800: 'Eucalyptus "Eucalyptus spp.", onbewerkt, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (m.u.v. ruw bewerkt hout voor wandelstokken, voor paraplu\'s, voor gereedschapsstelen e.d., hout gezaagd in de vorm van planken of balken enz. en met verf, met creosoot of met andere conserveringsmiddelen behandeld hout)',
    44039900: 'Onbewerkt hout, ook indien ontschorst, ontdaan van het spint of enkel vierkant behakt of vierkant bezaagd (ruw bewerkt hout voor wandelstokken, paraplu\'s, gereedschapsstelen e.d.; hout gezaagd in de vorm van dwarsliggers en wisselhouten of van planken of balken enz., alsmede met verf, met creosoot of met andere conserveringsmiddelen behandeld hout; m.u.v. naaldhout en tropisch hout, eik, beukenhout, berkenhout,populieren- en espenhout, eucalyptus)',
    44041000: 'hoephout, gekloofde staken, palen en stokken, enkel aangepunt, hout, ruw bewerkt of afgerond, doch verder onbewerkt, voor wandelstokken, voor paraplu\'s, voor gereedschapsstelen e.d.; spaanhout en hout in repen, linten e.d., van naaldhout (m.u.v. overlangs gezaagde palen en stokken van hout, hoepels, op lengte gesneden en voorzien van inkepingen aan de uiteinden, en m.u.v. borstelhouten en schoenleesten)',
    44042000: 'hoephout; gekloofde staken; palen en stokken van hout, enkel aangepunt; hout, ruw bewerkt of afgerond, doch verder onbewerkt, voor wandelstokken, voor paraplu\'s, voor gereedschapsstelen e.d.; spaanhout en hout in repen, linten e.d. (m.u.v. die van naaldhout en m.u.v. overlangs gezaagde palen en stokken van hout, hoepels, op lengte gesneden en voorzien van inkepingen aan de uiteinden, en m.u.v. borstelhouten en schoenleesten)',
    44050000: 'houtwol; houtmeel in poedervorm, dat bij gebruik van een controlezeef met een maaswijdte van 0,63 mm <= 8 gewichtspercenten achterblijvende deeltjes bevat',
    44061100: 'houten, dwarsliggers en wisselhouten, ongeïmpregneerd, naaldhout',
    44061200: 'houten dwarsliggers en wisselhouten, ongeïmpregneerd, niet-naaldhout',
    44069100: 'houten dwarsliggers en wisselhouten, geïmpregneerd, naaldhout',
    44069200: 'houten dwarsliggers en wisselhouten, geïmpregneerd,  niet-naaldhout',
    44071110: 'Dennenhout (Pinus spp.)in de lengte verbonden, overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm, ook indien geschaafd of geschuurd',
    44071120: 'Dennenhout (Pinus spp.),overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (m.u.v. in de lengte verbonden)',
    44071190: 'Dennenhout (Pinus spp. )overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm, (m.u.v. in de lengte verbonden en geschaafd)',
    44071210: 'Zilverspar (Abies spp.) en sparhout (Picea spp.),in de lengte verbonden, overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm, ook indien geschaafd of geschuurd',
    44071220: 'Zilverspar (Abies spp.) en sparhout (Picea spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild met een dikte van > 6 mm, geschaafd (m.u.v. in de lengte verbonden)',
    44071290: 'Zilverspar (Abies spp.) en sparhout (Picea spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm (m.u.v. in de lengte verbonden en geschaafd)',
    44071910: 'Naaldhout,in de lengte verbonden, overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm, ook indien geschaafd of geschuurd (m.u.v. dennenhout (Pinus spp.) en zilverspar (Abies spp.) en sparhout (Picea spp.))',
    44071920: 'Naaldhout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild  met een dikte van > 6 mm, ook indien geschaafd (m.u.v. dennenhout (Pinus spp.) en zilverspar (Abies spp.) en sparhout (Picea spp.) en  in de lengte verbonden)',
    44071990: 'Naaldhout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. dennenhout (Pinus spp.) , zilverspar (Abies spp.) en sparhout (Picea spp.) en m.u.v. in de lengte verbonden of geschaafd hout)',
    44072110: 'mahogany "Swietenia spp.",  overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd, of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072191: 'mahogany "Swietenia spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072199: 'mahogany "Swietenia spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44072210: 'virola, imbuia en balsa, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd, of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072291: 'virola, imbuia en balsa, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072299: 'virola, imbuia en balsa, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44072510: 'dark red meranti, light red meranti en meranti bakau, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072530: 'dark red meranti, light red meranti en meranti bakau, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072550: 'dark red meranti, light red meranti en meranti bakau, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (niet in de lengte verbonden)',
    44072590: 'dark red meranti, light red meranti en meranti bakau, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44072610: 'white lauan, white meranti, white seraya, yellow meranti en alan, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072630: 'white lauan, white meranti, white seraya, yellow meranti en alan, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072650: 'white lauan, white meranti, white seraya, yellow meranti en alan, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (niet in de lengte verbonden)',
    44072690: 'white lauan, white meranti, white seraya, yellow meranti en alan, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en m.u.v. die in de lengte verbonden)',
    44072710: 'sapelli, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072791: 'sapelli overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072799: 'sapelli overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44072810: 'iroko overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072891: 'iroko overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072899: 'iroko overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44072915: 'keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, okoumé, obeche, sipo, acajou d\'Afrique, makoré, tiama, mansonia, ilomba, dibétou, limba, azobé, palissandre de Rio, palissandre de Para, palissandre de Rose, abura, afrormosia, ako, andiroba, aningré, avodiré, balau, bossé clair, bossé foncé, cativo, cedro, dabema, doussié, framiré, freijo, fromager, fuma, geronggang, ipé, jaboty, jequitiba, kosipo, kotibé, koto, louro, maçaranduba, mahogany (m.u.v. "Swietenia spp."), mandioqueira, mengkulang, merawan, merpauh, mersawa, moabi, niangon, nyatoh, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, pau amarelo, pau marfim, pulai, punah, quaruba, saqui-saqui, sepetir, sucupira, suren, tauari en tola, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, in de lengte verbonden, ook indien geschaafd of geschuurd',
    44072920: 'palissandre de Rio, palissandre de Para en palissandre de rose, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (niet in de lengte verbonden)',
    44072983: 'Abura, afrormosia, ako, andiroba, aningré, avodiré, azobé, balau, bossé clair, bossé foncé, cativo, cedro, dabema, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, ipé, jaboty,  jequitiba, kosipo, kotibé, koto, louro, maçaranduba, mahonie (m.u.v. "Swietenia spp.") mandioqueira, mengkulang, merawan, merpauh, mersawa, moabi, niangon, nyatoh, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, pau Amarelo, pau marfim, pulai, punah, quaruba, saqui-saqui, sepetir, sucupira, suren, tauari, tola, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, okoumé, obeche, sipo, acajou d\'Afrique, makoré, tiama, mansonia, ilomba, dibétou, limba en azobé, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (m.u.v. in de lengte verbonden)',
    44072985: 'Abura, afrormosia, ako, andiroba, aningré, avodiré, azobé, balau, bossé clair, bossé foncé, cativo, cedro, dabema, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, ipé, jaboty,  jequitiba, kosipo, kotibé, koto, louro, maçaranduba, mahonie (m.u.v. "Swietenia spp.") mandioqueira, mengkulang, merawan, merpauh, mersawa, moabi, niangon, nyatoh, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, pau Amarelo, pau marfim, pulai, punah, quaruba, saqui-saqui, sepetir, sucupira, suren, tauari, tola, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, okoumé, obeche, sipo, acajou d\'Afrique, makoré, tiama, mansonia, ilomba, dibétou, limba en azobé,  palissandre de Rio, palissandre de Para en palissandre de Rose, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (m.u.v. in de lengte verbonden)',
    44072995: 'Abura, afrormosia, ako, andiroba, aningré, avodiré, azobé, balau, bossé clair, bossé foncé, cativo, cedro, dabema, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, ipé, jaboty,  jequitiba, kosipo, kotibé, koto, louro, maçaranduba, mahonie (m.u.v. "Swietenia spp.") mandioqueira, mengkulang, merawan, merpauh, mersawa, moabi, niangon, nyatoh, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, pau Amarelo, pau marfim, pulai, punah, quaruba, saqui-saqui, sepetir, sucupira, suren, tauari, tola, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, okoumé, obeche, sipo, acajou d\'Afrique, makoré, tiama, mansonia, ilomba, dibétou, limba en azobé,  palissandre de Rio, palissandre de Para en palissandre de Rose, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. in de lengte verbonden en geschuurd en geschaafd)',
    44072996: 'Tropisch hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd (m.u.v. abura, acajou d\'Afrique, afrormosia, ako, alan, andiroba, aningré, avodiré, azobé, balau, balsa, bossé clair, bossé foncé, cativo, cedro, dabema, dark red meranti, dibétou, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, imbuia, ipé, iroko, jaboty, jelutong, jequitiba, jongkong, kapur, kempas, keruing, kosipo, kotibé, koto, light red meranti, limba, louro, maçaranduba, mahonie, makoré, mandioqueira, mansonia, mengkulang, meranti bakau, merawan, merbau, merpauh, mersawa, moabi, niangon, nyatoh, obeche, okoumé, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, palissandre de Para, palissandre de Rio, palissandre de Rose, pau Amarelo, pau marfim, pulai, punah, quaruba, ramin, sapelli, saqui-saqui, sepetir, sipo, sucupira, suren, tauari, teak, tiama, tola, virola, white lauan, white meranti, white seraya en yellow meranti)',
    44072997: 'Tropisch hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd  (m.u.v. abura, acajou d\'Afrique, afrormosia, ako, alan, andiroba, aningré, avodiré, azobé, balau, balsa, bossé clair, bossé foncé, cativo, cedro, dabema, dark red meranti, dibétou, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, imbuia, ipé, iroko, jaboty, jelutong, jequitiba, jongkong, kapur, kempas, keruing, kosipo, kotibé, koto, light red meranti, limba, louro, maçaranduba, mahonie, makoré, mandioqueira, mansonia, mengkulang, meranti bakau, merawan, merbau, merpauh, mersawa, moabi, niangon, nyatoh, obeche, okoumé, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, palissandre de Para, palissandre de Rio, palissandre de Rose, pau Amarelo, pau marfim, pulai, punah, quaruba, ramin, sapelli, saqui-saqui, sepetir, sipo, sucupira, suren, tauari, teak, tiama, tola, virola, white lauan, white meranti, white seraya en yellow meranti)',
    44072998: 'Tropisch hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. geschuurd, geschaafd of in de lengte verbonden, en m.u.v.  abura, acajou d\'Afrique, afrormosia, ako, alan, andiroba, aningré, avodiré, azobé, balau, balsa, bossé clair, bossé foncé, cativo, cedro, dabema, dark red meranti, dibétou, doussié, framiré, freijo, fromager, fuma, geronggang, ilomba, imbuia, ipé, iroko, jaboty, jelutong, jequitiba, jongkong, kapur, kempas, keruing, kosipo, kotibé, koto, light red meranti, limba, louro, maçaranduba, mahonie, makoré, mandioqueira, mansonia, mengkulang, meranti bakau, merawan, merbau, merpauh, mersawa, moabi, niangon, nyatoh, obeche, okoumé, onzabili, orey, ovengkol, ozigo, padauk, paldao, palissandre de Guatemala, palissandre de Para, palissandre de Rio, palissandre de Rose, pau Amarelo, pau marfim, pulai, punah, quaruba, ramin, sapelli, saqui-saqui, sepetir, sipo, sucupira, suren, tauari, teak, tiama, tola, virola, white lauan, white meranti, white seraya en yellow meranti)',
    44079115: 'eik "Quercus spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd, alsmede in de lengte verbonden, ook indien geschaafd',
    44079131: 'losse plankjes voor parketvloeren, van eik "Quercus spp.", met een dikte van > 6 mm, geschaafd (m.u.v. die met fineer bekleed hout of van triplex- en multiplexhout)',
    44079139: 'eik "Quercus spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd (m.u.v. die in de lengte verbonden en m.u.v. plankjes voor parketvloeren)',
    44079190: 'eik "Quercus spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. geschaafd of geschuurd hout of hout in de lengte verbonden)',
    44079200: 'beuk "Fagus spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, ook indien geschaafd, geschuurd of in de lengte verbonden, met een dikte van > 6 mm',
    44079310: 'esdoorn (Acer spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44079391: 'esdoorn (Acer spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (niet in de lengte verbonden)',
    44079399: 'esdoorn (Acer spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44079410: 'kers (Prunus spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44079491: 'kers (Prunus spp.),  overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (niet in de lengte verbonden)',
    44079499: 'kers (Prunus spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44079510: 'es (Fraxinus spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44079591: 'es (Fraxinus spp.),  overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschuurd (niet in de lengte verbonden)',
    44079599: 'es (Fraxinus spp.), overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (niet geschaafd of geschuurd en niet in de lengte verbonden)',
    44079610: 'Berkenhout "Betula spp.", overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44079691: 'Berkenhout "Betula spp.", overlangs gezaagd of afgestoken, geschild of gesneden, met een dikte > 6 mm, geschuurd (m.u.v. in de lengte verbonden)',
    44079699: 'Berkenhout "Betula spp.", overlangs gezaagd of afgestoken, gesneden of geschild,  met een dikte > 6 mm (m.u.v. geschaafd, geschuurd of in de lengte verbonden)',
    44079710: 'Populieren- en espenhout "Populus spp.", overlangs gezaagd of afgestoken, gesneden of geschild, met een dikte > 6 mm ,  geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd',
    44079791: 'Populieren- en espenhout "Populus spp.", overlangs gezaagd of afgestoken, gesneden of geschild, met een dikte > 6 mm, geschuurd  (m.u.v. in de lengte verbonden)',
    44079799: 'populier- en espenhout , overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. geschaafd of geschuurd hout en van hout in de lengte verbonden)',
    44079927: 'Hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm, geschaafd of in de lengte verbonden, ook indien geschaafd of geschuurd (m.u.v. tropisch hout, naaldhout, eik "Quercus spp.", beuk "Fagus spp.", esdoorn "Acer spp.", kers "Prunus spp.",es "Fraxinus spp.", berk "Betula spp." en Populier en esp "Populus spp.")',
    44079940: 'Hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm,(m.u.v. in de lengte verbonden; tropisch hout, naaldhout, eik "Quercus spp.", beuk "Fagus spp.", esdoorn "Acer spp.", kers "Prunus spp.",es "Fraxinus spp.", berk "Betula spp." en Populier en esp "Populus spp.")',
    44079990: 'Hout, overlangs gezaagd of afgestoken, dan wel gesneden of geschild, met een dikte van > 6 mm (m.u.v. geschaafd, geschuurd of in de lengte verbonden;  tropisch hout, naaldhout, eik "Quercus spp.", beuk "Fagus spp.", esdoorn "Acer spp.", kers "Prunus spp.",es "Fraxinus spp.", berk "Betula spp." en Populier en esp "Populus spp.")',
    44081015: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout van naaldhout of voor ander op dergelijke wijze gelaagd naaldhout, alsmede ander naaldhout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschaafd, geschuurd of in de lengte verbonden',
    44081091: 'plankjes bestemd voor de vervaardiging van potloden, van naaldhout, met een dikte van <= 6 mm',
    44081098: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout van naaldhout of voor ander op dergelijke wijze gelaagd naaldhout, alsmede ander naaldhout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van <= 6 mm (m.u.v. geschaafd of geschuurd hout of hout in de lengte verbonden en m.u.v. plankjes bestemd voor de vervaardiging van potloden)',
    44083111: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, met verbinding aan de randen, ook indien geschaafd of geschuurd, van dark red meranti, light red meranti en meranti bakau',
    44083121: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschaafd, van dark red meranti, light red meranti en meranti bakau (m.u.v. hout in de lengte verbonden)',
    44083125: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschuurd, van dark red meranti, light red meranti en meranti bakau (m.u.v. die in de lengte verbonden)',
    44083130: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, ook indien met  verbinding aan de randen, van dark red meranti, light red meranti en meranti bakau (niet geschaafd of geschuurd en m.u.v. die in de lengte verbonden)',
    44083915: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschuurd en in de lengte verbonden, ook indien geschaafd, van white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahogany "Swietenia spp.", palissandre de Rio, palissandre de Para en palissandre de Rose',
    44083921: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschaafd, van white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahogany "Swietenia spp.", palissandre de Rio, palissandre de Para en palissandre de Rose (m.u.v. die in de lengte verbonden)',
    44083930: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van <= 6 mm, van white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahogany "Swietenia spp.", palissandre de Rio, palissandre de Para en palissandre de Rose (niet geschaafd of geschuurd en m.u.v. die in de lengte verbonden)',
    44083955: 'Fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschaafd, geschuurd of in de lengte verbonden, van tropisch hout (m.u.v.  white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahonie "Swietenia spp.", palissandre de Rio, palissandre de Para en palissandre de Rose)',
    44083970: 'Plankjes bestemd voor de vervaardiging van potloden, met een dikte van <= 6 mm, van tropisch hout (m.u.v.white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahogany "Swietenia spp.", palissandre de Rio, palissandre de Para en palissandre de Rose)',
    44083985: 'Fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van <= 1 mm, van tropisch hout ( m.u.v. white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahonie "Swietenia spp.", palissandre de Rio, palissandre de Para, palissandre de Rose, en geschaafd, geschuurd en in de lengte verbonden)',
    44083995: 'Fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van > 1 mm doch <= 6 mm, van tropisch hout (m.u.v. white lauan, sipo, limba, okoumé, obeche, acajou d\'Afrique, sapelli, virola, mahonie "Swietenia spp.", palissandre de Rio, palissandre de Para, palissandre de Rose, en mu.v. geschaafd, geschuurd of die in de lengte verbonden)',
    44089015: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, met een dikte van <= 6 mm, geschaafd, geschuurd of in de lengte verbonden (m.u.v. naaldhout en tropisch hout)',
    44089035: 'plankjes bestemd voor de vervaardiging van potloden, met een dikte van <= 6 mm (m.u.v. die van naaldhout of tropisch hout)',
    44089085: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van <= 1 mm (niet geschaafd of geschuurd, m.u.v. die in de lengte verbonden en m.u.v. naaldhout en tropisch hout)',
    44089095: 'fineerplaten "die verkregen door het snijden van gelaagd hout daaronder begrepen", platen voor de vervaardiging van triplex- en multiplexhout of voor ander op dergelijke wijze gelaagd hout, alsmede ander hout, overlangs gezaagd, dan wel gesneden of geschild, ook indien met verbinding aan de randen, met een dikte van > 1 mm (niet geschaafd of geschuurd, m.u.v. die in de lengte verbonden en m.u.v. naaldhout en tropisch hout)',
    44091011: 'staaflijst van naaldhout, voor lijsten voor schilderijen, voor foto\'s, voor spiegels e.d.',
    44091018: 'naaldhout "niet ineengezette plankjes voor parketvloeren daaronder begrepen", waarvan ten minste een zijde of uiteinde over de gehele lengte of uiteinde is geprofileerd "geploegd, van sponningen voorzien, afgerond met V-verbinding of dergelijke", ook indien geschaafd, geschuurd of in de lengte verbonden (m.u.v. staaflijst van hout, voor lijsten voor schilderijen, voor foto\'s, voor spiegels e.d.)',
    44092100: 'bamboe, niet-ineengezette plankjes voor parketvloeren daaronder begrepen,  waarvan ten minste een zijde of uiteinde over de gehele lengte is geprofileerd "geploegd, van sponningen voorzien, afgerond met V-verbinding of dergelijke", ook indien geschaafd, geschuurd of in de lengte verbonden',
    44092200: 'Tropisch hout, niet-ineengezette plankjes voor parketvloeren daaronder begrepen, waarvan ten minste een zijde of uiteinde over de gehele lengte is geprofileerd "geploegd, van sponningen voorzien, afgerond met V-verbinding of dergelijke", ook indien geschaafd , geschuurd of in de lengte verbonden',
    44092910: 'staaflijst van hout, voor lijsten voor schilderijen, voor foto\'s, voor spiegels e.d. (m.u.v. die van naaldhout, tropisch hout en van bamboe)',
    44092991: 'niet ineengezette plankjes voor parketvloeren, van hout, waarvan ten minste een zijde of uiteinde over de gehele lengte of uiteinde is geprofileerd "geploegd, van sponningen voorzien, afgerond met V-verbinding of dergelijke", ook indien geschaafd, geschuurd of in de lengte verbonden (m.u.v. die van naaldhout, tropisch hout en van bamboe)',
    44092999: 'hout, waarvan ten minste een zijde of uiteinde over de gehele lengte of uiteinde is geprofileerd "geploegd, van sponningen voorzien, afgerond met V-verbinding of dergelijke", ook indien geschaafd, geschuurd of in de lengte verbonden (m.u.v. naaldhout, tropisch hout en bamboe, staaflijst van hout, voor lijsten voor schilderijen, voor foto\'s, voor spiegels e.d. en plankjes voor parketvloeren)',
    44101110: 'Spaanplaat, van hout, ook indien samengeperst met harsen of met andere organische bindmiddelen, onbewerkt of enkel gladgeschuurd (m.u.v. zgn. "oriented strand board" en zgn. "waferboard"; vezelplaten en panelen met cellenstructuur)',
    44101130: 'Spaanplaat, van hout, ook indien samengeperst met harsen of met andere organische bindmiddelen, aan de oppervlakte bedekt met papier geïmpregneerd met melamine (m.u.v. zgn. "oriented strand board" en zgn. "waferboard"; vezelplaten; panelen met cellenstructuur)',
    44101150: 'Spaanplaat, van hout, ook indien samengeperst met harsen of met andere organische bindmiddelen, aan de oppervlakte bedekt met decoratieve gelaagde platen of vellen van kunststof (m.u.v. zgn. "oriented strand board" en zgn. "waferboard"; vezelplaten en panelen met cellenstructuur)',
    44101190: 'Spaanplaat, van hout, ook indien samengeperst met harsen of met andere organische bindmiddelen (m.u.v. onbewerkt of  enkel gladgeschuurd, m.u.v. die aan de oppervlakte bedekt met papier geïmpregneerd met melamine of met decoratieve gelaagde platen of vellen van kunststof,  zgn. "oriented strand board" en zgn. "waferboard", vezelplaten, en panelen met cellenstructuur)',
    44101210: 'zgn.oriented strand board "OSB", van hout, onbewerkt of enkel gladgeschuurd',
    44101290: 'zgn. oriented strand board "OSB", van hout (m.u.v. onbewerkt of enkel gladgeschuurd)',
    44101900: 'Zogenoemde "waferboard" en dergelijke plaat, van hout, ook indien samengeperst met harsen of met andere organische bindmiddelen (m.u.v. spaanplaat en zgn. "oriented strand board" [OSB] en panelen met cellenstructuur)',
    44109000: 'Spaanplaat e.d. plaat van ampas-, bamboe- of graanstrodeeltjes dan wel van andere houtachtige stoffen, ook indien samengeperst met harsen of met andere organische bindmiddelen (m.u.v. die van hout en m.u.v. vezelplaat, panelen met cellenstructuur, met fineer bekleed spaanplaat en plaat van houtachtige stoffen, gebonden met cement, gips of andere minerale bindmiddelen)',
    44111210: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van <= 5 mm (m.u.v. mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag)',
    44111290: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van <= 5 mm, mechanisch bewerkt of voorzien van een deklaag',
    44111310: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van > 5mm doch <= 9 mm (m.u.v. mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag)',
    44111390: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van > 5 mm doch <= 9 mm, mechanisch bewerkt of voorzien van een deklaag',
    44111410: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van > 9 mm (m.u.v. mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag)',
    44111490: 'Vezelplaat van houtvezels met gemiddelde dichtheid (MDF), met een dikte van > 9 mm, mechanisch bewerkt of voorzien van een deklaag',
    44119210: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van > 0,8 g/cm³ (m.u.v. vezelplaat met gemiddelde dichtheid "MDF", mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag, spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44119290: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van > 0,8 g/cm³, mechanisch bewerkt of voorzien van een deklaag (m.u.v. uitsluitend geslepen vezelplaat, vezelplaat met gemiddelde dichtheid "MDF", spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44119310: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van > 0,5, doch <= 0,8 g/cm³ (m.u.v. vezelplaat met gemiddelde dichtheid "MDF", mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag, spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44119390: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van > 0,5, doch <= 0,8 g/cm³, mechanisch bewerkt of voorzien van een deklaag (m.u.v. vezelplaat met gemiddelde dichtheid "MDF", uitsluitend geslepen vezelplaat, spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44119410: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van <= 0,5 g/cm³ (m.u.v. vezelplaat met gemiddelde dichtheid "MDF", mechanische bewerkte vezelplaat en vezelplaat voorzien van een deklaag, spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44119490: 'Vezelplaat van houtvezels of van andere houtachtige vezels, ook indien gebonden met harsen of met andere organische bindmiddelen, met een dichtheid van <= 0,5 g/cm³, mechanisch bewerkt of voorzien van een deklaag (m.u.v. vezelplaat met gemiddelde dichtheid "MDF", uitsluitend geslepen vezelplaat, spaanplaat, ook indien verbonden met een of meer vezelplaten, gelaagd hout met een laag van triplex- of multiplexhout, panelen met dekplaten van vezelplaat, karton en als zodanig herkenbare delen van meubelen)',
    44121000: 'Triplex- en multiplexhout, met fineer bekleed hout en op dergelijke wijze gelaagd hout, van bamboe (m.u.v. planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44123110: 'Triplex- en multiplexhout, enkel bestaande uit houten platen met een dikte van <= 6 mm, met ten minste een der buitenste lagen van dark red meranti, light red meranti, white lauan, sipo, limba, obeche, okoumé, acajou d\'Afrique, sapelli, virola, mahogany "Swietenia spp.", palissandre de Rio, palissandre de Para of palissandre de Rose (m.u.v. planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44123190: 'Triplex- en multiplexhout, enkel bestaande uit houten platen, iedere laag met een dikte van <=6 mm, met ten minste een der buitenste lagen van tropisch hout (m.u.v. een buitenste laag van okoumé, dark red meranti, light red meranti, white lauan, sipo, limba, obeche, acajou d\'Afrique, sapelli, virola, mahonie"Swietenia spp.", palissandre de Rio, palissandre de Para of palissandre de Rose, planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44123300: 'Triplex- en multiplexhout, enkel bestaande uit houten platen, iedere laag met een dikte van <= 6 mm, met ten minste een der buitenste lagen van ander hout dan naaldhout van de soort els, es, beuk, berk, kers, kastanje, iep, eucalyptus, carya (\'Hickory\'), paardenkastanje, linde, esdoorn, eik, plataan, populier en esp, robinia, rozenhout bahia (\'Dalbergia decipularis\')  of walnoot (m.u.v. bamboe,  met een buitenste laag van tropisch hout, en planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44123400: 'Triplex- en multiplexhout, enkel bestaande uit houten platen, iedere laag met een dikte van <= 6 mm, met ten minste een der buitenste lagen van ander hout dan naaldhout (m.u.v. de soort els, es, beuk, berk, kers, kastanje, iep, eucalyptus, carya (\'Hickory\'), paardenkastanje, linde, esdoorn, eik, plataan, populier en esp, robinia, rozenhout bahia (\'Dalbergia decipularis\')  of walnoot (m.u.v. bamboe,  met een buitenste laag van tropisch hout, en planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44123900: 'Triplex- en multiplexhout, enkel bestaande uit houten platen met een dikte van <= 6 mm, met beide buitenste lagen van naaldhout (m.u.v. bamboe,  planken van verdicht hout, panelen met cellenstructuur, inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44129410: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout met ten minste een der buitenste lagen geen naaldhout en met een vulling van plankjes, latten of staafjes (m.u.v. bamboe; triplex- en mutliplexhout, enkel bestaande uit houten platen, iedere laag met een dikte van <= 6 mm; platen van verdicht hout; inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44129490: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout met een vulling van plankjes, latten of staafjes (m.u.v. bamboe; deze met ten minste een der buitenste lagen van geen naaldhout; triplex- en mutliplexhout, enkel bestaande uit houten platen, iedere laag met een dikte van <= 6 mm; platen van verdicht hout; inlegwerk van hout en platen herkenbaar als delen van meubelen)',
    44129930: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout, bevattende ten minste één laag spaanplaat, zonder een vulling van plankjes, latten of staafjes (m.u.v. bamboe; deze met ten minste een der buitenste lagen van geen naaldhout iedere laag met een dikte van <= 6 mm; platen van verdicht hout; panelen met cellenstructuur; inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44129940: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout, met ten minste een der buitenste lagen van els, es, beuk, berk, kers, kastanje, iep,hickory, steenbeuk, paardenkastanje, linde, esdoorn, eik, plataan, populier, robinia, noot of yellow poplar, zonder spaanplaat en geen vulling van plankjes, latten of staafjes   (m.u.v triplex- en multiplexhout waarvan de dikte van een der buitenste lagen <= 6 mm; platen van verdicht hout; panelen met cellenstructuur; inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44129950: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout, met ten minste een der buitenste lagen van van ander hout dan naaldhout, zonder spaanplaat en geen vulling van plankjes, latten of staafjes   (m.u.v els, es, beuk, berk, kers, kastanje, iep,hickory, steenbeuk, paardenkastanje, linde, esdoorn, eik, plataan, populier, robinia, noot of yellow poplar; triplex- en multiplexhout waarvan de dikte van een der buitenste lagen <= 6 mm; platen van verdicht hout; panelen met cellenstructuur; inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44129985: 'Met fineer bekleed hout en op dergelijke wijze gelaagd hout, zonder spaanplaat en geen vulling van plankjes, latten of staafjes   (m.u.v deze bevattende  ten minste een der buitenste lagen van ander hout dan naaldhout; bamboe; triplex- en multiplexhout met een dikte van <= 6 mm; platen van verdicht hout; panelen met cellenstructuur; inlegwerk van hout en platen die herkenbaar zijn als delen van meubelen)',
    44130000: 'metaalhout en ander verdicht hout, in blokken, in planken, in stroken of in profielen',
    44140010: 'lijsten voor schilderijen, voor foto\'s, voor spiegels e.d., van de tropische houtsoorten "okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para en palissandre de Rose"',
    44140090: 'lijsten voor schilderijen, voor foto\'s, voor spiegels e.d. (m.u.v. die van tropische houtsoorten "okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para en palissandre de Rose")',
    44151010: 'pakkisten, kratten, trommels e.d. verpakkingsmiddelen, van hout',
    44151090: 'kabelhaspels van hout',
    44152020: 'laadborden en opzetranden voor laadborden, van hout',
    44152090: 'laadkisten en andere laadplateaus, van hout (m.u.v. laadborden en opzetranden voor laadborden en m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    44160000: 'vaten, kuipen, tobben en ander kuiperswerk, alsmede delen daarvan, van hout, duighout daaronder begrepen',
    44170000: 'gereedschap, alsmede monturen en stelen voor gereedschap, borstelhouten, borstel- en bezemstelen, van hout; schoenleesten en schoenspanners, van hout (m.u.v. vormen voor de vervaardiging van hoofddeksels, gietvormen als bedoeld bij post 8480, machines en delen van machines, van hout)',
    44181010: 'vensters en vensterdeuren, alsmede kozijnen daarvoor, van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para of palissandre de rose',
    44181050: 'vensters en vensterdeuren, alsmede kozijnen daarvoor, van naaldhout',
    44181090: 'vensters en vensterdeuren, alsmede kozijnen daarvoor, van hout (m.u.v. die van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para, palissandre de rose of naaldhout)',
    44182010: 'deuren en kozijnen daarvoor, alsmede drempels, van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para of palissandre de rose',
    44182050: 'deuren en kozijnen daarvoor, alsmede drempels, van naaldhout',
    44182080: 'deuren en kozijnen daarvoor, alsmede drempels, van hout (m.u.v. die van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para, palissandre de rose of naaldhout)',
    44184000: 'bekistingen voor betonwerken, van hout (m.u.v. platen van triplex- of multiplexhout)',
    44185000: 'dakspanen "shingles en shakes", van hout',
    44186000: 'palen en balken, van hout',
    44187310: 'Mozaïekpanelen voor vloerbedekking, ineengezet, van bamboe of met ten minste een toplaag (slijtlaag) van bamboe',
    44187390: 'Panelen voor vloerbedekking, ineengezet, van bamboe of met ten minste een toplaag (slijtlaag) van bamboe (m.u.v. mozaïekpanelen)',
    44187400: 'Panelen voor vloerbedekking, voor mozaïekpanelen, ineengezet, van hout (m.u.v. bamboe)',
    44187500: 'Panelen voor vloerbedekking bestaande uit meerdere lagen, ineengezet, van hout ander dan bamboe (m.u.v. voor mozaïekpanelen)',
    44187900: 'Panelen voor vloerbedekking, ineengezet, van hout ander dan bamboe (m.u.v. die bestaande uit meerdere lagen hout en die voor mozaïekpanelen)',
    44189100: 'Schrijn- en timmerwerk voor bouwwerken, van bamboe (m.u.v. vensters en vensterdeuren, alsmede kozijnen daarvoor, deuren en kozijnen daarvoor, alsmede drempels, palen en balken, panelen en plankjes voor parketvloeren,  bekistingen voor betonwerken, dakspanen "shingles en shakes" en geprefabriceerde bouwwerken)',
    44189910: 'schrijn- en timmerwerk voor bouwwerken, van gelamineerd hout (m.u.v. palen en balken)',
    44189990: 'schrijn- en timmerwerk voor bouwwerken, incl. panelen met cellenstructuur, van hout (m.u.v. die van bamboe en van gelamineerd hout en m.u.v. vensters en vensterdeuren, alsmede kozijnen daarvoor, deuren en kozijnen daarvoor, alsmede drempels, palen en balken, panelen en plankjes voor parketvloeren, bekistingen voor betonwerken, dakspanen "shingles en shakes" en geprefabriceerde bouwwerken)',
    44191100: 'Broodplanken, snijplanken en dergelijke planken, van bamboe',
    44191200: 'Eetsstokjes van bamboe',
    44191900: 'Tafel- en keukengerei van bamboe (m.u.v. broodplanken, snijplanken en dergelijke planken, eetstokjes)',
    44199010: 'Tafel- en keukengerei van tropische houtsoorten bedoeld bij aanvullende aantekening 2 op H.44',
    44199090: 'Tafel- en keukengerei van hout (m.u.v. van bamboe of van tropisch hout, en  meubelmakerswerk; siervoorwerpen; kuiperswerk; delen van tafel- en keukengerei van hout; borstels, penselen, bezems; handzeven)',
    44201011: 'beeldjes en andere siervoorwerpen, van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para of palissandre de rose (m.u.v. inlegwerk van hout)',
    44201019: 'beeldjes en andere siervoorwerpen, van hout (m.u.v. die van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para en palissandre de Rose; inlegwerk van hout)',
    44209010: 'inlegwerk van hout (m.u.v. beeldjes en andere siervoorwerpen; meubelen en verlichtingstoestellen, alsmede delen daarvan)',
    44209091: 'koffertjes, kistjes en etui\'s, voor juwelen of voor goudsmidswerk, alsmede dergelijke artikelen en meubelmakerswerk, van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para of palissandre de Rose',
    44209099: 'koffertjes, kistjes en etui\'s, voor juwelen of voor goudsmidswerk, alsmede dergelijke artikelen en meubelmakerswerk, van hout (m.u.v. die van okoumé, obeche, sapelli, sipo, acajou d\'Afrique, makoré, iroko, tiama, mansonia, ilomba, dibétou, limba, azobé, dark red meranti, light red meranti, meranti bakau, white lauan, white meranti, white seraya, yellow meranti, alan, keruing, ramin, kapur, teak, jongkong, merbau, jelutong, kempas, virola, mahogany "Swietenia spp.", imbuia, balsa, palissandre de Rio, palissandre de Para of palissandre de Rose; beeldjes en andere siervoorwerpen, inlegwerk van hout; meubelen en verlichtingstoestellen, alsmede delen daarvan)',
    44211000: 'kleerhangers van hout',
    44219100: 'houtwaren van bamboe, n.e.g.',
    44219910: 'houtwaren van vezelplaat, n.e.g.',
    44219991: 'doodkisten van hout (m.u.v. die van vezelplaat)',
    44219999: 'houtwaren n.e.g.',
    45011000: 'natuurkurk, ruw of natuurkurk, waarvan het oppervlak enkel is gereinigd',
    45019000: 'kurkafval; gebroken of gemalen kurk',
    45020000: 'natuurkurk, ontdaan van de buitenste laag of enkel kantrecht gemaakt, dan wel in vierkante of rechthoekige blokken, platen, bladen, vellen en strippen, incl. blokjes met scherpe kanten voor het vervaardigen van kurken "stoppen"',
    45031010: 'cilindrische kurken "stoppen", van natuurkurk',
    45031090: 'kurken "stoppen", van alle soorten, van natuurkurk, incl. halffabrikaten met afgeronde kanten (m.u.v. cilindrische kurken)',
    45039000: 'werken van natuurkurk (m.u.v. natuurkurk, in vierkante of rechthoekige blokken, platen, bladen, vellen en strippen; kurken "stoppen" en blokjes voor de vervaardiging daarvan; schoeisel en delen daarvan, inlegzolen, ook uitneembaar; hoofddeksels en delen daarvan; proppen en tussenschotten voor jachtpatronen; speelgoed, spellen en sportartikelen en delen daarvan)',
    45041011: 'cilindrische kurken "stoppen" voor mousserende wijn, van geagglomereerde kurk, ook indien met een ring van natuurlijke kurk',
    45041019: 'cilindrische kurken "stoppen" van geagglomereerde kurk (m.u.v. die voor mousserende wijn)',
    45041091: 'tegels in ongeacht welke vorm, blokken, platen, bladen, vellen, strippen en massieve cilinders, incl. schijven, van geagglomereerde kurk, met bindmiddel (m.u.v. kurken [stoppen])',
    45041099: 'tegels, in ongeacht welke vorm, blokken, platen, bladen, vellen, strippen en massieve cilinders, incl. schijven, van geagglomereerde kurk, zonder bindmiddel (m.u.v. kurken [stoppen])',
    45049020: 'Kurken "stoppen" van geagglomereerde kurk (m.u.v. cilindrische kurken)',
    45049080: 'Geagglomereerde kurk, ook indien met bindmiddel, en werken daarvan (m.u.v. schoeisel en delen daarvan, inlegzolen, ook uitneembaar; hoofddeksels en delen daarvan; proppen en tussenschotten voor jachtpatronen; speelgoed, spellen, sportartikelen en delen daarvan; blokken, platen, bladen, vellen en strippen; tegels in ongeacht welke vorm; massieve cilinders, incl. schijven)',
    46012110: 'matten en horden, van bamboe, samengebonden of plat geweven, van vlechten of van dergelijke in de lengterichting samengevoegde artikelen van vlechtstoffen',
    46012190: 'matten en horden, van vlechtstoffen van bamboe, samengebonden of plat geweven (m.u.v. die van vlechten of van dergelijke in de lengterichting samengevoegde vlechtstoffen)',
    46012210: 'matten en horden, van rotting, samengebonden of plat geweven, van vlechten of van dergelijke in de lengterichting samengevoegde artikelen van vlechtstoffen',
    46012290: 'matten en horden, van vlechtstoffen van rotting, samengebonden of plat geweven (m.u.v. die van vlechten of van dergelijke in de lengterichting samengevoegde vlechtstoffen)',
    46012910: 'matten en horden, van plantaardige vlechtstoffen (m.u.v. bamboe en rotting), samengebonden of plat geweven, van vlechten of van dergelijke in de lengterichting samengevoegde artikelen van vlechtstoffen',
    46012990: 'matten en horden, van plantaardige vlechtstoffen, samengebonden of plat geweven (m.u.v. van die van bamboe of rotting en m.u.v. die van vlechten of van dergelijke in de lengterichting samengevoegde vlechtstoffen)',
    46019205: 'Vlechten en dergelijke in de lengterichting gevlochten artikelen van bamboe, ook indien tot banden samengevoegd (m.u.v. matten en horden; bindgaren, touw, kabel en delen van schoeisel of van hoofddeksels)',
    46019210: 'vlechtstoffen, vlechten en dergelijke artikelen van bamboe, samengebonden of plat geweven, van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen (m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019290: 'Vlechtstoffen, vlechten en dergelijke artikelen van bamboe, samengebonden of plat geweven (m.u.v. die van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen en m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019305: 'vlechten en dergelijke in de lengterichting gevlochten artikelen van rotting, ook indien tot banden samengevoegd (m.u.v. bindgaren, touw, kabel en delen van schoeisel of van hoofddeksels)',
    46019310: 'vlechtstoffen, vlechten en dergelijke artikelen van rotting, samengebonden of plat geweven, van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen (m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019390: 'vlechtstoffen, vlechten en dergelijke artikelen van rotting, samengebonden of plat geweven (m.u.v. die van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen en m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019405: 'vlechten en dergelijke in de lengterichting gevlochten artikelen van plantaardig materiaal, ook indien tot banden samengevoegd (m.u.v. met die van bamboe of rotting en m.u.v. bindgaren, touw, kabel en delen van schoeisel of van hoofddeksels)',
    46019410: 'vlechtstoffen, vlechten en dergelijke artikelen van plantaardig materiaal, samengebonden of plat geweven, van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen (m.u.v. die van bamboe of rotting en m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019490: 'Vlechtstoffen, vlechten en dergelijke artikelen van plantaardig materiaal, samengebonden of plat geweven (m.u.v. van die van bamboe of rotting en die van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen en m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019905: 'vlechten en dergelijke in de lengterichting gevlochten artikelen van niet-plantaardig materiaal, ook indien tot banden samengevoegd (m.u.v. bindgaren, touw, kabel en delen van schoeisel of van hoofddeksels)',
    46019910: 'vlechtstoffen, vlechten en dergelijke artikelen van niet-plantaardig materiaal, samengebonden of plat geweven, van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen (m.u.v. wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46019990: 'vlechtstoffen, vlechten en dergelijke artikelen van niet-plantaardig materiaal, samengebonden of plat geweven (m.u.v. die van vlechten en van dergelijke in de lengterichting gevlochten artikelen van vlechtstoffen en m.u.v. matten en horden, wandbekleding als bedoeld bij post 4814 en delen van schoeisel of van hoofddeksels)',
    46021100: 'mandenmakerswerk, vervaardigd van vlechtstoffen of van artikelen bedoeld bij post 4601; werken van luffa, van bamboe (m.u.v. wandbekleding als bedoeld bij post 4814; bindgaren, touw en kabel; schoeisel en hoofddeksels en delen daarvan; vervoermiddelen en bakken daarvoor; artikelen bedoeld bij hoofdstuk 94, b.v. meubelen, verlichtingstoestellen)',
    46021200: 'mandenmakerswerk, vervaardigd van vlechtstoffen of van artikelen van post 4601; werken van luffa, van rotting (m.u.v. wandbekleding als bedoeld bij post 4814; bindgaren, touw en kabel; schoeisel en hoofddeksels en delen daarvan; vervoermiddelen en bakken daarvoor; artikelen bedoeld bij hoofdstuk 94, b.v. meubelen, verlichtingstoestellen)',
    46021910: 'hulzen voor flessen, gevlochten van plantaardig stro of van vlechten van plantaardig materiaal zoals bedoeld bij post 4601 (m.u.v. die van bamboe of rotting)',
    46021990: 'Mandenmakerswerk vervaardigd van plantaardige vlechtstoffen of van artikelen bedoeld bij post 4601; werken van luffa "loofah" (m.u.v. werken van bamboe of rotting; strohulzen voor flessen, wandbekleding als bedoeld bij post 4814; bindgaren, touw en kabel; schoeisel en hoofddeksels en delen daarvan; vervoermiddelen en bakken daarvoor; artikelen bedoeld bij hoofdstuk 94, b.v. meubelen, verlichtingstoestellen)',
    46029000: 'mandenmakerswerk, vervaardigd van vlechtstoffen of van artikelen bedoeld bij post 4601 (m.u.v. artikelen van plantaardig materiaal, wandbekleding als bedoeld bij post 4814; bindgaren, touw en kabel; schoeisel, hoofddeksels en delen daarvan; vervoermiddelen en bakken daarvoor; artikelen bedoeld bij hoofdstuk 94, b.v. meubelen, verlichtingstoestellen)',
    47010010: 'thermo-mechanische houtslijp, chemisch onbehandeld',
    47010090: 'houtslijp, chemisch onbehandeld (muv thermo-mechanische houtslijp)',
    47020000: 'houtcellulose voor oplossing "dissolving grades"',
    47031100: 'natron- en sulfaat-houtcellulose, ongebleekt, van naaldhout (m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47031900: 'natron- en sulfaat-houtcellulose, ongebleekt (m.u.v. die van naaldhout en m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47032100: 'natron- en sulfaat-houtcellulose, half gebleekt of gebleekt, van naaldhout (m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47032900: 'natron- en sulfaat-houtcellulose, half gebleekt of gebleekt (m.u.v. die van naaldhout en m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47041100: 'houtcellulose, ongebleekt, van naaldhout (m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47041900: 'sulfiet-houtcellulose, ongebleekt (m.u.v. die van naaldhout en m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47042100: 'sulfiet-houtcellulose, half gebleekt of gebleekt, van naaldhout (m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47042900: 'sulfiet-houtcellulose, half gebleekt of gebleekt (m.u.v. die van naaldhout en m.u.v. houtcellulose voor oplossingen "dissolving grades")',
    47050000: 'Houtpulp verkregen door de combinatie van een mechanische en een chemische behandeling',
    47061000: 'pulp van katoenlinters',
    47062000: 'pulp van vezels verkregen uit teruggewonnen papier of karton "resten en afval"',
    47063000: 'pulp van bamboe',
    47069100: 'mechanische pulp van cellulosehoudende vezelstoffen (m.u.v. die van bamboe, van hout, van katoenlinters en van vezels verkregen uit teruggewonnen papier of karton "resten en afval")',
    47069200: 'chemische pulp van cellulosehoudende vezelstoffen (m.u.v. die van bamboe, van hout, van katoenlinters en van vezels verkregen uit teruggewonnen papier of karton "resten en afval")',
    47069300: 'gedeeltelijk chemisch ontsloten pulp van cellulosehoudende vezelstoffen (m.u.v. die van bamboe, van hout, katoenlinters en vezels verkregen uit teruggewonnen papier of karton "resten en afval")',
    47071000: 'ongebleekt kraftpapier of -karton en gegolfd papier en karton voor het terugwinnen "resten en afval"',
    47072000: 'papier en karton, hoofdzakelijk vervaardigd van gebleekte houtcellulose (niet in de massa gekleurd), voor het terugwinnen "resten en afval"',
    47073010: 'oude en onverkochte kranten en tijdschriften, telefoongidsen, brochures en reclamedrukwerk',
    47073090: 'resten en afval, van papier of van karton, hoofdzakelijk vervaardigd van houtslijp (m.u.v. oude en onverkochte kranten en tijdschriften, telefoongidsen, brochures en reclamedrukwerk)',
    47079010: 'papier of van karton voor het terugwinnen "resten en afval", ongesorteerd (m.u.v. papierwol)',
    47079090: 'papier of van karton voor het terugwinnen "resten en afval", gesorteerd (m.u.v. resten en afval enkel bestaande uit ongebleekt kraftpapier of -karton of gegolfd papier of karton, uit papier of karton hoofdzakelijk vervaardigd van gebleekte houtcellulose, niet in de massa gekleurd, dan wel uit papier of karton hoofdzakelijk vervaardigd van houtslijp en m.u.v. papierwol)',
    48010000: 'Krantenpapier zoals bedoeld in aantekening 4 op hoofdstuk 48, in stroken of op rollen met een breedte > 28 cm, of in vierkante of rechthoekige bladen, waarvan in ongevouwen staat de lengte van een zijde > 28 cm en de lengte van de andere zijde > 15 cm bedraagt.',
    48021000: 'handgeschept papier en handgeschept karton, ongeacht vorm en formaat',
    48022000: 'basispapier en -karton, voor lichtgevoelig, warmtegevoelig of elektrogevoelig papier of karton, ongestreken en zonder deklaag, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat',
    48024010: 'basispapier voor behangselpapier, ongestreken en zonder deklaag, bevattende geen of <= 10 gewichtspercenten langs mechanische weg verkregen vezels, berekend over de totale vezelmassa',
    48024090: 'basispapier voor behangselpapier, ongestreken en zonder deklaag, bevattende geen of > 10 gewichtspercenten langs mechanische weg verkregen vezels, berekend over de totale vezelmassa',
    48025400: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa",  met een gewicht van < 40 g/m², n.e.g.',
    48025515: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa",  met een gewicht van >= 40 doch < 60 g/m², n.e.g.',
    48025525: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa",  met een gewicht van >= 60 doch < 75 g/m², n.e.g.',
    48025530: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa",  met een gewicht van >= 75 doch < 80 g/m², n.e.g.',
    48025590: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa",  met een gewicht van >= 80 doch <= 150 g/m², n.e.g.',
    48025620: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde 297 mm en de lengte van de andere zijde 210 mm bedraagt "formaat A4", bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van >= 40 doch <= 150 g/m², n.e.g.',
    48025680: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde <= 435 mm en de lengte van de andere zijde  <= 297 mm bedraagt, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van >= 40 doch <= 150 g/m², n.e.g. (m.u.v. papier en karton waarvan in ongevouwen staat de lengte van een zijde 297 mm en de lengte van de andere zijde 210 mm bedraagt "formaat A4")',
    48025700: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 435 mm bedraagt, dan wel de lengte van een zijde <= 435 mm en de lengte van de andere zijde > 297 mm, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van >= 40 doch <= 150 g/m², n.e.g.',
    48025810: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen, ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van > 150 g/m², n.e.g.',
    48025890: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in vierkante of rechthoekige bladen, ongeacht het formaat, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van > 150 g/m², n.e.g.',
    48026115: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen, ongeacht het formaat, bevattende > 50 gewichtspercenten langs mechanische weg verkregen vezels "berekend over de totale vezelmassa", met een gewicht van < 72 g/m², n.e.g.',
    48026180: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, op rollen, ongeacht het formaat, bevattende > 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", n.e.g. (m.u.v. papier en karton met een gewicht van < 72 g/m² bevattende > 50 gewichtspercenten vezels)',
    48026200: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde <= 435 mm bedraagt en de lengte van de andere zijde <= 297 mm bedraagt, bevattende > 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", n.e.g.',
    48026900: 'papier en karton, ongestreken en zonder deklaag, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, en papier en karton, ongeperforeerd, voor ponskaarten of ponsband, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 435 mm bedraagt, dan wel de lengte van een zijde <= 435 mm en de lengte van de andere zijde > 297 mm, bevattende > 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", n.e.g.',
    48030010: 'cellulosewatten, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48030031: 'papier, gecrept, huishoudelijk, hygiënisch of toiletgebruik, en vliezen van cellulosevezels "zgn. tissue", op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht, per laag, van <= 25 g/m²',
    48030039: 'papier, gecrept, huishoudelijk, hygiënisch of toiletgebruik, en vliezen van cellulosevezels "zgn. tissue", op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht, per laag, van > 25 g/m²',
    48030090: 'toilet-, handdoek-, servetten e.d. papier voor huishoudelijk, hygiënisch of toiletgebruik, cellulosewatten en vliezen van cellulosevezels, ook indien gecrept, geplisseerd, gegaufreerd, gegreineerd, geperforeerd of met gekleurd, versierd of bedrukt oppervlak, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. cellulosewatten, gecrept papier, vliezen van cellulosevezels "zgn. tissue")',
    48041111: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, met een gewicht van < 150 g/m² (m.u.v. goederen bedoeld bij de posten 4802 en 4803)',
    48041115: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, met een gewicht van >= 150 doch < 175 g/m² (m.u.v. goederen bedoeld bij de posten 4802 en 4803)',
    48041119: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, met een gewicht van >= 175 g/m² (m.u.v. goederen bedoeld bij de posten 4802 en 4803)',
    48041190: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt (m.u.v. kraftliner waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen en goederen bedoeld bij de posten 4802 en 4803)',
    48041912: 'Kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, bestaande uit een of meer ongebleekte lagen kraftliner en een gebleekte, halfgebleekte of gekleurde buitenste laag kraftliner, met een gewicht van < 175 g/m² (m.u.v. goederen bedoeld bij de posten 4802 en 4803)',
    48041919: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, bestaande uit een of meer ongebleekte lagen kraftliner en een gebleekte, halfgebleekte of gekleurde buitenste laag kraftliner, met een gewicht van >= 175 g/m² (m.u.v. goederen bedoeld bij de posten 4802 en 4803)',
    48041930: 'Kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. ongebleekte kraftliner, kraftliner bestaande uit een of meer ongebleekte lagen kraftliner en een gebleekte, halfgebleekte of gekleurde buitenste laag kraftliner, en goederen bedoeld bij de posten 4802 en 4803)',
    48041990: 'kraftliner, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm (m.u.v. ongebleekte kraftliner, kraftliner waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit m.b.v. het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, en goederen bedoeld bij de posten 4802 en 4803)',
    48042110: 'kraftpapier voor de vervaardiging van grote zakken, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48042190: 'kraftpapier voor de vervaardiging van grote zakken, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, ongebleekt (m.u.v. kraftpapier waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit m.b.v. het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48042910: 'kraftpapier voor de vervaardiging van grote zakken, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. ongebleekt kraftpapier, alsmede goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48042990: 'kraftpapier voor de vervaardiging van grote zakken, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm (m.u.v. ongebleekt kraftpapier, kraftpapier waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit m.b.v. het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48043151: 'isolatiekraftpapier voor elektrotechnisch gebruik, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. kraftliner en kraftpapier voor de vervaardiging van grote zakken)',
    48043158: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², ongebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. isolatiekraftpapier voor elektrotechnisch gebruik, kraftliner, kraftpapier voor de vervaardiging van grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48043180: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², ongebleekt (m.u.v. dat waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit m.b.v. het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, en m.u.v. kraftliner en kraftpapier voor de vervaardiging van grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48043951: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², gelijkmatig in de massa gebleekt, waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. dat bestemd voor de vervaardiging van papiergarens bedoeld bij post 5308 of 5607, en m.u.v  isolatiekraftpapier voor elektrotechnisch gebruik, kraftliner, kraftpapier voor de vervaardiging van grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48043958: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. papier of karton, ongebleekt of gelijkmatig in de massa gebleekt, kraftliner, kraftpapier voor de vervaardiging van grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48043980: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m² (m.u.v. dat waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen, en m.u.v. ongebleekt papier en karton, kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48044191: 'zgn. "saturating kraft", ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 doch < 225 g/m², ongebleekt',
    48044198: 'Ongebleekt kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 doch < 225 g/m², (m.u.v. zgn. "saturating kraft", kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48044200: 'Kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 doch < 225 g/m², gelijkmatig in de massa gebleekt en waarvan > 95 gewichtspercenten van de totale vezelmassa bestaat uit langs chemische weg ontsloten houtvezels (m.u.v. kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48044900: 'Kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 doch < 225 g/m² (m.u.v. ongebleekt of gelijkmatig in de massa gebleekt papier en karton waarvan > 95 gewichtspercenten van de totale vezelmassa bestaat uit langs chemische weg ontsloten houtvezels, kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48045100: 'Ongebleekt kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m², ( m.u.v. kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48045200: 'Kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m², gelijkmatig in de massa gebleekt en waarvan > 95 gewichtspercenten van de totale vezelmassa bestaat uit langs chemische weg ontsloten houtvezels ( (m.u.v. kraftliner, kraftpapier voor grote zakken, en goederen bedoeld bij de posten 4802, 4803 en 4808)',
    48045910: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m², waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen (m.u.v. ongebleekt of gelijkmatig in de massa gebleekt papier en karton waarvan > 95 gewichtspercenten van de totale vezelmassa bestaat uit langs chemische weg ontsloten houtvezels)',
    48045990: 'kraftpapier en kraftkarton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m² (m.u.v. ongebleekt of gelijkmatig in de massa gebleekt papier en karton waarvan > 95 gewichtspercenten van de totale vezelmassa bestaat uit langs chemische weg ontsloten houtvezels of waarvan >= 80 gewichtspercenten van de totale vezelmassa bestaat uit met behulp van het sulfaat- of natronproces ontsloten houtvezels van naaldbomen)',
    48051100: 'halfchemisch papier voor riffels, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm',
    48051200: 'stropapier voor riffels, op rollen met een breedte van > 36 cm, met een gewicht van >= 130 g/m²',
    48051910: 'zgn. "wellenstoff", ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48051990: 'papier voor riffels, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. halfchemisch papier, stropapier voor riffels en zgn. "wellenstoff")',
    48052400: 'zgn. "testliner" "herwonnen vezels", ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m²',
    48052500: 'zgn. "testliner" "herwonnen vezels", ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 g/m²',
    48053000: 'sulfietpakpapier ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48054000: 'filtreerpapier en -karton, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48055000: 'viltpapier en -karton, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48059100: 'papier en karton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van <= 150 g/m², n.e.g.',
    48059200: 'papier en karton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van > 150 doch < 225 g/m², n.e.g.',
    48059320: 'papier en karton vervaardigd van teruggewonnen papier, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m², n.e.g.',
    48059380: 'papier en karton, ongestreken en zonder deklaag, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt, met een gewicht van >= 225 g/m², n.e.g.',
    48061000: 'perkamentpapier en perkamentkarton, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48062000: 'vetvrij papier "greaseproof", op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48063000: 'calqueerpapier, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48064010: 'kristalpapier, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48064090: 'door kalanderen verkregen doorschijnend of doorzichtig papier, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. perkamentpapier, perkamentkarton, vetvrij papier "greaseproof", calqueerpapier en kristalpapier)',
    48070030: 'papier en karton, vervaardigd van teruggewonnen papier, samengesteld uit opeengelijmde vellen, ook indien met papier bekleed, ongeïmpregneerd, ongestreken en zonder deklaag, ook indien inwendige versterkt, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. papier en karton, samengesteld uit door middel van bitumen, teer of asfalt opeengelijmde vellen)',
    48070080: 'papier en karton, samengesteld uit opeengelijmde vellen, ongeïmpregneerd, ongestreken en zonder deklaag, ook indien inwendig versterkt, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. papier en karton, samengesteld uit d.m.v. bitumen, teer of asfalt opeengelijmde vellen, stropapier en -karton, ook indien met een buitenste laag van ander papier dan stropapier, en papier en karton vervaardigd van teruggewonnen papier, ook indien met papier bekleed)',
    48081000: 'gegolfd papier en gegolfd karton "ook indien daarop papier of karton in vlakke bladen is gelijmd", ook indien geperforeerd, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48084000: 'kraftpapier, gecrêpt of geplisseerd, ook indien gegaufreerd (voorzien van inpersingen), gegreineerd of geperforeerd, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt',
    48089000: 'papier en karton, gecrept, geplisseerd, gegaufreerd "voorzien van inpersingen", gegreineerd of geperforeerd, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. kraftpapier voor de vervaardiging van grote zakken en ander kraftpapier; papier van de soort beschreven in post 4803)',
    48092000: 'Zelfkopiërend papier, ook indien bedrukt, op rollen met een breedte van > 36 cm of  in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. carbonpapier e.d. papier)',
    48099000: 'Carbonpapier en ander papier voor het maken van doorslagen en overdrukken, (gestreken, van een deklaag voorzien of geïmpregneerd papier voor stencils of offsetplaten), ook indien bedrukt, op rollen met een breedte van > 36 cm of in vierkante of rechthoekige bladen waarvan in ongevouwen toestand de lengte van een zijde > 36 cm en de andere zijde > 15 cm bedraagt (m.u.v. zelfkopiërend papier)',
    48101300: 'papier en karton, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, op rollen, ongeacht het formaat',
    48101400: 'papier en karton, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde <= 435 mm en de lengte de lengte van de andere zijde <= 297 mm bedraagt',
    48101900: 'papier en karton, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, bevattende geen of <= 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, in vierkante of rechthoekige bladen waarvan in ongevouwen staat de lengte van een zijde > 435 mm bedraagt en de lengte van de andere zijde > 297 mm',
    48102200: 'licht gestreken papier "zgn. L.W.C.-papier", van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, dat voor > 10 gewichtspercenten van de totale vezelmassa uit langs mechanische weg of chemisch-mechanische weg verkregen houtvezels bestaat, aan een of beide zijden gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat',
    48102930: 'papier en karton van de soort om te worden beschreven of bedrukt, of voor andere grafische doeleinden, bevattende > 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, op rollen ongeacht het formaat (m.u.v. licht gestreken papier "zgn. L.W.C.-papier" en m.u.v. die voor kantoormachines en dergelijke)',
    48102980: 'papier en karton van de soort om te worden beschreven of bedrukt, of voor andere grafische doeleinden, bevattende > 10 gewichtspercenten langs mechanische of chemisch-mechanische weg verkregen vezels "berekend over de totale vezelmassa", aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, in vierkante of rechthoekige bladen ongeacht het formaat (m.u.v. licht gestreken papier "zgn. L.W.C.-papier" en m.u.v. die voor kantoormachines en dergelijke)',
    48103100: 'kraftpapier en -karton, gelijkmatig in de massa gebleekt, dat voor > 95 gewichtspercenten van de totale vezelmassa uit langs chemische weg verkregen vezels bestaat, aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat, met een gewicht van <= 150 g/m² (m.u.v. die van de soort om te worden beschreven, bedrukt, of voor andere grafische doeleinden)',
    48103210: 'kraftpapier en -karton, gelijkmatig in de massa gebleekt, dat voor > 95 gewichtspercenten van de totale vezelmassa uit langs chemische weg verkregen vezels bestaat, aan een of aan beide zijden gestreken of voorzien van een deklaag van kaolien, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat, met een gewicht van > 150 g/m² (m.u.v. die van de soort om te worden beschreven, bedrukt, of voor andere grafische doeleinden)',
    48103290: 'kraftpapier en -karton, gelijkmatig in de massa gebleekt, dat voor > 95 gewichtspercenten van de totale vezelmassa uit langs chemische weg verkregen vezels bestaat, aan een of aan beide zijden gestreken of voorzien van een deklaag van anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat, met een gewicht van > 150 g/m² (m.u.v. die, gestreken met of voorzien van een deklaag van kaolien; papier en karton voor grafische doeleinden)',
    48103900: 'kraftpapier en -karton, aan een of aan beide zijden gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die van de soorten die worden gebruikt om te worden beschreven, bedrukt, of voor andere grafische doeleinden; papier en karton gelijkmatig in de massa gebleekt, met een gehalte aan uit langs chemische weg ontsloten vezels, berekend op de totale vezelmassa van > 95 gewichtspercenten)',
    48109210: 'multiplexpapier en -karton waarvan alle lagen zijn gebleekt, aan een of aan beide gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden en m.u.v. kraftpapier en  karton)',
    48109230: 'multiplexpapier en -karton waarvan slechts één buitenste laag is gebleekt, aan een of aan beide gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden en m.u.v. kraftpapier en  karton)',
    48109290: 'multiplexpapier en -karton, aan een of aan beide gestreken met kaolien of met andere anorganische stoffen, op rollen, in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die waarvan alle lagen zijn gebleekt of waarvan slechts één buitenste laag is gebleekt en die van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden en m.u.v. kraftpapier en  karton)',
    48109910: 'papier of karton, gebleekt, aan een of aan beide zijden gestreken met of voorzien van een deklaag van kaolien, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden en m.u.v. kraftpapier en  karton, multiplexpapier en  karton en papier en karton voorzien van elke andere deklaag)',
    48109980: 'papier en karton, aan een of aan beide zijden gestreken met anorganische stoffen, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die welke zijn gebleekt, en die van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden en m.u.v. kraftpapier en  -karton, multiplexpapier en  -karton en papier en karton voorzien van elke andere deklaag)',
    48111000: 'papier en karton, geteerd, gebitumineerd of geasfalteerd, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat',
    48114120: 'papier en karton, zelfklevend, aan het oppervlak gekleurd of versierd, dan wel bedrukt, in stroken, op rollen of in bladen met een breedte van <= 10 cm, waarvan het kleefmiddel bestaat uit niet gevulkaniseerde natuurlijke of synthetische rubber',
    48114190: 'papier en karton, zelfklevend, aan het oppervlak gekleurd of versierd, dan wel bedrukt, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die met een breedte van <= 10 cm, waarvan het kleefmiddel bestaat uit niet gevulkaniseerde natuurlijke of synthetische rubber, en m.u.v. goederen bedoeld bij post 4810)',
    48114900: 'papier en karton, aan het oppervlak gekleurd of versierd, dan wel bedrukt, voorzien van een kleefmiddel, niet-zelfklevend, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. goederen bedoeld bij post 4810)',
    48115100: 'papier en karton, aan het oppervlak gekleurd of versierd, dan wel bedrukt, voorzien van een deklaag van, dan wel geïmpregneerd of bekleed met kunststof, gebleekt, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat, en met een gewicht van > 150 g/m² (m.u.v. die voorzien van een kleefmiddel)',
    48115900: 'papier en karton, aan het oppervlak gekleurd of versierd, dan wel bedrukt, voorzien van een deklaag van, dan wel geïmpregneerd of bekleed met kunststof, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. die voorzien van een kleefmiddel en m.u.v. papier en karton, gebleekt en met een gewicht van > 150 g/m²)',
    48116000: 'papier en karton, voorzien van een deklaag van, dan wel geïmpregneerd of bekleed met was, paraffine, stearine, olie of glycerol, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. producten als bedoeld bij 4803, 4809 of 4818)',
    48119000: 'papier, karton, cellulosewatten en vliezen van cellulosevezels, gestreken, van een deklaag voorzien, geïmpregneerd, aan het oppervlak gekleurd of versierd, dan wel bedrukt, op rollen of in vierkante of rechthoekige bladen, ongeacht het formaat (m.u.v. goederen bedoeld bij de posten 4803, 4809, 4810 of 4818 en de onderverdelingen 4811.10 tot en met 4811.60)',
    48120000: 'blokken en platen, van papierstof, voor filtreerdoeleinden',
    48131000: 'sigarettenpapier, in boekjes of in hulzen',
    48132000: 'sigarettenpapier, op rollen met een breedte van <= 5 cm',
    48139010: 'sigarettenpapier,  op rollen met een breedte van > 5 cm doch <= 15 cm',
    48139090: 'sigarettenpapier, ook indien op maat gesneden (m.u.v. dat in boekjes of in hulzen en dat op rollen met een breedte van <= 15 cm)',
    48142000: 'behangselpapier e.d. wandbekleding, bestaande uit papier, aan de voorzijde voorzien van een deklaag van of bekleed met kunststof die is gegreineerd, gegaufreerd, gekleurd, met motieven bedrukt of op andere wijze versierd',
    48149010: 'behangselpapier e.d. wandbekleding, bestaande uit gegreineerd, gegaufreerd, aan het oppervlak gekleurd, met motieven bedrukt of op andere wijze aan het oppervlak versierd papier, voorzien van een deklaag van of bekleed met een doorzichtige beschermende kunststof',
    48149070: 'behangselpapier e.d. wandbekleding van papier, vitrofanie (m.u.v. goederen bedoeld bij de onderverdelingen 4814.2000 en 4814.9010)',
    48162000: 'zelfkopiërend papier, op rollen met een breedte van <= 36 cm of in vierkante of rechthoekige bladen waarvan geen der zijden in ongevouwen toestand > 36 cm is, of in andere dan vierkante of rechthoekige vorm gesneden, ook indien verpakt in dozen (m.u.v. carbonpapier e.d. papier)',
    48169000: 'papier voor het maken van doorslagen en overdrukken, op rollen met een breedte van <= 36 cm of in vierkante of rechthoekige bladen waarvan geen der zijden in ongevouwen toestand > 36 cm is, of in andere dan vierkante of rechthoekige vorm gesneden, ook indien verpakt in dozen, alsmede offsetplaten van papier (m.u.v. zelfkopiërend papier)',
    48171000: 'enveloppen, van papier of van karton (m.u.v. postbladen)',
    48172000: 'postbladen, briefkaarten en correspondentiekaarten, van papier of van karton (m.u.v. die gefrankeerd met een gedrukt postvignet; prentbriefkaarten)',
    48173000: 'assortimenten van papierwaren voor correspondentie in dozen, in omslagen of in dergelijke verpakkingen, van papier of van karton',
    48181010: 'closetpapier, op rollen met een breedte van <= 36 cm, met een gewicht, per laag, van <= 25 g/m²',
    48181090: 'closetpapier, op rollen met een breedte van <= 36 cm, met een gewicht, per laag, van > 25 g/m²',
    48182010: 'zakdoeken en toiletdoekjes, van papierstof, van papier, van cellulosewatten of van cellulosevezels',
    48182091: 'handdoeken, van papierstof, van papier, van cellulosewatten of van cellulosevezels, op rollen met een breedte van > 36 cm',
    48182099: 'handdoeken, van papierstof, van papier, van cellulosewatten of van cellulosevezels (m.u.v. die op rollen met een breedte van > 36 cm)',
    48183000: 'tafellakens en servetten, van papierstof, van papier, van cellulosewatten of van cellulosevezels',
    48185000: 'kleding en kledingtoebehoren, van papierstof, van papier, van cellulosewatten of van cellulosevezels (m.u.v. schoeisel en delen daarvan, incl. inlegzolen, hielkussens e.d. artikelen, slobkousen, beenkappen e.d. artikelen; hoofddeksels en delen daarvan)',
    48189010: 'artikelen van papierstof, van papier, van cellulosewatten of van cellulosevliezen, voor chirurgisch, medisch of hygiënisch gebruik (m.u.v. closetpapier, zakdoeken, toiletdoekjes, handdoeken, tafellakens, servetten, maandverbanden en tampons, luiers e.d. artikelen voor hygiënisch gebruik; goederen opgemaakt voor de verkoop in het klein)',
    48189090: 'papier, cellulosewatten en vliezen van cellulosevezels, van de soort gebruikt voor huishoudelijke of sanitaire doeleinden, op rollen met een breedte van <= 36 cm of in op maat gesneden bladen; artikelen van papierstof, van papier, van cellulosewatten of van cellulosevezels, voor huishoudelijk, hygiënisch of klinisch gebruik (m.u.v. toiletpapier, zakdoeken, toiletdoekjes, handdoeken, tafellakens, servetten, maandverbanden, tampons, luiers, inlegluiers e.d. artikelen voor hygiënisch gebruik en m.u.v. artikelen voor chirurgisch, medisch of hygiënisch gebruik, niet opgemaakt voor de verkoop in het klein)',
    48191000: 'dozen van gegolfd papier of van gegolfd karton',
    48192000: 'vouwdozen (m.u.v. die van gegolfd papier of van gegolfd karton)',
    48193000: 'zakken, van papier of karton, van cellulosewatten of van vliezen van cellulosevezels, met een bodembreedte van >= 40 cm',
    48194000: 'zakken, incl. puntzakken, van papier, karton, van cellulosewatten of van vliezen van cellulosevezels (m.u.v. zakken met een bodembreedte van >= 40 cm; hoezen voor grammofoonplaten)',
    48195000: 'verpakkingsmiddelen, incl. hoezen voor grammofoonplaten, van papier, karton, van cellulosewatten of van vliezen van cellulosevezels (m.u.v. dozen van gegolfd papier of van gegolfd karton; vouwdozen van niet-gegolfd papier of van karton; zakken en puntzakken)',
    48196000: 'kartonnagewerk in de vorm van stijve dozen en bergingsmiddelen voor kantoorgebruik, voor winkelgebruik en voor dergelijk gebruik (m.u.v. verpakkingsmiddelen)',
    48201010: 'Registers, comptabiliteitsboeken, orderboekjes en kwitantieboekjes',
    48201030: 'zakboekjes, blocnotes, brievenblocs en memorandablocs',
    48201050: 'agenda\'s',
    48201090: 'Registers, comptabiliteitsboeken, zakboekjes, orderboekjes, kwitantieboekjes, agenda\'s, blocnotes e.d. artikelen, n.e.g.',
    48202000: 'schriften',
    48203000: 'opbergmappen, mappen en banden en omslagen voor dossiers, van papier of van karton (m.u.v. boekomslagen)',
    48204000: 'Sets kettingformulieren en sets formulieren, ook indien voorzien van carbonpapier',
    48205000: 'albums voor monstercollecties of voor verzamelingen, van papier of van karton',
    48209000: 'onderleggers en andere schoolartikelen, kantoorartikelen e.d. artikelen, van papier of van karton, alsmede boekomslagen, van papier of van karton (m.u.v. registers, comptabiliteitsboeken, zakboekjes, orderboekjes, kwitantieboekjes, agenda\'s, blocnotes, schriften, opbergmappen, mappen en banden, omslagen voor dossiers, sets kettingformulieren en andere sets formulieren, albums voor monstercollecties of voor verzamelingen)',
    48211010: 'etiketten van alle soorten, van papier of van karton, bedrukt, zelfklevend',
    48211090: 'etiketten van alle soorten, van papier of van karton, bedrukt (m.u.v. zelfklevende etiketten)',
    48219010: 'etiketten van alle soorten, van papier of van karton, onbedrukt, zelfklevend',
    48219090: 'etiketten van alle soorten, van papier of van karton, onbedrukt (m.u.v. zelfklevende etiketten)',
    48221000: 'klossen, hulzen, buisjes, spoelen e.d. opwindmiddelen, van papierstof, van papier of van karton, ook indien geperforeerd of gehard, van de soort gebruikt voor het opwinden van textielgarens',
    48229000: 'klossen, hulzen, buisjes, spoelen e.d. opwindmiddelen, van papierstof, van papier of van karton, ook indien geperforeerd of gehard (m.u.v. die van de soort gebruikt voor het opwinden van textielgarens)',
    48232000: 'filtreerpapier en -karton, in stroken of op rollen met een breedte van <= 36 cm of in vierkante of rechthoekige bladen waarvan geen der zijden in ongevouwen staat > 36 cm is of in andere dan vierkantige of rechthoekige vorm op maat gesneden',
    48234000: 'diagrampapier voor registreerapparaten, op rollen met een breedte van <= 36 cm, in vierkante of rechthoekige bladen waarvan in ongevouwen staat geen der zijden > 36 cm is, of in schijven',
    48236100: 'presenteerbladen, schalen, borden, kopjes, bekers e.d. artikelen, van bamboepapier of van bamboekarton',
    48236910: 'presenteerbladen, schalen en borden (m.u.v. dergelijke artikelen van bamboepapier of van bamboekarton)',
    48236990: 'kopjes, bekers e.d. artikelen, van papier of van karton (m.u.v. dergelijke artikelen van bamboepapier of van bamboekarton en m.u.v. presenteerbladen, schalen en borden)',
    48237010: 'gevormde platen en gevormde dozen, voor het verpakken van eieren, van papierstof',
    48237090: 'werken van papierstof, gevormd of geperst, n.e.g.',
    48239040: 'papier en karton, van de soort gebruikt om te worden beschreven of bedrukt of voor andere grafische doeleinden, n.e.g.',
    48239085: 'Papier, karton, cellulosewatten en vliezen van cellulosevezels, in stroken of op rollen, met een breedte van <= 36 cm, dan wel in vierkante of rechthoekige bladen waarvan in ongevouwen staat geen der zijden > 36 cm is of in andere dan vierkante of rechthoekige vorm op maat gesneden, alsmede werken van papierstof, papier, karton, cellulosewatten of vliezen van cellulosevezels, n.e.g.',
    49011000: 'boeken, brochures e.d. drukwerk, in losse vellen, ook indien gevouwen (m.u.v. tijdschriften en drukwerk dat hoofdzakelijk bedoeld is voor het maken van reclame)',
    49019100: 'woordenboeken en encyclopedieën, ook indien in afleveringen',
    49019900: 'boeken, brochures e.d. drukwerk (m.u.v. die in losse vellen en m.u.v. woordenboeken, encyclopedieën, tijdschriften en drukwerk dat hoofdzakelijk is bedoeld voor het maken van reclame)',
    49021000: 'couranten en tijdschriften, ook indien geïllustreerd of reclame bevattend, vier keer of meer per week verschijnend',
    49029000: 'Couranten en tijdschriften, ook indien geïllustreerd of reclame bevattend (m.u.v. die welke vier keer of meer per week verschijnend)',
    49030000: 'prentenalbums, prentenboeken, tekenboeken en kleurboeken, voor kinderen',
    49040000: 'geschreven of gedrukte muziek, ook indien geïllustreerd en ook indien ingebonden of ingenaaid',
    49051000: 'gedrukte cartografische globes (m.u.v. globes in reliëf)',
    49059100: 'gedrukte cartografische werken van alle soorten, incl. atlassen, plattegronden in de vorm van boeken en brochures (m.u.v. globes en m.u.v. kaarten en plattegronden in reliëf)',
    49059900: 'gedrukte cartografische werken van alle soorten, incl. atlassen, wandkaarten, plattegronden (m.u.v. die in de vorm van boeken en brochures, m.u.v. globes en m.u.v. kaarten en plattegronden in reliëf)',
    49060000: 'bouwplannen en -tekeningen, alsmede andere plannen en tekeningen, voor technische, industriële, commerciële, topografische e.d. doeleinden, zijnde met de hand vervaardigde originelen; handgeschreven teksten; fotografische reproducties op lichtgevoelig papier en carbonafdrukken van de hiervoor bedoelde plannen, tekeningen en teksten',
    49070010: 'postzegels, fiscale zegels en dergelijke, ongestempeld, die geldig zijn of zullen worden in het land waar ze een erkende nominale waarde hebben of zullen hebben',
    49070030: 'bankbiljetten',
    49070090: 'gezegeld papier; cheques, aandelen, obligaties e.d. effecten',
    49081000: 'verglaasbare decalcomanieën',
    49089000: 'decalcomanieën van alle soorten (m.u.v. verglaasbare decalcomanieën)',
    49090000: 'Prentbriefkaarten en andere gedrukte briefkaarten; gedrukte kaarten met persoonlijke wensen of mededelingen, ook indien geïllustreerd of met garneringen en ook indien met enveloppen',
    49100000: 'kalenders van alle soorten, gedrukte, kalenderblokken daaronder begrepen',
    49111010: 'Handelscatalogi',
    49111090: 'Reclamedrukwerk e.d. (m.u.v. handelscatalogi)',
    49119100: 'prenten, gravures, foto\'s en andere afbeeldingen, n.e.g.',
    49119900: 'drukwerk, n.e.g.',
    50010000: 'cocons van zijderupsen, geschikt om te worden afgehaspeld',
    50020000: 'ruwe zijde "haspelzijde of grège", ongemoulineerd',
    50030000: 'afval van zijde, incl. cocons ongeschikt om te worden afgehaspeld, afval van garen en rafelingen',
    50040010: 'garens van zijde, ongebleekt, ontgomd of gebleekt (m.u.v. garens van afval van zijde; garens opgemaakt voor de verkoop in het klein)',
    50040090: 'garens van zijde (m.u.v. ongebleekte, ontgomde of gebleekte garens; garens van afval van zijde; garens opgemaakt voor de verkoop in het klein)',
    50050010: 'garens van afval van zijde, ongebleekt, ontgomd of gebleekt (m.u.v. garens opgemaakt voor de verkoop in het klein)',
    50050090: 'garens van afval van zijde (m.u.v. ongebleekte, ontgomde of gebleekte garens en m.u.v. garens opgemaakt voor de verkoop in het klein)',
    50060010: 'garens van zijde, opgemaakt voor de verkoop in het klein (m.u.v. garens van afval van zijde)',
    50060090: 'garens van vlokzijde of van bourrette, opgemaakt voor de verkoop in het klein; poil de Messine "crin de Florence"',
    50071000: 'weefsels van bourrette',
    50072011: 'crêpe, bevattende >= 85 gewichtspercenten zijde of vlokzijde, ongebleekt, ontgomd of gebleekt',
    50072019: 'crêpe, bevattende >= 85 gewichtspercenten zijde of vlokzijde (m.u.v. ongebleekte, ontgomde of gebleekte crêpe)',
    50072021: 'pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde, met platbinding, ongebleekt of enkel ontgomd (m.u.v. die welke zijn vermengd met vlokzijde, met bourrette of met andere textielstoffen)',
    50072031: 'pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde, met platbinding (m.u.v. die vermengd met vlokzijde, met bourrette of met andere textielstoffen en m.u.v. ongebleekte of enkel ontgomde weefsels)',
    50072039: 'pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde, niet vermengd met vlokzijde, met bourrette of met andere textielstoffen (m.u.v. die met platbinding)',
    50072041: 'doorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde',
    50072051: 'ondoorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde, ongebleekt, ontgomd of gebleekt (m.u.v. crêpe en m.u.v. pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde)',
    50072059: 'ondoorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde, geverfd (m.u.v. crêpe en m.u.v. pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde)',
    50072061: 'ondoorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde, van verschillend gekleurd garen, met een breedte van > 57 cm, doch <= 75 cm (m.u.v. crêpe en m.u.v. pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde)',
    50072069: 'ondoorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde, van verschillend gekleurd garen (m.u.v. die met een breedte van > 57 cm, doch <= 75 cm, m.u.v. crêpe en m.u.v. pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde)',
    50072071: 'ondoorzichtige weefsels, bevattende >= 85 gewichtspercenten zijde of vlokzijde, bedrukt (m.u.v. crêpe en m.u.v. pongée, habutai, honan, shantoeng, corah e.d. weefsels uit het Verre Oosten, van zuivere zijde)',
    50079010: 'weefsels, die hoofdzakelijk, doch < 85 gewichtspercenten zijde of vlokzijde bevatten, ongebleekt, ontgomd of gebleekt',
    50079030: 'weefsels, die hoofdzakelijk, doch < 85 gewichtspercenten zijde of vlokzijde bevatten, geverfd',
    50079050: 'weefsels, die hoofdzakelijk, doch < 85 gewichtspercenten zijde of vlokzijde bevatten, van verschillend gekleurd garen',
    50079090: 'weefsels, die hoofdzakelijk, doch < 85 gewichtspercenten zijde of vlokzijde bevatten, bedrukt',
    51011100: 'ongewassen scheerwol, incl. ruggewassen wol, ongekaard en ongekamd',
    51011900: 'ongewassen wol, incl. ruggewassen wol, ongekaard en ongekamd (m.u.v. scheerwol)',
    51012100: 'scheerwol, ontvet, ongecarboniseerd, ongekaard en ongekamd',
    51012900: 'wol, ontvet, ongecarboniseerd, ongekaard en ongekamd (m.u.v. scheerwol)',
    51013000: 'wol, gecarboniseerd, ongekaard en ongekamd',
    51021100: 'haar van kasjmiergeiten, ongekaard en ongekamd',
    51021910: 'haar van angorakonijnen, ongekaard en ongekamd',
    51021930: 'haar van alpaca\'s, van lama\'s en van vicuna\'s "vigognes", ongekaard en ongekamd',
    51021940: 'haar van kamelen, van yakken, van angora- en tibetgeiten en van dergelijke geiten, ongekaard en ongekamd',
    51021990: 'haar van konijnen, van hazen, van bevers, van nutria\'s en van muskusratten, ongekaard en ongekamd (m.u.v. haar van angorakonijnen)',
    51022000: 'grof haar, ongekaard en ongekamd (m.u.v. dierlijk haar voor borstelwerk en paardenhaar van de manen of van de staart)',
    51031010: 'kammeling van wol of van fijn haar, ongecarboniseerd (m.u.v. rafelwol en rafelingen van haar)',
    51031090: 'kammeling van wol of van fijn haar, gecarboniseerd (m.u.v. rafelwol en rafelingen van haar)',
    51032000: 'Afval van wol of van fijn haar, incl. afval van garen(m.u.v. kammeling, rafelwol en rafelingen van haar)',
    51033000: 'afval van grof haar, incl. garenafval van grof haar (m.u.v. rafelwol, rafelingen van haar, afval van haar voor borstelwerk en afval van paardenhaar van de manen of van de staart)',
    51040000: 'rafelwol en rafelingen van fijn haar of van grof haar, ongekaard en ongekamd',
    51051000: 'gekaarde wol',
    51052100: 'gekamd vlies',
    51052900: 'gekamde wol (m.u.v. gekamd vlies)',
    51053100: 'fijn haar van kasjmiergeiten, gekaard of gekamd',
    51053900: 'Fijn haar, gekaard of gekamd (m.u.v. wol en fijn haar van kasjmiergeiten)',
    51054000: 'grof haar, gekaard of gekamd',
    51061010: 'kaardgaren bevattende >= 85 gewichtspercenten wol, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein)',
    51061090: 'kaardgaren bevattende >= 85 gewichtspercenten wol (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt kaardgaren)',
    51062010: 'kaardgaren hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, bevattende >= 85 gewichtspercenten wol en fijn haar (m.u.v. dat opgemaakt voor de verkoop in het klein)',
    51062091: 'kaardgaren hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. kaardgaren bevattende >= 85 gewichtspercenten wol en fijn haar)',
    51062099: 'kaardgaren hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. kaardgaren bevattende >= 85 gewichtspercenten wol en fijn haar en m.u.v. ongebleekt garen)',
    51071010: 'kamgaren bevattende >= 85 gewichtspercenten wol, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein)',
    51071090: 'kamgaren bevattende >= 85 gewichtspercenten wol (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt kaardgaren)',
    51072010: 'kamgaren hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, bevattende >= 85 gewichtspercenten wol en fijn haar, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein)',
    51072030: 'kamgaren hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, bevattende >= 85 gewichtspercenten wol en fijn haar (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt kamgaren)',
    51072051: 'kamgaren, hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, enkel of hoofdzakelijk gemengd met synthetische stapelvezels, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein)',
    51072059: 'kamgaren, hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, enkel of hoofdzakelijk gemengd met synthetische stapelvezels (m.u.v. dat opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt kamgaren)',
    51072091: 'kamgaren, hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend, ongebleekt (m.u.v. dat opgemaakt voor de verkoop in het klein, m.u.v. garen enkel of hoofdzakelijk gemengd met synthetische stapelvezels en m.u.v. garen bevattend >= 85 gewichtspercenten wol en fijn haar)',
    51072099: 'kamgaren, hoofdzakelijk, doch < 85 gewichtspercenten wol bevattend (m.u.v. dat opgemaakt voor de verkoop in het klein, m.u.v. garen enkel of hoofdzakelijk gemengd met synthetische stapelvezels en m.u.v. garen bevattend >= 85 gewichtspercenten wol en fijn haar en m.u.v. ongebleekt garen)',
    51081010: 'kaardgaren van fijn haar, ongebleekt (m.u.v. die van wol en m.u.v. die opgemaakt voor de verkoop in het klein)',
    51081090: 'kaardgaren van fijn haar (m.u.v. die van wol en m.u.v. die opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt garen)',
    51082010: 'kamgaren van fijn haar, ongebleekt (m.u.v. die van wol en m.u.v. die opgemaakt voor de verkoop in het klein)',
    51082090: 'kamgaren van fijn haar (m.u.v. die van wol en m.u.v. die opgemaakt voor de verkoop in het klein en m.u.v. ongebleekt garen)',
    51091010: 'garens, bevattend >= 85 gewichtspercenten wol of fijn haar, opgemaakt voor de verkoop in het klein, in bollen, kluwens of strengen met een gewicht van > 125 g, doch <= 500 g',
    51091090: 'garens, bevattend >= 85 gewichtspercenten wol of fijn haar, opgemaakt voor de verkoop in het klein (m.u.v. die in bollen, kluwens of strengen met een gewicht van > 125 g, doch <= 500 g)',
    51099000: 'Garens, hoofdzakelijk, doch < 85 gewichtspercenten wol of fijn haar, opgemaakt voor de verkoop in het klein',
    51100000: 'garens van grof haar of van paardenhaar "crin", incl. omwoeld paardenhaar, ook indien opgemaakt voor de verkoop in het klein (m.u.v. niet aaneengeknoopt paardenhaar)',
    51111100: 'weefsels bevattende >= 85 gewichtspercenten gekaarde wol of gekaard fijn haar, met een gewicht van <= 300 g/m²',
    51111900: 'weefsels bevattende >= 85 gewichtspercenten gekaarde wol of gekaard fijn haar, met een gewicht van > 300 g/m² (m.u.v. weefsels voor technisch gebruik bedoeld bij post 5911)',
    51112000: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar, enkel of hoofdzakelijk met synthetisch of kunstmatige filamenten gemengd',
    51113010: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar, enkel of hoofdzakelijk met synthetisch of kunstmatige stapelvezels gemengd, met een gewicht van <= 300 g/m²',
    51113080: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar, enkel of hoofdzakelijk met synthetisch of kunstmatige stapelvezels gemengd, met een gewicht van > 300 g/m²',
    51119010: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar bevattend, bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette (m.u.v. die welke enkel of hoofdzakelijk met synthetisch of kunstmatige filamenten of stapelvezels zijn gemengd)',
    51119091: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar bevattend, met een gewicht van <= 300 g/m² (m.u.v. die welke enkel of hoofdzakelijk met synthetisch of kunstmatige filamenten of stapelvezels zijn gemengd en m.u.v. weefsels bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette)',
    51119098: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekaarde wol of gekaard fijn haar bevattend, met een gewicht van >300 g/m² (m.u.v. die welke enkel of hoofdzakelijk met synthetisch of kunstmatige filamenten of stapelvezels zijn gemengd en m.u.v. weefsels bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette)',
    51121100: 'weefsels bevattende >= 85 gewichtspercenten gekamde wol of gekamd fijn haar, met een gewicht van <= 200 g/m²',
    51121900: 'weefsels bevattende >= 85 gewichtspercenten gekamde wol of gekamd fijn haar, met een gewicht van > 200 g/m² (m.u.v. weefsels voor technisch gebruik bedoeld bij post 5911)',
    51122000: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar, enkel of hoofdzakelijk met synthetische of kunstmatige filamenten gemengd',
    51123010: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar, enkel of hoofdzakelijk met synthetische of kunstmatige stapelvezels gemengd, met een gewicht van <= 200 g/m²',
    51123080: 'weefsels, hoofdzakelijk doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar, enkel of hoofdzakelijk met synthetische of kunstmatige stapelvezels gemengd, met een gewicht van > 200 g/m² (m.u.v. weefsels voor technisch gebruik bedoeld bij post 5911)',
    51129010: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar bevattend, bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of stapelvezels zijn gemengd)',
    51129091: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar bevattend, met een gewicht van <= 200 g/m² (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of stapelvezels zijn gemengd en m.u.v. weefsels bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette)',
    51129098: 'weefsels, hoofdzakelijk, doch < 85 gewichtspercenten gekamde wol of gekamd fijn haar bevattend, met een gewicht van > 200 g/m² (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of stapelvezels zijn gemengd, weefsels bevattende in totaal > 10 gewichtspercenten zijde, vlokzijde of bourrette en m.u.v. weefsels voor technisch gebruik bedoeld bij post 5911)',
    51130000: 'weefsels van grof haar of van paardenhaar "crin" (m.u.v. weefsels voor technisch gebruik bedoeld bij post 5911)',
    52010010: 'katoen, ongekaard en ongekamd, hydrofiel of gebleekt',
    52010090: 'katoen, ongekaard en ongekamd (m.u.v. hydrofiel of gebleekt katoen)',
    52021000: 'afval van garen van katoen',
    52029100: 'rafelingen van katoen',
    52029900: 'afval van katoen (m.u.v. afval van garen en rafelingen)',
    52030000: 'katoen, gekaard of gekamd',
    52041100: 'naaigarens van katoen, bevattende >= 85 gewichtspercenten katoen (niet opgemaakt voor de verkoop in het klein)',
    52041900: 'naaigarens van katoen, bevattende < 85 gewichtspercenten katoen (niet opgemaakt voor de verkoop in het klein)',
    52042000: 'naaigarens van katoen, opgemaakt voor de verkoop in het klein',
    52051100: 'eendraadsgarens van ongekamde katoenvezels van >= 714,29 decitex "<= 14 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52051200: 'eendraadsgarens van ongekamde katoenvezels van < 714,29, doch >= 232,56 decitex "> 14, doch <= 43 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52051300: 'eendraadsgarens van ongekamde katoenvezels van < 232,56, doch >= 192,31 decitex "> 43, doch <= 52 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52051400: 'eendraadsgarens van ongekamde katoenvezels van < 192,31, doch >= 125 decitex "> 52, doch <= 80 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52051510: 'eendraadsgarens van ongekamde katoenvezels van < 125, doch >= 83,33 decitex "> 80, doch <= 120 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52051590: 'eendraadsgarens van ongekamde katoenvezels van < 83,33 decitex "> 120 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052100: 'eendraadsgarens van gekamde katoenvezels van >= 714,29 decitex "<= 14 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052200: 'eendraadsgarens van gekamde katoenvezels van < 714,29, doch >= 232,56 decitex "> 14, doch <= 43 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052300: 'eendraadsgarens van gekamde katoenvezels van < 232,56, doch >= 192,31 decitex "> 43, doch <= 52 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052400: 'eendraadsgarens van gekamde katoenvezels van < 192,31, doch >= 125 decitex "> 52, doch <= 80 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052600: 'eendraadsgarens van gekamde katoenvezels, van >= 106,38 doch < 125 decitex "> 80 doch <= 94 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052700: 'eendraadsgarens van gekamde katoenvezels, van >= 83,33 doch < 106,38 decitex "> 94 doch <= 120 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52052800: 'eendraadsgarens van gekamde katoenvezels, van < 83,33 decitex "> 120 Nm", bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52053100: 'getwijnde of gekabelde garens van ongekamde katoenvezels van >= 714,29 decitex "<= 14 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52053200: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 714,29, doch >= 232,56 decitex "> 14, doch <= 43 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52053300: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 232,56, doch >= 192,31 decitex "> 43, doch <= 52 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52053400: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 192,31, doch >= 125 decitex "> 52, doch <= 80 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52053500: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 125 decitex "> 80 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054100: 'getwijnde of gekabelde garens van gekamde katoenvezels van >= 714,29 decitex "<= 14 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054200: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 714,29, doch >= 232,56 decitex "> 14, doch <= 43 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054300: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 232,56, doch >= 192,31 decitex "> 43, doch <= 52 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054400: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 192,31, doch >= 125 decitex "> 52, doch <= 80 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054600: 'getwijnde of gekabelde garens van gekamde katoenvezels, van >= 106,38 doch < 125 decitex "> 80 doch <= 94 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054700: 'getwijnde of gekabelde garens van gekamde katoenvezels, van >= 83,33 doch < 106,38 decitex "> 94 doch <= 120 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52054800: 'getwijnde of gekabelde garens van gekamde katoenvezels, van < 83,33 decitex "> 120 Nm" per enkelvoudige draad, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52061100: 'eendraadsgarens van ongekamde katoenvezels van < 714,29 decitex "<= 14 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52061200: 'eendraadsgarens van ongekamde katoenvezels van < 714,29, doch < 232,56 decitex "> 14, doch <= 43 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52061300: 'eendraadsgarens van ongekamde katoenvezels van < 232,56, doch < 192,31 decitex "> 43, doch <= 52 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52061400: 'eendraadsgarens van ongekamde katoenvezels van < 192,31, doch < 125 decitex "> 52, doch <= 80 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52061500: 'eendraadsgarens van ongekamde katoenvezels van < 125 decitex "> 80 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52062100: 'eendraadsgarens van gekamde katoenvezels van < 714,29 decitex "<= 14 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52062200: 'eendraadsgarens van gekamde katoenvezels van < 714,29, doch < 232,56 decitex "> 14, doch <= 43 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52062300: 'eendraadsgarens van gekamde katoenvezels van < 232,56, doch < 192,31 decitex "> 43, doch <= 52 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52062400: 'eendraadsgarens van gekamde katoenvezels van < 192,31, doch < 125 decitex "> 52, doch <= 80 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52062500: 'eendraadsgarens van gekamde katoenvezels van < 125 decitex "> 80 Nm", bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52063100: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 714,29 decitex "<= 14 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52063200: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 714,29, doch < 232,56 decitex "> 14, doch <= 43 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52063300: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 232,56, doch < 192,31 decitex "> 43, doch <= 52 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52063400: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 192,31, doch < 125 decitex "> 52, doch <= 80 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52063500: 'getwijnde of gekabelde garens van ongekamde katoenvezels van < 125 decitex "> 80 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52064100: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 714,29 decitex "<= 14 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52064200: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 714,29, doch < 232,56 decitex "> 14, doch <= 43 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52064300: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 232,56, doch < 192,31 decitex "> 43, doch <= 52 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52064400: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 192,31, doch < 125 decitex "> 52, doch <= 80 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52064500: 'getwijnde of gekabelde garens van gekamde katoenvezels van < 125 decitex "> 80 Nm" per enkelvoudige draad, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens; garens opgemaakt voor de verkoop in het klein)',
    52071000: 'garens van katoen, opgemaakt voor de verkoop in het klein, bevattende >= 85 gewichtspercenten katoen (m.u.v. naaigarens)',
    52079000: 'garens van katoen, opgemaakt voor de verkoop in het klein, bevattende < 85 gewichtspercenten katoen (m.u.v. naaigarens)',
    52081110: 'verbandgaas van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, ongebleekt',
    52081190: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, ongebleekt (m.u.v. verbandgaas)',
    52081216: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met platbinding, ongebleekt, met een breedte van <= 165 cm',
    52081219: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met platbinding, ongebleekt, met een breedte van > 165 cm',
    52081296: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, ongebleekt, met een breedte van <= 165 cm',
    52081299: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, ongebleekt, met een breedte van > 165 cm',
    52081300: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, ongebleekt',
    52081900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², ongebleekt (m.u.v. die met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of platbinding)',
    52082110: 'verbandgaas van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, gebleekt',
    52082190: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, gebleekt (m.u.v. verbandgaas)',
    52082216: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met plaatbinding, gebleekt, met een breedte van <= 165 cm',
    52082219: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met plaatbinding, gebleekt, met een breedte van > 165 cm',
    52082296: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, gebleekt, met een breedte van <= 165 cm',
    52082299: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, gebleekt, met een breedte van > 165 cm',
    52082300: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, gebleekt',
    52082900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², gebleekt (m.u.v. die met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of platbinding)',
    52083100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, geverfd',
    52083216: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met platbinding, geverfd, met een breedte van <= 165 cm',
    52083219: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m², doch <= 130 g/m², met platbinding, geverfd, met een breedte van > 165 cm',
    52083296: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, geverfd, met een breedte van <= 165 cm',
    52083299: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 130 g/m², doch <= 200 g/m², met platbinding, geverfd, met een breedte van > 165 cm',
    52083300: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd',
    52083900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², geverfd (m.u.v. die met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of platbinding)',
    52084100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, van verschillend gekleurd garen',
    52084200: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100, doch <= 200 g/m², met platbinding, van verschillend gekleurd garen',
    52084300: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, van verschillend gekleurd garen',
    52084900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², van verschillend gekleurd garen (m.u.v. die met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52085100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 100 g/m², met platbinding, bedrukt',
    52085200: 'Weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 100 g/m² doch <= 200 g/m², met platbinding, bedrukt',
    52085910: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, bedrukt',
    52085990: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², bedrukt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52091100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met platbinding, ongebleekt',
    52091200: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, ongebleekt',
    52091900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², ongebleekt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52092100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met platbinding, gebleekt',
    52092200: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, gebleekt',
    52092900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², gebleekt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52093100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met platbinding, geverfd',
    52093200: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd',
    52093900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², geverfd (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52094100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met platbinding, van verschillend gekleurd garen',
    52094200: 'denimweefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², van verschillend gekleurd garen',
    52094300: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, van verschillend gekleurd garen (m.u.v. denimweefsels)',
    52094900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², van verschillend gekleurd garen (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52095100: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met platbinding, bedrukt',
    52095200: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, bedrukt',
    52095900: 'weefsels van katoen, bevattende >= 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², bedrukt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52101100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met platbinding, ongebleekt',
    52101900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², ongebleekt (m.u.v. die met platbinding)',
    52102100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met platbinding, gebleekt',
    52102900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², gebleekt (m.u.v. die met platbinding)',
    52103100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met platbinding, geverfd',
    52103200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd',
    52103900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², geverfd (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52104100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met platbinding, van verschillend gekleurd garen',
    52104900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², van verschillend gekleurd garen (m.u.v. die met platbinding)',
    52105100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², met platbinding, bedrukt',
    52105900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van <= 200 g/m², bedrukt (m.u.v. die met platbinding)',
    52111100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met platbinding, ongebleekt',
    52111200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, ongebleekt',
    52111900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², ongebleekt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52112000: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², gebleekt',
    52113100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met platbinding, geverfd',
    52113200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd',
    52113900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², geverfd (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52114100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met platbinding, van verschillend gekleurd garen',
    52114200: 'denimweefsels, bestaande overwegend, doch uit < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige gemengd, met een gewicht van > 200 g/m², van verschillend gekleurd garen',
    52114300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, van verschillend gekleurd garen (m.u.v. denimweefsels)',
    52114910: 'jacquardweefsels, bestaande uit overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², van verschillend gekleurd garen',
    52114990: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², van verschillend gekleurd garen (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding en m.u.v. jacquardweefsels)',
    52115100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met platbinding, bedrukt',
    52115200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, bedrukt',
    52115900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met synthetische of kunstmatige vezels gemengd, met een gewicht van > 200 g/m², bedrukt (m.u.v. die met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, of met platbinding)',
    52121110: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van <= 200 g/m², ongebleekt',
    52121190: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², ongebleekt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52121210: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van <= 200 g/m², gebleekt',
    52121290: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², gebleekt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52121310: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van <= 200 g/m², geverfd',
    52121390: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², geverfd (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52121410: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van <= 200 g/m², van verschillend gekleurd garen',
    52121490: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², van verschillend gekleurd garen (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52121510: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van <= 200 g/m², bedrukt',
    52121590: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van <= 200 g/m², bedrukt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52122110: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van > 200 g/m², ongebleekt',
    52122190: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², ongebleekt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52122210: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van > 200 g/m², gebleekt',
    52122290: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², gebleekt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52122310: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van > 200 g/m², geverfd',
    52122390: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², geverfd (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52122410: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van > 200 g/m², van verschillend gekleurd garen',
    52122490: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², van verschillend gekleurd garen (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    52122510: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, enkel of hoofdzakelijk met vlas gemengd, met een gewicht van > 200 g/m², bedrukt',
    52122590: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten katoen, met een gewicht van > 200 g/m², bedrukt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige vezels of met vlas zijn gemengd)',
    53011000: 'ruw of geroot vlas',
    53012100: 'gebroken of gezwingeld vlas',
    53012900: 'gehekeld of anders bewerkt vlas (m.u.v. geroot, gebroken of gezwingeld vlas en m.u.v. gesponnen vlas)',
    53013000: 'Werk en klodden van vlas en vlasafval, incl. afval van garen en rafelingen van vlas',
    53021000: 'ruwe of gerote hennep "Cannabis sative L."',
    53029000: 'bewerkte hennep "Cannabis sative L."; werk en afval van hennep, incl. afval van garen en rafelingen van hennep (m.u.v. gerote hennep en gesponnen hennep)',
    53031000: 'jute en andere bastvezels, ruw of geroot (m.u.v. vlas, hennep en ramee)',
    53039000: 'jute en andere bastvezels, bewerkt; werk en afval van deze vezels, incl. afval van garen en rafelingen van deze vezels (m.u.v. gerote vezels, gesponnen vezels en m.u.v. vlas, hennep en ramee)',
    53050000: 'kokosvezel, abaca "manillahennep of Musa textillis Nee", ramee en andere plantaardige textielvezels, n.e.g., ruw of bewerkt, doch niet gesponnen; werk en afval van deze vezels, afval van garen en rafelingen daaronder begrepen',
    53061010: 'eendraadsgarens van vlas, van >= 833,3 decitex "<= 12 Nm" (niet opgemaakt voor de verkoop in het klein)',
    53061030: 'eendraadsgarens van vlas, van < 833,3, doch >= 277,8 decitex "> 12 doch <= 36 Nm" (niet opgemaakt voor de verkoop in het klein)',
    53061050: 'eendraadsgarens van vlas, van < 277,8 decitex "> 36 Nm" (niet opgemaakt voor de verkoop in het klein)',
    53061090: 'eendraadsgarens van vlas, opgemaakt voor de verkoop in het klein',
    53062010: 'getwijnde of gekabelde garens van vlas (niet opgemaakt voor de verkoop in het klein)',
    53062090: 'getwijnde of gekabelde garens van vlas, opgemaakt voor de verkoop in het klein',
    53071000: 'Eendraadsgarens van jute of van andere bastvezels bedoeld bij post 5303',
    53072000: 'getwijnde of gekabelde garens van jute of van andere bastvezels bedoeld bij post 5303',
    53081000: 'kokosgarens',
    53082010: 'garens van hennep (niet opgemaakt voor de verkoop in het klein)',
    53082090: 'garens van hennep (opgemaakt voor de verkoop in het klein)',
    53089012: 'garens van ramee, van  >= 277,8 decitex "<= 36 Nm"',
    53089019: 'garens van ramee, van < 277,8 decitex "> 36 Nm"',
    53089050: 'papiergarens',
    53089090: 'garens van plantaardige textielvezels (m.u.v. die van vlas, jute of andere bastvezels bedoeld bij post 5303, hennep, ramee of katoen en m.u.v. kokos- en papiergarens)',
    53091110: 'weefsels van vlas, bevattende >= 85 gewichtspercenten vlas, ongebleekt',
    53091190: 'weefsels van vlas, bevattende >= 85 gewichtspercenten vlas, gebleekt',
    53091900: 'weefsels van vlas, bevattende >= 85 gewichtspercenten vlas, geverfd, van verschillend gekleurd garen of bedrukt',
    53092100: 'Weefsels bevattende overwegend, doch < 85 gewichtspercenten vlas, ongebleekt of gebleekt',
    53092900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten vlas, geverfd, van verschillend gekleurd garen of bedrukt',
    53101010: 'weefsels van jute of van andere bastvezels bedoeld bij post 5303, ongebleekt, met een breedte van <= 150 cm',
    53101090: 'weefsels van jute of van andere bastvezels bedoeld bij post 5303, ongebleekt, met een breedte van > 150 cm',
    53109000: 'weefsels van jute of van andere bastvezels bedoeld bij post 5303, gebleekt, geverfd, van verschillend gekleurd garen of bedrukt',
    53110010: 'weefsels van ramee',
    53110090: 'weefsels van plantaardige textielvezels; weefsels van papiergarens (m.u.v. die van vlas, jute of andere bastvezels bedoeld bij post 5303 of ramee en m.u.v. weefsels van katoen)',
    54011012: 'naaigarens "core yarn" van polyesterfilament omsponnen met katoenvezels (niet opgemaakt voor de verkoop in het klein)',
    54011014: 'kerngespannen garens, zgn. "core yarn", van synthetische filamenten (niet opgemaakt voor de verkoop in het klein en m.u.v. polyesterfilament omsponnen met katoenvezels)',
    54011016: 'getextureerde naaigarens van synthetische filamenten (niet opgemaakt voor de verkoop in het klein en m.u.v. kerngespannen garens, zgn. "core yarn")',
    54011018: 'naaigarens van synthetische filamenten (niet opgemaakt voor de verkoop in het klein en m.u.v. kerngespannen garens, zgn. "core yarn" en getextureerde garens)',
    54011090: 'naaigarens van synthetische filamenten, opgemaakt voor de verkoop in het klein',
    54012010: 'naaigarens van kunstmatige filamenten (niet opgemaakt voor de verkoop in het klein)',
    54012090: 'naaigarens van kunstmatige filamenten, opgemaakt voor de verkoop in het klein',
    54021100: 'garens met een hoge sterktegraad, van aramiden (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54021900: 'garens met een hoge sterktegraad, van nylon of van andere polyamiden (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens van armiden)',
    54022000: 'garens met een hoge sterktegraad, van polyesters (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54023100: 'getextureerde garens, van nylon of van andere polyamiden, van <= 50 tex per enkelvoudige draad (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54023200: 'getextureerde garens, van nylon of van andere polyamiden, van > 50 tex per enkelvoudige draad (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54023300: 'getextureerde garens, van polyesters (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54023400: 'getextureerde garens van polypropyleen (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54023900: 'getextureerde synthetische filamentgarens (niet opgemaakt voor de verkoop in het klein en m.u.v. getextureerde garens van polypropyleen, polyesters, nylon of andere polyamiden en van naaigarens)',
    54024400: 'elastomeergarens van synthetische filamenten, eendraads, ongetwist of met een twist van <= 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, getextureerde garens en garens van polyesters, van nylon of van andere polyamiden)',
    54024500: 'garens van nylon of van andere polyamiden, incl. monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. elastomeergarens, naaigarens, garens met een hoge sterktegraad en getextureerde garens)',
    54024600: 'garens van polyesters, incl. monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 50 toeren per meter, gedeeltelijk verstrekt (niet opgemaakt voor de verkoop in het klein en m.u.v. elastomeergarens, naaigarens en getextureerde garens)',
    54024700: 'garens van polyesters, incl. monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. elastomeergarens, naaigarens, getextureerde garens en garens van gedeeltelijk verstrekt polyester)',
    54024800: 'garens van polypropyleen, incl. monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. elastomeergarens, naaigarens en getextureerde garens)',
    54024900: 'synthetische filamentgarens, incl. synthetische monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, getextureerde garens, elastomeergarens en garens van polypropyleen, polyesters, van nylon of van andere polyamiden)',
    54025100: 'garens van nylon of van andere polyamiden, incl. monofilamenten van < 67 decitex, eendraads, met een twist van > 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, garens met een hoge sterktegraad en getextureerde garens)',
    54025200: 'garens van polyesters, incl. monofilamenten van < 67 decitex, eendraads, met een twist van > 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en getextureerde garens)',
    54025300: 'garens van polypropyleen, incl. monofilamenten van < 67 decitex, eendraads, met een twist van > 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en getextureerde garens)',
    54025900: 'synthetische filamentgarens, incl. synthetische monofilamenten van < 67 decitex, eendraads, met > 50 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, getextureerde garens en garens van polypropyleen, polyesters, nylon of andere polyamiden)',
    54026100: 'garens van nylon of van andere polyamiden, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, garens met een hoge sterktegraad en getextureerde garens)',
    54026200: 'garens van polyesters, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, garens met een hoge sterktegraad en getextureerde garens)',
    54026300: 'garens van polypropyleen, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en getextureerde garens)',
    54026900: 'synthetische filamentgarens, incl. synthetische monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, getextureerde garens en garens van polypropyleen, polyesters, van nylon of van andere polyamiden)',
    54031000: 'garens met een hoge sterktegraad, van viscoserayon (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    54033100: 'garens van viscoserayon, incl. monofilamenten van < 67 decitex, eendraads, ongetwist of met een twist van <= 120 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens met een hoge sterktegraad)',
    54033200: 'garens van viscoserayon, incl. monofilamenten van < 67 decitex, eendraads, met een twist van > 120 toeren per meter (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens met een hoge sterktegraad)',
    54033300: 'garens van celluloseacetaat, incl. monofilamenten van < 67 decitex, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens met hoge sterktegraad)',
    54033900: 'kunstmatige filamentgarens, incl. monofilamenten van < 67 decitex, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en filamentgarens van viscoserayon of celluloseacetaat)',
    54034100: 'Garens van viscoserayon, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens met een hoge sterktegraad)',
    54034200: 'garens van celluloseacetaat, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens met een hoge sterktegraad)',
    54034900: 'kunstmatige filamentgarens, incl. monofilamenten van < 67 decitex, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens van viscoserayon of van celluloseacetaat)',
    54041100: 'elastomeermonofilamenten van >= 67 decitex, en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm',
    54041200: 'monofilamenten van polypropyleen van >= 67 decitex, en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm (m.u.v. elastomeerfilamenten)',
    54041900: 'synthetische monofilamenten van >= 67 decitex, en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm (m.u.v. die van polypropyleen en elastomeerfilamenten)',
    54049010: 'Sierstrippen en artikelen van dergelijke vorm, "b.v. kunststro", van polypropyleen, met een schijnbare breedte van <= 5 mm',
    54049090: 'strippen en artikelen van dergelijke vorm, "b.v. kunststro", van synthetische textielstoffen, met een schijnbare breedte van <= 5 mm (m.u.v. die van polypropyleen)',
    54050000: 'kunstmatige monofilamenten van >= 67 decitex, en met een grootste diameter van de dwarsdoorsnede van <= 1 mm; strippen en artikelen van dergelijke vorm, "b.v. kunststro", van kunstmatige textielstoffen, met een schijnbare breedte van <= 5 mm',
    54060000: 'synthetische of kunstmatige filamentgarens, opgemaakt voor de verkoop in het klein (m.u.v. naaigarens)',
    54071000: 'weefsels vervaardigd van garens met een hoge sterktegraad, van nylon, van andere polyamiden of van polyesters, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm',
    54072011: 'weefsels met een breedte van <= 3 m, vervaardigd van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen, met een schijnbare breedte van <= 5 mm',
    54072019: 'weefsels met een breedte van >= 3 m, vervaardigd van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen, met een schijnbare breedte van <= 5 mm',
    54072090: 'weefsels vervaardigd van strippen of van artikelen van dergelijke vorm, van synthetische textielstoffen, met een schijnbare breedte van <= 5 mm (m.u.v. die van polyethyleen of van polypropyleen)',
    54073000: 'weefsels van synthetische filamentgarens, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bestaande uit lagen evenwijdig liggende textieldraden, die zodanig op elkaar zijn geplaatst, dat de draden van de verschillende lagen elkaar onder een scherpe of een rechte hoek kruisen en op die kruispunten met behulp van een kleefstof of door thermisch lassen aan elkaar zijn gehecht',
    54074100: 'weefsels bevattende >= 85 gewichtspercenten filamenten van nylon of van andere polyamiden, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt',
    54074200: 'weefsels bevattende >= 85 gewichtspercenten filamenten van nylon of van andere polyamiden, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd',
    54074300: 'weefsels bevattende >= 85 gewichtspercenten filamenten van nylon of van andere polyamiden, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen',
    54074400: 'weefsels bevattende >= 85 gewichtspercenten filamenten van nylon of van andere polyamiden, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt',
    54075100: 'weefsels bevattende >= 85 gewichtspercenten getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt',
    54075200: 'weefsels bevattende >= 85 gewichtspercenten getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd',
    54075300: 'weefsels bevattende >= 85 gewichtspercenten getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen',
    54075400: 'weefsels bevattende >= 85 gewichtspercenten getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt, met een breedte van > 75 cm of <= 57 cm',
    54076110: 'weefsels bevattende >= 85 gewichtspercenten niet getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt',
    54076130: 'weefsels bevattende >= 85 gewichtspercenten niet getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd',
    54076150: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten niet-getextureerde filamenten van polyesters, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen',
    54076190: 'weefsels bevattende >= 85 gewichtspercenten niet getextureerde filamenten van polyester, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt',
    54076910: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten mengsels van getextureerde en niet-getextureerde filamenten van polyesters, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt',
    54076990: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten mengsels van getextureerde en niet-getextureerde filamenten van polyesters, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd, van verschillend gekleurd garen of bedrukt',
    54077100: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten synthetische filamenten, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt (m.u.v. die met filamenten of monofilamenten van polyesters, van nylon of van andere polyamiden en m.u.v. die van mengsels van getextureerde en niet-getextureerde filamenten van polyesters)',
    54077200: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten synthetische filamenten, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd (m.u.v. die met filamenten of monofilamenten van polyesters, van nylon of van andere polyamiden en m.u.v. die van mengsels van getextureerde en niet-getextureerde filamenten van polyesters)',
    54077300: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten synthetische filamenten, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen (m.u.v. die met filamenten of monofilamenten van polyesters, van nylon of van andere polyamiden en m.u.v. die van mengsels van getextureerde en niet-getextureerde filamenten van polyesters)',
    54077400: 'weefsels van filamentgarens bevattende >= 85 gewichtspercenten synthetische filamenten, incl. die van monofilamenten van >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt (m.u.v. die met filamenten of monofilamenten van polyesters, van nylon of van andere polyamiden en m.u.v. die van mengsels van getextureerde en niet-getextureerde filamenten van polyesters)',
    54078100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, enkel of hoofdzakelijk met katoen gemengd, ongebleekt of gebleekt',
    54078200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten >= 67 decitex en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, enkel of hoofdzakelijk met katoen gemengd, geverfd',
    54078300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en  met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, enkel of hoofdzakelijk met katoen gemengd, van verschillend gekleurd garen',
    54078400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, enkel of hoofdzakelijk met katoen gemengd, bedrukt',
    54079100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt (m.u.v. die welke enkel of hoofdzakelijk met katoen zijn gemengd)',
    54079200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd (m.u.v. die welke enkel of hoofdzakelijk met katoen zijn gemengd)',
    54079300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen andere dan die welke enkel of hoofdzakelijk met katoen zijn gemengd',
    54079400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische filamenten, incl. monofilamenten van >= 67 decitex en  met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt (m.u.v. die welke enkel of hoofdzakelijk met katoen zijn gemengd)',
    54081000: 'weefsels vervaardigd van garens met een hoge sterktegraad van viscoserayon, incl. die van monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm',
    54082100: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige filamenten, en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, strippen of artikelen van dergelijke vorm, van kunstmatige textielstoffen, ongebleekt of gebleekt (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54082210: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige filamenten, en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, strippen of artikelen van dergelijke vorm, van kunstmatige textielstoffen, geverfd, met een breedte van > 135, doch <= 155 cm, met plat-, keper- of satijnbinding (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54082290: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige filamenten, en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, strippen of artikelen van dergelijke vorm, van kunstmatige textielstoffen, geverfd (m.u.v. die met een breedte van > 135, doch <= 155 cm, met plat-, keper- of satijnbinding en m.u.v. weefsels vervaardigd van garens met een hoge sterktegraad van viscoserayon)',
    54082300: 'Weefsels bevattende >= 85 gewichtspercenten kunstmatige filamenten, incl. monofilamenten van >= 67 decitex en met  een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54082400: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige filamenten, en een grootste afmeting van de dwarsdoorsnede van <= 1 mm, strippen of artikelen van dergelijke vorm, van kunstmatige textielstoffen, bedrukt (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54083100: 'weefsels van garens bevattende voornamelijk, doch < 85 gewichtspercenten kunstmatige filamenten, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, ongebleekt of gebleekt (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54083200: 'weefsels van garens bevattende voornamelijk, doch < 85 gewichtspercenten kunstmatige filamenten, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, geverfd (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54083300: 'weefsels van garens bevattende voornamelijk, doch < 85 gewichtspercenten kunstmatige filamenten, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, van verschillend gekleurd garen (m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    54083400: 'weefsels van garens bevattende voornamelijk, doch < 85 gewichtspercenten kunstmatige filamenten, incl. monofilamenten met een grootste afmeting van de dwarsdoorsnede van <= 1 mm, bedrukt(m.u.v. die van garens met een hoge sterktegraad van viscoserayon)',
    55011000: 'kabel, overeenkomstig aantekening 1 op hoofdstuk 55, van filamenten van nylon of van andere polyamiden',
    55012000: 'kabel, overeenkomstig aantekening 1 op hoofdstuk 55, van filamenten van polyesters',
    55013000: 'acryl- of modacrylkabel',
    55014000: 'polypropyleenkabel, overeenkomstig aantekening 1 op hoofdstuk 55',
    55019000: 'kabel, overeenkomstig aantekening 1 op hoofdstuk 55, van synthetische filamenten (m.u.v. acryl- of modacrylkabel en kabel van filamenten van polyesters, van polypropyleen, van nylon of van andere polyamiden)',
    55021000: 'kabel, overeenkomstig aantekening 1 op hoofdstuk 55, van acetaatfilamenten',
    55029000: 'Kabel van kunstmatige filamenten, overeenkomstig aantekening 1 op hoofdstuk 55,  (m.u.v.celluloseacetaat)',
    55031100: 'stapelvezels van aramiden, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen',
    55031900: 'stapelvezels van nylon of van andere polyamiden, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen (m.u.v. die van aramiden)',
    55032000: 'stapelvezels van polyesters, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen',
    55033000: 'acryl- of modacrylvezels, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen',
    55034000: 'stapelvezels van polypropyleen, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen',
    55039000: 'Synthetische stapelvezels, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen (m.u.v. acryl- of modacrylvezels en  m.u.v. stapelvezels van polypropyleen, van polyesters, van nylon of van andere polyamiden)',
    55041000: 'stapelvezels van viscose, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen',
    55049000: 'kunstmatige stapelvezels, ongekaard, ongekamd, noch op andere wijze bewerkt met het oog op het spinnen (m.u.v. die van viscose)',
    55051010: 'afval van vezels van nylon of van andere polyamiden, incl. kammeling, afval van garen en rafelingen',
    55051030: 'afval van vezels van polyesters, incl. kammeling, afval van garen en rafelingen',
    55051050: 'afval van acryl of modacrylvezels, incl. kammeling, afval van garen en rafelingen',
    55051070: 'afval van vezels van polypropyleen, incl. kammeling, afval van garen en rafelingen',
    55051090: 'afval van synthetische vezels van polypropyleen, incl. kammeling, afval van garen en rafelingen (m.u.v. dat van acryl of modacrylvezels en m.u.v. dat van vezels van polypropyleen, polyesters, nylon of andere polyamiden)',
    55052000: 'afval van kunstmatige vezels, incl. kammeling, afval van garen en rafelingen',
    55061000: 'stapelvezels van nylon of van andere polyamiden, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen',
    55062000: 'stapelvezels van polyesters, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen',
    55063000: 'acryl of modacrylvezels, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen',
    55064000: 'Synthetische stapelvezels van polypropyleen, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen',
    55069000: 'Synthetische stapelvezels, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen (m.u.v. acryl of modacrylvezels en m.u.v. stapelvezels van  polyesters, van polypropyleen, van nylon of van andere polyamiden)',
    55070000: 'kunstmatige stapelvezels, gekaard, gekamd, of op andere wijze bewerkt met het oog op het spinnen',
    55081010: 'naaigarens van synthetische stapelvezels (niet opgemaakt voor de verkoop in het klein)',
    55081090: 'naaigarens van synthetische stapelvezels, opgemaakt voor de verkoop in het klein',
    55082010: 'naaigarens van kunstmatige stapelvezels (niet opgemaakt voor de verkoop in het klein)',
    55082090: 'naaigarens van kunstmatige stapelvezels, opgemaakt voor de verkoop in het klein',
    55091100: 'garens bevattende >= 85 gewichtspercenten stapelvezels van nylon of van andere polyamiden, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55091200: 'garens bevattende >= 85 gewichtspercenten stapelvezels van nylon of van andere polyamiden, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55092100: 'garens bevattende >= 85 gewichtspercenten stapelvezels van polyesters, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55092200: 'garens bevattende >= 85 gewichtspercenten stapelvezels van polyesters, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55093100: 'garens bevattende >= 85 gewichtspercenten acryl- of modacrylstapelvezels, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55093200: 'garens bevattende >= 85 gewichtspercenten acryl- of modacrylstapelvezels, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55094100: 'garens bevattende >= 85 gewichtspercenten synthetische stapelvezels, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, garens van acryl- of modacrylstapelvezels en garens van stapelvezels van polyesters, van nylon of van andere polyamiden)',
    55094200: 'garens bevattende >= 85 gewichtspercenten synthetische stapelvezels, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens, garens van acryl- of modacrylstapelvezels en garens van stapelvezels van polyesters, van nylon of van andere polyamiden)',
    55095100: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met kunstmatige stapelvezels gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55095200: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met wol of fijn haar gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55095300: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met katoen gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55095900: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten stapelvezels van polyesters (niet opgemaakt voor de verkoop in het klein, m.u.v. die welke enkel of hoofdzakelijk zijn gemengd met kunstmatige stapelvezels, met wol of met fijn haar of met katoen en m.u.v. naaigarens)',
    55096100: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met wol of fijn haar gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55096200: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met katoen gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55096900: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels (niet opgemaakt voor de verkoop in het klein, m.u.v. die welke enkel of hoofdzakelijk zijn gemengd met wol of met fijn haar of met katoen en m.u.v. naaigarens)',
    55099100: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten synthetische stapelvezels, die enkel of hoofdzakelijk zijn gemengd met wol of met fijn haar gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens van stapelvezels van polyester, of van acryl- of modacrylstapelvezels)',
    55099200: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten synthetische stapelvezels, die enkel of hoofdzakelijk zijn gemengd met katoen (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens van stapelvezels van polyester, of van acryl- of modacrylstapelvezels)',
    55099900: 'garens bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens van stapelvezels van polyesters, of van acryl- of modacrylstapelvezels en m.u.v. garens die enkel of hoofdzakelijk zijn gemengd met katoen of met wol of met fijn haar)',
    55101100: 'garens bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, eendraads (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55101200: 'garens bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, getwijnd of gekabeld (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55102000: 'garens bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met wol of met fijn haar gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55103000: 'garens bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met katoen gemengd (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens)',
    55109000: 'garens bevattende voornamelijk, doch < 85 gewichtspercenten kunstmatige stapelvezels (niet opgemaakt voor de verkoop in het klein en m.u.v. naaigarens en garens die enkel of hoofdzakelijk met katoen of met wol of met fijn haar zijn gemengd)',
    55111000: 'garens van synthetische stapelvezels, bevattende >= 85 gewichtspercenten van deze vezels, opgemaakt voor de verkoop in het klein (m.u.v. naaigarens)',
    55112000: 'garens van synthetische stapelvezels, bevattende overwegend, doch < 85 gewichtspercenten van deze vezels, opgemaakt voor de verkoop in het klein (m.u.v. naaigarens)',
    55113000: 'garens van kunstmatige stapelvezels, opgemaakt voor de verkoop in het klein (m.u.v. naaigarens)',
    55121100: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, ongebleekt of gebleekt',
    55121910: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, bedrukt',
    55121990: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, geverfd of van verschillend gekleurd garen',
    55122100: 'weefsels, bevattende >= 85 gewichtspercenten acryl of modacrylstapelvezels, ongebleekt of gebleekt',
    55122910: 'weefsels, bevattende >= 85 gewichtspercenten acryl of modacrylstapelvezels, bedrukt',
    55122990: 'weefsels, bevattende >= 85 gewichtspercenten acryl of modacrylstapelvezels, geverfd, van verschillend gekleurd garen',
    55129100: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, ongebleekt of gebleekt (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55129910: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, bedrukt (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55129990: 'weefsels, bevattende >= 85 gewichtspercenten synthetische stapelvezels, geverfd of van verschillend gekleurd garen (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55131120: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met platbinding, ongebleekt of gebleekt en met een breedte van <= 165 cm',
    55131190: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met platbinding, ongebleekt of gebleekt en met een breedte van > 165 cm',
    55131200: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, ongebleekt of gebleekt en met een breedte van > 165 cm',
    55131300: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², ongebleekt of gebleekt (m.u.v. die met platbinding of met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55131900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², ongebleekt of gebleekt (m.u.v. die van stapelvezels van polyesters)',
    55132100: 'Weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met platbinding, geverfd',
    55132310: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd',
    55132390: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², geverfd (m.u.v. die met platbinding of met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55132900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², geverfd (m.u.v. die van stapelvezels van polyesters)',
    55133100: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met platbinding, van verschillend gekleurd garen',
    55133900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², van verschillend gekleurd garen (m.u.v. die van stapelvezels van polyesters met platbinding)',
    55134100: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², met platbinding, bedrukt',
    55134900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van <= 170 g/m², bedrukt (m.u.v. die van stapelvezels van polyesters met platbinding)',
    55141100: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met platbinding, ongebleekt of gebleekt',
    55141200: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, ongebleekt of gebleekt en met een breedte van > 165 cm',
    55141910: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², ongebleekt of gebleekt (m.u.v. die met platbinding of met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55141990: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², ongebleekt of gebleekt (m.u.v. die van stapelvezels van polyesters)',
    55142100: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met platbinding, geverfd',
    55142200: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, geverfd en met een breedte van > 165 cm',
    55142300: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², geverfd (m.u.v. die met platbinding of met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55142900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², geverfd (m.u.v. die van stapelvezels van polyesters)',
    55143010: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met platbinding, van verschillend gekleurd garen',
    55143030: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, van verschillend gekleurd garen en met een breedte van > 165 cm',
    55143050: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², van verschillend gekleurd garen (m.u.v. die met platbinding of met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55143090: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², van verschillend gekleurd garen (m.u.v. die van stapelvezels van polyesters)',
    55144100: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met platbinding, bedrukt',
    55144200: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding, bedrukt en met een breedte van > 165 cm',
    55144300: 'weefsels van stapelvezels van polyesters, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², bedrukt (m.u.v. die met platbinding of met met drie- of vierschachtskeperbinding, incl. gelijkzijdige keperbinding)',
    55144900: 'weefsels van synthetische stapelvezels, bevattende overwegend doch < 85 gewichtspercenten van deze vezels, enkel of hoofdzakelijk met katoen gemengd, met een gewicht van > 170 g/m², bedrukt (m.u.v. die van stapelvezels van polyesters)',
    55151110: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met stapelvezels van viscose gemengd, ongebleekt of gebleekt',
    55151130: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met stapelvezels van viscose gemengd, bedrukt',
    55151190: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met stapelvezels van viscose gemengd, geverfd of van verschillend gekleurd garen',
    55151210: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, ongebleekt of gebleekt',
    55151230: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, bedrukt',
    55151290: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, geverfd of van verschillend gekleurd garen',
    55151311: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met gekaarde wol of met gekaard fijn haar gemengd, ongebleekt of gebleekt',
    55151319: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met gekaarde wol of met gekaard fijn haar gemengd, geverfd, van verschillend gekleurd garen of bedrukt',
    55151391: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met gekamde wol of met gekamd fijn haar gemengd, ongebleekt of gebleekt',
    55151399: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, enkel of hoofdzakelijk met gekamde wol of met gekamd fijn haar gemengd, geverfd, van verschillend gekleurd garen of bedrukt',
    55151910: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, ongebleekt of gebleekt (m.u.v. die welke enkel of hoofdzakelijk met wol of met fijn haar, met synthetische of kunstmatige filamenten, met stapelvezels van viscose of met katoen zijn gemengd)',
    55151930: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, bedrukt (m.u.v. die welke enkel of hoofdzakelijk met wol of met fijn haar, met synthetische of kunstmatige filamenten, met stapelvezels van viscose of met katoen zijn gemengd)',
    55151990: 'weefsels bevattende overwegend doch < 85 gewichtspercenten stapelvezels van polyesters, geverfd of van verschillend gekleurd garen (m.u.v. die welke enkel of hoofdzakelijk met wol of met fijn haar, met synthetische of kunstmatige filamenten, met stapelvezels van viscose of met katoen zijn gemengd)',
    55152110: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, ongebleekt of gebleekt',
    55152130: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, bedrukt',
    55152190: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, geverfd of van verschillend gekleurd garen',
    55152211: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met gekaarde wol of met gekaard fijn haar gemengd, ongebleekt of gebleekt',
    55152219: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met gekaarde wol of met gekaard fijn haar gemengd, geverfd, van verschillend gekleurd garen of bedrukt',
    55152291: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met gekamde wol of met gekamd fijn haar gemengd, ongebleekt of gebleekt',
    55152299: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels, enkel of hoofdzakelijk met gekamde wol of met gekamd fijn haar gemengd, geverfd, van verschillend gekleurd garen of bedrukt',
    55152900: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten acryl- of modacrylstapelvezels (m.u.v. die welke enkel of hoofdzakelijk met wol of met fijn haar, met synthetische of kunstmatige filamenten, of met katoen zijn gemengd)',
    55159110: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, ongebleekt of gebleekt (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55159130: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, bedrukt (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55159190: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, geverfd of van verschillend gekleurd garen (m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55159920: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels, ongebleekt of gebleekt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of met katoen zijn gemengd en m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55159940: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels, bedrukt (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of met katoen zijn gemengd en m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55159980: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten synthetische stapelvezels geverfd of van verschillend gekleurd garen (m.u.v. die welke enkel of hoofdzakelijk met synthetische of kunstmatige filamenten of met katoen zijn gemengd en m.u.v. die van acryl- of modacrylstapelvezels of van stapelvezels van polyesters)',
    55161100: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, ongebleekt of gebleekt',
    55161200: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, geverfd',
    55161300: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, van verschillend gekleurd garen',
    55161400: 'weefsels bevattende >= 85 gewichtspercenten kunstmatige stapelvezels, bedrukt',
    55162100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, ongebleekt of gebleekt',
    55162200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, geverfd',
    55162310: 'jacquardweefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, van verschillend gekleurd garen, met een breedte van >= 140 cm "matrastijk"',
    55162390: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, van verschillend gekleurd garen (m.u.v. jacquardweefsels met een breedte van >= 140 cm "matrastijk")',
    55162400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met synthetische of met kunstmatige filamenten gemengd, bedrukt',
    55163100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met wol of met fijn haar gemengd, ongebleekt of gebleekt',
    55163200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met wol of met fijn haar gemengd, geverfd',
    55163300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met wol of met fijn haar gemengd, van verschillend gekleurd garen',
    55163400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met wol of met fijn haar gemengd, bedrukt',
    55164100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met katoen gemengd, ongebleekt of gebleekt',
    55164200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met katoen gemengd, geverfd',
    55164300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met katoen gemengd, van verschillend gekleurd garen',
    55164400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, enkel of hoofdzakelijk met katoen gemengd, bedrukt',
    55169100: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, ongebleekt of gebleekt (m.u.v. die welke enkel of hoofdzakelijk met katoen, met wol of met fijn haar of met synthetische of met kunstmatige filamenten zijn gemengd)',
    55169200: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, geverfd (m.u.v. die welke enkel of hoofdzakelijk met katoen, met wol of met fijn haar of met synthetische of met kunstmatige filamenten zijn gemengd)',
    55169300: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, van verschillend gekleurd garen (m.u.v. die welke enkel of hoofdzakelijk met katoen, met wol of met fijn haar of met synthetische of met kunstmatige filamenten zijn gemengd)',
    55169400: 'weefsels bevattende overwegend, doch < 85 gewichtspercenten kunstmatige stapelvezels, bedrukt (m.u.v. die welke enkel of hoofdzakelijk met katoen, met wol of met fijn haar of met synthetische of met kunstmatige filamenten zijn gemengd)',
    56012110: 'watten van hydrofiel katoen en artikelen daarvan (m.u.v. maandverbanden en tampons, luiers en inlegluiers e.d. hygiënische artikelen en m.u.v. watten en artikelen daarvan, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein, voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden, of geïmpregneerd of bedekt met parfum, cosmetische producten, zeep, enz.)',
    56012190: 'watten van katoen en artikelen daarvan (m.u.v. die van hydrofiel katoen en m.u.v. maandverbanden en tampons, luiers en inlegluiers e.d. hygiënische artikelen en m.u.v. watten en artikelen daarvan, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein, voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden, of geïmpregneerd of bedekt met parfum, cosmetische producten, zeep, enz.)',
    56012210: 'watten van synthetische of kunstmatige vezels in rollen met een diameter van <= 8 mm (m.u.v. die welke volledig met weefsel zijn bedekt)',
    56012290: 'Watten van kunstmatige vezels en artikelen daarvan (m.u.v. watten in de vorm van rollen met een diameter van <= 8 mm en m.u.v. maandverbanden en tampons, luiers en inlegluiers e.d. hygiënische artikelen en m.u.v. watten en artikelen daarvan, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein, voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden, of geïmpregneerd of bedekt met parfum, cosmetische producten, zeep, enz.)',
    56012900: 'watten van textielstof en artikelen daarvan (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. maandverbanden en tampons, luiers en inlegluiers e.d. hygiënische artikelen en m.u.v. watten en artikelen daarvan, geïmpregneerd of bedekt met farmaceutische zelfstandigheden of opgemaakt voor de verkoop in het klein, voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden, of geïmpregneerd of bedekt met parfum, cosmetische producten, zeep, enz.)',
    56013000: 'scheerhaar en noppen van textielstof',
    56021011: 'naaldgetouwvilt van jute of van andere bastvezels bedoeld bij post 5303, n.e.g. (niet geïmpregneerd, bekleed, bedekt of met inlagen)',
    56021019: 'naaldgetouwvilt, n.e.g. (niet geïmpregneerd, bekleed, bedekt of met inlagen en m.u.v. dat van jute of van andere bastvezels bedoeld bij post 5303)',
    56021031: 'producten van vilt doorstikt met een naai-breisteek "stitch-bonding", van wol of van fijn haar, n.e.g. (niet geïmpregneerd, bekleed, bedekt of met inlagen)',
    56021038: 'Producten van vilt doorstikt met een naai-breisteek "stitch-bonding", n.e.g. (niet geïmpregneerd, bekleed, bedekt of met inlagen en m.u.v. die van wol of van fijn haar)',
    56021090: 'naaldgetouwvilt en producten van vilt doorstikt met een naai-breisteek "stitch-bonding", geïmpregneerd, bekleed, bedekt of met inlagen, n.e.g.',
    56022100: 'vilt van wol of van fijn haar, n.e.g. (niet geïmpregneerd, bekleed, bedekt of met inlagen en m.u.v. naaldgetouwvilt en producten van vilt doorstikt met een naai-breisteek "stitch-bonding")',
    56022900: 'vilt (niet geïmpregneerd, bekleed, bedekt of met inlagen en m.u.v. vilt van wol of van fijn haar, naaldgetouwvilt en producten van vilt doorstikt met een naai-breisteek "stitch-bonding")',
    56029000: 'vilt, geïmpregneerd, bekleed, bedekt of met inlagen (m.u.v. naaldgetouwvilt en producten van vilt doorstikt met een naai-breisteek "stitch-bonding")',
    56031110: 'gebonden textielvlies, bekleed of bedekt, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van <= 25 g/m²',
    56031190: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van <= 25 g/m² (niet bekleed of bedekt)',
    56031210: 'gebonden textielvlies, bekleed of bedekt, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 25 doch <= 70 g/m²',
    56031290: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 25 doch <= 70 g/m² (niet bekleed of bedekt)',
    56031310: 'gebonden textielvlies, bekleed of bedekt, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 70 doch <= 150 g/m²',
    56031390: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 70 doch <= 150 g/m² (niet bekleed of bedekt)',
    56031410: 'gebonden textielvlies, bekleed of bedekt, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 150 g/m²',
    56031490: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., van synthetische of kunstmatige filamenten, met een gewicht van > 150 g/m² (niet bekleed of bedekt)',
    56039110: 'gebonden textielvlies, bekleed of bedekt, n.e.g. met een gewicht van <= 25 g/m² (m.u.v. die van synthetische of kunstmatige filamenten)',
    56039190: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., met een gewicht van <= 25 g/m² (niet bekleed of bedekt en m.u.v. die van synthetische of kunstmatige filamenten)',
    56039210: 'gebonden textielvlies, bekleed of bedekt, n.e.g., met een gewicht van > 25 doch <= 70 g/m² (m.u.v. die van synthetische of kunstmatige filamenten)',
    56039290: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., met een gewicht van > 25 doch <= 70 g/m² (niet bekleed of bedekt en m.u.v. die van synthetische of kunstmatige filamenten)',
    56039310: 'gebonden textielvlies, bekleed of bedekt, n.e.g., met een gewicht van > 70 doch <= 150 g/m² (m.u.v. die van synthetische of kunstmatige filamenten)',
    56039390: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., met een gewicht van > 70 doch <= 150 g/m² (niet bekleed of bedekt en m.u.v. die van synthetische of kunstmatige filamenten)',
    56039410: 'gebonden textielvlies, bekleed of bedekt, n.e.g., met een gewicht van > 150 g/m² (m.u.v. die van synthetische of kunstmatige filamenten)',
    56039490: 'gebonden textielvlies, ook indien geïmpregneerd of met inlagen, n.e.g., met een gewicht van > 150 g/m² (niet bekleed of bedekt en m.u.v. die van synthetische of kunstmatige filamenten)',
    56041000: 'draad en koord van rubber, omwoeld of omvlochten met textiel; textielgarens, alsmede strippen en artikelen van dergelijke vorm, bedoeld bij post 5404 of 5405, geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. die welke zijn voorzien van vishaken of op andere wijze zijn opgemaakt als vissnoer en m.u.v. imitaties van catgut)',
    56049010: 'Garens met een hoge sterktegraad, van polyesters, van nylon of van andere polyamiden of van viscoserayon, geïmpregneerd of bekleed',
    56049090: 'Strippen en artikelen van dergelijke vorm, bedoeld bij post 5404 of 5405, geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. die welke zijn voorzien van vishaken of op andere wijze zijn opgemaakt als vissnoer en m.u.v. imitaties van catgut en garens met een hoge sterktegraad, van polyesters, van nylon of van andere polyamiden of van viscoserayon, geïmpregneerd of bekleed)',
    56050000: 'metaalgarens, ook indien omwoeld, bestaande uit textielgarens of uit strippen en artikelen van dergelijke vorm, bedoeld bij post 5404 of 5405, verbonden met metaaldraad, -strippen of -poeder, dan wel bedekt met metaal (m.u.v. garens die zijn vervaardigd van een mengsel van textielstoffen en metaalvezels, met een anti-statische werking, met metaaldraad versterkte garens en artikelen die het karakter hebben van passementwerk)',
    56060010: 'kettingsteekgaren "zgn. chainettegaren" (m.u.v. metaalgaren bedoeld bij post 5605, omwoeld paardenhaar "crin", rubberdraad, omwoeld of omvlochten met textiel, omwoeld koord of omwoeld snoer en andere omwoelde textielproducten bedoeld bij post 5808 en omwoeld metaaldraad)',
    56060091: 'omwoeld garen (m.u.v. metaalgaren bedoeld bij post 5605, omwoeld paardenhaar "crin", rubberdraad, omwoeld of omvlochten met textiel, omwoeld koord of omwoeld snoer en andere omwoelde textielproducten bedoeld bij post 5808 en omwoeld metaaldraad)',
    56060099: 'chenillegaren en omwoelde strippen en artikelen van dergelijke vorm, bedoeld bij post 5404 of 5405 (m.u.v. metaalgaren bedoeld bij post 5605, omwoeld paardenhaar "crin", rubberdraad, omwoeld of omvlochten met textiel, omwoeld koord of omwoeld snoer en andere omwoelde textielproducten bedoeld bij post 5808 en omwoeld metaaldraad)',
    56072100: 'bindtouw van sisal of van andere textielvezels van agaven',
    56072900: 'Bindgaren, touw en kabel, van sisal of van andere textielvezels van agaven,  al dan niet gevlochten, ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. bindtouw)',
    56074100: 'bindtouw van polyethyleen of van polypropyleen',
    56074911: 'bindgaren, touw en kabel, van polyethyleen of van polypropyleen, van > 50.000 decitex "5 g/m", gevlochten, ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. bindtouw)',
    56074919: 'bindgaren, touw en kabel, van polyethyleen of van polypropyleen, van > 50.000 decitex "5 g/m", ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (niet gevlochten, en m.u.v. bindtouw)',
    56074990: 'bindgaren, touw en kabel, van polyethyleen of van polypropyleen, van <= 50.000 decitex "5 g/m", gevlochten, ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. bindtouw)',
    56075011: 'bindgaren, touw en kabel, van nylon of van andere polyamiden of van polyesters, van > 50.000 decitex "5 g/m", gevlochten, ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof',
    56075019: 'bindgaren, touw en kabel, van nylon of van andere polyamiden of van polyesters, van > 50.000 decitex "5 g/m", ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (niet gevlochten)',
    56075030: 'bindgaren, touw en kabel, van nylon of van andere polyamiden of van polyesters, van > 50.000 decitex "5 g/m", ook indien gevlochten en ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof',
    56075090: 'bindgaren, touw en kabel, van synthetische vezels, ook indien gevlochten en ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. die van polyethyleen, polypropyleen, polyesters, nylon of andere polyamiden)',
    56079020: 'bindgaren, touw en kabel, van abaca "Manillahennep of Musa textilis Nee" of van andere harde vezels "bladvezels" en van jute of andere bastvezels bedoeld bij post 5303, al dan niet gevlochten, ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof',
    56079090: 'bindgaren, touw en kabel, ook indien gevlochten en ook indien geïmpregneerd, bekleed, bedekt of ommanteld met rubber of met kunststof (m.u.v. die van synthetische vezels, van jute of van andere bastvezels bedoeld bij post 5303, van sisal of van andere textielvezels van agaven, van abaca "Manillahennep of Musa textilis Nee" of van andere harde vezels "bladvezels")',
    56081120: 'Geknoopte visnetten, geconfectioneerd, van bindgaren, touw of kabel van kunstmatige textielstoffen (m.u.v. schepnetjes)',
    56081180: 'Geknoopte visnetten, geconfectioneerd, van garen van synthetische of kunstmatige textielstoffen (m.u.v. die van bindgaren, touw of kabel en m.u.v. schepnetjes)',
    56081911: 'geknoopte netten, geconfectioneerd, van bindgaren, touw of kabel van nylon of van andere polyamiden (m.u.v. van visnetten, haarnetjes, sportnetten, schepnetjes voor de visvangst of de vlindervangst e.d. netten)',
    56081919: 'geknoopte netten, geconfectioneerd, van nylon of van andere polyamiden (m.u.v. die van bindgaren, touw of kabel en m.u.v. visnetten, haarnetjes, sportnetten, schepnetjes voor de visvangst of de vlindervangst e.d. netten)',
    56081930: 'geknoopte netten, geconfectioneerd, van synthetische of kunstmatige textielstoffen (m.u.v. die van nylon of van andere polyamiden en m.u.v. visnetten, haarnetjes, sportnetten, schepnetjes voor de visvangst of de vlindervangst e.d. netten)',
    56081990: 'geknoopte netten, van bindgaren, touw of kabel, in balen of aan een stuk, van synthetische of kunstmatige textielstoffen (m.u.v. geconfectioneerde netten)',
    56089000: 'geknoopte netten, van bindgaren, touw of kabel, in balen of aan een stuk; visnetten en andere netten, van plantaardige textielstof (m.u.v. haarnetjes, sportnetten, schepnetjes voor de visvangst of de vlindervangst e.d. netten)',
    56090000: 'artikelen van garen, van strippen of dergelijke vorm bedoeld bij post 5404 of 5405, van bindgaren, van touw of van kabel, bedoeld bij post 5607, n.e.g.',
    57011010: 'tapijten van wol of van fijn haar, geknoopt of met opgerolde polen, ook indien geconfectioneerd, bevattende in totaal > 10 gewichtspercenten zijde of vlokzijde',
    57011090: 'tapijten van wol of van fijn haar, geknoopt of met opgerolde polen, ook indien geconfectioneerd (m.u.v. die welke in totaal > 10 gewichtspercenten zijde of vlokzijde bevatten)',
    57019010: 'tapijten van zijde, van vlokzijde, van synthetische vezels, van metaalgarens bedoeld bij post 5605 of van textielstoffen gecombineerd met metaaldraad, geknoopt of met opgerolde polen, ook indien geconfectioneerd',
    57019090: 'tapijten, geknoopt of met opgerolde polen, ook indien geconfectioneerd (m.u.v. die van wol of van fijn haar, van zijde, van vlokzijde, van synthetische vezels, van metaalgarens bedoeld bij post 5605 of van textielstoffen gecombineerd met metaaldraad)',
    57021000: 'Kelim, Sumak", "Karamanie" e.d. handgeweven tapijten, ook indien geconfectioneerd',
    57022000: 'tapijten van kokosweefsel, geweven, ook indien geconfectioneerd',
    57023110: 'Axminster-tapijten, van wol of van fijn haar, geweven, ongetuft en ongevlokt, met pool (niet geconfectioneerd)',
    57023180: 'tapijten, van wol of van fijn haar, geweven, ongetuft en ongevlokt, met pool (niet geconfectioneerd en m.u.v. "Axminster", "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57023200: 'tapijten, van synthetische of van kunstmatige textielstoffen, geweven, ongetuft en ongevlokt, met pool (niet geconfectioneerd en m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57023900: 'tapijten, van plantaardige textielstoffen of van grof haar, geweven, ongetuft en ongevlokt, met pool (niet geconfectioneerd en m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten en m.u.v. tapijten van kokosvezel)',
    57024110: 'Axminster-tapijten van wol of van fijn haar, geweven, ongetuft en ongevlokt, met pool, geconfectioneerd',
    57024190: 'tapijten van wol of van fijn haar, geweven, ongetuft en ongevlokt, met pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten en m.u.v. Axminster-tapijten',
    57024200: 'tapijten, van synthetische of van kunstmatige textielstoffen, geweven, ongetuft en ongevlokt, met pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57024900: 'tapijten, van plantaardige textielstoffen of van grof haar, geweven, ongetuft en ongevlokt, met pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten en m.u.v. tapijten met kokosvezel)',
    57025010: 'tapijten van wol of van fijn haar, geweven, ongetuft en ongevlokt, zonder pool (niet geconfectioneerd en m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57025031: 'tapijten,van polypropyleen, geweven, ongetuft en ongevlokt, zonder pool (niet geconfectioneerd en m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57025039: 'tapijten, van synthetische of van kunstmatige textielstoffen, geweven, ongetuft en ongevlokt, zonder pool (niet geconfectioneerd en m.u.v. die van polypropyleen en "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57025090: 'tapijten, van plantaardige textielstoffen of van grof haar, geweven, ongetuft en ongevlokt, zonder pool (niet geconfectioneerd en m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten en m.u.v. tapijten van kokosvezel)',
    57029100: 'tapijten van wol of van fijn haar, geweven, ongetuft en ongevlokt, zonder pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57029210: 'tapijten,van polypropyleen, geweven, ongetuft en ongevlokt, zonder pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57029290: 'tapijten van synthetische of van kunstmatige textielstoffen, geweven, ongetuft en ongevlokt, zonder pool, geconfectioneerd (m.u.v. die van polypropyleen en "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten)',
    57029900: 'tapijten van plantaardige textielstoffen of van grof haar, geweven, ongetuft en ongevlokt, zonder pool, geconfectioneerd (m.u.v. "Kelim", Sumak", "Karamanie" e.d. handgeweven tapijten en m.u.v. tapijten van kokosvezel)',
    57031000: 'tapijten van wol of van fijn haar, getuft, ook indien geconfectioneerd',
    57032012: 'Tegels van nylon of van andere polyamiden, getuft, ook indien geconfectioneerd, bedrukt, met een oppervlakte van <= 1m²',
    57032018: 'Tapijten van nylon of van andere polyamiden, getuft, ook indien geconfectioneerd, bedrukt (m.u.v. tegels met een oppervlakte van <= 1m²)',
    57032092: 'Tegels van nylon of van andere polyamiden, getuft, ook indien geconfectioneerd, met een oppervlakte van <= 1m² (niet bedrukt)',
    57032098: 'Tapijten van nylon of van andere polyamiden, getuft, ook indien geconfectioneerd (m.u.v. bedrukte en van tegels met een oppervlakte van <= 1 m²)',
    57033012: 'Tegels van polypropyleen, getuft, ook indien geconfectioneerd, met een oppervlakte van <= 1 m²',
    57033018: 'Tapijten van polypropyleen, getuft, ook indien geconfectioneerd (m.u.v. tegels met een oppervlakte  van <= 1m²)',
    57033082: 'Tegels van synthetische of van kunstmatige textielstoffen, getuft, ook indien geconfectioneerd, met een oppervlakte van <= 1 m² (m.u.v. die van polypropyleen, van nylon of van andere polyamiden)',
    57033088: 'Tapijten van synthetische of van kunstmatige textielstoffen, getuft, ook indien geconfectioneerd (m.u.v. die van polypropyleen, nylon of van andere polyamiden en  tegels met een oppervlakte van <= 1m²)',
    57039020: 'Tegels van plantaardige textielstoffen of van grof haar, getuft, ook indien geconfectioneerd, met een oppervlakte van <= 1m²',
    57039080: 'Tapijten van plantaardige textielstoffen of van grof haar, getuft, ook indien geconfectioneerd (m.u.v. tegels met een oppervlakte van <= 1 m²)',
    57041000: 'tegels van vilt, ongetuft en ongevlokt, met een oppervlakte van <= 0,3 m²',
    57042000: 'Tegels van vilt, niet getuft of gevlokt, met een oppervlakte van > 0,3 mÂ²  doch <= 1 m²',
    57049000: 'tapijten van vilt, ongetuft en ongevlokt, ook indien geconfectioneerd (m.u.v. tegels met een oppervlakte van <= 1 m²)',
    57050030: 'tapijten van synthetische of van kunstmatige textielstoffen, ook indien geconfectioneerd (niet geknoopt of niet met opgerolde polen, niet geweven en niet getuft en m.u.v. vilt)',
    57050080: 'Tapijten van wol of dierlijk haar of van plantaardige textielstoffen, ook indien geconfectioneerd (niet geknoopt of niet met opgerolde polen, niet geweven en niet getuft en m.u.v. vilt)',
    58011000: 'fluweel, pluche en chenilleweefsel, van wol of van fijn haar (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58012100: 'ongesneden inslagfluweel en -pluche, van katoen (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58012200: 'gesneden inslagfluweel en -pluche, geribd "corduroy", van katoen (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58012300: 'inslagfluweel en -pluche, van katoen (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806 en m.u.v. inslagfluweel en -pluche, geribd "corduroy")',
    58012600: 'chenilleweefsel, van katoen (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58012700: 'kettingfluweel en -pluche, van katoen (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58013100: 'ongesneden inslagfluweel en -pluche, van synthetische of van kunstmatige vezels (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58013200: 'gesneden inslagfluweel en -pluche, geribd "corduroy", van synthetische of van kunstmatige vezels (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58013300: 'inslagfluweel en -pluche, van synthetische of van kunstmatige vezels (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806 en m.u.v. inslagfluweel en -pluche, geribd "corduroy")',
    58013600: 'chenilleweefsel, van synthetische of van kunstmatige vezels (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58013700: 'kettingfluweel en -pluche, van synthetische of van kunstmatige vezels (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58019010: 'fluweel, pluche en chenilleweefsel, van vlas (m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58019090: 'fluweel, pluche en chenilleweefsel (m.u.v. die van vlas, synthetische of van kunstmatige vezels, katoen, wol of fijn haar en m.u.v. lussenweefsel, "bad- of frotteerstof", getufte textielstoffen en linten als bedoeld in post 5806)',
    58021100: 'lussenweefsel, "bad- of frotteerstof", van katoen, ongebleekt (m.u.v. linten als bedoeld in post 5806 en tapijten)',
    58021900: 'lussenweefsel "bad- of frotteerstof", van katoen (m.u.v. ongebleekt lussenweefsel, linten als bedoeld in post 5806 en tapijten)',
    58022000: 'lussenweefsel "bad- of frotteerstof" (m.u.v. die van katoen en m.u.v. linten als bedoeld in post 5806 en tapijten)',
    58023000: 'getufte textielstoffen (m.u.v. tapijten)',
    58030010: 'weefsel met gaasbinding, van katoen (m.u.v. linten als bedoeld in post 5806)',
    58030030: 'weefsel met gaasbinding, van zijde of van afval van zijde (m.u.v. linten als bedoeld in post 5806)',
    58030090: 'weefsel met gaasbinding (m.u.v. die van zijde of afval van zijde of katoen en m.u.v. linten als bedoeld in post 5806)',
    58041010: 'Tule en bobinettule en filetweefsel, onopgemaakt',
    58041090: 'tule, bobinettule en filetweefsel, opgemaakt',
    58042100: 'mechanisch vervaardigde kant, aan het stuk, in banden of in de vorm van motieven, van synthetische of kunstmatige vezels (m.u.v. producten bedoeld bij post 6002 tot en met 6006)',
    58042900: 'mechanisch vervaardigde kant, aan het stuk, in banden of in de vorm van motieven (m.u.v. die van synthetische of kunstmatige vezels en m.u.v.  producten bedoeld bij post 6002 tot en met 6006)',
    58043000: 'met de hand vervaardigde kant, aan het stuk, in banden of in de vorm van motieven (m.u.v. producten bedoeld bij post 6002 tot en met 6006)',
    58050000: 'tapisserieën, met de hand geweven "zoals gobelins, Vlaamse tapisserieën, aubussons, beauvais e.d." of met de naald vervaardigd "b.v. halve kruissteek, kruissteek", ook indien geconfectioneerd (m.u.v. "Kenim", "Sumak", "Karamani" e.d. handgeweven tapijten en tapisserieën die > 100 jaar oud zijn)',
    58061000: 'lint van fluweel, van pluche, van chenilleweefsel of van lussenweefsel (m.u.v. etiketten, insignes e.d. artikelen)',
    58062000: 'geweven lint van textielstoffen, bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden (m.u.v. lint van fluweel, van pluche, van chenilleweefsel of van lussenweefsel, etiketten, insignes e.d. artikelen)',
    58063100: 'geweven lint van katoen, n.e.g.',
    58063210: 'geweven lint van synthetische of kunstmatige vezels, met echte zelfkanten, n.e.g.',
    58063290: 'geweven lint van synthetische of kunstmatige vezels, zonder echte zelfkanten, n.e.g.',
    58063900: 'geweven lint, n.e.g.',
    58064000: 'bolduclint, zijnde lint zonder inslag van aaneengelijmde evenwijdig lopende draden of textielvezels',
    58071010: 'etiketten, insignes e.d. artikelen van textiel, aan het stuk, in banden of gesneden, geweven en met ingeweven opschriften en motieven',
    58071090: 'etiketten, insignes e.d. artikelen van textiel, aan het stuk, in banden of gesneden, geweven (niet geborduurd en m.u.v. die met ingeweven opschriften en motieven)',
    58079010: 'etiketten, insignes e.d. artikelen van vilt of van gebonden textielvlies, aan het stuk, in banden of gesneden (niet geborduurd)',
    58079090: 'etiketten, insignes e.d. artikelen van textiel, aan het stuk, in banden of gesneden (niet geweven en niet geborduurd en m.u.v. die van vilt of van gebonden textielvlies)',
    58081000: 'vlechten van textielstoffen, aan het stuk',
    58089000: 'passementwerk e.d. versieringsartikelen, van textielstoffen, aan het stuk, zonder borduurwerk (m.u.v. brei- en haakwerk); eikels, kwasten, pompons e.d. artikelen, van textielstoffen (m.u.v. vlechten aan het stuk)',
    58090000: 'weefsels van metaaldraad en weefsels van metaalgarens, bedoeld bij post 5605, van het soort gebezigd voor kleding, voor stoffering of voor dergelijk gebruik, n.e.g.',
    58101010: 'etskant op een grondweefsel van textielstoffen en borduurwerk zonder zichtbaar grondweefsel, aan een stuk, in banden of in de vorm van motieven, met een waarde van > 35 € per kg nettogewicht',
    58101090: 'etskant op een grondweefsel van textielstoffen en borduurwerk zonder zichtbaar grondweefsel, aan een stuk, in banden of in de vorm van motieven, met een waarde van <= 35 € per kg nettogewicht',
    58109110: 'borduurwerk van katoen, met een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van > 17,50 € per kg nettogewicht (m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58109190: 'borduurwerk van katoen, met een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van <= 17,50 € per kg nettogewicht (m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58109210: 'borduurwerk van synthetische of kunstmatige vezels, met een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van > 17,50 € per kg nettogewicht (m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58109290: 'borduurwerk van synthetische of kunstmatige vezels, met een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van <= 17,50 € per kg nettogewicht (m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58109910: 'borduurwerk op een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van > 17,50 € per kg nettogewicht (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58109990: 'borduurwerk op een grondweefsel van textielstoffen, aan een stuk, in banden of in de vorm van motieven, met een waarde van <= 17,50 € per kg nettogewicht (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. etskant en borduurwerk zonder zichtbaar grondweefsel)',
    58110000: 'Gematelasseerde textielproducten aan het stuk, bestaande uit een of meer lagen textiel, die door stikken of op andere wijze zijn samengevoegd, met watten of ander opvulmateriaal (m.u.v. borduurwerk bedoeld bij post 5810 en beddegoed en artikelen voor stoffering, opgevuld)',
    59011000: 'weefsels bedekt met lijm of met zetmeelachtige stoffen, van de soort gebruikt voor het boekbinden, voor het kartonneren, voor foedraalwerk of voor dergelijk gebruik',
    59019000: 'calqueerlinnen en tekenlinnen; schilderdoek; stijflinnen "buckram" e.d. weefsels van de soort gebruikt voor steunvormen van hoeden (m.u.v. weefsels bedekt met kunststof)',
    59021010: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van nylon of van andere polyamiden, geïmpregneerd met rubber',
    59021090: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van nylon of van andere polyamiden, geïmpregneerd met kunststof (m.u.v. die welke met rubber is geïmpregneerd)',
    59022010: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van polyesters, geïmpregneerd met rubber',
    59022090: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van polyesters, geïmpregneerd met kunststof (m.u.v. die welke met rubber is geïmpregneerd)',
    59029010: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van viscoserayon, geïmpregneerd met rubber',
    59029090: 'bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van viscoserayon, geïmpregneerd met kunststof (m.u.v. die welke met rubber is geïmpregneerd)',
    59031010: 'weefsels, geïmpregneerd met poly"vinylchloride" (m.u.v. wandbekleding van textielstof, geïmpregneerd met poly"vinylchloride")',
    59031090: 'weefsels, bekleed of bedekt met, dan wel met inlagen van poly"vinylchloride" (m.u.v. wandbekleding van textielstof, bedekt met poly"vinylchloride" en vloerbedekking bestaande uit een drager van textielstof, voorzien van een deklaag van of bekleed met poly"vinylchloride")',
    59032010: 'weefsels, geïmpregneerd met polyurethaan (m.u.v. wandbekleding van textielstof, geïmpregneerd met polyurethaan)',
    59032090: 'weefsels, bekleed of bedekt met, dan wel met inlagen van polyurethaan (m.u.v. wandbekleding van textielstof, bedekt met polyurethaan en vloerbedekking bestaande uit een drager van textielstof, voorzien van een deklaag van of bekleed met polyurethaan)',
    59039010: 'weefsels, geïmpregneerd met kunststof (m.u.v. die welke zijn geïmpregneerd met poly"vinylchloride" of polyurethaan en m.u.v. wandbekleding van textielstof, geïmpregneerd met kunststof)',
    59039091: 'weefsels, bekleed of bedekt met, dan wel met inlagen van cellulosederivaten of andere kunststof, met een bovenzijde van textielstof (m.u.v. die welke bekleed of bedekt zijn met, dan wel inlagen hebben van poly"vinylchloride" of polyurethaan en m.u.v. wandbekleding van textielstof, bedekt met kunststof)',
    59039099: 'weefsels, geïmpregneerd, bekleed of bedekt met, dan wel met inlagen van kunststof (m.u.v. die welke zijn geïmpregneerd, bekleed of bedekt met, dan wel inlagen hebben van, poly"vinylchloride" of polyurethaan, of met een bovenzijde van textielstof en m.u.v. bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van nylon of van andere polyamiden, van polyesters of van viscoserayon, m.u.v. wandbekleding van textielstof, geïmpregneerd of bedekt met kunststof en m.u.v. vloerbedekking bestaande uit een drager van textielstof, voorzien van een deklaag van of bekleed met kunststof)',
    59041000: 'linoleum, ook indien in bepaalde vorm gesneden',
    59049000: 'vloerbedekking, bestaande uit een deklaag of een bekleding op een drager van textiel, ook indien in bepaalde vorm gesneden (m.u.v. linoleum)',
    59050010: 'wandbekleding van textielstof, bestaande uit parallel gelegde draden aangebracht op een drager',
    59050030: 'wandbekleding van vlas (m.u.v. wandbekleding bestaande uit parallel gelegde draden aangebracht op een drager)',
    59050050: 'wandbekleding van jute (m.u.v. die bestaande uit parallel gelegde draden aangebracht op een drager)',
    59050070: 'wandbekleding van synthetische of kunstmatige textielvezels (m.u.v. die bestaande uit parallel gelegde draden aangebracht op een drager)',
    59050090: 'wandbekleding van textielstof (m.u.v. die van vlas, jute of van synthetische of kunstmatige textielvezels en m.u.v. die bestaande uit parallel gelegde draden aangebracht op een drager)',
    59061000: 'plakband van gegummeerde weefsels, met een breedte van <= 20 cm (m.u.v. die welke zijn geïmpregneerd of bedekt met farmaceutische zelfstandigheden of zijn opgemaakt voor de verkoop in het klein voor geneeskundige, chirurgische, tandheelkundige of veeartsenijkundige doeleinden)',
    59069100: 'gegummeerde weefsels van brei- of haakwerk, n.e.g.',
    59069910: 'canvas, samengesteld uit parallel gelegde textielgarens, gebonden met rubber',
    59069990: 'gegummeerde weefsels, n.e.g. (m.u.v. die van van brei- of haakwerk en m.u.v. canvas en plakband met een breedte van <= 20 cm, bandenkoordweefsel "tyre cord fabric", van garens met een hoge sterktegraad, van nylon of van andere polyamiden, van polyesters of van viscoserayon)',
    59070000: 'Weefsels, geïmpregneerd, bekleed of bedekt, alsmede beschilderd doek voor theatercoulissen, voor achtergronden van studio\'s of voor dergelijk gebruik, n.e.g.',
    59080000: 'kousen, pitten en wieken, voor lampen, voor komforen, voor aanstekers, voor kaarsen e.d., geweven, gevlochten of gebreid; gloeikousjes en rond gebreide buisjes voor het vervaardigen van gloeikousjes, ook indien geïmpregneerd (m.u.v. met was bedekte kousen en pitten, zoals waspitten, lonten en slagkoorden, pitten bestaande uit garens van textielstoffen en kousen of pitten van glasvezels)',
    59090010: 'brandslangen e.d. slangen, van synthetische textielvezels, ook indien geïmpregneerd of bekleed, en ook indien gewapend, met beslag of met toebehoren van andere stoffen',
    59090090: 'brandslangen e.d. slangen, van textielstoffen, ook indien geïmpregneerd of bekleed, en ook indien gewapend, met beslag of met toebehoren van andere stoffen (m.u.v. die van synthetische textielvezels)',
    59100000: 'Drijfriemen, drijfsnaren en transportbanden, van textielstoffen, ook indien geïmpregneerd, bekleed, bedekt met of met inlagen van kunststof, of versterkt met metaal of andere stoffen (m.u.v. die met een dikte van < 3 mm, aan het stuk of enkel op lengte gesneden, die welke geïmpregneerd, bekleed of bedekt zijn met, dan wel inlagen hebben van rubber en die welke zijn vervaardigd van garen of bindgaren van textielstof, dat is geïmpregneerd, bekleed, bedekt of ommanteld met rubber)',
    59111000: 'weefsels, vilt of met vilt gevoerd weefsel, bekleed of bedekt met, dan wel met inlagen van rubber, leder of andere stoffen, van de soort gebruikt voor de vervaardiging van kaardbeslag, alsmede dergelijke producten voor ander technisch gebruik, incl. lint van fluweel, geïmpregneerd met rubber voor het bekleden van kettingbomen',
    59112000: 'builgaas, ook indien geconfectioneerd',
    59113111: 'Al dan niet vervilte weefsels, van zijde of van synthetische of kunstmatige vezels,  eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines, met een gewicht van < 650 g/m² (bijvoorbeeld vormweefsel)',
    59113119: 'weefsels en vilt, van zijde of van kunstmatige textielvezels, eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines, alsmede weefsels en vilt, van zijde of van synthetische of kunstmatige textielvezels, van de soort gebruikt voor soortgelijke machines als papiermachines "b.v. machines voor pulp of asbestcement", met een gewicht van < 650 g/m²',
    59113190: 'weefsels en vilt, eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines e.d. machines, "b.v. machines voor pulp of asbestcement", met een gewicht van < 650 g/m² (m.u.v. die van zijde of van synthetische of kunstmatige textielvezels)',
    59113211: 'Weefsels met gestikte viltlaag, van zijde of van synthetische of kunstmatige vezels, eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines e.d. machines, met een gewicht van >= 650 g/m² (bijvoorbeeld persvilt)',
    59113219: 'weefsels en vilt, van zijde of van synthetische of kunstmatige textielvezels, eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines e.d. machines, "b.v. machines voor pulp of asbestcement", met een gewicht van >= 650 g/m² (m.u.v. weefsels met gestikte viltlaag, persvilt)',
    59113290: 'weefsels en vilt, eindloos of voorzien van verbindingsstukken, van de soort gebruikt voor papiermachines e.d. machines, "b.v. machines voor pulp of asbestcement", met een gewicht van >= 650 g/m² (m.u.v. die van zijde of van synthetische of kunstmatige textielvezels)',
    59114000: 'persdoeken en grove weefsels, incl. die van mensenhaar, van de soort gebruikt in oliepersen of voor dergelijk technisch gebruik',
    59119010: 'producten en artikelen van vilt, voor technisch gebruik, bedoeld bij aantekening 7 op hoofdstuk 59, n.e.g.',
    59119091: 'Zelfklevende ronde schuurlappen van de soort gebruikt voor de vervaardiging van schijven (wafers) van halfgeleidermateriaal',
    59119099: 'producten en artikelen van textiel, voor technisch gebruik, bedoeld bij aantekening 7 op hoofdstuk 59, n.e.g.',
    60011000: 'hoogpolig brei- en haakwerk, aan het stuk',
    60012100: 'poolbrei- en poolhaakwerk in de vorm van lussenstof, van katoen, aan het stuk',
    60012200: 'poolbrei- en poolhaakwerk in de vorm van lussenstof, van synthetische of kunstmatige vezels, aan het stuk',
    60012900: 'poolbrei- en poolhaakwerk in de vorm van lussenstof, aan het stuk (m.u.v. dat van katoen of van synthetische of kunstmatige vezels)',
    60019100: 'poolbrei- en poolhaakwerk, van katoen, aan het stuk (m.u.v. hoogpolige stoffen en lussenstof)',
    60019200: 'poolbrei- en poolhaakwerk, van synthetische of kunstmatige vezels, aan het stuk (m.u.v. hoogpolige stoffen en lussenstof)',
    60019900: 'poolbrei- en poolhaakwerk, aan het stuk (m.u.v. dat van katoen of van synthetische of kunstmatige vezels en m.u.v. hoogpolige stoffen en lussenstof)',
    60024000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, bevattende >= 5 gewichtspercenten elastomeergarens (m.u.v. die welke rubberdraden bevatten en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60029000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, bevattende >= 5 gewichtspercenten elastomeergarens en rubberdraden, dan wel alleen rubberdraden (m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen, brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen en steriele barrièremiddelen tegen het verkleven voor de chirurgie of de tandheelkunde als bedoeld bij onderverdeling 3006.10.30)',
    60031000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, van wol of van fijn haar (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60032000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, van katoen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60033010: 'raschelkant met een breedte van <= 30 cm, van synthetische vezels (m.u.v. die bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden)',
    60033090: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, van synthetische vezels (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. raschelkant, poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen, brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen en steriele barrièremiddelen tegen het verkleven voor de chirurgie of de tandheelkunde als bedoeld bij onderverdeling 3006.10.30)',
    60034000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm, van kunstmatige vezels (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen, brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen) en steriele barrièremiddelen tegen het verkleven voor de chirurgie of de tandheelkunde als bedoeld bij onderverdeling 3006.10.30)',
    60039000: 'brei- en haakwerk aan het stuk, met een breedte van <= 30 cm (m.u.v. dat van katoen, van synthetische of kunstmatige vezels, van wol of van fijn haar en dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60041000: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, bevattende >= 5 gewichtspercenten elastomeergarens (m.u.v. die welke rubberdraden bevatten en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60049000: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, bevattende >= 5 gewichtspercenten elastomeergarens en rubberdraden, dan wel alleen rubberdraden (m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60052100: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van katoen, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60052200: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van katoen, geverfd (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60052300: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van katoen, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60052400: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van katoen, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60053500: 'Kettingbreiwerk, met een breedte van > 30 cm, van stoffen bedoeld bij aanvullende aantekening 1 op dit hoofdstuk',
    60053600: 'Kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van synthetische vezels, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60053700: 'Kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van synthetische vezels, geverfd, (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60053800: 'Kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van synthetische vezels, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60053900: 'Kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van synthetische vezels, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60054100: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van kunstmatige vezels, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60054200: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van kunstmatige vezels, geverfd (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60054300: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van kunstmatige vezels, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60054400: 'kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van kunstmatige vezels, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60059010: 'kettingbreiwerk aan het stuk, "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm, van wol of van fijn haar (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60059090: 'kettingbreiwerk aan het stuk, "dat verkregen op de galonneermachine daaronder begrepen", met een breedte van > 30 cm (m.u.v. dat van synthetische of kunstmatige vezels, van katoen, van wol of van fijn haar en dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60061000: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van wol of van fijn haar (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60062100: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van katoen, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60062200: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van katoen, geverfd (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60062300: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van katoen, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60062400: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van katoen, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60063100: 'Brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van synthetische vezels, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60063200: 'Brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van synthetische vezels, geverfd (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60063300: 'Brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van synthetische vezels, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60063400: 'Brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van synthetische vezels, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60064100: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van kunstmatige vezels, ongebleekt of gebleekt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60064200: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van kunstmatige vezels, geverfd (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60064300: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van kunstmatige vezels, van verschillend gekleurd garen (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60064400: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm, van kunstmatige vezels, bedrukt (m.u.v. dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    60069000: 'brei- en haakwerk aan het stuk, met een breedte van > 30 cm (m.u.v. dat van synthetische of kunstmatige vezels, van katoen, van wol of van fijn haar en dat bevattende >= 5 gewichtspercenten elastomeergarens of rubberdraden en m.u.v. kettingbreiwerk "dat verkregen op de galonneermachine daaronder begrepen", poolbrei- en poolhaakwerk "hoogpolige stoffen en lussenstof daaronder begrepen", etiketten, insignes en dergelijke artikelen en brei- en haakwerk, geïmpregneerd, bekleed, bedekt of met inlagen)',
    61012010: 'overjassen, jekkers, capes e.d. waren, van brei- of haakwerk, van katoen, voor heren of voor jongens',
    61012090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. kostuums, ensembles, colbertjassen, blazers e.d. en broeken)',
    61013010: 'overjassen, jekkers, capes e.d. waren, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor heren of voor jongens',
    61013090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor heren of voor jongens (m.u.v. kostuums, ensembles, colbertjassen, blazers e.d. en broeken)',
    61019020: 'overjassen, jekkers, capes e.d. artikelen, van brei- of haakwerk, voor heren of voor jongens (m.u.v. die van katoen of van synthetische of kunstmatige vezels)',
    61019080: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, voor heren of voor jongens (m.u.v. die  van katoen of van synthetische of kunstmatige vezels en m.u.v. kostuums, ensembles, colbertjassen, blazers e.d. en broeken)',
    61021010: 'mantels, capes, e.d. artikelen, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes',
    61021090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes, japonnen, rokken, broekrokken en broeken)',
    61022010: 'mantels, capes, e.d. artikelen, van brei- of haakwerk, van katoen, voor dames of voor meisjes',
    61022090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes, japonnen, rokken, broekrokken en broeken)',
    61023010: 'mantels, capes, e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes',
    61023090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes, japonnen, rokken, broekrokken en broeken)',
    61029010: 'mantels, capes, e.d. artikelen, van brei- of haakwerk, voor vrouwen of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of van kunstmatige vezels)',
    61029090: 'anoraks, blousons e.d. artikelen, van brei- of haakwerk, voor vrouwen of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of van kunstmatige vezels en m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes, japonnen, rokken, broekrokken en broeken)',
    61031010: 'Kostuums, van brei- of haakwerk, van wol of van fijn haar, voor heren of voor jongens  (m.u.v. trainingspakken, skipakken en zwemkleding)',
    61031090: 'Kostuums, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens  (m.u.v. die van wol of van fijn haar en m.u.v. trainingspakken, skipakken en zwemkleding)',
    61032200: 'ensembles, van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61032300: 'ensembles, van brei- of haakwerk, van synthetische vezels, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61032900: 'ensembles, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61033100: 'colbertjassen, blazers e.d., van brei- of haakwerk, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en anoraks, blousons e.d. artikelen)',
    61033200: 'colbertjassen, blazers e.d., van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en anoraks, blousons e.d. artikelen)',
    61033300: 'colbertjassen, blazers e.d., van brei- of haakwerk, van synthetische vezels, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en anoraks, blousons e.d. artikelen)',
    61033900: 'colbertjassen, blazers e.d., van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. anoraks, blousons e.d. artikelen)',
    61034100: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van wol of van fijn haar, voor heren of voor jongens (m.u.v. onderbroeken en zwembroeken)',
    61034200: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. onderbroeken en zwembroeken)',
    61034300: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van synthetische vezels, voor heren of voor jongens (m.u.v. onderbroeken en zwembroeken)',
    61034900: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. onderbroeken en zwembroeken)',
    61041300: 'mantelpakken en broekpakken, van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. skipakken en zwemkleding)',
    61041920: 'Mantelpakken en broekpakken, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. die van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61041990: 'Mantelpakken en broekpakken, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61042200: 'ensembles van brei- of haakwerk, katoen, voor dames of voor meisjes (m.u.v. die skipakken en zwemkleding)',
    61042300: 'ensembles van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. die skipakken en zwemkleding)',
    61042910: 'Ensembles van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61042990: 'Ensembles van brei- of haakwerk, van andere textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische vezels en m.u.v. skipakken en zwemkleding)',
    61043100: 'blazers en andere jasjes, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. anoraks, blousons e.d. artikelen)',
    61043200: 'blazers en andere jasjes, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. anoraks, blousons e.d. artikelen)',
    61043300: 'blazers en andere jasjes, van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. anoraks, blousons e.d. artikelen)',
    61043900: 'blazers en andere jasjes, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. anoraks, blousons e.d. artikelen)',
    61044100: 'japonnen van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. onderjurken)',
    61044200: 'japonnen van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. onderjurken)',
    61044300: 'japonnen van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. onderjurken)',
    61044400: 'japonnen van brei- of haakwerk, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. onderjurken)',
    61044900: 'japonnen van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels en m.u.v. onderjurken)',
    61045100: 'rokken en broekrokken, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. onderrokken)',
    61045200: 'rokken en broekrokken, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. onderrokken)',
    61045300: 'rokken en broekrokken, van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. onderrokken)',
    61045900: 'rokken en broekrokken, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. onderrokken)',
    61046100: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. slips en zwembroeken)',
    61046200: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. slips en zwembroeken)',
    61046300: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. slips en zwembroeken)',
    61046900: 'lange en korte broeken, kniebroeken e.d. broeken en zgn. Amerikaanse overalls, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. slips en zwembroeken)',
    61051000: 'overhemden van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. nachthemden, T-shirts, borstrokken en onderhemden)',
    61052010: 'overhemden van brei- of haakwerk, van synthetische vezels, voor heren of voor jongens (m.u.v. nachthemden, T-shirts, borstrokken en onderhemden)',
    61052090: 'overhemden van brei- of haakwerk, van kunstmatige vezels, voor heren of voor jongens (m.u.v. nachthemden, T-shirts, borstrokken en onderhemden)',
    61059010: 'overhemden van brei- of haakwerk, van wol of van fijn haar, voor heren of voor jongens (m.u.v. nachthemden, T-shirts, borstrokken en onderhemden)',
    61059090: 'overhemden van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen, van synthetische of kunstmatige vezels of van wol of van fijn haar en m.u.v. nachthemden, T-shirts, borstrokken en onderhemden)',
    61061000: 'blouses en hemdblouses, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. die van T-shirts, borstrokken en onderhemden)',
    61062000: 'blouses en hemdblouses, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van T-shirts, borstrokken en onderhemden)',
    61069010: 'blouses en hemdblouses, van brei- of haakwerk, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van T-shirts, borstrokken en onderhemden)',
    61069030: 'blouses en hemdblouses, van brei- of haakwerk, van zijde of van afval van zijde, voor dames of voor meisjes (m.u.v. die van T-shirts, borstrokken en onderhemden)',
    61069050: 'blouses en hemdblouses, van brei- of haakwerk, van vlas of van ramee, voor dames of voor meisjes (m.u.v. die van T-shirts, borstrokken en onderhemden)',
    61069090: 'blouses en hemdblouses, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen, van synthetische of kunstmatige vezels, van wol of van fijn haar, van zijde of van afval van zijde, van vlas of van ramee en m.u.v. T-shirts, borstrokken en onderhemden)',
    61071100: 'slips en onderbroeken, van brei- of haakwerk, van katoen, voor heren of voor jongens',
    61071200: 'slips en onderbroeken, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor heren of voor jongens',
    61071900: 'slips en onderbroeken, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen of van synthetische of kunstmatige vezels)',
    61072100: 'nachthemden en pyjama\'s, van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. onderhemden)',
    61072200: 'nachthemden en pyjama\'s, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor heren of voor jongens (m.u.v. onderhemden)',
    61072900: 'nachthemden en pyjama\'s, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen of van chemische of kunstmatige vezels en m.u.v. onderhemden)',
    61079100: 'badjassen, kamerjassen e.d. artikelen, van brei- of haakwerk, van katoen, voor heren of voor jongens',
    61079900: 'badjassen, kamerjassen e.d. artikelen, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen)',
    61081100: 'onderjurken en onderrokken, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. T-shirts, borstrokken en onderhemden)',
    61081900: 'onderjurken en onderrokken, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van chemische of kunstmatige vezels en m.u.v. T-shirts, borstrokken en onderhemden)',
    61082100: 'slips en onderbroeken, van brei- of haakwerk, van katoen, voor dames of voor meisjes',
    61082200: 'slips en onderbroeken, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes',
    61082900: 'slips en onderbroeken, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische of kunstmatige vezels)',
    61083100: 'nachthemden en pyjama\'s, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. T-shirts, borstrokken, onderhemden en negligés)',
    61083200: 'nachthemden en pyjama\'s, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. T-shirts, borstrokken, onderhemden en negligés)',
    61083900: 'nachthemden en pyjama\'s, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische synthetische of kunstmatige vezels en m.u.v. T-shirts, borstrokken, onderhemden en negligés)',
    61089100: 'negligés, badjassen, kamerjassen e.d. artikelen, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. borstrokken en onderhemden, onderjurken, onderrokken, slips, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    61089200: 'negligés, badjassen, kamerjassen e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. borstrokken en onderhemden, onderjurken, onderrokken, slips, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    61089900: 'negligés, badjassen, kamerjassen e.d. artikelen, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. borstrokken en onderhemden, onderjurken, onderrokken, slips, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    61091000: 'T-shirts, borstrokken en onderhemden, van brei- of haakwerk, van katoen',
    61099020: 'T-shirts, borstrokken en onderhemden, van brei- of haakwerk, van wol of van fijn haar of synthetische of kunstmatige vezels',
    61099090: 'T-shirts, borstrokken en onderhemden, van brei- of haakwerk, van textielstoffen (m.u.v. die van katoen, van synthetische of kunstmatige vezels, van wol of van fijn haar)',
    61101110: 'truien, jumpers, pull-overs en slip-overs, bevattende >= 50 gewichtspercenten wol en wegende >= 600 g per stuk',
    61101130: 'truien, jumpers, pull-overs en slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van wol, voor heren of voor jongens (m.u.v. truien, jumpers, pull-overs en slip-overs bevattende >= 50 gewichtspercenten wol en wegende >= 600 g per stuk en m.u.v. gewatteerde vesten)',
    61101190: 'truien, jumpers, pull-overs en slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van wol, voor dames of voor meisjes (m.u.v. truien, jumpers, pull-overs en slip-overs bevattende >= 50 gewichtspercenten wol en wegende >= 600 g per stuk en m.u.v. gewatteerde vesten)',
    61101210: 'truien, jumpers, pull-overs, slip-overs, vesten en dergelijke artikelen, van brei- of haakwerk van fijn haar van kasjmiergeiten, voor heren of voor jongens (m.u.v. gewatteerde vesten)',
    61101290: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk van fijn haar van kasjmiergeiten, voor dames of voor meisjes (m.u.v. gewatteerde vesten)',
    61101910: 'truien, jumpers, pull-overs, slip-overs, vesten en dergelijke artikelen, van brei- of haakwerk van fijn haar, voor heren of voor jongens (m.u.v. die van fijn haar van kasjmiergeiten en m.u.v.gewatteerde vesten)',
    61101990: 'truien, jumpers, pull-overs, slip-overs, vesten en dergelijke artikelen, van brei- of haakwerk van fijn haar, voor dames of voor meisjes (m.u.v. die van fijn haar van kasjmiergeiten en m.u.v.gewatteerde vesten)',
    61102010: 'hemdtruien "sous-pull", van brei- of haakwerk, van katoen',
    61102091: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van katoen, voor heren of voor jongens (m.u.v. hemdtruien "sous-pull" en gewatteerde vesten)',
    61102099: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van katoen, voor dames of voor meisjes (m.u.v. hemdtruien "sous-pull" en gewatteerde vesten)',
    61103010: 'hemdtruien "sous-pull", van brei- of haakwerk, van synthetische of kunstmatige vezels',
    61103091: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor heren of voor jongens (m.u.v. hemdtruien "sous-pull" en gewatteerde vesten)',
    61103099: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. hemdtruien "sous-pull" en gewatteerde vesten)',
    61109010: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van vlas of van ramee (m.u.v. gewatteerde vesten)',
    61109090: 'truien, jumpers, pull-overs, slip-overs, vesten e.d. artikelen, van brei- of haakwerk, van textielstoffen (m.u.v. die van wol of van fijn haar, van katoen, van synthetische of kunstmatige vezels, van vlas of van ramee en m.u.v. gewatteerde vesten)',
    61112010: 'handschoenen en, wanten, voor baby\'s, van brei- of haakwerk, van katoen',
    61112090: 'kleding en kledingtoebehoren, voor baby\'s, van brei- of haakwerk, van katoen (m.u.v. handschoenen, wanten e.d. en m.u.v. mutsen)',
    61113010: 'handschoenen en wanten, voor baby\'s, van brei- of haakwerk, synthetische vezels',
    61113090: 'kleding en kledingtoebehoren, voor baby\'s, van brei- of haakwerk, van synthetische vezels (m.u.v. handschoenen, wanten e.d., en mutsen)',
    61119011: 'handschoenen, wanten e.d., voor baby\'s, van brei- of haakwerk, van wol of van fijn haar',
    61119019: 'kleding en kledingtoebehoren, voor baby\'s, van brei- of haakwerk, van wol of van fijn haar (m.u.v. handschoenen, wanten en m.u.v. mutsen)',
    61119090: 'kleding en kledingtoebehoren, voor baby\'s, van brei- of haakwerk, van textielstoffen (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. mutsen)',
    61121100: 'trainingspakken, van brei- of haakwerk, van katoen',
    61121200: 'trainingspakken, van brei- of haakwerk, van synthetische vezels',
    61121900: 'trainingspakken, van brei- of haakwerk, van textielstoffen (m.u.v. die van katoen of van synthetische vezels)',
    61122000: 'skipakken, van brei- of haakwerk',
    61123110: 'badpakken en zwembroeken, van brei- of haakwerk, van synthetische vezels, bevattende >= 5 gewichtspercenten rubberdraden, voor heren of voor jongens',
    61123190: 'badpakken en zwembroeken, van brei- of haakwerk, van synthetische vezels, voor heren of voor jongens (m.u.v. die bevattende >= 5 gewichtspercenten rubberdraden)',
    61123910: 'badpakken en zwembroeken, van brei- of haakwerk, van textielstoffen, bevattende >= 5 gewichtspercenten rubberdraden, voor heren of voor jongens (m.u.v. die van synthetische vezels)',
    61123990: 'badpakken en zwembroeken, van brei- of haakwerk, van textielstoffen, voor heren of voor jongens (m.u.v. die bevattende >= 5 gewichtspercenten rubberdraden en m.u.v. die van synthetische vezels)',
    61124110: 'badpakken en zwembroeken, van brei- of haakwerk, van synthetische vezels, bevattende >= 5 gewichtspercenten rubberdraden, voor dames of voor meisjes',
    61124190: 'badpakken en zwembroeken, van brei- of haakwerk, van synthetische vezels, voor dames of voor meisjes (m.u.v. die bevattende >= 5 gewichtspercenten rubberdraden)',
    61124910: 'badpakken en zwembroeken, van brei- of haakwerk, van textielstoffen, bevattende >= 5 gewichtspercenten rubberdraden, voor dames of voor meisjes (m.u.v. die van synthetische vezels)',
    61124990: 'badpakken en zwembroeken, van brei- of haakwerk, van textielstoffen, voor dames of voor meisjes (m.u.v. die bevattende >= 5 gewichtspercenten rubberdraden en m.u.v. die van synthetische vezels)',
    61130010: 'kleding van gegummeerd brei- of haakwerk (m.u.v. babykleding en kledingtoebehoren)',
    61130090: 'kleding van brei- of haakwerk, geïmpregneerd, bekleed of bedekt met kunststof of andere stoffen (m.u.v. die van gegummeerde weefsels en m.u.v. babykleding en kledingtoebehoren)',
    61142000: 'speciale kleding voor bepaalde beroepen, sporten of dergelijke doeleinden, n.e.g., van brei- of haakwerk, van katoen',
    61143000: 'speciale kleding voor bepaalde beroepen, sporten of dergelijke doeleinden, n.e.g., van brei- of haakwerk, van synthetische of van kunstmatige vezels',
    61149000: 'speciale kleding voor bepaalde beroepen, sporten of dergelijke doeleinden, n.e.g., van brei- of haakwerk, van textielstoffen (m.u.v. die van katoen of van synthetische of kunstmatige vezels)',
    61151010: 'aderspatkousen, van brei- of haakwerk, van synthetische vezels',
    61151090: 'kousenbroeken, kousen en kniekousen met degressieve compressie, van brei- of haakwerk (m.u.v. aderspatkousen van synthetische vezels en m.u.v. die voor babies)',
    61152100: 'kousenbroeken, van brei- of haakwerk, van synthetische vezels van < 67 decitex per enkelvoudige draad (m.u.v. die met degressieve compressie)',
    61152200: 'kousenbroeken, van brei- of haakwerk, van synthetische vezels van >= 67 decitex per enkelvoudige draad (m.u.v. van die met degressieve compressie)',
    61152900: 'kousenbroeken, van brei- of haakwerk, van textielstoffen (m.u.v. die met degressieve compressie en m.u.v. die van synthetische vezels en die voor baby\'s)',
    61153011: 'dameskniekousen, van brei- of haakwerk, van synthetische vezels, van < 67 decitex per enkelvoudige draad (m.u.v. die met degressieve compressie)',
    61153019: 'dameskousen, van brei- of haakwerk, van synthetische vezels, van < 67 decitex per enkelvoudige draad (m.u.v. van die met degressieve compressie en m.u.v. kousenbroeken en kniekousen)',
    61153090: 'dameskousen en -kniekousen, van brei- of haakwerk, van < 67 decitex per enkelvoudige draad (m.u.v. die met degressieve compressie en m.u.v. die van synthetische vezels en kousenbroeken)',
    61159400: 'kousen, kniekousen, sokken e.d. artikelen, van brei- of haakwerk, van wol of van fijn haar (m.u.v. die met degressieve compressie, kousenbroeken, dameskousen en -kniekousen, van < 67 decitex per enkelvoudige draad en m.u.v. dergelijke artikelen voor baby\'s)',
    61159500: 'kousen, kniekousen, sokken e.d. artikelen, van brei- of haakwerk, van katoen (m.u.v. die met degressieve compressie, kousenbroeken, dameskousen en -kniekousen, van < 67 decitex per enkelvoudige draad en m.u.v. dergelijke artikelen voor baby\'s)',
    61159610: 'kniekousen, van brei- of haakwerk, van synthetische vezels (m.u.v die met degressieve compressie, dameskousen van < 67 decitex per enkelvoudige draad en kousen e.d. artikelen voor baby\'s)',
    61159691: 'dameskousen, van brei- of haakwerk, van synthetische vezels (m.u.v. die met degressieve compressie, die van < 67 decitex per enkelvoudige draad en m.u.v. kousenbroeken, kniekousen)',
    61159699: 'kousen, sokken e.d. artikelen, van brei- of haakwerk, van synthetische vezels (m.u.v. die met degressieve compressie, dameskousen, kousenbroeken, kniekousen en m.u.v. kousen e.d. artikelen voor baby\'s)',
    61159900: 'kousen, kniekousen, sokken e.d. artikelen, van brei- of haakwerk, van textielstoffen (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels, die met degressieve compressie, kousenbroeken, dameskousen en -kniekousen van < 67 decitex per enkelvoudige draad en m.u.v. kousen e.d. artikelen voor baby\'s)',
    61161020: 'vingerhandschoenen van brei- of haakwerk, geïmpregneerd, bekleed of bedekt met rubber (m.u.v. wanten en mitaines)',
    61161080: 'handschoenen zonder vingers en wanten, van brei- of haakwerk, geïmpregneerd, bekleed of bedekt met rubber of met kunststof, alsmede vingerhandschoenen, van brei- of haakwerk, geïmpregneerd, bekleed of bedekt met kunststof',
    61169100: 'handschoenen [met of zonder vingers] en wanten, van brei- of haakwerk, van wol of van fijn haar (m.u.v. die voor baby\'s)',
    61169200: 'handschoenen [met of zonder vingers] en wanten, van brei- of haakwerk, van katoen (m.u.v. die voor baby\'s en die welke zijn geïmpregneerd, bekleed of bedekt met kunststof of met rubber)',
    61169300: 'handschoenen [met of zonder vingers] en wanten, van brei- of haakwerk, van synthetische vezels (m.u.v. die voor baby\'s en die welke zijn geïmpregneerd, bekleed of bedekt met kunststof of met rubber)',
    61169900: 'handschoenen [met of zonder vingers] en wanten, van brei- of haakwerk (m.u.v. die van wol, van fijn haar, van katoen of van synthetische vezels, die voor baby\'s en die welke zijn geïmpregneerd, bekleed of bedekt met kunststof of met rubber)',
    61171000: 'sjaals, sjerpen, hoofddeksels en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van brei- of haakwerk',
    61178010: 'geconfectioneerd kledingtoebehoren, van elastisch of gegummeerd brei- of haakwerk, n.e.g.',
    61178080: 'dassen, strikjes en sjaaldassen en andere geconfectioneerd kledingtoebehoren, van brei- of haakwerk, n.e.g. (m.u.v. van die van elastisch of gegummeerd brei- of haakwerk en m.u.v. sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen)',
    61179000: 'delen van kleding of van kledingtoebehoren, van brei- of haakwerk, n.e.g.',
    62011100: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- en haakwerk)',
    62011210: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van katoen, wegende per stuk <= 1 kg, voor heren of voor jongens (m.u.v. die van brei- en haakwerk)',
    62011290: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van katoen, wegende per stuk > 1 kg, voor heren of voor jongens (m.u.v. die van brei- en haakwerk)',
    62011310: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van synthetische of van kunstmatige vezels, wegende per stuk <= 1 kg, voor heren of voor jongens (m.u.v. die van brei- en haakwerk)',
    62011390: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van synthetische of van kunstmatige vezels, wegende per stuk > 1 kg, voor heren of voor jongens (m.u.v. die van brei- en haakwerk)',
    62011900: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, van textielstof (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels en m.u.v. die van brei- en haakwerk)',
    62019100: 'anoraks, blousons e.d. artikelen, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. kostuums, ensembles, colbertjassen, blazers e.d. en broeken)',
    62019200: 'anoraks, blousons e.d. artikelen, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. kostuums, ensembles, colbertjassen, blazers e.d., broeken en bovendelen van skipakken)',
    62019300: 'anoraks, blousons e.d. artikelen, van synthetische of kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. kostuums, ensembles, colbertjassen, blazers e.d., broeken en bovendelen van skipakken)',
    62019900: 'anoraks, blousons e.d. artikelen, van textielstoffen (m.u.v. die van wol of van fijn haar, van katoen, van synthetische of van kunstmatige vezels, m.u.v. brei- of haakwerk en m.u.v. kostuums, ensembles, colbertjassen, blazers e.d. en broeken)',
    62021100: 'mantels, regenjassen, capes e.d. artikelen, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62021210: 'mantels, regenjassen, capes e.d. artikelen, van katoen, wegende per stuk <= 1 kg, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62021290: 'mantels, regenjassen, capes e.d. artikelen, van katoen, wegende per stuk > 1 kg, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62021310: 'mantels, regenjassen, capes e.d. artikelen, van synthetische of van kunstmatige vezels, wegende per stuk <= 1 kg, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62021390: 'mantels, regenjassen, capes e.d. artikelen, van synthetische of van kunstmatige vezels, wegende per stuk > 1 kg, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62021900: 'mantels, regenjassen, capes e.d. artikelen, van textielstoffen voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels en m.u.v. die van brei- of haakwerk)',
    62029100: 'anoraks, blousons e.d. artikelen, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes en broeken)',
    62029200: 'anoraks, blousons e.d. artikelen, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. mantelpakken, broekpakken, ensembles, blazers e.d., broeken en bovendelen van skipakken)',
    62029300: 'anoraks, blousons e.d. artikelen, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. mantelpakken, broekpakken, ensembles, blazers e.d., broeken en bovendelen van skipakken)',
    62029900: 'anoraks, blousons e.d. artikelen, van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. mantelpakken, broekpakken, ensembles, blazers en andere jasjes en broeken)',
    62031100: 'kostuums, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, skipakken en zwemkleding)',
    62031200: 'kostuums, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, skipakken en zwemkleding)',
    62031910: 'kostuums, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, skipakken en zwemkleding)',
    62031930: 'kostuums, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, skipakken en zwemkleding)',
    62031990: 'kostuums, van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen, van synthetische of van kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, skipakken en zwemkleding)',
    62032210: 'werk- en bedrijfskleding, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62032280: 'ensembles van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62032310: 'werk- en bedrijfskleding, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62032380: 'ensembles van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62032911: 'werk- en bedrijfskleding, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62032918: 'ensembles van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62032930: 'ensembles van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. ski-ensembles en zwemkleding)',
    62032990: 'ensembles van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. ski-ensembles en zwemkleding)',
    62033100: 'colbertjassen, blazers e.d., van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62033210: 'werk- en bedrijfskleding, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62033290: 'colbertjassen, blazers e.d., van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62033310: 'werk- en bedrijfskleding, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62033390: 'colbertjassen, blazers e.d., van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62033911: 'werk- en bedrijfskleding, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62033919: 'colbertjassen, blazers e.d., van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62033990: 'colbertjassen, blazers e.d., van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62034110: 'lange broeken, incl. kniebroeken e.d. broeken, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls en onderbroeken)',
    62034130: 'zgn. Amerikaanse overalls, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62034190: 'korte broeken, van wol of van fijn haar, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken en zwembroeken)',
    62034211: 'lange broeken, zijnde werk- en bedrijfskleding, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62034231: 'lange broeken, incl. kniebroeken e.d. broeken, van denim, voor heren of voor jongens (m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls)',
    62034233: 'lange broeken, incl. kniebroeken e.d. broeken, van gesneden inslagfluweel en -pluche, geribd "corduroy", voor heren of voor jongens (m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls)',
    62034235: 'lange broeken, incl. kniebroeken e.d. broeken, van katoen, voor heren of voor jongens (m.u.v. die van denim of van gesneden inslagfluweel of -pluche, geribd "corduroy", dan wel van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls en onderbroeken)',
    62034251: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62034259: 'zgn. Amerikaanse overalls, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62034290: 'korte broeken van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken en zwembroeken)',
    62034311: 'lange broeken, zijnde werk- en bedrijfskleding, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62034319: 'lange broeken, incl. kniebroeken e.d. broeken, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls en onderbroeken)',
    62034331: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62034339: 'zgn. Amerikaanse overalls, van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62034390: 'korte broeken van synthetische vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken en zwembroeken)',
    62034911: 'lange broeken, zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62034919: 'lange broeken, incl. kniebroeken e.d. broeken, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls en onderbroeken)',
    62034931: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62034939: 'zgn. Amerikaanse overalls, van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62034950: 'korte broeken van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken en zwembroeken)',
    62034990: 'lange broeken, incl. kniebroeken e.d. broeken, zgn. Amerikaanse overalls en korte broeken, van textielstoffen, voor heren of voor jongens (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken en zwembroeken)',
    62041100: 'mantelpakken en broekpakken, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. skioveralls en zwemkleding)',
    62041200: 'mantelpakken en broekpakken, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. skioveralls en zwemkleding)',
    62041300: 'mantelpakken en broekpakken, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. skioveralls en zwemkleding)',
    62041910: 'mantelpakken en broekpakken, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. skioveralls en zwemkleding)',
    62041990: 'mantelpakken en broekpakken van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. skioveralls en zwemkleding)',
    62042100: 'ensembles, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. ski-ensembles en zwemkleding)',
    62042210: 'ensembles zijnde werk- en bedrijfskleding, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62042280: 'ensembles van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62042310: 'ensembles zijnde werk- en bedrijfskleding, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62042380: 'ensembles van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62042911: 'ensembles zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62042918: 'ensembles van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, trainingspakken, skipakken en zwemkleding)',
    62042990: 'ensembles, van textielstoffen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. trainingspakken, ski-ensembles en zwemkleding)',
    62043100: 'blazers en andere jasjes, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62043210: 'blazers en andere jasjes, zijnde werk- en bedrijfskleding, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62043290: 'blazers en andere jasjes, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62043310: 'blazers en andere jasjes, zijnde werk- en bedrijfskleding, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62043390: 'blazers en andere jasjes, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62043911: 'blazers en andere jasjes, zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62043919: 'blazers en andere jasjes, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, anoraks, blousons e.d. artikelen)',
    62043990: 'blazers en andere jasjes, van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. anoraks, blousons e.d. artikelen)',
    62044100: 'japonnen, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62044200: 'japonnen, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62044300: 'japonnen, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62044400: 'japonnen, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62044910: 'Japonnen, van zijde of van afval van zijde, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62044990: 'Japonnen, van textielstoffen, voor dames of voor meisjes, (m.u.v. die van zijde of van afval van zijde, van wol of van fijn haar, van katoen, van synthetische of van kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderjurken)',
    62045100: 'rokken en broekrokken, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderrokken)',
    62045200: 'rokken en broekrokken, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderrokken)',
    62045300: 'rokken en broekrokken, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderrokken)',
    62045910: 'rokken en broekrokken, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderrokken)',
    62045990: 'rokken en broekrokken, van textielstoffen, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderrokken)',
    62046110: 'lange broeken, incl. kniebroeken e.d. broeken, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls en slips)',
    62046185: 'zgn. Amerikaanse overalls en korte broeken, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. slips en zwembroeken)',
    62046211: 'lange broeken, zijnde werk- en bedrijfskleding, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62046231: 'lange broeken, incl. kniebroeken e.d. broeken, van denim, voor dames of voor meisjes (m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls en slips)',
    62046233: 'lange broeken, incl. kniebroeken e.d. broeken, van gesneden inslagfluweel en -pluche, geribd "corduroy", voor dames of voor meisjes (m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls en slips)',
    62046239: 'lange broeken, incl. kniebroeken e.d. broeken, van katoen, voor dames of voor meisjes (m.u.v. die van denim of van gesneden inslagfluweel en -pluche, geribd "corduroy", dan wel van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls, onderbroeken en trainingsbroeken)',
    62046251: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62046259: 'zgn. Amerikaanse overalls, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62046290: 'korte broeken, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. slips en zwembroeken)',
    62046311: 'lange broeken, zijnde werk- en bedrijfskleding, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62046318: 'lange broeken, incl. kniebroeken e.d. broeken, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls, onderbroeken en trainingsbroeken)',
    62046331: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62046339: 'zgn. Amerikaanse overalls van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62046390: 'korte broeken, van synthetische vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. slips en zwembroeken)',
    62046911: 'lange broeken, zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. zgn. Amerikaanse overalls)',
    62046918: 'lange broeken, incl. kniebroeken e.d. broeken, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding, zgn. Amerikaanse overalls, onderbroeken en trainingsbroeken)',
    62046931: 'zgn. Amerikaanse overalls, zijnde werk- en bedrijfskleding, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62046939: 'zgn. Amerikaanse overalls van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. werk- en bedrijfskleding)',
    62046950: 'korte broeken, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. slips en zwembroeken)',
    62046990: 'lange broeken, incl. kniebroeken e.d. broeken, zgn. Amerikaanse overalls en korte broeken, van kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van wol of van fijn haar, van katoen of van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderbroeken, slips en zwembroeken)',
    62052000: 'overhemden, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. nachthemden en onderhemden)',
    62053000: 'overhemden, van synthetische of van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. nachthemden en onderhemden)',
    62059010: 'overhemden, van vlas of van ramee, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. nachthemden en onderhemden)',
    62059080: 'overhemden, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen, van synthetische of kunstmatige vezels, van vlas of van ramee, m.u.v. die van brei- of haakwerk en m.u.v. nachthemden en onderhemden)',
    62061000: 'blouses en hemdblouses, van zijde of van afval van zijde voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62062000: 'blouses en hemdblouses, van wol of van fijn haar, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62063000: 'blouses en hemdblouses, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62064000: 'blouses en hemdblouses, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62069010: 'blouses en hemdblouses, van vlas of van ramee, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62069090: 'blouses en hemdblouses, van textielstoffen, voor dames of voor meisjes (m.u.v. die van zijde of van afval van zijde, van wol of van fijn haar, van katoen, van synthetische of kunstmatige vezels, van vlas of van ramee, m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62071100: 'slips en onderbroeken, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62071900: 'slips en onderbroeken, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen en m.u.v. die van brei- of haakwerk)',
    62072100: 'nachthemden en pyjama\'s, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden, slips en onderbroeken)',
    62072200: 'nachthemden en pyjama\'s, van synthetische of van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden, slips en onderbroeken)',
    62072900: 'nachthemden en pyjama\'s, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen of van synthetische of van kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderhemden, slips en onderbroeken)',
    62079100: 'onderhemden, badjassen, kamerjassen e.d. artikelen, van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken, nachthemden en pyjama\'s)',
    62079910: 'onderhemden, badjassen, kamerjassen e.d. artikelen, van synthetische of van kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken, nachthemden en pyjama\'s)',
    62079990: 'onderhemden, badjassen, kamerjassen e.d. artikelen, van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen of van synthetische of van kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. slips, onderbroeken, nachthemden en pyjama\'s)',
    62081100: 'onderjurken en onderrokken, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62081900: 'onderjurken en onderrokken, van textielstoffen, voor dames of voor meisjes (m.u.v. die van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderhemden)',
    62082100: 'nachthemden en pyjama\'s, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden en negligés)',
    62082200: 'nachthemden en pyjama\'s, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. onderhemden en negligés)',
    62082900: 'nachthemden en pyjama\'s, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen en van synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderhemden en negligés)',
    62089100: 'onderhemden, slips, negligés, badjassen, kamerjassen e.d. artikelen, van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. van onderjurken, onderrokken, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    62089200: 'onderhemden, slips, negligés, badjassen, kamerjassen e.d. artikelen, van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en m.u.v. van onderjurken, onderrokken, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    62089900: 'onderhemden, slips, negligés, badjassen, kamerjassen e.d. artikelen, van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of synthetische of kunstmatige vezels, m.u.v. die van brei- of haakwerk en m.u.v. onderjurken, onderrokken, nachthemden, pyjama\'s, bustehouders, gaines "step-ins", corsetten e.d. artikelen)',
    62092000: 'kleding en kledingtoebehoren, van katoen, voor baby\'s (m.u.v. die van brei- of haakwerk en m.u.v. mutsen, luiers en inlegluiers (post 9619))',
    62093000: 'kleding en kledingtoebehoren, van synthetische vezels, voor baby\'s (m.u.v. die van brei- of haakwerk en m.u.v. mutsen, luiers en inlegluiers (post 9619))',
    62099010: 'kleding en kledingtoebehoren, van wol of van fijn haar, voor baby\'s (m.u.v. die van brei- of haakwerk en m.u.v. mutsen)',
    62099090: 'kleding en kledingtoebehoren, van textielstoffen, voor baby\'s (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels, m.u.v. die van brei- of haakwerk en m.u.v. mutsen, luiers en inlegluiers)',
    62101010: 'kleding vervaardigd van vilt, ook indien geïmpregneerd, bekleed, bedekt of met inlagen (m.u.v. kleding voor baby\'s en kledingtoebehoren)',
    62101092: 'operatieschorten voor eenmalig gebruik, van de soort die tijdens operaties door patiënten of chirurgen worden gebruikt',
    62101098: 'kleding vervaardigd van gebonden textielvlies, ook indien geïmpregneerd, bekleed, bedekt of met inlagen (m.u.v. kleding voor baby\'s en kledingtoebehoren en m.u.v. operatieschorten voor eenmalig gebruik, van de soort die tijdens operaties door patiënten of chirurgen worden gebruikt)',
    62102000: 'overjassen, regenjassen, jekkers, capes e.d. artikelen, voor heren of voor jongens, geïmpregneerd, bekleed of bedekt met, dan wel met inlagen van kunststof of andere stoffen (m.u.v. die van brei- of haakwerk)',
    62103000: 'mantels, regenjassen, capes e.d. artikelen, voor dames of voor meisjes, geïmpregneerd, bekleed of bedekt met, dan wel met inlagen van kunststof of andere stoffen (m.u.v. die van brei- of haakwerk)',
    62104000: 'kleding van weefsel, geïmpregneerd, bekleed of bedekt met, dan wel met inlagen van kunststof of andere stoffen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en m.u.v. overjassen, regenjassen, jekkers, capes e.d. artikelen, kleding voor baby\'s en kledingtoebehoren)',
    62105000: 'kleding vervaardigd van producten bedoeld bij post 5903, 5906 of 5907, voor dames of voor meisjes (m.u.v. mantels, regenjassen, capes e.d. artikelen, kleding voor baby\'s en kledingtoebehoren en m.u.v. operatieschorten voor eenmalig gebruik, van de soort die tijdens operaties door patiënten of chirurgen wordt gebruikt, luiers, inlegluiers e.d. artikelen)',
    62111100: 'badpakken en zwembroeken, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62111200: 'badpakken en zwembroeken, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62112000: 'skipakken "overalls en ensembles" (m.u.v. die van brei- of haakwerk)',
    62113210: 'werk- en bedrijfskleding, n.e.g., van katoen, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113231: 'trainingspakken van katoen, met voering, waarvan de buitenzijde is vervaardigd van een en dezelfde stof, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113241: 'delen van trainingspakken voor het bovenlichaam, van katoen, met voering, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62113242: 'delen van trainingspakken voor het onderlichaam, van katoen, met voering, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62113290: 'kleding van katoen, n.e.g., voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113310: 'werk- en bedrijfskleding, van synthetische of kunstmatige vezels, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113331: 'trainingspakken van synthetische of kunstmatige vezels, met voering, waarvan de buitenzijde is vervaardigd van een en dezelfde stof, voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113341: 'delen van trainingspakken voor het bovenlichaam, van synthetische of kunstmatige vezels, met voering, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62113342: 'delen van trainingspakken voor het onderlichaam, van synthetische of kunstmatige vezels, met voering, voor heren of voor jongens (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62113390: 'kleding van synthetische of kunstmatige vezels, n.e.g., voor heren of voor jongens (m.u.v. die van brei- of haakwerk)',
    62113900: 'trainingspakken en andere kleding, n.e.g., van textielstoffen, voor heren of voor jongens (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. die van brei- of haakwerk)',
    62114210: 'schorten, stofjassen en andere werk- en bedrijfskleding, n.e.g., van katoen, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114231: 'trainingspakken van katoen, met voering, waarvan de buitenzijde is vervaardigd van een en dezelfde stof, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114241: 'delen van trainingspakken voor het bovenlichaam, van katoen, met voering, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62114242: 'delen van trainingspakken voor het onderlichaam, van katoen, met voering, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62114290: 'kleding van katoen, n.e.g., voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114310: 'schorten, stofjassen en andere werk- en bedrijfskleding, n.e.g., van synthetische of kunstmatige vezels, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114331: 'trainingspakken van synthetische of kunstmatige vezels, met voering, waarvan de buitenzijde is vervaardigd van een en dezelfde stof, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114341: 'delen van trainingspakken voor het bovenlichaam, van synthetische of kunstmatige vezels, met voering, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62114342: 'delen van trainingspakken voor het onderlichaam, van synthetische of kunstmatige vezels, met voering, voor dames of voor meisjes (m.u.v. die van brei- of haakwerk en die waarvan de buitenzijde is vervaardigd van een en dezelfde stof)',
    62114390: 'kleding van synthetische of kunstmatige vezels, n.e.g., voor dames of voor meisjes (m.u.v. die van brei- of haakwerk)',
    62114900: 'trainingspakken en andere kleding, n.e.g., van textielstoffen, voor dames of voor meisjes (m.u.v. die van katoen of van synthetische of kunstmatige vezels en m.u.v. die van brei- of haakwerk en goederen van post 9619)',
    62121010: 'bustehouders van textiel, ook indien elastisch, incl. die van brei- of haakwerk, in stellen of assortimenten opgemaakt voor de verkoop in het klein, bestaande uit een bustehouder en een slip',
    62121090: 'bustehouders van textiel, ook indien elastisch, incl. die van brei- of haakwerk (m.u.v. die in stellen of assortimenten opgemaakt voor de verkoop in het klein, bestaande uit een bustehouder en een slip)',
    62122000: 'gaines "step-ins" en gainebroeken "panty\'s", van textiel, ook indien elastisch, incl. die van brei- of haakwerk (m.u.v. corsetten en gordels die geheel uit rubber bestaan)',
    62123000: 'corseletten, van textiel, ook indien elastisch, incl. die van brei- of haakwerk',
    62129000: 'corsetten, bretels, jarretelles, kousenbanden e.d. artikelen, alsmede delen daarvan, incl. delen van bustehouders, gaines "step-ins", gainebroeken "panty\'s" en corseletten, van textiel, ook indien elastisch, incl. die van brei- of haakwerk (m.u.v. volledige bustehouders, gaines "step-ins", gainebroeken "panty\'s" en corseletten)',
    62132000: 'zakdoeken van katoen, met een lengte van alle zijden van <= 60 cm (m.u.v. die van brei- of haakwerk)',
    62139000: 'zakdoeken van textielstoffen, met een lengte van alle zijden van <= 60 cm (m.u.v. die van katoen en m.u.v. die van brei- of haakwerk)',
    62141000: 'sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van zijde of van afval van zijde (m.u.v. die van brei- of haakwerk)',
    62142000: 'sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van wol of van fijn haar (m.u.v. die van brei- of haakwerk)',
    62143000: 'sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van synthetische vezels (m.u.v. die van brei- of haakwerk)',
    62144000: 'sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van kunstmatige vezels (m.u.v. die van brei- of haakwerk)',
    62149000: 'Sjaals, sjerpen, hoofddoeken en halsdoeken, mantilles, sluiers, voiles e.d. artikelen, van textielstoffen (m.u.v. die van wol of van fijn haar of van synthetische of kunstmatige vezels, die van zijde of van afval van zijde en m.u.v. die brei- of haakwerk)',
    62151000: 'dassen, strikjes en sjaaldassen, van zijde of van afval van zijde (m.u.v. die brei- of haakwerk)',
    62152000: 'dassen, strikjes en sjaaldassen, van synthetische of kunstmatige vezels (m.u.v. die brei- of haakwerk)',
    62159000: 'dassen, strikjes en sjaaldassen, van textielstoffen (m.u.v. die van zijde of van afval van zijde, of van synthetische of kunstmatige vezels en m.u.v. die brei- of haakwerk)',
    62160000: 'handschoenen [met of zonder vingers] en wanten, van textiel (m.u.v. die van brei- of haakwerk en die voor baby\'s)',
    62171000: 'geconfectioneerd kledingtoebehoren, van ongeacht welke textielstoffen, n.e.g. (m.u.v. die van brei- of haakwerk)',
    62179000: 'delen van kleding of van kledingtoebehoren, van ongeacht welke textielstoffen, n.e.g. (m.u.v. die van brei- of haakwerk)',
    63011000: 'elektrisch verwarmde dekens van textiel',
    63012010: 'dekens van brei- of haakwerk, van wol of van fijn haar (m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63012090: 'dekens, van wol of van fijn haar (m.u.v. die van brei- of haakwerk en m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63013010: 'dekens van brei- of haakwerk, van katoen (m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63013090: 'dekens van katoen (m.u.v. die van brei- of haakwerk en m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63014010: 'dekens van brei- of haakwerk, van synthetische vezels (m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63014090: 'dekens van synthetische vezels (m.u.v. die van brei- of haakwerk en m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63019010: 'dekens van brei- of haakwerk (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels en m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63019090: 'dekens van textiel (m.u.v. die van wol of van fijn haar, van katoen of van synthetische vezels, m.u.v. die van brei- of haakwerk en m.u.v. elektrisch verwarmde dekens, spreien en artikelen voor bedden e.d. als bedoeld bij post 9404)',
    63021000: 'beddenlinnen van brei- of haakwerk',
    63022100: 'beddenlinnen van katoen, bedrukt (m.u.v. die van brei- of haakwerk)',
    63022210: 'beddenlinnen van gebonden textielvlies van synthetische of kunstmatige vezels, bedrukt',
    63022290: 'beddenlinnen van synthetische of kunstmatige vezels, bedrukt (m.u.v. dat van brei- of haakwerk en m.u.v. dat van gebonden textielvlies)',
    63022910: 'beddenlinnen van vlas of van ramee, bedrukt (m.u.v. dat van brei- of haakwerk)',
    63022990: 'beddenlinnen van textiel, bedrukt (m.u.v. dat van katoen, synthetische of kunstmatige vezels, vlas of ramee en m.u.v. dat van brei- of haakwerk)',
    63023100: 'beddenlinnen van katoen (m.u.v. dat van brei- of haakwerk en m.u.v. bedrukt beddenlinnen)',
    63023210: 'beddenlinnen van gebonden textielvlies van synthetische of kunstmatige vezels (m.u.v. bedrukt beddenlinnen)',
    63023290: 'beddenlinnen van synthetische of kunstmatige vezels (m.u.v. dat van gebonden textielvlies of dat van brei- of haakwerk en m.u.v. bedrukt beddenlinnen)',
    63023920: 'beddenlinnen van vlas of van ramee (m.u.v. dat van brei- of haakwerk en m.u.v. bedrukt beddenlinnen)',
    63023990: 'beddenlinnen van textiel (m.u.v. dat van katoen, synthetische of kunstmatige vezels, vlas of ramee, m.u.v. dat van brei- of haakwerk en m.u.v. bedrukt beddenlinnen)',
    63024000: 'tafellinnen van brei- of haakwerk',
    63025100: 'tafellinnen van katoen (m.u.v. dat van brei- of haakwerk)',
    63025310: 'tafellinnen van gebonden textielvlies van synthetische of kunstmatige vezels',
    63025390: 'tafellinnen van synthetische of kunstmatige vezels (m.u.v. dat van gebonden textielvlies of dat van brei- of haakwerk)',
    63025910: 'tafellinnen van vlas (m.u.v. dat van brei- of haakwerk)',
    63025990: 'tafellinnen van textielstoffen (m.u.v. dat van katoen, van vlas of van synthetische of kunstmatige vezels en m.u.v. dat van brei- of haakwerk)',
    63026000: 'huishoudlinnen van lussenstof van katoen (m.u.v. dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63029100: 'huishoudlinnen van katoen (m.u.v. dat van lussenstof en m.u.v. dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63029310: 'huishoudlinnen van gebonden textielvlies van synthetische of kunstmatige vezels (m.u.v. dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63029390: 'huishoudlinnen van synthetische of kunstmatige vezels (m.u.v. dat van gebonden textielvlies en m.u.v. van dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63029910: 'huishoudlinnen van vlas (m.u.v. dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63029990: 'huishoudlinnen van textiel (m.u.v. dat van katoen, van vlas of van synthetische of kunstmatige vezels en m.u.v. dweilen, vaatdoeken, stofdoeken, poetsdoeken e.d.)',
    63031200: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van brei- of haakwerk, van synthetische vezels (m.u.v. zonneschermen voor winkelpuien)',
    63031900: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van brei- of haakwerk (m.u.v. die van synthetische vezels, zonneschermen voor winkelpuien)',
    63039100: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van katoen (m.u.v. die van brei- of haakwerk en m.u.v. zonneschermen voor winkelpuien)',
    63039210: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van gebonden textielvlies van synthetische vezels (m.u.v. zonneschermen voor winkelpuien)',
    63039290: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van synthetische vezels (m.u.v. die van gebonden textielvlies, m.u.v. die van brei- of haakwerk en m.u.v. zonneschermen voor winkelpuien)',
    63039910: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van gebonden textielvlies (m.u.v. die van katoen of van synthetische vezels en m.u.v. zonneschermen voor winkelpuien)',
    63039990: 'vitrages, gordijnen en rolgordijnen, incl. bed- en gordijnvalletjes, van textiel (m.u.v. die van katoen of van synthetische vezels of van gebonden textielvlies, m.u.v. die van brei- of haakwerk en m.u.v. zonneschermen voor winkelpuien)',
    63041100: 'bedspreien van brei- of haakwerk (m.u.v. beddenlinnen, dekbedden en gewatteerde dekens)',
    63041910: 'bedspreien van katoen (m.u.v. die van brei- of haakwerk en m.u.v. beddenlinnen, dekbedden en gewatteerde dekens)',
    63041930: 'bedspreien van vlas of van ramee (m.u.v. die van brei- of haakwerk en m.u.v. beddenlinnen, dekbedden en gewatteerde dekens)',
    63041990: 'bedspreien van textiel (m.u.v. die van katoen, van vlas of van ramee, m.u.v. die van brei- of haakwerk en m.u.v. beddenlinnen, dekbedden en gewatteerde dekens)',
    63042000: 'Muskietennetten, van kettingbreiwerk, bedoeld bij aanvullende aantekening 1 op dit hoofdstuk',
    63049100: 'artikelen voor stoffering, van brei- of haakwerk (m.u.v. dekens, tafel-, bedde- en huishoudlinnen, vitrages, gordijnen en rolgordijnen, bed- en gordijnvalletjes, spreien, muskietennetten bedoeld bij aanvullende aantekening 1 op dit hoofdstuk, lampekappen en artikelen bedoeld bij post 9404)',
    63049200: 'artikelen voor stoffering, van katoen (m.u.v. die van brei- of haakwerk en m.u.v. dekens, tafel-, bedde- en huishoudlinnen, vitrages, gordijnen en rolgordijnen, bed- en gordijnvalletjes, spreien, lampekappen en artikelen bedoeld bij post 9404)',
    63049300: 'artikelen voor stoffering, van synthetische vezels (m.u.v. die van brei- of haakwerk en m.u.v. dekens, tafel-, bedde- en huishoudlinnen, vitrages, gordijnen en rolgordijnen, bed- en gordijnvalletjes, spreien, lampekappen en artikelen bedoeld bij post 9404)',
    63049900: 'artikelen voor stoffering (m.u.v. die van katoen of van synthetische vezels, m.u.v. die van brei- of haakwerk en m.u.v. dekens, tafel-, bedde- en huishoudlinnen, vitrages, gordijnen en rolgordijnen, bed- en gordijnvalletjes, spreien, lampekappen en artikelen bedoeld bij post 9404)',
    63051010: 'zakken voor verpakkingsdoeleinden, van jute of van andere bastvezels, bedoeld bij post 5303, gebruikt',
    63051090: 'zakken voor verpakkingsdoeleinden, van jute of van andere bastvezels, bedoeld bij post 5303, nieuw',
    63052000: 'zakken voor verpakkingsdoeleinden, van katoen',
    63053211: 'soepele bergingsmiddelen voor bulkgoederen, van strippen of van artikelen van dergelijke vorm, van brei- of haakwerk, van polyethyleen of van polypropyleen',
    63053219: 'Soepele bergingsmiddelen voor bulkgoederen, van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen (m.u.v. die van brei- of haakwerk)',
    63053290: 'soepele bergingsmiddelen voor bulkgoederen, van synthetische of kunstmatige textielstoffen (m.u.v. die van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen)',
    63053310: 'zakken voor verpakkingsdoeleinden, van strippen of van artikelen van dergelijke vorm, van brei- of haakwerk, van polyethyleen of van polypropyleen (m.u.v. soepele bergingsmiddelen voor bulkgoederen)',
    63053390: 'Zakken voor verpakkingsdoeleinden, van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen  (m.u.v. die van brei- of haakwerk en m.u.v. soepele bergingsmiddelen voor bulkgoederen)',
    63053900: 'zakken voor verpakkingsdoeleinden, van synthetische of kunstmatige textielstoffen (m.u.v. die van strippen of van artikelen van dergelijke vorm, van polyethyleen of van polypropyleen en m.u.v. soepele bergingsmiddelen voor bulkgoederen)',
    63059000: 'zakken voor verpakkingsdoeleinden, van textiel (m.u.v. die van synthetische of kunstmatige textielstoffen, van katoen, van jute of van andere bastvezels bedoeld bij post 5303)',
    63061200: 'dekkleden en zonneschermen voor winkelpuien e.d., van synthetische vezels (m.u.v. vlakke beschermingskleden van lichte weefsels, die de vorm hebben van dekkleden)',
    63061900: 'dekkleden en zonneschermen voor winkelpuien e.d., van textiel (m.u.v. die van synthetische vezels en m.u.v. vlakke beschermingskleden van lichte weefsels, die de vorm hebben van dekkleden)',
    63062200: 'Tenten van synthetische vezels (m.u.v. parasoltenten)',
    63062900: 'Tenten van textiel (m.u.v. die van synthetische vezels en m.u.v. parasoltenten)',
    63063000: 'zeilen voor schepen, zeilplanken, zeilwagens en zeilsleden van textiel',
    63064000: 'luchtbedden van textiel',
    63069000: 'kampeerartikelen van textiel (m.u.v. tenten, zonneschermen, zeilen en luchtbedden, rugzakken, ransels e.d. bergingsmiddelen, slaapzakken, matrassen en kussens, gevuld)',
    63071010: 'dweilen, vaatdoeken, stofdoeken, poetsdoeken, e.d., van brei- of haakwerk',
    63071030: 'dweilen, vaatdoeken, stofdoeken, poetsdoeken, e.d., van gebonden textielvlies',
    63071090: 'dweilen, vaatdoeken, stofdoeken, poetsdoeken, e.d., van textiel (m.u.v. die van brei- of haakwerk of die van gebonden textielvlies)',
    63072000: 'zwemgordels en zwemvesten, van textiel',
    63079010: 'geconfectioneerde artikelen van textiel, incl. patronen voor kleding, van brei- of haakwerk, n.e.g.',
    63079091: 'geconfectioneerde artikelen van textiel, incl. patronen voor kleding, van vilt, n.e.g.',
    63079092: 'Lakens voor eenmalig gebruik, vervaardigd van producten bedoeld bij post 5603, van de soort die tijdens operaties wordt gebruikt',
    63079098: 'geconfectioneerde artikelen van textiel, incl. patronen voor kleding, n.e.g. (m.u.v. die van brei- of haakwerk of die van vilt, m.u.v. lakens voor eenmalig gebruik, vervaardigd van producten bedoeld bij post 5603, van de soort die tijdens operaties wordt gebruikt )',
    63080000: 'stellen of assortimenten, bestaande uit weefsel en garen, ook indien met toebehoren, voor de vervaardiging van tapijten, van tapisserieën, van geborduurde tafelkleden en servetten, of van dergelijke artikelen van textiel, opgemaakt voor de verkoop in het klein (m.u.v. van stellen of assortimenten voor de vervaardiging van kleding)',
    63090000: 'kleding en kledingtoebehoren, dekens, linnengoed en artikelen voor stofferen, van textiel, schoeisel en hoofddeksels, ongeacht van welk materiaal, voorzover deze goederen duidelijke sporen van gebruik dragen, in bulk dan wel verpakt in balen, in zakken of in dergelijke verpakkingsmiddelen (m.u.v. tapijten en andere vloerbedekking en tapisserieën)',
    63101000: 'Lompen en vodden; afval en oud goed, van bindgaren, van touw of van kabel, gesorteerd',
    63109000: 'lompen en vodden; afval en oud goed, van bindgaren, van touw of van kabel, ongesorteerd',
    64011000: 'Waterdicht schoeisel, met beschermende metalen neus, met buitenzool  en met bovendeel van rubber of van  kunststof, waarvan het bovendeel op andere wijze dan door stikken of klinken of d.m.v. nagels, schroeven, pluggen of dergelijke is samengevoegd, en evenmin op dergelijke wijze aan de buitenzool is bevestigd (m.u.v. schaatsschoenen met aangezette schaatsen of rolschaatsen, scheenbeschermers e.d. beschermende artikelen voor de sportbeoefening)',
    64019210: 'Waterdicht schoeisel met buitenzool van rubber of van kunststof en met bovendeel van rubber, waarvan het bovendeel op andere wijze dan door stikken of klinken of d.m.v. nagels, schroeven, pluggen of dergelijke is samengevoegd, en evenmin op dergelijke wijze aan de buitenzool is bevestigd, uitsluitend de enkel bedekkend (m.u.v. schoeisel met beschermende metalen neus, orthopedisch schoeisel, schaatsschoenen met aangezette schaatsen of rolschaatsen en schoeisel dat het karakter heeft van speelgoed)',
    64019290: 'waterdicht schoeisel met buitenzool van rubber of van kunststof en met bovendeel van kunststof, waarvan het bovendeel op andere wijze dan door stikken of klinken of d.m.v. nagels, schroeven, pluggen of dergelijke is samengevoegd, en evenmin op dergelijke wijze aan de buitenzool is bevestigd, uitsluitend de enkel bedekkend (m.u.v. schoeisel met beschermende metalen neus, orthopedisch schoeisel, schaatsschoenen met aangezette schaatsen of rolschaatsen en schoeisel dat het karakter heeft van speelgoed)',
    64019900: 'waterdicht schoeisel met buitenzool en bovendeel van rubber of van kunststof, waarvan het bovendeel op andere wijze dan door stikken of klinken of d.m.v. nagels, schroeven, pluggen of dergelijke is samengevoegd, en evenmin op dergelijke wijze aan de buitenzool is bevestigd, de knie, noch de enkel bedekkend (m.u.v. schoeisel dat de enkel bedekt, doch niet de knie, schoeisel met beschermende metalen neus, orthopedisch schoeisel, schaatsschoenen met aangezette schaatsen of rolschaatsen en schoeisel dat het karakter heeft van speelgoed)',
    64021210: 'skischoenen met buitenzool en bovendeel van rubber of van kunststof (m.u.v. waterdicht schoeisel als bedoeld bij post 6401)',
    64021290: 'zgn. snowboardschoenen met buitenzool en bovendeel van rubber of van kunststof (m.u.v. waterdicht schoeisel bedoeld bij post 6401)',
    64021900: 'sportschoeisel met buitenzool en bovendeel van rubber of van kunststof (m.u.v. waterdicht schoeisel bedoeld bij post 6401, skischoenen, zgn. snowboardschoenen en schaatsschoenen met aangezette ijs- of rolschaatsen)',
    64022000: 'schoeisel met buitenzool en bovendeel van rubber of van kunststof, waarvan het bovendeel uit riempjes bestaat die met pluggen aan de zool zijn bevestigd (m.u.v. schoeisel dat het karakter heeft van speelgoed)',
    64029110: 'Schoeisel met buitenzool en bovendeel van rubber of van kunststof, de enkel bedekkend, met beschermende metalen neus (m.u.v. waterdicht schoeisel als bedoeld bij post 6401, sportschoeisel en orthopedisch schoeisel)',
    64029190: 'schoeisel met buitenzool van rubber of van kunststof, de enkel bedekkend (m.u.v. schoeisel met beschermende metalen neus, waterdicht schoeisel als bedoeld bij post 6401, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64029905: 'Schoeisel met buitenzool en bovendeel van rubber of van kunststof, niet de enkel bedekkend, met beschermende metalen neus (m.u.v. waterdicht schoeisel als bedoeld bij post 6401, sportschoeisel en orthopedisch schoeisel)',
    64029910: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van rubber (m.u.v. schoeisel dat uitsluitend de enkel bedekt of waarvan het bovendeel uit riempjes bestaat die met pluggen aan de zool zijn bevestigd, waterdicht schoeisel als bedoeld bij post 6401, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64029931: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen > 3 cm is (m.u.v. schoeisel waarvan het bovendeel uit riempjes bestaat die met pluggen aan de zool zijn bevestigd)',
    64029939: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is (m.u.v. schoeisel waarvan het bovendeel uit riempjes bestaat die met pluggen aan de zool zijn bevestigd)',
    64029950: 'pantoffels en ander huisschoeisel, met buitenzool van rubber of van kunststof en bovendeel van kunststof (m.u.v. schoeisel dat de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat en schoeisel dat het karakter heeft van speelgoed)',
    64029991: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel dat uitsluitend de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, schoeisel met beschermende metalen neus, huisschoeisel, sportschoeisel, waterdicht schoeisel als bedoeld bij post 6401, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64029993: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, met een binnenzoollengte van >= 24 cm, dat niet als heren- of damesschoeisel kan worden onderkend (m.u.v. schoeisel dat uitsluitend de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, schoeisel met beschermende metalen neus, huisschoeisel, sportschoeisel, waterdicht schoeisel als bedoeld bij post 6401, orthopedisch schoeisel)',
    64029996: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel dat uitsluitend de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, schoeisel met beschermende metalen neus, huisschoeisel, sportschoeisel, waterdicht schoeisel als bedoeld bij post 6401, orthopedisch schoeisel)',
    64029998: 'schoeisel met buitenzool van rubber of van kunststof en bovendeel van kunststof, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel dat uitsluitend de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, schoeisel met beschermende metalen neus, huisschoeisel, sportschoeisel, waterdicht schoeisel als bedoeld bij post 6401, orthopedisch schoeisel)',
    64031200: 'skischoenen en zgn. snowboardschoenen, met buitenzool van rubber, van kunststof, van leder of van kunstleder en met bovendeel van leder',
    64031900: 'sportschoeisel met buitenzool van rubber, van kunststof, van leder of van kunstleder en met bovendeel van leder (m.u.v. skischoenen, zgn. snowboardschoenen en schaatsschoenen met aangezette ijs- of rolschaatsen)',
    64032000: 'schoeisel met buitenzool van leder en met bovendeel dat bestaat uit lederen riemen over de wreef en om de grote teen',
    64034000: 'schoeisel met buitenzool van rubber, van kunststof, van leder of van kunstleder en met bovendeel van leder, met beschermende metalen neus (m.u.v. sportschoeisel en orthopedisch schoeisel)',
    64035105: 'Schoeisel met bovendeel van leder en met houten basis, de enkel bedekkend, zonder binnenzool of beschermende metalen neus',
    64035111: 'schoeisel met buitenzool en bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64035115: 'schoeisel met buitenzool en bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van >= 24 cm, voor mannen (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel en orthopedisch schoeisel)',
    64035119: 'schoeisel met buitenzool en bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel en orthopedisch schoeisel)',
    64035191: 'schoeisel met buitenzool en bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64035195: 'schoeisel met buitenzool en bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van >= 24 cm, voor mannen (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel en orthopedisch schoeisel)',
    64035199: 'schoeisel met buitenzool en bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van >= 24 cm, voor vrouwen (m.u.v. schoeisel met beschermende metalen neus, sportschoeisel en orthopedisch schoeisel)',
    64035905: 'Schoeisel met bovendeel van leder en met houten basis, zonder binnenzool of beschermende metalen neus (m.u.v. schoeisel dat de enkel bedekt)',
    64035911: 'schoeisel met buitenzool en bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen > 3 cm is (m.u.v. schoeisel met bovendeel dat bestaat uit lederen riemen over de wreef en om de grote teen)',
    64035931: 'schoeisel met buitenzool en bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is en met een binnenzoollengte van < 24 cm (m.u.v. schoeisel met bovendeel dat bestaat uit lederen riemen over de wreef en om de grote teen en schoeisel dat het karakter heeft van speelgoed)',
    64035935: 'schoeisel met buitenzool en bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is en met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel met bovendeel dat bestaat uit lederen riemen over de wreef en om de grote teen)',
    64035939: 'schoeisel met buitenzool en bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is en met een binnenzoollengte van >= 24 cm, voor vrouwen (m.u.v. schoeisel met bovendeel dat bestaat uit lederen riemen over de wreef en om de grote teen)',
    64035950: 'pantoffels en ander huisschoeisel, met buitenzool en bovendeel van leder (m.u.v. schoeisel dat de enkel bedekt of waarvan het voorblad is uitgesneden of uit riempjes bestaat en schoeisel dat het karakter heeft van speelgoed)',
    64035991: 'schoeisel met buitenzool en bovendeel van leder, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis, zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel en orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64035995: 'schoeisel met buitenzool en bovendeel van leder, met een binnenzoollengte van >= 24 cm, voor mannen (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis, zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel en orthopedisch schoeisel)',
    64035999: 'schoeisel met buitenzool en bovendeel van leder, met een binnenzoollengte van >= 24 cm, voor vrouwen (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis, zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel en orthopedisch schoeisel)',
    64039105: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder, met bovendeel van leder en met houten basis, de enkel bedekkend, zonder binnenzool of beschermende metalen neus',
    64039111: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64039113: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van >= 24 cm, dat niet als heren- of damesschoeisel kan worden onderkend (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel)',
    64039116: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat niet als heren- of damesschoeisel kan worden onderkend)',
    64039118: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel bedekkend, maar beneden de kuit, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat niet als heren- of damesschoeisel kan worden onderkend)',
    64039191: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64039193: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van >= 24 cm, dat niet als heren- of damesschoeisel kan worden onderkend (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel)',
    64039196: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat niet als heren - of damesschoeisel kan worden onderkend)',
    64039198: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met een bovendeel van leder, de enkel en de kuit bedekkend, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel met een beschermende metalen neus, sportschoeisel, orthopedisch schoeisel en schoeisel dat niet als heren - of damesschoeisel kan worden onderkend)',
    64039905: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder, met bovendeel van leder en met houten basis, zonder binnenzool of beschermende metalen neus (m.u.v. schoeisel dat de enkel bedekt)',
    64039911: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, en waarvan de grootste hoogte van de hak, incl. de zolen, > 3 cm is',
    64039931: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, en waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel dat het karakter heeft van speelgoed)',
    64039933: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, en waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is, met een binnenzoollengte van >= 24 cm, dat niet als heren- of damesschoeisel kan worden onderkend (m.u.v. schoeisel dat het karakter heeft van speelgoed)',
    64039936: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, en waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is, met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel dat niet als heren- of damesschoeisel kan worden onderkend)',
    64039938: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, waarvan het voorblad is uitgesneden of uit riempjes bestaat, en waarvan de grootste hoogte van de hak, incl. de zolen <= 3 cm is, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel dat niet als heren- of damesschoeisel kan worden onderkend)',
    64039950: 'pantoffels en ander huisschoeisel, met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder (m.u.v. schoeisel dat de enkel bedekt, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat en schoeisel dat het karakter heeft van speelgoed)',
    64039991: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, met een binnenzoollengte van < 24 cm (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64039993: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, met een binnenzoollengte van >= 24 cm, dat niet als heren- of damesschoeisel kan worden onderkend (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64039996: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, met een binnenzoollengte van >= 24 cm, voor heren (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64039998: 'schoeisel met buitenzool van rubber, van kunststof of van kunstleder en met bovendeel van leder, met een binnenzoollengte van >= 24 cm, voor dames (m.u.v. schoeisel dat de enkel bedekt, schoeisel met beschermende metalen neus, schoeisel met houten basis zonder binnenzool, schoeisel waarvan het voorblad is uitgesneden of uit riempjes bestaat, huisschoeisel, sportschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64041100: 'sportschoeisel, incl. tennisschoenen, basketbalschoenen, gymnastiekschoenen, trainingsschoenen e.d. schoeisel, met buitenzool van rubber of van kunststof en met bovendeel van textiel',
    64041910: 'pantoffels en ander huisschoeisel, met buitenzool van rubber of van kunststof en met bovendeel van textiel (m.u.v. tennisschoenen, basketbalschoenen, gymnastiekschoenen, trainingsschoenen e.d. schoeisel, en schoeisel dat het karakter heeft van speelgoed)',
    64041990: 'schoeisel met buitenzool van rubber of van kunststof en met bovendeel van textiel (m.u.v. huisschoeisel, sportschoeisel, incl. tennisschoenen, basketbalschoenen, gymnastiekschoenen, trainingsschoenen e.d. schoeisel, en schoeisel dat het karakter heeft van speelgoed)',
    64042010: 'pantoffels en ander huisschoeisel, met buitenzool van leder of van kunstleder en met bovendeel van textiel (m.u.v. schoeisel dat het karakter heeft van speelgoed)',
    64042090: 'schoeisel met buitenzool van leder of van kunstleder en met bovendeel van textiel (m.u.v. huisschoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64051000: 'schoeisel met bovendeel van leder of van kunstleder (m.u.v. die met buitenzool van rubber, van kunststof, van leder of van kunstleder en met bovendeel van leder, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64052010: 'schoeisel met buitenzool van hout of van kurk en met bovendeel van textiel (m.u.v. orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64052091: 'pantoffels en ander huisschoeisel, met bovendeel van textiel (m.u.v. schoeisel met buitenzool van rubber, van kunststof, van leder of van kunstleder en schoeisel dat het karakter heeft van speelgoed)',
    64052099: 'schoeisel met bovendeel van textiel (m.u.v. schoeisel met buitenzool van rubber, van kunststof, van leder of van kunstleder, van hout of van kurk, huisschoeisel, orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64059010: 'schoeisel met buitenzool van rubber, kunststof, van leder of van kunstleder (m.u.v. schoeisel met bovendeel van leder, van kunstleder of van textiel en m.u.v. orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64059090: 'schoeisel met buitenzool van hout, van kurk, van touw, van karton, van bont, van weefsel, van vilt, van gebonden textielvlies, van linoleum, van raffia, van stro, van luffa, enz. (m.u.v. schoeisel met bovendeel van leder, van kunstleder of van textiel en m.u.v. orthopedisch schoeisel en schoeisel dat het karakter heeft van speelgoed)',
    64061010: 'Bovendelen van schoeisel en delen daarvan, van leder (m.u.v. contreforts en stijve toppen)',
    64061090: 'bovendelen van schoeisel en delen daarvan, incl. bovendelen voorzien van zolen (m.u.v. bovendelen voorzien van buitenzolen, m.u.v. contreforts en stijve toppen en m.u.v. delen van leder of van asbest)',
    64062010: 'buitenzolen en hakken van schoeisel, van rubber',
    64062090: 'buitenzolen en hakken van schoeisel, van kunststof',
    64069030: 'samenvoegingen van bovendelen van schoeisel met een binnenzool of met andere binnendelen (m.u.v. die voorzien van een buitenzool n m.u.v. samenvoegingen met asbest)',
    64069050: 'inlegzolen en andere losse inlegstukken voor schoeisel',
    64069060: 'buitenzolen van schoeisel, van leder of van kunstleder',
    64069090: 'Delen van schoeisel en slobkousen, beenkappen e.d. artikelen en delen daarvan (m.u.v. buitenzolen van leder, kunstleder, rubber of kunststof, hakken van rubber of kunststof, bovendelen van schoeisel, ook indien voorzien van een binnenzool of van andere binnendelen, en delen daarvan en m.u.v. die van asbest)',
    65010000: 'hoedvormen "cloches", van vilt, waarvan bol noch rand zijn aangevormd, alsmede schijfvormige "plateaux" en cilindervormige "manchons", ook indien overlangs opengesneden, van vilt, voor hoeden',
    65020000: 'hoedvormen "cloches", waarvan bol noch rand zijn aangevormd, gevlochten uit een stuk of vervaardigd door het aaneenzetten van stroken, ongeacht de stof waarvan die stroken zijn vervaardigd, ongegarneerd',
    65040000: 'hoeden en andere hoofddeksels, gevlochten uit een stuk of vervaardigd door het aaneenzetten van stroken, ongeacht de stof waarvan die stroken zijn vervaardigd, ook indien gegarneerd',
    65050010: 'Hoeden en andere hoofddeksels, van brei- of haakwerk of vervaardigd van kant, van haarvilt of van vilt uit wol en bont, vervaardigd van hoedvormen "cloches" of van schijfvormen "plateaus" bedoeld bij post 6501, ook indien gegarneerd (m.u.v. die welke zijn vervaardigd door het aaneenzetten van stroken of stukken vilt en m.u.v. hoofddeksels die het karakter hebben van speelgoed of karnavalsartikelen)',
    65050030: 'petten, kepies e.d. hoofddeksels, voorzien van een klep, van brei- of haakwerk of vervaardigd van kant, van vilt of van andere textielproducten "aan een stuk" (niet in stroken), ook indien gegarneerd (m.u.v. die welke het karakter hebben van speelgoed of karnavalsartikelen)',
    65050090: 'Hoeden en andere hoofddeksels, van brei- of haakwerk, of vervaardigd van kant, van vilt of van andere textielproducten "aan een stuk" (niet in stroken), ook indien gegarneerd (m.u.v. die van haarvilt of van vilt uit wol en bont, petten, kepies e.d. hoofddeksels, voorzien van een klep, en hoofddeksels die het karakter hebben van speelgoed of karnavalsartikelen)',
    65061010: 'veiligheidshoofddeksels van kunststof, ook indien gegarneerd',
    65061080: 'Veiligheidshoofddeksels, ook indien gegarneerd (m.u.v. die van kunststof)',
    65069100: 'Badmutsen, regenkappen en andere hoofddeksels, van rubber of van kunststof, ook indien gegarneerd (m.u.v. veiligheidshoofddeksels; hoofddeksels die het karakter hebben van speelgoed of van carnavalsartikelen)',
    65069910: 'Hoeden en andere hoofddeksels, van haarvilt of van vilt uit wol en bont, vervaardigd van hoedvormen "cloches" of van schijfvormen "plateaus", bedoeld bij post 6501, ook indien gegarneerd (m.u.v. die van brei- of haakwerk of vervaardigd van kant, die welke zijn vervaardigd door het aaneenzetten van stroken of stukken vilt en m.u.v. hoofddeksels die het karakter hebben van speelgoed of karnavalsartikelen)',
    65069990: 'Hoofddeksels, ook indien gegarneerd, n.e.g.',
    65070000: 'binnenranden "zweetbanden", voeringen, overtrekken, karkassen, kleppen en stormbanden, voor hoofddeksels (m.u.v. hoofdbanden van de soort die door sportslieden als zweetband worden gebruikt, van brei- of haakwerk)',
    66011000: 'tuinparasols e.d. artikelen (m.u.v. parasoltenten)',
    66019100: 'paraplu\'s met telescopische stok of naald (m.u.v. kinderspeelgoed)',
    66019920: 'Paraplu\'s en parasols, incl. wandelstokparaplu\'s, met een overtrek van weefsel van textielstoffen (m.u.v. paraplu\'s met telescopische stok of naald, tuinparasols e.d. artikelen en speelgoed)',
    66019990: 'paraplu\'s en parasols, incl. wandelstokparaplu\'s (m.u.v. die met een overtrek van weefsel van textielstoffen en m.u.v. paraplu\'s met telescopische stok of naald, tuinparasols e.d. artikelen en speelgoed)',
    66020000: 'wandelstokken, zitstokken, zwepen, rijzwepen e.d. artikelen (m.u.v. stokken met maatindeling, krukken, stokken die het karakter hebben van een wapen en stokken voor sportdoeleinden)',
    66032000: 'gemonteerde geraamten voor paraplu\'s of voor parasols, ook indien voorzien van stok of naald, al dan niet met knop of handvat, als zodanig herkenbaar als zijnde bestemd voor paraplu\'s en parasols als bedoeld bij post 6601',
    66039010: 'handvatten en knoppen, als zodanig herkenbaar als zijnde bestemd voor paraplu\'s en parasols als bedoeld bij post 6601 of voor wandelstokken, zitstokken, zwepen, rijzwepen e.d. artikelen als bedoeld bij post 6602',
    66039090: 'delen, garnituren en toebehoren, als zodanig herkenbaar als zijnde bestemd voor paraplu\'s en parasols als bedoeld bij post 6601 of voor wandelstokken, zitstokken, zwepen, rijzwepen e.d. artikelen als bedoeld bij post 6602 (m.u.v. handvatten en knoppen en gemonteerde geraamten voor paraplu\'s of voor parasols, ook indien voorzien van stok of naald, al dan niet met knop of handvat)',
    67010000: 'vogelhuiden en andere delen van vogels, met hun veren of dons bezet; veren, delen van veren, dons en artikelen uit deze stoffen (m.u.v. producten bedoeld bij post 0505, bewerkte schachten en bewerkte spoelen, schoeisel en hoofddeksels, artikelen voor bedden e.d. als bedoeld bij post 9404, speelgoed, spellen en sportartikelen en delen van verzamelingen)',
    67021000: 'kunstbloemen, kunstloofwerk en kunstvruchten, alsmede delen daarvan; artikelen van kunstbloemen, van kunstloofwerk of van kunstvruchten, door aaneenbinden, aaneenlijmen, in elkaar steken of op dergelijke wijze vervaardigd, van kunststof',
    67029000: 'kunstbloemen, kunstloofwerk en kunstvruchten, alsmede delen daarvan; artikelen van kunstbloemen, van kunstloofwerk of van kunstvruchten, door aaneenbinden, aaneenlijmen, in elkaar steken of op dergelijke wijze vervaardigd (m.u.v. die van kunststof)',
    67030000: 'mensenhaar, gericht, verdund, gebleekt of op andere wijze bewerkt; wol en ander haar van dieren, alsmede andere textielstoffen, geprepareerd voor het maken van postiches of van dergelijke artikelen (m.u.v. natuurlijke vlechten van onbewerkt mensenhaar, ook indien gewassen en ontvet, die verder geen bewerking hebben ondergaan)',
    67041100: 'complete pruiken van synthetische textielstoffen',
    67041900: 'baarden, wenkbrauwen, wimpers, lokken e.d. artikelen, van synthetische textielstoffen (m.u.v. complete pruiken)',
    67042000: 'pruiken, baarden, wenkbrauwen, wimpers, lokken e.d. artikelen, van mensenhaar; artikelen van mensenhaar n.e.g.',
    67049000: 'pruiken, baarden, wenkbrauwen, wimpers, lokken e.d. artikelen, van haar van dieren of van textielstoffen (m.u.v. die van synthetische textielstoffen)',
    68010000: 'stenen voor bestrating, alsmede plaveien en trottoirbanden, van natuursteen (m.u.v. leisteen)',
    68021000: 'tegels, blokjes e.d. artikelen, van bewerkte natuursteen, incl. leisteen, voor mozaïeken e.d., ook in andere dan rechthoekige of vierkante vorm, voorzover het grootste vlak kan worden omsloten door een vierkant met een zijde van < 7 cm, korrels, splinters "scherven" en poeder, van natuursteen, incl. leisteen, kunstmatig gekleurd',
    68022100: 'werken van marmer, van travertijn of van albast, incl. bewerkte steen, enkel behakt of bezaagd, met platte of met effen vlakken (m.u.v. die met een vlak dat geheel of gedeeltelijk is geschaafd, met zand geschuurd, geslepen of gezoet, gepolijst; tegels, blokjes e.d. bedoeld bij post 6802.10, stenen voor bestrating, plaveien en trottoirbanden)',
    68022300: 'werken van graniet, incl. bewerkte graniet, enkel behakt of bezaagd, met platte of met effen vlakken (m.u.v. die met een vlak dat geheel of gedeeltelijk is geschaafd, met zand geschuurd, geslepen of gezoet, gepolijst; tegels, blokjes e.d. bedoeld bij post 6802 10 00, stenen voor bestrating, plaveien en trottoirbanden)',
    68022900: 'Werken van steen, incl. bewerkte steen, enkel behakt of bezaagd, met platte of met effen vlakken (m.u.v. werken van marmer, travertijn, albast, graniet en leisteen, die met een vlak dat geheel of gedeeltelijk is geschaafd, met zand geschuurd, geslepen of gezoet, gepolijst; tegels, blokjes e.d. bedoeld bij post 6802 10 00, stenen voor bestrating, plaveien en trottoirbanden)',
    68029100: 'Werken van marmer, travertijn of albast, in ongeacht welke vorm (m.u.v. tegels, blokjes e.d. van 6802 10; fancybijouterieën; uurwerken, verlichtingstoestellen en delen daarvan; knopen; origineel beeldhouwwerk; stenen voor bestrating, plaveien en trottoirbanden)',
    68029200: 'Werken van kalksteen in ongeacht welke vorm (m.u.v. werken van marmer, van travertijn of van albast; tegels, blokjes e.d. bedoeld bij 6802 10: fancybijouterieën; uurwerken, verlichtingstoestellen en delen daarvan; origineel beeldhouwwerk; stenen voor bestrating, plaveien en trottoirbanden)',
    68029310: 'werken van graniet, gepolijst, versierd of op andere wijze bewerkt, doch zonder beeldhouwwerk, met een nettogewicht van >= 10 kg (m.u.v. uurwerken, verlichtingstoestellen en delen daarvan; stenen voor bestrating, plaveien en trottoirbanden)',
    68029390: 'graniet in ongeacht welke vorm, gepolijst, versierd of op andere wijze bewerkt, met een nettogewicht van < 10 kg; beeldhouwwerk van graniet (m.u.v. tegels, blokjes e.d. artikelen bedoeld bij post 6802.10; fancy bijouterieën; uurwerken, verlichtingstoestellen en delen daarvan; origineel beeldhouwwerk, stenen voor bestrating, plaveien en trottoirbanden)',
    68029910: 'werken van steen, natuurlijk, in ongeacht welke vorm, gepolijst, versierd of op andere wijze bewerkt, doch zonder beeldhouwwerk, met een nettogewicht van >= 10 kg (m.u.v. werken van gesmolten bazalt; werken van speksteen, gebrand; uurwerken, verlichtingstoestellen en delen daarvan; stenen voor bestrating, plaveien en trottoirbanden)',
    68029990: 'werken van steen, natuurlijk, in ongeacht welke vorm, gepolijst, versierd of op andere wijze bewerkt, met een nettogewicht van < 10 kg; beeldhouwwerk daarvan (m.u.v. werken van kunststof, van graniet of van leisteen, tegels, blokjes e.d. artikelen bedoeld bij post 6802.10; werken van gesmolten bazalt; werken van speksteen, gebrand; fancy bijouterieën; uurwerken, verlichtingstoestellen en delen daarvan; knopen; origineel beeldhouwwerk, stenen voor bestrating, enz.)',
    68030010: 'leien voor daken en voor muren',
    68030090: 'Werken van leisteen of van samengekit leigruis, incl. bewerkte leisteen (m.u.v. leisteenpoeder en leisteengruis; blokjes voor mozaïeken; griffels, gebruiksklare schrijf- of tekenleien of schrijf- en tekenborden en m.u.v. leien voor daken en voor muren)',
    68041000: 'stenen om te malen, te vervezelen of te breken, zonder onderstel',
    68042100: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde natuurlijke of synthetische diamant (m.u.v. handwetstenen of handpolijststenen, slijpschijfjes speciaal voor boormachines van tandartsen)',
    68042212: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde kunstmatige schuur-, slijp- of polijstmiddelen, met bindmiddel van kunsthars, onversterkt (m.u.v. die van geagglomereerde synthetische diamant; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68042218: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde kunstmatige schuur-, slijp- of polijstmiddelen, met bindmiddel van kunsthars, versterkt (m.u.v. die van geagglomereerde synthetische diamant; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68042230: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde kunstmatige schuur-, slijp- of polijstmiddelen, met bindmiddel van keramische stoffen of van silicaten (m.u.v. die van geagglomereerde synthetische diamant; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68042250: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde kunstmatige schuur-, slijp- of polijstmiddelen (m.u.v. die van geagglomereerde synthetische diamant; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68042290: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van geagglomereerde natuurlijke schuur-, slijp- of polijstmiddelen of van keramiek (m.u.v. die van geagglomereerde natuurlijke diamant; geparfumeerde puimstenen; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68042300: 'molenstenen, slijpstenen e.d. artikelen, zonder onderstel, om te wetten, te polijsten, te slijpen, te zagen of te snijden, van natuursteen (m.u.v. die van geagglomereerde natuurlijke schuur-, slijp- of polijstmiddelen of van keramiek; geparfumeerde puimstenen; handwetstenen en handpolijststenen; slijpschijfjes enz. speciaal voor boormachines van tandartsen)',
    68043000: 'handwetstenen en handpolijststenen',
    68051000: 'schuur-, slijp- of polijstmiddelen, natuurlijk of kunstmatig, in poeder of in korrels, op een drager van uitsluitend textielweefsel, ook indien in een bepaalde vorm gesneden, genaaid of op andere wijze aaneengezet',
    68052000: 'schuur-, slijp- of polijstmiddelen, natuurlijk of kunstmatig, in poeder of in korrels, op een drager van uitsluitend papier of karton, ook indien in een bepaalde vorm gesneden, genaaid of op andere wijze aaneengezet',
    68053000: 'Schuur-, slijp- of polijstmiddelen, natuurlijk of kunstmatig, in poeder of in korrels, op een drager, ook indien in een bepaalde vorm gesneden, genaaid of op andere wijze aaneengezet (m.u.v. die op een drager van uitsluitend textielweefsel of die op een drager van uitsluitend papier of karton)',
    68061000: 'slakkenwol, steenwol e.d. minerale wol, ook indien onderling vermengd, in bulk, in bladen of op rollen',
    68062010: 'klei, geëxpandeerd',
    68062090: 'vermiculiet, geëxpandeerd; slakkenschuim e.d. geëxpandeerde minerale producten, ook indien onderling vermengd (m.u.v. geëxpandeerde klei)',
    68069000: 'mengsels en werken van minerale stoffen voor warmteisolering, geluiddemping of geluidabsorptie (m.u.v. slakkenwol, steenwol e.d. minerale wol; geëxpandeerde vermiculiet, geëxpandeerde klei, slakkenschuim e.d. geëxpandeerde minerale producten; werken van licht beton, van asbestcement, van cellulosecement e.d.; mengsels en andere werken van of op basis van asbest; keramische producten)',
    68071000: 'Werken van asfalt of van dergelijke producten "b.v. petroleumbitumen, koolteerpek", op rollen',
    68079000: 'werken van asfalt of van dergelijke producten "b.v. petroleumbitumen, koolteerpek" (m.u.v. die op rollen)',
    68080000: 'panelen, platen, tegels, blokken e.d. artikelen, van plantaardige vezels, van stro of van krullen, spanen, zaagsel of ander afval van hout, gebonden met cement, met gips of met andere minerale bindmiddelen (m.u.v. werken van asbestcement, van cellulosecement e.d.)',
    68091100: 'platen, panelen, tegels e.d. artikelen, van gips of van gipspreparaten, onversierd, uitsluitend met papier of karton bekleed of versterkt (m.u.v. werken voor warmteisolering, geluiddemping of geluidabsorptie, gebonden met gips)',
    68091900: 'platen, panelen, tegels e.d. artikelen, van gips of van gipspreparaten, onversierd (m.u.v. die uitsluitend met papier of karton bekleed of versterkt; werken voor warmteisolering, geluiddemping of geluidabsorptie, gebonden met gips)',
    68099000: 'werken van gips of van gipspreparaten (m.u.v. gipsverband, opgemaakt voor de verkoop in het klein, breukspalken van gipsverband; bouwpanelen en -platen en werken voor warmteisolering, geluiddemping of geluidabsorptie, gebonden met gips; anatomische en andere modellen voor het geven van demonstraties; origineel beeldhouwwerk; platen, panelen, tegels e.d. artikelen, onversierd)',
    68101110: 'blokken en stenen voor het bouwbedrijf, van licht beton "op basis van bimskies, gegranuleerde slakken, enz."',
    68101190: 'blokken en stenen voor het bouwbedrijf, van cement, van beton of van kunststeen, ook indien gewapend (m.u.v. die van licht beton "op basis van bimskies, gegranuleerde slakken, enz.")',
    68101900: 'Dakpannen, tegels e.d., van cement, van beton of van kunststeen (m.u.v. blokken en stenen voor het bouwbedrijf)',
    68109100: 'Geprefabriceerde bouwelementen van cement, van beton of van kunststeen, ook indien gewapend',
    68109900: 'werken van cement, van beton of van kunststeen, ook indien gewapend (m.u.v. geprefabriceerde bouwelementen en dakpannen, tegels, bouwstenen e.d. artikelen)',
    68114000: 'Werken van asbestcement, van cellulosecement e.d., bevattende asbest',
    68118100: 'Golfplaten van asbestcement, van cellulosecement e.d., geen asbest bevattend',
    68118200: 'Platen, panelen, tegels, dakpannen e.d. artikelen, van asbestcement, van cellulosecement e.d., geen asbest bevattend  (m.u.v. golfplaten)',
    68118900: 'Werken van asbestcement, van cellulosecement e.d., geen asbest bevattend (m.u.v. platen incl. golfplaten, panelen, tegels, dakpannen e.d.)',
    68128010: 'Bewerkte vezels van crocidoliet; mengsels samengesteld met asbest van crocidoliet of met asbest van crocidoliet en magnesiumcarbonaat',
    68128090: 'Werken van asbest van crocidoliet of van mengsels samengesteld met asbest van crocidoliet of met asbest van crocidoliet en magnesiumcarbonaat, b.v. garens, draad, koord,  weefsels of breiwerk, ook indien gewapend (m.u.v. bewerkt asbestvezels van crocidoliet; mengsels samengesteld met asbest van crocidoliet of met asbest van crocidoliet en magnesiumcarbonaat; wrijvingsmateriaal samengesteld met asbest van crocidoliet; werken van asbestcement van crocidoliet)',
    68129100: 'Kleding, kledingtoebehoren, schoeisel en hoofddeksels, van asbest of van mengsels samengesteld met asbest of met asbest en magnesiumcarbonaat (m.u.v. asbest van crocidoliet)',
    68129200: 'Papier, karton en vilt, van asbest of van mengsels samengesteld met asbest of met asbest en magnesiumcarbonaat (m.u.v. die met < 35 gewichtspercenten asbest en asbest van crocidoliet)',
    68129300: 'Asbestpakking, in vellen of op rollen (m.u.v. asbest van crocidoliet)',
    68129910: 'Bewerkte asbestvezels; mengsels samengesteld met asbest of met asbest en magnesiumcarbonaat (m.u.v. asbest van crocidoliet)',
    68129990: 'Werken van asbest of van mengsels samengesteld met asbest of met asbest en magnesiumcarbonaat, b.v. garens, draad, koord, weefsels of breiwerk, ook indien gewapend (m.u.v. asbest van crocidoliet; bewerkte asbestvezels; mengsels samengesteld met asbest of met asbest en magnesiumcarbonaat; asbestpakking, in vellen of op rollen: papier, karton en vilt: kleding, kledingtoebehoren, schoeisel en hoofddeksels: wrijvingsmateriaal samengesteld met asbest; werken van asbestcement)',
    68132000: 'Wrijvingsmateriaal, b.v. platen, rollen, banden, segmenten, schijven, ringen, voor koppelingen e.d., samengesteld met asbest, met andere minerale stoffen of met cellulose, ook indien verbonden met textiel of met andere stoffen, bevattende asbest',
    68138100: 'Remvoering samengesteld  met minerale stoffen of met cellulose, ook indien verbonden met textiel of met andere stoffen, geen asbest bevattend',
    68138900: 'Wrijvingsmateriaal, b.v. platen, rollen, banden, segmenten, schijven, ringen, voor koppelingen e.d., samengesteld met minerale stoffen of met cellulose, ook indien verbonden met textiel of met andere stoffen, geen asbest bevattend (m.u.v. remvoering)',
    68141000: 'platen, bladen en strippen van geagglomereerd of gereconstitueerd mica, ook indien op een drager van papier, van karton of van andere stoffen, op rollen of enkel vierkant of rechthoekig gesneden',
    68149000: 'mica, bewerkt en werken van mica (m.u.v. isolatoren, isolerende werkstukken, weerstanden en condensatoren voor de elektrotechniek; beschermingsbrillen van mica en glazen daarvoor; kerstboomversiering van mica; platen, bladen en strippen van geagglomereerde of gereconstitueerde mica, ook indien op een drager)',
    68151010: 'koolstofvezels en werken van koolstofvezels, voor niet-elektrisch gebruik',
    68151090: 'werken van grafiet of van andere koolstofvormen, voor niet-elektrisch gebruik (m.u.v. koolstofvezels en werken van koolstofvezels)',
    68152000: 'werken van turf (m.u.v. textielwaren van turfvezels)',
    68159100: 'werken van steen of van andere minerale stoffen, n.e.g., bevattende magnesiet, dolomiet of chromiet',
    68159900: 'Werken van steen of van andere minerale stoffen, n.e.g. (m.u.v. die bevattende magnesiet, dolomiet of chromiet; werken van grafiet of van andere koolstofvormen)',
    69010000: 'stenen, tegels en andere keramische voorwerpen van diatomeeënaarde "b.v. kiezelgoer, bergmeel, diatomiet" of van dergelijke kiezelaarden',
    69021000: 'stenen en tegels, vuurvast, e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden, bevattende, afzonderlijk of tezamen, > 50 gewichtspercenten MgO, CaO of Cr2O3',
    69022010: 'stenen en tegels, vuurvast, e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden, bevattende >= 93 gewichtspercenten siliciumdioxide "SiO2" (m.u.v. die van diatomeeënaarde of van dergelijke kiezelaarden)',
    69022091: 'stenen en tegels, vuurvast, e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden, bevattende > 7, doch < 45 gewichtspercenten aluminiumoxide "Al2O3", doch met een totaalgehalte aan Al2O3 en "SiO2" van < 50 gewichtspercenten',
    69022099: 'stenen en tegels, vuurvast, e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden, bevattende > 50 gewichtspercenten aluminiumoxide "Al2O3", siliciumdioxide "SiO2" of een mengsel of verbinding van deze producten (m.u.v. die met een gehalte aan SiO2 van >= 93 gewichtspercenten of met een gehalte aan Al2O3 van > 7, doch < 45 gewichtspercenten; die van diatomeeënaarde of van dergelijke kiezelaarden)',
    69029000: 'stenen en tegels, vuurvast, e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden (m.u.v. die bevattende, afzonderlijk of tezamen, > 50 gewichtspercenten Mg, Ca of Cr, berekand als MgO, CaO of Cr2O3 of bevattende > 50 gewichtspercenten Al2O3, SiO2 of een mengsel of verbinding van deze producten; die van diatomeeënaarde of van dergelijke kiezelaarden)',
    69031000: 'retorten, smeltkroezen, moffels, mondstukken, stoppen, dragers, schuitjes, pijpen, buizen, hulzen, staafjes en andere vuurvaste keramische voorwerpen, bevattende > 50 gewichtspercenten grafiet of andere koolstof of een mengsel van deze producten (m.u.v. vuurvaste stenen en tegels e.d. vuurvaste keramische vormstukken, voor constructiedoeleinden)',
    69032010: 'retorten, smeltkroezen, moffels, mondstukken, stoppen, dragers, schuitjes, pijpen, buizen, hulzen, staafjes en andere vuurvaste keramische voorwerpen, bevattende < 45 gewichtspercenten aluminiumoxide "Al2O3", doch > 50 gewichtspercenten siliciumdioxide "SiO2" en "Al2O3" samen (m.u.v. vuurvaste stenen en tegels e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden)',
    69032090: 'retorten, smeltkroezen, moffels, mondstukken, stoppen, dragers, schuitjes, pijpen, buizen, hulzen, staafjes en andere vuurvaste keramische voorwerpen, bevattende >= 45 gewichtspercenten aluminiumoxide "Al2O3", doch > 50 gewichtspercenten siliciumdioxide "SiO2" en "Al2O3" samen (m.u.v. vuurvaste stenen en tegels e.d. vuurvaste keramische vormstukken, voor konstruktiedoeleinden)',
    69039010: 'retorten, smeltkroezen, moffels, mondstukken, stoppen, dragers, schuitjes, pijpen, buizen, hulzen, staafjes en andere vuurvaste keramische voorwerpen, bevattende > 25 doch <= 50 gewichtspercenten grafiet of andere koolstof of een mengsel van deze producten (m.u.v. vuurvaste stenen en tegels e.d. vuurvaste keramische vormstukken, voor constructiedoeleinden)',
    69039090: 'retorten, smeltkroezen, moffels, mondstukken, stoppen, dragers, schuitjes, pijpen, buizen, hulzen, staafjes en andere vuurvaste keramische voorwerpen (m.u.v. die van diatomeeënaarde of van dergelijke kiezelaarden en die met een gehalte aan C, Al2O3 of SiO2 als bedoeld in de onderverdelingen 6903.10.00 en 6903.90.10 en m.u.v. goederen bedoeld bij post 6902)',
    69041000: 'baksteen (m.u.v. die van diatomeeënaarde of van dergelijke kiezelaarden; vuurvaste stenen bedoeld bij post 6902)',
    69049000: 'baksteen, vloerstroken "bourdis", balkbekleding e.d. keramische artikelen (m.u.v. die van diatomeeënaarde of dergelijke kiezelaarden; vuurvaste stenen bedoeld bij post 6902; gebrande plavuizen, vloer- en bodemtegels bedoeld bij de posten 6907 en 6908; baksteen)',
    69051000: 'dakpannen',
    69059000: 'dakpannen, elementen voor schoorstenen, rookkanalen, bouwkundige ornamenten en ander bouwmateriaal, van keramische stoffen (m.u.v. die van diatomeeënaarde of dergelijke kiezelaarden; vuurvaste keramische voorwerpen voor konstruktiedoeleinden; buizen en andere vormstukken voor leidingen e.d. gebruik; dakpannen)',
    69060000: 'buizen en goten, alsmede hulpstukken daarvoor, van keramische stoffen (m.u.v. die van diatomeeënaarde of dergelijke kiezelaarden; vuurvaste keramische voorwerpen, rookkanalen, buizen voor laboratoriumgebruik; isolatiebuizen en hulpstukken daarvoor, voor elektrotechnisch gebruik)',
    69072100: 'Plavuizen, vloer- en wandtegels, van keramische stoffen, met een waterabsorptiecoëfficiënt van <= 0,5% (m.u.v. vuurvaste keramische voorwerpen, blokjes en dergelijke voor mozaïeken en m.u.v. keramische afwerkstukken)',
    69072200: 'Plavuizen, vloer- en wandtegels, van keramische stoffen, met een waterabsorptiecoëfficiënt van > 0,5% doch <= 10% (m.u.v. vuurvaste keramische voorwerpen, blokjes en dergelijke voor mozaïeken en m.u.v. keramische afwerkstukken)',
    69072300: 'Plavuizen, vloer- en wandtegels, van keramische stoffen, met een waterabsorptiecoëfficiënt van >10% (m.u.v. vuurvaste keramische voorwerpen, blokjes en dergelijke voor mozaïeken en m.u.v. keramische afwerkstukken)',
    69073000: 'Blokjes en dergelijke artikelen voor mozaïeken, ook indien op een drager (m.u.v. vuurvaste keramische voorwerpen en keramische afwerkstukken)',
    69074000: 'Keramische afwerkstukken (m.u.v. vuurvaste)',
    69091100: 'apparaten en artikelen, voor chemisch of ander technisch gebruik, van porselein (m.u.v. vuurvaste keramische voorwerpen; elektrische toestellen, isolatoren en andere isolerende werkstukken voor elektrische installaties)',
    69091200: 'apparaten en artikelen van keramische stoffen met een hardheid >= 9 op de schaal van Mohs, voor chemisch of voor ander technisch gebruik (m.u.v. die van porselein en m.u.v. vuurvaste keramische voorwerpen, elektrische toestellen en isolatoren en andere isolerende werkstukken voor elektrische installaties)',
    69091900: 'apparaten en artikelen van keramische stoffen voor chemisch of voor ander technisch gebruik (m.u.v. die van porselein en die met een hardheid >= 9 op de schaal van Mohs en m.u.v. molen-, slijp- en wetstenen en andere artikelen bedoeld bij post 6804, vuurvaste keramische voorwerpen, elektrische toestellen en isolatoren en andere isolerende werkstukken voor elektrische installaties)',
    69099000: 'troggen, bakken e.d. bergingsmiddelen, voor het landbouwbedrijf, van keramische stoffen; kruiken e.d. bergingsmiddelen, voor vervoer of voor verpakking, van keramische stoffen (m.u.v. potten voor algemeen gebruik in laboratoria; potten voor winkels; huishoudelijke voorwerpen)',
    69101000: 'gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs e.d. artikelen voor sanitair gebruik, bestemd om blijvend te worden bevestigd, van porselein (m.u.v. zeep- en sponsbakjes, tandenborstelhouders, handdoekhouders, toiletpapierhouders)',
    69109000: 'gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs e.d. artikelen voor sanitair gebruik, bestemd om blijvend te worden bevestigd, van keramische stoffen (m.u.v. die van porselein, zeep- en sponsbakjes, tandenborstelhouders, handdoekhouders, toiletpapierhouders)',
    69111000: 'keuken- en tafelgerei, van porselein (m.u.v. versieringsvoorwerpen, kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en een mechanisch deel van metaal)',
    69119000: 'huishoudelijke artikelen en toiletartikelen, van porselein (m.u.v. keuken- en tafelgerei; badkuipen, bidets, gootstenen e.d. artikelen, bestemd om blijvend te worden bevestigd; beeldjes en andere versieringsvoorwerpen, kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en mechanisch deel van metaal)',
    69120021: 'vaatwerk, van gewoon aardewerk (m.u.v. beeldjes en andere versieringsvoorwerpen; kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en mechanisch deel van metaal)',
    69120023: 'vaatwerk, van gres (m.u.v.beeldjes en andere versieringsvoorwerpen; kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en mechanisch deel van metaal)',
    69120025: 'vaatwerk,  van faïence of van fijn aardewerk (m.u.v. beeldjes en andere versieringsvoorwerpen; kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en mechanisch deel van metaal)',
    69120029: 'vaatwerk, van keramische stoffen (m.u.v. beeldjes en andere versieringsvoorwerpen; kruiken e.d. bergingsmiddelen, voor vervoer en verpakking; koffie- en pepermolentjes met bakje van keramische stof en mechanisch deel van metaal; artikelen van porselein, van gewoon aardewerk, van gres, van faïence of van fijn aardewerk)',
    69120081: 'huishoudelijke artikelen en toiletartikelen, van gewoon aardewerk (m.u.v.vaatwerk, beeldjes en andere versieringsvoorwerpen; Gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs en dergelijke artikelen voor sanitair gebruik, kruiken e.d. bergingsmiddelen voor vervoer en verpakking)',
    69120083: 'huishoudelijke artikelen en toiletartikelen, van gres (m.u.v.vaatwerk, beeldjes en andere versieringsvoorwerpen; Gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs en dergelijke artikelen voor sanitair gebruik, kruiken e.d. bergingsmiddelen voor vervoer en verpakking)',
    69120085: 'huishoudelijke artikelen en toiletartikelen, van faïence of van fijn aardewerk (m.u.v.vaatwerk, beeldjes en andere versieringsvoorwerpen; Gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs en dergelijke artikelen voor sanitair gebruik, kruiken e.d. bergingsmiddelen voor vervoer en verpakking)',
    69120089: 'huishoudelijke artikelen en toiletartikelen, van keramische stoffen anders dan van porcelein, aardewerk, gres of faïence (m.u.v.vaatwerk, beeldjes en andere versieringsvoorwerpen; Gootstenen, wasbakken, zuilen voor wasbakken, badkuipen, bidets, closetpotten, stortbakken, urinoirs en dergelijke artikelen voor sanitair gebruik, kruiken e.d. bergingsmiddelen voor vervoer en verpakking)',
    69131000: 'beeldjes en andere versieringsvoorwerpen, van porselein, n.e.g.',
    69139010: 'beeldjes en andere versieringsvoorwerpen, van gewoon aardewerk, n.e.g.',
    69139093: 'beeldjes en andere versieringsvoorwerpen, van faïence of van fijn aardewerk, n.e.g.',
    69139098: 'Beeldjes en andere versieringsvoorwerpen, van keramische stoffen, n.e.g. (m.u.v. die van porselein, van gewoon aardewerk, van faïence of van fijn aardewerk)',
    69141000: 'werken van porselein, n.e.g.',
    69149000: 'werken van keramische stoffen , n.e.g. (m.u.v. die van porselein)',
    70010010: 'glasscherven en ander glasafval (m.u.v. glas in de vorm van poeder, van korreltjes, van schilfers of van vlokken)',
    70010091: 'optisch glas',
    70010099: 'glasmassa (m.u.v. optisch glas)',
    70021000: 'glas in kogels, onbewerkt (m.u.v. microbolletjes met een diameter van <= 1 mm en glazen kogels, die het karakter van speelgoed hebben)',
    70022010: 'staven of stengels, van optisch glas, onbewerkt',
    70022090: 'staven of stengels, glas, onbewerkt (m.u.v. die van optisch glas)',
    70023100: 'buizen van gesmolten kwarts of van ander gesmolten siliciumdioxide, onbewerkt',
    70023200: 'buizen van glas met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C, onbewerkt (m.u.v. die van gesmolten kwarts of van ander gesmolten siliciumdioxide)',
    70023900: 'buizen van glas, onbewerkt (m.u.v. die van glas met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C en m.u.v. die van gesmolten kwarts of van ander gesmolten siliciumdioxide)',
    70031210: 'gegoten of gewalst optisch glas in ongewapende platen, in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende, reflecterende of niet-reflecterende laag, doch verder onbewerkt',
    70031291: 'gegoten of gewalst glas in ongewapende platen, voorzien van een niet-reflecterende laag, doch verder onbewerkt (m.u.v. optisch glas)',
    70031299: 'platen van gegoten of gewalst glas, in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende of reflecterende laag, doch verder onbewerkt (m.u.v. die van optisch glas en m.u.v. gewapende platen)',
    70031910: 'gegoten of gewalst optisch glas in ongewapende platen, doch verder onbewerkt (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70031990: 'gegoten of gewalst glas in ongewapende platen, doch verder onbewerkt (m.u.v. optisch glas en in de massa gekleurd of troebel gemaakt glas, geplateerd of voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70032000: 'gegoten of gewalst draadglas in platen, ook indien voorzien van een absorberende, reflecterende of niet-reflecterende laag, doch verder onbewerkt',
    70033000: 'glas in profielen, ook indien voorzien van een absorberende, reflecterende of niet-reflecterende laag, doch verder onbewerkt',
    70042010: 'getrokken of geblazen optisch glas in platen, in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende, reflecterende of niet-reflecterende laag, doch verder onbewerkt',
    70042091: 'getrokken of geblazen glas in platen, voorzien van een niet-reflecterende laag, doch verder onbewerkt (m.u.v. optisch glas)',
    70042099: 'getrokken of geblazen glas in platen, in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende of reflecterende laag, doch verder onbewerkt (m.u.v. optisch glas)',
    70049010: 'getrokken of geblazen optisch glas in platen, doch verder onbewerkt (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70049080: 'Getrokken of geblazen glas in platen, onbewerkt (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of voorzien van een absorberende of reflecterende laag en m.u.v. optisch glas)',
    70051005: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, in ongewapende platen, voorzien van een niet-reflecterende laag, doch verder onbewerkt',
    70051025: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, in ongewapende platen, voorzien van een absorberende of reflecterende laag, doch verder onbewerkt, met een dikte van <= 3,5 mm',
    70051030: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, in ongewapende platen, voorzien van een absorberende of reflecterende laag, doch verder onbewerkt, met een dikte van > 3,5 doch <= 4,5 mm',
    70051080: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, in ongewapende platen, voorzien van een absorberende of reflecterende laag, doch verder onbewerkt, met een dikte van > 4,5 mm',
    70052125: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen glas, in ongewapende platen, in de massa gekleurd of troebel gemaakt, geplateerd of enkel geslepen, doch verder onbewerkt, met een dikte van <= 3,5 mm (m.u.v. glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70052130: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen glas, in ongewapende platen, in de massa gekleurd of troebel gemaakt, geplateerd of enkel geslepen, doch verder onbewerkt, met een dikte van > 3,5 doch <= 4,5 mm (m.u.v. glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70052180: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen glas, in ongewapende platen, in de massa gekleurd of troebel gemaakt, geplateerd of enkel geslepen, doch verder onbewerkt, met een dikte van > 4,5 mm (m.u.v. glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70052925: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, doch verder onbewerkt, in ongewapende platen met een dikte van <= 3,5 mm (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of enkel geslepen en glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70052935: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, doch verder onbewerkt, in ongewapende platen met een dikte van > 3,5 doch <= 4,5 mm (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of enkel geslepen en glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70052980: 'vuurgepolijst glas "float glass" en op een of beide zijden geslepen of gepolijst glas, doch verder onbewerkt, in ongewapende platen met een dikte van > 4,5 mm (m.u.v. in de massa gekleurd of troebel gemaakt glas, geplateerd of enkel geslepen en glas voorzien van een absorberende, reflecterende of niet-reflecterende laag)',
    70053000: 'vuurgepolijst draadglas "float glass" en op een of beide zijden geslepen of gepolijst draadglas, in platen, ook indien voorzien van een absorberende, reflecterende of niet-reflecterende laag, doch verder onbewerkt',
    70060010: 'optisch glas in platen of profielen, ook indien voorzien van een absorberende, reflecterende of niet-reflecterende laag, gebogen, met schuin geslepen randen, gegraveerd, van gaten voorzien, geëmailleerd of op andere wijze bewerkt (niet omlijst en niet met andere stoffen verbonden en m.u.v. veiligheidsglas, meerwandig glas voor isoleringsdoeleinden en spiegels van glas)',
    70060090: 'glas in platen of profielen, ook indien voorzien van een absorberende, reflecterende of niet-reflecterende laag, gebogen, met schuin geslepen randen, gegraveerd, van gaten voorzien, geëmailleerd of op andere wijze bewerkt (niet omlijst en niet met andere stoffen verbonden en m.u.v. optisch glas, veiligheidsglas, meerwandig glas voor isoleringsdoeleinden en spiegels van glas)',
    70071110: 'veiligheidsglas, bestaande uit geharde glasplaten "hardglas", blijkens afmeting en vorm geschikt voor automobielen en tractors',
    70071190: 'veiligheidsglas, bestaande uit geharde glasplaten "hardglas", blijkens afmeting en vorm geschikt voor luchtvaartuigen, voor vaartuigen of voor andere voertuigen (m.u.v. die voor automobielen en tractors)',
    70071910: 'veiligheidsglas, bestaande uit geharde glasplaten "hardglas", geëmailleerd',
    70071920: 'veiligheidsglas, bestaande uit geharde glasplaten "hardglas", in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende of reflecterende laag (m.u.v. veiligheidsglas dat blijkens afmeting en vorm geschikt is voor automobielen, voor luchtvaartuigen, voor vaartuigen of voor andere voertuigen en m.u.v. brillen- en horlogeglazen)',
    70071980: 'veiligheidsglas, bestaande uit geharde glasplaten "hardglas" (m.u.v. dat welk in de massa gekleurd of troebel gemaakt is, geplateerd is of voorzien van een absorberende of reflecterende laag en m.u.v. brillen- en horlogeglazen)',
    70072120: 'Veiligheidsglas bestaande uit opeengekitte glasplaten, blijkens afmetingen en vorm geschikt voor automobielen en tractors (m.u.v. meerwandig glas voor isoleringsdoeleinden)',
    70072180: 'Veiligheidsglas bestaande uit opeengekitte glasplaten, blijkens afmetingen en vorm geschikt voor luchtvaartuigen, voor vaartuigen of voor andere voertuigen (m.u.v. die voor automobielen en tractors, en m.u.v. meerwandig glas voor isoleringsdoeleinden)',
    70072900: 'veiligheidsglas bestaande uit opeengekitte glasplaten (m.u.v. dat welk blijkens afmeting en vorm geschikt is voor automobielen, voor luchtvaartuigen, voor vaartuigen of voor andere voertuigen en m.u.v. meerwandig glas voor isoleringsdoeleinden)',
    70080020: 'Glas voor isoleringsdoeleinden, meerwandig, in de massa gekleurd of troebel gemaakt, geplateerd of voorzien van een absorberende of reflecterende laag',
    70080081: 'glas voor isoleringsdoeleinden, bestaande uit twee langs de hele omtrek met een randprofiel luchtdicht afgesloten glasplaten en gescheiden door lucht, andere gassen of een vacuüm (m.u.v. dat welk in de massa gekleurd of troebel gemaakt is, geplateerd is of voorzien is van een absorberende of reflecterende laag)',
    70080089: 'glas voor isoleringsdoeleinden, bestaande uit twee glasplaten met een tussenlaag van glasvezels of uit drie of meer glasplaten (m.u.v. dat welk in de massa gekleurd of troebel gemaakt is, geplateerd is of voorzien is van een absorberende of reflecterende laag)',
    70091000: 'achteruitkijkspiegels van glas, ook indien omlijst, voor voertuigen',
    70099100: 'spiegels van glas, zonder omlijsting (m.u.v. achteruitkijkspiegels voor voertuigen, optisch bewerkte optische spiegels en spiegels > 100 jaar oud)',
    70099200: 'spiegels van glas, omlijst (m.u.v. achteruitkijkspiegels voor voertuigen, optisch bewerkte optische spiegels en spiegels > 100 jaar oud)',
    70101000: 'ampullen van glas',
    70102000: 'stoppen, deksels en andere sluitingen, van glas',
    70109010: 'weckglazen',
    70109021: 'buisjes en andere in de handel gebruikelijke bergingsmiddelen, verkregen uit een buis van glas, voor verpakking (m.u.v. ampullen)',
    70109031: 'flessen, flacons, bokalen, potten, buisjes en andere in de handel gebruikelijke bergingsmiddelen, van glas, voor vervoer of voor verpakking, met een capaciteit van >= 2,5 l',
    70109041: 'in de handel gebruikelijke flessen en flacons, van ongekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van >= 1 doch < 2,5 l',
    70109043: 'in de handel gebruikelijke flessen en flacons, van ongekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van > 0,33 doch < 1 l',
    70109045: 'in de handel gebruikelijke flessen en flacons, van ongekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van >= 0,15 doch <= 0,33 l',
    70109047: 'in de handel gebruikelijke flessen en flacons, van ongekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van < 0,15 l',
    70109051: 'in de handel gebruikelijke flessen en flacons, van gekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van >= 1 doch < 2,5 l',
    70109053: 'in de handel gebruikelijke flessen en flacons, van gekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van > 0,33 doch < 1 l',
    70109055: 'in de handel gebruikelijke flessen en flacons, van gekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van >= 0,15 doch <= 0,33 l',
    70109057: 'in de handel gebruikelijke flessen en flacons, van gekleurd glas, voor vervoer of voor verpakking van voedingsmiddelen of dranken, met een capaciteit van < 0,15 l',
    70109061: 'bokalen, potten, buisjes en andere in de handel gebruikelijke bergingsmiddelen, van glas, voor vervoer of voor verpakking van voedingswaren en dranken, met een capaciteit van >= 0,25 doch < 2,5 l (m.u.v. flessen en flacons)',
    70109067: 'bokalen, potten, buisjes en andere in de handel gebruikelijke bergingsmiddelen, van glas, voor vervoer of voor verpakking van voedingswaren en dranken, met een capaciteit van < 0,25 l (m.u.v. flessen en flacons)',
    70109071: 'flessen, flacons, buisjes  en andere in de handel gebruikelijke bergingsmiddelen, van glas, voor vervoer of voor verpakking van farmaceutische producten, met een capaciteit van > 0,055 doch < 2,5 l (m.u.v. ampullen, bergingsmiddelen verkregen uit een buis van glas en binnenflessen voor bergingsmiddelen met vacuümisolatie)',
    70109079: 'flessen, flacons, buisjes  en andere in de handel gebruikelijke bergingsmiddelen, van glas, voor vervoer of voor verpakking van farmaceutische producten, met een capaciteit van <= 0,055 l (m.u.v. ampullen, bergingsmiddelen verkregen uit een buis van glas en binnenflessen voor bergingsmiddelen met vacuümisolatie)',
    70109091: 'flessen, flacons, bokalen, potten, buisjes en andere in de handel gebruikelijke bergingsmiddelen, van ongekleurd glas, voor vervoer of voor verpakking, met een capaciteit van < 2,5 l (m.u.v. die voor voedingsmiddelen, dranken of farmaceutische producten en m.u.v. ampullen, bergingsmiddelen verkregen uit een buis van glas, binnenflessen voor bergingsmiddelen met vacuümisolatie, parfumverstuivers en flessen en flacons, bestemd voor vaporisators)',
    70109099: 'flessen, flacons, bokalen, potten, buisjes en andere in de handel gebruikelijke bergingsmiddelen, van gekleurd glas, voor vervoer of voor verpakking, met een capaciteit van < 2,5 l (m.u.v. die voor voedingsmiddelen, dranken of farmaceutische producten en m.u.v. ampullen, bergingsmiddelen verkregen uit een buis van glas, binnenflessen voor bergingsmiddelen met vacuümisolatie, parfumverstuivers en flessen en flacons, bestemd voor vaporisators)',
    70111000: 'ballons, peren, alsmede buizen "van speciale vorm, dan wel met een fluorescerende stof bedekt", open, en delen daarvan, van glas, zonder fittings, herkenbaar als zijnde bestemd voor lampen voor elektrische verlichting',
    70112000: 'ballons, peren, alsmede buizen "van speciale vorm, dan wel met een fluorescerende stof bedekt", open, en delen daarvan, van glas, zonder fittings, herkenbaar als zijnde bestemd voor kathodestraalbuizen',
    70119000: 'ballons, peren, alsmede buizen "van speciale vorm, dan wel met een fluorescerende stof bedekt", open, en delen daarvan, van glas, zonder fittings, herkenbaar als zijnde bestemd voor elektrische lampen, voor kathodestraalbuizen of voor dergelijke artikelen (m.u.v. die welke zijn bestemd voor kathodestraalbuizen of voor lampen voor elektrische verlichting)',
    70131000: 'glaswerk van glaskeramiek, voor tafel-, keuken-, toilet- of kantoorgebruik, voor binnenhuisversiering of voor dergelijk gebruik (m.u.v. artikelen bedoeld bij post 7010 of 7018, kookplaten, glas in lood, verlichtingstoestellen en delen daarvan, parfumverstuivers e.d. vaporisators)',
    70132210: 'Drinkglazen op voet,  van kristal, handgeschept',
    70132290: 'Drinkglazen op voet, van kristal, mechanisch vervaardigd',
    70132810: 'Drinkglazen op voet, handgeschept (m.u.v. die van glaskeramiek of van kristal)',
    70132890: 'Drinkglazen op voet, mechanisch vervaardigd (m.u.v. die van glaskeramiek of van kristal)',
    70133311: 'Drinkglazen van kristal, handgeschept, geslepen of op andere wijze versierd (m.u.v. drinkglazen op voet)',
    70133319: 'Drinkglazen van kristal, handgeschept (m.u.v. die welke zijn geslepen of op andere wijze versierd en drinkglazen op voet)',
    70133391: 'Drinkglazen van kristal, mechanisch vervaardigd, geslepen of op andere wijze versierd (m.u.v. drinkglazen op voet)',
    70133399: 'Drinkglazen van kristal, mechanisch vervaardigd (m.u.v. die welke zijn geslepen of op andere wijze versierd en drinkglazen op voet)',
    70133710: 'Drinkglazen van hardglas (m.u.v. drinkglazen op voet)',
    70133751: 'Drinkglazen, handgeschept, geslepen of op andere wijze versierd (m.u.v. die van glaskeramiek, van kristal of van hardglas en drinkglazen op voet)',
    70133759: 'Drinkglazen, handgeschept (niet-geslepen of op andere wijze versierd en m.u.v. die van glaskeramiek, van kristal of van hardglas en drinkglazen op voet)',
    70133791: 'Drinkglazen, mechanisch vervaardigd, geslepen of op andere wijze versierd (m.u.v. die van glaskeramiek, van kristal of van hardglas en drinkglazen op voet)',
    70133799: 'Drinkglazen, mechanisch vervaardigd (niet-geslepen of op andere wijze versierd en m.u.v. die van glaskeramiek, van kristal of van hardglas en drinkglazen op voet)',
    70134110: 'glaswerk van kristal, voor tafel- of keukengebruik, handgeschept (m.u.v. artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70134190: 'glaswerk van kristal, voor tafel- of keukengebruik, mechanisch vervaardigd (m.u.v. artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70134200: 'glaswerk voor tafel- of keukengebruik, met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C (m.u.v. artikelen van glaskeramiek of van kristal en artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70134910: 'glaswerk voor tafel- of keukengebruik, van hardglas (m.u.v. die met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C, m.u.v. artikelen van glaskeramiek of van kristal en artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70134991: 'glaswerk voor tafel- of keukengebruik, handgeschept (m.u.v. die van hardglas of die met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C, m.u.v. artikelen van glaskeramiek of van kristal en artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70134999: 'glaswerk voor tafel- of keukengebruik, mechanisch vervaardigd (m.u.v. die van hardglas of die met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C, m.u.v. artikelen van glaskeramiek of van kristal en artikelen bedoeld bij post 7018 en m.u.v. drinkglazen, weckglazen, thermosflessen en andere isothermische berginsgmiddelen met vacuümisolatie)',
    70139110: 'artikelen van kristal, voor toilet- of kantoorgebruik, voor binnenhuisversiering of voor dergelijk gebruik, handgeschept (m.u.v. die voor tafel- of keukengebruik, drinkglazen, m.u.v. artikelen bedoeld bij post 7010 of 7018 en m.u.v. spiegels, glas in lood, verlichtingstoestellen en delen daarvan, parfumverstuivers e.d. vaporisators)',
    70139190: 'artikelen van kristal, voor toilet- of kantoorgebruik, voor binnenhuisversiering of voor dergelijk gebruik, mechanisch vervaardigd (m.u.v. die voor tafel- of keukengebruik, m.u.v. artikelen bedoeld bij post 7010 of 7018 en m.u.v. spiegels, glas in lood, verlichtingstoestellen en delen daarvan, parfumverstuivers e.d. vaporisators)',
    70139900: 'glaswerk, voor toilet- of kantoorgebruik, voor binnenhuisversiering of voor dergelijk gebruik (m.u.v. die van kristal en die voor tafel- of keukengebruik, m.u.v. artikelen bedoeld bij post 7010 of 7018 en m.u.v. spiegels, glas in lood, verlichtingstoestellen en delen daarvan, parfumverstuivers e.d. vaporisators)',
    70140000: 'signaal- en waarschuwingsartikelen, van glas; optische elementen van glas (niet-optisch bewerkt en m.u.v. horlogeglazen, brillenglazen, ongeacht of deze bestemd zijn voor de verbetering van de gezichtsscherpte, e.d. glazen, m.u.v. holle glazen, bollen en bolsegmenten voor de vervaardiging van deze glazen en m.u.v. losse micro-bolletjes en verlichtingstoestellen en delen daarvan)',
    70151000: 'brillenglazen voor de verbetering van de gezichtsscherpte, gebombeerd, gebogen of op dergelijke wijze bewerkt, doch zonder optische bewerking (m.u.v. vlakglas voor dezelfde doeleinden)',
    70159000: 'horlogeglazen, brillenglazen, e.d. glazen, gebombeerd, gebogen of op dergelijke wijze bewerkt, doch zonder optische bewerking (m.u.v. brillenglazen voor de verbetering van de gezichtsscherpte); holle glazen, bollen en bolsegmenten voor de vervaardiging van deze glazen of voor de vervaardiging van brillenglazen voor de verbetering van de gezichtsscherpte (m.u.v. vlakglas voor dezelfde doeleinden en m.u.v. brillenglazen voor de verbetering van de gezichtsscherpte)',
    70161000: 'glazen blokjes en ander klein glaswerk, ook indien op een drager, voor mozaïeken of voor dergelijke doeleinden (m.u.v. gerede platen en andere gerede decoratieve figuren die zijn vervaardigd van glazen blokjes voor mozaïeken)',
    70169010: 'glas in lood (m.u.v. > 100 jaar oud)',
    70169040: 'Blokken en stenen voor bouwdoeleinden',
    70169070: 'Tegels, dakpannen en andere bouwmaterialen van gegoten of geperst glas, ook  indien gewapend; multicellulair glas of schuimglas, in blokken, in panelen, in platen, in schalen of in dergelijke vormen (m.u.v. veiligheidsglas, bestaande uit geharde glasplaten (hardglas) of uit opeengekitte glasplaten, glas in lood en blokken en stenen voor bouwdoeleinden)',
    70171000: 'glaswerk voor laboratoria, voor apotheken of voor hygiënisch gebruik, ook indien voorzien van graad- of maataanwijzingen, van gesmolten kwarts of van ander gesmolten siliciumdioxide (m.u.v. bergingsmiddelen voor vervoer of voor verpakking en m.u.v. meet-, test- en medische instrumenten, apparaten en toestellen bedoeld bij hoofdstuk 90)',
    70172000: 'glaswerk voor laboratoria, voor apotheken of voor hygiënisch gebruik, ook indien voorzien van graad- of maataanwijzingen, van glas met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C (m.u.v. die van gesmolten kwarts of van ander gesmolten siliciumdioxide en m.u.v. bergingsmiddelen voor vervoer of voor verpakking en m.u.v. meet-, test- en medische instrumenten, apparaten en toestellen bedoeld bij hoofdstuk 90)',
    70179000: 'glaswerk voor laboratoria, voor apotheken of voor hygiënisch gebruik, ook indien voorzien van graad- of maataanwijzingen (m.u.v. die van glas met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C of van gesmolten kwarts of van ander gesmolten siliciumdioxide en m.u.v. bergingsmiddelen voor vervoer of voor verpakking en m.u.v. meet-, test- en medische instrumenten, apparaten en toestellen bedoeld bij hoofdstuk 90)',
    70181011: 'glazen kralen, geslepen en mechanisch gepolijst (m.u.v. werken daarvan)',
    70181019: 'glazen kralen (niet-geslepen en niet-mechanisch gepolijst en m.u.v. werken daarvan)',
    70181030: 'onechte parels, van glas (m.u.v. werken daarvan)',
    70181051: 'onechte edelstenen, van glas, geslepen en mechanisch gepolijst (m.u.v. werken daarvan)',
    70181059: 'onechte edelstenen, van glas (niet-geslepen en niet-mechanisch gepolijst en m.u.v. werken daarvan)',
    70181090: 'onecht koraal e.d. klein glaswerk (m.u.v. werken daarvan en m.u.v. onechte parels en onechte edelstenen)',
    70182000: 'glazen microbolletjes met een diameter van <= 1 mm',
    70189010: 'glazen ogen en voorwerpen van glazen kralen, van glazen parels, van onechte edelstenen of van dergelijk klein glaswerk (m.u.v. prothesen en fancybijouterieën)',
    70189090: 'beeldjes en ander met de blaaslamp vervaardigde glazen ornamenten (m.u.v. fancybijouterieën)',
    70191100: 'garens van glas "chopped strands", versneden op een lengte van <= 50 mm',
    70191200: 'rovings van glas',
    70191910: 'lonten en garens van filamenten van glas, ook indien gesneden (m.u.v. garens versneden op een lengte van <= 50 mm en rovings)',
    70191990: 'garens van stapelvezels van glas',
    70193100: 'Matten van ongeregeld gelaagde glasvezels',
    70193200: 'Vliezen van ongeregeld gelaagde glasvezels',
    70193900: 'netten, matrassen, platen e.d. niet-geweven producten, van glas (m.u.v. matten en vliezen)',
    70194000: 'weefsels van rovings van glas',
    70195100: 'weefsels, incl. lint, van glas, met een breedte van <= 30 cm (m.u.v. die van rovings)',
    70195200: 'weefsels, incl. lint, met een breedte van > 30 cm, met platbinding, met een gewicht van < 250 g/m², van filamenten van glas van <= 136 tex per enkelvoudig garen (m.u.v. die van rovings)',
    70195900: 'weefsels, incl. lint, van glas, met een breedte van > 30 cm (m.u.v. die van rovings en die met platbinding, met een gewicht van < 250 g/m², van filamenten van <= 136 tex per enkelvoudig garen)',
    70199000: 'glasvezels, incl. glaswol, en werken daarvan (m.u.v. lonten, rovings, garens, vliezen, netten, matten, matrassen, platen e.d. niet-geweven producten, weefsels incl. lint, minerale wol en artikelen daarvan, isolatoren voor elektriciteit en isolerende delen daarvan, optische vezels, optische-vezelbundels en optischevezelkabels, borstels en penselen van glasvezels en poppepruiken)',
    70200005: 'reactorbuizen en -houders van kwarts, van de soort gebruikt in diffusie- en oxidatieovens voor de vervaardiging van halfgeleidermaterialen',
    70200007: 'binnenflessen voor thermosflessen of voor andere isothermische bergingsmiddelen met vacuümisolatie, onafgewerkt',
    70200008: 'binnenflessen voor thermosflessen of voor andere isothermische bergingsmiddelen met vacuümisolatie, afgewerkt',
    70200010: 'glaswerk van gesmolten kwarts of van ander gesmolten siliciumdioxide, n.e.g.',
    70200030: 'glaswerk met een lineaire uitzettingscoëfficiënt van <= 5 x 10-6 per Kelvin tussen 0°C en 300°C, n.e.g. (m.u.v. die van gesmolten kwarts of van ander gesmolten siliciumdioxide)',
    70200080: 'glaswerk, n.e.g.',
    71011000: 'echte parels, ook indien bewerkt of in stellen (m.u.v. die welke zijn aaneengeregen, gezet of gevat), alsmede echte parels, tijdelijk aaneengeregen met het oog op vervoer (m.u.v. paarlemoer)',
    71012100: 'parels, gekweekt, onbewerkt, ook indien in stellen',
    71012200: 'gekweekte parels, bewerkt, ook indien in stellen (m.u.v. die welke zijn aaneengeregen, gezet of gevat), alsmede gekweekte parels, bewerkt, tijdelijk aaneengeregen met het oog op vervoer',
    71021000: 'diamanten, ongesorteerd',
    71022100: 'industriediamanten, onbewerkt of enkel gezaagd, gekloofd of ruw gesneden',
    71022900: 'industriediamanten, bewerkt, noch gezet noch gevat (m.u.v. niet gezette stenen voor grammofonen; stenen, waarvan kan worden onderkend dat zij delen zijn van meters, meetinstrumenten of andere artikelen bedoeld bij hoofdstuk 90)',
    71023100: 'diamanten, onbewerkt of enkel gezaagd, gekloofd of ruw gesneden (m.u.v. industriediamanten)',
    71023900: 'diamant, bewerkt, noch gezet noch gevat (m.u.v. industriediamant)',
    71031000: 'natuurlijke edelstenen, incl. halfedelstenen, ruw of enkel gezaagd of ruw bewerkt, ook indien in stellen (m.u.v. diamant; namaak edelstenen en -halfedelstenen)',
    71039100: 'robijnen, saffieren en smaragden, bewerkt, ook indien in stellen (niet aaneengeregen, gevat of gezet); bewerkte robijnen, saffieren en smaragden, tijdelijk aaneengeregen met het oog op vervoer (niet in stellen) (m.u.v. enkel gezaagde of ruw bewerkte edelstenen; namaak edelstenen en -halfedelstenen)',
    71039900: 'natuurlijke edelstenen, incl. halfedelstenen, bewerkt, ook indien in stellen (niet aaneengeregen, gezet of gevat); bewerkte edelstenen en halfedelstenen, tijdelijk aaneengeregen met het oog op vervoer (niet in stellen) (m.u.v. enkel gezaagd of ruw bewerkt; diamant, robijnen, saffieren, smaragden; namaak edelstenen en -halfedelstenen)',
    71041000: 'kwarts, piëzo-elektrisch, van synthetische of gereconstrueerde stenen, ook indien bewerkt of in stellen, noch gezet noch gevat',
    71042010: 'diamanten, synthetisch of gereconstrueerd, onbewerkt of enkel gezaagd of ruw bewerkt',
    71042090: 'edelstenen en halfedelstenen, synthetisch of gereconstrueerd, onbewerkt of enkel gezaagd of ruw bewerkt, ook indien in stellen (m.u.v. piëzo-elektrische kwarts en diamanten)',
    71049010: 'diamanten, synthetisch of gereconstrueerd, bewerkt, ook indien in stellen (niet aaneengeregen, gezet of gevat); diamanten, synthetisch of gereconstrueerd, tijdelijk aaneengeregen met het oog op vervoer (niet in stellen) (m.u.v. enkel gezaagde of ruw diamanten)',
    71049090: 'edelstenen en halfedelstenen, synthetisch of gereconstrueerd, bewerkt, ook indien in stellen (niet aaneengeregen, gezet of gevat); edelstenen en halfedelstenen, synthetisch of gereconstrueerd, tijdelijk aaneengeregen met het oog op vervoer (niet in stellen) (m.u.v. enkel gezaagde of ruw bewerkte edelstenen; piëzo-elektrische kwarts en diamanten)',
    71051000: 'poeder en stof, van diamant, incl. synthetische diamant',
    71059000: 'poeder en stof, van natuurlijke of van synthetische edelstenen of halfedelstenen (m.u.v. dat van diamant)',
    71061000: 'zilver, incl. verguld en geplatineerd zilver, in poedervorm',
    71069100: 'Zilver, incl. verguld en geplatineerd zilver, onbewerkt  (m.u.v. dat in poedervorm)',
    71069200: 'Zilver, incl. verguld en geplatineerd zilver, halfbewerkt',
    71070000: 'onedele metalen geplateerd met zilver, onbewerkt of halfbewerkt',
    71081100: 'goud, incl. geplatineerd goud, in poedervorm (m.u.v. monetair goud)',
    71081200: 'goud, incl. geplatineerd goud, onbewerkt (m.u.v. monetair goud; goud in poedervorm)',
    71081310: 'staven, draad en profielen, massief, alsmede platen, bladen en strippen, met een dikte, zonder drager, van > 0,15 mm, van goud, incl. geplatineerd goud',
    71081380: 'goud, incl. geplatineerd goud, halfbewerkt (m.u.v. monetair goud; platen, bladen en strippen, met een dikte, zonder drager, van > 0,15 mm; massieve staven, draad en profielen)',
    71082000: 'monetair goud',
    71090000: 'onedele metalen en zilver, geplateerd met goud, onbewerkt of halfbewerkt',
    71101100: 'platina, onbewerkt of in poedervorm',
    71101910: 'staven, draad en profielen, massief, alsmede platen, bladen en strippen, met een dikte, zonder drager, van > 0,15 mm, van platina',
    71101980: 'platina, halfbewerkt (m.u.v. platen, bladen en strippen, met een dikte, zonder drager, van > 0,15 mm; massieve staven, draad en profielen)',
    71102100: 'palladium, onbewerkt of in poedervorm',
    71102900: 'palladium, halfbewerkt',
    71103100: 'rhodium, onbewerkt of in poedervorm',
    71103900: 'rhodium, halfbewerkt',
    71104100: 'iridium, osmium en ruthenium, onbewerkt of in poedervorm',
    71104900: 'iridium, osmium en ruthenium, halfbewerkt',
    71110000: 'onedele metalen, zilver en goud, geplateerd met platina, onbewerkt of halfbewerkt',
    71123000: 'as, bevattende edele metalen of verbindingen van edele metalen',
    71129100: 'resten en afval van goud, van met goud geplateerde metalen daaronder begrepen; andere resten en afval, bevattende goud of verbindingen van goud, van de soort hoofdzakelijk gebruikt voor het terugwinnen van edele metalen (m.u.v. as, bevattende goud of verbindingen van goud, gesmolten en tot blokken, gietelingen of dergelijke primaire vormen gegoten resten en afval van goud, en schuim dat andere edele metalen bevat)',
    71129200: 'resten en afval van platina, van met platina geplateerde metalen daaronder begrepen; andere resten en afval, bevattende platina of verbindingen van platina, van de soort hoofdzakelijk gebruikt voor het terugwinnen van edele metalen (m.u.v. as, bevattende platina of verbindingen van platina, gesmolten en tot blokken, gietelingen of dergelijke primaire vormen gegoten resten en afval van platina, en schuim dat andere edele metalen bevat)',
    71129900: 'resten en afval van zilver, van met zilver geplateerde metalen daaronder begrepen; andere resten en afval, bevattende zilver of verbindingen van zilver, van de soort hoofdzakelijk gebruikt voor het terugwinnen van edele metalen (m.u.v. as, alsmede gesmolten en tot blokken, gietelingen of dergelijke primaire vormen gegoten resten en afval van edele metalen)',
    71131100: 'bijouterieën en juwelen, alsmede delen daarvan, van zilver, ook indien bekleed of geplateerd met andere edele metalen (m.u.v. die van > 100 jaar oud)',
    71131900: 'bijouterieën en juwelen, alsmede delen daarvan, van edele metalen, ook indien bekleed of geplateerd met edele metalen (m.u.v. die van zilver; die van > 100 jaar oud)',
    71132000: 'bijouterieën en juwelen, alsmede delen daarvan, van onedele metalen geplateerd met edele metalen (m.u.v. die van > 100 jaar oud)',
    71141100: 'edelsmidswerk en delen daarvan, van zilver, ook indien bekleed of geplateerd met andere edele metalen (m.u.v. bijouterieën en juwelen, klokkenmakerswerken; muziekinstrumenten; wapens; vaporisators voor toiletgebruik en montuurkoppen daarvoor; origineel beeldhouwwerk; voorwerpen voor verzamelingen en antiquiteiten)',
    71141900: 'edelsmidswerk en delen daarvan, van edele metalen, ook indien bekleed of geplateerd met edele metalen (m.u.v. die van zilver; bijouterieën en juwelen, klokkenmakerswerken; muziekinstrumenten; wapens; vaporisators voor toiletgebruik en montuurkoppen daarvoor; origineel beeldhouwwerk; voorwerpen voor verzamelingen en antiquiteiten)',
    71142000: 'edelsmidswerk en delen daarvan, van onedele metalen geplateerd met edele metalen (m.u.v. bijouterieën en juwelen, klokkenmakerswerken; muziekinstrumenten; wapens; vaporisators voor toiletgebruik en montuurkoppen daarvoor; origineel beeldhouwwerk; voorwerpen voor verzamelingen en antiquiteiten)',
    71151000: 'katalysatoren in de vorm van platinadoek of platinagaas',
    71159000: 'werken van edele metalen of van metalen geplateerd met edele metalen, n.e.g.',
    71161000: 'werken van echte of gekweekte parels, n.e.g.',
    71162011: 'halssnoeren, armbanden en andere werken, uitsluitend van natuurlijke edelstenen of halfedelstenen, enkel aaneengeregen, doch zonder sluiting of ander toebehoren',
    71162080: 'Werken van natuurlijke, synthetische of gereconstrueerde edelstenen of halfedelstenen, n.e.g.',
    71171100: 'manchetknopen e.d. knopen, van onedel metaal, ook indien verzilverd, verguld of geplatineerd',
    71171900: 'Fancybijouterieën, van onedel metaal, verzilverd, verguld of geplatineerd (m.u.v. manchetknopen e.d. knopen)',
    71179000: 'fancybijouterieën (m.u.v. die van onedel metaal, ook indien verzilverd, verguld of geplatineerd)',
    71181000: 'Munten (m.u.v. geldige wettige betaalmiddelen, gouden munten; medailles; munten verwerkt tot sieraden; voorwerpen voor verzamelingen met een numismatisch belang; resten en afvallen)',
    71189000: 'gouden munten; munten als geldige wettige betaalmiddelen (m.u.v. medailles; munten verwerkt tot sieraden; voorwerpen voor verzamelingen met een numismatisch belang; resten en afvallen)',
    72011011: 'niet-gelegeerd gietijzer, in gietelingen, in blokken of in andere primaire vormen, bevattende <= 0,5 gewichtspercent fosfor, bevattende >= 0,4 gewichtspercent mangaan en met een siliciumgehalte van <= 1 gewichtspercent',
    72011019: 'niet-gelegeerd gietijzer, in gietelingen, in blokken of in andere primaire vormen, bevattende <= 0,5 gewichtspercent fosfor, bevattende >= 0,4 gewichtspercent mangaan en met een siliciumgehalte van > 1 gewichtspercent',
    72011030: 'niet-gelegeerd gietijzer, in gietelingen, in blokken of in andere primaire vormen, bevattende <= 0,5 gewichtspercent fosfor, bevattende >= 0,1, doch < 0,4 gewichtspercent mangaan',
    72011090: 'niet-gelegeerd gietijzer, in gietelingen, in blokken of in andere primaire vormen, bevattende <= 0,5 gewichtspercent fosfor, bevattende < 0,1 gewichtspercent mangaan',
    72012000: 'niet-gelegeerd gietijzer, in gietelingen, in blokken of in andere primaire vormen, bevattende > 0,5 gewichtspercent fosfor',
    72015010: 'gelegeerd gietijzer in gietelingen, in blokken of in andere primaire vormen, bevattende >= 0,3 doch <= 1 gewichtspercent titaan en >= 0,5 doch <= 1 gewichtspercent vanadium',
    72015090: 'gelegeerd gietijzer en spiegelijzer, in gietelingen, in blokken of in andere primaire vormen (m.u.v. gietijzer bevattende >= 0,3 doch <= 1 gewichtspercent titaan en >= 0,5 doch <= 1 gewichtspercent vanadium)',
    72021120: 'ferromangaan, bevattende > 2 gewichtspercent koolstof, met een korrel van <= 5 mm en een mangaangehalte van > 65 gewichtspercenten',
    72021180: 'ferromangaan, bevattende > 2 gewichtspercent koolstof (m.u.v. die met een korrel van <= 5 mm en een mangaangehalte van > 65 gewichtspercenten)',
    72021900: 'ferromangaan bevattende <= 2 gewichtspercenten koolstof',
    72022100: 'ferrosilicium bevattende > 55 gewichtspercenten silicium',
    72022910: 'ferrosilicium bevattende <= 55 gewichtspercenten silicium en >= 4 doch <=10 gewichtspercenten magnesium',
    72022990: 'ferrosilicium bevattende <= 55 gewichtspercenten silicium (m.u.v. dat met een magnesiumgehalte van >= 4 doch <=10 gewichtspercenten)',
    72023000: 'ferrosilicomangaan',
    72024110: 'ferrochroom, bevattende > 4, doch <= 6 gewichtspercenten koolstof',
    72024190: 'ferrochroom bevattende > 6 gewichtspercenten koolstof',
    72024910: 'ferrochroom, bevattende <= 0,05 gewichtspercent koolstof',
    72024950: 'ferrochroom, bevattende > 0,05, doch <= 0,5 gewichtspercent koolstof',
    72024990: 'ferrochroom, bevattende > 0,5, doch <= 4 gewichtspercent koolstof',
    72025000: 'ferrosilicochroom',
    72026000: 'ferronikkel',
    72027000: 'ferromolybdeen',
    72028000: 'ferrowolfraam en ferrosilicowolfraam',
    72029100: 'ferrotitaan en ferrosilicotitaan',
    72029200: 'ferrovanadium',
    72029300: 'ferroniobium',
    72029910: 'ferrofosfor',
    72029930: 'ferrosilicomagnesium',
    72029980: 'ferrolegeringen (m.u.v. ferromangaan, ferrosilicium, ferrosilicomangaan, ferrochroom, ferrosilicochroom, ferronikkel, ferromolybdeen, ferrowolfraam, ferrosilicowolfraam, ferrotitaan, ferrosilicotitaan, ferrovanadium, ferroniobium, ferrofosfor en ferrosilicomagnesium)',
    72031000: 'ferroproducten verkregen door het rechtstreeks reduceren van ijzererts',
    72039000: 'sponsachtige ferroproducten, in blokken, in pellets of in dergelijke vormen; ijzer met een zuiverheid van >= 99,94 gewichtspercenten, in blokken, in pellets of in dergelijke vormen (m.u.v. ferroproducten verkregen door het rechtstreeks reduceren van ijzererts)',
    72041000: 'resten en afval, van gietijzer (m.u.v. radioactieve resten en afval)',
    72042110: 'resten en afval, van roestvrij staal, bevattende >= 8 gewichtspercenten nikkel (niet radioactief en m.u.v. die van batterijen en accumulatoren)',
    72042190: 'resten en afval, van roestvrij staal (niet radioactief en m.u.v. die bevattende >= 8 gewichtspercenten nikkel en die van batterijen en accumulatoren)',
    72042900: 'resten en afval, van gelegeerd staal (niet radioactief en m.u.v. die van roestvrij staal en die van batterijen en accumulatoren)',
    72043000: 'resten en afval, van vertind ijzer en staal (niet radioactief en m.u.v. die van batterijen en accumulatoren)',
    72044110: 'draaisel, krullen, spanen, slijpsel, zaagsel en vijlsel, van ijzer of van staal, ook indien in pakketten (m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal)',
    72044191: 'afval van het stampen of stansen, van ijzer of van staal, in pakketten (m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal)',
    72044199: 'afval van het stampen of stansen, van ijzer of van staal (niet in pakketten en m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal)',
    72044910: 'resten en afval, van ijzer of van staal, gefragmenteerd (niet radioactief, m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal en die van batterijen en accumulatoren en m.u.v. slakken, walsschilfers en ander bij de vervaardiging van ijzer en staal verkregen afval, draaisel, krullen, spanen, slijpsel, zaagsel, vijlsel, afval van het stampen of stansen en stukken van gietelingen, van blokken of van andere primaire vormen, van gietijzer of van spiegelijzer)',
    72044930: 'resten en afval, van ijzer of van staal, in pakketten (niet gefragmenteerd en niet radioactief, m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal en die van batterijen en accumulatoren en m.u.v. slakken, walsschilfers en ander bij de vervaardiging van ijzer en staal verkregen afval, draaisel, krullen, spanen, slijpsel, zaagsel, vijlsel, afval van het stampen of stansen en stukken van gietelingen, van blokken of van andere primaire vormen, van gietijzer of van spiegelijzer)',
    72044990: 'resten en afval, van ijzer of van staal (niet gefragmenteerd, niet in pakketten en niet radioactief, m.u.v. die van gietijzer, van gelegeerd staal of van vertind ijzer en staal en die van batterijen en accumulatoren en m.u.v. slakken, walsschilfers en ander bij de vervaardiging van ijzer en staal verkregen afval, draaisel, krullen, spanen, slijpsel, zaagsel, vijlsel, afval van het stampen of stansen en stukken van gietelingen, van blokken of van andere primaire vormen, van gietijzer of van spiegelijzer)',
    72045000: 'afvalingots van ijzer of van staal (m.u.v. producten die beantwoorden aan de chemische samenstelling van gietijzer, van spiegelijzer of van ferrolegeringen)',
    72051000: 'korrels van gietijzer, van spiegelijzer, van ijzer of van staal (m.u.v. die van ferrolegeringen en m.u.v. draaisel en vijlsel van ijzer of staal en bepaalde defekte kogels, van kleinkaliber, voor kogellagers)',
    72052100: 'poeder van gelegeerd staal (m.u.v. poeder van ferrolegeringen en radioactief ijzerpoeder "isotopen")',
    72052900: 'poeder van gietijzer, van spiegelijzer, van ijzer of van niet-gelegeerd staal (m.u.v. poeder van ferrolegeringen en radioactief ijzerpoeder "isotopen")',
    72061000: 'ijzer en niet-gelegeerd staal, in ingots (m.u.v. afvalingots, continu-gegoten producten en ferroproducten bedoeld bij post 7203)',
    72069000: 'ijzer en niet-gelegeerd staal, in loepen of in andere primaire vormen (m.u.v. ingots, afvalingots, continu-gegoten producten en ferroproducten bedoeld bij post 7203)',
    72071111: 'halffabrikaten van niet-gelegeerd automatenstaal, bevattende < 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede, waarvan de breedte < 2 x de dikte is, gewalst of door continu-gieten verkregen',
    72071114: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede waarvan de breedte < 2 maal de dikte is, met een dikte van <= 130 mm, gewalst of door continu-gieten verkregen (m.u.v. die van automatenstaal)',
    72071116: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede waarvan de breedte < 2 maal de dikte is, met een dikte van > 130 mm, gewalst of door continu-gieten verkregen (m.u.v. die van automatenstaal)',
    72071190: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede, waarvan de breedte < 2 x de dikte is, gesmeed',
    72071210: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een rechthoekige dwarsdoorsnede waarvan de breedte >= 2 maal de dikte is, gewalst of door continu-gieten verkregen',
    72071290: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een rechthoekige dwarsdoorsnede, waarvan de breedte >= 2 x de dikte is, gesmeed',
    72071912: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een cirkelvormige of veelhoekige dwarsdoorsnede, gewalst of door continu-gieten verkregen',
    72071919: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof, met een cirkelvormige of veelhoekige dwarsdoorsnede, gesmeed',
    72071980: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende < 0,25 gewichtspercent koolstof (m.u.v. die met een vierkante, rechthoekige, cirkelvormige of veelhoekige dwarsdoorsnede)',
    72072011: 'halffabrikaten van niet-gelegeerd automatenstaal, bevattende >= 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede, waarvan de breedte < 2 x de dikte is, gewalst of door continu-gieten verkregen',
    72072015: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25, doch < 0,6 gewichtspercent koolstof, met vierkante of rechthoekige dwarsdoorsnede waarvan de breedte < 2 x de dikte is, gewalst of door continu-gieten verkregen (m.u.v. automatenstaal)',
    72072017: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,6 gewichtspercent koolstof, met vierkante of rechthoekige dwarsdoorsnede waarvan de breedte < 2 x de dikte is, gewalst of door continu-gieten verkregen (m.u.v. automatenstaal)',
    72072019: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof, met een vierkante of rechthoekige dwarsdoorsnede, waarvan de breedte < 2 x de dikte is, gesmeed',
    72072032: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof, met een rechthoekige dwarsdoorsnede waarvan de breedte >= 2 maal de dikte is, gewalst of door continu-gieten verkregen',
    72072039: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof, met een rechthoekige dwarsdoorsnede, waarvan de breedte >= 2 x de dikte is, gesmeed',
    72072052: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof, met een cirkelvormige of veelhoekige dwarsdoorsnede, gewalst of door continu-gieten verkregen',
    72072059: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof, met een cirkelvormige of veelhoekige dwarsdoorsnede, gesmeed',
    72072080: 'halffabrikaten van ijzer of van niet-gelegeerd staal, bevattende >= 0,25 gewichtspercent koolstof (m.u.v. die met een vierkante, rechthoekige, cirkelvormige of veelhoekige dwarsdoorsnede)',
    72081000: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met in reliëf gewalste motieven',
    72082500: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4,75 mm, zonder in reliëf gewalste motieven, gebeitst',
    72082600: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 3 doch < 4,75 mm, zonder in reliëf gewalste motieven, gebeitst',
    72082700: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van < 3 mm, zonder in reliëf gewalste motieven, gebeitst',
    72083600: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van > 10 mm, zonder in reliëf gewalste motieven, ongebeitst',
    72083700: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4,75 doch <= 10 mm, zonder in reliëf gewalste motieven, ongebeitst',
    72083800: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 3 doch < 4,75 mm, zonder in reliëf gewalste motieven, ongebeitst',
    72083900: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van < 3 mm, zonder in reliëf gewalste motieven, ongebeitst',
    72084000: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met in reliëf gewalste motieven',
    72085120: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van > 15 mm, zonder in reliëf gewalste motieven',
    72085191: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 2050 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van > 10 doch <= 15 mm, zonder in reliëf gewalste motieven',
    72085198: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 doch < 2050 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van > 10 doch <= 15 mm, zonder in reliëf gewalste motieven',
    72085210: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van <= 1.250 mm, onopgerold, enkel aan vier zijden of in een gesloten kaliber warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4,75 doch <= 10 mm, zonder in reliëf gewalste motieven',
    72085291: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 2.050 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4,75 doch <= 10 mm, zonder in reliëf gewalste motieven',
    72085299: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 doch < 2.050 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4,75 doch <= 10 mm, zonder in reliëf gewalste motieven (m.u.v. producten aan vier zijden of in een gesloten kaliber gewalst met een breedte <= 1.250 mm)',
    72085310: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van <= 1.250 mm, onopgerold, enkel aan vier zijden of in een gesloten kaliber warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 4 doch < 4,75 mm, zonder in reliëf gewalste motieven',
    72085390: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van >= 3 doch < 4,75 mm, zonder in reliëf gewalste motieven (m.u.v. producten aan vier zijden of in een gesloten kaliber gewalst met een breedte <= 1 250 mm en een dikte van >= 4 mm)',
    72085400: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel warm gewalst, ongeplateerd en onbekleed, met een dikte van < 3 mm, zonder in reliëf gewalste motieven',
    72089020: 'Gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, warm gewalst en verder bewerkt, doch ongeplateerd en onbekleed, geperforeerd',
    72089080: 'Gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, warm gewalst en verder bewerkt, doch ongeplateerd en onbekleed, niet geperforeerd',
    72091500: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van >= 3 mm',
    72091610: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, met een dikte van > 1 doch < 3 mm',
    72091690: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van > 1 doch < 3 mm (m.u.v. dynamoplaat of transformatorband)',
    72091710: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, met een dikte van >= 0,5 doch <= 1 mm',
    72091790: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van >= 0,5 doch <= 1 mm (m.u.v. dynamoplaat of transformatorband)',
    72091810: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, met een dikte van < 0,5 mm',
    72091891: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van >= 0,35 doch < 0,5 mm (m.u.v. dynamoplaat of transformatorband)',
    72091899: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, opgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van < 0,35 mm (m.u.v. dynamoplaat of transformatorband)',
    72092500: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van >= 3 mm',
    72092610: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, met een dikte van > 1 doch < 3 mm',
    72092690: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van > 1 doch < 3 mm (m.u.v. dynamoplaat of transformatorband)',
    72092710: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, met een dikte van >= 0,5 doch <= 1 mm',
    72092790: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van >= 0,5 doch <= 1 mm (m.u.v. dynamoplaat of transformatorband)',
    72092810: 'dynamoplaat of transformatorband, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, met een dikte van < 0,5 mm',
    72092890: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, onopgerold, enkel koud gewalst, ongeplateerd en onbekleed, met een dikte van < 0,5 mm (m.u.v. dynamoplaat of transformatorband)',
    72099020: 'Gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, koud gewalst en verder bewerkt, doch ongeplateerd en onbekleed, geperforeerd',
    72099080: 'Gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, koud gewalst en verder bewerkt, doch ongeplateerd en onbekleed, niet geperforeerd',
    72101100: 'koud of warm gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, geplateerd of bekleed, vertind, met een dikte van >= 0,5 mm',
    72101220: 'blik, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm en met een dikte van < 0,5 mm, vertind [bedekt met een laag metaal, waarvan het tingehalte >= 97 gewichtspercenten bedraagt], enkel aan het oppervlak bewerkt',
    72101280: 'koud of warm gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, geplateerd of bekleed, vertind, met een dikte van < 0,5 mm (m.u.v. blik)',
    72102000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, verlood',
    72103000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, elektrolytisch verzinkt',
    72104100: 'Warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, gegolfd, verzinkt (m.u.v. die welke elektrolytisch zijn verzinkt)',
    72104900: 'Warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, verzinkt (niet gegolfd en niet elektrolytisch verzinkt)',
    72105000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, bekleed met chroomoxiden of met chroom en chroomoxiden',
    72106100: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, bekleed met aluminium-zinklegeringen',
    72106900: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, bekleed met aluminium (m.u.v. die bekleed met aluminium-zinklegeringen)',
    72107010: 'blik met een breedte van >= 600 mm en met een dikte van < 0,5 mm, vertind [bedekt met een laag metaal, waarvan het tingehalte >= 97 gewichtspercenten bedraagt], enkel gevernist en warm of koud gewalste platte producten, van ijzer of van niet- gelegeerd staal, met een breedte van >= 600 mm, bekleed met chroomoxiden of met chroom en chroomoxiden, gevernist',
    72107080: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, geverfd, gevernist of bekleed met kunststof (m.u.v.gevernist blik en warm of koud gewalste platte producten bekleed met chroomoxiden of met chroom en chroomoxiden, gevernist)',
    72109030: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, geplateerd',
    72109040: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, vertind en bedrukt',
    72109080: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van >= 600 mm, bekleed (m.u.v. die welke zijn vertind, verlood, verzinkt, bekleed met chroomoxiden, chroom en chroomoxiden, aluminium of kunststof, geverfd of gevernist, geplateerd,  en m.u.v. die welke zijn vertind en bedrukt)',
    72111300: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, aan vier zijden of in een gesloten kaliber, enkel warm gewalst, ongeplateerd en onbekleed, met een breedte van > 150 doch < 600 mm en een dikte van >= 4 mm, onopgerold, zonder in reliëf gewalste motieven "zgn. universaalstaal genoemd"',
    72111400: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm en een dikte van >= 4,75 mm (m.u.v. zgn. universaalstaal)',
    72111900: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm en een dikte van < 4,75 mm (m.u.v. zgn. universaalstaal)',
    72112320: 'dynamoband of transformatorband, van ijzer of van niet-gelegeerd staal, enkel koud gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm, bevattende < 0,25 gewichtspercent koolstof',
    72112330: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, enkel koud gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm en een dikte van >= 0,35 mm, bevattende < 0,25 gewichtspercent koolstof, opgerold (m.u.v. dynamoband of transformatorband)',
    72112380: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, enkel koud gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm en een dikte van < 0,35 mm, bevattende < 0,25 gewichtspercent koolstof, opgerold (m.u.v. dynamoband of transformatorband)',
    72112900: 'gewalste platte producten, van ijzer of van niet-gelegeerd staal, enkel koud gewalst, ongeplateerd en onbekleed, met een breedte van < 600 mm, bevattende >= 0,25 gewichtspercent koolstof',
    72119020: 'Warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, verder bewerkt, doch ongeplateerd en onbekleed, geperforeerd',
    72119080: 'Warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, verder bewerkt, doch ongeplateerd en onbekleed, niet geperforeerd',
    72121010: 'blik, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm en met een dikte van < 0,5 mm, vertind [bedekt met een laag metaal, waarvan het tingehalte >= 97 gewichtspercenten bedraagt], enkel aan het oppervlak bewerkt',
    72121090: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, vertind (m.u.v. blik, enkel aan het oppervlak bewerkt)',
    72122000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, elektrolytisch verzinkt',
    72123000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van <= 600 mm, verzinkt (niet elektrolytisch verzinkt)',
    72124020: 'blik met een breedte van < 600 mm en met een dikte van < 0,5 mm, vertind [bedekt met een laag metaal, waarvan het tingehalte >= 97 gewichtspercenten bedraagt], enkel gevernist en warm of koud gewalste platte producten, van ijzer of van niet- gelegeerd staal, met een breedte van < 600 mm, bekleed met chroomoxiden of met chroom en chroomoxiden, gevernist',
    72124080: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, geverfd, gevernist of bekleed met kunststof (m.u.v. blik, enkel gevernist en m.u.v. producten bekleed met chroomoxiden of met chroom en chroomoxiden, gevernist)',
    72125020: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, bekleed met chroomoxiden of met chroom en chroomoxiden (niet gevernist)',
    72125030: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, vernikkeld of verchroomd',
    72125040: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, verkoperd',
    72125061: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, bekleed met aluminium-zinklegeringen',
    72125069: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, bekleed met aluminium (m.u.v. die welke zijn bekleed met aluminium-zinklegeringen)',
    72125090: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van < 600 mm, bekleed (niet vertind, verzinkt, geverfd, gevernist, bekleed met kunststof of met chroomoxiden of met chroom en chroomoxiden, verkoperd, verchroomd, vernikkeld of bekleed met aluminium)',
    72126000: 'warm of koud gewalste platte producten, van ijzer of van niet-gelegeerd staal, met een breedte van <= 600 mm, geplateerd',
    72131000: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen',
    72132000: 'walsdraad van niet-gelegeerd automatenstaal, onregelmatig opgerold (m.u.v. dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72139110: 'glad walsdraad van de soort gebruikt voor het wapenen van beton, van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm',
    72139120: 'glad walsdraad van de soort gebruikt voor bandenkoord, van ijzer of van niet-gelegeerd staal, onregelmatig opgerold',
    72139141: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende <= 0,06 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm (m.u.v. dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en m.u.v. walsdraad van de soort gebruikt voor het wapenen van beton of voor bandenkoord)',
    72139149: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende > 0,06 doch < 0,25 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm (m.u.v. dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en m.u.v. walsdraad van de soort gebruikt voor het wapenen van beton of voor bandenkoord)',
    72139170: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende >= 0,25 doch <= 0,75 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm (m.u.v. dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en m.u.v. walsdraad van de soort gebruikt voor het wapenen van beton of voor bandenkoord)',
    72139190: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende > 0,75 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm (m.u.v. dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en m.u.v. walsdraad van de soort gebruikt voor het wapenen van beton of voor bandenkoord)',
    72139910: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende < 0,25 gewichtspercent koolstof (m.u.v. dat met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm, dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72139990: 'walsdraad van ijzer of van niet-gelegeerd staal, onregelmatig opgerold, bevattende >= 0,25 gewichtspercent koolstof (m.u.v. dat met een cirkelvormige dwarsdoorsnede met een diameter van < 14 mm, dat van automatenstaal en dat voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72141000: 'staven van ijzer of van niet-gelegeerd staal, enkel gesmeed',
    72142000: 'staven van ijzer of van niet-gelegeerd staal, voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen, ook indien na het walsen getordeerd',
    72143000: 'staven van niet-gelegeerd automatenstaal, enkel warm gewalst, warm getrokken of warm geperst (m.u.v. die voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72149110: 'staven van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende < 0,25 gewichtspercent koolstof, met een rechthoekige "niet-vierkante" dwarsdoorsnede (niet na het walsen getordeerd en m.u.v. die voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en die van automatenstaal)',
    72149190: 'staven van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende >= 0,25 gewichtspercent koolstof, met een rechthoekige "niet-vierkante" dwarsdoorsnede (niet na het walsen getordeerd en m.u.v. die voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en die van automatenstaal)',
    72149910: 'gladde staven van de soort gebruikt voor het wapenen van beton, van ijzer of van niet-gelegeerd staal, enkel warmgewalst, warmgetrokken of warmgeperst, bevattende < 0,25 gewichtspercent koolstof (niet na het walsen getordeerd en m.u.v. die met rechthoekige "niet-vierkante" dwarsdoorsnede)',
    72149931: 'Staven van ijzer of van niet-gelegeerd staal, enkel warmgewalst, warmgetrokken of warmgeperst, bevattende < 0,25 gewichtspercent koolstof, met cirkelvormige dwarsdoorsnede met een grootste diameter van >= 80 mm (m.u.v. die van automatenstaal; gladde staven van de soort gebruikt voor het wapenen van beton; staven voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72149939: 'Staven van ijzer of van niet-gelegeerd staal, enkel warmgewalst, warmgetrokken of warmgeperst, bevattende < 0,25 gewichtspercent koolstof, met cirkelvormige dwarsdoorsnede met een grootste diameter van < 80 mm (m.u.v. die van automatenstaal; gladde staven van de soort gebruikt voor het wapenen van beton; staven voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72149950: 'Staven van ijzer of van niet-gelegeerd staal, enkel warmgewalst, warmgetrokken of warmgeperst, bevattende < 0,25 gewichtspercent koolstof (m.u.v. die met een rechthoekige "niet-vierkante" of cirkelvormige doorsnede; die van automatenstaal; gladde staven van de soort gebruikt voor het wapenen van beton; staven voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen)',
    72149971: 'staven van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende >= 0,25 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm (niet na het walsen getordeerd en m.u.v. die voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en die van automatenstaal)',
    72149979: 'staven van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende >= 0,25 gewichtspercent koolstof, met een cirkelvormige dwarsdoorsnede met een diameter van < 80 mm (niet na het walsen getordeerd en m.u.v. die voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en die van automatenstaal)',
    72149995: 'staven van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende >= 0,25 gewichtspercent koolstof (niet na het walsen getordeerd en m.u.v. die met een rechthoekige "niet-vierkante" of cirkelvormige dwarsdoorsnede, die voorzien van inkepingen, ribbels of andere bij het walsen verkregen vervormingen en die van automatenstaal)',
    72151000: 'staven van niet-gelegeerd automatenstaal, enkel door koud bewerken of koud nabewerken verkregen',
    72155011: 'staven van ijzer of van niet-gelegeerd staal, enkel door koud bewerken of koud nabewerken verkregen, bevattende < 0,25 gewichtspercent koolstof, met een rechthoekige dwarsdoorsnede (m.u.v. die van automatenstaal)',
    72155019: 'staven van ijzer of van niet-gelegeerd staal, enkel door koud bewerken of koud nabewerken verkregen, bevattende < 0,25 gewichtspercent koolstof (m.u.v. die van automatenstaal of met een rechthoekige dwarsdoorsnede)',
    72155080: 'staven van ijzer of van niet-gelegeerd staal, enkel door koud bewerken of koud nabewerken verkregen, bevattende >= 0,25 gewichtspercent koolstof (m.u.v. die van automatenstaal)',
    72159000: 'staven van ijzer of van niet-gelegeerd staal, vervaardigd door koud bewerken of koud nabewerken en verder bewerken, of door warm bewerken en verder bewerken, n.e.g.',
    72161000: 'U-, I- of H-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van < 80 mm',
    72162100: 'L-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van < 80 mm',
    72162200: 'T-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van < 80 mm',
    72163110: 'U-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm, doch <= 220 mm',
    72163190: 'U-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 220 mm',
    72163211: 'I-profielen, van ijzer of van niet-gelegeerd staal, met flenzen met evenwijdige vlakken, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm, doch <= 220 mm',
    72163219: 'I-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm, doch <= 220 mm (m.u.v. die met flenzen met evenwijdige vlakken)',
    72163291: 'I-profielen, van ijzer of van niet-gelegeerd staal, met flenzen met evenwijdige vlakken, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 220 mm',
    72163299: 'I-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 220 mm (m.u.v. die met flenzen met evenwijdige vlakken)',
    72163310: 'H-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm, doch <= 180 mm',
    72163390: 'H-profielen van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van > 180 mm',
    72164010: 'L-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm',
    72164090: 'T-profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een lijfhoogte van >= 80 mm',
    72165010: 'profielen, van ijzer of van niet-gelegeerd staal, enkel warm gewalst, warm getrokken of warm geperst, met een dwarsdoorsnede die kan worden omsloten door een vierkant met een zijde van <= 80 mm (m.u.v. U-, I-, H-, L- of T-profielen)',
    72165091: 'Platbulbstaal, enkel warmgewalst, warmgetrokken of warmgeperst',
    72165099: 'Profielen van ijzer of van niet-gelegeerd staal, enkel warmgewalst, warmgetrokken of warmgeperst (m.u.v. die met een dwarsdoorsnede die kan worden omsloten door een vierkant met een zijde van 80 mm; U-, I-, H-, L- of T-profielen; platbulbstaal)',
    72166110: 'C-, L-, U-, Z- en omegaprofielen en open buisprofielen, van ijzer of van niet-gelegeerd staal, vervaardigd door enkel koud bewerken of koud nabewerken van gewalste platte producten',
    72166190: 'profielen, van ijzer of van niet-gelegeerd staal, vervaardigd door enkel koud bewerken of koud nabewerken van gewalste platte producten (m.u.v. C-, L-, U-, Z- en omegaprofielen, open buisprofielen en profielplaten)',
    72166900: 'profielen van ijzer of van niet-gelegeerd staal, enkel door koud bewerken of koud nabewerken verkregen (m.u.v. die welke zijn vervaardigd van gewalste platte producten en m.u.v. profielplaten)',
    72169110: 'profielplaten, van ijzer of van niet-gelegeerd staal, door koud bewerken of door koud nabewerken verkregen',
    72169180: 'profielen van ijzer of van niet-gelegeerd staal, vervaardigd door koud bewerken of door koud nabewerken en verder bewerken van gewalste platte producten (m.u.v. profielplaten)',
    72169900: 'profielen van ijzer of van niet-gelegeerd staal, vervaardigd door koud bewerken of koud nabewerken en verder bewerken of enkel door smeden of door smeden of op andere wijze warm bewerken en verder bewerken, n.e.g. (m.u.v. die welke zijn vervaardigd van gewalste platte producten)',
    72171010: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, onbekleed, ook indien gepolijst, met een grootste afmeting der dwarsdoorsnede van < 0,8 mm (m.u.v. walsdraad)',
    72171031: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, onbekleed, ook indien gepolijst, met een grootste afmeting der dwarsdoorsnede van >= 0,8 mm, voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen (m.u.v. walsdraad)',
    72171039: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, onbekleed, ook indien gepolijst, met een grootste afmeting der dwarsdoorsnede van >= 0,8 mm (m.u.v. die welke is voorzien van inkepingen, verdikkingen, ribbels of andere bij het walsen verkregen vervormingen en m.u.v. walsdraad)',
    72171050: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,25, doch < 0,6 gewichtspercent koolstof, onbekleed, ook indien gepolijst (m.u.v. walsdraad)',
    72171090: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,6 gewichtspercent koolstof, onbekleed, ook indien gepolijst (m.u.v. walsdraad)',
    72172010: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, verzinkt, met een grootste afmeting der dwarsdoorsnede van < 0,8 mm',
    72172030: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, verzinkt, met een grootste afmeting der dwarsdoorsnede van >= 0,8 mm (m.u.v. walsdraad)',
    72172050: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,25, doch < 0,6 gewichtspercent koolstof, verzinkt (m.u.v. walsdraad)',
    72172090: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,6 gewichtspercent koolstof, verzinkt (m.u.v. walsdraad)',
    72173041: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, verkoperd (m.u.v. walsdraad)',
    72173049: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, bekleed met onedele metalen (niet verzinkt of verkoperd en m.u.v. walsdraad)',
    72173050: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,25, doch < 0,6 gewichtspercent koolstof, bekleed met onedele metalen (m.u.v. verzinkt draad en walsdraad)',
    72173090: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,6 gewichtspercent koolstof, bekleed met onedele metalen (m.u.v. verzinkt draad en walsdraad)',
    72179020: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende < 0,25 gewichtspercent koolstof, bekleed (m.u.v. die welke zijn bekleed met onedele metalen en m.u.v. walsdraad)',
    72179050: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,25, doch < 0,6 gewichtspercent koolstof, bekleed (m.u.v. die welke zijn bekleed met onedele metalen en m.u.v. walsdraad)',
    72179090: 'draad van ijzer of van niet-gelegeerd staal, opgerold, bevattende >= 0,6 gewichtspercent koolstof, bekleed (m.u.v. die welke zijn bekleed met onedele metalen en m.u.v. walsdraad)',
    72181000: 'roestvrij staal in ingots of in andere primaire vormen (m.u.v. afvalingots en continu gegoten producten)',
    72189110: 'halffabrikaten van roestvrij staal, met een rechthoekige "niet-vierkante" dwarsdoorsnede, bevattende >= 2,5 gewichtspercenten nikkel',
    72189180: 'halffabrikaten van roestvrij staal, met een rechthoekige "niet-vierkante" dwarsdoorsnede, bevattende < 2,5 gewichtspercenten nikkel',
    72189911: 'halffabrikaten van roestvrij staal, met een vierkante dwarsdoorsnede, gewalst of verkregen door continu-gieten',
    72189919: 'halffabrikaten van roestvrij staal, met een vierkante dwarsdoorsnede, gesmeed',
    72189920: 'halffabrikaten van roestvrij staal, met een cirkelvormige of veelhoekige dwarsdoorsnede, gewalst of verkregen door continu-gieten (m.u.v. die met een rechthoekige dwarsdoorsnede)',
    72189980: 'halffabrikaten van roestvrij staal, gesmeed (m.u.v. die met een veelhoekige dwarsdoorsnede)',
    72191100: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van 10 mm',
    72191210: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van >= 4,75, doch <= 10 mm, bevattende >= 2,5 gewichtspercent nikkel',
    72191290: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van >= 4,75, doch <= 10 mm, bevattende < 2,5 gewichtspercent nikkel',
    72191310: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van >= 3, doch <= 4,75 mm, bevattende >= 2,5 gewichtspercent nikkel',
    72191390: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van >= 3, doch <= 4,75 mm, bevattende < 2,5 gewichtspercent nikkel',
    72191410: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van < 3 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72191490: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold, met een dikte van < 3 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72192110: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van > 10 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72192190: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van > 10 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72192210: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van >= 4,75, doch <= 10 mm, bevattende >= 2,5 gewichtspercent nikkel',
    72192290: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van >= 4,75, doch <= 10 mm, bevattende < 2,5 gewichtspercent nikkel',
    72192300: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van >= 3, doch <= 4,75 mm',
    72192400: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van < 3 mm',
    72193100: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van >= 4,75 mm',
    72193210: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van >= 3, doch < 4,75 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72193290: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van >= 3, doch < 4,75 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72193310: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van > 1, doch < 3 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72193390: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van > 1, doch < 3 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72193410: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van >= 0,5, doch <= 1 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72193490: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van >= 0,5, doch <= 1 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72193510: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van < 0,5 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72193590: 'platte producten van roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst, met een dikte van < 0,5 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72199020: 'Gewalste platte producten van roestvrij staal, met een breedte van >= 600 mm, warm of koud gewalst en verder bewerkt, geperforeerd',
    72199080: 'Gewalste platte producten van roestvrij staal, met een breedte van >= 600 mm, warm of koud gewalst en verder bewerkt, niet-geperforeerd',
    72201100: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel warm gewalst, met een dikte van >= 4,75 mm',
    72201200: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel warm gewalst, met een dikte van < 4,75 mm',
    72202021: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van >= 3 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72202029: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van >= 3 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72202041: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van > 0,35, doch < 3 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72202049: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van > 0,35, doch <= 3 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72202081: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van <= 0,35 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72202089: 'platte producten van roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst, met een dikte van <= 0,35 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72209020: 'Gewalste platte producten van roestvrij staal, met een breedte van < 600 mm, warm of koud gewalst en verder bewerkt, geperforeerd',
    72209080: 'Gewalste platte producten van roestvrij staal, met een breedte van < 600 mm, warm of koud gewalst en verder bewerkt, niet-geperforeerd',
    72210010: 'walsdraad van roestvrij staal, onregelmatig opgerold, bevattende >= 2,5 gewichtspercenten nikkel',
    72210090: 'walsdraad van roestvrij staal, onregelmatig opgerold, bevattende < 2,5 gewichtspercenten nikkel',
    72221111: 'staven van roestvrij staal, n.e.g., enkel warm gewalst, warm getrokken of warm geperst, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72221119: 'staven van roestvrij staal, n.e.g., enkel warm gewalst, warm getrokken of warm geperst, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72221181: 'staven van roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst, met een cirkelvormige dwarsdoorsnede met een diameter van < 80 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72221189: 'staven van roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst, met een cirkelvormige dwarsdoorsnede met een diameter van < 80 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72221910: 'staven van roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende >= 2,5 gewichtspercenten nikkel (m.u.v. die met een cirkelvormige dwarsdoorsnede)',
    72221990: 'staven van roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst, bevattende < 2,5 gewichtspercenten nikkel (m.u.v. die met een cirkelvormige dwarsdoorsnede)',
    72222011: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72222019: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72222021: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter >= 25 mm doch < 80 mm, bevattende >= 2,5 gewichtspercenten nikkel',
    72222029: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter >= 25 mm doch < 80 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72222031: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van < 25 mm, bevatten >= 2,5 gewichtspercenten nikkel',
    72222039: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van < 25 mm, bevattende < 2,5 gewichtspercenten nikkel',
    72222081: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, bevattende >= 2,5 gewichtspercenten nikkel (m.u.v. die met een cirkelvormige dwarsdoorsnede)',
    72222089: 'staven van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, bevattende < 2,5 gewichtspercenten nikkel (m.u.v. die met een cirkelvormige dwarsdoorsnede)',
    72223051: 'staven van roestvrij staal, bevattende >= 2,5 gewichtspercenten nikkel, gesmeed',
    72223091: 'staven van roestvrij staal, bevattende < 2,5 gewichtspercenten nikkel, gesmeed',
    72223097: 'staven van roestvrij staal, verkregen door koud bewerken of koud nabewerken en verder bewerken dan wel door warm bewerken en verder bewerken, n.e.g. (niet gesmeed)',
    72224010: 'profielen van roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst',
    72224050: 'profielen van roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen',
    72224090: 'profielen van roestvrij staal, door koud bewerken of koud nabewerken verkregen en verder bewerkt, of enkel gesmeed of gesmeed of op andere wijze door warm bewerken verkregen en verder bewerkt, n.e.g.',
    72230011: 'draad van roestvrij staal, bevattende >= 28 doch <= 31 gewichtspercenten nikkel en >= 20 doch <= 22 gewichtspercenten chroom, opgerold (m.u.v. walsdraad)',
    72230019: 'draad van roestvrij staal, bevattende >= 2,5 gewichtspercenten nikkel, opgerold (m.u.v. dat bevattende >= 28 doch <= 31 gewichtspercenten nikkel en >= 20 doch <= 22 gewichtspercenten chroom en m.u.v. walsdraad)',
    72230091: 'draad van roestvrij staal, bevattende < 2,5 gewichtspercenten nikkel, >= 13 doch <= 25 gewichtspercenten chroom, >= 3,5 doch <= 6 gewichtspercenten aluminium, opgerold (m.u.v. walsdraad)',
    72230099: 'draad van roestvrij staal, bevattende < 2,5 gewichtspercenten nikkel (m.u.v. dat bevattende >= 13 doch <= 25 gewichtspercenten chroom en >= 3,5 doch <= 6 gewichtspercenten aluminium en m.u.v. walsdraad)',
    72241010: 'ingots en andere primaire vormen, van gereedschapsstaal',
    72241090: 'gelegeerd, niet-roestvrij staal in ingots of in andere primaire vormen (m.u.v. gereedschapsstaal en m.u.v. afvalingots en continu gegoten producten)',
    72249002: 'halffabrikaten van gereedschapsstaal',
    72249003: 'halffabrikaten van sneldraaistaal, warm gewalst of verkregen door continu- gieten, met een vierkante of rechthoekige dwarsdoorsnede en een breedte van < 2 maal de dikte',
    72249005: 'halffabrikaten van staal bevattende <= 0,7 gewichtspercenten koolstof, >= 0,5 doch <= 1,2 gewichtspercenten mangaan en >= 0,6 doch <= 2,3 gewichtspercenten silicium, of van staal bevattende >= 0,0008 gewichtspercenten boor met andere elementen waarvan de hoeveelheden kleiner zijn dan de in aantekening 1, onder f, op hoofdstuk 72 vermelde gehalten, warm gewalst of verkregen door continu-gieten, met een vierkante of rechthoekige dwarsdoorsnede en een breedte van < 2 maal de dikte',
    72249007: 'halffabrikaten van gelegeerd, niet-roestvrij staal, warm gewalst of verkregen door continu-gieten, met een vierkante of rechthoekige dwarsdoorsnede en een breedte van < 2 maal de dikte (m.u.v. die van gereedschapsstaal, van sneldraaistaal en m.u.v. producten bedoeld bij onderverdeling 7224.90.05)',
    72249014: 'halffabrikaten van gelegeerd, niet-roestvrij staal, warm gewalst of verkregen door continu-gieten, met een rechthoekige dwarsdoorsnede en een breedte van >= 2 maal de dikte (m.u.v. die van gereedschapsstaal)',
    72249018: 'halffabrikaten van gelegeerd, niet-roestvrij staal, gesmeed, met een vierkante of rechthoekige dwarsdoorsnede (m.u.v. die van gereedschapsstaal)',
    72249031: 'halffabrikaten van staal bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof en >= 0,5 doch <= 2 gewichtspercenten chroom en indien aanwezig <= 0,5 gewichtspercent molybdeen, warm gewalst of verkregen door continu- gieten (m.u.v. die met een vierkante of rechthoekige dwarsdoorsnede)',
    72249038: 'halffabrikaten van gelegeerd, niet-roestvrij staal, warm gewalst of verkregen door continu-gieten (m.u.v. die van gereedschapsstaal en m.u.v. die met een vierkante of rechthoekige dwarsdoorsnede en die bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof en >= 0,5 doch <= 2 gewichtspercenten chroom en indien aanwezig <= 0,5 gewichtspercent molybdeen)',
    72249090: 'halffabrikaten van gelegeerd, niet-roestvrij staal, gesmeed (m.u.v. die van gereedschapsstaal en m.u.v. die met een vierkante, rechthoekige, cirkelvormige of veelhoekige dwarsdoorsnede)',
    72251100: 'gewalste platte producten van siliciumstaal "transformatorstaal", met een breedte van >= 600 mm, met georiënteerde korrel',
    72251910: 'platte producten van siliciumstaal "transformatorstaal", met een breedte van >= 600 mm, warm gewalst',
    72251990: 'platte producten van siliciumstaal "transformatorstaal", met een breedte van >= 600 mm, koud gewalst, met niet-georiënteerde korrel',
    72253010: 'platte producten van gereedschapsstaal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold',
    72253030: 'Platte producten van sneldraaistaal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold',
    72253090: 'platte producten van gelegeerd niet-roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, opgerold (m.u.v. die van gereedschapsstaal, van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72254012: 'platte producten van gereedschapsstaal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold',
    72254015: 'Platte producten van sneldraaistaal, met een breedte van >= 600 mm, enkel warm gewalst, niet opgerold',
    72254040: 'platte producten van gelegeerd niet-roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van > 10 mm (m.u.v. die van gereedschapsstaal, van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72254060: 'platte producten van gelegeerd niet-roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van >= 4,75, doch <= 10 mm (m.u.v. die van gereedschapsstaal, van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72254090: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van >= 600 mm, enkel warm gewalst, onopgerold, met een dikte van < 4,75 mm (m.u.v. die van gereedschapsstaal, van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72255020: 'Gewalste platte producten van sneldraaistaal, met een breedte van >= 600 mm, enkel koud gewalst',
    72255080: 'Gewalste platte producten van gelegeerd niet-roestvrij staal, met een breedte van >= 600 mm, enkel koud gewalst (m.u.v. die van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72259100: 'Gewalste platte producten van gelegeerd, niet-roestvrij staal, met een breedte van >= 600 mm, warm of koud gewalst, elektrolytisch verzinkt (m.u.v. die van siliciumstaal "transformatorstaal")',
    72259200: 'Gewalste platte producten van gelegeerd, niet-roestvrij staal, met een breedte van >= 600 mm, warm of koud gewalst, verzinkt (niet elektrolytisch verzinkt en m.u.v. die van siliciumstaal "transformatorstaal")',
    72259900: 'Gewalste platte producten van gelegeerd, niet-roestvrij staal, met een breedte van >= 600 mm, warm of koud gewalst en verder bewerkt (niet verzinkt en m.u.v. die van sneldraaistaal of van siliciumstaal "transformator staal")',
    72261100: 'platte producten van siliciumstaal "transformatorstaal", met een breedte van < 600 mm, warm of koud gewalst, met georiënteerde korrel',
    72261910: 'platte producten van siliciumstaal "transformatorstaal", met een breedte van < 600 mm, enkel warm gewalst',
    72261980: 'platte producten van siliciumstaal "transformatorstaal", met een breedte van < 600 mm, koud gewalst, ook indien verder bewerkt, of warm gewalst en verder bewerkt, met niet-georiënteerde korrel',
    72262000: 'platte producten van sneldraaistaal, met een breedte van < 600 mm, warm of koud gewalst',
    72269120: 'platte producten van gereedschapsstaal, met een breedte van < 600 mm, enkel warm gewalst',
    72269191: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, enkel warm gewalst, met een dikte van >= 4,75 mm (m.u.v. die van gereedschapsstaal, van sneldraaistaal of die van siliciumstaal "transformatorstaal")',
    72269199: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, enkel warm gewalst, met een dikte van < 4,75 mm (m.u.v. die van gereedschapsstaal, van sneldraaistaal of die van siliciumstaal "transformatorstaal")',
    72269200: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, enkel koud gewalst (m.u.v. die van sneldraaistaal of die van siliciumstaal "transformatorstaal")',
    72269910: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, warm of koud gewalst, elektrolytisch verzinkt (m.u.v. die van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72269930: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, warm of koud gewalst, verzinkt (niet elektrolytisch verzinkt en m.u.v. die van sneldraaistaal of van siliciumstaal "transformatorstaal")',
    72269970: 'platte producten van gelegeerd, niet-roestvrij staal, met een breedte van < 600 mm, warm of koud gewalst en verder bewerkt (niet verzinkt en m.u.v. die van sneldraaistaal of die van siliciumstaal "transformatorstaal")',
    72271000: 'walsdraad van sneldraaistaal, onregelmatig opgerold',
    72272000: 'walsdraad van siliciummangaanstaal, onregelmatig opgerold',
    72279010: 'walsdraad van staal bevattende >= 0,0008 gewichtspercenten boor "borium" met andere elementen waarvan de hoeveelheden kleiner zijn dan de in aantekening 1, onder f, op hoofdstuk 72 vermelde gehalten, onregelmatig opgerold',
    72279050: 'walsdraad van staal bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof, >= 0,5 doch <= 2 gewichtspercenten chroom en indien aanwezig <= 0,5 gewichtspercent molybdeen, onregelmatig opgerold',
    72279095: 'walsdraad van gelegeerd, niet-roestvrij staal, onregelmatig opgerold (m.u.v. die van sneldraaistaal of van siliciummangaanstaal en die bedoeld bij onderverdeling 7227.90.10 en 7227.90.50)',
    72281020: 'staven van sneldraaistaal, enkel warm gewalst, warm getrokken of warm geperst en warm gewalst, warm getrokken of warm geperst en enkel geplateerd',
    72281050: 'staven van sneldraaistaal, gesmeed',
    72281090: 'staven van sneldraaistaal, enkel door koud bewerken of koud nabewerken verkregen, ook indien verder bewerkt, of door warm bewerken verkregen en verder bewerkt (m.u.v. gesmede staven)',
    72282010: 'staven van siliciummangaanstaal, met een rechthoekige dwarsdoorsnede, aan vier zijden warm gewalst',
    72282091: 'staven van siliciummangaanstaal, enkel warm gewalst, warm getrokken of warm geperst en warm gewalst, warm getrokken of warm geperst en enkel geplateerd (m.u.v. die met een rechthoekige dwarsdoorsnede, aan vier zijden warmgewalst)',
    72282099: 'staven van siliciummangaanstaal, enkel door koud bewerken of koud nabewerken verkregen, ook indien verder bewerkt, of door warm bewerken verkregen en verder bewerkt (m.u.v. die welke warm gewalst, warm getrokken of warm geperst en enkel geplateerd zijn en m.u.v. die met een rechthoekige dwarsdoorsnede, aan vier zijden warmgewalst)',
    72283020: 'staven van gereedschapsstaal, enkel warm gewalst, warm getrokken of warm geperst',
    72283041: 'Staven van staal, bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof en >= 0,5 doch <= 2 gewichtspercenten chroom, ook indien bevattende <= 0,5 gewichtspercent molybdeen, enkel warmgewalst, warmgetrokken of warmgeperst, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm',
    72283049: 'Staven van staal, bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof en >= 0,5 doch <= 2 gewichtspercenten chroom, ook indien bevattende <= 0,5 gewichtspercent molybdeen, enkel warmgewalst, warmgetrokken of warmgeperst (m.u.v. die met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm)',
    72283061: 'Staven van gelegeerd, niet-roestvrij staal, enkel warmgewalst, warmgetrokken of warmgeperst, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm (m.u.v. die van sneldraaistaal, van silicium-mangaanstaal of van gereedschapsstaal; producten bedoeld bij 7228.30.41)',
    72283069: 'Staven van gelegeerd, niet-roestvrij staal, enkel warmgewalst, warmgetrokken of warmgeperst, met een cirkelvormige dwarsdoorsnede met een diameter van < 80 mm (m.u.v. die van sneldraaistaal, van silicium-mangaanstaal of van gereedschapsstaal; producten bedoeld bij 7228.30.49)',
    72283070: 'Staven van gelegeerd, niet-roestvrij staal, met een rechthoekige "niet-vierkante" dwarsdoorsnede, aan vier zijden warmgewalst (m.u.v. die van sneldraaistaal, van silicium-mangaanstaal of van gereedschapsstaal; producten bedoeld bij 7228.30.49)',
    72283089: 'Staven van gelegeerd, niet-roestvrij staal, enkel warmgewalst, warmgetrokken of warmgeperst (m.u.v. die met een rechthoekige "niet-vierkante" dwarsdoorsnede, aan vier zijden gewalst; die met een cirkelvormige dwarsdoorsnede; die uit sneldraaistaal, silicium-mangaanstaal of gereedschapsstaal; producten bedoeld bij 7228.30.49)',
    72284010: 'staven van gereedschapsstaal, enkel gesmeed',
    72284090: 'staven van gelegeerd, niet-roestvrij staal, enkel gesmeed (m.u.v. die van sneldraaistaal, van siliciummangaanstaal of van gereedschapsstaal)',
    72285020: 'staven van gereedschapsstaal, enkel door koud bewerken of koud nabewerken verkregen',
    72285040: 'staven van staal bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof, >= 0,5 doch <= 2 gewichtspercenten chroom en indien aanwezig <= 0,5 gewichtspercent molybdeen, enkel door koud bewerken of koud nabewerken verkregen',
    72285061: 'staven van gelegeerd, niet-roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van >= 80 mm (m.u.v. die van sneldraaistaal, van siliciummangaanstaal of van gereedschapsstaal en die bedoeld bij onderverdeling 7228.50.40)',
    72285069: 'staven van gelegeerd, niet-roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen, met een cirkelvormige dwarsdoorsnede met een diameter van < 80 mm (m.u.v. die van sneldraaistaal, van siliciummangaanstaal of van gereedschapsstaal en die bedoeld bij onderverdeling 7228.50.40)',
    72285080: 'staven van gelegeerd, niet-roestvrij staal, enkel door koud bewerken of koud nabewerken verkregen (m.u.v. die met een cirkelvormige dwarsdoorsnede en m.u.v. die van sneldraaistaal, van siliciummangaanstaal of van gereedschapsstaal en die bedoeld bij onderverdeling 7228.50.40)',
    72286020: 'staven van gereedschapsstaal, door koud bewerken of koud nabewerken verkregen en verder bewerkt of door warm bewerken verkregen en verder bewerkt',
    72286080: 'staven van gelegeerd, niet-roestvrij staal, door koud bewerken of koud nabewerken verkregen en verder bewerkt of door warm bewerken verkregen en verder bewerkt (m.u.v. die van sneldraaistaal, van siliciummangaanstaal of van gereedschapsstaal)',
    72287010: 'profielen van gelegeerd niet-roestvrij staal, enkel warm gewalst, warm getrokken of warm geperst',
    72287090: 'profielen van gelegeerd niet-roestvrij staal, n.e.g. (m.u.v. die welke enkel warm gewalst, warm getrokken of warm geperst zijn)',
    72288000: 'holle staven voor boringen van gelegeerd of van niet-gelegeerd staal',
    72292000: 'draad van siliciummangaanstaal, opgerold (m.u.v. walsdraad)',
    72299020: 'draad van sneldraaistaal, opgerold (m.u.v. walsdraad)',
    72299050: 'draad van staal bevattende >= 0,9 doch <= 1,15 gewichtspercent koolstof, >= 0,5 doch <= 2 gewichtspercenten chroom en indien aanwezig <= 0,5 gewichtspercent molybdeen, opgerold (m.u.v. walsdraad)',
    72299090: 'draad van gelegeerd, niet-roestvrij staal, opgerold (m.u.v. die van sneldraaistaal of van siliciummangaanstaal en die bedoeld bij onderverdeling 7229.90.50 en m.u.v. walsdraad)',
    73011000: 'damwandprofielen van ijzer of van staal, ook indien van gaten voorzien of bestaande uit aaneengezette delen',
    73012000: 'gelaste profielen van ijzer of van staal',
    73021010: 'stroomgeleiders van ijzer of van staal, gedeeltelijk van non-ferrometaal, voor spoorbanen',
    73021022: 'vignolerails van ijzer of van staal, voor spoorbanen, nieuw, wegende >= 36 kg/m',
    73021028: 'vignolerails van ijzer of van staal, voor spoorbanen, nieuw, wegende < 36 kg/m',
    73021040: 'groefrails van ijzer of van staal, voor spoorbanen, nieuw',
    73021050: 'rails, van gietijzer, van ijzer of van staal, voor spoorbanen, nieuw,  (m.u.v. contrarails, vignolerails, groefrails en m.u.v. stroomgeleiders gedeeltelijk van non-ferrometaal)',
    73021090: 'rails, van gietijzer, van ijzer of van staal, voor spoorbanen, gebruikt (m.u.v. contrarails en m.u.v. stroomgeleiders gedeeltelijk van non-ferrometaal)',
    73023000: 'wisseltongen, puntstukken wisselstangen en andere bestanddelen van kruisingen en wissels, van gietijzer, van ijzer of van staal',
    73024000: 'dwarsplaten en onderlegplaten, van gietijzer, van ijzer of van staal, voor spoorbanen',
    73029000: 'dwarsliggers, contrarails, heugels voor tandradbanen, spoorstoelen, wiggen, klemplaten, dwarsplaten en dwarsstangen en andere bestanddelen, voor het leggen, het verbinden of het bevestigen van rails, van gietijzer, van ijzer of van staal (m.u.v. rails, dwarsliggers, wisseltongen, puntstukken, wisselstangen en andere bestanddelen van kruisingen en wissels, lasplaten en onderlegplaten)',
    73030010: 'buizen en pijpen, van de soort gebruikt voor drukleidingen, van gietijzer',
    73030090: 'buizen, pijpen en holle profielen, van gietijzer (m.u.v. buizen en pijpen, van de soort gebruikt voor drukleidingen)',
    73041100: 'Buizen en pijpen, naadloos, van de soort gebruikt voor olie- of gasleidingen, van roestvrij staal',
    73041910: 'Buizen en pijpen, naadloos, van ijzer of van staal, van de soort gebruikt voor olie- of gasleidingen met een uitwendige diameter van <= 168,3 mm (m.u.v. producten van roestvrij staal)',
    73041930: 'Buizen en pijpen, naadloos, van ijzer of van staal, van de soort gebruikt voor olie- of gasleidingen met een uitwendige diameter van > 168,3 mm, doch <= 406,4 mm (m.u.v. die van roestvrij staal)',
    73041990: 'Buizen en pijpen, naadloos, van ijzer of van staal, van de soort gebruikt voor olie- of gasleidingen met een uitwendige diameter van > 406,4 mm (m.u.v. die van roestvrij staal)',
    73042200: 'Boorpijpen "drill pipes", naadloos, van roestvrij staal, van de soort gebruikt bij de olie- of gaswinning',
    73042300: 'Boorpijpen "drill pipes", naadloos, van ijzer of staal, van de soort gebruikt bij de olie- of gaswinning (m.u.v. die van roestvrij staal)',
    73042400: 'Bekledingsbuizen "casings" en exploitatiebuizen "tubings", naadloos, van roestvrij staal, van de soort gebruikt bij de olie- of gaswinning',
    73042910: 'Bekledingsbuizen "casings" en exploitatiebuizen "tubings", naadloos, van ijzer of van staal, van de soort gebruikt bij de olie- of gaswinning, met een uitwendige diameter van <= 168,3 mm (m.u.v. die van gietijzer)',
    73042930: 'Bekledingsbuizen "casings" en exploitatiebuizen "tubings", naadloos, van ijzer of van staal, van de soort gebruikt bij de olie- of gaswinning, met een uitwendige diameter  van > 168,3 mm, doch <= 406,4 mm (m.u.v. die van gietijzer)',
    73042990: 'bekledingsbuizen "casings" en exploitatiebuizen "tubings", naadloos, van ijzer of van staal, van de soort gebruikt bij de olie- of gaswinning, met een uitwendige diameter > 406,4 mm',
    73043120: 'Precisiebuizen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, koud getrokken of koud gewalst (m.u.v. buizen en pijpen van de soort gebruikt voor olie- of gasleidingen of van de soort gebruikt bij de olie- of gaswinning)',
    73043180: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, koud getrokken of koud gewalst (m.u.v. buizen en pijpen voor olie- of voor gasleidingen of gebruikt bij de olie- of gaswinning en precisiebuizen)',
    73043910: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, onbewerkt, recht en op alle plaatsen van gelijke wanddikte, uitsluitend bestemd om te worden verwerkt tot buizen en pijpen van ander profiel of van andere wanddikte (m.u.v. koud getrokken of koud gewalste buizen, pijpen en holle profielen)',
    73043952: 'Buizen met schroefdraad of van schroefdraad te voorzien, gasbuizen genoemd, naadloos, van ijzer of van niet-gelegeerd staal, verzinkt',
    73043958: 'Buizen met schroefdraad of van schroefdraad te voorzien, gasbuizen genoemd, naadloos, van ijzer of van niet-gelegeerd staal, niet-verzinkt',
    73043992: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, niet koud-getrokken of niet koud gewalst, met een uitwendige diameter van <= 168, 3 mm (m.u.v. gietijzeren producten; buizen, pijpen voor olie- of gasleidingen; buizen en pijpen gebruikt bij de olie- of gaswinning; buizen, pijpen en holle profielen bedoeld bij onderverdeling 7304.39.30 tot en met 7304.39.58)',
    73043993: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, niet koud getrokken of niet koud gewalst, met een uitwendige diameter van > 168,3 mm, doch <= 406,6 mm (m.u.v. gietijzeren producten, buizen en pijpen van de soort gebruikt voor olie- of gasleidingen, bekledingsbuizen, exploitatiebuizen en boorpijpen van de soort gebruikt bij de olie- en gaswinning; buizen, pijpen en holle profielen bedoeld bij onderverdeling 7304.39.10 tot en met 7304.39.58)',
    73043998: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van ijzer of van niet-gelegeerd staal, met een uitwendige diameter van > 406,6 mm (m.u.v. koud getrokken of koud gewalste producten; producten van gietijzer; buizen, pijpen voor olie- of gasleidingen; buizen en pijpen gebruikt bij de olie- of gaswinning; buizen, pijpen en holle profielen bedoeld bij onderverdeling 7304.39.52 en 7304.39.58)',
    73044100: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van roestvrij staal, koud getrokken of koud gewalst (m.u.v. die van de soort gebruikt voor olie- of gasleidingen; of van de soort gebruikt bij de olie- of gaswinning)',
    73044910: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van roestvrij staal, onbewerkt, recht en op alle plaatsen van gelijke wanddikte, uitsluitend bestemd om te worden verwerkt tot buizen en pijpen van ander profiel of van andere wanddikte (m.u.v. koud getrokken of koud gewalste producten)',
    73044993: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van roestvrij staal, met een uitwendige diameter van <= 168,3 mm (m.u.v. koud getrokken of koud gewalste producten; buizen en pijpen voor olie- of gasleidingen; buizen en pijpen voor gebruik bij de olie- of gaswinning; producten bedoeld bij de onderverdelingen 7304 49 10)',
    73044995: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van roestvrij staal, met een uitwendige diameter van > 168,3 mm doch <= 406,4 mm (m.u.v. koud getrokken of koud gewalste producten; buizen en pijpen voor olie- of gasleidingen; buizen en pijpen voor gebruik bij de olie- of gaswinning; producten bedoeld bij de onderverdelingen 7304 49 10)',
    73044999: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van roestvrij staal, met een uitwendige diameter van > 406,4 mm (m.u.v. koud getrokken of koud gewalste producten; buizen en pijpen voor olie- of gasleidingen; buizen en pijpen voor gebruik bij de olie- of gaswinning; producten bedoeld bij de onderverdelingen 7304.49.10 en 7304.49.30)',
    73045112: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, koud getrokken of koud gewalst, recht en op alle plaatsen van gelijke wanddikte, van gelegeerd staal bevattende >= 0,9, doch <= 1,15 gewichtspercent koolstof, >= 0,5, doch <= 2 gewichtspercenten chroom, evtl. <= 0,5 gewichtspercent molybdeen, met een lengte van <= 0,5 m (m.u.v. buizen, pijpen en holleprofielen bedoeld bij de onderverdelingen 7304 19 en 7304 29)',
    73045118: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, koud getrokken of koud gewalst, recht en op alle plaatsen van gelijke wanddikte, van gelegeerd staal bevattende >= 0,9, doch <= 1,15 gewichtspercent koolstof, >= 0,5, doch <= 2 gewichtspercenten chroom, evtl. <= 0,5 gewichtspercent molybdeen, met een lengte van > 0,5 m (m.u.v. die van roestvrij staal; die bedoeld bij de onderverdelingen 7304 19 en 7304 29)',
    73045181: 'Precisiebuizen, naadloos, met rond profiel, van gelegeerd staal, koud getrokken of koud gewalst (m.u.v. die van roestvrij staal; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; buizen en holle profielen recht en op alle plaatsen van gelijke wanddikte, bevattende >= 0,9% en <1,15% koolstof, >=0,5% en < 2% chroom en <= 0,5% molybdeen)',
    73045189: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van gelegeerd staal, koud getrokken of koud gewalst (m.u.v. die van roestvrij staal; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; buizen en holle profielen recht en op alle plaatsen van gelijke wanddikte, bevattende >= 0,9% en <1,15% koolstof, >=0,5% en < 2% chroom en <= 0,5% molybdeen)',
    73045910: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van gelegeerd staal, onbewerkt, recht en op alle plaatsen van gelijke wanddikte, uitsluitend bestemd om te worden verwerkt tot buizen en pijpen van ander profiel of van andere wanddikte (m.u.v. die van roestvrij staal, koud getrokken of koud gewalste producten)',
    73045932: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, recht en op alle plaatsen van gelijke wanddikte, van gelegeerd staal bevattende >= 0,9, doch <= 1,15 gewichtspercent koolstof, >= 0,5, doch <= 2 gewichtspercenten chroom, evtl. <= 0,5 gewichtspercent molybdeen, met een lengte van <= 0,5 m (m.u.v. buizen, pijpen en holle profielen bedoeld bij de onderverdelingen 7304 19, 7304 29 en 7304 59 10)',
    73045938: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, recht en op alle plaatsen van gelijke wanddikte, van gelegeerd staal bevattende >= 0,9, doch <= 1,15 gewichtspercent koolstof, >= 0,5, doch <= 2 gewichtspercenten chroom, evtl. <= 0,5 gewichtspercent molybdeen, met een lengte van > 0,5 m (m.u.v. buizen, pijpen en holle profielen bedoeld bij de onderverdelingen 7304 19, 7304 29 en 7304 59 10)',
    73045992: 'Buizen, pijpen en holle profielen, naadloos, met rond profiel, van gelegeerd staal, met een uitwendige diameter van <= 168,3 mm (m.u.v. die van roestvrij staal; koud getrokken of koud gewalste producten; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; die bedoeld bij de onderverdelingen 7304.59.10 tot en met 7304.59.38)',
    73045993: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van gelegeerd staal, met een uitwendige diameter van > 168,3 mm, doch <= 406,4 mm (m.u.v. die van roestvrij staal; koud getrokken of koud gewalste producten; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; die bedoeld bij de onderverdelingen 7304.59.10 tot en met 74045950)',
    73045999: 'buizen, pijpen en holle profielen, naadloos, met rond profiel, van gelegeerd staal, met een uitwendige diameter van > 406,4 mm (m.u.v. die van roestvrij staal; koud getrokken of koud gewalste producten; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; die bedoeld bij de onderverdelingen 7304.59.10 tot en met 74045950)',
    73049000: 'Buizen, pijpen en holle profielen, naadloos, van ijzer of van staal, (m.u.v. die met rond profiel)',
    73051100: 'buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, van ijzer of van staal, overlangs gelast, door booglassen onder poederdek',
    73051200: 'buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, van ijzer of van staal, overlangs gelast (niet door booglassen onder poederdek)',
    73051900: 'buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, vervaardigd van platte gewalste producten van ijzer of van staal (niet overlangs gelast)',
    73052000: 'bekledingsbuizen "casings", van de soort gebruikt bij de olie- of gaswinning, met een rond profiel en met een uitwendige diameter van > 406,4 mm, vervaardigd van platte gewalste producten van ijzer of van staal',
    73053100: 'buizen en pijpen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, van ijzer of van staal, overlangs gelast (m.u.v. die van de soort gebruikt voor olie- of gasleidingen of bij de olie- of gaswinning)',
    73053900: 'buizen en pijpen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, van ijzer of van staal, gelast (niet overlangs gelast en m.u.v. die van de soort gebruikt voor olie- of gasleidingen of bij de olie- of gaswinning)',
    73059000: 'buizen en pijpen, met een rond profiel en met een uitwendige diameter van > 406,4 mm, vervaardigd van platte gewalste producten van ijzer of van staal (niet gelast en m.u.v. die van de soort gebruikt voor olie- of gasleidingen of bij de olie- of gaswinning)',
    73061110: 'Buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, overlangs gelast, van roestvrij staal, met een uitwendige diameter van <= 406,4 mm',
    73061190: 'Buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, spiraalvormig gelast, van roestvrij staal, met een uitwendige diameter van <= 406.4 mm',
    73061910: 'Buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, van ijzer of van staal, overlangs gelast (m.u.v. die van roestvrij staal of van gietijzer)',
    73061990: 'Buizen en pijpen, van de soort gebruikt voor olie- of gasleidingen, van ijzer of van staal, spiraalvormig gelast, met een uitwendige diameter van <= 406,4 mm (m.u.v. die van roestvrij staal of van gietijzer)',
    73062100: 'Bekledingsbuizen "casings" en exploitatiebuizen "tubings", van de soort gebruikt bij de olie- of gaswinning, gelast, vervaardigd van platte, gewalste producten van roestvrij staal, met een uitwendige diameter <= 406,4 mm',
    73062900: 'Bekledingsbuizen "casings" en exploitatiebuizen "tubings", van de soort gebruikt bij de olie- of gaswinning, vervaardigd van platte, gewalste producten van ijzer of van staal, met een uitwendige diameter <= 406,4 mm (m.u.v. die van roestvrij staal of van gietijzer)',
    73063011: 'Precisiebuizen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, met wanddikte van <= 2 mm',
    73063019: 'Precisiebuizen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, met wanddikte van > 2 mm',
    73063041: 'Buizen met schroefdraad of van schroefdraad te voorzien, zgn. gasbuizen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, verzinkt',
    73063049: 'Buizen met schroefdraad of van schroefdraad te voorzien, zgn. gasbuizen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal (m.u.v. verzinkte producten)',
    73063072: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, met een uitwendige diameter van <= 168,3 mm, verzinkt (m.u.v. die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; precisiebuizen, buizen met schroefdraad of van schroefdraad te voorzien, zogenaamde gasbuizen)',
    73063077: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, met een uitwendige diameter van <= 168,3 mm (m.u.v. verzinkte producten;  die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; precisiebuizen, buizen met schroefdraad of van schroefdraad te voorzien, zogenaamde gasbuizen)',
    73063080: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van ijzer of van niet-gelegeerd staal, met een uitwendige diameter van > 168,3 mm, doch <= 406,4 mm (m.u.v. die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; precisiebuizen, buizen met schroefdraad of van schroefdraad te voorzien)',
    73064020: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van roestvrij staal, koud getrokken of koud gewalst (m.u.v. die rond inwendig en uitwendig profiel en met een uitwendige diameter van > 406,4 mm; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning)',
    73064080: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van roestvrij staal (m.u.v. koud getrokken of koud gewalste producten; die met een rond inwendig en uitwendig profiel en met een uitwendige diameter van > 406,4 mm; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning)',
    73065020: 'Precisiebuizen, gelast, met rond profiel, van gelegeerd staal (m.u.v. die van roestvrij staal)',
    73065080: 'Buizen, pijpen en holle profielen, gelast, met rond profiel, van gelegeerd staal (m.u.v. die van roestvrij staal; die met een rond inwendig en uitwendig profiel en met een uitwendige diameter van > 406,4 mm; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning; precisiebuizen)',
    73066110: 'Buizen, pijpen en holle profielen, gelast, met vierkant of rechthoekig profiel van roestvrij staal',
    73066192: 'Buizen, pijpen en holle profielen, gelast, met vierkant of rechthoekig profiel, met een wanddikte van <= 2 mm, van ijzer of van niet-roestvrij staal',
    73066199: 'Buizen, pijpen en holle profielen, gelast, met vierkant of rechthoekig profiel, met een wanddikte van > 2 mm, van ijzer of van niet-roestvrij staal',
    73066910: 'Buizen, pijpen en holle profielen, gelast, van roestvrij staal (m.u.v. die met rond, vierkant of rechthoekig profiel ; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning)',
    73066990: 'Buizen, pijpen en holle profielen, gelast, van ijzer of van niet-roestvrij staal (m.u.v. die met rond, vierkant of rechthoekig profiel; die van de soort gebruikt voor olie- of gasleidingen; die van de soort gebruikt bij de olie- of gaswinning)',
    73069000: 'buizen, pijpen en holle profielen, geklonken, genageld, gefelst, enz, van ijzer of van staal (m.u.v. gelaste producten; die met rond, vierkant of rechthoekig profiel, die met een rond inwendig en uitwendig profiel en met een uitwendige diameter van > 406,4 mm)',
    73071110: 'hulpstukken "fittings", van niet smeedbaar gietijzer, voor buizen en pijpen, van de soort gebruikt voor drukleidingen',
    73071190: 'hulpstukken "fittings", voor buisleidingen, van niet smeedbaar gietijzer (m.u.v. die van de soort gebruikt voor drukleidingen)',
    73071910: 'hulpstukken "fittings", voor buisleidingen, van smeedbaar gietijzer',
    73071990: 'hulpstukken "fittings", voor buisleidingen, van gietstaal',
    73072100: 'flensen voor buisleidingen, van roestvrij staal (m.u.v. gietstaal)',
    73072210: 'moffen voor buisleidingen, van roestvrij staal, met schroefdraad (m.u.v. gegoten producten)',
    73072290: 'ellebogen en bochten voor buisleidingen, van roestvrij staal, met schroefdraad (m.u.v. gegoten producten)',
    73072310: 'ellebogen en bochten voor buisleidingen, door stomplassen te bevestigen, van roestvrij staal (m.u.v. die van gietstaal)',
    73072390: 'hulpstukken voor buisleidingen, door stomplassen te bevestigen, van roestvrij staal (m.u.v. die van gietstaal; ellebogen en bochten voor buisleidingen)',
    73072910: 'hulpstukken "fittings" voor buisleidingen, met schroefdraad, van roestvrij staal (m.u.v. die van gietstaal; flensen; ellebogen, bochten en moffen)',
    73072980: 'hulpstukken "fittings" voor buisleidingen, van roestvrij staal (m.u.v. die van gietstaal; producten met schroefdraad;  producten door stomplassen te bevestigen en flenzen)',
    73079100: 'flensen voor buisleidingen, van ijzer of van staal (m.u.v. gegoten producten; producten van roestvrij staal)',
    73079210: 'moffen voor buisleidingen, van gietijzer, van ijzer of van staal, met schroefdraad (m.u.v. gegoten producten en producten van roestvrij staal)',
    73079290: 'ellebogen en bochten voor buisleidingen, van gietijzer, van ijzer of van staal, met schroefdraad (m.u.v. gegoten producten en producten van roestvrij staal)',
    73079311: 'ellebogen en bochten voor buisleidingen, door stomplassen te bevestigen, van ijzer of van staal, met een grootste uitwendige diameter van <= 609,6 mm (m.u.v. gegoten producten; producten van roestvrij staal)',
    73079319: 'hulpstukken "fittings" voor buisleidingen, door stomplassen te bevestigen, van ijzer of van staal, met een grootste uitwendige diameter van <= 609,6 mm (m.u.v. gegoten producten; producten van roestvrij staal; ellebogen, bochten en flensen)',
    73079391: 'ellebogen en bochten voor buisleidingen, door stomplassen te bevestigen, van ijzer of van staal, met een grootste uitwendige diameter van > 609,6 mm (m.u.v. gegoten producten; producten van roestvrij staal)',
    73079399: 'hulpstukken "fittings" voor buisleidingen, door stomplassen te bevestigen, van ijzer of van staal, met een grootste uitwendige diameter van > 609,6 mm (m.u.v. gegoten producten; producten van roestvrij staal; ellebogen, bochten en flensen)',
    73079910: 'hulpstukken "fittings" voor buisleidingen, van ijzer of van staal, met schroefdraad (m.u.v. gegoten producten; producten van roestvrij staal; flensen; ellebogen, bochten en moffen)',
    73079980: 'hulpstukken "fittings" voor buisleidingen, van ijzer of van staal (m.u.v. gegoten producten; producten van roestvrij staal; producten met schroefdraad; producten door stomplassen te bevestigen; flenzen)',
    73081000: 'bruggen en brugdelen, van gietijzer, van ijzer of van staal',
    73082000: 'vakwerkmasten en andere masten, van gietijzer, van ijzer of van staal',
    73083000: 'deuren en ramen, alsmede kozijnen daarvoor, drempels, van gietijzer, van ijzer of van staal',
    73084000: 'steiger-, bekistings-, steun- en stutmateriaal, van gietijzer, van ijzer of van staal',
    73089051: 'gelaagde profielplaten, van gietijzer, van ijzer of van staal, met isolerende tussenlaag',
    73089059: 'constructiewerken en delen van constructiewerken, van gietijzer, van ijzer of van staal, geheel of hoofdzakelijk bestaande uit platen, n.e.g. (m.u.v. deuren en ramen, alsmede kozijnen daarvoor, drempels; gelaagde profielplaten, met isolerende tussenlaag)',
    73089098: 'constructiewerken en delen van constructiewerken, van gietijzer, van ijzer of van staal, n.e.g. (m.u.v. bruggen, brugdelen, vakwerk- masten en andere masten, deuren, ramen, alsmede kozijnen daarvoor en drempels; steiger-, bekistings-, steun- en stutmateriaal;  die geheel of hoofdzakelijk bestaande uit platen)',
    73090010: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor gassen, van gietijzer, van ijzer of van staal, met een inhoudsruimte van > 300 l (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen, met een mechanische inrichting of met een inrichting om te koelen of te warmen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73090030: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor vloeistoffen, van gietijzer, van ijzer of van staal, inwendig bekleed of voorzien van een warmte-isolerende bekleding, met een inhoudsruimte van > 300 l (m.u.v. die met een mechanische inrichting of met een inrichting om te koelen of te warmen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73090051: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor vloeistoffen, van gietijzer, van ijzer of van staal, met een inhoudsruimte van > 100.000 l (m.u.v. die inwendig bekleed of voorzien van een warmte-isolerende bekleding; die met een mechanische inrichting of met een inrichting om te koelen of te warmen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welke vervoermiddel)',
    73090059: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor vloeistoffen, van gietijzer, van ijzer of van staal, met een inhoudsruimte van > 300 l, doch <= 100.000 l (m.u.v. die inwendig bekleed of voorzien van een warmte-isolerende bekleding; die met een mechanische inrichting of een inrichting om te koelen of te warmen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73090090: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor vaste stoffen, van gietijzer, van ijzer of van staal, met een inhoudsruimte van > 300 l (m.u.v. die inwendig bekleed of voorzien van een warmte-isolerende bekleding; die met een mechanische inrichting of een inrichting om te koelen of te warmen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73101000: 'reservoirs, fusten, trommels, bussen, blikken e.d. bergingsmiddelen, voor ongeacht welke goederen, met een inhoudsruimte van >= 50 l, doch <= 300 l, n.e.g. (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen; die met een mechanische inrichting of een inrichting om te koelen of te warmen)',
    73102111: 'blikken van ijzer of van staal, met een inhoudsruimte van < 50 l, die door solderen of door felsen worden gesloten, van de soort gebruikt voor voedingsmiddelen',
    73102119: 'blikken van ijzer of van staal, met een inhoudsruimte van < 50 l, die door solderen of door felsen worden gesloten, van de soort gebruikt voor dranken',
    73102191: 'blikken, van gietijzer, van ijzer of van staal, met een inhoudsruimte van < 50 l, die door solderen of door felsen worden gesloten, met een wanddikte van < 0,5 mm (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen, conservenblikken van de soort gebruikt voor dranken en voedingsmiddelen)',
    73102199: 'blikken, van gietijzer, van ijzer of van staal, met een inhoudsruimte van < 50 l, die door solderen of door felsen worden gesloten, met een wanddikte van >= 0,5 mm (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen, conservenblikken van de soort gebruikt voor dranken en voedingsmiddelen)',
    73102910: 'reservoirs, fusten, trommels, bussen, blikken e.d. bergingsmiddelen, van gietijzer, van ijzer of van staal, voor ongeacht welke goederen, met een inhoudsruimte van < 50 l en met een wanddikte van < 0,5 mm, n.e.g. (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen; blikken)',
    73102990: 'reservoirs, fusten, trommels, bussen, blikken e.d. bergingsmiddelen, van gietijzer, van ijzer of van staal, voor ongeacht welke goederen, met een inhoudsruimte van < 50 l en met een wanddikte van >= 0,5 mm, n.e.g. (m.u.v. die voor gecomprimeerde of vloeibaar gemaakte gassen; die met een mechanische inrichting of een inrichting om te koelen of te warmen; blikken)',
    73110011: 'Bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, naadloos, voor een druk van >=165 bar en met een inhoud van < 20 l (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73110013: 'Bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, naadloos, voor een druk van >=165 bar en met een inhoud van >= 20 l doch <= 50 l (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73110019: 'Bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, naadloos, voor een druk van >=165 bar en met een inhoud van > 50 l (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73110030: 'Bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, naadloos, voor een druk van < 165 bar (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73110091: 'bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, met een inhoud van < 1.000 l (m.u.v. naadloze bergingsmiddelen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73110099: 'bergingsmiddelen voor gecomprimeerd of vloeibaar gemaakt gas, van gietijzer, van ijzer of van staal, met een inhoud van >= 1.000 l (m.u.v. naadloze bergingsmiddelen; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73121020: 'Kabels en strengen, van roestvrij staal (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121041: 'Kabels en strengen, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van <= 3 mm, bekleed met koper-zinklegeringen (messing of geel koper) (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121049: 'Kabels en strengen, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van <= 3 mm (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad en die bekleed met koper-zinklegeringen (messing of geel koper))',
    73121061: 'Strengen, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 3 mm, onbekleed (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121065: 'Strengen, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 3 mm, verzinkt (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121069: 'Strengen, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 3 mm, bekleed (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad; prikkeldraad; verzinkte strengen)',
    73121081: 'Kabels, incl. gesloten kabels, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 3 doch <= 12 mm, onbekleed of enkel verzinkt (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121083: 'Kabels, incl. gesloten kabels, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 12 doch <= 24 mm, onbekleed of enkel verzinkt (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121085: 'Kabels, incl. gesloten kabels, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 24 doch <= 48 mm, onbekleed of enkel verzinkt (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121089: 'Kabels, incl. gesloten kabels, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 48 mm, onbekleed of enkel verzinkt (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad)',
    73121098: 'Kabels, inclusief gesloten kabels, van ijzer of van niet-roestvrij staal, met een grootste afmeting der dwarsdoorsnede van > 3 mm, bekleed, (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit; samengedraaid afrasteringsdraad en prikkeldraad en verzinkte kabels)',
    73129000: 'Lengen e.d. artikelen, van ijzer of van staal  (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    73130000: 'prikkeldraad e.d. afrasteringsmateriaal, bestaande uit getorste draden of uit strippen, al dan niet voorzien van punten, stekels of tanden, van ijzer of van staal',
    73141200: 'eindeloos metaaldoek voor machines, van roestvrij staal',
    73141400: 'geweven metaaldoek, incl. eindeloos metaaldoek, van roestvrij staal (m.u.v. weefsels van metaaldraad van de soort gebruikt voor kleding, voor stoffering en voor dergelijk gebruik en m.u.v. eindeloos metaaldoek voor machines)',
    73141900: 'Geweven metaaldoek, incl. eindeloos metaaldoek, van ijzerdraad of van staaldraad (niet van roestvrij staal, m.u.v. weefsels van metaaldraad van de soort gebruikt voor kleding, voor stoffering en voor dergelijk gebruik)',
    73142010: 'Metaalgaas en traliewerk, op de kruispunten gelast, van geribde ijzer- of staaldraad waarvan de grootste afmeting der dwarsdoorsnede >= 3 mm bedraagt en met een maas van >= 100 cm²',
    73142090: 'Metaalgaas en traliewerk, op de kruispunten gelast, van ijzer- of staaldraad waarvan de grootste afmeting der dwarsdoorsnede >= 3 mm bedraagt en met een maas van >= 100 cm² (m.u.v. die van geribde draad)',
    73143100: 'metaalgaas en traliewerk, van ijzerdraad of van staaldraad, op de kruispunten gelast, verzinkt (m.u.v. dat van draad waarvan de grootste afmeting der dwarsdoorsnede >= 3 mm bedraagt en met een maas van >= 100 cm²)',
    73143900: 'metaalgaas en traliewerk, van ijzerdraad of van staaldraad, op de kruispunten gelast (niet verzinkt en m.u.v. dat van draad waarvan de grootste afmeting der dwarsdoorsnede >= 3 mm bedraagt en met een maas van >= 100 cm²)',
    73144100: 'metaaldoek, metaalgaas en traliewerk, van ijzerdraad of van staaldraad, verzinkt (niet op de kruispunten gelast)',
    73144200: 'metaaldoek, metaalgaas en traliewerk, van ijzerdraad of van staaldraad, bekleed met kunststof (niet op de kruispunten gelast)',
    73144900: 'metaaldoek, metaalgaas en traliewerk, van ijzerdraad of van staaldraad (niet verzinkt, bekleed met kunststof of op de kruispunten gelast)',
    73145000: 'plaatgaas verkregen door het uitrekken van plaatijzer, plaatstaal, bandijzer of bandstaal',
    73151110: 'rolkettingen, van gietijzer, van ijzer of van staal, van de soort gebruikt voor rijwielen of voor motorrijwielen',
    73151190: 'rolkettingen, van gietijzer, van ijzer of van staal (m.u.v. die van de soort gebruikt voor rijwielen of voor motorrijwielen)',
    73151200: 'scharnierende kettingen, van gietijzer, van ijzer of van staal (m.u.v. rolkettingen)',
    73151900: 'delen van scharnierende kettingen, van gietijzer, van ijzer of van staal',
    73152000: 'sneeuwkettingen, voor automobielen, van gietijzer, van ijzer of van staal',
    73158100: 'damkettingen, van gietijzer, van ijzer of van staal',
    73158200: 'kettingen, van gietijzer, van ijzer of van staal, met gelaste schakels (m.u.v. scharnierende ketttingen, sneeuwkettingen, damkettingen en m.u.v. veiligheidskettingen voor het sluiten van deuren)',
    73158900: 'kettingen, van gietijzer, van ijzer of van staal (m.u.v. scharnierende kettingen, sneeuwkettingen, damkettingen, kettingen met gelaste schakels, alsmede delen daarvan; horlogekettingen, kettingen voor bijouterieën, enz.; kettingzagen, kettingfrezen; sneeuwkettingen; transportkettingen; kettingen met naalden of pennetjes voor de textielindustrie enz.; veiligheidskettingen voor het sluiten van deuren; landmeterskettingen)',
    73159000: 'delen van sneeuwkettingen, damkettingen en andere kettingen bedoeld bij post 7315 (m.u.v. die van scharnierende kettingen)',
    73160000: 'ankers, dreggen en delen daarvan van gietijzer, van ijzer of van staal',
    73170020: 'nagels en spijkers, van ijzerdraad of van staaldraad, in strippen, ook indien op rollen',
    73170060: 'draadnagels, spijkers, aangepunte krammen, gegolfde krambanden e.d., van ijzerdraad of van staaldraad (m.u.v. nagels en spijkers in strippen of op rollen, en hechtnieten in strippen)',
    73170080: 'spijkers, punaises, aangepunte krammen, gegolfde krambanden e.d. artikelen, van gietijzer, van ijzer of van staal (m.u.v. die van draad, en hechtnieten in strippen)',
    73181100: 'kraagschroeven, van gietijzer, van ijzer of van staal',
    73181210: 'houtschroeven, van roestvrij staal (m.u.v. kraagschroeven; oogschroeven en schroefhaken)',
    73181290: 'houtschroeven, van gietijzer, van ijzer of van niet-roestvrij staal (m.u.v. kraagschroeven; oogschroeven en schroefhaken)',
    73181300: 'oogschroeven en schroefhaken, van gietijzer, van ijzer of van staal',
    73181410: 'schroeven, zelftappend, van roestvrij staal (m.u.v. houtschroeven)',
    73181491: 'plaatschroeven, zelftappend, van gietijzer, van ijzer of van niet-roestvrij staal',
    73181499: 'schroeven, zelftappend, van gietijzer, van ijzer of van niet-roestvrij staal (m.u.v. plaatschroeven, houtschroeven)',
    73181520: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van staal, ook indien met bijbehorende moeren of sluitringen, voor het bevestigen van bestanddelen van spoorbanen (m.u.v. kraagschroeven)',
    73181535: 'schroeven en bouten, met schroefdraad, van roestvrij staal, ook indien met bijbehorende moeren of sluitringen, zonder kop (m.u.v schroeven en bouten, voor het bevestigen van bestanddelen van spoorbanen)',
    73181542: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van niet-roestvrij staal, ook indien met bijbehorende moeren of sluitringen, zonder kop, met een trekvastheid van < 800 MPa (m.u.v. schroeven en bouten, voor het bevestigen van bestanddelen van spoorbanen)',
    73181548: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van niet-roestvrij staal, ook indien met bijbehorende moeren of sluitringen, zonder kop, met een trekvastheid van >= 800 MPa (m.u.v. schroeven en bouten, voor het bevestigen van bestanddelen van spoorbanen)',
    73181552: 'schroeven en bouten, van roestvrij staal, ook indien met bijhorende moeren of sluitringen, met kop, met gleuf of kruisgleuf (m.u.v. houtschroeven en zelftappende schroeven)',
    73181558: 'schroeven en bouten, van ijzer of van niet-roestvrij staal, ook indien met bijhorende moeren of sluitringen, met kop, met gleuf of kruisgleuf (m.u.v. houtschroeven en zelftappende schroeven)',
    73181562: 'schroeven en bouten, van roestvrij staal, ook indien met bijhorende moeren of sluitringen, met binnenzeskant (m.u.v. houtschroeven en zelftappende schroeven en m.u.v.  schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen)',
    73181568: 'schroeven en bouten, van ijzer of van niet-roestvrij staal, ook indien met bijhorende moeren of sluitringen, met binnenzeskant (m.u.v. houtschroeven en zelftappende schroeven en m.u.v.  schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen)',
    73181575: 'schroeven en bouten, met schroefdraad, van roestvrij staal, ook indien met bijbehorende moeren of sluitringen, met zeskantkop (m.u.v. inbusbouten, houtschroeven, zelftappende schroeven; schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen)',
    73181582: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van niet-roestvrij staal, ook indien met bijbehorende moeren of sluitringen, met zeskantkop, met een trekvastheid van < 800 MPa (m.u.v. inbusbouten, houtschroeven, zelftappende schroeven; schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen)',
    73181588: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van niet-roestvrij staal, ook indien met bijbehorende moeren of sluitringen, met zeskantkop, met een trekvastheid van >= 800 MPa (m.u.v. inbusbouten,  houtschroeven, zelftappende schroeven; schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen)',
    73181595: 'schroeven en bouten, met schroefdraad, van gietijzer, van ijzer of van staal, ook indien met bijbehorende moeren of sluitringen, met kop (m.u.v. die met kop met gleuf, met kruisgleuf, met binnenzeskant of die met zeskantkop; houtschroeven, zelftappende schroeven; schroeven en bouten met schroefdraad voor het bevestigen van bestanddelen van spoorbanen; oogschroeven en schroefhaken; schroefspijkers)',
    73181631: 'blindklinkmoeren van roestvrij staal',
    73181639: 'moeren van roestvrij staal (m.u.v. blindklinkmoeren)',
    73181640: 'blindklinkmoeren van gietijzer, van ijzer of van niet-roestvrij staal',
    73181660: 'borgmoeren van gietijzer, van ijzer of van niet-roestvrij staal',
    73181692: 'moeren van gietijzer, van ijzer of van niet-roestvrij staal, met een binnendiameter <= 12 mm (m.u.v. blindklinkmoeren en borgmoeren)',
    73181699: 'moeren van gietijzer, van ijzer of van niet-roestvrij staal, met een binnendiameter van > 12 mm (m.u.v. blindklinkmoeren en borgmoeren)',
    73181900: 'bevestigingsmateriaal en -inrichtingen met schroefdraad, van gietijzer, van ijzer of van staal, n.e.g.',
    73182100: 'veringen en andere verende sluitringen, van gietijzer, van ijzer of van staal',
    73182200: 'sluitringen, van gietijzer, van ijzer of van staal (m.u.v. veringen en andere verende sluitringen)',
    73182300: 'massieve klinknagels en klinkbouten, van gietijzer, van ijzer of van staal (m.u.v. holle en gespleten klinknagels, ongeacht voor welk gebruik)',
    73182400: 'splitpennen en splitbouten, stelpennen en stelbouten en spieën',
    73182900: 'bevestigingsmateriaal en -inrichtingen, zonder schroefdraad, van gietijzer, van ijzer of van staal, n.e.g.',
    73194000: 'veiligheidsspelden en andere spelden, van ijzer of van staal, n.e.g.',
    73199010: 'naainaalden, stopnaalden of borduurnaalden, voor handwerk, van ijzer of van staal',
    73199090: 'breinaalden, rijgnaalden, haakpennen, borduurpriemen e.d. artikelen, voor handwerk, van ijzer of van staal (m.u.v. naainaalden, stopnaalden en borduurnaalden)',
    73201011: 'parabolveren en veerbladen daarvoor, van ijzer of van staal',
    73201019: 'bladveren en veerbladen daarvoor, van ijzer of van staal, warm gevormd (m.u.v. parabolveren en veerbladen daarvoor; veren voor uurwerken; torsiveren bedoeld bij afdeling 17)',
    73201090: 'bladveren en veerbladen daarvoor, van ijzer of van staal (m.u.v. warm gevormde producten; veren voor uurwerken; torsiveren bedoeld bij afdeling 17)',
    73202020: 'schroefveren, van ijzer of van staal, warm gevormd (m.u.v. vlakke spiraalveren, veren voor uurwerken; veren voor stokken en naalden van paraplu\'s en parasols; schokdempers bedoeld bij afdeling 17)',
    73202081: 'drukveren, van ijzer of van staal (m.u.v. voluutveren; veren voor uurwerken; veren voor stokken en naalden van paraplu\'s en parasols; schokdempers bedoeld bij afdeling 17)',
    73202085: 'trekveren, van ijzer of van staal (m.u.v. veren voor uurwerken; veren voor stokken en naalden van paraplu\'s en parasols)',
    73202089: 'schroefveren, van ijzer of van staal (m.u.v. warm gevormde veren; vlakke spiraalveren; drukveren; trekveren; veren voor uurwerken; veren voor stokken en naalden van paraplu\'s en parasols; schokdempers bedoeld bij afdeling 17)',
    73209010: 'vlakke spiraalveren, van ijzer of van staal (m.u.v. schroefveren en veren voor uurwerken)',
    73209030: 'schotelveren, van ijzer of van staal',
    73209090: 'veren en veerbladen, van ijzer of van staal (m.u.v. vlakke spiraalveren, schotelveren, schroefveren, bladveren en veerbladen daarvoor; veren voor uurwerken, veerringen en verende sluitringen; schokdempers en torsiveren bedoeld bij afdeling 17)',
    73211110: 'kook- of braadtoestellen, voorzien van oven, incl. afzonderlijke ovens, voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor gas of voor gas en andere brandstof',
    73211190: 'kook- of braadtoestellen en bordenwarmers, voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor gas of voor gas en andere brandstof (m.u.v. toestellen voorzien van oven, incl. afzonderlijke ovens)',
    73211200: 'kook- of braadtoestellen en bordenwarmers, voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor vloeibare brandstof',
    73211900: 'Kook- of braadtoestellen en bordenwarmers, voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor vaste brandstof of andere niet-elektrische energiebron (m.u.v. vloeibare brandstof of gas, en grote kook- of braadtoestellen)',
    73218100: 'kachels, kookketels met vuurhaard, keukenfornuizen e.d. niet-elektrische toestellen voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor gas of voor gas en andere brandstof (m.u.v. kook- en braadtoestellen, ook indien voorzien van bakoven, incl. afzonderlijke ovens, bordenwarmers; ketels voor centrale verwarming; geisers en andere heetwatertoestellen)',
    73218200: 'kachels, kookketels met vuurhaard, keukenfornuizen e.d. toestellen voor huishoudelijk gebruik, van gietijzer, van ijzer of van staal, voor vloeibare brandstof (m.u.v. kook- en braadtoestellen, ook indien voorzien van oven, incl. afzonderlijke ovens, bordenwarmers; ketels voor centrale verwarming; heetwatertoestellen)',
    73218900: 'Kachels, barbecues, vuurpotten, wasketels e.d. toestellen, van gietijzer, ijzer of van staal, voor vaste brandstof of andere niet-elektrische energiebron (m.u.v. vloeibare brandstof of gas; kook- of braadtoestellen, ook indien met oven; bordenwarmens: ketels voor centrale verwarming: heetwatertoestellen en grote kooktoestellen)',
    73219000: 'delen van niet-elektrische verwarmde toestellen voor huishoudelijk gebruik, bedoeld bij post 7321, n.e.g.',
    73221100: 'radiatoren voor centrale verwarming, niet-elektrisch verwarmd, alsmede delen daarvan, van gietijzer (m.u.v. elders genoemde delen; ketels voor centrale verwarming)',
    73221900: 'radiatoren voor centrale verwarming, niet-elektrisch verwarmd, alsmede delen daarvan, van ijzer of van staal (m.u.v. die van gietijzer; elders genoemde delen; ketels voor centrale verwarming)',
    73229000: 'Luchtverhitters en apparaten voor het verspreiden van warme lucht, incl. apparaten die tevens geschikt zijn voor gekoelde of geconditioneerde lucht, niet-elektrisch verwarmd, voorzien van een door een motor aangedreven ventilator of blaasinrichting, alsmede delen daarvan, van gietijzer, van ijzer of van staal',
    73231000: 'ijzerwol en staalwol, sponsen, schuurlappen, schuurhandjes e.d. artikelen voor het schuren, voor het polijsten of voor dergelijke doeleinden, van ijzer of van staal',
    73239100: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van gietijzer, ongeëmailleerd (m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; papiermanden; wafelijzers en andere artikelen met het karakter van gereedschap; lepels, pollepels, enz. bedoeld bij de post 8215; versieringsvoorwerpen; artikelen voor toiletgebruik of voor hygiënisch gebruik)',
    73239200: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van gietijzer, geëmailleerd (m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; papiermanden; schoppen, en andere artikelen met het karakter van gereedschap; lepels, pollepels, enz. bedoeld bij de post 8215; versieringsvoorwerpen; artikelen voor toiletgebruik of voor hygiënisch gebruik)',
    73239300: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van roestvrij staal (m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; papiermanden; schoppen, kurkentrekkers en andere artikelen met het karakter van gereedschap; messenmakerswerk; lepels, pollepels, vorken enz. bedoeld bij post 8215; versieringsvoorwerpen; artikelen voor toiletgebruik of voor hygiënisch gebruik)',
    73239400: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van ijzer of van staal, geëmailleerd (m.u.v. die van gietijzer en van roestvrij staal; bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; papiermanden; schoppen,kurkentrekkers en andere artikelen met het karakter van gereedschap;messenmakerswerk;  lepels, pollepels, vorken enz. bedoeld bij post 8215; versieringsvoorwerpen; artikelen voor toiletgebruik en voor hygiënisch gebruik)',
    73239900: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van ijzer of van staal (m.u.v. die van gietijzer of van roestvrij staal; geëmailleerde artikelen; bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; papiermanden; schoppen, kurkentrekkers en andere artikelen met het karakter van gereedschap; messenmakerswerk, lepels, pollepels, vorken enz. bedoeld bij post 8215; versieringsvoorwerpen; artikelen voor toiletgebruik of voor hygiënisch gebruik)',
    73241000: 'Gootstenen en wasbakken, van roestvrij staal',
    73242100: 'badkuipen, van gietijzer, ook indien geëmailleerd',
    73242900: 'badkuipen, van plaatstaal',
    73249000: 'Toiletartikelen en sanitaire artikelen, alsmede delen daarvan, van gietijzer, van ijzer of van staal (m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7310; ophangbare medicijnkastjes en andere meubelen bedoeld bij hoofdstuk 94; complete gootstenen en wasbakken, van roestvrij staal; komplete badkuipen; armaturen; komplete sanitaire artikelen)',
    73251000: 'werken van niet-smeedbaar gietijzer, n.e.g.',
    73259100: 'kogels e.d. artikelen voor breekmachines, gegoten (m.u.v. die van niet-smeedbaar gietijzer)',
    73259910: 'werken van smeedbaar gietijzer, n.e.g. (m.u.v. kogels e.d. artikelen voor breekmachines)',
    73259990: 'werken van gegoten staal, n.e.g. (m.u.v. kogels e.d. artikelen voor breekmachines)',
    73261100: 'kogels e.d. artikelen voor breekmachines, van ijzer of van staal, gesmeed of gestampt, doch verder onbewerkt',
    73261910: 'werken van ijzer of van staal, gesmeed, doch verder onbewerkt, n.e.g. (m.u.v. kogels e.d. artikelen voor breekmachines)',
    73261990: 'werken van ijzer of van staal, gestampt, doch verder onbewerkt, n.e.g. (m.u.v. kogels e.d. artikelen voor breekmachines)',
    73262000: 'werken van ijzer- of staaldraad, n.e.g.',
    73269030: 'ladders en trapladders, van ijzer of van staal',
    73269040: 'paletten en laadborden voor de behandeling van goederen, van ijzer of van staal (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    73269050: 'haspels voor kabels, slangen enz. van ijzer of van staal',
    73269060: 'ventilators, niet-mechanisch "luchtroosters e.d.", dakgoten, bevestigingshaken en andere artikelen voor bouwwerken, n.e.g., van ijzer of van staal',
    73269092: 'werken van ijzer of van staal, gesmeed, n.e.g.',
    73269094: 'werken van ijzer of van staal, gestampt, n.e.g.',
    73269096: 'werken van ijzer of van staal, gesinterd, n.e.g.',
    73269098: 'Werken van ijzer of van staal, n.e.g.',
    74010000: 'Kopersteen of ruwsteen; cementkoper "neergeslagen koper"',
    74020000: 'koper, niet-geraffineerd; anoden van koper voor het elektrolytisch raffineren',
    74031100: 'koper, geraffineerd, in de vorm van kathoden en in stukken gesneden kathoden',
    74031200: 'koper, geraffineerd, in de vorm van wire-bars',
    74031300: 'koper, geraffineerd, in de vorm van billets',
    74031900: 'koper, geraffineerd, ruw (m.u.v. koper in de vorm van billets, wire-bars, kathoden of in stukken gesneden kathoden)',
    74032100: 'koper-zinklegeringen "messing of geelkoper", ruw',
    74032200: 'koper-tinlegeringen "brons", ruw',
    74032900: 'Koperlegeringen , ruw (m.u.v. koper-zinklegeringen "messing of geelkoper"; koper-tinlegeringen "brons"; toeslaglegeringen van koper bedoeld bij post 7405)',
    74040010: 'resten en afval, van geraffineerd koper (m.u.v. ingots e.d. primaire vormen, van gesmolten resten en afval, assen en residuen die geraffineerd koper bevatten en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    74040091: 'resten en afval, van koper-zinklegeringen "messing of geelkoper" (m.u.v. ingot e.d. primaire vormen, van gesmolten resten en afval, assen en residuen die koper-zinklegeringen bevatten en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    74040099: 'resten en afval, van koperlegeringen (m.u.v. die van koper-zinklegeringen "messing of geelkoper" en m.u.v. ingot e.d. primaire vormen, van gesmolten resten en afval, assen en residuen die koperlegeringen bevatten en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    74050000: 'toeslaglegeringen van koper (m.u.v. fosfor-koperverbindingen die > 15 gewichtspercenten fosfor bevatten)',
    74061000: 'poeder van koper, zonder lamellaire structuur (m.u.v. korrels van koper)',
    74062000: 'poeder van koper, met lamellaire structuur; schilfers van koper (m.u.v. korrels van koper, pailletten bedoeld bij post 8308)',
    74071000: 'staven en profielen, van geraffineerd koper, n.e.g.',
    74072110: 'staven van koper-zinklegeringen "messing", n.e.g.',
    74072190: 'profielen van koper-zinklegeringen "messing", n.e.g.',
    74072900: 'Staven en profielen, van koperlegeringen,  n.e.g. (m.u.v. die van koper-zinklegeringen "messing")',
    74081100: 'draad van geraffineerd koper, met een grootste afmeting der dwarsdoorsnede van > 6 mm',
    74081910: 'draad van geraffineerd koper, met een grootste afmeting der dwarsdoorsnede van > 0,5 mm, doch <= 6 mm',
    74081990: 'draad van geraffineerd koper, met een grootste afmeting der dwarsdoorsnede van <= 0,5 mm',
    74082100: 'draad van koper-zinklegeringen "messing"',
    74082200: 'Draad van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver"',
    74082900: 'Draad van koperlegeringen (m.u.v. die van koper-zinklegeringen "messing of geel koper"; die van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver")',
    74091100: 'platen, bladen en strippen, van geraffineerd koper, met een dikte van > 0,15 mm, opgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74091900: 'platen, bladen en strippen, van geraffineerd koper, met een dikte van > 0,15 mm, onopgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74092100: 'platen, bladen en strippen, van koper-zinklegeringen "messing", met een dikte van > 0,15 mm, opgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74092900: 'platen, bladen en strippen, van koper-zinklegeringen "messing", met een dikte van > 0,15 mm, onopgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74093100: 'platen, bladen en strippen, van koper-tinlegeringen "brons", met een dikte van > 0,15 mm, opgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74093900: 'platen, bladen en strippen, van koper-tinlegeringen "brons", met een dikte van > 0,15 mm, onopgerold (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74094000: 'Platen, bladen en strippen,van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver", met een dikte van > 0,15 mm (m.u.v. plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74099000: 'platen, bladen en strippen, van koperlegeringen, met een dikte van > 0,15 mm (m.u.v. die van koper-zinklegeringen "messing of geelkoper", van koper-tinlegeringen "brons", van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver"; plaatgaas verkregen door het uitrekken van plaat- of bandkoper; geïsoleerde stripjes voor elektrotechnisch gebruik)',
    74101100: 'bladkoper, van geraffineerd koper, zonder drager, met een dikte van <= 0,15 mm (m.u.v. stempelfolieën bedoeld bij post 3212, metaalgarens en gemetalliseerde textielgarens; folieën opgemaakt als kerstboomversiering)',
    74101200: 'bladkoper, van koperlegeringen, zonder drager, met een dikte van <= 0,15 mm (m.u.v. stempelfolieën bedoeld bij post 3212, metaalgarens en gemetalliseerde textielgarens; folieën opgemaakt als kerstboomversiering)',
    74102100: 'bladkoper, van geraffineerd koper, met drager, met een dikte van <= 0,15 mm (m.u.v. stempelfolieën bedoeld bij post 3212, metaalgarens en gemetalliseerde textielgarens; folieën opgemaakt als kerstboomversiering)',
    74102200: 'bladkoper, van koperlegeringen, met drager, met een dikte van <= 0,15 mm (m.u.v. stempelfolieën bedoeld bij post 3212, metaalgarens en gemetalliseerde textielgarens; folieën opgemaakt als kerstboomversiering)',
    74111010: 'Buizen en pijpen van geraffineerd koper, recht',
    74111090: 'buizen en pijpen, van koper, opgerold of anderszins gebogen',
    74112110: 'buizen en pijpen, van koper-zinklegeringen "messing of geelkoper", recht',
    74112190: 'buizen en pijpen, van koper-zinklegeringen "messing of geelkoper", opgerold of anderszins gebogen',
    74112200: 'Buizen en pijpen, van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver"',
    74112900: 'buizen en pijpen, van koperlegeringen (m.u.v. die van koper-zinklegeringen "messing of geelkoper", van koper-nikkellegeringen of van koper-nikkel-zinklegeringen "nieuwzilver")',
    74121000: 'hulpstukken "fittings" voor buisleidingen, van geraffineerd koper',
    74122000: 'hulpstukken "fittings" voor buisleidingen, van koperlegeringen',
    74130000: 'Kabels, strengen e.d. artikelen, van koper (m.u.v. die geïsoleeerd voor het geleiden van elektriciteit)',
    74151000: 'draadnagels, spijkers, punaises, aangepunte krammen e.d. artikelen, van koper of met schacht van ijzer of van staal en een koperen kop (m.u.v. krammen, in strippen)',
    74152100: 'sluitringen, incl. veerringen en andere verende sluitringen, van koper',
    74152900: 'bouten, klinknagels en klinkbouten, splitpennen en splitbouten, stelpennen en stelbouten, spieën, zonder schroefdraad, van koper (m.u.v. sluitringen, incl. veerringen en andere verende sluitringen)',
    74153300: 'schroeven, bouten en moeren en dergelijke artikelen met schroefdraad, van koper (m.u.v. schroefhaken, oogschroeven, schroefspijkers, schroefstoppen en  sponnen en dergelijke, met schroefdraad)',
    74153900: 'oogschroeven en schroefhaken, e.d. artikelen, met schroefdraad, van koper (m.u.v. gewone schroeven, bouten en moeren)',
    74181010: 'komforen en andere huishoudelijke verwarmingstoestellen, niet-elektrisch, alsmede delen daarvan, van koper (m.u.v. heetwatertoestellen en geisers)',
    74181090: 'Keukengerei en huishoudelijke artikelen, alsmede delen daarvan, sponsen, schuurlappen, schuurhandjes e.d. artikelen voor het schuren, voor het polijsten of voor dergelijke doeleinden van koper (m.u.v. komforen en andere niet-elektrische verwarmingstoestellen; bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7419, artikelen met het karakter van gereedschap, messenmakerswerk, lepels, pollepels enz., versieringsvoorwerpen, toiletartikelen)',
    74182000: 'toiletartikelen en sanitaire artikelen, alsmede delen daarvan, van koper (m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7419; armaturen)',
    74191000: 'kettingen en delen daarvan, van koper (m.u.v. horlogekettingen, kettingen voor bijouterieën enz.)',
    74199100: 'werken van koper, gegoten, gevormd, gestampt of gesmeed, doch verder onbewerkt, n.e.g.',
    74199910: 'Doek "incl. eindloze band", rasters en netten van koperdraad waarvan de doosnede <= 6 mm bedraagt, en plaatgaas  van plaat-of bandkoper (m.u.v. weefsels van metaaldraad van de soort gebruikt voor kleding, voor stoffering en voor dergelijk gebruik, met vloeimiddelen bekleed koperdoek, voor het hardsolderen, en weefsels, gemonteerd tot handzeven of verwerkt tot onderdelen van machines)',
    74199930: 'veren van koper (m.u.v. uurwerkveren, veerringen en andere verende sluitingen)',
    74199990: 'werken van koper, n.e.g.',
    75011000: 'nikkelmatte',
    75012000: 'nikkeloxidesinters en andere tussenproducten van de nikkelmetallurgie (m.u.v. nikkelmatte)',
    75021000: 'nikkel, niet-gelegeerd, ruw',
    75022000: 'nikkellegeringen, ruw',
    75030010: 'resten en afval, van niet-gelegeerd nikkel (m.u.v. ingots e.d. primaire vormen, van gesmolten resten en afval, assen en residuen die niet-gelegeerd nikkel bevatten en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    75030090: 'resten en afval, van nikkellegeringen (m.u.v. ingot e.d. primaire vormen, van gesmolten resten en afval, van nikkellegeringen; schuim bevattende nikkellegeringen)',
    75040000: 'poeder en schilfers, van nikkel (m.u.v. nikkeloxidesinters)',
    75051100: 'staven, profielen en draad, van niet-gelegeerd nikkel, n.e.g. (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    75051200: 'staven, profielen en draad, van nikkellegeringen, n.e.g. (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    75052100: 'draad, van niet-gelegeerd nikkel (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    75052200: 'draad, van nikkellegeringen (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    75061000: 'platen, bladen en strippen, van niet-gelegeerd nikkel (m.u.v. plaatgaas verkregen door het uitrekken van platen of banden)',
    75062000: 'platen, bladen en strippen, van nikkellegeringen (m.u.v. plaatgaas verkregen door het uitrekken van platen of banden)',
    75071100: 'buizen en pijpen, van niet-gelegeerd nikkel',
    75071200: 'buizen en pijpen, van nikkellegeringen',
    75072000: 'hulpstukken "fittings" voor buisleidingen, van nikkel',
    75081000: 'metaaldoek en metaalgaas, van nikkeldraad',
    75089000: 'werken van nikkel, n.e.g.',
    76011000: 'aluminium, niet-gelegeerd, ruw',
    76012020: 'Aluminium, gelegeerd, ruw, in de vorm van plakken en billets',
    76012080: 'Aluminium, gelegeerd, ruw (m.u.v. plakken en billets)',
    76020011: 'draaisel, krullen, spaanders, slijpsel, zaagsel en vijlsel, van aluminium; resten van bladaluminium, gekleurd, bekleed of beplakt, met een dikte, zonder drager, van <= 0,2 mm, van aluminium',
    76020019: 'resten van aluminium, incl. onbruikbare of afgekeurde werkstukken (m.u.v. metaalschuim en slakken afkomstig van de ferrometaalproductie, welke recupereerbaar aluminium in de vorm van silicaten bevatten; ingots e.d. primaire vormen, van gesmolten resten en afvallen van aluminium; assen en residuen van de aluminiumbereiding; afvallen bedoeld bij de posten 7602.00.11 en 7602.00.19)',
    76020090: 'afval van aluminium (m.u.v. metaalschuim en -slakken afkomstig van de ferrometaalproductie, welke recupereerbaar aluminium in de vorm van silicaten bevatten; ingots e.d. primaire vormen, van gesmolten resten en afvallen van aluminium; assen en andere residuen van de aluminiumbereiding)',
    76031000: 'poeder van aluminium, zonder lamellaire structuur (m.u.v. aluminiumkorrels)',
    76032000: 'poeder van aluminium, met lamellaire structuur; schilfers van aluminium (m.u.v. aluminiumkorrels; gesneden pailletten)',
    76041010: 'staven, van niet-gelegeerd aluminium, n.e.g.',
    76041090: 'profielen, van niet-gelegeerd aluminium, n.e.g.',
    76042100: 'holle profielen, van aluminiumlegeringen, n.e.g.',
    76042910: 'staven, van aluminiumlegeringen, n.e.g.',
    76042990: 'massieve profielen, van aluminiumlegeringen, n.e.g.',
    76051100: 'draad van niet-gelegeerd aluminium, waarvan de grootste afmeting der dwarsdoorsnede >= 7 mm (m.u.v. kabels, strengen, e.d., bedoeld bij post 7614; geïsoleerd draad voor elektrotechnisch gebruik)',
    76051900: 'Draad van niet-gelegeerd aluminium, met een grootste afmeting der dwarsdoorsnede van <= 7 mm (m.u.v. kabels, strengen e.d., bedoeld bij post 7614; geïsoleerd draad voor elektrotechnisch gebruik; snaren voor muziekinstrumenten)',
    76052100: 'draad van aluminiumlegeringen, waarvan de grootste afmeting der dwarsdoorsnede > 7 mm is (m.u.v. kabels, strengen, e.d., bedoeld bij post 7614; geïsoleerd draad voor elektrotechnisch gebruik)',
    76052900: 'Draad van aluminiumlegeringen, met een grootste afmeting der dwarsdoorsnede van <= 7 mm (m.u.v. kabels, strengen e.d., bedoeld bij post 7614; geïsoleerd draad voor elektrotechnisch gebruik; snaren voor muziekinstrumenten)',
    76061110: 'platen, banden en strippen, van niet-gelegeerd aluminium, met een dikte van > 0,2 mm, vierkant of rechthoekig, geverfd, gevernist of bekleed met kunststof',
    76061191: 'platen, banden en strippen, van niet-gelegeerd aluminium, vierkant of rechthoekig, met een dikte van > 0,2 mm, doch < 3 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof; plaatgaas verkregen door het uitrekken van platen, banden of strippen)',
    76061193: 'platen, banden en strippen, van niet-gelegeerd aluminium, vierkant of rechthoekig, met een dikte van >= 3 mm, doch < 6 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof)',
    76061199: 'platen, banden en strippen, van niet-gelegeerd aluminium, vierkant of rechthoekig, met een dikte van >= 6 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof)',
    76061211: 'materiaal, van aluminiumlegeringen voor rompen van drankblikjes , met een dikte van > 0,2 mm ',
    76061219: 'materiaal, van aluminiumlegeringen voor bodems en lipjes van drankblikjes, met een dikte van > 0,2 mm ',
    76061220: 'Platen, bladen en strippen, van aluminiumlegeringen, met een dikte van > 0,2 mm, vierkant of rechthoekig, geverfd, gevernist of bekleed met kunststof (m.u.v. rompen voor drankblikjes  en bodems en  lipjes van drankblikjes)',
    76061292: 'Platen, bladen en strippen, van aluminiumlegeringen, vierkant of rechthoekig, met een dikte van > 0,2 mm, doch < 3 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof; plaatgaas verkregen door het uitrekken van platen, bladen en strippen; rompen van drankblikjes  en bodems en  lipjes van drankblikjes)',
    76061293: 'platen, bladen en strippen, van aluminiumlegeringen, vierkant of rechthoekig, met een dikte van >= 3 mm, doch < 6 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof)',
    76061299: 'platen, bladen en strippen, van aluminiumlegeringen, vierkant of rechthoekig, met een dikte van >= 6 mm (m.u.v. die geverfd, gevernist of bekleed met kunststof)',
    76069100: 'platen, bladen en strippen, van niet-gelegeerd aluminium, met een dikte van > 0,2 mm (m.u.v. vierkante of rechthoekige producten)',
    76069200: 'platen, bladen en strippen, van aluminiumlegeringen, met een dikte van > 0,2 mm (m.u.v. vierkante of rechthoekige producten)',
    76071111: 'Bladaluminium, zonder drager, enkel gewalst, met een dikte van < 0,021 mm, op rollen met een gewicht van <= 10 kg (m.u.v. stempelfolieën bedoeld bij post 3212; bladaluminium voor kerstboomversieringen)',
    76071119: 'Bladaluminium, zonder drager, enkel gewalst, met een dikte van < 0,021 mm (m.u.v. stempelfolieën bedoeld bij post 3212; bladaluminium voor kerstboomversieringen en die  op rollen met een gewicht van <= 10 kg )',
    76071190: 'bladaluminium, zonder drager, enkel gewalst, met een dikte van >= 0,021 mm, doch <= 0,2 mm (m.u.v. stempelfolieën bedoeld bij post 3212; bladaluminium voor kerstboomversieringen)',
    76071910: 'Bladaluminium, zonder drager, gewalst en verder bewerkt, met een dikte van < 0,021 mm (m.u.v. stempelfolieën bedoeld bij post 3212; bladaluminium voor kerstboomversieringen)',
    76071990: 'Bladaluminium, zonder drager, gewalst en verder bewerkt, met een dikte van >= 0,021 doch <= 0,2 mm (m.u.v. stempelfoliën bedoeld bij post 3212 en bladaluminium voor kerstboomversieringen)',
    76072010: 'bladaluminium, op een drager, met een dikte, zonder drager, van < 0,021 mm (m.u.v. stempelfolieën bedoeld bij post 3212; bladaluminium voor kerstboomversieringen)',
    76072090: 'Bladaluminium, ook indien bedrukt, op een drager, met een dikte, zonder drager, van >= 0,021 doch <= 0,2 mm (m.u.v. stempelfoliën bedoeld bij post 3212 en bladaluminium voor kerstboomversieringen)',
    76081000: 'Buizen en pijpen, van niet-gelegeerd aluminium (m.u.v. holle profielen)',
    76082020: 'Buizen en pijpen, van aluminiumlegeringen, gelast (m.u.v. holle profielen)',
    76082081: 'Buizen en pijpen, van aluminiumlegeringen, enkel warm geperst (m.u.v. holle profielen)',
    76082089: 'Buizen en pijpen, van aluminiumlegeringen (m.u.v. gelaste of enkel warm geperste producten; holle profielen)',
    76090000: 'hulpstukken "fittings", voor buisleidingen, b.v. verbindingsstukken, ellebogen, moffen, van aluminium',
    76101000: 'deuren en ramen, alsmede kozijnen daarvoor en drempels, van aluminium (m.u.v. beslag)',
    76109010: 'bruggen en brugdelen, vakwerkmasten en andere masten',
    76109090: 'constructiewerken en delen van constructiewerken, van aluminium, n.e.g.; platen, staven, profielen, buizen, e.d., van aluminium, gereedgemaakt voor gebruik in constructiewerken, n.e.g. (m.u.v. geprefabriceerde bouwwerken bedoeld bij post 9406; deuren en ramen, alsmede kozijnen daarvoor en drempels; deuren en ramen, alsmede kozijnen daarvoor en drempels)',
    76110000: 'reservoirs, voeders, kuipen e.d. bergingsmiddelen, voor ongeacht welke goederen, van aluminium, met een inhoudsruimte van > 300 l, zonder mechanische inrichting of inrichting om te koelen of te warmen, ook indien inwendig bekleed of voorzien van een warmte-isolerende bekleding (m.u.v. die voor gecomprimeerd of vloeibaar gemaakt gas; containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    76121000: 'soepele tubes, van aluminium, b.v. voor tandpasta',
    76129020: 'Bergingsmiddelen van de soort gebruikt als aërosolverpakking, van aluminium',
    76129030: 'Reservoirs, fusten, trommels, bussen, blikken e.d. bergingsmiddelen, van bladaluminium met een dikte van niet meer dan 0,2 mm',
    76129080: 'Reservoirs, fusten, trommels, bussen, blikken e.d. bergingsmiddelen met een inhoudsruimte van <= 300 l, , van aluminium, voor ongeacht welke goederen, n.e.g. (m.u.v. die voor gecomprimeerd of vloeibaar gemaakt gas; bergingsmiddelen van de soort gebruikt als aërosolverpakking; bergingsmiddelen van bladaluminium met een dikte van niet meer dan 0,2 mm)',
    76130000: 'bergingsmiddelen voor gecomprimeerd of voor vloeibaar gemaakt gas, van aluminium',
    76141000: 'kabels, strengen e.d. artikelen, van aluminium, met een kern van staal (m.u.v. geïsoleerde producten voor het geleiden van elektriciteit)',
    76149000: 'Kabels, strengen e.d., van aluminium (m.u.v. die met een kern van staal; geïsoleerde producten voor elektrotechnisch gebruik)',
    76151010: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan,van aluminium, gegoten (m.u.v. bussen, blikken e.d. bedoeld bij post 7612; lepels, pollepels, vorken en andere bedoeld bij post 8211 tot en met 8215; siervoorwerpen; armaturen; toilet- en sanitaire artikelen)',
    76151030: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, van bladaluminium met een dikte van niet meer dan 0,2 mm ( m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7612)',
    76151080: 'keukengerei en huishoudelijke artikelen, alsmede delen daarvan, sponzen, schuurlappen, schuurhandjes e.d. artikelen voor het schuren, voor het polijsten of voor dergelijke doeleinden, van aluminium (niet gegoten en m.u.v. bussen, blikken e.d. bergingsmiddelen bedoeld bij post 7612, lepels, pollepels, vorken e.d., bedoeld bij post 8211 tot en met 8215, siervoorwerpen, armaturen, toiletartikelen en sanitaire artikelen)',
    76152000: 'toilet- en sanitaire artikelen, alsmede delen daarvan, van aluminium (m.u.v. bussen, blikken e.d. bedoeld bij post 7612; armaturen)',
    76161000: 'draadnagels, spijkers, aangepunte krammen, schroeven, bouten, moeren, haken met schroefdraad, klinknagels en klinkbouten, splitpennen, spiebouten, spieën, sluitringen e.d. artikelen, van aluminium (m.u.v. nietjes; schroefstoppen en schroefsponnen)',
    76169100: 'metaaldoek, metaalgaas en traliewerk, van aluminiumdraad (m.u.v. dat gemonteerd tot handzeven of verwerkt tot onderdelen van machines en m.u.v. weefsels van metaaldraad voor kleding, voor stoffering en voor dergelijke doeleinden)',
    76169910: 'werken van aluminium, gegoten, n.e.g.',
    76169990: 'werken van aluminium, n.e.g. (niet gegoten)',
    78011000: 'lood, geraffineerd, ruw',
    78019100: 'lood, ruw, waarin, van de andere elementen, antimoon met het hoogste gewicht voorkomt',
    78019910: 'lood, ruw, bevattende >= 0,02 gewichtspercent zilver, bestemd om te worden geraffineerd "werklood"',
    78019990: 'Lood, ruw (m.u.v. lood, waarin, van de andere elementen, antimoon met het hoogste gewicht voorkomt; lood met een zilvergehalte van >= 0,02 gewichtspercent "werklood"; geraffineerd lood)',
    78020000: 'resten en afval, van lood (m.u.v. assen en residuen van de loodproductie, bedoeld bij post 2620, ingots e.d. primaire vormen, van gesmolten resten en afval, bedoeld bij post 7801, en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    78041100: 'bladen en strippen, van lood, met een dikte, zonder drager, van <= 0,2 mm',
    78041900: 'platen, van lood; bladen en strippen, van lood, met een dikte, zonder drager, van > 0,2 mm',
    78042000: 'poeder en schilfers, van lood (m.u.v. korrels van lood; gesneden pailletten bedoeld bij post 8308)',
    78060010: 'bergingsmiddelen met loden bekleding ter afscherming van radioactieve stralen, voor het vervoer of de opslag van radioactieve stoffen "Euratom" (m.u.v. containers e.d. laadkisten, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel)',
    78060080: 'werken van lood, n.e.g.',
    79011100: 'zink, ruw, niet gelegeerd, bevattende >= 99,99 gewichtspercenten zink',
    79011210: 'zink, ruw, niet gelegeerd, bevattende >= 99,95, doch < 99,99 gewichtspercenten zink',
    79011230: 'zink, ruw, niet gelegeerd, bevattende >= 98,5, doch < 99,95 gewichtspercenten zink',
    79011290: 'zink, ruw, niet gelegeerd, bevattende >= 97,5, doch < 98,5 gewichtspercenten zink',
    79012000: 'zinklegeringen, ruw',
    79020000: 'resten en afval, van zink (m.u.v. assen en residuen van de zinkproductie, bedoeld bij post 2620, ingots e.d. primaire vormen, van gesmolten resten en afval, bedoeld bij post 7901, en resten en afval van elektrische elementen, batterijen en accumulatoren)',
    79031000: 'zinkstof',
    79039000: 'poeder en schilfers, van zink (m.u.v. korrels van zink; gesneden pailletten bedoeld bij post 8308)',
    79040000: 'staven, profielen en draad, van zink, n.e.g.',
    79050000: 'Platen, bladen en strippen, van zink',
    79070000: 'Werken van zink, n.e.g.',
    80011000: 'tin, ruw, niet gelegeerd',
    80012000: 'tinlegeringen, ruw',
    80020000: 'resten en afval, van tin (m.u.v. assen en residuen van de tinproductie bedoeld bij post 2620; ingots e.d. primaire vormen, van gesmolten resten en afval, van tin, bedoeld bij post 8001)',
    80030000: 'staven, profielen en draad, van tin, n.e.g.',
    80070010: 'platen, bladen en strippen, van tin, met een dikte van > 0,2 mm',
    80070080: 'werken van tin, n.e.g.',
    81011000: 'poeder van wolfraam',
    81019400: 'wolfraam, ruw, incl. enkel door sinteren verkregen staven',
    81019600: 'draad van wolfraam',
    81019700: 'resten en afval van wolfraam (m.u.v. assen en residuen die wolfraam bevatten)',
    81019910: 'staven, profielen, platen, bladen en strippen, van wolfraam, n.e.g. (m.u.v. enkel door sinteren verkregen staven)',
    81019990: 'werken van wolfraam, n.e.g.',
    81021000: 'poeder van molybdeen',
    81029400: 'molybdeen, ruw, incl. enkel door sinteren verkregen staven',
    81029500: 'staven, profielen, platen, bladen en strippen, van molybdeen, n.e.g. (m.u.v. enkel door sinteren verkregen staven)',
    81029600: 'draad van molybdeen',
    81029700: 'resten en afval van molybdeen (m.u.v. assen en residuen die molybdeen bevatten)',
    81029900: 'werken van molybdeen, n.e.g.',
    81032000: 'tantaal, ruw, incl. enkel door sinteren verkregen staven; poeder van tantaal',
    81033000: 'resten en afval, van tantaal (m.u.v. assen en residuen die tantaal bevatten)',
    81039010: 'staven, profielen, draad, platen, bladen en strippen, van tantaal, n.e.g. (m.u.v. enkel door sinteren verkregen staven)',
    81039090: 'werken van tantaal, n.e.g.',
    81041100: 'magnesium, ruw, bevattende >= 99, 8 gewichtspercenten magnesium',
    81041900: 'magnesium, ruw, bevattende < 99, 8 gewichtspercenten magnesium',
    81042000: 'resten en afval, van magnesium (m.u.v. assen en residuen die magnesium bevatten; draaisel en korrels, van magnesium, gecalibreerd)',
    81043000: 'draaisel en korrels, van magnesium, gekalibreerd; poeder van magnesium',
    81049000: 'Werken van magnesium, n.e.g.',
    81052000: 'kobaltmatte en andere tussenproducten van de kobaltmetallurgie; kobalt, ruw; poeder van kobalt',
    81053000: 'resten en afval, van kobalt (m.u.v. assen en residuen die kobalt bevatten)',
    81059000: 'werken van kobalt, n.e.g.',
    81060010: 'bismut, ruw; poeder van bismut; resten en afval, van bismut (m.u.v. assen en residuen die bismut bevatten)',
    81060090: 'werken van bismut, n.e.g.',
    81072000: 'ruw cadmium; poeder van cadmium',
    81073000: 'resten en afval van cadmium (m.u.v. schuim bevattende cadmium)',
    81079000: 'werken van cadmium, n.e.g.',
    81082000: 'titaan, ruw; poeder van titaan',
    81083000: 'resten en afval, van titaan (m.u.v. assen en residuen die titaan bevatten)',
    81089030: 'staven, profielen en draad, van titaan, n.e.g.',
    81089050: 'platen, bladen en strippen, van titaan',
    81089060: 'Buizen en pijpen, van titaan',
    81089090: 'werken van titaan, n.e.g.',
    81092000: 'zirkonium, ruw; poeder van zirkonium',
    81093000: 'resten en afval van zirkonium, n.e.g.',
    81099000: 'werken van zirkonium, n.e.g.',
    81101000: 'antimoon, ruw; poeder van antimoon',
    81102000: 'resten en afval, van antimoon (m.u.v. assen en residuen die antimoon bevatten)',
    81109000: 'werken van antimoon, n.e.g.',
    81110011: 'mangaan, ruw; poeder van mangaan',
    81110019: 'resten en afval, van mangaan (m.u.v. assen en residuen die mangaan bevatten)',
    81110090: 'werken van mangaan, n.e.g.',
    81121200: 'ruw beryllium; poeder van beryllium',
    81121300: 'resten en afval van beryllium (m.u.v. schuim bevattende beryllium)',
    81121900: 'werken van beryllium, n.e.g.',
    81122110: 'chroomlegeringen, met een nikkelgehalte van > 10 gewichtspercenten, ruw; poeder van deze legeringen (m.u.v. assen en residuen die chroom of dergelijke chroomlegeringen bevatten)',
    81122190: 'chroom, ruw; poeder van chroom (m.u.v. chroomlegeringen met een nikkelgehalte van > 10 gewichtspercenten)',
    81122200: 'resten en afval, van chroom (m.u.v. assen en residuen die chroom bevatten; chroomlegeringen met een nikkelgehalte van > 10 gewichtspercenten)',
    81122900: 'werken van chroom, n.e.g.',
    81125100: 'thallium, ruw; poeder van thallium',
    81125200: 'resten en afval van thallium (m.u.v. schuim dat thallium bevat)',
    81125900: 'werken van thallium, n.e.g.',
    81129210: 'hafnium "celtium", ruw; poeder van hafnium "celtium"; resten en afval van hafnium "celtium" (m.u.v. assen en residuen die hafnium bevatten)',
    81129221: 'Niobium "columbium", rhenium, gallium, indium, vanadium, germanium, resten en afval (m.u.v. assen en residuen die deze metalen bevatten)',
    81129231: 'niobium "columbium" en rhenium, ruw; poeder van niobium "columbium" of van rhenium',
    81129281: 'ruw indium; poeder van indium',
    81129289: 'gallium, ruw; poeder van gallium',
    81129291: 'Vanadium, ruw; poeder van vanadium; resten en afval, van vanadium (m.u.v. assen en residuen die vanadium bevatten)',
    81129295: 'ruw germanium; poeder van germanium',
    81129920: 'Werken van hafnium "celtium" en van germanium, n.e.g.',
    81129930: 'werken van niobium "columbium" of van rhenium, n.e.g.',
    81129970: 'werken van gallium, van indium en van vanadium, n.e.g.',
    81130020: 'ruwe cermets',
    81130040: 'resten en afval van cermets (m.u.v. schuim bevattende cermets)',
    81130090: 'werken van cermets, n.e.g.',
    82011000: 'spaden en schoppen, waarvan het werkzaam deel bestaat uit onedel metaal',
    82013000: 'houwelen, hakken, harken en schoffels, waarvan het werkzaam deel bestaat uit onedel metaal (m.u.v. ijspikkels)',
    82014000: 'bijlen, houthiepen, kloofmessen e.d. gereedschap, waarvan het werkzaam deel bestaat uit onedel metaal',
    82015000: 'snoeischaren en wildscharen, met een handbediening, waarvan het werkzaam deel bestaat uit onedel metaal',
    82016000: 'heggenscharen en andere met twee handen te bedienen scharen, waarvan het werkzaam deel bestaat uit onedel metaal',
    82019000: 'zeisen, sikkels, stro- en hooimessen, wiggen en ander handgereedschap voor de land-, tuin- of bosbouw, waarvan het werkzaam deel bestaat uit onedel metaal (m.u.v. spaden, schoppen, houwelen, hakken, harken, schoffels, bijlen, houthiepen, kloofmessen e.d. gereedschap, snoeischaren en wildscharen, met een handbediening en heggenscharen en andere met twee handen te bedienen scharen)',
    82021000: 'handzagen, waarvan het werkzaam deel bestaat uit onedel metaal (m.u.v. handzagen met aangebouwde motor)',
    82022000: 'bladen voor lintzagen, van onedel metaal',
    82023100: 'bladen voor cirkelzagen, incl. freeszagen, van onedel metaal, met werkzaam deel van staal',
    82023900: 'bladen voor cirkelzagen, incl. freeszagen, en delen daarvan, van onedel metaal (m.u.v. die waarvan het werkzaam deel bestaat uit staal)',
    82024000: 'zaagkettingen, van onedel metaal',
    82029100: 'Rechte zaagbladen voor metaalbewerking, van onedel metaal',
    82029920: 'Zaagbladen, incl. ongetande zaagbladen, van onedel metaal, voor metaalbewerking (m.u.v. bladen voor lintzagen, voor cirkelzagen, voor freeszagen; zaagkettingen; rechte zaagbladen)',
    82029980: 'Zaagbladen, incl. ongetande zaagbladen, van onedel metaal, voor bewerking van stoffen (m.u.v. die voor metaalbewerking; bladen voor lintzagen, voor cirkelzagen, voor freeszagen; zaagkettingen)',
    82031000: 'vijlen, raspen e.d. handgereedschap, van onedel metaal',
    82032000: 'Tangen, incl. buig-, snij- en ponstangen, pincetten e.d. handgereedschap, van onedel metaal',
    82033000: 'metaalscharen, b.v. blikscharen, plaatscharen e.d. handgereedschap, van onedel metaal',
    82034000: 'pijpsnijders, boutenscharen, holpijpen e.d. handgereedschap, van onedel metaal',
    82041100: 'moersleutels, met de hand bediend, incl. dynamometrische moersleutels en inbussleutels, van onedel metaal (m.u.v. verstelbare sleutels)',
    82041200: 'moersleutels, met de hand bediend, incl. dynamometrische moersleutels en inbussleutels, van onedel metaal, verstelbaar',
    82042000: 'doppen van dopsleutels, uitwisselbaar, ook indien met handvat, van onedel metaal',
    82051000: 'boorgereedschap en gereedschap voor het snijden of het tappen van schroefdraad, met de hand bediend',
    82052000: 'hamers en voorhamers, met werkzaam deel van onedel metaal',
    82053000: 'schaven, beitels, gutsen e.d. houtsnijgereedschap',
    82054000: 'schroevendraaiers, met de hand bediend',
    82055100: 'handgereedschap voor huishoudelijk gebruik, niet-mechanisch, met werkzaam deel van onedel metaal, n.e.g.',
    82055910: 'handgereedschap voor metselaars, vormers, stukadoors en schilders, van onedel metaal, n.e.g.',
    82055980: 'handgereedschap, incl. glassnijders, van onedel metaal, n.e.g.',
    82056000: 'soldeer-, blaas- en brandlampen (m.u.v. met gas werkende soldeerapparaten)',
    82057000: 'bankschroeven, lijmknechten, pijpklemmen e.d. (m.u.v. toebehoren of delen van gereedschapswerktuigen of  van waterstraalsnijmachines)',
    82059010: 'aambeelden; veldsmidsen; slijpstenen met hand- of voetaandrijving',
    82059090: 'stellen bestaande uit artikelen van twee of meer onderverdelingen van post 8205',
    82060000: 'stellen, bestaande uit gereedschap van twee of meer onderverdelingen van posten 8202 tot en met 8205, opgemaakt voor de verkoop in het klein',
    82071300: 'grond- en gesteenteboren, verwisselbaar, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen',
    82071910: 'grond- en gesteenteboren, verwisselbaar, waarvan het werkzaam deel bestaat uit diamant of geagglomereerd diamant, incl. delen daarvan',
    82071990: 'grond- en gesteenteboren, verwisselbaar, incl. delen daarvan (m.u.v. die waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen, uit diamant of uit geagglomereerd diamant)',
    82072010: 'trekstenen of trekmatrijzen en pers- of extrusiematrijzen voor het bewerken van metalen, verwisselbaar, waarvan het werkzaam deel bestaat uit diamant, incl. geagglomereerd diamant',
    82072090: 'trekstenen of trekmatrijzen en pers- of extrusiematrijzen voor het bewerken van metalen, verwisselbaar (m.u.v. die waarvan het werkzaam deel bestaat uit diamant of uit geagglomereerd diamant)',
    82073010: 'gereedschap voor het stampen en stansen, verwisselbaar, voor metaalbewerking',
    82073090: 'gereedschap voor het stampen en stansen, verwisselbaar (m.u.v. die voor metaalbewerking)',
    82074010: 'verwisselbaar gereedschap voor het draadtappen, voor metaalbewerking',
    82074030: 'verwisselbaar gereedschap voor het draadsnijden, voor metaalbewerking',
    82074090: 'gereedschap voor het draadtappen en -snijden, verwisselbaar (m.u.v. dat voor metaalbewerking)',
    82075010: 'gereedschap voor het boren, verwisselbaar, waarvan het werkzaam deel bestaat uit diamant, incl. geagglomereerd diamant (m.u.v. grond- en gesteenteboren en gereedschap voor het draadtappen)',
    82075030: 'muurboren, verwisselbaar (m.u.v. die waarvan het werkzaam deel bestaat uit diamant incl. geagglomereerd diamant)',
    82075050: 'verwisselbaar gereedschap voor het boren, voor metaalbewerking, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen (m.u.v. gereedschap voor het draadtappen)',
    82075060: 'gereedschap voor het boren, verwisselbaar, voor metaalbewerking, waarvan het werkzaam deel bestaat uit sneldraaistaal (m.u.v. gereedschap voor het draadtappen)',
    82075070: 'verwisselbaar gereedschap voor het boren, voor metaalbewerking (m.u.v. dat waarvan het werkzaam deel bestaat uit diamant, uit geagglomereerd diamant, uit cermets, uit gesinterde hardmetalen of uit sneldraaistaal en m.u.v. gereedschap voor het draadtappen)',
    82075090: 'gereedschap voor het boren, verwisselbaar (m.u.v. dat waarvan het werkzaam deel bestaat uit diamant, incl. geagglomereerd diamant, en dat voor metaalbewerking en m.u.v. grond- en gesteenteboren, muurboren en gereedschap voor het draadtappen)',
    82076010: 'gereedschap voor het ruimen of kotteren, verwisselbaar, waarvan het werkzaam deel bestaat uit diamant incl. geagglomereerd diamant',
    82076030: 'verwisselbaar gereedschap voor het ruimen, voor metaalbewerking',
    82076050: 'gereedschap voor het ruimen, verwisselbaar (m.u.v. die voor metaalbewerking, die waarvan het werkzaam deel bestaat uit diamant incl. geagglomereerd diamant)',
    82076070: 'verwisselbaar gereedschap voor het kotteren, voor metaalbewerking',
    82076090: 'gereedschap voor het kotteren, verwisselbaar (m.u.v. die voor metaalbewerking, die waarvan het werkzaam deel bestaat uit diamant incl. geagglomereerd diamant)',
    82077010: 'verwisselbaar gereedschap voor het frezen, voor metaalbewerking, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen',
    82077031: 'verwisselbare schachtfrezen, voor metaalbewerking (m.u.v. die waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen)',
    82077037: 'verwisselbaar gereedschap voor het frezen, voor metaalbewerking (m.u.v. dat waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen en m.u.v. schachtfrezen)',
    82077090: 'gereedschap voor het frezen, verwisselbaar (m.u.v. die voor metaalbewerking)',
    82078011: 'verwisselbaar gereedschap voor het draaien, voor metaalbewerking, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen',
    82078019: 'verwisselbaar gereedschap voor het draaien, voor metaalbewerking (m.u.v. die waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen)',
    82078090: 'gereedschap voor het draaien, verwisselbaar (m.u.v. die voor metaalbewerking)',
    82079010: 'gereedschap, verwisselbaar, voor al dan niet mechanisch handgereedschap of voor gereedschapswerktuigen, waarvan het werkzaam deel bestaat uit diamant incl. geagglomereerd diamant, n.e.g.',
    82079030: 'stiften en bladen voor schroevendraaiers, van onedel metaal',
    82079050: 'gereedschap voor het steken, schaven en slijpen van tandwielen, verwisselbaar (m.u.v. tandwielfrezen)',
    82079071: 'verwisselbaar gereedschap, voor mechanisch of niet-mechanisch handgereedschap of voor gereedschapswerktuigen, voor metaalbewerking, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen, n.e.g.',
    82079078: 'verwisselbaar gereedschap, voor mechanisch of niet-mechanisch handgereedschap of voor gereedschapswerktuigen, waarvan het werkzaam deel bestaat uit cermets of gesinterde hardmetalen, n.e.g. (m.u.v. dat voor metaalbewerking)',
    82079091: 'verwisselbaar gereedschap, voor mechanisch of niet-mechanisch handgereedschap of voor gereedschapswerktuigen, voor metaalbewerking, n.e.g. (m.u.v. dat waarvan het werkzaam deel bestaat uit diamant, uit geagglomereerd diamant, uit cermets of uit gesinterde hardmetalen)',
    82079099: 'verwisselbaar gereedschap, voor mechanisch of niet-mechanisch handgereedschap of voor gereedschapswerktuigen, n.e.g. (m.u.v. dat voor metaalbewerking en dat waarvan het werkzaam deel bestaat uit diamant, uit geagglomereerd diamant, uit cermets of uit gesinterde hardmetalen)',
    82081000: 'messen en snijbladen, van onedel metaal, voor machines en voor mechanische toestellen, voor metaalbewerking',
    82082000: 'messen en snijbladen, van onedel metaal, voor machines en voor mechanische toestellen, voor houtbewerking',
    82083000: 'Messen en snijbladen, van onedel metaal, voor keukengereedschap of voor machines voor de voedselindustrie',
    82084000: 'messen en snijbladen, van onedel metaal, voor land-, tuin- of bosbouwmachines (m.u.v. die voor houtbewerking)',
    82089000: 'messen en snijbladen, van onedel metaal, voor machines en voor mechanische toestellen (m.u.v. die voor metaalbewerking, die voor houtbewerking; die voor keukengereedschap of voor machines voor de voedselindustrie, die voor land-, tuin- of bosbouwmachines)',
    82090020: 'wisselplaten voor gereedschap, niet-gemonteerd, vervaardigd van gesinterd metaalcarbide of van cermet',
    82090080: 'plaatjes, staafjes e.d. voorwerpen voor gereedschap, niet-gemonteerd, vervaardigd van gesinterd metaalcarbide of van cermet (m.u.v. wisselplaten)',
    82100000: 'Mechanische toestellen, met de hand aangedreven, van onedel metaal, met een gewicht van <= 10 kg, voor het bereiden, opmaken, enz. van voedingsmiddelen of van dranken',
    82111000: 'stellen van verschillende messen bedoeld bij post 8211; stellen waarvan de meeste onderdelen messen bedoeld bij post 8211 zijn',
    82119100: 'Tafelmessen met vast lemmet van onedel metaal (m.u.v. botermesjes en vismessen)',
    82119200: 'messen met vast lemmet van onedel metaal (m.u.v. hooi- en strostekers, hakmessen en machetes, messen en snijplaten, voor machines en voor mechanische toestellen, tafelmessen, vismessen, botermesjes, scheermessen, mesjes voor scheermessen en messen bedoeld bij post 8214)',
    82119300: 'messen, incl. zaksnoeimessen, van onedel metaal (m.u.v. die met vast lemmet en m.u.v. scheermessen)',
    82119400: 'lemmeten, van onedel metaal, voor tafelmessen, zakmessen en andere messen bedoeld bij post 8211',
    82119500: 'heften van onedel metaal, voor tafelmessen, zakmessen en andere messen bedoeld bij post 8211',
    82121010: 'veiligheidsscheermessen van onedel metaal, onnavulbaar',
    82121090: 'scheermessen en niet-elektrische scheerapparaten, van onedel metaal (m.u.v. niet navulbare veiligheidsscheermessen)',
    82122000: 'mesjes voor veiligheidsscheermessen, incl. niet-afgewerkte scheermesjes, al dan niet in bandvorm, van onedel metaal',
    82129000: 'delen van scheermessen en niet-elektrische scheerapparaten, van onedel metaal (m.u.v. mesjes voor veiligheidsscheermessen, incl. niet-afgewerkte scheermesjes, al dan niet in bandvorm)',
    82130000: 'scharen en schaarbladen, van onedel metaal (m.u.v. heggescharen en andere met twee handen te bedienen scharen; met een hand te bedienen snoeischaren; speciale scharen voor hoefsmeden)',
    82141000: 'papiermesjes, briefopeners, radeermesjes, potloodslijpers en mesjes daarvoor, van onedel metaal (m.u.v. machines en toestellen bedoeld bij hoofdstuk 84)',
    82142000: 'gereedschap voor manicure of voor pedicure, ook indien in stellen, incl. nagelvijltjes, van onedel metaal (m.u.v. gewone scharen)',
    82149000: 'tondeuses en ander messenmakerswerk, n.e.g., van onedel metaal',
    82151020: 'stellen van lepels, vorken of andere artikelen bedoeld bij post 8215, van onedel metaal, die enkel verzilverde, vergulde of geplatineerde artikelen bevatten, ook indien hoogstens de helft van het aantal artikelen uit messen bedoeld bij post 8211 bestaat',
    82151030: 'stellen van lepels, vorken of andere artikelen bedoeld bij post 8215, van roestvrij staal, die ten minste één verzilverd, verguld of geplatineerd artikel bevatten, ook indien hoogstens de helft van het aantal artikelen uit messen bedoeld bij post 8211 bestaat (m.u.v. stellen die enkel verzilverde, vergulde of geplatineerde artikelen bevatten)',
    82151080: 'stellen van lepels, vorken of andere artikelen bedoeld bij post 8215, van onedel metaal, die ten minste één verzilverd, verguld of geplatineerd artikel bevatten, ook indien hoogstens de helft van het aantal artikelen uit messen bedoeld bij post 8211  bestaat (m.u.v. die van roestvrij staal en die welke enkel verzilverde, vergulde of geplatineerde artikelen bevatten)',
    82152010: 'stellen van lepels, vorken of andere artikelen bedoeld bij post 8215, ook indien met een hooguit gelijk aantal messen bedoeld bij post 8211, van roestvrij staal, die geen verzilverde, vergulde of geplatineerde artikelen bevatten',
    82152090: 'stellen van lepels, vorken of andere artikelen bedoeld bij post 8215, ook indien met een hooguit gelijk aantal messen bedoeld bij post 8211, van onedel metaal, die geen verzilverde, vergulde of geplatineerde artikelen bevatten (m.u.v. die van roestvrij staal)',
    82159100: 'lepels, vorken, pollepels, schuimspanen, taartscheppen, vismessen en botermesjes, suikertangen e.d. artikelen, van onedel metaal, verzilverd, verguld of geplatineerd (m.u.v. stellen; kreeft- en wildscharen)',
    82159910: 'lepels, vorken, pollepels, schuimspanen, taartscheppen, vismessen en botermesjes, suikertangen e.d. artikelen, van roestvrij staal (m.u.v. verzilverde, vergulde of geplatineerde artikelen; stellen; kreeft- en wildscharen)',
    82159990: 'lepels, vorken, pollepels, schuimspanen, taartscheppen, vismessen en botermesjes, suikertangen e.d. artikelen, van onedel metaal (m.u.v. die van roestvrij staal en verzilverde, vergulde of geplatineerde artikelen; stellen; kreeft- en wildscharen)',
    83011000: 'hangsloten van onedel metaal',
    83012000: 'sloten van de soort gebruikt voor automobielen, van onedel metaal',
    83013000: 'sloten van de soort gebruikt voor meubelen, van onedel metaal',
    83014011: 'cilindersloten, van de soort gebruikt voor deuren van gebouwen, van onedel metaal',
    83014019: 'sloten van de soort gebruikt voor deuren van gebouwen, van onedel metaal (m.u.v. cilindersloten; hangsloten)',
    83014090: 'grendelsloten en andere sloten, van onedel metaal (m.u.v. hangsloten; sloten van de soort gebruikt voor automobielen, voor meubelen of voor deuren van gebouwen)',
    83015000: 'sluitingen en sluitbeugels, voorzien van een slot, van onedel metaal',
    83016000: 'delen van hangsloten, grendelsloten en andere sloten, alsmede voor van een slot voorziene sluitingen en sluitbeugels, van ondel metaal, n.e.g.',
    83017000: 'sleutels, afzonderlijk aangeboden, voor hangsloten, grendelsloten en andere sloten, alsmede voor van een slot voorziene sluitingen en sluitbeugels, van onedel metaal',
    83021000: 'Scharnieren en andere hengsels, van onedel metaal',
    83022000: 'Zwenkwielen met montuur van onedel metaal',
    83023000: 'garnituren, beslag e.d. artikelen, van onedel metaal, voor automobielen (m.u.v. sloten en scharnieren)',
    83024110: 'Garnituren, beslag e.d. artikelen, voor deuren, van onedel metaal (m.u.v. grendelsloten en andere sloten met sleutel; scharnieren)',
    83024150: 'Garnituren, beslag e.d. artikelen, voor vensters en vensterdeuren, van onedel metaal (m.u.v. grendelsloten en andere sloten met sleutel; scharnieren)',
    83024190: 'Garnituren, beslag e.d. artikelen, voor gebouwen, van onedel metaal (m.u.v. die voor deuren,vensters en vensterdeuren; grendelsloten en andere sloten met sleutel; scharnieren)',
    83024200: 'Garnituren, beslag e.d. artikelen, van onedel metaal, voor meubelen ( m.u.v. grendelsloten en andere sloten met sleutel; scharnieren; zwenkwielen)',
    83024900: 'Garnituren, beslag e.d. artikelen, van onedel metaal (m.u.v. grendelsloten en andere sloten met sleutel; sluitingen en sluitbeugels, voorzien van slot; scharnieren; zwenkwielen; garnituren, beslag e.d. artikelen voor automobielen, voor gebouwen en voor meubelen)',
    83025000: 'hoedhaken, jashaken, kapstokken, plankdragers e.d. artikelen, van onedel metaal',
    83026000: 'Automatische deursluiters en deurdrangers, van onedel metaal',
    83030040: 'Brandkasten, gepantserde deuren en safeloketten voor kluizen, van onedel metaal',
    83030090: 'geldkistjes en -cassetten e.d. artikelen, van onedel metaal (m.u.v. brandkasten, gepantserde deuren en safeloketten voor kluizen)',
    83040000: 'bergkastjes voor kantoorbescheiden, kaartenkasten, opberg- en sorteerdozen, kopijstanders "concepthouders", pennenbakjes, stempelhouders e.d. kantoormaterieel, van onedel metaal (m.u.v. kantoormeubelen bedoeld bij post 9403; papiermanden)',
    83051000: 'mechanismen voor opbergmappen, voor losbladige boeken e.d., van onedel metaal (m.u.v. sluitingen voor boeken en registers)',
    83052000: 'hechtnieten in strippen, van onedel metaal',
    83059000: 'papierhechters, hoekplaatjes, clips, ruitertjes e.d. kantoorbenodigdheden, van onedel metaal, incl. delen van artikelen bedoeld bij post 8305 (m.u.v. complete mechanismen voor opbergmappen, voor losbladige boeken e.d.; hechtnieten in strippen; punaises; sluitingen voor boeken en registers)',
    83061000: 'klokken, bellen, gongs e.d., niet-elektrisch, van onedel metaal (m.u.v. muziekinstrumenten)',
    83062100: 'beeldjes en andere versieringsvoorwerpen, van onedel metaal, verzilverd, verguld of geplatineerd (m.u.v. kunstvoorwerpen, voorwerpen voor verzamelingen en antiquiteiten)',
    83062900: 'Beeldjes en andere versieringsvoorwerpen, van onedel metaal (m.u.v. verzilverde, vergulde of geplatineerde voorwerpen, kunstvoorwerpen, voorwerpen voor verzamelingen en antiquiteiten)',
    83063000: 'lijsten van onedel metaal, voor foto\'s, printen e.d.; spiegels van onedel metaal (m.u.v. optische elementen)',
    83071000: 'Buigzame buizen (slangen), van ijzer of van staal zelfs voorzien van hulpstukken "fittings"',
    83079000: 'Buigzame buizen (slangen), van onedel metaal zelfs voorzien van hulpstukken "fittings" (m.u.v. die van ijzer of van staal)',
    83081000: 'haken en ogen e.d., van onedel metaal, voor kleding, voor schoeisel, voor dekzeilen, voor marokijnwerk en voor alle geconfectioneerde goederen en uitrustingen',
    83082000: 'holle en gespleten klinknagels, van onedel metaal',
    83089000: 'sluitingen en sluitbeugels, zonder slot; gespen, sluitgespen, e.d., van onedel metaal, voor kleding, voor schoeisel, voor dekzeilen, voor marokijnwerk en voor alle geconfectioneerde goederen en uitrustingen, incl. delen van goederen bedoeld bij post 8308, van onedel metaal (m.u.v. haken en ogen; holle en gespleten klinknagels)',
    83091000: 'kroonkurken, van onedel metaal',
    83099010: 'flessencapsules, van lood; flessencapsules, van aluminium, met een diameter van > 21 mm (m.u.v. kroonkurken)',
    83099090: 'Stoppen, incl. schroefstoppen en schenkkurken, deksels, flessencapsules, schroefsponnen, sponblikjes, plombeerblikjes en -loodjes en andere benodigdheden voor verpakkingen, van onedel metaal (m.u.v. kroonkurken; flessencapsules van lood; flessencapsules, van aluminium, met een diameter van > 21 mm)',
    83100000: 'straatnaamborden, uithangborden, naam- en nummerborden e.d. borden, cijfers, nummers, letters, e.d., van onedel metaal, incl. verkeersborden (m.u.v. verlichte aanwijzingsborden bedoeld bij post 9405, drukletters e.d.; aanwijsborden, aanwijsschijven en seinpalen bedoeld bij post 8608)',
    83111000: 'Elektroden, bekleed, van onedel metaal, voor het elektrisch booglassen',
    83112000: 'draad, gevuld, van onedel metaal, voor het elektrisch booglassen',
    83113000: 'staven, bekleed en draad, gevuld, voor het lassen of solderen met de steekvlam, van onedel metaal (m.u.v. draad en staven van kernsoldeer, waarbij het soldeer, afgezien van het bijts- of vulmiddel, bestaat uit een legering die >= 2 gewichtspercent van een edel metaal bevat)',
    83119000: 'draad, staven, buizen, platen, elektroden e.d. artikelen, van onedel metaal of van metaalcarbide, bekleed of gevuld met vloeimiddelen, voor het lassen, solderen of afzetten van metaal of van metaalcarbide, n.e.g.; draad en staven van samengekit poeder van onedel metaal, voor het metaalspuiten "schooperen", incl. delen van artikelen bedoeld bij post 8311 n.e.g.',
    84011000: 'kernreactoren "Euratom"',
    84012000: 'machines en apparaten voor isotopenscheiding en delen daarvan, n.e.g. "Euratom"',
    84013000: 'niet-bestraalde splijtstofelementen "patronen", in een mantel en voorzien voor uitrustingsstukken voor het hanteren ervan, voor kernreactoren "Euratom"',
    84014000: 'delen van kernreactoren, n.e.g. "Euratom"',
    84021100: 'waterpijpketels met een stroomproductie van > 45 ton per uur',
    84021200: 'waterpijpketels met een stroomproductie van <= 45 ton per uur (m.u.v. ketels voor centrale verwarming die zowel heet water als lagedrukstoom kunnen produceren)',
    84021910: 'vlampijpketels (m.u.v. ketels voor centrale verwarming die zowel heet water als lagedrukstoom kunnen produceren)',
    84021990: 'stoomketels, incl. hybride ketels (m.u.v. waterpijpketels, vlampijpketels en ketels voor centrale verwarming die zowel heet water als lagedrukstoom kunnen produceren)',
    84022000: 'ketels voor oververhit water',
    84029000: 'delen van stoomketels en van ketels voor oververhit water, n.e.g.',
    84031010: 'ketels voor centrale verwarming, niet-elektrisch,  van gietijzer (m.u.v. stoomketels en van ketels voor oververhit water als bedoeld bij post 8402)',
    84031090: 'niet-elektrische ketels voor centrale verwarming (m.u.v. die van gietijzer en m.u.v. stoomketels en van ketels voor oververhit water als bedoeld bij post 8402)',
    84039010: 'delen van ketels voor centrale verwarming, van gietijzer, n.e.g.',
    84039090: 'delen van ketels voor centrale verwarming, n.e.g.',
    84041000: 'hulptoestellen voor ketels bedoeld bij post 8402 of 8403 "b.v. voorwarmers "economisers", oververhitters, roetblazers, terugvoerinrichtingen voor vliegas',
    84042000: 'condensors voor stoommachines',
    84049000: 'delen van hulptoestellen voor ketels bedoeld bij post 8402 of 8403 en van condensors voor stoommachines, n.e.g.',
    84051000: 'generatoren voor watergas of voor luchtgas, ook indien met bijbehorende zuiveringstoestellen; acetyleenontwikkelaars e.d. gasgeneratoren werkend met water, ook indien met bijbehorende zuiveringstoestellen (m.u.v. cokesovens, elektrolytisch werkende gasgeneratoren en acetyleenlampen)',
    84059000: 'delen van generatoren voor watergas of voor luchtgas en van acetyleenontwikkelaars e.d. langs natte weg werkende gasgeneratoren, n.e.g.',
    84061000: 'stoomturbines en andere dampturbines, voor de voortstuwing van schepen',
    84068100: 'Stoomturbines en andere dampturbines, met een vermogen van > 40 MW (m.u.v. die voor de voortstuwing van schepen)',
    84068200: 'Stoomturbines en andere dampturbines, met een vermogen van <= 40 MW (m.u.v. die voor de voortstuwing van schepen)',
    84069010: 'loopschoepen, leischoepen en rotoren van stoomturbines en andere dampturbines',
    84069090: 'delen van stoomturbines en andere dampturbines, n.e.g. (m.u.v. loopschoepen, leischoepen en rotoren)',
    84071000: 'Zuigermotoren met vonkontsteking, incl. wankelmotoren, voor luchtvaartuigen',
    84072110: 'Buitenboordmotoren met vonkontsteking, voor de voortstuwing van schepen, met een cilinderinhoud van <= 325 cm³',
    84072191: 'buitenboordmotoren met vonkontsteking, voor de voortstuwing van schepen, met een cilinderinhoud van > 325 m³ en met een vermogen van <= 30 kW',
    84072199: 'buitenboordmotoren met vonkontsteking, voor de voortstuwing van schepen, met een cilinderinhoud van > 325 m³ en met een vermogen van > 30 kW',
    84072900: 'Zuigermotoren met vonkontsteking, incl. wankelmotoren, voor de voortstuwing van schepen (m.u.v. buitenboordmotoren)',
    84073100: 'motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van <= 50 cm³',
    84073210: 'motoren met op- en neergaande zuigers, met vonkontsteking, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van > 50 doch <= 125 cm³',
    84073290: 'motoren met op- en neergaande zuigers, met vonkontsteking, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van > 125 doch <= 250 cm³',
    84073320: 'Motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van > 250, doch <= 500 cm³',
    84073380: 'Motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van > 500, doch <= 1.000 cm³',
    84073410: 'motoren met op- en neergaande zuigers, met een cilinderinhoud van > 1.000 cm³, bestemd voor de industriële montage van motoculteurs van onderverdeling 8701.10, van motorvoertuigen van post 8703, van motorvoertuigen van post 8704 met een motor met een cilinderinhoud van < 2.800 cm³ of van motorvoertuigen van post 8705',
    84073430: 'motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een cilinderinhoud van > 1.000 cm³, gebruikt',
    84073491: 'motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, nieuw, met een cilinderinhoud van > 1.000 cm³, doch <= 1.500 cm³ (m.u.v. die welke zijn bedoeld voor de industriële montage van motoculteurs van onderverdeling 8701.10, van motorvoertuigen van post 8703, van motorvoertuigen van post 8704 met een motor met een cilinderinhoud van < 2.800 cm³ of van motorvoertuigen van post 8705)',
    84073499: 'motoren met op- en neergaande zuigers, voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, nieuw, met een cilinderinhoud van > 1.500 cm³ (m.u.v. die welke zijn bedoeld voor de industriële montage van motoculteurs van onderverdeling 8701.10, van motorvoertuigen van post 8703, van motorvoertuigen van post 8704 met een motor met een cilinderinhoud van < 2.800 cm³ of van motorvoertuigen van post 8705)',
    84079010: 'zuigermotoren met vonkontsteking, incl. wankelmotoren, met een cilinderinhoud van < 250 cm³ (m.u.v. die voor luchtvaartuigen, voor de voortstuwing van schepen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84079050: 'zuigermotoren met vonkontsteking, incl. wankelmotoren, met een cilinderinhoud van > 250 cm³, bestemd voor de industriële montage van motoculteurs van onderverdeling 8701.10, van motorvoertuigen van post 8703, van motorvoertuigen van post 8704 met een motor met een cilinderinhoud van < 2.800 cm³ of van motorvoertuigen van post 8705',
    84079080: 'Zuigermotoren met vonkontsteking, incl. wankelmotoren, met een cilinderinhoud van > 250 cm³, met een vermogen van <= 10 kW (m.u.v. die bedoeld bij onderverdeling 8407.90.50; die voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87; die voor luchtvaartuigen; die voor de voortstuwing van schepen)',
    84079090: 'Zuigermotoren met vonkontsteking, incl. wankelmotoren, met een cilinderinhoud van > 250 cm³, met een vermogen van > 10 kW (m.u.v. die bedoeld bij onderverdeling 8407.90.50; die voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87; die voor luchtvaartuigen; die voor de voortstuwing van schepen)',
    84081011: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, gebruikt',
    84081019: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", gebruikt (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081023: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van <= 50 kW',
    84081027: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van <= 50 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081031: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 50 doch <= 100 kW',
    84081039: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 50 doch <= 100 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081041: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 100 doch <= 200 kW',
    84081049: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 100 doch <= 200 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081051: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 200 doch <= 300 kW',
    84081059: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 200 doch <= 300 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081061: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 300 doch <= 500 kW',
    84081069: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 300 doch <= 500 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081071: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 500 doch <= 1000 kW',
    84081079: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 500 doch <= 1000 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081081: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 1000 doch <= 5000 kW',
    84081089: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 1000 doch <= 5000 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84081091: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", voor de voortstuwing van zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00, nieuw, met een vermogen van > 5000 kW',
    84081099: 'Zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", nieuw, met een vermogen van > 5000 kW (m.u.v. die voor zeeschepen bedoeld bij de posten 8901 t/m 8906, sleepboten bedoeld bij onderverdeling 8904.00.10 en oorlogsschepen bedoeld bij onderverdeling 8906.10.00)',
    84082010: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", bestemd voor de industriële montage van motoculteurs van onderverdeling 8701.10, van motorvoertuigen van post 8703, van motorvoertuigen van post 8704 met een motor met een cilinderinhoud van < 2800 cm³ of van motorvoertuigen van post 8705',
    84082031: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor land- en bosbouwtractors op wielen, met een vermogen van <= 50 kW',
    84082035: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor land- en bosbouwtractors op wielen, met een vermogen van > 50, doch <= 100 kW',
    84082037: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor land- en bosbouwtractors op wielen, met een vermogen van > 100 kW',
    84082051: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een vermogen van <= 50 kW (m.u.v. motoren bedoeld bij onderverdeling 8408.20.10 en motoren voor land- en bosbouwtractors op wielen)',
    84082055: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een vermogen van > 50, doch <= 100 kW (m.u.v. motoren bedoeld bij onderverdeling 8408.20.10 en motoren voor land- en bosbouwtractors op wielen)',
    84082057: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een vermogen van > 100, doch <= 200 kW (m.u.v. motoren bedoeld bij onderverdeling 8408.20.10 en motoren voor land- en bosbouwtractors op wielen)',
    84082099: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87, met een vermogen van > 200 kW (m.u.v. motoren bedoeld bij onderverdeling 8408.20.10 en motoren voor land- en bosbouwtractors op wielen)',
    84089021: 'zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", voor de voortbeweging van spoorvoertuigen',
    84089027: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", gebruikt (m.u.v. motoren voor de voortstuwing van schepen , voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089041: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van <= 15 kW  (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089043: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 15, doch <= 30 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089045: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 30, doch <= 50 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089047: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 50, doch <= 100 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089061: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 100, doch <= 200 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089065: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 200, doch <= 300 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089067: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 300, doch <= 500 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089081: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 500, doch <= 1.000 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089085: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 1.000, doch <= 5.000 kW (m.u.v. motoren die voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84089089: 'Zuigermotoren met zelfontsteking "diesel en semi-dieselmotoren", nieuw, met een vermogen van > 5.000 kW (m.u.v. motoren voor de voortstuwing van schepen, voor de voortbeweging van spoorvoertuigen of voor de voortbeweging van voertuigen bedoeld bij hoofdstuk 87)',
    84091000: 'Delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zuigermotoren, voor luchtvaart, n.e.g.',
    84099100: 'delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zuigermotoren met vonkontsteking, n.e.g.',
    84099900: 'delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zuigermotoren met zelfontsteking "diesel- en semi-dieselmotoren", n.e.g.',
    84101100: 'waterturbines en waterraderen "waterwielen", met een vermogen van <= 1.000 kW (m.u.v. hydraulische motoren en hydraulische krachtmachines bedoeld bij post 8412)',
    84101200: 'waterturbines en waterraderen "waterwielen", met een vermogen van > 1.000, doch <= 10.000 kW (m.u.v. hydraulische motoren en hydraulische krachtmachines bedoeld bij post 8412)',
    84101300: 'waterturbines en waterraderen "waterwielen", met een vermogen van > 10.000 kW (m.u.v. hydraulische motoren en hydraulische krachtmachines bedoeld bij post 8412)',
    84109000: 'Delen van waterturbines en waterraderen "waterwielen", alsmede regulators daarvoor',
    84111100: 'Turbinestraalmotoren met een stuwkracht van <= 25 kN',
    84111210: 'Turbinestraalmotoren met een stuwkracht van > 25, doch <= 44 kN',
    84111230: 'Turbinestraalmotoren  met een stuwkracht van > 44, doch <= 132 kN',
    84111280: 'Turbinestraalmotoren  met een stuwkracht van > 132 kN',
    84112100: 'Schroefturbines met een vermogen van <= 1.100 kW',
    84112220: 'Schroefturbines  met een vermogen van > 1.100, doch <= 3.730 kW',
    84112280: 'Schroefturbines  met een vermogen van > 3.730 kW',
    84118100: 'Gasturbines met een vermogen van <= 5.000 kW (m.u.v. turbinestraalmotoren en schroefturbines)',
    84118220: 'Gasturbines met een vermogen van > 5.000, doch <= 20.000 kW (m.u.v. turbinestraalmotoren en schroefturbines)',
    84118260: 'Gasturbines met een vermogen van > 20.000, doch <= 50.000 kW ( m.u.v. turbinestraalmotoren en schroefturbines)',
    84118280: 'Gasturbines met een vermogen van > 50.000 kW (m.u.v. turbinestraalmotoren en schroefturbines)',
    84119100: 'Delen van turbinestraalmotoren of van schroefturbines, n.e.g.',
    84119900: 'Delen van gasturbines, n.e.g.',
    84121000: 'Straalmotoren (reactiemotoren),  (m.u.v. turbinestraalmotoren)',
    84122120: 'Hydraulische systemen met hydraulische cilinders als werkend orgaan, rechtlijnig werkend',
    84122180: 'Hydraulische motoren, rechtlijnig werkend (cilinders) (m.u.v. hydraulische systemen)',
    84122920: 'Hydraulische systemen met hydraulische motoren als werkend orgaan (m.u.v. hydraulische motoren en hydraulische krachtmachines, rechtlijnig werkend (cilinders))',
    84122981: 'Hydraulische motoren (m.u.v. hydraulische motoren en hydraulische krachtmachines, rechtlijnig werkend (cilinders) en hydraulische systemen)',
    84122989: 'Hydraulische krachtmachines (m.u.v. hydraulische motoren, incl. hydraulische motoren en hydraulische krachtmachines, rechtlijnig werkend (cilinders), waterturbines en waterraderen (waterwielen) bedoeld bij post 8410 en stoomturbines en andere dampturbines)',
    84123100: 'Pneumatische motoren en pneumatische krachtmachines, rechtlijnig werkend (cilinders)',
    84123900: 'Pneumatische motoren en pneumatische krachtmachines (m.u.v. van die welke rechtlijnig werkend (cilinders))',
    84128010: 'stoommachines en krachtwerktuigen voor andere dampen (m.u.v. stoomketels "stoomgeneratoren" en stoomturbines en andere dampturbines)',
    84128080: 'Motoren en krachtmachines, niet-elektrisch (m.u.v. stoomturbines en andere dampturbines, zuigermotoren, waterturbines en waterraderen (waterwielen), gasturbines, straalmotoren (reactiemotoren), hydraulische motoren, hydraulische krachtmachines, pneumatische motoren, pneumatische krachtmachines, stoommachines en krachtwerktuigen voor andere dampen en elektromotoren)',
    84129020: 'Delen van straalmotoren (reactiemotoren), n.e.g. (m.u.v. die van turbinestraalmotoren)',
    84129040: 'Delen van hydraulische motoren en van hydraulische krachtmachines, n.e.g.',
    84129080: 'Delen van motoren en van krachtmachines, niet elektrisch, n.e.g.',
    84131100: 'distributiepompen voorzien van een meettoestel of ingericht om van een meettoestel te worden voorzien, voor motorbrandstof of voor smeermiddelen, van de soort gebruikt in benzinestations en garages',
    84131900: 'Pompen voor vloeistoffen, voorzien van een meettoestel of ingericht om van een meettoestel te worden voorzien (m.u.v. distributiepompen voor motorbrandstof of voor smeermiddelen, van de soort gebruikt in benzinestations en garages)',
    84132000: 'Handpompen voor vloeistoffen (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19)',
    84133020: 'Injectiepompen voor explosiemotoren of voor verbrandingsmotoren',
    84133080: 'Brandstof-, olie- en koelvloeistofpompen, voor explosiemotoren of voor verbrandingsmotoren (m.u.v. injectiepompen)',
    84134000: 'betonpompen',
    84135020: 'Hydraulische aggregaten met oscillerende verdringerpompen als belangrijkste onderdeel',
    84135040: 'Oscillerende doseerpompen voor vloeistoffen, mechanisch aangedreven',
    84135061: 'Hydraulische zuiger- en plunjerpompen voor vloeistoffen (m.u.v. hydraulische aggregaten)',
    84135069: 'Zuiger- en plunjerpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistofpompen voor explosiemotoren of voor verbrandingsmotoren, betonpompen, hydraulische pompen, incl. hydraulische aggregaten en doseerpompen)',
    84135080: 'Oscillerende verdringerpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistofpompen voor explosiemotoren of voor verbrandingsmotoren, betonpompen, hydraulische aggregaten, doseerpompen en zuiger- en plunjerpompen)',
    84136020: 'Roterende aggregaten met oscillerende verdringerpompen als belangrijkste onderdeel',
    84136031: 'Hydraulische tandwielpompen voor vloeistoffen (m.u.v. hydraulische aggregaten)',
    84136039: 'Tandwielpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, hydraulische pompen, incl. hydraulische aggregaten)',
    84136061: 'Hydraulische schottenpompen voor vloeistoffen (m.u.v. hydraulische aggregaten)',
    84136069: 'schottenpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, hydraulische pompen, incl. hydraulische aggregaten)',
    84136070: 'Schroefspindelpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, betonpompen en hydraulische aggregaten)',
    84136080: 'Roterende verdringerpompen voor vloeistoffen, mechanisch aangedreven (m.u.v. betonpompen, tandwielpompen, schottenpompen, schroefspindelpompen en hydraulische aggregaten)',
    84137021: 'onderwaterpompen, eentraps',
    84137029: 'onderwaterpompen, meertraps',
    84137030: 'circulatiepompen voor centrale verwarming of voor warm water',
    84137035: 'Centrifugaalpompen voor vloeistoffen, mechanisch aangedreven, met een uitlaatopening met een doorsnede van <= 15 mm (m.u.v. brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren en onderwaterpompen)',
    84137045: 'Kanaalwaaierpompen en zijkanaalpompen voor vloeistoffen',
    84137051: 'Centrifugaalpompen voor vloeistoffen, radiale pompen met een uitlaatopening met een doorsnede van > 15 mm, eentraps, met eenzijdige instroming, monobloc (m.u.v. brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, betonpompen, onderwaterpompen en circulatiepompen voor centrale verwarming of voor warm water)',
    84137059: 'Centrifugaalpompen voor vloeistoffen, radiale pompen met een uitlaatopening met een doorsnede van > 15 mm, eentraps, met eenzijdige instroming, geen monobloc (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19)',
    84137065: 'Centrifugaalpompen voor vloeistoffen, radiale pompen met een uitlaatopening met een doorsnede van > 15 mm, eentraps, met meerzijdige instroming, geen monobloc (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19 en onderwaterpompen)',
    84137075: 'Centrifugaalpompen voor vloeistoffen, radiale pompen met een uitlaatopening met een doorsnede van > 15 mm, meertraps, met meerzijdige instroming, geen monobloc (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19 en onderwaterpompen)',
    84137081: 'Centrifugaalpompen voor vloeistoffen, mechanisch aangedreven, met een uitlaatopening met een doorsnede van > 15 mm, eentraps (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, betonpompen, onderwaterpompen, circulatiepompen voor centrale verwarming of voor warm water, kanaalwaaierpompen, zijkanaalpompen en radiale pompen)',
    84137089: 'Centrifugaalpompen voor vloeistoffen, mechanisch aangedreven, met een uitlaatopening met een doorsnede van > 15 mm, meertraps (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19, brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, betonpompen, onderwaterpompen, circulatiepompen voor centrale verwarming of voor warm water, kanaalwaaierpompen, zijkanaalpompen en radiale pompen)',
    84138100: 'Vloeistofpompen, mechanisch aangedreven (m.u.v. pompen bedoeld bij onderverdeling 8413.11 of 8413.19 en m.u.v. brandstof-, olie- en koelvloeistof voor explosiemotoren of voor verbrandingsmotoren, betonpompen en oscillerende of roterende verdringerpompen en centrifugaalpompen van alle soorten)',
    84138200: 'elevatoren voor vloeistoffen (m.u.v. pompen)',
    84139100: 'Delen van pompen voor vloeistoffen, n.e.g.',
    84139200: 'delen van elevatoren voor vloeistoffen',
    84141015: 'Vacuümpompen die worden gebruikt voor de vervaardiging van halfgeleiders of uitsluitend of hoofdzakelijk worden gebruikt voor de vervaardiging van platte beeldschermen',
    84141025: 'Draaizuigerpompen, schottenpompen, moleculaire pompen en rootspompen (excl. vacuümpompen bestemd voor de vervaardiging van halfgeleiders of van platte beeldschermen)',
    84141081: 'Diffusiepompen, cryopompen en adsorptiepompen (excl. vacuümpompen bestemd voor de vervaardiging van halfgeleiders of van paltte beeldschermen)',
    84141089: 'Andere vacuümpompen dan draaizuigerpompen, schottenpompen, moleculaire pompen, rootspompen, diffusiepompen, cryopompen en adsorptiepompen en pompen bestemd voor de vervaardiging van halfgeleiders of platte beeldschermen',
    84142020: 'Handbediende pompen voor rijwielen',
    84142080: 'Hand- en voetpompen, voor lucht (m.u.v. handbediende pompen voor rijwielen)',
    84143020: 'Compressoren van het soort gebruikt in koelinstallaties, met een vermogen van <= 0,4 kW',
    84143081: 'Compressoren van het soort gebruikt in koelinstallaties, met een vermogen van > 0,4 kW, hermetisch of half-hermetisch',
    84143089: 'Compressoren van het soort gebruikt in koelinstallaties, met een vermogen van > 0,4 kW (m.u.v. hermetische of half-hermetische compressoren)',
    84144010: 'luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken, met een capaciteit per minuut van <= 2 m³',
    84144090: 'luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken, met een capaciteit per minuut van > 2 m³',
    84145100: 'Tafel-, vloer-, wand-, raam-, plafond- en dakventilatoren, met ingebouwde elektromotor met een vermogen van <= 125 W',
    84145915: 'Ventilatoren, van de soort uitsluitend of hoofdzakelijk gebruikt voor de koeling van microprocessoren, telecommunicatietoestellen, automatische gegevensverwerkende machines of eenheden van automatische gegevensverwerkende machines',
    84145925: 'Axiale ventilatoren (m.u.v. tafel-, vloer-, wand-, raam-, plafond- en dakventilatoren, met ingebouwde elektromotor met een vermogen van <= 125 W en m.u.v. ventilatoren voor koeling van IT apparatuur vermeld bij code 8414 59 15)',
    84145935: 'centrifugale ventilatoren (m.u.v. tafel-, vloer-, wand-, raam-, plafond- en dakventilatoren, met ingebouwde elektromotor met een vermogen van <= 125 W en m.u.v. ventilatoren voor koeling van IT apparatuur vermeld bij code 8414 59 15)',
    84145995: 'Ventilatoren (m.u.v. tafel-, vloer-, wand-, raam-, plafond- en dakventilatoren, met ingebouwde elektromotor met een vermogen van <= 125 W, axiale en centrifugale ventilatoren, en m.u.v. ventilatoren voor koeling van IT apparatuur vermeld bij code 8414 59 15)',
    84146000: 'damp- of wasemafzuigkappen met ingebouwde ventilator, ook indien met filter, waarvan de langste horizontale zijde een lengte heeft van <= 120 cm',
    84148011: 'Turbocompressoren, eentraps (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148019: 'Turbocompressoren, meertraps (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148022: 'Zuigerverdringercompressoren waarmede een overdruk kan worden verkregen van <= 15 bar, en een capaciteit per uur van <= 60 m³ (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148028: 'Zuigerverdringercompressoren waarmede een overdruk kan worden verkregen van <= 15 bar, en een capaciteit per uur van > 60 m³ (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148051: 'Zuigerverdringercompressoren waarmede een overdruk kan worden verkregen van > 15 bar, en een capaciteit per uur van <= 120 m³ (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148059: 'Zuigerverdringercompressoren waarmede een overdruk kan worden verkregen van > 15 bar, en een capaciteit per uur van > 120 m³ (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148073: 'Roterende verdringercompressoren met een as (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148075: 'Schroefcompressoren met meer assen (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84148078: 'Roterende verdringercompressoren met meer assen (m.u.v. compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken en m.u.v. schroefcompressoren)',
    84148080: 'Luchtpompen, compressoren voor lucht of voor andere gassen; damp- of wasemafzuigkappen met ingebouwde ventilator, ook indien met filter, waarvan de langste horizontale zijde een lengte heeft van > 120 cm (m.u.v. vacuümpompen, hand- en voetpompen, voor lucht, compressoren van de soort gebruikt in koelinstallaties en luchtcompressoren gemonteerd op een verrijdbaar onderstel dat is ingericht om door een voertuig te worden getrokken)',
    84149000: 'Delen van luchtpompen, van vacuümpompen, van compressoren voor lucht of voor andere gassen, voor ventilatoren, of voor damp- of wasemafzuigkappen met ingebouwde ventilator, n.e.g.',
    84151010: 'machines en apparaten voor de regeling van het klimaat in besloten ruimten, van de soort die aan muren of vensters wordt bevestigd en die uit een enkele eenheid bestaat',
    84151090: 'machines en apparaten voor de regeling van het klimaat in besloten ruimten, van de soort die aan muren of vensters wordt bevestigd, van het type "split-systeem" "systemen met afzonderlijke elementen"',
    84152000: 'machines en apparaten voor de regeling van het klimaat in besloten ruimten, van de soort gebruikt voor het comfort van personen in motorvoertuigen',
    84158100: 'Machines en apparaten voor de regeling van het klimaat in besloten ruimten, uitgerust met een koeltechnische inrichting en voorzien van een klep voor het omkeren van de werking daarvan (omkeerbare warmtepompen) (m.u.v. die van de soort die aan muren of vensters worden bevestigd en die uit een enkele eenheid bestaat of van het type "split-systeem" (systemen met afzonderlijke elementen), en die van de soort gebruikt voor het comfort van personen in motorvoertuigen)',
    84158200: 'Machines en apparaten voor de regeling van het klimaat in besloten ruimten, uitgerust met een koeltechnische inrichting, maar zonder klep voor het omkeren van de werking daarvan (m.u.v. die van de soort die aan muren of vensters worden bevestigd en die uit een enkele eenheid bestaat of van het type "split-systeem" (systemen met afzonderlijke elementen), en die van de soort gebruikt voor het comfort van personen in motorvoertuigen)',
    84158300: 'Machines en apparaten voor de regeling van het klimaat in besloten ruimten, uitgerust met een door een motor aangedreven ventilator, zonder koeltechnische inrichting, doch voorzien van elementen voor het wijzigen van de temperatuur en de vochtigheid van de lucht (m.u.v. die van de soort die aan muren of vensters worden bevestigd en die uit een enkele eenheid bestaat of van het type "split-systeem" (systemen met afzonderlijke elementen), en die van de soort gebruikt voor het comfort van personen in motorvoertuigen)',
    84159000: 'Delen van machines en apparaten voor de regeling van het klimaat in besloten ruimten, bestaande uit een door een motor aangedreven ventilator, voorzien van elementen voor het wijzigen van de temperatuur en de vochtigheid van de lucht n.e.g.',
    84161010: 'branders voor het stoken van vuurhaarden met vloeibare brandstof, met automatische controle-inrichting',
    84161090: 'branders voor het stoken van vuurhaarden met vloeibare brandstof (m.u.v. die met automatische controle-inrichting)',
    84162010: 'branders enkel voor gas "monobloc" met ingebouwde ventilator en controle-inrichting',
    84162020: 'Branders voor meer dan een soort brandstof met verpoederde vaste brandstof of met gas',
    84162080: 'Branders voor het stoken van vuurhaarden met verpoederde vaste brandstof of met gas (m.u.v. die enkel voor gas "monobloc" met ingebouwde ventilator en controle-inrichting en branders voor meer dan een soort brandstof)',
    84163000: 'automatische stookinrichtingen, alsmede daarvoor bestemde werp- en schroefstokers, mechanische roosters, mechanische toestellen voor het verwijderen van as e.d. inrichtingen (m.u.v. branders)',
    84169000: 'delen van branders voor het stoken van vuurhaarden, van automatische stookinrichtingen, van de daarvoor bestemde werp- en schroefstokers, van mechanische roosters, van mechanische toestellen voor het verwijderen van as en van dergelijke inrichtingen, n.e.g.',
    84171000: 'niet-elektrische roostovens en gietovens voor ertsen, niet-elektrische smeltovens voor metalen en andere niet-elektrische ovens voor thermische behandeling van ertsen of van metalen (m.u.v. droogovens)',
    84172010: 'niet-elektrische tunnelovens voor bakkerijen',
    84172090: 'niet-elektrische bakkersovens (m.u.v. tunnelovens)',
    84178030: 'Ovens voor het bakken van keramische producten',
    84178050: 'Ovens voor het bakken van cement, glazen of chemische producten',
    84178070: 'Niet-elektrische industriële ovens en niet-elektrische ovens voor laboratoria, incl. verbrandingsovens (m.u.v. roostovens en gietovens voor ertsen, smeltovens voor metalen en andere ovens voor thermische behandeling van ertsen of van metalen, bakkersovens, droogovens, ovens voor het bakken van keramische producten, ovens voor het bakken van cement, glazen of chemische producten en ovens voor het kraken van petroleum)',
    84179000: 'delen van niet-elektrische industriële ovens, van niet-elektrische ovens voor laboratoria, incl. die van ovens voor verbranding van afval, n.e.g.',
    84181020: 'Koelkast-vrieskastcombinaties, voorzien van afzonderlijke buitendeuren, met een capaciteit van > 340 l',
    84181080: 'Koelkast-vrieskastcombinaties, voorzien van afzonderlijke buitendeuren, met een capaciteit van <= 340 l',
    84182110: 'koelkasten met compressiekoelmachine voor huishoudelijk gebruik, met een capaciteit van > 340 l',
    84182151: 'koelkasten met compressiekoelmachine voor huishoudelijk gebruik, tafelmodel',
    84182159: 'koelkasten met compressiekoelmachine voor huishoudelijk gebruik, om te worden ingebouwd',
    84182191: 'koelkasten met compressiekoelmachine voor huishoudelijk gebruik, met een capaciteit van <= 250 l (m.u.v. tafelmodellen en koelkasten bestemd om te worden ingebouwd)',
    84182199: 'koelkasten met compressiekoelmachine voor huishoudelijk gebruik, met een capaciteit van > 250, doch <= 340 l (m.u.v. tafelmodellen en koelkasten bestemd om te worden ingebouwd)',
    84182900: 'koelkasten met absorptiekoelmachine, voor huishoudelijk gebruik',
    84183020: 'Vrieskisten met een capaciteit van <= 400 l',
    84183080: 'Vrieskisten met een capaciteit van > 400 l, doch <= 800 l',
    84184020: 'Vrieskasten met een capaciteit van <= 250 l',
    84184080: 'Vrieskasten met een capaciteit van > 250, doch <= 900 l',
    84185011: 'koelvitrines en koeltoonbanken, met ingebouwde koelgroep of verdamper, voor diepvriesproducten',
    84185019: 'koelvitrines en koeltoonbanken, met ingebouwde koelgroep of verdamper (m.u.v. die voor diepvriesproducten)',
    84185090: 'Koelvriesmeubelen met ingebouwde koelgroep of verdamper (m.u.v. koelkast-vrieskastcombinaties voorzien van afzonderlijke buitendeuren, koelkasten voor huishoudelijk gebruik, koelvitrines, koeltoonbanken)',
    84186100: 'Warmtepompen (m.u.v. machines en apparaten voor de regeling van het klimaat bedoeld bij post 8415)',
    84186900: 'Machines, apparaten en toestellen voor de koeltechniek (m.u.v. koel- en vriesmeubelen)',
    84189100: 'meubelen ingericht om van een koeltechnische inrichting te worden voorzien',
    84189910: 'verdampers en condensors, voor koelmachines (m.u.v. die voor apparaten voor huishoudelijk gebruik)',
    84189990: 'delen van koelkasten, vrieskasten, en andere machines, apparaten en andere toestellen voor de koeltechniek en van warmtepompen, n.e.g.',
    84191100: 'niet-elektrische gasgeisers "doorstroomtoestellen" (m.u.v. ketels voor centrale verwarming)',
    84191900: 'niet-elektrische geisers "doorstroomtoestellen" en andere niet-elektrische heetwatertoestellen "voorraadtoestellen of boilers" (m.u.v. gasgeisers en ketels voor centrale verwarming)',
    84192000: 'sterilisatoren voor medisch, chirurgisch of laboratoriumgebruik',
    84193100: 'droogtoestellen voor landbouwproducten',
    84193200: 'droogtoestellen voor hout, papierstof, papier of karton',
    84193900: 'Droogtoestellen (m.u.v. die voor landbouwproducten, hout, papierstof, papier, karton, garens, weefsels en andere artikelen van textiel, die voor flessen of andere bergingsmiddelen en m.u.v. haardroogtoestellen, toestellen voor het drogen van de handen en huishoudelijke apparaten)',
    84194000: 'distilleertoestellen en rectificeertoestellen',
    84195020: 'warmtewisselaars, vervaardigd van fluorpolymeren, met in- en uitlaatbuizen met een binnendiameter van 3 cm of minder',
    84195080: 'warmtewisselaars (m.u.v. die vervaardigd van fluorpolymeren, met in- en uitlaatbuizen met een binnendiameter van <= 3 cm en die gebruikt voor boilers)',
    84196000: 'apparaten en inrichtingen voor het vloeibaar maken van lucht of van andere gassen',
    84198120: 'Percolators en andere apparaten voor het bereiden van koffie en andere warme dranken (m.u.v. huishoudapparaten)',
    84198180: 'Toestellen, apparaten en inrichtingen voor het koken, bakken of opwarmen van spijzen ( m.u.v. percolators en andere apparaten voor het bereiden van warme dranken en m.u.v. huishoudapparaten)',
    84198910: 'koelapparaten en -inrichtingen met circulatie van eigen water waarbij de warmteuitwisseling niet plaatsvindt via een scheidingswand',
    84198930: 'koelapparaten en -inrichtingen voor het onder vacuüm opdampen van metalen',
    84198998: 'toestellen, apparaten en inrichtingen, ook indien elektrisch verhit, voor de behandeling van stoffen, volgens werkwijzen waarbij temperatuurverandering nodig is, n.e.g.',
    84199015: 'delen van sterilisatoren voor medisch, chirurgisch of laboratoriumgebruik, n.e.g.',
    84199085: 'Delen van toestellen, apparaten en inrichtingen, ook indien elektrisch verhit, voor de behandeling van stoffen volgens werkwijzen waarbij temperatuurverandering nodig is en van niet elektrische geisers "doorstroomtoestellen" en andere niet elektrische heetwatertoestellen "voorraadtoestellen of boilers", n.e.g. (m.u.v. die van sterilisatoren bedoeld bij onderverdeling 8419.20.00, die van apparaten voor het kortstondig verhitten van of voor opdampen, ook indien chemisch, dan wel door een elektronenbundel aanbrengen van lagen op schijven "wafers" van halfgeleidermateriaal, die van apparaten voor het door chemisch opdampen aanbrengen van lagen op platen of bladen voorzien van substraten met vloeibare kristallen "LCD" en die van ovens en andere toestellen bedoeld bij post 8514)',
    84201010: 'kalanders en walsmachines van de soort gebruikt in de textielindustrie',
    84201030: 'kalanders en walsmachines van de soort gebruikt in de papierindustrie',
    84201081: 'Walsmachines van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van substraten voor gedrukte schakelingen of van gedrukte schakelingen',
    84201089: 'kalanders en walsmachines (m.u.v. die van de soort die uitsluitend of hoofdzakelijk gebruikt voor de vervaardiging van substraten voor gedrukte schakelingen of van gedrukte schakelingen en m.u.v. die voor metalen of voor glas en die van de soort gebruikt in de textiel-,  papierindustrie)',
    84209110: 'cilinders voor kalanders of voor walsmachines, van gietijzer (m.u.v. die voor kalanders en walsmachines voor metalen of voor glas)',
    84209180: 'cilinders voor kalanders of voor walsmachines (m.u.v. die van gietijzer; die voor kalanders en walsmachines voor metalen of voor glas)',
    84209900: 'delen van kalanders en walsmachines, n.e.g. (m.u.v. die voor kalanders en walsmachines voor metalen of voor glas en m.u.v. cilinders)',
    84211100: 'melkontromers "melkcentrifuges"',
    84211200: 'centrifuges voor wasgoed',
    84211920: 'Centrifuges van de soort gebruikt in laboratoria',
    84211970: 'Centrifuges, incl. centrifugaaldrogers (m.u.v. die voor isotopenscheiding, die voor wasgoed, die van de soort gebruikt in laboratoria en die voor het aanbrengen van een lichtgevoelige laag op schijven (wafers) van halfgeleidermateriaal en m.u.v. melkontromers)',
    84212100: 'Toestellen voor het filtreren of zuiveren van water',
    84212200: 'toestellen voor het filtreren of zuiveren van dranken (m.u.v. die voor het filtreren of zuiveren van water)',
    84212300: 'Smeerolie- en brandstoffilters voor explosiemotoren of voor verbrandingsmotoren',
    84212920: 'Toestellen bestemd voor het filtreren of zuiveren van vloeistoffen, gemaakt van fluoropolymeren met filter- of zuivermembranen met een dikte <= 140  μm (exclusief die voor water en dranken en kunstmatige nieren)',
    84212980: 'Toestellen bestemd voor het filtreren of zuiveren van vloeistoffen (excl. die gemaakt van fluoropolymeren met filter- of zuivermembranen met een dikte <= 140  μm, voor water en dranken, smeerolie- of brandstoffilters voor explosiemotoren ofverbrandingsmotoren en kunstmatige nieren)',
    84213100: 'Luchtfilters voor explosiemotoren of voor verbrandingsmotoren',
    84213915: 'Toestellen voor het filtreren of zuiveren van gassen met een behuizing van roestvrij staal en in- en uitlaatbuizen met een binnendiameter van <= 1,3 cm.',
    84213925: 'Toestellen voor het filtreren of zuiveren van lucht (m.u.v. inlaatluchtfilters voor explosiemotoren of voor verbrandingsmotoren en m.u.v. die met een behuizing van roestvrij staal en in- en uitlaatbuizen met een binnendiameter van <= 1,3 cm)',
    84213935: 'Toestellen voor het filtreren of zuiveren van gassen andere dan lucht, katalytisch werkend(excl. die met een behuizing van roestvrij staal en in- en uitlaatbuizen met een binnendiameter van <= 1,3 cm)',
    84213985: 'toestellen voor het filtreren of zuiveren van gassen andere dan lucht (excl. Isotopenscheiders, die  katalytisch werkend en die met een behuizing van roestvrij staal en in- en uitlaatbuizen met een binnendiameter van <= 1,3 cm)',
    84219100: 'Delen van centrifuges en centrifugaaldrogers, n.e.g.',
    84219910: 'Delen van machines en apparaten van GN-codes 84212920 of 84213915, n.e.g.',
    84219990: 'Delen van toestellen voor het filtreren of zuiveren van vloeistoffen of gassen, n.e.g.',
    84221100: 'machines voor het afwassen van vaatwerk, voor huishoudelijk gebruik',
    84221900: 'machines voor het afwassen van vaatwerk (m.u.v. die voor huishoudelijk gebruik)',
    84222000: 'machines en toestellen voor het reinigen of het drogen van flessen en andere bergingsmiddelen (m.u.v. machines voor het afwassen van vaatwerk)',
    84223000: 'machines en toestellen voor het vullen, sluiten of etiketteren van flessen, van bussen, van zakken of van andere bergingsmiddelen; machines en toestellen voor het capsuleren van flessen, van potten, van tubes en van dergelijke bergingsmiddelen; toestellen voor het persen van koolzuur in dranken',
    84224000: 'verpakkingsmachines, incl. krimpverpakkingsmachines en -toestellen (m.u.v. machines en toestellen voor het vullen, sluiten of etiketteren van flessen, van bussen, van zakken of van andere bergingsmiddelen of voor het capsuleren van flessen, van potten, van tubes en van dergelijke bergingsmiddelen)',
    84229010: 'delen van machines voor het afwassen van vaatwerk, n.e.g.',
    84229090: 'delen van machines en toestellen voor het reinigen of het drogen van flessen en andere bergingsmiddelen, van machines en toestellen voor het vullen, sluiten, capsuleren of etiketteren van flessen, van bussen, van zakken of van andere bergingsmiddelen, van andere verpakkingsmachines en van toestellen voor het persen van koolzuur in dranken, n.e.g. (m.u.v. delen van machines voor het afwassen van vaatwerk)',
    84231010: 'huishoudweegschalen (m.u.v. personenweegtoestellen en babyweegtoestellen)',
    84231090: 'personenweegtoestellen, incl. babyweegtoestellen',
    84232010: 'Toestellen voor het continu wegen van goederen op transportbanden die gebruik maken van elektronische middelen voor het wegen',
    84232090: 'Toestellen voor het continu wegen van goederen op transportbanden die geen gebruik maken van elektronische middelen voor het wegen',
    84233010: 'Toestellen voor het afwegen van constante hoeveelheden en weegtoestellen voor het vullen van zakken of andere bergingsmiddelen die gebruik maken van elektronische hulpmiddelen voor het wegen',
    84233090: 'Toestellen voor het afwegen van constante hoeveelheden en weegtoestellen voor het vullen van zakken of andere bergingsmiddelen die geen gebruik maken van elektronische hulpmiddelen voor het wegen',
    84238121: 'Gewichtssorteermachines en automatische controleweegtoestellen die het gewicht toetsen aan een standaardgewicht, met een weegvermogen van <= 30 kg,  die gebruik maken van elektronische middelen voor het wegen',
    84238123: 'Machines voor het wegen en etiketteren van verpakte goederen, met een weegvermogen van <= 30 kg, die gebruik maken van elektronische middelen voor het wegen',
    84238125: 'Weegschalen voor toonbankgebruik, met een weegvermogen van <= 30 kg (m.u.v. toestellen en inrichtingen voor het wegen en etiketteren van verpakte goederen) die gebruik maken van elektronische middelen voor het wegen',
    84238129: 'Weegtoestellen en weeginrichtingen, met een weegvermogen van <= 30 kg, die gebruikmaken van elektronische middelen voor het wegen, n.e.g.',
    84238180: 'Weegtoestellen en weeginrichtingen, met een weegvermogen <= 30 kg (m.u.v. gewichtsorteermachines en automatische controleweegtoestellen, machines voor het wegen en etiketteren van verpakte goederen en voor toonbankgebruik en excl. weegschalen met een gevoeligheid <= 5 cg, personenweegschalen, huishoudweegschalen, weegschalen om goederen op een lopende band te wegen en weegschalen voor het afwegen van constante hoeveelheden en weegtoestellen voor het vullen van zakken of andere bergingsmiddelen) die geen gebruik maken van elektronische middelen voor het wegen, n.e.g.',
    84238220: 'Weegmachines met een weegvermogen > 30 kg  en <= 5.000 kg die gebruik maken van elektronische middelen voor het wegen, m.u.v. machines voor het wegen van motorvoertuigen (excl. personenweegschalen, weegschalen om goederen op een lopende band te wegen en weegschalen voor het afwegen van constante hoeveelheden en weegtoestellen voor het vullen van zakken of andere bergingsmiddelen, incl. bunkerwegers)',
    84238281: 'Gewichtssorteermachines en automatische controleweegtoestellen die geen gebruik maken van elektronische middelen voor het wegen, maar die het gewicht toetsen aan een standaardgewicht, met een weegvermogen van > 30, doch <= 5.000 kg',
    84238289: 'Weegtoestellen en weeginrichtingen, met een weegvermogen van > 30 doch <= 5.000 kg, n.e.g.',
    84238920: 'Weegmachines met een weegvermogen van >  5.000 kg, die gebruik maken van eklektronische middelen voor het wegen',
    84238980: 'Weegmachines met een weegvermogen  van > 5.000 kg die geen gebruik maken van eklektronische middelen voor het wegen',
    84239010: 'Delen van machines van de goederengroepen 84232010, 84233010, 84238121, 84238123 , 84238125, 84238129, 84238220 or 84238920, n.e.g.',
    84239090: 'gewichten voor weegtoestellen van alle soorten; delen van weegtoestellen en weeginrichtingen, n.e.g.',
    84241000: 'Blusapparaten, ook indien gevuld',
    84242000: 'Spuitpistolen e.d. toestellen (m.u.v. elektrische machines, apparaten en toestellen voor het warmverspuiten van metaal of van gesinterde metaalcarbiden bedoeld bij post 8515; zandstraaltoestellen, stoomstraaltoestellen e.d. straaltoestellen)',
    84243001: 'toestellen, met ingebouwde motor, voor het reinigen met water "hogedrukreinigers", voorzien van een verwarmingsinrichting',
    84243008: 'Toestellen, met ingebouwde motor, voor het reinigen met water "hogedrukreinigers", zonder verwarmingsinrichting',
    84243010: 'zandstraaltoestellen, stoomstraaltoestellen e.d. straaltoestellen, met perslucht',
    84243090: 'zandstraaltoestellen, stoomstraaltoestellen e.d. straaltoestellen (m.u.v. die met perslucht en die voor het reinigen van bergingsmiddelen en m.u.v. toestellen, met ingebouwde motor, voor het reinigen met water "hogedrukreinigers")',
    84244100: 'Draagbare sproeiers voor de land- of tuinbouw',
    84244910: 'Mechanische toestellen voor het spuiten, verspreiden, of verstuiven van vloeistoffen of van poeder,  voor de land- of tuinbouw speciaal vervaardigd om  te worden gemonteerd op of te worden getrokken door een tractor',
    84244990: 'Niet draagbare spuitmachines voor de land- of tuinbouw en niet gemaakt om bevestigd te worden op een tractor',
    84248210: 'Watersproeitoestellen voor de land- of tuinbouw, ook indien voor handkracht',
    84248290: 'Toestellen voor de land- of tuinbouw, ook indien voor handkracht, voor het spuiten, verspreiden of verstuiven van vloeistoffen of poeders  (excl. sproeiers en watersproeistoestellen)',
    84248940: 'Mechanische toestellen voor het spuiten, verspreiden of verstuiven van vloeistoffen of poeders van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen of assemblage van gedrukte schakelingen',
    84248970: 'mechanische toestellen, ook indien voor handkracht, voor het spuiten, verspreiden of verstuiven van vloeistoffen of van poeder, n.e.g.',
    84249020: 'Delen van mechanische toestellen voor het spuiten, verspreiden of verstuiven van vloeistoffen of van poeder, van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen of assemblage van gedrukte schakelingen',
    84249080: 'Delen van blusapparaten, van spuitpistolen en van dergelijke toestellen, van zandstraaltoestellen, van stoomstraaltoestellen of van dergelijke straaltoestellen en van mechanische toestellen voor het spuiten, verspreiden of verstuiven van vloeistoffen of van poeder, n.e.g.',
    84251100: 'Takels met elektromotor',
    84251900: 'Niet-elektrische takels',
    84253100: 'Lieren en kaapstanders, met elektromotor',
    84253900: 'Niet-elektrische lieren en kaapstanders',
    84254100: 'stationaire hefbruggen van de soort gebruikt in garagewerkplaatsen',
    84254200: 'Dommekrachten en vijzels, hydraulisch werkend (m.u.v. stationaire hefbruggen van de soort gebruikt in garagewerkplaatsen)',
    84254900: 'Niet-hydraulische dommekrachten en vijzels',
    84261100: 'loopkranen',
    84261200: 'verrijdbare hefportalen op luchtbanden en portaalwagens',
    84261900: 'laadbruggen, bokkranen en hefportalen (m.u.v. loopkranen, verrijdbare hefportalen op luchtbanden, portaalwagens en portaalkranen)',
    84262000: 'torenkranen',
    84263000: 'portaalkranen',
    84264100: 'mobiele kranen en transportwagens met kraan, op luchtbanden (m.u.v. kraanauto\'s, verrijdbare hefportalen op luchtbanden en portaalwagens)',
    84264900: 'mobiele kranen en transportwagens met kraan (m.u.v. die op luchtbanden en m.u.v. portaalwagens)',
    84269110: 'hydraulische kranen voor het laden of het lossen van het voertuig waarop het is gemonteerd',
    84269190: 'kranen, ontworpen om op een wegvoertuig te worden gemonteerd (m.u.v. hydraulische kranen voor het laden of het lossen van het voertuig waarop het is gemonteerd)',
    84269900: 'Dirkkranen, vervoerkabels en andere kranen (m.u.v. loopkranen, laadbruggen, bokkranen, hefportalen en portaalwagens, torenkranen, portaalkranen, transportwagens met kraan, mobiele kranen en kranen, ontworpen om op een wegvoertuig te worden gemonteerd)',
    84271010: 'transportwagens met eigen beweegkracht, aangedreven door een elektromotor, met een hefvermogen van >= 1 m',
    84271090: 'transportwagens met eigen beweegkracht, aangedreven door een elektromotor, met een hefvermogen van < 1 m',
    84272011: 'vork en andere heftrucks, voor alle terreinen, met een hefvermogen van >= 1 m',
    84272019: 'transportwagens met eigen beweegkracht, zonder elektrische aandrijving, met een hefvermogen van >= 1 m (m.u.v. vork en andere heftrucks, voor alle terreinen)',
    84272090: 'transportwagens met eigen beweegkracht, zonder elektrische aandrijving, met een hefvermogen van < 1 m',
    84279000: 'transportwagens zonder eigen beweegkracht',
    84281020: 'Elektrische personen- en goederenliften, incl. bakkenliften',
    84281080: 'Niet-elektrische personen- en goederenliften, incl. bakkenliften',
    84282020: 'Pneumatische transportinrichtingen, voor stortgoederen',
    84282080: 'Pneumatische transportinrichtingen (m.u.v. die voor stortgoederen)',
    84283100: 'continu-werkende transportinrichtingen voor goederen, speciaal ontworpen voor ondergronds gebruik (m.u.v. pneumatische transportinrichtingen)',
    84283200: 'continu-werkende transportinrichtingen voor goederen, werkend met bakken (m.u.v. die welke speciaal zijn ontworpen voor ondergronds gebruik)',
    84283300: 'Continu-werkende transportinrichtingen voor goederen, werkend met banden (m.u.v. die welke speciaal zijn ontworpen voor ondergronds gebruik)',
    84283920: 'Rollenbanen en zgn. castors',
    84283990: 'Continu-werkende transportinrichtingen voor goederen (m.u.v. die welke speciaal zijn ontworpen voor ondergronds gebruik of werken met bakken of met banden en m.u.v. rollenbanen, pneumatische transportinrichtingen en automatische goederentransportmachines voor het transporteren e.d. van materiaal gebruikt bij de vervaardiging van elektronische schakelingen)',
    84284000: 'roltrappen en rolpaden',
    84286000: 'kabelbanen, incl. stoelliften en skisleepliften; trekmechanismen voor kabelsporen',
    84289071: 'laadtoestellen vervaardigd om te worden gemonteerd op een landbouwtractor',
    84289079: 'laadtoestellen speciaal vervaardigd om te worden gebruikt in de landbouw (m.u.v. die welke worden gemonteerd op een landbouwtractor en m.u.v. landbouwtractoren)',
    84289090: 'Hef-, hijs-, laad- en losmachines en -toestellen, alsmede machines en toestellen voor het hanteren van goederen, n.e.g.',
    84291100: 'bulldozers en angledozers, op rupsbanden',
    84291900: 'bulldozers en angledozers, op wielen',
    84292000: 'egaliseermachines, met eigen beweegkracht',
    84293000: 'schrapers, met eigen beweegkracht',
    84294010: 'trilwalsen, met eigen beweegkracht',
    84294030: 'Wegwalsen, met eigen beweegkracht (m.u.v. trilwalsen)',
    84294090: 'schapenpootwalsen en andere bodemverdichtingsmachines (m.u.v. wegwalsen)',
    84295110: 'laadschoppen met voorschop, met eigen beweegkracht, speciaal ontworpen voor ondergronds gebruik',
    84295191: 'Laadschoppen met voorschop, met rupsbanden, met eigen beweegkracht (m.u.v. die welke speciaal zijn ontworpen voor ondergronds gebruik)',
    84295199: 'Laadschoppen met voorschop, met eigen beweegkracht (m.u.v. die welke speciaal zijn ontworpen voor ondergronds gebruik; die met rupsbanden)',
    84295210: 'Graafmachines met rupsbanden, met eigen beweegkracht, waarvan de bovenbouw 360° kan draaien',
    84295290: 'Graafmachines, met eigen beweegkracht, waarvan de bovenbouw 360° kan draaien (m.u.v. die met rupsbanden)',
    84295900: 'mechanische schoppen, excavateurs "emmergravers" en laadschoppen, met eigen beweegkracht (m.u.v. mechanische schoppen waarvan de bovenbouw 360° kan draaien en laadschoppen met voorschop)',
    84301000: 'heimachines en machines voor het uittrekken van heipalen (m.u.v. die welke op spoorwegwagons of op automobielchassis of op vrachtauto\'s zijn gemonteerd)',
    84302000: 'sneeuwruimers (m.u.v. die welke op spoorwegwagons of op automobielchassis of op vrachtauto\'s zijn gemonteerd, machines met eigen beweegkracht bedoeld bij post 8429, hef-, hijs-, laad- en losmachines en toestellen bedoeld bij de posten 8425 tot en met 8428 en handgereedschapswerktuigen)',
    84303100: 'gesteentesnijders, kolenploegen "schaven" en machines voor het boren van tunnels, met eigen beweegkracht (m.u.v. hydraulische wandelondersteuning)',
    84303900: 'gesteentesnijders, kolenploegen "schaven" en machines voor het boren van tunnels, zonder eigen beweegkracht (m.u.v. hydraulische wandelondersteuning)',
    84304100: 'machines en toestellen voor het boren, of het diep boren in grond, mineralen of ertsen, met eigen beweegkracht (m.u.v. die welke op spoorwegwagons of op automobielchassis of op vrachtauto\'s zijn gemonteerd, machines met eigen beweegkracht bedoeld bij post 8429, hef-, hijs-, laad- en losmachines en toestellen bedoeld bij de posten 8425 tot en met 8428 en handgereedschapswerktuigen en m.u.v. machines voor het boren van tunnels)',
    84304900: 'machines en toestellen voor het boren in grond, mineralen of ertsen, zonder eigen beweegkracht (niet hydraulisch en m.u.v. machines voor het boren van tunnels en handgereedschapswerktuigen)',
    84305000: 'machines en toestellen voor grondverzet, met eigen beweegkracht, n.e.g.',
    84306100: 'machines en toestellen voor het aanstampen, zonder eigen beweegkracht (m.u.v. handgereedschapswerktuigen)',
    84306900: 'machines en toestellen voor grondverzet, zonder eigen beweegkracht, n.e.g.',
    84311000: 'delen van takels, van lieren "windassen" en kaapstanders en van dommekrachten en vijzels, n.e.g.',
    84312000: 'delen van vorkheftrucks en andere transportwagentjes met hef- of hanteerinrichting, n.e.g.',
    84313100: 'delen van personen- en goederenliften, incl. bakkenliften, of van roltrappen, n.e.g.',
    84313900: 'Delen van machines en toestellen bedoeld bij post 8428, n.e.g.',
    84314100: 'emmers, bakken, grijpemmers, schoppen, grijpers en tangen voor machines en toestellen bedoeld bij post 8426, 8429 en 8430',
    84314200: 'bladen voor bulldozers of voor angledozers',
    84314300: 'delen van machines voor het boren bedoeld bij onderverdeling 8430.41 of 8430.49, n.e.g.',
    84314920: 'delen van machines of toestellen bedoeld bij onderverdeling 8426, 8429 of 8430, van gietijzer of van gegoten staal, n.e.g.',
    84314980: 'delen van machines of toestellen bedoeld bij onderverdeling 8426, 8429 of 8430, n.e.g. (m.u.v. die van gietijzer of van gegoten staal)',
    84321000: 'Ploegen voor land-, tuin- of bosbouw',
    84322100: 'schijfeggen voor land-, tuin- of bosbouw',
    84322910: 'cultivators voor land-, tuin- of bosbouw',
    84322930: 'eggen voor land-, tuin- of bosbouw (m.u.v. schijfeggen)',
    84322950: 'motorhakmachines voor land-, tuin- of bosbouw',
    84322990: 'hak-, schoffel- en wiedmachines, voor land-, tuin- of bosbouw (m.u.v. motorhakmachines)',
    84323100: 'no-till zaai- , plant-  en pootmachines',
    84323911: 'precisiezaaimachines met centrale aandrijving (excl. no-till zaai- , plant-  en pootmachines)',
    84323919: 'Andere zaaimachines dan precisiezaaimachines (excl. no-till zaai- , plant-  en pootmachines)',
    84323990: 'Plant- en pootmachines (excl. no-till zaai- , plant-  en pootmachines)',
    84324100: 'Mestverspreiders (excl. Sproeiers)',
    84324200: 'kunstmeststrooiers (excl. Sproeiers en mestverspreiders)',
    84328000: 'machines, toestellen en werktuigen, voor land-, tuin- of bosbouw, voor de voorbereiding, bewerking of bebouwing van de bodem, rollers voor gras en sportvelden (m.u.v. toestellen voor het spuiten, verspreiden of verstuiven van vloeistoffen of van poeder, ploegen, eggen, cultivators, extirpators, hak-, schoffel- en wiedmachines, zaai-, plant- en pootmachines, en machines voor het strooien of verspreiden van meststoffen)',
    84329000: 'delen van machines, toestellen en werktuigen, voor land-, tuin- of bosbouw, of voor de voorbereiding, bewerking of bebouwing van de bodem, en van rollers voor gras en sportvelden, n.e.g.',
    84331110: 'gazonmaaimachines met elektromotor en met een in een horizontaal vlak draaiende snijinrichting, incl. die voor parken en sportvelden',
    84331151: 'gazonmaaimachines met zuigermotor met een in een horizontaal vlak draaiende snijinrichting, incl. die voor parken en sportvelden, met eigen beweegkracht, uitgerust met een zitplaats',
    84331159: 'gazonmaaimachines met zuigermotor met een in een horizontaal vlak draaiende snijinrichting, incl. die voor parken en sportvelden, met eigen beweegkracht, zonder zitplaats',
    84331190: 'gazonmaaimachines met zuigermotor met een in een horizontaal vlak draaiende snijinrichting, incl. die voor parken en sportvelden, zonder eigen beweegkracht, zonder zitplaats',
    84331910: 'gazonmaaimachines met elektromotor en met een in een verticaal vlak draaiende snijinrichting of met een maaibalk, incl. die voor parken en sportvelden',
    84331951: 'gazonmaaimachines met zuigermotor en met een in een verticaal vlak draaiende snijinrichting of met een maaibalk, incl. die voor parken en sportvelden, met eigen beweegkracht, uitgerust met een zitplaats',
    84331959: 'gazonmaaimachines met zuigermotor en met een in een verticaal vlak draaiende snijinrichting of met een maaibalk, incl. die voor parken en sportvelden, met eigen beweegkracht, zonder zitplaats',
    84331970: 'gazonmaaimachines met zuigermotor en met een in een verticaal vlak draaiende snijinrichting of met een maaibalk, incl. die voor parken en sportvelden, zonder eigen beweegkracht',
    84331990: 'gazonmaaimachines zonder motor "handgrasmaaiers"',
    84332010: 'maaimachines met motor (m.u.v. gazonmaaimachines, incl. die voor parken en sportvelden)',
    84332050: 'Maaimachines zonder motor, incl. maaibalken, speciaal vervaardigd om te worden gemonteerd op of te worden getrokken door een tractor',
    84332090: 'maaimachines (m.u.v. die welke zijn vervaardigd om te worden gemonteerd op of te worden getrokken door een tractor en m.u.v. gazonmaaimachines, incl. die voor parken en sportvelden, maaimachines met motor en maaidorsers)',
    84333000: 'Machines en toestellen voor het hooien (m.u.v. maaimachines)',
    84334000: 'Stro- en veevoederpersen, opraappersen daaronder begrepen',
    84335100: 'maaidorsers',
    84335200: 'dorsmachines (m.u.v. maaidorsers)',
    84335310: 'aardappeloogstmachines',
    84335330: 'bietenkopper en bietenoogstmachines',
    84335390: 'machines voor het oogsten van wortels en knollen (m.u.v. aardappeloogstmachines, bietenkopper en bietenoogstmachines)',
    84335911: 'veldhakselaars met eigen beweegkracht',
    84335919: 'veldhakselaars zonder eigen beweegkracht',
    84335985: 'Machines en toestellen voor het oogsten van landbouwproducten (m.u.v. maaimachines, machines en toestellen voor het hooien, stro- en veevoederpersen, opraappersen, maaidorsers en andere dorsmachines, machines voor het oogsten van wortels en knollen en veldhakselaars )',
    84336000: 'machines voor het reinigen of sorteren van eieren, vruchten of andere landbouwproducten (m.u.v. die voor het reinigen of sorteren van zaad, graan of gedroogde peulgroenten bedoeld bij onderverdeling 8437.10)',
    84339000: 'delen van machines, toestellen en werktuigen voor het oogsten of voor het dorsen van landbouwproducten, van gazon- en andere grasmaaimachines en van machines voor het reinigen of sorteren van eieren, vruchten of andere landbouwproducten, n.e.g.',
    84341000: 'melkmachines',
    84342000: 'machines en toestellen voor zuivelbedrijven (m.u.v. koelapparaten en inrichtingen om welk een warmtebehandeling te laten ondergaan, melkontromers, centrifugale reinigers, filters en filterpersen)',
    84349000: 'delen van melkmachines en machines en toestellen voor zuivelbedrijven, n.e.g.',
    84351000: 'persen, molens, machines e.d. toestellen voor het bereiden van wijn, van appeldrank, van vruchtensap of van dergelijke dranken (m.u.v. machines en toestellen voor het behandelen van deze dranken, incl. centrifuges, filterpersen en andere filterapparaten en apparaten voor huishoudelijk gebruik)',
    84359000: 'delen van persen, molens, machines e.d. toestellen voor het bereiden van wijn, van appeldrank, van vruchtensap of van dergelijke dranken, n.e.g.',
    84361000: 'machines en toestellen voor het bereiden van veevoeder op landbouwbedrijven e.d. (m.u.v. die voor de veevoederindustrie en m.u.v. veldhakselaars, toestellen voor het stomen van veevoeder, e.d.)',
    84362100: 'broedmachines en kunstmoeder voor de pluimveeteelt',
    84362900: 'machines en toestellen voor de pluimveeteelt (m.u.v. machines voor het sorteren van eieren, plukmachines bedoeld bij post 8438 en broedmachines en kunstmoeders)',
    84368010: 'machines en toestellen voor de bosbouw',
    84368090: 'Machines en toestellen voor de landbouw, de tuinbouw of de bijenteelt, n.e.g.',
    84369100: 'delen van machines en toestellen voor de pluimveeteelt, van broedmachines en kunstmoeders, n.e.g.',
    84369900: 'delen van machines en toestellen voor de landbouw, de tuinbouw, de bosbouw of de bijenteelt, n.e.g.',
    84371000: 'machines voor het reinigen of sorteren van zaad, graan of gedroogde peulgroenten',
    84378000: 'machines en toestellen voor de meelindustrie of voor de behandeling van graan en van gedroogde peulgroenten (m.u.v. machines en toestellen die in het boerenbedrijf worden gebruikt, inrichtingen voor het warm behandelen van producten, centrifugaaldrogers, luchtfilters en machines voor het reinigen of sorteren van zaad, graan of gedroogde peulgroenten)',
    84379000: 'delen van machines voor het reinigen of sorteren van zaad, graan of gedroogde peulgroenten of van machines en toestellen voor de meelindustrie of voor de behandeling van graan en van gedroogde peulgroenten, n.e.g.',
    84381010: 'machines en toestellen voor de brood- en banketbakkerijen (m.u.v. bakkersovens en machines voor het in vellen uitrollen van deeg)',
    84381090: 'machines en toestellen voor het vervaardigen van deegwaren (m.u.v. droogmachines voor deegwaren en machines voor het in vellen uitrollen van deeg)',
    84382000: 'machines en toestellen voor de industriële vervaardiging van suikergoed, cacao of chocolade (m.u.v. centrifuges en toestellen voor het filtreren, verwarmen of koelen)',
    84383000: 'machines en toestellen voor suikerfabrieken (m.u.v. centrifuges en toestellen voor het filtreren, verwarmen of koelen)',
    84384000: 'machines en toestellen voor brouwerijen (m.u.v. centrifuges en toestellen voor het filtreren, verwarmen of koelen)',
    84385000: 'machines en toestellen voor de industriële bewerking of verwerking van vlees (m.u.v. toestellen voor het koken en verwarmen en machines en toestellen voor het koelen of vriezen)',
    84386000: 'machines en toestellen voor de industriële bereiding van vruchten of groenten (m.u.v. toestellen voor het koken of verwarmen en machines en toestellen voor het koelen of vriezen en machines voor het sorteren van groenten en vruchten)',
    84388010: 'machines en toestellen voor de industriële behandeling of bereiding van koffie of thee (m.u.v. centrifuges, toestellen voor filtreren of roosten, vriesdrogers en andere verwarmingsapparatuur)',
    84388091: 'machines en toestellen voor de industriële bereiding of vervaardiging van dranken, n.e.g. (m.u.v. centrifuges, toestellen voor het filtreren, verwarmen of koelen en vriesdrogers)',
    84388099: 'machines en toestellen voor de industriële bereiding of vervaardiging van voedingsmiddelen, n.e.g.',
    84389000: 'delen van machines en toestellen voor de industriële bereiding of vervaardiging van voedingsmiddelen of dranken, n.e.g.',
    84391000: 'machines en toestellen voor het vervaardigen van pulp, van cellulosehoudende vezelstoffen (m.u.v. autoclaven, kooktoestellen en andere verwarmingsapparaten)',
    84392000: 'machines en toestellen voor het vervaardigen van papier of van karton (m.u.v. droogmachines en andere verwarmingsapparatuur, kalanders en machines en apparaten voor het vervaardigen van pulp)',
    84393000: 'machines en toestellen voor het afwerken van papier of van karton (m.u.v. kalanders)',
    84399100: 'delen van machines en toestellen voor het vervaardigen van pulp, van cellulosehoudende vezelstoffen',
    84399900: 'delen van machines en toestellen voor het vervaardigen of afwerken van papier of van karton, n.e.g.',
    84401010: 'vouwmachines voor het boekbindersbedrijf',
    84401020: 'vergaarmachines voor het boekbindersbedrijf',
    84401030: 'naai-, stik- en hechtmachines voor het boekbindersbedrijf',
    84401040: 'kleefbindmachines voor het boekbindersbedrijf',
    84401090: 'machines en toestellen voor het innaaien of voor het inbinden van boeken (m.u.v. machines en toestellen voor de bewerking van papierstoffen, van papier of van karton bedoeld bij post 8441, algemeen bruikbare persen, machines en toestellen voor het drukken bedoeld bij post 8443 alsmede hulptoestellen daarvoor, vouwmachines, vergaarmachines, naai-, stik- en hechtmachines en kleefbindmachines)',
    84409000: 'delen van machines en toestellen voor het innaaien of voor het inbinden van boeken, n.e.g.',
    84411010: 'omrolmachines, gecombineerd met een snijmachine, voor papierstof, voor papier of voor karton',
    84411020: 'overlangs- en overdwarssnijmachines, voor papierstof, voor papier of voor karton',
    84411030: 'snelsnijmachines met een mes, voor papier of voor karton',
    84411070: 'Snijmachines voor papier of voor karton (m.u.v. machines voor het boekbindersbedrijf bedoeld bij post 8440; omrolmachines, gecombineerd met een snijmachine; overlangs- en overdwarssnijmachines; snelsnijmachines met één mes)',
    84412000: 'machines voor de vervaardiging van zakken of van enveloppen, van papierstof, van papier of van karton (m.u.v. naaimachines en machines en toestellen voor het aanbrengen van oogjes)',
    84413000: 'machines voor de vervaardiging van dozen, van kokers, van trommels of van dergelijke bergingsmiddelen (anders dan door vormen) van papierstof, van papier of van karton (m.u.v. droogtoestellen en naaimachines)',
    84414000: 'machines voor het vormen van artikelen uit papierstof, uit papier of uit karton (m.u.v. droogtoestellen)',
    84418000: 'machines en toestellen voor de bewerking van papierstof, van papier of van karton, n.e.g.',
    84419010: 'delen van snijmachines voor papierstof, voor papier of voor karton, n.e.g.',
    84419090: 'delen van machines en toestellen voor de bewerking van papierstof, van papier of van karton, n.e.g.',
    84423000: 'Machines, toestellen en uitrustingsstukken voor het maken van printplaten, printcylinders of andere printcomponenten (excl machine en uitrustingsstukken van hoofdstukken 8456 tot 8465)',
    84424000: 'delen van machines, toestellen en uitrustingsstukken voor het maken van drukplaten, drukcilinders en ander materiaal voor het drukken, n.e.g.',
    84425000: 'Drukplaten, drukcilinders en ander materiaal voor het drukken; platen, cilinders en lithografische stenen, geprepareerd voor het drukken (bijvoorbeeld geslepen, gekorreld, gepolijst)',
    84431100: 'machines en toestellen voor offsetdruk, gevoed met rollen',
    84431200: 'machines en toestellen voor offsetdruk, gevoed met vellen van <= 22 x 36 cm "voor kantoorgebruik"',
    84431310: 'gebruikte machines en toestellen voor offsetdruk, gevoed met vellen van > 22 x 36 cm',
    84431332: 'nieuwe machines en toestellen voor offsetdruk, gevoed met vellen van <= 53 x 75 cm doch > 22 x 36 cm',
    84431334: 'nieuwe machines en toestellen voor offsetdruk, gevoed met vellen van > 53 x 75 cm doch <= 75 x 107 cm',
    84431338: 'nieuwe machines en toestellen voor offsetdruk, gevoed met vellen van > 75 x 107 cm',
    84431390: 'Machines en toestellen voor offsetdruk (m.u.v. die welke worden gevoed met rollen of met vellen)',
    84431400: 'machines en toestellen voor de hoogdruk "boekdruk", gevoed met rollen (m.u.v. die voor flexografie)',
    84431500: 'machines en toestellen voor de hoogdruk "boekdruk" (m.u.v. die voor flexografie of die welke worden gevoed met rollen)',
    84431600: 'machines en toestellen voor de flexografie',
    84431700: 'machines en toestellen voor de diepdruk',
    84431920: 'machines en toestellen voor het bedrukken van textielwaren (m.u.v. machines en toestellen voor offsetdruk, voor flexografie, voor hoogdruk "boekdruk" of voor diepdruk)',
    84431940: 'machines en toestellen voor het drukken, bestemd voor de vervaardiging van halfgeleiders',
    84431970: 'Machines en toestellen gebruikt om te drukken met behulp van drukplaten, drukcilinders en ander materiaal bedoeld bij post 8442  (m.u.v. die voor het bedrukken van textielwaren, die bestemd voor de vervaardiging van halfgeleiders, hectografen, stencilmachines, adresseermachines en andere kantoormachines en -toestellen voor het drukken, bedoeld bij de posten 8469 t/m 8472, inktstraaldrukkers en drukkers voor offset, flexografie, hoogdruk "boekdruk" en diepdruk)',
    84433100: 'Machines die twee of meer van de functies afdrukken, kopiëren of telekopiëren verrichten, geschikt om te worden aangesloten op een automatisch gegevensverwerkende machine of op een netwerk',
    84433210: 'Printers geschikt om te worden aangesloten  op een automatisch gegevensverwerkende machine of op een netwerk',
    84433280: 'Machines die maar één van de functies kopiëren of telekopiëren verrichten, geschikt om te worden aangesloten op een automatisch gegevensverwerkende machine of op een netwerk',
    84433900: 'Printers, kopieermachines of telekopieermachines, ook indien gecombineerd (excl. Apparaten die geschikt zijn om aangesloten te worden op een automatisch gegevensverwerkende machine of op een netwerk en drukmachines van hoofdstuk 8442)',
    84439110: 'Delen en toebehoren van machines en toestellen voor het drukken, bestemd voor de vervaardiging van halfgeleiders, n.e.g.',
    84439191: 'Delen en toebehoren van machines en toestellen voor het drukken met behulp van drukplaten, drukcilinders en ander materiaal voor het drukken bedoeld bij post 8442, van gietijzer of van gegoten staal, n.e.g. (m.u.v. die bestemd voor de vervaardiging van halfgeleiders)',
    84439199: 'Delen en toebehoren van machines en toestellen voor het drukken met behulp van drukplaten, drukcilinders en ander materiaal voor het drukken bedoeld bij post 8442, n.e.g. (m.u.v. die bestemd voor de vervaardiging van halfgeleiders en van gietijzer of van gegoten staal)',
    84439910: 'Elektronische assemblages van afdrukkers, kopieertoestellen en telekopieertoestellen (m.u.v. machines en toestellen voor het drukken met behulp van drukplaten, drukcilinders en ander materiaal voor het drukken bedoeld bij post 8442)',
    84439990: 'Delen en toebehoren van afdrukkers, kopieertoestellen en telekopieertoestellen, n.e.g. (m.u.v. elektronische assemblages en machines en toestellen voor het drukken met behulp van drukplaten, drukcilinders en ander materiaal  voor het drukken bedoeld bij post 8442)',
    84440010: 'machines voor het spinnen "extruderen" van synthetische of kunstmatige textielstoffen',
    84440090: 'machines voor het rekken, het textureren of het snijden van synthetische of kunstmatige textielstoffen',
    84451100: 'kaardmachines voor het bereiden van spinvezels',
    84451200: 'kammachines voor het bereiden van spinvezels',
    84451300: 'voorspinmachines',
    84451900: 'machines voor het bereiden van spinvezels (m.u.v. kaardmachines, kammachines en voorspinmachines)',
    84452000: 'machines voor het spinnen van spinvezels (m.u.v. spinmachines voor kunstmatige of synthetische continu-vezels en voorspinmachines)',
    84453000: 'machines voor het doubleren of twijnen',
    84454000: 'machines voor het spoelen, opwinden "incl. inslagspoelmachines" of afhaspelen van textielstoffen',
    84459000: 'machines en toestellen voor de vervaardiging van textielgaren en machines voor het voorbereiden van textielgaren om te worden verwerkt op de machines bedoeld bij post 8446 of 8447 (m.u.v. machines bedoeld bij post 8444 en machines voor het spinnen, doubleren of twijnen)',
    84461000: 'weefgetouwen voor weefsels met een breedte van <= 30 cm',
    84462100: 'weefgetouwen voor weefsels met een breedte van > 30 cm, met schietspoel en met motor',
    84462900: 'weefgetouwen voor weefsels met een breedte van > 30 cm, met schietspoel, doch zonder motor',
    84463000: 'weefgetouwen voor weefsels met een breedte van > 30 cm, zonder schietspoel',
    84471100: 'Rondbreimachines met een cilinderdiameter van <= 165 mm',
    84471200: 'Rondbreimachines met een cilinderdiameter van > 165 mm',
    84472020: 'kettingtricotbreimachines, incl. raschelmachines, alsmede naaibreimachines "stitch-bonding-machines"',
    84472080: 'vlakbreimachines (m.u.v. kettingtricotbreimachines, raschelmachines en naaibreimachines "stitch-bonding-machines")',
    84479000: 'guipeermachines, machines voor de vervaardiging van tule, van kant, van borduurwerk, van passementwerk, van vlechtwerk of van filetweefsel, alsmede machines voor het tuften (m.u.v. kettingsteekmachines)',
    84481100: 'dobby\'s "schaftmechanismen" en jacquardmechanismen, kaartherhalers, kaartenpons- , kaartenkopieer- en kaartenrijgmachines',
    84481900: 'hulpmachines en hulptoestellen voor de machines bedoeld bij de posten 8444, 8445, 8446 en 8447 (m.u.v. dobby\'s "schaftmechanismen" en jacquardmechanismen, kaartherhalers, kaartenpons- , kaartenkopieer- en kaartenrijgmachines)',
    84482000: 'delen en toebehoren voor machines voor het spinnen "extruderen", het rekken, het textureren of het snijden van synthetische of kunstmatige textielstoffen, n.e.g.',
    84483100: 'kaardbeslag voor machines voor het bereiden van spinvezels',
    84483200: 'delen en toebehoren voor machines voor het bereiden van spinvezels, n.e.g. (m.u.v. kaardbeslag)',
    84483300: 'Spillen en spilvleugels, ringen en spinoogjes "travellers", voor machines bedoeld bij post 8445',
    84483900: 'delen en toebehoren voor de machines bedoeld bij post 8445, n.e.g.',
    84484200: 'rieten, hevels en schachten, voor weefgetouwen',
    84484900: 'delen en toebehoren voor weefgetouwen of voor de hulpmachines of hulptoestellen daarvoor, n.e.g.',
    84485110: 'platinen voor het vormen van mazen voor de machines of toestellen bedoeld bij post 8447',
    84485190: 'naalden en andere artikelen voor het vormen van mazen, voor de machines of toestellen bedoeld bij post 8447',
    84485900: 'delen en toebehoren voor de machines of toestellen bedoeld bij post 8447, of voor de hulpmachines of hulptoestellen daarvoor, n.e.g.',
    84490000: 'machines en toestellen voor de vervaardiging of voor de afwerking van vilt of van gebonden textielvlies, zowel aan het stuk als in bepaalde vormen, incl. machines en toestellen voor de vervaardiging van vilthoeden; hoedvormen; delen daarvan (m.u.v. machines voor het bereiden van de vezels voor het vervilten en m.u.v. kalanders)',
    84501111: 'volautomatische wasmachines voor wasgoed, voorladers, met een capaciteit van <= 6 kg droog wasgoed',
    84501119: 'volautomatische wasmachines voor wasgoed, bovenladers, met een capaciteit van <= 6 kg droog wasgoed',
    84501190: 'volautomatische wasmachines voor wasgoed, met een capaciteit van > 6, doch <= 10 kg droog wasgoed',
    84501200: 'wasmachines voor wasgoed, ook met ingebouwde centrifuge, met een capaciteit van <= 10 kg droog wasgoed (m.u.v. volautomatische wasmachines)',
    84501900: 'wasmachines voor wasgoed, met een capaciteit van <= 10 kg droog wasgoed (m.u.v. volautomatische wasmachines en machines met ingebouwde centrifuge)',
    84502000: 'wasmachines voor wasgoed, met een capaciteit van > 10 kg droog wasgoed',
    84509000: 'delen van wasmachines voor wasgoed, n.e.g.',
    84511000: 'machines voor het droog reinigen van textielstoffen',
    84512100: 'droogmachines met een capaciteit van <= 10 kg droog wasgoed (m.u.v. centrifuges)',
    84512900: 'machines en toestellen voor het drogen van garens, weefsels of andere textielstoffen (m.u.v. droogmachines voor wasgoed met een capaciteit van <= 10 kg droog wasgoed en m.u.v. centrifuges)',
    84513000: 'strijkmachines en strijkpersen, incl. fixeerpersen (m.u.v. kalanders)',
    84514000: 'machines voor het wassen, het bleken of het verven van garens, weefsels of andere textielstoffen (m.u.v. wasmachines voor wasgoed)',
    84515000: 'machines voor het oprollen, het afrollen, het vouwen, het snijden of het kartelen van weefsels',
    84518010: 'machines voor het opbrengen van deklagen op weefsels of op andere onderlagen bij de vervaardiging van vloerbedekkingen "linoleum, enz." (m.u.v. kalanders en algemeen bruikbare persen)',
    84518030: 'machines voor het appreteren of het afwerken van garens of weefsels (m.u.v. die voor het appreteren of afwerken van vilt en m.u.v. kalanders en algemeen bruikbare persen)',
    84518080: 'machines en toestellen voor het bestrijken of het impregneren van garens, weefsels of textielwaren (m.u.v. kalanders en algemeen bruikbare persen)',
    84519000: 'delen van machines en toestellen voor het wassen, reinigen, wringen, drogen, strijken, persen, bleken, verven, appreteren, afwerken, bestrijken of impregneren van garens, van weefsels of van textielwaren, of van machines voor het opbrengen van deklagen op weefsels of op andere onderlagen die worden gebruikt bij de vervaardiging van vloerbedekkingen of van machines voor het oprollen, afrollen, vouwen, snijden of het kartelen van weefsels, n.e.g.',
    84521011: 'naaimachines voor huishoudelijk gebruik, die uitsluitend stiksteken kunnen vormen, waarvan de kop zonder de motor <= 16 kg of met de motor <= 17 kg weegt, met een waarde per stuk "m.u.v. onderstellen, tafels, of meubelen" van > 65 €',
    84521019: 'naaimachines voor huishoudelijk gebruik, die uitsluitend stiksteken kunnen vormen, waarvan de kop zonder de motor <= 16 kg of met de motor <= 17 kg weegt, met een waarde per stuk "m.u.v. onderstellen, tafels, of meubelen" van <= 65 €; koppen van naaimachines die uitsluitend stiksteken kunnen vormen, wegende zonder de motor <= 16 kg of met de motor <= 17 kilo',
    84521090: 'naaimachines en koppen van naaimachines, voor huishoudelijk gebruik (m.u.v. naaimachines die uitsluitend stiksteken kunnen vormen, waarvan de kop zonder de motor <= 16 kg of met de motor <= 17 kg weegt, m.u.v. koppen van naaimachines die uitsluitend stiksteken kunnen vormen, wegende zonder de motor <= 16 kg of met de motor <= 17 kg)',
    84522100: 'automatische naaieenheden voor industrieel of ambachtelijk gebruik',
    84522900: 'naaimachines voor industrieel of ambachtelijk gebruik (m.u.v. automatische eenheden)',
    84523000: 'naalden voor naaimachines',
    84529000: 'meubelen, onderstellen en kappen voor naaimachines, alsmede delen daarvan; andere delen van naaimachines n.e.g.',
    84531000: 'machines en toestellen voor het bereiden of het bewerken van huiden, vellen of leder (m.u.v. droogtoestellen, spuitpistolen, machines voor het ontharen van varkens en persen voor algemeen gebruik)',
    84532000: 'machines en toestellen voor het vervaardigen of het herstellen van schoeisel of van leder (m.u.v. naaimachines)',
    84538000: 'machines en toestellen voor het vervaardigen of het herstellen van lederwaren (m.u.v. die voor het vervaardigen of het herstellen van schoeisel en m.u.v. naaimachines)',
    84539000: 'delen van machines en toestellen voor het bereiden of het bewerken van huiden, vellen of leder of voor het vervaardigen of het herstellen van schoeisel of andere lederwaren, n.e.g.',
    84541000: 'convertors voor ijzer- of staalfabrieken en voor gieterijen',
    84542000: 'Gietvormen voor ingots "blokvormen" en gietkommen voor ijzer- of staalfabrieken en voor gieterijen',
    84543010: 'persgiet- of spuitgietmachines, voor ijzer- of staalfabrieken en voor gieterijen',
    84543090: 'gietmachines, voor ijzer- of staalfabrieken en voor gieterijen (m.u.v. persgiet- of spuitgietmachines)',
    84549000: 'delen van convertors, gietpannen, gietvormen voor ingots "blokvormen", alsmede gietmachines, voor ijzer- of staalfabrieken en voor gieterijen, n.e.g.',
    84551000: 'walsstoelen voor buizen van metaal',
    84552100: 'walsstoelen voor het warmwalsen of voor het gecombineerd warm en koud walsen van metalen (m.u.v. walsstoelen voor buizen)',
    84552200: 'walsstoelen voor het koud walsen van metalen (m.u.v. walsstoelen voor buizen)',
    84553010: 'walsrollen voor walsstoelen voor metalen, van gietijzer',
    84553031: 'werkwalsen voor het warmwalsen en steunwalsen voor het warm en koud walsen, van gesmeed staal',
    84553039: 'werkwalsen voor het koud walsen, van gesmeed staal',
    84553090: 'walsrollen voor walsstoelen voor metalen, van gegoten staal',
    84559000: 'delen van walsstoelen voor metalen, n.e.g.',
    84561110: 'Gereedschapswerktuigen voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van laserstralen van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen, assemblages van gedrukte schakelingen, delen van post 8517, of delen van automatische gegevensverwerkende machines',
    84561190: 'Gereedschapswerktuigen voor het bewerken van ongeacht welke stof, waarbij materiaal wordt weggenomen, met behulp van laserstralen (m.u.v. machines en toestellen voor het solderen of het lassen, ook indien geschikt voor het snijden, en machines voor onderzoek van materialen en machines voor het vervaardigen van halfgeleidermateriaal of van elektronisch geïntegreerde schakelingen, gedrukte schakelingen, delen van post 8517 of van automatische gegevensverwerkende machines)',
    84561210: 'Gereedschapswerktuigen voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van andere licht- of fotonenstralen dan laserstralen  van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen, assemblages van gedrukte schakelingen, delen van post 8517, of delen van automatische gegevensverwerkende machines.',
    84561290: 'Gereedschapswerktuigen voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van andere licht- of fotonenstralen dan laserstralen van de soort die niet uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen, assemblages van gedrukte schakelingen, delen van post 8517, of delen van automatische gegevensverwerkende machines (excl. Soldeer- en lasapparatuur die ook geschikt is om materiaal te snijden, machines om materialen te testen en machines voor het maken van halfgeleiders of elektronisch geïntegreerde schakelingen)',
    84562000: 'gereedschapswerktuigen voor het bewerken van ongeacht welke stof, waarbij materiaal wordt weggenomen, met behulp van ultrasone trillingen (m.u.v. machines voor het reinigen met ultrasone trillingen en machines voor onderzoek van materialen)',
    84563011: 'gereedschapswerktuigen voor het bewerken van ongeacht welke stof, waarbij materiaal wordt weggenomen, met behulp van draadvonkerosie, met numerieke besturing',
    84563019: 'gereedschapswerktuigen voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van elektro-erosie "vonkerosie", met numerieke besturing (m.u.v. die voor het bewerken van stoffen met behulp van draadvonkerosie)',
    84563090: 'gereedschapswerktuigen voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van elektro-erosie "vonkerosie", zonder numerieke besturing',
    84564000: 'Gereedschapswerktuigen voor het bewerken van stoffen waarbij materiaal wordt weggenomen, met behulp van plasmastralen',
    84565000: 'Waterstraalsnijmachines',
    84569000: 'Ander machines voor het bewerken van ongeacht welke stof waarbij materiaal wordt weggenomen, met behulp van elektrochemische procédés of van elektronen- of ionenstralen (m.u.v. soldeer- en lasmachachines, machines voor onderzoek van materialen en machines voor de vervaardiging van halfgeleidermateriaal of van elektronisch geïntegreerde schakelingen)',
    84571010: 'horizontale bewerkingscentra voor de bewerking van metalen',
    84571090: 'niet-horizontale bewerkingscentra voor de bewerking van metalen',
    84572000: 'enkel-stationsbewerkingsmachines, voor de bewerking van metalen',
    84573010: 'Meervoudige transferbewerkingsmachines voor de bewerking van metalen, met numerieke besturing',
    84573090: 'Meervoudige transferbewerkingsmachines voor de bewerking van metalen (m.u.v. die met numerieke besturing)',
    84581120: 'Horizontale draaicentra voor het verspanend bewerken van metaal, met numerieke besturing',
    84581141: 'Horizontale draaiautomaten, éénspillig, voor het verspanend bewerken van metaal, met numerieke besturing',
    84581149: 'Horizontale draaiautomaten, meerspillig, voor het verspanend bewerken van metaal, met numerieke besturing',
    84581180: 'Horizontale draaibanken voor het verspanend bewerken van metaal, met numerieke besturing (m.u.v. draaicentra en draaiautomaten)',
    84581900: 'Horizontale draaibanken, incl. draaicentra, voor het verspanend bewerken van metaal (m.u.v. die met numerieke besturing)',
    84589120: 'Draaicentra voor het verspanend bewerken van metaal, met numerieke besturing (m.u.v. horizontale draaicentra)',
    84589180: 'Draaibanken voor het verspanend bewerken van metaal, met numerieke besturing (m.u.v. horizontale draaibanken; draaicentra)',
    84589900: 'draaibanken, incl. draaicentra, voor het verspanend bewerken van metaal, zonder numerieke besturing (m.u.v. horizontale draaibanken)',
    84591000: 'bewerkingseenheden op sleden, voor het boren, ruimen, frezen, draadsnijden of draadtappen in metaal',
    84592100: 'Boormachines voor de metaalbewerking, met numerieke besturing (m.u.v. bewerkingseenheden op slede)',
    84592900: 'Boormachines voor de metaalbewerking, zonder numerieke besturing (m.u.v. bewerkingseenheden op slede; handgereedschap)',
    84593100: 'ruim-freesmachines voor de metaalbewerking met numerieke besturing (m.u.v. bewerkingseenheden op sleden)',
    84593900: 'ruim-freesmachines voor de metaalbewerking zonder numerieke besturing (m.u.v. bewerkingseenheden op sleden)',
    84594100: 'Boormachines voor metalen met numerieke besturing (excl. Bewerkingseenheden op slede en freesmachines)',
    84594900: 'Boormachines voor metalen zonder numerieke besturing (excl. Bewerkingseenheden op slede en freesmachines)',
    84595100: 'kniefreesmachines voor de metaalbewerking, met numerieke besturing',
    84595900: 'kniefreesmachines voor de metaalbewerking, zonder numerieke besturing',
    84596110: 'machines voor het frezen van gereedschappen, van metaal, met numerieke besturing',
    84596190: 'freesmachines voor de metaalbewerking, met numerieke besturing (m.u.v. bewerkingseenheden op sleden, ruim-freesmachines, kniefreesmachines, machines voor het frezen van gereedschappen en machines voor het frezen, steken, schaven en slijpen van tandwielen)',
    84596910: 'machines voor het frezen van gereedschappen, van metaal, zonder numerieke besturing',
    84596990: 'freesmachines voor de metaalbewerking, zonder numerieke besturing (m.u.v. bewerkingseenheden op sleden, ruim-freesmachines, kniefreesmachines, machines voor het frezen van gereedschappen en machines voor het frezen, steken, schaven en slijpen van tandwielen)',
    84597000: 'machines voor het snijden of tappen van draad van metaal (m.u.v. bewerkingseenheden op sleden)',
    84601200: 'Vlakslijpmachines om metalen af te werken met numerieke besturing',
    84601900: 'Vlakslijpmachines om metalen af te werken zonder numerieke besturing',
    84602200: 'Centerloze uitwendige rondslijpmachines om metalen af te werken met numerieke besturing (excl. machines voor het afwerken van tandwielen)',
    84602300: 'rondslijpmachines om metalen af te werken met numerieke besturing (excl. machines voor het afwerken van tandwielen en centerloze uitwendige rondslijpmachines)',
    84602400: 'Slijpmachines om metalen af te werken met numerieke besturing (excl. Vlakslijp- en rondslijpmachines en machines voor het afwerken van tandwielen)',
    84602910: 'Slijpmachines voor het bewerken van cylindrische metalen oppervlakten zonder numerieke besturing (excl. Machines voor het frezen, steken, schaven, slijpen of afwerken van tandwielen)',
    84602990: 'Slijpmachines voor het bewerken van andere dan cylindrische metalen oppervlakten zonder numerieke besturing (excl. vlakslijpmachines en machines voor het frezen, steken, schaven, slijpen of afwerken van tandwielen)',
    84603100: 'machines voor het slijpen van gereedschap van metalen, gesinterde metaalcarbiden of cermets, met numerieke besturing',
    84603900: 'machines voor het slijpen van gereedschap van metalen, gesinterde metaalcarbiden of cermets, zonder numerieke besturing',
    84604010: 'Hoon- en lapmachines, voor de afwerking van metalen, gesinterde metaalcarbiden of cermets, met numerieke besturing (m.u.v. machines voor het afwerken van tandwielen)',
    84604090: 'Hoon- en lapmachines, voor de afwerking van metalen, gesinterde metaalcarbiden of cermets (m.u.v. die met numerieke besturing; machines voor het afwerken van tandwielen)',
    84609000: 'Machines voor het afbramen of  polijsten of op andere wijze afwerken van metalen of van cermets (excl. slijp-, hoon- en lapmachines en handwerktuigen)',
    84612000: 'sterke-armschaafbanken en steekbanken voor het bewerken van metaal, gesinterde metaalcarbiden of cermets',
    84613010: 'Trekfreesbanken voor het bewerken van metaal, gesinterde metaalcarbiden of cermets, met numerieke besturing',
    84613090: 'Trekfreesbanken voor het bewerken van metaal, gesinterde metaalcarbiden of cermets (m.u.v. die met numerieke besturing)',
    84614011: 'machines voor het frezen, steken, schaven en slijpen van cilindrische tandwielen van metaal, gesinterde metaalcarbiden of cermets, met numerieke besturing',
    84614019: 'machines voor het frezen, steken, schaven en slijpen van cilindrische tandwielen van metaal, gesinterde metaalcarbiden of cermets, zonder numerieke besturing',
    84614031: 'machines voor het frezen, steken, schaven en slijpen van niet-cilindrische tandwielen van metaal, gesinterde metaalcarbiden of cermets, met numerieke besturing',
    84614039: 'machines voor het frezen, steken, schaven en slijpen van niet-cilindrische tandwielen van metaal, gesinterde metaalcarbiden of cermets, zonder numerieke besturing',
    84614071: 'machines voor het afwerken van tandwielen van metaal, gesinterde metaalcarbiden of cermets, waarbij de instelling in een der assen tot op ten minste 0,01 mm nauwkeurig kan worden geregeld, met numerieke besturing',
    84614079: 'machines voor het afwerken van tandwielen van metaal, gesinterde metaalcarbiden of cermets, waarbij de instelling in een der assen tot op ten minste 0,01 mm nauwkeurig kan worden geregeld, zonder numerieke besturing',
    84614090: 'machines voor het afwerken van tandwielen van metaal, gesinterde metaalcarbiden of cermets (m.u.v. die waarbij de instelling in een der assen tot op ten minste 0,01 mm nauwkeurig kan worden geregeld)',
    84615011: 'cirkelzaagmachines, voor het bewerken van tandwielen van metaal, gesinterde metaalcarbiden of cermets (m.u.v. handgereedschappen)',
    84615019: 'zaagmachines voor de metaalbewerking (m.u.v. cirkelzaagmachines en handgereedschappen)',
    84615090: 'afsteekbanken voor het bewerken van tandwielen van metaal, gesinterde metaalcarbiden of cermets (m.u.v. zaagmachines en handgereedschappen)',
    84619000: 'schaafbanken en andere machines voor het verspanend bewerken van metaal, gesinterde metaalcarbiden of cermets, n.e.g.',
    84621010: 'machines, incl. persen, voor het smeden, het stampen of het hameren van metaal, met numerieke besturing',
    84621090: 'machines, incl. persen, voor het smeden, het stampen of het hameren van metaal, zonder numerieke besturing',
    84622110: 'machines, incl. persen, voor het buigen, het vouwen, het strekken of het vlakken van metaal, met numerieke besturing, voor het bewerken van vlakke producten',
    84622180: 'machines, incl. persen, voor het buigen, het vouwen, het strekken of het vlakken van metaal, met numerieke besturing (m.u.v. die van de soort gebruikt bij de vervaardiging van elementen of schakelingen van halfgeleidermateriaal en die voor het bewerken van vlakke producten)',
    84622910: 'machines, incl. persen, voor het buigen, het vouwen, het strekken of het vlakken van metaal, zonder numerieke besturing, voor het bewerken van vlakke producten',
    84622991: 'hydraulische machines, incl. persen, voor het buigen, het vouwen, het strekken of het vlakken van metaal, zonder numerieke besturing (m.u.v. die voor het bewerken van vlakke producten)',
    84622998: 'niet-hydraulische machines, incl. persen, voor het buigen, het vouwen, het strekken of het vlakken van metaal, zonder numerieke besturing (m.u.v. die van de soort gebruikt bij de vervaardiging van elementen of schakelingen van halfgeleidermateriaal en die voor het bewerken van vlakke producten)',
    84623100: 'Machines, incl. persen, voor het afknippen van metaal, met numerieke besturing (m.u.v. gecombineerde machines voor het ponsen en afknippen)',
    84623910: 'machines, incl. persen, voor het afknippen van metaal, zonder numerieke besturing, voor het bewerken van vlakke producten (m.u.v. gecombineerde machines voor het ponsen en afknippen)',
    84623991: 'hydraulische machines, incl. persen, voor het afknippen van metaal, zonder numerieke besturing (m.u.v. die voor het bewerken van vlakke producten en m.u.v. gecombineerde machines voor het ponsen en afknippen)',
    84623999: 'niet-hydraulische machines, incl. persen, voor het afknippen van metaal, zonder numerieke besturing (m.u.v. die voor het bewerken van vlakke producten en m.u.v. gecombineerde machines voor het ponsen en afknippen)',
    84624110: 'machines, incl. persen, voor het inkepen of het ponsen van metaal, incl. gecombineerde machines voor het ponsen en afknippen, met numerieke besturing, voor het bewerken van vlakke producten',
    84624190: 'machines, incl. persen, voor het inkepen of het ponsen van metaal, incl. gecombineerde machines voor het ponsen en afknippen, met numerieke besturing (m.u.v. die voor het bewerken van vlakke producten)',
    84624910: 'machines, incl. persen, voor het inkepen of het ponsen van metaal, incl. gecombineerde machines voor het ponsen en afknippen, zonder numerieke besturing, voor het bewerken van vlakke producten',
    84624990: 'machines, incl. persen, voor het inkepen of het ponsen van metaal, incl. gecombineerde machines voor het ponsen en afknippen, zonder numerieke besturing (m.u.v. die voor het bewerken van vlakke producten)',
    84629120: 'Hydraulische persen voor de metaalbewerking, met numerieke besturing (m.u.v. persen voor het smeden, het buigen, het vouwen, het strekken of het vlakken)',
    84629180: 'Hydraulische persen voor de metaalbewerking, zonder numerieke besturing (m.u.v. die voor het smeden, het buigen, het vouwen, het strekken of het vlakken)',
    84629920: 'Niet-hydraulische persen voor de metaalbewerking, met numerieke besturing (m.u.v. persen voor het smeden, het buigen, het vouwen, het strekken of het vlakken)',
    84629980: 'Niet-hydraulische persen voor de metaalbewerking, zonder numerieke besturing (m.u.v. die voor het smeden, het buigen, het vouwen, het strekken of het vlakken)',
    84631010: 'draadtrekbanken voor draad van metaal',
    84631090: 'trekbanken voor staven, buizen, profielen, e.d., van metaal (m.u.v. draadtrekbanken)',
    84632000: 'schroefdraadrol- en schroefdraadwalsmachines, voor de metaalbewerking',
    84633000: 'gereedschapswerktuigen voor het niet-verspanend bewerken van draad van metaal (m.u.v. machines voor het buigen van draad bedoeld bij post 8462 en m.u.v. handgereedschappen)',
    84639000: 'gereedschapswerktuigen voor het niet-verspanend bewerken van metaal, gesinterde metaalcarbiden of cermets (m.u.v. machines voor het smeden, het buigen, het vouwen, het strekken, het vlakken, het afknippen, het ponsen of het inkepen van metaal, persen, trekbanken, schroefdraadrol- en schroefdraadwalsmachines, machines voor het bewerken van draad en handgereedschappen)',
    84641000: 'Zaagmachines voor het bewerken van steen, van keramische producten, van beton, van asbestcement en van dergelijke minerale stoffen, alsmede voor het koud bewerken van glas (m.u.v. handgereedschap)',
    84642011: 'slijp- en polijstmachines voor het bewerken van glazen voor optische doeleinden',
    84642019: 'slijp- en polijstmachines voor het bewerken van glas (m.u.v. die voor het bewerken van glazen voor optische doeleinden)',
    84642080: 'Slijp- en polijstmachines voor het bewerken van steen, van beton, van asbestcement en van dergelijke minerale stoffen (m.u.v. die voor het bewerken van schijven "wafers" van halfgeleidermateriaal en die voor het koud bewerken van glas en m.u.v. handgereedschappen)',
    84649000: 'Gereedschapswerktuigen voor het bewerken van steen, van beton, van asbestcement en van dergelijke minerale stoffen, alsmede voor het koud bewerken van glas (m.u.v. zaagmachines, slijp- en polijstmachines, handgereedschappen en apparaten voor het inkerven of inkrassen van schijven "wafers" van halfgeleidermateriaal)',
    84651010: 'gereedschapswerktuigen voor het bewerken van hout, van kurk, van been, van geharde rubber, van geharde kunststof en van dergelijke harde stoffen, incl. machines voor het spijkeren, nieten, lijmen of op andere wijze samenvoegen, waarmede bewerkingen van verschillende aard zonder tussentijdse gereedschapswisseling kunnen worden uitgevoerd en waarbij het werkstuk bij elke bewerking met de hand wordt toegevoerd',
    84651090: 'gereedschapswerktuigen voor het bewerken van hout, van kurk, van been, van geharde rubber, van geharde kunststof en van dergelijke harde stoffen, incl. machines voor het spijkeren, nieten, lijmen of op andere wijze samenvoegen, waarmede bewerkingen van verschillende aard zonder tussentijdse gereedschapswisseling kunnen worden uitgevoerd en waarbij het werkstuk tussen elke bewerking automatisch wordt toegevoerd',
    84652000: 'Bewerkingscentra voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en dergelijke harde stoffen, die verschillende bewerkingen uitvoeren met gebruikmaking van automatische gereedschapswisseling vanuit een magazijn of dergelijke inrichting, overeenkomstig een vooraf vastgesteld bewerkingsprogramma',
    84659110: 'zaagmachines voor lintzagen, voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (m.u.v. handgereedschappen)',
    84659120: 'zaagmachines voor cirkelzagen, voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (m.u.v. handgereedschappen)',
    84659190: 'zaagmachines voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (m.u.v. die voor lintzagen of voor cirkelzagen en m.u.v. handgereedschappen)',
    84659200: 'Schaaf-, frees- en profileermachines voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (excl. Handwerktuigen en machines uit de hoofdstukken 8465.10 en 8465.20)',
    84659300: 'Schuur-, slijp- en polijstmachines voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (excl. Handwerktuigen en bewerkingscentra)',
    84659400: 'Machines voor het buigen of het ineenzetten,  voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (excl. Handwerktuigen en bewerkingscentra)',
    84659500: 'Boor- en uitsteekmachines voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen (excl. Handwerktuigen en machines uit de onderverdelingen 8465.10 en 8465.20)',
    84659600: 'Split-, afsnij- en schilmachines voor het bewerken van hout (excl. bewerkingscentra)',
    84659900: 'Andere machines voor het bewerken van hout, van kurk, van been, van geharde rubber, van harde kunststof en van dergelijke harde stoffen, n.e.g.  (excl. Handwerktuigen en machines uit de hoofdstukken 8465.10 en 8465.20, zaagmachines, schaafmachines, freesmachines, profileermachines, schuur-, slijp- en polijstmachines, machines voor het buigen en machines voor het ineenzetten, boormachines, uitsteekmachines, splijt-, afsnij- en schilmachines)',
    84661020: 'spandoorns, spantangen en hulzen, voor gebruik als gereedschaphouder in gereedschapswerktuigen, incl. die voor handgereedschap van alle soorten',
    84661031: 'gereedschaphouders voor draaibanken (m.u.v. spandoorns, spantangen en hulzen)',
    84661038: 'gereedschaphouders voor gereedschapswerktuigen, incl. die voor handgereedschap van alle soorten (m.u.v. die voor draaibanken en m.u.v. spandoorns, spantangen en hulzen)',
    84661080: 'zelfopenende draadsnijkoppen, voor gereedschapswerktuigen',
    84662020: 'werkstukhouders voor gereedschapswerktuigen, in de vorm van werkstukgebonden houders, incl. toebehoren voor werkstukgebonden houders',
    84662091: 'werkstukhouders voor draaibanken (m.u.v. werkstukgebonden houders, incl. toebehoren voor werkstukgebonden houders)',
    84662098: 'werkstukhouders voor gereedschapswerktuigen (m.u.v. die voor draaibanken en m.u.v. werkstukgebonden houders, incl. toebehoren voor werkstukgebonden houders)',
    84663000: 'verdeelkoppen en andere speciale toestellen voor montage op gereedschapswerktuigen, n.e.g.',
    84669120: 'delen en toebehoren voor gereedschapswerktuigen voor het bewerken van steen, van keramische producten, van beton enz., alsmede voor het koud bewerken van glas, van gietijzer of van gegoten staal, n.e.g.',
    84669195: 'Delen en toebehoren van gereedschapswerktuigen voor het bewerken van steen, van keramische producten, van beton, van asbestcement en van dergelijke minerale stoffen, alsmede voor het koud bewerken van glas, n.e.g. (m.u.v. die van gietijzer of van gegoten staal)',
    84669220: 'delen en toebehoren, van gietijzer of van gegoten staal, waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor gereedschapswerktuigen voor het bewerken van hout, van kurk, van been, van geharde rubber, van geharde kunststof en van dergelijke harde stoffen, n.e.g.',
    84669280: 'delen en toebehoren waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor gereedschapswerktuigen voor het bewerken van hout, van kurk, van been, van geharde rubber, van geharde kunststof en van dergelijke harde stoffen, n.e.g. (m.u.v. die van gietijzer of gegoten staal)',
    84669340: 'Delen en toebehoren van machines uit de onderverdelingen 84561110, 84561210, 845620, 845630, 845710, 845891, 84952100 of 846150,  hoofdzakelijk of uitsluitend bestemd voor het maken van gedrukte schakelingen, printkaarten en delen van hoofdstuk 8517 of onderdelen van gegevensverwerkende machines',
    84669350: 'Onderdelen en toebehoren van watersnijmachines, n.e.g.',
    84669360: 'Delen en toebehoren van machines om materialen te bewerken door het verwijderen van materiaal, van de posten 8456 tot en met 8461 n.e.g.',
    84669400: 'Delen en toebehoren voor gereedschapswerktuigen voor het niet-verspanend bewerken van metaal, n.e.g.',
    84671110: 'handgereedschap dat pneumatisch wordt aangedreven, roterend "ook indien met slagmechanisme", voor metaalbewerking',
    84671190: 'handgereedschap dat pneumatisch wordt aangedreven, roterend "ook indien met slagmechanisme" (m.u.v. die voor metaalbewerking)',
    84671900: 'Niet-roterend handgereedschap dat pneumatisch wordt aangedreven',
    84672110: 'handboormachines van alle soorten, met ingebouwde elektromotor, werkend zonder externe energiebron',
    84672191: 'elektropneumatische handboormachines van alle soorten',
    84672199: 'handboormachines van alle soorten, met ingebouwde elektromotor, voor aansluiting op het elektriciteitsnet',
    84672210: 'handkettingzagen, met ingebouwde elektromotor',
    84672230: 'handcirkelzagen, met ingebouwde elektromotor',
    84672290: 'handzagen, met ingebouwde elektromotor (m.u.v. ketting- en cirkelzagen)',
    84672920: 'elektromechanisch handgereedschap, met ingebouwde elektromotor, werkend zonder externe energiebron (m.u.v. boormachines en zagen)',
    84672951: 'haakse slijp- en schuurmachines, met ingebouwde elektromotor, handgereedschap, voor aansluiting op het elektriciteitsnet',
    84672953: 'bandschuurmachines, met ingebouwde elektromotor, handgereedschap, voor aansluiting op het elektriciteitsnet',
    84672959: 'slijp- en schuurmachines, met ingebouwde elektromotor, handgereedschap, voor aansluiting op het elektriciteitsnet (m.u.v. bandschuurmachines en haakse slijp- en schuurmachines)',
    84672970: 'handschaafmachines, met ingebouwde elektromotor, voor aansluiting op het elektriciteitsnet',
    84672980: 'heggenscharen, grasscharen en graskantsnijders, met handbediening, met ingebouwde elektromotor, voor aansluiting op het elektriciteitsnet',
    84672985: 'elektromechanisch handgereedschap met ingebouwde elektromotor, voor aansluiting op het elektriciteitsnet (m.u.v. boormachines, zagen, slijp- en schuurmachines, schaafmachines, heggescharen, grasscharen en graskantsnijders)',
    84678100: 'kettingzagen met handbediening, die door een ingebouwde niet-elektrische motor worden aangedreven',
    84678900: 'handgereedschap dat hydraulisch of door een ingebouwde niet-elektrische motor wordt aangedreven (m.u.v. kettingzagen en pneumatisch gereedschap)',
    84679100: 'delen van kettingzagen met handbediening, die door een ingebouwde elektrische of niet elektrische motor worden aangedreven, n.e.g.',
    84679200: 'delen van handgereedschap dat pneumatisch wordt aangedreven, n.e.g.',
    84679900: 'delen van handgereedschap dat hydraulisch of door een ingebouwde elektrische of niet elektrische motor wordt aangedreven, n.e.g.',
    84681000: 'handbranders voor het solderen of het lassen, werkend met gas',
    84682000: 'machines en toestellen voor het solderen of het lassen, ook indien geschikt voor het snijden, of voor het oppervlakteharden, werkend met gas (m.u.v. handbranders)',
    84688000: 'machines en toestellen voor het lassen (m.u.v. die welke werken met gas en m.u.v. elektrische machines en toestellen bedoeld bij post 8515)',
    84689000: 'delen van machines en toestellen voor het niet-elektrisch solderen, lassen, of oppervlakteharden, n.e.g.',
    84701000: 'elektronische rekenmachines die zonder externe elektrische energiebron kunnen functioneren en machines in zakformaat "afmetingen <= 170 mm x 100 mm x 45 mm" voor het opslaan, het reproduceren en het tonen van gegevens, met rekenfuncties',
    84702100: 'schrijvende elektronische rekenmachines, met aansluiting op het elektriciteitsnet (m.u.v. gegevens verwerkende machines bedoeld bij post 8471)',
    84702900: 'niet- schrijvende elektronische rekenmachines, met aansluiting op het elektriciteitsnet (m.u.v. gegevens verwerkende machines bedoeld bij post 8471)',
    84703000: 'niet-elektronische rekenmachines',
    84705000: 'kasregisters met rekenmechanisme',
    84709000: 'Boekhoudmachines, frankeermachines, machines voor de afgifte van kaartjes e.d. machines, met rekenmechanisme (m.u.v. kasregisters, rekenmachines en verkoopautomaten)',
    84713000: 'draagbare automatische gegevensverwerkende machines, wegende <= 10 kg, die ten minste bestaan uit een centrale verwerkingseenheid, een toetsenbord en een beeldscherm (m.u.v. randeenheden)',
    84714100: 'Automatische gegevensverwerkende machines, bevattende in dezelfde behuizing ten minste een centrale verwerkingseenheid en, al dan niet gecombineerd, een invoer- en uitvoereenheid (m.u.v. draagbare wegende <= 10 kg en die aangeboden in de vorm van systemen en randeenheden)',
    84714900: 'Automatische gegevensverwerkende machines, aangeboden in de vorm van systemen "bevattende ten minste een centrale verwerkingseenheid, en een invoer- en een uitvoereenheid" (m.u.v. draagbare, wegende <= 10 kg en m.u.v. randeenheden)',
    84715000: 'Verwerkingseenheden voor automatisch gegevensverwerkende machines, andere dan die bedoeld bij onderverdelingen 8471.40 of 8471.49, ook indien zij die in dezelfde behuizing een of twee van de volgende eenheden bevatten: geheugeneenheden, invoer- en uitvoereenheden (m.u.v. randeenheden)',
    84716060: 'Toetsenborden voor digitale automatische gegevensverwerkende machines',
    84716070: 'Invoer- of uitvoereenheden voor automatische gegevensverwerkende machines, ook indien in dezelfde behuizing geheugeneenheden bevattend (m.u.v. toetsenborden)',
    84717020: 'Geheugens van centrale eenheden voor digitale automatische gegevensverwerkende machines',
    84717030: 'Optische en magneto-optische schijvengeheugeneenheden voor digitale automatische gegevensverwerkende machines "b.v. CD-ROM-lezers" (m.u.v. geheugens van centrale eenheden)',
    84717050: 'Eenheden voor harde schijven van digitale automatische gegevensverwerkende machines, niet-optisch of magneto-optische, ( m.u.v. geheugens van centrale eenheden)',
    84717070: 'Schijvengeheugeneenheden voor digitale automatische gegevensverwerkende machines (niet optisch of magneto-optisch en m.u.v. eenheden voor harde schijven en geheugens van centrale eenheden)',
    84717080: 'Bandgeheugeneenheden voor digitale automatische gegevensverwerkende machines (m.u.v. geheugens van centrale eenheden)',
    84717098: 'Geheugeneenheden voor digitale automatische gegevensverwerkende machines (m.u.v. geheugens van centrale eenheden en schijven- en bandgeheugeneenheden)',
    84718000: 'Eenheden voor automatische gegevensverwerkende machines (m.u.v. verwerkingseenheden, invoereenheden, uitvoereenheden en geheugeneenheden)',
    84719000: 'magnetische en optische lezers, machines voor het in gecodeerde vorm op dragers overzetten van gegevens en machines voor het verwerken van die gegevens, n.e.g.',
    84721000: 'duplicators "hectografen en stencilmachines" (m.u.v. machines en toestellen voor het drukken en foto- en thermo-kopieerapparaten)',
    84723000: 'machines voor het sorteren, het vouwen, het in enveloppen steken of het van adresband voorzien van poststukken, machines voor het openen, het sluiten of het verzegelen van correspondentie en machines voor het aanbrengen of het stempelen van postzegels',
    84729010: 'geldsorteer-, geldtel- en geldinpakmachines',
    84729080: 'Kantoormachines n.e.g.',
    84732110: 'elektronische assemblages voor elektronische rekenmachines, bedoeld bij de onderverdelingen 8470.10, 8470.21 en 8470.29, n.e.g.',
    84732190: 'delen en toebehoren van elektronische rekenmachines, bedoeld bij de onderverdelingen 8470.10, 8470.21 en 8470.29, n.e.g. (m.u.v. elektronische assemblages)',
    84732910: 'elektronische assemblages van niet-elektronische rekenmachines of van boekhoudmachines, kasregisters en andere machines met rekenmechanisme, bedoeld bij post 8470, n.e.g.',
    84732990: 'delen en toebehoren van niet-elektronische rekenmachines of voor boekhoudmachines, kasregisters en andere machines met rekenmechanisme bedoeld bij post 8470, n.e.g. (m.u.v. elektronische assemblages)',
    84733020: 'Elektronische assemblages van automatische gegevensverwerkende machines en andere machines, bedoeld bij post 8471, n.e.g.',
    84733080: 'delen en toebehoren van automatische gegevensverwerkende machines en andere machines, bedoeld bij post 8471, n.e.g. (m.u.v. elektronische assemblages)',
    84734010: 'Elektronische assemblages voor andere kantoormachines bedoeld bij post 8472,  n.e.g.',
    84734080: 'delen en toebehoren van kantoormachines en toestellen, bedoeld bij post 8472, n.e.g. (m.u.v. elektronische assemblages)',
    84735020: 'elektronische assemblages die in dezelfde mate geschikt zijn voor gebruik met twee of meer van de machines bedoeld bij de posten 8469 tot en met 8472 "b.v. schrijfmachines, tekstverwerkende machines, rekenmachines", n.e.g.',
    84735080: 'delen en toebehoren die in dezelfde mate geschikt zijn voor gebruik met de machines bedoeld bij twee of meer van de posten 84.70 tot en met 84.72 n.e.g. (excl. Elektronische assemblages)',
    84741000: 'machines en toestellen voor het sorteren, het ziften, het scheiden of het wassen vaste minerale stoffen, incl. poeders en pasta\'s (m.u.v. centrifuges en filterpersen)',
    84742000: 'Machines en toestellen voor het breken, het malen of het verpulveren van vaste minerale stoffen',
    84743100: 'betonmolens en machines voor het aanmaken van mortel (m.u.v. die welke op spoorwagens of op vrachtwagenchassis zijn gemonteerd)',
    84743200: 'machines voor het mengen van minerale stoffen met bitumen',
    84743900: 'Machines en toestellen voor het mengen of het kneden van vaste minerale stoffen, incl. poeders en pasta\'s (m.u.v.  betonmolens, machines voor het aanmaken van mortel of voor het mengen van minerale stoffen met bitumen en kalanders)',
    84748010: 'machines voor het persen, het vormen of het gieten van keramische specie',
    84748090: 'machines voor het persen, het vormen of het gieten van vaste minerale brandstoffen, van cement, van gips of van andere minerale stoffen in poeder- of in pastavorm en machines voor het maken van gietvormen in zand (m.u.v. machines voor het persen, het vormen of het gieten van keramische specie of voor het gieten of persen van glas)',
    84749010: 'delen van machines en toestellen voor het be- of verwerken van minerale stoffen bedoeld bij post 8474, n.e.g., van gietijzer of van gegoten staal',
    84749090: 'delen van machines en toestellen voor het be- of verwerken van minerale stoffen bedoeld bij post 8474, n.e.g. (m.u.v. die van gietijzer of van gegoten staal)',
    84751000: 'machines voor het samenstellen van elektrische of elektronische lampen of buizen of van flitslampen, met een omhulling van glas',
    84752100: 'machines voor het vervaardigen van optische vezels en van onafgewerkte vormen "ébauches" daarvan',
    84752900: 'machines voor het vervaardigen of voor het warm bewerken van glas of van glaswerk (m.u.v. die voor het vervaardigen van optische vezels en van onafgewerkte vormen "ébauches" daarvan en m.u.v. ovens en verwarmingstoestellen voor het vervaardigen van hardglas)',
    84759010: 'Delen van machines voor het maken van optische vezels en voorvormen daarvan, n.e.g.',
    84759090: 'Delen van machines voor het samenstellen van elektrische of elektronische lampen of buizen of van flitslampen, met een omhulling van glas; machines voor het vervaardigen van of voor het warm bewerken van glas of van glaswerk, n.e.g.',
    84762100: 'verkoopautomaten voor dranken, voorzien van een inrichting voor het verwarmen of voor het koelen',
    84762900: 'verkoopautomaten voor dranken, zonder inrichting voor het verwarmen of voor het koelen',
    84768100: 'verkoopautomaten voorzien van een inrichting voor het verwarmen of voor het koelen (m.u.v. die voor dranken)',
    84768910: 'Geldwisselapparaten',
    84768990: 'Andere verkoopautomaten zonder verwarming of koelmogelijkheden (excl. Verkoopmachines voor dranken en geldwisselapparaten)',
    84769010: 'Onderdelen van geldwisselapparaten n.e.g.',
    84769090: 'Onderdelen van verkoopautomaten, n.e.g.  (excl. geldwisselapparaten)',
    84771000: 'Spuitgietmachines voor het bewerken  van rubber of van kunststof',
    84772000: 'strengpersen "extrudeerpersen" voor het bewerken van rubber of van kunststof',
    84773000: 'blaasvormmachines voor het bewerken van rubber of van kunststof',
    84774000: 'vacuümvormmachines en andere thermovormmachines voor het bewerken van rubber of van kunststof',
    84775100: 'machines voor het vormen van luchtbanden, voor het aanbrengen van een nieuw loopvlak op luchtbanden of voor het vervaardigen van binnenbanden van rubber of van kunststof',
    84775910: 'persen voor het vormen van producten van rubber of van kunststof (m.u.v. spuitgietmachines, strengpersen "extrudeerpersen", thermovormmachines en machines en toestellen voor het aanbrengen van een nieuw loopvlak op luchtbanden)',
    84775980: 'machines en toestellen voor het gieten of vormen van producten van rubber of van kunststof (m.u.v. die voor het aanbrengen van een omhulling om elementen of schakelingen van halfgeleidermateriaal en m.u.v. persen, spuitgietmachines, blaasvormmachines, vacuümvormmachines en andere thermovormmachines en machines voor het vormen van luchtbanden, voor het aanbrengen van een nieuw loopvlak op luchtbanden of voor het vervaardigen van binnenbanden)',
    84778011: 'machines voor de bewerking van reactieve harsen',
    84778019: 'machines voor het vervaardigen van producten met spons- of celstructuur (m.u.v. machines voor de bewerking van reactieve harsen)',
    84778091: 'maalmolens voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen',
    84778093: 'meng-, kneed- en roermachines en toestellen voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen',
    84778095: 'snij- en splijtmachines en afpelmachines voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen',
    84778099: 'Machines en toestellen voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen, n.e.g. onder andere posten van dit hoofdstuk',
    84779010: 'Delen van machines en toestellen voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen, bedoeld bij de onderverdelingen 8477.10.00 tot en met 8477.80.99, n.e.g., van gietijzer of van gegoten staal (m.u.v. die van vormmachines voor het aanbrengen van een omhulling om elementen of schakelingen van halfgeleidermateriaal)',
    84779080: 'Delen van machines en toestellen voor het bewerken van rubber of van kunststof of voor de vervaardiging van producten van deze stoffen, bedoeld bij de onderverdelingen 8477.10.00 tot en met 8477.80.99, n.e.g. (m.u.v. die van machines voor het aanbrengen van een omhulling om elementen of schakelingen van halfgeleidermateriaal en die van gietijzer of van gegoten staal)',
    84781000: 'machines en toestellen voor het bewerken of het verwerken van tabak (m.u.v. droogtoestellen en andere verwarmingstoestellen, centrifuges en filterpersen)',
    84789000: 'delen van machines en toestellen voor het bewerken of het verwerken van tabak, n.e.g.',
    84791000: 'machines en toestellen voor het uitvoeren van openbare werken, van bouwwerken en van dergelijke werken, n.e.g.',
    84792000: 'Machines en toestellen voor het extraheren of het bereiden van dierlijke olie of vet of van plantaardige vette olie of vet (m.u.v. centrifuges, filter- en verwarmingstoestellen)',
    84793010: 'persen voor het vervaardigen van spaan- en vezelplaat van hout of van andere houtachtige stoffen of voor het behandelen van hout of kurk (m.u.v. gereedschapswerktuigen bedoeld bij post 8465)',
    84793090: 'machines en toestellen voor de behandeling van hout of kurk (m.u.v. droogtoestellen, spuitpistolen e.d. toestellen, gereedschapswerktuigen en persen voor het vervaardigen van spaan- en vezelplaat)',
    84794000: 'machines voor het vervaardigen van touw en kabel (m.u.v. machines voor het twijnen van het soort gebruikt in spinnerijen)',
    84795000: 'industriële robots, n.e.g.',
    84796000: 'toestellen voor het verfrissen van lucht door middel van verdamping',
    84797100: 'Loopbruggen voor passagiers, van de soort gebruikt op luchthavens',
    84797900: 'Loopbruggen voor passagiers (m.u.v. die van de soort gebruikt op luchthavens)',
    84798100: 'machines en toestellen voor de behandeling van metaal, incl. wikkelmachines voor het vervaardigen van elektrische spoelen, n.e.g. (m.u.v. industriële robots, ovens, droogtoestellen, spuitpistolen e.d. toestellen, hoge-drukreinigers en andere met sproeikoppen werkende reinigingsmachines, walsstoelen, gereedschapswerktuigen en machines voor het vervaardigen van touw en kabel)',
    84798200: 'machines en toestellen voor het mengen, het kneden, het breken, het malen, het ziften, het homogeniseren, het emulgeren of het roeren, n.e.g. (m.u.v. industriële robots)',
    84798930: 'wandelende hydraulische mijnstutten',
    84798960: 'centrale smeertoestellen',
    84798970: 'Machines om hoofdzakelijk of alleen onderdelen te plaatsen bij het maken van printkaarten',
    84798997: 'machines en mechanische toestellen, n.e.g.',
    84799015: 'Onderdelen van machines om hoofdzakelijk of alleen onderdelen te plaatsen bij het maken van printkaarten',
    84799020: 'Onderdelen van machines en mechanische toestellen met  een eigen functie, van gietijzer of van gegoten staal, n.e.g.',
    84799070: 'Onderdelen van machines en mechanische toestellen met  een eigen functie n.e.g. (excl. van gietijzer of gegoten staal)',
    84801000: 'vormkasten voor gieterijen',
    84802000: 'Modelplaten voor gietvormen (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen of van glas)',
    84803010: 'modellen voor gietvormen, van hout',
    84803090: 'modellen voor gietvormen (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen, van glas of van hout)',
    84804100: 'vormen voor metalen of voor metaalcarbiden, voor het spuitgieten of het persgieten (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen of van glas)',
    84804900: 'vormen voor metalen of voor metaalcarbiden (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen of van glas en m.u.v. matrijzen en gietvormen voor regelgietmachines bedoeld bij post 8442 en vormen voor het spuit- of persgieten of voor het gieten van ingots)',
    84805000: 'vormen voor glas (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen)',
    84806000: 'vormen voor minerale stoffen (m.u.v. die van grafiet of van andere koolstof, van keramische stoffen of van glas)',
    84807100: 'Vormen voor het spuitgieten of het persgieten voor rubber of voor kunststof',
    84807900: 'Vormen voor rubber of voor kunststof (m.u.v. die voor het spuitgieten of het persgieten)',
    84811005: 'reduceerventielen gecombineerd met filters of met smeerapparaten',
    84811019: 'reduceerventielen van gietijzer of van staal (niet gecombineerd met filters of met smeerapparaten)',
    84811099: 'reduceerventielen (niet gecombineerd met filters of met smeerapparaten en m.u.v. die van gietijzer of van staal)',
    84812010: 'kleppen voor oleohydraulische overbrenging',
    84812090: 'kleppen voor pneumatische overbrenging',
    84813091: 'terugslagkleppen voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, van gietijzer of van staal',
    84813099: 'terugslagkleppen voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen (m.u.v. die van gietijzer of van staal)',
    84814010: 'overloopkleppen en veiligheidskleppen, van gietijzer of van staal',
    84814090: 'overloopkleppen en veiligheidskleppen (m.u.v. die van gietijzer of van staal)',
    84818011: 'sanitaire mengkranen',
    84818019: 'sanitaire kranen (m.u.v. mengkranen)',
    84818031: 'thermostatisch werkende kranen voor radiatoren van centrale verwarming',
    84818039: 'kranen voor radiatoren van centrale verwarming (m.u.v. thermostatisch werkende kranen)',
    84818040: 'ventielen voor luchtbanden',
    84818051: 'temperatuurregelaars (m.u.v. thermostatisch werkende kranen voor radiatoren van centrale verwarming)',
    84818059: 'regelafsluiters (m.u.v. temperatuurregelaars, reduceerventielen, kleppen voor oleohydraulische of pneumatische overbrenging, terugslagkleppen, overloop- en veiligheidskleppen, sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818061: 'schuifafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, van gietijzer (m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818063: 'schuifafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, van staal (m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818069: 'schuifafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen (m.u.v. die van gietijzer of van staal en m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818071: 'klepafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, van gietijzer (m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818073: 'klepafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, van staal (m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818079: 'klepafsluiters voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen (m.u.v. die van gietijzer of van staal en m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818081: 'kogelafsluiters en plugkranen (m.u.v. sanitaire kranen en kranen voor radiatoren van centrale verwarming)',
    84818085: 'vlinderkleppen voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen (m.u.v. terugslagkleppen)',
    84818087: 'membraanafsluiters, voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen',
    84818099: 'kranen e.d. artikelen, voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke artikelen (m.u.v. reduceerventielen, kleppen voor oleohydraulische of pneumatische overbrenging, terugslag-, overloop- en veiligheidskleppen, sanitaire kranen, kranen voor radiatoren van centrale verwarming, ventielen voor luchtbanden, regel-, schuif-, klep-, kogel- en membraanafsluiters, plugkranen en vlinderkleppen)',
    84819000: 'delen van kranen e.d. artikelen, voor leidingen, voor ketels, voor reservoirs, voor bakken of voor dergelijke bergingsmiddelen, n.e.g.',
    84821010: 'kogellagers met een grootste uitwendige diameter van <= 30 mm',
    84821090: 'kogellagers met een grootste uitwendige diameter van > 30 mm',
    84822000: 'kegellagers, incl. samenstellingen van conische ringen en conische rollen',
    84823000: 'tonlagers',
    84824000: 'naaldlagers',
    84825000: 'cilinderlagers',
    84828000: 'lagers, incl. gecombineerde lagers (m.u.v. kogellagers, kegellagers, samenstellingen van conische ringen en conische rollen, tonlagers, naaldlagers en cilinderlagers)',
    84829110: 'rollen in kegelvorm, voor lagers',
    84829190: 'kogels, rollen, naalden e.d., voor lagers (m.u.v. rollen in kegelvormen kogels van staal bedoeld bij post 7326)',
    84829900: 'delen van lagers, n.e.g. (m.u.v. wentellichamen)',
    84831021: 'Krukken en krukassen, van gietijzer of van gegoten staal',
    84831025: 'Krukken en krukassen, van gesmeed staal',
    84831029: 'Krukken en krukassen (m.u.v. die van gietijzer of van gegoten of gesmeed staal)',
    84831050: 'Gelede assen of scharnierassen',
    84831095: 'Drijfwerkassen, nokkenassen, excentrische assen en andere machine-assen (m.u.v.  krukken en krukassen; gelede assen en scharnierassen)',
    84832000: 'Kussenblokken voorzien van kogel-, rol-, naald- of dergelijke lagers',
    84833032: 'Kussenblokken voor kogel-, rol-, naald- of dergelijke lagers',
    84833038: 'Kussenblokken zonder kogel-, rol-, naald- of dergelijke lagers, voor lagerschalen (m.u.v. die bestemd voor kogel-, rol-, naald- of dergelijke lagers)',
    84833080: 'Lagerschalen',
    84834021: 'Getande overbrengingen, cilindrisch (m.u.v. tandwielkasten en andere overbrengingsmechanismen voor het opvoeren, vertragen of anderszins aanpassen van de snelheid)',
    84834023: 'Getande overbrengingen, conisch of cilindrisch-conisch (m.u.v. tandwielkasten en andere overbrengingsmechanismen voor het opvoeren, vertragen of anderszins aanpassen van de snelheid)',
    84834025: 'Wormwieloverbrengingen (m.u.v. tandwielkasten en andere overbrengingsmechanismen voor het opvoeren, vertragen of anderszins aanpassen van de snelheid)',
    84834029: 'Getande overbrengingen (m.u.v. cilindrische, conische of cilindrisch-conische overbrengingen; wormwieloverbrengingen; tandwielkasten en andere overbrengingsmechanismen voor het opvoeren, vertragen of aanpassen van de snelheid)',
    84834030: 'Kogellager- en rollagerassen, voor machines',
    84834051: 'Tandwielkasten en versnellingsbakken',
    84834059: 'Overbrengingsmechanismen voor het opvoeren, vertragen of anderszins aanpassen van de snelheid (m.u.v. tandwielkasten en versnellingsbakken)',
    84834090: 'Overbrengingsmechanismen (m.u.v. tandwielkasten en andere overbrengingsmechanismen voor het opvoeren, vertragen of anderszins aanpassen van de snelheid, kogellager- en rollagerassen en afzonderlijk aangeboden tandwielen en andere elementaire overbrengingsorganen)',
    84835020: 'Vliegwielen en riemschijven, incl. takelblokken, van gietijzer of van gegoten staal',
    84835080: 'Vliegwielen en riemschijven, incl. takelblokken (m.u.v. die van gietijzer of van gegoten staal)',
    84836020: 'Koppelingen en koppelingsorganen, incl. beweeglijke koppelingen, van gietijzer of van gegoten staal',
    84836080: 'Koppelingen en koppelingsorganen, incl. beweeglijke koppelingen (m.u.v. die van gietijzer of van gegoten staal)',
    84839020: 'Delen van kussenblokken voor lagers van alle soorten, n.e.g.',
    84839081: 'Afzonderlijk aangeboden tandwielen en andere elementaire overbrengingsorganen, en delen van drijfwerkassen, overbrengingsmechanismen, koppelingen en andere artikelen van post 8483, van gietijzer of van gegoten staal, n.e.g.',
    84839089: 'Afzonderlijk aangeboden tandwielen en andere elementaire overbrengingsorganen, en delen van drijfwerkassen, overbrengingsmechanismen, koppelingen en andere artikelen van post 8483, n.e.g. (m.u.v. die van gietijzer of van gegoten staal)',
    84841000: 'Metalloplastische pakking',
    84842000: 'mechanisme afdichtingen',
    84849000: 'Stellen of assortimenten van pakkingringen en andere pakkingstukken, van verschillende samenstelling, in zakjes, in enveloppen of in dergelijke bergingsmiddelen',
    84861000: 'Machines en apparaten voor de vervaardiging van staven of schijven "wafers" van halfgeleidermateriaal',
    84862000: 'machines en apparaten voor de vervaardiging van elementen of schakelingen van halfgeleidermateriaal of van elektronische geïntegreerde schakelingen',
    84863000: 'machines en apparaten voor de vervaardiging van platte beeldschermen',
    84864000: 'Machines en apparaten bedoeld bij aantekening 9 (C) op hoofdstuk 84',
    84869000: 'Delen en toebehoren van machines en apparaten van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van staven of schijven (wafers) van halfgeleidermateriaal, van elementen of schakelingen van halfgeleidermateriaal, van elektronische geïntegreerde schakelingen of van platte beeldschermen en van machines en apparaten bedoeld bij aantekening 9, onder C), op dit hoofdstuk, n.e.g.',
    84871010: 'scheepsschroeven en schroefbladen voor scheepsschroeven, van brons',
    84871090: 'scheepsschroeven en schroefbladen voor scheepsschroeven (m.u.v. die van brons)',
    84879040: 'delen van machines of van toestellen van hoofdstuk 84, zonder specifieke kenmerken wat het gebruik betreft, van gietijzer, n.e.g.',
    84879051: 'delen van machines of van toestellen van hoofdstuk 84, zonder specifieke kenmerken wat het gebruik betreft, van gegoten staal, n.e.g.',
    84879057: 'delen van machines of van toestellen van hoofdstuk 84, zonder specifieke kenmerken wat het gebruik betreft, van gesmeed of gestampt ijzer of staal, n.e.g.',
    84879059: 'delen van machines of van toestellen van hoofdstuk 84, zonder specifieke kenmerken wat het gebruik betreft, van ijzer of van staal, n.e.g. (m.u.v. die van gegoten staal, van gesmeed ijzer of staal, of van gestampt ijzer of staal)',
    84879090: 'Delen van machines of van toestellen van hoofdstuk 84, zonder specifieke kenmerken wat het gebruik betreft, n.e.g. (m.u.v. die van gietijzer, van ijzer of van staal)',
    85011010: 'synchroonmotoren met een vermogen van <= 18 W',
    85011091: 'universele motoren met een vermogen van <= 37,5 W',
    85011093: 'wisselstroommotoren met een vermogen van <= 37,5 W (m.u.v. synchroonmotoren met een vermogen van <= 18 W)',
    85011099: 'gelijkstroommotoren met een vermogen van <= 37,5 W',
    85012000: 'Universele motoren met een vermogen van > 37,5 W',
    85013100: 'Gelijkstroommotoren met een vermogen van > 37,5, doch <= 750 W en gelijkstroomgeneratoren met een vermogen van <= 750 W',
    85013200: 'Gelijkstroommotoren en -generatoren met een vermogen van > 750 W, doch <= 75 kW',
    85013300: 'Gelijkstroommotoren en -generatoren, met een vermogen van > 75 doch <= 375 kW',
    85013400: 'Gelijkstroommotoren en -generatoren met een vermogen van > 375 kW',
    85014020: 'Eenfasewisselstroommotoren met een vermogen van > 37,5 doch <= 750 W',
    85014080: 'Eenfasewisselstroommotoren met een vermogen van > 750 W',
    85015100: 'Meerfasenwisselstroommotoren, met een vermogen van > 37,5 doch <= 750 W',
    85015220: 'Meerfasenwisselstroommotoren, met een vermogen van > 750 W doch <= 7,5 kW',
    85015230: 'Meerfasenwisselstroommotoren, met een vermogen van > 7,5 doch <= 37 kW',
    85015290: 'Meerfasenwisselstroommotoren, met een vermogen van > 37 doch <= 75 kW',
    85015350: 'meerfasenwisselstroom-tractiemotoren, met een vermogen van > 75 kW',
    85015381: 'Meerfasenwisselstroommotoren met een vermogen van > 75 doch <= 375 kW (m.u.v. tractiemotoren)',
    85015394: 'meerfasenwisselstroommotoren met een vermogen van > 375 doch <= 750 kW (m.u.v. tractiemotoren)',
    85015399: 'meerfasenwisselstroommotoren, met een vermogen van > 750 kW (m.u.v. tractiemotoren)',
    85016120: 'Wisselstroomgeneratoren met een vermogen van <= 7,5 kVA',
    85016180: 'Wisselstroomgeneratoren met een vermogen van > 7,5 doch <= 75 kVA',
    85016200: 'Wisselstroomgeneratoren met een vermogen van > 75 doch <= 375 kVA',
    85016300: 'Wisselstroomgeneratoren met een vermogen van > 375 doch <= 750 kVA',
    85016400: 'wisselstroomgeneratoren met een vermogen van > 750 kVA',
    85021120: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren) met een vermogen van <= 7,5 kVA',
    85021180: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren), met een vermogen van > 7,5 doch <= 75 kVA',
    85021200: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren), met een vermogen van > 75 doch <= 375 kVA',
    85021320: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren), met een vermogen van > 375 doch <= 750 kVA',
    85021340: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren), met een vermogen van > 750 kVA doch <= 2 000 kVA',
    85021380: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met zelfontsteking (diesel- of semi-dieselmotoren), met een vermogen van > 2 000 kVA',
    85022020: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met vonkontsteking (explosiemotor), met een vermogen van <= 7,5 kVA',
    85022040: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met vonkontsteking (explosiemotor), met een vermogen van > 7,5 kVA doch <= 375 kVA',
    85022060: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met vonkontsteking (explosiemotor), met een vermogen van > 375 doch <= dan 750 kVA',
    85022080: 'Elektrische generatoraggregaten, aangedreven door een zuigermotor met vonkontsteking (explosiemotor), met een vermogen van > 750 kVA',
    85023100: 'Elektrische generatoraggregaten werkend op windenergie',
    85023920: 'Turbogeneratoren',
    85023980: 'Elektrische generatoraggregaten (m.u.v. die welke werken op windenergie of worden aangedreven door een explosie- of een verbrandingsmotor en m.u.v. turbogeneratoren)',
    85024000: 'Roterende omvormers',
    85030010: 'niet-magnetische ringen voor elektromotoren of elektrische generatoren',
    85030091: 'delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor elektromotoren, elektrische generatoren, elektrische generatoraggregaten of roterende omvormers, n.e.g., van gietijzer of van gegoten staal',
    85030099: 'delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor elektromotoren, elektrische generatoren, elektrische generatoraggregaten of roterende omvormers, n.e.g. (m.u.v. die van gietijzer of van gegoten staal en m.u.v. niet-magnetische ringen)',
    85041020: 'Smoorspoelen, voor ontladingslampen of -buizen, ook indien met aangeschakelde condensator',
    85041080: 'Ballasten voor ontladingslampen of -buizen (m.u.v. smoorspoelen, ook indien met aangeschakelde condensator)',
    85042100: 'transformatoren met vloeistofisolatie, met een vermogen van <= 650 kVA',
    85042210: 'transformatoren met vloeistofisolatie, met een vermogen van > 650, doch <= 1.600 kVA',
    85042290: 'transformatoren met vloeistofisolatie, met een vermogen van > 1.600, doch <= 10.000 kVA',
    85042300: 'transformatoren met vloeistofisolatie, met een vermogen van > 10.000 kVA',
    85043121: 'Elektrische meettransformatoren voor het meten van spanning, met een vermogen van <= 1 kVA',
    85043129: 'Elektrische meettransformatoren met een vermogen van <= 1 kVA (m.u.v. die voor het meten van de spanning)',
    85043180: 'Elektrische drogertransformatoren met een vermogen van <= 1 kVA (m.u.v. meettransformatoren)',
    85043200: 'Elektrische transformatoren met een vermogen van > 1 doch <= 16 kVA (m.u.v. die met vloeistofisolatie)',
    85043300: 'Elektrische drogertransformatoren met een vermogen van > 16 doch <= 500 kVA',
    85043400: 'elektrische drogertransformatoren met een vermogen van > 500 kVA',
    85044030: 'Statische omvormers van de soort gebruikt voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor',
    85044055: 'Gelijkrichters voor het laden van accumulatoren (m.u.v. die welke gebruikt worden voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor en m.u.v. polykristallijne halfgeleidergelijkrichters)',
    85044082: 'Statische gelijkrichters (m.u.v. die voor het laden van accumulatoren en die welke gebruikt worden voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor )',
    85044084: 'Statische omzetters met een vermogen van <= 7,5 kVA (m.u.v. die welke gebruikt worden voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor)',
    85044088: 'Statische omzetters met een vermogen van > 7,5 kVA (m.u.v. die welke gebruikt worden voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor)',
    85044090: 'Statische omvormers (m.u.v. die welke gebruikt worden voor telecommunicatietoestellen, automatische gegevensverwerkende machines en eenheden daarvoor en m.u.v. gelijkrichters voor het laden van accumulatoren, polykristallijne halfgeleidergelijkrichters, andere gelijkrichters en omzetters)',
    85045000: 'Smoorspoelen en zelfinductiespoelen (m.u.v. smoorspoelen voor ontladingslampen of -buizen)',
    85049011: 'kernen van ferriet, voor elektrische transformatoren, smoorspoelen en zelfinductiespoelen',
    85049019: 'delen van elektrische transformatoren, smoorspoelen en zelfinductiespoelen, n.e.g. (m.u.v.  kernen van ferriet)',
    85049090: 'delen van statische omvormers, n.e.g.',
    85051100: 'permanente magneten en artikelen bestemd om na magnetisering als permanente magneten te worden gebruikt, van metaal (m.u.v. opspanplaten en werkstukhouders)',
    85051910: 'permanente magneten van geagglomereerd ferriet',
    85051990: 'permanente magneten en artikelen bestemd om na magnetisering als permanente magneten te worden gebruikt (m.u.v. die van metaal of van geagglomereerd ferriet)',
    85052000: 'elektromagnetische koppelingen, gangwissels en remmen',
    85059021: 'Elektromagneten van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor magnetische resonantieapparaten anders dan elektromagneten van post 9018',
    85059029: 'Elektromagneten; magnetische en elektromagnetische klemmen, opspanplaten en dergelijke werkstukhouders (m.u.v. elektromagneten van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor magnetische resonantieapparaten anders dan elektromagneten van post 9018)',
    85059050: 'lastmagneten',
    85059090: 'delen van permanente magneten, elektromagneten, elektromagnetische koppelingen, gangwissels en remmen, lastmagneten of magnetische en elektromagnetische opspanplaten en werkstukhouders, n.e.g.',
    85061011: 'alkalische mangaandioxide-elementen en -batterijen in de vorm van cilindervormige elementen (niet gebruikt)',
    85061018: 'Alkalische mangaandioxide-elementen en -batterijen (niet gebruikt en m.u.v. cilindervormige elementen)',
    85061091: 'niet-alkalische mangaandioxide-elementen en -batterijen in de vorm van cilindervormige elementen (niet gebruikt)',
    85061098: 'Mangaandioxide-elementen en -batterijen, niet alkalisch (niet gebruikt en m.u.v. cilindervormige elementen)',
    85063000: 'Kwikoxide-elementen en -batterijen (niet gebruikt)',
    85064000: 'Zilveroxide-elementen en -batterijen (niet gebruikt)',
    85065010: 'lithiumelementen en -batterijen in de vorm van cilindervormige elementen (niet gebruikt)',
    85065030: 'lithiumelementen en -batterijen in de vorm van knoopelementen (niet gebruikt)',
    85065090: 'lithiumoxide-elementen en -batterijen (niet gebruikt en m.u.v. die in de vorm van cilindervormige of knoopelementen)',
    85066000: 'Lucht-zinkelementen en -batterijen (niet gebruikt)',
    85068005: 'droge zink-koolstofelementen en -batterijen met een spanning van >= 5,5 doch <= 6,5 V (niet gebruikt)',
    85068080: 'Elektrische elementen en elektrische batterijen (niet gebruikt, m.u.v. droge zink-koolstofelementen en -batterijen met een spanning van >= 5,5 doch <= 6,5 V, alsmede mangaandioxide-, kwikoxide-, zilveroxide-, lithium- en lucht-zinkelementen en -batterijen)',
    85069000: 'delen van elektrische elementen en van elektrische batterijen, n.e.g.',
    85071020: 'Niet gebruikte loodaccumulatoren van de soort gebruikt voor het starten van zuigermotoren, werkend met vloeibare elektrolyt',
    85071080: 'Niet gebruikte loodaccumulatoren van de soort gebruikt voor het starten van zuigermotoren,  werkend met niet-vloeibare elektrolyt',
    85072020: 'Niet gebruikte loodaccumulatoren werkend met vloeibare elektrolyt (m.u.v. die van de soort gebruikt voor het starten van zuigermotoren)',
    85072080: 'Niet gebruikte loodaccumulatoren werkend met niet-vloeibare elektrolyt (m.u.v. die van de soort gebruikt voor het starten van zuigermotoren)',
    85073020: 'Niet gebruikte gasdichte nikkel-cadmiumaccumulatoren',
    85073080: 'Niet-gebruikte en niet-gasdichte nikkel-cadmiumaccumulatoren',
    85074000: 'Niet gebruikte nikkel-ijzeraccumulatoren',
    85075000: 'Niet gebruikte nikkel-metaalhydridaccumulatoren',
    85076000: 'Niet gebruikte lithiumionaccumulatoren',
    85078000: 'Niet gebruikte elektrische accumulatoren (m.u.v. lood-, nikkel-cadmium-, nikkel-ijzer-, nikkelmetaalhydride- en lithiumionaccumulatoren)',
    85079030: 'Scheiplaten voor elektrische accumulatoren (m.u.v. die van niet- geharde gevulkaniseerde rubber of van textiel)',
    85079080: 'Delen van elektrische accumulatoren (m.u.v. scheiplaten voor elektrische accumulatoren)',
    85081100: 'stofzuigers, daaronder begrepen stofzuigers voor het opzuigen van droge stoffen en vloeistoffen, met ingebouwde elektromotor  met een vermogen <= 1500 W en een stofreservoir van <= 20 l',
    85081900: 'stofzuigers, daaronder begrepen stofzuigers voor het opzuigen van droge stoffen en vloeistoffen, met ingebouwde elektromotor (m.u.v. die met een vermogen <= 1500 W en een stofreservoir <= 20 l)',
    85086000: 'stofzuigers, daaronder begrepen stofzuigers voor het opzuigen van droge stoffen en vloeistoffen (m.u.v. die met ingebouwde elektromotor)',
    85087000: 'delen van stofzuigers, daaronder begrepen van stofzuigers voor het opzuigen van droge stoffen en vloeistoffen, n.e.g.',
    85094000: 'apparaten voor het malen, het mengen e.d. van voedings- en genotmiddelen en vruchten- en groentepersen, voor huishoudelijk gebruik, met ingebouwde elektromotor',
    85098000: 'Elektromechanische toestellen voor huishoudelijk gebruik, met ingebouwde elektromotor (m.u.v. stofzuigers, daaronder begrepen stofzuigers voor het opzuigen van droge stoffen en vloeistoffen, apparaten voor het malen, het mengen en dergelijke van voedings- en genotmiddelen, vruchten- en groentepersen en epileerapparaten)',
    85099000: 'delen van elektromechanische toestellen voor huishoudelijk gebruik, met ingebouwde elektromotor, n.e.g. (m.u.v. die van stofzuigers, daaronder begrepen stofzuigers voor het opzuigen van droge stoffen en vloeistoffen)',
    85101000: 'scheerapparaten, met ingebouwde elektromotor',
    85102000: 'tondeuses, met ingebouwde elektromotor',
    85103000: 'epileerapparaten met ingebouwde elektromotor',
    85109000: 'delen van scheerapparaten, tondeuses en epileerapparaten, met ingebouwde elektromotor, n.e.g.',
    85111000: 'Ontstekingsbougies, voor verbrandingsmotoren',
    85112000: 'Magneto\'s, dynamo-magneto\'s, en vliegwielmagneten, voor verbrandingsmotoren',
    85113000: 'Stroomverdelers en ontstekingsspoelen, voor verbrandingsmotoren',
    85114000: 'Elektrische startmotoren, ook indien werkend als generator, voor verbrandingsmotoren',
    85115000: 'Generatoren voor verbrandingsmotoren (m.u.v. dynamo-magneto\'s en startmotoren werkend als generator)',
    85118000: 'Elektrische ontstekingstoestellen voor verbrandingsmotoren, incl. automatische schakelaars voor vonkontsteking- en zelfontstekingsmotoren (m.u.v. generatoren, starttoestellen, stroomverdelers, ontstekingsspoelen, magneto\'s, vliegwielmagneten en ontstekingsbougies)',
    85119000: 'delen van elektrische ontstekings- en starttoestellen, generatoren en automatische schakelaars, bedoeld bij post 8511, n.e.g.',
    85121000: 'Elektrische verlichtingstoestellen en elektrische toestellen voor het geven van zichtbare signalen, van de soort gebruikt op rijwielen (m.u.v. lampen en buizen bedoeld bij post 8539)',
    85122000: 'elektrische verlichtingstoestellen en elektrische toestellen voor het geven van zichtbare signalen, van de soort gebruikt op motorvoertuigen (m.u.v. lampen en buizen bedoeld bij post 8539)',
    85123010: 'Elektrische toestellen voor het geven van geluidssignalen "alarmtoestellen tegen diefstal" van de soort gebruikt voor motorvoertuigen',
    85123090: 'Elektrische toestellen voor het geven van geluidssignalen, van de soort gebruikt op rijwielen (m.u.v. alarmtoestellen tegen diefstal van de soort gebruikt voor motorvoertuigen)',
    85124000: 'elektrische ruitenwissers en elektrische toestellen om ijsafzetting op of om het beslaan van ruiten tegen te gaan, van de soort gebruikt op motorvoertuigen',
    85129010: 'Delen van elektrische toestellen voor het geven van geluidssignalen "alarmtoestellen tegen diefstal" van de soort gebruikt voor motorvoertuigen, n.e.g.',
    85129090: 'Delen van elektrische verlichtingstoestellen, van elektrische signaal- en waarschuwingstoestellen, van elektrische ruitenwissers en van elektrische toestellen om ijsafzetting op of om het beslaan van ruiten tegen te gaan, van de soort gebruikt op rijwielen of op motorvoertuigen, n.e.g. (m.u.v. toestellen voor het geven van geluidssignalen "alarmtoestellen tegen diefstal" voor motorvoertuigen)',
    85131000: 'zaklampen en andere draagbare elektrische lampen, bestemd om met eigen energiebron te werken "b.v. met elementen of batterijen, met accumulatoren of met ingebouwde dynamo" (m.u.v. verlichtingstoestellen bedoeld bij post 8512)',
    85139000: 'delen van zaklampen en andere draagbare elektrische lampen, bestemd om met eigen energiebron te werken, n.e.g.',
    85141010: 'elektrische bakkersovens, zijnde weerstandovens "met indirecte verwarming"',
    85141080: 'weerstandovens "met indirecte verwarming", voor industrieel gebruik of voor laboratoriumgebruik (m.u.v. die voor de vervaardiging van elementen of schakelingen van halfgeleidermateriaal op schijven "wafers" van halfgeleidermateriaal en m.u.v. droogovens en bakkersovens)',
    85142010: 'ovens met inductieve verwarming',
    85142080: 'ovens met diëlektrische verwarming (m.u.v. die voor de vervaardiging van elementen of schakelingen van halfgeleidermateriaal op schijven "wafers" van halfgeleidermateriaal)',
    85143020: 'Elektrische ovens voor industrieel gebruik of voor laboratoriumgebruik uitsluitend of hoofdzakelijk gebruikt voor de vervaardiging van gedrukte schakelingen en printplaten',
    85143080: 'Elektrische ovens voor industrieel gebruik of voor laboratoriumgebruik (m.u.v. weerstandsovens "met indirecte verwarming", ovens met inductieve of met diëlektrische verwarming, droogovens en ovens gebruikt voor de vervaardiging van gedrukte schakelingen)',
    85144000: 'toestellen voor de behandeling van stoffen door inductieve of door diëlektrische verwarming (m.u.v. ovens)',
    85149030: 'Delen van elektrische ovens  van post 8514 30 20 gebruikt voor de vervaardiging  van gedrukte schakelingen',
    85149070: 'Delen van elektrische ovens voor industrieel of voor laboratoriumgebruik, ovens werkend met inductieve of met diëlektrische verwarming daaronder begrepen, en van andere toestellen voor industrieel gebruik of voor laboratoriumgebruik, voor de behandeling van stoffen door inductieve of door diëlektrische verwarming, n.e.g.',
    85151100: 'elektrische soldeerbouten en elektrische soldeerpistolen',
    85151910: 'Machines, apparaten en toestellen voor het golfsolderen, uitsluitend en hoofdzakelijk gebruikt voor de vervaardiging van printkaarten',
    85151990: 'Machines, apparaten en toestellen voor hard of zacht solderen ( m.u.v. soldeerijzers en soldeerpistolen, en  golfsoldeerapparaten uitsluitend en hoofdzakelijk gebruikt voor de vervaardiging van printkaarten)',
    85152100: 'automatische of half-automatische machines, apparaten en toestellen voor het weerstandlassen van metalen',
    85152900: 'Machines, apparaten en toestellen voor het weerstandlassen van metalen (niet-automatisch of halfautomatisch)',
    85153100: 'automatische of half-automatische machines, apparaten en toestellen werkend met lichtboog of met plasmastraal, voor het lassen van metalen',
    85153913: 'machines, apparaten en toestellen werkend met lichtboog of met plasmastraal, voor het lassen van metalen, met handbediening, met beklede elektroden, bestaande uit laskoppen of -tangen en een transformator',
    85153918: 'Machines, apparaten en toestellen werkend met lichtboog- of met plasmastraal, voor het lassen van metalen, met handbediening, met beklede elektroden, bestaande uit laskoppen of -tangen en een generator, een roterende omvormer of een statische omvormer',
    85153990: 'machines, apparaten en toestellen werkend met lichtboog of met plasmastraal, voor het lassen van metalen, niet automatisch of halfautomatisch (m.u.v. die met handbediening en met beklede elektroden)',
    85158010: 'Elektrische machines, apparaten en toestellen voor het lassen of voor het warm verspuiten van metaal, van cermets of van gesinterde hardmetalen (m.u.v. die voor het weerstandlassen of voor het lassen met lichtboog of met plasmastraal en m.u.v. afzonderlijk aangeboden spuitpistolen voor metaal)',
    85158090: 'Elektrische machines, apparaten en toestellen voor het lassen van thermoplastische stoffen (m.u.v. die voor het aanbrengen van verbindingsdraad "zgn. wire bonders", van de soort gebruikt voor de vervaardiging van elementen of schakelingen van halfgeleidermateriaal)',
    85159020: 'Delen van machines, apparaten en toestellen voor het golfsolderen,  gebruikt voor de vervaardiging van printkaarten',
    85159080: 'Delen van machines, apparaten en toestellen voor het solderen, voor het lassen of voor het warm verspuiten van metaal, van cermets of van hardmetalen, n.e.g. (m.u.v.die van golfsoldeermachines gebruikt voor de vervaardiging van printkaarten )',
    85161011: 'elektrische geisers',
    85161080: 'Elektrische heetwatertoestellen en elektrische dompelaars (m.u.v. geisers)',
    85162100: 'accumulatieradiatoren voor de verwarming van woonruimten',
    85162910: 'elektrische radiatoren met vloeistofcirculatie, voor verwarming van woonruimten, voor bodemverwarming of voor dergelijk gebruik',
    85162950: 'elektrische convectieradiatoren, voor verwarming van woonruimten, voor bodemverwarming of voor dergelijk gebruik',
    85162991: 'elektrische toestellen voor verwarming van woonruimten, voor bodemverwarming of voor dergelijk gebruik, met ingebouwde ventilator (m.u.v. accumulatieradiatoren)',
    85162999: 'elektrische toestellen voor verwarming van woonruimten, voor bodemverwarming of voor dergelijk gebruik, zonder ingebouwde ventilator (m.u.v. accumulatieradiatoren)',
    85163100: 'Elektrische haardroogtoestellen',
    85163200: 'elektrothermische toestellen voor haarbehandeling (m.u.v. haardroogtoestellen)',
    85163300: 'elektrische toestellen voor het drogen van de handen',
    85164000: 'Elektrische strijkijzers',
    85165000: 'microgolfovens',
    85166010: 'elektrische fornuizen, voor huishoudelijk gebruik',
    85166050: 'Elektrische komforen en kookplaten, voor huishoudelijk gebruik',
    85166070: 'elektrische grilleerapparaten, voor huishoudelijk gebruik',
    85166080: 'elektrische inbouwovens, voor huishoudelijk gebruik',
    85166090: 'elektrische ovens, voor huishoudelijk gebruik (m.u.v. microgolfovens, fornuizen en inbouwovens)',
    85167100: 'elektrothermische koffie- en theezetapparaten, voor huishoudelijk gebruik',
    85167200: 'elektrische broodroosters, voor huishoudelijk gebruik',
    85167920: 'elektrische frituurpannen voor huishoudelijk gebruik',
    85167970: 'elektrothermische toestellen voor huishoudelijk gebruik (m.u.v. die voor haarbehandeling, voor het drogen van de handen, voor verwarming van woonruimten, voor bodemverwarming of voor dergelijke gebruik en m.u.v. geisers en andere heetwatertoestellen, dompelaars, strijki jzers, microgolfovens en andere ovens, fornuizen, komforen, grilleerapparaten, koffie- en theezetapparaten, broodroosters en frituurpannen)',
    85168020: 'Elektrische verwarmingselementen (verwarmingsweerstanden), aangebracht op een drager van isolerende stof',
    85168080: 'Elektrische verwarmingselementen (verwarmingsweerstanden), (m.u.v. die aangebracht op een drager van isolerende stof; die van geagglomereerde koolstof of grafiet)',
    85169000: 'delen van elektrische heetwatertoestellen, toestellen voor verwarming van woonruimten, toestellen voor haarbehandeling of voor het drogen van de handen, elektrotechnische toestellen voor huishoudelijk gebruik en elektrische verwarmingselementen "verwarmingsweerstanden", n.e.g.',
    85171100: 'lijntelefoontoestellen met draagbare draadloze hoorn',
    85171200: 'Telefoontoestellen voor cellulaire netwerken of voor andere draadloze netwerken',
    85171800: 'Telefoontoestellen (m.u.v. die met draagbare draadloze hoorn en die voor cellulaire netwerken of voor andere draadloze netwerken)',
    85176100: 'Basisstations voor het zenden of ontvangen van spraak, beelden of van andere gegevens',
    85176200: 'Toestellen voor het ontvangen, omzetten en zenden of regenereren van spraak, van beelden of van andere gegevens, schakelapparaten en toestellen voor het routen daaronder begrepen (m.u.v. telefoontoestellen,  toestellen voor cellulaire netwerken of voor andere draadloze netwerken)',
    85176910: 'videofoontoestellen',
    85176920: 'Parlofoons',
    85176930: 'Ontvangtoestellen voor radiotelefonie of radiotelegrafie',
    85176990: 'Toestellen voor het zenden of ontvangen van spraak, van beelden of van andere gegevens, daaronder begrepen toestellen voor de overdracht in een kabelnetwerk of in een draadloos netwerk (zoals  een lokaal netwerk of een uitgestrekt netwerk) (m.u.v. telefoontoestellen, toestellen voor cellulaire netwerken of voor andere draadloze netwerken, basisstations, toestellen voor het ontvangen, omzetten en zenden of regenereren van spraak van beelden of van andere gegevens, videofoontoestellen, parlofoons, ontvangtoestellen voor radiotelefonie of radiotelegrafie  en die bedoeld bij posten 8443, 8525, 8527 en 8528)',
    85177000: 'Delen van telefoontoestellen, daaronder begrepen delen van telefoontoestellen voor cellulaire netwerken of voor andere draadloze netwerken; delen van andere toestellen voor het zenden of ontvangen van spraak, van beelden of van andere gegevens, n.e.g.',
    85181000: 'Microfoons en statieven daarvoor (m.u.v. draadloze microfoons met ingebouwde zender)',
    85182100: 'Klankkasten met één luidspreker',
    85182200: 'Klankkasten met meer dan een luidspreker',
    85182900: 'Luidsprekers zonder klankkast',
    85183000: 'Hoofdtelefoons en oortelefoons, ook indien gecombineerd met een microfoon, en stellen of assortimenten bestaande uit een microfoon en een of meer luidsprekers (m.u.v. telefoontoestellen, gehoorapparaten en helmen met ingebouwde hoofdtelefoons, ook indien met microfoon)',
    85184000: 'Elektrische audiofrequentversterkers',
    85185000: 'Elektrische geluidsversterkers',
    85189000: 'delen van microfoons, van luidsprekers, van hoofd- en oortelefoons, van elektrische audiofrequentversterkers en van elektrische geluidsversterkers n.e.g.',
    85192010: 'elektrogrammofoons, werkend met munt- of penninginworp',
    85192091: 'Toestellen voor het opnemen of het weergeven van geluid, werkende op munten, bankbiljetten, bankkaarten, penningen of door andere wijze van betaling, voorzien van een mechanisme voor aflezing door middel van laserstraal (m.u.v. elektrogrammofoons, werkend met munt- of penninginworp)',
    85192099: 'Toestellen voor het opnemen of het weergeven van geluid, werkende op munten, bankbiljetten, bankkaarten, penningen of door andere wijze van betaling, zonder mechanisme voor aflezing door middel van laserstraal (m.u.v. elektrogrammofoons, werkend met munt- of penninginworp)',
    85193000: 'Platenspelers',
    85195000: 'Telefoonantwoordapparaten',
    85198100: 'Toestellen voor het opnemen of het weergeven van geluid, werkend met magnetische dragers, met optische dragers of met dragers op basis van halfgeleiders (m.u.v. die werkend op munten, bankbiljetten, bankkaarten, penningen of door andere wijze van betaling, platenspelers, telefoonantwoordapparaten)',
    85198900: 'Toestellen voor het opnemen of het weergeven van geluid (m.u.v. die werkend met magnetische dragers, optische dragers of dragers op basis van halfgeleiders en m.u.v. die werkend op munten, bankbiljetten, bankkaarten, penningen of door andere wijze van betaling, platenspelers,telefoonantwoordapparaten)',
    85211020: 'Video-opname- en videoweergaveapparaten, ook indien met ingebouwde videotuner, werkend met magneetbanden met een bandbreedte van <= 1,3 cm en geschikt voor opname of weergave met een bandsnelheid van <= 50 mm/sec (m.u.v. videocamera-opnametoestellen)',
    85211095: 'Video-opname- en videoweergaveapparaten, ook indien met ingebouwde videotuner, werkend met magneetbanden (m.u.v. die werkend met magneetbanden met een bandbreedte van <= 1,3 cm en geschikt voor opname of weergave met een bandsnelheid van <= 50 mm/sec en m.u.v. videocamera-opnametoestellen)',
    85219000: 'video-opname- en videoweergaveapparaten, ook indien met ingebouwde videotuner (m.u.v. die werkend met magneetbanden en m.u.v. videocamera-opnametoestellen)',
    85221000: 'weergavekoppen voor platenspelers',
    85229000: 'Delen en toebehoren, waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk zijn bestemd voor toestellen en apparaten voor het opnemen en het weergeven van geluid of voor video-opname- en videoweergaveapparaten (m.u.v. weergavekoppen voor platenspelers)',
    85232100: 'Kaarten voorzien van een mangnetische strip voor het opnemen van geluid of voor dergelijke doeleinden',
    85232915: 'Magneetbanden en magneetschijven, waarop niet is opgenomen, voor het opnemen van geluid of voor dergelijke doeleinden',
    85232919: 'Magneetbanden en magneetschijven, voor het opnemen van geluid of voor dergelijke doeleinden, waarop is opgenomen',
    85232990: 'Magnetische dragers, voor het opnemen van geluid of voor dergelijke doeleinden (m.u.v. kaarten voorzien van een magnetische strip,  magneetbanden, magneetschijven en goederen behorend tot hoofdstuk 37)',
    85234110: 'Optische platen voor afleessysteem door middel van een laserstraal, niet opgenomen, met een opnamecapaciteit van <=900 megabyte, niet uitwisbare "CD-R"',
    85234130: 'Optische platen voor afleessysteem door middel van een laserstraal, niet opgenomen, met een opnamecapaciteit van > 900 megabyte doch <= 18 gigabyte, niet uitwisbare "DVD-/+R"',
    85234190: 'Optische dragers, niet opgenomen, voor het opnemen van geluid of voor dergelijke doeleinden, bv. CD-RW, DVD-/+RW, DVD-RAM, MiniDiscs, (m.u.v. niet-uitwisbare platen voor afleessystemen door middel van een laserstraal, met een opnamecapaciteit van <= 18 gigabyte "CD-R, DVD-/+R" en m.u.v. goederen bedoeld bij hoofdstuk 37)',
    85234910: 'Digitale veelzijdige discs  „Digital versatile discs" (dvd)” , waarop is opgenomen',
    85234920: 'Optische platen voor afleessystemen door middel van een laserstraal, waarop is opgenomen (m.u.v. DVDs)',
    85234990: 'Optische dragers, waarop is opgenomen (m.u.v. optische platen voor afleessystemen door middel van een laserstraal)',
    85235110: 'Niet-vluchtige geheugens op basis van halfgeleiders voor het opnemen van gegevens van een externe bron, bv. "flashgeheugenkaarten" of "flash elektronische geheugenkaarten", waarop niet is opgenomen',
    85235190: 'Niet-vluchtige geheugens op basis van halfgeleiders voor het opnemen van gegevens van een externe bron, bv. "flashgeheugenkaarten" of "flash elektronische geheugenkaarten", waarop is opgenomen',
    85235200: 'Kaarten  met een of meer elektronische geïntegreerde schakelingen "intelligente kaarten"',
    85235910: 'Dragers op basis van halfgeleiders, waarop niet is opgenomen, voor het opnemen van geluid of voor dergelijke doeleinden (m.u.v. niet-vluchtige geheugens op basis van halfgeleiders en intelligente kaarten)',
    85235990: 'Dragers op basis van halfgeleiders, waarop is opgenomen (m.u.v. niet-vluchtige geheugens op basis van halfgeleiders voor het opnemen van gegevens van een externe bron en m.u.v. kaarten  met een of meer elektronische geïntegreerde schakelingen "intelligente kaarten")',
    85238010: 'Dragers voor het opnemen van geluid of voor dergelijke doeleinden, waarop niet is opgenomen, galvanische vormen en matrijzen voor het maken van platen daaronder begrepen (m.u.v. magnetische en optische dragers, dragers op basis van halfgeleiders, en producten bedoeld bij hoofdstuk 37)',
    85238090: 'Media, waarop is opgenomen, voor het weergeven van geluids- of beeldopnamen, galvanische vormen en matrijzen voor het maken van platen daaronder begrepen (m.u.v. magnetische en optische dragers, dragers op basis van halfgeleiders, en m.u.v. goederen bedoeld bij hoofdstuk 37)',
    85255000: 'Zendtoestellen voor radio-omroep of televisie, zonder ingebouwd ontvangtoestel',
    85256000: 'Zendtoestellen voor radio-omroep of televisie, met ingebouwd ontvangtoestel',
    85258011: 'televisiecamera\'s, bevattende >= 3 beeldopnamebuizen',
    85258019: 'televisiecamera\'s (m.u.v. die welke ten minste drie beeldopnamebuizen bevatten en m.u.v. videocamera-opnametoestellen)',
    85258030: 'Digitale fototoestellen',
    85258091: 'videocamera-opnametoestellen, enkel voorzien van een opnamemogelijkheid van het door de televisiecamera geregistreerde beeld en geluid',
    85258099: 'videocamera-opnametoestellen, voorzien van een opnamemogelijkheid van televisieprogramma\'s en van het door de televisiecamera geregistreerde beeld en geluid',
    85261000: 'Radartoestellen',
    85269120: 'Toestellen voor radionavigatie (m.u.v. radartoestellen)',
    85269180: 'Toestellen voor radionavigatie (m.u.v. radionavigatie-ontvangtoestellen en radartoestellen)',
    85269200: 'Toestellen voor radioafstandsbediening',
    85271200: 'zakformaat-radiocassettespelers waarvan de afmetingen <= 170 x 100 x 45 mm bedragen, met ingebouwde versterker, doch zonder ingebouwde luidspreker, die zonder externe energiebron kunnen functioneren',
    85271300: 'ontvangtoestellen voor radio-omroep, die zonder externe energiebron kunnen functioneren, gecombineerd met een toestel voor het opnemen of het weergeven van geluid (m.u.v. zakformaat-radiocassettespelers)',
    85271900: 'ontvangtoestellen voor radio-omroep, die zonder externe energiebron kunnen functioneren, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen (m.u.v. die welke zijn gecombineerd met een toestel voor het opnemen of het weergeven van geluid)',
    85272120: 'ontvangtoestellen voor radio-omroep, geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid, voorzien van een mechanisme voor aflezing door middel van een laserstraal',
    85272152: 'ontvangtoestellen voor radio-omroep, geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid, werkend met cassettes en voorzien van een mechanisme voor analoge en digitale aflezing',
    85272159: 'ontvangtoestellen voor radio-omroep, geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid (m.u.v. die voorzien van een mechanisme voor aflezing door middel van een laserstraal of werkend met cassettes en voorzien van een mechanisme voor analoge en digitale aflezing)',
    85272170: 'ontvangtoestellen voor radio-omroep, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid, voorzien van een mechanisme voor aflezing door middel van een laserstraal (niet geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen)',
    85272192: 'ontvangtoestellen voor radio-omroep, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid, werkend met cassettes en voorzien van een mechanisme voor analoge en digitale aflezing (niet geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen)',
    85272198: 'ontvangtoestellen voor radio-omroep, die niet zonder externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen, gecombineerd met een toestel voor het opnemen of het weergeven van geluid (niet geschikt voor de ontvangst en het decoderen van digitale radio-informatiesysteem "RDS" signalen en m.u.v. die voorzien van een mechanisme voor aflezing door middel van een laserstraal of werkend met cassettes en voorzien van een mechanisme voor analoge en digitale aflezing)',
    85272900: 'ontvangtoestellen voor radio-omroep, die uitsluitend met externe energiebron kunnen functioneren, van de soort die in motorvoertuigen wordt gebruikt, incl. toestellen waarmee tevens radiotelefonie of radiotelegrafie kan worden ontvangen (m.u.v. die welke zijn gecombineerd met een toestel voor het opnemen of het weergeven van geluid)',
    85279100: 'ontvangtoestellen voor radio-omroep, die uitsluitend met externe energiebron kunnen functioneren, gecombineerd met een toestel voor het opnemen en het weergeven van geluid (m.u.v. die van de soort die in motorvoertuigen wordt gebruikt)',
    85279200: 'ontvangtoestellen voor radio-omroep, die uitsluitend met externe energiebron kunnen functioneren, zonder toestel voor het opnemen of het weergeven van geluid doch gecombineerd met een uurwerk (m.u.v. die van de soort die in motorvoertuigen wordt gebruikt)',
    85279900: 'ontvangtoestellen voor radio-omroep, die uitsluitend met externe energiebron kunnen functioneren, zonder toestel voor het opnemen of het weergeven van geluid en zonder uurwerk (m.u.v. die van de soort die in motorvoertuigen wordt gebruikt)',
    85284200: 'Monitors werkend met kathodestraalbuis, niet uitgerust met ontvangtoestel voor televisie, van de soort die uitsluitend of hoofdzakelijk wordt gebruikt of direct verbinding kan maken met een automatisch gegevensverwerkend systeem als bedoeld bij post 8471',
    85284900: 'Monitors werkend met kathodestraalbuis, niet uitgerust met ontvangtoestel voor televisie (m.u.v. die van de soort uitsluitend of hoofdzakelijk gebruikt in een automatisch gegevensverwerkend systeem bedoeld bij post 8471)',
    85285210: 'Monitors, niet uitgerust met ontvangtoestel voor televisie, van de soort die uitsluitend of hoofdzakelijk wordt gebruikt in een automatisch gegevensverwerkend systeem als bedoeld bij post 8471 (m.u.v. monitors werkend met kathodestraalbuis)',
    85285291: 'LCD-monitors, niet uitgerust met ontvangtoestel voor televisie, ontworpen voor gebruik met, maar niet hoofdzakelijk gebruikt, met  een automatisch gegevensverwerkend systeem als bedoeld bij post 8471',
    85285299: 'Monitors, niet uitgerust met ontvangtoestel voor televisie, ontworpen voor gebruik  met, maar niet hoofdzakelijk gebruikt met,  een automatisch gegevensverwerkend systeem als bedoeld bij post 8471 (m.u.v. monitors werkend met kathodestraalbuis, LCD-monitors)',
    85285900: 'Monitors, niet uitgerust met ontvangtoestel voor televisie, (m.u.v. monitors werkend met kathodestraalbuis en monitors van de soort die uitsluitend of hoofdzakelijk wordt gebruikt in een automatisch gegevensverwerkend systeem als bedoeld bij post 8471 )',
    85286200: 'Projectietoestellen, niet uitgerust met ontvangstoestel voor televisie,  van de soort die uitsluitend of hoofdzakelijk wordt gebruikt of direct verbinding kan maken met een automatisch gegevensverwerkend systeem als bedoeld bij post 8471',
    85286920: 'Projectietoestellen, voor monochrome weergave "zwart-wit of andere", niet uitgerust met ontvangstoestel voor televisie (m.u.v. die van de soort die uitsluitend of hoofdzakelijk wordt gebruikt in een automatisch gegevensverwerkend systeem als bedoeld bij post 8471)',
    85286980: 'Projectietoestellen, voor kleurenweergave, niet uitgerust met ontvangstoestel voor televisie (m.u.v. die van de soort die uitsluitend of hoofdzakelijk wordt gebruikt in een automatisch gegevensverwerkend systeem als bedoeld bij post 8471)',
    85287111: 'elektronische assemblages voor videotuners, voor inbouw in een automatische gegevensverwerkende machine',
    85287115: 'Videotuners gestuurd door een microprocessor, uitgerust met een ingebouwde modem voor toegang tot het internet, en met een functie voor interactieve informatie-uitwisseling en de mogelijkheid tot ontvangst van televisiesignalen (\'settopboxen met communicatiefunctie\', met inbegrip van toestellen met een opname- of afspeelfunctie, mits zij het essentiële karakter van een settopbox met communicatiefunctie behouden)',
    85287119: 'videotuners (m.u.v. elektronische assemblages voor inbouw in een automatische gegevensverwerkende machine en m.u.v. toestellen gestuurd door een microprocessor, uitgerust met een ingebouwde modem voor toegang tot het internet, een functie voor interactieve informatie-uitwisseling en de mogelijkheid tot ontvangst van televisiesignalen "settopboxen met communicatiefunctie")',
    85287191: 'Toestellen gestuurd door een microprocessor, uitgerust met een ingebouwde modem voor toegang tot het internet, een functie voor interactieve informatie uitwisseling en de mogelijkheid tot ontvangst van televisiesignalen (\'set-top-boxen met communicatiefunctie\' met inbegrip van toestellen met een opname- of afspeelfunctie, mits zij het essentiële karakter van een settopbox met communicatiefunctie behouden, m.u.v. videotuners)',
    85287199: 'Ontvangtoestellen voor televisie, ook indien met ingebouwd ontvangtoestel voor radio-omroep of toestel voor het opnemen of weergeven van geluid of van beelden, niet ontworpen om een beeldscherm of een videoscherm te bevatten (m.u.v. videotuners \'settop-boxen met communicatiefunctie\' met inbegrip van toestellen met een opname- of afspeelfunctie, mits zij het essentiële karakter van een settopbox met communicatiefunctie behouden)',
    85287210: 'televisieprojectietoestellen voor kleurenweergave, ontworpen om een beeldscherm of een videoscherm te bevatten',
    85287220: 'ontvangtoestellen voor televisie, voor kleurenweergave, met een ingebouwd video-opname- of videoweergavetoestel',
    85287230: 'Ontvangtoestellen voor televisie, voor kleurenweergave, met ingebouwde beeldbuis (m.u.v. die met een ingebouwd video-opname- of videoweergavetoestel en m.u.v. monitors)',
    85287240: 'Ontvangtoestellen voor televisie, voor kleurenweergave, met lcd-beeldscherm (m.u.v. die met een ingebouwd video-opname- of videoweergavetoestel en m.u.v. televisieprojectietoestellen)',
    85287260: 'Ontvangtoestellen voor televisie, voor kleurenweergave, met plasmabeeldscherm (m.u.v. die met een ingebouwd video-opname- of videoweergavetoestel, en monitors en m.u.v. televisieprojectietoestellen)',
    85287280: 'Ontvangtoestellen voor televisie, voor kleurenweergave (m.u.v. die met ingebouwde beeldbuis of een ingebouwd video-opname- of videoweergavetoestel , die met lcd- of plasmabeeldscherm en m.u.v. televisieprojectietoestellen)',
    85287300: 'ontvangtoestellen voor televisie, voor monochrome weergave "zwart-wit of ander", ook indien met ingebouwd ontvangtoestel voor radio-omroep of toestel voor het opnemen of het weergeven van geluid of van beelden, ontworpen om een beeldscherm of een videoscherm te bevatten',
    85291011: 'Telescoopantennes en staafantennes voor draagbare toestellen bedoeld bij de posten 8525 tot en met 8528, en voor motorvoertuigen',
    85291030: 'buitenantennes voor radio- en televisietoestellen',
    85291065: 'Kamerantennes voor radio- en televisietoestellen, incl. inbouwantennes (m.u.v. telescoopantennes en staafantennes voor draagbare toestellen en voor toestellen in motorvoertuigen)',
    85291069: 'Antennes (m.u.v. kamerantennes voor radio- en televisietoestellen, inbouwantennes daaronder begrepen en m.u.v. telescoopantennes en staafantennes voor draagbare toestellen en voor motorvoertuigen)',
    85291080: 'Antennefilters en toestelfilters',
    85291095: 'Antennereflectoren en andere delen waarvan kan worden onderkend dat zij bestemd zijn om samen met antennes en antennereflectoren te worden gebruikt, n.e.g. (m.u.v. antennefilters en toestelfilters)',
    85299015: 'Organische LED  "OLED" modules en OLED-panelen voor ontvangtoestellen voor televisie',
    85299020: 'Onderdelen en toebehoren waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zend- en ontvangtoestellen voor radio-omroep of televisie, voor digitale camera\'s, voor computermonitors en projectietoestellen n.e.g.',
    85299041: 'Meubelen en kasten, van hout, voor zend- en ontvangtoestellen voor radio-omroep of televisie, voor televisiecamera\'s, voor digitale camera\'s, voor videocamera-opnametoestellen, voor radartoestellen of voor toestellen voor radionavigatie of voor radioafstandsbediening, monitors en projectietoestellen n.e.g.,',
    85299049: 'Meubelen en kasten, voor zend- en ontvangtoestellen, voor radio-omroep of televisie, voor televisiecamera\'s, voor digitale camera\'s, voor videocamera-opnametoestellen, voor radartoestellen of voor toestellen voor radionavigatie of voor radioafstandsbediening, voor monitors en projectietoestellen n.e.g. (m.u.v. die van hout)',
    85299065: 'Elektronische assemblages, waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zend- en ontvangtoestellen voor radio-omroep of televisie, voor televisiecamera\'s, voor digitale camera\'s, voor videocamera-opnametoestellen, voor radartoestellen of voor toestellen voor radionavigatie of radioafstandsbediening, voor monitors en projectietoestellen n.e.g.',
    85299091: 'LED achtergrondverlichtingsmodules, voor schermen met vloeibare kristallen (LCD) van onderverdelingen 8525 t.e.m. 8528 (m.u.v. computermonitors)',
    85299092: 'delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor televisiecamera\'s, voor ontvangtoestellen voor radio-omroep of televisie, voor monitors en projectietoestellen n.e.g. (m.u.v. antennes, meubelen en kasten en elektronische assemblages en m.u.v. delen voor monitors en projectietoestellen die uitsluitend of hoofdzakelijk gebruikt worden in een automatische gegevensverwerkende machine)',
    85299097: 'Delen waarvan kan worden onderkend dat zij uitsluitend of hoofdzakelijk bestemd zijn voor zendtoestellen voor radio-omroep of televisie, voor videocamera-opnametoestellen, voor radartoestellen of voor toestellen voor radionavigatie of radioafstandsbediening, n.e.g.',
    85301000: 'elektrische signaaltoestellen (m.u.v. die voor de overbrenging van berichten), veiligheids-, controle- en bedieningstoestellen, voor spoor- en tramwegen, voor verkeers- en waterwegen (m.u.v. mechanische en elektro-mechanische toestellen bedoeld bij post 8608)',
    85308000: 'elektrische signaaltoestellen (m.u.v. die voor de overbrenging van berichten), veiligheids-, controle- en bedieningstoestellen (m.u.v. die voor spoor- en tramwegen en m.u.v. mechanische en elektro-mechanische toestellen bedoeld bij post 8608)',
    85309000: 'delen van elektrische signaaltoestellen (m.u.v. die voor de overbrenging van berichten), veiligheids-, controle- en bedieningstoestellen, n.e.g.',
    85311030: 'alarmtoestellen tegen diefstal, brandalarmtoestellen e.d. toestellen, van de soort gebruikt voor gebouwen',
    85311095: 'Alarmtoestellen tegen diefstal, brandalarmtoestellen e.d. toestellen (m.u.v. die van de soort gebruikt voor motorvoertuigen of voor gebouwen)',
    85312020: 'Signaalborden voorzien van luminescentiedioden (LED) (m.u.v. die van de soort gebruikt op rijwielen of op motorvoertuigen of voor verkeerswegen)',
    85312040: 'Signaalborden voorzien van actieve matrixelementen met vloeibare kristallen (LCD) (m.u.v. die van de soort gebruikt op rijwielen of op motorvoertuigen of voor verkeerswegen)',
    85312095: 'Signaalborden voorzien van elementen met vloeibare kristallen (LCD) (m.u.v. die met actieve matrixelementen met vloeibare kristallen en die van de soort gebruikt op rijwielen of op motorvoertuigen of voor verkeerswegen)',
    85318040: 'Elektrische bellen, zoemers, deurbellen en dergelijke (m.u.v. alarmtoestellen tegen inbraak, brandalarmtoestellen e.d. toestellen en toestellen van de soort gebruikt op rijwielen of op motorvoertuigen of voor verkeerswegen)',
    85318070: 'Elektrische toestellen voor hoorbare of voor zichtbare signalen (m.u.v. signaalborden voorzien van elementen met vloeibare kristallen (LCD) of van luminescentiedioden (LED), alarmtoestellen tegen inbraak of brandalarmtoestellen e.d. toestellen, toestellen van de soort gebruikt op rijwielen of op motorvoertuigen of voor verkeerswegen,  en elektrische bellen, zoemers, deurbellen en dergelijke )',
    85319000: 'Delen van elektrische toestellen voor hoorbare of voor zichtbare signalen, n.e.g.',
    85321000: 'vaste condensatoren, berekend op gebruik bij 50/60 Hz, met een blind vermogen van >= 0,5 kvar "vermogenscondensatoren"',
    85322100: 'tantaalcondensatoren (m.u.v. vermogenscondensatoren)',
    85322200: 'elektrolytische aluminiumcondensatoren "vermogenscondensatoren"',
    85322300: 'vaste elektrische condensatoren met keramisch diëlektricum, enkellagig (m.u.v. vermogenscondensatoren)',
    85322400: 'vaste elektrische condensatoren met keramisch diëlektricum, meerlagig (m.u.v. vermogenscondensatoren)',
    85322500: 'vaste elektrische condensatoren met keramisch diëlektricum, van papier of van kunststof (m.u.v. vermogenscondensatoren)',
    85322900: 'vaste elektrische condensatoren (m.u.v. die met keramisch diëlektricum of met diëlektricum van papier of van kunststof en m.u.v. vermogenscondensatoren, tantaalcondensatoren en elektrolytische aluminiumcondensatoren)',
    85323000: 'regelbare en instelbare elektrische condensatoren',
    85329000: 'delen van vaste, regelbare en instelbare elektrische condensatoren, n.e.g.',
    85331000: 'vaste geagglomereerde koolweerstanden en vaste koolfilmweerstanden (m.u.v. verwarmingsweerstanden)',
    85332100: 'vaste elektrische weerstanden voor een vermogen van <= 20 W (m.u.v. verwarmingsweerstanden)',
    85332900: 'vaste elektrische weerstanden voor een vermogen van > 20 W (m.u.v. verwarmingsweerstanden)',
    85333100: 'regelbare en instelbare elektrische weerstanden, incl. reostaten en potentiometers, draadgewonden, voor een vermogen van <= 20 W (m.u.v. verwarmingsweerstanden)',
    85333900: 'regelbare en instelbare elektrische weerstanden, incl. reostaten en potentiometers, draadgewonden, voor een vermogen van > 20 W (m.u.v. verwarmingsweerstanden)',
    85334010: 'regelbare en instelbare weerstanden, incl. reostaten en potentiometers, voor een vermogen van <= 20 W (m.u.v. draadgewonden weerstanden en verwarmingsweerstanden)',
    85334090: 'regelbare en instelbare weerstanden, incl. reostaten en potentiometers, voor een vermogen van > 20 W (m.u.v. draadgewonden weerstanden en verwarmingsweerstanden)',
    85339000: 'delen van elektrische weerstanden, incl. reostaten en potentiometers, n.e.g.',
    85340011: 'meerlagige gedrukte schakelingen, bevattende enkel stroomvoerende elementen en contacten',
    85340019: 'enkellagige gedrukte schakelingen, bevattende enkel stroomvoerende elementen en contacten',
    85340090: 'gedrukte schakelingen, bevattende stroomvoerende elementen, contacten en andere passieve elementen (m.u.v. die met passieve en actieve elementen)',
    85351000: 'smeltveiligheden voor een spanning van > 1.000 V',
    85352100: 'vermogensschakelaars voor een spanning van > 1.000 V, doch < 72,5 kV',
    85352900: 'vermogensschakelaars voor een spanning van > 72,5 kV',
    85353010: 'scheidingsschakelaars en lastschakelaars, voor een spanning van > 1.000 V, doch < 72,5 kV',
    85353090: 'scheidingsschakelaars en lastschakelaars, voor een spanning van > 72,5 kV',
    85354000: 'bliksemafleiders, overspanningsveiligheden en golfafvlakkers, voor een spanning van > 1.000 V',
    85359000: 'toestellen voor het inschakelen, uitschakelen, omschakelen, aansluiten of verdelen van of voor het beveiligen tegen elektrische stroom, voor een spanning van > 1.000 V (m.u.v. smeltveiligheden, vermogensschakelaars, scheidingsschakelaars, lastschakelaars, bliksemafleiders, overspanningsveiligheden, golfafvlakkers en m.u.v. schakelkasten, schakelpanelen, besturingsinrichtingen, enz. bedoeld bij post 8537)',
    85361010: 'smeltveiligheden voor een spanning van <= 1.000 V en een stroomsterkte van <= 10 A',
    85361050: 'smeltveiligheden voor een spanning van <= 1.000 V en een stroomsterkte van > 10, doch <= 63 A',
    85361090: 'smeltveiligheden voor een spanning van <= 1.000 V en een stroomsterkte van > 63 A',
    85362010: 'vermogensschakelaars voor een spanning van <= 1.000 V en een stroomsterkte van <= 63 A',
    85362090: 'vermogensschakelaars voor een spanning van <= 1.000 V en een stroomsterkte van > 63 A',
    85363010: 'toestellen voor het beveiligen tegen elektrische stroom, voor een spanning van <= 1.000 V en een stroomsterkte van <= 63 A (m.u.v. smeltveiligheden en vermogensschakelaars)',
    85363030: 'toestellen voor het beveiligen tegen elektrische stroom, voor een spanning van <= 1.000 V en een stroomsterkte van > 16, doch <= 125 A (m.u.v. smeltveiligheden en vermogensschakelaars)',
    85363090: 'toestellen voor het beveiligen tegen elektrische stroom, voor een spanning van <= 1.000 V en een stroomsterkte van > 125 A (m.u.v. smeltveiligheden en vermogensschakelaars)',
    85364110: 'relais voor een spanning van <= 60 V en een stroomsterkte van <= 2 A',
    85364190: 'relais voor een spanning van <= 60 V en een stroomsterkte van > 2 A',
    85364900: 'relais voor een spanning van > 60, doch <= 1.000 V',
    85365003: 'elektronische wisselstroomschakelaars, bestaande uit optisch gekoppelde in- en uitgangsschakelingen "geïsoleerde thyristors" (m.u.v. relais en vermogensschakelaars)',
    85365005: 'elektronische schakelaars, thermisch beveiligde elektronische schakelaars daaronder begrepen, bestaande uit een transistor en een logische schakeling "zgn. [chip-on-chip]-techniek" (m.u.v. relais en vermogensschakelaars)',
    85365007: 'elektromechanische schakelaars van het type snap-action, voor een stroomsterkte van <= 11 A (m.u.v. relais en vermogensschakelaars)',
    85365011: 'drukknopschakelaars voor een spanning van <= 60 V',
    85365015: 'draaischakelaars voor een spanning van <= 60 V',
    85365019: 'schakelaars voor een spanning van <= 60 V (m.u.v. relais en drukknop- en draaischakelaars)',
    85365080: 'schakelaars voor een spanning van > 60 doch <= 1.000 V (m.u.v. relais, vermogensschakelaars, elektronische wisselstroomschakelaars, bestaande uit optisch gekoppelde in- en uitgangsschakelingen "geïsoleerde thyristors", elektronische schakelaars, thermisch beveiligde elektronische schakelaars daaronder begrepen, bestaande uit een transistor en een logische schakeling "zgn. [chip-on-chip]-techniek" en elektromechanische schakelaars van het type snap-action, voor een stroomsterkte van <= 11 A)',
    85366110: 'lamp- en buishouders, met schroefdraad, voor een spanning van <= 1.000 V',
    85366190: 'lamp- en buishouders, voor een spanning van <= 1.000 V (m.u.v. die met schroefdraad)',
    85366910: 'contactdozen en contactstoppen "stekkers" voor een spanning van <= 1.000 V, voor coaxiale kabels',
    85366930: 'contactdozen en contactstoppen "stekkers" voor een spanning van <= 1.000 V, voor gedrukte schakelingen',
    85366990: 'contactdozen en contactstoppen "stekkers" voor een spanning van <= 1.000 V (m.u.v. die voor coaxiale kabels of voor gedrukte schakelingen)',
    85367000: 'Verbindingsstukken voor optische vezels, optischevezelbundels of optischevezelkabels',
    85369001: 'geprefabriceerde elementen voor elektrische stroomleidingen, voor een spanning van <= 1.000 V',
    85369010: 'aansluittoestellen en contactverbindingen voor elektrische stroomleidingen, voor een spanning van <= 1.000 V (m.u.v. contactdozen en contactstoppen en geprefabriceerde elementen)',
    85369040: 'Accuklemmen voor motorvoertuigen van posten 8702, 8703, 8704 of 8711',
    85369095: 'Elektrische toestellen voor het in-, uit- of omschakelen, aansluiten of verdelen van elektrische stroom, voor een spanning van <= 1000 V (m.u.v. toestellen voor het beveiligen tegen elektrische stroom, relais en andere schakelaars, lamp- en buishouders, contactdozen en contactstoppen, geprefabriceerde elementen voor elektrische stroomleidingen, aansluittoestellen en contactverbindingen voor draad en kabels en accuklemmen voor motorvoertuigen)',
    85371010: 'numerieke besturingskasten met ingebouwde automatische gegevens verwerkende machine',
    85371091: 'besturingstoestellen met programmeerbaar geheugen (m.u.v. numerieke besturingskasten met ingebouwde automatische gegevens verwerkende machine)',
    85371095: 'Aanraakgevoelige apparaten voor gegevensinvoer, zgn "aanraakschermen", zonder weergavecapaciteit, bestemd om te worden ingebouwd in apparaten met een scherm',
    85371098: 'Schakelborden, schakelpanelen e.d. combinaties van toestellen, voor elektrische bediening of voor het verdelen van elektrische stroom, voor een spanning van <= 1.000 V (m.u.v. schakeltoestellen voor lijntelefonie of voor lijntelegrafie, numerieke besturingskasten met ingebouwde automatische gegevens verwerkende machine, besturingstoestellen met programmeerbaar geheugen en aanraakschermen)',
    85372091: 'schakelborden, schakelpanelen e.d. combinaties van toestellen, voor elektrische bediening of voor het verdelen van elektrische stroom, voor een spanning van > 1.000 V, doch <= 72,5 kV (m.u.v. schakeltoestellen voor lijntelefonie of voor lijntelegrafie)',
    85372099: 'schakelborden, schakelpanelen e.d. combinaties van toestellen, voor elektrische bediening of voor het verdelen van elektrische stroom, voor een spanning van > 72,5 kV (m.u.v. schakeltoestellen voor lijntelefonie of voor lijntelegrafie)',
    85381000: 'borden, panelen, kasten e.d. artikelen bedoeld bij post 8537, doch zonder hun toestellen',
    85389011: 'elektronische assemblages voor aansluittoestellen voor het meten of het verifiëren van elektrische grootheden in schijven "wafers" van halfgeleidermateriaal "waferprober" bedoeld bij onderverdeling 8536.90.20',
    85389019: 'delen van aansluittoestellen voor het meten of het verifiëren van elektrische grootheden in schijven "wafers" van halfgeleidermateriaal "waferprober" bedoeld bij onderverdeling 8536.90.20, n.e.g. (m.u.v. elektronische assemblages)',
    85389091: 'elektronische assemblages voor toestellen voor het inschakelen, uitschakelen, omschakelen, aansluiten of verdelen van of voor het beveiligen tegen elektrische stroom, bedoeld bij de posten 8535 en 8536, of voor schakelborden of -kasten e.d. samenstellingen van apparaten en toestellen bedoeld bij post 8537 (m.u.v. "waferprober" bedoeld bij onderverdeling 8536.90.20)',
    85389099: 'delen waarvan kan worden onderkend, dat zij uitsluitend of hoofdzakelijk bestemd zijn voor de toestellen bedoeld bij de posten 8535, 8536 en 8537, n.e.g. (m.u.v. die van aansluittoestellen voor het meten of het verifiëren van elektrische grootheden in schijven "wafers" van halfgeleidermateriaal "waferprober" bedoeld bij onderverdeling 8536.90.20 en m.u.v. elektronische assemblages,  borden, panelen, kasten e.d. bedoeld bij post 8537, niet voorzien van hun toestellen)',
    85391000: 'sealed beam-lampen',
    85392130: 'halogeenlampen met gloeidraad van wolfraam van de soort gebruikt voor motorrijwielen en voor andere motorvoertuigen (m.u.v. "sealed beam"-lampen)',
    85392192: 'halogeenlampen met gloeidraad van wolfraam voor een spanning van > 100 V',
    85392198: 'halogeenlampen met gloeidraad van wolfraam voor een spanning van <= 100 V (m.u.v. die van de soort gebruikt voor motorrijwielen en voor andere motorvoertuigen)',
    85392210: 'reflectorgloeilampen met een vermogen van <= 200 W en voor een spanning van > 100 V (m.u.v. halogeenlampen met gloeidraad van wolfraam)',
    85392290: 'gloeilampen en -buizen, met een vermogen van <= 200 W en voor een spanning van > 100 V (m.u.v. halogeenlampen met gloeidraad van wolfraam, reflectorlampen en lampen en buizen voor ultraviolette of voor infrarode stralen)',
    85392930: 'gloeilampen van de soort gebruikt voor motorrijwielen en voor andere motorvoertuigen (m.u.v. halogeenlampen met gloeidraad van wolfraam)',
    85392992: 'gloeilampen voor een spanning van > 100 V (m.u.v. halogeenlampen met gloeidraad van wolfraam, lampen met een vermogen van <= 200 W en lampen en buizen voor ultraviolette of voor infrarode stralen)',
    85392998: 'gloeilampen voor een spanning van <= 100 V (m.u.v. halogeenlampen met gloeidraad van wolfraam en lampen en buizen van de soort gebruikt voor motorrijwielen en voor andere motorvoertuigen)',
    85393110: 'fluorescentielampen met verhitte kathode, met twee lampvoeten',
    85393190: 'fluorescentielampen met verhitte kathode, met een of meer dan twee lampvoeten',
    85393220: 'Kwik- of natriumdamplampen',
    85393290: 'metaalhalogeenlampen',
    85393920: 'Koude-kathode fluorescentielampen "CCFL\'s" voor de achtergrondverlichting van platte beeldschermen',
    85393980: 'Gasontladingslampen en -buizen (m.u.v. fluorescentielampen met verhitte kathode, kwik- of natriumdamplampen, metaalhalogeenlampen en lampen en buizen voor ultraviolette stralen en koude-kathode fluorescentielampen "CCFL\'s" voor de achtergrondverlichting van platte beeldschermen)',
    85394100: 'booglampen',
    85394900: 'Lampen en buizen voor ultraviolette of infrarode stralen',
    85395000: 'Luminescentiedioden "LED"- lampen en -buizen',
    85399010: 'lampvoeten of -sokkels voor gloeilampen en -buizen, gasontladingslampen en -buizen, en andere lampen en buizen bedoeld bij post 8539',
    85399090: 'Delen van elektrische gloeilampen en -buizen, elektrische gasontladingslampen en -buizen, "sealed beam"-lampen, lampen en buizen voor ultraviolette of voor infrarode stralen, booglampen en LED-lampen en -buizen, n.e.g.',
    85401100: 'Kathodestraalbuizen voor ontvangtoestellen voor televisie of voor videomonitors, voor kleurenweergave',
    85401200: 'kathodestraalbuizen voor televisie of voor videomonitors, voor monochrome weergave "zwart-wit of ander"',
    85402010: 'buizen voor televisiecamera\'s',
    85402080: 'beeldomzetters en beeldversterkers en andere buizen met fotokathode (m.u.v. buizen voor televisiecamera\'s en kathodestraalbuizen voor ontvangtoestellen voor televisie of voor videomonitors)',
    85404000: 'buizen voor de monochrome weergave van grafische gegevens, buizen voor de kleurenweergave van grafische gegevens, met een fosforscherm met een afstand tussen de beeldpunten van < 0,4 mm (m.u.v. elektronenbuizen met fotokathode en kathodestraalbuizen)',
    85406000: 'kathodestraalbuizen (m.u.v. die voor ontvangtoestellen voor televisie of voor videomonitors en m.u.v. buizen voor televisiecamera\'s, beeldomzetters en beeldversterkers, andere buizen met fotokathode, en buizen voor de weergave van grafische gegevens, voor monochrome "zwart-wit of ander" weergave of voor kleurenweergave met een fosforscherm met een puntafstand van < 0,4 mm)',
    85407100: 'magnetrons',
    85407900: 'microgolfbuizen "b.v. klystrons, lopende-golfbuizen en carcinotrons" (m.u.v. magnetrons en buizen met roostersturing)',
    85408100: 'ontvang- en versterkerbuizen (m.u.v. microgolfbuizen, buizen met fotokathode en kathodestraalbuizen)',
    85408900: 'elektronenbuizen (m.u.v. ontvang- en versterkerbuizen, microgolfbuizen, buizen met fotokathode, kathodestraalbuizen en buizen voor het zichtbaar maken van grafische gegevens, voor monochrome weergave "zwart-wit of ander" of voor kleurenweergave, met een fosforscherm met een puntafstand van < 0,4 mm)',
    85409100: 'delen van kathodestraalbuizen, n.e.g.',
    85409900: 'delen van elektronenbuizen met verhitte kathode, met koude kathode of met fotokathode, n.e.g. (m.u.v. die van kathodestraalbuizen)',
    85411000: 'dioden (m.u.v. fotodioden en luminescentiedioden)',
    85412100: 'transistors met een dissipatievermogen van < 1 W (m.u.v. fototransistors)',
    85412900: 'transistors met een dissipatievermogen van >= 1 W (m.u.v. fototransistors)',
    85413000: 'thyristors, diacs en triacs (m.u.v. lichtgevoelige halfgeleiderelementen)',
    85414010: 'luminescentiedioden, incl. laser dioden',
    85414090: 'lichtgevoelige halfgeleiderelementen,  incl. fotovoltaïsche cellen',
    85415000: 'halfgeleiderelementen, n.e.g.',
    85416000: 'gemonteerde piëzo-elektrische kristallen',
    85419000: 'delen van dioden, transistors e.d. halfgeleiderelementen, lichtgevoelige halfgeleiderelementen, luminescentiedioden en gemonteerde piëzo-elektrische kristallen, n.e.g.',
    85423111: 'Elektronische geïntegreerde multi-component-schakelingen "MCO\'s", zoals processors en controllers, zoals genoemd in aantekening 9 (b) (4) op hoofdstuk 85, ook indien gecombineerd met geheugens, omzetters, logische schakelingen, versterkers, klokschakelingen, synchronisatieschakelingen of andere schakelingen',
    85423119: 'Elektronisch geïntegreerde schakelingen, zoals processors en controllers, ook indien gecombineerd met geheugens, omzetters, logische schakelingen, versterkers, klokschakelingen, synchronisatieschakelingen of andere schakelingen in de vorm van geïntegreerde multichipschakelingen bestaande uit twee of meer onderling verbonden monlitihische geïntegreerde schakelingen zoals genoemd in aantekening 9 (b) (3) op hoofdstuk 85',
    85423190: 'Elektonisch geïntegreerde schakelingen zoals processors en controllers, ook indien gecombineerd met geheugens, omzetters, logische schakelingen, versterkers, klokschakelingen, synchronisatieschakelingen of andere schakelingen (m.u. v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423211: 'Elektronische geïntegreerde multi-component-schakelingen "MCO\'s" als geheugens zoals genoemd in aantekening 9 (b) (4) op hoofdstuk 85',
    85423219: 'Elektronisch geïntegreerde schakelingen, als geheugens, in de vorm van geïntegreerde multichipschakelingen bestaande uit twee of meer onderling verbonden monlitihische geïntegreerde schakelingen zoals genoemd in aantekening 9 (b) (3) op hoofdstuk 85',
    85423231: 'Elektronisch geïntegreerde schakelingen, als dynamisch willekeurig toegankelijke geheugens,zgn. "D-RAM\'s", met een capaciteit van <= 512 Mbit (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423239: 'Elektronisch geïntegreerde schakelingen, als dynamisch willekeurig toegankelijke geheugens,zgn. "D-RAM\'s", met een capaciteit van > 512 Mbit (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423245: 'Elektronisch geïntegreerde schakelingen, als statisch willekeurig toegankelijke geheugens "zgn. S-RAM\'s", willekeurig toegankelijke voorgeheugens "zgn. cache-RAM\'s" daaronder begrepen (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423255: 'Elektronisch geïntegreerde schakelingen, als programmeerbare geheurgens, enkel voor het uitlezen van de daarin opgeslagen gegevens, die met ultraviolette stralen kunnen worden uitgewist "zgn. EPROM\'s" (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423261: 'Elektronische geïntegreerde schakelingen, als programmeerbare geheugens, enkel voor het uitlezen van de daarin opgeslagen gegevens, die elektrisch kunnen worden uitgewist "zgn. flash E²PROM\'s", met een capaciteit van <= 512 Mbit (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423269: 'Elektronische geïntegreerde schakelingen, als programmeerbare geheugens, enkel voor het uitlezen van de daarin opgeslagen gegevens, die elektrisch kunnen worden uitgewist "zgn. flash E²PROM\'s", met een capaciteit van > 512 Mbit (m.u.v. die in de vorm van geïntegreerde multichipschakelingen)',
    85423275: 'Elektronische geïntegreerde schakelingen, als programmeerbare geheugens, enkel voor het uitlezen van de daarin opgeslagen gegevens, die elektrisch kunnen worden uitgewist "zgn. E²PROM\'s (m.u.v. flas E²PROM\'s en die in de vorm van geïntegreerde multichipschakelingen)',
    85423290: 'Geheugens in meervoudige combinatievormen zoals stapel D-RAMs en modules (m.u.v. die in de vorm van geïntegreerde multichipschakelingen en m.u.v. D-RAMs, S-RAMs, cache-RAMs, EPROMs en flashE²PROMs)',
    85423310: 'Elektronische geïntegreerde multi-component-schakelingen "MCO\'s" als versterkers, zoals genoemd in aantekening 9 (b) (4) bij hoofdstuk 85',
    85423390: 'Elektronische geïntegreerde schakelingen, als versterkers (m.u.v. geïntegreerde multi-component schakelingen)',
    85423911: 'Elektronische geïntegreerde multi-component-schakelingen "MCO\'s", zoals genoemd in aantekening 9 (b) (4) bij hoofdstuk 85  (m.u.v. processors, controllers, geheugens en versterkers)',
    85423919: 'Elektronisch geïntegreerde schakelingen in de vorm van geïntegreerde multichipschakelingen bestaande uit twee of meer onderling verbonden monolitische geïntegreerde schakelingen zoals genoemd in aantekening 9 (b) (3) bij hoofdstuk 85 (m.u.v. processors, controllers, geheugens en versterkers)',
    85423990: 'Elektronische geïntegreerde schakelingen (m.u.v. die in de vorm van geïntegreerde multichipschakelingen en processors, controllers, geheugens en versterkers)',
    85429000: 'delen van elektronische geïntegreerde schakelingen en microassemblages, n.e.g.',
    85431000: 'elektrische deeltjesversnellers voor elektronen, protonen enz. (m.u.v. ionenimplanters voor het dopen van halfgeleidermateriaal)',
    85432000: 'elektrische signaalgeneratoren',
    85433040: 'Galvaniseer- en elektrolyse-machines van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen',
    85433070: 'Machines en toestellen voor de galvanotechniek, voor elektrolyse of elektroforese (m.u.v. galvaniseer- en elektrolyse-machines van de soort die uitsluitend of hoofdzakelijk wordt gebruikt voor de vervaardiging van gedrukte schakelingen)',
    85437001: 'Werken speciaal vervaardigd voor aansluiting op toestellen voor telegrafie of telefonie of netwerken voor telegrafie of telefonie',
    85437002: 'Microgolfversterkers',
    85437003: 'Draadloze infraroodtoestellen voor de bediening op afstand van consoles voor videospellen',
    85437004: 'Digitale vluchtgegevensrecorders',
    85437005: 'Draagbare op batterijen werkende elektronische lezer voor het opnemen en weergeven van tekst, stilstaand beeld of geluidsbestand',
    85437006: 'Toestel voor de verwerking van digitale signalen geschikt voor aansluiting op een kabelnetwerk of een draadloos netwerk voor het mengen van geluid',
    85437007: 'Draagbare interactieve elektronische onderwijshulpmiddelen, hoofdzakelijk bestemd voor kinderen',
    85437008: 'Plasmareinigingsmachines voor het verwijderen van organische verontreiniging van elektronenmicroscopiepreparaten en preparaatsluizen',
    85437009: 'Aanraakgevoelige apparaten voor gegevensinvoer (zogenaamde aanraakschermen of  "touch screens") zonder weergavecapaciteit, bestemd om te worden ingebouwd in apparaten met een scherm (m.u.v. die voor elektrische bediening van onderverdeling 8537 10 95)',
    85437010: 'elektrische toestellen met vertaal- of woordenboekfuncties',
    85437030: 'antenneversterkers',
    85437050: 'Zonnebanken, zonnehemels e.d. bruiningsapparatuur',
    85437060: 'elektrische weideafrasteringstoestellen',
    85437070: 'Elektronische sigaretten',
    85437090: 'Elektrische machines, apparaten en toestellen, met een eigen functie, genoemd noch begrepen onder andere posten van hoofdstuk 85',
    85439000: 'Delen van elektrische machines, apparaten en toestellen met een eigen functie, n.e.g in  hoofdstuk 85',
    85441110: 'wikkeldraad voor elektrotechnische doeleinden, van koper, gevernist of gelakt, zgn. emaildraad',
    85441190: 'geïsoleerd wikkeldraad voor elektrotechnische doeleinden, van koper (m.u.v. gevernist of gelakt wikkeldraad)',
    85441900: 'Geïsoleerd wikkeldraad voor elektrotechnische doeleinden (m.u.v. dat van koper)',
    85442000: 'coaxiaalkabel en andere coaxiale geleiders van elektriciteit, geïsoleerd',
    85443000: 'Bougiekabelsets en andere kabelbundels (kabelbomen) van de soort gebruikt in vervoermiddelen',
    85444210: 'Geleiders van elektriciteit, van de soort gebruikt voor telecommunicatie, voor spanningen van  <= 1.000 V, geïsoleerd, voorzien van verbindingsstukken, n.e.g.',
    85444290: 'Geleiders van elektriciteit, voor spanningen van <= 1.000 V, geïsoleerd, voorzien van verbindingsstukken, n.e.g. (m.u.v. die van de soort gebruikt voor telecommunicatie)',
    85444920: 'geleiders van elektriciteit, voor spanningen van <= 80 V, geïsoleerd, zonder verbindingsstukken, van de soort gebruikt voor telecommunicatie, n.e.g.',
    85444991: 'draad en kabels voor elektrotechnische doeleinden, voor spanningen van <= 1.000 V, geïsoleerd,  zonder verbindingsstukken, waarvan de diameter van de enkelvoudige geleiderdraad > 0,51 mm bedraagt, n.e.g.',
    85444993: 'geleiders van elektriciteit, voor spanningen van <= 80 V, geïsoleerd, zonder verbindingsstukken, n.e.g. (m.u.v. wikkeldraad, coaxiale geleiders, kabelbundels van de soort gebruikt in vervoermiddelen, en draad en kabel waarvan de diameter van de enkelvoudige geleiderdraad > 0,51 mm bedraagt)',
    85444995: 'Geleiders van elektriciteit voor spanningen van > 80 doch < 1.000 V, geïsoleerd, zonder verbindingsstukken, n.e.g. (m.u.v.wikkeldraad, coaxiale geleiders, kabelbundels van de soort gebruikt in vervoermiddelen, en draad en kabel waarvan de diameter van de enkelvoudige geleiderdraad > 0,51 mm bedraagt)',
    85444999: 'Geleiders van elektriciteit voor spanningen van 1.000 V, geïsoleerd, zonder verbindingsstukken, n.e.g. (m.u.v.wikkeldraad, coaxiale geleiders, kabelbundels van de soort gebruikt in vervoermiddelen, en draad en kabel waarvan de diameter van de enkelvoudige geleiderdraad > 0,51 mm bedraagt)',
    85446010: 'geleiders van elektriciteit, voor spanningen van > 1.000 V, met een kern van koper, geïsoleerd, n.e.g.',
    85446090: 'geleiders van elektriciteit, voor spanningen van > 1.000 V, zonder kern van koper, geïsoleerd, n.e.g.',
    85447000: 'optischevezelkabels bestaande uit individueel omhulde vezels, ook indien elektrische geleiders bevattend of voorzien van verbindingsstukken',
    85451100: 'elektroden van grafiet of andere koolstof, van de soort gebruikt in elektrische ovens',
    85451900: 'Elektroden van grafiet of andere koolstof, voor elektrisch gebruik (m.u.v. die van de soort gebruikt in ovens)',
    85452000: 'koolborstels, voor elektrisch gebruik',
    85459010: 'elektrische verwarmingsweerstanden, van grafiet of andere koolstof',
    85459090: 'artikelen van grafiet of andere koolstof, voor elektrisch gebruik (m.u.v. elektroden, koolborstels en verwarmingsweerstanden)',
    85461000: 'isolatoren voor elektriciteit, van glas (m.u.v. isolerende werkstukken)',
    85462000: 'Isolatoren voor elektriciteit, van keramische stoffen (m.u.v. isolerende werkstukken)',
    85469010: 'isolatoren voor elektriciteit, van kunststof (m.u.v. isolerende werkstukken)',
    85469090: 'isolatoren voor elektriciteit (m.u.v. die van glas, keramische stoffen of kunststof en m.u.v. isolerende werkstukken)',
    85471000: 'Isolerende werkstukken van keramische stoffen',
    85472000: 'isolerende werkstukken, geheel van kunststof, dan wel voorzien van daarin bij het gieten, persen, enz. aangebrachte eenvoudige metalen verbindingsstukken "b.v. nippels met schroefdraad", voor elektrische machines, toestellen of installaties (m.u.v. isolatoren bedoeld bij post 8546)',
    85479000: 'isolerende werkstukken, geheel van isolerend materiaal, dan wel voorzien van daarin bij het gieten, persen, enz. aangebrachte eenvoudige metalen verbindingsstukken "b.v. nippels met schroefdraad", voor elektrische machines, toestellen of installaties (m.u.v. die van keramische stoffen of van kunststof en m.u.v. isolatoren bedoeld bij post 8546); isolatiebuizen en verbindingsstukken daarvoor, van onedel metaal, inwendig geïsoleerd',
    85481010: 'gebruikte elektrische elementen en gebruikte elektrische batterijen',
    85481021: 'elektrische loodaccumulatoren, gebruikt',
    85481029: 'elektrische accumulatoren, gebruikt (m.u.v. loodaccumulatoren)',
    85481091: 'resten en afval, van elektrische elementen, van elektrische batterijen en van elektrische accumulatoren, lood bevattend',
    85481099: 'resten en afval, van elektrische elementen, van elektrische batterijen en van elektrische accumulatoren (m.u.v. lood bevattende resten en afval)',
    85489020: 'geheugens in meervoudige combinatievormen zoals stapel D-RAMs en modules',
    85489030: 'Luminescentiedioden (led) backlightmodules zijnde verlichtingsbronnen die bestaan uit één of meer leds en één of meer verbindingsstukken, die zijn aangebracht op een gedrukte schakeling of ander soortgelijk substraat, en andere passieve elementen, al dan niet in combinatie met optische onderdelen of beschermende dioden, en gebruikt als backlight van Liquid Crystal Displays (LCD\'s), n.e.g.',
    85489090: 'elektrische delen van machines, van apparaten of van toestellen, genoemd noch begrepen onder andere posten van hoofdstuk 85',
    86011000: 'locomotieven en railtractors, elektrisch, zonder eigen energiebron',
    86012000: 'locomotieven en railtractors, elektrisch, met elektrische accumulatoren',
    86021000: 'locomotieven, dieselelektrisch',
    86029000: 'locomotieven en railtractors (m.u.v. die zonder eigen energiebron of met elektrische accumulatoren en dieselelektrische locomotieven)',
    86031000: 'motorwagens voor spoor- en tramwegen en railauto\'s, elektrisch aangedreven, zonder eigen energiebron (m.u.v. wagens voor het onderhoud van spoor- en tramwegen, bedoeld bij post 8604)',
    86039000: 'motorwagens voor spoor- en tramwegen en railauto\'s (m.u.v. die elektrisch aangedreven, zonder eigen energiebron; wagens voor het onderhoud van spoor- en tramwegen, bedoeld bij post 8604)',
    86040000: 'wagens voor het onderhoud van spoor- en tramwegen, ook indien met eigen beweegkracht, b.v. rijdende werkplaatsen, kraanwagens, wagens met inrichting voor het aanstampen van ballast, wagens voor het leggen van rails, testwagens, railauto\'s',
    86050000: 'personenrijtuigen, bagagewagens, postwagens en andere speciale wagens, zonder eigen beweegkracht, voor spoor- en tramwegen (m.u.v. motorwagens voor spoor- en tramwegen, railauto\'s, wagens voor het onderhoud van spoor- en tramwegen; goederenwagens)',
    86061000: 'tankwagens, e.d., zonder eigen beweegkracht, voor spoor- en tramwegen',
    86063000: 'zelflossende wagens, zonder eigen beweegkracht, voor spoor- en tramwegen (m.u.v. ketelwagens e.d., thermisch geïsoleerde wagens en koelwagens)',
    86069110: 'goederenwagens, zonder eigen beweegkracht, voor spoor- en tramwegen, gesloten "met wanden en dak", speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom" (m.u.v. ketelwagens e.d.; thermisch geïsoleerde wagens en koelwagens; zelflossende wagens)',
    86069180: 'goederenwagens, zonder eigen beweegkracht, voor spoor- en tramwegen, gesloten "met wanden en dak" (m.u.v. die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; tankwagens e.d.; zelflossende wagens)',
    86069200: 'goederenwagens, zonder eigen beweegkracht, voor spoor- en tramwegen, open, met niet-afneembare zijkanten, met een hoogte van > 60 cm (m.u.v. zelflossende wagens)',
    86069900: 'goederenwagens, zonder eigen beweegkracht, voor spoor- en tramwegen (m.u.v. die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; ketelwagens e.d.; thermisch geïsoleerde wagens en koelwagens; zelflossende wagens; open wagens, met niet-afneembare zijkanten, met een hoogte van > 60 cm)',
    86071100: 'tractiedraaistellen en tractiebisseldraaistellen, van rollend spoor- en tramwegmaterieel',
    86071200: 'draaistellen "bogies" en bisseldraaistellen, van rollend spoor- en tramwegmaterieel (m.u.v. tractiedraaistellen en tractiebisseldraaistellen)',
    86071910: 'Assen, ook indien gemonteerd; wielen en delen daarvan, voor rollend spoor- en tramwegmaterieel, n.e.g.',
    86071990: 'Delen van draaistellen "bogies" en van bisseldraaistellen, e.d., van rollend spoor- en tramwegmaterieel, n.e.g.',
    86072110: 'luchtdrukremmen en delen daarvan, voor rollend spoor- en tramwegmaterieel, van gietijzer of van gegoten staal, n.e.g.',
    86072190: 'luchtdrukremmen en delen daarvan, voor rollend spoor- en tramwegmaterieel, n.e.g.',
    86072900: 'Remmen en delen daarvan, voor rollend spoor- en tramwegmaterieel, n.e.g. (m.u.v. luchtdrukremmen)',
    86073000: 'Koppelhaken en andere koppelingen, buffers, alsmede delen daarvan, voor rollend spoor- en tramwegmaterieel, n.e.g.',
    86079110: 'Draagpotten "vetpotten en oliepotten" en delen daarvan, voor locomotieven of voor railtractors, n.e.g.',
    86079190: 'delen van locomotieven of van railtractors, n.e.g.',
    86079910: 'Draagpotten "vetpotten en oliepotten" en delen daarvan, voor rollend spoor- en tramwegmaterieel bedoeld bij de posten 8603, 8604, 8605 en 8606, n.e.g.',
    86079980: 'delen van rollend spoor- en tramwegmaterieel, bedoeld bij de posten 8603, 8604, 8605 en 8606, n.e.g.',
    86080000: 'Vast materieel voor spoor- en tramwegen (m.u.v.  dwarsliggers van hout, van beton, van staal; spoorstaven en andere spoorbaanbestanddelen); signaal-, veiligheids-, controle- en bedieningstoestellen, voor verkeers- en waterwegen, voor parkeerterreinen, voor havens en voor vliegvelden, mechanisch, ook indien elektromechanisch; delen daarvan',
    86090010: 'containers e.d. laadkisten, met loden bekleding ter afscherming van radioactieve straling, voor het vervoer van radioactieve stoffen "Euratom"',
    86090090: 'containers e.d. laadkisten, incl. tankcontainers, ingericht en uitgerust voor het vervoer met ongeacht welk vervoermiddel (m.u.v. die met met loden bekleding ter afscherming van radioactieve straling, voor het vervoer van radioactieve stoffen "Euratom")',
    87011000: 'motoculteurs e.d. trekkers voor de industrie (m.u.v. eenassige trekkers voor gelede voertuigen)',
    87012010: 'trekkers "wegtractors" voor opleggers, nieuw',
    87012090: 'trekkers "wegtractors" voor opleggers, gebruikt',
    87013000: 'Tractors met rupsbanden (m.u.v. motoculteurs met rupsbanden)',
    87019110: 'Landbouwtractors en tractors voor de bosbouw, op wielen, met een vermogen van <= 18 kW (m.u.v. motoculteurs)',
    87019190: 'Tractoren, met een motorvermogen van  <=  18 kW (m.u.v. tractoren onder post 8709, motoculteurs, trekkers voor opleggers, rupstrekkers en land- / bosbouwtrekkers op wielen )',
    87019210: 'Landbouwtractors en tractors voor de bosbouw, op wielen, met een vermogen van > 18 kW, doch <= 37 kW (m.u.v. motoculteurs)',
    87019290: 'Tractoren, met een motorvermogen van > 18 kW, doch <= 37 kW (m.u.v. tractoren onder post 8709, motoculteurs, trekkers voor opleggers, rupstrekkers en land- / bosbouwtrekkers op wielen)',
    87019310: 'Landbouwtractors en tractors voor de bosbouw, op wielen, met een vermogen van > 37 kW, doch <= 75 kW (m.u.v. motoculteurs)',
    87019390: 'Tractoren, met een motorvermogen van > 37 kW, doch <= 75 kW (m.u.v. tractoren onder post 8709, motoculteurs, trekkers voor opleggers, rupstrekkers en land- / bosbouwtrekkers op wielen)',
    87019410: 'Landbouwtractors en tractors voor de bosbouw, op wielen, met een vermogen van > 75 kW, doch <= 130 kW (m.u.v. motoculteurs)',
    87019490: 'Tractoren, met een motorvermogen van > 75 kW, doch <= 130 kW (m.u.v. tractoren onder post 8709, motoculteurs, trekkers voor opleggers, rupstrekkers en land- / bosbouwtrekkers op wielen)',
    87019510: 'Landbouwtractors en tractors voor de bosbouw, op wielen, met een vermogen van > 130 kW (m.u.v. motoculteurs)',
    87019590: 'Tractoren, met een motorvermogen van > 130 kW (m.u.v. tractoren onder post 8709, motoculteurs, trekkers voor opleggers, rupstrekkers en land- / bosbouwtrekkers op wielen)',
    87021011: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met uitsluitend een dieselmotor, met een cinlinderinhoud  van > 2.500 cm³, nieuw',
    87021019: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met uitsluitend een dieselmotor, met een cilinderinhoud  van > 2.500 cm³, gebruikt',
    87021091: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met uitsluitend een dieselmotor, met een cilinderinhoud  van <= 2.500 cm³, nieuw',
    87021099: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met uitsluitend een dieselmotor, met een cilinderinhoud  van <= 2.500 cm³, gebruikt',
    87022010: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met zowel een dieselmotor als een elektromotor, met een cilinderinhoud van > 2.500 cm³',
    87022090: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met zowel een dieselmotor als een elektromotor, met een cilinderinhoud van <= 2.500 cm³',
    87023010: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met zowel een motor met vonkontsteking en met op- en neergaande zuigers als een elektromotor, met een cilinderinhoud van > 2.800 cm³',
    87023090: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met zowel een motor met vonkontsteking en met op- en neergaande zuigers als een elektromotor, met een cilinderinhoud van <= 2.800 cm³',
    87024000: 'Automobielen voor het vervoer van  >= 10 personen, inclusief de bestuurder, met uitsluitend een elektromotor',
    87029011: 'Automobielen voor het vervoer van >= 10 personen, incl. de bestuurder, met een motor met vonkontsteking, met een cilinderinhoud van > 2.800 cm³, nieuw  (m.u.v. voertuigen met elektromotor)',
    87029019: 'Automobielen voor het vervoer van >= 10 personen, incl. de bestuurder, met een motor met vonkontsteking, met een cilinderinhoud van > 2.800 cm³, gebruikt  (m.u.v. voertuigen met elektromotor)',
    87029031: 'Automobielen voor het vervoer van >= 10 personen, incl. de bestuurder, met een motor met vonkontsteking, met een cilinderinhoud van <= 2.800 cm³, nieuw  (m.u.v. voertuigen met elektromotor)',
    87029039: 'Automobielen voor het vervoer van >= 10 personen, incl. de bestuurder, met een motor met vonkontsteking, met een cilinderinhoud van <= 2.800 cm³, gebruikt  (m.u.v. voertuigen met elektromotor)',
    87029090: 'Automobielen voor het vervoer van >= 10 personen, incl. de bestuurder (m.u.v. die met een motor met zelfontsteking "diesel- of semi-dieselmotor" of met vonkontsteking of met elektromotor)',
    87031011: 'Voertuigen voor het zich verplaatsen op sneeuw, voor het vervoer van  minder dan 10 personen,  met een motor met zelfontsteking of met vonkontsteking',
    87031018: 'Voertuigen voor het vervoer van minder dan 10 personen, voor het zich verplaatsen op sneeuw (m.u.v. die met een motor met zelfontsteking of met vonkontsteking); voertuigen, speciaal ontworpen voor het zich verplaatsen op golfvelden e.d. voertuigen',
    87032110: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagen , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van <=  1.000 cm³, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87032190: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagen , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van <=  1.000 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87032210: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagen , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van >  1.000 cm³ , doch <=  1.500 cm³, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87032290: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagen , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van >  1.000 cm³ , doch <=  1.500 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87032311: 'Kampeerauto\'s, met een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van > 1.500 cm³, doch <= 3.000 cm³, nieuw',
    87032319: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van > 1.500 cm³ , doch <= 3.000 cm³, nieuw (m.u.v. voertuigen van de onderverdeling 8703.10 en kampeerauto\'s)',
    87032390: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van > 1.500 cm³ , doch <= 3.000 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en voertuigen van de onderverdeling 8703.10 )',
    87032410: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van >  3.000 cm³, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87032490: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een motor met vonkontsteking en met op- en neergaande zuigers, met een cilinderinhoud van >  3.000 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033110: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een dieselmotor met een cilinderinhoud van  <= 1.500 cm³, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033190: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een dieselmotor met een cilinderinhoud van  <= 1.500 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033211: 'Kampeerauto\'s, met uitsluitend een dieselmotor met een cilinderinhoud van > 1.500 cm³ doch < =2.500  cm³, nieuw',
    87033219: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" , met uitsluitend een dieselmotor met een cilinderinhoud van  > 1.500 cm³ doch <= 2.500  cm³, nieuw (m.u.v. kampeerauto\'s en voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033290: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens , met uitsluitend een dieselmotor met een cilinderinhoud van  > 1.500 cm³ doch <= 2.500  cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033311: 'Kampeerauto\'s, met uitsluitend een dieselmotor met een cilinderinhoud van > 2.500 cm³, nieuw',
    87033319: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met uitsluitend een dieselmotor met een cilinderinhoud van >2.500 cm³, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87033390: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met uitsluitend een dieselmotor met een cilinderinhoud van >2.500 cm³, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87034010: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met zowel een motor met vonkontsteking en op- en neergaande zuigers als een elektromotor als motor voor de voortstuwing, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw, andere speciaal ontworpen voertuigen van de onderverdeling 8703.10 en plug-in hybride-auto\'s)',
    87034090: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagen, met zowel een motor met vonkontsteking en op- en neergaande zuigers als een elektromotor als motor voor de voortstuwing, gebruikt (m.u.v. voertuigen voor het zich verplaatsen op sneeuw, andere speciaal ontworpen voertuigen van de onderverdeling 8703.10 en plug-in hybride-auto\'s)',
    87035000: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met zowel dieselmotor als een elektromotor als motor voor de voortstuwing (m.u.v. voertuigen voor het zich verplaatsen op sneeuw, andere speciaal ontworpen voertuigen van de onderverdeling 8703.10 en plug-in hybride-auto\'s)',
    87036010: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met zowel een motor met vonkontsteking en op- en neergaande zuigers als een elektromotor als motor voor de voortstuwing, die kunnen worden opgeladen door het aansluiten op een externe bron van elektrische energie, nieuw (m.u.v. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87036090: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief  "station wagon" en racewagens, met een motor met zowel vonkontsteking en op- en neergaande zuigers als een elektromotor als motor voor de voortstuwing, die kunnen worden opgeladen door het aansluiten op een externe bron van elektrische energie, gebruikt (excl. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87037000: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van  minder dan 10 personen, inclusief "station wagon" en racewagens, met zowel  een dieselmotor als een elektromotor als motor voor de voortstuwing, die kunnen worden opgeladen door het aansluiten op een externe bron van elektrische energie (excl. voertuigen voor het zich verplaatsen op sneeuw en andere speciaal ontworpen voertuigen van de onderverdeling 8703.10)',
    87038010: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van minder dan 10 personen, inclusief motorvoertuigen van het type "station-wagon" of "break" en racewagens, met enkel een elektromotor voor voortbeweging, nieuw (m.u.v. voertuigen, speciaal ontworpen voor het zich verplaatsen op sneeuw en andere speciale voertuigen bedoeld bij onderverdeling 8703.10)',
    87038090: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor vervoer van minder dan 10 personen, inclusief motorvoertuigen van het type "station-wagon" of "break" en racewagens, met enkel een elektromotor boor voortbeweging, gebruikt (m.u.v. voertuigen, speciaal ontworpen voor het zich verplaatsen op sneeuw en andere speciale voertuigen bedoeld bij onderverdeling 8703.10)',
    87039000: 'Automobielen en andere motorvoertuigen hoofdzakelijk ontworpen voor het vervoer van minder dan 10 personen, inclusief motorvoertuigen van het type "station-wagon" of "break" en racewagens, met een motor anders dan een motor met op- en neergaande zuigers of een elektromotor , (m.u.v. voertuigen, speciaal ontworpen voor het zich verplaatsen op sneeuw en andere speciale voertuigen bedoeld bij onderverdeling 8703.10)',
    87041010: 'dumpers, ontworpen voor gebruik in het terrein, met een motor met zelfontsteking of met een motor met vonkontsteking',
    87041090: 'dumpers, ontworpen voor gebruik in het terrein (m.u.v. die met een motor met zelfontsteking of met vonkontsteking)',
    87042110: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van <= 5 ton, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"',
    87042131: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van > 2.500 cm³, nieuw (m.u.v. dumpers, bedoeld bij post 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042139: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van > 2.500 cm³, gebruikt (m.u.v. dumpers, bedoeld bij post 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042191: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van <= 2.500 cm³, nieuw (m.u.v. dumpers, bedoeld bij post 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042199: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van <= 2.500 cm³, gebruikt (m.u.v. dumpers, bedoeld bij post 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042210: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 5 ton, doch <= 20 ton, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"',
    87042291: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 5 ton, doch <= 20 ton, nieuw (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042299: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 5 ton, doch <= 20 ton, gebruikt (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042310: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 20 ton, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"',
    87042391: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 20 ton, nieuw (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87042399: 'automobielen voor goederenvervoer, met een motor met zelfontsteking, met een maximaal toegelaten gewicht van > 20 ton, gebruikt (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043110: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van <= 5 ton, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom" (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705)',
    87043131: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van > 2.800 cm³, nieuw (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043139: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van > 2.800 cm³, gebruikt (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043191: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van <= 2.800 cm³, nieuw (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043199: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van <= 5 ton, met een cilinderinhoud van <= 2.800 cm³, gebruikt (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043210: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van > 5 ton, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom" (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705)',
    87043291: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van > 5 ton, nieuw (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87043299: 'automobielen voor goederenvervoer, met een motor met vonkontsteking, met een maximaal toegelaten gewicht van > 5 ton, gebruikt (m.u.v. dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705; speciale automobielen voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87049000: 'automobielen voor goederenvervoer (m.u.v. die met motor met zelfontsteking, die met motor met vonkontsteking; dumpers, bedoeld bij onderverdeling 870410; automobielen voor bijzondere doeleinden bedoeld bij post 8705)',
    87051000: 'kraanauto\'s (m.u.v. takelwagens)',
    87052000: 'verrijdbare boortorens',
    87053000: 'brandweerauto\'s (m.u.v. automobielen voor het vervoer van personen)',
    87054000: 'automobielen met menginstallatie voor beton',
    87059030: 'betonpompvoertuigen',
    87059080: 'automobielen voor bijzondere doeleinden (m.u.v. die hoofdzakelijk ontworpen voor het vervoer van personen of van goederen; die met menginstallatie voor beton; brandweerauto\'s; verrijdbare boortorens; kraanauto\'s; betonpompvoertuigen)',
    87060011: 'chassis, met een motor met zelfontsteking, met een cilinderinhoud van > 2.500 cm³, dan wel met vonkontsteking met een cilinderinhoud van > 2.800 cm³, voor bussen en automobielen voor goederenvervoer',
    87060019: 'chassis, met een motor met zelfontsteking, met een cilinderinhoud van > 2.500 cm³, dan wel met vonkontsteking met een cilinderinhoud van > 2.800 cm³, voor automobielen voor personenvervoer; chassis met motor voor tractors bedoeld bij post 8701',
    87060091: 'chassis, met een motor met zelfontsteking, met een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking met een cilinderinhoud van <= 2.800 cm³, voor automobielen voor personenvervoer',
    87060099: 'chassis, met een motor met zelfontsteking, met een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking met een cilinderinhoud van <= 2.800 cm³, voor bussen en voor automobielen voor goederenvervoer; chassis voor automobielen voor bijzondere doeleinden bedoeld bij post 8705',
    87071010: 'carrosserieën, bestemd voor de industriële montage van automobielen voor personenvervoer',
    87071090: 'carrosserieën voor automobielen voor personenvervoer (m.u.v. die bestemd voor industriële montage bedoeld bij onderverdeling 8707.10.10)',
    87079010: 'carrosserieën, bestemd voor de industriële montage van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, alsmede van automobielen voor bijzondere doeleinden bedoeld bij post 8705',
    87079090: 'carrosserieën voor tractors, bussen, automobielen voor goederenvervoer en automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde voertuigen zoals bedoeld bij onderverdeling 8707.90.10)',
    87081010: 'bumpers en delen daarvan, bestemd voor industriële montage: van automobielen voor personenvervoer en van goederenvervoer met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87081090: 'bumpers en delen daarvan, voor tractors, bussen, automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde voertuigen bedoeld bij post 8708.10.10)',
    87082110: 'veiligheidsgordels, ter beveiliging van personen in motorvoertuigen, bestemd voor industriële montage: van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705',
    87082190: 'veiligheidsgordels, ter beveiliging van personen in motorvoertuigen (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij post 8708.21.10)',
    87082910: 'delen en toebehoren van carrosserieën bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705 (m.u.v. bumpers en delen daarvan; veiligheidsgordels)',
    87082990: 'delen en toebehoren van carrosserieën voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. bumpers en delen daarvan; die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.29.10)',
    87083010: 'remmen en servoremmen, alsmede delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87083091: 'Delen voor schijfremmen, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.30.10)',
    87083099: 'remmen en servoremmen, alsmede delen daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.30.10 en van schijfremmen)',
    87084020: 'versnellingsbakken, alsmede delen daarvan,  bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87084050: 'versnellingsbakken voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.40.20)',
    87084091: 'delen van versnellingsbakken, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer en goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.40.20)',
    87084099: 'Delen voor versnellingsbakken, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.40.20 en m.u.v. die van gestampt staal)',
    87085020: 'drijfassen met differentieel, ook indien met andere organen voor de overbrenging, alsmede draagassen, en delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer; van automobielen voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87085035: 'drijfassen met differentieel, ook indien met andere organen voor de overbrenging, alsmede draagassen en delen daarvan,  voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.50.20)',
    87085055: 'Delen van drijfassen met differentieel, ook indien met andere organen voor de overbrenging, alsmede draagassen, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.50.20)',
    87085091: 'draagassen en delen daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.50.20 en die van gestampt staal)',
    87085099: 'delen van drijfassen met differentieel, ook indien met andere organen voor de overbrenging, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.50.20 en m.u.v. die voor draagassen en die van gestampt staal)',
    87087010: 'wielen, alsmede delen en toebehoren daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705',
    87087050: 'wielen, alsmede delen en toebehoren daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, van aluminium (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.70.10)',
    87087091: 'delen van wielen, stervormig, uit een stuk gegoten, van gietijzer, van ijzer of van staal, voor tractors, voor bussen, voor automobielen voor personenvervoer of voor goederenvervoer of voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.70.10)',
    87087099: 'wielen, alsmede delen en toebehoren daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.70.10)',
    87088020: 'ophanginrichtingen en delen daarvan, incl. schokdempers, bestemd voor industriële montage: van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87088035: 'schokdempers, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.80.20)',
    87088055: 'stabilisatorstaven en torsiestangen voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.80.20)',
    87088091: 'ophanginrichtingen en delen daarvan, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, van gestampt staal, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.80.20, schokdempers, stabilisatorstaven en torsiestangen)',
    87088099: 'Ophanginrichtingen en delen daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.80.20, schokdempers, stabilisatorstaven, torsiestangen en m.u.v. die van gestampt staal)',
    87089120: 'radiatoren en delen daarvan, voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer en voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87089135: 'radiatoren, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.91.20)',
    87089191: 'delen van radiatoren, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.91.20)',
    87089199: 'Delen van radiatoren voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.91.20 en m.u.v. die van gestampt staal)',
    87089220: 'knaldempers en uitlaatpijpen en delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer; van automobielen voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87089235: 'knaldempers en uitlaatpijpen, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.92.20)',
    87089291: 'delen van knaldempers en uitlaatpijpen, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.92.20)',
    87089299: 'delen van knaldempers en uitlaatpijpen voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.92.20 en m.u.v. die van gestampt staal)',
    87089310: 'koppelingen en delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer; van automobielen voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705',
    87089390: 'koppelingen en delen daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer en voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.93.10)',
    87089420: 'Stuurwielen, stuurkolommen en stuurhuizen, alsmede delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer, van automobielen voor goederenvervoer, met een motor met zelfontsteking "diesel- of semidieselmotor" en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87089435: 'stuurwielen, stuurkolommen en stuurhuizen, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.94.20)',
    87089491: 'delen van stuurwielen, stuurkolommen en stuurhuizen voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, van gestampt staal, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.94.20)',
    87089499: 'delen van stuurwielen, stuurkolommen en stuurhuizen voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.94.20 en m.u.v. die van gestampt staal)',
    87089510: 'veiligheidsairbags met opblaassysteem en delen daarvan, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer; van automobielen voor goederenvervoer, met een motor met zelfontsteking en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87089591: 'Veiligheidsairbags met opblaassystem en delen daarvan, van gestampt staal, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.95.10)',
    87089599: 'Veiligheidsairbags met opblaassystem en delen daarvan, voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.95.10 en m.u.v. die van gestampt staal)',
    87089910: 'delen en toebehoren, bestemd voor industriële montage: van motoculteurs bedoeld bij onderverdeling 8701.10, van automobielen voor personenvervoer; van automobielen voor goederenvervoer, met een motor met zelfontsteking "diesel- of semidieselmotor en een cilinderinhoud van <= 2.500 cm³, dan wel met vonkontsteking en een cilinderinhoud van <= 2.800 cm³, van automobielen voor bijzondere doeleinden bedoeld bij post 8705, n.e.g.',
    87089993: 'delen en toebehoren voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, van gestampt staal, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.99.10)',
    87089997: 'delen en toebehoren voor tractors, voor bussen, voor automobielen voor personenvervoer, voor automobielen voor goederenvervoer en voor automobielen voor bijzondere doeleinden, n.e.g. (m.u.v. die bestemd voor industriële montage van bepaalde motorvoertuigen bedoeld bij onderverdeling 8708.99.19 en m.u.v. gestampt staal)',
    87091110: 'transportwagens en trekkers, elektrisch, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"',
    87091190: 'transportwagens en trekkers, elektrisch, zonder hefsysteem, van de soort gebruikt voor het vervoer van goederen over korte afstanden of voor het trekken van perronwagentjes (m.u.v. die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom")',
    87091910: 'transportwagens en trekkers, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom" (m.u.v. elektrische wagens en trekkers)',
    87091990: 'transportwagens en trekkers, zonder hefsysteem, van de soort gebruikt voor het vervoer van goederen over korte afstanden of voor het trekken van perronwagentjes (m.u.v. die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; elektrische wagens en trekkers)',
    87099000: 'delen van transportwagens en van trekkers, zonder hefsysteem, van de soort gebruikt voor het vervoer van goederen over korte afstanden of voor het trekken van perronwagentjes, n.e.g.',
    87100000: 'gevechtswagens en pantserauto\'s, ook indien met bewapening; delen daarvan, n.e.g.',
    87111000: 'Motorrijwielen, inclusief bromfietsen, met een motor met op- en neergaande zuigers, met een cilinderinhoud van <= 50 cm³',
    87112010: 'scooters, met motor met op- en neergaande zuigers, met een cilinderinhoud van > 50 cm³, doch <= 250 cm³',
    87112092: 'motorrijwielen, ook indien met zijspan, met motor met op- en neergaande zuigers, met een cilinderinhoud van > 50 doch <= 125 cm³ (m.u.v. scooters)',
    87112098: 'motorrijwielen, ook indien met zijspan, met motor met op- en neergaande zuigers, met een cilinderinhoud van > 125 doch <= 250 cm³ (m.u.v. scooters)',
    87113010: 'motorrijwielen, ook indien met zijspan, met motor met op- en neergaande zuigers, met een cilinderinhoud van > 250 doch <= 380 cm³ (m.u.v. scooters)',
    87113090: 'motorrijwielen, ook indien met zijspan, met motor met op- en neergaande zuigers, met een cilinderinhoud van > 380 doch <= dan 500 cm³',
    87114000: 'motorrijwielen met motor met op- en neergaande zuigers, met een cilinderinhoud van > 500, doch <= 800 cm³',
    87115000: 'motorrijwielen met motor met op- en neergaande zuigers, met een cilinderinhoud van > 800 cm³',
    87116010: 'Fietsen, driewielers en vierwielers, met trapondersteuning, met een elektrische hulpmotor met een continu nominaal vermogen < = 250 W',
    87116090: 'Motorrijwielen, inclusief bromfietsen, met elektrische motor voor aandrijving (m.u.v. fietsen, driewielers en vierwielers, met trapondersteuning, met een continu nominaal vermogen < = 250 W)',
    87119000: 'Motorrijwielen en rijwielen met hulpmotor, ook indien met zijspan (m.u.v. die met motor met op- en neergaande zuigers, en m.u.v. rijwielen met een elektrische hulpmotor)',
    87120030: 'tweewielige rijwielen, zonder motor, met kogellagers',
    87120070: 'rijwielen, incl. bakfietsen, zonder motor (m.u.v. tweewielige rijwielen met kogellagers)',
    87131000: 'invalidenwagens, zonder voortbewegingsmechanisme',
    87139000: 'invalidenwagens, met motor of ander voortbewegingsmechanisme (m.u.v. automobielen en rijwielen, met bijzondere inrichtingen)',
    87141010: 'remmen en onderdelen daarvan, van motorrijwielen, rijwielen met hulpmotor daaronder begrepen',
    87141020: 'versnellingsbakken, alsmede delen daarvan, van motorrijwielen, rijwielen met hulpmotor daaronder begrepen',
    87141030: 'wielen, alsmede delen en toebehoren daarvan, van motorrijwielen, rijwielen met hulpmotor daaronder begrepen',
    87141040: 'knaldempers en uitlaatpijpen, alsmede delen daarvan, van motorrijwielen, rijwielen met hulpmotor daaronder begrepen',
    87141050: 'koppelingen, alsmede delen daarvan, van motorrijwielen, rijwielen met hulpmotor daaronder begrepen',
    87141090: 'delen en toebehoren van motorrijwielen, rijwielen met hulpmotor daaronder begrepen, n.e.g. (m.u.v remmen, versnellingsbakken, wielen, knaldempers en uitlaatpijpen, koppelingen,  en onderdelen daarvan)',
    87142000: 'delen en toebehoren van invalidenwagens, n.e.g.',
    87149110: 'frames voor rijwielen (m.u.v. voor motorrijwielen)',
    87149130: 'vorken voor rijwielen (m.u.v. voor motorrijwielen)',
    87149190: 'delen van vorken voor rijwielen (m.u.v. voor motorrijwielen)',
    87149210: 'velgen voor rijwielen (m.u.v. voor motorrijwielen)',
    87149290: 'spaken voor rijwielen (m.u.v. voor motorrijwielen)',
    87149300: 'naven en tandwielen voor vrijloop "free-wheels", voor rijwielen (m.u.v. remnaven en voor motorrijwielen)',
    87149420: 'remmen, incl. remnaven, voor rijwielen (m.u.v. voor motorrijwielen)',
    87149490: 'delen van remmen, incl. van remnaven, voor rijwielen, n.e.g. (m.u.v. voor motorrijwielen)',
    87149500: 'zadels voor rijwielen (m.u.v. voor motorrijwielen)',
    87149610: 'pedalen voor rijwielen',
    87149630: 'pedaalaandrijvingen voor rijwielen',
    87149690: 'delen van pedalen en pedaalaandrijvingen, voor rijwielen, n.e.g.',
    87149910: 'sturen voor rijwielen',
    87149930: 'bagagedragers voor rijwielen',
    87149950: 'derailleurs "kettingschakelaars" voor rijwielen',
    87149990: 'delen en toebehoren van rijwielen, n.e.g.',
    87150010: 'kinderwagens',
    87150090: 'delen van kinderwagens, n.e.g.',
    87161092: 'aanhangwagens en opleggers van het caravantype die als woonruimte worden gebruikt of om te kamperen, met een gewicht van <= 1.600 kg',
    87161098: 'aanhangwagens en opleggers van het caravantype die als woonruimte worden gebruikt of om te kamperen, met een gewicht van > 1.600kg',
    87162000: 'aanhangwagens en opleggers voor landbouwdoeleinden, zelfladend of zelflossend',
    87163100: 'tankaanhangwagens en tankopleggers (m.u.v. die voor spoor- en tramwegen)',
    87163910: 'aanhangwagens en opleggers, speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"',
    87163930: 'opleggers, nieuw, voor goederenvervoer (m.u.v. die voor spoor- en tramwegen; die voor landbouwdoeleinden, zelfladend of zelflossend; die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; tankaanhangwagens en tankopleggers)',
    87163950: 'aanhangwagens voor goederenvervoer, nieuw, (m.u.v. die voor spoor- en tramwegen; die voor landbouwdoeleinden, zelfladend of zelflossend; die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; opleggers; tankaanhangwagens)',
    87163980: 'aanhangwagens, gebruikt, voor goederenvervoer (m.u.v. die voor spoor- en tramwegen; die voor landbouwdoeleinden, zelfladend of zelflossend; die speciaal vervaardigd voor het vervoer van sterk radioactieve stoffen "Euratom"; tankaanhangwagens en tankopleggers)',
    87164000: 'aanhangwagens en opleggers (m.u.v. die voor spoor- en tramwegen; die die als woonruimte worden gebruikt of om te kamperen)',
    87168000: 'voertuigen, met de hand voortbewogen, en andere voertuigen zonder eigen beweegkracht (m.u.v. aanhangwagens en opleggers)',
    87169010: 'chassis van aanhangwagens en opleggers, alsmede van andere voertuigen zonder eigen beweegkracht, n.e.g.',
    87169030: 'carrosserieën van aanhangwagens en opleggers, alsmede van andere voertuigen zonder eigen beweegkracht, n.e.g.',
    87169050: 'assen van aanhangwagens en opleggers, alsmede van andere voertuigen zonder eigen beweegkracht, n.e.g.',
    87169090: 'delen van aanhangwagens en van opleggers, alsmede van andere voertuigen zonder eigen beweegkracht, n.e.g.',
    88010010: 'Zweefvliegtuigen, zonder voortbewegingsmechanisme, en zeilvliegers; luchtballons en luchtschepen (m.u.v. speelgoedballons)',
    88010090: 'Vliegers en andere luchtvaartuigen, zonder voortbewegingsmechanisme (m.u.v. zweefvliegtuigen en zeilvliegers, luchtballons en luchtschepen en m.u.v. speelgoedvliegers)',
    88021100: 'Hefschroefvliegtuigen met een leeggewicht van <= 2.000 kg',
    88021200: 'Hefschroefvliegtuigen met een leeggewicht van > 2.000 kg',
    88022000: 'Vliegtuigen en andere luchtvaartuigen met voortbewegingsmechanisme, met een leeggewicht van <= 2.000 kg (m.u.v. hefschroefvliegtuigen en luchtschepen)',
    88023000: 'Vliegtuigen en andere luchtvaartuigen met voortbewegingsmechanisme, met een leeggewicht van > 2.000 doch <= 15.000 kg (m.u.v. hefschroefvliegtuigen en luchtschepen)',
    88024000: 'Vliegtuigen en andere luchtvaartuigen met voortbewegingsmechanisme, met een leeggewicht van > 15.000 kg (m.u.v. hefschroefvliegtuigen en luchtschepen)',
    88026011: 'Telecommunicatiesatellieten',
    88026019: 'Ruimtevaartuigen (m.u.v. telecommunicatiesatellieten)',
    88026090: 'draagraketten voor ruimtevaartuigen en suborbitale voertuigen',
    88031000: 'Propellors en rotors, alsmede delen daarvan, bestemd voor luchtvaartuigen, n.e.g',
    88032000: 'Landingsgestellen en delen daarvan, bestemd voor luchtvaartuigen, n.e.g.',
    88033000: 'Delen van hefschroefvliegtuigen of van vliegtuigen, n.e.g. (m.u.v. delen van zweefvliegtuigen)',
    88039010: 'delen van kabelvliegers, n.e.g.',
    88039021: 'Onderdelen van telecommunicatiesatellieten, n.e.g.',
    88039029: 'Onderdelen van ruimtevaartuigen,  inclusief satellieten, n.e.g.',
    88039030: 'delen van draagraketten voor ruimtevaartuigen en suborbitale voertuigen, n.e.g.',
    88039090: 'Delen van luchtvaartuigen, n.e.g. (m.u.v. die voor ruimtevaartuigen (incl. satellieten), draagraketten voor ruimtevaartuigen en suborbitale voertuigen)',
    88040000: 'valschermen, incl. bestuurbare valschermen en vliegschermen, en rotochutes; delen en toebehoren daarvan, n.e.g.',
    88051010: 'lanceertoestellen voor luchtvaartuigen (m.u.v. startlieren voor het doen opstijgen van zweefvliegtuigen) en delen daarvan, n.e.g.',
    88051090: 'deklandingstoestellen e.d., alsmede delen daarvan, n.e.g.',
    88052100: 'luchtgevechtssimulators en delen daarvan',
    88052900: 'Toestellen voor vliegoefeningen op de grond en delen daarvan, n.e.g. (m.u.v. luchtgevechtssimulators en delen daarvan)',
    89011010: 'passagiersschepen, rondvaartboten e.d. schepen voor het vervoer van personen, veerboten van alle soorten, voor de zeevaart',
    89011090: 'passagiersschepen, rondvaartboten e.d. schepen voor het vervoer van personen; veerboten van alle soorten (m.u.v. die voor de zeevaart)',
    89012010: 'tankschepen voor de zeevaart',
    89012090: 'tankschepen (m.u.v. die voor de zeevaart)',
    89013010: 'koelschepen voor de zeevaart (m.u.v. tankschepen)',
    89013090: 'koelschepen (m.u.v. die voor de zeevaart en m.u.v. tankschepen)',
    89019010: 'schepen voor het vervoer van goederen en schepen die zowel bestemd zijn voor het vervoer van personen als van goederen, voor de zeevaart (m.u.v. koelschepen, tankschepen, veerboten en schepen die hoofdzakelijk bestemd zijn voor het vervoer van personen)',
    89019090: 'Schepen voor het vervoer van goederen en schepen die zowel bestemd zijn voor het vervoer van personen als van goederen, met of zonder mechanische voortbeweging (m.u.v. die voor de zeevaart en koelschepen, tankschepen, veerboten en schepen die hoofdzakelijk bestemd zijn voor het vervoer van personen)',
    89020010: 'Vissersvaartuigen; fabrieksschepen en andere schepen voor het behandelen of het conserveren van visserijproducten, voor de zeevaart',
    89020090: 'vissersvaartuigen; fabrieksschepen en andere schepen voor het behandelen of het conserveren van visserijproducten (m.u.v. die voor de zeevaart en die voor de sportvisserij)',
    89031010: 'opblaasbare plezier- en sportvaartuigen, met een gewicht per stuk van <= 100 kg',
    89031090: 'opblaasbare plezier- en sportvaartuigen, met een gewicht van > 100 kg',
    89039110: 'zeilschepen en zeiljachten, ook indien met hulpmotor, voor plezier- en sportdoeleinden, voor de zeevaart',
    89039190: 'Zeilschepen en zeiljachten, ook indien met hulpmotor, voor plezier- en sportdoeleinden (m.u.v. die voor de zeevaart)',
    89039210: 'motorboten en motorjachten, voor plezier- en sportdoeleinden, voor de zeevaart (m.u.v. die met buitenboordmotor)',
    89039291: 'motorboten, voor plezier- en sportdoeleinden, met een lengte van <= 7,5 m (m.u.v. die met buitenboordmotor)',
    89039299: 'motorboten en motorjachten, voor plezier- en sportdoeleinden, met een lengte van > 7,5 m (m.u.v. die voor de zeevaart en die met buitenboordmotor)',
    89039910: 'plezier- en sportvaartuigen, alsmede roeiboten en kano\'s, met een gewicht van <= 100 kg (m.u.v. motorboten die niet met een buitenboordmotor zijn uitgerust, zeilboten, ook indien met hulpmotor en opblaasbare boten)',
    89039991: 'plezier- en sportvaartuigen, alsmede roeiboten en kano\'s, met een gewicht van > 100 kg, met een lengte van <= 7,5 m (m.u.v. motorboten die niet met een buitenboordmotor zijn uitgerust, zeilboten, ook indien met hulpmotor en opblaasbare boten)',
    89039999: 'plezier- en sportvaartuigen, alsmede roeiboten en kano\'s, met een gewicht van > 100 kg, met een lengte van > 7,5 m (m.u.v. motorboten en motorjachten, die niet met een buitenboordmotor zijn uitgerust, zeilboten en zeiljachten, ook indien met hulpmotor en opblaasbare boten)',
    89040010: 'sleepboten, voor de zee- of de binnenvaart',
    89040091: 'zeeduwboten',
    89040099: 'duwboten (m.u.v. zeeduwboten)',
    89051010: 'baggermolens en zandzuigers, voor de zeevaart',
    89051090: 'baggermolens en zandzuigers (m.u.v. die voor de zeevaart)',
    89052000: 'boor- en werkeilanden, ook indien deze op de zeebodem geplaatst kunnen worden',
    89059010: 'lichtschepen, pompboten, drijvende kranen en andere schepen waarbij het varen slechts van bijkomstige betekenis is vergeleken met de hoofdfunctie, voor de zeevaart (m.u.v. baggermolens en zandzuigers; boor- en werkeilanden, ook indien deze op de zeebodem geplaatst kunnen worden, vissersvaartuigen en oorlogsschepen)',
    89059090: 'lichtschepen, pompboten, drijvende kranen en andere schepen waarbij het varen slechts van bijkomstige betekenis is vergeleken met de hoofdfunctie (m.u.v. die voor de zeevaart en m.u.v. baggermolens en zandzuigers; boor- en werkeilanden, ook indien deze op de zeebodem geplaatst kunnen worden, vissersvaartuigen en oorlogsschepen)',
    89061000: 'oorlogsschepen van alle soorten',
    89069010: 'schepen, incl. reddingsboten, voor de zeevaart (m.u.v. oorlogsschepen, roeiboten en andere vaartuigen bedoeld bij post 8901 tm 8905 en m.u.v. sloopschepen)',
    89069091: 'schepen, reddingsboten daaronder begrepen, met een gewicht per stuk van <= 100 kg, n.e.g. (m.u.v. roeiboten en andere schepen bedoeld bij de posten 8901 tot en met 8905 en sloopschepen)',
    89069099: 'vaartuigen, incl. reddingsboten, met een gewicht van > 100 kg (m.u.v. die voor de zeevaart, m.u.v. oorlogsschepen, roeiboten en andere vaartuigen bedoeld bij de posten 8901 tot en met 8905 en m.u.v. sloopschepen)',
    89071000: 'opblaasbare vlotten',
    89079000: 'vlotten, tanks, caissons, aanlegsteigers, boeien, bakens en ander drijvend materieel (m.u.v. opblaasbare vlotten, vaartuigen bedoeld bij de posten 8901 tot en met 8906 en drijvend materieel bestemd voor de sloop)',
    89080000: 'sloopschepen en ander drijvend materieel bestemd voor de sloop',
    90011010: 'optischevezelkabels voor beeldgeleiding (m.u.v. die bestaande uit individueel omhulde vezels bedoeld bij post 8544)',
    90011090: 'optische vezels, optischevezelbundels en optischevezelkabels (m.u.v. die bestaande uit individueel omhulde vezels bedoeld bij post 8544 en m.u.v. kabels voor beeldgeleiding)',
    90012000: 'platen of bladen van polariserende stoffen',
    90013000: 'contactlenzen',
    90014020: 'brillenglazen van glas (m.u.v. die voor verbetering van gezichtsstoornissen)',
    90014041: 'brillenglazen van glas, voor verbetering van gezichtsstoornissen, afgewerkt aan beide zeiden, unifocaal',
    90014049: 'brillenglazen van glas, voor verbetering van gezichtsstoornissen, afgewerkt aan beide zeiden, bifocaal of multifocaal',
    90014080: 'brillenglazen van glas, voor verbetering van gezichtsstoornissen (m.u.v. die welke aan beide zeiden zijn afgewerkt)',
    90015020: 'brillenglazen (m.u.v. die van glas of voor verbetering van gezichtsstoornissen)',
    90015041: 'brillenglazen voor verbetering van gezichtsstoornissen, afgewerkt aan beide zeiden, unifocaal (m.u.v. die van glas)',
    90015049: 'brillenglazen voor verbetering van gezichtsstoornissen, afgewerkt aan beide zeiden, bifocaal of multifocaal (m.u.v. die van glas)',
    90015080: 'brillenglazen voor verbetering van gezichtsstoornissen (m.u.v. die van glas en die welke aan beide zeiden zijn afgewerkt)',
    90019000: 'Lenzen, prisma\'s, spiegels en andere optische elementen, ongeacht de stof waarvan zij zijn vervaardigd, ongemonteerd (m.u.v. die van niet-optisch bewerkt glas en m.u.v. contactlenzen en brillenglazen)',
    90021100: 'objectieven voor camera\'s, voor projectietoestellen of voor vergrotings- of verkleiningstoestellen voor de fotografie of voor de cinematografie',
    90021900: 'objectieven (m.u.v. die voor camera\'s, voor projectietoestellen of voor vergrotings- of verkleiningstoestellen voor de fotografie of voor de cinematografie)',
    90022000: 'Optische filters, gemonteerd, voor instrumenten, apparaten en toestellen',
    90029000: 'Lenzen, prisma\'s, spiegels en andere optische elementen, ongeacht de stof waarvan zij zijn vervaardigd, gemonteerd, voor instrumenten, apparaten en toestellen (m.u.v. die van niet-optisch bewerkt glas; objectieven en filters)',
    90031100: 'monturen voor brillen of voor dergelijke artikelen, van kunststof',
    90031900: 'Monturen voor brillen of voor dergelijke artikelen (m.u.v. die van kunststof)',
    90039000: 'delen van monturen voor brillen of voor dergelijke artikelen, n.e.g.',
    90041010: 'zonnebrillen met optisch bewerkte brillenglazen',
    90041091: 'Zonnebrillen met niet-optisch bewerkte brillenglazen van kunststof',
    90041099: 'Zonnebrillen met niet-optisch bewerkte brillenglazen van glas',
    90049010: 'Brillen "voor de verbetering van de gezichtsscherpte, voor het beschermen van de ogen en andere", e.d. artikelen, met brillenglazen van kunststof (m.u.v. brillen voor het testen van gezichtsvermogen, zonnebrillen, contactlenzen, brillenglazen en brilmonturen)',
    90049090: 'Brillen "voor de verbetering van de gezichtsscherpte, voor het beschermen van de ogen en andere", e.d. artikelen (m.u.v. die met brillenglazen van kunststof; brillen voor het testen van gezichtsvermogen, zonnebrillen, contactlenzen, brillenglazen en brilmonturen)',
    90051000: 'Binocles "dubbele kijkers"',
    90058000: 'verrekijkers, astronomische kijkers, optische telescopen en andere astronomische instrumenten (m.u.v. binocles "dubbele kijkers, radioastronomische apparaten en andere instrumenten, apparaten en toestellen, elders genoemd noch elders onder begrepen)',
    90059000: 'delen en toebehoren, incl. onderstellen voor binocles "dubbele kijkers, verrekijkers, astronomische kijkers en andere astronomische instrumenten, n.e.g.',
    90063000: 'fototoestellen speciaal ontworpen voor onderwaterfotografie, voor luchtfotografie, voor medisch onderzoek van inwendige organen of voor gerechtelijke en criminologische laboratoria',
    90064000: 'fototoestellen voor direct-klaar-fotografie (m.u.v. fototoestellen bedoeld bij de onderverdelingen 90061000, 9006.20.00 of 9006.30.00)',
    90065100: 'eenogige spiegel-reflexcamera\'s voor rolfilm met een breedte van <= 35 mm (m.u.v. fototoestellen voor direct-klaar-fotografie en speciale fototoestellen bedoeld bij de onderverdeling 90061000, 9006.20.00 of 9006.30.00)',
    90065200: 'fototoestellen voor rolfilm met een breedte van < 35 mm (m.u.v. fototoestellen voor directklaarfotografie, eenogige spiegel-reflexcamera\'s en speciale fototoestellen bedoeld bij de onderverdeling 9006.10 of 9006.30)',
    90065310: 'wegwerpfototoestellen voor rolfilm met een breedte van 35 cm',
    90065380: 'fototoestellen voor rolfilm met een breedte van 35 mm (m.u.v. die voor directklaarfotografie, eenogige spiegelreflexcamera\'s en speciale fototoestellen, bedoeld bij de onderverdeling 9006.10 of 9006.30 en m.u.v. wegwerpfototoestellen)',
    90065900: 'Camera\'s voor rolfilm met een breedte van > 35 mm of voor vlakfilm (m.u.v. directklaarcamera\'s en camera\'s die speciaal zijn ontworpen voor gebruik onder water, voor luchtfotografie of voor medisch of chirurgisch onderzoek van inwendige organen, en voor  forensische of criminologische laboratoria)',
    90066100: 'flitstoestellen, voor de fotografie, met ontladingsbuizen, zgn. elektronische flitstoestellen',
    90066900: 'flitstoestellen en flitslampen en -buizen, voor de fotografie (m.u.v. toestellen met ontladingsbuizen "elektronische flitstoestellen")',
    90069100: 'Delen en toebehoren van fototoestellen, n.e.g.',
    90069900: 'delen en toebehoren van flitstoestellen voor de fotografie, n.e.g.',
    90071000: 'filmcamera\'s',
    90072000: 'filmprojectietoestellen',
    90079100: 'Delen en toebehoren van filmcamera\'s, n.e.g.',
    90079200: 'delen en toebehoren van filmprojectietoestellen, n.e.g.',
    90085000: 'projectietoestellen voor niet-bewegende beelden, vergrotings- en verkleiningstoestellen voor de fotografie',
    90089000: 'delen en toebehoren van projectietoestellen voor niet-bewegende beelden en van vergrotings- en verkleiningstoestellen voor de fotografie, n.e.g.',
    90101000: 'apparaten en uitrustingsstukken voor het automatisch ontwikkelen van fotografische en cinematografische film of papier, op rollen, dan wel voor het automatisch afdrukken van ontwikkelde film op rollen fotografisch papier',
    90105000: 'apparaten en uitrustingsstukken voor fotografische en cinematografische laboratoria, n.e.g.; negatoscopen',
    90106000: 'projectieschermen',
    90109020: 'Onderdelen en toebehoren van apparaten en uitrustingsstukken van de onderverdelingen 9010 50 00 of 9010 60 00, n.e.g.',
    90109080: 'Onderdelen en toebehoren van apparaten en uitrustingsstukken voor het automatisch ontwikkelen van fotografische of cinematografische film of papier, op rollen, dan wel voor het automatisch afdrukken van ontwikkelde film op rollen fotografisch papier, n.e.g.',
    90111000: 'stereoscopische optische microscopen',
    90112010: 'niet-stereoscopische optische microscopen voor fotomicrografie, voorzien van uitrustingsstukken speciaal vervaardigd voor het hanteren en transporteren van schijven "wafers" van halfgeleidermateriaal of van patroonmaskers of systeemrasters "reticles"',
    90112090: 'niet-stereoscopische optische microscopen, voor fotomicrografie, cinefotomicrografie en microprojectie (m.u.v. die voorzien van uitrustingsstukken speciaal vervaardigd voor het hanteren en transporteren van schijven "wafers" van halfgeleidermateriaal of van patroonmaskers of systeemrasters "reticles")',
    90118000: 'optische microscopen (m.u.v. die voor fotomicrografie, cinefotomicrografie en micropojectie en m.u.v. stereoscopische microscopen, binoculaire microscopen voor de oogheelkunde en instrumenten, apparaten een toestellen bedoeld bij post 9031)',
    90119000: 'delen en toebehoren van optische microscopen, n.e.g.',
    90121000: 'elektronenmicroscopen, protonenmicroscopen en diffractieapparaten',
    90129000: 'delen en toebehoren van elektronenmicroscopen, protonenmicroscopen en diffractieapparaten, n.e.g.',
    90131010: 'Kijkers voor machines, apparaten, toestellen of instrumenten bedoeld bij hoofdstuk 84, 85 of 90',
    90131090: 'Vizierkijkers voor wapens; periscopen',
    90132000: 'lasers (m.u.v. laserdioden)',
    90138020: 'actieve matrixelementen met vloeibare kristallen',
    90138030: 'elementen met vloeibare kristallen, die als zodanig geen artikelen vormen die elders meer specifiek zijn omschreven',
    90138090: 'loepen, dradentellers, stereoscopen, kaleidoscopen en andere optische instrumenten, apparaten en toestellen, genoemd noch begrepen onder andere posten van hoofdstuk 90',
    90139005: 'Onderdelen en toebehoren van vizierkijkers voor wapens of voor periscopen',
    90139010: 'delen en toebehoren van elementen met vloeibare kristallen "LCD"',
    90139080: 'Onderdelen en toebehoren van lasers en andere machines, apparaten en toestellen niet gespecificeerd of elders begrepen in hoofdstuk 90, n.e.g.',
    90141000: 'Kompassen',
    90142020: 'Traagheidsplatformsystemen voor de navigatie van de lucht- of ruimtevaart (m.u.v. kompassen en radio- en radartoestellen voor navigatiedoeleinden)',
    90142080: 'Instrumenten, apparaten en toestellen voor de navigatie van de lucht- of ruimtevaart (m.u.v. kompassen, traagheidsplatformsystemen en apparaten voor radionavigatie)',
    90148000: 'instrumenten, apparaten en toestellen voor de navigatie (m.u.v. die voor de lucht- of ruimtevaart en m.u.v. kompassen en radio- en radartoestellen voor navigatiedoeleinden)',
    90149000: 'Delen en toebehoren van kompassen en van andere instrumenten, apparaten en toestellen voor de navigatie, n.e.g.',
    90151000: 'afstandmeters',
    90152000: 'theodolieten en tachymeters',
    90153010: 'elektronische waterpassen',
    90153090: 'niet-elektronische waterpassen',
    90154000: 'instrumenten, apparaten en toestellen voor de fotogrammetrie',
    90158020: 'Instrumenten, apparaten en toestellen voor de meteorologie, hydrologie of geofysica (m.u.v. kompassen, afstandmeters, theodolieten, tachymeters "tacheometers", waterpassen en instrumenten, apparaten en toestellen voor de fotogrammetrie)',
    90158040: 'Instrumenten, apparaten en toestellen voor de geodesie, de topografie, het landmeten en de hydrografie (m.u.v. kompassen, afstandmeters, theodolieten, tachymeters "tacheometers", waterpassen en instrumenten, apparaten en toestellen voor de fotogrammetrie)',
    90158080: 'Instrumenten, apparaten en toestellen voor de oceanografie (m.u.v. kompassen, afstandmeters, theodolieten, tachymeters  "tacheometers",  waterpassen en instrumenten, apparaten en toestellen voor de fotogrammetrie)',
    90159000: 'delen en toebehoren van instrumenten, apparaten en toestellen voor de geodesie, voor de topografie, voor het landmeten, voor de fotogrammetrie, voor de hydrografie, voor de oceanografie, voor de hydrologie, voor de meteorologie of voor de geofysica en van afstandmeters, n.e.g.',
    90160010: 'precisiebalansen met een gewichtsgevoeligheid van 50 cg of beter, ook indien met gewichten',
    90160090: 'delen en toebehoren van precisiebalansen met een gewichtsgevoeligheid van 5 cg of beter, n.e.g.',
    90171010: 'afdrukeenheden "zgn. plotters" in de vorm van tekentafels en tekenmachines, ook indien automatisch',
    90171090: 'tekentafels en tekenmachines, ook indien automatisch (m.u.v. afdrukeenheden "zgn. plotters")',
    90172005: 'afdrukeenheden "zgn. plotters" in de vorm van teken- en aftekeninstrumenten',
    90172010: 'Tekeninstrumenten (m.u.v. tekentafels, tekenmachines, afdrukeenheden "zgn. plotters" )',
    90172039: 'aftekeninstrumenten (m.u.v. afdrukeenheden "zgn. plotters" en patroongeneratoren van de soort gebruikt voor het vervaardigen van maskers of systeemmaskers "reticles" op substraten voorzien van een lichtgevoelige laag)',
    90172090: 'rekeninstrumenten "rekenlinialen, rekenschijven" e.d. (m.u.v. rekenmachines)',
    90173000: 'Micrometers en schuifmaten, kalibers e.d. maten (m.u.v. niet-instelbare kalibers van onderverdeling 9031.80)',
    90178010: 'meters en linialen met maatindeling',
    90178090: 'handinstrumenten voor lengtemeting, n.e.g.',
    90179000: 'delen en toebehoren van tekeninstrumenten, aftekeninstrumenten, rekeninstrumenten en handinstrumenten voor lengtemeting, n.e.g.',
    90181100: 'elektrocardiografen',
    90181200: 'ultrasone diagnose-apparaten "zgn. scanners"',
    90181300: 'magnetische resonantie-apparaten',
    90181400: 'scintigrafische apparaten',
    90181910: 'apparaten en toestellen voor elektrodiagnose, voor de gelijktijdige bewaking en analyse van twee of meer fysiologische parameters',
    90181990: 'apparaten en toestellen voor elektrodiagnose, incl. die voor functioneel onderzoek of voor het onderzoek van fysiologische parameters (m.u.v. elektrocardiografen; ultrasone diagnose-apparaten "zgn. scanners"; magnetische resonantie-apparaten; scintigrafische apparaten; apparaten en toestellen voor de gelijktijdige bewaking en analyse van twee of meer fysiologische parameters)',
    90182000: 'apparaten en toestellen werkend met ultraviolette of met infrarode stralen, voor medisch gebruik',
    90183110: 'spuiten met of zonder naalden, voor medisch gebruik, van kunststof',
    90183190: 'spuiten met of zonder naalden, voor medisch gebruik (m.u.v. die van kunststof)',
    90183210: 'buisvormige metalen naalden, voor medisch gebruik',
    90183290: 'hechtnaalden, voor medisch gebruik',
    90183900: 'naalden, katheters, canules e.d. instrumenten, voor medisch gebruik (m.u.v. spuiten, buisvormige metalen naalden en hechtnaalden)',
    90184100: 'boormachines, ook indien met andere tandheelkundige uitrustingsstukken gecombineerd op een gemeenschappelijk voetstuk',
    90184910: 'Slijpsteentjes, schijven, frezen of boren en borstels, van de soort gebruikt in boormachines voor de tandheelkunde',
    90184990: 'Instrumenten, apparaten en toestellen voor de tandheelkunde, n.e.g.',
    90185010: 'niet-optische instrumenten, apparaten en toestellen voor de oogheelkunde, n.e.g.',
    90185090: 'optische instrumenten, apparaten en toestellen voor de oogheelkunde, n.e.g.',
    90189010: 'bloeddrukmeters',
    90189020: 'endoscopen, voor medisch gebruik',
    90189030: 'kunstnieren',
    90189040: 'Apparaten voor diathermie (m.u.v.die werkend met ultraviolette of met infrarode stralen)',
    90189050: 'transfusie- en infusietoestellen voor medische doeleinden',
    90189060: 'narcoseinstrumenten, -apparaten en -toestellen',
    90189075: 'apparaten voor zenuwprikkeling',
    90189084: 'instrumenten, apparaten en toestellen voor de geneeskunde, voor de chirurgie of voor de veeartsenijkunde, n.e.g.',
    90191010: 'elektrische toestellen voor vibromassage',
    90191090: 'toestellen voor mechanische therapie; toestellen voor massage; toestellen voor psychotechniek (m.u.v. elektrische toestellen voor vibromassage)',
    90192000: 'toestellen voor ozontherapie, voor oxygeentherapie, of voor aërosoltherapie; toestellen voor kunstmatige ademhaling en andere therapeutische ademhalingstoestellen',
    90200000: 'Ademhalingstoestellen en gasmaskers (m.u.v. beschermingsmaskers zonder mechanische delen of vervangbare filters, toestellen voor kunstmatige ademhaling en andere therapeutische ademhalingstoestellen)',
    90211010: 'orthopedische artikelen en toestellen',
    90211090: 'artikelen en toestellen voor de behandeling van breuken in het beendergestel',
    90212110: 'kunsttanden van kunststof',
    90212190: 'kunsttanden (m.u.v. die van kunststof)',
    90212900: 'tandprothesen (m.u.v. kunsttanden)',
    90213100: 'gewrichtsprothesen',
    90213910: 'oogprothesen',
    90213990: 'prothesen (m.u.v. gewrichts-, tand- en oogprothesen)',
    90214000: 'hoorapparaten voor hardhorigen (m.u.v. delen en toebehoren)',
    90215000: 'hartstimulatoren (m.u.v. delen en toebehoren)',
    90219010: 'delen en toebehoren van hoorapparaten voor hardhorigen',
    90219090: 'apparatuur voor het verhelpen of verlichten van gebreken of van kwalen, die door de patiënt in de hand wordt gehouden of op andere wijze wordt gedragen, dan wel wordt ingeplant (m.u.v. prothesen, complete hoorapparaten voor hardhorigen, incl. delen en toebehoren en complete hartstimulatoren)',
    90221200: 'tomografen die werken met behulp van een machine voor het automatisch verwerken van gegevens',
    90221300: 'röntgentoestellen voor tandheelkundig gebruik',
    90221400: 'röntgentoestellen voor medisch, chirurgisch of veeartsenijkundig gebruik (m.u.v. die voor tandheelkundig gebruikt en m.u.v. tomografen die werken met behulp van een machine voor het automatisch verwerken van gegevens)',
    90221900: 'röntgentoestellen (m.u.v. die voor medisch, chirurgisch, tandheelkundig of veeartsenijkundig gebruik)',
    90222100: 'toestellen waarbij gebruik wordt gemaakt van alfa-, beta- of gammastralen, ook indien voor medisch, chirurgisch, tandheelkundig of veeartsenijkundig gebruik',
    90222900: 'toestellen waarbij gebruik wordt gemaakt van alfa-, beta- of gammastralen (m.u.v. die voor medisch, chirurgisch, tandheelkundig of veeartsenijkundig gebruik)',
    90223000: 'röntgenbuizen',
    90229020: 'Onderdelen en toebehoren van apparatuur gebaseerd op het gebruik van röntgenstralen',
    90229080: 'Röntgenstraalgeneratoren (m.u.v. röntgenbuizen), spanningsgeneratoren, bedieningslessenaars, schermen, tafels, stoelen e.d. artikelen voor onderzoek of behandeling, alsmede in het algemeen delen en toebehoren van goederen van post 9022, n.e.g.',
    90230010: 'instrumenten, apparaten, toestellen en modellen, bestemd voor het onderwijs in de fysica, de scheikunde of de techniek',
    90230080: 'Instrumenten, apparaten, toestellen en modellen, bestemd voor het geven van demonstraties "b.v. voor onderwijs of voor tentoonstellingen", onbruikbaar voor andere doeleinden (m.u.v. die bestemd voor het onderwijs in de fysica, de scheikunde of de techniek; toestellen voor vliegoefeningen op de grond bedoeld bij post 8805; voorwerpen voor verzamelingen bedoeld bij post 9705; antiquiteiten, > 100 jaar oud bedoeld bij post 9706)',
    90241020: 'Machines, apparaten en toestellen voor universeel gebruik en voor trekproeven van metaal',
    90241040: 'Machines, apparaten en toestellen voor hardheidsproeven van metaal',
    90241080: 'Machines, apparaten en toestellen voor onderzoek van metaal (m.u.v. die voor universeel gebruik en voor trekproeven en m.u.v. die voor hardheidsproeven)',
    90248000: 'Machines, apparaten en toestellen voor onderzoek van de mechanische eigenschappen van materialen (m.u.v. metaal)',
    90249000: 'delen en toebehoren van machines, apparaten en toestellen voor onderzoek van de mechanische eigenschappen van materialen, n.e.g.',
    90251120: 'Koortsthermometers, gevuld met vloeistof, met rechtstreekse aflezing',
    90251180: 'Thermometers en pyrometers, gevuld met vloeistof, met rechtstreekse aflezing (m.u.v. die gecombineerd met andere instrumenten en m.u.v. koortsthermometers)',
    90251900: 'thermometers en pyrometers (m.u.v. die gecombineerd met andere instrumenten en thermometers gevuld met vloeistof, met rechtstreekse aflezing)',
    90258020: 'barometers (m.u.v. die bestemd voor burgerluchtvaartuigen, bedoeld bij onderverdeling 9025.80.15, en die gecombineerd met andere instrumenten)',
    90258040: 'Elektronische densimeters, areometers, vochtwegers e.d. drijvende instrumenten, hygrometers en psychrometers, alsmede combinaties van deze instrumenten onderling of met thermometers of barometers',
    90258080: 'Niet-elektronische densimeters, areometers, vochtwegers e.d. drijvende instrumenten, hygrometers en psychrometers, alsmede combinaties van deze instrumenten onderling of met thermometers of barometers',
    90259000: 'Delen en toebehoren van densimeters, areometers, vochtwegers e.d. drijvende instrumenten, thermometers, pyrometers, barometers, hygrometers en psychrometers, n.e.g.',
    90261021: 'Elektronische doorstromingsmeters voor vloeistoffen (m.u.v. tellers en regelmechanismen)',
    90261029: 'Elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van de doorstroming of het peil van vloeistoffen (m.u.v. doorstromingsmeters, tellers en regelmechanismen)',
    90261081: 'Niet-elektronische doorstromingsmeters voor vloeistoffen (m.u.v. tellers en regelmechanismen)',
    90261089: 'Niet-elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van de doorstroming of het peil van vloeistoffen (m.u.v. doorstromingsmeters, tellers en regelmechanismen)',
    90262020: 'Elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van de druk van vloeistoffen of van gassen (m.u.v. regelmechanismen)',
    90262040: 'Manometers met spiralen of membranen van metaal',
    90262080: 'Niet-elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van de druk van vloeistoffen of van gassen (m.u.v. manometers met spiralen of membranen van metaal en regelmechanismen)',
    90268020: 'Elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van variabele karakteristieken van vloeistoffen of van gassen, n.e.g.',
    90268080: 'Niet-elektronische instrumenten, apparaten en toestellen voor het meten of het verifiëren van variabele karakteristieken van vloeistoffen of van gassen, n.e.g.',
    90269000: 'delen en toebehoren van instrumenten, apparaten en toestellen voor het meten of het verifiëren van de doorstroming, het peil, de druk of andere variabele karakteristieken van vloeistoffen of van gassen, n.e.g.',
    90271010: 'elektronische analysetoestellen voor gassen of voor rook',
    90271090: 'niet-elektronische analysetoestellen voor gassen of voor rook',
    90272000: 'chromatografen en elektroforeseapparaten en -toestellen',
    90273000: 'spectrometers, spectrofotometers en spectrografen, die gebruik maken van optische stralen "UV, zichtbare, IR"',
    90275000: 'instrumenten, apparaten en toestellen die gebruik maken van optische stralen "UV, zichtbare, IR" (m.u.v. spectrometers, spectrofotometers en spectrografen en analysetoestellen voor gassen of voor rook)',
    90278005: 'belichtingsmeters',
    90278020: 'pH- en rH-meters en andere toestellen voor het meten van het geleidingsvermogen',
    90278080: 'instrumenten, apparaten en toestellen voor natuurkundige of scheikundige analyse, voor het meten van oppervlaktespanning e.d., of voor het meten of het verifiëren van hoeveelheden warmte of geluid, n.e.g.',
    90279000: 'microtomen; delen en toebehoren van instrumenten, apparaten en toestellen voor natuurkundige of scheikundige analyse, voor het meten of het verifiëren van de viscositeit, de poreusheid, de uitzetting, de oppervlaktespanning e.d. of voor het meten of het verifiëren van hoeveelheden warmte, geluid of licht, en van microtomen n.e.g.',
    90281000: 'gasmeters, incl. standaardmeters',
    90282000: 'vloeistofmeters, incl. standaardmeters',
    90283011: 'elektriciteitsmeters voor eenfasewisselstroom, incl. standaardmeters',
    90283019: 'elektriciteitsmeters voor meerfasenwisselstroom, incl. standaardmeters',
    90283090: 'elektriciteitsmeters voor gelijkstroom, incl. standaardmeters',
    90289010: 'delen en toebehoren van elektriciteitsmeters, n.e.g.',
    90289090: 'delen en toebehoren van gasmeters en vloeistofmeters, n.e.g.',
    90291000: 'Toerentellers, productietellers, taximeters, kilometertellers, schredentellers e.d. (m.u.v. verbruiksmeters en productiemeters voor gassen, voor vloeistoffen of voor elektriciteit)',
    90292031: 'snelheidsmeters voor voertuigen voor vervoer te lande',
    90292038: 'Snelheidsmeters en tachometers (m.u.v. snelheidsmeters voor voertuigen voor vervoer te lande)',
    90292090: 'stroboscopen',
    90299000: 'Delen en toebehoren van toerentellers, productietellers, taximeters, kilometertellers, schredentellers e.d., van snelheidsmeters en tachometers en van stroboscopen, n.e.g.',
    90301000: 'Meet- en detectietoestellen en -instrumenten voor ioniserende stralen',
    90302000: 'Oscilloscopen en oscillografen',
    90303100: 'Multimeters (universeelmeters) voor het meten of het verifiëren van spanning, stroomsterkte, weerstand of vermogen, zonder registreerinrichting',
    90303200: 'Multimeters "universeelmeters" met registreerinrichting',
    90303320: 'Instrumenten, apparaten en toestellen voor het meten van weerstand zonder registreerinrichting',
    90303370: 'instrumenten en apparaten voor het meten of verifiëren van spanning, stroomsterkte of vermogen, zonder registreerinrichting (m.u.v. multimeters, oscilloscopen en oscillografen)',
    90303900: 'Instrumenten, apparaten en toestellen voor het meten of verifiëren van spanning, stroomsterkte, weerstand of vermogen, met registreerinrichting (m.u.v. multimeters "universeelmeters", oscilloscopen en oscillografen)',
    90304000: 'Instrumenten, apparaten en toestellen voor het meten of het verifiëren van elektrische grootheden, speciaal vervaardigd voor de telecommunicatietechniek, b.v. overspraakmeters, versterkingsmeters, vervormingsmeters, ruismeters',
    90308200: 'instrumenten, apparaten en toestellen voor het meten of het verifiëren van halfgeleiderschijven of van halfgeleiderschakelingen',
    90308400: 'Instrumenten, apparaten en toestellen voor het meten of het verifiëren van elektrische grootheden, met registreerinrichting (m.u.v. die speciaal vervaardigd voor de telecommunicatietechniek, multimeters "universeelmeters", oscilloscopen en oscillografen, en m.u.v. die voor het meten of het verifiëren van halfgeleiderschijven of van halfgeleiderschakelingen)',
    90308900: 'Instrumenten, apparaten en toestellen voor het meten of het verifiëren van elektrische grootheden, zonder registreerinrichting, n.e.g.',
    90309000: 'Onderdelen en toebehoren van instrumenten, apparaten en toestellen voor het meten of het verifiëren van elektrische grootheden of voor detectie van  ioniserende straling, n.e.g.',
    90311000: 'machines voor het uitbalanceren van mechanische delen',
    90312000: 'proefbanken voor motoren, generatoren, pompen, enz.',
    90314100: 'optische instrumenten, apparaten en toestellen voor het verifiëren van halfgeleiderschijven of van halfgeleiderschakelingen of voor het verifiëren van fotomaskers gebruikt in de fabricage van halfgeleiderschakelingen',
    90314910: 'profielprojectietoestellen',
    90314990: 'optische meet- en verificatie-instrumenten, -apparaten, -toestellen of -machines, genoemd noch begrepen onder andere posten van hoofdstuk 90',
    90318020: 'Instrumenten, apparaten en machines voor het meten of het verifiëren van geometrische grootheden, n.e.g. in Ch 90',
    90318080: 'Niet-optische instrumenten, apparaten en machines voor het meten of het verifiëren , n.e.g. in Ch 90',
    90319000: 'Onderdelen en toebehoren van instrumenten, apparaten en machines voor het meten of het verifiëren , n.e.g.',
    90321020: 'Elektronische thermostaten',
    90321080: 'thermostaten (m.u.v. elektronische)',
    90322000: 'Manostaten (pressostaten) (m.u.v. kranen bedoeld bij post 8481)',
    90328100: 'Hydraulische of pneumatische automatische regelaars (m.u.v. manostaten (pressostaten) en kranen bedoeld bij post 8481)',
    90328900: 'Automatische regelaars (m.u.v. hydraulische of pneumatische regelaars, manostaten (pressostaten), thermostaten en kranen bedoeld bij post 8481)',
    90329000: 'Delen en toebehoren van automatische regelaars, n.e.g.',
    90330010: 'Luminescentiedioden (led) backlightmodules zijnde verlichtingsbronnen die bestaan uit één of meer leds en één of meer verbindingsstukken, die zijn aangebracht op een gedrukte schakeling of ander soortgelijk substraat, en andere passieve elementen, al dan niet in combinatie met optische onderdelen of beschermende dioden, en gebruikt als backlight van Liquid Crystal Displays (LCD\'s) van toestellen behorend tot Hoofdstuk 90',
    90330090: 'Onderdelen en toebehoren van machines, toestellen, instrumenten of andere apparatuur in Ch 90, n.e.g.',
    91011100: 'polshorloges met kast van edel metaal of van metaal geplateerd met edel metaal, ook indien met ingebouwd stophorloge, elektrisch werkend, uitsluitend met mechanische aanwijzing (m.u.v. die met een bodem van staal)',
    91011900: 'polshorloges met kast van edel metaal of van metaal geplateerd met edel metaal, ook indien met ingebouwd stophorloge, elektrisch werkend, met opto-elektronische aanwijzing of met gecombineerde mechanische en opto-elektronische aanwijzing (m.u.v. die met een bodem van staal)',
    91012100: 'polshorloges met kast van edel metaal of van metaal geplateerd met edel metaal, met automatisch opwindsysteem (m.u.v. die met een bodem van staal)',
    91012900: 'polshorloges met kast van edel metaal of van metaal geplateerd met edel metaal, uitsluitend opwindbaar met de hand (m.u.v. die met een bodem van staal)',
    91019100: 'zakhorloges e.d. horloges, incl. stophorloges, met kast van edel metaal of van metaal geplateerd met edel metaal, elektrisch werkend (m.u.v. die met een bodem van staal en m.u.v. polshorloges)',
    91019900: 'zakhorloges e.d. horloges, incl. stophorloges, met kast van edel metaal of van metaal geplateerd met edel metaal, opwindbaar met de hand of met automatisch opwindsysteem (m.u.v. die met een bodem van staal en m.u.v. polshorloges)',
    91021100: 'polshorloges, ook indien met ingebouwd stophorloge, elektrisch werkend, uitsluitend met mechanische aanwijzing (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal)',
    91021200: 'polshorloges, ook indien met ingebouwd stophorloge, elektrisch werkend, uitsluitend met opto-elektronische aanwijzing (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal)',
    91021900: 'polshorloges, ook indien met ingebouwd stophorloge, elektrisch werkend, met gecombineerde mechanische en opto-elektronische aanwijzing (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal)',
    91022100: 'polshorloges, ook indien met ingebouwd stophorloge, met automatisch opwindsysteem (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal)',
    91022900: 'polshorloges, ook indien met ingebouwd stophorloge, uitsluitend opwindbaar met de hand (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal)',
    91029100: 'zakhorloges e.d. horloges, incl. stophorloges, elektrisch werkend (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal en m.u.v. polshorloges)',
    91029900: 'zakhorloges e.d. horloges, incl. stophorloges, opwindbaar met de hand of met automatisch opwindsysteem (m.u.v. die met kast van edel metaal of van metaal geplateerd met edel metaal en m.u.v. polshorloges)',
    91031000: 'wekkers en klokjes, met horloge-uurwerk, elektrisch werkend (m.u.v. polshorloges, zakhorloges e.d. horloges, bedoeld bij post 9101 of 9102, en klokjes voor instrumentenborden e.d. klokjes, bedoeld bij post 9104)',
    91039000: 'wekkers en klokjes, met horloge-uurwerk (niet elektrisch werkend en m.u.v. polshorloges, zakhorloges e.d. horloges, bedoeld bij post 9101 of 9102, en klokjes voor instrumentenborden e.d. klokjes, bedoeld bij post 9104)',
    91040000: 'Klokjes voor instrumentenborden e.d. klokjes, voor automobielen voor luchtvaartuigen, voor vaartuigen of voor andere vervoermiddelen',
    91051100: 'wekkers, elektrisch werkend',
    91051900: 'wekkers (niet elektrisch werkend)',
    91052100: 'wandklokken, elektrisch werkend',
    91052900: 'wandklokken (niet elektrisch werkend)',
    91059100: 'pendules, klokken e.d. artikelen, elektrisch werkend (m.u.v. polshorloges, zakhorloges e.d. horloges, bedoeld bij post 9101 of 9102, wekkers en klokjes, met horloge-uurwerk, bedoeld bij post 9103, klokjes voor instrumentenborden e.d. klokjes, bedoeld bij post 9104, wekkers en wandklokken)',
    91059900: 'Pendules, klokken e.d. artikelen (niet elektrisch werkend en m.u.v. polshorloges, zakhorloges e.d. horloges, bedoeld bij post 9101 of 9102, wekkers en klokjes, met horloge-uurwerk, bedoeld bij post 9103, klokjes voor instrumentenborden e.d. klokjes, bedoeld bij post 9104, wekkers en wandklokken)',
    91061000: 'Controleklokken, uurstempels en tijdregistreertoestellen',
    91069000: 'Tijdcontroleapparaten en tijdmeters, met uurwerk of met synchroonmotor (m.u.v. uurwerken bedoeld bij de posten 9101 tot en met 9105, controleklokken, uurstempels, tijdregistreertoestellen)',
    91070000: 'schakelklokken en andere toestellen, bestemd om een mechanisme op een bepaalde tijd te doen werken, met uurwerk of met synchroonmotor',
    91081100: 'horloge-uurwerken, compleet en gemonteerd, elektrisch werkend, uitsluitend met mechanische aanwijzing of met een inrichting waarop een mechanische aanwijzing kan worden aangesloten',
    91081200: 'horloge-uurwerken, compleet en gemonteerd, elektrisch werkend, uitsluitend met opto-elektronische aanwijzing',
    91081900: 'horloge-uurwerken, compleet en gemonteerd, elektrisch werkend, met een gecombineerde mechanische "ook indien zonder wijzerplaat en wijzers" en opto-elektronische aanwijzing',
    91082000: 'horloge-uurwerken, compleet en gemonteerd, met automatisch opwindmechanisme',
    91089000: 'horloge-uurwerken, compleet en gemonteerd, uitsluitend opwindbaar met de hand',
    91091000: 'uurwerken, compleet en gemonteerd, elektrisch werkend (m.u.v. horloge-uurwerken)',
    91099000: 'Uurwerken, compleet en gemonteerd, niet-elektrisch werkend (m.u.v. horloge-uurwerken)',
    91101110: 'complete horloge-uurwerken, ongemonteerd of gedeeltelijk gemonteerd "stellen onderdelen", met van een spiraalveer voorziene onrust',
    91101190: 'complete horloge-uurwerken, ongemonteerd of gedeeltelijk gemonteerd "stellen onderdelen" (m.u.v. die met van een spiraalveer voorziene onrust)',
    91101200: 'niet-complete horloge-uurwerken, gemonteerd',
    91101900: 'onvolledige, onafgewerkte horloge-uurwerken "ébauches"',
    91109000: 'complete uurwerken, ongemonteerd of gedeeltelijk gemonteerd "stellen onderdelen", alsmede niet-complete uurwerken, gemonteerd en onvolledige, onafgewerkte uurwerken "ébauches" (m.u.v. horloge-uurwerken)',
    91111000: 'kasten van polshorloges, zakhorloges e.d. horloges, incl. stophorloges, bedoeld bij post 9101 of 9102, van edel metaal of van metaal geplateerd met edel metaal',
    91112000: 'kasten van polshorloges, zakhorloges e.d. horloges, incl. stophorloges, bedoeld bij post 9101 of 9102, van onedel metaal, ook indien verguld of verzilverd',
    91118000: 'kasten van polshorloges, zakhorloges e.d. horloges, incl. stophorloges, bedoeld bij post 9101 of 9102 (m.u.v. die van edel metaal, van metaal geplateerd met edel metaal of van onedel metaal)',
    91119000: 'delen van kasten van polshorloges, zakhorloges e.d. horloges, incl. stophorloges, bedoeld bij post 9101 of 9102, n.e.g.',
    91122000: 'kasten voor klokken, voor pendules, enz. (m.u.v. die voor polshorloges, zakhorloges en dergelijke horloges, bedoeld bij de posten 9101 of 9102)',
    91129000: 'delen van kasten voor klokken, voor pendules, enz., n.e.g. (m.u.v. die van kasten voor polshorloges, zakhorloges e.d. horloges, bedoeld bij post 9101 of 9102)',
    91131010: 'horlogebanden en delen daarvan, van edel metaal, n.e.g.',
    91131090: 'horlogebanden en delen daarvan, van metaal geplateerd met edel metaal, n.e.g.',
    91132000: 'horlogebanden en delen daarvan, van onedel metaal, ook indien verguld of verzilverd, n.e.g.',
    91139000: 'horlogebanden en delen daarvan, n.e.g.',
    91141000: 'veren, incl. onrustveren, van uurwerken',
    91143000: 'wijzerplaten van uurwerken',
    91144000: 'platen en bruggen van uurwerken',
    91149000: 'delen van uurwerken, n.e.g.',
    92011010: 'piano\'s, staande, nieuw',
    92011090: 'piano\'s, staande, gebruikt',
    92012000: 'vleugelpiano\'s',
    92019000: 'klavecimbels en andere snaarinstrumenten met klavier (m.u.v. piano\'s)',
    92021010: 'violen (m.u.v. altviolen en basviolen)',
    92021090: 'strijkinstrumenten (m.u.v. violen andere dan altviolen en basviolen)',
    92029030: 'gitaren',
    92029080: 'mandolines, citers en andere snaarinstrumenten (m.u.v. die met klavier; strijkinstrumenten; gitaren)',
    92051000: 'blaasinstrumenten, zgn. koper',
    92059010: 'accordeons e.d. instrumenten',
    92059030: 'mondharmonica\'s',
    92059050: 'orgels met pijpen en klavier; harmoniums e.d. instrumenten met klavier en met vrije metalen tongen (m.u.v. snaarinstrumenten)',
    92059090: 'Muziekinstrumenten waarbij de klanken worden voortgebracht door luchtstromen (m.u.v. zogenaamd koper, accordeons e.d. instrumenten, mondharmonica\'s, orgels met pijpen en klavier, harmoniums e.d. instrumenten met klavier en met vrije metalen tongen)',
    92060000: 'Slaginstrumenten, b.v. trommels, Turkse trommen, xylofonen, cimbalen, castagnetten, maracas',
    92071010: 'orgels met klavier, waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt',
    92071030: 'digitale piano\'s met klavier',
    92071050: 'synthesizers met klavier',
    92071080: 'muziekinstrumenten met klavier, waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt (m.u.v. accordeons, orgels, digitale piano\'s en synthesizers)',
    92079010: 'gitaren, waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt',
    92079090: 'accordeons en instrumenten zonder klavier, waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt (m.u.v. gitaren)',
    92081000: 'muziekdozen',
    92089000: 'orchestrions, straatorgels, zingende vogels, zingende zagen, n.e.g.; lokfluitjes e.d. lokinstrumenten; fluitjes, toeters en andere met de mond te bespelen roep- en signaalinstrumenten',
    92093000: 'snaren voor muziekinstrumenten',
    92099100: 'delen en toebehoren van piano\'s, n.e.g.',
    92099200: 'delen en toebehoren van snaarinstrumenten zonder klavier, n.e.g. (m.u.v. snaren en m.u.v. muziekinstrumenten waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt)',
    92099400: 'delen en toebehoren van muziekinstrumenten waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt, n.e.g.',
    92099920: 'delen en toebehoren van klarinetten, van trompetten, doedelzakken, orgels met pijpen en klavier, van harmoniums en van dergelijke instrumenten met klavier en met vrije metalen tongen en andere muziekinstrumenten van post 9205, n.e.g.',
    92099940: 'metronomen en diapasons',
    92099950: 'mechanieken voor muziekdozen',
    92099970: 'delen en toebehoren "b.v. kaarten, schijven en rollen voor mechanisch te bespelen toestellen" van accordeons e.d. instrumenten, mondharmonica\'s, slaginstrumenten, muziekinstrumenten waarvan het geluid elektrisch wordt voortgebracht of elektrisch moet worden versterkt, muziekdozen, orchestrions, straatorgels, zingende vogels en dergelijke muziekinstrumenten, n.e.g. (m.u.v. metronomen, diapasons, mechanieken voor muziekdozen, snaren en m.u.v. delen en toebehoren voor piano\'s, snaarinstrumenten zonder klavier, orgels met pijpen en klavier, harmoniums e.d. instrumenten met klavier en met vrije metalen tongen en m.u.v. blaasinstrumenten)',
    93011000: 'artilleriestukken (b.v. kanonnen, houwitsers en mortieren)',
    93012000: 'raketwerpers; vlammenwerpers; granaatwerpers; torpedolanceerbuizen en dergelijke lanceerbuizen',
    93019000: 'oorlogswapens, incl. machinepistolen (m.u.v. artilleriestukken, raketwerpers, vlammenwerpers, granaatwerpers, torpedolanceerbuizen en dergelijke lanceerbuizen, revolvers en pistolen bedoeld bij post 9302, en blanke wapens bedoeld bij post 9307)',
    93020000: 'revolvers en pistolen (m.u.v. die bedoeld bij de posten 9302 en 9304; machinepistolen voor oorlogsdoeleinden)',
    93031000: 'vuurwapens die alleen via de loop kunnen worden geladen "vuurwapens voor zwart kruit", noch ontworpen, noch in staat om patronen af te vuren',
    93032010: 'geweren en karabijnen, voor het jagen of voor de schietsport, met één gladde loop (m.u.v. vuurwapens die alleen via de loop kunnen worden geladen en geweren werkend met veer, perslucht of gas)',
    93032095: 'geweren en karabijnen, voor het jagen of voor de schietsport, met één of twee gladde lopen en één getrokken loop, alsmede tweeloopsgeweren',
    93033000: 'Geweren en karabijnen, voor het jagen of voor de schietsport, met één getrokken loop of met twee getrokken lopen (m.u.v. geweren, karabijnen en pistolen werkend met veer, perslucht of gas)',
    93039000: 'vuurwapens e.d. toestellen, waarbij de explosieve kracht van kruit benut wordt (m.u.v. geweren en karabijnen voor het jagen of voor de schietsport, revolvers en pistolen bedoeld bij post 9302; oorlogswapens)',
    93040000: 'geweren, karabijnen en pistolen werkend met veer, perslucht of gas en andere niet-vuurwapens (m.u.v. sabels, degens, bajonetten, lansen en andere blanke wapens bedoeld bij post 9307)',
    93051000: 'delen en toebehoren, van revolvers of van pistolen, n.e.g.',
    93052000: 'delen en toebehoren, van geweren of van karabijnen, voor het jagen of voor de schietsport, bedoeld bij post 9303, n.e.g.',
    93059100: 'delen en toebehoren, van oorlogswapens bedoeld bij post 9301, n.e.g.',
    93059900: 'delen en toebehoren, van wapens en van dergelijke toestellen, bedoeld bij de posten 9303 of 9304, n.e.g. (m.u.v. die van geweren of van karabijnen, bedoeld bij post 9303)',
    93062100: 'patronen voor geweren of voor karabijnen met gladde loop',
    93062900: 'Delen van patronen voor geweren of voor karabijnen met gladde loop; loden kogeltjes voor met perslucht werkende geweren en karabijnen',
    93063010: 'patronen en delen daarvan, voor revolvers en voor pistolen bedoeld bij post 9302 en voor machinepistolen bedoeld bij post 9301',
    93063030: 'patronen en delen daarvan, voor oorlogswapens',
    93063090: 'Patronen en delen daarvan, n.e.g.',
    93069010: 'bommen, granaten, torpedo\'s, mijnen, raketten, en andere munitie en projectielen, voor oorlogsdoeleinden, alsmede delen daarvan, n.e.g. (m.u.v. patronen en delen daarvan)',
    93069090: 'munitie en projectielen, alsmede delen daarvan, n.e.g. (m.u.v. die bestemd voor voor oorlogsdoeleinden)',
    93070000: 'sabels, degens, bajonetten, lansen en andere blanke wapens, alsmede delen daarvan en scheden daarvan (m.u.v. die van edele metalen of van metalen geplateerd met edele metalen; wapens voorzien van knoppen, voor schermzalen; jachtmessen en -dolken, kampeermessen en ander messenmakerswerk bedoeld bij post 8211; koppels e.d. artikelen van leder of van textielstoffen; degenkwasten)',
    94011000: 'Zitmeubelen van de soort gebruikt in luchtvaartuigen',
    94012000: 'zitmeubelen van de soort gebruikt in automobielen',
    94013000: 'Draaistoelen, in de hoogte verstelbaar ( m.u.v. die voor geneeskundig, voor chirurgisch of voor tandheelkundig gebruik en m.u.v. kappersstoelen)',
    94014000: 'zitmeubelen die tot bed kunnen worden omgevormd (m.u.v. tuin- of campingmeubelen en meubelen voor geneeskundig, voor chirurgisch of voor tandheelkundig gebruik)',
    94015200: 'Zitmeubelen van bamboe',
    94015300: 'Zitmeubelen van rotan',
    94015900: 'zitmeubelen van teen of van dergelijke stoffen (m.u.v. van bamboe of van rotting)',
    94016100: 'zitmeubelen, met onderstel van hout, opgevuld (m.u.v. die welke tot bed kunnen worden omgevormd)',
    94016900: 'zitmeubelen, met onderstel van hout, zonder opvulling',
    94017100: 'zitmeubelen, met onderstel van metaal, opgevuld (m.u.v. zitmeubelen van de soort gebruikt in luchtvaartuigen of in automobielen, in de hoogte verstelbare draaistoelen en meubelen voor geneeskundig, voor chirurgisch of voor tandheelkundig gebruik)',
    94017900: 'zitmeubelen, met onderstel van metaal, zonder opvulling (m.u.v. in de hoogte verstelbare draaistoelen en meubelen voor geneeskundig, voor chirurgisch of voor tandheelkundig gebruik)',
    94018000: 'zitmeubelen, n.e.g.',
    94019010: 'delen van zitmeubelen van de soort gebruikt in luchtvaartuigen, n.e.g.',
    94019030: 'delen van zitmeubelen, van hout, n.e.g.',
    94019080: 'delen van zitmeubelen, n.e.g. (m.u.v. die van hout)',
    94021000: 'tandartsstoelen, kappersstoelen e.d. stoelen, met draai-, hef- en verstelinrichting; delen van genoemde artikelen, n.e.g.',
    94029000: 'operatietafels, onderzoektafels, verstelbare bedden voor klinisch gebruik en andere meubelen voor geneeskundig, voor chirurgisch of voor tandheelkundig of voor veeartsenijkundig gebruik (m.u.v. tandartsstoelen en andere zitmeubelen; speciale tafels voor röntgenologische onderzoeken en draagbaren of brancards, ook indien op wielen)',
    94031051: 'bureaus van de soort gebruikt in kantoren, met onderstel van metaal',
    94031058: 'Meubelen van metaal, van de soort gebruikt in kantoren, met een hoogte van <= 80 cm (m.u.v. bureaus en tekentafels uitgerust met instrumenten voor het tekenen bedoeld bij post 9017)',
    94031091: 'kasten van metaal, met deuren, met rolluiken of met kleppen, van de soort gebruikt in kantoren, met een hoogte van > 80 cm',
    94031093: 'kasten met laden en kaartkasten, van metaal, van de soort gebruikt in kantoren, met een hoogte van > 80 cm',
    94031098: 'Meubelen van metaal, van de soort gebruikt in kantoren, met een hoogte van > 80 cm (m.u.v. tekentafels uitgerust met instrumenten voor het tekenen bedoeld bij post 9017, kasten met deuren, met rolluiken, met kleppen of met laden, kaartkasten en zitmeubelen)',
    94032020: 'Bedden van metaal (m.u.v. verstelbare bedden voor klinisch gebruik)',
    94032080: 'Meubelen van metaal (m.u.v. die van de soort gebruikt in kantoren en die voor geneeskundig, voor chirurgisch, voor tandheelkundig of voor veeartsenijkundig gebruik en m.u.v. bedden en zitmeubelen)',
    94033011: 'bureaus van de soort gebruikt in kantoren, met onderstel van hout',
    94033019: 'meubelen van hout, van de soort gebruikt in kantoren, met een hoogte van <= 80 cm (m.u.v. bureaus en zitmeubelen)',
    94033091: 'kasten van hout, van de soort gebruikt in kantoren, met een hoogte van > 80 cm',
    94033099: 'meubelen van hout, van de soort gebruikt in kantoren, met een hoogte van > 80 cm (m.u.v. kasten)',
    94034010: 'elementen voor aanbouwkeukens',
    94034090: 'meubelen van hout, van de soort gebruikt in keukens (m.u.v. zitmeubelen en elementen voor aanbouwkeukens)',
    94035000: 'meubelen van hout, van de soort gebruikt in slaapkamers (m.u.v. zitmeubelen)',
    94036010: 'meubelen van hout, van de soort gebruikt in zit- en eetkamers (m.u.v. zitmeubelen)',
    94036030: 'meubelen van hout, van de soort gebruikt in winkels (m.u.v. zitmeubelen)',
    94036090: 'meubelen van hout (m.u.v. die van de soort gebruikt in kantoren, winkels, keukens, slaapkamers, zit- en eetkamers en m.u.v. zitmeubelen)',
    94037000: 'Meubelen van kunststof (m.u.v. die voor geneeskundig, voor chirurgisch, voor tandheelkundig of voor veeartsenijkundig gebruik en m.u.v. zitmeubelen)',
    94038200: 'Meubelen van bamboe  (m.u.v. zitmeubelen en meubelen voor geneeskundig, voor chirurgisch, voor tandheelkundig of voor veeartsenijkundig gebruik)',
    94038300: 'Meubelen van rotan  (m.u.v. zitmeubelen en meubelen voor geneeskundig, voor chirurgisch, voor tandheelkundig of voor veeartsenijkundig gebruik)',
    94038900: 'Meubelen van teen of van dergelijke stoffen (m.u.v. die van bamboe of van rotting, van metaal, van hout en van kunststof en m.u.v. zitmeubelen  en meubelen voor geneeskundig, voor chirurgisch, voor tandheelkundig of voor veeartsenijkundig gebruik)',
    94039010: 'delen van meubelen (m.u.v. zitmeubelen), van metaal, n.e.g.',
    94039030: 'delen van meubelen (m.u.v. zitmeubelen), van hout, n.e.g.',
    94039090: 'delen van meubelen (m.u.v. zitmeubelen en m.u.v. die van metaal of van hout), n.e.g.',
    94041000: 'springbakken, spiraalmatrassen e.d. in een lijst of in een raam gevatte matrassen (m.u.v. metalen binnenveringen voor zitmeubelen)',
    94042110: 'matrassen van rubber met celstructuur, ook indien overtrokken',
    94042190: 'matrassen van kunststof met celstructuur, ook indien overtrokken',
    94042910: 'matrassen met metalen binnenvering',
    94042990: 'matrassen, opgevuld met ongeacht welk materiaal (m.u.v. die van rubber of van kunststof met celstructuur, en matrassen en kussens bestemd om met lucht of met water te worden gevuld)',
    94043000: 'slaapzakken, ook indien met elektrische verwarmingselementen',
    94049010: 'artikelen voor bedden e.d., gevuld met veren of dons (m.u.v. matrassen en slaapzakken)',
    94049090: 'artikelen voor bedden e.d., met binnenvering of opgevuld met ongeacht welk materiaal, dan wel van rubber of van kunststof met celstructuur, ook indien overtrokken (m.u.v. die gevuld met veren of dons en m.u.v. slaapzakken, springbakken, spiraalmatrassen, matrassen en kussens bestemd om met lucht of met water te worden gevuld, andere matrassen, dekens en bedlinnen)',
    94051021: 'elektrische plafond- en wandlampen, van kunststof, van de soort gebruikt voor gloeilampen en gloeibuizen (m.u.v. die bestemd voor burgerluchtvaartuigen bedoeld bij onderverdeling 9405.10.10)',
    94051040: 'Luchters en andere elektrische plafond- en wandlampen, van kunststof of van keramische stoffen (m.u.v. die van kunststof van de soort gebruikt voor gloeilampen en-buizen)',
    94051050: 'luchters en andere elektrische plafond- en wandlampen, van glas',
    94051091: 'elektrische plafond- en wandlampen, van de soort gebruikt voor gloeilampen en gloeibuizen (m.u.v. die van onedel metaal, bestemd voor burgerluchtvaartuigen bedoeld bij onderverdeling 9405.10.10, van kunststof, van keramische stoffen of van glas)',
    94051098: 'Luchters en andere elektrische plafond- en wandlampen, van de soort gebruikt voor gasontladingslampen en -buizen (m.u.v. die van kunststof, van keramische stoffen of van glas)',
    94052011: 'elektrische tafel-, bed- en bureaulampen, alsmede elektrische staande lampen, van kunststof, van de soort gebruikt voor gloeilampen en gloeibuizen',
    94052040: 'Elektrische tafel-, bed- en bureaulampen, alsmede elektrische staande lampen, van kunststof of van keramische stoffen (m.u.v. die van kunststof van de soort gebruikt voor gloeilampen en gloeibuizen)',
    94052050: 'elektrische tafel-, bed- en bureaulampen, alsmede elektrische staande lampen, van glas',
    94052091: 'elektrische tafel-, bed- en bureaulampen, alsmede elektrische staande lampen, van de soort gebruikt voor gloeilampen en gloeibuizen (m.u.v. die van kunststof, van keramische stoffen of van glas)',
    94052099: 'elektrische tafel-, bed- en bureaulampen, alsmede elektrische staande lampen, van de soort gebruikt voor gasontladingslampen en -buizen (m.u.v. die van kunststof, van keramische stoffen of van glas)',
    94053000: 'elektrische guirlandes, van de soort gebruikt voor kerstboomverlichting',
    94054010: 'elektrische zoeklichten en schijnwerpers (m.u.v. die van de soort gebruikt voor luchtvaartuigen, rijwielen of motorvoertuigen en m.u.v. "sealed beam"-lampen)',
    94054031: 'elektrische verlichtingstoestellen, van kunststof, van de soort gebruikt voor gloeilampen en gloeibuizen, n.e.g.',
    94054035: 'elektrische verlichtingstoestellen, van kunststof, van de soort gebruikt voor fluorescentiebuizen, n.e.g.',
    94054039: 'elektrische verlichtingstoestellen, van kunststof, n.e.g.',
    94054091: 'elektrische verlichtingstoestellen (m.u.v. die van kunststof), van de soort gebruikt voor gloeilampen en gloeibuizen, n.e.g.',
    94054095: 'elektrische verlichtingstoestellen (m.u.v. die van kunststof), van de soort gebruikt voor fluorescentiebuizen, n.e.g.',
    94054099: 'elektrische verlichtingstoestellen (m.u.v. die van kunststof), n.e.g.',
    94055000: 'niet-elektrische verlichtingstoestellen',
    94056020: 'Lichtreclames, verlichte aanwijzingsborden e.d. artikelen, voorzien van een vast aangebrachte lichtbron, van kunststof',
    94056080: 'Lichtreclames, verlichte aanwijzingsborden e.d. artikelen, voorzien van een vast aangebrachte lichtbron (m.u.v. die van kunststof)',
    94059110: 'Uitrustingsstukken voor elektrische verlichtingsartikelen van glas (m.u.v. die voor zoeklichten en schijnwerpers)',
    94059190: 'delen van verlichtingstoestellen, lichtreclames, verlichte aanwijzingsborden e.d. artikelen, van glas, n.e.g.',
    94059200: 'Delen van elektrische plafond- en wandlampen van lichtreclames, van verlichte aanwijzingsborden e.d. artikelen, van kunststof, n.e.g.',
    94059900: 'Delen van elektrische plafond- en wandlampen van lichtreclames, van verlichte aanwijzingsborden e.d., n.e.g.',
    94061000: 'Geprefabriceerde bouwwerken gemaakt van hout, al dan niet volledig of reeds gemonteerd',
    94069010: 'Stacaravans, mobiele bungalows',
    94069031: 'Broeikassen, geprefabriceerd, al dan niet volledig of reeds gemonteerd, uitsluitend of hoofdzakelijk gemaakt van ijzer of staal',
    94069038: 'Bouwwerken, geprefabriceerd, al dan niet volledig of reeds gemonteerd, uitsluitend of hoofdzakelijk gemaakt van ijzer of staal (m.u.v. stacaravans, mobiele bungalows en kassen)',
    94069090: 'Geprefabriceerde bouwwerken al dan niet volledig of reeds gemonteerd (m.u.v. stacaravans, mobiele bungalows en gebouwen uitsluitend of hoofdzakelijk gemaakt van hout, ijzer of staal)',
    95030010: 'driewielers, autopeds, pedaalauto\'s e.d. speelgoed op wielen; poppenwagens (m.u.v. gewone rijwielen met kogellagers)',
    95030021: 'poppen, zijnde nabootsingen van de mens, ook indien aangekleed',
    95030029: 'delen en toebehoren van poppen, zijnde nabootsingen van de mens, n.e.g.',
    95030030: 'elektrische treinen, incl. rails, signalen en ander toebehoren; zelfbouwmodellen op schaal, ook indien bewegend',
    95030035: 'bouwdozen en ander constructiespeelgoed, van kunststof (m.u.v. zelfbouwmodellen op schaal)',
    95030039: 'bouwdozen en ander constructiespeelgoed (m.u.v. die van kunststof en m.u.v. zelfbouwmodellen op schaal)',
    95030041: 'speelgoed, zijnde nabootsingen van dieren of van niet-menselijke wezens, opgevuld',
    95030049: 'speelgoed, zijnde nabootsingen van dieren of van niet-menselijke wezens, zonder opvulling',
    95030055: 'speelgoedmuziekinstrumenten en -apparaten',
    95030061: 'puzzels van alle soorten, van hout',
    95030069: 'puzzels van alle soorten (m.u.v. die van hout)',
    95030070: 'speelgoed, aangeboden in assortimenten (m.u.v. elektrische treinen, incl. toebehoren, zelfbouwmodellen op schaal, bouwdozen en ander contructiespeelgoed en puzzels)',
    95030075: 'speelgoed en modelbouwspeelgoed, met ingebouwde motor, van kunststof (m.u.v. elektrische treinen, zelfbouwmodellen op schaal, en speelgoed zijnde nabootsingen van de mens, van dieren of van niet-menselijke wezens)',
    95030079: 'speelgoed en modelbouwspeelgoed, met ingebouwde motor (m.u.v. die van kunststof en m.u.v. elektrische treinen, zelfbouwmodellen op schaal, en speelgoed zijnde nabootsingen van de mens, van dieren of van niet-menselijke wezens)',
    95030081: 'speelgoedwapens',
    95030085: 'miniatuurmodellen, vervaardigd door vormgieten, van metaal',
    95030087: 'Draagbare interactieve elektronische onderwijs apparaten bedoeld voor kinderen',
    95030095: 'Speelgoed van kunststof, n.e.g.',
    95030099: 'Speelgoed, n.e.g.',
    95042000: 'Biljarten van alle soorten, alsmede toebehoren daarvoor',
    95043010: 'spellen met beeldscherm, werkende op munten, bankbiljetten, penningen of andere gelijksoortige artikelen',
    95043020: 'Spellen, werkende op munten, bankbiljetten, penningen of andere gelijksoortige artikelen (m.u.v. die met beeldscherm en bowlinginstallaties)',
    95043090: 'delen van spellen, werkende op munten, bankbiljetten, penningen of andere wijze van betaling (m.u.v. die van bowlinginstallaties)',
    95044000: 'speelkaarten',
    95045000: 'consoles en machines voor videospellen, andere dan die bedoeld bij onderverdeling 9504 30',
    95049010: 'elektrische autobanen, voor het houden van wedstrijden',
    95049080: 'artikelen voor gezelschapsspellen, daaronder begrepen spellen met motor of drijfwerk, speciale tafels voor casinospellen en automatische bowlinginstallaties (m.u.v. spellen werkende op munten, bankbiljetten, penningen of andere wijze van betaling, biljarten, consoles en machines voor videospellen, speelkaarten en elektrische autobanen, voor het houden van wedstrijden)',
    95051010: 'kerstfeestartikelen van glas (m.u.v. elektrische guirlandes voor kerstboomverlichting)',
    95051090: 'kerstfeestartikelen (m.u.v. die van glas en m.u.v. elektrische guirlandes voor kerstboomverlichting)',
    95059000: 'feestartikelen, carnavalsartikelen en andere ontspanningsartikelen, incl. benodigdheden voor het goochelen en fop- en schertsartikelen, n.e.g.',
    95061110: 'langlaufski\'s voor de sneeuwskisport',
    95061121: 'monoski\'s en snowboards',
    95061129: 'ski\'s voor het alpineskiën (m.u.v. monoski\'s en snowboards)',
    95061180: 'ski\'s voor de sneeuwskisport (excl. langlaufski\'s voor de sneeuwskisport en ski\'s voor het alpineskiën)',
    95061200: 'skibindingen',
    95061900: 'Materieel voor de sneeuwskisport (m.u.v. ski\'s en skibindingen)',
    95062100: 'zeilplanken voor de watersport',
    95062900: 'Waterski\'s, surfplanken en ander materieel voor de watersport (m.u.v. boten en zeilplanken)',
    95063100: 'complete golfstokken',
    95063200: 'golfballen',
    95063910: 'delen van golfstokken',
    95063990: 'materieel voor het golfspel (m.u.v. golfballen, golfstokken en delen daarvoor)',
    95064000: 'Artikelen en materieel voor het tafeltennis',
    95065100: 'tennisrackets, ook indien zonder bespanning (m.u.v. tafeltennisbats)',
    95065900: 'Badmintonrackets e.d., ook indien zonder bespanning (m.u.v. tennisrackets)',
    95066100: 'tennisballen (m.u.v. tafeltennisballen)',
    95066200: 'Opblaasbare ballen',
    95066910: 'ballen voor cricket en polo',
    95066990: 'ballen voor sporten (m.u.v. opblaasbare ballen en tennis-, golf- en tafeltennis-, cricket- en poloballen)',
    95067010: 'ijsschaatsen, incl. schoeisel waaraan dergelijke schaatsen zijn bevestigd',
    95067030: 'rolschaatsen, incl. schoeisel waaraan dergelijke schaatsen zijn bevestigd',
    95067090: 'delen en toebehoren van ijsschaatsen en rolschaatsen',
    95069110: 'fitnessapparaten met instelbare weerstandscontrole',
    95069190: 'artikelen en materieel voor lichaamsoefening, voor gymnastiek of voor atletiekartikelen en materieel voor lichaamsoefening, voor gymnastiek of voor atletiek (m.u.v. fitnessapparaten met instelbare weerstandscontrole)',
    95069910: 'artikelen voor cricket en polo (m.u.v. ballen)',
    95069990: 'artikelen en materieel voor sporten of voor openluchtspelen, n.e.g.; zwembaden en zwembadjes',
    95071000: 'hengelstokken',
    95072010: 'niet-gemonteerde vishaken',
    95072090: 'gemonteerde vishaken',
    95073000: 'hengelmolens',
    95079000: 'hengelbenodigdheden, n.e.g.; schepnetjes voor alle doeleinden; lokvogels e.d. jachtbenodigdheden (m.u.v. lokfluitjes van alle soorten en opgezette vogels bedoeld bij post 9705)',
    95081000: 'reizende circussen en reizende dierenspelen',
    95089000: 'draaimolens, schommels, schiettenten en andere kermisattracties; reizende theaters (m.u.v. reizende circussen en reizende dierenspelen, tenten en barakken voor de verkoop van goederen, daaronder begrepen de voor de verkoop bestemde goederen, als prijzen aangeboden artikelen, spellen werkende op munten, bankbiljetten, penningen of andere gelijksoortige artikelen, tractors en andere transportmiddelen, normale aanhangwagens daaronder begrepen)',
    96011000: 'ivoor, bewerkt; werken van ivoor, n.e.g.',
    96019000: 'Been, schildpad, hoorn, geweien, paarlemoer en andere stoffen van dierlijke herkomst geschikt om te worden gesneden, bewerkt; werken van deze stoffen, n.e.g. (m.u.v. ivoor en werken daarvan)',
    96020000: 'stoffen geschikt om te worden gesneden, plantaardig of mineraal, bewerkt, alsmede werken van deze stoffen, n.e.g.; gevormde of gesneden werken van was, van paraffine, van stearine, van natuurlijke gommen of harsen, van modelleerpasta en andere gevormde of gesneden werken, n.e.g.; niet-geharde gelatine, bewerkt, alsmede werken daarvan, n.e.g.',
    96031000: 'bezems en heiboenders e.d. artikelen, bestaande uit samengebonden twijgen of ander plantaardig materiaal, ook indien met steel',
    96032100: 'tandenborstels, incl. borstels voor het reinigen van kunstgebitten, ook indien zij delen van apparaten en toestellen zijn',
    96032930: 'haarborstels',
    96032980: 'scheerkwasten, nagelborstels, wimperborstels en andere borstels voor de lichaamsverzorging (m.u.v. tandenborstels, borstels voor het reinigen van kunstgebitten en haarborstels)',
    96033010: 'penselen, kwasten en borstels voor kunstschilders;penselen om te schrijven',
    96033090: 'penselen voor het aanbrengen van cosmetica',
    96034010: 'borstels, kwasten en penselen voor het schilderen, verven, behangen e.d. (m.u.v. penselen, kwasten en borstels voor kunstschilders e.d. bedoeld bij onderverdeling 9603.30)',
    96034090: 'verfkussens en verfrollen',
    96035000: 'borstels, zijnde delen van machines, van toestellen of van voertuigen',
    96039010: 'mechanische vegers, met de hand bediend, zonder motor',
    96039091: 'straatbezems en -borstels voor huishoudelijk gebruik, incl. kleerborstels, schoenborstels en borstels voor de verzorging van dieren (m.u.v. borstels zijnde delen van machines, van toestellen of van voertuigen; bezems en heiboenders e.d. bestaande uit samengebonden twijgen of ander plantaardig materiaal)',
    96039099: 'plumeaus; gerede knotten voor borstelwerk; wissers van rubber of van andere soepele stoffen; bezems, borstels, penselen en kwasten, n.e.g.',
    96040000: 'handzeven en handteemsen (m.u.v. vergieten)',
    96050000: 'reisassortimenten voor de lichaamsverzorging van personen, voor het schoonmaken van schoeisel of van kleding en reisnaaigarnituren (m.u.v. nagelgarnituren)',
    96061000: 'drukknopen en delen daarvan',
    96062100: 'knopen van kunststof (m.u.v. die met textiel overtrokken; drukknopen; manchetknopen)',
    96062200: 'knopen van onedel metaal (m.u.v. die met textiel overtrokken; drukknopen; manchetknopen)',
    96062900: 'knopen (m.u.v. die van kunststof of van onedel metaal, niet overtrokken met textiel; drukknopen; manchetknopen)',
    96063000: 'knoopvormen en andere delen van knopen of van drukknopen; knopen in voorwerpsvorm',
    96071100: 'treksluitingen, met haakjes, van onedel metaal',
    96071900: 'treksluitingen (m.u.v. die met haakjes van onedel metaal)',
    96072010: 'Delen van treksluitingen, van onedel metaal',
    96072090: 'Delen van treksluitingen (m.u.v. die van onedel metaal)',
    96081010: 'kogelpennen met dun vloeibare inkt "rolschrijvers"',
    96081092: 'Kogelpennen, met vervangbare vulling (m.u.v. kogelpennen met dun vloeibare inkt "rolschrijvers")',
    96081099: 'kogelpennen (m.u.v. die met vervangbare vulling; die met houder of afsluitdop van edele metalen of van metalen geplateerd met edele metalen; die met dun vloeibare inkt "rolschrijvers")',
    96082000: 'vilt- en merkstiften, alsmede andere pennen met poreuze punt',
    96083000: 'vulpennen en andere pennen',
    96084000: 'vulpotloden',
    96085000: 'assortimenten bestaande uit ten minste twee van de volgende artikelen: kogelpennen, vilt- en merkstiften, vulpennen',
    96086000: 'vullingen voor kogelpennen met punt',
    96089100: 'schrijfpennen en punten voor schrijfpennen',
    96089900: 'Delen van kogelpennen, van vilt- en merkstiften, alsmede andere pennen met poreuze punt, van vulpennen en vulpotloden, n.e.g. en doorschrijfpennen, penhouders, potloodhouders e.d.',
    96091010: 'potloden met grafietstift',
    96091090: 'potloden (m.u.v. die met grafietstift)',
    96092000: 'stiften voor potloden of voor vulpotloden',
    96099010: 'pastellen en tekenkool',
    96099090: 'griffels, schrijf- en tekenkrijt, kleermakerskrijt',
    96100000: 'schrijf- en tekenleien en schrijf- en tekenborden, ook indien omlijst',
    96110000: 'handstempels, zoals datumstempels, zegelstempels, numeroteurs, incl. handetiketteermachines; verstelbare handstempels en drukdozen',
    96121010: 'inktlinten voor schrijfmachines e.d. inktlinten, geïnkt of op andere wijze geprepareerd voor het maken van afdrukken, ook indien op spoelen of in cassettes, van kunststof',
    96121020: 'inktlinten van synthetische of kunstmatige vezels, met een breedte van < 30 mm, duurzaam opgeborgen in kunststoffen of metalen cassettes, van de soort gebruikt in automatische schrijfmachines, automatische gegevensverwerkende machines of andere machines',
    96121080: 'inktlinten voor schrijfmachines e.d. inktlinten, geïnkt of op andere wijze geprepareerd voor het maken van afdrukken, ook indien op spoelen of in cassettes, van textielstof of van papier (m.u.v. die van synthetische of kunstmatige vezels bedoeld bij onderverdeling 9612.10.20)',
    96122000: 'stempelkussens, ook indien geïnkt, met of zonder doos',
    96131000: 'niet-navulbare zakaanstekers werkend met gas',
    96132000: 'Navulbare zakaanstekers werkend met gas',
    96138000: 'aanstekers (m.u.v. zakaanstekers werkeknd met gas en lonten bestemd voor het ontsteken op afstand van springladingen)',
    96139000: 'delen van aanstekers, n.e.g.',
    96140010: 'ebauchons van pijpen, van hout of van wortel',
    96140090: 'Pijpen, incl. pijpenkoppen, sigaren- en sigarettenpijpjes, alsmede delen daarvan, n.e.g. (m.u.v. ebauchons van pijpen, van hout of van wortel)',
    96151100: 'kammen, haarklemmen e.d. artikelen, van harde rubber of van kunststof',
    96151900: 'kammen, haarklemmen e.d. artikelen (m.u.v. die van harde rubber of van kunststof)',
    96159000: 'haarspelden, krulspelden e.d. artikelen, alsmede delen daarvan, n.e.g. (m.u.v. elektrothermische toestellen bedoeld bij post 8516 alsmede delen daarvan)',
    96161010: 'Vaporisators voor toiletgebruik',
    96161090: 'Monturen en montuurkoppen, voor vaporisators voor toiletgebruik',
    96162000: 'poederdonsjes e.d. artikelen voor het aanbrengen van cosmetica',
    96170000: 'Thermosflessen en andere gemonteerde isothermische bergingsmiddelen, met vacuümisolatie, en delen daarvan (m.u.v. binnenflessen van glas)',
    96180000: 'paspoppen, ledepoppen e.d. (m.u.v. modellen voor het geven van demonstraties; poppen als speelgoed); automaten en mechanische blikvangers, voor etalages (m.u.v. de aangeboden artikelen zelf)',
    96190030: 'maandverbanden en tampons, luiers en inlegluiers e.d. hygiënische artikelen, van textielwatten van textielstoffen',
    96190040: 'maandverbanden, tampons e.d. artikelen van textielstoffen (m.u.v. die van textielwatten)',
    96190050: 'Luiers en inlegluiers voor babies, e.d. artikelen van textielstoffen (m.u.v. die van textielwatten)',
    96190071: 'maandverbanden (m.u.v. die van  textielstoffen)',
    96190075: 'tampons, (m.u.v. die van textielstoffen)',
    96190079: 'artikelen voor hygiënisch gebruik door de vrouw (m.u.v. die van textielwatten en andere textielstoffen, maandverbanden en tampons)',
    96190081: 'Luiers en inlegluiers voor babies (m.u.v. die van textielwatten en andere textielstoffen)',
    96190089: 'sanitaire artikelen, bijvoorbeeld artikelen voor incontinentiepatiënten  (m.u.v. die van textielwatten en andere textielstoffen, maandverbanden, tampons, luiers en inlegluiers voor babies)',
    96200010: 'Statieven met 1,2 of 3 poten en dergelijke artikelen van de soort gebruikt voor digitale, fotografische of videocamera\'s, cinematografische camera\'s en projectoren,  en van de soort gebruikt voor andere toestellen van Ch 90',
    96200091: 'Statieven met 1,2 of 3 poten en dergelijke artikelen van kunststof of aluminium (m.u.v. de soort gebruikt voor digitale, fotografische of videocamera\'s, cinematografische camera\'s en projectoren,  en van de soort gebruikt voor andere toestellen van Ch 90)',
    96200099: 'Statieven met 1,2 of 3 poten en dergelijke artikelen (m.u.v. de soort gebruikt voor digitale, fotografische of videocamera\'s, cinematografische camera\'s en projectoren, en van de soort gebruikt voor andere toestellen van Ch 90, en van kunststof of aluminium)',
    97011000: 'schilderijen, schilderingen en tekeningen, geheel met de hand vervaardigd (m.u.v. technische tekeningen e.d. bedoeld bij post 4906 en met de hand versierde voorwerpen)',
    97019000: 'collages e.d. decoratieve platen',
    97020000: 'originele gravures, originele etsen en originele litho\'s',
    97030000: 'originele standbeelden en origineel beeldhouwwerk, ongeacht het materiaal waarvan zij vervaardigd zijn',
    97040000: 'Postzegels, fiscale zegels, gefrankeerde enveloppen en postkaarten, eerstedagenveloppen en dergelijke, gestempeld of ongestempeld (m.u.v. die welke geldig zijn of zullen worden in het land waar ze een erkende nominale waarde hebben of zullen hebben)',
    97050000: 'verzamelingen en voorwerpen voor verzamelingen, met een zoölogisch, botanisch, mineralogisch, anatomisch, historisch, archeologisch, paleontologisch, etnografisch of numismatisch belang',
    97060000: 'antiquiteiten, zijnde voorwerpen ouder dan 100 jaar'
}

export default (label) => {
    const raw = window.atob(label)
    const rawLength = raw.length
    const array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
    }

    const blob = new Blob([array], {type: 'application/pdf'})

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, 'labels.pdf')
    } else {
        const blobUrl = URL.createObjectURL(blob)
        window.open(blobUrl)
    }
}

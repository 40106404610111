import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setQueries',
    'setSelected',

    'get',
    'getOne',
    'create',
    'edit',
    'remove'
])

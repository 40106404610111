import Reflux from 'reflux-react-16'
import socket from '../socketio/socketio.js'
import AddressActions from '../actions/AddressActions.js'

export default class AddressStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {addressBook: []}
        this.listenables = AddressActions

        AddressActions.get()

        socket.on('updateAddresses', () => {
            AddressActions.get()
        })
    }

    onGet() {
        socket.emit('addresses.get', sessionStorage.token, (err, addressBook) => {
            this.setState({addressBook})
        })
    }

    onUpdate(...args) {
        socket.emit('addresses.update', sessionStorage.token, ...args)
    }

    onEdit(address, callback) {
        socket.emit('addresses.edit', sessionStorage.token, address, callback)
    }

    onRemove(ids, callback) {
        socket.emit('addresses.remove', sessionStorage.token, ids, callback)
    }

    onAutocomplete(address, callback) {
        socket.emit('addresses.autocomplete', sessionStorage.token, address, callback)
    }

    onAutocompleteSearch(value, callback) {
        socket.emit('addresses.autocompleteSearch', (sessionStorage.token || localStorage.mtoken), value, callback)
    }

    onDownloadAttachment(attachment, callback) {
        socket.emit('addresses.downloadAttachment', sessionStorage.token, attachment, callback)
    }
}

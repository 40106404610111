import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'underscore'

import {Colors, Select, Toggle, Input, H6, H3, Panel, IconButton, ListItem, MetricInput, Modal, H4, Alert, Button, Divider, Check, Badge, Address, S1, CurrencyInput} from '../../../components/UI/index.js'

class ParcelSettings extends Reflux.Component {
    onChangeValue(key, event) {
        const user = {...this.props.user}

        user.settings[key] = event.target.value

        this.props.onChange(user)
    }

    onChangeAddress(address) {
        const user = {...this.props.user}

        user.settings.parcels.senderAddress = address

        this.props.onChange(user)
    }

    onChangeValueParcels(key, event) {
        const user = {...this.props.user}

        user.settings.parcels[key] = event.target.value

        this.props.onChange(user)
    }

    onChangeValuesParcels(key, event) {
        const user = {...this.props.user}

        user.settings.parcels[key] = event.target.values

        this.props.onChange(user)
    }

    onChangeCheckedParcel(key, event) {
        const user = {...this.props.user}

        user.settings.parcels[key] = event.target.checked

        this.props.onChange(user)
    }

    onNewSize() {
        const user = {...this.props.user}

        const newSize = {
            length: 0,
            width: 0,
            height: 0,
            isDefault: user.settings.parcels.sizes.length === 0
        }

        this.sizeModal.open(newSize, this.onSaveSize.bind(this, user.settings.parcels.sizes.length))
    }

    onSaveSize(index, size) {
        const user = {...this.props.user}

        if (size.isDefault) {
            user.settings.parcels.sizes = user.settings.parcels.sizes.map((size, i) => {
                if (index !== i) {
                    size.isDefault = false
                }
                return size
            })
        }

        user.settings.parcels.sizes[index] = size

        this.props.onChange(user)
        this.sizeModal.close()
    }

    onRemoveSize(index) {
        const user = {...this.props.user}

        user.settings.parcels.sizes.splice(index, 1)

        this.props.onChange(user)
        this.sizeModal.close()
    }

    render() {
        const {reseller, user} = this.props

        let enabledOptions = (_.keys(reseller.settings.parcels.products) || []).filter((product) => {
            return _.some(_.keys(reseller.settings.parcels.products[product]), (carrier) => {
                return reseller.settings.parcels.products[product][carrier]
            })
        }).sort()

        enabledOptions = enabledOptions.map((option) => {
            return {value: option, title: option}
        })

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Zendingen</H3>

                <Select
                    label='Standaard weergave'
                    value={user.settings.parcels.defaultDateRange}
                    onChange={this.onChangeValueParcels.bind(this, 'defaultDateRange')}
                >
                    <option value='today'>Vandaag</option>
                    <option value='week'>Laatste 7 dagen</option>
                </Select>


                <S1 style={{marginTop: 24, marginBottom: 12, fontSize: 14}}>Afzender adres</S1>
                <Address
                    style={{marginBottom: 24}}
                    noTAV
                    noStreet2
                    address={user.settings.parcels.senderAddress}
                    onChange={this.onChangeAddress.bind(this)}

                />

                <Select
                    style={{marginTop: 24}}
                    noSort
                    label='Standaard verzendopties'
                    info='Selecteer de opties die standaard gebruikt worden bij het aanmelden van een pakket. De geselecteerde opties zijn niet voor elke zending beschikbaar, als een combinatie van opties niet mogelijk is wordt de voorkeur gegeven aan de eerst geselecteerde optie.'
                    values={user.settings.parcels.defaultOptions || []}
                    options={enabledOptions}
                    onChange={this.onChangeValuesParcels.bind(this, 'defaultOptions')}
                />

                {user.settings.parcels.defaultOptions?.includes('Verzekerd') && (
                    <>
                        <CurrencyInput
                            label='Bedrag verzekerd'
                            value={user.settings.parcels?.coverAmount || '0,00'}
                            onChange={this.onChangeValueParcels.bind(this, 'coverAmount')}
                        />
                    </>
                )}

                <Input
                    label='Standaard bezorginstructie'
                    info='De bezorginstructie is een opmerking voor de bezorger, niet elke vervoerder maakt gebruik van deze instructie.'
                    value={user.settings.parcels.defaultComment || ''}
                    onChange={this.onChangeValueParcels.bind(this, 'defaultComment')}
                />

                <Toggle
                    label='Stuur emailadressen niet naar vervoerders'
                    info='Emailadressen worden niet naar externe vervoerders gestuurd. De ontvanger zal dus geen emails van de vervoerder ontvangen.'
                    checked={user.settings.parcels.dontSendEmailToCarriers}
                    onChange={this.onChangeCheckedParcel.bind(this, 'dontSendEmailToCarriers')}
                />

                <Toggle
                    label='Referentie verplicht'
                    info='Bij het aanmaken van een zending is het verplicht een referentie in te vullen.'
                    checked={!!user.settings.parcels.referenceRequired}
                    onChange={this.onChangeCheckedParcel.bind(this, 'referenceRequired')}
                />

                <Toggle
                    label='Referentie niet tonen op label'
                    info='De referentie wordt niet doorgestuurd naar externe vervoerders en komt niet op het label te staan.'
                    checked={!!user.settings.parcels.referenceNotOnLabel}
                    onChange={this.onChangeCheckedParcel.bind(this, 'referenceNotOnLabel')}
                />

                <br/>
                <br/>
                <H6>Pakketformaten</H6>
                <Divider/>

                {user.settings.parcels.sizes && user.settings.parcels.sizes.length > 0 &&
                    <Panel style={{marginBottom: 12}}>
                        {user.settings.parcels.sizes.map((size, index) => (
                            <ListItem key={index} onClick={() => this.sizeModal.open(size, this.onSaveSize.bind(this, index), this.onRemoveSize.bind(this, index))} style={{justifyContent: 'space-between', height: 'fit-content'}}>
                                <div style={{margin: '1em 0'}}>
                                    <span style={{display: 'flex', alignItems: 'center'}}>{size.name} {size.isDefault && <Badge style={{marginLeft: 12}}>Standaard</Badge>}</span>
                                    <span style={{fontSize: 12, color: 'grey'}}>L: {(size.length / 10).toString().replace('.', ',')}cm x B: {(size.width / 10).toString().replace('.', ',')}cm x H: {(size.height / 10).toString().replace('.', ',')}cm</span>
                                </div>
                            </ListItem>
                        ))}
                    </Panel>
                }

                <Button
                    variant='outline'
                    icon='mdi mdi-plus'
                    label='Nieuw formaat'
                    onClick={this.onNewSize.bind(this)}
                />

                <SizeModal ref={(ref) => this.sizeModal = ref}/>
            </Panel>
        )
    }
}

class SizeModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            size: {
                name: '',
                length: 0,
                width: 0,
                height: 0,
                isDefault: false
            },
            modalIsOpen: false,
            loading: false,
            error: '',
            onSave: undefined,
            onRemove: undefined
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(size, onSave, onRemove) {
        this.setState({
            modalIsOpen: true,
            size: size || this.state.size,
            onSave,
            onRemove
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onSubmit(event) {
        event.preventDefault()
        const {size, onSave} = this.state

        this.setState({loading: true, error: ''})

        if (onSave && typeof onSave === 'function') {
            onSave(size)
        }
    }

    onClickRemove() {
        const {onRemove} = this.state

        this.setState({loading: true, error: ''})
        onRemove()
    }

    setError(error) {
        this.setState({error, loading: false})
    }

    response(err) {
        if (err) {
            this.setState({loading: false, error: err})
        } else {
            this.close()
        }
    }

    onChangeValue(key, event) {
        const {size} = this.state
        if (key !== 'name') {
            size[key] = event.target.value
        } else {
            size[key] = event.target.value
        }

        this.setState({size})
    }

    onChangeChecked(key, event) {
        const {size} = this.state

        size[key] = event.target.checked

        this.setState({size})
    }

    render() {
        const {size, modalIsOpen, error, loading, onRemove} = this.state

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>Pakket formaat</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <Input value={size.name} label='Naam' onChange={this.onChangeValue.bind(this, 'name')}/>
                    <div style={{display: 'flex', gap: 10}}>
                        <MetricInput
                            label='Lengte'
                            value={size.length}
                            onChange={this.onChangeValue.bind(this, 'length')}
                            divisionFactor={10}
                            append='cm'
                        />
                        <MetricInput
                            label='Breedte'
                            value={size.width}
                            onChange={this.onChangeValue.bind(this, 'width')}
                            divisionFactor={10}
                            append='cm'
                        />
                        <MetricInput
                            label='Hoogte'
                            value={size.height}
                            onChange={this.onChangeValue.bind(this, 'height')}
                            divisionFactor={10}
                            append='cm'
                        />
                    </div>
                    <Check label='Gebruik als standaard' checked={size.isDefault} onChange={this.onChangeChecked.bind(this, 'isDefault')}/>

                    {error &&
                        <Alert variant='danger'>{error}</Alert>
                    }

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {onRemove && typeof onRemove === 'function' &&
                            <Button variant='error' onClick={this.onClickRemove.bind(this)} loading={loading}>Verwijder</Button>
                        }
                        <Button type='submit' loading={loading}>Opslaan</Button>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default (ParcelSettings)

import React, {useReducer} from 'react'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

import {H3, H6, Divider, Button, Alert} from '../../../../components/UI/index.js'
import {Toggle, Input, Select} from '../../../../components/UI/index.js'

const initialState = {
    loading: false,
    verified: false,
    error: ''
}

export default function ShoptraderSettings({user, onChange}) {
    const [{loading, verified, error}, dispatch] = useReducer(reducer, initialState)

    function onChangeChecked(key, event) {
        user.settings.webshops.shoptrader[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.shoptrader[key] = event.target.value

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('shoptrader', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true})
                }
            })
        }, 1000)
    }

    return (
        <div>
            <H3>Shoptrader</H3>

            <H6>Instellingen</H6>
            <Divider/>

            <Toggle
                label='Actief'
                checked={user.settings.webshops.shoptrader.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.shoptrader.active &&
            <>
                <Input
                    label='Webshop url'
                    value={user.settings.webshops.shoptrader.url}
                    onChange={(event) => onChangeValue('url', event)}
                />

                <Input
                    label='Api Token'
                    value={user.settings.webshops.shoptrader.apiKey}
                    onChange={(event) => onChangeValue('apiKey', event)}
                />

                <Select
                    label='Update order status in Shoptrader'
                    info=''
                    value={user.settings.webshops.shoptrader.updateStatus}
                    onChange={(event) => onChangeValue('updateStatus', event)}
                >
                    <option value=''>Nooit</option>
                    <option value='confirm'>Na bevestigen</option>
                    <option value='afterSent'>Na verzenden</option>
                </Select>

                <Button
                    variant='text'
                    style={{marginBottom: 12, marginLeft: 0}}
                    loading={loading}
                    onClick={onVerifyConnection}
                >
                    Verifieer
                </Button>

                {error && (
                    <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                )}

                {!error && verified && (
                    <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                )}
            </>
            }
        </div>

    )
}

import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'

import ShiftStore from '../../stores/ShiftStore.js'
import ShiftActions from '../../actions/ShiftActions.js'
import UserStore from '../../stores/UserStore.js'

import ShiftModal from '../../components/shifts/ShiftModal.js'
import {AlertPopup, Button, Colors, IconButton, Panel, Popup, Select} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import csvExport from '../../utils/csvExport.js'
import filter from '../../utils/filter.js'


export default class Shifts extends Reflux.Component {
    constructor(props) {
        super(props)
        this.stores = [ShiftStore, UserStore]
    }

    componentDidMount() {
        const {reseller} = this.props
        document.title = `Diensten • ${reseller.settings.accountName || reseller.name}`
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.shiftLimitWarning) {
            this.setState({shiftLimitWarning: false})
            this.alertPopup.open('Waarschuwing', 'Je huidige datumrange bevat meer dan 35 000 diensten, hierdoor worden niet alle diensten weergegeven. Verklein de datumrange om dit te voorkomen. ')
        }
    }

    columns() {
        return [
            {title: 'Datum', visible: false, key: 'date', width: 120, render: (shift) => {
                return moment(shift.date).format('DD-MM-YYYY')
            }},
            {title: 'Dienst', visible: true, flex: 1, key: 'name'},
            {title: 'koerier', visible: true, key: 'messenger', width: 300, render: (shift) => {
                return (
                    <Select
                        style={{marginBottom: 0}}
                        options={this.state.users.map((user) => {
                            return {value: user._id, title: user.name}
                        })}
                        allowEmptyValue
                        value={shift.messenger}
                        onChange={this.onChangeMessenger.bind(this, shift._id)}
                    />
                )
            }},
            {title: 'Aanvangstijd', visible: true, width: 150, key: 'startTime'},
            {title: 'Eindtijd', visible: true, width: 150, key: 'endTime'},
            {title: 'Opmerkingen', visible: true, flex: 2, key: 'comment'},
            {title: '', visible: true, key: '', width: 60, onClick: () => {}, render: (order) => {
                return (
                    <IconButton onClick={this.onClickEdit.bind(this, order._id)}>
                        <i className="mdi mdi-pencil"/>
                    </IconButton>
                )
            }}
        ]
    }

    onChangeDates(startDate, endDate) {
        ShiftActions.setDates(startDate, endDate)
    }

    onChangeQueries(shiftQueries) {
        this.table.selectPage(1)
        ShiftActions.setQueries(shiftQueries)
    }

    onChangeTable(selectedShifts) {
        ShiftActions.setSelected(selectedShifts)
    }

    onClickNew() {
        this.modal.open()
        ShiftActions.setSelected([])
    }

    onClickCopy() {
        const {shifts, selectedShifts}= this.state

        const id = selectedShifts[0]
        let shift = _.findWhere(shifts, {_id: id})
        shift = JSON.parse(JSON.stringify(shift))
        delete shift._id
        delete shift.rrule
        delete shift.templateId
        delete shift.messenger
        this.modal.open(shift)
        ShiftActions.setSelected([])
    }

    onClickEdit(id) {
        const {shifts}= this.state

        const shift = _.findWhere(shifts, {_id: id})
        this.modal.open(shift)
        ShiftActions.setSelected([])
    }

    onClickRemove() {
        const {selectedShifts} = this.state

        this.popup.open('Diensten verwijderen', 'Diensten worden definitief verwijderd.', () => {
            ShiftActions.setSelected([])

            ShiftActions.remove(selectedShifts, (err) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    onClickExport() {
        const {shifts, selectedShifts, startDate, endDate} = this.state
        const exportShifts = []

        selectedShifts.map((id) => {
            const shift = _.findWhere(shifts, {_id: id})
            if (shift) {
                exportShifts.push(shift)
            }
        })

        csvExport.shifts(exportShifts, startDate, endDate)
        ShiftActions.setSelected([])
    }

    onChangeMessenger(id, event) {
        const {selectedShifts} = this.state

        let ids
        if (!event) {
            event = id
            ids = selectedShifts
        } else {
            ids = [id]
        }

        ShiftActions.setMessenger(ids, event.target.value)
        ShiftActions.setSelected([])
    }

    render() {
        const {selectedShifts, shiftsLoading, shiftQueries, users, startDate, endDate} = this.state
        const shifts = filter.shifts(this.state.shifts, shiftQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        onDateChange={this.onChangeDates.bind(this)}
                        startDate={startDate}
                        endDate={endDate}
                        queries={shiftQueries}
                    />

                    <Button
                        variant='outline-white'
                        onClick={this.onClickNew.bind(this)}
                    >
                        Nieuwe Dienst
                    </Button>

                    {selectedShifts.length === 1 &&
                        <Button
                            variant='outline-white'
                            onClick={this.onClickCopy.bind(this)}
                        >
                            <i className="mdi mdi-content-copy"/>
                        </Button>
                    }

                    {selectedShifts.length > 0 &&
                    <>
                        <Button
                            variant='outline-white'
                            onClick={this.onClickExport.bind(this)}
                        >
                            <i className="mdi mdi-file-export"/>
                        </Button>

                        <Button
                            variant='outline-white'
                            onClick={this.onClickRemove.bind(this)}
                        >
                            <i className="mdi mdi-delete"/>
                        </Button>

                        <Select
                            style={{width: 160, marginLeft: 12}}
                            label='Wijzig koerier'
                            allowEmptyValue
                            options={users.map((user) => {
                                return {value: user._id, title: user.name}
                            })}
                            onChange={this.onChangeMessenger.bind(this)}
                        />
                    </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='shifts'
                        columns={this.columns()}
                        rows={shifts}
                        selectedRows={selectedShifts}
                        loading={shiftsLoading}
                        onChange={this.onChangeTable.bind(this)}
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <ShiftModal
                    users={users}
                    ref={(ref) => this.modal = ref}
                />
                <Popup ref={(ref) => this.popup = ref}/>
                <AlertPopup ref={(ref) => this.alertPopup = ref}/>
            </div>

        )
    }
}


import React from 'react'


export default class FlexColumn extends React.Component {
    render() {
        const {style={}, children} = this.props

        const defaultStyle = {
            display: 'flex',
            flexDirection: 'column',
            flex: typeof style.width !== 'number' ? 1 : undefined,
            height: '100%',
            ...style
        }

        return (
            <div {...this.props} style={defaultStyle} >
                {children}
            </div>

        )
    }
}


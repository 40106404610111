import React from 'react'

import {Input, FileUpload, Toggle, Select, TimePicker, Divider, MetricInput, Colors, ColorPicker} from '../UI/index.js'
import {Alert, Button, IconButton, Modal, H4, H6, P} from '../UI/index.js'

export default class CarrierModal extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            modalIsOpen: false,
            carrierName: '',
            settings: {},
            carrier: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(carrierName, settings) {
        const {carriers} = this.props

        const carrier = carriers.find((carrier) => carrier?.name === carrierName)

        if (carrier) {
            this.setState({
                modalIsOpen: true,
                carrierName,
                settings,
                carrier
            })
        }
    }

    close() {
        this.setState(this.initialState)
    }

    onEnableCarrier(event) {
        const settings = {...this.state.settings}

        settings.enabled = event.target.checked

        this.setState({settings})
    }

    onChangeCarrierValue(key, event) {
        const settings = {...this.state.settings}
        settings[key] = event.target.value
        this.setState({settings})
    }

    onChangeCarrierValues(key, event) {
        const settings = {...this.state.settings}
        settings[key] = event.target.values
        this.setState({settings})
    }

    onChangeFileUpload(key, fileName, value) {
        const settings = {...this.state.settings}
        settings[key] = value
        settings[`${key}FileName`] = fileName
        this.setState({settings})
    }

    onChangeCarrierChecked(key, event) {
        const settings = {...this.state.settings}
        settings[key] = !settings[key]
        this.setState({settings})
    }

    onChangeUseDeliveryZones(event) {
        const settings = {...this.state.settings}
        settings.useCustomDeliveryZone = event.target.checked
        this.setState({settings})
    }

    onChangeWeight(event) {
        if (/^[0-9]{0,5}$/.test(event.target.value)) {
            const settings = {...this.state.settings}
            settings.maxWeight = parseInt(event.target.value)
            this.setState({settings})
        }
    }

    onChangeDimension(key, event) {
        const settings = {...this.state.settings}

        settings.maxDimensions = settings.maxDimensions || {}
        settings.maxDimensions[key] = event.target.value

        this.setState({settings})
    }

    onChangeVolume(event) {
        const settings = {...this.state.settings}
        settings.maxVolume = event.target.value
        this.setState({settings})
    }

    onDisable() {
        const settings = {...this.state.settings}
        settings.enabled = false
        this.props.onSubmit(this.state.carrierName, settings)
        this.close()
    }

    onSubmit() {
        const settings = {...this.state.settings}
        settings.enabled = true
        this.props.onSubmit(this.state.carrierName, settings)
        this.close()
    }

    render() {
        const {modalIsOpen, carrierName, settings, carrier} = this.state
        const {reseller} = this.props

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{`${carrier?.displayName || carrierName} ${settings.enabled ? 'instellingen' : 'activeren'}`}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                {(carrierName === 'Cycloon' || carrierName === 'DHL' || carrierName === 'GLS' || carrierName === 'Peddler' || carrierName === 'PostNL' || carrierName === 'Skynet' || carrierName === 'PostNL-MSG' || carrierName === 'DHL-MSG') &&
                    <>
                        <H6>Koppeling</H6>
                        <Divider/>
                    </>
                }

                {carrierName === 'Cycloon' &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            info='Vul hier je gebruikersnaam en API Key in van je klantaccount bij Cycloon, niet van je vervoerdersaccount.'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='API Key'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {carrierName === 'DHL' &&
                    <>
                        <Input
                            label='Klantnummer'
                            value={settings.accountId}
                            onChange={this.onChangeCarrierValue.bind(this, 'accountId')}
                        />

                        <Input
                            label='User ID'
                            value={settings.userId}
                            onChange={this.onChangeCarrierValue.bind(this, 'userId')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {carrierName === 'GLS' &&
                    <>
                        <Alert variant='info'>{'Om status updates van zendingen te ontvangen moeten er een aanvraag worden ingediend bij GLS. Stuur hiervoor een bericht naar info@veloyd.nl en vermeld hierin je GLS gebruikersnaam.'}</Alert>

                        <Input
                            label='Klantnummer'
                            value={settings.customerNumber}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerNumber')}
                        />

                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {carrierName === 'Peddler' &&
                    <>
                        <Input
                            label='Gebruiker Id'
                            value={settings.userId}
                            onChange={this.onChangeCarrierValue.bind(this, 'userId')}
                        />

                        <Input
                            label='Applicatie Id'
                            value={settings.applicationId}
                            onChange={this.onChangeCarrierValue.bind(this, 'applicationId')}
                        />

                        <Input
                            label='Winkel Id'
                            value={settings.storeId}
                            onChange={this.onChangeCarrierValue.bind(this, 'storeId')}
                        />

                        <FileUpload
                            accept='.rsa'
                            label='RSA key'
                            fileName={settings.rsaKeyFileName}
                            onChange={this.onChangeFileUpload.bind(this, 'rsaKey')}
                        />
                    </>
                }

                {carrierName === 'PostNL' &&
                    <>
                        <Input
                            label='Klantnummer'
                            value={settings.customerNumber}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerNumber')}
                        />

                        <Input
                            label='Klantcode'
                            value={settings.customerCode}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerCode')}
                        />

                        <Input
                            label='Klantcode internationaal'
                            value={settings.customerCodeInt}
                            onChange={this.onChangeCarrierValue.bind(this, 'customerCodeInt')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {carrierName === 'Skynet' &&
                    <>
                        <Alert variant='info'>{'Om status updates van zendingen te ontvangen moeten er een aanvraag worden ingediend bij Skynet. Stuur hiervoor een bericht naar info@veloyd.nl en vermeld hierin je Skynet gebruikersnaam.'}</Alert>

                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />

                        <Input
                            label='API Key'
                            value={settings.apiKey}
                            onChange={this.onChangeCarrierValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {(carrierName === 'PostNL-MSG' || carrierName === 'DHL-MSG') &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />

                        <Input
                            label='Klantnaam in Monta'
                            value={settings.originName}
                            onChange={this.onChangeCarrierValue.bind(this, 'originName')}
                        />
                    </>
                }

                {carrierName === 'Packs' &&
                    <>
                        <Input
                            label='Gebruikersnaam'
                            value={settings.username}
                            onChange={this.onChangeCarrierValue.bind(this, 'username')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={settings.password}
                            onChange={this.onChangeCarrierValue.bind(this, 'password')}
                        />
                    </>
                }

                {(carrierName === 'Cycloon' || carrierName === 'DHL' || carrierName === 'GLS' || carrierName === 'Peddler' || carrierName === 'PostNL' || carrierName === 'Skynet' || carrierName === 'Packs') &&
                    <>
                        <br/>
                        <br/>
                    </>
                }

                <H6>Bezorgrestricties</H6>
                <Divider/>

                {carrierName !== reseller.name ?
                    <>
                        <Toggle
                            label='Gebruik eigen bezorggebied'
                            checked={settings.useCustomDeliveryZone}
                            onChange={this.onChangeUseDeliveryZones.bind(this)}
                        />

                        {settings.useCustomDeliveryZone &&
                            <Select
                                copy
                                label='Bezorggebieden'
                                options={reseller.settings.deliveryZones.map((area) => {
                                    return {value: area.id, title: area.name}
                                })}
                                values={settings.deliveryZones || []}
                                onChange={this.onChangeCarrierValues.bind(this, 'deliveryZones')}
                            />
                        }
                    </> :
                    <>
                        <Select
                            copy
                            label='Bezorggebieden'
                            options={reseller.settings.deliveryZones.map((area) => {
                                return {value: area.id, title: area.name}
                            })}
                            values={settings.deliveryZones || []}
                            onChange={this.onChangeCarrierValues.bind(this, 'deliveryZones')}
                        />

                        <Input
                            label='(Aanvullende) postcodes'
                            info='De postcodes waar je zendingen gaat bezorgen. Gebruik een komma om postcodes te scheiden en een streepje om een reeks te noteren, bijvoorbeeld: 1000-2000,3000,4000-5000'
                            value={settings.postalCodes}
                            onChange={this.onChangeCarrierValue.bind(this, 'postalCodes')}
                        />
                    </>
                }

                <MetricInput
                    label='Max gewicht (kg)'
                    value={settings.maxWeight}
                    divisionFactor={1000}
                    onChange={this.onChangeWeight.bind(this)}
                    max={carrier?.maxWeight}
                    placeholder={carrier?.maxWeight}
                    append='kg'
                />

                <P>Max dimensies</P>
                <br/>
                <div style={{display: 'flex', gap: 10}}>
                    <MetricInput
                        label='Lengte'
                        value={settings.maxDimensions?.length}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'length')}
                        max={carrier?.maxDimensions?.length}
                        placeholder={carrier?.maxDimensions?.length}
                        append='cm'
                    />
                    <MetricInput
                        label='Breedte'
                        value={settings.maxDimensions?.width}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'width')}
                        max={carrier?.maxDimensions?.width}
                        placeholder={carrier?.maxDimensions?.width}
                        append='cm'
                    />
                    <MetricInput
                        label='Hoogte'
                        value={settings.maxDimensions?.height}
                        divisionFactor={10}
                        onChange={this.onChangeDimension.bind(this, 'height')}
                        max={carrier?.maxDimensions?.height}
                        placeholder={carrier?.maxDimensions?.height}
                        append='cm'
                    />
                </div>

                <MetricInput
                    label='Max inhoud'
                    value={settings.maxVolume}
                    onChange={this.onChangeCarrierValue.bind(this, 'maxVolume')}
                    max={carrier?.maxVolume}
                    placeholder={carrier?.maxVolume}
                    append='dm³'
                />

                {carrierName === reseller.name &&
                    <>
                        <br/>
                        <br/>
                        <H6>Bezorgopties</H6>
                        <Divider/>

                        {!reseller.isShop &&
                            <Input
                                label="Aantal bezorgpogingen"
                                type='number'
                                max={99}
                                value={settings.nrOfDeliveries}
                                onChange={this.onChangeCarrierValue.bind(this, 'nrOfDeliveries')}
                            />
                        }


                        {!reseller.isShop &&
                            <Input
                                type='number'
                                label='Bewaartijd'
                                info='Hoeveel dagen blijft de zending in bewaring na de laatste bezorgpoging. Vul 0 in om de zending meteen retour te sturen.'
                                value={settings.nrOfDaysBeforeRetour}
                                onChange={this.onChangeCarrierValue.bind(this, 'nrOfDaysBeforeRetour')}
                            />
                        }

                        {reseller.settings.parcels.products['Same Day'] && reseller.settings.parcels.products['Same Day'][carrierName] &&
                        <>
                            <Toggle
                                label='Same Day naar bedrijfsadressen'
                                checked={settings.sameDayBusiness}
                                onChange={this.onChangeCarrierChecked.bind(this, 'sameDayBusiness')}
                            />

                            {settings.sameDayBusiness &&
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <P style={{flex: 1}}>Same Day bedrijfsadres bezorgtijdvak</P>

                                    <TimePicker
                                        label='Van'
                                        style={{width: 130}}
                                        time={settings.sameDayBusinessFrom || ''}
                                        onChange={this.onChangeCarrierValue.bind(this, 'sameDayBusinessFrom')}
                                    />

                                    <TimePicker
                                        label='Tot'
                                        style={{width: 130, marginLeft: 15}}
                                        time={settings.sameDayBusinessTill || ''}
                                        onChange={this.onChangeCarrierValue.bind(this, 'sameDayBusinessTill')}
                                    />
                                </div>
                            }

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <P style={{flex: 1}}>Same Day bezorgtijdvak</P>

                                <TimePicker
                                    label='Van'
                                    style={{width: 130}}
                                    time={settings.sameDayFrom || ''}
                                    onChange={this.onChangeCarrierValue.bind(this, 'sameDayFrom')}
                                />

                                <TimePicker
                                    label='Tot'
                                    style={{width: 130, marginLeft: 15}}
                                    time={settings.sameDayTill || ''}
                                    onChange={this.onChangeCarrierValue.bind(this, 'sameDayTill')}
                                />
                            </div>
                        </>
                        }

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P style={{flex: 1}}>Next Day bezorgtijdvak</P>

                            <TimePicker
                                label='Van'
                                style={{width: 130}}
                                time={settings.nextDayFrom || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'nextDayFrom')}
                            />

                            <TimePicker
                                label='Tot'
                                style={{width: 130, marginLeft: 15}}
                                time={settings.nextDayTill || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'nextDayTill')}
                            />
                        </div>

                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <P style={{flex: 1}}>Next Day bedrijfsadres bezorgtijdvak</P>

                            <TimePicker
                                label='Van'
                                style={{width: 130}}
                                time={settings.businessFrom || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'businessFrom')}
                            />

                            <TimePicker
                                label='Tot'
                                style={{width: 130, marginLeft: 15}}
                                time={settings.businessTill || ''}
                                onChange={this.onChangeCarrierValue.bind(this, 'businessTill')}
                            />
                        </div>

                        {!reseller.isShop &&
                            <Toggle
                                label='Standaard met handtekening'
                                info='Voor alle zendingen wordt een handtekening gevraagd bij het bezorgen ongeacht of de optie Handtekening voor ontvangst is aangevinkt.'
                                checked={settings.defaultSigned}
                                onChange={this.onChangeCarrierChecked.bind(this, 'defaultSigned')}
                            />
                        }
                    </>
                }

                <br/>
                <br/>
                <H6>Overig</H6>
                <Divider/>

                <div style={{display: 'flex', alignItems: 'center', gap: 24, marginBottom: 12}}>
                    <Toggle
                        label='Kleur tonen bij scannen'
                        info='Bij meerdere vervoerders met hetzelfde logo kan het handig zijn om tijdens het scannen een kleur te zien voor snelle herkenning'
                        checked={settings.useCustomScanColor}
                        onChange={this.onChangeCarrierChecked.bind(this, 'useCustomScanColor')}
                        style={{marginBottom: 0}}
                    />

                    {settings.useCustomScanColor &&
                        <div style={{width: '50%', display: 'flex', alignItems: 'center', gap: 24}}>
                            <ColorPicker
                                value={settings.customScanColor}
                                colors={Colors.routeColors}
                                onChange={this.onChangeCarrierValue.bind(this, 'customScanColor')}
                            />

                            <P
                                style={{
                                    height: 36,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 4,
                                    backgroundColor: settings.customScanColor || '',
                                    color: Colors.isDarkBackground(settings.customScanColor || '') ? 'white' : 'black'
                                }}
                            >
                                {carrier.displayName || carrier.name}
                            </P>
                        </div>
                    }

                </div>

                {carrierName === reseller.name &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }
                    </>
                }

                {carrierName === 'Cycloon' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Aangetekend aanmelden als'
                            options={[
                                {value: '', title: 'Handtekening voor ontvangst + Niet bij buren'},
                                {value: '5', title: 'Aangetekend pakket'}
                            ]}
                            value={settings.signedParcelProduct || ''}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedParcelProduct')}
                        />

                        <Toggle
                            label='Lagere voorkeur op vrijdag'
                            info='Cycloon krijgt een lagere voorkeur dan andere vervoerders voor pakketten die worden aangemeld op vrijdag, dit voorkomt vertraging van een pakket omdat Cycloon op zaterdag niet bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.noParcelsOnFriday}
                            onChange={this.onChangeCarrierChecked.bind(this, 'noParcelsOnFriday')}
                        />

                        <Toggle
                            label='Alleen Cycloon bezorgingen per fiets'
                            info='Cycloon is alleen beschikbaar voor zendingen die op de fiets worden bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.deliveredByBikeOnly}
                            onChange={this.onChangeCarrierChecked.bind(this, 'deliveredByBikeOnly')}
                        />
                    </>
                }

                {carrierName === 'DHL' &&
                    <>
                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />

                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'DHL-MSG' &&
                    <>
                        <Toggle
                            label='Aangetekend optie activeren'
                            info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld met alleen de optie \'Handtekening voor ontvangst\'.'}
                            checked={settings.signedEnabled}
                            onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                        />

                        <Input
                            label='Standaard product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.defaultProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultProductSKU')}
                        />

                        <Input
                            label='Brievenbuspakket product SKU'
                            info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                            value={settings.mailboxProductSKU}
                            onChange={this.onChangeCarrierValue.bind(this, 'mailboxProductSKU')}
                        />
                    </>
                }

                {['DHL-MSG', 'DHL', 'Cycloon'].includes(carrierName) &&
                    <Toggle
                        label='Lagere voorkeur voor bedrijfsadres'
                        info={`${carrierName} krijgt een lagere voorkeur dan andere vervoerders voor pakketten die worden bezorgd op een bedrijfsadres. Deze instelling kan op klant niveau worden overschreven.`}
                        checked={settings.noParcelsForBusinessAddress}
                        onChange={this.onChangeCarrierChecked.bind(this, 'noParcelsForBusinessAddress')}
                    />
                }

                {carrierName === 'Peddler' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Toggle
                            label='Toon sorteercodes op label'
                            checked={settings.sortCodesOnLabel}
                            onChange={this.onChangeCarrierChecked.bind(this, 'sortCodesOnLabel')}
                        />


                    </>
                }

                {carrierName === 'PostNL' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder.'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Standaard emailadres Track & Trace emails'
                            info='Voor PostNL zendingen is het meesturen van een emailadres verplicht. Als een zending geen emailadres heeft wordt de gekozen optie gebruikt.'
                            allowEmptyValue
                            options={[
                                {value: 'resellerEmail', title: reseller.settings.companyAddress.email},
                                {value: 'customerEmail', title: 'Emailadres klant'},
                                {value: 'custom', title: 'Anders'}
                            ]}
                            value={settings.defaultTTEmailAddress}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultTTEmailAddress')}
                        />

                        {settings.defaultTTEmailAddress === 'custom' &&
                            <Input
                                label='Emailadres'
                                value={settings.customDefaultTTEmailAddress}
                                onChange={this.onChangeCarrierValue.bind(this, 'customDefaultTTEmailAddress')}
                            />
                        }

                        <Select
                            label='Aangetekend aanmelden als'
                            options={[
                                {value: '3089', title: '3089 Handtekening voor ontvangst, Alleen huisadres'},
                                {value: '1020', title: '1020 Aangetekend pakket'}
                            ]}
                            value={settings.signedParcelProductCode || '1020'}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedParcelProductCode')}
                        />

                        <Select
                            label='Aangetekende brief aanmelden als'
                            options={[
                                {value: '1010', title: '1010 Aangetekende brief'},
                                {value: '1410', title: '1410 Aangetekende brief partijenpost'},
                                {value: '3089', title: '3089 Handtekening voor ontvangst, Alleen huisadres'}
                            ]}
                            value={settings.signedLetterProductCode || '1010'}
                            onChange={this.onChangeCarrierValue.bind(this, 'signedLetterProductCode')}
                        />

                        <Select
                            label='Brievenbuspakket buitenland aanmelden als'
                            options={[
                                {value: '6350', title: '6350 Packet Track & Trace'},
                                {value: '6972', title: '6972 Boxable Track & Trace'}
                            ]}
                            value={settings.mailboxAbroadProductCode || '6350'}
                            onChange={this.onChangeCarrierValue.bind(this, 'mailboxAbroadProductCode')}
                        />

                        <Select
                            label='Buitenland zendingen binnen EU'
                            options={[
                                {value: '', title: 'Niet standaard verzekerd'},
                                {value: 'trackTraceInsured', title: 'Track & trace Verzekerd'},
                                {value: 'trackTraceInsuredPlus', title: 'Track & trace Verzekerd Plus'}
                            ]}
                            value={settings.foreignInsuranceType || ''}
                            onChange={this.onChangeCarrierValue.bind(this, 'foreignInsuranceType')}
                        />

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'Skynet' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} wordt gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'GLS' &&
                    <>
                        {!reseller.isShop &&
                            <Input
                                label='Crediteurnummer'
                                info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                value={settings.creditorCode}
                                onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                            />
                        }

                        <Select
                            label='Standaard emailadres Track & Trace emails'
                            info='Voor GLS zendingen is het meesturen van een emailadres verplicht. Als een zending geen emailadres heeft wordt de gekozen optie gebruikt.'
                            allowEmptyValue
                            options={[
                                {value: 'resellerEmail', title: reseller.settings.companyAddress.email},
                                {value: 'customerEmail', title: 'Emailadres klant'},
                                {value: 'custom', title: 'Anders'}
                            ]}
                            value={settings.defaultTTEmailAddress}
                            onChange={this.onChangeCarrierValue.bind(this, 'defaultTTEmailAddress')}
                        />

                        {settings.defaultTTEmailAddress === 'custom' &&
                            <Input
                                label='Emailadres'
                                value={settings.customDefaultTTEmailAddress}
                                onChange={this.onChangeCarrierValue.bind(this, 'customDefaultTTEmailAddress')}
                            />
                        }

                        <Toggle
                            label='Gebruik Economy Parcel als standaard'
                            checked={settings.useEconomyParcel}
                            onChange={this.onChangeCarrierChecked.bind(this, 'useEconomyParcel')}
                        />

                        <Toggle
                            label='Zaterdagbezorging'
                            info='Zendingen die op vrijdag worden aangemeld worden op zaterdag bezorgd. Deze instelling kan op klant niveau worden overschreven.'
                            checked={settings.glsSaturdayDelivery}
                            onChange={this.onChangeCarrierChecked.bind(this, 'glsSaturdayDelivery')}
                        />

                        {!reseller.isShop &&
                            <Toggle
                                label={`Retouren via ${reseller.settings?.accountName || reseller.name}`}
                                info={`Het adres van ${reseller.settings?.accountName || reseller.name} gebruikt als afzender op de verzendlabels.`}
                                checked={settings.returnsViaReseller}
                                onChange={this.onChangeCarrierChecked.bind(this, 'returnsViaReseller')}
                            />
                        }
                    </>
                }

                {carrierName === 'PostNL-MSG' &&
                <>
                    <Toggle
                        label='Aangetekend optie activeren'
                        info={'De optie \'Aangetekend\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                        checked={settings.signedEnabled}
                        onChange={this.onChangeCarrierChecked.bind(this, 'signedEnabled')}
                    />
                    <Toggle
                        label='Aangetekende brief optie activeren'
                        info={'De optie \'Aangetekende brief\' wordt voor deze vervoerder aangemeld als combinatie van de opties \'Handtekening voor ontvangst\' en \'Niet bij buren\'.'}
                        checked={settings.signedLetterEnabled}
                        onChange={this.onChangeCarrierChecked.bind(this, 'signedLetterEnabled')}
                    />

                    <Input
                        label='Standaard product SKU'
                        info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                        value={settings.defaultProductSKU}
                        onChange={this.onChangeCarrierValue.bind(this, 'defaultProductSKU')}
                    />

                    <Input
                        label='Brievenbuspakket product SKU'
                        info='Vul hier de SKU code in zoals in het Monta account is gedefineerd.'
                        value={settings.mailboxProductSKU}
                        onChange={this.onChangeCarrierValue.bind(this, 'mailboxProductSKU')}
                    />
                </>
                }

                {carrier?.type === 'partner' &&
                    <>
                        {!reseller.isShop &&
                            <>
                                <Input
                                    label='Crediteurnummer'
                                    info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                                    value={settings.creditorCode}
                                    onChange={this.onChangeCarrierValue.bind(this, 'creditorCode')}
                                />
                            </>
                        }
                    </>
                }


                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 90}}>
                    {settings.enabled &&
                        <Button variant='error' onClick={this.onDisable.bind(this)}>
                            Deactiveer
                        </Button>
                    }

                    <div style={{flex: 1}}/>

                    <Button variant='text' onClick={this.close.bind(this)}>
                        Annuleer
                    </Button>

                    <Button onClick={this.onSubmit.bind(this)}>
                        {settings.enabled ? 'Opslaan' : 'Activeer'}
                    </Button>
                </div>
            </Modal>
        )
    }
}


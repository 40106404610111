// @ts-check

import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Row, DatePicker, Input, IconButton} from '../UI/index.js'


/**
 * @typedef {Object} VehicleNotAvailableProps
 * @property {notAvailableDateTimeRange} range
 * @property {(range: notAvailableDateTimeRange) => void} onChange
 * @property {() => void} onRemove
 */

/**
 * @component
 *
 * @param {VehicleNotAvailableProps} props
 * @returns
 */
export default function VehicleNotAvailable({range, onChange, onRemove}) {
    const [startDate, setStartDate] = useState(range.start || moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(range.end || moment().format('YYYY-MM-DD'))
    const [reasonLocal, setReasonLocal] = useState(range.reason || '')
    const [reason, setReason] = useState(range.reason || '')

    useEffect(() => {
        onChange({start: startDate, end: endDate, reason})
    }, [startDate, endDate, reason])

    return (
        <Row style={{alignItems: 'baseline'}}>
            <DatePicker
                style={{marginLeft: 0}}
                label='Datum'
                minDate={moment()}
                value={startDate}
                range={{startDate, endDate}}
                onChange={(event) => {
                    setStartDate(event.startDate)
                    setEndDate(event.endDate)
                }}
            />

            <Input
                label='Reden'
                value={reasonLocal}
                onChange={(event) => setReasonLocal(event.target.value)}
                onBlur={() => setReason(reasonLocal)}
            />

            <IconButton
                icon='mdi mdi-delete'
                onClick={onRemove}
            />
        </Row>
    )
}

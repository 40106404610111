import React from 'react'


import {P, Popover} from './index.js'

class Info extends React.Component {
    onMouseEnter() {
        this.popover.show()
    }

    onMouseLeave() {
        this.popover.close()
    }

    render() {
        const {icon, text, iconColor, placement} = this.props

        return (
            <Popover
                ref={(ref) => this.popover = ref}
                content={
                    <P
                        style={{
                            padding: 10,
                            width: 300,
                            whiteSpace: 'normal'
                        }}
                    >
                        {text}
                    </P>
                }
                placement={placement}
            >
                <i
                    style={{marginLeft: 6, color: iconColor}}
                    className={icon || 'mdi mdi-information'}
                    onMouseEnter={this.onMouseEnter.bind(this)}
                    onMouseLeave={this.onMouseLeave.bind(this)}
                />
            </Popover>
        )
    }
}

export default (Info)

import React from 'react'

import OrderActions from '../../actions/OrderActions.js'

import dbCodes from '../../../server/dbCodes.js'
import {Alert, Colors, IconButton, S1, P, S2} from '../UI/index.js'
import EditCollo from './EditCollo.js'


class Collo extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            edit: false,
            hover: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = structuredClone(this.initialState)
    }

    saveCollo(collo) {
        const {order} = this.props


        this.setState({hover: false, edit: false, error: ''})

        OrderActions.editCollo(order._id, collo, (err, order) => {
            if (err) {
                this.setState({error: err})
            } else {
                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {hover, edit, error} = this.state
        const {order, addressIndex, colloIndex, index, colli, disabled, onClickPrintLabels} = this.props

        const collo = order.colli[colloIndex]


        return (
            <>
                <div style={{display: 'flex', minHeight: 37, borderRadius: index === 0 ? '4px 4px 0px 0px' : index === colli.length ? '0px 0px 4px 4px' : 0, borderTop: index === 0 ? '' : `1px solid ${Colors.border}`}}>

                    {edit &&
                    <EditCollo
                        order={order}
                        collo={collo}
                        onSave={this.saveCollo.bind(this)}
                        ref={(ref) => this.editCollo = ref}
                    />
                    }

                    {!edit &&
                    <>
                        <div
                            style={{display: 'flex', flex: 1, borderRight: `1px dotted ${Colors.border}`}}
                            onMouseEnter={() => this.setState({hover: true})}
                            onMouseLeave={() => this.setState({hover: false})}
                        >
                            <div style={{flex: 1, padding: 6}} >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <S1 style={{width: 20, marginRight: 12}}>{collo.index + 1}</S1>
                                    <P disabled={disabled}>{collo.barcode || 'Geen barcode'}</P>
                                </div>

                                {!!(collo.length || collo.width || collo.height) &&
                                <P disabled={disabled} style={{marginLeft: 36}}>{`${(collo.length / 10).toString().replace('.', ',')} x ${(collo.width / 10).toString().replace('.', ',')} x ${(collo.height / 10).toString().replace('.', ',')} cm`}</P>
                                }


                                {collo.description &&
                                <div style={{display: 'flex'}}>
                                    <i style={{width: 20, marginRight: 12, fontSize: 16}} className='mdi mdi-message-outline'/>
                                    <P disabled={disabled} >{collo.description}</P>
                                </div>
                                }
                            </div>

                            {hover ?
                                <div style={{display: 'flex', flexDirection: 'column', width: 150, alignItems: 'flex-end'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        {onClickPrintLabels &&
                                        <IconButton
                                            onClick={() => onClickPrintLabels(order._id, null, collo.index)}
                                            icon='mdi mdi-printer'
                                        />
                                        }
                                        <IconButton
                                            icon='mdi mdi-pencil'
                                            onClick={() => this.setState({edit: !edit})}
                                        />
                                    </div>
                                    {!!collo.weight &&
                                    <P disabled={disabled} style={{paddingRight: 6, paddingBottom: 6, visibility: 'hidden', ...(!collo.type && {display: 'none'})}}>{`${collo.weight} g`}</P>
                                    }
                                </div>:
                                <div style={{display: 'flex', flexDirection: 'column', width: 150, padding: 6, alignItems: 'flex-end'}}>
                                    {collo.type &&
                                    <P disabled={disabled} style={collo.weight ? {minHeight: 30} : undefined}>{dbCodes.colloTypes[collo.type] || collo.type}</P>
                                    }

                                    {!!collo.weight &&
                                    <P disabled={disabled}>{`${collo.weight} g`}</P>
                                    }
                                </div>
                            }

                        </div>

                        <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}>


                            {addressIndex === collo.pickupAddressIndex &&
                            <div style={{display: 'flex', alignItems: 'center', padding: 6}}>
                                <div style={{marginRight: 12}}>
                                    <P style={{textAlign: 'right'}} disabled={disabled} >{Colors.colloStatusColor('pickup', collo).status}</P>


                                    <S2 style={{textAlign: 'right'}} disabled={disabled} >{Colors.colloStatusColor('pickup', collo).reason}{collo.status === 'notcollected' && collo.reasonSpecification ? `${collo.reason ? ' - ' : ''}${collo.reasonSpecification}` : ''}</S2>
                                </div>


                                <i style={{fontSize: 12, color: Colors.colloStatusColor('pickup', collo).statusColor}} className='mdi mdi-circle'/>
                            </div>
                            }

                            {addressIndex === collo.deliveryAddressIndex &&
                            <div style={{display: 'flex', alignItems: 'center', padding: 6}}>
                                <div style={{marginRight: 12}}>
                                    <P style={{textAlign: 'right'}} disabled={disabled} >{Colors.colloStatusColor('delivery', collo).status}</P>

                                    <S2 style={{textAlign: 'right'}} disabled={disabled} >{Colors.colloStatusColor('delivery', collo).reason}{['notsupplied', 'onhold', 'notdelivered', 'missing', 'refused'].includes(collo.status) && collo.reasonSpecification ? `${collo.reason ? ' - ' : ''}${collo.reasonSpecification}` : ''}</S2>
                                </div>

                                <i style={{fontSize: 12, color: Colors.colloStatusColor('delivery', collo).statusColor}} className='mdi mdi-circle'/>

                            </div>
                            }


                        </div>
                    </>
                    }
                </div>

                {error &&
                <Alert variant='error'>{error}</Alert>
                }
            </>

        )
    }
}

export default Collo


import React from 'react'
import _ from 'lodash'

import {Colors, Info, P, ToolTip, Popover, ListItem, FlexRow, Icon, Badge} from '../UI/index.js'

export default class Select extends React.Component {
    constructor(props) {
        super(props)

        let options = props.options

        if (!options) {
            options = props.children.map((child) => {
                if (child && child.props) {
                    return {value: child.props.value, title: child.props.children, disabled: child.props.disabled}
                }
            })
        }

        if (props.allowEmptyValue || props.placeholder) {
            options = [{value: '', title: props.placeholder || ''}, ...options]
        }

        if (props.allowCustomValues) {
            props.values.map((value) => {
                if (!options.find((option) => option.value === value)) {
                    options.push({value, title: value})
                }
            })
        }

        let displayOptions = options.filter((option) => !!option && !option.hidden)

        if (this.props.groupSubvalues) {
            displayOptions = displayOptions.sort((a, b) => {
                const aValueTitle = displayOptions.find((option) => option.value === a.value)?.title
                const bValueTitle = displayOptions.find((option) => option.value === b.value)?.title
                let aStr = `${aValueTitle}${a.value}${a.disabled && a.subvalue ? 'zzz' : ''}${a.subvalue ? ` - ${a.title}` : ''}`
                let bStr = `${bValueTitle}${b.value}${b.disabled && b.subvalue ? 'zzz' : ''}${b.subvalue ? ` - ${b.title}` : ''}`

                if (a.disabled && !a.subvalue) {
                    aStr = `zzz${aStr}`
                }

                if (b.disabled && !b.subvalue) {
                    bStr = `zzz${bStr}`
                }

                return aStr.localeCompare(bStr)
            })
        }

        this.state = {
            focused: false,
            options,
            displayOptions,
            inputValue: '',
            selectedValue: 0,
            showCopyTooltip: false,
            timeout: null
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout)
    }

    componentDidUpdate(prevProps, prevState) {
        const {values, options, groupSubvalues, children=[]} = this.props

        const newKeys = children.map((child) => {
            if (child) {
                return child.key
            }
        })
        const oldKeys = (prevProps.children || []).map((child) => {
            if (child) {
                return child.key
            }
        })

        if (JSON.stringify(values) !== JSON.stringify(prevProps.values) || JSON.stringify(options) !== JSON.stringify(prevProps.options) || JSON.stringify(newKeys) !== JSON.stringify(oldKeys) || prevState.inputValue !== this.state.inputValue) {
            let options = this.props.options

            if (!options) {
                options = this.props.children.map((child) => {
                    if (child && child.props) {
                        return {value: child.props.value, title: child.props.children, disabled: child.props.disabled}
                    }
                })
            }

            if (this.props.allowEmptyValue || this.props.placeholder) {
                options = [{value: '', title: this.props.placeholder || ''}, ...options]
            }

            if (this.props.allowCustomValues) {
                this.props.values.map((value) => {
                    if (!options.find((option) => option.value === value)) {
                        options = [...options, {value, title: value}]
                    }
                })
            }

            let displayOptions = _.filter(options, (option) => {
                if (!option || option?.hidden) {
                    return false
                }

                if (this.props.values) {
                    return option && typeof option.title === 'string' && this.props.values.indexOf(option.value) === -1 && option.title.toLowerCase().indexOf(this.state.inputValue.toLowerCase()) > -1
                } else {
                    return option && typeof option.title === 'string' && option.title.toLowerCase().indexOf(this.state.inputValue.toLowerCase()) > -1
                }
            })

            if (groupSubvalues) {
                displayOptions = displayOptions.sort((a, b) => {
                    const aValueTitle = displayOptions.find((option) => option.value === a.value)?.title
                    const bValueTitle = displayOptions.find((option) => option.value === b.value)?.title
                    let aStr = `${aValueTitle}${a.value}${a.disabled && a.subvalue ? 'zzz' : ''}${a.subvalue ? ` - ${a.title}` : ''}`
                    let bStr = `${bValueTitle}${b.value}${b.disabled && b.subvalue ? 'zzz' : ''}${b.subvalue ? ` - ${b.title}` : ''}`

                    if (a.disabled && !a.subvalue) {
                        aStr = `zzz${aStr}`
                    }

                    if (b.disabled && !b.subvalue) {
                        bStr = `zzz${bStr}`
                    }

                    return aStr.localeCompare(bStr)
                })
            }

            this.setState({options, displayOptions, selectedValue: 0})
        }
    }

    onChangeInput(event) {
        const {values, noSort, allowFreeMode, openAfter=0} = this.props
        const {options} = this.state

        if (this.popover.closed() && event.target.value.length >= openAfter) {
            this.popover.show()
        }

        if (!this.popover.closed() && event.target.value.length < openAfter) {
            this.popover.close()
        }

        if (event.target.value.indexOf(';') > -1 && values) {
            const inputValues = event.target.value.split(';')

            inputValues.map((inputValue) => {
                const option = _.find(options, (option) => {
                    return inputValue === option?.value
                })

                if (option && values.indexOf(option.value) === -1) {
                    values.push(option.value)
                }
            })

            if (!noSort) {
                event.target.values = _.sortBy([...values], (value) => _.findIndex(options, (option) => option?.value === value))
            } else {
                event.target.values = [...values]
            }

            this.setState({inputValue: ''})
            this.props.onChange(event)
        } else {
            const inputValue = event.target.value

            let displayOptions = options.filter((option) => {
                if (!option || option.hidden) {
                    return false
                }

                if (values) {
                    return option && typeof option.title === 'string' && values.indexOf(option.value) === -1 && option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                } else {
                    return option && typeof option.title === 'string' && option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                }
            })

            if (this.props.groupSubvalues) {
                displayOptions = displayOptions.sort((a, b) => {
                    const aValueTitle = displayOptions.find((option) => option.value === a.value)?.title
                    const bValueTitle = displayOptions.find((option) => option.value === b.value)?.title
                    let aStr = `${aValueTitle}${a.value}${a.disabled && a.subvalue ? 'zzz' : ''}${a.subvalue ? ` - ${a.title}` : ''}`
                    let bStr = `${bValueTitle}${b.value}${b.disabled && b.subvalue ? 'zzz' : ''}${b.subvalue ? ` - ${b.title}` : ''}`

                    if (a.disabled && !a.subvalue) {
                        aStr = `zzz${aStr}`
                    }

                    if (b.disabled && !b.subvalue) {
                        bStr = `zzz${bStr}`
                    }

                    return aStr.localeCompare(bStr)
                })
            }

            if (allowFreeMode) {
                event.target.value = inputValue
                this.setState({inputValue, displayOptions})
                this.props.onChange(event)
            } else {
                this.setState({inputValue, displayOptions})
            }
        }
    }

    onKeyDown(event) {
        const {displayOptions, selectedValue, inputValue, openAfter=0} = this.state
        const {values} = this.props

        if (values && inputValue === '' && (event.keyCode === 8 || event.which === 8)) {
            event.target.values = values.slice(0, -1)
            this.props.onChange(event)
        }

        if ((event.keyCode === 40 || event.which === 40) && selectedValue < displayOptions.length- 1) { // Down
            event.preventDefault()

            if (this.popover.closed() && inputValue.length >= openAfter) {
                this.popover.show()
            } else {
                this.setState({selectedValue: selectedValue+1})
            }
        }

        if ((event.keyCode === 38 || event.which === 38) && selectedValue > 0) { // Up
            event.preventDefault()

            this.setState({selectedValue: selectedValue-1})
        }
    }

    onKeyPress(event) {
        const {options, displayOptions, selectedValue} = this.state
        const {values} = this.props


        if (event.keyCode === 13 || event.which === 13) { // Enter
            event.preventDefault()

            const option = displayOptions[selectedValue]

            if (values) {
                event.target.values = _.sortBy([...values, option?.value], (value) => _.findIndex(options, (option) => option?.value === value))
            } else {
                event.target.value = option?.value.toString() || ''
                event.target.subvalue = option?.subvalue?.toString() || ''
            }

            this.setState({inputValue: '', selectedValue: 0})
            this.props.onChange(event)
            this.popover.close()
        }
    }

    onClickAddValue(event) {
        event.preventDefault()

        const values = [...this.props.values]
        const options = [...this.props.options]

        const value = this.state.inputValue


        if (values && value) {
            options.push({value, title: value})
            event.target.values = _.sortBy([...values, value], (value) => _.findIndex(options, (option) => option?.value === value))

            this.setState({inputValue: '', selectedValue: 0})
            this.props.onChange(event)
            this.popover.close()
        }
    }


    onClickOption(option, event) {
        const {options} = this.state
        const {values, noSort} = this.props

        if (values) {
            const index = values.findIndex((item) => item === option?.value.toString())

            if (index === -1) {
                if (!noSort) {
                    event.target.values = _.sortBy([...values, option?.value.toString()], (value) => _.findIndex(options, (option) => option?.value.toString() === value))
                } else {
                    event.target.values = [...values, option?.value.toString()]
                }
            } else {
                event.target.values = [...values.slice(0, index), ...values.slice(index + 1)]
            }
        } else {
            event.target.value = option?.value.toString() || ''
            event.target.subvalue = option?.subvalue?.toString() || ''
        }

        this.setState({inputValue: '', selectedValue: 0})
        this.props.onChange(event)
        this.popover.close()
    }

    onClickValue(index, event) {
        event.stopPropagation()
        const values = [...this.props.values]

        values.splice(index, 1)
        event.target.values = values
        this.props.onChange(event)
    }

    onClickCopyValues(event) {
        event.stopPropagation()
        const {values} = this.props

        const text = `${values.join(';')};`

        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                this.setState({
                    showCopyTooltip: true,
                    timeout: setTimeout(() => {
                        this.setState({showCopyTooltip: false})
                    }, 1200)
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    focus() {
        this.input?.focus()
    }

    blur() {
        this.input?.blur()
    }

    getRef() {
        return this.input
    }

    setInvalid() {
        this.setState({isInvalid: true})
        this.focus()
    }

    boldMatch(text, query) {
        const parts = text.split(new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi')) // .replace to escape special characters from regex
        return parts.map((part, i) => (
            <span key={i} style={part?.toLowerCase() === query?.toLowerCase() ? {fontWeight: 'bold'} : {}}>
                {part}
            </span>
        ))
    }

    render() {
        const {id, style = {}, inputStyle, label, info, infoIcon, value = '', subvalue='', values, openAfter=0, readOnly, disabled, isInvalid, onFocus, onBlur, copy, prepend, inputMode, allowFreeMode, allowCustomValues, maxOptions = 400, smartPlacement, showOnlyIcon} = this.props
        const {focused, options, displayOptions, inputValue, selectedValue, showCopyTooltip} = this.state


        const option = _.find(options, (option) => option && value === option.value && subvalue === (option.subvalue || ''))

        const title = option ? option.title : (allowFreeMode ? value : '')

        const focusedState = focused || inputValue || title || (values && values.length > 0)

        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexShrink: 0,
            position: 'relative',
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 12,
            height: values ? 'auto' : 42
        }

        if (style && style.width) {
            delete defaultStyle.flex
        }

        const borderColor = disabled && !readOnly ? Colors.border : isInvalid ? Colors.errorBright : (focusedState && focused) ? Colors.buttonSolid : Colors.border

        const wrapperStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            height: values ? 'auto' : 42,
            minHeight: 42,
            width: '100%',
            position: 'relative',
            paddingTop: 12,
            paddingRight: copy ? 24 : 10,
            borderBottom: `1px solid ${borderColor}`,
            borderRadius: '4px 4px 0px 0px',
            background: readOnly ? 'transparent' : disabled ? Colors.grey20 : Colors.backgroundWhite,
            color: disabled && !readOnly? Colors.textMedium : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text'
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            paddingLeft: prepend && !focusedState ? 24 : 10,
            top: focusedState ? 2 : 10,
            fontSize: focusedState ? 12 : 14,
            color: Colors.textMedium,
            cursor: disabled || readOnly ? 'default' : 'text',
            transition: 'all .2s ease'
        }

        const defaultInputStyle = {
            fontSize: 14,
            background: 'transparent',
            height: focusedState && focused ? 28 : 29,
            paddingLeft: prepend ? 24 : 10,
            minWidth: 100,
            width: inputValue.length * 12,
            maxWidth: 'calc(100% - 15px)',
            outline: 'none',
            border: 'none',
            color: disabled && !readOnly? Colors.textMedium : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text',
            caretColor: inputMode === 'none' ? 'transparent' : 'default'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        inputStyle && Object.keys(inputStyle).map((key) => {
            defaultInputStyle[key] = inputStyle[key]
        })

        return (
            <div
                style={defaultStyle}
                onMouseDown={(event) => event.stopPropagation()}
                onClick={() => this.input?.focus()}
            >
                <Popover
                    ref={(ref) => this.popover = ref}
                    disabled={disabled || readOnly}
                    noClickOpen
                    smartPlacement={smartPlacement}
                    content={
                        <div style={{maxHeight: 400, overflowY: 'auto'}}>
                            {displayOptions.length ?
                                focused && displayOptions.slice(0, maxOptions).map((option, index) => {
                                    if (option) {
                                        return (
                                            <ListItem
                                                size='sm'
                                                style={{fontWeight: option.bold ? 'bold' : 'normal', paddingLeft: option.subvalue ? 15 : undefined}}
                                                key={`${index}select${id || ''}`}
                                                focused={displayOptions[selectedValue] && displayOptions[selectedValue].value === option.value && displayOptions[selectedValue].subvalue === option.subvalue}
                                                active={(value === option.value && subvalue === (option.subvalue || '')) || values?.includes(option.value)}
                                                onMouseDown={this.onClickOption.bind(this, option)}
                                                disabled={option.disabled}
                                            >
                                                <FlexRow style={{alignItems: 'center'}}>
                                                    {option.icon &&
                                                        <Icon
                                                            style={{color: option.iconColor, marginRight: 6}}
                                                            icon={option.icon}
                                                        />
                                                    }

                                                    {option.badge &&
                                                        <Badge
                                                            size='sm'
                                                            style={{marginRight: 6}}
                                                            color={option.badgeColor}
                                                            textColor={option.badgeTextColor}
                                                        >
                                                            {option.badge}
                                                        </Badge>
                                                    }

                                                    {typeof option.title === 'string' ?
                                                        <span>{this.boldMatch(option.title, inputValue)}</span> :
                                                        option.title
                                                    }


                                                </FlexRow>


                                            </ListItem>
                                        )
                                    }
                                }) :
                                <ListItem size='sm'><P ellipsis>Geen opties</P></ListItem>
                            }
                            {allowCustomValues && inputValue &&
                                <ListItem
                                    size='sm'
                                    icon='mdi mdi-plus-circle'
                                    onMouseDown={(event) => this.onClickAddValue(event)}
                                >
                                    <P ellipsis>
                                        {`Voeg "${inputValue}" toe`}
                                    </P>
                                </ListItem>
                            }
                        </div>
                    }
                >
                    <div style={wrapperStyle} onClick={() => this.input.focus()}>
                        <div style={labelStyle} onClick={() => this.input.focus()}>
                            <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                            {info && <Info icon={infoIcon} text={info}/>}
                        </div>

                        {values && values.map((value, index) => {
                            const option = _.find(options, (option) => option && value == option.value)

                            if (option) {
                                return (
                                    <div
                                        key={value}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 22,
                                            marginTop: 3,
                                            marginBottom: 2,
                                            marginLeft: 12,
                                            fontSize: 12,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            borderRadius: 11,
                                            cursor: 'pointer',
                                            background: Colors.grey20,
                                            color: disabled ? Colors.textMedium : Colors.textDark
                                        }}
                                        onClick={!disabled && !readOnly ? this.onClickValue.bind(this, index) : undefined}
                                    >
                                        {option.title}
                                    </div>
                                )
                            }
                        })}

                        {prepend?.includes('mdi') &&
                            <i
                                style={{position: 'absolute', left: 10, top: 10}}
                                className={prepend}
                            />
                        }

                        {prepend && !prepend.includes('mdi') &&
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 6,
                                    bottom: 6,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 20,
                                    height: 20
                                }}
                            >
                                {prepend}
                            </div>
                        }

                        <input
                            id={id}
                            style={defaultInputStyle}
                            disabled={disabled || readOnly}
                            value={inputValue}
                            inputMode={inputMode}
                            onFocus={() => {
                                if (!disabled && !readOnly) {
                                    if (inputValue.length >= openAfter) {
                                        this.popover.show()
                                    }
                                    this.setState({focused: true})
                                    onFocus && onFocus()
                                }
                            }}
                            onBlur={() => {
                                if (allowFreeMode) {
                                    this.setState({focused: false})
                                } else {
                                    this.setState({focused: false, inputValue: ''})
                                }
                                this.popover.close()
                                onBlur && onBlur()
                            }}
                            onKeyDown={this.onKeyDown.bind(this)}
                            onKeyPress={this.onKeyPress.bind(this)}
                            onChange={this.onChangeInput.bind(this)}
                            ref={(ref) => this.input = ref}
                        />

                        {!values && !inputValue &&
                            <FlexRow style={{position: 'absolute', left: prepend ? 36 : 12, bottom: focusedState && focused ? 5 : 6, paddingRight: 20}}>
                                {option?.icon &&
                                    <Icon
                                        style={{color: option.iconColor, marginRight: 6, ...(showOnlyIcon && {fontSize: 24})}}
                                        icon={option.icon}
                                    />
                                }

                                {option?.badge &&
                                    <Badge
                                        style={{marginRight: 6}}
                                        color={option.badgeColor}
                                        textColor={option.badgeTextColor}
                                    >
                                        {option.badge}
                                    </Badge>
                                }

                                {(!showOnlyIcon || !option?.icon) &&
                                    <P
                                        style={{
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            color: disabled && !readOnly ? Colors.textMedium : Colors.textDark
                                        }}
                                        onClick={() => this.input.focus()}
                                    >
                                        {title}
                                    </P>
                                }
                            </FlexRow>
                        }

                        {option?.info &&
                            <div style={{position: 'absolute', right: 30, top: 10}}>
                                <Info iconColor={Colors.buttonSolid} text={option.info}/>
                            </div>
                        }

                        {copy &&
                            <i
                                style={{position: 'absolute', right: option?.info ? 55 : 25, top: 10, cursor: 'pointer', color: disabled && !readOnly ? Colors.textMedium : Colors.textDark}} onClick={this.onClickCopyValues.bind(this)}
                                className='mdi mdi-content-copy'
                            />
                        }

                        {!readOnly &&
                            <i
                                style={{position: 'absolute', right: 10, top: 10, color: disabled && !readOnly ? Colors.textMedium : Colors.textDark}} onClick={() => this.input.focus()}
                                className='mdi mdi-menu-down'
                            />
                        }

                    </div>
                </Popover>

                {copy &&
                    <ToolTip visible={showCopyTooltip} text='Gekopieerd naar clipboard'></ToolTip>
                }
            </div>
        )
    }
}


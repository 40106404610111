export default
`­Beste {naam},

Bedankt voor het aanmelden van je retourzending.
Het verzendlabel vind je in de bijlage.

Print het label, plak deze op het pakket en lever deze in bij een inleverpunt van {vervoerder}. De zending is vervolgens te volgen via de Track & Trace link onderaan deze email.

Met vriendelijke groet,

{bedrijfsnaam}`

import React from 'react'


import {DatePicker} from '../UI/index.js'

class Datepicker extends React.Component {
    constructor(props) {
        super(props)
    }

    onChangeDate(range) {
        const {onChange} = this.props
        const startDate = range.startDate
        const endDate = range.endDate

        onChange(startDate, endDate)
    }

    render() {
        const {startDate, endDate} = this.props

        return (
            <div style={{display: 'flex', marginRight: 12}}>
                {startDate && endDate &&
                    <DatePicker label='Datum' style={{width: 200, marginRight: 6}} onChange={this.onChangeDate.bind(this)} value={startDate} range={{startDate, endDate}}/>
                }
            </div>
        )
    }
}

export default (Datepicker)

import React from 'react'


class ScrollView extends React.Component {
    render() {
        const {children, style} = this.props

        const defaultStyle = {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
            width: '100%'
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                {children}
            </div>

        )
    }
}

export default (ScrollView)

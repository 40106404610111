import React from 'react'

import {IconButton} from './index.js'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'

const DragHandle = SortableHandle(() => <IconButton style={{color: 'black'}} onClick={(event) => event.preventDefault()}><i className='mdi mdi-drag-horizontal-variant'/></IconButton>)

const SortableItem = SortableElement(({key, style, item, renderItem, elementIndex}) => {
    return (
        <div key={key} style={{...style, zIndex: 99999}}>
            {renderItem(item, DragHandle, elementIndex)}
        </div>
    )
})

const SortableList = SortableContainer(({style, items, renderItem}) => {
    return (
        <ul style={{width: '100%', padding: 0, margin: 0}}>
            {items.map((item, index) => {
                return (
                    <SortableItem
                        key={`item${index}`}
                        style={style}
                        index={index}
                        elementIndex={index}
                        item={items[index]}
                        renderItem={renderItem}
                    />
                )
            })}
        </ul>
    )
})


class DraggableList extends React.Component {
    onSortEnd({oldIndex, newIndex}) {
        const {onChange} = this.props
        const items = [...this.props.items]

        if (oldIndex === newIndex) {
            return
        }

        if (newIndex >= 0 && newIndex < items.length) {
            const element = items[oldIndex]
            items.splice(oldIndex, 1)
            items.splice(newIndex, 0, element)
            onChange(items)
        }
    }

    render() {
        const {items, renderItem, noDraghandle} = this.props

        return 	(
            <SortableList
                lockAxis='y'
                useDragHandle={!noDraghandle}
                items={items || []}
                renderItem={renderItem}
                onSortEnd={this.onSortEnd.bind(this)}
            />
        )
    }
}


export default DraggableList

export default function calcDistance(origin, destination) {
    const R = 6371 // Radius of the earth in km
    const dLat = (destination.lat - origin.lat) * Math.PI / 180 // deg2rad below
    const dLng = (destination.lng - origin.lng) * Math.PI / 180
    const a =
        0.5 - Math.cos(dLat) / 2 +
        Math.cos(origin.lat * Math.PI / 180) *
        Math.cos(destination.lat * Math.PI / 180) *
        (1 - Math.cos(dLng)) / 2


    return R * 2 * Math.asin(Math.sqrt(a)) * 1000
}

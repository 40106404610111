import React from 'react'
import moment from 'moment'

import Button from './Button.js'
import Colors from './Colors.js'
import IconButton from './IconButton.js'
import S1 from './fonts/S1.js'
import Popover from './Popover.js'
import Input from './Input.js'


const monthLabels = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
const dayLabels = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']

class DatePicker extends React.Component {
    constructor(props) {
        super(props)

        if (props.range) {
            props.range.clickedDate = ''
        }

        this.state = {
            inputFocused: false,
            timeout: null,
            value: props.range && props.range.startDate !== props.range.endDate ? `${moment(props.range.startDate).format('DD-MM-YY')} t/m ${moment(props.range.endDate).format('DD-MM-YY')}` : props.range ? moment(props.value).format('DD-MM-YY') : moment(props.value).format('DD-MM-YY'),
            displayDate: props.value,
            dateHover: {},
            range: props.range,
            mode: ''
        }
    }

    componentDidUpdate(prevProps) {
        const {value, range} = this.props
        if (prevProps.value !== value) {
            if (range) {
                const newRange = {
                    startDate: range.startDate,
                    endDate: range.endDate,
                    clickedDate: range.startDate
                }
                if (range.startDate !== range.endDate) {
                    this.setState({value: `${moment(range.startDate).format('DD-MM-YY')} t/m ${moment(range.endDate).format('DD-MM-YY')}`, range: newRange, displayDate: range.startDate})
                } else {
                    this.setState({value: `${moment(range.startDate).format('DD-MM-YY')}`, displayDate: range.startDate})
                }
            } else {
                this.setState({value: moment(value).format('DD-MM-YY'), displayDate: value})
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout)
    }

    onFocus(event) {
        if (this.popover.closed()) {
            this.setState({mode: ''})
        }

        this.popover.show()
        this.setState({
            inputFocused: true
        })
    }

    onBlur(event) {
        const {range} = this.props
        this.setState({
            inputFocused: false,
            timeout: setTimeout(() => {
                const {value} = this.props

                if (!this.state.inputFocused && !range) {
                    this.popover.close()
                    this.setState({value: moment(value).format('DD-MM-YY')})
                }
            }, 100)
        })
    }

    onChangeInput(event) {
        const {onChange, range} = this.props

        let value = event.target.value

        if (this.state.value.length === 1 && value.length === 2) {
            value = `${value}-`
        }

        if (this.state.value.length === 4 && value.length === 5) {
            value = `${value}-`
        }

        // scheiding
        if (range && this.state.value.length === 8 && value.length === 9) {
            value = `${value}t/m `
        }

        if (range && this.state.value.length === 13 && value.length === 12) {
            value = value.replace(' t/m', '')
        }

        if (range && this.state.value.length === 14 && value.length === 13) {
            value = value.replace(' t/m ', '')
        }

        if (range && this.state.value.length === 14 && value.length === 15) {
            value = `${value}-`
        }

        if (range && this.state.value.length === 17 && value.length === 18) {
            value = `${value}-`
        }

        if (!range) {
            if (!/^[0-9]{1,2}[-]{2}[0-9]{1,2}$/.test(value)) {
                value = value.replace('--', '-')
            }

            if (/^$|^[0-9]{0,2}[-]?[0-9]{0,2}[-]?[0-9]{0,2}$/.test(value)) {
                this.setState({value})

                if (/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(value) && moment(value, 'DD-MM-YY').isValid()) {
                    event.target.value = moment(value, 'DD-MM-YY').format('YYYY-MM-DD')
                    onChange(event)
                }
            }
        }

        if (range) {
            // regex voor tijdens het typen...
            // en dubblele streepjes
            if (/^$|^[0-9]{0,2}[-]?[0-9]{0,2}[-]?[0-9]{0,2}?[\s]?[^.]{0,3}?[\s]?[0-9]{0,2}[-]?[0-9]{0,2}[-]?[0-9]{0,2}$/.test(value)) {
                value = value.replace('--', '-')
                this.setState({value})
            }

            const dates = value.split(' t/m ')
            if (dates.length === 2) {
                if (moment(dates[0], 'DD-MM-YY').isValid(dates[0]) && moment(dates[1], 'DD-MM-YY').isValid(dates[1])) {
                    range.clickedDate = moment(dates[0], 'DD-MM-YY').format('YYYY-MM-DD')
                    range.startDate = moment(dates[0], 'DD-MM-YY').format('YYYY-MM-DD')
                    range.endDate = moment(dates[1], 'DD-MM-YY').format('YYYY-MM-DD')
                    if (range.endDate < range.startDate) {
                        const startDate = range.startDate
                        range.startDate = range.endDate
                        range.endDate = startDate
                    }

                    // regex voor de dubbele datum
                    if (/^$|^[0-9]{2}[-][0-9]{2}[-][0-9]{2}?[\s]?[^.]{3}?[\s]?[0-9]{2}[-][0-9]{2}[-]?[0-9]{2}$/.test(value)) {
                        if (moment(dates[0], 'DD-MM-YY').isValid(dates[0]) && moment(dates[1], 'DD-MM-YY').isValid(dates[1])) {
                            // event.target.value = `${dates[0]} t/m ${moment(dates[1]).format('DD-MM-YY')}`
                            this.setState({range})
                            onChange(range)
                        }
                    }
                }
            } else {
                if (moment(dates[0], 'DD-MM-YY').isValid(dates[0])) {
                    if (/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(value)) {
                        range.startDate = moment(dates[0], 'DD-MM-YY').format('YYYY-MM-DD')
                        range.endDate = moment(dates[0], 'DD-MM-YY').format('YYYY-MM-DD')
                        // event.target.value = `${moment(dates[0]).format('DD-MM-YY')}`
                        this.setState({range})
                        onChange(range)
                    }
                }
            }
        }
    }

    onClickPrevious() {
        const {displayDate} = this.state

        const newDate = moment(displayDate).subtract(1, 'months').format('YYYY-MM-DD')

        this.setState({displayDate: newDate})

        setTimeout(() => {
            this.formControl.focus()
        }, 1)
    }

    onClickNext() {
        const {displayDate} = this.state

        const newDate = moment(displayDate).add(1, 'months').format('YYYY-MM-DD')

        this.setState({displayDate: newDate})

        setTimeout(() => {
            this.formControl.focus()
        }, 1)
    }

    onClickRange(date, event) {
        const {onChange} = this.props
        const {range, mode} = this.state

        if (range) {
            if (mode !== 'selectRange') {
                range.startDate = date
                range.endDate = date
                range.clickedDate = date

                event.target.value = range
                onChange(range)

                const value = `${moment(range.startDate).format('DD-MM-YY')}`
                this.setState({range: range, mode: 'selectRange', value: value})
            } else {
                range.clickedDate = ''

                event.target.value = range
                onChange(range)

                const value = `${moment(range.startDate).format('DD-MM-YY')} t/m ${moment(range.endDate).format('DD-MM-YY')}`
                this.setState({range: range, mode: '', value: value})
            }

            setTimeout(() => {
                this.formControl.focus()
            }, 1)
        }
    }

    onHoverRange(date, event) {
        const {range, mode} = this.state

        if (mode === 'selectRange') {
            if (date < range.clickedDate) {
                range.startDate = date
                range.endDate = range.clickedDate
            } else {
                range.startDate = range.clickedDate
                range.endDate = date
            }
            this.setState(range)
        }
    }

    onMouseLeaveRange(date, event) {
        const {range, mode} = this.state

        if (mode === 'selectRange') {
            range.startDate = range.clickedDate
            range.endDate = range.clickedDate

            this.setState(range)
        }
    }

    onClickDate(date, event) {
        const {onChange} = this.props

        event.target.value = date

        this.formControl.blur()
        onChange(event)
    }

    onClickToday(event) {
        const {onChange, range} = this.props

        const today = moment().format('YYYY-MM-DD')

        if (range) {
            range.startDate = today
            range.endDate = today
            range.clickedDate = today

            event.target.value = range
            onChange(range)

            const value = `${moment(range.startDate).format('DD-MM-YY')}`
            this.setState({range: range, mode: 'selectRange', value: value, displayDate: today})

            setTimeout(() => {
                this.formControl.focus()
            }, 1)
        } else {
            event.target.value = today
            this.formControl.blur()
            onChange(event)
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13 || event.keyCode === 27) {
            this.popover.close()
            this.formControl.blur()
        }
    }

    render() {
        const {displayDate, mode, range} = this.state
        const {label, value, readOnly, disabled, style={}, minDate, maxDate, startDate, endDate, inputMode} = this.props

        const showTodayButton = (!startDate || moment().format('YYYY-MM-DD') >= startDate) && (!endDate || moment().format('YYYY-MM-DD') <= endDate)

        const today = moment().format('YYYY-MM-DD')
        const year = moment(displayDate).year()
        const month = moment(displayDate).month()
        let date = moment(displayDate).startOf('month').startOf('isoweek').format('YYYY-MM-DD')
        const lastDay = moment(displayDate).endOf('month').endOf('isoweek').format('YYYY-MM-DD')

        const weeks = [[]]

        while (date <= lastDay) {
            let week = weeks[weeks.length-1]

            if (week.length === 7) {
                weeks.push([])
                week = weeks[weeks.length-1]
            }

            week.push(date)
            date = moment(date).add(1, 'day').format('YYYY-MM-DD')
        }

        return (
            <div style={{flex: 1, height: 42, marginBottom: 10, marginLeft: 6, marginRight: 6, ...style}} >
                <Popover
                    popoverStyle={{overflow: 'hidden', width: 280, minWidth: 280}}
                    disabled={disabled || readOnly}
                    noClose={true}
                    ref={(ref) => this.popover = ref}
                    content={
                        <div style={{width: 280, padding: 10}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                                <IconButton onMouseDown={this.onClickPrevious.bind(this)} >
                                    <i className='mdi mdi-chevron-left'/>
                                </IconButton>
                                <div style={{flex: 1, textAlign: 'center'}}>
                                    <S1 style={{textTransform: 'capitalize'}}>{monthLabels[month]} {year}</S1>
                                </div>

                                <IconButton onMouseDown={this.onClickNext.bind(this)} >
                                    <i className='mdi mdi-chevron-right'/>
                                </IconButton>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <table>
                                    <thead>
                                        <tr>
                                            {dayLabels.map((label) => {
                                                return (
                                                    <td
                                                        key={label}
                                                        style={{textAlign: 'center', width: 35, height: 35, fontSize: 12, color: Colors.textMedium}}
                                                    >
                                                        {label}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {weeks.map((week, index) => {
                                            return (
                                                <tr key={`tr-${index}`}>
                                                    {week.map((date, index) => {
                                                        let disabled = false
                                                        const currentMonth = moment(date).month() === moment(displayDate).month()

                                                        if (startDate && date < startDate) {
                                                            disabled = true
                                                        }

                                                        if (endDate && date > endDate) {
                                                            disabled = true
                                                        }

                                                        if (minDate && date < minDate) {
                                                            disabled = true
                                                        }

                                                        if (maxDate && date > maxDate) {
                                                            disabled = true
                                                        }

                                                        const dateStyle = {
                                                            position: 'relative',
                                                            textAlign: 'center',
                                                            cursor: disabled ? 'default' : 'pointer',
                                                            borderRadius: '50%',
                                                            width: 35,
                                                            height: 35,
                                                            fontSize: date === today ? 16 : 14,
                                                            fontWeight: date === today ? 500 : 400,
                                                            background: date === value ? Colors.buttonSolid : 'transparent',
                                                            color: date === value ? 'white' : (currentMonth ? Colors.textDark : Colors.textMedium)
                                                        }

                                                        if (range) {
                                                            // selected startDate / enDate btn
                                                            if (mode !== 'selectRange' && (date === range.startDate || date === range.endDate)) {
                                                                dateStyle.color = Colors.white
                                                                dateStyle.background = Colors.buttonSolid
                                                            }

                                                            if (mode === 'selectRange' && date === range.clickedDate) {
                                                                dateStyle.color = Colors.white
                                                                dateStyle.background = Colors.buttonSolid
                                                            }

                                                            if (mode === 'selectRange' && date !== range.clickedDate && (date === range.endDate || date === range.startDate)) {
                                                                // dateStyle.color = Colors.white
                                                                dateStyle.background = Colors.backgroundSelected
                                                            }
                                                        }

                                                        // background between range
                                                        const rangeStyle = {
                                                            position: 'absolute',
                                                            width: '0',
                                                            height: '100%',
                                                            left: '-50%',
                                                            top: 0,
                                                            zIndex: -1,
                                                            pointerEvents: 'none'
                                                        }

                                                        if (range && (date < range.endDate && date > range.startDate)) {
                                                            rangeStyle.width = '200%'
                                                            rangeStyle.borderTop = `1px solid ${Colors.grey20}`
                                                            rangeStyle.borderBottom = `1px solid ${Colors.grey20}`
                                                            if (mode !== 'selectRange') {
                                                                rangeStyle.background = Colors.backgroundSelected
                                                                rangeStyle.borderTop = 'none'
                                                                rangeStyle.borderBottom = 'none'
                                                            }
                                                        }

                                                        if (range && date === range.startDate && range.startDate !== range.endDate) {
                                                            rangeStyle.width = '100%'
                                                            rangeStyle.left = '50%'
                                                            rangeStyle.borderTop = `1px solid ${Colors.grey20}`
                                                            rangeStyle.borderBottom = `1px solid ${Colors.grey20}`
                                                            if (mode !== 'selectRange') {
                                                                rangeStyle.background = Colors.backgroundSelected
                                                                rangeStyle.borderTop = 'none'
                                                                rangeStyle.borderBottom = 'none'
                                                            }
                                                        }

                                                        if (range && date === range.endDate && range.startDate !== range.endDate) {
                                                            rangeStyle.width = '100%'
                                                            rangeStyle.left = '-50%'
                                                            rangeStyle.borderTop = `1px solid ${Colors.grey20}`
                                                            rangeStyle.borderBottom = `1px solid ${Colors.grey20}`
                                                            if (mode !== 'selectRange') {
                                                                rangeStyle.background = Colors.buttonSolid300
                                                                rangeStyle.borderTop = 'none'
                                                                rangeStyle.borderBottom = 'none'
                                                            }
                                                        }

                                                        const hoverStyle = {
                                                            position: 'absolute',
                                                            borderRadius: '50%',
                                                            width: 35,
                                                            height: 35,
                                                            top: 0,
                                                            left: 0,
                                                            background: mode === 'selectRange' ? Colors.grey20 : Colors.grey20,
                                                            opacity: !disabled && this.state.dateHover[date] ? 1: 0,
                                                            mixBlendMode: 'multiply'
                                                        }

                                                        return (
                                                            <td
                                                                key={`td-${date}`}
                                                                onMouseDown={disabled ? () => {} : range ? this.onClickRange.bind(this, date) : this.onClickDate.bind(this, date)}
                                                                onMouseEnter={(event) => {
                                                                    const dateHover = {}
                                                                    dateHover[date] = true
                                                                    this.setState({dateHover})
                                                                    range && this.onHoverRange(date, event)
                                                                }}
                                                                onMouseLeave={(event) => {
                                                                    this.setState({dateHover: {}})
                                                                    range && this.onMouseLeaveRange(date, event)
                                                                }}
                                                                style={dateStyle}
                                                            >
                                                                {!disabled && moment(date).date()}
                                                                <div key={`range-${date}`} style={rangeStyle}></div>
                                                                <div key={`hover-${date}`} style={hoverStyle}></div>
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                {showTodayButton &&
                                    <Button
                                        style={{width: 200, marginTop: 15, marginLeft: 0}}
                                        onMouseDown={this.onClickToday.bind(this)}
                                    >
                                        Vandaag
                                    </Button>
                                }
                            </div>
                        </div>
                    }
                >
                    <Input
                        style={{marginBottom: 0, marginLeft: 0, marginRight: 0}}
                        label={label}
                        disabled={disabled}
                        readOnly={readOnly}
                        value={value === '2999-12-31' ? '' : this.state.value}
                        onFocus={disabled ? () => {} : this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        onChange={this.onChangeInput.bind(this)}
                        ref={(ref) => this.formControl = ref}
                        append='mdi mdi-calendar'
                        inputMode={inputMode}
                    />
                </Popover>
            </div>
        )
    }
}

export default DatePicker

// @ts-check

/**
 * New vehicle
 *
 * @param {Reseller} reseller
 * @returns {Vehicle}
 */
const newVehicle = (reseller) => {
    return structuredClone({
        reseller: reseller.name,
        name: '',
        typeId: reseller.settings.vehicleTypes.find((vehicleType) => !vehicleType.isArchived && vehicleType._id === reseller.settings.defaultVehicle)?._id || reseller.settings.vehicleTypes.find((vehicleType) => !vehicleType.isArchived)?._id,
        comment: '',
        notes: '',
        notAvailableDateTimeRanges: [],
        isArchived: false
    })
}

/**
 * New vehicle type
 *
 * @returns {VehicleType}
 */
const newVehicleType = () => {
    return structuredClone({
        name: '',
        icon: '',
        routeProfile: 'bike',
        speedDifferenceEnabled: false,
        speedDifferenceMode: 'faster',
        speedDifference: 0,
        fuelType: 'none',
        usage: 0,
        range: 0,
        payload: 0,
        payloadType: 'colli',
        stopTime: 120,
        comment: '',
        notes: '',
        isArchived: false
    })
}


export {
    newVehicle,
    newVehicleType
}

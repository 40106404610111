import React from 'react'
import _ from 'underscore'

import {Colors, Info, P, Popover, ListItem, Spinner} from '../UI/index.js'

class NewSelect extends React.Component {
    constructor(props) {
        super(props)

        let options = props.options

        if (!options) {
            options = props.children.map((child) => {
                if (child && child.props) {
                    return {value: child.props.value, title: child.props.children, disabled: child.props.disabled}
                }
            })
        }

        if (props.allowEmptyValue || props.placeholder) {
            options = [{value: '', title: props.placeholder || ''}, ...options]
        }

        this.state = {
            focused: false,
            options,
            displayOptions: [...options],
            inputValue: '',
            selectedValue: 0
        }
    }

    componentDidUpdate(prevProps) {
        const {options, children=[]} = this.props
        const newKeys = children.map((child) => child.props)
        const oldKeys = (prevProps.children || []).map((child) => child.props)

        if (JSON.stringify(options) !== JSON.stringify(prevProps.options) || JSON.stringify(newKeys) !== JSON.stringify(oldKeys)) {
            let options = this.props.options

            if (!options) {
                options = this.props.children.map((child) => {
                    if (child && child.props) {
                        return {value: child.props.value, title: child.props.children, disabled: child.props.disabled}
                    }
                })
            }

            if (this.props.allowEmptyValue || this.props.placeholder) {
                options = [{value: '', title: this.props.placeholder || ''}, ...options]
            }
            this.setState({options, displayOptions: [...options], selectedValue: 0})
        }
    }

    onChangeInput(event) {
        const {options} = this.state

        const inputValue = event.target.value


        const displayOptions = _.filter(options, (option) => {
            return option && typeof option.title === 'string' && option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
        })

        this.setState({selectedValue: 0, inputValue, displayOptions})
        this.popover.show()
    }

    onKeyDown(event) {
        const {displayOptions, selectedValue} = this.state

        if ((event.keyCode === 40 || event.which === 40) && selectedValue < displayOptions.length-1) { // Down
            if (this.popover.closed()) {
                this.popover.show()
            } else {
                this.setState({selectedValue: selectedValue+1})
            }
        }

        if ((event.keyCode === 38 || event.which === 38) && selectedValue > 0) { // Up
            this.setState({selectedValue: selectedValue-1})
        }
    }

    onKeyPress(event) {
        const {options, displayOptions, selectedValue} = this.state
        const {onChange} = this.props


        if (event.keyCode === 13 || event.which === 13) { // Enter
            event.preventDefault()
            event.target.value = displayOptions[selectedValue].value
            onChange(event)
            this.setState({inputValue: '', selectedValue: 0, displayOptions: [...options]})
            this.popover.close()
        }
    }

    onClickOption(value, event) {
        const {onChange} = this.props

        event.target.value = value.toString()

        this.setState({selectedValue: 0, inputValue: ''})
        onChange(event)
        this.input.blur()
        this.popover.close()
    }

    render() {
        const {id, style={}, inputStyle={}, label, info, infoIcon, value = '', loading, readOnly, disabled, isInvalid, onFocus, onBlur, prepend, inputMode} = this.props
        const {focused, options, displayOptions, inputValue, selectedValue} = this.state

        const option = _.find(options, (option) => option && value === option.value)

        const title = option ? option.title : ''

        const focusedState = focused || inputValue || title


        const defaultStyle = {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexShrink: 0,
            width: '100%',
            position: 'relative',
            marginLeft: 6,
            marginRight: 6,
            marginBottom: 12,
            height: 42,
            ...style
        }

        if (style?.width) {
            delete defaultStyle.flex
        }

        const wrapperStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            height: 42,
            minHeight: 42,
            width: '100%',
            position: 'relative',
            paddingTop: 12,
            paddingRight: 10,
            borderBottom: '1px solid',
            borderColor: disabled ? Colors.grey20 : isInvalid ? Colors.errorBright : (focusedState && focused) ? Colors.buttonSolid : Colors.grey20,
            borderRadius: '4px 4px 0px 0px',
            background: readOnly ? 'transparent' : disabled ? Colors.grey20 : Colors.backgroundWhite,
            color: disabled ? Colors.textLight : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text'
        }

        const labelStyle = {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            paddingLeft: prepend ? 36 : 10,
            top: focusedState ? 2 : 10,
            fontSize: focusedState ? 12 : 15,
            color: Colors.textMedium,
            cursor: disabled || readOnly ? 'default' : 'text',
            transition: 'top .2s ease'
        }

        const defaultInputStyle = {
            fontSize: 15,
            background: 'transparent',
            height: focusedState && focused ? 28 : 29,
            paddingLeft: prepend ? 36 : 10,
            minWidth: 100,
            width: inputValue.length * 12,
            maxWidth: 'calc(100% - 15px)',
            outline: 'none',
            border: 'none',
            color: disabled ? Colors.textLight : Colors.textDark,
            cursor: disabled || readOnly ? 'default' : 'text',
            caretColor: inputMode === 'none' ? 'transparent' : 'default',
            ...inputStyle
        }


        return (
            <div
                style={defaultStyle}
                onMouseDown={(event) => event.stopPropagation()}
                onClick={() => this.input.focus()}
            >
                <Popover
                    ref={(ref) => this.popover = ref}
                    content={
                        <div style={{maxHeight: 400, overflowY: 'auto'}}>
                            {displayOptions.length ?
                                displayOptions.slice(0, 400).map((option, index) => {
                                    if (option) {
                                        return (
                                            <ListItem
                                                size='sm'
                                                key={`${index}select${id || ''}`}
                                                style={{fontWeight: option.bold ? 'bold' : 'normal'}}
                                                focused={selectedValue === index}
                                                active={value === option.value}
                                                disabled={option.disabled}
                                                onMouseDown={this.onClickOption.bind(this, option.value)}
                                            >
                                                {option.title}
                                            </ListItem>
                                        )
                                    }
                                }) :
                                <ListItem size='sm'><P ellipsis>Geen opties</P></ListItem>
                            }
                        </div>
                    }
                >
                    <div style={wrapperStyle} onClick={() => this.input.focus()}>
                        <div style={labelStyle} onClick={() => this.input.focus()}>
                            <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                            {info && <Info icon={infoIcon} text={info}/>}
                        </div>

                        {prepend?.includes('mdi') &&
                            <i
                                style={{position: 'absolute', left: 10, top: 10}}
                                className={prepend}
                            />
                        }

                        {prepend && !prepend.includes('mdi') &&
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 10,
                                    bottom: 7,
                                    fontSize: 15,
                                    fontWeight: 400,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 20,
                                    height: 20
                                }}
                            >
                                {prepend}
                            </div>
                        }

                        <input
                            id={id}
                            style={defaultInputStyle}
                            disabled={disabled || readOnly}
                            value={inputValue}
                            inputMode={inputMode}
                            onFocus={() => {
                                if (!disabled && !readOnly) {
                                    this.popover.show()
                                    this.setState({focused: true})
                                    onFocus && onFocus()
                                }
                            }}
                            onBlur={() => {
                                this.setState({focused: false, inputValue: '', displayOptions: [...options], selectedValue: 0})
                                this.popover.close()
                                onBlur && onBlur()
                            }}
                            onKeyDown={this.onKeyDown.bind(this)}
                            onKeyPress={this.onKeyPress.bind(this)}
                            onChange={this.onChangeInput.bind(this)}
                            ref={(ref) => this.input = ref}
                        />

                        <P
                            style={{position: 'absolute', left: prepend ? 36 : 10, bottom: focusedState && focused ? 5 : 6, width: '100%', paddingRight: 20, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                color: disabled ? Colors.textLight : Colors.textDark}} onClick={() => this.input.focus()}
                        >
                            {loading ?
                                <Spinner name='ball-clip-rotate' fadeIn='none' color={Colors.textDark}/> :
                                inputValue ? '' : title
                            }
                        </P>

                        <i
                            style={{position: 'absolute', right: 10, top: 10, color: disabled ? Colors.textLight : Colors.textDark}} onClick={() => this.input.focus()}
                            className='mdi mdi-menu-down'
                        />

                    </div>
                </Popover>
            </div>
        )
    }
}


export default NewSelect

import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'underscore'

import CustomerStore from '../../../../stores/CustomerStore.js'
import CustomerActions from '../../../../actions/CustomerActions.js'

import PriceTable from '../../../../components/settings/ParcelsPriceTable/index.js'
import {H3, S2, Panel} from '../../../../components/UI/index.js'

export default class ParcelPrices extends Reflux.Component {
    constructor(props) {
        super(props)
        this.store = CustomerStore
    }

    onParcelPricesChange(reseller, path, unsetPath) {
        const {onChange} = this.props
        onChange(reseller, path, unsetPath)
    }

    onUpdateCustomers(name, oldName, callback) {
        CustomerActions.updatePriceTable(name, oldName, callback)
    }

    render() {
        const {reseller, carriers} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })

        return (
            <Panel style={{padding: 20, minWidth: 600, width: 'fit-content'}}>
                <H3>Tarieven</H3>

                {enabledCarriers.length < 1 ?
                    <S2 style={{marginBottom: 30}}>Er moet minimaal 1 vervoerders zijn geactiveerd voordat tarieven kunnen worden ingesteld.</S2> :

                    <PriceTable
                        reseller={reseller}
                        carriers={carriers}
                        onUpdateCustomers={this.onUpdateCustomers.bind(this)}
                        onChange={this.onParcelPricesChange.bind(this)}
                    />
                }
            </Panel>
        )
    }
}

import React from 'react'

import _ from 'lodash'

import {H3, H6, Divider, EmailPreview} from '../../../../components/UI/index.js'
import {Input, Editor, Panel} from '../../../../components/UI/index.js'

import customerWelcomeTemplate from '../../../../../server/functions/mail/templates/customerWelcomeTemplate.js'


class CustomerSettings extends React.Component {
    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Klanten</H3>

                <H6>Welkomstmail</H6>
                <Divider/>

                <Input
                    label='Afzender naam'
                    placeholder={reseller.settings?.accountName || reseller.name}
                    value={reseller.settings.customers.senderName}
                    onChange={this.onChangeValue.bind(this, 'customers.senderName')}
                    onBlur={this.onSaveValue.bind(this, 'customers.senderName')}
                />

                <Input
                    label='Afzender emailadres'
                    placeholder={(reseller.settings.useOwnSMTP && reseller.settings.smtpUser) || reseller.settings.companyAddress.email || 'noreply@veloyd.nl'}
                    value={reseller.settings.customers.replyToEmail}
                    onChange={this.onChangeValue.bind(this, 'customers.replyToEmail')}
                    onBlur={this.onSaveValue.bind(this, 'customers.replyToEmail')}
                />

                <br/>
                <Input
                    label='Onderwerp'
                    value={reseller.settings.customers.welcomeEmailSubject}
                    onChange={this.onChangeValue.bind(this, 'customers.welcomeEmailSubject')}
                    onBlur={this.onSaveValue.bind(this, 'customers.welcomeEmailSubject')}
                />

                <Editor
                    label='bericht'
                    tags={['bedrijfsnaam', 'klant']}
                    value={reseller.settings.customers.welcomeEmailBody}
                    onChange={this.onSaveValue.bind(this, 'customers.welcomeEmailBody')}
                />

                <div style={{position: 'absolute', top: 90, left: 1100, width: 650}}>
                    <EmailPreview
                        type='customerWelcome'
                        html={customerWelcomeTemplate({
                            logo: reseller.settings.logo || '/images/logoPlaceholder.png',
                            color: reseller.settings.color,
                            message: reseller.settings.customers.welcomeEmailBody?.replace(/\n/g, '<br/>') || '',
                            token: ''
                        })}
                    />
                </div>
            </Panel>
        )
    }
}

export default (CustomerSettings)

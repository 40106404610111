import React from 'react'

import _ from 'underscore'

import {Button, IconButton, DraggableList, Divider, H6, S1, S2, Caption} from '../UI/index.js'
import {CarrierSelector, Input, Select} from '../UI/index.js'
import RuleModal from './RuleModal.js'
import CategoryModal from './CategoryModal.js'


class ProductCodeTable extends React.Component {
    constructor(props) {
        super(props)

        this.state= {
            selectedCarrier: props.carriers[0] || {}
        }
    }

    onChangeCarrier(event) {
        const {carriers} = this.props

        const selectedCarrier = _.findWhere(carriers, {name: event.target.value})

        this.setState({selectedCarrier})
    }


    onClickAddRule(carrier, product, productName) {
        const {reseller, onChange} = this.props

        const rule = {
            name: '',
            countries: [],
            type: 'weight',
            productCode: '',
            kgProductCode: '',
            categories: [
                {
                    minWeight: '0',
                    maxWeight: '2',
                    productCode: ''
                },
                {
                    minWeight: '2',
                    maxWeight: '5',
                    productCode: ''
                },
                {
                    minWeight: '5',
                    maxWeight: '10',
                    productCode: ''
                },
                {
                    minWeight: '10',
                    maxWeight: '20',
                    productCode: ''
                },
                {
                    minWeight: '20',
                    maxWeight: '31,5',
                    productCode: ''
                }
            ]
        }

        reseller.settings.parcels.productCodes = reseller.settings.parcels.productCodes || {}
        reseller.settings.parcels.productCodes[carrier.name] = reseller.settings.parcels.productCodes[carrier.name] || {}
        reseller.settings.parcels.productCodes[carrier.name][productName] = reseller.settings.parcels.productCodes[carrier.name][productName] || []
        reseller.settings.parcels.productCodes[carrier.name][productName].push(rule)

        onChange(reseller, `parcels.productCodes.${carrier.name}.${productName}`)
        this.onClickOpenRuleModal(carrier, productName, reseller.settings.parcels.productCodes[carrier.name][productName].length - 1, rule)
    }

    onClickOpenRuleModal(carrier, productName, index, rule) {
        const {reseller} = this.props
        let countries = carrier.combinedProductsValues[productName]


        if (countries.length > 1) {
            countries.push('Overige')
        }

        const usedCountries = []
        reseller.settings.parcels.productCodes[carrier.name][productName].map((rule) => {
            usedCountries.push(...rule.countries)
        })
        countries = _.uniq(_.difference(countries, usedCountries))
        countries.push(...rule.countries)

        this.ruleModal.open(carrier.name, productName, index, rule, countries)
    }

    onEditRule(carrierName, productName, index, rule) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][index] = rule
        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${index}`)
    }

    onRemoveRule(carrierName, productName, index) {
        const {reseller, onChange} = this.props
        reseller.settings.parcels.productCodes[carrierName][productName].splice(index, 1)
        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}`)
    }

    onChangeCountries(carrierName, productName, rule, event) {
        const {reseller, onChange} = this.props

        const {options, selectedIndex} = event.target
        const option = options[selectedIndex]

        if (reseller.settings.parcels.productCodes[carrierName][productName][rule].countries.indexOf(option.value) === -1) {
            reseller.settings.parcels.productCodes[carrierName][productName][rule].countries.push(option.value)
        }

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onChangeType(carrierName, productName, rule, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].type = event.target.value

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onChangeProductCode(carrierName, productName, rule, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].productCode = event.target.value

        onChange(reseller)
    }

    onChangeKgProductCode(carrierName, productName, rule, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].kgProductCode = event.target.value

        onChange(reseller)
    }

    onSaveProductCode(carrierName, productName, rule) {
        const {reseller, onChange} = this.props

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onSortRules(carrierName, productName, rules) {
        const {reseller, onChange} = this.props
        reseller.settings.parcels.productCodes[carrierName][productName] = rules
        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}`)
    }


    onClickAddCategory(carrierName, productName, rule) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories.push({
            minWeight: '0',
            maxWeight: '0',
            productCode: ''
        })

        // Sort categories on minWeight
        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories = _.sortBy(reseller.settings.parcels.productCodes[carrierName][productName][rule].categories, (category) => {
            return parseFloat(category.minWeight.replace(',', '.'))
        })
        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onClickOpenCategoryModal(carrierName, productName, rule, index, category) {
        this.categoryModal.open(carrierName, productName, rule, index, category)
    }

    onEditCategory(carrierName, productName, rule, index, category) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories[index] = category

        // Sort categories on minWeight
        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories = _.sortBy(reseller.settings.parcels.productCodes[carrierName][productName][rule].categories, (category) => {
            return parseFloat(category.minWeight.replace(',', '.'))
        })

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onRemoveCategory(carrierName, productName, rule, index) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories.splice(index, 1)

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }

    onChangeCategoryProductCode(carrierName, productName, rule, category, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.productCodes[carrierName][productName][rule].categories[category].productCode = event.target.value

        onChange(reseller)
    }

    onSaveCategoryProductCode(carrierName, productName, rule) {
        const {reseller, onChange} = this.props

        onChange(reseller, `parcels.productCodes.${carrierName}.${productName}.${rule}`)
    }


    onClickAddFee() {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.fees = reseller.settings.parcels.fees || []

        reseller.settings.parcels.fees.push({
            description: '',
            price: '',
            productCode: ''
        })

        onChange(reseller, 'parcels.fees')
    }

    onClickRemoveFee(index) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.fees.splice(index, 1)

        onChange(reseller, 'parcels.fees')
    }

    onChangeFeeDescription(index, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.fees[index].description = event.target.value

        onChange(reseller)
    }

    onChangeFeeProductCode(index, event) {
        const {reseller, onChange} = this.props

        reseller.settings.parcels.fees[index].productCode = event.target.value

        onChange(reseller)
    }

    onSaveFee(index) {
        const {reseller, onChange} = this.props

        onChange(reseller, `parcels.fees.${index}`)
    }


    render() {
        const {selectedCarrier} = this.state
        const {reseller, enabledCarriers} = this.props

        const productCodes = reseller.settings.parcels.productCodes || {}

        return (
            <div>
                <div style={{display: 'flex'}}>
                    <div style={{width: 330}}/>

                    <CarrierSelector
                        size={45} carriers={enabledCarriers} value={selectedCarrier.name} onChange={this.onChangeCarrier.bind(this)}
                    />
                </div>

                {selectedCarrier && _.keys(selectedCarrier.combinedProductsValues).map((productName) => {
                    const productActivated = _.every(productName.split(', '), (productName) => {
                        productName = productName.split(' (')[0]
                        return productName === 'Standaard zending' || reseller.settings.parcels.products[productName] && reseller.settings.parcels.products[productName][selectedCarrier.name]
                    })

                    if (productActivated) {
                        const product = selectedCarrier.combinedProductsValues[productName]

                        return (
                            <div key={productName} style={{marginBottom: 12}}>
                                <S1 style={{marginBottom: 12}}>{productName}</S1>


                                {productCodes[selectedCarrier.name] && productCodes[selectedCarrier.name][productName] &&
                                <DraggableList
                                    items={productCodes[selectedCarrier.name][productName]}
                                    renderItem={(rule, DragHandle, index) => {
                                        return (
                                            <div key={`${selectedCarrier.name}${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: 285,
                                                    minWidth: 285,
                                                    height: 30
                                                }}
                                                >
                                                    <DragHandle/>
                                                    <IconButton onClick={this.onClickOpenRuleModal.bind(this, selectedCarrier, productName, index, rule)}>
                                                        <i className='mdi mdi-pencil'/>
                                                    </IconButton>
                                                    <S2>{rule.name}</S2>
                                                </div>

                                                <Select
                                                    style={{width: 150, marginRight: 12}}
                                                    allowEmptyValue
                                                    value={rule.type}
                                                    onChange={this.onChangeType.bind(this, selectedCarrier.name, productName, index)}
                                                >
                                                    <option value='fixed'>Productcode</option>
                                                    <option value='weight'>Gewicht</option>
                                                    <option value='fixedKg'>Kilo toeslag</option>
                                                </Select>

                                                {rule.type === 'weight' && rule.categories.map((category, j) => {
                                                    const categoryValid = category.minWeight && category.maxWeight && parseFloat(category.maxWeight.replace(',', '.')) >= parseFloat(category.minWeight.replace(',', '.')) && category.maxWeight !== '0' && _.every(rule.categories, (cat, i) => {
                                                        if (!cat.minWeight || !cat.maxWeight || i === j) {
                                                            return true
                                                        }
                                                        return (parseFloat(cat.maxWeight.replace(',', '.')) <= parseFloat(category.minWeight.replace(',', '.')) || parseFloat(cat.minWeight.replace(',', '.')) >= parseFloat(category.maxWeight.replace(',', '.')))
                                                    })

                                                    return (
                                                        <div
                                                            key={j} style={{
                                                                width: 150,
                                                                marginRight: 12
                                                            }}
                                                        >
                                                            <Input
                                                                value={category.productCode}
                                                                isInvalid={!categoryValid}
                                                                onChange={this.onChangeCategoryProductCode.bind(this, selectedCarrier.name, productName, index, j)}
                                                                onBlur={this.onSaveCategoryProductCode.bind(this, selectedCarrier.name, productName, index)}
                                                            />

                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <IconButton onClick={this.onClickOpenCategoryModal.bind(this, selectedCarrier.name, productName, index, j, category)}>
                                                                    <i className='mdi mdi-pencil'/>
                                                                </IconButton>
                                                                <Caption>{`${category.minWeight} t/m ${category.maxWeight} kg`}</Caption>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                                {rule.type === 'weight' &&
                                                <Button
                                                    style={{marginRight: 30}}
                                                    variant='outline-white'
                                                    icon='mdi mdi-plus'
                                                    label='Nieuw gewicht'
                                                    onClick={this.onClickAddCategory.bind(this, selectedCarrier.name, productName, index)}
                                                />
                                                }

                                                {(rule.type === 'fixed' || rule.type === 'fixedKg') &&
                                                <>
                                                    <div style={{
                                                        width: 200,
                                                        marginRight: 12
                                                    }}
                                                    >
                                                        <Input
                                                            value={rule.productCode}
                                                            onChange={this.onChangeProductCode.bind(this, selectedCarrier.name, productName, index)}
                                                            onBlur={this.onSaveProductCode.bind(this, selectedCarrier.name, productName, index)}
                                                        />
                                                        {rule.type === 'fixedKg' &&
                                                        <Caption style={{marginLeft: 13, marginTop: -15}}>Productcode</Caption>
                                                        }
                                                    </div>

                                                    {rule.type === 'fixedKg' &&
                                                    <div style={{
                                                        width: 200,
                                                        marginRight: 12
                                                    }}
                                                    >
                                                        <Input
                                                            value={rule.kgProductCode}
                                                            onChange={this.onChangeKgProductCode.bind(this, selectedCarrier.name, productName, index)}
                                                            onBlur={this.onSaveProductCode.bind(this, selectedCarrier.name, productName, index)}
                                                        />
                                                        <Caption style={{marginLeft: 13, marginTop: -15}}>Toeslag</Caption>
                                                    </div>
                                                    }
                                                </>
                                                }
                                            </div>
                                        )
                                    }}
                                    onChange={this.onSortRules.bind(this, selectedCarrier.name, productName)}
                                />
                                }

                                <Button
                                    variant='outline-white'
                                    style={{marginBottom: 24, width: 'fit-content'}}
                                    icon='mdi mdi-plus'
                                    label='Nieuwe landengroep'
                                    onClick={this.onClickAddRule.bind(this, selectedCarrier, product, productName)}
                                />
                            </div>
                        )
                    }
                })}

                <br/>
                <Divider/>

                <H6>Toeslagen</H6>

                {(reseller.settings.parcels.fees || []).map((fee, index) => {
                    return (
                        <div key={`fee${index}`} style={{display: 'flex', width: 500, alignItems: 'center', marginBottom: 12}}>
                            <Input
                                style={{marginRight: 12, marginBottom: 0}}
                                label='Beschrijving'
                                value={fee.description}
                                onChange={this.onChangeFeeDescription.bind(this, index)}
                                onBlur={this.onSaveFee.bind(this, index)}
                            />

                            <Input
                                style={{maxWidth: 200, marginBottom: 0}}
                                label='Productcode'
                                value={fee.productCode}
                                onChange={this.onChangeFeeProductCode.bind(this, index)}
                                onBlur={this.onSaveFee.bind(this, index)}
                            />

                            <IconButton onClick={this.onClickRemoveFee.bind(this, index)}>
                                <i className='mdi mdi-delete'/>
                            </IconButton>
                        </div>
                    )
                })}

                <Button
                    variant='outline-white'
                    icon='mdi mdi-plus'
                    label='Nieuwe toeslag'
                    onClick={this.onClickAddFee.bind(this)}
                />

                <RuleModal
                    onChange={this.onEditRule.bind(this)}
                    remove={this.onRemoveRule.bind(this)}
                    ref={(ref) => this.ruleModal = ref}
                />

                <CategoryModal
                    onChange={this.onEditCategory.bind(this)}
                    remove={this.onRemoveCategory.bind(this)}
                    ref={(ref) => this.categoryModal = ref}
                />
            </div>
        )
    }
}


export default ProductCodeTable

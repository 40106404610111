import React, {useEffect, useState} from 'react'
import Leaflet from 'leaflet'

import {Card, FlexColumn, Button, Colors} from '../UI/index.js'


export default function LocationPicker({value={lat: 52.2076823, lng: 5.1584931}, onChange}) {
    let [map, setMap] = useState()
    let [addressMarker, setAddressMarker] = useState()

    const initMap = () => {
        if (map) {
            map.remove()
        }

        map = Leaflet.map('map', {zoomControl: false}).setView(value, 13)
        setMap(map)

        Leaflet.tileLayer(`${window.location.origin}/api/mapbox/styles/v1/{account}/{id}/tiles/{z}/{x}/{y}`, {
            maxZoom: 18,
            account: 'veloyd',
            id: 'cka2lgtlz0huj1iln27ujuqim',
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map)


        updateMap()
    }

    const updateMap = () => {
        if (!map) {
            return
        }

        if (addressMarker) {
            map.removeLayer(addressMarker)
        }

        if (value) {
            const icon = Leaflet.divIcon({
                html: `<i style="font-size: 38px; color: ${Colors.buttonSolid};" class="mdi mdi-map-marker">`,
                iconSize: [38, 38],
                iconAnchor: [19, 38]
            })

            addressMarker = Leaflet.marker(value, {icon, draggable: true}).addTo(map)

            addressMarker.on('dragend', (e) => {
                const latlng = e.target.getLatLng()

                onChange({lat: latlng.lat, lng: latlng.lng})
            })

            map.setView(addressMarker.getLatLng())

            setAddressMarker(addressMarker)
        }
    }


    useEffect(() => {
        initMap()
    }, [])

    useEffect(() => {
        if (value) {
            updateMap()
        }
    }, [value])


    return (
        <div style={{height: 400, marginTop: 10, position: 'relative'}}>
            <FlexColumn style={{position: 'absolute', left: 6, top: 6, zIndex: 99999999, overflow: 'default'}}>
                <Card shadow='true' style={{padding: 0, width: 'fit-content', marginBottom: 24}}>
                    <Button
                        style={{marginLeft: 0, marginRight: 0}}
                        variant='text'
                        icon='mdi mdi-plus'
                        onClick={() => map.zoomIn()}
                    />

                    <Button
                        style={{marginLeft: 0, marginRight: 0}}
                        variant='text'
                        icon='mdi mdi-minus'
                        onClick={() => map.zoomOut()}
                    />
                </Card>
            </FlexColumn>

            <div id='map' style={{width: '100%', height: '100%'}}>
                <style>
                    {`
                                .leaflet-div-icon {
                                    background: none;
                                    border: unset;
                                }
                            `}
                </style>
            </div>
        </div>
    )
}

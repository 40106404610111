export default () => JSON.parse(JSON.stringify({
    senderName: '',
    replyToEmail: '',
    defaultNotificationsReceiver: [],
    defaultNotificationsCustomer: [],
    pickup_registered: {
        default: {
            subject: 'Er is pakket aangemeld voor ophalen',
            body: '­Beste {naam},\n\nWe komen in opdracht van {klant} een pakket bij je ophalen. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het ophaalmoment.'
        },
        label: 'Ophalen aangemeld',
        comment: 'Dit bericht wordt verstuurd wanneer de rit is aangemeld.'
    },
    pickup_eta: {
        default: {
            subject: 'Het ophalen van je zending is gepland',
            body: '­Beste {naam},\n\nWe komen je zending voor {klant} bij je ophalen. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het ophaalmoment.'
        },
        label: 'Ophalen gepland',
        comment: 'Dit bericht wordt verstuurd wanneer de route waar het adres in zit gereed is.'
    },
    pickup_ontheway: {
        default: {
            subject: 'We zijn onderweg om je zending op te halen',
            body: 'Beste {naam},\n\nDe koerier is onderweg om je zending voor {klant} op te halen. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het bezorgmoment.'
        },
        label: 'Ophalen onderweg',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de route start.'
    },
    pickup_delayed: {
        default: {
            subject: 'Het is niet gelukt je zending op te halen',
            body: 'Beste {naam},\n\nHet is ons niet gelukt om je zending voor {klant} vandaag op te halen. We kunnen niet automatisch een nieuw ophaalmoment inplannen omdat we afhankelijk zijn van jouw beschikbaarheid en eventuele wensen van {klant}. Neem contact op met {klant} om een nieuwe afspraak te maken.'
        },
        label: 'Ophalen niet gelukt',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de ophaalpoging niet succesvol heeft afgerond.'
    },
    pickup_nothome: {
        default: {
            subject: 'We hebben je gemist',
            body: 'Beste {naam}, \n\nJe was niet thuis toen we je zending voor {klant} kwamen ophalen. We kunnen niet automatisch een nieuw ophaalmoment inplannen omdat we afhankelijk zijn van jouw beschikbaarheid en eventuele wensen van {klant}. Neem contact op met {klant} om een nieuwe afspraak te maken.'
        },
        label: 'Ophalen niet thuis',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de ophaalpoging heeft afgerond maar geadresseerde niet thuis was.'
    },
    pickup_completed: {
        default: {
            subject: 'Je zending is opgehaald',
            body: 'Beste {naam}, \n\nWe hebben je zending voor {klant} opgehaald. Hieronder vind je alle informatie over je zending.'
        },
        label: 'Opgehaald',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de zending scant bij het ophaaladres. Als er niet gescand wordt, dan wordt dit bericht verstuurd wanneer de koerier de ophaalpoging succesvol heeft afgerond.'
    },
    delivery_registered: {
        default: {
            subject: 'Er is pakket aangemeld voor bezorging',
            body: '­Beste {naam},\n\n{klant} heeft een pakket bij ons aangemeld voor bezorging. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het bezorgmoment.'
        },
        label: 'Bezorging aangemeld',
        comment: 'Dit bericht wordt verstuurd wanneer de rit is aangemeld.'

    },
    delivery_collected: {
        default: {
            subject: 'Je zending is door ons ontvangen',
            body: 'Beste {naam}, \n\nWe hebben je zending ontvangen. We gaan ervoor zorgen dat deze zo snel mogelijk bij jou wordt bezorgd. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het bezorgmoment.'
        },
        label: 'Bezorging pakket ontvangen',
        comment: 'Dit bericht wordt verstuurd wanneer de zending een scan op de hub krijgt.'
    },
    delivery_eta: {
        default: {
            subject: 'De bezorging van je zending is gepland',
            body: 'Beste {naam},\n\nWe komen je zending voor {klant} bij je bezorgen. Je kunt de Track & Trace pagina in de gaten houden voor mogelijke updates in het bezorgmoment.'
        },
        label: 'Bezorging gepland',
        comment: 'Dit bericht wordt verstuurd wanneer de route waar het adres in zit gereed is.'
    },
    delivery_ontheway: {
        default: {
            subject: 'Je zending is onderweg',
            body: 'Beste {naam},\n\nDe koerier is onderweg om je zending te bezorgen. Je kunt de Track & Trace pagina in de gaten houden voor updates in het bezorgmoment.'
        },
        label: 'Bezorging onderweg',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de route start.'
    },
    delivery_delayed: {
        reschedule: {
            subject: 'Het is niet gelukt je zending te bezorgen',
            body: 'Beste {naam},\n\nHet is ons niet gelukt je zending van {klant} vandaag te bezorgen. We proberen het zo snel mogelijk nog een keer.\n\nOnze excuses dat je iets langer moet wachten op je zending.'
        },
        retour: {
            subject: 'Het is niet gelukt je zending te bezorgen',
            body: 'Beste {naam},\n\nHet is ons niet gelukt je zending van {klant} vandaag te bezorgen. Helaas hebben we geconstateerd dat de zending een foutief adres bevatte en gaat daarom retour naar {klant}. Voor meer informatie over kun je contact opnemen met {klant}.\n\nOnze excuses voor het ongemak.'
        },
        label: 'Bezorging niet gelukt',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de bezorgpoging niet succesvol heeft afgerond.'
    },
    delivery_nothome: {
        reschedule: {
            subject: 'We hebben je gemist',
            body: 'Beste {naam},\n\nJe was niet thuis toen we je zending van {klant} probeerden te bezorgen. We proberen het snel nog een keer. Je kunt de Track & Trace pagina in de gaten houden voor updates in het bezorgmoment.'
        },
        retour: {
            subject: 'We hebben je gemist',
            body: 'Beste {naam},\n\nJe was niet thuis toen we je zending van {klant} probeerden te bezorgen.  Helaas zullen we geen nieuwe bezorgpoging meer ondernemen en gaat de zending retour naar {klant}. Voor meer informatie kun je contact opnemen met {klant}.'
        },
        onhold: {
            subject: 'We hebben je gemist',
            body: 'Beste {naam},\n\nJe was niet thuis toen we je zending van {klant} probeerden te bezorgen. Helaas zullen we geen nieuwe bezorgpoging meer ondernemen. Kijk op de Track & Trace pagina voor de mogelijkheden om je zending in ontvangst te kunnen nemen.'
        },
        label: 'Bezorging niet thuis',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de bezorgpoging heeft afgerond maar geadresseerde niet thuis was.'
    },
    delivery_completed: {
        delivered: {
            subject: 'Je zending is bezorgd',
            body: 'Beste {naam}, \n\nWe hebben je zending van {klant} bezorgd. Hieronder vind je alle informatie over je zending.'
        },
        deliveredAtNeighbors: {
            subject: 'Je zending is bezorgd bij de buren op nummer {huisnummer_buren}',
            body: 'Beste {naam}, \n\nWe hebben je zending van {klant} bezorgd bij de buren. Hieronder vind je alle informatie over je zending.'
        },
        deliveredInMailbox: {
            subject: 'Je zending is bezorgd in de brievenbus',
            body: 'Beste {naam}, \n\nWe hebben je zending van {klant} bezorgd in de brievenbus. Hieronder vind je alle informatie over je zending.'
        },
        label: 'Bezorgd',
        comment: 'Dit bericht wordt verstuurd wanneer de koerier de bezorgpoging succesvol heeft afgerond.'
    },
    customer_pickup_completed: {
        default: {
            subject: 'Ophaalbewijs',
            body: 'Beste {klant},\n\nVandaag hebben wij in opdracht van jullie een zending opgehaald. Bijgesloten vind je een ophaalbewijs van de opdracht.'
        },
        label: 'Ophaalbewijs'
    },
    customer_delivery_completed: {
        default: {
            subject: 'Bezorgbewijs',
            body: 'Beste {klant}, \n\nVandaag hebben wij in opdracht van jullie een zending bezorgd. Bijgesloten vind je een bezorgbewijs van de opdracht.'
        },
        label: 'Bezorgbewijs'
    }
}))

import React from 'react'


import Colors from './Colors.js'


class Divider extends React.Component {
    render() {
        const {style={}} = this.props

        const defaultStyle = {
            width: '100%', height: 1, marginBottom: 12, background: Colors.grey40,
            ...style
        }

        return (
            <div style={defaultStyle}/>
        )
    }
}

export default (Divider)

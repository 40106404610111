import React from 'react'

import Reflux from 'reflux-react-16'
import _ from 'lodash'

import LoginActions from '../../../actions/LoginActions.js'

import {Address, Alert, Colors, Input, Popup, SMTPSettings, UploadImage} from '../../../components/UI/index.js'
import {H3, H6, Divider, Panel, Toggle, MfaPopup, S2} from '../../../components/UI/index.js'

class AccountSettings extends Reflux.Component {
    onChangeLogo(logo) {
        const user = {...this.props.user}

        user.settings.newLogo = logo

        this.props.onChange(user)
    }

    onChangeValue(path, event) {
        const user = {...this.props.user}

        _.set(user.settings, path, event.target.value)

        this.props.onChange(user)
    }

    onChangeChecked(path, event) {
        const user = {...this.props.user}

        if (path === 'mfaRequired') {
            if (!event.target.checked) {
                if (user.settings.mfaRequired) {
                    if (user.mfaMethod === 'email') {
                        LoginActions.mailMFACode(null, user.index, null, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            }
                        })
                    }

                    this.mfaPopup.open((code) => {
                        LoginActions.verify2FACode(code, (err) => {
                            if (err) {
                                this.mfaPopup.setError(err)
                            } else {
                                this.mfaPopup.close()

                                _.set(user.settings, path, event.target.checked)

                                this.props.onChange(user)
                            }
                        })
                    }, user.mfaMethod)
                }
            }

            if (event.target.checked) {
                if (user.mfaEnabled) {
                    _.set(user.settings, path, event.target.checked)

                    this.props.onChange(user)
                } else {
                    this.popup.open('Tweestapsverificatie nog niet ingesteld.', 'Om 2FA te activeren word je nu uitgelogd. Bij opnieuw inloggen zal gevraagd worden om de gewenste 2FA methode te activeren.', () => {
                        _.set(user.settings, path, event.target.checked)

                        this.props.onChange(user, true)

                        // give app the time to save the settings before logging out
                        setTimeout(() => {
                            LoginActions.logout()
                        }, 1000)
                    })
                }
            }
        } else {
            _.set(user.settings, path, event.target.checked)

            this.props.onChange(user)
        }
    }

    onChangeAddress(address) {
        const user = {...this.props.user}

        user.address = address
        user.name = address.name

        this.props.onChange(user)
    }


    render() {
        const {user} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Bedrijfsgegevens</H3>

                <H6 style={{marginTop: 48}}>Inlogbeveiliging</H6>
                <Divider/>

                <Toggle
                    label='Tweefactorauthenticatie'
                    checked={user.settings.mfaRequired}
                    onChange={this.onChangeChecked.bind(this, 'mfaRequired')}
                    info='De handleiding voor 2FA is te vinden via het vraagteken-icoon rechtsboven.'
                />

                <H6 style={{marginTop: 48}}>Logo en kleur</H6>
                <Divider/>

                <UploadImage
                    label={
                        <>
                            Logo
                            <S2 style={{whiteSpace: 'initial'}}>(Maximale bestandsgrootte: 1MB, bestandstype: .png)</S2>
                        </>
                    }
                    image={user.settings.newLogo ?? ((!user.settings.logo || user.settings.logo?.includes('data:image')) ? user.settings.logo : `/${user.settings.logo}`)}
                    onChange={this.onChangeLogo.bind(this)}
                    resize={false}
                    maxSize={1}
                />

                {!user.settings.logo &&
                    <Alert variant="info" style={{marginTop: -10, marginBottom: 30, maxWidth: 658}}>Je hebt nog geen logo geupload, dit logo wordt op verschillende plekken gebruikt.</Alert>
                }

                <Input
                    type='color'
                    label='Kleur'
                    value={user.settings.color || Colors.brandColor}
                    onChange={this.onChangeValue.bind(this, 'color')}
                />

                <br/>
                <br/>
                <H6>Adres</H6>
                <Divider/>

                <Address
                    noTAV
                    address={user.address || {}}
                    onChange={this.onChangeAddress.bind(this)}
                />

                <br/>
                <br/>
                <br/>
                <H6>Email instellingen</H6>
                <Divider/>

                <SMTPSettings
                    settings={user.settings}
                    onChange={this.onChangeValue.bind(this)}
                    onChangeChecked={this.onChangeChecked.bind(this)}
                />

                <MfaPopup confirmButtonText='Bevestig' ref={(ref) => this.mfaPopup = ref}/>
                <Popup confirmBtnText='Bevestigen' cancelBtnText='Annuleren' ref={(ref) => this.popup = ref}/>
            </Panel>
        )
    }
}

export default (AccountSettings)

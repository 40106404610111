import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import aSync from 'async'

import AdminStore from '../../stores/AdminStore.js'
import AdminActions from '../../actions/AdminActions.js'

import {InvoiceListItem, Invoice} from './components/invoices/index.js'
import {Check, Select} from '../../components/UI/index.js'
import {Button, Panel, ListItem, Popup, S2, Spinner} from '../../components/UI/index.js'


class Invoices extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.stores = [AdminStore]
    }

    componentDidMount() {
        AdminActions.getInvoices(true)
    }

    onChangeMonth(event) {
        let {month} = this.state
        month = moment(month).month(event.target.value).format('YYYY-MM')
        AdminActions.setMonth(month)
    }

    onChangeYear(event) {
        let {month} = this.state
        month = moment(month).year(event.target.value)
        AdminActions.setMonth(month)
    }

    onSelectInvoice(id) {
        AdminActions.selectInvoice(id)
    }

    onClickSendInvoices() {
        const selectedInvoices = JSON.parse(JSON.stringify((this.state.selectedInvoices)))

        this.popup.open('Verstuur facturen', `${selectedInvoices.length} facturen worden verstuurd`, () => {
            aSync.eachSeries(selectedInvoices, (id, next) => {
                AdminActions.sendInvoice(id, (err) => {
                    if (err) {
                        this.popup.setError(err)
                    } else {
                        next()
                    }
                })
            }, () => {
                this.popup.setMessage(`${selectedInvoices.length} facturen verstuurd`)
            })
        })
    }

    render() {
        const {invoices, selectedInvoices, month, invoicesLoading} = this.state

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', paddingTop: 30, paddingLeft: 10, paddingRight: 10}}>
                <div style={{display: 'flex', marginBottom: 30}}>
                    <Select
                        style={{width: 150}}
                        value={moment(month).month()}
                        options={[
                            {value: 0, title: 'Januari'},
                            {value: 1, title: 'Februari'},
                            {value: 2, title: 'Maart'},
                            {value: 3, title: 'April'},
                            {value: 4, title: 'Mei'},
                            {value: 5, title: 'Juni'},
                            {value: 6, title: 'Juli'},
                            {value: 7, title: 'Augustus'},
                            {value: 8, title: 'September'},
                            {value: 9, title: 'Oktober'},
                            {value: 10, title: 'November'},
                            {value: 11, title: 'December'}
                        ]}
                        onChange={this.onChangeMonth.bind(this)}
                    />

                    <Select
                        style={{width: 150}}
                        value={moment(month).format('YYYY')}
                        options={[
                            {value: '2019', title: '2019'},
                            {value: '2020', title: '2020'},
                            {value: '2021', title: '2021'},
                            {value: '2022', title: '2022'},
                            {value: '2023', title: '2023'},
                            {value: '2024', title: '2024'},
                            {value: '2025', title: '2025'}
                        ]}
                        onChange={this.onChangeYear.bind(this)}
                    />

                    <Button onClick={this.onClickSendInvoices.bind(this)}>Verstuur facturen</Button>
                </div>

                <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, paddingRight: 25}}>
                        <ListItem>
                            <Check
                                checked={invoices.length && invoices.length === selectedInvoices.length}
                                onChange={this.onSelectInvoice.bind(this, null)}
                            />
                            <S2 style={{flex: 2, marginLeft: 30}}>Reseller</S2>
                            <S2 style={{width: 200, marginLeft: 30}}>Maand</S2>
                            <S2 style={{width: 130, marginLeft: 30}}>Totaal</S2>
                            <S2 style={{flex: 1, marginLeft: 30}}/>
                        </ListItem>

                        {invoicesLoading ?
                            <Spinner name='ball-clip-rotate' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 15}} fadeIn='none'/> :
                            <Panel>
                                {invoices.map((invoice, index) => {
                                    if (invoice) {
                                        return (
                                            <InvoiceListItem
                                                key={`InvoiceListItem${index}`}
                                                invoice={invoice}
                                                selected={selectedInvoices.indexOf(invoice?._id) > -1}
                                                onSelect={this.onSelectInvoice.bind(this, invoice?._id)}
                                            />
                                        )
                                    }
                                })}
                            </Panel>
                        }
                    </div>

                    <div style={{flex: 1, paddingLeft: 25}}>
                        {selectedInvoices.length === 1 &&
                            <Invoice
                                invoice={_.findWhere(invoices, {_id: selectedInvoices[0]})}
                            />
                        }
                    </div>
                </div>

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default (Invoices)

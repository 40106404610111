import React from 'react'


class NotFoundPage extends React.Component {
    render() {
        const {style} = this.props

        const defaultStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 20
        }

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                <img
                    style={{width: 256, marginTop: 120, marginBottom: 50, objectFit: 'contain'}}
                    src="/images/VELOYDLOGO.png" alt="Logo"
                />
                {window.location.pathname.includes('retourportaal') ?
                    <>
                        <h1>Retourportaal niet gevonden</h1>

                        <p style={{maxWidth: 500, textAlign: 'center'}}>Het retourportaal waar je naar zocht bestaat niet, neem contact op met de afzender van je zending om de juiste gegevens te krijgen.</p>
                    </> :
                    <>
                        <h1>Pagina niet gevonden</h1>

                        <p style={{maxWidth: 500}}>De pagina die je zocht bestaat niet, klik <a href='/login'>hier</a> om terug te gaan.</p>
                    </>
                }

            </div>

        )
    }
}

export default (NotFoundPage)

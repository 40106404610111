import React from 'react'

import _ from 'underscore'

import {Colors, Input, CarrierSelector, DraggableList, Select, CurrencyInput, SaveBeforeExitPopup, Popup} from '../../UI/index.js'
import {Alert, Button, IconButton, Modal, H4, S1, S2} from '../../UI/index.js'

import PriceRuleModal from './PriceRuleModal.js'
import CategoryModal from './CategoryModal.js'

class PriceTableModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            open: false,
            name: '',
            oldName: '',
            priceTable: {},
            selectedCarrier: props.carriers[0] || {},
            selectedPriceTable: 'Standaard',
            error: ''
        }

        this.state = this.initialState
    }

    open(name, oldName, priceTable) {
        this.setState({open: true, name, oldName, priceTable, initialPriceTable: priceTable})
    }

    close(showWarning) {
        if (showWarning) {
            this.saveBeforeExitPopup.open(this.onSubmit.bind(this), () => {
                this.setState(this.initialState)
            })
        } else {
            this.setState(this.initialState)
        }
    }

    onChangeName(event) {
        if (!event.target.value.includes('.')) {
            this.setState({name: event.target.value})
        }
    }

    onClickPasteCarrierPriceTable(carrier) {
        const {priceTable} = this.state
        const {copiedPriceTable, onClickCopyCarrierPriceTable} = this.props

        priceTable[carrier.name] = copiedPriceTable

        this.setState({priceTable})

        onClickCopyCarrierPriceTable()
    }

    onChangeCarrier(event) {
        const {carriers} = this.props

        const selectedCarrier = _.findWhere(carriers, {name: event.target.value})

        this.setState({selectedCarrier})
    }

    onEditPriceRule(carrier, product, index, priceRule) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][index] = priceRule


        this.setState({priceTable})
    }

    onRemovePriceRule(carrier, product, index) {
        const {priceTable} = this.state

        priceTable[carrier.name][product].splice(index, 1)

        this.setState({priceTable})
    }

    onClickOpenPriceRuleModal(carrier, product, index, priceRule) {
        const {priceTable} = this.state
        let countries = [...carrier.countries]

        if (product !== 'Standaard zending') {
            product.split(', ').map((product) => {
                countries = _.intersection(countries, carrier.products[product].countries)
            })
        }

        if (countries.length > 1) {
            countries.push('Overige')
        }

        const usedCountries = []
        priceTable[carrier.name][product].map((priceRule) => {
            usedCountries.push(...priceRule.countries)
        })
        countries = _.uniq(_.difference(countries, usedCountries))
        countries.push(...priceRule.countries)

        this.PriceRuleModal.open(carrier, product, index, priceRule, countries)
    }

    onSortPricesRules(carrier, product, priceRules) {
        const {priceTable} = this.state

        priceTable[carrier.name][product] = priceRules

        this.setState({priceTable})
    }

    onClickAddPriceRule(carrier, product, productName) {
        const {priceTable} = this.state

        const priceRule = {
            name: '',
            countries: [],
            type: product.type === 'product' ? 'weight' : 'fee',
            price: '0,00',
            fee: '0,00',
            categories: [
                {
                    price: '0,00',
                    minWeight: '0',
                    maxWeight: '2',
                    productCode: ''
                },
                {
                    price: '0,00',
                    minWeight: '2',
                    maxWeight: '5',
                    productCode: ''
                },
                {
                    price: '0,00',
                    minWeight: '5',
                    maxWeight: '10',
                    productCode: ''
                },
                {
                    price: '0,00',
                    minWeight: '10',
                    maxWeight: '20',
                    productCode: ''
                },
                {
                    price: '0,00',
                    minWeight: '20',
                    maxWeight: '31,5',
                    productCode: ''
                }
            ],
            values: {}
        }

        priceTable[carrier.name] = priceTable[carrier.name] || {}
        priceTable[carrier.name][productName] = priceTable[carrier.name][productName] || []
        priceTable[carrier.name][productName].push(priceRule)


        this.setState({priceTable})

        this.onClickOpenPriceRuleModal(carrier, productName, priceTable[carrier.name][productName].length - 1, priceRule)
    }

    onChangeType(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][priceRule].type = event.target.value

        this.setState({priceTable})
    }

    onChangePrice(carrier, product, priceRule, value, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            if (value) {
                priceTable[carrier.name][product][priceRule].values[value] = event.target.value
            } else {
                priceTable[carrier.name][product][priceRule].price = event.target.value
            }

            this.setState({priceTable})
        }
    }

    onClickAddCategory(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][priceRule].categories.push({
            minWeight: '0',
            maxWeight: '0',
            price: '0,00',
            fee: '0'
        })

        // Sort categories on minWeight
        priceTable[carrier.name][product][priceRule].categories = _.sortBy(priceTable[carrier.name][product][priceRule].categories, (category) => {
            return parseFloat(category.minWeight.replace(',', '.'))
        })


        this.setState({priceTable})
    }


    onClickOpenCategoryModal(carrier, product, priceRule, index, category) {
        this.categoryModal.open(carrier, product, priceRule, index, category)
    }

    onEditCategory(carrier, product, priceRule, index, category) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][priceRule].categories[index] = category

        // Sort categories on minWeight
        priceTable[carrier.name][product][priceRule].categories = _.sortBy(priceTable[carrier.name][product][priceRule].categories, (category) => {
            return parseFloat(category.minWeight.replace(',', '.'))
        })

        this.setState({priceTable})
    }

    onRemoveCategory(carrier, product, priceRule, index) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][priceRule].categories.splice(index, 1)

        this.setState({priceTable})
    }


    onChangeCategoryPrice(carrier, product, priceRule, category, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            priceTable[carrier.name][product][priceRule].categories[category].price = event.target.value

            this.setState({priceTable})
        }
    }

    onChangeFee(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        if (/^$|^[-]{0,1}[0-9]{0,4}$|^[-]{0,1}[0-9]{0,4}[,][0-9]{0,2}$/.test(event.target.value)) {
            priceTable[carrier.name][product][priceRule].fee = event.target.value


            this.setState({priceTable})
        }
    }

    onChangeRound(carrier, product, priceRule, event) {
        const {priceTable} = this.state

        priceTable[carrier.name][product][priceRule].round = event.target.value

        this.setState({priceTable})
    }

    onRemove() {
        const {oldName} = this.state
        const {onRemove} = this.props

        this.popup.open('Tarieftabel verwijderen', 'Weet je zeker dat je deze tarieftabel wilt verwijderen?', () => {
            onRemove(oldName)
            this.close()
        })
    }

    onSubmit() {
        const {name, oldName, priceTable} = this.state
        const {onAdd, onChange} = this.props

        if (oldName) {
            onChange(name, oldName, priceTable)
        } else {
            onAdd(name, priceTable)
        }
        this.close()
    }

    render() {
        const {open, name, oldName, selectedCarrier, priceTable, error} = this.state
        const {carriers, reseller, copiedPriceTable, onClickCopyCarrierPriceTable} = this.props

        const enabledCarriers = _.filter(carriers, (carrier) => {
            return reseller.settings.parcels.carriers[carrier.name] && reseller.settings.parcels.carriers[carrier.name].enabled
        })


        let selectedCarrierProducts = []

        if (selectedCarrier && selectedCarrier.products) {
            selectedCarrierProducts = [
                'Standaard zending',
                ..._.keys(selectedCarrier.products).sort()
            ]
        }

        return (
            <Modal style={{width: 'fit-content', minWidth: '95%', maxWidth: '95%', overflowX: 'auto'}} show={open} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{`${oldName ? 'Wijzig' : 'Nieuwe'} tarieftabel`}</H4>

                    <IconButton onClick={this.close.bind(this, true)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <Input
                    label='Naam'
                    value={name}
                    onChange={this.onChangeName.bind(this)}
                    style={{maxWidth: 400}}
                    disabled={name === 'Standaard'}
                />

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', width: 330, height: 40}}>
                        <IconButton
                            disabled={!selectedCarrier.name}
                            tooltip={'Kopieer vervoerder tarieven'}
                            onClick={() => onClickCopyCarrierPriceTable(priceTable, selectedCarrier)}
                        >
                            <i className='mdi mdi-content-copy'/>
                        </IconButton>
                        <IconButton
                            disabled={!copiedPriceTable}
                            tooltip={'Plak vervoerder tarieven'}
                            onClick={this.onClickPasteCarrierPriceTable.bind(this, selectedCarrier)}
                        >
                            <i className='mdi mdi-content-paste'/>
                        </IconButton>
                    </div>
                    <CarrierSelector
                        size={45}
                        carriers={enabledCarriers}
                        value={selectedCarrier.name}
                        onChange={this.onChangeCarrier.bind(this)}
                    />
                </div>

                {selectedCarrierProducts.map((productName) => {
                    if (productName == 'Standaard zending' || (reseller.settings.parcels.products[productName] && reseller.settings.parcels.products[productName][selectedCarrier.name])) {
                        let product = selectedCarrier.products[productName]

                        if (productName == 'Standaard zending') {
                            product = {type: 'product', countries: selectedCarrier.countries}
                        }

                        return (
                            <div key={productName} style={{marginBottom: 12}}>
                                <S1 style={{marginBottom: 12}}>{productName}</S1>

                                {priceTable[selectedCarrier.name] && priceTable[selectedCarrier.name][productName] &&
                                <DraggableList
                                    items={priceTable[selectedCarrier.name][productName]}
                                    renderItem={(priceRule, DragHandle, index) => {
                                        return (
                                            <div key={`${selectedCarrier.name}${productName}${index}`} style={{display: 'flex', marginLeft: 15}}>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexShrink: 0,
                                                    width: 285,
                                                    minWidth: 285,
                                                    height: 30
                                                }}
                                                >
                                                    <DragHandle/>
                                                    <IconButton
                                                        onClick={this.onClickOpenPriceRuleModal.bind(this, selectedCarrier, productName, index, priceRule)}
                                                    >
                                                        <i className='mdi mdi-pencil'/>
                                                    </IconButton>
                                                    <S2>{priceRule.name}</S2>
                                                </div>

                                                <div style={{display: 'flex'}}>
                                                    <Select
                                                        style={{width: 150, marginRight: 12}}
                                                        allowEmptyValue
                                                        value={priceRule.type}
                                                        onChange={this.onChangeType.bind(this, selectedCarrier, productName, index)}
                                                    >
                                                        {product.type === 'product' &&
                                                            <option value='fixed'>Vaste prijs</option>
                                                        }

                                                        {product.type === 'product' &&
                                                            <option value='weight'>Gewicht</option>
                                                        }

                                                        {product.type === 'product' &&
                                                            <option value='fixedKg'>Kilo toeslag</option>
                                                        }

                                                        {product.type !== 'product' &&
                                                            <option value='fee'>Toeslag</option>
                                                        }

                                                        {product.type !== 'product' &&
                                                            <option value='feepercent'>Toeslag %</option>
                                                        }
                                                    </Select>

                                                    {priceRule.type === 'weight' && priceRule.categories.map((category, j) => {
                                                        const categoryValid = category.minWeight && category.maxWeight && parseFloat(category.maxWeight.replace(',', '.')) >= parseFloat(category.minWeight.replace(',', '.')) && category.maxWeight !== '0' && _.every(priceRule.categories, (cat, i) => {
                                                            if (!cat.minWeight || !cat.maxWeight || i === j) {
                                                                return true
                                                            }
                                                            return (parseFloat(cat.maxWeight.replace(',', '.')) <= parseFloat(category.minWeight.replace(',', '.')) || parseFloat(cat.minWeight.replace(',', '.')) >= parseFloat(category.maxWeight.replace(',', '.')))
                                                        })

                                                        return (
                                                            <div
                                                                key={j}
                                                                style={{
                                                                    width: 150,
                                                                    flexShrink: 0,
                                                                    marginRight: 12
                                                                }}
                                                            >
                                                                <CurrencyInput
                                                                    label={`${category.minWeight} t/m ${category.maxWeight} kg`}
                                                                    value={category.price}
                                                                    isInvalid={!categoryValid}
                                                                    onChange={this.onChangeCategoryPrice.bind(this, selectedCarrier, productName, index, j)}
                                                                    append={
                                                                        <IconButton
                                                                            onClick={this.onClickOpenCategoryModal.bind(this, selectedCarrier, productName, index, j, category)}
                                                                        >
                                                                            <i className='mdi mdi-pencil'/>
                                                                        </IconButton>
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                {priceRule.type === 'weight' &&
                                                    <Button
                                                        style={{marginRight: 30}}
                                                        variant='outline-white'
                                                        icon='mdi mdi-plus'
                                                        label='Nieuw gewicht'
                                                        onClick={this.onClickAddCategory.bind(this, selectedCarrier, productName, index)}
                                                    />
                                                }

                                                {(priceRule.type === 'fixed' || priceRule.type === 'fixedKg' || priceRule.type === 'fee' || priceRule.type === 'feepercent') &&
                                                <div style={{display: 'flex', width: 800, flexWrap: 'wrap'}}>
                                                    {product.values ?
                                                        product.values.map((value) => {
                                                            return (
                                                                <div
                                                                    key={value}
                                                                    style={{
                                                                        width: 150,
                                                                        marginRight: 12
                                                                    }}
                                                                >
                                                                    {priceRule.type !== 'feepercent' &&
                                                                        <CurrencyInput
                                                                            label={`${productName === 'Verzekerd' ? 'Tot: ' : ''}${value}`}
                                                                            value={priceRule.values[value]}
                                                                            onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, value)}
                                                                        />
                                                                    }

                                                                    {priceRule.type === 'feepercent' &&
                                                                        <Input
                                                                            label={`${productName === 'Verzekerd' ? 'Tot: ' : ''}${value}`}
                                                                            value={priceRule.values[value]}
                                                                            onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, value)}
                                                                            append='%'
                                                                        />
                                                                    }
                                                                </div>
                                                            )
                                                        }) :
                                                        <div style={{width: 150, marginRight: 12}}>
                                                            {priceRule.type !== 'feepercent' &&
                                                                <CurrencyInput
                                                                    label='Prijs'
                                                                    value={priceRule.price}
                                                                    onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, null)}
                                                                />
                                                            }
                                                            {priceRule.type === 'feepercent' &&
                                                                <Input
                                                                    label='Percentage'
                                                                    value={priceRule.price}
                                                                    onChange={this.onChangePrice.bind(this, selectedCarrier, productName, index, null)}
                                                                    append='%'
                                                                />
                                                            }
                                                        </div>
                                                    }

                                                    {priceRule.type === 'fixedKg' &&
                                                        <CurrencyInput
                                                            style={{width: 150, marginRight: 12}}
                                                            label='Toeslag'
                                                            value={priceRule.fee}
                                                            onChange={this.onChangeFee.bind(this, selectedCarrier, productName, index)}
                                                        />
                                                    }

                                                    {priceRule.type === 'fixedKg' &&
                                                        <Select
                                                            style={{width: 150, marginRight: 12}}
                                                            label='Afronden op'
                                                            options={[
                                                                {title: 'kg', value: ''},
                                                                {title: '100g', value: '100g'},
                                                                {title: 'g', value: 'g'}
                                                            ]}
                                                            value={priceRule.round}
                                                            onChange={this.onChangeRound.bind(this, selectedCarrier, productName, index)}
                                                        />
                                                    }
                                                </div>
                                                }
                                            </div>
                                        )
                                    }}
                                    onChange={this.onSortPricesRules.bind(this, selectedCarrier, productName)}
                                />
                                }

                                <Button
                                    variant='outline-white'
                                    style={{marginBottom: 24, width: 'fit-content'}}
                                    icon='mdi mdi-plus'
                                    label='Nieuwe landengroep'
                                    onClick={this.onClickAddPriceRule.bind(this, selectedCarrier, product, productName)}
                                />
                            </div>
                        )
                    }
                })}

                <br/>
                {error && <Alert variant="danger">{error}</Alert>}

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {oldName && oldName !== 'Standaard' &&
                        <Button variant='error' onClick={this.onRemove.bind(this)}>Verwijder</Button>
                    }
                    <Button variant='text' onClick={this.close.bind(this, true)}>Annuleer</Button>
                    <Button variant='text' disabled={!name} onClick={this.onSubmit.bind(this)}>Opslaan</Button>
                </div>

                <PriceRuleModal
                    onChange={this.onEditPriceRule.bind(this)}
                    remove={this.onRemovePriceRule.bind(this)}
                    ref={(ref) => this.PriceRuleModal = ref}
                />

                <CategoryModal
                    onChange={this.onEditCategory.bind(this)}
                    remove={this.onRemoveCategory.bind(this)}
                    ref={(ref) => this.categoryModal = ref}
                />

                <SaveBeforeExitPopup
                    ref={(ref) => this.saveBeforeExitPopup = ref}
                />

                <Popup
                    ref={(ref) => this.popup = ref}
                    cancelBtnText='Terug'
                    confirmBtnText='Verwijderen'
                />
            </Modal>
        )
    }
}

export default (PriceTableModal)

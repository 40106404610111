// @ts-check

import React, {useState, useEffect, useRef} from 'react'
import {Alert, Button, H4, IconButton, Input, MetricInput, Modal, RadioGroup, Row, Select, Toggle} from '../UI/index.js'
import {fuelTypeMap, payloadTypeMap, routeProfileMap} from '../../views/planner/Vehicles.js'
import IconPicker from '../UI/IconPicker.js'

/**
 * @typedef {object} EditVehicleModalProps
 * @property {VehicleType} vehicleType
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {VehicleType[]} vehicleTypes
 * @property {(vehicle: VehicleType) => void} onChange
 * @property {() => void} onArchive
 * @property {string[]} [errors]
 * @property {boolean} [noArchivePossible]
 */

const usageMap = {
    electric: 'kWh',
    hydrogen: 'kg'
}

/**
 * @component
 *
 * @param {EditVehicleModalProps} props
 * @returns {JSX.Element}
 */
export default function EditVehicleTypeModal({vehicleType, open, onClose, vehicleTypes, onChange, onArchive, errors, noArchivePossible}) {
    const [vehicleTypeLocal, setVehicleLocal] = useState(structuredClone(vehicleType))
    const [duplicateNameError, setDuplicateNameError] = useState('')
    const nameRef = useRef(null)
    const [iconInvalid, setIconInvalid] = useState(false)
    const routeProfileRef = useRef(null)


    const onSave = () => {
        setIconInvalid(false)
        setDuplicateNameError('')
        const requiredFields = [
            {
                value: vehicleTypeLocal.name,
                ref: nameRef
            }
        ]

        let fieldsAreValid = true

        requiredFields.forEach((field) => {
            if (!field.value) {
                if (field.ref) {
                    field.ref.current?.setInvalid()
                }

                if (field.action) {
                    field.action()
                }

                fieldsAreValid = false
            }
        })

        const vehicleWithSameNameExists = vehicleTypes.some((vehicleType) => vehicleType.name === vehicleTypeLocal.name && vehicleType._id !== vehicleTypeLocal._id)

        if (vehicleWithSameNameExists) {
            setDuplicateNameError('Er bestaat al een voertuigsoort met deze naam.')
            return
        }

        if (fieldsAreValid) {
            onChange(vehicleTypeLocal)
        }
    }

    useEffect(() => {
        setVehicleLocal(structuredClone(vehicleType))
    }, [vehicleType])

    useEffect(() => {
        if (!open) {
            setVehicleLocal(structuredClone(vehicleType))
            setDuplicateNameError('')
        } else {
            console.log(vehicleType)
        }
    }, [open])

    const inputStyle = {
        marginLeft: 0,
        marginRight: 0
    }

    return (
        <Modal show={open} onClose={onClose} style={{maxWidth: 600}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 24}}>
                <H4>Voertuigsoort</H4>

                <IconButton icon='mdi mdi-close' onClick={onClose}/>
            </div>


            <Row style={{gap: 12}}>
                <Input
                    label='Naam'
                    value={vehicleTypeLocal.name}
                    onChange={(event) => {
                        setVehicleLocal({...vehicleTypeLocal, name: event.target.value})
                        setDuplicateNameError('')
                    }}
                    ref={nameRef}
                    style={{...inputStyle, flex: 1}}
                />

                <div style={{flex: 1}}>
                    <IconPicker
                        label='Icoon'
                        value={vehicleTypeLocal.icon}
                        icons={['bike', 'bike-fast', 'bicycle-cargo', 'truck-trailer', 'car-side', 'van-utility', 'truck', 'car-electric', 'bus-electric', 'moped', 'moped-electric', 'walk']}
                        onChange={(event) => {
                            setVehicleLocal({...vehicleTypeLocal, icon: event.target.value})
                        }}
                        isInvalid={iconInvalid}
                    />
                </div>
            </Row>

            {duplicateNameError && <Alert variant='error'>{duplicateNameError}</Alert>}

            <Row style={{gap: 12}}>
                <Select
                    label='Routeberekening'
                    value={vehicleTypeLocal.routeProfile}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, routeProfile: event.target.value})}
                    options={
                        [
                            ...['car', 'bike', 'walk'].map((routeProfile) => ({
                                value: routeProfile,
                                title: routeProfileMap[routeProfile]
                            }))
                        ]
                    }
                    ref={routeProfileRef}
                    style={inputStyle}
                />

                <Toggle
                    label='Snelheid aanpassen'
                    checked={vehicleTypeLocal.speedDifferenceEnabled}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, speedDifferenceEnabled: event.target.checked})}
                    style={{marginLeft: 0, marginRight: 0}}
                />
            </Row>


            {vehicleTypeLocal.speedDifferenceEnabled &&
            <Row style={{gap: 12}}>
                <div style={{flex: 1}}>
                    <RadioGroup
                        options={[
                            {value: 'faster', label: 'Sneller'},
                            {value: 'slower', label: 'Langzamer'}
                        ]}
                        value={vehicleTypeLocal.speedDifferenceMode || 'faster'}
                        onChange={(event) => setVehicleLocal({...vehicleTypeLocal, speedDifferenceMode: event.target.value})}
                        style={{marginLeft: 0, marginRight: 0}}
                    />
                </div>

                <Input
                    type='number'
                    label='Snelheidsafwijking'
                    value={vehicleTypeLocal.speedDifference}
                    min={0}
                    max={vehicleTypeLocal.speedDifferenceMode === 'faster' ? 99 : undefined}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, speedDifference: event.target.value})}
                    append='%'
                    style={inputStyle}
                />
            </Row>
            }

            <Row>
                <Select
                    label='Aandrijving / brandstof'
                    value={vehicleTypeLocal.fuelType}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, fuelType: event.target.value})}
                    options={
                        [
                            ...['green-electric', 'grey-electric', 'unknown-electric', 'bio-diesel', 'diesel', 'lpg', 'hydrogen', 'none'].map((fuelType) => ({
                                value: fuelType,
                                title: fuelTypeMap[fuelType]
                            }))
                        ]
                    }
                    style={inputStyle}
                />
            </Row>


            {vehicleTypeLocal.fuelType !== 'none' &&
            <Row>
                <MetricInput
                    label={`Verbruik (${usageMap[Object.keys(usageMap).find((key) => vehicleTypeLocal.fuelType.includes(key))] || 'L'})`}
                    value={vehicleTypeLocal.usage}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, usage: event.target.value})}
                    divisionFactor={1}
                    precision={2}
                    append='per 100km'
                    appendStyle={{width: 'unset', height: 'unset'}}
                    style={inputStyle}
                />
            </Row>
            }

            <Row>
                <MetricInput
                    label='Bereik'
                    value={vehicleTypeLocal.range}
                    onChange={(/** @type {{ target: { value: number; }; }} */ event) => setVehicleLocal({...vehicleTypeLocal, range: event.target.value})}
                    divisionFactor={1000}
                    append='km'
                    style={inputStyle}
                />
            </Row>

            <Row style={{gap: 12}}>

                <Select
                    label='Laadvermogen type'
                    value={vehicleTypeLocal.payloadType}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, payloadType: event.target.value})}
                    style={inputStyle}
                >
                    <option value='weight'>{payloadTypeMap['weight']}</option>
                    <option value='colli'>{payloadTypeMap['colli']}</option>
                </Select>

                <MetricInput
                    label='Laadvermogen'
                    value={vehicleTypeLocal.payload}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, payload: event.target.value})}
                    divisionFactor={vehicleTypeLocal.payloadType === 'weight' ? 1000 : 1}
                    append={vehicleTypeLocal.payloadType === 'weight' ? 'kg' : 'stuks'}
                    style={inputStyle}
                />

            </Row>

            <Row>
                <MetricInput
                    label='Stoptijd'
                    value={vehicleTypeLocal.stopTime}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, stopTime: event.target.value})}
                    append='s'
                    style={inputStyle}
                />
            </Row>

            <Row>
                <Input
                    label='Opmerking voor koerier'
                    value={vehicleTypeLocal.comment}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, comment: event.target.value})}
                    style={inputStyle}
                />
            </Row>

            <Row>
                <Input
                    label='Notities'
                    value={vehicleTypeLocal.notes}
                    onChange={(event) => setVehicleLocal({...vehicleTypeLocal, notes: event.target.value})}
                    style={inputStyle}
                />
            </Row>

            {errors?.length > 0 && errors.map((error, index) => (
                <Alert key={index} variant='error'>
                    {error}
                </Alert>
            ))}

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {vehicleTypeLocal._id && (vehicleTypeLocal.isArchived || !noArchivePossible) ?
                    <Button onClick={onArchive} variant='error'>
                        {vehicleTypeLocal.isArchived ? 'Dearchiveren' : 'Archiveren'}
                    </Button> :
                    <Button onClick={onClose} variant='error'>Annuleren</Button>
                }

                <Button
                    style={{marginLeft: 6}}
                    disabled={!vehicleTypeLocal.name}
                    onClick={onSave}
                >
                    Opslaan
                </Button>
            </div>
        </Modal>
    )
}

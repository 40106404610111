import React from 'react'
import _ from 'lodash'

import {
    H3,
    Panel,
    SMTPSettings
} from '../../../../components/UI/index.js'

class EmailSettings extends React.Component {
    onChangeChecked(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.checked)

        this.props.onChange(reseller, path)
    }

    onChangeValue(path, event) {
        const reseller = {...this.props.reseller}

        _.set(reseller.settings, path, event.target.value)

        this.props.onChange(reseller)
    }

    onSaveValue(path, event) {
        const reseller = {...this.props.reseller}

        if (event) {
            _.set(reseller.settings, path, event.target.value)
        }

        this.props.onChange(reseller, path)
    }

    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Email instellingen</H3>

                <SMTPSettings
                    settings={reseller.settings}
                    onChange={this.onChangeValue.bind(this)}
                    onChangeChecked={this.onChangeChecked.bind(this)}
                    onBlur={this.onSaveValue.bind(this)}
                />
            </Panel>
        )
    }
}

export default EmailSettings

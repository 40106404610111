import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'
import {RRule} from 'rrule'
import _ from 'underscore'

import SubscriptionStore from '../../stores/SubscriptionStore.js'
import SubscriptionActions from '../../actions/SubscriptionActions.js'
import CustomerStore from '../../stores/CustomerStore.js'

import Table from '../../components/Table/index.js'
import SearchBar from '../../components/SearchBar/index.js'
import {Button, Colors, IconButton, Panel, Popup} from '../../components/UI/index.js'
import SubscriptionModal from '../../components/subscriptions/SubscriptionModal.js'
import csvExport from '../../utils/csvExport.js'
import languages from '../../../server/languages.js'
import filter from '../../utils/filter.js'

class Subscriptions extends Reflux.Component {
    constructor(props) {
        super(props)
        this.stores = [SubscriptionStore, CustomerStore]
    }

    componentDidMount() {
        const {reseller} = this.props
        if (this.props.location?.state?.subscriptionId) {
            this.onClickView(this.props.location?.state?.subscriptionId)
        }

        document.title = `Abonnementen • ${reseller.settings.accountName || reseller.name}`

        window.history.replaceState({}, '')
    }

    columns() {
        return [
            {title: 'Klant', visible: true, flex: 1, key: 'customer', sort: (subscription) => {
                return this.state.customers[subscription.customer] ? this.state.customers[subscription.customer].name : ''
            }, render: (subscription) => {
                return this.state.customers[subscription.customer] ? this.state.customers[subscription.customer].name : ''
            }},
            {title: 'Referentie', visible: true, flex: 1, key: 'reference'},
            {title: 'Omschrijving', visible: true, flex: 1, key: 'description'},
            {title: 'Prijs', visible: true, width: 120, key: 'price', render: (subscription) => {
                return `€ ${subscription.price}`
            }},
            {title: 'Termijn', visible: true, width: 300, render: (subscription) => {
                return RRule.fromString(subscription.rrule).toText((t) => {
                    return languages.DUTCH.rrule[t] || t
                }, languages.DUTCH)
            }},
            {title: 'Eerste factuur datum', visible: true, width: 300, key: 'rrule', render: (subscription) => {
                return moment(RRule.fromString(subscription.rrule).options.dtstart).format('DD-MM-YYYY')
            }},
            {title: 'Startdatum', visible: true, width: 120, key: 'startDate', render: (subscription) => {
                return moment(subscription.startDate).format('DD-MM-YYYY')
            }},
            {title: 'Einddatum', visible: true, width: 120, key: 'endDate', render: (subscription) => {
                return subscription.endDate === '2999-12-31' ? '' : moment(subscription.endDate).format('DD-MM-YYYY')
            }},
            {title: 'Opmerking', visible: true, flex: 1, key: 'notes'},
            {title: '', visible: true, key: '', width: 60, onClick: () => {}, render: (subscription) => {
                return (
                    <IconButton onClick={this.onClickView.bind(this, subscription._id)}>
                        <i className="mdi mdi-eye"/>
                    </IconButton>
                )
            }}
        ]
    }

    onChangeQueries(subscriptionQueries) {
        this.table.selectPage(1)
        SubscriptionActions.setQueries(subscriptionQueries)
    }

    onChangeTable(selectedSubscriptions) {
        SubscriptionActions.setSelected(selectedSubscriptions)
    }

    subscriptionColor(subscription) {
        const date = moment().format('YYYY-MM-DD')

        return date > subscription.endDate && Colors.warningLight
    }

    onClickNew() {
        this.subscriptionModal.open()
        SubscriptionActions.setSelected([])
    }

    onClickView(id) {
        this.subscriptionModal.open(id)
    }

    onClickExport() {
        const {subscriptions, selectedSubscriptions, customers} = this.state
        const exportSubscriptions = []

        selectedSubscriptions.map((id) => {
            const subscription = _.findWhere(subscriptions, {_id: id})
            if (subscription) {
                exportSubscriptions.push(subscription)
            }
        })
        csvExport.subscriptions(exportSubscriptions, customers)
        SubscriptionActions.setSelected([])
    }

    onClickRemove() {
        const {selectedSubscriptions} = this.state

        this.popup.open('Abonnementen verwijderen', 'Abonnementen worden definitief verwijderd.', () => {
            SubscriptionActions.setSelected([])

            SubscriptionActions.remove(selectedSubscriptions, (err) => {
                if (err) {
                    this.popup.setError(err)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    render() {
        const {selectedSubscriptions, subscriptionsLoading, subscriptionQueries, customers} = this.state
        const {history} = this.props

        const subscriptions = filter.subscriptions(this.state.subscriptions, subscriptionQueries)

        return (
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Panel style={{display: 'flex', borderTop: 'none', background: Colors.backgroundNeutral, padding: '20px 10px 10px 10px'}}>

                    <SearchBar
                        columns={this.columns()}
                        onQueryChange={this.onChangeQueries.bind(this)}
                        queries={subscriptionQueries}
                    />

                    <Button
                        variant='outline-white'
                        onClick={this.onClickNew.bind(this)}
                    >
                        Nieuw Abonnement
                    </Button>

                    {selectedSubscriptions.length > 0 &&
                        <>
                            <Button
                                variant='outline-white'
                                onClick={this.onClickExport.bind(this)}
                            >
                                <i className="mdi mdi-file-export"/>
                            </Button>

                            <Button
                                variant='outline-white'
                                onClick={this.onClickRemove.bind(this)}
                            >
                                <i className="mdi mdi-delete"/>
                            </Button>
                        </>
                    }
                </Panel>

                <div style={{flex: 1, marginTop: 24, marginRight: 24, marginLeft: 24}}>
                    <Table
                        tableName='subscriptions'
                        columns={this.columns()}
                        sortColumnIndex={7}
                        sortDecending={true}
                        rows={subscriptions}
                        rowBackground={this.subscriptionColor.bind(this)}
                        selectedRows={selectedSubscriptions}
                        loading={subscriptionsLoading}
                        onChange={this.onChangeTable.bind(this)}
                        ref={(ref) => this.table = ref}
                    />
                </div>

                <Popup ref={(modal) => this.popup = modal}/>

                <SubscriptionModal
                    history={history}
                    customers={customers}
                    ref={(modal) => this.subscriptionModal = modal}
                />
            </div>
        )
    }
}

export default (Subscriptions)


import React from 'react'

import {IconButton, Info, P} from '../UI/index.js'

class FileUpload extends React.Component {
    upload(event) {
        event.preventDefault()
        this.fileControl.click()
    }

    saveFile(event) {
        const {multiple, maxAmount, maxSize, accept} = this.props
        event.preventDefault()

        // TODO: Add stripping of files when max is reached. Add stripping of files when not allowed extension. Add stripping of files when too large.
        if (multiple) {
            const files = event.target.files

            if (typeof max === 'number' && files.length > maxAmount) {
                alert(`Maximaal ${maxAmount} bestanden toegestaan, de eerste ${maxAmount} bestanden worden geüpload.`)
            }

            const loadedFilesPromises = files.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    const fileName = file.name

                    reader.onloadend = (event) => {
                        resolve({fileName, data: event.target.result})
                    }

                    reader.onerror = (event) => {
                        reject(event)
                    }

                    reader.readAsDataURL(file)
                })
            })

            Promise.all(loadedFilesPromises).then((loadedFiles) => {
                this.props.onChange(loadedFiles)
            })
        } else {
            const reader = new FileReader()

            const file = event.target.files[0]

            const extension = file.name.split('.').pop().toLowerCase()

            if (accept && accept.length && !accept.includes(`.${extension}`)) {
                alert(`Bestandstype ${extension} niet toegestaan.`)
                return
            }

            const fileName = file.name

            // TODO: Add max size check, maxSize is in mb, so 5mb for example
            if (maxSize && file.size > maxSize * 1024 * 1024) {
                alert(`Bestand is te groot, maximaal ${maxSize}mb toegestaan.`)
                return
            }


            reader.onloadend = (event) => {
                this.props.onChange(fileName, event.target.result, file.type)
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    removeFile(event) {
        event.preventDefault()
        this.props.onChange('', '')
    }

    render() {
        const {style, label, info, infoIcon, fileName, accept, multiple} = this.props

        const labelStyle = {
            display: 'flex',
            alignItems: 'center'
        }

        const defaultStyle = {display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 42, marginBottom: 12}

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                {label &&
                    <P style={labelStyle}>
                        <div style={{whiteSpace: 'nowrap'}}>{label}</div>
                        {info && <Info icon={infoIcon} text={info}/>}
                    </P>
                }

                <div style={{display: 'flex', alignItems: 'center'}}>

                    {fileName &&
                        <P>{fileName}</P>
                    }

                    <IconButton onClick={this.upload.bind(this)}>
                        <i className='mdi mdi-upload'/>
                    </IconButton>

                    {fileName &&
                        <IconButton onClick={this.removeFile.bind(this)}>
                            <i className='mdi mdi-delete'/>
                        </IconButton>
                    }
                </div>

                <input
                    style={{display: 'none'}}
                    type="file" accept={accept && accept.length ? accept.join() : undefined}
                    ref={(ref) => this.fileControl = ref}
                    onChange={this.saveFile.bind(this)}
                    multiple={multiple}
                />
            </div>
        )
    }
}

export default (FileUpload)

import React from 'react'


import {IconButton, Popover} from '../UI/index.js'


export default class ColorPicker extends React.Component {
    onClickColor(color, event) {
        const {onChange} = this.props
        event.target.value = color
        onChange(event)
        this.popover.close()
    }

    render() {
        const {value, colors} = this.props

        return (
            <Popover
                noClose={true}
                ref={(ref) => this.popover = ref}
                content={
                    <div style={{display: 'flex', flexWrap: 'wrap', width: 216}}>
                        {colors.map((color) => {
                            return (
                                <IconButton
                                    key={color}
                                    onClick={this.onClickColor.bind(this, color)}
                                >
                                    <i style={{color, fontSize: 20}} className='mdi mdi-circle'/>
                                </IconButton>
                            )
                        })}
                    </div>
                }
            >
                <IconButton style={{color: value}} >
                    <i className='mdi mdi-circle'/>
                </IconButton>
            </Popover>
        )
    }
}


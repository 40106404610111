import React from 'react'


import AdminActions from '../../../../actions/AdminActions.js'

import {Input, CurrencyInput, Check, Select, Toggle, Row, Column, ConfirmPopup, TextArea, DatePicker} from '../../../../components/UI/index.js'
import {Alert, Button, IconButton, Card, H4, H6, P} from '../../../../components/UI/index.js'
import RemovePopup from './RemovePopup.js'
import ArchivePopup from './ArchivePopup.js'
import _ from 'lodash'

class EditReseller extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reseller: JSON.parse(JSON.stringify(props.reseller)),
            user: {
                name: '',
                firstName: '',
                lastName: '',
                address: {
                    name: '',
                    street: '',
                    nr: '',
                    addition: '',
                    street2: '',
                    postalCode: '',
                    city: '',
                    country: 'NL'
                },
                email: '',
                phone: '',
                settings: {
                    customSpeed: 0,
                    speedDifference: 0,
                    stopTime: 0
                },
                permissions: {
                    orders: false,
                    parcels: false,
                    shifts: false,
                    subscriptions: false,
                    customers: false,
                    invoices: false,
                    users: false,
                    vehicles: false,
                    paychecks: false,
                    settings: false
                }
            },
            loading: false,
            message: '',
            error: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reseller !== this.props.reseller) {
            this.setState({reseller: JSON.parse(JSON.stringify(this.props.reseller))})
        }
    }

    onChangePrice(category, index, key, event) {
        const reseller = {...this.state.reseller}

        reseller.prices[category][index][key] = event.target.value


        this.setState({reseller})
    }

    onClickAddPrice(category) {
        const reseller = {...this.state.reseller}


        const previousPrice = reseller.prices[category][reseller.prices[category].length-1]

        if (previousPrice) {
            reseller.prices[category].push({max: previousPrice.max, price: previousPrice.price})
        } else {
            reseller.prices[category].push({max: 100, price: '0,00'})
        }


        this.setState({reseller})
    }

    onClickRemovePrice(category, index) {
        const reseller = {...this.state.reseller}

        reseller.prices[category].splice(index, 1)

        this.setState({reseller})
    }

    onChangeValue(category, key, event) {
        const reseller = {...this.state.reseller}

        if (category) {
            reseller[category][key] = event.target.value
        } else {
            reseller[key] = event.target.value
        }

        if (key === 'accountType') {
            reseller.permissions.parcels = event.target.value === 'carrierPlus'
            reseller.permissions.vehicles = event.target.value === 'carrierPlus'
        }

        this.setState({reseller})
    }

    onChangeValues(path, event) {
        const reseller = {...this.state.reseller}

        _.set(reseller, path, event.target.values)

        this.setState({reseller})
    }

    onChangeChecked(category, key, event) {
        const reseller = {...this.state.reseller}

        if (category) {
            reseller[category][key] = event.target.checked
        } else {
            reseller[key] = event.target.checked
        }

        this.setState({reseller})
    }

    onChangeValueUser(key, event) {
        const user = {...this.state.user}

        if (key === 'email') {
            user[key] = event.target.value.toLowerCase()
        } else {
            user[key] = event.target.value
        }


        this.setState({user})
    }

    onClickSave() {
        const {reseller, user} = this.state

        this.setState({loading: true, message: '', error: ''})

        AdminActions.updateReseller(reseller, reseller._id ? null : user, (err) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, message: 'Je wijzigingen zijn opgeslagen'})
            }
        })
    }

    onClickCancel() {
        AdminActions.selectReseller(null)
    }

    render() {
        const {reseller, user, loading, message, error} = this.state
        const {resellers, viewArchived} = this.props

        return (
            <Card style={{padding: 24, width: 800, maxHeight: '95%', overflowY: 'auto'}}>
                <H4 style={{marginBottom: 48}}>{reseller._id ? 'Bewerk reseller' : 'Nieuwe reseller'}</H4>

                <Input
                    label='Naam'
                    value={(reseller.settings?.accountName && reseller.settings?.accountName !== reseller.name) ? `${reseller.settings.accountName} (${reseller.name})` : reseller.name}
                    disabled={reseller._id}
                    onChange={this.onChangeValue.bind(this, null, 'name')}
                />

                <Select
                    label='Subaccount van'
                    allowEmptyValue={true}
                    value={reseller.invoiceResellerId}
                    onChange={this.onChangeValue.bind(this, null, 'invoiceResellerId')}
                >
                    {resellers.map((reseller) => {
                        return <option key={reseller._id} value={reseller._id}>{reseller.settings?.accountName ? `${reseller.settings.accountName} (${reseller.name})` : reseller.name}</option>
                    })}
                </Select>


                {!reseller.invoiceResellerId &&
                <>
                    <DatePicker
                        label='Startdatum'
                        value={reseller.startDate}
                        onChange={this.onChangeValue.bind(this, null, 'startDate')}
                    />

                    <Toggle
                        label='Deze reseller niet factureren'
                        checked={reseller.noInvoice}
                        onChange={this.onChangeChecked.bind(this, null, 'noInvoice')}
                    />

                    <Input
                        label='Debiteurnummer'
                        value={reseller.debtorCode}
                        onChange={this.onChangeValue.bind(this, null, 'debtorCode')}
                    />

                    <Select
                        label='Account type'
                        value={reseller.accountType}
                        onChange={this.onChangeValue.bind(this, null, 'accountType')}
                    >
                        <option value='carrier'>Koerier Standaard</option>
                        <option value='carrierPlus'>Koerier Plus</option>
                    </Select>

                    <Toggle
                        label='Vestiging beheer aan/uit'
                        checked={reseller.prices.branchManagement}
                        onChange={this.onChangeChecked.bind(this, 'prices', 'branchManagement')}
                    />

                    <Input
                        label='Referentie (wordt meegestuurd naar Jortt)'
                        value={reseller.reference}
                        onChange={this.onChangeValue.bind(this, null, 'reference')}
                    />

                    <TextArea
                        style={{marginTop: 24}}
                        label='Notities'
                        rows={10}
                        value={reseller.notes}
                        onChange={this.onChangeValue.bind(this, null, 'notes')}
                    />


                    <H4 style={{marginTop: 48, marginBottom: 24}}>Tarieven</H4>

                    <Toggle
                        label='Custom tarieven'
                        checked={reseller.prices.customPrices}
                        onChange={this.onChangeChecked.bind(this, 'prices', 'customPrices')}
                    />

                    {reseller.prices.customPrices &&
                        <Row style={{gap: 24}}>
                            <Column style={{flexDirection: 'column'}}>
                                <H6>Ritten</H6>


                                {reseller.prices.orders.map((orderPrice, index) => {
                                    return (
                                        <div key={`orderPrice${index}`} style={{display: 'flex'}}>
                                            <Input
                                                type='number'
                                                value={orderPrice.max}
                                                onChange={this.onChangePrice.bind(this, 'orders', index, 'max')}
                                            />

                                            <CurrencyInput
                                                value={orderPrice.price}
                                                onChange={this.onChangePrice.bind(this, 'orders', index, 'price')}
                                            />

                                            <IconButton onClick={this.onClickRemovePrice.bind(this, 'orders', index)}><i className='mdi mdi-delete'/></IconButton>
                                        </div>


                                    )
                                })}

                                <Button
                                    variant='outline-white'
                                    icon='mdi mdi-plus'
                                    label='Nieuwe staffel'
                                    onClick={this.onClickAddPrice.bind(this, 'orders')}
                                />

                            </Column>


                            <Column style={{flexDirection: 'column'}}>
                                <H6>Zendingen</H6>
                                {reseller.prices.parcels.map((parcelPrice, index) => {
                                    return (
                                        <div key={`parcelPrice${index}`} style={{display: 'flex'}}>
                                            <Input
                                                type='number'
                                                value={parcelPrice.max}
                                                onChange={this.onChangePrice.bind(this, 'parcels', index, 'max')}
                                            />

                                            <CurrencyInput
                                                value={parcelPrice.price}
                                                onChange={this.onChangePrice.bind(this, 'parcels', index, 'price')}
                                            />

                                            <IconButton onClick={this.onClickRemovePrice.bind(this, 'parcels', index)}><i className='mdi mdi-delete'/></IconButton>
                                        </div>


                                    )
                                })}

                                <Button
                                    variant='outline-white'
                                    icon='mdi mdi-plus'
                                    label='Nieuwe staffel'
                                    onClick={this.onClickAddPrice.bind(this, 'parcels')}
                                />

                            </Column>
                        </Row>
                    }
                </>
                }

                <H4 style={{marginTop: 48, marginBottom: 24}}>Herhaalde factuurregels (maandelijks)</H4>

                {reseller?.repeatedInvoiceRules?.map((rule, index) => {
                    return (
                        <div key={`rule${index}`} style={{display: 'flex'}}>
                            <Input
                                label='Omschrijving'
                                value={rule.description}
                                onChange={(event) => {
                                    const newRule = {...rule, description: event.target.value}
                                    this.onChangeValue('repeatedInvoiceRules', index, {target: {value: newRule}})
                                }}
                            />

                            <CurrencyInput
                                decimals={2}
                                value={rule.price}
                                onChange={(event) => {
                                    const newRule = {...rule, price: parseFloat(event.target.value.replace(',', '.'))}
                                    this.onChangeValue('repeatedInvoiceRules', index, {target: {value: newRule}})
                                }}
                            />

                            <IconButton
                                onClick={() => {
                                    const reseller = {...this.state.reseller}

                                    reseller.repeatedInvoiceRules.splice(index, 1)

                                    this.setState({reseller})
                                }}
                                icon='mdi mdi-delete'
                            />
                        </div>
                    )
                })}

                <Button
                    variant='outline-white'
                    icon='mdi mdi-plus'
                    label='Nieuwe regel'
                    onClick={() => {
                        const reseller = {...this.state.reseller}

                        reseller.repeatedInvoiceRules = reseller.repeatedInvoiceRules || []
                        reseller.repeatedInvoiceRules.push({description: '', price: 0})

                        this.setState({reseller})
                    }}
                />

                <H4 style={{marginTop: 48, marginBottom: 24}}>Instellingen</H4>

                <Row>
                    <Column>
                        <Select
                            label='Rit partners'
                            allowEmptyValue={true}
                            values={reseller.orderPartners || []}
                            options={resellers.map((partner) => {
                                if (partner._id !== reseller._id) {
                                    return {value: partner._id, title: partner.name}
                                }
                            })}
                            onChange={this.onChangeValues.bind(this, 'orderPartners')}
                        />

                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Toggle
                            label='PostNL-MSG koppeling beschikbaar'
                            checked={reseller.msgPostNLenabled}
                            info='Als deze optie wordt uitgezet moet de koppeling handmatig worden gedeactiveerd in het account van de reseller.'
                            onChange={this.onChangeChecked.bind(this, null, 'msgPostNLenabled')}
                        />
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Toggle
                            label='DHL-MSG koppeling beschikbaar'
                            checked={reseller.msgDHLenabled}
                            info='Als deze optie wordt uitgezet moet de koppeling handmatig worden gedeactiveerd in het account van de reseller.'
                            onChange={this.onChangeChecked.bind(this, null, 'msgDHLenabled')}
                        />
                    </Column>
                </Row>

                {!reseller.invoiceResellerId &&
                    <>
                        <Row>
                            <Column>
                                <Toggle
                                    label='Custom branding beschikbaar'
                                    info='Hier zit mollie facturatie aan gekoppeld'
                                    checked={reseller.customBrandingEnabled}
                                    onChange={this.onChangeChecked.bind(this, null, 'customBrandingEnabled')}
                                />
                            </Column>
                        </Row>

                        {reseller.customBrandingEnabled &&
                        <Row>
                            <Column>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Input
                                        label='domeinnaam'
                                        value={reseller.settings.domain}
                                        onChange={this.onChangeValue.bind(this, 'settings', 'domain')}
                                    />
                                    <P>.veloyd.nl</P>
                                </div>
                            </Column>
                        </Row>
                        }
                    </>
                }


                <H4 style={{marginTop: 48, marginBottom: 24}}>Modules</H4>
                <Check
                    label='Ritten'
                    checked={reseller.permissions.orders}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'orders')}
                />
                <Check
                    label='Zendingen'
                    checked={reseller.permissions.parcels}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'parcels')}
                />
                <Check
                    label='Diensten'
                    checked={reseller.permissions.shifts}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'shifts')}
                />
                <Check
                    label='Urenregistratie'
                    checked={reseller.permissions.paychecks}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'paychecks')}
                />
                <Check
                    label='Abonnementen'
                    checked={reseller.permissions.subscriptions}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'subscriptions')}
                />
                <Check
                    label='Facturatie'
                    checked={reseller.permissions.invoices}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'invoices')}
                />
                <Check
                    label='Voertuigen'
                    checked={reseller.permissions.vehicles}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'vehicles')}
                />
                <Check
                    label='Klanten'
                    checked={reseller.permissions.customers}
                    onChange={this.onChangeChecked.bind(this, 'permissions', 'customers')}
                />

                {!reseller._id &&
                    <>
                        <br/>
                        <br/>
                        <H4>Gebruiker</H4>
                        <Input
                            label='Naam'
                            value={user.name}
                            onChange={this.onChangeValueUser.bind(this, 'name')}
                        />
                        <Input
                            label='Emailadres'
                            value={user.email}
                            onChange={this.onChangeValueUser.bind(this, 'email')}
                        />
                    </>
                }

                <br/>
                {message &&
                    <Alert variant='success' dismissible onClose={() => this.setState({message: ''})}>
                        {message}
                    </Alert>
                }
                {error &&
                    <Alert variant='danger' dismissible onClose={() => this.setState({error: ''})}>
                        {error}
                    </Alert>
                }

                <div style={{display: 'flex'}}>
                    {reseller._id &&
                    <>
                        <Button variant='error' disabled={loading} onClick={() => this.removePopup.open(reseller)}>Verwijder</Button>
                        <Button onClick={() => this.archivePopup.open(reseller)} disabled={loading}>{reseller.isArchived ? 'Activeer' : 'Archiveer'}</Button>
                    </>
                    }

                    <div style={{flex: 1}}/>

                    <Button variant='text' disabled={loading} onClick={this.onClickCancel.bind(this)}>Annuleren</Button>
                    <Button onClick={this.onClickSave.bind(this)} loading={loading}>Opslaan</Button>


                </div>

                <RemovePopup ref={(ref) => this.removePopup = ref}/>
                <ArchivePopup ref={(ref) => this.archivePopup = ref} viewArchived={viewArchived}/>
                <ConfirmPopup ref={(ref) => this.confirmPopup = ref}/>
            </Card>
        )
    }
}

export default (EditReseller)

import React from 'react'

import Reflux from 'reflux-react-16'

import LoginStore from '../../../stores/LoginStore.js'
import LoginActions from '../../../actions/LoginActions.js'


class LightSpeedUninstall extends Reflux.Component {
    constructor(props) {
        super(props)

        this.store = LoginStore
    }

    componentDidMount() {
        const {history} = this.props

        const query = new URLSearchParams(history.location.search)
        const language = query.get('language')
        const shopId = query.get('shop_id')
        const timestamp = query.get('timestamp')
        const signature = query.get('signature')

        LoginActions.uninstallLightspeed(language, shopId, timestamp, signature, (err) =>{
            history.push('/klant/instellingen/koppelingen/webshops/lightspeed', {err, message: !err && 'Lightspeed koppeling is gedeactiveerd.'})
        })
    }


    render() {
        return (
            <div/>
        )
    }
}

export default (LightSpeedUninstall)

import _ from 'lodash'
import dbCodes from '../dbCodes.js'

function colliToPickupStatus(entry) {
    if (entry.type !== 'pickup') {
        return undefined
    }

    let pickupStatus = dbCodes.pickupStatus.nietOpgehaald()

    const someColliCollected = _.some(entry.colli, (collo) => collo.status === 'collected')

    if (someColliCollected) {
        pickupStatus = dbCodes.pickupStatus.opgehaald()
    } else {
        const allColliNotHome = _.every(entry.colli, (collo) => collo.status === 'notdelivered' && collo.reason === 'nothome')

        if (allColliNotHome) {
            pickupStatus = dbCodes.pickupStatus.nietThuis()
        }
    }

    return pickupStatus
}

function colliToDeliveryStatus(entry) {
    if (entry.type !== 'delivery') {
        return undefined
    }

    let deliveryStatus = dbCodes.deliveryStatus.nietBezorgd()

    const someColliDelivered = _.some(entry.colli, (collo) => collo.status === 'delivered')

    if (someColliDelivered) {
        deliveryStatus = dbCodes.deliveryStatus.bezorgd()

        const someColliNeighbors = _.some(entry.colli, (collo) => collo.status === 'delivered' && collo.deliveredAtNeighbors)

        if (someColliNeighbors) {
            deliveryStatus = dbCodes.deliveryStatus.bezorgdBijBuren()
        }
    } else {
        const allColliNotHome = _.every(entry.colli, (collo) => collo.status === 'notdelivered' && collo.reason === 'nothome')

        if (allColliNotHome) {
            deliveryStatus = dbCodes.deliveryStatus.nietThuis()
        }

        const allColliRefused = _.every(entry.colli, (collo) => collo.status === 'refused')

        if (allColliRefused) {
            deliveryStatus = dbCodes.deliveryStatus.geweigerd()
        }
    }

    return deliveryStatus
}

function addressToPickupStatus(order, address) {
    if (address.type !== 'pickup') {
        return undefined
    }

    const reversedHistory = [...order.history].reverse()

    let pickupStatus

    reversedHistory.map((entry) => {
        pickupStatus = pickupStatus || colliToPickupStatus(entry)
    })

    return pickupStatus
}

function addressToDeliveryStatus(order, address) {
    if (address.type !== 'delivery') {
        return undefined
    }

    const reversedHistory = [...order.history].reverse()

    let deliveryStatus

    reversedHistory.map((entry) => {
        deliveryStatus = deliveryStatus || colliToDeliveryStatus(entry)
    })

    return deliveryStatus
}

export default {
    colliToPickupStatus,
    colliToDeliveryStatus,
    addressToPickupStatus,
    addressToDeliveryStatus
}

import React from 'react'
import _ from 'lodash'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, IconButton, Input, Select, TextArea, S1, CurrencyInput, Price, Toggle, Row, DatePicker} from '../UI/index.js'


class Administration extends React.PureComponent {
    constructor(props) {
        super(props)

        this.initialState = {
            order: null,
            dontInvoice: true,
            invoiceDate: null,
            priceTableId: '',
            distance: 0,
            feeIds: [],
            reference: '',
            price: '0,00',
            fees: [],
            edit: false,
            expanded: false,
            priceLoading: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = structuredClone(this.initialState)
    }

    componentDidMount() {
        this.setState({
            order: structuredClone(this.props.order),
            dontInvoice: !!this.props.order.dontInvoice,
            invoiceDate: this.props.order.invoiceDate,
            priceTableId: this.props.order.priceTableId,
            distance: this.props.order.distance,
            feeIds: [...(this.props.order.feeIds|| [])],
            reference: this.props.order.reference,
            price: this.props.order.price,
            fees: [...(this.props.order.fees || [])]
        })
    }

    onChangePrice(price, fees) {
        this.setState({
            priceTableId: '',
            feeIds: [],
            price,
            fees
        })
    }

    onChangePricetableId(event) {
        const {reseller} = this.props
        const priceTable = _.find(reseller.settings.orders.prices, {id: event.target.value})

        this.setState({priceTableId: priceTable?.id || '', feeIds: [], price: '0,00'}, () => {
            if (priceTable?.id) {
                this.calculateDistanceAndPrice()
            }
        })
    }

    onChangeFees(event) {
        this.setState({feeIds: [...event.target.values]}, () => {
            this.calculateDistanceAndPrice()
        })
    }

    calculateDistanceAndPrice() {
        const {order, priceTableId, feeIds} = this.state

        this.setState({priceLoading: true, error: ''})

        order.priceTableId = priceTableId
        order.feeIds = feeIds

        OrderActions.getPrice(order, (err, price, distance) => {
            if (!err) {
                this.setState({priceLoading: false, price, distance})
            } else {
                this.setState({priceLoading: false, error: err})
            }
        })
    }

    save() {
        const {dontInvoice, invoiceDate, priceTableId, distance, feeIds, reference, price, fees} = this.state
        const {order} = this.props

        this.setState({loading: true, error: ''})

        OrderActions.editAdministration(order._id, dontInvoice, invoiceDate, priceTableId, distance, feeIds, reference, price, fees, (err, order) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, edit: false, error: ''})

                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {order, dontInvoice, invoiceDate, priceTableId, distance, feeIds, reference, price, fees, expanded, edit, priceLoading, error} = this.state
        const {reseller} = this.props

        const priceTable = _.find(reseller.settings.orders.prices, {id: priceTableId})

        return (
            <div style={{marginBottom: 24, width: '100%'}}>
                <Row style={{alignItems: 'center', minHeight: 36}}>
                    <Row style={{cursor: 'pointer'}} onClick={() => this.setState({expanded: !expanded})}>
                        <S1>Administratie</S1>
                        <i style={{marginLeft: 6}}className={expanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>
                    </Row>

                    <div style={{flex: 1}}/>

                    {!edit && expanded &&
                        <IconButton
                            icon='mdi mdi-pencil'
                            onClick={() => this.setState({edit: !edit})}
                        />
                    }

                    {edit &&
                        <IconButton
                            icon='mdi mdi-check'
                            onClick={this.save.bind(this)}
                        />
                    }
                </Row>

                {expanded &&
                    <div style={{marginBottom: 12, width: '100%', padding: 6}} >
                        <Row>
                            <Toggle
                                label='Rit factureren'
                                checked={!dontInvoice}
                                onChange={(event) => this.setState({dontInvoice: !event.target.checked})}
                                disabled={!edit || order.invoiced}
                            />

                            <DatePicker
                                label='Factuurdatum'
                                value={invoiceDate}
                                onChange={({target: {value}}) => this.setState({invoiceDate: value})}
                                readOnly={!edit}
                                disabled={dontInvoice || order.invoiced}
                            />
                        </Row>

                        <div style={{display: 'flex'}}>
                            <Select
                                loading={priceLoading}
                                label='Tarieftabel'
                                allowEmptyValue
                                value={priceTableId}
                                onChange={this.onChangePricetableId.bind(this)}
                                readOnly={!edit}
                                disabled={dontInvoice}

                            >
                                {(reseller.settings.orders.prices || []).map((priceTable) => {
                                    let disabled = false

                                    if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order?.addresses.length === 1) {
                                        disabled = true
                                    }
                                    if (priceTable.distanceFeeType === 'zones' && order?.addresses.length > 2) {
                                        disabled = true
                                    }

                                    return (
                                        <option key={priceTable.id} value={priceTable.id} disabled={disabled}>{priceTable.name}</option>
                                    )
                                })}
                            </Select>

                            {priceTableId && distance > 0 &&
                                <Input
                                    style={{marginLeft: 6, width: 100}}
                                    label='Afstand'
                                    value={`${distance} km`}
                                    readOnly
                                />
                            }


                            <Select
                                style={{flex: 1, marginLeft: 6}}
                                label='Toeslag'
                                values={feeIds}
                                options={_.keys(priceTable?.fees || {}).map((id) => {
                                    const fee = priceTable.fees[id]
                                    return {value: id, title: fee.name}
                                })}
                                onChange={this.onChangeFees.bind(this)}
                                disabled={!priceTableId || !priceTable || dontInvoice}
                                readOnly={!edit}
                            />
                        </div>


                        <div style={{display: 'flex'}}>
                            <TextArea
                                style={{marginRight: 6}}
                                label='Referentie'
                                value={reference}
                                onChange={(event) => this.setState({reference: event.target.value})}
                                disabled={dontInvoice}
                                readOnly={!edit}
                            />

                            {reseller.settings.orders.useNewPrices ?
                                <CurrencyInput
                                    style={{marginLeft: 6}}
                                    label='Prijs'
                                    value={price}
                                    onChange={(event) => this.setState({price: event.target.value})}
                                    disabled={dontInvoice}
                                    readOnly={priceTableId || !edit}
                                /> :
                                <Price
                                    style={{marginLeft: 6}}
                                    label='Prijs'
                                    price={price}
                                    fees={fees}
                                    priceTables={reseller.settings.priceTables || []}
                                    onChange={this.onChangePrice.bind(this)}
                                    disabled={dontInvoice}
                                    readOnly={!edit}
                                    ref={(ref) => this.price = ref}
                                />
                            }
                        </div>

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </div>
                }
            </div>


        )
    }
}

export default Administration


const returnRefundMethods = {
    refund: {title: 'Geld terug', info: 'Je ontvangt je geld terug'},
    exchange: {title: 'Artikelen ruilen', info: 'Ruil de geselecteerde artikelen voor een ander product.'},
    credit: {title: 'Winkel tegoed', info: 'Je ontvang een cadeaubon of kortingscode'}
}

const returnReasons = [
    'Anders:'
]

export {
    returnRefundMethods,
    returnReasons
}

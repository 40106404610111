import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../socketio/socketio.js'
import PaycheckActions from '../actions/PaycheckActions.js'

export default class PaycheckStore extends Reflux.Store {
    constructor() {
        super()
        this.state = {
            startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            paycheckQueries: [],
            paychecks: [],
            selectedPaychecks: [],
            paychecksLoading: false
        }
        this.listenables = PaycheckActions

        PaycheckActions.get(true)

        socket.on('updateShifts', () => {
            PaycheckActions.get()
        })
    }

    onSetDates(startDate, endDate) {
        this.setState({startDate, endDate, selectedPaychecks: []})
        PaycheckActions.get(true)
    }

    onSetQueries(paycheckQueries) {
        this.setState({paycheckQueries, selectedPaychecks: []})
    }

    onSetSelected(selectedPaychecks) {
        this.setState({selectedPaychecks})
    }


    onGet(loading) {
        const {startDate, endDate, selectedPaychecks} = this.state
        this.setState({paychecksLoading: !!loading})

        socket.emit('paychecks.get', sessionStorage.token, startDate, endDate, (err, paychecks) => {
            if (!err) {
                const ids = _.pluck(paychecks, '_id')

                this.setState({
                    paychecks: _.sortBy(paychecks, 'messenger'),
                    selectedPaychecks: _.filter(selectedPaychecks, (id) => ids.indexOf(id) > -1),
                    paychecksLoading: false
                })
            }
        })
    }

    onToPDF(paychecks, callback) {
        socket.emit('paychecks.ToPDF', sessionStorage.token, paychecks, callback)
    }

    onSendByEmail(paychecks, callback) {
        socket.emit('paychecks.SendByEmail', sessionStorage.token, paychecks, callback)
    }
}

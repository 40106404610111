import React from 'react'

import Guid from '../../../utils/guid.js'
import _ from 'underscore'

import {Button, IconButton, Modal, H4, H6, Input, Popup, Toggle, CurrencyInput, FloatInput, Select, Colors, Row, Steps, P, Column, ToolTip} from '../UI/index.js'

export default class PriceTableModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            priceTable: {
                id: '',
                name: '',
                startRate: '0,00',
                minimumRate: '0,00',
                feeExtraStop: '0,00',
                nrOfStopsIncluded: 2,
                feeForPickupAddress: '0,00',
                feeForDeliveryAddress: '0,00',
                feeForExtraCollo: '0,00',
                nrOfColliIncluded: 1,
                pickupAndDeliveryOptions: [{
                    fee: '0,00',
                    option: ''
                }],
                distanceFeeType: '',
                startAtReseller: false,
                retour: false,
                distanceFee: '0,00',
                startDistance: 0,
                fuelFeeType: '',
                fuelFeePercent: '',
                deliveryZones: [],
                zoneFees: {},
                rounding: '',
                fees: {}
            },
            validation: {}
        }

        this.state = this.initialState
    }

    open(priceTable, index) {
        const {reseller} = this.props

        priceTable = priceTable ?
            JSON.parse(JSON.stringify(priceTable)) :
            {
                ...this.initialState.priceTable,
                id: Guid.generate()
            }


        priceTable.deliveryZones = priceTable.deliveryZones || []
        priceTable.deliveryZones = priceTable.deliveryZones.filter((id) => {
            return !!_.findWhere(reseller.settings.deliveryZones, {id})
        })

        this.setState({
            modalIsOpen: true,
            newPriceTable: typeof index !== 'number',
            index,
            priceTable
        })

        if (!priceTable.fees) {
            priceTable.fees = {}
        }
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const priceTable = {...this.state.priceTable}


        if (key === 'rounding') {
            priceTable[key] = parseFloat(event.target.value)
        } else if (key === 'startDistance') {
            priceTable[key] = parseInt(event.target.value)
        } else {
            priceTable[key] = event.target.value
        }

        this.setState({priceTable})
    }

    onChangeChecked(key, event) {
        const priceTable = {...this.state.priceTable}

        priceTable[key] = event.target.checked


        this.setState({priceTable})
    }

    onAddPickupDeliveryOption() {
        const {priceTable} = this.state

        priceTable.pickupAndDeliveryOptions.push({
            fee: '0,00',
            option: ''
        })

        this.setState({priceTable})
    }

    onChangePickupDeliveryOption(key, index, event) {
        const {priceTable} = this.state

        if (priceTable.pickupAndDeliveryOptions?.[index]) {
            priceTable.pickupAndDeliveryOptions[index][key] = event.target.value
        }

        this.setState({priceTable})
    }

    onRemovePickupDeliveryOption(index) {
        const {priceTable} = this.state

        priceTable.pickupAndDeliveryOptions.splice(index, 1)

        this.setState({priceTable})
    }

    onChangeDeliveryZones(event) {
        const priceTable = {...this.state.priceTable}

        priceTable.deliveryZones = event.target.values

        this.setState({priceTable})
    }

    onChangeZoneFee(key, event) {
        const priceTable = {...this.state.priceTable}

        const keys = key.split('-')

        if (!priceTable.zoneFees) {
            priceTable.zoneFees = {}
        }

        priceTable.zoneFees[`${keys[0]}-${keys[1]}`] = event.target.value
        priceTable.zoneFees[`${keys[1]}-${keys[0]}`] = event.target.value

        this.setState({priceTable})
    }

    onValidate() {
        const priceTable = {...this.state.priceTable}
        const validation = {}
        let id = ''
        let valid = true


        if (!priceTable.name) {
            id = 'name'
            validation.name = true
            valid = false
        }

        if (!valid) {
            const element = document.getElementById(id)
            if (element) {
                element.focus()
            }
        }

        this.setState({validation})
        return valid
    }

    onRemove() {
        this.popup.open('Tarieftabel verwijderen', 'Weet je zeker dat je deze tarieftabel wilt verwijderen?', () => {
            this.props.onRemove(this.state.index)
            this.close()
        })
    }

    onSubmit(event) {
        event.preventDefault()

        if (this.onValidate()) {
            this.props.onSubmit(this.state.priceTable, this.state.index)
            this.close()
        }
    }

    onAddFee() {
        const priceTable = {...this.state.priceTable}

        const id = Math.random().toString(16).substring(2, 14)

        priceTable.fees[id] = {name: '', price: '0,00'}

        this.setState({priceTable})
    }

    onChangeFee(key, id) {
        const priceTable = {...this.state.priceTable}

        priceTable.fees[id][key] = event.target.value

        this.setState({priceTable})
    }

    onRemoveFee(id) {
        const priceTable = {...this.state.priceTable}

        delete priceTable.fees[id]

        this.setState({priceTable})
    }

    render() {
        const {modalIsOpen, priceTable, newPriceTable, validation} = this.state
        const {reseller} = this.props

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <H4>{newPriceTable ? 'Nieuwe tarieftabel' : 'Bewerk tarieftabel'}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <br/>
                <form onSubmit={this.onSubmit.bind(this)}>

                    <Input
                        id='name'
                        label='Naam'
                        value={priceTable.name}
                        isInvalid={validation.name}
                        onChange={this.onChangeValue.bind(this, 'name')}
                    />

                    <br/>

                    <Row>
                        <CurrencyInput
                            style={{marginRight: 12}}
                            label='Starttarief'
                            value={priceTable.startRate}
                            onChange={this.onChangeValue.bind(this, 'startRate')}
                        />

                        <CurrencyInput
                            label='Minimum prijs'
                            value={priceTable.minimumRate}
                            onChange={this.onChangeValue.bind(this, 'minimumRate')}
                        />
                    </Row>

                    <Select
                        label='Afronding totaal'
                        allowEmptyValue
                        value={priceTable.rounding}
                        options={[
                            {value: 1, title: 'Hele euro\'s'},
                            {value: 2.5, title: 'Veelvoud van €2,50'},
                            {value: 5, title: 'Veelvoud van €5,00'},
                            {value: 10, title: 'Veelvoud van €10,00'},
                            {value: 5.05, title: 'Veelvoud van €5,00 - €0,05'},
                            {value: 10.05, title: 'Veelvoud van €10,00 - €0,05'}
                        ]}
                        onChange={this.onChangeValue.bind(this, 'rounding')}
                    />


                    <br/>
                    <H6>Stops</H6>

                    <Row>
                        <CurrencyInput
                            label='Tarief per stop'
                            value={priceTable.feeExtraStop}
                            onChange={this.onChangeValue.bind(this, 'feeExtraStop')}
                        />

                        <Steps
                            label='Aantal stops inbegrepen'
                            value={priceTable.nrOfStopsIncluded}
                            onChange={this.onChangeValue.bind(this, 'nrOfStopsIncluded')}
                            min={0}
                        />
                    </Row>

                    <Row>
                        <CurrencyInput
                            label='Toeslag per ophaaladres'
                            value={priceTable.feeForPickupAddress}
                            onChange={this.onChangeValue.bind(this, 'feeForPickupAddress')}
                        />

                        <CurrencyInput
                            label='Toeslag per bezorgadres'
                            value={priceTable.feeForDeliveryAddress}
                            onChange={this.onChangeValue.bind(this, 'feeForDeliveryAddress')}
                        />
                    </Row>


                    <br/>
                    <H6>Colli</H6>
                    <Row>
                        <CurrencyInput
                            label='Tarief per collo'
                            value={priceTable.feeForExtraCollo}
                            onChange={this.onChangeValue.bind(this, 'feeForExtraCollo')}
                        />

                        <Steps
                            label='Aantal colli inbegrepen'
                            value={priceTable.nrOfColliIncluded}
                            onChange={this.onChangeValue.bind(this, 'nrOfColliIncluded')}
                            min={0}
                        />
                    </Row>


                    <br/>
                    <H6>Ophaal- en bezorgopties</H6>

                    {priceTable.pickupAndDeliveryOptions.map((option, index) => (
                        <Row key={`option-${index}`}>
                            <CurrencyInput
                                label='Tarief per optie per adres'
                                value={option.fee}
                                onChange={(event) => this.onChangePickupDeliveryOption('fee', index, event)}
                            />


                            <Row style={{flex: 1, marginRight: 12}}>
                                <Select
                                    label='Selecteer ophaal-/bezorgoptie'
                                    options={[
                                        {value: 'gpsRequired', title: 'GPS verplicht'},
                                        {value: 'scanRequired', title: 'Scan verplicht'},
                                        {value: 'imageRequired', title: 'Foto verplicht'},
                                        {value: 'signatureRequired', title: 'Handtekening'},
                                        {value: 'signatureNeighbors', title: 'Handtekening buren'},
                                        {value: 'nameRequired', title: 'Naam verplicht'},
                                        {value: 'statedAddressOnly', title: 'Niet bij buren'},
                                        {value: 'minimumAge +16', title: 'Leeftijdscheck +16'},
                                        {value: 'minimumAge +18', title: 'Leeftijdscheck +18'},
                                        {value: 'minimumAge +21', title: 'Leeftijdscheck +21'},
                                        {value: 'idCheck', title: 'ID check'},
                                        {value: 'mailboxDelivery', title: 'Brievenbus'}
                                    ].filter((opt) => {
                                        const i = priceTable.pickupAndDeliveryOptions.findIndex((option) => option.option === opt.value)
                                        return i <= -1 || i === index
                                    })
                                    }
                                    value={option.option}
                                    onChange={(event) => this.onChangePickupDeliveryOption('option', index, event)}
                                />

                                <IconButton
                                    icon='mdi mdi-delete'
                                    disabled={priceTable.pickupAndDeliveryOptions.length <= 1}
                                    onClick={this.onRemovePickupDeliveryOption.bind(this, index)}
                                />
                            </Row>
                        </Row>
                    ))}

                    <Button onClick={this.onAddPickupDeliveryOption.bind(this)} variant='outline-white' icon='mdi mdi-plus'/>


                    <br/>
                    <H6>Afstand</H6>

                    <Select
                        label='Afstand afhankelijke toeslag'
                        allowEmptyValue
                        value={priceTable.distanceFeeType}
                        options={[
                            {value: 'distance', title: 'Kilometerprijs snelste route'},
                            {value: 'zones', title: 'Prijs per bezorggebied'}]}
                        onChange={this.onChangeValue.bind(this, 'distanceFeeType')}
                    />

                    {priceTable.distanceFeeType === 'distance' &&
                        <>
                            <Toggle
                                label={`Start bij ${reseller.settings?.accountName || reseller.name}`}
                                onChange={this.onChangeChecked.bind(this, 'startAtReseller')}
                                checked={priceTable.startAtReseller}
                            />

                            <Toggle
                                label={'Retour naar start'}
                                onChange={this.onChangeChecked.bind(this, 'retour')}
                                checked={priceTable.retour}
                            />

                            <div style={{display: 'flex'}}>
                                <CurrencyInput
                                    style={{marginRight: 12}}
                                    label='Kilometertarief'
                                    value={priceTable.distanceFee}
                                    onChange={this.onChangeValue.bind(this, 'distanceFee')}
                                />

                                <Input
                                    type='number'
                                    label='Kilometers inbegrepen bij starttarief'
                                    value={priceTable.startDistance || 0}
                                    onChange={this.onChangeValue.bind(this, 'startDistance')}
                                />
                            </div>

                            <div style={{display: 'flex'}}>
                                <Select
                                    allowEmptyValue
                                    label='Brandstof afhankelijke toeslag'
                                    value={priceTable.fuelFeeType}
                                    options={[
                                        {value: 'orderPrice', title: 'Percentage over de ritprijs'},
                                        {value: 'distanceFee', title: 'Percentage over kilometertarief'}
                                    ]}
                                    onChange={this.onChangeValue.bind(this, 'fuelFeeType')}
                                />

                                {priceTable.fuelFeeType &&
                                    <FloatInput
                                        label='Percentage'
                                        value={priceTable.fuelFeePercent}
                                        onChange={this.onChangeValue.bind(this, 'fuelFeePercent')}
                                        append='%'
                                    />
                                }

                            </div>
                        </>
                    }

                    {priceTable.distanceFeeType === 'zones' && reseller.settings.deliveryZones &&
                        <div>
                            <Select
                                label='Bezorggebieden'
                                options={reseller.settings.deliveryZones.map((zone) => {
                                    return {value: zone.id, title: zone.name}
                                })}
                                values={priceTable.deliveryZones || []}
                                onChange={this.onChangeDeliveryZones.bind(this)}
                            />

                            <div key='scrollContainer' style={{width: 760, overflowX: 'auto', marginBottom: 10}}>

                                <Row style={{marginTop: 12, marginBottom: 12}}>
                                    <Column style={{width: 120, flexShrink: 0}}/>

                                    {priceTable.deliveryZones.map((zoneColumn) => {
                                        const deliveryZone = _.findWhere(reseller.settings.deliveryZones, {id: zoneColumn})

                                        if (!deliveryZone) {
                                            return
                                        }

                                        return (
                                            <Column key={deliveryZone.id} style={{width: 120, flexShrink: 0}} >
                                                <ToolTip text={deliveryZone.name} style={{width: 112}}>
                                                    <P ellipsis >{deliveryZone.name}</P>
                                                </ToolTip>
                                            </Column>
                                        )
                                    })}
                                </Row>

                                {priceTable.deliveryZones.map((zoneRow, rowIndex) => {
                                    const deliveryZone = _.findWhere(reseller.settings.deliveryZones, {id: zoneRow})

                                    if (!deliveryZone) {
                                        return
                                    }

                                    return (
                                        <Row key={zoneRow} style={{alignItems: 'center'}}>
                                            <Column style={{width: 120, flexShrink: 0}}>
                                                <ToolTip text={deliveryZone.name} placement='right' style={{width: 112}}>
                                                    <P ellipsis >{deliveryZone.name}</P>
                                                </ToolTip>
                                            </Column>

                                            {priceTable.deliveryZones.map((zoneColumn, columnIndex) => {
                                                const deliveryZone = _.findWhere(reseller.settings.deliveryZones, {id: zoneColumn})

                                                if (!deliveryZone || columnIndex > rowIndex) {
                                                    return
                                                }

                                                return (
                                                    <Column key={`${zoneColumn}-${zoneRow}`} style={{width: 120, flexShrink: 0}}>
                                                        <CurrencyInput
                                                            label='Prijs'
                                                            value={priceTable.zoneFees ? priceTable.zoneFees[`${zoneColumn}-${zoneRow}`] || 0 : 0}
                                                            onChange={this.onChangeZoneFee.bind(this, `${zoneColumn}-${zoneRow}`)}
                                                        />
                                                    </Column>

                                                )
                                            })}
                                        </Row>
                                    )
                                })}
                            </div>
                        </div>
                    }


                    <br/>
                    <H6>Handmatige toeslagen</H6>
                    {
                        _.keys(priceTable.fees).map((id) => {
                            const fee = priceTable.fees[id]

                            return (
                                <Row key={`fee${id}`}>
                                    <CurrencyInput
                                        label='Prijs'
                                        value={fee.price}
                                        onChange={this.onChangeFee.bind(this, 'price', id)}
                                    />

                                    <Input
                                        label='Naam'
                                        value={fee.name}
                                        onChange={this.onChangeFee.bind(this, 'name', id)}
                                    />

                                    <IconButton onClick={this.onRemoveFee.bind(this, id)} style={{marginLeft: 12}}>
                                        <i className='mdi mdi-delete'/>
                                    </IconButton>
                                </Row>
                            )
                        })
                    }

                    <Button onClick={this.onAddFee.bind(this)} variant='outline-white' icon='mdi mdi-plus'/>

                    <br/>
                    <br/>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {!newPriceTable &&
                            <Button variant='error' onClick={this.onRemove.bind(this)}>
                                Verwijder
                            </Button>
                        }

                        <Button type="submit">
                            Opslaan
                        </Button>
                    </div>
                </form>

                <Popup
                    ref={(ref) => this.popup = ref}
                    cancelBtnText='Terug'
                    confirmBtnText='Verwijderen'
                />
            </Modal>
        )
    }
}


import React from 'react'

import Reflux from 'reflux-react-16'
import moment from 'moment'

import AdminStore from '../../stores/AdminStore.js'
import AdminActions from '../../actions/AdminActions.js'
import LoginActions from '../../actions/LoginActions.js'

import {NewReseller, EditReseller} from './components/resellers/index.js'
import {Button, IconButton, Popup, P, Row, Spinner} from '../../components/UI/index.js'
import Table from '../../components/Table/index.js'


export default class Resellers extends Reflux.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedResellers: [],
            viewArchived: false
        }
        this.stores = [AdminStore]
    }

    onClickNew() {
        AdminActions.selectReseller(NewReseller())
    }

    onClickSelect(reseller) {
        AdminActions.selectReseller(reseller)
    }

    onClickLogin(reseller, event) {
        event.stopPropagation()

        LoginActions.loginAsReseller(reseller._id, undefined, (err) => {
            if (err) {
                this.popup.open('Foutmelding', '', () => {})
                this.popup.setError(err)
            }
        })
    }

    onViewArchived() {
        const {viewArchived} = this.state

        AdminActions.getResellers(true, !viewArchived)

        this.setState({viewArchived: !viewArchived})
    }

    columns() {
        const {history} = this.props

        return [
            {title: 'Naam', visible: true, width: 250, onClick: (reseller) => {
                AdminActions.selectReseller(reseller)
            }, render: (reseller) =>{
                return (reseller.settings?.accountName && reseller.settings?.accountName !== reseller.name) ? `${reseller.settings?.accountName} (${reseller.name})` : reseller.name
            }},
            {
                title: 'Startdatum', visible: true, width: 100, sort: (reseller) => {
                    return reseller.startDate
                }, render: (reseller) => {
                    return moment(reseller.startDate).format('DD-MM-YYYY')
                }
            },
            {
                title: 'Factureren', visible: true, width: 80, render: (reseller) => {
                    return reseller?.noInvoice && !reseller.invoiceResellerId ? 'Nee' : 'Ja'
                }
            },
            {
                title: 'Debiteurnummer', visible: true, flex: 1, render: (reseller) => {
                    return reseller.debtorCode || reseller.invoiceResellerId
                }
            },
            {
                title: 'Laatste factuur', visible: true, key: 'lastInvoice', width: 100, render: (reseller) => {
                    return reseller.lastInvoice ? moment(reseller.lastInvoice).format('DD-MM-YYYY') : ''
                }
            },
            {
                title: 'Hoofdlocatie', visible: true, key: 'settings.companyAddress.city', width: 100
            },
            {
                title: 'Gearchiveerd', visible: this.state.viewArchived, width: 80, render: (reseller) => {
                    return reseller?.isArchived ? `Ja (${moment(reseller?.archivedTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY')})` : ''
                }
            },
            {title: 'Bekijk logs', visible: true, key: '', width: 120, render: (reseller) => {
                return (
                    <IconButton
                        onClick={() => {
                            history?.push(`/admin/logs?resellerId=${reseller._id}`)
                        }}
                    >
                        <i className="mdi mdi-text-box-search-outline"/>
                    </IconButton>
                )
            }},
            {title: '', visible: true, key: '', width: 60, onClick: () => {}, render: (reseller) => {
                return (
                    <IconButton onClick={this.onClickLogin.bind(this, reseller)} >
                        <i className="mdi mdi-login"/>
                    </IconButton>
                )
            }}
        ]
    }


    render() {
        const {resellers, selectedReseller, resellersLoading, selectedResellers, viewArchived} = this.state

        return (
            <div style={{display: 'flex', minHeight: '100%', paddingTop: 30, paddingLeft: 24, paddingRight: 24}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 30}}>
                        <Button onClick={this.onClickNew.bind(this)}>Nieuwe reseller</Button>

                        <Row style={{alignItems: 'center', width: 'fit-content'}}>
                            <P>{viewArchived ? 'Verberg' : 'Toon'} gearchiveerde resellers</P>
                            <IconButton icon={viewArchived ? 'mdi mdi-eye-off' : 'mdi mdi-eye'} onClick={this.onViewArchived.bind(this)}/>
                        </Row>
                    </div>

                    {resellersLoading ?
                        <Spinner name='ball-clip-rotate' style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 15}} fadeIn='none'/> :
                        <Table
                            noPagination
                            tableName='Resellers'
                            columns={this.columns()}
                            rows={resellers}
                            selectedRows={selectedResellers}
                            onChange={(selectedResellers) => this.setState({selectedResellers})}
                            sortKey='name'
                            ref={(ref) => this.table = ref}
                        />
                    }
                </div>

                <div style={{flex: 1, paddingLeft: 24}}>
                    {selectedReseller &&
                        <EditReseller reseller={selectedReseller} resellers={resellers} viewArchived={viewArchived}/>
                    }
                </div>
                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

